import { gql, useMutation } from '@apollo/client';

const CREATE_ANNOTATION = gql`
  mutation createAnnotation($input: CreateAnnotationInput!) {
    createAnnotation(input: $input) {
      annotation {
        id
        externalId
        shared
      }

      errors {
        attribute
        message
      }
    }
  }
`;

const useCreateAnnotationMutation = (options) => useMutation(CREATE_ANNOTATION, options);

export default useCreateAnnotationMutation;
