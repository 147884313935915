import React, { useState, useEffect, useContext } from 'react';
import FreeTrialBadge from 'images/icons/free-trial-14.svg';
import Heading from 'components/v2/shared/Heading';
import Switch from 'components/v2/shared/Switch';
import Button from 'components/v2/shared/Button';
import Box from 'components/v2/shared/Box';
import PlanDetails from 'components/v2/planDetails';
import PaymentSuccess from 'components/v2/planDetails/PaymentSuccess';
import Spinner from 'components/v2/shared/Spinner';
import Alert from 'components/v2/shared/Alert';
import { FiChevronRight } from 'react-icons/fi';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ToastContext } from 'contexts/v2/ToastContext';
import Toast, { Severity } from '../shared/Toast';
import clsx from 'clsx';
import axios from 'axios';
import { useProductsQuery } from 'data/v2/queries/ProductsQuery';
import { getAxiosHeaders } from '../../../utils/getHeaders';

const BillingInvoices = ({ currentCompany }) => {
  const stripePromise = loadStripe(ENV['STRIPE_KEY']);
  const [isIndexPlan, setIndexPlan] = useState(0);
  const [isActive, setActive] = useState(false);
  const [isActivePaymentSuccess, setActivePaymentSuccess] = useState(false);
  const [isActivePlan, setActivePlan] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [planPrices, setPlanPrices] = useState([]);
  const [taxRates, setTaxRates] = useState(null);
  const [selectedPlanData, setSelectedPlanData] = useState({});
  const [alert, setAlert] = useState({ display: false, isValid: false });
  const [errMsg, setErrorMsg] = useState('');
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const { toastInfo } = useContext(ToastContext);
  const [invoices, setInvoices] = useState([]);
  const [inclusionData] = useState([
    {
      title: 'Board portal',
    },
    {
      title: 'Meeting space',
    },
    {
      title: 'Calendar integration',
    },
    {
      title: 'Document storage',
    },
    {
      title: 'Minute taking',
    },
    {
      title: 'Voting',
    },
    {
      title: 'Taskboard',
    },
    {
      title: 'Electronic signature tool',
    },
    {
      title: 'Chat',
    },
    {
      title: 'Contacts & groups',
    },
    {
      title: 'Multi-company access',
    },
  ]);

  const handleOnSwitchChange = (e) => {
    setActive((current) => !current);
    setProductPrices(planData, e);
  };

  const setProductPrices = (products, isPerYear) => {
    let productList = [];
    if (isPerYear) {
      productList = products.filter((pro) => pro.interval === 'year');
    } else {
      productList = products.filter((pro) => pro.interval === 'month');
    }

    setPlanPrices(productList);
    setSelectedPlanData(productList[0]);
    setIndexPlan(0);
  };

  const onClickPlan = (data, index) => {
    setActivePlan(true);
    setSelectedPlanData(data);
    setIndexPlan(index);
  };
  const onClickPaymentSuccess = async (val, latest_invoice) => {
    if (val) {
      let invoiceList = [...invoices];
      invoiceList.unshift(latest_invoice);
      setInvoices(invoiceList);
    }
    await getCurrentSubscription();
    setActivePaymentSuccess(val);
  };

  const loadInvoices = async () => {
    try {
      const response = await axios.get(
        `/${currentCompany.slug}/invoices?id=${currentCompany.id.split('/').pop()}`,
        getAxiosHeaders()
      );
      const invoiceList = response.data.map((invoice) => {
        return { ...invoice, total: parseFloat(invoice.total).toFixed(2) };
      });

      setInvoices(invoiceList.reverse());
    } catch (err) {
      setInvoices([]);
    }
  };

  const getTaxRate = async () => {
    const header = { Authorization: `Bearer ${ENV['STRIPE_SECRET']}` };
    try {
      const taxesResponse = await axios.get(`${ENV['STRIPE_API']}/tax_rates/${ENV['STRIPE_TAX_RATE']}`, {
        headers: header,
      });
      setTaxRates(taxesResponse.data);
      return true;
    } catch (err) {
      setErrorMsg(err.message);
      setAlert({
        display: true,
        isValid: false,
      });
      return false;
    }
  };

  const getCurrentSubscription = async () => {
    if (productList?.data) {
      try {
        const response = await axios.get(`/${currentCompany.slug}/profile/reload_subscription`, getAxiosHeaders());

        if (response.data.status === 'active') {
          setCurrentSubscription(response.data);

          let products = productList.data.products;
          let currProduct = response.data.product_id;

          setSelectedPlanData(products.find((product) => currProduct === Number(product.id.split('/').pop())));
          setActivePaymentSuccess(true);
        }
      } catch (err) {
        setCurrentSubscription(null);
      }
    }
  };

  const productList = useProductsQuery();

  const getProducts = async () => {
    setLoading(true);
    const hasTaxData = await getTaxRate();

    if (productList?.data && hasTaxData) {
      setPlanData([]);
      setPlanPrices([]);

      let products = productList.data.products.filter((product) => !product.deprecated);
      setPlanData(products);
      setPlanPrices(products);
      setProductPrices(products);
      await loadInvoices();
      await getCurrentSubscription();
    }
    setLoading(false);
  };

  useEffect(() => {
    setActivePlan(true);
    getProducts();
  }, [productList]);

  const handleCancelPurchase = async () => {
    setActivePlan(false);
    setIndexPlan(-1);
  };

  return (
    <>
      <div
        className={clsx('tw-justify-center_ tw-mx-auto tw-w-full xl:tw-flex', {
          'lg:tw-flex-row': !isActivePaymentSuccess,
          'tw-flex-col sm:tw-max-w-md sm:tw-px-0': isActivePaymentSuccess && currentSubscription,
        })}
      >
        {!isActivePaymentSuccess && (
          <>
            <Toast severity={toastInfo.severity} status={toastInfo.status} message={toastInfo.message} />
            <div className='tw-flex tw-justify-center xl:tw-w-1/2'>
              <div className='tw-max-w-md xl:tw-w-[350px]'>
                <div className='tw-mt-9 tw-hidden tw-items-center tw-justify-between sm:tw-block sm:tw-flex'>
                  <Heading text='Billing & Invoices' className='!tw-mb-0' />
                </div>
                <div>
                  <div className='tw-grid tw-grid-flow-col tw-grid-cols-3 tw-items-center tw-py-3'>
                    <div className='tw-col-span-2'>
                      <span className='tw-font-sans tw-text-xs tw-font-medium tw-text-gray-700 sm:tw-text-sm'>
                        We've made pricing simple with our all inclusive features. Charities can take advantage of our
                        special discounts.
                      </span>
                    </div>
                    <div className='tw-col-span-1 tw-flex tw-justify-end'>
                      <img
                        src={FreeTrialBadge}
                        alt='Free Trial for 14 Days'
                        className='!tw-h-24 !tw-w-24 sm:!tw-h-32 sm:!tw-w-32'
                      />
                    </div>
                  </div>
                </div>
                <div className='tw-mt-2.5'>
                  <h6 className='tw-mb-0 tw-pt-3 tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>
                    Select plan below
                  </h6>
                  <div className='tw-mt-8 tw-flex tw-items-center xl:tw-mt-6'>
                    <div className='tw-font-sans tw-text-xs tw-font-medium tw-text-black'>Pricing by</div>
                    <div className='tw-px-8'>
                      <Switch
                        labelAfter='Year'
                        labelBefore='Month'
                        onChange={(e) => {
                          handleOnSwitchChange(e);
                        }}
                        variant='purple-bg'
                        isChecked={isActive}
                      />
                    </div>
                  </div>
                  <div className='tw-mt-7 xl:tw-mt-5'>
                    <p className='!tw-m-0 tw-font-sans tw-text-xs tw-font-medium tw-text-gray-700'>
                      Prices are per month / per boardspace, paid monthly. Exclude VAT.
                    </p>
                  </div>
                </div>
                {alert.display && !alert.isValid && (
                  <div className='tw-mt-12'>
                    <Alert
                      description={errMsg}
                      isDismissable={false}
                      severity='error'
                      className='!tw-bg-red-200 !tw-font-sans tw-text-sm tw-font-medium !tw-text-black'
                    />
                  </div>
                )}
                {planPrices.length < 1 && isLoading && (
                  <div className='tw-relative tw-mt-12 tw-mb-8 tw-p-5'>
                    <Spinner />
                  </div>
                )}
                {planPrices.length > 0 && !isLoading && (
                  <div className='tw-my-5'>
                    {planPrices.map((row, i) => {
                      return (
                        <div className='tw-mb-2 tw-rounded !tw-bg-teal-200' key={i}>
                          <Box
                            onClick={() => {
                              onClickPlan(row, i);
                            }}
                            isSelected={isIndexPlan === i}
                            className='tw-cursor-pointer !tw-rounded !tw-py-6'
                            variant='teal'
                          >
                            <div className='tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-8'>
                              <div className='tw-col-span-7 xl:tw-col-span-8'>
                                <div className='tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>{row.name}</div>
                                <div className='tw-justify-between sm:tw-flex'>
                                  <div className='tw-mt-2 tw-font-sans tw-text-sm tw-font-medium tw-text-black sm:tw-mt-5'>
                                    ${row?.price} {row.currency?.toUpperCase()} / {row?.interval}
                                  </div>
                                  <div className='tw-grid tw-grid-flow-col tw-gap-6'>
                                    <div className='tw-mt-3.5 sm:tw-mt-5'>
                                      <Button
                                        font='medium'
                                        onClick={() => {}}
                                        size='large'
                                        variant='transparent'
                                        className={clsx('!tw-py-0 !tw-px-0 tw-font-sans !tw-text-xs tw-font-medium', {
                                          '!tw-text-teal-600': isIndexPlan === i,
                                        })}
                                      >
                                        See inclusions
                                      </Button>
                                    </div>
                                    <div className='tw-mt-3.5 sm:tw-mt-5'>
                                      <Button
                                        font='medium'
                                        onClick={() => {}}
                                        size='large'
                                        variant='transparent'
                                        className={clsx('!tw-py-0 !tw-px-0 tw-font-sans !tw-text-xs tw-font-medium', {
                                          '!tw-text-teal-600': isIndexPlan === i,
                                        })}
                                      >
                                        Select plan
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='xl:tw-col-span-0 tw-col-span-1 tw-flex tw-items-center	tw-justify-end xl:tw-hidden'>
                                <FiChevronRight className='tw-h-5 tw-w-5 !tw-text-gray-700 tw-duration-100' />
                              </div>
                            </div>
                          </Box>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div
              className={clsx('xl:tw-w-1/2', {
                'xl:tw-border-0 xl:tw-border-l xl:tw-border-solid xl:tw-border-gray-200': isActivePlan,
              })}
            >
              <div className='tw-mx-auto tw-max-w-md xl:tw-w-[350px]'>
                {isActivePlan && planPrices.length > 0 && !isLoading && (
                  <Elements stripe={stripePromise}>
                    <PlanDetails
                      data={selectedPlanData}
                      onClick={onClickPaymentSuccess}
                      company={currentCompany}
                      taxData={taxRates}
                      planData={inclusionData}
                      cancelPurchase={handleCancelPurchase}
                    />
                  </Elements>
                )}
              </div>
            </div>
          </>
        )}
        {isActivePaymentSuccess && currentSubscription !== null && (
          <PaymentSuccess
            company={currentCompany}
            subscription={currentSubscription}
            product={selectedPlanData}
            inclusions={inclusionData}
            invoices={invoices}
            onSelectPlan={() => {
              setActivePaymentSuccess(false);
              setCurrentSubscription(null);
              setProductPrices(planData, false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default BillingInvoices;
