import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const items = css`
  overflow: auto;
  max-height: 241px;
`;

export const duration = css`
  ${fonts.regular}
  color: ${colors.lightGrey};
  line-height: 1;
  font-size: 12px;
`;

export const value = css`
  padding: 7px 15px 8px 20px;
  background: ${colors.agendaGrey};
  border-radius: 2px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
