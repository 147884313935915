import React, { useState, useMemo, useContext, useEffect } from 'react';
import Switch from 'components/v2/shared/Switch';
import Label from 'components/v2/shared/Label';
import { FaLock } from 'react-icons/fa';
import ImageUpload from 'components/v2/shared/ImageUpload';
import Heading from 'components/v2/shared/Heading';
import Button from 'components/v2/shared/Button';
import TextInput from 'components/v2/shared/TextInput';
import Modal from 'components/v2/shared/Modal';
import DeleteCompany from './DeleteCompany';
import { useDeleteCompanyLogoMutation } from 'data/v2/mutations/DeleteCompanyLogo';
import { wrapMutation } from 'utils/v2/gql';
import { useUpdateCompanyMutation } from 'data/v2/mutations/UpdateCompanyMutation';
import { useSetCompanyAsPrimaryMutation } from 'data/v2/mutations/SetCompanyAsPrimaryMutation';
import { useRemoveCompanyAsPrimaryMutation } from 'data/v2/mutations/RemoveCompanyAsPrimaryMutation';
import { useCurrentCompanyQuery } from 'data/v2/queries/CurrentCompanyQuery';
import Toast, { Severity } from '../shared/Toast/Toast';
import { ToastContext } from 'contexts/v2/ToastContext';
import Spinner from '../shared/Spinner/Spinner';

const CompanyDetail = () => {
  const [isDeleteCompany, setDeleteCompany] = useState(false);
  const [companyFormData, setCompanyFormData] = useState({
    slug: '',
    name: '',
    crn: '',
    taxId: '',
    address1: '',
    address2: '',
    memberContactCount: 1,
    website: '',
    isPrimary: false,
    companyLogo: '',
  });
  const [deleteCompanyLogo] = useDeleteCompanyLogoMutation();
  const { toastInfo, setToastInfo } = useContext(ToastContext);
  const { data, refetch, loading } = useCurrentCompanyQuery();
  const [primaryInitState, setPrimaryInitState] = useState(data?.currentCompany?.primaryForCurrentUser);

  useEffect(() => {
    if (data?.currentCompany) {
      let currentCompany = data.currentCompany;
      setCompanyFormData({
        slug: currentCompany?.slug || '',
        name: currentCompany?.name || '',
        crn: currentCompany?.crn || '',
        taxId: currentCompany?.taxId || '',
        address1: currentCompany?.address?.address1 || '',
        address2: currentCompany?.address?.address2 || '',
        memberContactCount: currentCompany?.memberContactCount || 1,
        website: currentCompany?.website || '',
        isPrimary: currentCompany?.primaryForCurrentUser,
        companyLogo: null,
      });
    }
  }, [data]);

  const onClose = () => {
    setDeleteCompany(false);
  };

  const handleOnSwitchChange = () => {
    setCompanyFormData((prev) => ({ ...prev, ['isPrimary']: !companyFormData.isPrimary }));
  };

  const handleOnTextChange = (e) => {
    const { name, value } = e.target;
    setCompanyFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogoUpload = (attachment) => {
    setCompanyFormData((prev) => ({ ...prev, ['companyLogo']: attachment.signed_id }));
  };

  const handleLogoDelete = () => {
    wrapMutation(
      deleteCompanyLogo,
      {
        variables: {
          input: { companyId: data?.currentCompany?.id },
        },
      },
      'deleteCompanyLogo'
    ).then((data) => {
      setCompanyFormData((prev) => ({
        ...prev,
        companyLogo: data?.company.logoUrl,
      }));
    });
  };

  const companyDate = useMemo(() => {
    const dateString = Date.parse(data?.currentCompany?.createdAt);
    const dateObject = new Date(dateString);
    const date = dateObject.getDate() < 9 ? `0${dateObject.getDate()}` : `${dateObject.getDate()}`;
    const month = dateObject.toLocaleDateString('default', { month: 'short' });
    const year = dateObject.getFullYear();

    return `${date} ${month}, ${year}`;
  }, [data]);

  const [updateCompany] = useUpdateCompanyMutation();
  const [setCompanyAsPrimary] = useSetCompanyAsPrimaryMutation();
  const [removeCompanyAsPrimary] = useRemoveCompanyAsPrimaryMutation();
  const handleSubmit = () => {
    wrapMutation(
      updateCompany,
      {
        variables: {
          input: {
            companyId: data?.currentCompany?.id,
            attributes: companyFormData,
          },
        },
      },
      'updateCompany'
    )
      .then(() => {
        setToastInfo({
          severity: Severity.Success,
          status: 'Success',
          message: `Company details has been successfully updated.`,
        });
      })
      .catch((err) => {
        setToastInfo({
          severity: Severity.Success,
          status: 'Error',
          message: `An error has occured.`,
        });
      });

    if (companyFormData.isPrimary !== primaryInitState) {
      if (companyFormData.isPrimary === true) {
        wrapMutation(
          setCompanyAsPrimary,
          {
            variables: {
              input: {
                userId: data?.currentUser?.id,
                companyId: data?.currentCompany?.id,
              },
            },
          },
          'setCompanyAsPrimary'
        )
          .then(() => {
            refetch();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        wrapMutation(
          removeCompanyAsPrimary,
          {
            variables: {
              input: {
                userId: data?.currentUser?.id,
              },
            },
          },
          'removeCompanyAsPrimary'
        )
          .then(() => {
            refetch();
          })
          .catch((err) => {
            setToastInfo({
              severity: Severity.Success,
              status: 'Error',
              message: `Error setting primary company.`,
            });
            console.log(err);
          });
      }
    }
  };

  return (
    <div className='tw-py-6 sm:tw-px-[55px] md:tw-py-9 md:tw-px-[102px]'>
      <Toast severity={toastInfo.severity} status={toastInfo.status} message={toastInfo.message} />
      {loading ? (
        <div className='tw-flex tw-w-full tw-justify-center'>
          <Spinner className='!tw-static' />
        </div>
      ) : (
        <div className='tw-w-full md:tw-w-[350px]'>
          <div className='tw-items-center tw-justify-between sm:tw-flex'>
            <div className='tw-flex tw-w-full tw-items-center tw-justify-between'>
              <Heading
                text='Company details'
                className='!tw-mb-0 tw-font-sans tw-text-xl tw-text-[22px] tw-font-bold sm:tw-text-[26px]'
              />
              <a
                onClick={() => setDeleteCompany(true)}
                className='tw-cursor-pointer tw-font-semibold tw-text-red-600 hover:tw-text-red-600'
              >
                Delete
              </a>
              <Modal isOpen={isDeleteCompany} onClose={onClose} size='xl'>
                <DeleteCompany company={data?.currentCompany} />
              </Modal>
            </div>
          </div>
          {companyDate && (
            <div className='text-gray-700 tw-pt-[7px] tw-font-sans tw-text-xs tw-font-medium'>
              Company created on {companyDate}
            </div>
          )}
          <div className='tw-w-full md:tw-w-[350px]'>
            <div className='tw-mt-7'>
              <Label text='Details' className='!tw-mb-0 tw-font-sans !tw-text-[19px] tw-font-semibold' />
              <div className='tw-mt-3 tw-flex tw-h-[65px] tw-justify-between tw-rounded-[4px] tw-border-2 tw-bg-gray-100 tw-px-5'>
                <div className='rounded tw-flex tw-flex-col tw-justify-center tw-font-sans tw-text-sm tw-font-medium tw-text-black'>
                  <span className='tw-text-[13px]'>Make primary company</span>
                </div>
                <div className='tw-flex tw-items-center tw-justify-end'>
                  <Switch
                    variant={companyFormData.isPrimary ? 'teal-bg' : 'gray'}
                    onChange={handleOnSwitchChange}
                    isChecked={companyFormData.isPrimary}
                  />
                </div>
              </div>
            </div>
            <div className='tw-mt-6'>
              <div className='tw-flex tw-justify-between'>
                <Label
                  text='Name of board'
                  className='!tw-mb-3 tw-font-sans tw-text-xs tw-font-semibold !tw-text-[#95969F]'
                />
                <FaLock className='tw-mb-2.5 tw-text-[#95969F]' />
              </div>
              <TextInput name='slug' className='tw-text-sm' value={companyFormData.slug} isDisabled size='large' />
            </div>
            <div className='tw-mt-8 tw-flex'>
              <ImageUpload
                text='Logo'
                variant='company'
                onUpload={handleLogoUpload}
                onDelete={handleLogoDelete}
                previewUrl={data.currentCompany.logoUrl}
              />
            </div>
            <div className='tw-mt-16 tw-font-sans tw-text-base tw-font-bold tw-text-black'>Registered details</div>
            <div className='tw-mt-8'>
              <div className='tw-flex tw-justify-between tw-text-[#95969F]'>
                <Label
                  text='Name of company'
                  className='!tw-mb-3 tw-font-sans tw-text-xs tw-font-semibold !tw-text-[#95969F]'
                />
                <FaLock className='tw-mb-2.5 tw-text-[#95969F]' />
              </div>
              <TextInput name='name' className='tw-text-sm' value={companyFormData.name} isDisabled size='large' />
            </div>
            <div className='tw-mt-7'>
              <div className='tw-flex tw-justify-between tw-text-[#95969F]'>
                <Label
                  text='Registered company number'
                  className='!tw-mb-3 tw-font-sans tw-text-xs tw-font-semibold !tw-text-[#95969F]'
                />
                <FaLock className='tw-mb-2.5 tw-text-[#95969F]' />
              </div>
              <TextInput name='crn' className='tw-text-sm' value={companyFormData.crn} isDisabled size='large' />
            </div>
            <div className='tw-mt-7'>
              <TextInput
                label='Tax identification number'
                name='taxId'
                onChange={handleOnTextChange}
                value={companyFormData.taxId}
                size='large'
              />
            </div>
            <div className='tw-mt-11 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>
              Registered Company Address
            </div>
            <div className='tw-mt-8'>
              <TextInput
                label='Address Line 1'
                name='address1'
                onChange={handleOnTextChange}
                value={companyFormData.address1}
                size='large'
              />
            </div>
            <div className='tw-mt-7'>
              <TextInput
                label='Address Line 2'
                name='address2'
                onChange={handleOnTextChange}
                value={companyFormData.address2}
                size='large'
              />
            </div>
            <div className='tw-mt-11 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Website</div>
            <div className='tw-mt-5'>
              <TextInput
                label='URL'
                name='website'
                onChange={handleOnTextChange}
                value={companyFormData.website}
                size='large'
              />
            </div>
            <div className='tw-my-12'>
              <Button
                className='tw-text-sm'
                font='semibold'
                isFullWidth
                size='large'
                variant='teal'
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyDetail;
