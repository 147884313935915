import { gql, useMutation } from '@apollo/client';
import { VOTE_FRAGMENT } from './../fragments/VoteFragment';

const VALIDATE_RESOLUTIONS = gql`
  mutation validateResolutions($input: ValidateResolutionsInput!) {
    validateResolutions(input: $input) {
      vote {
        ...VoteFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${VOTE_FRAGMENT}
`;

const useValidateResolutionsMutation = (options) => useMutation(VALIDATE_RESOLUTIONS, options);

export { useValidateResolutionsMutation };
