/** @jsx jsx */

import React, { memo, useRef, useState, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import * as styles from './Expandable.styles';

const Expandable = memo(({ isHighlighted, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [textHeight, setTextHeight] = useState(null);

  const handleExpandClick = () => {
    setIsCollapsed(false);
    ref.current.scrollTo({ behavior: 'smooth', bottom: textHeight });
  };

  const handleCollapseClick = () => {
    setIsCollapsed(true);
    ref.current.scrollTo({ behavior: 'smooth', top: textHeight });
  };

  const measureHeight = useCallback((node) => {
    if (node) {
      ref.current = node;
      setTextHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const ref = useRef();
  const maxHeight = 250;
  const isCollapsible = textHeight >= maxHeight;

  const expandButton = (
    <div css={styles.button} onClick={handleExpandClick}>
      <span>
        <FormattedMessage id='Expandable.expand' />
      </span>
      <i className='fa fa-chevron-down fw-300 fs-10 m-l-5' />
    </div>
  );

  const collapseButton = (
    <div css={styles.button} onClick={handleCollapseClick}>
      <span>
        <FormattedMessage id='Expandable.collapse' />
      </span>
      <i className='fa fa-chevron-up fw-300 fs-10 m-l-5' />
    </div>
  );

  const renderButton = () => {
    if (isCollapsible) {
      return isCollapsed ? expandButton : collapseButton;
    }
  };

  const contentStyles = [
    styles.content.base,
    isCollapsible && isCollapsed && styles.content.collapsed,
    isCollapsible && isCollapsed && isHighlighted && styles.content.highlighted,
  ];

  return (
    <div css={styles.wrapper}>
      <div css={contentStyles} ref={measureHeight}>
        {children}
      </div>

      {renderButton()}
    </div>
  );
});

export { Expandable };
