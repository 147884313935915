import { createStore } from './createStore';

import { trackPromise } from 'react-promise-tracker';
import { getAttributes } from 'helpers/getAttributes';

import axios from 'axios';

const companyId = getCompanySlug();

const initialState = {
  ooe: {},
};

const actionTypes = {
  SET: 'ooe.set',
  UPDATE: 'ooe.update',
};

const api = {
  updateOoE: (minuteId, params) => {
    const apiURL = `/api/${companyId}/v1/minutes/${minuteId}/ooe.json`;
    const payload = { order_of_event: params };

    const promise = axios
      .put(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },
};

const useOoEActions = () => {
  const dispatch = useOoEDispatch();

  const setOoE = (payload) => {
    dispatch({ type: actionTypes.SET, payload: payload });
  };

  const editOoE = (payload) => {
    dispatch({ type: actionTypes.UPDATE, payload: payload });
  };

  const updateOoE = (minuteId, payload) => {
    return api.updateOoE(minuteId, payload).then((ooe) => {
      return dispatch({ type: actionTypes.UPDATE, payload: ooe });
    });
  };

  return {
    setOoE,
    editOoE,
    updateOoE,
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET:
      return { ooe: payload };

    case actionTypes.UPDATE:
      return { ooe: { ...state.ooe, ...payload } };
  }
};

const [OoEProvider, useOoEStore, useOoEDispatch] = createStore(reducer, initialState);

export { OoEProvider, useOoEStore, useOoEActions };
