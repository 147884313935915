import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as FolderIcon } from 'images/icons/documents/openedFolder.svg';

export const FolderRowInMobile = ({
  directories,
  onDirectoryClick,
  isSelectable,
  showDocumentCount,
  showCreatedAt,
  showPath,
  createdAtFormat,
}) => {
  return (
    <div className='directories-explorer'>
      {directories.map((directory) => {
        const disabled = !isSelectable(directory);
        const onClickHandler = () => onDirectoryClick(directory.id);
        const path = showPath && directory.breadcrumbs.map((node) => node.name).join(' > ');

        return (
          <Row key={directory.id} onClick={onClickHandler}>
            <Col>
              <div className={`dir-card directory-row${disabled ? ' disabled' : ''}`}>
                <div className='directory-container dir-link'>
                  <div className='d-flex align-items-center name-block'>
                    <div className='p-r-10'>
                      <FolderIcon />
                    </div>

                    <div className='lh-1 w-100'>
                      <h5 className='card-title m-b-0 text-truncate'>{directory.name}</h5>

                      {showPath && <span className='text-light-grey fs-10 card-title m-b-0'>{path}</span>}
                    </div>
                  </div>

                  {showDocumentCount && (
                    <span className='card-title m-b-0'>
                      <FormattedMessage id='FolderRowInMobile.files' values={{ count: directory.documentsCount }} />
                    </span>
                  )}

                  {showCreatedAt && (
                    <span className='card-title m-b-0'>
                      {moment(directory.createdAt).parseZone().format(createdAtFormat)}
                    </span>
                  )}
                </div>
                <div className='directory-row-overlay'>&nbsp;</div>
              </div>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};
