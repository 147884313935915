import { useToggleFavouriteDocumentMutation } from './../data/mutations/ToggleFavouriteDocumentMutation';
import { useApolloCacheHelpers } from './../hooks/useApolloCacheHelpers';

const useToggleFavouriteDocument = ({ id }) => {
  const { addDocumentToFavouritesCache } = useApolloCacheHelpers();

  const update = (
    cache,
    {
      data: {
        toggleFavouriteDocument: { document },
      },
    }
  ) => {
    addDocumentToFavouritesCache(document);
  };

  const [mutate, { loading: isToggling }] = useToggleFavouriteDocumentMutation({ update });

  const toggleFavouriteDocument = () => {
    mutate({ variables: { input: { documentId: id } } });
  };

  return { isToggling, toggleFavouriteDocument };
};

export { useToggleFavouriteDocument };
