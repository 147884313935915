/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';

import { HighlightTag } from 'components/shared/HighlightTag';
import { Dropdown } from 'components/shared/Dropdown';
import { DropdownOption } from 'components/shared/DropdownOption';

import { useRegExpFormatter } from 'hooks/useRegExpFormatter';

import * as styles from './MeetingPicker.styles';

const MeetingPicker = memo(
  ({ inputLabel, inputValue, inputError, dropdownWidth, options, value, onSelect, isDisabled }) => {
    let [isOpen, setIsOpen] = useState(false);
    let [searchTerm, setSearchTerm] = useState('');

    const intl = useIntl();

    const { formattedRegExp } = useRegExpFormatter(searchTerm);

    const handleInputChange = (e) => {
      setSearchTerm(e.target.value || '');
    };

    const regex = new RegExp(formattedRegExp, 'gi');

    const pickerOptions = options.filter((option) => option.title.match(regex));

    const isSearching = searchTerm.length > 0;
    const hasOptions = pickerOptions.length > 0;

    const handleFocus = () => setIsOpen(true);
    const handleBlur = () => setIsOpen(false);

    const checkmark = (
      <div className='checkmark-circle'>
        <div className='checkmark' />
      </div>
    );

    const renderOption = (meeting) => {
      const key = `meeting-picker-${meeting.id}`;
      const onClick = onSelect.bind(this, meeting, handleBlur);
      const isSelected = meeting.id === value?.id;

      return (
        <DropdownOption key={key} onClick={onClick}>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <div className='w-100 text-truncate'>
              <Highlighter
                textToHighlight={meeting.title}
                highlightTag={HighlightTag}
                searchWords={[formattedRegExp]}
              />
            </div>

            {isSelected && checkmark}
          </div>
        </DropdownOption>
      );
    };

    const noResults = <DropdownOption noResults />;
    const dropdownContent = pickerOptions.map(renderOption);
    const dropdownBody = hasOptions ? dropdownContent : noResults;

    return (
      <>
        <Dropdown
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputPlaceholder={intl.formatMessage({ id: 'MeetingPicker.select' })}
          inputIcon={<i className='fa fa-chevron-down fs-14 fw-300' />}
          inputIconPosition='right'
          inputError={inputError}
          inputValue={isOpen ? searchTerm : value?.title || ''}
          isOpen={isOpen}
          isDisabled={isDisabled}
          inputLabel={inputLabel}
          dropdownWidth={dropdownWidth}
          onInputChange={handleInputChange}
          rotatableIcon
        >
          <div>
            <div css={styles.items} className='cursor-pointer'>
              {dropdownBody}
            </div>
          </div>
        </Dropdown>
      </>
    );
  }
);

export { MeetingPicker };
