import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const Sizes = {
  Regular: 'regular',
  Large: 'large',
};

const Label = ({ text, id, isInvalid, className, size = Sizes.Regular, isRequired }) => (
  <label
    htmlFor={id}
    className={clsx(
      'tw-mb-2 tw-block ',
      {
        'tw-text-black': !isInvalid,
        'tw-text-red-400': isInvalid,
        'tw-text-sm tw-font-bold': size === Sizes.Regular,
        'tw-font-sans tw-text-xs tw-font-semibold': size === Sizes.Large,
      },
      className
    )}
  >
    {text}
  </label>
);

Label.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  isInvalid: PropTypes.bool,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default Label;
