/** @jsx jsx */

import React, { memo, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Spinner } from 'components/shared/Spinner';
import { MeetingDetails } from 'components/meetings/MeetingDetails';
import { Editor } from './../Editor';
import { AlertPopup } from 'components/shared/AlertPopup';
import { StatusBadge } from 'components/shared/StatusBadge';

import { useActionCable } from 'hooks/useActionCable';
import { useFlashStore, useFlashActions } from 'store/flashStoreContext';
import { useMeetingActions, useMeetingStore } from 'store/meetingStoreContext';
import { useMinutesActions, useMinutesStore } from 'store/minutesStoreContext';
import { useOoEStore } from 'store/ooeStoreContext';
import { useReviewsStore } from 'store/reviewsStoreContext';

import * as styles from './ApprovedMinutes.styles';

const ApprovedMinutes = memo(({ meetingId, minuteId, contactTimezone, contactTimezoneOffset }) => {
  const { meeting, loaded } = useMeetingStore();
  const { loadMeeting } = useMeetingActions();
  const { flash } = useFlashStore();
  const { setFlash, discardFlash } = useFlashActions();

  const intl = useIntl();

  useEffect(() => {
    loadMeeting(meetingId).catch(setFlash);
  }, []);

  const { minutes } = useMinutesStore();
  const { ooe } = useOoEStore();
  const { reviews } = useReviewsStore();
  const { setMinutesContext } = useMinutesActions();

  useActionCable('MinutesChannel', { minute_id: minuteId }, (response) => setMinutesContext(minuteId, response));

  if (!loaded) {
    return <Spinner show />;
  }

  return (
    <Container fluid='lg'>
      <Row>
        <Col>
          <Spinner />
          <StatusBadge status={minutes.status} text={intl.formatMessage({ id: 'ApprovedMinutes.minutes' })} />

          <AlertPopup
            show={!!flash}
            popupType='failure'
            headerText={intl.formatMessage({ id: 'ApprovedMinutes.failure' })}
            onClose={discardFlash}
          >
            <div className='text-black fs-14'>{flash}</div>
          </AlertPopup>

          <MeetingDetails
            contactTimezone={contactTimezone}
            contactTimezoneOffset={contactTimezoneOffset}
            isMinuteContext
          />

          <div className='m-t-20'>
            <Row noGutters>
              <Col md={12} css={styles.outline}>
                <Editor value={ooe?.description} isReadOnly />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
});

export { ApprovedMinutes };
