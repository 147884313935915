import React from 'react';

const TwoLinesCell = () => {
  return (
    <div className='placeholder-table-cell'>
      <div className='placeholder-text-stub large grey-darker w-percent-30' />
      <div className='placeholder-text-stub regular grey-darker w-percent-20' />
    </div>
  );
};

export { TwoLinesCell };
