import React, { memo, useState, useEffect } from 'react';
import { Spinner } from './../Spinner';

const DelayedSpinner = memo(({ delay }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(timer);
  });

  return show && <Spinner show />;
});

export { DelayedSpinner };
