import { useState, useEffect } from 'react';
import { useVoteQuery } from './../data/queries/VoteQuery';

const useVoteEditor = ({ voteId }) => {
  const [updatableData, setUpdatableData] = useState({
    isLoading: false,
    vote: null,
  });

  const updateVoteData = (updates) => setUpdatableData((state) => ({ ...state, ...updates }));

  const { loading: isVoteLoading, data: voteData } = useVoteQuery({
    variables: { id: voteId },
    skip: !voteId,
  });

  useEffect(() => setIsLoading(), [isVoteLoading]);
  useEffect(() => setVoteData(), [voteData]);

  const setIsLoading = () => {
    if (!isVoteLoading) return;

    updateVoteData({ isLoading: true });
  };

  const setVoteData = () => {
    if (!voteData) return;

    const { vote } = voteData.currentCompany;

    updateVoteData({ isLoading: false, vote });
  };

  return {
    ...updatableData,
  };
};

export { useVoteEditor };
