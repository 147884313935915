import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Label from '../Label';
import InputError from '../InputError';

export const Sizes = {
  Large: 'large',
  Regular: 'regular',
};

const TextInput = forwardRef(
  (
    {
      id,
      name,
      value,
      placeholder,
      label,
      isRequired,
      isDisabled,
      isReadOnly,
      errorMessage,
      isInvalid,
      onChange,
      className,
      size = Sizes.Regular,
      ...props
    },
    ref
  ) => {
    return (
      <div className='tw-w-auto'>
        {!!label && (
          <Label id={id} size={size} text={label} isInvalid={errorMessage || isInvalid} isRequired={isRequired} />
        )}
        <div>
          <input
            ref={ref}
            id={id}
            name={name}
            type='text'
            autoComplete='none'
            value={value}
            placeholder={placeholder}
            required={isRequired}
            disabled={isDisabled}
            readOnly={isReadOnly}
            onChange={onChange}
            {...props}
            className={clsx(
              'tw-box-border tw-w-full tw-rounded-sm tw-border tw-border-solid tw-px-5 tw-font-sans tw-text-sm tw-outline-none placeholder:tw-text-gray-500',
              {
                'tw-py-4 tw-font-medium tw-text-black': size === Sizes.Large,
                'tw-h-11': size === Sizes.Regular,
                'tw-bg-gray-100': isReadOnly,
              },
              {
                'tw-border-gray-700 focus:tw-border-teal-500': size === Sizes.Large && !errorMessage && !isInvalid,
                'tw-border-gray-500 focus:tw-border-teal-500': size === Sizes.Regular && !errorMessage && !isInvalid,
                'tw-cursor-not-allowed tw-border-gray-100 tw-border-opacity-50 tw-bg-transparent !tw-text-[#95969F]':
                  isDisabled,
                'tw-border-red-400 placeholder:tw-text-red-400': errorMessage || isInvalid,
              },
              className
            )}
          />
          <InputError message={errorMessage} className='tw-my-3' />
        </div>
      </div>
    );
  }
);

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  errorMessage: PropTypes.string,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Sizes)),
};

export default TextInput;
