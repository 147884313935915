import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Link = ({ href, className, children, ...props }) => (
  <a
    {...props}
    href={href}
    className={clsx(
      'tw-box-border tw-cursor-pointer tw-no-underline',
      'tw-text-sm tw-font-medium',
      'tw-flex tw-items-center',
      className
    )}
  >
    {children}
  </a>
);

Link.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  props: PropTypes.object,
};

export default Link;
