import React from 'react';
import Box from 'components/v2/shared/Box';
import Button from 'components/v2/shared/Button';

const OrderOverview = () => {
  return (
    <>
      <div className='tw-mt-8'>
        <h6 className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Order overview</h6>
      </div>
      <div className=' tw-mt-4'>
        <Box onClick={() => {}} onRemove={function noRefCheck() {}} className='!tw-py-2'>
          <div className='tw-flex tw-w-full tw-py-2'>
            <div className=''>
              <div className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Plan</div>
            </div>
            <div className='tw-ml-auto tw-text-right'>
              <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>Education and Associations</div>
            </div>
          </div>
        </Box>
      </div>

      <div className='tw-mt-1'>
        <Box onClick={() => {}} onRemove={function noRefCheck() {}} className='!tw-py-2'>
          <div className='tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-4 tw-py-2'>
            <div className='tw-col-span-3'>
              <div className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Due today</div>
              <p className='tw-mt-3.5 tw-font-sans tw-text-xs tw-font-medium tw-text-gray-700'>
                First payment will be taken immediately, then yearly on the 5th of July. Charged in USD.
              </p>
            </div>
            <div className='tw-col-span-1 tw-text-right'>
              <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>$30.00</div>
            </div>
          </div>
        </Box>
      </div>
      <div className='tw-mt-1'>
        <Box className='!tw-py-2'>
          <div className='tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-4 tw-py-2'>
            <div className='tw-col-span-3 tw-flex tw-items-center'>
              <div className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Taxes (UK 20%)</div>
              <div className='tw-px-2 tw-text-sm tw-font-semibold'>
                <Button
                  font='medium'
                  onClick={() => {}}
                  size='small'
                  variant='transparent'
                  className='tw-font-sans tw-text-xs !tw-font-medium	!tw-text-teal-500'
                >
                  Swap location
                </Button>
              </div>
            </div>
            <div className='tw-col-span-1 tw-text-right'>
              <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>$6.00</div>
            </div>
          </div>
        </Box>
      </div>

      <div className='tw-mt-1'>
        <Box onClick={() => {}} className='!tw-py-2'>
          <div className='tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-4 tw-py-2'>
            <div className='tw-col-span-3 tw-flex tw-items-center'>
              <div className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Coupon discount</div>
              <div className='tw-px-[2rem] tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>10%</div>
            </div>
            <div className='tw-col-span-1 tw-text-right'>
              <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>$12.60</div>
            </div>
          </div>
        </Box>
      </div>
      <div className='tw-mt-1'>
        <Box onClick={() => {}} className='!tw-py-2'>
          <div className='tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-4 tw-py-2'>
            <div className='tw-col-span-3'>
              <div className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Total</div>
            </div>
            <div className='tw-col-span-1 tw-text-right'>
              <div className='tw-text-sm tw-font-semibold'>$36.00</div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default OrderOverview;
