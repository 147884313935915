import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const Variants = {
  Teal: 'teal',
  Gray: 'gray',
};

const ChatMessage = ({ variant = Variants.Gray, message, author, className }) => (
  <div
    className={clsx(
      'tw-inline-flex tw-flex-col tw-justify-center',
      'tw-gap-y-2 tw-rounded-lg tw-text-sm',
      'tw-box-border tw-px-4 tw-py-3',
      className,
      {
        'tw-bg-teal-500 tw-text-white': variant === Variants.Teal,
        'tw-bg-gray-100 tw-text-black': variant === Variants.Gray,
      }
    )}
  >
    <div className='tw-font-semibold empty:tw-hidden'>{author}</div>
    <div className='tw-font-medium'>{message}</div>
  </div>
);

ChatMessage.propTypes = {
  variant: PropTypes.oneOf(Object.values(Variants)),
  message: PropTypes.string.isRequired,
  author: PropTypes.string,
  className: PropTypes.string,
};

export default ChatMessage;
