import React, { useContext, useState, useEffect } from 'react';
import Switch from 'components/v2/shared/Switch';
import ImageUpload from 'components/v2/shared/ImageUpload';
import Alert from 'components/v2/shared/Alert';
import TextInput from 'components/v2/shared/TextInput';
import Button from 'components/v2/shared/Button';
import Label from 'components/v2/shared/Label';
import { FiCheckCircle, FiChevronLeft } from 'react-icons/fi';
import clsx from 'clsx';
import Heading from 'components/v2/shared/Heading';
import InviteUsers from './InviteUsers';

import { wrapMutation } from 'utils/v2/gql';
import { useCreateCompanyMutation } from 'data/v2/mutations/CreateCompanyMutation';
import { useCurrentCompanyQuery } from 'data/v2/queries/CurrentCompanyQuery';
import Toast, { Severity } from '../../shared/Toast/Toast';
import { ToastContext } from '../../../../contexts/v2/ToastContext';
import { capitalize } from '../../../../utils/capitalize';

const AddCompany = ({ onClick, refetch }) => {
  const { toastInfo, setToastInfo } = useContext(ToastContext);
  const [result, setResult] = useState(null);
  const { data, loading } = useCurrentCompanyQuery({
    fetchPolicy: 'no-cache',
  });
  const [isActive, setIsActive] = useState({
    use_company_name: false,
  });
  const [isUser, setIsUser] = useState(false);
  const [companyData, setCompanyData] = useState({
    name: '',
    slug: '',
    crn: '',
    taxId: '',
    address1: '',
    address2: '',
    website: '',
    isPrimary: true,
    companyLogo: null,
  });

  const [roles, setRoles] = useState([]);
  const accessRoles = data?.currentCompany?.accessRoles;
  useEffect(() => {
    if (!loading && accessRoles) {
      const roles = accessRoles.map((role) => {
        return { ...role, label: role.name, value: role.id };
      });
      setRoles(roles);
    }
  }, [accessRoles]);

  const [createCompany] = useCreateCompanyMutation();
  const handleSubmit = (event) => {
    wrapMutation(
      createCompany,
      {
        variables: {
          input: {
            attributes: companyData,
          },
        },
      },
      'createCompany'
    )
      .then((result) => {
        setResult(result);
        refetch();
        onContinue(event);
      })
      .catch((err) => {
        console.log(err);
        const message = `${capitalize(Object.keys(err).shift())} ${Object.values(err).shift()}`;
        setToastInfo({
          severity: Severity.Error,
          status: 'Error',
          message,
        });
      });
  };

  const onPrimarySwitchChangeHandle = () => {
    setCompanyData((prev) => ({ ...prev, ['isPrimary']: !companyData.isPrimary }));
  };

  const onSwitchChangeHandle = (name) => {
    setCompanyData((prev) => ({ ...prev, slug: !isActive.use_company_name ? companyData.name : '' }));
    setIsActive((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const onLogoUpload = (attachment) => {
    setCompanyData((prev) => ({ ...prev, ['companyLogo']: attachment.signed_id }));
  };
  const onTextChangeHandle = (event) => {
    const { name, value } = event.target;
    setCompanyData((prev) => ({ ...prev, [name]: value }));
  };
  const onContinue = (event) => {
    if (event == 'invite-users') {
      setIsUser(true);
    }
  };
  const onClickPreviousPage = (event) => {
    setIsUser(event);
  };
  const onClickCompany = (event) => {
    onClick(event);
  };
  return (
    <>
      {!isUser && (
        <div className='tw-container tw-mx-auto tw-flex tw-max-w-full tw-justify-center tw-bg-white tw-py-6 tw-px-5 sm:tw-px-10'>
          <Toast severity={toastInfo.severity} status={toastInfo.status} message={toastInfo.message} />
          <div className='tw-relative tw-w-full tw-pb-10 sm:tw-w-[450px]'>
            <h5
              className='tw-flex tw-cursor-pointer tw-items-center tw-text-base !tw-font-extrabold'
              onClick={() => onClick('list')}
            >
              <FiChevronLeft
                size={20}
                className='tw-text-purple-700 sm:tw-absolute sm:-tw-left-6 sm:tw-top-0.5'
                color='#47126E'
              />
              <span className='tw-pl-1.5 tw-font-sans tw-text-sm !tw-font-semibold sm:tw-pt-1'>Companies</span>
            </h5>

            <Heading text='Create a new company' className='!tw-text-3xl' />
            <div
              className={clsx(
                'tw-mt-9 tw-grid tw-h-14 tw-grid-flow-col tw-grid-cols-10 !tw-rounded-sm tw-border-2 tw-px-5',
                {
                  'tw-bg-teal-200': companyData.isPrimary,
                  'tw-bg-gray-100': !companyData.isPrimary,
                }
              )}
            >
              <div className='tw-col-span-2 tw-flex tw-items-center'>
                <Switch
                  variant={companyData.isPrimary ? 'teal' : 'gray'}
                  onChange={onPrimarySwitchChangeHandle}
                  isChecked={companyData.isPrimary}
                />
              </div>
              <div
                className={clsx('tw-col-span-6 tw-flex tw-items-center tw-font-sans tw-text-sm tw-font-medium', {
                  'tw-text-teal-500': companyData.isPrimary,
                  'tw-text-gray-700': !companyData.isPrimary,
                })}
              >
                Make primary company
              </div>
              <div className='tw-col-span-2 tw-flex tw-items-center tw-font-sans tw-text-xs tw-font-semibold tw-text-teal-500'>
                {companyData.isPrimary && (
                  <>
                    <FiCheckCircle className='tw-mr-1.5 tw-h-3.5 tw-w-3.5' />
                    Primary
                  </>
                )}
              </div>
            </div>
            <div className='tw-mt-7 tw-flex'>
              <div className='tw-mt-8 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Logo</div>
              <div className='tw-ml-8 sm:tw-ml-14'>
                <ImageUpload
                  text='Logo'
                  variant='company'
                  attachment={companyData.companyLogo}
                  onUpload={onLogoUpload}
                />
              </div>
            </div>
            <div className='tw-mt-11 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Registered details</div>
            <div className='tw-mt-10'>
              <Alert
                description='Company name, registered number and boardspace name cannot be changed after company is saved.'
                severity='warning'
                isDismissable={false}
                className='tw-font-sans'
              />
            </div>
            <div className='tw-mt-8'>
              <TextInput
                onChange={onTextChangeHandle}
                label='Name of company'
                name='name'
                value={companyData.name}
                size='large'
              />
            </div>
            <div className='tw-mt-7 tw-text-black'>
              <TextInput
                onChange={onTextChangeHandle}
                label='Registered company number'
                name='crn'
                value={companyData.crn}
                size='large'
              />
            </div>
            <div className='tw-mt-7'>
              <TextInput
                onChange={onTextChangeHandle}
                value={companyData.taxId}
                label='Tax identification number'
                name='taxId'
                size='large'
              />
            </div>
            <div className='tw-mt-11 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>
              Registered Company Address
            </div>
            <div className='tw-mt-8'>
              <TextInput
                onChange={onTextChangeHandle}
                value={companyData.address1}
                label='Address Line 1'
                name='address1'
                size='large'
              />
            </div>
            <div className='tw-mt-7'>
              <TextInput
                onChange={onTextChangeHandle}
                value={companyData.address2}
                label='Address Line 2'
                name='address2'
                size='large'
              />
            </div>
            <div className='tw-mt-11 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Website</div>
            <div className='tw-mt-5'>
              <TextInput
                onChange={onTextChangeHandle}
                value={companyData.website}
                label='URL'
                name='website'
                size='large'
              />
            </div>
            <div className='tw-mt-12 tw-justify-between sm:tw-flex'>
              <Label text='Name of board' className='!tw-mb-0 tw-font-sans tw-text-sm tw-font-semibold' />
              <div className='tw-flex'>
                <div className='tw-pr-5 tw-font-sans tw-text-sm tw-font-medium tw-text-black/[0.3]'>
                  Use company name
                </div>
                <Switch
                  variant={isActive.use_company_name ? 'teal' : 'gray'}
                  onChange={() => onSwitchChangeHandle('use_company_name')}
                  isChecked={isActive.use_company_name}
                  isGray={isActive.use_company_name}
                />
              </div>
            </div>
            <div className='tw-mt-5'>
              <TextInput onChange={onTextChangeHandle} name='slug' value={companyData.slug} size='large' />
            </div>
            <div className='tw-mt-11'>
              <Button
                font='semibold'
                isFullWidth
                size='large'
                variant='purple'
                onClick={() => handleSubmit('invite-users')}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* ------------------------Invite Contact-------------------------- */}
      {isUser && (
        <InviteUsers
          roles={roles}
          onClickPage={onClickPreviousPage}
          onClick={onClickCompany}
          company={result.company}
        />
      )}
    </>
  );
};
export default AddCompany;
