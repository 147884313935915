import { css } from '@emotion/core';
import { colors, fonts } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const date = css`
  ${fonts.semiBold}
  font-size: 14px;
  color: ${colors.black};
  line-height: 1;
  margin-bottom: 5px;
`;

export const description = css`
  ${fonts.regular}
  font-size: 14px;
  color: ${colors.mediumGrey};
  word-break: break-word;
  line-height: 1;
`;

export const author = css`
  ${description}
  margin-top: 5px;
`;

export const icon = css`
  flex-shrink: 0;
  margin-right: 17px;
`;

export const container = css`
  display: flex;
  align-items: center;
  padding-top: 6px;
  position: relative;

  &:not(:last-child):after {
    content: '';
    width: 1px;
    height: 100%;
    background: ${colors.lighterGrey};
    z-index: -1;
    position: absolute;
    left: 9px;
    top: 50%;
  }
`;

export const wrapper = css`
  display: flex;
  align-items: center;
`;

const background = HexToRgba(colors.grey, 50);

export const timeline = css`
  position: relative;
`;

export const line = css`
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  left: 10px;
  background: ${colors.lighterGrey};
`;

export const block = css`
  padding: 13px 26px;
  background: ${background};
  border-radius: 2px;
  width: 250px;
`;

export const arrow = css`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid ${background};
`;
