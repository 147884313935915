import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useCurrentContactQuery } from 'data/v2/queries/CurrentContactQuery';

const initialState = {
  currentContact: null,
};

const actionTypes = {
  SET_CURRENT_CONTACT: 'CurrentContactContext.setCurrentContact',
};

const CurrentContactContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_CURRENT_CONTACT:
      return { ...state, currentContact: payload.currentContact };
  }
};

export const CurrentContactContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { data } = useCurrentContactQuery();

  useEffect(() => {
    if (data) {
      dispatch({ type: actionTypes.SET_CURRENT_CONTACT, payload: data });
    }
  }, [data]);

  const { currentContact } = state;

  if (!currentContact) return null;

  return <CurrentContactContext.Provider value={{ currentContact }}>{children}</CurrentContactContext.Provider>;
};

CurrentContactContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useCurrentContactContext = () => useContext(CurrentContactContext);
