const getHeaders = () => {
  return {
    'X-CSRF-Token': $("[name='csrf-token']").attr('content'),
  };
};

const getAxiosHeaders = () => {
  return {
    headers: getHeaders(),
  };
};

export { getHeaders, getAxiosHeaders };
