import { gql } from '@apollo/client';
import { CONTACT_FRAGMENT } from './ContactFragment';
import { CONTACT_GROUP_FRAGMENT } from './ContactGroupFragment';

const CONTACT_MEETING_FRAGMENT = gql`
  fragment ContactMeetingFields on ContactMeeting {
    id

    contact {
      ...ContactFields
    }

    contactGroup {
      ...ContactGroupFields
    }
  }
  ${CONTACT_FRAGMENT}
  ${CONTACT_GROUP_FRAGMENT}
`;

export { CONTACT_MEETING_FRAGMENT };
