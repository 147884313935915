/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';

import Spinner from 'components/v2/shared/Spinner';
import Label from 'components/v2/shared/Label';
import RadioButton from 'components/v2/shared/RadioButton';
import TextAreaInput from 'components/v2/shared/TextAreaInput';
import TimePicker from 'components/v2/shared/TimePicker';
import DatePicker from 'components/v2/shared/DatePicker';
import ContactSelectInput from 'components/v2/shared/ContactSelectInput';
import TextInput from 'components/v2/shared/TextInput';
import Button, { Variants } from 'components/v2/shared/Button';
import SelectInput from 'components/v2/shared/SelectInput';

import { useGQLDataContext } from 'contexts/v2/GQLDataContext';
import { useCurrentContactContext } from 'contexts/v2/CurrentContactContext';
import { useContactsContext } from 'contexts/v2/ContactsContext';
import { useTimezonesContext } from 'contexts/v2/TimezonesContext';
import { useMeetingContext } from 'contexts/v2/MeetingContext';
import { getFieldData } from 'utils/v2/data';

import useNavigation from 'hooks/v2/useNavigation';
import { useMeetingFormStepContext } from 'contexts/v2/MeetingFormStepContext';

import * as styles from './MeetingDetailsStep.styles';

const MeetingDetailsStep = () => {
  const [errors, setErrors] = useState({});

  const { nextStep } = useMeetingFormStepContext();

  const navigate = useNavigation();

  const { loading } = useGQLDataContext();
  const { contacts, getContactByValue } = useContactsContext();
  const { timezones, getTimezoneByValue } = useTimezonesContext();
  const { updateEdits, edits, saveMeetingData } = useMeetingContext();
  const { currentContact } = useCurrentContactContext();

  const handleFieldChange = (fieldName) => (value) => {
    updateEdits(fieldName, getFieldData(value));
  };

  const handleRadioChange = (e) => {
    updateEdits('contactId', e.target.value || null);
  };

  const handleContactClear = () => updateEdits('contactId', '');

  const isMeetingForMyself = edits.contactId === currentContact.id || edits.contactId === '';

  const handleMeetingSave = (redirectToIndex) => async () => {
    setErrors({});

    try {
      await saveMeetingData();

      if (redirectToIndex) {
        navigate('meetingsIndex');
      } else {
        nextStep();
      }
    } catch (e) {
      setErrors(e);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Label text='Creator of meeting' />

      <Row noGutters className='mb-4'>
        <Col lg={3}>
          <RadioButton
            value={currentContact.id}
            isChecked={isMeetingForMyself}
            onChange={handleFieldChange('contactId')}
            name='creator'
            label='Myself'
          />
        </Col>

        <Col>
          <RadioButton
            isChecked={!isMeetingForMyself}
            onChange={handleRadioChange}
            name='creator'
            label='On behalf of someone else'
            value={''}
          />
        </Col>
      </Row>

      {!isMeetingForMyself && (
        <Row noGutters className='mb-4'>
          <Col>
            <ContactSelectInput
              errorMessage={errors.contactId}
              onChange={handleFieldChange('contactId')}
              label='Type name who you are creating a meeting for'
              options={contacts}
              placeholder='Enter contact name'
              value={getContactByValue(edits.contactId)}
              onClear={handleContactClear}
            />
          </Col>
        </Row>
      )}

      <Row noGutters className='mb-4'>
        <Col>
          <TextInput
            name='title'
            errorMessage={errors.title}
            value={edits.title}
            onChange={handleFieldChange('title')}
            label='Meeting name'
            placeholder='Enter meeting name'
          />
        </Col>
      </Row>

      <Row noGutters className='mb-4'>
        <Col>
          <TextInput
            name='type'
            errorMessage={errors.type}
            value={edits.type}
            onChange={handleFieldChange('type')}
            label='Meeting type'
            placeholder='Enter meeting type'
          />
        </Col>
      </Row>

      <Row noGutters className='mb-4'>
        <Col>
          <TextAreaInput
            errorMessage={errors.description}
            name='description'
            value={edits.description}
            onChange={handleFieldChange('description')}
            label='Description'
          />
        </Col>
      </Row>

      <Row noGutters className='mb-4'>
        <Col>
          <DatePicker
            errorMessage={errors.meetingDate}
            onChange={handleFieldChange('meetingDate')}
            value={edits.meetingDate}
            label='Date'
            placeholder='Select date'
          />
        </Col>
      </Row>

      <Row noGutters className='mb-4'>
        <Col lg={6}>
          <TimePicker
            errorMessage={errors.meetingStartTime}
            onChange={handleFieldChange('meetingStartTime')}
            value={edits.meetingStartTime}
            label='Start time'
          />
        </Col>

        <Col>
          <TimePicker
            errorMessage={errors.meetingEndTime}
            onChange={handleFieldChange('meetingEndTime')}
            value={edits.meetingEndTime}
            label='End time'
          />
        </Col>
      </Row>

      <Row noGutters className='mb-4'>
        <Col>
          <SelectInput
            onChange={handleFieldChange('timezone')}
            options={timezones}
            label='Timezone'
            value={getTimezoneByValue(edits.timezone)}
            placeholder='Select timezone'
            errorMessage={errors.timezone}
          />
        </Col>
      </Row>

      <Button className='mt-4 mb-2' isFullWidth onClick={handleMeetingSave()}>
        Next
      </Button>

      <Button variant={Variants.Teal} isFullWidth onClick={handleMeetingSave(true)}>
        Save draft
      </Button>
    </div>
  );
};

MeetingDetailsStep.propTypes = {};

export default MeetingDetailsStep;
