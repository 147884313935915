import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';

export const wrapper = css`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  ${media.mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const container = css`
  background: ${colors.white};
  width: calc(100% - 70px);
  height: 100%;
  display: flex;
  ${media.mobile} {
    width: calc(100% - 42px);
  }
`;

export const board = css`
  height: 100%;
  width: 254px;
  padding: 28px 30px 24px;
  background-color: ${colors.lightestGrey};
  border-right: 1px solid ${colors.borderSecondGrey};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  ${media.mobile} {
    width: 100px;
    padding: 15px 5px;
  }
`;

export const toggleButton = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const boardTitle = css`
  ${fonts.bold};
  font-weight: bold;
  margin-bottom: 42px;
  font-size: 22px;
  line-height: 18px;
  color: ${colors.black};
  ${media.mobile} {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
`;

export const companies = css`
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 10px;
  margin-right: -25px;
  max-width: 100%;
  ${media.mobile} {
    margin-right: -15px;
  }
`;

export const profileBlock = css`
  ${fonts.regular};
  font-weight: 500;
  color: ${colors.lightGrey} !important;
  border-top: 1px solid ${colors.borderSecondGrey};
  border-bottom: 1px solid ${colors.borderSecondGrey};
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.mobile} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const profileActionsBlock = css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${colors.lightGrey};
`;

export const userNameBlock = css`
  ${fonts.regular};
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  /* margin-left: 3px; */
  ${media.mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const userName = css`
  ${fonts.bold};
  font-weight: bold;
  margin: 0;
`;

export const nameAndAvatar = css`
  padding: 22px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.mobile} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const viewAll = css`
  color: ${colors.cyan};
  text-align: center;
`;

export const actionsBlock = css`
  flex-grow: 1;
  padding: 22px;
  padding-left: 30px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.mobile} {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
`;

export const signOut = css`
  color: ${colors.lightGrey} !important;
`;

export const close = css`
  position: absolute;
  right: 26px;
  top: 26px;
  width: 26px;
  height: 26px;

  ${media.mobile} {
    right: 10px;
    top: 10px;
    width: 22px;
    height: 22px;
  }
  &:before,
  &:after {
    position: absolute;
    left: 50%;
    content: ' ';
    height: 100%;
    width: 2px;
    opacity: 0.5;
    background-color: ${colors.white};
    transform-origin: center;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const link = css`
  color: ${colors.lightGrey} !important;
  display: flex;
  align-items: center;
`;

export const companyBlock = css`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  ${media.mobile} {
    flex-direction: column;
  }
`;

export const companyIconWrapper = ({ isActive }) => css`
  width: 52px;
  min-width: 52px;
  height: 52px;
  padding: 3px;
  ${isActive && `border: 1px solid ${colors.cyan};`}
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 12px;
  ${media.mobile} {
    margin-right: 0;
    margin-bottom: 2px;
  }
`;

export const companyIconCont = css`
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(0, 186, 198, 0.1);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const textEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const companyTextWrapper = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  ${media.mobile} {
    align-items: center !important;
  }
  ${media.tablet} {
    align-items: flex-start;
  }
`;

export const companyTitle = css`
  ${fonts.regular};
  max-width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin: 0;
  ${media.mobile} {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }
`;

export const companyPendingLabel = css`
  ${fonts.regular}
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15em;
  color: ${colors.orange};
  margin: 0;
  font-weight: 400;
  ${media.mobile} {
    text-align: center;
    font-size: 10px;
  }
`;

export const companyManageLabel = css`
  ${fonts.regular}
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15em;
  color: ${colors.cyan};
  margin: 0;
  font-weight: 400;
  &:focus,
  &:active {
    color: ${colors.cyan};
  }
  ${media.mobile} {
    text-align: center;
    font-size: 10px;
  }
`;

export const companyPrimaryLabel = css`
  ${fonts.bold}
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15em;
  color: ${colors.headingGrey};
  margin: 0;
  font-weight: 600;
  ${media.mobile} {
    text-align: center;
    font-size: 10px;
  }
`;

export const companyTextCont = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const closeCont = css`
  flex-grow: 1;
`;
