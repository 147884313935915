import { gql, useQuery } from '@apollo/client';
import { TIMEZONE_FRAGMENT } from './../fragments/TimezoneFragment';

const TIMEZONE_SUBSCRIPTION = gql`
  subscription TimezoneSubscription {
    timezoneChanged {
      ...TimezoneFields
    }
  }
  ${TIMEZONE_FRAGMENT}
`;

export { TIMEZONE_SUBSCRIPTION };
