import { media } from 'styles';
import { NAV_ITEM_WIDTH, MOBILE_NAV_PADDING, MORE_BUTTON_WIDTH } from './constants';
import { intl } from '../../../contexts/v2/TranslationContext';

export const calcVisibleNavItems = ({ links, setVisibleLinks, setHiddenLinks }) => {
  const windWidth = window.innerWidth;
  const paddings = windWidth > media.MOBILE_SCREEN_WIDTH ? MOBILE_NAV_PADDING * 2 : 0;
  const availableSpace = windWidth - paddings;
  let sumLinksWidth = MORE_BUTTON_WIDTH;
  let availableButtons = 0;

  links.forEach((_, index) => {
    sumLinksWidth += NAV_ITEM_WIDTH;
    if (availableSpace >= sumLinksWidth) availableButtons = index + 1;
  });
  if (availableButtons === links.length - 1) availableButtons = links.length;

  setVisibleLinks(links.slice(0, availableButtons));
  setHiddenLinks(links.slice(availableButtons, links.length));
};

export const setLink = (link, isActive) => ({ link, isActive });

export const setLinksList = ({ dashboard, directories, meetings, minutes, tasks, smart, votes }) => {
  return [
    {
      title: intl.formatMessage({ id: 'Navigation.dashboard' }),
      link: dashboard.link,
      isActive: dashboard.isActive,
      iconClassName: 'mdi mdi-view-dashboard',
    },
    {
      title: intl.formatMessage({ id: 'Navigation.documents' }),
      link: directories.link,
      isActive: directories.isActive,
      iconClassName: 'mdi mdi-folder',
    },
    {
      title: intl.formatMessage({ id: 'Navigation.meetings' }),
      link: meetings.link,
      isActive: meetings.isActive,
      iconClassName: 'fas fa-comment-alt-lines',
    },
    {
      title: intl.formatMessage({ id: 'Navigation.minutes' }),
      link: minutes.link,
      isActive: minutes.isActive,
      iconClassName: 'fas fa-list-alt',
    },
    {
      title: intl.formatMessage({ id: 'Navigation.taskboard' }),
      link: tasks.link,
      isActive: tasks.isActive,
      iconClassName: 'fas fa-tasks',
    },
    {
      title: intl.formatMessage({ id: 'Navigation.smartSign' }),
      link: smart.link,
      isActive: smart.isActive,
      iconClassName: 'fas fa-signature',
    },
    {
      title: intl.formatMessage({ id: 'Navigation.voting' }),
      link: votes.link,
      isActive: votes.isActive,
      iconClassName: 'fas fa-chart-pie-alt',
    },
  ];
};
