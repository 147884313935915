/** @jsx jsx */
import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { Spinner } from 'components/shared/Spinner';

import { useFetchResolutionDocuments } from './../hooks/useFetchResolutionDocuments';

import { ReactComponent as ResolutionDocumentIcon } from 'images/icons/resolution-document-icon.svg';
import * as styles from './index.styles';

const ResolutionDocumentViewerList = memo(({ resolutionId, previewDocument }) => {
  const { isLoading, resolutionDocuments } = useFetchResolutionDocuments({ resolutionId: resolutionId });

  const renderDocuments = resolutionDocuments.map((resolutionDocument) => {
    const handlePreview = previewDocument.bind(this, resolutionDocument.document);
    const documentName = (document) => `${document.name}.${document.extension}`;

    return (
      <div
        key={resolutionDocument.id}
        onClick={handlePreview}
        className='tw-flex tw-w-1/3 tw-cursor-pointer tw-flex-col tw-items-center tw-gap-2 md:tw-w-1/5'
      >
        <ResolutionDocumentIcon />

        <div className='text-black fs-12 tw-w-full tw-truncate tw-px-4'>
          {documentName(resolutionDocument.document)}
        </div>
      </div>
    );
  });

  return (
    <>
      <Spinner show={isLoading} />

      {resolutionDocuments.length > 0 && (
        <div className='m-b-15'>
          <div className='text-black fs-12 font-weight-semibold m-b-15'>
            <FormattedMessage id='ResolutionDocumentViewerList.filesAttached' />
          </div>

          <div className='tw-flex tw-flex-wrap tw-gap-y-4'>{renderDocuments}</div>
        </div>
      )}
    </>
  );
});

export { ResolutionDocumentViewerList };
