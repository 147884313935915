/** @jsxRuntime classic */
/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import TextareaAutosize from 'react-autosize-textarea';

import { ReactComponent as PlaneIcon } from 'images/icons/plane.svg';

import * as styles from './MessageForm.styles';

const MessageForm = memo(({ value, onChange, onSubmit }) => {
  const intl = useIntl();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const handleKeyDown = (e) => {
    if (e.keyCode == 13 && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  return (
    <form css={styles.wrapper} onSubmit={handleSubmit}>
      <TextareaAutosize
        css={styles.textArea}
        value={value}
        onChange={onChange}
        maxRows={2}
        onKeyDown={handleKeyDown}
        placeholder={intl.formatMessage({ id: 'MessageForm.messagePlaceholder' })}
      />

      <button className='border-0 bg-transparent'>
        <PlaneIcon css={styles.icon} />
      </button>
    </form>
  );
});

export { MessageForm };
