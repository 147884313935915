import { useState } from 'react';
import { ASC, DESC } from 'data/globals/SortDirections';
import { sort, createNewSortInstance } from 'fast-sort';

const useSort = (items) => {
  const [direction, setDirection] = useState(ASC);

  const toggleSort = () => {
    const opposite = isDescending ? ASC : DESC;

    setDirection(opposite);
  };

  const isDescending = direction === DESC;
  const isAscending = direction === ASC;

  const naturalSort = createNewSortInstance({
    comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
  });

  const sortedItems = isDescending
    ? naturalSort(items).desc((item) => item.name)
    : naturalSort(items).asc((item) => item.name);

  return { toggleSort, sortedItems, isDescending };
};

export { useSort };
