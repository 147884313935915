import { gql, useMutation } from '@apollo/client';
import { CONTACT_GROUP_FRAGMENT } from './../fragments/ContactGroupFragment';

const CREATE_CONTACT_GROUP_MUTATION = gql`
  mutation createContactGroup($input: CreateContactGroupInput!) {
    createContactGroup(input: $input) {
      contactGroup {
        ...ContactGroupFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${CONTACT_GROUP_FRAGMENT}
`;

const useCreateContactGroupMutation = (options) => useMutation(CREATE_CONTACT_GROUP_MUTATION, options);

export { CREATE_CONTACT_GROUP_MUTATION, useCreateContactGroupMutation };
