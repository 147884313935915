import { css } from '@emotion/core';
import { panels, colors, fonts, media } from 'styles';

export const component = css`
  font-size: 12px;
  color: ${colors.lightGrey};
  display: flex;
  align-items: baseline;
  position: relative;
`;

export const assigneeless = css`
  color: ${colors.red};
  display: inline-block;
  line-height: 1;
`;

export const assignee = {
  base: css`
    color: ${colors.black};
    display: inline-block;
    line-height: 1;
  `,

  hoverable: css`
    cursor: pointer;
  `,
};

export const assigneeIcon = css`
  margin-right: 5px;

  path {
    fill: ${colors.cyan};
  }
`;

export const chevron = {
  base: css`
    margin-left: 5px;
    font-weight: 400;
    color: ${colors.black};
    font-size: 10px;
    transition: transform 0.2s ease-in-out;
  `,

  flipped: css`
    transform: rotate(180deg);
  `,
};

export const picker = {
  base: css`
    ${panels.base}
    display: none;
    box-shadow: 0px 1.2px 3.6px rgba(0, 20, 90, 0.1), 0px 6.4px 14.4px rgba(0, 50, 97, 0.13);
    padding: 14px 12px 0 12px;
    width: 346px;
    background: ${colors.white};
    position: absolute;
    top: 15px;
    z-index: 5;
    ${media.mobile} {
      max-width: 100%;
      width: 100%;
      position: fixed;
      left: 0;
      top: unset;
      z-index: 1000;
      bottom: 0;
    }
  `,

  visible: css`
    display: block;
  `,
};

export const dropdown = css`
  position: static;
  box-shadow: none;

  .react-picker-option {
    padding-left: 5px;
    padding-right: 5px;
  }

  .react-items-wrapper {
    padding-right: 0;
  }
`;
