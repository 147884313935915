import { css } from '@emotion/core';

export const container = {
  base: css`
    display: flex;
    align-items: center;
    transition: none;
    opacity: 0;
    flex-shrink: 0;
  `,

  visible: css`
    opacity: 1;
    transition: opacity 0.4s ease-in;
  `,
};
