import axios from 'axios';

export default class API {
  // SmartSign specific
  static createSmartDocument(originalFile, onSubmit, onError = this.handleError) {
    const formData = new FormData();
    formData.append('smart_document[document_attributes][original_file]', originalFile);
    return axios
      .post(`/api/${getCompanySlug()}/smart_documents.json`, formData)
      .then((response) => response.data)
      .then(onSubmit)
      .catch(onError);
  }
}
