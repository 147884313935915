/** @jsx jsx */
import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FormattedMessage, useIntl } from 'react-intl';

import * as styles from './VoteSettingsForm.styles';
import { MeetingAlert } from '../MeetingAlert';
import { MeetingPicker } from 'components/shared/MeetingPicker';
import { Input } from 'components/shared/Input';
import { RadioButton } from 'components/shared/RadioButton';
import { CheckBox } from 'components/shared/CheckBox';
import { Tooltip } from '../../roles/Tooltip';

const VoteSettingsForm = memo(
  ({
    voteParams,
    handleInputChange,
    updateVoteParams,
    errorMessage,
    voteOpened,
    selectedMeeting,
    onMeetingDataSet,
    onMeetingSelect,
    meetings,
  }) => {
    const [meetingAlertIsVisible, setMeetingAlertIsVisible] = useState(false);
    const [passcodeVisible, setPasscodeVisible] = useState(false);
    const [voteMeeting, setVoteMeeting] = useState(selectedMeeting);

    const intl = useIntl();

    const meetingOptions = meetings?.map((item) => ({ id: item.id, title: item.title }));

    const hideMeetingAlert = () => {
      setVoteMeeting(null);
      setMeetingAlertIsVisible(false);
    };

    const setMeetingData = () => {
      onMeetingDataSet();
      setMeetingAlertIsVisible(false);
    };

    const selectMeeting = (meeting, onSelect) => {
      if (voteMeeting?.id === meeting.id) {
        onMeetingSelect(null);
        setVoteMeeting(null);
      } else {
        onMeetingSelect(meeting);
        setVoteMeeting(meeting);
        setMeetingAlertIsVisible(true);
        onSelect();
      }
    };

    const setShowResultsToOwners = () => updateVoteParams('showResults', false);
    const setShowResultsToAll = () => updateVoteParams('showResults', true);

    const togglePasscodeVisible = () => setPasscodeVisible(!passcodeVisible);
    const copyPasscode = () => navigator.clipboard.writeText(voteParams.passcode);

    const copyPopover = (
      <Popover css={styles.popover}>
        <Popover.Content>
          <FormattedMessage id='VoteSettingsForm.passCopied' />
          <div className='triangle' />
        </Popover.Content>
      </Popover>
    );

    return (
      <>
        <MeetingAlert show={meetingAlertIsVisible} onClose={hideMeetingAlert} onConfirm={setMeetingData} />

        <div className='panel vote-panel p-t-5'>
          <div className='vote-heading'>
            <FormattedMessage id='VoteSettingsForm.voteDetails' />
          </div>
          <div className='row'>
            <div className='col-md-10'>
              <div className='vote-name vote-input'>
                <label className='form-label dash-title p-b-10 fs-12'>
                  <FormattedMessage id='VoteSettingsForm.voteName' />
                </label>
                <Input
                  name='name'
                  value={voteParams.name}
                  placeholder={intl.formatMessage({ id: 'VoteSettingsForm.enterName' })}
                  onChange={handleInputChange}
                  isRequired
                  disabled={voteOpened}
                  errorMessage={errorMessage('name')}
                />
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='vote-voter-type'>
                    <div className='dash-title form-label p-b-10'>
                      <FormattedMessage id='VoteSettingsForm.voterType' />
                    </div>
                    <RadioButton
                      name='voterType'
                      value='contacts'
                      defaultChecked={voteParams.voterType === 'contacts'}
                      onChange={handleInputChange}
                      id='voter-type-contacts'
                      label={intl.formatMessage({ id: 'VoteSettingsForm.onlyCompany' })}
                    />
                    <RadioButton
                      name='voterType'
                      value='all'
                      defaultChecked={voteParams.voterType !== 'contacts'}
                      onChange={handleInputChange}
                      id='voter-type-all'
                      label={intl.formatMessage({ id: 'VoteSettingsForm.outsideCollab' })}
                    />
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='vote-privacy'>
                    <div className='dash-title form-label p-b-10'>
                      <FormattedMessage id='VoteSettingsForm.privacy' />
                    </div>

                    <div className='d-flex'>
                      <RadioButton
                        name='showResults'
                        defaultChecked={!voteParams.showResults}
                        onChange={setShowResultsToOwners}
                        isDisabled={voteOpened}
                        id='show-results-owner'
                        label={intl.formatMessage({ id: 'VoteSettingsForm.showResultsCreator' })}
                      />
                    </div>
                    <div className='d-flex flex-wrap'>
                      <div className='m-r-10'>
                        <RadioButton
                          name='showResults'
                          defaultChecked={voteParams.showResults}
                          onChange={setShowResultsToAll}
                          isDisabled={voteOpened}
                          id='show-results-all'
                          label={intl.formatMessage({ id: 'VoteSettingsForm.showResultsAll' })}
                        />
                      </div>

                      <div className='checkbox checkbox-primary'>
                        <CheckBox
                          name='anonymous'
                          isDisabled={!voteParams.showResults || voteOpened}
                          defaultChecked={voteParams.showResults && voteParams.anonymous}
                          onChange={handleInputChange}
                          id='vote-anonymous'
                          label={intl.formatMessage({ id: 'VoteSettingsForm.anonymousVote' })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row m-t-15'>
                <div className='col-md-6 m-b-10'>
                  <div className='vote-meeting'>
                    <div className='d-flex dash-title form-label p-b-10'>
                      <div>
                        <FormattedMessage id='VoteSettingsForm.assignedMeeting' />
                      </div>
                      <div className='m-l-5 font-weight-light'>
                        <FormattedMessage id='VoteSettingsForm.optional' />
                      </div>
                    </div>
                    <div className='react-select'>
                      <MeetingPicker
                        onSelect={selectMeeting}
                        value={voteMeeting}
                        isDisabled={voteOpened}
                        options={meetingOptions}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-md-6 m-b-10'>
                  <div className='vote-passcode'>
                    <div className='d-flex dash-title form-label p-b-10'>
                      <div>
                        <FormattedMessage id='VoteSettingsForm.passcode' />
                      </div>
                      <div className='m-l-5 m-r-5 font-weight-light'>
                        <FormattedMessage id='VoteSettingsForm.optional' />
                      </div>
                      <Tooltip
                        message={intl.formatMessage({ id: 'VoteSettingsForm.passcodeTooltip' })}
                        className='c-accent'
                      />
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='col-md-10 p-0' css={styles.passcodeInput}>
                        <Input
                          type={passcodeVisible ? 'text' : 'password'}
                          className='passcode-input'
                          name='passcode'
                          value={voteParams.passcode}
                          placeholder={intl.formatMessage({ id: 'VoteSettingsForm.enterPass' })}
                          onChange={handleInputChange}
                          disabled={voteOpened}
                        />
                        <i
                          className={` far ${passcodeVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                          onClick={togglePasscodeVisible}
                          css={styles.passcodeIcon}
                        />
                      </div>
                      <OverlayTrigger trigger='click' placement='top' overlay={copyPopover} rootClose>
                        <a
                          className={`m-l-10 btn btn-secondary btn-copy d-flex align-items-center ${
                            voteParams.passcode ? '' : 'disabled'
                          }`}
                          onClick={copyPasscode}
                        >
                          <i className='far fa-copy' />
                        </a>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export { VoteSettingsForm };
