/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Spinner } from 'components/shared/Spinner';
import { AlertPopup } from '../shared/AlertPopup';

import { getAxiosHeaders } from 'utils/getHeaders';

import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { getStripeFontOptions } from 'utils/getStripeFontOptions';

import { deleteById } from 'utils/deleteById';

import EditForm from './EditForm';
import AddForm from './AddForm';
import useWindowDimensions from 'hooks/useWindowDimensions';

import * as styles from './PaymentMethods.styles';

import Mastercard from 'images/icons/payment-icons/mastercard.svg';
import Discover from 'images/icons/payment-icons/discover.svg';
import Unionpay from 'images/icons/payment-icons/unionpay.svg';
import Diners from 'images/icons/payment-icons/diners.svg';
import Visa from 'images/icons/payment-icons/visa.svg';
import Amex from 'images/icons/payment-icons/amex.svg';
import JCB from 'images/icons/payment-icons/jcb.svg';

const PaymentMethods = ({ company }) => {
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [primaryMethod, setPrimaryMethod] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [flash, setFlash] = useState(null);

  const { isMobile } = useWindowDimensions();

  const intl = useIntl();

  const dismissFlash = () => setFlash(null);

  const iconsMap = {
    mastercard: Mastercard,
    discover: Discover,
    unionpay: Unionpay,
    diners: Diners,
    visa: Visa,
    amex: Amex,
    jcb: JCB,
  };

  const loadPaymentMethods = () => {
    trackPromise(
      axios
        .get(`/${company.slug}/payment_methods`, getAxiosHeaders())
        .then((response) => response.data)
        .then(setPaymentMethods)
    );
  };

  useEffect(() => {
    if (paymentMethods) {
      setPrimaryMethod(paymentMethods.find((method) => method.primary));
    }
  }, [paymentMethods]);

  useEffect(() => {
    loadPaymentMethods();
  }, []);

  const stripePromise = loadStripe(ENV['STRIPE_KEY']);

  const hideEditPaymentMethod = () => {
    setSelectedCard(null);
    setShowEdit(false);
  };

  const hideAddPaymentMethod = () => {
    setSelectedCard(null);
    setShowAdd(false);
  };

  const handleAddClick = () => {
    setShowEdit(false);

    if (showAdd) {
      hideAddPaymentMethod();
    } else {
      setSelectedCard(null);
      setShowAdd(!showAdd);
    }
  };

  const handleEditClick = (paymentMethod) => {
    setShowAdd(false);

    if (showEdit && selectedCard === paymentMethod) {
      hideEditPaymentMethod();
    } else {
      setSelectedCard(paymentMethod);
      setShowEdit(true);
    }
  };

  const handleAddPaymentMethod = ({ data }) => {
    hideAddPaymentMethod();
    loadPaymentMethods();
  };

  const handleError = ({ response }) => {
    setFlash(response.data.error?.message || response.data.message);
  };

  const handleDeletePaymentMethod = ({ data }) => {
    setPaymentMethods(deleteById(paymentMethods, data));
  };

  return (
    <div className='p-l-15 p-r-15'>
      <div className='d-flex align-items-center justify-content-between m-b-25 m-t-25'>
        <span className='fs-18 font-weight-semibold c-dark text-nowrap m-r-50'>
          <FormattedMessage id='PaymentMethods.methods' />
        </span>
        <button onClick={handleAddClick} className='btn btn-secondary btn-sm-fw'>
          {isMobile
            ? intl.formatMessage({ id: 'PaymentMethods.add' })
            : intl.formatMessage({ id: 'PaymentMethods.addMethod' })}
        </button>
      </div>

      <div css={styles.cardsTable} key='cards-table'>
        <AlertPopup
          show={!!flash}
          headerText={intl.formatMessage({ id: 'PaymentMethods.oops' })}
          popupType='failure'
          onClose={dismissFlash}
        >
          <div className='text-black fs-14'>{flash}</div>
        </AlertPopup>

        <Spinner />

        {showAdd && !showEdit && (
          <div css={styles.cardsTableRow}>
            <Elements stripe={stripePromise} options={getStripeFontOptions()}>
              <AddForm
                company={company}
                iconsMap={iconsMap}
                handleError={handleError}
                setFlash={setFlash}
                onAdd={handleAddPaymentMethod}
                onDelete={hideAddPaymentMethod}
              />
            </Elements>
          </div>
        )}

        {paymentMethods ? (
          paymentMethods.map((paymentMethod) => (
            <div css={styles.cardsTableRow} key={`payment-method-row-${paymentMethod.id}`}>
              <div className={`d-flex align-items-center ${isMobile && 'flex-grow-1 justify-content-between'}`}>
                <div css={styles.card}>
                  <img src={iconsMap[paymentMethod.brand]} />
                </div>

                {paymentMethod.primary && (
                  <span style={{ letterSpacing: '0.08em' }} className='fs-14 c-secondary d-md-none'>
                    <FormattedMessage id='PaymentMethods.primaryCard' />
                  </span>
                )}
              </div>

              <div className={`fs-14 c-dark ${isMobile && 'w-100'}`}>
                <span>{`•••• •••• •••• ${paymentMethod.last_digits || paymentMethod.last4}`}</span>

                <br />

                <div className={`${isMobile && 'lh-1'}`}>
                  <span className='font-weight-semibold'>
                    <FormattedMessage id='PaymentMethods.expiresOn' />
                  </span>
                  {paymentMethod.exp_month && paymentMethod.exp_year ? (
                    <span>
                      {paymentMethod.exp_month.toString().padStart(2, '0')}/{paymentMethod.exp_year}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>

              <span className='flex-grow-1' />
              {paymentMethod.primary && (
                <span style={{ letterSpacing: '0.08em' }} className='fs-14 c-secondary m-r-30 d-none d-md-block'>
                  <FormattedMessage id='PaymentMethods.primaryCard' />
                </span>
              )}
              <a
                className={`fs-14 btn btn-link c-secondary text-uppercase font-weight-semibold ${
                  isMobile && 'w-100 m-t-10'
                }`}
                onClick={() => handleEditClick(paymentMethod)}
              >
                <FormattedMessage id='PaymentMethods.edit' />
              </a>

              {showEdit && !showAdd && selectedCard === paymentMethod && (
                <EditForm
                  paymentMethod={selectedCard}
                  company={company}
                  handleError={handleError}
                  paymentMethods={paymentMethods}
                  onDelete={handleDeletePaymentMethod}
                  onUpdate={loadPaymentMethods}
                />
              )}
            </div>
          ))
        ) : (
          <span>
            <FormattedMessage id='PaymentMethods.noMethods' />
          </span>
        )}
      </div>
    </div>
  );
};

export default PaymentMethods;
