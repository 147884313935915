/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';

import { ReactComponent as UnionIcon } from 'images/icons/union.svg';
import { ReactComponent as CheckmarkIcon } from 'images/icons/checkmark.svg';

import { formatDateTime } from 'helpers/formatDateTime';

import { useDateFormatContext } from '../../../contexts/v2/DateFormatContext';

import * as styles from './index.styles';

export const TaskTableDesktop = ({
  adminAccess,
  editAccess,
  tasks,
  agendas,
  onEdit,
  onDelete,
  onToggle,
  currentContactId,
}) => {
  const tableBody = tasks.map((task, index) => {
    const { userDateFormat } = useDateFormatContext();

    const isActionable = adminAccess || editAccess;
    const handleEdit = onEdit.bind(this, task);
    const isTogglable = isActionable || task.contact_ids.includes(currentContactId);
    const handleDelete = adminAccess ? onDelete.bind(this, task.id) : null;
    const handleToggle = isTogglable ? onToggle.bind(this, task.id) : null;
    const agenda = agendas.find((agenda) => agenda.id === task.agenda_id);

    return (
      <tr css={styles.tableRow(!tasks[index + 1], task.completed)} key={task.id}>
        <td css={styles.tableCell}>
          <div onClick={handleToggle} css={styles.checkmark(task.completed, isTogglable)} className='task-checkmark'>
            <CheckmarkIcon />
          </div>
        </td>

        <td css={styles.tableCell}>
          <div className='d-flex align-items-flex-start flex-wrap'>
            <div css={styles.title(task.completed)} className='text-truncate' onClick={handleEdit}>
              {task.title}
            </div>

            <span className='fs-14 c-mid p-t-2 w-100 d-flex align-items-center'>
              <UnionIcon className='flex-shrink-0' />
              <span className='lh-16 m-l-5 text-truncate'>{agenda?.heading || '-'}</span>
            </span>
          </div>
        </td>

        <td className='text-truncate' css={styles.tableCell}>
          <span>{task.assignee_names}</span>
        </td>

        <td css={[styles.tableCell, task.completed && styles.completed]}>{task.completed && 'completed'}</td>

        <td css={styles.tableCell} className='text-right'>
          {formatDateTime(task.due_date, userDateFormat)}
        </td>

        <td css={styles.tableCell} className='text-right'>
          {handleDelete && <i onClick={handleDelete} css={styles.deleteIcon} className='far fa-fw fa-trash-alt' />}
        </td>
      </tr>
    );
  });

  return (
    <table css={styles.table}>
      <thead>
        <tr>
          <th css={styles.tableHeader(8)} />
          <th css={styles.tableHeader(34)} />
          <th css={styles.tableHeader(33)} />
          <th css={styles.tableHeader(15)} />
          <th css={styles.tableHeader(10)} />
          <th css={styles.tableHeader(10)} />
        </tr>
      </thead>
      <tbody id='tasks-table-body'>{tableBody}</tbody>
    </table>
  );
};
