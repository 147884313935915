/** @jsx jsx */

import React from 'react';
import { createPortal } from 'react-dom';
import { jsx } from '@emotion/core';

import { Badge } from 'components/shared/Badge';

import * as styles from './StatusBadge.styles';

const StatusBadge = ({ status, text }) => {
  const targetNode = document.querySelector('#status-badge');

  const component = (
    <div className='d-flex align-items-center'>
      <h5 className='m-b-0'>{text}</h5>
      <Badge status={status} badgeStyles={styles.badge} />
    </div>
  );

  if (targetNode) {
    return createPortal(component, targetNode);
  } else {
    return null;
  }
};

export { StatusBadge };
