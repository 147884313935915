/** @jsx jsx */

import React, { memo, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { Uploadable } from '../../shared/Uploadable';
import { getRandomId } from 'helpers/getRandomId';
import { FormattedMessage, useIntl } from 'react-intl';

import Dropzone from 'react-dropzone';

import { ReactComponent as DocumentsIcon } from 'images/icons/agenda-documents-upload.svg';
import { useFlashActions } from 'store/flashStoreContext';
import { useDocumentsActions } from 'store/documentsStoreContext';
import { useMeetingStore } from 'store/meetingStoreContext';

import FileBrowser from '../../shared/FileBrowser';

import * as styles from './AgendaDocumentsUpload.styles';

const AgendaDocumentsUpload = memo(({ meetingId, agendaId, show, root }) => {
  const { meeting } = useMeetingStore();

  const intl = useIntl();

  const minSize = 0;
  const maxSize = 20971520; // 20MB

  const [showBrowse, setShowBrowse] = useState(false);

  const hideBrowse = () => {
    setShowBrowse(false);
  };

  const acceptedFileTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    '.ppt',
    '.pdf',
    '.pptx',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.xlsm',
  ];

  const { createDocument } = useDocumentsActions();
  const { setFlash } = useFlashActions();

  const [queue, setQueue] = useState([]);
  const [queueState, setQueueState] = useState({});

  useEffect(() => {
    queue.map((document) => {
      uploadDocument(document, (event) => {
        updateQueueStateItem(document.uid, { percentage: getPercentage(event) });
      })
        .then((response) => {
          updateQueueStateItem(document.uid, { done: true });
        })
        .catch((error) => {
          updateQueueStateItem(document.uid, { percentage: 0, error: 'Failed' });
        });
    });
  }, [queue]);

  const handleBrowse = () => {
    $('#modal-window').modal('hide');
    setShowBrowse(true);
  };

  const handleDrop = (documents, rejected) => {
    const tempState = {};

    documents.map((document) => {
      const uid = getRandomId();

      document.uid = uid;

      tempState[uid] = {
        percentage: 0,
        error: null,
        done: false,
      };
    });

    setQueueState((state) => ({ ...state, ...tempState }));
    setQueue(documents);

    if (rejected.length) {
      setFlash(intl.formatMessage({ id: 'AgendaDocumentsUpload.rejectedError' }));
    }
  };

  const updateQueueStateItem = (uid, params) => {
    setQueueState((state) => ({
      ...state,
      [uid]: {
        ...state[uid],
        ...params,
      },
    }));
  };

  const getPercentage = (event) => {
    return Math.round((100 * event.loaded) / event.total);
  };

  const uploadDocument = (document, onProgress) => {
    return createDocument(meetingId, agendaId, document, onProgress);
  };

  const uploads = queue.map((upload) => {
    const { percentage, error, done } = queueState[upload.uid];
    const handleCancel = updateQueueStateItem.bind(this, upload.uid, { done: true });

    return (
      <Uploadable
        key={upload.uid}
        name={upload.name}
        percentage={percentage}
        error={error}
        uploaded={done}
        onCancel={handleCancel}
      />
    );
  });

  const dropzoneComponent = ({ getRootProps, getInputProps, isDragActive }) => (
    <>
      {uploads}

      <div css={styles.dropzone(isDragActive)} {...getRootProps()}>
        <input {...getInputProps()} />

        <div css={styles.icon}>
          <DocumentsIcon />
        </div>

        <div>
          <span className='d-none d-lg-block'>
            <FormattedMessage id='AgendaDocumentsUpload.dragNDrop' />
          </span>
          <a css={styles.upload}>
            <FormattedMessage id='AgendaDocumentsUpload.clickToUpload' />
          </a>
        </div>
      </div>
    </>
  );

  return (
    show && (
      <div>
        <FileBrowser
          visible={showBrowse}
          multiple
          context='meeting'
          agendaId={agendaId}
          meetingId={meetingId}
          onClose={hideBrowse}
          meetingDirectoryId={meeting.directory_id}
        />

        <Dropzone minSize={minSize} maxSize={maxSize} accept={acceptedFileTypes} onDrop={handleDrop}>
          {dropzoneComponent}
        </Dropzone>

        <div className='text-center m-t-10'>
          <FormattedMessage id='AgendaDocumentsUpload.or' />
          <a onClick={handleBrowse} css={styles.upload} className='browse'>
            <FormattedMessage id='AgendaDocumentsUpload.browse' />
          </a>
        </div>
      </div>
    )
  );
});

export { AgendaDocumentsUpload };
