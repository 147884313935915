import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SelectedItem from '../SelectedItem';
import Placeholder from '../Placeholder';

const SelectedItemList = ({ items, onClick }) => {
  const handleSelectedItemClick = (item) => () => onClick(item);
  const renderSelectedItems = items.map((item) => (
    <SelectedItem key={item.id} name={item.name} onClick={handleSelectedItemClick(item)} />
  ));

  if (!items.length) {
    return <Placeholder message='You have not selected any files yet' />;
  }

  return <div className='tw-flex tw-flex-col tw-gap-2 tw-overflow-y-auto'>{renderSelectedItems}</div>;
};

SelectedItemList.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
};

export default SelectedItemList;
