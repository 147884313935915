/** @jsx jsx */
import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import * as styles from './PublishConfirmation.styles';

import { Modal } from 'components/shared/Modal';
import { TextAreaInput } from 'components/shared/TextAreaInput';
import { AlertPopup } from 'components/shared/AlertPopup';
import { formatTime, formatDate } from 'helpers/formatDateTime';
import { useDateFormatContext } from '../../../contexts/v2/DateFormatContext';

import { usePublishVote } from './../hooks/usePublishVote';

const PublishConfirmation = memo(({ vote, show, onClose }) => {
  const { userDateFormat } = useDateFormatContext();
  const onPublished = () => {
    onClose();
    setPopupIsOpen(true);
  };

  const intl = useIntl();

  const { isPublishing, publishVote } = usePublishVote({ onCompleted: onPublished });

  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');

  const navigateToVotes = () => window.location.assign(`/${getCompanySlug()}/voting`);

  const closePopup = () => {
    navigateToVotes();
    setPopupIsOpen(false);
  };

  const handleInputChange = (event) => setEmailMessage(event.target.value || '');
  const handlePublishVote = () => publishVote({ voteId: vote.id, emailMessage: emailMessage });

  const modalFooter = (
    <div className='d-flex align-items-center'>
      <a onClick={handlePublishVote} className='btn btn-primary'>
        <FormattedMessage id='PublishConfirmation.schedule' />
      </a>
      <a onClick={onClose} className='btn btn-text brand-text m-l-5'>
        <FormattedMessage id='PublishConfirmation.cancel' />
      </a>
    </div>
  );

  return (
    <>
      <AlertPopup
        show={popupIsOpen}
        headerText={intl.formatMessage({ id: 'PublishConfirmation.success' })}
        popupType='success'
        onClose={closePopup}
      >
        <div className='text-black fs-14'>
          <FormattedMessage id='PublishConfirmation.votePublished' />.
        </div>
      </AlertPopup>
      <Modal
        title={intl.formatMessage({ id: 'PublishConfirmation.schedAndPub' })}
        modalStyles={styles.modal}
        footer={modalFooter}
        centered
        show={show}
        onClose={onClose}
      >
        <div className='text-black m-t-15'>
          <div className='fs-14'>
            <FormattedMessage
              id='PublishConfirmation.aboutToSched'
              values={{
                name: <span className='font-weight-bold'>{vote.name}</span>,
                date1: <span className='font-weight-bold'>{formatTime(vote.startedAt)}</span>,
                date2: <span className='font-weight-bold'>{formatDate(vote.startedAt, userDateFormat)}</span>,
              }}
            />
          </div>

          <div className='m-t-35'>
            <TextAreaInput
              label={intl.formatMessage({ id: 'PublishConfirmation.emailLabel' })}
              name='message'
              onChange={handleInputChange}
              value={emailMessage}
              placeholder={intl.formatMessage({ id: 'PublishConfirmation.emailPlaceholder' })}
            />
          </div>
        </div>
      </Modal>
    </>
  );
});

export { PublishConfirmation };
