import axios from 'axios';

export default class API {
  static handleError({ response: { data } }) {
    let message = data.message || 'Something went wrong. Please try again in a few seconds.';

    displayFlashMessage(message, 'error');
  }

  static uploadSignature(blob, onUpload, onError = this.handleError) {
    const formData = new FormData();

    formData.append('smart_sign_signature[file]', blob);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'X-CSRF-Token': $("[name='csrf-token']").attr('content'),
      },
    };

    return axios
      .put(`/api/${getCompanySlug()}/v1/smart_sign_signature`, formData, config)
      .then((response) => onUpload(response))
      .catch(onError);
  }
}
