import { css } from '@emotion/core';
import { colors, media } from 'styles';

export const wrapper = {
  base: css`
    position: fixed;
    z-index: 9999;
    width: 440px;
    bottom: 25px;
    right: 25px;
    box-shadow: -20px 4px 50px rgb(54 71 98 / 25%);
    border: 2px solid transparent;
    transition: border-color 0.2s ease-in;
    display: none;
    background: ${colors.white};
    ${media.tablet} {
      width: 100%;
      height: calc(100% - var(--nav-height));
      top: var(--nav-height);
      bottom: 0;
      right: 0;
      flex-direction: column;
      border: none;
      box-shadow: none;
    }
  `,

  visible: css`
    display: block;
    ${media.tablet} {
      display: flex;
    }
  `,

  highlighted: css`
    border-color: ${colors.cyan};
  `,
};

export const spinner = css`
  height: 477px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
