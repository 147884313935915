import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { EditableInput } from 'components/shared/EditableInput';

import { ReactComponent as PencilIcon } from 'images/icons/pencil.svg';

const MAX_WIDTH = 170;

const EditableName = memo(({ value, error, isEditing, onClick, onChange, onCancel, onUpdate }) => {
  if (isEditing) {
    return (
      <EditableInput
        isEditable
        value={value}
        error={error}
        maxWidth={MAX_WIDTH}
        minWidth={MAX_WIDTH}
        onChange={onChange}
        onCancel={onCancel}
        onUpdate={onUpdate}
        withButton
      />
    );
  }

  return (
    <div className='cursor-pointer' onClick={onClick}>
      <div className='text-truncate'>{value}</div>

      <div className='justify-content-center d-flex align-items-center'>
        <PencilIcon className='m-r-5' />
        <span className='fs-14 text-grey'>
          <FormattedMessage id='EditableName.changeFilename' />
        </span>
      </div>
    </div>
  );
});

export { EditableName };
