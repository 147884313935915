/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import FormStepItem from 'components/v2/shared/FormStepItem';
import Text from 'components/v2/shared/Text';
import TextBadge from 'components/v2/shared/TextBadge';
import { useMeetingFormStepContext } from 'contexts/v2/MeetingFormStepContext';
import { useMeetingContext } from '../../../../contexts/v2/MeetingContext';

import * as styles from './FormSidebar.styles';

const FormSidebar = ({ steps, headerText, activeStep, badgeStatus }) => {
  const { setCurrentStep } = useMeetingFormStepContext();

  const {
    meeting: { meetingId },
  } = useMeetingContext();

  const handleStepClick = (index) => () => {
    if (!meetingId) {
      return;
    }
    setCurrentStep(index);
  };

  const renderSteps = steps.map((title, index) => (
    <FormStepItem
      key={`form-step-${index}`}
      title={title}
      isActive={index === activeStep}
      onClick={handleStepClick(index)}
    />
  ));

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <Text fontSize={20} isBold text={headerText} />
        <TextBadge text={badgeStatus} color='white' />
      </div>

      {renderSteps}
    </div>
  );
};

FormSidebar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  activeStep: PropTypes.number,
  onStepClick: PropTypes.func,
  meetingStatus: PropTypes.string,
  headerText: PropTypes.string,
};

export default FormSidebar;
