import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';

export const navigation = css`
  .nav-tabs {
    .nav-item {
      font-size: 14px;
      line-height: 20px;
      flex: none;
      color: ${colors.mediumGrey};
      flex: unset;
      padding: 10px 20px;
      text-align: center;
      cursor: pointer;

      &.active {
        padding: 10px 32px;
        margin-bottom: -1px;
        font-family: ${fonts.bold};
        color: ${colors.purple};
        border-bottom: 1px solid ${colors.purple};
        cursor: default;
      }
    }

    ${media.tablet} {
      margin-top: 45px;
    }
  }
}
`;
