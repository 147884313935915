import React, { createContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { IoIosClose } from 'react-icons/io';
import IconButton from './../IconButton';
import clsx from 'clsx';

export const Sizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  '2xl': '2xl',
  '3xl': '3xl',
  '4xl': '4xl',
  '5xl': '5xl',
  '6xl': '6xl',
  '7xl': '7xl',
  fullScreen: 'fullScreen',
};

export const ModalContext = createContext(null);

// `afterLeave` is not set on `Transition.Root` due to https://github.com/tailwindlabs/headlessui/issues/1364
const Modal = ({ isOpen = false, isDismissable = true, onClose = () => {}, children, size = Sizes.md, afterLeave }) => (
  <ModalContext.Provider value={{ onClose }}>
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' onClose={onClose} className='tw-relative tw-z-max tw-font-sans tw-text-sm tw-font-normal'>
        <Transition.Child
          as={Fragment}
          enter='tw-ease-out tw-duration-300'
          enterFrom='tw-opacity-0'
          enterTo='tw-opacity-100'
          leave='tw-ease-in tw-duration-200'
          leaveFrom='tw-opacity-100'
          leaveTo='tw-opacity-0'
          afterLeave={afterLeave}
        >
          <div className='tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-20 tw-transition-opacity' />
        </Transition.Child>

        <div className='tw-fixed tw-inset-0 tw-overflow-y-auto'>
          <div
            className={clsx('tw-flex tw-min-h-full tw-items-center tw-justify-center', {
              'tw-p-4': size !== Sizes.fullScreen,
            })}
          >
            <Transition.Child
              as={Fragment}
              enter='tw-ease-out tw-duration-300'
              enterFrom='tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95'
              enterTo='tw-opacity-100 tw-translate-y-0 sm:tw-scale-100'
              leave='tw-ease-in tw-duration-200'
              leaveFrom='tw-opacity-100 tw-translate-y-0 sm:tw-scale-100'
              leaveTo='tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95'
            >
              <Dialog.Panel
                className={clsx(
                  'tw-overflow tw-relative tw-min-h-[68px]',
                  'tw-h-full tw-w-full tw-bg-white tw-shadow-lightbox tw-transition-all',
                  {
                    'tw-rounded-xl': size !== Sizes.fullScreen,
                    'tw-max-w-full': size === Sizes.fullScreen,
                    'tw-max-w-sm': size === Sizes.sm,
                    'tw-max-w-md': size === Sizes.md,
                    'tw-max-w-lg': size === Sizes.lg,
                    'tw-max-w-xl': size === Sizes.xl,
                    'tw-max-w-2xl': size === Sizes['2xl'],
                    'tw-max-w-3xl': size === Sizes['3xl'],
                    'tw-max-w-4xl': size === Sizes['4xl'],
                    'tw-max-w-5xl': size === Sizes['5xl'],
                    'tw-max-w-6xl': size === Sizes['6xl'],
                    'tw-max-w-7xl': size === Sizes['7xl'],
                  }
                )}
              >
                {isDismissable && (
                  <IconButton onClick={onClose} className='tw-absolute tw-top-3 tw-right-1 tw-z-10 sm:tw-right-3'>
                    <IoIosClose className='tw-h-9 tw-w-9 tw-stroke-1 tw-font-extrabold tw-text-gray-700' />
                  </IconButton>
                )}

                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  </ModalContext.Provider>
);

Modal.propTypes = {
  isOpen: PropTypes.bool,
  isDismissable: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  size: PropTypes.oneOf(Object.values(Sizes)),
  afterLeave: PropTypes.func,
};

export default Modal;
