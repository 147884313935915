export default {
  User: {
    fields: {
      companies: {
        keyArgs: ['name', '@connection', ['key']],
        merge(existing = {}, incoming, { args, variables }) {
          if (variables.context === 'v2.companies.Index') return incoming;
          if (!args.after) return incoming;
          if (!existing.edges) return incoming;

          const edges = [...existing.edges, ...incoming.edges];

          return { ...incoming, edges };
        },
      },
    },
  },
};
