import { gql, useQuery } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';
import { DOCUMENT_FRAGMENT } from './../fragments/DocumentFragment';

const SHARED_DIRECTORIES_QUERY = gql`
  query sharedDirectories {
    currentContact {
      id

      directories: sharedDirectories {
        ...DirectoryFields
      }

      documents: sharedDocuments {
        ...DocumentFields
      }
    }
  }
  ${DIRECTORY_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
`;

const useSharedDirectoriesQuery = (options) => useQuery(SHARED_DIRECTORIES_QUERY, options);

export { useSharedDirectoriesQuery };
