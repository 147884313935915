import { gql, useMutation } from '@apollo/client';

const DOWNLOAD_DIRECTORY = gql`
  mutation downloadDirectory($input: DownloadDirectoryInput!) {
    downloadDirectory(input: $input) {
      directory {
        id
        name
      }
    }
  }
`;

const useDownloadDirectoryMutation = (options) => useMutation(DOWNLOAD_DIRECTORY, options);

export { useDownloadDirectoryMutation };
