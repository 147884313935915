import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const row = css`
  box-shadow: 2px 4px 4px rgba(223, 223, 223, 0.25);
  display: flex;
  ${media.mobile} {
    flex-wrap: wrap;
  }
`;

export const editor = css`
  background: ${colors.white};
  border-right: 1px solid ${colors.zircon};
  width: 75%;
  flex-grow: 1;
  /* overflow: hidden; */
  position: relative;
  ${media.mobile} {
    border-bottom: 1px solid ${colors.zircon};
    border-right: none;
  }
`;

export const commentBlock = css`
  min-width: 280px;
  width: 25%;
  ${media.mobile} {
    min-height: 500px;
    flex-basis: 100%;
  }
`;
