/** @jsx jsx */

import React, { memo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as UserIcon } from 'images/icons/user-icon.svg';
import { ReactComponent as EmailIcon } from 'images/icons/email-icon.svg';

import { Input } from 'components/shared/Input';

import * as styles from './InvitationForm.styles';

export default memo(({ searchTerm, onClose, onSubmit, persistContact }) => {
  let [invitation, setInvitation] = useState({ email: '', name: '' });
  let [invitationErrors, setInvitationErrors] = useState({});

  const intl = useIntl();

  useEffect(() => prefillInvitation(), [searchTerm]);

  const prefillInvitation = () => {
    if (!searchTerm.length) return;

    if (searchTerm.includes('@')) {
      setInvitation({ email: searchTerm, name: '' });
    } else {
      setInvitation({ email: '', name: searchTerm });
    }
  };

  const handleInputChange = (e) => {
    setInvitation({ ...invitation, [e.target.name]: e.target.value });
  };

  const handleSubmit = (signatory) => {
    persistContact(invitation, afterSubmit, displayErrors);
  };

  const displayErrors = (error) => {
    setInvitationErrors(error.response.data.errors);
  };

  const afterSubmit = (signatory) => {
    setInvitation({ email: '', name: '' });
    onSubmit(signatory);
  };

  const userIcon = <UserIcon css={styles.icon} />;
  const emailIcon = <EmailIcon css={styles.icon} />;

  return (
    <div css={styles.form} id='react-invite-signatory-form'>
      <div css={styles.heading}>
        <FormattedMessage id='InvitationForm.addNonContact' />
      </div>

      <div className='m-b-20'>
        <Input
          value={invitation.name}
          onChange={handleInputChange}
          placeholder={intl.formatMessage({ id: 'InvitationForm.name' })}
          label={intl.formatMessage({ id: 'InvitationForm.name' })}
          icon={userIcon}
          name='name'
          isRequired
          iconPosition='left'
          errorMessage={invitationErrors.name && invitationErrors.name[0]}
        />
      </div>

      <div className='m-b-20'>
        <Input
          value={invitation.email}
          onChange={handleInputChange}
          placeholder={intl.formatMessage({ id: 'InvitationForm.email' })}
          label={intl.formatMessage({ id: 'InvitationForm.email' })}
          name='email'
          icon={emailIcon}
          isRequired
          iconPosition='left'
          errorMessage={invitationErrors.email && invitationErrors.email[0]}
        />
      </div>

      <button onClick={handleSubmit} className='btn btn-primary'>
        <FormattedMessage id='InvitationForm.addContact' />
      </button>
      <button onClick={onClose} className='btn btn-inverted m-l-10'>
        <FormattedMessage id='InvitationForm.cancel' />
      </button>
    </div>
  );
});
