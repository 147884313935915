import { gql } from '@apollo/client';
import { RESOLUTION_DOCUMENT_FRAGMENT } from './ResolutionDocumentFragment';

const RESOLUTION_FRAGMENT = gql`
  fragment ResolutionFields on Resolution {
    id
    description

    resolutionDocuments {
      ...ResolutionDocumentFields
    }
  }
  ${RESOLUTION_DOCUMENT_FRAGMENT}
`;

export { RESOLUTION_FRAGMENT };
