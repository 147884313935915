/** @jsx jsx */

import React, { memo, useRef, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { useKeyPress } from 'hooks/useKeyPress';
import { Error } from 'components/shared/Input/Error';

import * as styles from './EditableInput.styles';

const EditableInput = memo(
  ({
    value,
    onClick,
    error,
    onChange,
    onCancel,
    isEditable,
    minWidth,
    placeholderText,
    onUpdate,
    maxWidth,
    isReadOnly,
    withButton,
  }) => {
    const [editableText, setEditableText] = useState(value || '');
    const [editableWidth, setEditableWidth] = useState(minWidth || 0);

    const Enter = useKeyPress('Enter');
    const Escape = useKeyPress('Escape');

    const editableRef = useRef();
    const measureRef = useRef();

    useEffect(() => setEditableText(value || ''), [value]);
    useEffect(() => setEditableCursor(), [isEditable]);
    useEffect(() => trackEditableWidth(), [editableText, isEditable]);
    useEffect(() => handleKeyPress(), [Enter, Escape]);

    const changeText = (event) => {
      const text = event.target.value || '';
      setEditableText(text);
      onChange && onChange(text);
    };

    const setEditableCursor = () => {
      if (!isEditable) return;

      editableRef.current.focus();

      editableRef.current.selectionStart = editableText.length;
      editableRef.current.selectionEnd = editableText.length;
    };

    const trackEditableWidth = () => {
      if (!measureRef.current) return;

      const { width } = measureRef.current.getBoundingClientRect();
      const inputWidth = minWidth && width < minWidth ? minWidth : width;

      setEditableWidth(inputWidth + 2);
    };

    const handleKeyPress = () => {
      if (isEditable && Enter) return updateText();
      if (isEditable && Escape) return discardChanges();
    };

    const updateText = () => {
      if (error || !editableText.trim().length) {
        discardChanges();
      } else if (editableText === value) {
        onCancel();
      } else {
        onUpdate(editableText);
      }
    };

    const discardChanges = () => {
      setEditableText(value);
      onCancel();
    };

    const measure = (
      <div css={styles.measure} ref={measureRef}>
        {editableText}
      </div>
    );

    const editableInputStyles = styles.input(editableWidth, !!error);

    const editableInput = isEditable && (
      <input
        css={editableInputStyles}
        placeholder={placeholderText}
        onBlur={updateText}
        ref={editableRef}
        value={editableText}
        onChange={changeText}
      />
    );

    const staticTextStyles = [styles.staticText.base, !editableText.length && styles.staticText.placeholder];

    const placeholder = editableText.length > 0 ? editableText : placeholderText;

    const staticText = !isEditable && (
      <div className='d-flex align-items-center'>
        <span css={staticTextStyles}>{placeholder}</span>
        <i className='fa fa-pencil' css={styles.icon} />
      </div>
    );

    if (isReadOnly) {
      return <span>{placeholder}</span>;
    }

    return (
      <span onClick={onClick} style={{ maxWidth: maxWidth }}>
        {measure}
        {staticText}

        <div className='position-relative d-flex align-items-center'>
          {editableInput}
          <Error message={error} styles={{ right: 0 }} />
          {withButton && (
            <button onClick={updateText} className='btn m-l-5 btn-sm btn-primary'>
              <FormattedMessage id='EditableInput.ok' />
            </button>
          )}
        </div>
      </span>
    );
  }
);

export { EditableInput };
