import { gql, useQuery } from '@apollo/client';

const ANNOTATIONS_QUERY = gql`
  query annotations($id: ID!) {
    currentCompany {
      id
      meeting(id: $id) {
        id
        smartPack {
          id
          annotations {
            id
            externalId
            contactId
            data
          }
        }
      }
    }
  }
`;

const useAnnotationsQuery = (options) => useQuery(ANNOTATIONS_QUERY, options);

export default useAnnotationsQuery;
