/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { FONT_SIZES, COLORS } from './constants';

import * as styles from './Text.styles';

const Text = ({
  fontSize = 14,
  color = 'black',
  isBold = false,
  isSemiBold = false,
  isUnderline = false,
  isMedium = false,
  isItalic = false,
  isTruncated = false,
  text,
  className,
}) => (
  <span
    className={className}
    css={[
      styles.text.base({ fontSize }),
      styles.text[color],
      isMedium && styles.text.medium,
      isSemiBold && styles.text.semiBold,
      isBold && styles.text.bold,
      isUnderline && styles.text.underline,
      isItalic && styles.text.italic,
      isTruncated && styles.text.truncated,
    ]}
  >
    {text}
  </span>
);

Text.propTypes = {
  className: PropTypes.string,
  fontSize: PropTypes.oneOf(FONT_SIZES),
  isSemiBold: PropTypes.bool,
  isBold: PropTypes.bool,
  isMedium: PropTypes.bool,
  isUnderline: PropTypes.bool,
  text: PropTypes.string,
  isItalic: PropTypes.bool,
  isTruncated: PropTypes.bool,
  color: PropTypes.oneOf(COLORS),
};

export default Text;
