import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Attachment from '../Attachment';
import Button, { Sizes, Variants } from 'components/v2/shared/Button';
import { AttachmentState } from 'contexts/v2/FileUploadContext';
import * as ImageUpload from '../ImageUpload';

const AttachmentManagement = ({
  hasPreview,
  attachment,
  isDisabled,
  onUpload,
  onRemove,
  onCancel,
  onRestart,
  variant,
}) => {
  if (!attachment) {
    return (
      <div>
        <div className='tw-pb-4 tw-text-xs'>We recommend a 50px x 50px image.</div>
        <div className='tw-flex'>
          <Button
            size={Sizes.Small}
            className={clsx('!tw-p-0', {
              'tw-hidden lg:tw-block': variant === ImageUpload.Variants.Default,
              'tw-font-sans tw-text-sm tw-font-semibold tw-text-teal-500': variant === ImageUpload.Variants.Company,
            })}
            variant={Variants.Transparent}
            onClick={onUpload}
            isDisabled={isDisabled}
          >
            {variant === ImageUpload.Variants.Default
              ? 'Upload'
              : variant === ImageUpload.Variants.Company
              ? 'Upload logo'
              : 'Upload file'}
          </Button>
          {hasPreview && (
            <Button
              onClick={() => onRemove(attachment)}
              size={Sizes.Small}
              variant={Variants.Transparent}
              className='pl-4 !tw-py-0 tw-text-red-600'
            >
              Delete
            </Button>
          )}
        </div>
      </div>
    );
  }

  if (attachment.state === AttachmentState.Uploaded) {
    return (
      <div>
        <div className='tw-pb-4 tw-text-xs'>We recommend a 50px x 50px image.</div>
        <div className='tw-flex'>
          <Button
            size={Sizes.Small}
            variant={Variants.Transparent}
            onClick={onUpload}
            isDisabled={isDisabled}
            className={clsx('!tw-p-0', {
              'tw-hidden lg:tw-block': variant === ImageUpload.Variants.Default,
              'tw-font-sans tw-text-sm !tw-font-semibold tw-text-teal-500': variant === ImageUpload.Variants.Company,
            })}
          >
            {variant === ImageUpload.Variants.Default
              ? 'Upload'
              : variant === ImageUpload.Variants.Company
              ? 'Upload logo'
              : 'Upload file'}
          </Button>
          <Button
            onClick={() => onRemove(attachment)}
            size={Sizes.Small}
            variant={Variants.Transparent}
            className='pl-4 !tw-py-0 tw-text-red-600'
          >
            Delete
          </Button>
        </div>
      </div>
    );
  }

  const { id, name, progress, state, error } = attachment;

  const handleAttachmentRemove = () => onRemove(attachment);
  const handleAttachmentCancel = () => onCancel(attachment);
  const handleAttachmentRestart = () => onRestart(attachment);

  return (
    <Attachment
      key={id}
      name={name}
      progress={progress}
      state={state}
      error={error}
      onRemove={handleAttachmentRemove}
      onCancel={handleAttachmentCancel}
      onRestart={handleAttachmentRestart}
    />
  );
};

AttachmentManagement.propTypes = {
  attachment: PropTypes.object,
  isDisabled: PropTypes.func,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  onCancel: PropTypes.func,
  onRestart: PropTypes.func,
};

export default AttachmentManagement;
