import React, { memo } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { FormattedMessage } from 'react-intl';

import { Modal } from 'components/shared/Modal';
import { SendToSignatories } from './SendToSignatories';
import { SentSuccess } from './SentSuccess';
import * as styles from './Send.styles';
import { colors } from 'styles';
import { CountTitle, Overlay } from './styles';

const Send = memo(
  ({ show, message, setMessage, showSpinner, documentSent, onSend, onClose, signatories, errors, filename }) => {
    const modalTitle = (
      <div>
        <span>
          <FormattedMessage id='Send.sendTo' />
        </span>
        <CountTitle>
          <FormattedMessage id='Send.signatoryCount' values={{ count: signatories.length }} />
        </CountTitle>
      </div>
    );

    const modalOverlay = (
      <Overlay>
        <BounceLoader loading={true} color={colors.cyan} />
      </Overlay>
    );

    return (
      <div>
        <Modal
          centered
          overlay={showSpinner && modalOverlay}
          show={show}
          onClose={onClose}
          title={!documentSent && modalTitle}
          modalStyles={styles.modalStyles}
          id='react-send-document-modal'
          hasCloseIcon={documentSent ? false : true}
        >
          {documentSent ? (
            <SentSuccess signatories={signatories} />
          ) : (
            <SendToSignatories
              signatories={signatories}
              errors={errors}
              documentName={filename}
              message={message}
              setMessage={setMessage}
              hasHeader={false}
            />
          )}

          <div className='text-center'>
            {documentSent ? (
              <button onClick={onClose} className='p-l-20 p-r-20 m-t-35 btn btn-primary'>
                <FormattedMessage id='Send.close' />
              </button>
            ) : (
              <button onClick={onSend} className='p-l-20 p-r-20 btn btn-primary'>
                <FormattedMessage id='Send.send' />
              </button>
            )}
          </div>
        </Modal>
      </div>
    );
  }
);

export { Send };
