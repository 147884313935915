/** @jsx jsx */
import React, { memo, useRef, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';
import { InView } from 'react-intersection-observer';
import clsx from 'clsx';

import detectClickOutside from '../shared/helpers/hooks/DetectClickOutside';
import useCurrentUserCompanies from '../../hooks/useCurrentUserCompanies';
import BoardSpaceIcon from '../../../assets/images/icons/board-space.svg';
import CheckMarkGreenIcon from '../../../assets/images/icons/checkmark-green.svg';
import CompanyIcon from '../../../assets/images/icons/company.svg';
import ChevronRightIcon from '../../../assets/images/icons/chevron-right.svg';
import { withIntl } from '../../utils/v2/intl';
import Spinner from 'components/v2/shared/Spinner/Spinner';
import { withApollo } from '../../utils/v2/apollo';

const Styles = {
  root: {
    position: 'relative',
  },
  label: {
    cursor: 'pointer',
  },
  popout: {
    background: '#fff',
    position: 'absolute',
    right: -300,
    top: 0,
    width: 320,
    maxHeight: 500,
    padding: '20px 20px 0px 20px',
    boxShadow: '-20px 4px 50px rgba(54, 71, 98, 0.25)',
    borderRadius: 2,
  },
  gateway: {
    background: '#fff',
    position: 'absolute',
    left: 0,
    top: 60,
    width: 320,
    padding: '20px 20px 0px 20px',
    boxShadow: '-20px 4px 50px rgba(54, 71, 98, 0.25)',
    borderRadius: 2,
    zIndex: 2,
  },
  result: {
    borderBottom: '1px solid #ededed',
    paddingBottom: 10,
    paddingTop: 10,
    marginLeft: -20,
    marginRight: -20,
    paddingLeft: 20,
    paddingRight: 20,
    minHeight: 43,
    '&:hover': {
      backgroundColor: '#f6f6f6',
      cursor: 'pointer',
    },
  },
  results: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 315,
    margin: '0 -20px',
    padding: '0 20px',
  },
  rightWrapper: {
    display: 'flex',
    marginLeft: 'auto',
  },
  subcommittee: {
    marginLeft: 15,
    position: 'relative',
    '&:before': {
      content: '""',
      borderLeft: 'solid 1px #787d96',
      borderBottom: 'solid 1px #787d96',
      marginLeft: -10,
      marginTop: -13,
      height: 27,
      width: 7,
      position: 'absolute',
    },
    '&:first-of-type:before': {
      height: 17,
      marginTop: -4,
    },
  },
};

const BoardSpaceSelect = memo(({ primaryCompanyId, currentCompany, gateway }) => {
  const searchInputRef = useRef(null);
  const { ref, isComponentVisible, setIsComponentVisible } = detectClickOutside(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

  const { data, loading, fetchMoreCompanies, fetchCompanies } = useCurrentUserCompanies({
    skipFetch: true,
    name: debouncedSearchTerm,
    context: 'BoardSpaceSelect',
  });

  const intl = useIntl();

  const onSearchInputChange = (e) => setSearchTerm(e.target.value);

  const onTogglePopout = () => {
    setIsComponentVisible(!isComponentVisible);
    fetchCompanies();
  };

  const onManageDashboardRedirect = (company) => {
    if (company.isPending) return;
    if (company.isParentOnly) return;

    window.location.assign(`/${company.slug}/dashboard`);
  };

  const onViewAllRedirect = () => {
    window.location.assign(`/${currentCompany.slug}/companies`);
  };

  const handleInViewChange = (inView) => {
    const {
      currentUser: {
        companies: { pageInfo },
      },
    } = data;

    if (inView && pageInfo.hasNextPage && !loading) {
      fetchMoreCompanies(pageInfo.endCursor);
    }
  };

  const renderLoadMore = () => {
    if (!data || loading) {
      return null;
    }

    const {
      currentUser: {
        companies: { pageInfo },
      },
    } = data;

    return (
      pageInfo.hasNextPage && (
        <InView as='div' onChange={handleInViewChange} className='tw-relative tw-py-8'>
          <Spinner />
        </InView>
      )
    );
  };

  const renderBoardSpaceList = () => {
    if (!data && loading) {
      return (
        <div className='tw-relative tw-py-8'>
          <Spinner />
        </div>
      );
    }

    const {
      currentUser: {
        companies: { edges: companies },
        subcommittees,
      },
    } = data;

    const companyIds = companies.map((c) => c.node.id);
    const parentCompanies = subcommittees
      .map((subcommittee) => subcommittee.parentCompany)
      .filter((val, idx, array) => array.indexOf(val) === idx)
      .filter((parentCompany) => companyIds.indexOf(parentCompany.id) < 0)
      .map((parentCompany) => ({
        node: { ...parentCompany, companyPermissions: {}, isParentOnly: true },
      }));
    const allCompanies = companies.concat(parentCompanies);

    if (!allCompanies.length) {
      return (
        <div className='tw-p-5 tw-text-center tw-text-sm'>
          <FormattedMessage id='CompanyList.noFound' />
        </div>
      );
    }

    return allCompanies.map((company) => {
      const {
        node: { id, name, isPending, isActive, isPrimary, isCurrentCompany, slug, companyPermissions },
      } = company;

      return (
        <div
          css={Styles.result}
          className={clsx('search-result d-flex flex-grow-1 align-items-center justify-content-between', {
            pending: isPending,
            current: isCurrentCompany,
          })}
          onClick={() => onManageDashboardRedirect(company.node)}
          key={`CompanyId${id}`}
        >
          {isPending && (
            <div className='d-flex align-items-center w-100'>
              <img src={BoardSpaceIcon} alt='board-space-icon' className='m-0' />
              <div className='d-flex flex-column m-l-10 m-w-0'>
                <div className='contact-name c-dark text-truncate'>{name}</div>
              </div>
              <div className='m-l-10 fs-12 flex-grow-1 text-right pending-text'>
                <FormattedMessage id='BoardSpaceSelect.pending' />
              </div>
            </div>
          )}
          {!isPending && (
            <div className='d-flex w-100 tw-flex-col'>
              <div className='d-flex align-items-center w-100 tw-gap-2' style={{ minHeight: 43 }}>
                <img src={BoardSpaceIcon} alt='board-space-icon' className='m-0' />
                <div className='d-flex flex-column m-w-0'>
                  <div className='contact-name c-dark text-truncate'>{name}</div>
                  {companyPermissions.canBeManagedByCurrentUser && (
                    <a
                      href={`/${slug}/settings`}
                      className='fs-14 c-secondary font-weight-bold manage-link'
                      style={{ marginTop: -5 }}
                    >
                      <FormattedMessage id='BoardSpaceSelect.manage' />
                    </a>
                  )}
                </div>

                <div css={Styles.rightWrapper} className='tw-shrink-0 tw-gap-2'>
                  {isPrimary && isActive && (
                    <div className='text-uppercase fs-12 font-weight-semibold c-light-mid'>
                      <FormattedMessage id='BoardSpaceSelect.primary' />
                    </div>
                  )}

                  {companyPermissions.canBeManagedByCurrentUser && !isActive && (
                    <div className='fs-12 pending-text'>
                      <FormattedMessage id='BoardSpaceSelect.pending' />
                    </div>
                  )}

                  {isCurrentCompany && (
                    <img
                      src={CheckMarkGreenIcon}
                      alt='active-boardspace'
                      className='tw-shrink-0'
                      style={{ width: 16 }}
                    />
                  )}
                </div>
              </div>
              {renderSubcommittees(subcommittees.filter((subcommittee) => subcommittee.parentCompany.id === id))}
            </div>
          )}
        </div>
      );
    });
  };

  const renderSubcommittees = (subcommittees = []) => {
    return (
      <div className=''>
        {subcommittees.map((subcommittee) => (
          <div className='tw-px-2' key={subcommittee.id} css={Styles.subcommittee}>
            <a href={`/${subcommittee.slug}`} className='fs-12 c-secondary'>
              {subcommittee.name}
            </a>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div css={Styles.root} ref={ref}>
      <div style={Styles.label} className='nav-item fs-14 md:tw-py-4' onClick={() => onTogglePopout()}>
        <div className='tw-flex tw-min-w-0 tw-grow tw-flex-col tw-items-center tw-gap-2 tw-text-center md:tw-flex-row md:tw-text-left'>
          <img src={CompanyIcon} alt='company-icon' style={{ width: 20, marginLeft: 10 }} />
          {gateway ? (
            <div className='board-space-nav d-inline-block tw-leading-none'>
              <FormattedMessage id='BoardSpaceSelect.switchAccounts' />
            </div>
          ) : (
            <>
              <div
                className={`board-space-nav text-truncate ${
                  Cookies.get('smallNav') && Cookies.get('smallNav') === 'false' ? '' : 'd-none'
                }`}
              >
                {currentCompany.name}
              </div>
              <img src={ChevronRightIcon} alt='chevron-right' style={{ width: 7, marginRight: 10 }} />
            </>
          )}
        </div>
      </div>
      {isComponentVisible && (
        <div style={gateway ? Styles.gateway : Styles.popout}>
          <div className='d-flex'>
            <h2 className='d-flex flex-column fs-18 font-weight-bold m-b-15'>
              <FormattedMessage id='BoardSpaceSelect.companies' />
            </h2>
            {!gateway && (
              <a
                onClick={onViewAllRedirect}
                className='cursor-pointer d-flex flex-grow-1 fs-14 c-secondary font-weight-bold m-l-10'
              >
                <FormattedMessage id='BoardSpaceSelect.viewAll' />
              </a>
            )}
          </div>
          <div className='search-input-wrapper m-b-20'>
            <input
              type='text'
              value={searchTerm}
              placeholder={intl.formatMessage({ id: 'BoardSpaceSelect.searchCompanies' })}
              className='w-100 form-control'
              onChange={onSearchInputChange}
              ref={searchInputRef}
            />
          </div>
          <div className='search-results' style={Styles.results}>
            {renderBoardSpaceList()}
            {renderLoadMore()}
          </div>
        </div>
      )}
    </div>
  );
});

export default withIntl(withApollo(BoardSpaceSelect));
