import React, { useState } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { PasscodeModal } from './PasscodeModal';
import { VoteNotOpenedModal } from './VoteNotOpenedModal';
import { ViewerDesktop } from './ViewerDesktop';
import { ViewerMobile } from './ViewerMobile';
import { accessToken, votePasscode, voteName, voteEndTime, voteStartDate, voteStartTime, voteEndDate } from './helper';

import { ApolloProvider } from '@apollo/client';
import { client } from 'utils/apollo';

export const Viewer = ({ vote, voter, loggedIn, meetingDrawer, previewDocument }) => {
  const { isDesktop } = useWindowDimensions();
  const [voteStatus, setVoteStatus] = useState(vote.data.attributes.status);
  const initialPasscodeVerified = () => {
    if (meetingDrawer || !votePasscode(vote) || !votePasscode(vote).length) {
      return true;
    }
    return Cookies.get('passcodeVerified') === accessToken();
  };
  const [passcodeVerified, setPasscodeVerified] = useState(initialPasscodeVerified());
  const [showNotOpenedModal, setShowNotOpenedModal] = useState(
    passcodeVerified && voteStatus == 'published' && !meetingDrawer
  );
  const changePasscodeVerified = () => {
    setPasscodeVerified(true);
    if (voteStatus == 'published') {
      return setShowNotOpenedModal(true);
    }
  };
  const changeVoteStatus = (status) => {
    return setVoteStatus(status);
  };

  const voteDocument = vote.data?.attributes?.document && vote.data.attributes.document.data.attributes;

  const pdfDocument = {
    name: voteDocument?.name,
    extension: voteDocument?.extension,
    id: voteDocument?.id,
    url: voteDocument?.url,
    contentType: 'pdf',
  };

  const openPdf = () => previewDocument(pdfDocument);

  return (
    <>
      <ApolloProvider client={client}>
        <PasscodeModal votePasscode={votePasscode(vote)} show={!passcodeVerified} onVerify={changePasscodeVerified} />

        <VoteNotOpenedModal
          voteName={voteName(vote)}
          voteStartDate={voteStartDate(vote)}
          voteStartTime={voteStartTime(vote)}
          voteEndDate={voteEndDate(vote)}
          voteEndTime={voteEndTime(vote)}
          show={showNotOpenedModal}
        />

        {passcodeVerified && !showNotOpenedModal && isDesktop && (
          <ViewerDesktop
            meetingDrawer={meetingDrawer}
            loggedIn={loggedIn}
            vote={vote}
            voteStatus={voteStatus}
            voter={voter}
            changeVoteStatus={changeVoteStatus}
            previewDocument={previewDocument}
            openPdf={openPdf}
          />
        )}
        {passcodeVerified && !showNotOpenedModal && !isDesktop && (
          <ViewerMobile
            meetingDrawer={meetingDrawer}
            loggedIn={loggedIn}
            vote={vote}
            voteStatus={voteStatus}
            voter={voter}
            changeVoteStatus={changeVoteStatus}
            previewDocument={previewDocument}
            openPdf={openPdf}
          />
        )}
      </ApolloProvider>
    </>
  );
};
