/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { usePopper } from 'react-popper';
import { useClickOutside } from 'hooks/useClickOutside';
import { PLACEMENTS } from './constants';

import Icon from './../Icon';
import Menu from './../Menu';

const ActionsMenu = ({ className, children, placement = 'right-start', offset = { skidding: 0, distance: 5 } }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside();

  const [iconRef, setIconRef] = useState();
  const [menuRef, setMenuRef] = useState();

  const { styles, attributes, update } = usePopper(iconRef, menuRef, {
    placement: placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [offset.skidding, offset.distance],
        },
      },
    ],
  });

  const openMenu = () => setIsComponentVisible(true);

  return (
    <div ref={ref} className={className}>
      <Icon icon='dots' setRef={setIconRef} onClick={openMenu} />

      <Menu isOpen={isComponentVisible} setRef={setMenuRef} css={styles.popper} {...attributes.popper}>
        {children}
      </Menu>
    </div>
  );
};

ActionsMenu.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.oneOf(PLACEMENTS),
  className: PropTypes.string,
  offset: PropTypes.shape({
    skidding: PropTypes.number,
    distance: PropTypes.number,
  }),
};

export default ActionsMenu;
