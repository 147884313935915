/** @jsx jsx */

import React, { useEffect, forwardRef, memo, useState } from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { TextField } from './TextField';
import { Label } from './Label';
import { Icon } from './Icon';
import { Error } from './Error';

import { position } from 'styles';

const Input = memo(
  forwardRef(
    (
      {
        id,
        name,
        icon,
        iconPosition,
        rotatableIcon,
        label,
        placeholder,
        isRequired,
        isDisabled,
        isReadOnly,
        errorMessage,
        value,
        labelStyle,
        onChange,
        ...props
      },
      textFieldRef
    ) => {
      const [isFocused, setIsFocused] = useState(false);

      const handleFocus = (event) => {
        setIsFocused(true);
      };

      const handleBlur = () => {
        setIsFocused(false);
      };

      const inputRef = textFieldRef || React.useRef();

      return (
        <div>
          <Label text={label} style={labelStyle} />

          <div css={position.relative}>
            <TextField
              id={id}
              name={name}
              ref={inputRef}
              value={value}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onChange={onChange}
              placeholder={placeholder}
              required={isRequired}
              autoComplete='off'
              iconPosition={iconPosition}
              isDisabled={isDisabled}
              isReadOnly={isReadOnly}
              isInvalid={!!errorMessage}
              {...props}
            />

            <Icon
              icon={icon}
              position={iconPosition}
              isRotatable={rotatableIcon}
              asFocused={isFocused}
              asInvalid={!!errorMessage}
            />

            <Error message={errorMessage} />
          </div>
        </div>
      );
    }
  )
);

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.object,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  label: PropTypes.string,
  labelStyle: PropTypes.any,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export { Input };
