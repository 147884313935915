import React, { useState } from 'react';
import clsx from 'clsx';
import { FiSearch, FiChevronRight } from 'react-icons/fi';
import StateBadge from 'components/v2/shared/StateBadge';
import Pagination from 'components/v2/shared/Pagination';
import Button from 'components/v2/shared/Button/';
import Alert from 'components/v2/shared/Alert';
import Heading from 'components/v2/shared/Heading/';
import Avatar from 'components/v2/shared/Avatar/';
import moment from 'moment';
import SearchBar from '../../shared/SearchBar/SearchBar';

const logo = (url, name) => {
  if (url) {
    return (
      <div className='tw-flex tw-justify-center tw-text-center'>
        <img
          src={url}
          className='tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-white'
        />
      </div>
    );
  } else {
    return <Avatar text={name.slice(0, 2)} />;
  }
};

const SingleCompany = ({
  name,
  slug,
  logoUrl,
  createdAt,
  memberContactCount,
  pendingContactCount,
  primaryForCurrentUser,
  ownedByCurrentUser,
  currentUserRole,
  screen = 'large',
}) => {
  const handleCompanyClick = (link) => {
    window.location.href = link;
  };

  const payment_status = 'asd';

  if (screen === 'large') {
    return (
      <div onClick={() => handleCompanyClick(`/${slug}/settings`)} className='tw-relative '>
        {primaryForCurrentUser && (
          <div className='tw-absolute -tw-top-2.5 tw-left-10'>
            <StateBadge variant='teal' className='tw- tw-px-20 tw-font-sans tw-text-xs tw-font-medium'>
              Primary
            </StateBadge>
          </div>
        )}
        <div
          className={clsx({
            'tw-mb-3.5 tw-mt-7 tw-rounded tw-border tw-border-dashed tw-border-teal-500 ': primaryForCurrentUser,
          })}
        >
          <div className='tw-m-1.5 tw-grid tw-cursor-pointer tw-grid-flow-col tw-grid-cols-6 tw-items-center tw-rounded tw-bg-gray-100 tw-py-5 hover:tw-bg-teal-200'>
            <div className='tw-col-span-3 tw-flex tw-items-center'>
              <span className='tw-px-2'>{logo(logoUrl, name)}</span>
              <span className='tw-px-2 tw-font-sans tw-text-sm tw-font-medium'>{name}</span>
              {payment_status === 'pending' && (
                <div className='tw-px-2'>
                  <Alert
                    description={
                      <span>
                        <span className='tw-font-sans	tw-text-xs tw-font-medium'>Pending payment </span>
                        <a className='tw-ml-auto tw-cursor-pointer tw-px-2 tw-font-sans tw-text-xs tw-font-medium tw-text-orange-600 tw-underline hover:tw-text-orange-600 hover:tw-opacity-80'>
                          Pay now
                        </a>
                      </span>
                    }
                    severity='warning'
                    isDismissable={false}
                    className='!tw-p-1'
                  />
                </div>
              )}
            </div>
            <div className='tw-col-span-1 tw-text-center tw-font-sans tw-text-xs tw-font-medium'>
              {memberContactCount}
            </div>
            <div className='tw-col-span-1 tw-text-center tw-font-sans tw-text-xs tw-font-medium'>
              {pendingContactCount}
            </div>
            <div className='tw-col-span-1 tw-text-center'>
              {currentUserRole == 'Owner' ? (
                <StateBadge variant='admin' className='!tw-bg-purple-200 tw-font-sans tw-text-xs tw-font-medium'>
                  {currentUserRole}
                </StateBadge>
              ) : (
                <StateBadge
                  variant='admin'
                  className='!tw-bg-purple-100 tw-font-sans tw-text-xs tw-font-medium tw-text-black'
                >
                  {currentUserRole}
                </StateBadge>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div onClick={() => handleCompanyClick(`/${slug}/settings`)} className='tw-relative !tw-text-xs'>
      {primaryForCurrentUser && (
        <div className='tw-absolute -tw-top-3.5 tw-left-10'>
          <StateBadge variant='teal' className='tw-px-10 tw-font-sans tw-text-xs tw-font-medium sm:tw-px-20'>
            Primary
          </StateBadge>
        </div>
      )}
      <div
        className={clsx({
          'tw-my-3 tw-rounded tw-border tw-border-dashed tw-border-teal-500': primaryForCurrentUser,
        })}
      >
        <div className='tw-grid-row-2 tw-m-1.5 tw-grid tw-cursor-pointer tw-grid-flow-row tw-rounded tw-bg-gray-100 hover:tw-bg-teal-200'>
          <div className=' tw-grid tw-grid-flow-col tw-grid-cols-6 tw-items-center tw-py-2 sm:tw-grid-cols-9 sm:tw-py-3'>
            <div className='tw-col-span-1'>{logo(logoUrl, name)}</div>
            <div className='tw-col-span-4 tw-font-sans tw-text-sm tw-font-medium sm:tw-col-span-7'>{name}</div>
            <div className='tw-col-span-1 tw-text-center'>
              <FiChevronRight size={20} color='#9091A9' />
            </div>
          </div>
          <div className='tw-grid tw-grid-flow-col tw-grid-cols-6 tw-items-center tw-py-2 tw-text-gray-700 sm:tw-grid-cols-9 sm:tw-py-3'>
            <div className='tw-col-span-1 tw-hidden tw-text-center sm:tw-block'></div>
            <div className='tw-col-span-2 tw-flex tw-justify-center sm:tw-col-span-1 sm:tw-justify-start	'>
              {currentUserRole == 'Owner' ? (
                <StateBadge variant='admin' className='!tw-bg-purple-200 tw-font-sans tw-text-xs tw-font-medium'>
                  {currentUserRole}
                </StateBadge>
              ) : (
                <StateBadge
                  variant='admin'
                  className='!tw-bg-purple-100 tw-font-sans tw-text-xs tw-font-medium tw-text-black'
                >
                  {currentUserRole}
                </StateBadge>
              )}
            </div>
            <div className='tw-col-span-2 tw-text-center'>
              <span className='tw-text-700 tw-font-sans tw-text-xs tw-font-medium'>Members</span> &nbsp;
              <span className='tw-text-xs tw-font-medium tw-text-black'>{memberContactCount}</span>
            </div>
            <div className='tw-col-span-2 tw-text-center'>
              <span className='tw-text-700 tw-font-sans tw-text-xs tw-font-medium'>Invited</span>&nbsp;
              <span className='tw-font-sans tw-text-xs tw-font-medium tw-text-black'>{pendingContactCount}</span>
            </div>
            <div className='tw-text-700 tw-col-span-1 tw-hidden tw-text-center tw-font-sans tw-text-xs tw-font-medium sm:tw-block'>
              {moment(createdAt).format('MMM YYYY')}
            </div>
            <div className='tw-col-span-1 tw-hidden tw-text-right sm:tw-block'>
              <Avatar text='AD' color='lightPurple' />
            </div>
            <div className='tw-col-span-1 tw-hidden tw-text-center sm:tw-block'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompanyList = ({ companies }) => {
  return (
    <>
      <div className='tw-grid tw-hidden tw-grid-flow-row !tw-text-sm lg:tw-block'>
        <div className='tw-grid tw-grid-flow-col tw-grid-cols-6'>
          <div className='tw-col-span-3 tw-font-sans tw-text-xs tw-font-medium'>Name</div>
          <div className='tw-col-span-1 tw-text-center tw-font-sans tw-text-xs tw-font-medium'>Members</div>
          <div className='tw-col-span-1 tw-text-center tw-font-sans tw-text-xs tw-font-medium'>Pending</div>
          <div className='tw-col-span-1 tw-text-center tw-font-sans tw-text-xs tw-font-medium'>Your role</div>
        </div>
        {companies.map((company) => {
          return <SingleCompany key={company.id} {...company} />;
        })}
      </div>
      <div className='lg:tw-hidden'>
        {companies.map((company) => {
          return <SingleCompany key={company.id} {...company} screen='medium' />;
        })}
      </div>
    </>
  );
};

const Companies = ({ companies, pageInfo, onClick, onFetchMore, refetch }) => {
  let searchTimeout;
  const [search, setSearch] = useState('');

  const handleSearchChange = async (e) => {
    clearTimeout(searchTimeout);
    const val = e.target.value.toLowerCase();

    searchTimeout = setTimeout(() => {
      try {
        setSearch(val);
        refetch({ name: val });
      } catch (error) {
        console.log(error);
      }
    }, 2000);
  };

  return (
    <div className='tw-flex tw-justify-center'>
      <div className='tw-w-full tw-max-w-5xl tw-pb-20'>
        <div className='tw-mt-14 tw-px-5 sm:tw-flex sm:tw-items-center sm:tw-px-10'>
          <div className='tw-flex tw-w-full tw-items-center tw-justify-between tw-py-6 sm:tw-pr-5 '>
            <Heading text='Companies' className='!tw-m-0 !tw-text-xl !tw-font-bold sm:!tw-text-2xl lg:!tw-text-3xl' />
            <SearchBar onChange={handleSearchChange} />
          </div>
          <div className='tw-ml-auto tw-grid tw-grid-flow-row tw-place-content-center tw-items-center tw-gap-1.5 sm:tw-grid-flow-col'>
            <div>
              <Button
                onClick={() => onClick('add')}
                variant='teal'
                size='small'
                className='tw-h-[38px] tw-w-[90vw] sm:tw-w-full'
              >
                Create new board
              </Button>
            </div>
            <div className='sm:tw-w-full'>
              <Button
                onClick={() => onClick('existing')}
                variant='purple'
                size='small'
                className='tw-h-[38px] tw-w-[90vw] sm:tw-w-full'
              >
                Join another board
              </Button>
            </div>
          </div>
        </div>
        <div className='tw-mt-3 tw-p-5 !tw-leading-none sm:tw-m-0'>
          <CompanyList companies={companies} />
        </div>
        <div className='tw-content-center tw-pt-6 '>
          <Pagination {...pageInfo} onChange={onFetchMore} />
        </div>
      </div>
    </div>
  );
};
export default Companies;
