import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from 'utils/apollo';

export const withApollo = (Component) => (props) =>
  (
    <ApolloProvider client={client}>
      <Component {...props} />
    </ApolloProvider>
  );
