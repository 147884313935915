import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const Variants = {
  Teal: 'teal',
  Purple: 'purple',
};

const RadioButton = ({ variant = Variants.Purple, name, label, value, isDisabled, isChecked, onChange }) => (
  <label
    className={clsx('tw-relative tw-m-0 tw-inline-flex tw-items-center tw-gap-2', {
      'tw-cursor-pointer': !isDisabled,
      'tw-cursor-not-allowed tw-opacity-30': isDisabled,
    })}
  >
    <input
      className='tw-peer tw-invisible tw-absolute tw-inset-0 tw-m-0 tw-h-full tw-w-full'
      type='radio'
      name={name}
      disabled={isDisabled}
      onChange={onChange}
      checked={isChecked}
      value={value}
    />

    <span
      className={clsx(
        'tw-inline-flex tw-items-center tw-justify-center',
        'tw-box-border tw-h-4 tw-w-4 tw-rounded-full',
        'tw-border tw-border-solid tw-border-gray-500',
        {
          'hover:tw-border-teal-500 peer-checked:tw-border-teal-500': variant === Variants.Teal,
          'hover:tw-border-purple-500 peer-checked:tw-border-purple-500': variant === Variants.Purple,
        }
      )}
    >
      <span
        className={clsx('tw-h-2 tw-w-2 tw-rounded-full', {
          'tw-scale-0': !isChecked,
          'tw-scale-100': isChecked,
          'tw-bg-teal-500': variant === Variants.Teal,
          'tw-bg-purple-500': variant === Variants.Purple,
        })}
      />
    </span>

    <span className='tw-mt-0.5 tw-text-sm tw-font-medium tw-leading-none tw-text-black empty:tw-hidden'>{label}</span>
  </label>
);

RadioButton.propTypes = {
  variant: PropTypes.oneOf(Object.values(Variants)),
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RadioButton;
