/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { Input } from 'components/shared/Input';

import { ReactComponent as SearchIcon } from 'images/icons/documents/search-icon.svg';
import { ReactComponent as ClearIcon } from 'images/icons/purple-cross.svg';

import * as styles from './Search.styles';

const Search = memo(({ value, isFocused, onFocus, onBlur, onChange }) => {
  const wrapperStyles = [styles.wrapper.base, isFocused && styles.wrapper.focused];

  const intl = useIntl();

  return (
    <div css={wrapperStyles} onFocus={onFocus}>
      <Input
        placeholder={intl.formatMessage({ id: 'Search.searchPlaceholder' })}
        iconPosition='left'
        value={value}
        onChange={onChange}
        css={styles.input}
        icon={<SearchIcon />}
      />

      {isFocused && <ClearIcon css={styles.clear} onClick={onBlur} />}
    </div>
  );
});

export { Search };
