import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const text = {
  base: ({ fontSize }) => css`
    ${fonts.regular}
    display: inline-block;
    line-height: 1.2;
    font-size: ${fontSize}px;
  `,

  medium: css`
    ${fonts.medium}
  `,

  semiBold: css`
    ${fonts.semiBold}
  `,

  bold: css`
    ${fonts.bold}
  `,

  underline: css`
    text-decoration: underline;
  `,

  italic: css`
    font-style: italic;
  `,

  black: css`
    color: ${colors.black};
  `,

  white: css`
    color: ${colors.white};
  `,

  lightGrey: css`
    color: ${colors.lightGrey};
  `,

  mediumGrey: css`
    color: ${colors.mediumGrey};
  `,

  cyan: css`
    color: ${colors.cyan};
  `,

  truncated: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
};
