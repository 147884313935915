import { useState, useEffect } from 'react';
import { useCompanyQuery } from './../data/queries/CompanyQuery';

const useFetchCompany = ({ companyId }) => {
  const [fetchedData, setFetchedData] = useState({
    contacts: [],
    meetings: [],
  });

  const updateCompanyData = (updates) => setFetchedData((state) => ({ ...state, ...updates }));

  const { data: companyData } = useCompanyQuery({});

  useEffect(() => setCompanyData(), [companyData]);

  const setCompanyData = () => {
    if (!companyData) return;

    const { contacts, meetings } = companyData.currentCompany;

    updateCompanyData({ contacts: contacts, meetings: meetings });
  };

  return {
    ...fetchedData,
  };
};

export { useFetchCompany };
