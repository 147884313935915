import { css } from '@emotion/core';

export const regular = css`
  font-family: 'Gilroy-Regular', sans-serif;
`;

export const semiBold = css`
  font-family: 'Gilroy-Semibold', sans-serif;
`;

export const bold = css`
  font-family: 'Gilroy-Bold', sans-serif;
`;

export const medium = css`
  font-family: 'Gilroy-Medium', sans-serif;
`;

export const extraBold = css`
  font-family: 'Gilroy-Extrabold', sans-serif;
`;

export const bethEllen = css`
  font-family: 'BethEllen-Regular', sans-serif;
`;

export const dancingScript = css`
  font-family: 'DancingScript-Regular', sans-serif;
`;

export const dawning = css`
  font-family: 'Dawning-Regular', sans-serif;
`;

export const glory = css`
  font-family: 'GiveYouGlory-Regular', sans-serif;
  line-height: 40px;
`;

export const sheppards = css`
  font-family: 'MrsSheppards-Regular', sans-serif;
`;

export const styledLight = `font-family: 'Gilroy-Light', sans-serif;`;

export const styledRegular = `font-family: 'Gilroy-Regular', sans-serif;`;

export const styledMedium = `font-family: 'Gilroy-Medium', sans-serif;`;

export const styledSemiBold = `font-family: 'Gilroy-Semibold', sans-serif;`;

export const styledBold = `font-family: 'Gilroy-Bold', sans-serif;`;

export const styledExtraBold = `font-family: 'Gilroy-Extrabold', sans-serif;`;
