import React, { Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { GoCode } from 'react-icons/go';
import clsx from 'clsx';

const Variants = {
  Category: 'category',
  Group: 'group',
};

const people = [
  { id: 1, name: 'View by A-Z' },
  { id: 2, name: 'View by Z - A' },
  { id: 3, name: 'View by status' },
  { id: 4, name: 'View by role' },
];

const Filter = ({ variant }) => {
  const [selected, setSelected] = useState(people[0]);
  const [query, setQuery] = useState('');

  const filteredPeople =
    query === ''
      ? people
      : people.filter((person) =>
          person.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        );
  return (
    <div className='tw-h-[38px]'>
      <Combobox value={selected} onChange={setSelected}>
        <div className='tw-relative'>
          <div
            className={clsx(
              'rounded tw-flex tw-w-[119px] tw-cursor-default tw-overflow-hidden tw-text-left tw-shadow-md focus:tw-outline-none focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2 sm:tw-text-sm',
              {
                'tw-flex-row-reverse tw-rounded-sm tw-bg-gray-100': variant === Variants.Category,
                'tw-flex-row tw-bg-white': variant === Variants.Group,
              }
            )}
          >
            <Combobox.Input
              className={clsx(
                'rounded tw-w-full !tw-overflow-hidden tw-border-none	tw-text-center tw-font-sans tw-text-xs tw-leading-5 focus:tw-ring-0',
                {
                  'tw-bg-gray-100 tw-pl-0 tw-pr-0': variant === Variants.Category,
                  'tw-h-11 tw-bg-white tw-pl-0 tw-pr-0': variant === Variants.Group,
                }
              )}
              displayValue={(person) => person.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button
              className={clsx('tw-flex tw-items-center tw-border-none tw-bg-white tw-font-sans', {
                'tw-bg-gray-100': variant === Variants.Category,
                'tw-bg-white': variant === Variants.Group,
              })}
            >
              <GoCode className=' tw-text-purple-500' style={{ transform: 'rotate(90deg)' }} aria-hidden='false' />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave='tw-transition ease-in duration-100'
            leaveFrom='tw-opacity-100'
            leaveTo='tw-opacity-0'
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className='tw-absolute tw-z-50 tw-mt-1 tw-max-h-60 tw-w-full tw-list-outside tw-list-none tw-overflow-auto tw-rounded-md tw-bg-white tw-px-2 tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-text-sm'>
              {filteredPeople.length === 0 && query !== '' ? (
                <div className='tw-relative tw-cursor-default tw-select-none tw-py-2 tw-px-4 tw-text-gray-700'>
                  Nothing found.
                </div>
              ) : (
                filteredPeople.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `tw-relative tw-cursor-default tw-select-none tw-text-sm ${
                        active ? 'tw-bg-teal-600 tw-text-white' : 'tw-text-gray-900'
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`tw-block tw-truncate ${selected ? 'tw-font-medium' : 'tw-font-normal'}`}>
                          {person.name}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default Filter;
