import { css } from '@emotion/core';
import { utils, colors } from 'styles';

export const empty = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const heading = css`
  ${utils.uppercasedText}
  width: 100%;
  text-align: center;
`;

export const wrapper = css`
  color: ${colors.mediumGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-left: 17px;
  flex-grow: 1;
  line-height: 1;
`;

export const component = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.cyan};
  font-size: 16px;
  margin-top: 10px;

  svg {
    flex-shrink: 0;
    margin-right: 7px;
  }
`;

export const addTask = css`
  display: inline-flex;
  cursor: pointer;

  &:hover {
    svg {
      opacity: 0.8;
    }
  }
`;
