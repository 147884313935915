import { useState, useEffect } from 'react';
import resolveTailwindConfig from 'tailwindcss/resolveConfig';
import themeConfig from 'theme.config.js';

export default function useWindowDimensions() {
  const tailwindConfig = resolveTailwindConfig(themeConfig);
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    const isTablet = (width > 767 && width < 992) ?? false;
    const isMobile = width < 768 ?? false;
    const isDesktop = width > 991 ?? false;
    const isLargeDesktop = width >= Number.parseInt(tailwindConfig.theme.screens.xl);

    return {
      width,
      height,
      isTablet,
      isMobile,
      isDesktop,
      isLargeDesktop,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}
