import { useState } from 'react';
import { useDeleteResolutionDocumentMutation } from './../data/mutations/DeleteResolutionDocumentMutation';

const useDeleteResolutionDocument = ({ onCompleted }) => {
  const update = (cache, { data: { deleteResolutionDocument } }) => {
    const { resolutionDocument } = deleteResolutionDocument;

    onCompleted(resolutionDocument.id);
  };

  const [mutate, { loading: isDeleting }] = useDeleteResolutionDocumentMutation({ update });

  const deleteResolutionDocument = (resolutionDocumentId) =>
    mutate({ variables: { input: { resolutionDocumentId: resolutionDocumentId } } });

  return {
    isDeleting,
    deleteResolutionDocument,
  };
};

export { useDeleteResolutionDocument };
