/** @jsx jsx */

import React, { memo, useCallback } from 'react';
import { css, jsx } from '@emotion/core';

import * as styles from './index.styles';

const Line = ({ header, children }) => {
  return (
    <div css={styles.line}>
      <p css={styles.header}>{header}:</p>
      <div css={[styles.statuses, styles.textOverflow]}>{children}</div>
    </div>
  );
};

const DateLabel = ({ day, month, time }) => (
  <div css={styles.dateLabel}>
    <div css={[styles.dateLabelPart, styles.dateLabelDay]}>
      {day} {month}
    </div>
    <div css={[styles.dateLabelPart, styles.dateLabelTime]}>{time}</div>
  </div>
);

export default memo(({ minutes, name, owner, approver, link, type, dasherize, status, index, day, month, time }) => {
  const isLast = minutes.length - 1 === index;

  const redirectToMinuteOverview = useCallback(() => {
    window.location.assign(link);
  }, [link]);

  return (
    <div css={styles.card(isLast)} onClick={redirectToMinuteOverview}>
      <Line header='date'>
        <DateLabel day={day} month={month} time={time} />
      </Line>
      <Line header='Minutes name'>{name}</Line>
      <Line header='Meeting type'>{type}</Line>
      <Line header='Author'>{owner}</Line>
      <Line header='Approver'>{approver}</Line>
      <Line header='Status'>
        <span className={`badge badge-large badge-${dasherize}`}>{status}</span>
      </Line>
      {/* <div css={styles.dotsBlock}></div> */}
    </div>
  );
});
