import React, { useRef, useEffect, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GroupsTableRow } from './../GroupsTableRow';
import { Spinner } from 'components/shared/Spinner';
import { PlaceholderTable } from './../PlaceholderTable';
import { FadingOverlay } from 'components/shared/PlaceholderTable';
import { useContactGroupsQuery } from './../data/queries/ContactGroupsQuery';
import { sort } from 'fast-sort';

const GroupsTable = memo(({ onEdit }) => {
  const { data, loading, refetch } = useContactGroupsQuery();
  const ref = useRef();

  const intl = useIntl();

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('groups:reload', refetch);

      return () => {
        node.removeEventListener('groups:reload', refetch);
      };
    }
  }, [data]);

  if (loading) {
    return <Spinner show />;
  }

  const { contactGroups } = data.currentCompany;

  const tableRows = sort(contactGroups)
    .desc((item) => item.createdAt)
    .map((group) => {
      const handleEdit = onEdit.bind(this, group);
      return <GroupsTableRow key={group.id} name={group.name} size={group.size} id={group.id} onEdit={handleEdit} />;
    });

  return (
    <div className='position-relative'>
      <FadingOverlay show={!contactGroups.length} resource={intl.formatMessage({ id: 'GroupsTable.groups' })} />
      <table className='table table-layout-fixed contact-groups-table' ref={ref}>
        <thead>
          <tr>
            <th className='w-70'>
              <FormattedMessage id='GroupsTable.name' />
            </th>
            <th className='w-auto' />
          </tr>
        </thead>

        <tbody>
          {tableRows}
          <PlaceholderTable show={!contactGroups.length} />
        </tbody>
      </table>
    </div>
  );
});

export { GroupsTable };
