import React, { memo, useRef, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Modal from 'react-bootstrap/Modal';
import Avatar from '../shared/Avatar';

import closeIcon from 'images/icons/close-icon-black.svg';

export default memo(({ contacts, onHide, show, header }) => {
  const searchInputRef = useRef(null);

  const intl = useIntl();

  let [filteredContacts, setFilteredContacts] = useState(contacts);

  useEffect(() => {
    setFilteredContacts(contacts);
  }, [contacts]);

  const closeBtn = (
    <button className='close' aria-label='Close' onClick={onHide}>
      <span aria-hidden='true'>
        <img src={closeIcon} />
      </span>
    </button>
  );

  const onModalEnter = () => {
    searchInputRef.current.focus();
    setFilteredContacts(contacts);
  };

  const onSearchInputChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();

    if (searchTerm.length === 0) {
      setFilteredContacts(contacts);
    } else {
      setFilteredContacts(contacts.filter(({ name }) => name.toLowerCase().includes(searchTerm)));
    }
  };

  const contactsList = filteredContacts.map((contact) => {
    return (
      <div className='search-result d-flex align-items-center justify-content-between' key={contact.id}>
        <div className='d-flex align-items-center'>
          <Avatar contact={contact} />
          <div className='contact-name'>{contact.name}</div>
        </div>
      </div>
    );
  });

  return (
    <Modal className='shaparency-contact-picker' show={show} onEnter={onModalEnter} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{header || intl.formatMessage({ id: 'ContactsListModal.contacts' })}</Modal.Title>
        {closeBtn}
      </Modal.Header>
      <Modal.Body>
        <div className='search-input-wrapper'>
          <input
            type='text'
            placeholder='Search contacts'
            className='w-100 form-control'
            onChange={onSearchInputChange}
            ref={searchInputRef}
          />
        </div>
        <div className='search-results'>{contactsList}</div>
        <div className='no-results'>
          <FormattedMessage id='ContactsListModal.noResults' />
        </div>
      </Modal.Body>
    </Modal>
  );
});
