/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';

import CollapsedAgendaItem from './../CollapsedAgendaItem';
import RadioButton from 'components/v2/shared/RadioButton';
import TextInput from 'components/v2/shared/TextInput';
import Text from 'components/v2/shared/Text';
import TextButton from 'components/v2/shared/TextButton';
import LabeledRow from 'components/v2/shared/LabeledRow';
import Button, { Variants } from 'components/v2/shared/Button';

import * as styles from './AgendaItem.styles';

const AgendaItem = ({ title, isExpanded = false }) => {
  if (!isExpanded) return <CollapsedAgendaItem title={title} className='mb-2' />;

  return (
    <div css={styles.container} className='mb-2'>
      <Row noGutters className='mb-2'>
        <Col>
          <Text isBold text={title} />
        </Col>
      </Row>

      <Row noGutters className='mb-2'>
        <Col lg={3}>
          <RadioButton name='type' label='Task based' />
        </Col>

        <Col>
          <RadioButton name='type' label='Vote based' />
        </Col>
      </Row>

      <Row noGutters className='mb-2'>
        <Col>
          <TextInput label='Title of agenda' placeholder='Name your agenda item' />
        </Col>
      </Row>

      <Button className='mb-2' isFullWidth variant={Variants.Teal}>
        Save title and description
      </Button>

      <LabeledRow label='Tasks' className='mb-2'>
        <TextButton text='add' />
      </LabeledRow>

      <LabeledRow label='Duration' className='mb-2'>
        <TextButton text='add' />
      </LabeledRow>

      <LabeledRow label='Documents' className='mb-2'>
        <TextButton text='add' />
      </LabeledRow>

      <LabeledRow label='Assignee' className='mb-2'>
        <TextButton text='add' />
      </LabeledRow>
    </div>
  );
};

AgendaItem.propTypes = {
  title: PropTypes.string,
  isExpanded: PropTypes.bool,
};

export default AgendaItem;
