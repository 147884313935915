import React, { useState, useContext, useCallback, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Heading from 'components/v2/shared/Heading';
import TextInput from 'components/v2/shared/TextInput';
import SelectInput from 'components/v2/shared/SelectInput';
import Button from 'components/v2/shared/Button';
import StateBadge from 'components/v2/shared/StateBadge';
import { Listbox, Transition } from '@headlessui/react';
import { FiChevronRight, FiX, FiInfo } from 'react-icons/fi';
import { HiChevronDown } from 'react-icons/hi2';
import { getCurrentBreakpoint } from 'helpers/tailwindStyling';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Label from 'components/v2/shared/Label';
import Box from 'components/v2/shared/Box';
import Modal from 'components/v2/shared/Modal';
import clsx from 'clsx';
import Spinner from '../shared/Spinner/Spinner';
import Toast, { Severity } from '../shared/Toast';
import { ToastContext } from 'contexts/v2/ToastContext';
import { useSubcommitteesQuery } from '../../subcommittees/data/queries/SubcommitteesQuery';
import { useCreateSubcommittee } from '../../subcommittees/hooks/useCreateSubcommittee';
import { useUpdateSubcommittee } from '../../subcommittees/hooks/useUpdateSubcommittee';
import { useDeleteSubcommittee } from '../../subcommittees/hooks/useDeleteSubcommittee';

const InvitedMembers = ({
  accessRoles,
  initials,
  name,
  role,
  email,
  isMember,
  status,
  removeInviteMember,
  removeOutsideInvitee,
  updateInviteeRole,
  isReadOnly,
  index,
}) => {
  let selectedRole = role ? accessRoles.filter((accessRole) => accessRole.id === role)[0] : accessRoles[0];
  return (
    <div className='tw-mt-1 tw-grid tw-grid-flow-col tw-grid-cols-12 tw-items-center tw-rounded tw-bg-gray-100 tw-py-5 tw-px-3 sm:tw-grid-cols-10'>
      <div className='tw-col-span-1 tw-flex tw-justify-center'>
        <div className='tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-[#AFB0D0]  tw-object-contain tw-font-sans tw-text-sm tw-font-semibold  tw-text-white'>
          {initials ? initials : 'SHP'}
        </div>
      </div>
      <div className={isMember ? 'tw-col-span-5' : 'tw-col-span-4'}>
        <div
          className={clsx('tw-ml-2 tw-truncate tw-font-sans tw-font-medium tw-text-black', {
            'tw-text-xs': email,
            'tw-text-sm': !email,
          })}
        >
          {email ?? name}
        </div>
        <div className='tw-ml-2 tw-truncate tw-font-sans tw-font-medium'>{selectedRole.name}</div>
      </div>
      {!isMember && (
        <div className={clsx('tw-col-span-3 tw-justify-self-end', { 'tw-col-span-6 tw-w-1/2': !status })}>
          <RoleSelect
            items={accessRoles}
            selectedRole={selectedRole}
            index={index}
            isMember={name !== undefined}
            updateInviteeRole={updateInviteeRole}
          />
        </div>
      )}
      <div className={`tw-col-span-3 tw-flex ${isMember ? 'tw-justify-end' : 'tw-justify-center'}`}>
        {status && (
          <StateBadge
            className='tw-col-span-2 !tw-mx-0 tw-mb-5 tw-w-fit !tw-justify-end tw-justify-self-end sm:tw-col-span-3 sm:tw-mb-0 sm:tw-justify-self-end'
            variant={
              status === 'archived'
                ? 'faint'
                : status === 'registered'
                ? 'green'
                : status === 'confirmed'
                ? 'orange'
                : (status === 'invited' || status === 'reinvited')
                ? 'purple'
                : 'gray'
            }
          >
            {status === 'archived'
              ? 'Archived'
              : status === 'registered'
              ? 'Active'
              : status === 'confirmed'
              ? 'Confirmed'
              : (status === 'invited' || status === 'reinvited')
              ? 'Invited'
              : ''}
          </StateBadge>
        )}
      </div>
      {!isReadOnly && (
        <div className='tw-col-span-2 tw-mr-2.5 tw-flex !tw-justify-center sm:!tw-col-span-1 sm:tw-mr-0 sm:tw-justify-end'>
          {removeOutsideInvitee ? (
            <FiX
              className='tw-cursor-pointer tw-text-gray-700'
              onClick={() => {
                removeOutsideInvitee(index);
              }}
            />
          ) : (
            <FiX
              className='tw-cursor-pointer tw-text-gray-700'
              onClick={() => {
                removeInviteMember(index);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

const RoleSelect = ({ items, index, selectedRole, isMember, updateInviteeRole }) => {
  const [selected, setSelected] = useState(selectedRole);

  useEffect(() => {
    updateInviteeRole(index, selected, isMember);
  }, [selected]);

  return (
    <Listbox value={selected} onChange={setSelected} className='tw-mr-1'>
      <div className='tw-relative'>
        <Listbox.Button className='tw-flex tw-h-8 tw-w-full tw-cursor-default tw-items-center tw-justify-between tw-rounded-md tw-border-none tw-bg-[#d7dbe2] tw-px-2'>
          <span className='tw-flex tw-items-center tw-truncate tw-font-sans tw-text-xs tw-font-medium tw-text-[#787d96]'>
            {selected.name}
          </span>
          <HiChevronDown className='tw-text-[#787d96]' />
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave='tw-transition tw-ease-in tw-duration-100'
          leaveFrom='tw-opacity-100'
          leaveTo='tw-opacity-0'
        >
          <Listbox.Options className='tw-absolute tw-z-[1000] tw-mt-1 tw-w-full tw-rounded tw-bg-white tw-pl-0 tw-text-sm tw-shadow-xl'>
            {items.map((item) => (
              <Listbox.Option
                as='div'
                key={item.id}
                className={({ active }) =>
                  `tw-relative tw-select-none tw-p-2 ${active ? 'tw-bg-teal-500 tw-text-white' : 'tw-text-black'}`
                }
                value={item}
              >
                {({ selected }) => (
                  <>
                    <span className={`tw-block tw-truncate ${selected ? 'tw-font-extrabold' : 'tw-font-normal'}`}>
                      {item.name}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

const EmptyList = ({ createNewGroup }) => {
  return (
    <div
      className='sm:tw-flex sm:tw-w-full sm:tw-justify-center md:tw-block
      xl:tw-border-0
      xl:tw-border-r
      xl:tw-border-solid
      xl:tw-border-gray-200
      xl:tw-pr-9'
    >
      <div className='tw-flex tw-items-center tw-justify-between'>
        <div className='tw-col-span-3'>
          <Heading text='Subcommittees' className='!tw-mb-0 !tw-text-[26px] tw-font-bold' />
        </div>
        <div
          onClick={createNewGroup}
          className='tw-text-s tw-cursor-pointer tw-font-sans tw-font-semibold tw-text-teal-500'
        >
          Create new subcommittee
        </div>
      </div>
      <div className='tw-mt-8'>
        <div className='tw-box-border tw-flex tw-items-center tw-justify-between tw-rounded tw-bg-orange-200 tw-p-6'>
          <FiInfo className='tw-mr-3' size={28} color='orange' />
          <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>
            No subcommittee currently exists. Please click 'create new subcommittee' to create one.
          </div>
        </div>
      </div>
    </div>
  );
};

const OutsideContactInviteForm = ({ onAddEmail }) => {
  const schema = z.object({
    email: z
      .string({
        required_error: 'Email address is required',
        invalid_type_error: 'Invalid value',
      })
      .email('Invalid email format'),
  });

  const { handleSubmit, control } = useForm({
    resolver: zodResolver(schema),
    defaultValues: { email: '' },
  });

  return (
    <form onSubmit={handleSubmit(onAddEmail)}>
      <div className='tw-mt-5'>
        <Controller
          control={control}
          name='email'
          render={({ field, fieldState: { error } }) => (
            <div className='tw-grid tw-grid-cols-12 tw-flex-col-reverse tw-gap-2.5'>
              <div className='tw-col-span-10'>
              <label className='tw-mb-2 tw-block tw-text-black tw-font-sans tw-text-xs tw-font-semibold'>Invite people from outside your organization</label>
                <TextInput placeholder='Type email and click add' size='large' {...field} errorMessage={error?.message} />
              </div>
              <div className='tw-mt-6 tw-col-span-2 tw-flex tw-justify-end'>
                <Button font='semibold' isFullWidth size='large' variant='teal' className='tw-h-[56px] tw-py-4'>
                  Add
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    </form>
  );
};

const Subcommittee = () => {
  const {
    register,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [groups, setGroups] = useState([]);
  const [isGroupName, setGroupName] = useState('');
  const [isActiveGroupName, setActiveGroupName] = useState(false);
  const [isReadOnly, setReadOnly] = useState(true);
  const [isIndexGroup, setIndexGroup] = useState();
  const [isAddGroup, setIsAddGroup] = useState(false);
  const [isActiveInvitedMember, setActiveInvitedMember] = useState(false);
  const [isInvitedMemberData, setInvitedMemberData] = useState([]);
  const [isOutsideInviteeData, setOutsideInviteeData] = useState([]);
  const [isGroupData, setIsGroupData] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [isAddUser, setAddUser] = useState(false);
  const [isDeleteGroup, setIsDeleteGroup] = useState(false);
  const [isUpdateGroup, setIsUpdateGroup] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState('');
  const { toastInfo, setToastInfo } = useContext(ToastContext);
  const [parentCompanyContacts, setParentCompanyContacts] = useState([]);
  const [accessRoles, setAccessRoles] = useState([]);
  const dimensions = useWindowDimensions();
  const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint(dimensions.width));

  const { data, refetch, loading } = useSubcommitteesQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setBreakpoint(getCurrentBreakpoint(dimensions.width));
  }, [dimensions.width]);

  useEffect(() => {
    let groupList = data?.currentCompany?.subcommittees;
    let parentContacts = data?.currentCompany?.contacts;
    setParentCompanyContacts(data?.currentCompany?.contacts);
    setAccessRoles(data?.currentCompany?.accessRoles);

    if (groupList?.length > 0) {
      setGroupData([]);
      for (let group of groupList) {
        let groupObj = { ...group, groupName: group.name };
        setGroupData((prevVal) => [...prevVal, groupObj]);
      }
    }

    if (parentContacts?.length > 0) {
      let contactList = parentContacts.map((contact) => {
        return { ...contact, label: contact.name, value: contact.name, initials: contact.initials };
      });
      setGroups(contactList);
    }
  }, [data]);
  const companyId = data?.currentCompany?.id;
  const onError = useCallback((errors) => {
    setToastInfo({
      severity: Severity.Error,
      status: 'Error',
      message: errors[0].message,
    });
  }, []);
  const { createSubcommittee } = useCreateSubcommittee({ onError, companyId });
  const { updateSubcommittee } = useUpdateSubcommittee({ onError });
  const { deleteSubcommittee } = useDeleteSubcommittee({ onError });

  const onSubmit = async (subcom) => {
    const groupName = subcom.groupName;
    if (groupName) {
      const invitedMembers = [...isInvitedMemberData, ...isOutsideInviteeData].map((member) => ({
        email: member.email,
        accessRoleId: member.accessRoleId,
      }));

      try {
        const result = await createSubcommittee({
          name: groupName,
          contacts: invitedMembers,
        })
        setGroupData((groups) => [
          ...groups,
          {
            id: result.data.createSubcommittee.subcommittee.id,
            groupName: groupName,
            contacts: invitedMembers.map((contact) => {
              return { ...contact,
                initials: contact.initials ? contact.initials : 'shp',
                name: contact.name ? contact.name : contact.email,
                status: contact.status ? contact.status : 'invited',
              }})
          },
        ]);
        setIsAddGroup(false);
        setToastInfo({
          severity: Severity.Success,
          status: 'Success',
          message: 'Created successfully',
        });
      } catch (error) {
        setToastInfo({
          severity: Severity.Error,
          status: 'Error',
          message: 'Error creating subcommittee',
        });
      }
    }
  };

  const createNewGroup = () => {
    setIsAddGroup(true);
    setReadOnly(false);
    setActiveGroupName(false);
    setIndexGroup(false);
    resetField('groupName');
    setInvitedMemberData([]);
    setOutsideInviteeData([]);
  };
  const onClickGroupName = (data, index) => {
    setInvitedMemberData(data?.contacts);
    setOutsideInviteeData([]);
    setGroupName(data?.groupName);
    setIndexGroup(index);
    setActiveGroupName(true);
    setReadOnly(true);
    setIsAddGroup(false);
  };
  const onClickEdit = (value) => {
    setReadOnly(value);
    setIsUpdateGroup(true);
  };
  const deleteGroup = () => {
    let newGroupData = [...groupData];
    newGroupData.splice(isIndexGroup, 1);
    deleteSubcommittee({ subcommitteeId: groupData[isIndexGroup].id });
    setGroupData(newGroupData);
    setConfirmDelete('');
    setToastInfo({
      severity: Severity.Success,
      status: 'Success',
      message: 'Deleted successfully',
    });
    setActiveGroupName(false);
    setIsDeleteGroup(false);
    setInvitedMemberData([]);
    setOutsideInviteeData([]);
    if (newGroupData.length == 0) {
      setIsAddGroup(false);
    }
  };
  const toSet = (array, property) => {
    const seen = new Set();
    const result = [];
    for (const obj of array) {
        const value = obj[property];
        if (!seen.has(value)) {
            seen.add(value);
            result.push(obj);
        }
    }
    return result;
}
  const onChangeContactGroup = (data) => {
    setSelectedMembers(data);
  };
  const addToContactGroup = (closeMenu) => {
    setActiveInvitedMember(true);
    let newContacts = toSet([...isInvitedMemberData, ...selectedMembers], 'email');
    setInvitedMemberData(newContacts);
    closeMenu();
  };

  const updateInviteeRole = (index, selected, isMember) => {
    if (isMember) {
      let newInviteeData = isInvitedMemberData.map((member, i) => {
        if (i === index) {
          return { ...member, accessRoleId: selected.id };
        }
        return member;
      });
      setInvitedMemberData(newInviteeData);
    } else {
      let newInviteeData = isOutsideInviteeData.map((member, i) => {
        if (i === index) {
          return { ...member, accessRoleId: selected.id };
        }
        return member;
      });
      setOutsideInviteeData(newInviteeData);
    }
  };

  const removeInviteMember = (index) => {
    let newGroupData = [...isInvitedMemberData];
    newGroupData.splice(index, 1);
    setInvitedMemberData(newGroupData);
    setIsGroupData(newGroupData);
  };
  const removeOutsideInvitee = (index) => {
    const newInviteeData = [...isOutsideInviteeData];
    newInviteeData.splice(index, 1);
    setOutsideInviteeData(newInviteeData);
  };
  const onAddUserChange = (state) => {
    setAddUser(state);
    setReadOnly((prevVal) => (prevVal = !prevVal));
    setIsUpdateGroup(true);
  };

  const renderFooter = ({ closeMenu }) => (
    <Button type='button' isFullWidth variant='teal' onClick={() => addToContactGroup(closeMenu)}>
      Add to subcommittee
    </Button>
  );
  const handleUserInput = (e) => {
    setGroupName(e.target.value);
  };
  const onCloseModal = () => {
    setIsDeleteGroup(true);
  };
  const onClose = () => {
    setIsDeleteGroup(false);
  };
  const handleUpdateGroup = () => {
    let groupList = [...groupData];
    let outsideInvitees = isOutsideInviteeData ? [...isOutsideInviteeData] : [];
    const invitedMembers = [...isInvitedMemberData, ...outsideInvitees].map((member) => ({
      email: member.email,
      accessRoleId: member.accessRoleId,
    }));
    updateSubcommittee({
      subcommitteeId: groupData[isIndexGroup].id,
      name: isGroupName,
      contacts: invitedMembers,
    });
    setToastInfo({
      severity: Severity.Success,
      status: 'Success',
      message: 'Subcommittee update success',
    });
    groupList[isIndexGroup] = {
      groupName: isGroupName,
      contacts: [...isInvitedMemberData, ...outsideInvitees].map((contact) => {
        return { ...contact,
          initials: contact.initials ? contact.initials : 'shp',
          name: contact.name ? contact.name : contact.email,
          status: contact.status ? contact.status : 'invited',
        }}),
      id: groupData[isIndexGroup].id
    };
    setGroupData(groupList);
    setReadOnly(true);
    setAddUser(false);
    setIsUpdateGroup(false);
    setActiveGroupName(false);
    setIndexGroup(false);
  };

  const handleAddEmail = (data) => {
    const inviteeData = [...isInvitedMemberData, ...isOutsideInviteeData];
    const contactExists = inviteeData.some((contact) => contact.email === data.email);

    if (contactExists) {
      setToastInfo({
        severity: Severity.Error,
        status: 'Error',
        message: 'Email already in invitee list.',
      });
      return;
    }

    setOutsideInviteeData([
      ...isOutsideInviteeData,
      { email: data.email, accessRoleId: accessRoles.find((role) => role.name === 'Observer')?.id },
    ]);
  };

  return (
    <>
      <div className='tw-flex tw-justify-center sm:tw-px-9 sm:tw-pt-9 xl:tw-h-auto'>
        <Toast severity={toastInfo.severity} status={toastInfo.status} message={toastInfo.message} />
        <div className='tw-w-full tw-pb-64 sm:tw-w-[450px] xl:tw-w-full xl:tw-pb-0'>
          <div className='tw-h-full tw-w-full'>
            <div className='tw-h-full xl:tw-flex'>
              {groupData.length === 0 && !isAddGroup ? <EmptyList createNewGroup={createNewGroup} /> : null}
              <div
                className={clsx('-tw-mt-9 sm:tw-flex sm:tw-w-full sm:tw-justify-center xl:tw-pr-9', {
                  'tw-hidden md:tw-block': isActiveGroupName || (isAddGroup && groupData.length > 0),
                  'xl:tw-w-1/2': isActiveGroupName || (isAddGroup && groupData.length > 0),
                  'xl:tw-border-0 xl:tw-border-r xl:tw-border-solid xl:tw-border-gray-200':
                    isActiveGroupName || (isAddGroup && groupData.length > 0),
                })}
              >
                <div>
                  <div className='tw-mx-auto tw-mt-9'>
                    <div className='-tw-mt-2.5 tw-items-center tw-justify-between lg:tw-flex'>
                      {groupData.length === 0 ? null : (
                        <>
                          <div className='tw-col-span-3'>
                            <Heading text='Subcommittees' className='!tw-mb-0 !tw-text-[26px] tw-font-bold' />
                          </div>
                          {groupData.length > 0 && (
                            <Button
                              font='semibold'
                              isFullWidth={!['lg', 'xl', '2xl'].includes(breakpoint)}
                              size='small'
                              variant={['lg', 'xl', '2xl'].includes(breakpoint) ? 'transparent' : 'teal'}
                              className={clsx('!tw-col-span-2 tw-h-[48px]', {
                                'tw-text-teal-500': ['lg', 'xl', '2xl'].includes(breakpoint),
                                'tw-mt-4': !['lg', 'xl', '2xl'].includes(breakpoint),
                              })}
                              onClick={createNewGroup}
                            >
                              Create new subcommittee
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                    {groupData.length === 0 && isAddGroup && (
                      <>
                        <div className='tw-col-span-3'>
                          <Heading text='Subcommittees' className='!tw-mb-0 !tw-text-[26px] tw-font-bold' />
                        </div>
                        <div className='tw-mt-8 tw-font-sans tw-text-sm tw-text-black'>
                          No subcommittees have been made. Create a new one below.
                        </div>
                      </>
                    )}
                    <div className='tw-mt-8'>
                      {groupData.map((row, i) => {
                        return (
                          <div key={row.groupName} className='tw-mb-1 tw-rounded !tw-bg-teal-200'>
                            <Box
                              onClick={() => {
                                onClickGroupName(row, i);
                              }}
                              isSelected={isIndexGroup === i}
                              className='tw-py-0_ tw-cursor-pointer !tw-p-6'
                              variant='purple'
                            >
                              <div className='tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-8'>
                                <div className='tw-col-span-7'>
                                  <div className='tw-truncate tw-font-sans tw-text-sm tw-font-medium tw-text-black hover:tw-overflow-visible'>
                                    {row.groupName}
                                  </div>
                                </div>
                                <div className='tw-col-span-1 tw-flex tw-justify-end'>
                                  <FiChevronRight className='tw-h-5  tw-w-5 !tw-text-gray-700 tw-duration-100 sm:tw-ml-8' />
                                </div>
                              </div>
                            </Box>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {groupData.length === 0 && isAddGroup && (
                    <div className={clsx('tw-mt-16 xl:tw-mt-0')}>
                      <div className={clsx('tw-mx-auto sm:tw-w-[450px]')}>
                        <Heading text='Create new subcommittee' className='!tw-mt-4 !tw-text-[20px] tw-font-bold' />
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div className='tw-mt-5 tw-w-full'>
                            <TextInput
                              label='New subcommittee name'
                              isRequired
                              errorMessage={errors.groupName && 'this field is required'}
                              {...register('groupName', { required: true })}
                              placeholder='Name your subcommittee'
                              size='large'
                            />
                          </div>
                          <div className='tw-mt-12'>
                            <div className='tw-mb-5 tw-font-sans tw-text-base tw-font-bold tw-text-black'>
                              Invited members
                            </div>
                            <div className='tw-mt-1.5'>
                              <Label
                                text='Type name and select contacts to add'
                                className='tw-font-sans tw-text-xs !tw-font-semibold'
                              />
                              <SelectInput
                                isMulti
                                isFilterable
                                optionVariant='contact'
                                onChange={onChangeContactGroup}
                                options={groups}
                                name='contactGroups'
                                placeholder='type name and select to add'
                                renderFooter={renderFooter}
                                listheight='large'
                                listborder
                              />
                            </div>
                            {isActiveInvitedMember && (
                              <div className='tw-mt-4'>
                                {isInvitedMemberData.map((row, i) => {
                                  return (
                                    <div key={i} className='tw-relative'>
                                      <InvitedMembers
                                        accessRoles={accessRoles}
                                        initials={row.initials}
                                        name={row.name}
                                        role={row.accessRoleId}
                                        status={row.status}
                                        removeInviteMember={removeInviteMember}
                                        updateInviteeRole={updateInviteeRole}
                                        isReadOnly={isReadOnly}
                                        index={i}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          <OutsideContactInviteForm onAddEmail={handleAddEmail} />
                          <div className='tw-mt-4'>
                            {isOutsideInviteeData && (
                              <div className='tw-mt-4'>
                                {isOutsideInviteeData.map((row, i) => {
                                  return (
                                    <div key={i} className='tw-relative'>
                                      <InvitedMembers
                                        key={i}
                                        accessRoles={accessRoles}
                                        email={row.email}
                                        role={row.accessRoleId}
                                        status={row.status}
                                        removeOutsideInvitee={removeOutsideInvitee}
                                        updateInviteeRole={updateInviteeRole}
                                        isReadOnly={isReadOnly}
                                        index={i}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>

                          <div className='tw-my-12'>
                            <Button
                              font='semibold'
                              isFullWidth
                              size='large'
                              variant='teal'
                              onClick={handleSubmit(onSubmit)}
                            >
                              Create subcommittee
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {groupData.length > 0 && (
                <div className='sm:tw-mt-8 xl:tw-mt-0 xl:tw-w-1/2'>
                  <div className='xl:tw-mx-auto xl:tw-w-[400px]'>
                    {isAddGroup && (
                      <>
                        <Heading text='Create new subcommittee' className='tw-font-bold' />
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div className='tw-mt-5 tw-w-full'>
                            <TextInput
                              label='New subcommittee name'
                              isRequired
                              errorMessage={errors.groupName && 'this field is required'}
                              {...register('groupName', { required: true })}
                              placeholder='Name your subcommittee'
                              size='large'
                            />
                          </div>
                          <div className='tw-mt-12'>
                            <div className='tw-mb-5 tw-font-sans tw-text-base tw-font-bold tw-text-black'>
                              Invited members
                            </div>
                            <div className='tw-mt-1.5'>
                              <Label
                                text='Type name and select contacts to add'
                                className='tw-font-sans tw-text-xs !tw-font-semibold'
                              />
                              <SelectInput
                                isMulti
                                isFilterable
                                optionVariant='contact'
                                onChange={onChangeContactGroup}
                                options={groups}
                                name='contactGroups'
                                placeholder='type name and select to add'
                                renderFooter={renderFooter}
                                listheight='large'
                                listborder
                              />
                            </div>
                            {isActiveInvitedMember && (
                              <div className='tw-mt-4'>
                                {isInvitedMemberData.map((row, i) => {
                                  return (
                                    <div key={i} className='tw-relative'>
                                      <InvitedMembers
                                        accessRoles={accessRoles}
                                        initials={row.initials}
                                        name={row.name}
                                        role={row.accessRoleId}
                                        status={row.status}
                                        updateInviteeRole={updateInviteeRole}
                                        removeInviteMember={removeInviteMember}
                                        isReadOnly={isReadOnly}
                                        index={i}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>

                          <OutsideContactInviteForm onAddEmail={handleAddEmail} />
                          <div className='tw-mt-4'>
                            {isOutsideInviteeData && (
                              <div className='tw-mt-4'>
                                {isOutsideInviteeData.map((row, i) => {
                                  return (
                                    <div key={i} className='tw-relative'>
                                      <InvitedMembers
                                        key={i}
                                        accessRoles={accessRoles}
                                        email={row.email}
                                        role={row.accessRoleId}
                                        status={row.status}
                                        updateInviteeRole={updateInviteeRole}
                                        removeOutsideInvitee={removeOutsideInvitee}
                                        isReadOnly={isReadOnly}
                                        index={i}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>

                          <div className='tw-my-12'>
                            <Button
                              font='semibold'
                              isFullWidth
                              size='large'
                              variant='teal'
                              onClick={handleSubmit(onSubmit)}
                            >
                              Create subcommittee
                            </Button>
                          </div>
                        </form>
                      </>
                    )}
                    {isActiveGroupName && (
                      <>
                        {isReadOnly ? (
                          <div className='tw-items-center'>
                            <div className='tw-grid tw-grid-flow-col tw-grid-cols-8 tw-items-center'>
                              <div className='tw-col-span-4 tw-line-clamp-2'>
                                <Heading text={isGroupName} className='!tw-mb-0 !tw-text-[26px] !tw-font-bold' />
                              </div>
                              <div className='tw-col-span-3 tw-mr-2 tw-flex tw-justify-end'>
                                <Button
                                  className='tw-cursor-pointer !tw-p-0 !tw-font-sans !tw-text-sm !tw-font-semibold !tw-text-teal-500 hover:tw-text-teal-500'
                                  variant='transparent'
                                  onClick={() => onClickEdit(!isReadOnly)}
                                >
                                  {isReadOnly ? `Edit` : 'Save'}
                                </Button>
                              </div>
                              <div className='tw-col-span-1 tw-flex tw-justify-end'>
                                {groupData.length > 0 && (
                                  <Button
                                    className='tw-cursor-pointer !tw-p-0 tw-font-sans !tw-text-sm !tw-font-semibold !tw-text-red-500 hover:tw-text-red-500'
                                    variant='transparent'
                                    onClick={onCloseModal}
                                  >
                                    Delete
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className='tw-flex tw-justify-between'>
                            <Heading text='Edit Subcommittee' className='!tw-mb-0' />

                            <Button
                              className='tw-cursor-pointer !tw-p-0 tw-font-sans !tw-text-sm !tw-font-semibold !tw-text-red-500 hover:tw-text-red-500'
                              variant='transparent'
                              onClick={onCloseModal}
                            >
                              Delete
                            </Button>
                          </div>
                        )}

                        {isUpdateGroup && !isReadOnly && (
                          <div className='tw-mt-5 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>
                            <TextInput
                              label={!isReadOnly && 'New Subcommittee name'}
                              isReadOnly={isReadOnly}
                              value={isGroupName}
                              size='large'
                              className={clsx({
                                '!tw-border-none !tw-text-sm !tw-font-semibold': isReadOnly,
                              })}
                              onChange={handleUserInput}
                            />
                          </div>
                        )}

                        <div className='tw-mt-8'>
                          {isReadOnly && (
                            <>
                              <div className='tw-mb-4 tw-flex tw-items-center tw-justify-between'>
                                <Label
                                  text={isAddUser ? 'Invited members' : 'Members'}
                                  className='!tw-m-0 !tw-font-sans	!tw-text-base !tw-font-semibold'
                                />
                                {isUpdateGroup && (
                                  <Button
                                    className='tw-cursor-pointer !tw-px-0 tw-font-sans !tw-text-sm !tw-font-medium !tw-text-teal-500 hover:tw-text-teal-500'
                                    variant='transparent'
                                    onClick={() => onAddUserChange(true)}
                                  >
                                    Add user
                                  </Button>
                                )}
                              </div>
                              {isInvitedMemberData.length === 0 && <div>0 Members</div>}
                              {loading ? (
                                <div className='tw-flex tw-h-40 tw-items-center tw-justify-center'>
                                  <Spinner className='!tw-static' />
                                </div>
                              ) : (
                                isInvitedMemberData.map((row, i) => {
                                  return (
                                    <div key={i} className='tw-relative'>
                                      <InvitedMembers
                                        accessRoles={accessRoles}
                                        name={row.name}
                                        role={row.accessRoleId}
                                        initials={row.initials}
                                        isMember={true}
                                        status={row.status}
                                        updateInviteeRole={updateInviteeRole}
                                        removeInviteMember={removeInviteMember}
                                        isReadOnly={isReadOnly}
                                        index={i}
                                      />
                                    </div>
                                  );
                                })
                              )}
                            </>
                          )}
                          {isUpdateGroup && !isReadOnly && (
                            <div>
                              <div className='tw-mt-9'>
                                <Label
                                  text='Type name and select contacts to add to Subcommittee'
                                  className='tw-font-sans tw-text-xs !tw-font-semibold'
                                />
                                <SelectInput
                                  isMulti
                                  isFilterable
                                  optionVariant='contact'
                                  onChange={onChangeContactGroup}
                                  options={groups.filter((contact) => {
                                    return !isInvitedMemberData.some((invitee) => invitee.email === contact.email);
                                  })}
                                  name='contactGroups'
                                  placeholder='Type name and select to add'
                                  renderFooter={renderFooter}
                                  listHeight='large'
                                  listBorder
                                />
                              </div>
                              {!isReadOnly && (
                                <>
                                  <div className='tw-mb-4 tw-mt-9 tw-flex tw-items-center tw-justify-between'>
                                    <Label
                                      text={isAddUser ? 'Invited members' : 'Members'}
                                      className='!tw-m-0 !tw-font-sans	!tw-text-sm !tw-font-semibold'
                                    />
                                    {!isUpdateGroup && !isAddUser && (
                                      <Button
                                        className='tw-cursor-pointer !tw-px-0 tw-font-sans !tw-text-xs !tw-font-medium !tw-text-teal-500 hover:tw-text-teal-500'
                                        variant='transparent'
                                        onClick={() => onAddUserChange(true)}
                                      >
                                        Add user
                                      </Button>
                                    )}
                                  </div>
                                  {isInvitedMemberData.length === 0 && <div>0 Members</div>}
                                  {isInvitedMemberData.map((row, i) => {
                                    return (
                                      <div key={row.id} className='tw-relative'>
                                        <InvitedMembers
                                          accessRoles={accessRoles}
                                          name={row.name}
                                          role={row.accessRoleId}
                                          status={row.status}
                                          updateInviteeRole={updateInviteeRole}
                                          removeInviteMember={removeInviteMember}
                                          isReadOnly={isReadOnly}
                                          index={i}
                                        />
                                      </div>
                                    );
                                  })}
                                  <OutsideContactInviteForm onAddEmail={handleAddEmail} />
                                  <div className='tw-mt-4'>
                                    {isOutsideInviteeData && (
                                      <div className='tw-mt-4'>
                                        {isOutsideInviteeData.map((row, i) => {
                                          return (
                                            <div key={row.id} className='tw-relative'>
                                              <InvitedMembers
                                                accessRoles={accessRoles}
                                                email={row.email}
                                                role={row.accessRoleId}
                                                status={row.status}
                                                updateInviteeRole={updateInviteeRole}
                                                removeOutsideInvitee={removeOutsideInvitee}
                                                isReadOnly={isReadOnly}
                                                index={i}
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                              <div className='tw-mt-8'>
                                <Button
                                  font='semibold'
                                  isFullWidth
                                  size='large'
                                  variant='teal'
                                  onClick={handleUpdateGroup}
                                >
                                  Save subcommittee
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal isOpen={isDeleteGroup} onClose={onClose} centered size='xl'>
          <div className='tw-m-14'>
            <div className='tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>
              Are you sure you want to delete this subcommittee?
            </div>
            <div className='tw-text-14 tw-mt-4 tw-font-sans tw-text-black'>
              Once deleted all information for this subcommittee will be removed permanently.
            </div>

            <div className='tw-mt-8 '>
              <TextInput
                label='Write DELETE to confirm'
                onChange={(e) => setConfirmDelete(e.target.value)}
                placeholder='DELETE'
                size='regular'
                value={confirmDelete}
              />
              <div className='tw-mt-6 lg:tw-flex lg:tw-justify-between'>
                <Button
                  font='semibold'
                  className='tw-text-xs lg:tw-mr-2'
                  isFullWidth
                  onClick={deleteGroup}
                  size='large'
                  variant='red'
                  isDisabled={confirmDelete !== 'DELETE'}
                >
                  Yes, delete subcommittee
                </Button>
                <Button
                  font='semibold'
                  className={clsx('tw-ring-solid tw-text-xs tw-text-red-500 tw-ring tw-ring-red-500', {
                    'tw-mt-2': !['xl', '2xl'].includes(breakpoint),
                  })}
                  isFullWidth
                  onClick={onClose}
                  size='large'
                  variant='transparent'
                >
                  No, keep subcommittee
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Subcommittee;
