export const MEETING_STEPS = [
  'details',
  'how-to-attend',
  'invite-and-notice',
  'attendees',
  'tasks',
  'voting',
  'agenda',
  'overview',
];

export const MEETING_CONFIG_DEFAULTS = {
  config: 'zoom',
  meetingUrl: '',
  passcode: '',
  location: '',
  locationNotes: '',
};

export const ZOOM_MEETING_CONFIG_DEFAULTS = {
  zoomId: null,
  existing: false,
  agenda: '',
  password: '',
  hostVideo: true,
  participantVideo: true,
  waitingRoom: false,
  joinBeforeHost: true,
  muteUponEntry: true,
  autoRecording: 'local',
  existing: false,
};
