import { useUpdateContactGroupMutation } from './../data/mutations/UpdateContactGroupMutation';

const useUpdateContactGroup = ({ onCompleted, onError }) => {
  const update = (cache, { data: { updateContactGroup } }) => {
    const { errors } = updateContactGroup;

    if (errors.length) {
      return onError(errors);
    }

    onCompleted(updateContactGroup);
  };

  const [mutate] = useUpdateContactGroupMutation({ update });

  const updateContactGroup = ({ contactGroupId, name, contactIds }) => {
    mutate({ variables: { input: { contactGroupId, name, contactIds } } });
  };

  return { updateContactGroup };
};

export { useUpdateContactGroup };
