import { gql, useMutation } from '@apollo/client';
import { MEETING_FRAGMENT } from 'data/v2/fragments/MeetingFragment';

const UPDATE_MEETING_MUTATION = gql`
  mutation updateMeeting($input: UpdateMeetingInput!) {
    updateMeeting(input: $input) {
      meeting {
        ...MeetingFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${MEETING_FRAGMENT}
`;

const useUpdateMeetingMutation = (options) => useMutation(UPDATE_MEETING_MUTATION, options);

export { UPDATE_MEETING_MUTATION, useUpdateMeetingMutation };
