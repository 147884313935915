/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';

import FormSidebar from 'components/v2/shared/FormSidebar';
import MeetingFormContent from './../MeetingFormContent';

import { Row, Col } from 'react-bootstrap';
import { STEPS } from './../constants';

import { useMeetingFormStepContext } from 'contexts/v2/MeetingFormStepContext';
import * as styles from './MeetingForm.styles';

const MeetingForm = () => {
  const { currentStep, prevStep } = useMeetingFormStepContext();

  return (
    <Row css={styles.panel}>
      <Col lg={4} css={styles.sidebar}>
        <FormSidebar headerText='Create meeting' badgeStatus='Draft' steps={STEPS} activeStep={currentStep} />
      </Col>

      <Col lg={8} css={styles.content}>
        <MeetingFormContent activeStep={currentStep} title={STEPS[currentStep]} onBackClick={prevStep} />
      </Col>
    </Row>
  );
};

export default MeetingForm;
