/** @jsx jsx */
import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { RadioButton } from 'components/shared/RadioButton';

import * as styles from './ResolutionSettings.styles';

const ResolutionSettings = memo(({ pollForVote, setPollForVote, config, setConfig }) => {
  const isConfigSimple = config === 'simple';

  const intl = useIntl();

  const setPollPerResolution = () => setPollForVote(false);
  const setPollPerVote = () => setPollForVote(true);
  const changeConfig = (event) => setConfig(event.target.value);

  return (
    <div className='vote-wrapper'>
      <div>
        <div css={styles.resolutionHeading}>
          <FormattedMessage id='ResolutionSettings.settings' />
        </div>
        <label className='form-label dash-title p-b-10 fs-14'>
          <FormattedMessage id='ResolutionSettings.polling' />
        </label>
        <RadioButton
          name='pollForVote'
          defaultChecked={!pollForVote}
          value='false'
          onChange={setPollPerResolution}
          id='poll-false'
          label={intl.formatMessage({ id: 'ResolutionSettings.createEach' })}
        />
        <RadioButton
          name='pollForVote'
          defaultChecked={pollForVote}
          value='true'
          onChange={setPollPerVote}
          id='poll-true'
          label={intl.formatMessage({ id: 'ResolutionSettings.createAll' })}
        />
      </div>
      <div className='m-t-20'>
        <label className='form-label dash-title p-b-10 fs-14'>
          <FormattedMessage id='ResolutionSettings.voteConfig' />
        </label>
        <RadioButton
          name='config'
          defaultChecked={isConfigSimple}
          value='simple'
          onChange={changeConfig}
          id='config-simple'
          label={intl.formatMessage({ id: 'ResolutionSettings.yesNo' })}
        />
        <RadioButton
          name='config'
          defaultChecked={!isConfigSimple}
          value='extended'
          onChange={changeConfig}
          id='config-extended'
          label={intl.formatMessage({ id: 'ResolutionSettings.forAgainst' })}
        />
      </div>
    </div>
  );
});

export { ResolutionSettings };
