import React, { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { withIntl } from '../../../utils/v2/intl';
import { AlertPopup } from '../../shared/AlertPopup';

const CorruptedFilePopup = memo(({ contentTitle }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const intl = useIntl();

  const showPopup = () => setIsPopupVisible(true);
  const hidePopup = () => setIsPopupVisible(false);

  return (
    <>
      <div onClick={showPopup} className='cursor-pointer'>
        {contentTitle}
      </div>

      <AlertPopup
        show={isPopupVisible}
        headerText={intl.formatMessage({ id: 'CorruptedFilePopup.corruptedFile' })}
        onClose={hidePopup}
        popupType='failure'
      >
        <div className='text-black fs-14'>
          <FormattedMessage id='CorruptedFilePopup.corruptedFileInfo' />
        </div>

        <a className='btn btn-red-inverted w-180 m-t-40' onClick={hidePopup}>
          <FormattedMessage id='CorruptedFilePopup.ok' />
        </a>
      </AlertPopup>
    </>
  );
});

export default withIntl(CorruptedFilePopup);
