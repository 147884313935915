export const matchedParts = (text, query) => {
  const regex = new RegExp(query.trim(), 'gi');
  const matches = [...text.matchAll(regex)];

  if (matches.length === 0) return [{ text: text, matched: false }];

  let parts = [];

  if (matches[0].index > 0) {
    parts.push({ text: text.slice(0, matches[0].index), matched: false });
  }

  matches.forEach((match, matchIndex) => {
    const startIndex = match.index;
    const endIndex = match.index + match[0].length;

    parts.push({ text: text.slice(startIndex, endIndex), matched: true });

    const isLastMatch = matchIndex === matches.length - 1;

    if (isLastMatch) {
      if (endIndex < text.length) {
        parts.push({ text: text.slice(endIndex, text.length), matched: false });
      }
    } else if (endIndex < matches[matchIndex + 1].index) {
      parts.push({ text: text.slice(endIndex, matches[matchIndex + 1].index), matched: false });
    }
  });

  return parts;
};
