import { useShareDirectoryMutation } from './../data/mutations/ShareDirectoryMutation';
import { useApolloCacheHelpers } from './../hooks/useApolloCacheHelpers';

const useShareDirectory = ({ onCompleted, onError }) => {
  const update = (cache, { data: { shareDirectory } }) => {
    onCompleted();
  };

  const [mutate, { loading: isSharing }] = useShareDirectoryMutation({ update });

  const shareDirectory = (directoryId, contactIds) => {
    mutate({ variables: { input: { directoryId, contactIds } } });
  };

  return { shareDirectory, isSharing };
};

export { useShareDirectory };
