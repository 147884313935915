import { useDownloadDirectoryMutation } from './../data/mutations/DownloadDirectoryMutation';

const useDownloadDirectory = ({ onDownload }) => {
  const update = (cache, { data: { downloadDirectory } }) => {
    const { directory } = downloadDirectory;

    onDownload(directory);
  };

  const [mutate, { loading: isDownloading }] = useDownloadDirectoryMutation({ update });

  const downloadDirectory = (directoryId) => {
    mutate({ variables: { input: { directoryId } } });
  };

  return { downloadDirectory, isDownloading };
};

export { useDownloadDirectory };
