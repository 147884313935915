import { gql, useMutation } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from './../fragments/DocumentFragment';

const DELETE_DOCUMENT = gql`
  mutation deleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      document {
        ...DocumentFields

        parent {
          id
          empty

          ancestors {
            id
            empty
          }
        }
      }

      errors {
        attribute
        message
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

const useDeleteDocumentMutation = (options) => useMutation(DELETE_DOCUMENT, options);

export { useDeleteDocumentMutation };
