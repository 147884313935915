import { gql, useMutation } from '@apollo/client';

const UPDATE_ANNOTATION = gql`
  mutation updateAnnotation($input: UpdateAnnotationInput!) {
    updateAnnotation(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`;

const useUpdateAnnotationMutation = (options) => useMutation(UPDATE_ANNOTATION, options);

export default useUpdateAnnotationMutation;
