import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const wrapper = css`
  display: flex;
`;

export const viewer = css`
  background: ${colors.white};
  width: 777px;
  padding: 20px;
`;

export const viewport = css`
  height: calc(100vh - 40px);
`;

export const toolbar = css`
  box-shadow: 0px 0px 3px 1px rgba(51, 55, 70, 0.14);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  background: ${colors.lightestGrey};
  padding: 14px 0;
`;
