import { gql, useMutation } from '@apollo/client';

const REMOVE_COMPANY_AS_PRIMARY_MUTATION = gql`
  mutation RemoveCompanyAsPrimary($input: RemoveCompanyAsPrimaryInput!) {
    removeCompanyAsPrimary(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`;

const useRemoveCompanyAsPrimaryMutation = (options) => useMutation(REMOVE_COMPANY_AS_PRIMARY_MUTATION, options);

export { REMOVE_COMPANY_AS_PRIMARY_MUTATION, useRemoveCompanyAsPrimaryMutation };
