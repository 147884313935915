import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useContactGroupsQuery } from '../../data/v2/queries/ContactGroupsQuery';

const initialState = {
  contactGroups: [],
  skipFetch: true,
};

const actionTypes = {
  SET_CONTACT_GROUPS: 'ContactGroupsContext.setContactGroups',
  FETCH_CONTACT_GROUPS: 'ContactGroupsContext.fetchContactGroups',
};

const ContactGroupsContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_CONTACT_GROUPS:
      return { ...state, contactGroups: payload.contactGroups };
    case actionTypes.FETCH_CONTACT_GROUPS:
      return { ...state, skipFetch: false };
  }
};

export const ContactGroupsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { contactGroups, skipFetch } = state;

  const { loading, data } = useContactGroupsQuery({
    skip: skipFetch,
  });

  const format = ({ name, contacts, id }) => ({
    label: name,
    value: id,
    contacts,
  });

  useEffect(() => {
    if (data) {
      const {
        currentCompany: { contactGroups },
      } = data;
      dispatch({ type: actionTypes.SET_CONTACT_GROUPS, payload: { contactGroups: contactGroups.map(format) } });
    }
  }, [data]);

  const getGroupsContacts = (groupIds) => {
    return contactGroups.reduce((result, group) => {
      if (groupIds.includes(group.value)) {
        return [...result, ...group.contacts.map((contact) => ({ ...contact, contactGroupId: group.value }))];
      }

      return result;
    }, []);
  };

  const fetchContactGroups = () => {
    dispatch({ type: actionTypes.FETCH_CONTACT_GROUPS });
  };

  return (
    <ContactGroupsContext.Provider value={{ contactGroups, fetchContactGroups, getGroupsContacts, loading }}>
      {children}
    </ContactGroupsContext.Provider>
  );
};

ContactGroupsContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useContactGroupsContext = () => useContext(ContactGroupsContext);
