import { css } from '@emotion/core';
import { panels, media } from 'styles';

export const bar = css`
  ${media.mobile} {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: -10px;

    .btn {
      margin-bottom: 10px;
    }
  }
`;
