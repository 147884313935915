import moment from 'moment';

const parseDateTime = (value, number = 0, period = 'minute') => {
  if (value) {
    return moment(value).parseZone();
  } else {
    return moment().parseZone().add(number, period);
  }
};

export { parseDateTime };
