/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './AlertBox.styles';

const AlertBox = memo(({ primaryMessage, secondaryMessage }) => {
  return (
    <div css={styles.wrapper}>
      <span css={styles.message.primary}>{primaryMessage}</span>
      <span css={styles.message.secondary}>{secondaryMessage}</span>
    </div>
  );
});

export { AlertBox };
