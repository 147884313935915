import { useState } from 'react';
import { useCreateResolutionMutation } from './../data/mutations/CreateResolutionMutation';

const useCreateResolution = ({ voteId, onCompleted }) => {
  const update = (cache, { data: { createResolution } }) => {
    const { resolution } = createResolution;

    onCompleted(resolution);
  };

  const [mutate, { loading: isCreating }] = useCreateResolutionMutation({ update });

  const createResolution = () => mutate({ variables: { input: { voteId: voteId } } });

  return {
    isCreating,
    createResolution,
  };
};

export { useCreateResolution };
