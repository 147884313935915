/** @jsx jsx */
import React, { memo, useState, useRef } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { StatusBadge } from 'components/shared/StatusBadge';
import { Spinner } from 'components/shared/Spinner';
import { AlertPopup } from 'components/shared/AlertPopup';
import { Steps } from 'components/shared/Steps';
import { VoteDetailsForm } from '../VoteDetailsForm';
import { PollingForm } from '../PollingForm';

import * as styles from './Form.styles';

import { useSteps } from 'hooks/useSteps';
import { useVoteEditor } from './../hooks/useVoteEditor';
import { useValidateResolutions } from './../hooks/useValidateResolutions';

const Form = memo(({ voteId, companyId, showPolling, root }) => {
  const intl = useIntl();

  const { current: stepMap } = useRef({
    1: intl.formatMessage({ id: 'Form.voteDetails' }),
    2: intl.formatMessage({ id: 'Form.polling' }),
  });

  const initialStep = showPolling ? 2 : 1;

  const { steps, changeStep, advanceStep, jumpStep } = useSteps(stepMap, initialStep);
  const { isLoading, vote } = useVoteEditor({ voteId });

  const toggleActiveStep = () => {
    if (!voteId) return;
    if (steps.current === stepMap.details) return advanceStep();

    if (vote?.status === 'published') {
      validateResolutions({ voteId: vote?.id });
    } else {
      jumpStep(stepMap.details);
    }
  };

  const [showResolutionAlert, setShowResolutionAlert] = useState(false);

  const displayResolutionAlert = () => setShowResolutionAlert(true);
  const hideResolutionAlert = () => setShowResolutionAlert(false);
  const changeStepToDetails = () => jumpStep(stepMap.details);

  const { validateResolutions } = useValidateResolutions({
    onError: displayResolutionAlert,
    onCompleted: changeStepToDetails,
  });

  if (isLoading) return <Spinner show />;

  return (
    <>
      <AlertPopup
        show={showResolutionAlert}
        onClose={hideResolutionAlert}
        headerText={intl.formatMessage({ id: 'Form.failiure' })}
        popupType='failure'
      >
        <div className='text-black fs-14'>
          <FormattedMessage id='Form.oneResolution' />
        </div>
      </AlertPopup>

      <div className='container-lg'>
        <div className='page-heading'>
          <div className='main d-flex align-items-center m-t-40'>
            <StatusBadge
              status={vote?.status || 'draft'}
              text={
                voteId ? intl.formatMessage({ id: 'Form.editVote' }) : intl.formatMessage({ id: 'Form.createVote' })
              }
            />
          </div>
        </div>

        {vote?.status != 'open' && (
          <div className='panel p-40 p-b-0'>
            <Steps
              stepMap={stepMap}
              currentStep={steps.current}
              completedSteps={steps.completed}
              changeStep={changeStep}
              disabledSteps={steps.disabled}
            />
          </div>
        )}

        {steps.current === 1 && <VoteDetailsForm vote={vote} companyId={companyId} onStepChange={toggleActiveStep} />}

        {steps.current === 2 && vote?.id && <PollingForm vote={vote} onStepChange={toggleActiveStep} root={root} />}
      </div>
    </>
  );
});

export { Form };
