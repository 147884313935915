import { gql, useQuery } from '@apollo/client';
import { VOTE_FRAGMENT } from './../fragments/VoteFragment';

const VOTE_QUERY = gql`
  query vote($id: ID!) {
    currentCompany {
      id

      vote(id: $id) {
        ...VoteFields
      }
    }
  }
  ${VOTE_FRAGMENT}
`;

const useVoteQuery = (options) => useQuery(VOTE_QUERY, options);

export { useVoteQuery };
