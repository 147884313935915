import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const uploadable = {
  base: css`
    background: ${colors.white};
    opacity: 1;
    padding: 10px 17px;
    display: flex;
    margin-bottom: 5px;
    font-size: 14px;
    color: ${colors.black};
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    position: relative;
  `,

  progress: (width) => css`
    position: absolute;
    left: 0;
    height: 100%;
    width: ${width}%;
    background: #e1e4e8;
  `,

  invalid: css`
    background: #ffeaea;
  `,
};

export const warn = css`
  color: ${colors.red};
  font-size: 14px;
`;
