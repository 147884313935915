/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import Divider from 'components/v2/shared/Divider';
import ContentToggle from 'components/v2/shared/ContentToggle';
import PlacesInput from 'components/v2/shared/PlacesInput';
import TextAreaInput from 'components/v2/shared/TextAreaInput';

import { useMeetingConfigContext } from 'contexts/v2/MeetingConfigContext';
import { getFieldData } from 'utils/v2/data';

import * as styles from './InPersonLocationForm.styles';

const InPersonLocationForm = ({ className }) => {
  const { edits, updateEdits } = useMeetingConfigContext();

  const handleLocationChange = (value) => {
    const targetData = getFieldData(value);

    updateEdits('location', targetData);
  };

  const handleLocationNotesChange = (value) => {
    const targetData = value.target;

    updateEdits('locationNotes', targetData.value);
  };

  const resetLocationNotes = () => {
    updateEdits('locationNotes', '');
  };

  const isLocationNotesVisible = edits.locationNotes?.length > 0;

  return (
    <Row noGutters className={className}>
      <Col lg={12}>
        <Divider isBold position='left' title='In person location only' />
      </Col>

      <Col lg={12} className='mt-4'>
        <PlacesInput
          label='Type and select address'
          name='location'
          value={edits.location}
          onChange={handleLocationChange}
        />
      </Col>

      <Col lg={12} className='mt-2'>
        <ContentToggle
          toggleText='Add location notes'
          labelText='Add notes to location'
          isVisible={isLocationNotesVisible}
          onHide={resetLocationNotes}
        >
          <TextAreaInput name='locationNotes' value={edits.locationNotes} onChange={handleLocationNotesChange} />
        </ContentToggle>
      </Col>
    </Row>
  );
};

InPersonLocationForm.propTypes = {
  className: PropTypes.string,
};

export default InPersonLocationForm;
