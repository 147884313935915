const colors = require('tailwindcss/colors');
const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  screens: {
    xs: '380px',
    ...defaultTheme.screens,
  },
  fontFamily: {
    sans: ['Gilroy', 'sans-serif'],
  },
  fontSize: {
    xxs: ['0.625rem', '0.875rem'],
    xs: ['0.75rem', '1rem'],
    sm: ['0.875rem', '1.25rem'],
    base: ['1rem', '1.5rem'],
    lg: ['1.125rem', '1.75rem'],
    xl: ['1.25rem', '1.75rem'],
    '2xl': ['1.5rem', '2rem'],
    '3xl': ['1.875rem', '2.25rem'],
    '4xl': ['2.25rem', '2.5rem'],
  },
  fontWeight: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  colors: {
    transparent: colors.transparent,
    white: colors.white,
    black: '#00031F',
    teal: {
      100: '#F3FAFB',
      200: '#E2F3F4',
      300: '#AEE0E6',
      400: '#73AFB7',
      500: '#00BAC6',
      600: '#008F98',
      700: '#006775',
    },
    purple: {
      100: '#F0ECF3',
      150: '#DEDCEA',
      200: '#E0DEF0',
      500: '#662D91',
      600: '#551F7D',
    },
    gray: {
      100: '#F8F8FA',
      200: '#E7EAEF',
      500: '#C5CADA',
      600: '#ADADC1',
      700: '#9091A9',
      800: '#787D96',
      900: '#98A4AF',
    },
    green: {
      100: '#EFFBF2',
      200: '#E8F5ED',
      300: '#D0F4D9',
      500: '#B1EDBE',
      600: '#117C28',
    },
    orange: {
      100: '#FFF5E5',
      200: '#FAEADB',
      500: '#FFCE7B',
      600: '#FF7A00',
    },
    red: {
      100: '#FFE4E4',
      200: '#F8D7D7',
      400: '#FF7A7A',
      500: '#E45555',
      600: '#F12F2F',
    },
    blue: {
      100: '#E0EEFF',
      500: '#0073FF',
      600: '#0056BE',
    },
  },
  extend: {
    screens: {
      xsm: '320px',
    },
    borderWidth: {
      6: '6px',
    },
    boxShadow: {
      lightbox: '0px 1.2px 3.6px rgba(0, 20, 90, 0.16), 0px 6.4px 14.4px rgba(147, 157, 162, 0.13)',
      dropdown: '0px 15px 30px rgba(18, 5, 34, 0.1), 0px 6.4px 14.4px rgba(32, 0, 56, 0.13)',
      soft: '0px 1px 1px rgba(0, 0, 0, 0.084)',
      placeholder: '0px 4px 20px rgba(54, 71, 98, 0.15)',
    },
    zIndex: {
      max: 9999,
      backdrop: 10000,
      aboveBackdrop: 10001,
    },
    spacing: {
      2.25: '0.5625rem',
      4.5: '1.125rem',
      22: '5.5rem',
    },
    cursor: {
      inherit: 'inherit',
    },
  },
};
