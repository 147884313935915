import React, { useState, useEffect } from 'react';
import { withIntl } from 'utils/v2/intl';
import { withApollo } from 'utils/v2/apollo';
import { FolderExplorerInMobile } from './FolderExplorerInMobile';
import { FoldersInMobile } from './FoldersInMobile';
import { SmartUploadContainer } from '../styles';
import api from '../API';
import LoadingCircle from 'components/shared/LoadingCircle';

import { getResourceID } from 'helpers/graphql';
import { useDirectoriesBrowser } from 'components/documents/hooks/useDirectoriesBrowser';

const BrowserDocuments = ({ deviceType, context }) => {
  const [currentDocument, setCurrentDocument] = useState();
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [error, setError] = useState();

  const resetSearch = () => {
    if (searchMode) {
      setSearchMode(false);
      setSearchResults(null);
      setSearchTerm('');
    }
  };

  const { isLoading, browseDirectory, resetBrowser, currentDirectory, browseSidebarItem, rootDirectories } =
    useDirectoriesBrowser({ onBrowse: resetSearch });

  const isSelectable = () => true;

  const selectDocument = () => {
    setError(null);

    const companySlug = getCompanySlug();

    // check context
    if (context === 'smart_sign') {
      api.createSmartDocument(
        getResourceID(currentDocument.id),
        (smartDocument) => {
          window.location.assign(`/${companySlug}/smart_sign/${smartDocument.slug}/edit`);
        },
        (error) => {
          setError(error.response.data.message);
        }
      );
    }
  };

  const currentFolder = searchMode ? searchResults : currentDirectory;

  return (
    <SmartUploadContainer id='react-smart-upload'>
      {isLoading && <LoadingCircle />}
      {currentDirectory ? (
        <FolderExplorerInMobile
          currentFolderChildren={currentFolder.directories}
          searchMode={searchMode}
          currentPath={currentDirectory.breadcrumbs}
          selectFolder={browseDirectory}
          error={error}
          isSelectable={isSelectable}
          currentFolderDocuments={currentFolder.documents}
          selectDocument={selectDocument}
          currentDocument={currentDocument}
          setCurrentDocument={setCurrentDocument}
          selectedDocuments={selectedDocuments}
          navigateToRoot={resetBrowser}
          setSelectedDocuments={setSelectedDocuments}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setIsSearching={setIsSearching}
          setSearchResults={setSearchResults}
          setSearchMode={setSearchMode}
          currentRoot={currentDirectory.breadcrumbs[0]}
          isSearching={isSearching}
        />
      ) : (
        <FoldersInMobile rootDirectories={rootDirectories} onClick={browseDirectory} />
      )}
    </SmartUploadContainer>
  );
};

export default withApollo(withIntl(BrowserDocuments));
