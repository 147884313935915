/** @jsx jsx */

import React, { memo, forwardRef } from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import * as styles from './TextField.styles';

const TextField = memo(
  forwardRef(
    (
      {
        id,
        name,
        value,
        placeholder,
        onFocus,
        onBlur,
        onClick,
        onChange,
        isInvalid,
        isRequired,
        isDisabled,
        isReadOnly,
        iconPosition,
        ...props
      },
      inputRef
    ) => {
      const applicableStyles = [
        styles.textField.base,
        isInvalid && styles.textField.state.invalid,
        iconPosition && styles.textField.accommodation.icon[iconPosition],
        isDisabled && styles.textField.state.disabled,
      ];

      return (
        <input
          id={id}
          css={applicableStyles}
          type='text'
          value={value}
          onChange={onChange}
          ref={inputRef}
          name={name}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          placeholder={placeholder}
          required={isRequired}
          disabled={isDisabled}
          readOnly={isReadOnly}
          autoComplete='off'
          {...props}
        />
      );
    }
  )
);

TextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
};

export { TextField };
