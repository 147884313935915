/** @jsx jsx */

import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import { jsx, css } from '@emotion/core';

import ClipLoader from 'react-spinners/ClipLoader';

import { FileViewerTopBar } from 'components/shared/FileViewerTopBar';
import { ImageViewer } from 'components/shared/ImageViewer';
import { PdfViewer } from 'components/shared/PdfViewer';
import { DocumentViewer } from './../DocumentViewer';

import * as styles from './FileViewer.styles';
import { colors } from 'styles';

const VIEWER_WIDTH = 800;
const DEFAULT_SCALE = 1;
const MAX_SCALE = 1.75;
const SCALE_STEP = 0.25;

const FileViewer = memo(({ show, onClose, id, url, title, contentType }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [scale, setScale] = useState({ current: DEFAULT_SCALE, previous: DEFAULT_SCALE });

  const hideSpinner = useCallback(() => {
    setIsLoading(false);
  }, []);

  const resetComponent = () => {
    if (show) return;

    setScale({
      current: DEFAULT_SCALE,
      previous: DEFAULT_SCALE,
    });

    setIsLoading(true);
  };

  useEffect(() => resetComponent(), [show]);

  const isImage = contentType === 'image';
  const isDocument = contentType === 'document';
  const isPdf = contentType === 'pdf';

  const handleDownload = () => {
    var link = document.createElement('a');
    link.download = title;
    link.href = `/${getCompanySlug()}/blobs/download?document_id=${id}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const imageViewer = isImage && (
    <ImageViewer source={url} scale={scale.current} width={VIEWER_WIDTH} onLoad={hideSpinner} />
  );

  const documentViewer = isDocument && <DocumentViewer title={title} source={url} onLoad={hideSpinner} />;

  const pdfViewer = isPdf && (
    <PdfViewer
      source={url}
      viewerScale={scale}
      viewerWidth={VIEWER_WIDTH}
      pageWidth={VIEWER_WIDTH * MAX_SCALE}
      onRender={hideSpinner}
    />
  );

  const isMaxScale = scale.current === MAX_SCALE;
  const isMinScale = scale.current === DEFAULT_SCALE;

  const handleScaleIn = useCallback(() => {
    if (isMaxScale) return;

    setScale((state) => ({
      current: state.current + SCALE_STEP,
      previous: state.current,
    }));
  }, [isMaxScale]);

  const handleScaleOut = useCallback(() => {
    if (isMinScale) return;

    setScale((state) => ({
      current: state.current - SCALE_STEP,
      previous: state.current,
    }));
  }, [isMinScale]);

  const containerStyles = [styles.container.base, show && styles.container.visible];

  const viewerStyles = [styles.viewer.base, isLoading && styles.viewer.hidden];

  return (
    show && (
      <div css={containerStyles}>
        <FileViewerTopBar
          title={title}
          onDownload={handleDownload}
          onScaleIn={handleScaleIn}
          onScaleOut={handleScaleOut}
          onClose={onClose}
          isMaxScale={isMaxScale}
          isMinScale={isMinScale}
          isScaleable={!isDocument}
        />

        <div css={styles.spinner}>
          <ClipLoader loading={isLoading} color={colors.cyan} />
        </div>

        <div css={viewerStyles}>
          {imageViewer}
          {documentViewer}
          {pdfViewer}
        </div>
      </div>
    )
  );
});

export { FileViewer };
