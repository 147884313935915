import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const wrapper = css`
  ${fonts.regular}
  padding: 0 20px 0 20px;
  position: relative;
  line-height: 16px;
  word-break: break-word;
  color: ${colors.black};
`;

export const content = {
  base: css`
    overflow: hidden;
    max-height: none;
  `,

  collapsed: css`
    max-height: 150px;

    &:before {
      content: '';
      text-align: right;
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      width: 100%;
      height: 100px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 100%);
    }
  `,

  highlighted: css`
    &:before {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(247, 254, 255, 1) 100%);
    }
  `,
};

export const button = css`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.cyan};

  span {
    font-size: 12px;
  }

  &:hover {
    cursor: pointer;
  }
`;
