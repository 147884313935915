import { css } from '@emotion/core';
import { fonts, panels, colors } from 'styles';

export const wrapper = css`
  ${panels.base}
  padding: 25px 30px 25px 30px;
  height: 100%;
`;

export const viewMore = css`
  ${fonts.bold}
  color: ${colors.cyan};
  font-size: 14px;

  &:hover {
    color: ${colors.cyan};
    opacity: 0.8;
  }
`;

export const heading = css`
  ${fonts.semiBold}
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: ${colors.black};
  margin: 0 0 20px 0;
`;

export const spinner = css`
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:empty {
    min-height: 0;
  }
`;
