/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { useEditable } from 'hooks/useEditable';
import { useUpdateDocument } from './../hooks/useUpdateDocument';
import { useDeleteDocument } from './../hooks/useDeleteDocument';
import { useToggleFavouriteDocument } from './../hooks/useToggleFavouriteDocument';
import { AlertPopup } from 'components/shared/AlertPopup';

import { LoadingIndicator } from 'components/shared/LoadingIndicator';
import { EditableName } from './../EditableName';
import { ActionsDropdown } from './../ActionsDropdown';

import { ReactComponent as SharedIcon } from 'images/icons/documents/shared-badge.svg';
import { ReactComponent as SmartDocumentIcon } from 'images/icons/documents/smart-sign-icon.svg';
import { ReactComponent as StarIcon } from 'images/icons/documents/star-icon.svg';
import { ReactComponent as DocumentIcon } from 'images/icons/documents/document-icon.svg';

import * as styles from './Document.styles';
import moment from 'moment';

import { useDateFormatContext } from '../../../contexts/v2/DateFormatContext';

const Document = memo(
  ({
    id,
    name,
    extension,
    shared,
    highlight,
    capabilities,
    favourite,
    contentType,
    url,
    createdAt,
    smartDocument,
    withSmartDocuments,
    onViewHistoryClick,
    onClick,
    onShareClick,
    onMoveClick,
  }) => {
    const { userDateFormat } = useDateFormatContext();
    const { isEditing, startEditing, stopEditing } = useEditable();
    const { isDeleting, deleteDocument } = useDeleteDocument({ id });
    const { isToggling, toggleFavouriteDocument } = useToggleFavouriteDocument({
      id,
    });
    const { isUpdating, error, resetError, updateDocument } = useUpdateDocument({ id, onCompleted: stopEditing });
    const { isEditing: isAlertVisible, startEditing: showAlert, stopEditing: hideAlert } = useEditable();

    const intl = useIntl();

    const handleToggleFavouriteClick = (event) => {
      event.stopPropagation();
      toggleFavouriteDocument();
    };

    const handleAlertCancel = (e) => {
      e.stopPropagation();
      hideAlert();
    };

    const handleAlertConfirm = (e) => {
      handleAlertCancel(e);
      deleteDocument();
    };

    const quickActions = [
      [!smartDocument && capabilities?.canUpdate, intl.formatMessage({ id: 'Document.rename' })],
      [capabilities?.canMove, intl.formatMessage({ id: 'Document.move' })],
      [!smartDocument && !withSmartDocuments && capabilities?.canDelete, intl.formatMessage({ id: 'Document.delete' })],
      [!smartDocument && capabilities?.canShare, intl.formatMessage({ id: 'Document.share' })],
      [!smartDocument && capabilities?.canUpdate, intl.formatMessage({ id: 'Document.viewHistory' })],
    ];

    const actionable = quickActions.filter((option) => option[0]).length > 0;

    const handleQuickActionClick = (option) => {
      switch (option) {
        case intl.formatMessage({ id: 'Document.rename' }):
          return startEditing();
        case intl.formatMessage({ id: 'Document.delete' }):
          return showAlert();
        case intl.formatMessage({ id: 'Document.viewHistory' }):
          return onViewHistoryClick();
        case intl.formatMessage({ id: 'Document.move' }):
          return onMoveClick();
        case intl.formatMessage({ id: 'Document.share' }):
          return onShareClick();
      }
    };

    const smartDocumentIcon = smartDocument && (
      <SmartDocumentIcon css={styles.smartDocumentBadge} className='smart-sign-icon' />
    );
    const sharedDocumentIcon = shared && <SharedIcon css={styles.sharedBadge} />;

    const starStyles = [styles.star.base, favourite && styles.star.favourite];

    const dateCreatedFull = moment(createdAt)
      .parseZone()
      .format(userDateFormat + ' hh:mm A');
    const [dateCreated, timeCreated] = dateCreatedFull.split(',');
    const documentName = isEditing ? name : `${name}.${extension}`;

    const onPreviewClick = () => {
      !isEditing && onClick();
    };

    return (
      <div css={styles.container} onClick={onPreviewClick} className='document-container'>
        <div css={styles.wrapper}>
          <div className='position-relative'>
            <DocumentIcon className='document__icon' />
            {smartDocumentIcon}
            {sharedDocumentIcon}
          </div>

          <div className={`document__name ${!isEditing && 'text-truncate'}`}>
            <EditableName
              value={documentName}
              error={error}
              highlight={highlight}
              isEditing={isEditing}
              onChange={resetError}
              onUpdate={updateDocument}
              onCancel={stopEditing}
            />
          </div>
        </div>

        <div className='document__createdAt'>
          {!isEditing ? (
            <>
              <span>{dateCreated}</span>
              <span>{timeCreated}</span>
            </>
          ) : null}
        </div>

        <div className='document__actions'>
          <LoadingIndicator show={isToggling || isUpdating || isDeleting} />

          <StarIcon css={starStyles} onClick={handleToggleFavouriteClick} />

          {actionable && <ActionsDropdown options={quickActions} onClick={handleQuickActionClick} />}

          <AlertPopup show={isAlertVisible} popupType='info' headerText='Confirmation' onClose={handleAlertCancel}>
            <div className='text-black fs-14'>
              <FormattedMessage id='Document.deleteConfirm' />
            </div>

            <div className='d-flex align-items-center m-t-40 justify-content-center'>
              <button className='btn btn-info' onClick={handleAlertConfirm}>
                <FormattedMessage id='Document.yes' />
              </button>

              <button className='btn btn-info-inverted m-l-10' onClick={handleAlertCancel}>
                <FormattedMessage id='Document.cancel' />
              </button>
            </div>
          </AlertPopup>
        </div>
      </div>
    );
  }
);

export { Document };
