/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as Folder } from 'images/icons/folder-lg.svg';
import * as styles from './FileBrowser.styles';

export const Folders = ({ rootDirectories, onClick }) => {
  if (!rootDirectories) {
    return null;
  }

  return (
    <div css={styles.padded}>
      <div css={styles.header}>Folders</div>
      <div css={styles.folders}>
        {rootDirectories.map((folder) => {
          const documentCount = folder.documentsCount;
          const subFolderCount = folder.directoriesCount;

          const isPersonal = folder.name === 'Personal Folders';
          const folderIconStyles = styles.folderIcon(isPersonal);
          const handleClick = onClick.bind(this, folder.id);

          return (
            <div className='react-folder' onClick={handleClick} key={folder.id} css={styles.folder}>
              <Folder css={folderIconStyles} />

              <div css={styles.name}>{folder.name}</div>

              <div css={styles.info}>
                <FormattedMessage id='Folders.fileCount' values={{ count: documentCount }} />
              </div>

              <div css={styles.info}>
                <FormattedMessage id='Folders.folderCount' values={{ count: subFolderCount }} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
