import React, { memo, useState } from 'react';

import { RoleDrawerProvider } from 'store/roleDrawerStoreContext';

import List from 'components/roles/List';
import { RoleDrawer } from 'components/roles/RoleDrawer';

import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';

import AppSignalWrapper from '../../v2/shared/AppSignalWrapper';
import { updateById } from 'utils/updateById';
import { TranslationContextProvider } from '../../../contexts/v2/TranslationContext';

export default memo(({ companyId, defaultRoleId, permissions }) => {
  const [accessRoles, setAccessRoles] = useState(null);

  const loadAccessRoles = (statuses) => {
    const path = `/api/${companyId}/v1/access_roles.json`;
    const status = statuses.filter((option) => option.value).map((option) => option.value);
    const payload = { params: { status: status } };

    const promise = axios
      .get(path, payload)
      .then((response) => response.data.data)
      .then(setAccessRoles);

    trackPromise(promise);
  };

  const createAccessRole = (params, onUpdate, onError) => {
    const path = `/api/${companyId}/v1/access_roles.json`;
    const payload = { role: params };

    const promise = axios
      .post(path, payload)
      .then((response) => response.data.data)
      .then(handleCreate)
      .then(onUpdate)
      .catch(onError);

    trackPromise(promise);
  };

  const updateAccessRole = (id, params, onUpdate, onError) => {
    const path = `/api/${companyId}/v1/access_roles/${id}.json`;
    const payload = { role: params };

    const promise = axios
      .put(path, payload)
      .then((response) => response.data.data)
      .then(handleUpdate)
      .then(onUpdate)
      .catch(onError);

    trackPromise(promise);
  };

  const deleteAccessRole = (id, replacementId, onDelete) => {
    const path = `/api/${companyId}/v1/access_roles/${id}.json?replacement_id=${replacementId}`;

    const promise = axios
      .delete(path)
      .then((response) => response.data.data)
      .then((response) => handleDelete(response, id))
      .then(onDelete);

    trackPromise(promise);
  };

  const handleDelete = (loadedAccessRoles, deletedRoleId) => {
    const existingAccessRoleIds = accessRoles.map((role) => role.id);
    const updatedAccessRoles = loadedAccessRoles.filter(
      (role) => existingAccessRoleIds.includes(role.id) && role.id !== deletedRoleId
    );

    setAccessRoles(updatedAccessRoles);
  };

  const handleCreate = (accessRole) => {
    setAccessRoles([...accessRoles, accessRole]);
  };

  const handleUpdate = (accessRole) => {
    setAccessRoles(updateById(accessRoles, accessRole));
  };

  const drawerTarget = document.querySelector('.settings.show .settings-container');

  return (
    <AppSignalWrapper tags={{ page: 'roles' }}>
      <TranslationContextProvider>
        <RoleDrawerProvider>
          <List accessRoles={accessRoles} onFiltersChange={loadAccessRoles} />

          <RoleDrawer
            permissions={permissions.data}
            accessRoles={accessRoles}
            defaultRoleId={defaultRoleId}
            onCreate={createAccessRole}
            onUpdate={updateAccessRole}
            onDelete={deleteAccessRole}
            appendTo={drawerTarget}
          />
        </RoleDrawerProvider>
      </TranslationContextProvider>
    </AppSignalWrapper>
  );
});
