import { gql, useQuery } from '@apollo/client';
import { MESSAGE_FRAGMENT } from './../fragments/MessageFragment';
import { CONTACT_FRAGMENT } from 'data/fragments/ContactFragment';

const CONVERSATION_QUERY = gql`
  query messages($last: Int, $before: String, $fetchParticipants: Boolean = false) {
    currentCompany {
      id
      name

      conversation {
        id

        participants @include(if: $fetchParticipants) {
          ...ContactFields
        }

        messages(last: $last, before: $before) @connection(key: "messages") {
          pageInfo {
            endCursor
            startCursor
            hasPreviousPage
            hasNextPage
          }

          edges {
            cursor

            node {
              ...MessageFields

              owner {
                ...ContactFields
              }
            }
          }
        }
      }
    }
  }
  ${MESSAGE_FRAGMENT}
  ${CONTACT_FRAGMENT}
`;

const useConversationQuery = (options) => useQuery(CONVERSATION_QUERY, options);

export { CONVERSATION_QUERY, useConversationQuery };
