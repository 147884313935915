import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const separator = css`
  width: 100%;
  height: 1px;
  border-top: 1px solid ${colors.separator};
`;

export const uppercasedText = css`
  ${fonts.regular}
  letter-spacing: 0.2em;
  margin-left: 7px;
  white-space: nowrap;
  text-transform: uppercase;
  color: ${colors.black};
  font-size: 14px;
  line-height: 16px;
`;
