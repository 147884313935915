import React from 'react';

import { client } from 'utils/apollo';
import { ApolloProvider } from '@apollo/client';

import { Icon } from 'components/conversations/Icon';

export default ({ showBadge, showChat, title = '' }) => (
  <ApolloProvider client={client}>
    <Icon showBadge={showBadge} showChat={showChat} title={title} />
  </ApolloProvider>
);
