export const STATUS_OPTIONS = [
  { value: 'draft', label: 'Draft' },
  { value: 'approved', label: 'Approved' },
  { value: 'in_review', label: 'In review' },
  { value: 'archived', label: 'Archived' },
];

export const TASK_STATUSES = [
  { value: 'Completed', className: 'badge badge-large badge-soft-low fs-i-12' },
  { value: 'Overdue', className: 'badge badge-large badge-danger fs-i-12 ' },
  { value: 'Archived', className: 'badge badge-large badge-archived fs-i-12' },
  { value: 'In Progress', className: 'badge badge-large badge-soft-draft fs-i-12' },
  { value: 'Incomplete', className: 'badge badge-large badge-soft-draft fs-i-12' },
];
