import styled from 'styled-components';
import { colors, fonts } from 'styles';

export const Outer = styled.div`
  #loading {
    display: none !important;
  }
`;

export const Bar = styled.div`
  background: ${colors.white};
  border-bottom: 1px solid ${colors.brandBorder};
  position: fixed;
  left: 0;
  z-index: 5;
  right: 0;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 61px;
  left: 0px;
  width: 100%;
  height: calc(100vh - 61px);
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const ViewerWrapper = styled.div`
  .viewport {
    padding: ${({ isdesktop }) => (!isdesktop ? '8px' : '0 30px 0 100px')};
    max-height: calc(100vh - 100px);
    margin-bottom: 24px;
    .canvas-page-wrapper {
      max-width: 777px;
      margin-bottom: 60px;
      border-bottom: none;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    canvas {
      box-shadow: 0px 0px 3px 1px rgba(51, 55, 70, 0.14);
    }
  }
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 1000;
`;

export const DropdownClose = styled.button`
  text-align: center;
  padding: 0;
  width: 100%;
  color: ${colors.red};
  text-align: center;
`;

export const DocumentNavigation = styled.div`
  position: absolute;
  top: 60px;
  background: ${colors.white};
  width: 304px;
  box-shadow: 0px 4px 50px rgba(54, 71, 98, 0.12);
  right: 0;
  h3 {
    color: ${colors.black};
    padding: 15px;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    margin-bottom: 0;
    font-size: 15px;
    @media all and (max-width: 767px) {
      font-size: 12px;
    }
  }
  .doc-description {
    display: flex;
    @media all and (max-width: 767px) {
      display: none;
    }
  }

  .scrollable {
    max-height: 580px;
    overflow: auto;
  }

  @media all and (max-width: 767px) {
    width: 142px;
  }
  transition: 0.2s;
  ${({ shownavigation }) => (shownavigation ? 'transform: translate(0%);' : 'transform: translate(100%);')}
  @media all and (max-height: 568px) {
    max-height: 490px;
  }
`;

export const NavigationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  font-size: 12px;
  justify-content: space-between;
  color: ${colors.black};
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #e8e8e8;
  }
  .row-label {
    margin-top: -2px;
    line-height: 10px;
  }
  .padding-indicator {
    color: ${colors.lightGrey};
  }
`;

export const ShowNavigation = styled.div`
  background: ${colors.white};
  box-shadow: 0px 4px 50px rgba(54, 71, 98, 0.12);
  position: absolute;
  right: 0;
  background: white;
  top: 112px;
  height: 54px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: 0.4s;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ shownavigation }) => (shownavigation ? 'transform: translate(100%);' : 'transform: translate(0%);')}
`;
