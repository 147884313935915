import { useState, useCallback } from 'react';

const useModal = () => {
  const [modalContext, setModalContext] = useState({
    show: false,
    resource: null,
  });

  const updateContext = (updates) => {
    setModalContext((state) => ({ ...state, ...updates }));
  };

  const openModal = useCallback((resource) => {
    updateContext({ resource, show: true });
  }, []);

  const closeModal = useCallback(() => {
    updateContext({ show: false });
  }, []);

  return {
    modalContext,
    openModal,
    closeModal,
  };
};

export { useModal };
