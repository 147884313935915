/** @jsxRuntime classic */
/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import * as styles from './CopyPopover.styles';

const CopyPopover = memo(({ children }) => {
  const copyPopover = (
    <Popover css={styles.popover}>
      <Popover.Content>
        <FormattedMessage id='CopyPopover.linkCopied' />
        <div className='triangle' />
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger='click' placement='top' overlay={copyPopover} rootClose>
      {children}
    </OverlayTrigger>
  );
});

export { CopyPopover };
