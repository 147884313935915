import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import AppHeader from '../../shared/AppHeader';
import { ToolbarButtonWrapper, IconButton, HeaderTitle, AppBody } from '../../shared/AppHeader/styles';
import { SendingSignContext } from '../../../contexts/MobSignContext';
import { SendToSignatories } from './SendToSignatories';
import { SentSuccess } from './SentSuccess';
import { Wrapper, FinishBtnWrapper } from './styles';

export const SendForMob = ({ filename, message, setMessage, documentSent, onSend, signatories, errors, onClose }) => {
  const { setIsOpendSendingSign } = useContext(SendingSignContext);

  const intl = useIntl();

  return (
    <Wrapper>
      <AppHeader>
        {!documentSent && (
          <ToolbarButtonWrapper>
            <IconButton
              onClick={() => {
                setIsOpendSendingSign(false);
              }}
            >
              <i className='far fa-angle-left fa-2x m-r-20' />
            </IconButton>
          </ToolbarButtonWrapper>
        )}
        <div className='d-flex justify-content-center w-100'>
          <HeaderTitle fontSize={18} lineHeight={34}>
            {documentSent
              ? intl.formatMessage({ id: 'SendForMob.sent' })
              : intl.formatMessage({ id: 'SendForMob.sendDocument' })}
          </HeaderTitle>
        </div>
      </AppHeader>
      <AppBody>
        {documentSent ? (
          <SentSuccess signatories={signatories} />
        ) : (
          <SendToSignatories
            signatories={signatories}
            errors={errors}
            documentName={filename}
            message={message}
            setMessage={setMessage}
          />
        )}

        <FinishBtnWrapper>
          {documentSent ? (
            <button onClick={onClose} className='btn btn-primary'>
              <FormattedMessage id='SendForMob.close' />
            </button>
          ) : (
            <button onClick={onSend} className='btn btn-primary'>
              <FormattedMessage id='SendForMob.send' />
            </button>
          )}
        </FinishBtnWrapper>
      </AppBody>
    </Wrapper>
  );
};
