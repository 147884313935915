import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { intl } from 'contexts/v2/TranslationContext';
import { FiFile } from 'react-icons/fi';

const AgendaItemDocumentList = ({ documents, onClick }) => (
  <div className='tw-flex tw-flex-col tw-gap-1'>
    <div className='tw-text-xs tw-text-gray-700'>{intl.formatMessage({ id: 'AgendaItem.assets' })}</div>

    {documents.map((document) => (
      <button
        key={document.label}
        onClick={onClick(document)}
        className={clsx(
          'tw-border-0 tw-bg-transparent tw-p-0 tw-outline-none',
          'tw-flex tw-min-w-0 tw-items-center tw-gap-2',
          'tw-cursor-pointer',
          'tw-text-black hover:tw-text-purple-500'
        )}
      >
        <FiFile className='tw-h-4 tw-w-4 tw-shrink-0' />
        <span className='tw-truncate tw-text-sm tw-font-medium'>{document.label}</span>
      </button>
    ))}
  </div>
);

AgendaItemDocumentList.propTypes = {
  documents: PropTypes.array,
  onClick: PropTypes.func,
};

export default AgendaItemDocumentList;
