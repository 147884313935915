/** @jsx jsx */

import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import * as styles from './Icon.styles';

const Icon = memo(({ icon, position, isRotatable, asFocused, asInvalid, ...props }) => {
  if (!icon) {
    return <></>;
  }

  const appliedStyles = [
    styles.icon.base,
    styles.icon.alignment[position],
    isRotatable && asFocused && styles.icon.state.rotated,
    asFocused && styles.icon.state.focused,
    asInvalid && styles.icon.state.invalid,
  ];

  return (
    <div css={appliedStyles} {...props}>
      {icon}
    </div>
  );
});

Icon.propTypes = {
  icon: PropTypes.object,
  position: PropTypes.oneOf(['left', 'right']),
  asFocused: PropTypes.bool,
  asInvalid: PropTypes.bool,
};

export { Icon };
