/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import AvatarWithBadge from 'components/v2/shared/AvatarWithBadge';
import Text from 'components/v2/shared/Text';
import TextButton from 'components/v2/shared/TextButton';
import LabeledRow from 'components/v2/shared/LabeledRow';
import Link from 'components/v2/shared/Link';

import * as styles from './OverviewMeetingBreakdown.styles';

const OverviewMeetingBreakdown = () => (
  <div className='mt-4 mb-4'>
    <LabeledRow className='mt-2' label='Owner'>
      <AvatarWithBadge text='HB' />
      <Text text='Hannah Burgess' className='ml-3' />
    </LabeledRow>

    <LabeledRow className='mt-2' label='Chair'>
      <AvatarWithBadge text='HB' />
      <Text text='Hannah Burgess' className='ml-3' />
    </LabeledRow>

    <LabeledRow className='mt-2' label='Meeting Type'>
      <Text text='Annual General Meeting' />
    </LabeledRow>

    <LabeledRow className='mt-2' label='Duration'>
      <Text text='03:30 am — 02:45 pm' />
    </LabeledRow>

    <LabeledRow className='mt-2' label='Timezone'>
      <Text text='(GMT+00:00) London' />
    </LabeledRow>

    <LabeledRow className='mt-2' label='Conference link'>
      <Link href='https://us04web.zoom.us/j/7730489898?pwd=UUNJTDdENXhxa2JmNTNLSlkwdFBXQT09'>
        <Text
          text='https://us04web.zoom.us/j/7730489898?pwd=UUNJTDdENXhxa2JmNTNLSlkwdFBXQT09'
          isUnderline
          color='cyan'
        />
      </Link>
    </LabeledRow>

    <LabeledRow className='mt-2' label='Location'>
      <Text text='500 Howard Street, San Francisco, California 94105' isUnderline />
    </LabeledRow>

    <LabeledRow className='mt-2' label='Invite and notice schedule'>
      <div className='d-flex align-items-center'>
        <Text text='Invites to be sent - 12.02.2022' className='d-block' />
        <TextButton text='edit' className='ml-2' />
      </div>

      <Text text='Notice sent - 03.01.2022' className='d-block' />
    </LabeledRow>

    <LabeledRow className='mt-2' label='Attendees'>
      <div className='d-flex align-items-center'>
        <Text text='Invites to be sent - 12.02.22' />
        <TextButton text='add attendees' className='ml-2' />
      </div>
    </LabeledRow>

    <LabeledRow className='mt-2' label='To be invited' isBold={false}>
      <AvatarWithBadge text='HB' className='mr-2' />
      <AvatarWithBadge text='HB' className='mr-2' badgeColor='pink' />
      <AvatarWithBadge text='HB' className='mr-2' avatarColor='cyan' />
      <AvatarWithBadge text='HB' className='mr-2' badgeColor='green' />
      <AvatarWithBadge text='HB' className='mr-2' />
    </LabeledRow>
  </div>
);

OverviewMeetingBreakdown.propTypes = {};

export default OverviewMeetingBreakdown;
