import React, { useContext, createContext, useRef, useState, useEffect, Fragment } from 'react';
import Heading from 'components/v2/shared/Heading';
import Button from 'components/v2/shared/Button';
import StateBadge from 'components/v2/shared/StateBadge';
import Filter from 'components/v2/shared/Filter';
import TextInput from 'components/v2/shared/TextInput';
import { useCreateContactsMutation } from 'data/v2/mutations/CreateContactsMutation';
import Modal, { ModalContext } from 'components/v2/shared/Modal';
import ContactIndividual from 'components/v2/contacts/ContactIndividual';
import Approval from 'components/v2/contacts/Approval';
import clsx from 'clsx';
import List from 'components/v2/shared/List';
import ContactListItem from '../contacts/ContactListItem';
import SearchBar from '../shared/SearchBar';
import { ToastContext } from 'contexts/v2/ToastContext';
import { useContactsQuery } from 'data/v2/queries/ContactsQuery';
import { useSubcommitteesQuery } from '../../subcommittees/data/queries/SubcommitteesQuery';
import Toast, { Severity } from '../shared/Toast/Toast';
import { MdDelete } from 'react-icons/md';
import IconButton from 'components/v2/shared/IconButton';
import { wrapMutation } from 'utils/v2/gql';
import { HiChevronDown } from 'react-icons/hi2';
import { HiChevronUpDown } from 'react-icons/hi2';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Spinner from '../shared/Spinner/Spinner';
import { useTailwindBreakpoints } from 'hooks/v2/useTailwindBreakpoints';

export const SelectedContactContext = createContext();

const filterItems = [
  { id: 1, name: 'View by A - Z', value: 'first_name ASC, last_name ASC' },
  { id: 2, name: 'View by Z - A', value: 'first_name DESC, last_name DESC' },
  { id: 3, name: 'View by status', value: 'status DESC' },
  { id: 4, name: 'View by role', value: 'access_role_id DESC' },
];

const inviteFormSchema = z.object({
  invite: z.array(
    z.object({
      email: z
        .string({
          required_error: 'Email address is required',
          invalid_type_error: 'Invalid value',
        })
        .email({ message: 'Invalid email address' }),
      accessRoleId: z.string(),
      subcommitteeId: z.string().nullable().optional(),
    })
  ),
});

const Contacts = ({ roles, contactRequests }) => {
  let searchTimeout;
  const PER_PAGE = 10;
  const { toastInfo, setToastInfo } = useContext(ToastContext);
  const [breakpoint] = useTailwindBreakpoints();
  const [filter, setFilter] = useState(filterItems[0]);
  const [search, setSearch] = useState('');
  const [pageData, setPageData] = useState(() => ({
    pageTotal: 1,
    perPage: PER_PAGE,
    page: 1,
  }));
  const [contacts, setContacts] = useState(() => ({
    contactRequests,
    items: [],
  }));
  const {
    data: contactsData,
    loading,
    fetchMore,
    refetch,
  } = useContactsQuery({
    variables: { page: pageData.page, limit: PER_PAGE, order: filter.value, search },
  });

  const { data: subcommitteeData, refetch: subcommitteeRefetch } = useSubcommitteesQuery();
  const [isIndividual, setIndividual] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [modalState, setModalState] = useState({
    inviteModal: false,
    archiveModal: false,
    reassignModal: false,
  });
  const [createContacts] = useCreateContactsMutation();

  useEffect(() => {
    if (contactsData) {
      setCompanyContacts();
      setPageData((prev) => ({
        ...prev,
        pageTotal:
          contactsData.companyContactCount > PER_PAGE ? Math.ceil(contactsData.companyContactCount / PER_PAGE) : 1,
      }));
    }
  }, [contactsData]);

  const roleFilterItems = roles?.map((role) => ({
    id: role.id,
    name: role.name,
  }));

  const subcommitteeFilterItems = subcommitteeData?.currentCompany.subcommittees.map((sc) => ({
    id: sc.id,
    name: sc.name,
  }));

  const handlePaginationClick = (page) => {
    setPageData((prev) => ({
      ...prev,
      page,
    }));

    fetchMore({
      variables: {
        page,
        limit: PER_PAGE,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.contacts.edges;
        return newEdges.length
          ? {
              contacts: {
                edges: [...newEdges],
              },
            }
          : prevResult;
      },
    });
  };

  const getContactItems = () => {
    return contactsData.contacts.edges
      .filter((edge) => !['requested', 'rejected'].includes(edge.node.status))
      .map((c) => {
        c = c.node;

        return {
          name: c.name,
          id: c.id,
          isOwner: c.isOwner,
          contactRole: c.contactRole,
          accessRoleId: c.accessRoleId,
          avatarUrl: c.avatarUrl,
          initials: c.initials,
          companyPosition: c.companyPosition,
          status: c.status,
          email: c.email,
          linkedinUrl: c.linkedinUrl,
          phoneNumber: c.phoneNumber,
          address: c.address,
          createdAt: c.createdAt,
          daysSinceCreation: c.daysSinceCreation,
          lastActiveDate: c.lastActiveDate,
          daysSinceActive: c.daysSinceActive,
          actionItems: c.actionItems,
        };
      });
  };

  const setCompanyContacts = () => {
    let companyContacts = getContactItems();
    setContacts((prev) => {
      return {
        ...prev,
        items: companyContacts,
      };
    });
  };

  const individualRef = useRef(null);

  const handleModal = (key, value) => {
    setModalState((prev) => ({ ...prev, [key]: value }));
  };

  const onDecide = (id) => {
    setContacts((prev) => {
      return {
        ...prev,
        contactRequests: contacts.contactRequests.filter((c) => c.id !== id),
      };
    });
  };

  const onSortSelect = (obj) => {
    const selectedFilter = filterItems.find((item) => item.id == obj.id);
    setFilter(selectedFilter);
    setPageData((prev) => ({
      ...prev,
      page: 1,
    }));
    refetch({ page: 1, limit: PER_PAGE, order: selectedFilter.value });
  };

  const InviteContactModal = () => {
    const { control, handleSubmit } = useForm({
      resolver: zodResolver(inviteFormSchema),
      defaultValues: {
        invite: [{ email: '', accessRoleId: null, subcommitteeId: null }],
      },
    });
    const { fields, append, remove } = useFieldArray({
      control,
      name: 'invite',
    });
    const { onClose } = useContext(ModalContext);

    const handleAddRow = (e) => {
      e.preventDefault();
      append();
    };

    const handleSendInvites = async (data) => {
      try {
        await wrapMutation(
          createContacts,
          {
            variables: {
              input: {
                contacts: data.invite,
              },
            },
          },
          'createContacts'
        );
        subcommitteeRefetch();
        onClose();
        setToastInfo({
          severity: Severity.Success,
          status: 'Success',
          message: `Successfully added ${data.invite.length} new contacts`,
        });
        refetch({ page: 1, limit: PER_PAGE, order: filter.value });
      } catch (error) {
        Object.values(error).forEach((err) => {
          setToastInfo({
            severity: Severity.Error,
            status: 'Error',
            message: err,
          });
        });
      }
    };

    return (
      <form className='tw-mx-5 tw-my-11 sm:tw-mx-[55px] sm:tw-my-[60px]' onSubmit={handleSubmit(handleSendInvites)}>
        <Heading text='Invite' className='tw-mb-4 tw-text-[26px] tw-font-bold' />
        <div className='tw-mb-4 tw-font-sans tw-text-sm tw-font-medium tw-text-black'>
          Invite people outside your organization
        </div>
        <ul className='tw-list-none tw-p-0'>
          {fields.map((item, index) => {
            return (
              <li key={item.id} className='tw-mb-[15px]'>
                <div className='tw-mb-1 tw-flex tw-items-center tw-justify-between sm:tw-mb-0 sm:tw-block'>
                  <Heading text={`Contact ${index + 1}`} className='tw-mb-[2px] !tw-text-base' />
                  <IconButton
                    aria-label='first'
                    className='tw-col-span-1 tw-block !tw-h-11 !tw-w-11 tw-justify-self-center tw-rounded-full tw-text-xl sm:tw-hidden'
                    onClick={() => remove(index)}
                  >
                    <MdDelete className='tw-text-gray-700' />
                  </IconButton>
                </div>
                <div className='tw-flex tw-flex-col tw-gap-2 sm:tw-grid sm:tw-grid-cols-8'>
                  <div className='tw-col-span-3'>
                    <Controller
                      control={control}
                      name={`invite.${index}.email`}
                      render={({ field, fieldState: { error } }) => (
                        <TextInput
                          className='tw-border-gray-700 tw-text-sm'
                          placeholder='Email address'
                          {...field}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </div>
                  <div className='tw-col-span-2 tw-h-11'>
                    <Controller
                      control={control}
                      name={`invite.${index}.accessRoleId`}
                      render={({ field }) => (
                        <Filter
                          items={roleFilterItems}
                          placeholder='Role'
                          iconRight={<HiChevronDown className='tw-text-2xl' />}
                          {...field}
                          onSelect={(data) => {
                            field.onChange(data.id);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className='tw-col-span-2 tw-h-11'>
                    <Controller
                      control={control}
                      name={`invite.${index}.subcommitteeId`}
                      render={({ field }) => (
                        <Filter
                          items={subcommitteeFilterItems}
                          placeholder='Subcommittee'
                          iconRight={<HiChevronDown className='tw-text-2xl' />}
                          {...field}
                          onSelect={(data) => {
                            field.onChange(data.id);
                          }}
                        />
                      )}
                    />
                  </div>
                  <IconButton
                    aria-label='first'
                    className='tw-col-span-1 tw-hidden !tw-h-11 !tw-w-11 tw-justify-self-center tw-rounded-full tw-text-xl sm:tw-block'
                    onClick={() => remove(index)}
                  >
                    <MdDelete className='tw-text-gray-700' />
                  </IconButton>
                </div>
              </li>
            );
          })}
        </ul>
        <Button
          className='tw-mt-5 tw-h-11 tw-w-full tw-cursor-pointer !tw-font-sans !tw-text-sm !tw-font-semibold sm:tw-w-auto'
          variant='teal'
          onClick={handleAddRow}
        >
          Add Contact
        </Button>
        <Button
          type='submit'
          font='semibold'
          size='large'
          variant='purple'
          className='tw-mt-[14px] tw-w-full tw-text-base '
        >
          Send Invite
        </Button>
      </form>
    );
  };

  const ConfirmReassignModal = () => {
    return (
      <div className='tw-m-14'>
        <div className='tw-font-sans tw-text-xl tw-font-bold tw-text-black'>Archive contact</div>
        <p className='tw-mt-4 tw-flex tw-font-sans tw-text-sm tw-font-medium tw-text-black'>
          The contact you are about to archive has &nbsp;
          <span className='tw-font-bold'> 1 active action item:</span>
        </p>
        <p className='tw-mt-4 tw-flex tw-font-sans tw-text-sm tw-font-medium tw-text-black'>
          1. &nbsp;
          <span className='tw-font-bold'> Hannah</span>
        </p>

        <p className='tw-mt-4 tw-font-sans tw-text-sm tw-font-medium tw-text-black'>
          Would you like to reassign this action item to the administrator?
        </p>

        <div className='tw-mt-8'>
          <div className='tw-grid tw-grid-flow-col tw-grid-cols-2 tw-gap-2'>
            <div className='tw-col-span-1'>
              <Button
                font='semibold'
                className='tw-text-xs'
                isFullWidth
                onClick={() => handleModal('reassignModal', false)}
                size='large'
                variant='purple'
              >
                Yes
              </Button>
            </div>
            <div className='tw-col-span-1'>
              <Button
                font='semibold'
                className='tw-text-xs'
                isFullWidth
                onClick={() => handleModal('reassignModal', false)}
                size='large'
                variant='Lavander'
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RoleList = () => {
    let roles = new Set(contacts?.items.map((m) => m.contactRole));
    roles = [...roles];
    roles = roles.reduce((r, k) => {
      r[`${k.charAt(0).toUpperCase() + k.slice(1)}`] = contacts.items.filter((m) => m.contactRole === k);
      return r;
    }, {});

    return Object.keys(roles).map((role, index) => (
      <Fragment key={role}>
        <Heading text={role} className='!tw-mb-4 !tw-mt-12 !tw-text-xl' />
        <List
          items={roles[`${role}`]}
          render={(props) => <ContactListItem openIndividual={openIndividual} {...props} />}
          paginated={index == Object.keys(roles).length - 1 && pageData.pageTotal > 1}
          page={pageData.page}
          perPage={pageData.perPage}
          pageTotal={pageData.pageTotal}
          onPaginationClick={handlePaginationClick}
        />
      </Fragment>
    ));
  };

  const StatusList = () => {
    let statuses = new Set(contacts?.items.map((m) => m.status));
    statuses = [...statuses];
    statuses = statuses.reduce((s, k) => {
      s[`${k.charAt(0).toUpperCase() + k.slice(1)}`] = contacts.items.filter((m) => m.status === k);
      return s;
    }, {});

    return Object.keys(statuses).map((status, index) => (
      <Fragment key={status}>
        <Heading text={status} className='!tw-mb-4 !tw-mt-12 !tw-text-xl' />
        <List
          items={statuses[`${status}`]}
          render={(props) => <ContactListItem openIndividual={openIndividual} {...props} />}
          paginated={index == Object.keys(statuses).length - 1 && pageData.pageTotal > 1}
          page={pageData.page}
          perPage={pageData.perPage}
          pageTotal={pageData.pageTotal}
          onPaginationClick={handlePaginationClick}
        />
      </Fragment>
    ));
  };

  const openIndividual = (state, contactData, i) => {
    setIndividual(state);
    setSelectedContact({ ...contactData, roles: roles });
    state && individualRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  const handleSearchChange = async (e) => {
    clearTimeout(searchTimeout);
    const val = e.target.value.toLowerCase();

    searchTimeout = setTimeout(() => {
      try {
        setSearch(val);
        refetch({ page: 1, limit: PER_PAGE, order: filter.value, search: val });
      } catch (error) {
        setToastInfo({
          severity: Severity.Error,
          status: 'Search Failed',
          message: error.message,
        });
      }
    }, 2000);
  };

  return (
    <SelectedContactContext.Provider value={[selectedContact, setSelectedContact]}>
      <div className='tw-flex tw-justify-center sm:tw-px-9 sm:tw-pt-9 md:tw-px-8'>
        <Toast severity={toastInfo.severity} status={toastInfo.status} message={toastInfo.message} />
        <div
          className={clsx('tw-mx-auto tw-block tw-w-full', {
            'xl:tw-flex': isIndividual,
          })}
        >
          <div
            className={clsx('-tw-mt-9 md:tw-px-8', {
              'tw-hidden': isIndividual,
              'xl:tw-w-3/5 xl:tw-border-0 xl:tw-border-r xl:tw-border-solid xl:tw-border-gray-200': isIndividual,
              'lg:tw-block': ['xl', '2xl'].includes(breakpoint) && isIndividual,
            })}
          >
            <div className='tw-mx-auto tw-mt-9 sm:tw-max-w-xl'>
              <Heading text='Contacts' className='!tw-mb-0 !tw-text-[26px]' />
              <div className='tw-mt-[10px] tw-flex tw-flex-col tw-items-center tw-space-x-3 sm:tw-flex-row'>
                <div className='tw-w-full tw-flex-1 tw-cursor-pointer'>
                  <SearchBar className='tw-h-[38px]' onChange={handleSearchChange} />
                </div>
                <div className='tw-mt-[10px] !tw-ml-0 tw-flex tw-w-full tw-space-x-3 sm:!tw-ml-3 sm:tw-mt-0 sm:tw-w-auto'>
                  <div className='tw-h-[38px] tw-flex-1 sm:tw-flex-none'>
                    <Filter
                      items={filterItems}
                      defaultValue={filterItems[0]}
                      onSelect={onSortSelect}
                      iconLeft={<HiChevronUpDown className='tw-h-6 tw-w-6' />}
                    />
                  </div>
                  <Button
                    font='semibold'
                    size='small'
                    variant='teal'
                    className='tw-h-[38px] tw-flex-1 tw-px-4 tw-text-sm sm:tw-flex-none'
                    onClick={() => handleModal('inviteModal', true)}
                  >
                    Invite new
                  </Button>
                </div>
              </div>
              {loading ? (
                <div className='tw-flex tw-h-40 tw-items-center tw-justify-center'>
                  <Spinner className='!tw-static' />
                </div>
              ) : (
                <>
                  {contacts?.contactRequests?.length > 0 && (
                    <div className=' tw-mt-10 tw-flex tw-justify-center'>
                      <div className='tw-relative tw-flex tw-w-full tw-flex-col tw-justify-center tw-rounded-lg tw-border tw-border-dashed tw-border-teal-500 tw-pb-3 tw-pr-3 tw-pl-4 tw-pt-6 sm:tw-max-w-xl'>
                        <div className='tw-absolute -tw-top-3.5 tw-left-6'>
                          <StateBadge className='tw-h-6 tw-w-36 !tw-bg-teal-200 !tw-font-sans !tw-text-sm !tw-font-semibold'>
                            Awaiting approval
                          </StateBadge>
                        </div>
                        {contacts.contactRequests.map((c) => (
                          <Approval key={c.id} id={c.id} email={c.email} createdAt={c.createdAt} onDecide={onDecide} />
                        ))}
                      </div>
                    </div>
                  )}
                  <div className='tw-mt-7 tw-w-full'>
                    <div>
                      {(filter.id == 1 || filter.id == 2) && (
                        <List
                          items={contacts.items}
                          render={(props) => <ContactListItem openIndividual={openIndividual} {...props} />}
                          paginated={pageData.pageTotal > 1}
                          page={pageData.page}
                          perPage={pageData.perPage}
                          pageTotal={pageData.pageTotal}
                          component={ContactListItem}
                          onPaginationClick={handlePaginationClick}
                        />
                      )}
                      {filter.id == 3 && <StatusList />}
                      {filter.id == 4 && <RoleList />}
                    </div>
                  </div>
                </>
              )}
              <div>
                <div className='tw-mt-7 tw-w-full'>
                  <Modal
                    isOpen={modalState.inviteModal}
                    onClose={() => handleModal('inviteModal', false)}
                    centered
                    size='2xl'
                  >
                    <InviteContactModal />
                  </Modal>
                  <Modal
                    isOpen={modalState.reassignModal}
                    onClose={() => handleModal('reassignModal', false)}
                    centered
                    size='xl'
                  >
                    <ConfirmReassignModal />
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          <div
            ref={individualRef}
            className={clsx('md:tw-px-8 xl:tw-w-2/5', {
              'tw-hidden': !isIndividual,
            })}
          >
            <div className='tw-mx-auto sm:tw-max-w-xl xl:tw-w-[350px] xl:tw-max-w-none'>
              <ContactIndividual selectedContact={selectedContact} openIndividual={openIndividual} />
            </div>
          </div>
        </div>
      </div>
    </SelectedContactContext.Provider>
  );
};

export default Contacts;
