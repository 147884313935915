import { css } from '@emotion/core';
import { colors } from 'styles';

export const modal = css`
  .modal-dialog {
    width: 330px;
  }

  .modal-title {
    color: ${colors.black};
  }
`;
