/** @jsx jsx */

import React, { memo, useRef, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';

import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { PageRenderer } from './../PageRenderer';
import * as styles from './PdfViewer.styles';

const PAGE_MARGIN = 20;
const VIEWER_MARGIN = 80;

const PdfViewer = memo(({ source, pageWidth, viewerScale, viewerWidth, onRender }) => {
  const [pdfData, setPdfData] = useState({
    totalHeight: null,
    pages: null,
    dimensions: null,
  });

  const scrollableRef = useRef();
  const viewerRef = useRef();

  const getPageOffsetTop = (dimensions, index) => {
    if (dimensions[index - 1]) {
      return dimensions[index - 1].top + dimensions[index - 1].height + PAGE_MARGIN;
    } else {
      return 0;
    }
  };

  const setPdfDimensions = (pdf) => {
    const indexes = Array.from({ length: pdf.numPages }, (_, i) => i + 1);
    const promises = indexes.map((index) => pdf.getPage(index));

    Promise.all(promises).then((pages) => {
      const dimensions = {};
      let totalHeight = 0;

      for (const page of pages) {
        const viewbox = page.getViewport({ scale: 1 });
        const index = page._pageIndex + 1;
        const offsetTop = getPageOffsetTop(dimensions, index);
        const height = (viewerWidth / viewbox.width) * viewbox.height;

        totalHeight += height + PAGE_MARGIN;

        dimensions[page._pageIndex + 1] = {
          width: viewbox.width,
          height: height,
          top: offsetTop,
        };
      }

      totalHeight += VIEWER_MARGIN;

      setPdfData({ totalHeight, dimensions, pages: pdf.numPages });
    });
  };

  const pageList =
    pdfData.dimensions &&
    Array.from({ length: pdfData.pages }).map((_, index) => {
      const { width, height, top } = pdfData.dimensions[index + 1];

      return (
        <PageRenderer
          key={`pdf-page-${index + 1}`}
          index={index}
          viewer={scrollableRef.current}
          margin={PAGE_MARGIN}
          viewerWidth={viewerWidth}
          pageWidth={pageWidth}
          offsetTop={top}
          initialPageHeight={height}
          onRender={onRender}
        />
      );
    });

  useEffect(() => {
    if (viewerScale.current === viewerScale.previous) return;

    const scaleRatio = viewerScale.current / viewerScale.previous;
    const prevHeight = pdfData.totalHeight * viewerScale.previous;
    const newHeight = pdfData.totalHeight * viewerScale.current;
    const heightDiff = newHeight - prevHeight;
    const nextScroll = scrollableRef.current.scrollTop * scaleRatio;

    viewerRef.current.style.transform = `scale(${viewerScale.current})`;

    if (newHeight > nextScroll) {
      scrollableRef.current.scrollTop = nextScroll;
    } else {
      scrollableRef.current.scrollTop = nextScroll - heightDiff;
    }
  }, [viewerScale]);

  const viewerStyles = styles.viewer({
    height: pdfData.totalHeight,
    margin: VIEWER_MARGIN,
  });

  return (
    <div css={styles.outer} ref={scrollableRef}>
      <div css={viewerStyles} ref={viewerRef}>
        <Document file={source} onLoadSuccess={setPdfDimensions}>
          {pageList}
        </Document>
      </div>
    </div>
  );
});

export { PdfViewer };
