import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from 'components/v2/shared/Button';

const ListPlaceholder = ({ listItemNode, placeholderText, buttonText, onButtonClick }) => (
  <div className='tw-relative tw-flex tw-flex-col tw-gap-2'>
    <div className='tw-opacity-100'>{listItemNode}</div>
    <div className='tw-opacity-80'>{listItemNode}</div>
    <div className='tw-opacity-60'>{listItemNode}</div>
    <div className='tw-opacity-40'>{listItemNode}</div>
    <div className='tw-opacity-30'>{listItemNode}</div>
    <div className='tw-opacity-20'>{listItemNode}</div>

    <div className='tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center'>
      <div className='tw-w-full tw-max-w-lg tw-rounded-xl tw-bg-white tw-shadow-placeholder'>
        <div className='tw-mx-auto tw-flex tw-max-w-sm tw-flex-col tw-gap-6 tw-px-6 tw-py-10'>
          <div className='tw-text-center tw-text-xl tw-font-bold tw-text-black'>{placeholderText}</div>
          <Button variant='teal' onClick={onButtonClick}>
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

ListPlaceholder.propTypes = {
  listItemNode: PropTypes.node.isRequired,
  placeholderText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
};

export default ListPlaceholder;
