import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import clsx from 'clsx';
import BaseOption from '../BaseOption';

const ContactGroupOption = ({ item, ...props }) => (
  <BaseOption {...props} className='tw-flex-col'>
    <div className='tw-text-sm tw-text-black'>{item.label}</div>
    <div className='tw-text-xs tw-text-gray-600'>{pluralize('member', item.contacts.length, true)}</div>
  </BaseOption>
);

ContactGroupOption.propTypes = {
  ...BaseOption.propTypes,
  item: PropTypes.object,
};

export default ContactGroupOption;
