import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';

export const sort = css`
  padding: 0 10px;
  ${media.tablet} {
    padding: 0 15px;
  }
`;

export const actions = css`
  display: flex;
  justify-content: space-between;
  padding: 0 10px 10px 10px;
  ${media.tablet} {
    padding: 0 15px 10px 15px;
  }
`;

export const icon = css`
  margin-right: 5px;
`;

export const placeholder = css`
  border-radius: 21px;
  background: ${colors.lightestGrey};
  padding: 60px 0;
`;

export const children = css`
  height: calc(100% - 50px);
  overflow: auto;
`;
