import React, { memo } from 'react';
import { EditableInput } from 'components/shared/EditableInput';

const MAX_WIDTH = 170;

const EditableName = memo(({ value, error, highlight, isEditing, onChange, onUpdate, onCancel }) => {
  if (!isEditing && highlight) {
    return highlight;
  }

  if (!isEditing && value) {
    return value;
  }

  return (
    <div style={{ maxWidth: MAX_WIDTH }}>
      <EditableInput
        isEditable
        value={value}
        error={error}
        minWidth={MAX_WIDTH}
        maxWidth={MAX_WIDTH}
        onChange={onChange}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
    </div>
  );
});

export { EditableName };
