import { gql, useQuery } from '@apollo/client';
import { TASK_FRAGMENT } from 'data/v2/fragments/TaskFragment';

const MEETING_TASKS_QUERY = gql`
  query MeetingTasksQuery($id: ID!) {
    currentCompany {
      id

      meeting(id: $id) {
        id

        tasks {
          ...TaskFields
        }
      }
    }
  }
  ${TASK_FRAGMENT}
`;

const useMeetingTasksQuery = (options) => useQuery(MEETING_TASKS_QUERY, options);

export { MEETING_TASKS_QUERY, useMeetingTasksQuery };
