import React from 'react';
import Document from 'images/icons/document.svg';
import { StyledDocumentExtension } from './styles';

export const CurrentDocument = ({
  multiple,
  handleMultiClick,
  handleClick,
  documentClassName,
  extension,
  name,
  path,
  searchMode,
  createdAt,
}) => {
  const timestampFormat = 'DD.MM.YYYY hh:mm A';

  return (
    <div onClick={multiple ? handleMultiClick : handleClick} className={documentClassName}>
      <div className='directory-container dir-link'>
        <div className='d-flex align-items-center name-block'>
          <div className='flex-shrink-0 position-relative'>
            <img src={Document} className='m-0' />
            <StyledDocumentExtension>{extension}</StyledDocumentExtension>
          </div>

          <div className='lh-1 w-100'>
            <h5 className='card-title m-t-0 m-b-0 m-l-20 text-truncate'>{name}</h5>
            {searchMode && <span className='m-l-20 text-light-grey fs-10 card-title m-b-0'>{path}</span>}
          </div>
        </div>

        <span className='card-title m-t-0 m-b-0'>{moment(createdAt).parseZone().format(timestampFormat)}</span>
      </div>
    </div>
  );
};
