/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';

import { ReactComponent as VoteCheckmarkIcon } from 'images/icons/vote-checkmark.svg';

import { Viewer } from 'components/votes/Viewer/Viewer';

import * as styles from './VotingDrawer.styles';

const VotingDrawer = memo(({ votes, show, hide, previewDocument }) => {
  const [activeVote, setActiveVote] = useState(votes[0]);

  const changeActiveVote = (vote) => setActiveVote(vote);
  const isVoteActive = (vote) => activeVote == vote;

  const renderVoteList = votes.map((vote) => {
    const handleVoteChange = changeActiveVote.bind(this, vote);

    return (
      <div
        key={vote.data.id}
        className={`nav-link cursor-pointer ${isVoteActive(vote) ? 'active' : ''}`}
        onClick={handleVoteChange}
      >
        {isVoteActive(vote) && <VoteCheckmarkIcon className='m-0 m-r-10' />}
        <span>{vote.data.attributes.name}</span>
      </div>
    );
  });

  return (
    <>
      {show && (
        <div css={styles.votingDrawer}>
          <div className='text-right close-icon' onClick={hide}>
            <i className='far fa-times' />
          </div>

          <div className='wrapper'>
            <div className='row m-0'>
              <div className='col-md-3 p-0 side-nav'>
                <div className='nav flex-column nav-pills nav-pills-tab'>{renderVoteList}</div>
              </div>

              <div className='col-md-9 p-0 main-content'>
                <Viewer
                  vote={activeVote}
                  voter={activeVote.data.attributes.voter}
                  meetingDrawer={true}
                  loggedIn={true}
                  previewDocument={previewDocument}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export { VotingDrawer };
