import React, { useState, useEffect } from 'react';
import Button from 'components/v2/shared/Button';
import TextInput from 'components/v2/shared/TextInput';
import Alert from 'components/v2/shared/Alert';
import Label from 'components/v2/shared/Label';
import OrderOverview from './OrderOverview';
import axios from 'axios';
import { FiX } from 'react-icons/fi';
import { useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';

const CustomTextField = ({ label, element }) => {
  return (
    <div className='tw-flex tw-flex-col tw-space-y-1'>
      <Label text={label} size='large' />
      <div className='tw-ring tw-ring-solid tw-ring-gray-500 tw-ring-1 tw-rounded-sm tw-p-5'>
        {element}
      </div>
    </div>
  );
};

const PurchasePlan = ({ company, onCardDetailsDone, onAddCoupon, product, taxData }) => {
  const [alert, setAlert] = useState({ display: false, isValid: false });
  const [couponCode, setCouponCode] = useState('');
  const [coupon, setCoupon] = useState(null);
  const elements = useElements();

  useEffect(() => {
    const cardNumberElement = elements?.getElement('cardNumber');
    const cardCvcElement = elements?.getElement('cardCvc');
    const cardExpiryElement = elements?.getElement('cardExpiry');

    let isCardNumberComplete = false;
    let isCvcComplete = false;
    let isExpiryComplete = false;

    const handleInputChange = (e) => {
      switch (e.elementType) {
        case 'cardNumber':
          e.complete ? isCardNumberComplete = true : isCardNumberComplete = false;
          break;
        case 'cardCvc':
          e.complete ? isCvcComplete = true : isCvcComplete = false;
          break;
        case 'cardExpiry':
          e.complete ? isExpiryComplete = true : isExpiryComplete = false;
      }

      if (isCardNumberComplete && isCvcComplete && isExpiryComplete) {
        onCardDetailsDone(elements.getElement(CardNumberElement));
      } else {
        onCardDetailsDone(null);
      }
    };

    if (cardNumberElement && cardCvcElement && cardExpiryElement) {
      cardNumberElement.addEventListener('change', handleInputChange);
      cardCvcElement.addEventListener('change', handleInputChange);
      cardExpiryElement.addEventListener('change', handleInputChange);
    }
  }, [elements]);

  const handleCouponInputChange = (e) => {
    setCouponCode(e.target.value);
  };

  const handleApplyClick = async () => {
    const result = await axios.get(`/${company.slug}/subscriptions/verify_promotion_code?promotion_code=${couponCode}`);

    if (result?.data) {
      onAddCoupon(couponCode);
      setCoupon(result.data);
      setAlert({
        display: true,
        isValid: true,
      });
    } else {
      setAlert({
        display: true,
        isValid: false,
      });
    }
  };

  const removeCoupon = () => {
    setAlert({
      display: false,
      isValid: false,
    });
    setCouponCode('');
    onAddCoupon('');
    setCoupon(null);
  }

  return (
    <>
      <div className='tw-mt-14 xl:tw-mt-5'>
        <p className='tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>Purchase plan with Credit Card</p>
      </div>
      <div className='tw-mt-9'>
        <CustomTextField label='Card Number' element={<CardNumberElement />} />
      </div>
      <div className='tw-mt-3 tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-2 tw-gap-8 sm:tw-w-[350px] xl:tw-w-[300px]'>
        <CustomTextField label='Expiry date' element={<CardExpiryElement />} />
        <CustomTextField label='CSV' element={<CardCvcElement />} />
      </div>
      <>
        <div className='tw-mt-5'>
          <Label text='Apply coupon code? (optional)' className='!tw-text-xs !tw-font-semibold' />
          <div className='tw-relative'>
            <TextInput
              name='coupon_code'
              size='large'
              placeholder='Coupon code'
              value={couponCode}
              onChange={handleCouponInputChange}
            />
            {couponCode && (
              <FiX
                className='tw-cursor-pointer tw-text-gray-700 tw-absolute tw-right-24 tw-top-5 tw-text-12'
                onClick={() => removeCoupon()}
              />
            )}
            <Button
              font='semibold'
              size='large'
              variant='teal'
              className='tw-absolute tw-right-1 tw-top-1'
              onClick={handleApplyClick}
            >
              Apply
            </Button>
          </div>
        </div>
        {alert.display ? (
          alert.isValid ? (
            <div className='tw-mt-2'>
              <Alert
                description='We have added a discount to your order.'
                isDismissable={false}
                severity='success'
                className='!tw-font-sans tw-text-sm tw-font-medium !tw-text-black'
              />
            </div>
          ) : (
            <div className='tw-mt-2'>
              <Alert
                description="Sorry, we don't recognise that code."
                isDismissable={false}
                severity='error'
                className='!tw-bg-red-200 !tw-font-sans tw-text-sm tw-font-medium !tw-text-black'
              />
            </div>
          )
        ) : null}
      </>

      <div className='tw-mt-16'>
        <OrderOverview product={product} coupon={coupon?.coupon} taxData={taxData} />
      </div>
    </>
  );
};

export default PurchasePlan;
