import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const container = css`
  display: flex;
  align-items: center;
  padding: 10px 21px;
  justify-content: space-between;
  position: relative;
`;

export const flex = css`
  display: flex;
  align-items: center;
`;

export const icon = css`
  flex-shrink: 0;
  margin-right: 7px;
  line-height: 1;
`;

export const name = css`
  ${fonts.semiBold}
  color: ${colors.black};
  font-size: 12px;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
  margin-right: 10px;
`;

export const path = css`
  ${fonts.regular}
  line-height: 1;
  color: ${colors.lightGrey};
  font-size: 10px;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 180px;
`;

export const size = css`
  ${path}
`;

export const cancel = css`
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const actions = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
`;

export const bar = css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  width: 100%:
  background: ${colors.mediumGrey};
`;

export const progress = (width) => css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  width: ${width}%;
  background: ${colors.cyan};
`;

export const failed = css`
  ${progress(100)}
  background: ${colors.red};
`;

export const error = css`
  line-height: 1;
  color: ${colors.red};
`;

export const tooltip = css`
  ${fonts.regular};
  font-size: 10px;
`;
