import { css } from '@emotion/core';
import { colors } from 'styles';

export const modal = css`
  .modal-dialog {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .modal-content {
    border-radius: 8px;
    height: 100%;
    padding: 0 !important;

    @media (max-width: 810px) {
      border: none;
    }
  }

  .modal-body {
    padding: 0;
    overflow: hidden;
  }
`;

export const modalButtons = css`
  display: flex;
  justify-content: space-between;
`;

export const viewerContainer = css`
  display: flex;
  height: calc(100% - 53px);

  iframe {
    border: none;
  }

  @media (max-width: 810px) {
    flex-direction: column;
  }
`;

export const viewer = css`
  height: 100%;
  flex: 1;
  position: relative;
`;

export const leftPanel = css`
  width: 300px;
`;

const roundButton = css`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  position: absolute;
`;

export const draftBanner = css`
  color: ${colors.orange};
  background-color: ${colors.lightOrange};
  font-size: 12px;
  padding: 15px;
  border: solid 1px ${colors.orange};
  border-radius: 5px;
  margin: 10px;

  @media (min-width: 810px) {
    display: none;
  }
`;
