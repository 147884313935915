import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FiX } from 'react-icons/fi';

const IconButton = forwardRef(({ children, title, className, onClick, isDisabled, ...rest }, ref) => (
  <button
    type='button'
    ref={ref}
    disabled={isDisabled}
    title={title}
    onClick={onClick}
    className={clsx(
      'tw-inline-flex tw-items-center tw-justify-center',
      'tw-w-auto tw-cursor-pointer tw-rounded-full tw-border-none tw-bg-transparent tw-p-1',
      'hover:tw-bg-gray-100',
      'disabled:tw-cursor-not-allowed disabled:tw-bg-transparent',
      'focus:tw-outline-none',
      className
    )}
    {...rest}
  >
    {children}
  </button>
));

IconButton.X = (props) => (
  <IconButton {...props}>
    <FiX className='tw-h-3 tw-w-3 tw-shrink-0' />
  </IconButton>
);

IconButton.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default IconButton;
