import React from 'react';
import { FormattedMessage } from 'react-intl';

import Sent from 'images/document-sent.svg';
import { Success, CountTitle, LastConfirmMsg, SentSuccessWrapper } from './styles';

export const SentSuccess = ({ signatories }) => {
  return (
    <SentSuccessWrapper>
      <img src={Sent} className='m-0' />

      <Success className='m-l-20'>
        <div>
          <span>
            <FormattedMessage id='SentSuccess.sentTo' />
          </span>
          <CountTitle>
            <FormattedMessage id='SentSuccess.signatoryCount' values={{ count: signatories.length }} />
          </CountTitle>
        </div>

        <LastConfirmMsg className='m-t-20'>
          <FormattedMessage id='SentSuccess.notified' />
        </LastConfirmMsg>
      </Success>
    </SentSuccessWrapper>
  );
};
