import { createStore } from './createStore';
import { getErrors } from 'helpers/getErrors';
import { getAttributes } from 'helpers/getAttributes';
import { parseDateTime } from 'helpers/parseDateTime';

const DEFAULTS = {
  title: '',
  detail: '',
  status: 'incomplete',
  agenda_id: null,
  due_date: parseDateTime(),
  contact_ids: [],
  tags: [],
};

const initialState = {
  isOpen: false,
  task: DEFAULTS,
  errors: {},
  isSubmit: false,
};

const actionTypes = {
  OPEN: 'drawer.open',
  HIDE: 'drawer.hide',
  SET_TASK: 'drawer.task.set',
  SET_ERRORS: 'drawer.errors.set',
};

const useTaskDrawerActions = () => {
  const dispatch = useTaskDrawerDispatch();

  const openDrawer = (task) => {
    dispatch({ type: actionTypes.OPEN, payload: { ...DEFAULTS, ...task } });
  };

  const hideDrawer = () => {
    dispatch({ type: actionTypes.HIDE });
  };

  const setTask = (key, value) => {
    dispatch({ type: actionTypes.SET_TASK, payload: { key, value } });
  };

  const setErrors = (payload) => {
    const messages = getErrors(payload);

    dispatch({ type: actionTypes.SET_ERRORS, payload: messages });
  };

  return {
    openDrawer,
    hideDrawer,
    setTask,
    setErrors,
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.OPEN:
      return {
        ...state,
        isOpen: true,
        isSubmit: false,
        errors: {},
        task: { ...payload },
      };

    case actionTypes.HIDE:
      return {
        ...state,
        isOpen: false,
        isSubmit: false,
        errors: {},
        task: { ...DEFAULTS },
      };

    case actionTypes.SET_TASK:
      return {
        ...state,
        task: {
          ...state.task,
          [payload.key]: payload.value,
        },
        errors: {
          ...state.errors,
          [payload.key]: null,
        },
        isSubmit: false,
      };

    case actionTypes.SET_ERRORS:
      return { ...state, errors: payload, isSubmit: true };
  }
};

const [TaskDrawerProvider, useTaskDrawerStore, useTaskDrawerDispatch] = createStore(reducer, initialState);

export { TaskDrawerProvider, useTaskDrawerStore, useTaskDrawerActions };
