/** @jsxRuntime classic */
/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './MediaHeader.styles';

const MediaHeader = memo(({ onClose, title }) => {
  return (
    <div css={styles.wrapper}>
      <i className='fa fa-chevron-left' css={styles.close} onClick={onClose} />
      <div css={styles.title}>{title}</div>
    </div>
  );
});

export { MediaHeader };
