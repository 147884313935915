import { css } from '@emotion/core';

export const shake = css`
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) 2 both;
  backface-visibility: hidden;
  transform-origin: top right;

  @keyframes shake {
    0% {
      transform: rotate(0);
    }
    15% {
      transform: rotate(6deg);
    }
    30% {
      transform: rotate(-6deg);
    }
    45% {
      transform: rotate(4deg);
    }
    60% {
      transform: rotate(-4deg);
    }
    75% {
      transform: rotate(2deg);
    }
    85% {
      transform: rotate(-2deg);
    }
    92% {
      transform: rotate(1deg);
    }
    100% {
      transform: rotate(0);
    }
  }
`;

export const slideIn = (slideFrom, slideTo) => css`
  animation: slideIn 0.2s ease-out forwards;

  @keyframes slideIn {
    0% {
      transform: translate3d(${slideFrom}px, 0, 0);
    }

    100% {
      transform: translate3d(${slideTo}px, 0, 0);
    }
  }
`;

export const slideOut = (slideFrom, slideTo) => css`
  animation: slideOut 0.2s ease-out forwards;

  @keyframes slideOut {
    0% {
      transform: translate3d(${slideFrom}px, 0, 0);
    }

    100% {
      transform: translate3d(${slideTo}px, 0, 0);
    }
  }
`;
