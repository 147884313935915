import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const container = css`
  label {
    font-size: 12px;
    color: ${colors.black};
  }

  textarea {
    height: 100px;
  }

  .zoom-wrapper {
    margin-top: 30px;

    h4 {
      ${fonts.regular}
      font-size: 14px;
      letter-spacing: 0.1em;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid ${colors.headingGrey};
      line-height: 0.1em;
      margin: 10px 0 20px;
    }

    h4 span {
      background: #fff;
      padding: 0 10px;
    }

    i {
      color: ${colors.black};
      font-size: 12px;
      margin-top: 5px;
    }
  }

  .zoom-check-box {
    margin: 0px 0px 10px 4px;
  }

  .radio {
    label {
      &::after {
        background-color: ${colors.cyan};
      }
    }

    input[type='radio']:checked + label {
      &::after {
        background-color: ${colors.cyan};
      }
    }

    input[type='radio']:checked + label {
      &::before {
        border-color: ${colors.cyan};
      }
    }
  }
`;

export const radioButton = css`
  label {
    font-size: 14px !important;
  }
`;
