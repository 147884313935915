import { gql, useQuery } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';
import { DIRECTORY_CAPABILITIES_FRAGMENT } from './../fragments/DirectoryCapabilitiesFragment';

const ROOT_DIRECTORIES_QUERY = gql`
  query rootDirectories {
    currentCompany {
      id

      rootDirectories {
        ...DirectoryFields

        capabilities {
          ...DirectoryCapabilitiesFields
        }
      }
    }
  }
  ${DIRECTORY_FRAGMENT}
  ${DIRECTORY_CAPABILITIES_FRAGMENT}
`;

const useRootDirectoriesQuery = (options) => useQuery(ROOT_DIRECTORIES_QUERY, options);

export { useRootDirectoriesQuery };
