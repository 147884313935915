import React, { memo } from 'react';

import { useFlashActions } from 'store/flashStoreContext';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import { useMeetingStore, useMeetingActions } from 'store/meetingStoreContext';
import { FormattedMessage } from 'react-intl';

import { Step } from './../MultistepForm/Step';
import { ActionBar } from './../ActionBar';
import { AttendeesPicker } from './../AttendeesPicker';

const AttendeesStep = memo(
  ({ show, steps, onSave, saveDraftLabel, onAdvance, onInviteClick, onInvite, isDisabled, isMinutesContext }) => {
    const { setFlash } = useFlashActions();
    const { edits: meeting } = useMeetingStore();
    const { setEdits } = useMeetingActions();
    const { isDraft, isUpcoming } = useMeetingStatus(meeting.status);

    const handleSaveDraftClick = () => {
      if (isDisabled) return onSave();

      onInvite().then(onSave).catch(setFlash);
    };

    const handleNextClick = () => {
      if (isDisabled) return onAdvance();

      onInvite().then(onAdvance).catch(setFlash);
    };

    const inviteButton = !isDisabled && isDraft && (
      <a className='btn btn-responsive btn-secondary btn-inverted m-l-10 m-b-10' onClick={onInviteClick}>
        <FormattedMessage id='AttendeesStep.sendNotice' />
      </a>
    );

    return (
      show && (
        <>
          <Step steps={steps}>
            <AttendeesPicker isDisabled={isDisabled} isMinutesContext={isMinutesContext} />
          </Step>

          <ActionBar>
            <div className='d-flex flex-wrap justify-content-center align-items-center'>
              <a onClick={handleSaveDraftClick} className='btn m-b-10 btn-responsive btn-inverted'>
                {saveDraftLabel}
              </a>

              {inviteButton}

              <a className='btn btn-responsive btn-primary m-l-10 m-b-10' onClick={handleNextClick}>
                <FormattedMessage id='AttendeesStep.next' />
              </a>
            </div>
          </ActionBar>
        </>
      )
    );
  }
);

export { AttendeesStep };
