import React, { memo, useRef, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { Spinner } from 'components/shared/Spinner';
import { MeetingDetails } from 'components/meetings/MeetingDetails';
import { ReviewSection } from './../ReviewSection';
import { StatusBadge } from 'components/shared/StatusBadge';
import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';
import { useFlashActions } from 'store/flashStoreContext';
import { useMeetingActions, useMeetingStore } from 'store/meetingStoreContext';
import { useOoEStore } from 'store/ooeStoreContext';
import { useMinutesStore } from 'store/minutesStoreContext';
import { useCommentsStore } from 'store/commentsStoreContext';
import { useReviewsActions } from 'store/reviewsStoreContext';

const ReviewSpace = memo(({ meetingId, minuteId, review, currentContact, contactTimezone, contactTimezoneOffset }) => {
  const { meeting, loaded } = useMeetingStore();
  const { ooe } = useOoEStore();
  const { comments } = useCommentsStore();
  const { minutes } = useMinutesStore();

  const { setFlash } = useFlashActions();
  const { loadMeeting } = useMeetingActions();

  const intl = useIntl();

  useEffect(() => {
    loadMeeting(meetingId).catch(setFlash);
  }, []);

  const { finalizeReview } = useReviewsActions();

  const reviewComplete = review.status == 'completed';

  const navigateToMinutes = () => {
    window.location.assign(`/${getCompanySlug()}/minutes/${minutes.slug}/review`);
  };

  const sendToCreator = () => {
    finalizeReview(minuteId, review.id).then(navigateToMinutes).catch(setFlash);
  };

  const hasComments = comments.find((item) => item.author.data.id === currentContact.data.id);

  const sendBackButton = (
    <button className='btn btn-primary' onClick={hasComments ? sendToCreator : null}>
      <FormattedMessage id='ReviewSpace.sendToCreator' />
    </button>
  );

  const quillRef = useRef();

  if (!loaded) {
    return <Spinner show />;
  }

  return (
    <Container fluid='lg'>
      <Row noGutters>
        <StatusBadge status={minutes.status} text={intl.formatMessage({ id: 'ReviewSpace.minutes' })} />

        <Col md={12}>
          <MeetingDetails
            contactTimezone={contactTimezone}
            contactTimezoneOffset={contactTimezoneOffset}
            context='in-review-minutes'
          />
        </Col>

        {!reviewComplete && (
          <Col md={12} className='m-t-20 m-b-20'>
            <div className='d-flex justify-content-end'>
              {hasComments ? (
                sendBackButton
              ) : (
                <ButtonWithConfirmation
                  button={sendBackButton}
                  confirmationText={intl.formatMessage({ id: 'ReviewSpace.confirmation' })}
                  onConfirm={sendToCreator}
                />
              )}
            </div>
          </Col>
        )}

        <ReviewSection
          minuteId={minuteId}
          quillRef={quillRef}
          currentContact={currentContact}
          isCommentListReadOnly={reviewComplete}
          isEditorReadOnly
        />
      </Row>
    </Container>
  );
});

export { ReviewSpace };
