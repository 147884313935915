/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from 'components/shared/Modal';
import Signature from 'images/icons/signature-large.svg';
import * as styles from './Submit.styles';

export default memo(({ show }) => {
  const redirectToRoot = () => {
    window.location.assign('/');
  };

  const intl = useIntl();

  const footer = (
    <div className='text-center'>
      <button onClick={redirectToRoot} className='btn btn-primary'>
        <FormattedMessage id='Submit.ok' />
      </button>
    </div>
  );

  return (
    <Modal
      css={styles.modal}
      centered
      show={show}
      footer={footer}
      onClose={redirectToRoot}
      title={intl.formatMessage({ id: 'Submit.sigRecorded' })}
    >
      <div css={styles.modalBody}>
        <img css={styles.signatureIcon} src={Signature} />
      </div>
    </Modal>
  );
});
