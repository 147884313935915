import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SidebarItem from '../SidebarItem';

const Sidebar = ({ items, onClick, isSelected = () => false }) => {
  const handleItemClick = (item) => () => onClick(item);
  const renderSidebarItems = items.map((item) => (
    <SidebarItem key={item.id} name={item.name} onClick={handleItemClick(item)} isSelected={isSelected(item)} />
  ));

  return (
    <div className='tw-flex tw-h-full tw-flex-col tw-gap-y-4 tw-rounded tw-bg-gray-100 tw-p-6 lg:tw-rounded-none'>
      <div className='tw-hidden tw-text-xl tw-font-bold tw-text-black lg:tw-block'>Documents</div>
      <div className='tw-flex tw-flex-col'>{renderSidebarItems}</div>
    </div>
  );
};

Sidebar.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
  isSelected: PropTypes.func,
};

export default Sidebar;
