import styled from 'styled-components';
import { fonts } from 'styles';

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  color: #2c2e3f;
`;

export const CloseIconWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #dde0ed;
  margin-left: 9px;
`;

export const UploadWrapper = styled.div`
  display: flex;
  padding: 16px;
  img {
    margin: unset;
    width: ${(props) => (props.deviceType === 'mobile' ? 20 : 29)}px;
  }
  span {
    margin-left: 12px;
  }
  label {
    display: flex;
    align-items: center;
  }
  ${fonts.styledRegular}
  font-size: ${(props) => props.fontSize}px;
  color: #2c2e3f;
`;
