import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { Sizes } from './../Modal';
import TextInput from './../TextInput';
import SelectInput from 'components/v2/shared/SelectInput';
import Button, { Variants } from './../Button';
import { useAccessRolesContext } from 'contexts/v2/AccessRolesContext';
import { useContactsContext } from 'contexts/v2/ContactsContext';

const InviteContactModalWindow = ({ show, onHide, onDataAdded }) => {
  const [email, setEmail] = useState('');
  const [accessRole, setAccessRole] = useState();
  const [errors, setErrors] = useState({});

  const { addContact } = useContactsContext();
  const { accessRoles, defaultRole } = useAccessRolesContext();

  useEffect(() => setAccessRole(defaultRole), [defaultRole]);

  const handleEmailChange = (e) => setEmail(e.target.value);

  const resetForm = () => {
    setErrors({});
    setEmail('');
    setAccessRole(defaultRole);
  };

  const handleSave = async () => {
    setErrors({});

    try {
      const option = await addContact({ email, accessRoleId: accessRole?.value });
      onDataAdded && onDataAdded(option);
      onHide();
    } catch (e) {
      setErrors(e);
    }
  };

  return (
    <Modal isOpen={show} onClose={onHide} size={Sizes.lg} afterLeave={resetForm}>
      <div className='tw-flex tw-flex-col tw-py-8 tw-px-12'>
        <div className='tw-mb-8 tw-text-xl tw-font-bold tw-text-black'>Add new contact</div>

        <div className='tw-flex tw-flex-col'>
          <div className='tw-mb-5'>
            <TextInput
              value={email}
              onChange={handleEmailChange}
              errorMessage={errors.email}
              label='Email'
              placeholder='Email'
            />
          </div>

          <SelectInput
            value={accessRole}
            onChange={setAccessRole}
            errorMessage={errors.accessRoleId}
            isFilterable
            label='Access role'
            options={accessRoles}
          />
        </div>

        <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-y-1'>
          <Button onClick={handleSave} isFullWidth>
            Save
          </Button>

          <Button variant={Variants.Teal} isFullWidth onClick={onHide}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

InviteContactModalWindow.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onDataAdded: PropTypes.func,
};

export default InviteContactModalWindow;
