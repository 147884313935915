import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const padded = css`
  padding: 35px 40px 0;
  ${media.mobile} {
    padding: 15px 15px 0;
  }
`;

export const outline = css`
  outline: 1px solid ${colors.lighterGrey};
  background: ${colors.white};
`;
