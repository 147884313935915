/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';

import { useCommentsActions } from 'store/commentsStoreContext';
import { useFlashActions } from 'store/flashStoreContext';

import { ReactComponent as CheckmarkIcon } from 'images/icons/checkmark.svg';

import * as styles from './CommentActions.styles';

const CommentActions = memo(({ minuteId, minuteOwnerId, currentContactId, comment, onEdit }) => {
  const handleEdit = onEdit && onEdit.bind(this, comment);

  const intl = useIntl();

  const { deleteComment, updateComment } = useCommentsActions();
  const { setFlash } = useFlashActions();

  const handleDelete = () => {
    deleteComment(minuteId, comment.id).catch(setFlash);
  };

  const handleResolve = () => {
    updateComment(minuteId, comment.id, {
      resolved: !comment.resolved,
    }).catch(setFlash);
  };

  const isOwner = currentContactId === comment.author.data.id;
  const canResolve = !isOwner && Number(currentContactId) === minuteOwnerId;

  const checkmarkStyles = styles.checkmark(comment.resolved);
  const resolveIcon = canResolve && (
    <div onClick={handleResolve} css={checkmarkStyles}>
      {comment.resolved && <CheckmarkIcon />}
    </div>
  );

  const editIcon = isOwner && <i className='far fa-fw fa-pencil-alt m-l-5' css={styles.icon} onClick={handleEdit} />;

  const deleteIcon = isOwner && (
    <ButtonWithConfirmation
      button={<i className='far fa-fw fa-trash-alt m-l-5' css={styles.icon} />}
      confirmationText={intl.formatMessage({ id: 'CommentActions.confirmation' })}
      onConfirm={handleDelete}
    />
  );

  return (
    <div className='d-flex align-self-baseline align-items-center flex-grow-1 justify-content-end'>
      {resolveIcon}
      {editIcon}
      {deleteIcon}
    </div>
  );
});

export { CommentActions };
