import { css } from '@emotion/core';
import { colors } from 'styles';

export const icon = {
  base: css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.1s ease-in-out;
    line-height: 1;
    display: flex;
    align-items: center;
    margin: 0 14px;
    color: ${colors.headingGrey};
  `,

  alignment: {
    left: css`
      right: auto;
      left: 0;
    `,

    right: css`
      right: 0;
      left: auto;
    `,
  },

  state: {
    rotated: css`
      transform: translateY(-50%) rotate(180deg);
    `,

    focused: css`
      color: ${colors.cyan};

      path {
        fill: ${colors.cyan};
      }
    `,

    invalid: css`
      color: ${colors.red};

      path {
        fill: ${colors.red};
      }
    `,
  },
};
