import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentCard, Item, ItemTitle, ItemContent, Delete, EmptyWrapper, ContacsWrapper } from './styles';
import { ReactComponent as TrashIcon } from 'images/icons/trash.svg';
import { ReactComponent as Contacts } from 'images/icons/no-contacts.svg';

export const SignatoriesList = ({ signatories, onDelete }) => {
  const intl = useIntl();

  if (signatories.length === 0) {
    return (
      <EmptyWrapper>
        <ContacsWrapper className='d-flex align-items-center'>
          <Contacts className='d-flex m-0' />
        </ContacsWrapper>

        <div className='m-t-20 d-flex justify-content-center'>
          <FormattedMessage id='SignatoriesList.noSignatories' />
        </div>
      </EmptyWrapper>
    );
  }

  return signatories.map((signatory, index) => (
    <ContentCard hasTopBorder={index === 0 ? true : false} key={index}>
      <Item className='d-flex'>
        <ItemTitle className='d-flex align-items-center'>
          <FormattedMessage id='SignatoriesList.name' />
        </ItemTitle>
        <ItemContent className='d-flex align-items-center'>{signatory.name}</ItemContent>
      </Item>
      <Item className='d-flex'>
        <ItemTitle className='d-flex align-items-center'>
          <FormattedMessage id='SignatoriesList.email' />
        </ItemTitle>
        <ItemContent className='d-flex align-items-center text-truncate'>
          <div className='w-100 text-truncate'>{signatory.email}</div>
        </ItemContent>
      </Item>
      <Item className='d-flex'>
        <ItemTitle className='d-flex align-items-center'>
          <FormattedMessage id='SignatoriesList.contact' />
        </ItemTitle>
        <ItemContent className='d-flex align-items-center'>
          {signatory.withContact
            ? intl.formatMessage({ id: 'SignatoriesList.yes' })
            : intl.formatMessage({ id: 'SignatoriesList.no' })}
        </ItemContent>
      </Item>
      {onDelete && (
        <Delete onClick={onDelete.bind(this, signatory)}>
          <TrashIcon />
        </Delete>
      )}
    </ContentCard>
  ));
};
