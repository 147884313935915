import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const container = css`
  display: flex;
  align-items: center;
  position: relative;
  padding: 6px 12px 6px 6px;
  min-width: 0;
`;

export const shareable = css`
  margin-left: 5px;
  width: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17px;
  background: ${colors.lightCyan};

  .shareable__icon {
    flex-shrink: 0;

    path {
      fill: ${colors.cyan};
    }
  }
`;

export const overlay = css`
  background: ${colors.white};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
`;

export const name = css`
  ${fonts.regular}
  color: ${colors.black};
  font-size: 14px;
  padding-left: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const icon = css`
  font-size: 14px;
  color: ${colors.lightGrey};

  &:hover {
    cursor: pointer;
    color: ${colors.cyan};
  }
`;
