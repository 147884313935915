import { gql, useMutation } from '@apollo/client';
import { ACCESS_ROLE_FRAGMENT } from '../fragments/AccessRoleFragment'

const UPDATE_ACCESS_ROLE_MUTATION = gql`
  mutation UpdateAccessRole($input: UpdateAccessRoleInput!) {
    updateAccessRole(input: $input) {
      accessRole {
        ...AccessRoleFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${ACCESS_ROLE_FRAGMENT}
`;

const useUpdateAccessRoleMutation = (options) => useMutation(UPDATE_ACCESS_ROLE_MUTATION, options);

export { UPDATE_ACCESS_ROLE_MUTATION, useUpdateAccessRoleMutation };
