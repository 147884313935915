/** @jsxRuntime classic */
/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import Group from 'components/contacts/picker/Group';

import * as styles from './ChatHeader.styles';

const ChatHeader = memo(({ title, participants, onClose }) => {
  const chatParticipants = participants && <Group contacts={participants} maxContacts={3} allowAdd={false} />;

  return (
    <div css={styles.wrapper}>
      <div css={styles.title}>{title}</div>

      <div css={styles.flex}>
        {chatParticipants}

        <i className='fa fa-fw fa-times' onClick={onClose} css={styles.close} />
      </div>
    </div>
  );
});

export { ChatHeader };
