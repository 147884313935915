/** @jsx jsx */

import React, { memo, useRef, useState, useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { jsx } from '@emotion/core';

import { useKeyPress } from 'hooks/useKeyPress';

import * as styles from './EditableTextArea.styles';

const EditableTextArea = memo(({ value, placeholder, onBlur, isDisabled }) => {
  const [editableText, setEditableText] = useState(value);
  const [isEditable, setIsEditable] = useState(false);

  const Escape = useKeyPress('Escape');

  const editableRef = useRef();

  useEffect(() => setEditableText(value), [value]);

  useEffect(() => setEditableCursor(), [isEditable]);

  useEffect(() => handleKeyPress(), [Escape]);

  const enableEditable = () => {
    setIsEditable(true);
  };

  const disableEditable = () => {
    setIsEditable(false);
  };

  const changeText = (event) => {
    setEditableText(event.target.value || '');
  };

  const setEditableCursor = () => {
    if (!isEditable) return;

    editableRef.current.focus();

    editableRef.current.selectionStart = editableText.length;
    editableRef.current.selectionEnd = editableText.length;
  };

  const handleKeyPress = () => {
    if (isEditable && Escape) return discardChanges();
  };

  const updateText = () => {
    if (!editableText.length) {
      discardChanges();
    } else if (editableText === value) {
      disableEditable();
    } else {
      onBlur(editableText);
      disableEditable();
    }
  };

  const discardChanges = () => {
    setEditableText(value);
    disableEditable();
  };

  const editableInput = isEditable && (
    <div className='d-flex align-items-start'>
      <TextareaAutosize
        css={styles.textArea}
        ref={editableRef}
        value={editableText}
        onBlur={updateText}
        onChange={changeText}
      />

      <i className='fa fa-pencil' css={styles.editableIcon} />
    </div>
  );

  const staticTextStyles = [styles.staticText.base, !editableText.length && styles.staticText.placeholder];

  const placeholderText = editableText.length > 0 ? editableText : placeholder;

  const staticText = !isEditable && (
    <div css={staticTextStyles}>
      <span>{placeholderText}</span>
      <i className='fa fa-pencil' css={styles.icon} />
    </div>
  );

  if (isDisabled) {
    return (
      value && (
        <div css={staticTextStyles}>
          <span>{value}</span>
        </div>
      )
    );
  }

  return (
    <span onClick={enableEditable}>
      {staticText}
      {editableInput}
    </span>
  );
});

export { EditableTextArea };
