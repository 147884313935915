/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import Button, { Variants } from './../Button';
import TextInput from './../TextInput';
import TextAreaInput from './../TextAreaInput';
import Label from './../Label';
import SelectInput from 'components/v2/shared/SelectInput';
import RadioButton from './../RadioButton';
import ResourceManager from './../ResourceManager';

import { VOTE_DEFAULTS, SIMPLE_CONFIG, EXTENDED_CONFIG } from './constants';
import { getFieldData } from 'utils/v2/data';
import { useContactsContext } from 'contexts/v2/ContactsContext';
import { useVotesContext } from 'contexts/v2/VotesContext';

import * as styles from './VoteManager.styles';

const VoteManager = ({ className }) => {
  const [attributes, setAttributes] = useState({ ...VOTE_DEFAULTS });
  const [errors, setErrors] = useState({});

  const handleFieldChange = (fieldName) => (e) => {
    const value = getFieldData(e);
    setAttributes((state) => ({ ...state, [fieldName]: value }));
  };

  const { contacts } = useContactsContext();
  const { addVote } = useVotesContext();

  const handleAddVote = (resetResourceManager) => async () => {
    setErrors({});

    try {
      await addVote(attributes);
      resetResourceManager();
      setAttributes({ ...VOTE_DEFAULTS });
    } catch (e) {
      setErrors(e);
    }
  };

  const inputMap = {
    description: {
      label: 'Description',
      component: (
        <TextAreaInput
          errorMessage={errors.description}
          value={attributes.description}
          onChange={handleFieldChange('description')}
          placeholder='Enter your description...'
          label='Description'
        />
      ),
    },

    attachment: {
      label: 'Attachment',
      component: <p>TODO: Attachment component</p>,
    },

    assignees: {
      label: 'Assign',
      component: (
        <SelectInput
          optionVariant='contact'
          onChange={handleFieldChange('contactId')}
          options={contacts}
          label='Assign'
          variantValueBackground='white'
        />
      ),
    },
  };

  return (
    <ResourceManager
      className={className}
      inputMap={inputMap}
      renderHeader={
        <>
          <Col lg={12} className='mb-4'>
            <TextInput
              label='Add new vote'
              value={attributes.name}
              onChange={handleFieldChange('name')}
              errorMessage={errors.name}
              placeholder='Write what you are voting on'
            />
          </Col>

          <Col lg={12} className='mb-4'>
            <Label text='Resolution type' />

            <Row noGutters>
              <Col lg={6}>
                <RadioButton
                  onChange={handleFieldChange('config')}
                  value={SIMPLE_CONFIG}
                  isChecked={attributes.config === SIMPLE_CONFIG}
                  name='type'
                  label='Yes / No'
                />
              </Col>

              <Col lg={6}>
                <RadioButton
                  onChange={handleFieldChange('config')}
                  value={EXTENDED_CONFIG}
                  isChecked={attributes.config === EXTENDED_CONFIG}
                  name='type'
                  label='For / Against / Abstain'
                />
              </Col>
            </Row>
          </Col>
        </>
      }
      renderButton={({ resetResourceManager }) => (
        <Button variant={Variants.Teal} isFullWidth onClick={handleAddVote(resetResourceManager)}>
          Add vote to meeting
        </Button>
      )}
    />
  );
};

VoteManager.propTypes = {
  className: PropTypes.string,
};

export default VoteManager;
