/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import Box from 'components/v2/shared/Box';
import Text from 'components/v2/shared/Text';
import Icon from 'components/v2/shared/Icon';
import AgendaItemActionsMenu from './../AgendaItemActionsMenu';

import * as styles from './CollapsedAgendaItem.styles';

const CollapsedAgendaItem = ({ title, className }) => (
  <Box variant='meetingBoxItem' className={className}>
    <div className='d-flex align-items-center'>
      <Icon icon='move' className='mr-2' />
      <Text isSemiBold text={title} />
    </div>

    <AgendaItemActionsMenu className='ml-2' />
  </Box>
);

CollapsedAgendaItem.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default CollapsedAgendaItem;
