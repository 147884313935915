import { useState, useEffect } from 'react';

const useVisibility = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);

  const showComponent = () => {
    setIsComponentVisible(true);
  };

  const hideComponent = () => {
    setIsComponentVisible(false);
  };

  return {
    isComponentVisible,
    showComponent,
    hideComponent,
  };
};

export { useVisibility };
