const useMeetingStatus = (status) => {
  const isPseudo = status === 'pseudo';
  const isDraft = status === 'draft';
  const isScheduled = status === 'scheduled';
  const isUpcoming = status === 'upcoming';
  const isInSession = status === 'in_session';
  const isCompleted = status === 'completed';
  const isArchived = status === 'archived';

  const isEditable = isDraft || isScheduled || isUpcoming || isPseudo;

  return {
    isPseudo,
    isDraft,
    isUpcoming,
    isScheduled,
    isInSession,
    isCompleted,
    isArchived,
    isEditable,
  };
};

export { useMeetingStatus };
