import { css } from '@emotion/core';
import { colors } from 'styles';

export const form = css`
  outline: 1px solid ${colors.lighterGrey};
  z-index: 2;
`;

export const header = css`
  padding-bottom: 10px;

  &:empty {
    padding: 0;
  }
`;

export const replyWrapper = css`
  padding: 19px 7px 20px 20px;
  outline: 1px solid ${colors.lighterGrey};
`;

export const textAreaWrapper = css`
  border: none;
  padding: 0;
`;

export const textAreaInput = {
  base: css`
    font-size: 12px;
    padding-left: 0;
    line-height: 16px;
    height: 75px;
  `,

  fullHeight: css`
    height: 342px;
  `,
};
