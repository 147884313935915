import { css } from '@emotion/core';

export const modal = css`
  .modal-dialog.shaparency {
    width: 407px;

    .modal-body,
    .modal-header {
      padding: 0 36px;
    }

    .modal-close {
      position: absolute;
      top: 31px;
      right: 31px;
    }
  }
`;
