import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';

export const container = css`
  padding: 22px 38px;
  ${media.tablet} {
    padding: 40px 15px 20px;
  }
`;

export const header = css`
  ${fonts.bold}
  font-size: 20px;
  line-height: 16px;
  color: ${colors.black};
  margin-bottom: 15px;
`;

export const filename = css`
  ${fonts.regular}
  font-size: 12px;
  color: ${colors.mediumGrey};
  line-height: 1;
  margin-bottom: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const timeline = css`
  overflow: auto;
  max-height: 85vh;
`;
