import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { RawIntlProvider, createIntl, createIntlCache } from 'react-intl';

import en from '../../lang/en.json';
import zhSC from '../../lang/zh-SC.json';
import zhTC from '../../lang/zh-TC.json';

const localeData = { en, 'zh-SC': zhSC, 'zh-TC': zhTC };

const currentLocale = typeof getCurrentLocale == 'function' ? getCurrentLocale() : 'en';

const TranslationContext = createContext();

const cache = createIntlCache();

export const intl = createIntl(
  {
    defaultLocale: 'en',
    locale: currentLocale,
    messages: localeData[currentLocale],
  },
  cache
);

export const TranslationContextProvider = ({ children }) => {
  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};

TranslationContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useTranslationContext = () => useContext(TranslationContext);
