import { useState, useCallback, useEffect } from 'react';

import { useRootDirectoriesQuery } from './../data/queries/RootDirectoriesQuery';
import { useDirectoryQuery } from './../data/queries/DirectoryQuery';
import { useRecentDirectoriesQuery } from './../data/queries/RecentDirectoriesQuery';
import { useSharedDirectoriesQuery } from './../data/queries/SharedDirectoriesQuery';
import { useFavouriteDirectoriesQuery } from './../data/queries/FavouriteDirectoriesQuery';
import { SHARED, RECENTS, FAVOURITES, VIRTUAL_SIDEBAR_ITEMS } from './../data/globals/VirtualSidebarItems';

const useDirectoriesBrowser = ({ onBrowse, currentDirectoryId }) => {
  const [browserData, setBrowserData] = useState({
    sidebarItemId: null,
    requestedDirectoryId: currentDirectoryId,
    currentDirectory: null,
    rootDirectories: null,
    isLoading: false,
  });

  const { requestedDirectoryId, currentDirectory } = browserData;
  const requestedFulfilled = requestedDirectoryId === currentDirectory?.id;

  const isCurrentDirectory = ({ id }) => browserData.requestedDirectoryId === id;
  const updateBrowserData = (updates) => setBrowserData((state) => ({ ...state, ...updates }));

  const virtualDirectoryData = (data, virtualDirectory) => ({
    ...data.currentContact,
    ...virtualDirectory,
    virtual: true,
    breadcrumbs: [virtualDirectory],
  });

  const { data: rootsData } = useRootDirectoriesQuery();

  const { loading: directoryLoading, data: directoryData } = useDirectoryQuery({
    skip: !browserData.requestedDirectoryId || !!VIRTUAL_SIDEBAR_ITEMS.find(isCurrentDirectory),
    variables: { id: browserData.requestedDirectoryId },
  });

  const { loading: recentsLoading, data: recentsData } = useRecentDirectoriesQuery({
    skip: !isCurrentDirectory(RECENTS),
  });

  const { loading: sharedLoading, data: sharedData } = useSharedDirectoriesQuery({
    skip: !isCurrentDirectory(SHARED),
  });

  const { loading: favouritesLoading, data: favouritesData } = useFavouriteDirectoriesQuery({
    skip: !isCurrentDirectory(FAVOURITES),
  });

  const dataLoading = directoryLoading || recentsLoading || sharedLoading || favouritesLoading;

  useEffect(() => setIsLoading(), [dataLoading]);
  useEffect(() => setRootDirectoriesData(), [rootsData]);
  useEffect(() => setDirectoryData(), [directoryData]);
  useEffect(() => setFavouritesData(), [favouritesData]);
  useEffect(() => setRecentsData(), [recentsData]);
  useEffect(() => setSharedData(), [sharedData]);
  useEffect(() => finalize(), [requestedFulfilled]);

  const finalize = () => {
    if (!requestedFulfilled) return;

    onBrowse && onBrowse();
  };

  const setIsLoading = () => {
    if (!dataLoading) return;

    updateBrowserData({ isLoading: true });
  };

  const setRootDirectoriesData = () => {
    if (!rootsData) return;

    const { rootDirectories } = rootsData.currentCompany;

    updateBrowserData({ rootDirectories });
  };

  const setDirectoryData = () => {
    if (!directoryData) return;

    const currentDirectory = directoryData.currentCompany.directory;
    const sidebarItemId = currentDirectory.breadcrumbs[0].id;

    updateBrowserData({ sidebarItemId, currentDirectory, isLoading: false });
  };

  const setSharedData = () => {
    if (!sharedData) return;

    const currentDirectory = virtualDirectoryData(sharedData, SHARED);

    updateBrowserData({ sidebarItemId: SHARED.id, currentDirectory, isLoading: false });
  };

  const setFavouritesData = () => {
    if (!favouritesData) return;

    const currentDirectory = virtualDirectoryData(favouritesData, FAVOURITES);

    updateBrowserData({ sidebarItemId: FAVOURITES.id, currentDirectory, isLoading: false });
  };

  const setRecentsData = () => {
    if (!recentsData) return;

    const currentDirectory = virtualDirectoryData(recentsData, RECENTS);

    updateBrowserData({ sidebarItemId: RECENTS.id, currentDirectory, isLoading: false });
  };

  const browseSidebarItem = useCallback((id) => {
    updateBrowserData({ sidebarItemId: id, requestedDirectoryId: id });
  }, []);

  const resetBrowser = () => {
    updateBrowserData({ currentDirectory: null, requestedDirectoryId: null });
  };

  const browseDirectory = useCallback(
    (id) => {
      const isCurrent = isCurrentDirectory({ id });

      if (isCurrent) return onBrowse && onBrowse();

      updateBrowserData({ requestedDirectoryId: id });
    },
    [browserData.requestedDirectoryId]
  );

  return { resetBrowser, browseDirectory, browseSidebarItem, ...browserData };
};

export { useDirectoriesBrowser };
