import React, { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const FilterVariants = {
  Role: 'Role',
  Contact: 'Contact',
};

const Filter = ({ items, onSelect, defaultValue, placeholder, iconLeft, variant, iconRight, name }) => {
  const [selected, setSelected] = useState(defaultValue);

  const handleChange = (e) => {
    setSelected(e);
    onSelect?.(e);
  };

  return (
    <div className='tw-relative tw-h-full tw-w-full'>
      <Listbox value={selected} onChange={handleChange} name={name}>
        <Listbox.Button
          className={`tw-flex tw-h-full tw-w-full tw-cursor-default tw-items-center tw-justify-between tw-rounded-sm tw-border tw-border-solid tw-border-gray-700 tw-bg-white tw-px-4.5 ${
            variant === 'Role' && '!tw-rounded-md !tw-border-none !tw-bg-gray-100'
          }`}
        >
          {iconLeft}
          <span
            className={clsx('tw-block tw-truncate tw-text-sm tw-font-medium tw-capitalize', {
              'tw-text-gray-500': !selected && variant !== 'Role',
            })}
          >
            {selected?.name ?? placeholder}
          </span>
          <div>{iconRight}</div>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave='tw-transition tw-ease-in tw-duration-100'
          leaveFrom='tw-opacity-100'
          leaveTo='tw-opacity-0'
        >
          <Listbox.Options className='tw-relative tw-z-max tw-mt-1 tw-max-h-[160px] tw-overflow-y-auto tw-rounded tw-bg-white tw-pl-0 tw-text-sm tw-shadow-xl'>
            {items.map((item) => (
              <Listbox.Option
                as='div'
                key={item.id}
                className={({ active }) =>
                  clsx('tw-select-none tw-rounded tw-p-2 tw-text-black', {
                    'tw-bg-teal-500 tw-text-white': active,
                  })
                }
                value={item}
              >
                {({ selected }) => (
                  <>
                    <span className={clsx('tw-block tw-truncate tw-font-normal', { '!tw-font-extrabold': selected })}>
                      {item.name}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

Filter.propTypes = {
  items: PropTypes.array,
  onSelect: PropTypes.func,
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
};

export default Filter;
