import { css } from '@emotion/core';
import { colors, fonts, panels, media } from 'styles';

export const panel = css`
  ${panels.base}
  padding: 24px 34px 29px 34px;
  ${media.mobile} {
    padding: 24px 15px 15px;
    box-shadow: none;
  }
`;

export const minutePanel = css`
  ${panels.base}
  box-shadow: none;
  padding: 24px 34px 29px 34px;
  ${media.mobile} {
    padding: 24px 15px 15px;
  }
`;

export const heading = css`
  ${fonts.bold}
  font-size: 26px;
  color: ${colors.black};
`;

export const block = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

export const bold = css`
  ${fonts.semiBold}
  font-size: 14px;
  padding-left: 32px;
  line-height: 1;
  margin-top: -5px;
  color: ${colors.black};
  width: 100%;
`;

export const header = css`
  ${fonts.regular}
  align-self: flex-start;
  line-height: 1;
  font-size: 12px;
  padding-left: 8px;
  color: ${colors.mediumGrey};
`;

export const truncateParent = css`
  min-width: 0;
`;

export const mobileFullWidth = css`
  ${media.mobile} {
    width: 100%;
  }
`;
