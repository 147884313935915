import { useState } from 'react';

const useEditable = () => {
  const [isEditing, setIsEditing] = useState(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return { isEditing, startEditing, stopEditing };
};

export { useEditable };
