/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { LoadingIndicator } from 'components/shared/LoadingIndicator';
import { EditableInput } from 'components/shared/EditableInput';
import { ReactComponent as DirectoryIcon } from 'images/icons/documents/directory-icon.svg';

import * as styles from './../Directory/Directory.styles';

const MAX_WIDTH = 170;

const CreateDirectoryInput = memo(({ show, error, isLoading, onClick, onChange, onCancel, onUpdate }) => {
  const intl = useIntl();

  return (
    show && (
      <div css={styles.container}>
        <div css={styles.wrapper}>
          <DirectoryIcon className='directory__icon' />

          <div className='directory__name' style={{ maxWidth: MAX_WIDTH }}>
            <EditableInput
              isEditable={show}
              error={error}
              onClick={onClick}
              onCancel={onCancel}
              placeholderText={intl.formatMessage({ id: 'CreateDirectoryInput.newFolder' })}
              onUpdate={onUpdate}
              onChange={onChange}
              minWidth={MAX_WIDTH}
              maxWidth={MAX_WIDTH}
            />
          </div>

          <div className='m-l-10'>
            <LoadingIndicator show={isLoading} />
          </div>
        </div>
      </div>
    )
  );
});

export { CreateDirectoryInput };
