/** @jsx jsx */

import React, { memo, useMemo, forwardRef, useEffect } from 'react';
import { jsx } from '@emotion/core';
import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import ImageResize from 'quill-image-resize-module';
import MagicUrl from 'quill-magic-url';
import PlainClipboard from './PlainClipboard';
import { useFlashActions } from 'store/flashStoreContext';
import axios from 'axios';

import * as styles from './Editor.styles';

Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/magicUrl', MagicUrl);
Quill.register('modules/clipboard', PlainClipboard);

const Editor = memo(
  forwardRef(({ value, isReadOnly, onChange, onSelectionChange }, ref) => {
    const { setFlash } = useFlashActions();

    const handleUpload = async (file) => {
      const formData = new FormData();
      formData.append('image[attachment]', file);

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const URL = `/api/${getCompanySlug()}/v1/images.json`;

      const response = await axios
        .post(URL, formData, config)
        .then((response) => response.data)
        .catch((response) => setFlash(response.response.data.message));

      return response.data.attributes.attachment_url;
    };

    const modules = useMemo(() => {
      const base = {
        history: {
          userOnly: false,
          maxStack: 50,
          delay: 1000,
        },

        magicUrl: true,

        imageUploader: {
          upload: handleUpload,
        },

        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],

          [{ list: 'ordered' }, { list: 'bullet' }],

          [{ indent: '-1' }, { indent: '+1' }],

          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          ['link', 'image'],

          [{ align: [] }],
        ],
      };

      if (!isReadOnly) {
        base['imageResize'] = { displaySize: true };
      }

      return base;
    }, [isReadOnly]);

    const quillStyles = [styles.quill.base, isReadOnly && styles.quill.readOnly];

    useEffect(() => {
      if (ref && ref.current) {
        // Change default link placeholder
        // https://github.com/quilljs/quill/issues/1107
        const tooltip = ref.current.editor.theme.tooltip;
        const input = tooltip.root.querySelector('input[data-link]');
        input.dataset.link = 'https://shaparency.com';

        const toolbar = ref.current.getEditor().getModule('toolbar');

        toolbar.container.addEventListener('mousedown', (event) => {
          event.preventDefault();
        });
      }
    }, [ref]);

    return (
      <div className='position-relative'>
        <ReactQuill
          ref={ref}
          theme='snow'
          css={quillStyles}
          value={value}
          readOnly={isReadOnly}
          onChange={onChange}
          onChangeSelection={onSelectionChange}
          preserveWhitespace
          modules={modules}
        />
      </div>
    );
  })
);

export { Editor };
