import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import IconButton from 'components/v2/shared/IconButton';
import { FiX, FiFile } from 'react-icons/fi';

const SelectedItem = ({ name, onClick }) => (
  <div className='tw-flex tw-items-center tw-gap-x-4'>
    <IconButton className='tw-shrink-0' onClick={onClick}>
      <FiX className='tw-h-4 tw-w-4' />
    </IconButton>

    <div className='tw-flex tw-min-w-0 tw-items-center tw-gap-x-4'>
      <FiFile className='tw-h-4 tw-w-4 tw-shrink-0' />
      <div className='tw-truncate tw-text-sm tw-font-medium tw-text-black'>{name}</div>
    </div>
  </div>
);

SelectedItem.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default SelectedItem;
