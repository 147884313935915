import { css } from '@emotion/core';
import { colors } from 'styles';

export const circle = {
  base: css`
    display: inline-flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  `,

  disabled: css`
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  `,

  small: css`
    height: 20px;
    width: 20px;
  `,

  regular: css`
    height: 38px;
    width: 38px;
  `,

  grey: css`
    background-color: ${colors.iconGrey};
  `,

  white: css`
    background-color: ${colors.white};
  `,
};
