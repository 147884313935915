import React, { useState } from 'react';
import StateBadge from 'components/v2/shared/StateBadge';
import Heading from 'components/v2/shared/Heading';
import Switch from 'components/v2/shared/Switch';
import clsx from 'clsx';
import { isEqual } from 'lodash';

const MemberContact = ({ openIndividual, onActiveSwitch, isArchive, currentIndex }) => {
  const [selectedItem, setSelectedItem] = useState(null)

  const members = [
    {
      firstName: 'Aaron',
      lastName: 'Smith',
      role: 'Non-Executive Director',
      status: 'Owner',
    },
    {
      firstName: 'Benjamin',
      lastName: 'K',
      role: 'Non-Executive Director',
      status: 'Pending',
    },
    {
      firstName: 'Daniela',
      lastName: 'B',
      role: 'Non-Executive Director',
      status: 'Active',
    },
    {
      firstName: 'Gabriel',
      lastName: 'D',
      role: 'Non-Executive Director',
      status: 'Archived',
    },
    {
      firstName: 'Ivan',
      lastName: 'M',
      role: 'Non-Executive Director',
      status: 'Active',
    },
    {
      firstName: 'Peter',
      lastName: 'M',
      role: 'Non-Executive Director',
      status: 'Confirmed',
    },
    {
      firstName: 'Noah',
      lastName: 'H',
      role: 'Non-Executive Director',
      status: 'Pending',
    },
  ];

  const onSelectItem = (e, i) => {
    setSelectedItem(e)
    openIndividual(true, e, i)
  }

  const MemberPerStatus = ({ title }) => {
    const baseOnStatus = members.filter((item) => {
      if (item.status === 'Owner' && title === 'Active') {
        return item
      } else if (item.status === title) {
        return item
      }
    })

    return (
      <>
        <Heading text={title} className='!tw-mb-0 mt-4' />
        {baseOnStatus.map((e, i) => {
          console.log(selectedItem === e)
          return (
            <div className='tw-flex tw-justify-between'>
              <div
                className={clsx(
                  'tw-direction-col tw-mt-2.5 tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-rounded tw-px-3.5 tw-py-4',
                  {
                    'tw-bg-purple-100': isEqual(selectedItem, e),
                    'tw-bg-gray-100 ': selectedItem !== e,
                  }
                )}
                onClick={() => onSelectItem(e, i)}
              >
                <div className=''>
                  <div className='tw-flex'>
                    <div className='tw-flex tw-justify-center mr-3'>
                      <div className='tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-[#AFB0D0] tw-object-contain tw-font-sans tw-text-sm tw-font-semibold tw-text-white'>
                        SS
                      </div>
                    </div>
                    <div className=''>
                      <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>
                        {e.firstName + ' ' + e.lastName}
                      </div>
                      <div className='tw-font-sans tw-text-xs tw-font-normal tw-font-normal tw-text-gray-800'>{e.role}</div>
                    </div>
                  </div>
                  <div className='tw-font-sans tw-text-xs tw-font-medium tw-text-teal-500 tw-mt-4'>
                    {e.status === 'Archived' && <div>Re-Invite</div>}
                  </div>
                </div>


                <div className=''>
                  <StateBadge
                    variant={
                      e.status === 'Archived'
                        ? 'faint'
                        : e.status === 'Owner'
                          ? 'purple'
                          : e.status === 'Active'
                            ? 'green'
                            : e.status === 'Confirmed'
                              ? 'orange'
                              : e.status === 'Pending'
                                ? 'purple'
                                : ''
                    }
                    className='!tw-text-xs tw-mb-4'
                  >
                    {e.status === 'Archived'
                      ? 'Archived'
                      : e.status === 'Owner'
                        ? 'Owner'
                        : e.status === 'Active'
                          ? 'Active'
                          : e.status === 'Confirmed'
                            ? 'Confirmed'
                            : e.status === 'Pending'
                              ? 'Pending'
                              : ''}
                  </StateBadge>
                  {e.status !== 'Owner' && <div className='tw-hidden tw-pl-3.5 sm:tw-flex'>
                    <Switch
                      labelAfter={isArchive ? 'Archive' : 'Active'}
                      onChange={onActiveSwitch}
                      isChecked={isArchive}
                      variant={isArchive ? 'gray-bg' : 'teal-bg'}
                    />
                  </div>}
                </div>
              </div>

            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <MemberPerStatus title='Active' />
      <MemberPerStatus title='Pending' />
      <MemberPerStatus title='Archived' />
    </>
  );
}

export default MemberContact;
