/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import * as styles from './index.styles';

export default ({ title, link, isActive, navItemWidth, action, iconClassName, insideMore = false, children }) => {
  const Content = () => {
    return (
      <>
        <div css={styles.navIconWrapper({ insideMore, isActive })}>
          {children ? children : <i className={iconClassName}></i>}
        </div>
        <span css={styles.navTitle({ insideMore, isActive })}>{title}</span>
      </>
    );
  };

  return (
    <>
      {action ? (
        <div onClick={action} css={styles.navItem(navItemWidth, insideMore)}>
          <Content />
        </div>
      ) : (
        <a href={link} css={styles.navItem(navItemWidth, insideMore)}>
          <Content />
        </a>
      )}
    </>
  );
};
