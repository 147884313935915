import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withContext } from 'utils/v2/shared';
import { AttachmentState, FileUploadContextProvider, useFileUploadContext } from 'contexts/v2/FileUploadContext';
import Dropzone from 'components/v2/shared/Dropzone';
import AttachmentManagement from './AttachmentManagement';
import Label from 'components/v2/shared/Label';

export const Variants = {
  Circle: 'circle',
  Company: 'company',
  Default: 'default',
};

const Preview = ({ previewUrl, attachment, text, variant }) => {
  if (attachment?.preview) {
    return <img src={attachment.preview} alt={attachment.name} className='tw-max-h-full tw-max-w-full' />;
  } else if (previewUrl?.length > 0) {
    return <img src={previewUrl} className='tw-max-h-full tw-max-w-full' />;
  }

  return (
    <>
      {variant === Variants.Circle ? (
        <div className='tw-text-4xl tw-font-extrabold tw-text-black'>{text}</div>
      ) : (
        <div
          className={clsx('tw-text-center tw-font-semibold tw-text-black', {
            'tw-font-sans tw-text-xs': variant === Variants.Company,
            'tw-text-sm': variant !== Variants.Company,
          })}
        >
          <div
            className={clsx({
              'lg:tw-hidden': variant === Variants.Default,
              'tw-hidden': variant === Variants.Company,
            })}
          >
            Upload logo
          </div>
          <div className={clsx({ 'tw-hidden lg:tw-block': variant === Variants.Default })}>{text}</div>
        </div>
      )}
    </>
  );
};

export const ImageUpload = ({
  previewUrl,
  text = 'Drop file',
  isDisabled,
  onUpload,
  onDelete,
  variant = Variants.Default,
}) => {
  const {
    attachments,
    enqueuedAttachmentId,
    uploadedAttachments,
    addAttachments,
    cancelAttachment,
    restartAttachment,
    removeAttachment,
    uploadAttachment,
  } = useFileUploadContext();

  const acceptedFileTypes = ['.png', '.jpeg', '.jpg'];
  const attachment = attachments[0] || uploadedAttachments[0];
  const dropzoneRef = useRef();

  useEffect(() => {
    uploadAttachment(enqueuedAttachmentId, onUpload);
  }, [enqueuedAttachmentId]);

  const handleUploadClick = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const handleDeleteClick = (attachment) => {
    if (attachment) {
      removeAttachment(attachment);
    }
    onDelete();
  };

  return (
    <div className='tw-flex tw-w-full tw-items-center'>
      <Dropzone
        ref={dropzoneRef}
        accept={acceptedFileTypes}
        isDisabled={isDisabled}
        multiple={false}
        maxFiles={1}
        onDrop={addAttachments}
        className={clsx(' tw-shrink-0 tw-p-2', {
          'tw-h-24 tw-w-24 tw-rounded tw-border tw-border-dashed tw-bg-transparent':
            variant === Variants.Default || variant === Variants.Company,
          'tw-h-20 tw-w-20 tw-rounded-full tw-bg-gray-500': variant === Variants.Circle,
          'tw-border-solid tw-border-gray-700': attachment?.state === AttachmentState.Uploaded,
          ' tw-border-red-400': attachment?.state === AttachmentState.Failed,
          ' tw-border-gray-700':
            attachment?.state !== AttachmentState.Uploaded && attachment?.state !== AttachmentState.Failed,
        })}
      >
        <div className='tw-flex tw-h-full tw-items-center tw-justify-center'>
          <Preview previewUrl={previewUrl} attachment={attachment} text={text} variant={variant} />
        </div>
      </Dropzone>
      {(variant === Variants.Default || variant === Variants.Company) && (
        <div className='tw-ml-5 tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-evenly'>
          <Label text='Company logo' className='!tw-mb-0 tw-font-sans !tw-text-base tw-font-semibold' />
          <AttachmentManagement
            hasPreview={previewUrl ? true : false}
            attachment={attachment}
            isDisabled={isDisabled}
            onUpload={handleUploadClick}
            onRemove={handleDeleteClick}
            onCancel={cancelAttachment}
            onRestart={restartAttachment}
            variant={variant}
          />
        </div>
      )}
    </div>
  );
};

ImageUpload.propTypes = {
  variant: PropTypes.oneOf(Object.values(Variants)),
  text: PropTypes.string,
  isDisabled: PropTypes.bool,
  onUpload: PropTypes.func,
  onDelete: PropTypes.func,
};

export default withContext(FileUploadContextProvider, ImageUpload);
