import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import MeetingForm from 'components/v2/meetings/MeetingForm';
import { EditMeetingContextProvider } from 'contexts/v2/EditMeetingContext';

export default ({ id }) => (
  <EditMeetingContextProvider id={id}>
    <Container>
      <Row className='justify-content-center'>
        <Col lg={10}>
          <MeetingForm />
        </Col>
      </Row>
    </Container>
  </EditMeetingContextProvider>
);
