import React, { memo } from 'react';
import RSelect, { components as Components } from 'react-select';

import { If } from '../helpers/If';

import * as selectStyles from './Select.styles';

const Select = memo(
  ({
    styles,
    isClearable,
    isSearchable,
    hideSelectedOptions,
    backspaceRemovesValue,
    onChange,
    components,
    options,
    isMulti = false,
    ...props
  }) => {
    const MultiValueContainer = ({ selectProps: { value } }) => value.label;
    const DropdownIndicator = (props) => (
      <Components.DropdownIndicator {...props}>
        <i className='fa fa-chevron-down' />
      </Components.DropdownIndicator>
    );

    const Option = (props) => (
      <Components.Option {...props}>
        <div className='single-option-wrapper'>
          <label>{props.label}</label>

          <If query={props.isSelected}>
            <div className='checkmark-circle'>
              <div className='checkmark' />
            </div>
          </If>
        </div>
      </Components.Option>
    );

    const isDeselect = (event) => event.action === 'deselect-option';
    const onChangeHandler = (selected, event) => onChange(isDeselect(event) ? null : selected);
    const applicableStyles = styles ? styles(selectStyles.component) : selectStyles.component;

    return (
      <>
        <RSelect
          isMulti={isMulti}
          styles={applicableStyles}
          options={options}
          isClearable={false}
          onChange={onChangeHandler}
          isSearchable={false}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, Option, MultiValueContainer }}
          {...props}
        />
      </>
    );
  }
);

export { Select };
