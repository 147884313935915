import { useContactStore } from 'store/contactStoreContext';
import { useApolloClient } from '@apollo/client';

import { DIRECTORY_FRAGMENT } from './../data/fragments/DirectoryFragment';
import { DOCUMENT_FRAGMENT } from './../data/fragments/DocumentFragment';

const useApolloCacheHelpers = () => {
  const { contactId, companyId } = useContactStore();
  const { cache } = useApolloClient();

  const getDocumentFragment = (document) => {
    return cache.writeFragment({
      data: document,
      fragment: DOCUMENT_FRAGMENT,
      fragmentName: 'DocumentFields',
    });
  };

  const getDirectoryFragment = (directory) => {
    return cache.writeFragment({
      data: directory,
      fragment: DIRECTORY_FRAGMENT,
      fragmentName: 'DirectoryFields',
    });
  };

  const removeDirectoryFromCache = (parent, directory) => {
    cache.modify({
      id: cache.identify({ __typename: 'Directory', id: parent.id }),
      fields: {
        directories(existing = [], { readField }) {
          return existing.filter((item) => readField('id', item) !== directory.id);
        },
      },
    });
  };

  const removeDocumentFromCache = (parent, document) => {
    cache.modify({
      id: cache.identify({ __typename: 'Directory', id: parent.id }),
      fields: {
        documents(existing = [], { readField }) {
          return existing.filter((item) => readField('id', item) !== document.id);
        },
      },
    });
  };

  const addDirectoryToFavouritesCache = (directory) => {
    cache.modify({
      id: cache.identify({ __typename: 'Contact', id: contactId }),
      fields: {
        favouriteDirectories(existing = [], { readField }) {
          const isPresent = existing.find((item) => readField('id', item) === directory.id);

          if (isPresent) {
            return existing.filter((item) => readField('id', item) !== directory.id);
          } else {
            return [...existing, getDirectoryFragment(directory)];
          }
        },
      },
    });
  };

  const addDocumentToFavouritesCache = (document) => {
    cache.modify({
      id: cache.identify({ __typename: 'Contact', id: contactId }),
      fields: {
        favouriteDocuments(existing = [], { readField }) {
          const isPresent = existing.find((item) => readField('id', item) === document.id);

          if (isPresent) {
            return existing.filter((item) => readField('id', item) !== document.id);
          } else {
            return [...existing, getDocumentFragment(document)];
          }
        },
      },
    });
  };

  const addDirectoryToCache = (parent, directory) => {
    cache.modify({
      id: cache.identify({ __typename: 'Directory', id: parent.id }),
      fields: {
        directories(existing = [], { readField }) {
          const isPresent = existing.find((item) => readField('id', item) === directory.id);

          if (isPresent) {
            return existing;
          }

          return [...existing, getDirectoryFragment(directory)];
        },
      },
    });
  };

  const addDocumentToCache = (parent, document) => {
    cache.modify({
      id: cache.identify({ __typename: 'Directory', id: parent.id }),
      fields: {
        documents(existing = [], { readField }) {
          const isPresent = existing.find((item) => readField('id', item) === document.id);

          if (isPresent) {
            return existing;
          }

          return [...existing, getDocumentFragment(document)];
        },
      },
    });
  };

  const evictSearchDirectoriesCache = (broadcast = false) => {
    cache.evict({
      id: cache.identify({ __typename: 'Company', id: companyId }),
      fieldName: 'directories',
      broadcast: broadcast,
    });

    cache.gc();
  };

  const evictSearchDocumentsCache = (broadcast = false) => {
    cache.evict({
      id: cache.identify({ __typename: 'Company', id: companyId }),
      fieldName: 'documents',
      broadcast: broadcast,
    });

    cache.gc();
  };

  const evictFavouriteDirectoriesCache = (broadcast = false) => {
    cache.evict({
      id: cache.identify({ __typename: 'Contact', id: contactId }),
      fieldName: 'favouriteDirectories',
      broadcast: broadcast,
    });

    cache.gc();
  };

  const evictFavouriteDocumentsCache = (broadcast = false) => {
    cache.evict({
      id: cache.identify({ __typename: 'Contact', id: contactId }),
      fieldName: 'favouriteDocuments',
      broadcast: broadcast,
    });

    cache.gc();
  };

  const evictRecentDirectoriesCache = (broadcast = false) => {
    cache.evict({
      id: cache.identify({ __typename: 'Contact', id: contactId }),
      fieldName: 'recentDirectories',
      broadcast: broadcast,
    });

    cache.gc();
  };

  const evictRecentDocumentsCache = (broadcast = false) => {
    cache.evict({
      id: cache.identify({ __typename: 'Contact', id: contactId }),
      fieldName: 'recentDocuments',
      broadcast: broadcast,
    });

    cache.gc();
  };

  return {
    addDirectoryToCache,
    addDocumentToCache,
    addDirectoryToFavouritesCache,
    addDocumentToFavouritesCache,
    removeDirectoryFromCache,
    removeDocumentFromCache,
    evictFavouriteDirectoriesCache,
    evictFavouriteDocumentsCache,
    evictRecentDirectoriesCache,
    evictRecentDocumentsCache,
    evictSearchDirectoriesCache,
    evictSearchDocumentsCache,
  };
};

export { useApolloCacheHelpers };
