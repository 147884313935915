import { gql, useMutation } from '@apollo/client';
import { VOTE_FRAGMENT } from './../fragments/VoteFragment';

const UPSERT_VOTE = gql`
  mutation upsertVote($input: UpsertVoteInput!) {
    upsertVote(input: $input) {
      vote {
        ...VoteFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${VOTE_FRAGMENT}
`;

const useUpsertVoteMutation = (options) => useMutation(UPSERT_VOTE, options);

export { useUpsertVoteMutation };
