import { css } from '@emotion/core';

export const outer = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
`;

export const viewer = ({ height, margin }) => css`
  position: relative;
  transform-origin: center top;
  width: 800px;
  margin: ${margin}px auto 0 auto;
  height: ${height}px;
`;
