import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useCreateContactsMutation } from 'data/v2/mutations/CreateContactsMutation';
import { useCurrentContactContext } from 'contexts/v2/CurrentContactContext';
import { useGQLDataContext } from './GQLDataContext';
import { wrapMutation } from 'utils/v2/gql';
import { findOptionByValue } from 'utils/v2/data';

const initialState = {
  data: [],
};

const actionTypes = {
  SET_DATA: 'ContactsContext.setData',
  ADD_CONTACT: 'ContactsContext.addContact',
};

const ContactsContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_DATA:
      return { ...state, data: payload.data };
    case actionTypes.ADD_CONTACT:
      return { ...state, data: [...state.data, payload.contact] };
  }
};

export const ContactsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [createContact] = useCreateContactMutation();

  const { currentContact } = useCurrentContactContext();
  const { gqlData, getDataByPath } = useGQLDataContext();

  const format = ({ name, id, ...rest }) => ({ label: name, value: id, id, name, ...rest });

  const filterCurrentContact = ({ value }) => value !== currentContact.id;

  useEffect(() => {
    if (gqlData) {
      const data = getDataByPath('currentCompany.contacts', []).map(format).filter(filterCurrentContact);
      dispatch({ type: actionTypes.SET_DATA, payload: { data } });
    }
  }, [gqlData]);

  const addContact = async (input) => {
    return wrapMutation(createContact, { variables: { input } }, 'createContact').then((data) => {
      const contact = format(data.contact);
      dispatch({ type: actionTypes.ADD_CONTACT, payload: { contact } });
      return contact;
    });
  };

  const getContactByValue = (value) => findOptionByValue(value, data);

  const { data } = state;

  return (
    <ContactsContext.Provider value={{ contacts: data, addContact, getContactByValue }}>
      {children}
    </ContactsContext.Provider>
  );
};

ContactsContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useContactsContext = () => useContext(ContactsContext);
