/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { ButtonWithSuccess } from 'components/shared/ButtonWithSuccess';
import { Placeholder } from 'components/shared/Placeholder';
import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';
import { ReactComponent as ContactsIcon } from 'images/icons/no-contacts.svg';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import * as styles from './index.styles';

const ContactsTableMobile = memo(({ contactTableAttrs }) => {
  const {
    contacts,
    contactMeetings,
    meetingStatus,
    setChairman,
    isCreator,
    chairmanId,
    meetingOrganizerId,
    onDelete,
    onResend,
    readOnly,
  } = contactTableAttrs;

  const { isDraft, isUpcoming, isScheduled, isPseudo } = useMeetingStatus(meetingStatus);

  const intl = useIntl();

  const getInvitationText = (contact, invitationStatus) => {
    const acceptedStatuses = ['yes', 'confirmed'];

    if (isCreator(contact)) {
      return intl.formatMessage({ id: 'ContactsTable.creator' });
    }

    if (!meetingStatus || isDraft) {
      return intl.formatMessage({ id: 'ContactsTable.invitePending' });
    }

    if (acceptedStatuses.includes(invitationStatus)) {
      return intl.formatMessage({ id: 'ContactsTable.accepted' });
    } else if (invitationStatus === 'no') {
      return intl.formatMessage({ id: 'ContactsTable.declined' });
    } else if (invitationStatus === 'waiting') {
      return intl.formatMessage({ id: 'ContactsTable.maybe' });
    } else {
      return intl.formatMessage({ id: 'ContactsTable.inviteSent' });
    }
  };

  const placeholderIcon = <ContactsIcon />;

  if (!contacts || !contacts.length) {
    return <Placeholder image={placeholderIcon} message={intl.formatMessage({ id: 'ContactsTable.noAttendees' })} />;
  }

  const handleChairman = (event) => {
    if (event.target.checked) {
      const contactMeetingId = Number(event.target.value);
      setChairman(contactMeetingId);
    } else {
      setChairman(null);
    }
  };

  const tableBody = contacts.map((contact, index) => {
    const handleDelete = onDelete.bind(this, contact);
    const handleResend = onResend && onResend.bind(this, contact);
    const isChairman = chairmanId === contact.id;
    const invitation = contactMeetings.find((item) => item.contact.id === contact.id);
    const invitationText = getInvitationText(contact, invitation?.status);

    return (
      <div css={styles.mobileItem} key={contact.id}>
        <div css={styles.mobileItemWrapper}>
          <div css={styles.flexBetween}>
            <div>{contact.name}</div>
            <div css={styles.deleteMobile}>
              {!readOnly && !isCreator(contact) && (
                <ButtonWithConfirmation
                  confirmationText={intl.formatMessage({ id: 'ContactsTable.confirm' }, { name: contact.name })}
                  onConfirm={handleDelete}
                  button={<i css={styles.deleteIcon} className='m-l-20 far fa-fw fa-trash-alt' />}
                />
              )}
            </div>
          </div>
          <div css={styles.flexBetween}>
            <span css={[styles.status, styles.statuses[invitation?.status || 'pending']]}>{invitationText}</span>

            {!isPseudo && !isCreator(contact) && onResend && (isUpcoming || isScheduled) && (
              <ButtonWithSuccess
                className={`m-l-30 btn font-weight-regular ${
                  invitation.id ? 'btn-light-orange' : 'btn-disabled'
                } btn-no-transform btn-xsm`}
                style={{ width: 108 }}
                onClick={handleResend}
              >
                <FormattedMessage id='ContactsTable.resend' />
              </ButtonWithSuccess>
            )}
          </div>
          <div css={styles.flexBetween}>
            <div className='custom-control custom-switch shaparency-switch inverted'>
              <input
                type='checkbox'
                id={`chairman-${invitation.id}`}
                value={contact.id}
                onChange={handleChairman}
                disabled={readOnly}
                checked={isChairman}
                className='custom-control-input'
              />

              <label className='custom-control-label' htmlFor={`chairman-${contact.id}`}>
                <FormattedMessage id='ContactsTable.chair' />
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div css={styles.mobileContainer}>{tableBody}</div>
    </>
  );
});

export { ContactsTableMobile };
