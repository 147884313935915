import { css } from '@emotion/core';

export const wrapper = (hasOptions) => css`
  padding: ${hasOptions ? '13px 8px 13px 0' : 0};
`;

export const dropdownWrapper = css`
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
`;

export const items = css`
  overflow: auto;
  max-height: 241px;
`;
