import React, { useState } from 'react';
import Button from 'components/v2/shared/Button';
import IconButton from '../IconButton';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const PageTab = ({ pageNumber, isActive, onClick }) => {
  return (
    <div
      onClick={() => onClick(pageNumber)}
      className={clsx(
        'tw-flex tw-h-[48px] tw-w-[55px] tw-items-center tw-justify-center tw-rounded-sm tw-bg-purple-100 tw-text-sm tw-font-medium tw-text-purple-500 hover:tw-bg-purple-500 hover:tw-text-purple-100',
        {
          'tw-bg-purple-500 !tw-text-purple-100': isActive,
        }
      )}
    >
      {pageNumber}
    </div>
  );
};

const Pagination = ({ page: currentPage, pageTotal, onPaginationClick }) => {
  const pages = [...Array(pageTotal).keys()];
  const displayedPageTabs = 5;
  const [tabSet, setTabSet] = useState(1);
  const [pageTabs, setPageTabs] = useState(
    pages.slice(displayedPageTabs * tabSet - displayedPageTabs, displayedPageTabs * tabSet)
  );

  const handlePaginationClick = (page) => {
    const first = [...pageTabs].shift();
    const last = [...pageTabs].pop();

    if (page === pageTotal) {
      const set = Math.ceil(pageTotal / displayedPageTabs);
      setPageTabs(pages.slice(displayedPageTabs * set - displayedPageTabs, displayedPageTabs * set));
      setTabSet(set);
    } else if (page === 1) {
      const set = 1;
      setPageTabs(pages.slice(displayedPageTabs * set - displayedPageTabs, displayedPageTabs * set));
      setTabSet(set);
    } else if (page > last + 1) {
      const set = tabSet + 1;
      setPageTabs(pages.slice(displayedPageTabs * set - displayedPageTabs, displayedPageTabs * set));
      setTabSet(set);
    } else if (page <= first) {
      const set = tabSet - 1;
      setPageTabs(pages.slice(displayedPageTabs * set - displayedPageTabs, displayedPageTabs * set));
      setTabSet(set);
    }

    onPaginationClick(page);
  };

  return (
    <div className='tw-mt-7'>
      <div className='tw-mx-auto tw-max-w-[360px] md:tw-hidden'>
        <div className='tw-mx-auto tw-flex tw-items-center tw-justify-center tw-space-x-3'>
          {pageTabs.map((page) => (
            <PageTab key={page} pageNumber={page + 1} isActive={currentPage === page + 1} onClick={onPaginationClick} />
          ))}
        </div>
        <div className='tw-mx-auto tw-flex tw-items-center tw-justify-evenly tw-space-x-3 tw-pt-4'>
          <IconButton
            aria-label='first'
            onClick={() => handlePaginationClick(1)}
            isDisabled={currentPage === 1}
            className='tw-col-span-3 tw-justify-self-end tw-rounded-sm tw-p-1 tw-text-xl tw-leading-none tw-text-purple-500 disabled:tw-opacity-30'
          >
            <HiChevronDoubleLeft />
          </IconButton>
          <Button
            onClick={() => handlePaginationClick(currentPage - 1)}
            className='tw-col-span-3  tw-w-24 !tw-bg-purple-100 !tw-text-sm !tw-font-medium !tw-text-purple-500 hover:!tw-bg-purple-500 hover:!tw-text-purple-100'
            isDisabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            onClick={() => handlePaginationClick(currentPage + 1)}
            className='tw-col-span-3 tw-w-24 !tw-bg-purple-100 !tw-text-sm !tw-font-medium !tw-text-purple-500 hover:!tw-bg-purple-500 hover:!tw-text-purple-100'
            isDisabled={currentPage === pageTotal}
          >
            Next
          </Button>
          <IconButton
            aria-label='last'
            onClick={() => handlePaginationClick(pageTotal)}
            className='tw-col-span-3 tw-justify-self-start tw-rounded-sm tw-p-1 tw-text-xl tw-leading-none tw-text-purple-500 disabled:tw-opacity-30'
            isDisabled={currentPage === pageTotal}
          >
            <HiChevronDoubleRight />
          </IconButton>
        </div>
      </div>
      <div className='tw-hidden md:tw-block'>
        <div className='tw-mx-auto tw-flex tw-items-center tw-justify-center tw-space-x-1 tw-pt-4'>
          <IconButton
            aria-label='first'
            onClick={() => handlePaginationClick(1)}
            isDisabled={currentPage === 1}
            className='tw-col-span-3 tw-justify-self-end tw-rounded-sm tw-p-1 tw-text-xl tw-leading-none tw-text-purple-500 disabled:tw-opacity-30'
          >
            <HiChevronDoubleLeft />
          </IconButton>
          <Button
            onClick={() => handlePaginationClick(currentPage - 1)}
            className='tw-col-span-3 !tw-bg-purple-100 !tw-text-sm !tw-font-medium !tw-text-purple-500 hover:!tw-bg-purple-500 hover:!tw-text-purple-100 md:tw-w-24'
            isDisabled={currentPage === 1}
          >
            Previous
          </Button>
          {pageTabs.map((page) => (
            <PageTab key={page} pageNumber={page + 1} isActive={currentPage === page + 1} onClick={onPaginationClick} />
          ))}
          <Button
            onClick={() => handlePaginationClick(currentPage + 1)}
            className='tw-col-span-3 !tw-bg-purple-100 !tw-text-sm !tw-font-medium !tw-text-purple-500 hover:!tw-bg-purple-500 hover:!tw-text-purple-100 md:tw-w-24'
            isDisabled={currentPage === pageTotal}
          >
            Next
          </Button>
          <IconButton
            aria-label='last'
            onClick={() => handlePaginationClick(pageTotal)}
            className='tw-col-span-3 tw-justify-self-start tw-rounded-sm tw-p-1 tw-text-xl tw-leading-none tw-text-purple-500 disabled:tw-opacity-30'
            isDisabled={currentPage === pageTotal}
          >
            <HiChevronDoubleRight />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  pageTotal: PropTypes.number,
  onPaginationClick: PropTypes.func,
};

export default Pagination;
