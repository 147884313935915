import { css } from '@emotion/core';
import { colors } from 'styles';

export const icon = {
  base: css`
    flex-shrink: 0;
    display: inline;
  `,

  move: css`
    cursor: move;
  `,

  close: css`
    cursor: pointer;

    &:hover {
      svg {
        path {
          fill: ${colors.cyan};
        }
      }
    }
  `,

  dots: css`
    cursor: pointer;
    padding: 0 5px;
    margin: 0 -5px;

    &:hover {
      svg {
        g {
          fill: ${colors.black};
        }
      }
    }
  `,
};
