import React, { useState, useEffect } from 'react';
import Button from 'components/v2/shared/Button';
import SelectInput from 'components/v2/shared/SelectInput';
import TextInput from 'components/v2/shared/TextInput';
import Shape from 'components/v2/shared/Shape';
import { FiChevronLeft, FiPlus, FiX } from 'react-icons/fi';
import Heading from 'components/v2/shared/Heading';
import { wrapMutation } from 'utils/v2/gql';
import { useCreateContactsMutation } from 'data/v2/mutations/CreateContactsMutation';

const AddUser = ({ addUser, onClick, isInviteUser, users }) => {
  const [value, setValue] = useState({ email: '', roleId: null });
  const handleSubmit = (e) => {
    value && addUser(value);
    setValue('');
    isInviteUser(false);
  };

  return (
    <div className='tw-mx-auto sm:tw-w-[450px]'>
      <div>
        <form onSubmit={handleSubmit}>
          <div className='tw-mt-11'>
            <TextInput
              className='!tw-h-11'
              placeholder='Enter email'
              name='email'
              value={value.email}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
          <div className='tw-pt-2.5 tw-pb-5'>
            <Button font='semibold' isFullWidth size='large' variant='teal'>
              Add user
            </Button>
          </div>
          {users.length === 0 && (
            <a
              onClick={() => onClick('list')}
              className='tw-ml-4 tw-cursor-pointer tw-text-xs tw-font-medium tw-text-teal-500 hover:tw-text-teal-500'
            >
              Skip this step (you can invite users later)
            </a>
          )}
        </form>
      </div>
    </div>
  );
};

const InviteUsers = ({ roles, onClickPage, onClick, company }) => {
  const [users, setUsers] = useState([]);
  const [isUser, setIsUser] = useState(false);
  const [createContacts] = useCreateContactsMutation();

  const onPreviousPage = () => {
    onClickPage(false);
  };
  const addUserOnclick = () => {
    setIsUser(true);
  };
  const onClickInvite = (value) => {
    setIsUser(value);
  };
  const addUsers = (email) => setUsers([...users, { email }]);
  const addInviteUserContinue = async () => {
    await wrapMutation(
      createContacts,
      {
        variables: {
          input: {
            contacts: users,
            companyId: company.id,
          },
        },
      },
      'createContacts'
    );
    onClick('list');
  };
  const removeInvitedUsers = (index) => {
    const invitedUser = [...users];
    invitedUser.splice(index, 1);
    setUsers(invitedUser);
  };

  const updateUserRole = (index, accessRoleId) => {
    const usersArray = [...users];
    usersArray[index] = { ...usersArray[index], accessRoleId };
    setUsers(usersArray);
  };

  return (
    <>
      <div className='tw-container tw-mx-auto	tw-max-w-full tw-justify-center tw-bg-white tw-py-6 tw-px-5 sm:tw-flex sm:tw-w-[774px] sm:tw-px-10'>
        <div className='tw-relative'>
          <h5
            className='tw-flex tw-cursor-pointer tw-items-center tw-text-base !tw-font-extrabold'
            onClick={onPreviousPage}
          >
            <FiChevronLeft
              size={24}
              className='tw-text-purple-700 sm:tw-absolute sm:-tw-left-6 sm:tw-top-0.5'
              color='#47126E'
            />
            <span className='tw-pl-1.5 tw-font-sans tw-text-sm tw-font-semibold sm:tw-pt-1'>Companies</span>
          </h5>
          <Heading text='Invite users' className='tw-font-sans !tw-text-3xl tw-font-bold tw-text-black' />
          <div className='tw-mt-12'>
            {users.map((row, i) => {
              return (
                <>
                  <div className='tw-mb-2 tw-flex tw-rounded tw-bg-gray-100'>
                    <div className='tw-grid-flow-col tw-grid-cols-8 tw-items-center tw-py-5 tw-pr-7 tw-pl-8 sm:tw-grid'>
                      <div className='tw-col-span-4'>
                        <div className='tw-my-4 tw-flex tw-items-center tw-font-sans tw-text-sm tw-font-medium sm:tw-my-0'>
                          {row.email}
                        </div>
                      </div>
                      <div className='tw-col-span-3'>
                        <SelectInput
                          placeholder='Assign role'
                          isToggleBorder={false}
                          optionVariant='regular'
                          options={roles}
                          toggleVariant='gray'
                          onChange={(item) => updateUserRole(i, item.id)}
                        />
                      </div>
                      <div className='tw-relative tw-col-span-1 tw-my-2 tw-flex tw-justify-center sm:tw-my-0 sm:tw-justify-end'>
                        <FiX
                          className='tw-top-0.5 tw-cursor-pointer tw-text-gray-700'
                          onClick={() => {
                            removeInvitedUsers(i);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          {isUser ? (
            <AddUser addUser={addUsers} isInviteUser={onClickInvite} users={users} />
          ) : (
            users.length === 0 && (
              <AddUser addUser={addUsers} onClick={onClick} isInviteUser={onClickInvite} users={users} />
            )
          )}
          {users.length > 0 && (
            <>
              <div className='tw-mx-auto tw-mt-5 tw-flex sm:tw-ml-10'>
                <a className='tw-relative tw-flex tw-cursor-pointer'>
                  <FiPlus
                    className='tw-absolute tw-left-1.5 tw-top-1.5 tw-font-sans tw-text-base tw-font-normal tw-text-[#2C2E3F]'
                    onClick={addUserOnclick}
                  />
                  <Shape variant='circle' className='!tw-h-7 !tw-w-7 !tw-bg-purple-100' onClick={addUserOnclick} />
                  <span
                    className='tw-mt-2 tw-ml-3 tw-font-sans tw-text-xs tw-font-medium tw-text-black hover:tw-text-black'
                    onClick={addUserOnclick}
                  >
                    Add another user
                  </span>
                </a>
              </div>

              <div className='tw-mx-auto tw-my-24 sm:tw-w-[450px]'>
                <Button font='semibold' isFullWidth size='large' variant='purple' onClick={addInviteUserContinue}>
                  Invite users and continue
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default InviteUsers;
