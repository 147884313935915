import { css } from '@emotion/core';
import { colors } from 'styles';

export const container = css`
  position: relative;
  display: inline-flex;
`;

export const badge = {
  base: css`
    position: absolute;
    right: 0;
    box-shadow: 0 0 0 2px ${colors.white};
    border-radius: 50%;
    bottom: 0;
    width: 8px;
    height: 8px;
  `,

  green: css`
    background: ${colors.badgeGreen};
  `,

  pink: css`
    background: ${colors.badgePink};
  `,

  grey: css`
    background: ${colors.badgeGrey};
  `,
};
