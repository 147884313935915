import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const component = css`
  outline: none;
  position: relative;

  &:focus {
    outline: none;
  }
`;

export const clear = css`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const wrapper = css`
  width: 100%;
  position: relative;
`;

export const dropdown = (width) => css`
  ${fonts.regular}
  width: 100%;
  position: absolute;
  box-shadow: 0px 1.2px 3.6px rgba(0, 72, 90, 0.1), 0px 6.4px 14.4px rgba(0, 77, 97, 0.13);
  border-radius: 2px;
  height: auto;
  z-index: 3;
  max-width: ${width}px;
  background: ${colors.white};
  font-size: 14px;
  line-height: 20px;
  color: ${colors.black};
  ${media.mobile} {
    max-width: 100%;
  }
`;

export const skeleton = css`
  padding: 7px 21px;
`;
