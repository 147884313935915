import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ListItemTemplate from 'components/v2/smartSign/ListItemTemplate';
import StateBadge from 'components/v2/shared/StateBadge';
import Box from 'components/v2/shared/Box';
import Avatar from 'components/v2/shared/Avatar';
import Shape from 'components/v2/shared/Shape';
import { documentStatusBadgeVariantMap } from 'components/v2/smartSign/constants';

export const ListItemPlaceholder = () => (
  <ListItemTemplate
    nameNode={<Shape variant='rectangle' />}
    signatureCountNode={<Shape variant='rectangle' />}
    statusNode={<Shape variant='rectangle' />}
    dateNode={<Shape variant='rectangle' />}
    timeNode={<Shape variant='rectangle' />}
    ownerNode={<Shape variant='circle' />}
  />
);

ListItemPlaceholder.propTypes = {};

const ListItem = ({
  isSplitView,
  name,
  signedSignatureCount,
  totalSignatureCount,
  status,
  createdAtDate,
  createdAtTime,
  ownerInitials,
}) => (
  <ListItemTemplate
    isHoverable
    isSplitView={isSplitView}
    nameNode={<div className='tw-line-clamp-2'>{name}</div>}
    signatureCountNode={
      <div className='tw-text-xs tw-text-gray-700'>{`${signedSignatureCount} / ${totalSignatureCount}`}</div>
    }
    statusNode={
      <StateBadge variant={documentStatusBadgeVariantMap[status]}>
        <div className='tw-capitalize'>{status}</div>
      </StateBadge>
    }
    dateNode={
      <StateBadge variant={documentStatusBadgeVariantMap[status]}>
        <div className='tw-font-semibold'>{createdAtDate}</div>
      </StateBadge>
    }
    timeNode={<StateBadge variant={documentStatusBadgeVariantMap[status]}>{createdAtTime}</StateBadge>}
    ownerNode={<Avatar size='small' text={ownerInitials} />}
  />
);

ListItem.propTypes = {
  isSplitView: PropTypes.bool,
  name: PropTypes.string.isRequired,
  signedSignatureCount: PropTypes.number.isRequired,
  totalSignatureCount: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  createdAtDate: PropTypes.string.isRequired,
  createdAtTime: PropTypes.string.isRequired,
  ownerInitials: PropTypes.string.isRequired,
};

export default ListItem;
