import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const steps = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;

  ${media.tablet} {
    align-items: flex-start;
  }
`;

export const step = (grow) => css`
  display: flex;
  align-items: center;
  position: relative;
  ${media.tablet} {
    align-items: flex-start;
    flex-grow: ${grow ? 1 : 0};
  }
`;

export const wrapper = (isClickable) => css`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  ${media.tablet} {
    flex-direction: column;
  }

  &:hover {
    cursor: ${isClickable ? 'pointer' : 'default'};
  }
`;

export const line = css`
  margin: 0 12px;
  border-top: 1px solid ${colors.headingGrey};
  height: 1px;
  width: 30px;

  ${media.tablet} {
    width: 20px;
    flex-direction: column;
    margin: 0 5px;
    margin: 0 10px 0 0;
    transform: translateY(20px);
    z-index: 1;
    flex-grow: 1;
  }
`;

export const outerCircle = (isActive) => css`
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  height: 34px;
  background: ${HexToRgba(isActive ? colors.cyan : colors.lightGrey, 20)};
`;

export const innerCircle = (isActive) => css`
  ${fonts.bold}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  flex-shrink: 0;
  height: 24px;
  background: ${isActive ? colors.cyan : colors.lightGrey};
  border-radius: 50%;
  color: ${colors.white};
  font-size: 12px;
`;

export const name = (isActive) => css`
  ${isActive ? fonts.semiBold : fonts.regular}
  font-size: 16px;
  flex-shrink: 0;
  color: ${isActive ? colors.cyan : colors.mediumGrey};
  margin-left: 12px;
  font-weight: 500;

  ${media.tablet} {
    margin-top: 5px;
    text-align: center;
    margin-left: 0;
    font-size: 12px;
    line-height: 14px;
  }
`;
