/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';
import { withIntl } from '../../../utils/v2/intl';

import { useClickOutside } from 'hooks/useClickOutside';
import CreatePopup from 'components/minutes/CreatePopup';

import * as styles from './index.styles';

const AddButton = memo(({ meetings }) => {
  const { isComponentVisible, setIsComponentVisible } = useClickOutside(false);

  const show = () => setIsComponentVisible(true);
  const hide = () => setIsComponentVisible(false);

  const meetingOptions = meetings.map((item) => ({ id: item[0], title: item[1] }));

  if (!isComponentVisible) {
    return (
      <div className='media-heading-wrapper_action'>
        <button
          onClick={show}
          className='react-add-minutes justify-content-center btn btn-primary btn-sm btn-no-transform btn-index new-button'
          css={styles.button}
        >
          <FormattedMessage id='AddButton.create' />
        </button>
      </div>
    );
  }

  return <CreatePopup show={isComponentVisible} onClose={hide} meetingOptions={meetingOptions} />;
});

export default withIntl(AddButton);
