import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { SubcommitteeDrawerTableRow } from '../SubcommitteeDrawerTableRow';
import { DrawerPlaceholderTable } from '../DrawerPlaceholderTable';
import { FadingOverlay } from 'components/shared/PlaceholderTable';

const SubcommitteeDrawerTable = memo(({ contacts, accessRoles, onDelete }) => {
  const tableRows = contacts.map((contact) => (
    <SubcommitteeDrawerTableRow key={contact.id} contact={contact} onDelete={onDelete} accessRoles={accessRoles} />
  ));

  const intl = useIntl();

  return (
    <div className='position-relative'>
      <FadingOverlay
        show={!contacts.length}
        resource={intl.formatMessage({ id: 'SubcommitteeDrawerTable.contacts' })}
      />

      <table className='table table-layout-fixed'>
        <thead>
          <tr>
            <th className='w-30' />
            <th className='w-40' />
            <th className='w-20' />
            <th className='w-10' />
          </tr>
        </thead>

        <tbody>
          {tableRows}
          <DrawerPlaceholderTable show={!contacts.length} />
        </tbody>
      </table>
    </div>
  );
});

export { SubcommitteeDrawerTable };
