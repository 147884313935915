import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { capitalize } from 'utils/capitalize';

const AccessRoleTableRow = memo(({ accessRole, onClick }) => {
  const { isMobile } = useWindowDimensions();

  const intl = useIntl();

  const { name, contact_count, status, company_id } = accessRole.attributes;

  const getButtonText = () => {
    if (company_id) {
      return intl.formatMessage({ id: 'AccessRoleTableRow.manage' });
    } else {
      return intl.formatMessage({ id: 'AccessRoleTableRow.view' });
    }
  };

  const handleClick = onClick.bind(this, accessRole.attributes);

  return (
    <>
      <tr>
        <td>
          <div className='w-100 text-truncate fs-14 text-black p-r-5'>{name}</div>
        </td>

        <td>
          <span className={`badge badge-large badge-${status}`}>{capitalize(status)}</span>
        </td>

        <td>
          <div className='fs-12 text-black'>
            {isMobile
              ? contact_count
              : intl.formatMessage({ id: 'AccessRoleTableRow.contactCount' }, { count: contact_count })}
          </div>
        </td>

        <td className='text-right'>
          <button
            className={`btn btn-inverted ${isMobile ? 'btn-sm-fw btn-md-fw' : 'btn-sm w-100px'}`}
            onClick={handleClick}
          >
            {getButtonText()}
          </button>
        </td>
      </tr>
    </>
  );
});

export { AccessRoleTableRow };
