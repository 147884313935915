import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const radio = css`
  position: relative;
  margin-bottom: 10px;
`;

export const input = css`
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
  outline: none !important;
`;

export const label = css`
  padding-left: 25px !important;
  &:before {
    left: 18px !important;
  }
  &:after {
    left: 24px !important;
  }
`;
