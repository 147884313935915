import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FiFile, FiFolder } from 'react-icons/fi';

export const Variants = {
  Directory: 'Directory',
  Document: 'Document',
};

const variantIconMap = {
  [Variants.Directory]: FiFolder,
  [Variants.Document]: FiFile,
};

const ContentAreaItem = ({ name, variant, isSelected = false, onClick }) => {
  const VariantIcon = variantIconMap[variant];

  return (
    <div
      onClick={onClick}
      className={clsx(
        'tw-flex tw-items-center tw-justify-between',
        'tw-cursor-pointer tw-rounded tw-font-medium',
        'tw-group tw-gap-x-4 tw-py-2 tw-px-4',
        {
          'tw-bg-teal-200': isSelected,
          'hover:tw-bg-purple-200': !isSelected,
        }
      )}
    >
      <div className='tw-flex tw-min-w-0 tw-items-center tw-gap-x-4'>
        <VariantIcon
          className={clsx('tw-h-4 tw-w-4 tw-shrink-0', {
            'group-hover:tw-stroke-teal-500': isSelected,
            'group-hover:tw-stroke-purple-500': !isSelected,
          })}
        />

        <div
          className={clsx('tw-truncate tw-text-sm tw-text-black', {
            'group-hover:tw-text-teal-500': isSelected,
            'group-hover:tw-text-purple-500': !isSelected,
          })}
        >
          {name}
        </div>
      </div>

      <div
        className={clsx('tw-invisible tw-whitespace-nowrap tw-text-xs group-hover:tw-visible', {
          'tw-hidden': variant === Variants.Directory,
          'tw-text-teal-500': isSelected,
          'tw-text-purple-500': !isSelected,
        })}
      >
        {isSelected ? 'Unselect' : 'Select'}
      </div>
    </div>
  );
};

ContentAreaItem.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(Variants)).isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ContentAreaItem;
