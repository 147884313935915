const getErrorMessages = (errors) => {
  const messages = {};

  Object.keys(errors).map((key) => {
    messages[key] = errors[key][0];
  });

  return messages;
};

const getErrors = ({ response }) => {
  const {
    data: { errors, message },
  } = response;

  return getErrorMessages(errors || []);
};

export { getErrors };
