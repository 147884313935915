/** @jsx jsx */

import React, { memo, useMemo, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDirectoriesBrowser } from './../hooks/useDirectoriesBrowser';
import { useMoveDirectory } from './../hooks/useMoveDirectory';
import { useMoveDocument } from './../hooks/useMoveDocument';
import { useIncrementDirectoryName } from './../hooks/useIncrementDirectoryName';
import { useIncrementDocumentName } from './../hooks/useIncrementDocumentName';

import { Scrollable } from 'components/shared/Scrollable';
import { LoadingIndicator } from 'components/shared/LoadingIndicator';
import { AlertPopup } from 'components/shared/AlertPopup';
import { MoveModalDirectory } from './../MoveModalDirectory';
import { Breadcrumbs } from './../Breadcrumbs';
import { Modal } from 'components/shared/Modal';

import ClipLoader from 'react-spinners/ClipLoader';

import { colors } from 'styles';
import * as styles from './MoveModal.styles';

const ROOT_BREADCRUMB = { id: null, name: 'Root' };

const MoveModal = memo(({ show, resource, onClose }) => {
  const [moveError, setMoveError] = useState(null);
  const [selectedDirectoryId, setSelectedDirectoryId] = useState(null);
  const [showRenameAlert, setShowRenameAlert] = useState(false);

  const intl = useIntl();

  const toggleSelectedDirectoryId = (id) => {
    if (selectedDirectoryId === id) {
      setSelectedDirectoryId(null);
    } else {
      setSelectedDirectoryId(id);
    }
  };

  const handleMoveError = (error) => {
    if (error.includes('already exists')) {
      setShowRenameAlert(true);
    } else {
      setMoveError(error);
    }
  };

  const { moveDirectory, isMoving: isMovingDirectory } = useMoveDirectory({
    onCompleted: onClose,
    onError: handleMoveError,
    parent: resource?.parent,
  });

  const { moveDocument, isMoving: isMovingDocument } = useMoveDocument({
    onCompleted: onClose,
    onError: handleMoveError,
    parent: resource?.parent,
  });

  const { isLoading, requestedDirectoryId, currentDirectory, rootDirectories, browseDirectory } = useDirectoriesBrowser(
    {}
  );

  const resetError = () => setMoveError(null);

  const resetModal = () => {
    setMoveError(null);
    setSelectedDirectoryId(null);
    browseDirectory(null);
  };

  const handleMove = () => {
    switch (resource.__typename) {
      case 'Directory':
        return moveDirectory(resource.id, selectedDirectoryId);
      case 'Document':
        return moveDocument(resource.id, selectedDirectoryId);
    }
  };

  const handleMoveAndCloseAlert = () => {
    handleMove();
    setShowRenameAlert(false);
  };

  const { incrementDirectoryName, isIncremeting: isIncrementingDirectoryName } = useIncrementDirectoryName({
    onCompleted: handleMoveAndCloseAlert,
    onError: handleMoveError,
    parent: resource?.parent,
  });

  const { incrementDocumentName, isIncremeting: isIncrementingDocumentName } = useIncrementDocumentName({
    onCompleted: handleMoveAndCloseAlert,
    onError: handleMoveError,
    parent: resource?.parent,
  });

  const hideRenameAlert = () => setShowRenameAlert(false);

  const incrementResourceName = () => {
    switch (resource?.__typename) {
      case 'Directory':
        return incrementDirectoryName(resource.id, selectedDirectoryId);
      case 'Document':
        return incrementDocumentName(resource.id, selectedDirectoryId);
    }
  };

  const renderDirectory = (directory) => {
    const isSelected = selectedDirectoryId === directory.id;

    return (
      <MoveModalDirectory
        key={`move-modal-directory-${directory.id}`}
        id={directory.id}
        isSelected={isSelected}
        onArrowClick={browseDirectory}
        hasChildren={directory.hasChildren}
        onClick={toggleSelectedDirectoryId}
        name={directory.name}
      />
    );
  };

  const breadcrumbs = useMemo(() => {
    if (currentDirectory && requestedDirectoryId) {
      return [ROOT_BREADCRUMB, ...currentDirectory.breadcrumbs];
    } else {
      return [ROOT_BREADCRUMB];
    }
  }, [requestedDirectoryId, currentDirectory]);

  const directories = useMemo(() => {
    if (currentDirectory && requestedDirectoryId) {
      return currentDirectory.directories;
    } else if (resource?.inSmartSignDirectory && rootDirectories) {
      return rootDirectories.filter((item) => item.name == 'SmartSign');
    } else if (rootDirectories) {
      return rootDirectories.filter((item) => item.capabilities.canCreate && item.name != 'SmartSign');
    } else {
      return [];
    }
  }, [resource, requestedDirectoryId, currentDirectory, rootDirectories]);

  const isMoving = !!(isMovingDirectory || isMovingDocument || isIncrementingDirectoryName);
  const renderedDirectories = directories && directories.map(renderDirectory);

  const contentStyles = [styles.content.base, moveError && styles.content.error];

  const title = (
    <div className='d-flex align-items-center'>
      <div className='m-r-5'>
        <FormattedMessage id='MoveModal.moveToFolder' />
      </div>
      <LoadingIndicator show={isMoving} />
    </div>
  );

  const resourceName = () => {
    switch (resource?.__typename) {
      case 'Directory':
        return resource?.name;
      case 'Document':
        return `${resource?.name}.${resource?.extension}`;
    }
  };

  const allowMove = selectedDirectoryId && resource?.parent?.id !== selectedDirectoryId;

  return (
    <>
      <AlertPopup
        show={showRenameAlert}
        popupType='info'
        headerText={intl.formatMessage({ id: 'MoveModal.confirmation' })}
        onClose={hideRenameAlert}
      >
        <div className='text-black fs-14 m-b-15'>
          <FormattedMessage id='MoveModal.confirmationText' values={{ name: <strong>'{resourceName()}'</strong> }} />
        </div>

        <button className='btn btn-info' onClick={incrementResourceName}>
          <FormattedMessage id='MoveModal.keep' />
        </button>

        <button className='btn btn-info-inverted m-l-10' onClick={hideRenameAlert}>
          <FormattedMessage id='MoveModal.cancel' />
        </button>
      </AlertPopup>

      <Modal title={title} onClose={onClose} onExited={resetModal} modalStyles={styles.modal} show={show} centered>
        <Breadcrumbs isLoading={false} items={breadcrumbs} onClick={browseDirectory} />

        <div css={styles.loading}>
          <ClipLoader color={colors.cyan} loading={isLoading} />
        </div>

        <div css={contentStyles}>
          <Scrollable>{!isLoading && renderedDirectories}</Scrollable>
        </div>

        <div css={styles.error}>{moveError}</div>

        <div className='d-flex align-items-center m-t-15'>
          <a
            onClick={handleMove}
            className={`btn btn-sm ${allowMove ? 'btn-primary' : 'btn-disabled'}`}
            disabled={!allowMove}
          >
            <FormattedMessage id='MoveModal.select' />
          </a>

          <a onClick={onClose} className='btn btn-sm btn-text brand-text m-l-5'>
            <FormattedMessage id='MoveModal.cancel' />
          </a>
        </div>
      </Modal>
    </>
  );
});

export { MoveModal };
