/** @jsx jsx */

import React, { memo, useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './DocumentViewer.styles';

const DocumentViewer = memo(({ title, source, onLoad }) => {
  const ref = useRef();
  const iframeUrl = `https://docs.google.com/gview?url=${source}&embedded=true`;

  const reloadIframe = () => {
    const iframe = ref.current;

    try {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      if (iframeDoc.readyState === 'complete') {
        iframe.setAttribute('src', iframeUrl);
        setTimeout(reloadIframe, 500);
      } else {
        setTimeout(reloadIframe, 100);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const timer = setTimeout(reloadIframe, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div css={styles.wrapper}>
      <iframe src={iframeUrl} title={title} onLoad={onLoad} width='100%' height='100%' ref={ref} css={styles.iframe} />
    </div>
  );
});

export { DocumentViewer };
