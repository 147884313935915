import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const userDateFormat = window.getUserDateFormat?.();

const DateFormatContext = createContext();

export const DateFormatContextProvider = ({ children }) => {
  return <DateFormatContext.Provider value={{ userDateFormat }}>{children}</DateFormatContext.Provider>;
};

DateFormatContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useDateFormatContext = () => useContext(DateFormatContext);

export const withDateFormatContext = (Component) => (props) =>
  (
    <DateFormatContextProvider>
      <Component />
    </DateFormatContextProvider>
  );
