/** @jsx jsx */
import React from 'react';
import { signatureFonts } from './types';
import { jsx } from '@emotion/core';
import { fonts } from 'styles';
import { SignatoryName, SignatoryWrapper } from './styles';

export const SignatureScript = ({ changeFontHandler, fontName, name, isActive }) => {
  const clickHandler = changeFontHandler(signatureFonts[fontName]);

  return (
    <SignatoryWrapper onClick={clickHandler} css={fonts[fontName]} isActive={isActive}>
      <SignatoryName className='text-truncate'>{name}</SignatoryName>
    </SignatoryWrapper>
  );
};
