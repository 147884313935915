import { css } from '@emotion/core';
import { tables, fonts, colors, media } from 'styles';

export const table = tables.table;
export const tableRow = tables.tableRow;

export const voterTable = css`
  overflow-y: auto;
  padding: 20px;
  max-height: 350px;
  background-color: ${colors.backgroundGrey};
  border-radius: 20px;
  margin-left: 60px;
  ${media.mobile} {
    margin-left: 0;
    background-color: ${colors.white};
  }
`;

export const votersTitle = css`
  ${media.mobile} {
    text-align: center;
  }
`;

export const tableHead = css`
  position: relative;
  &:before {
    position: absolute;
    content: '';
    height: 1px;
    width: calc(100% + 40px);
    bottom: -1px;
    left: -20px;
    background-color: ${colors.zircon};
    z-index: 80;
  }
`;

export const tableHeader = (width) => css`
  ${tables.tableHeader(width)}
`;

export const voterRow = css`
  margin: 5px 0;
`;

export const voteOption = css`
  width: 80px;
  margin: 5px;

  &.selected {
    ${fonts.semiBold};
    border: 1px solid ${colors.purple};
    border-radius: 44px;
    color: ${colors.purple};
    cursor: default;
  }
`;
