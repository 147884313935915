import { gql } from '@apollo/client';

const CONTACT_FRAGMENT = gql`
  fragment ContactFields on Contact {
    id
    name
    email
    initials
    avatarUrl
    accessRoleId
    isCurrentContact
    status
  }
`;

export { CONTACT_FRAGMENT };
