import { useState } from 'react';
import { useAttachResolutionDocumentMutation } from './../data/mutations/AttachResolutionDocumentMutation';

const useAttachResolutionDocument = ({ resolutionId, onCompleted }) => {
  const update = (cache, { data: { attachResolutionDocument } }) => {
    const { resolution } = attachResolutionDocument;

    onCompleted(resolution);
  };

  const [mutate, { loading: isAttaching }] = useAttachResolutionDocumentMutation({ update });

  const attachResolutionDocument = (documentIds) => {
    mutate({ variables: { input: { resolutionId: resolutionId, documentIds: documentIds } } });
  };

  return {
    isAttaching,
    attachResolutionDocument,
  };
};

export { useAttachResolutionDocument };
