import { css } from '@emotion/core';
import { panels, colors, fonts } from 'styles';

export const component = {
  base: css`
    font-size: 12px;
    color: ${colors.lightGrey};
    display: flex;
    align-items: baseline;
    position: relative;
  `,

  hoverable: css`
    cursor: pointer;
  `,
};

export const errorButton = css`
  ${fonts.bold}
  background: ${colors.red};
  color: ${colors.white};
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  line-height: 16px;
  white-space: pre-wrap;
  padding: 0;

  &:hover {
    opacity: 1;
    color: ${colors.white};
    cursor: auto;
  }
`;

export const dropdown = css`
  ${panels.base}
  padding: 12px 0;
  z-index: 3;
  box-shadow: 0px 1.2px 3.6px rgba(0, 20, 90, 0.1), 0px 6.4px 14.4px rgba(0, 50, 97, 0.13);
  width: 185px;
  position: absolute;
  top: 15px;
`;

export const padded = css`
  padding: 0 20px;
`;
