import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const cardBox = css`
  background: #fff;
  box-shadow: 0px 4px 50px rgba(54, 71, 98, 0.12);
  border-radius: 16px;
  padding: 20px;
  margin: 30px 0;
  position: relative;
`;

export const errorCardBox = css`
  background: #fff;
  box-shadow: 0px 4px 50px rgba(54, 71, 98, 0.12);
  border-radius: 16px;
  padding: 19px;
  position: relative;
  border: 1px solid #ff7a7a;
`;

export const couponBox = css`
  margin-top: 30px;
  background: #fff;
  box-shadow: 0px 4px 50px rgba(54, 71, 98, 0.12);
  border-radius: 16px;
  padding: 20px 20px 0px 20px;
  position: relative;
  height: 130px;

  input {
    border: 1px solid #f0eee9;
    padding: 10px;
    height: 35px;
  }

  .btn {
    height: 35px !important;
    line-height: 35px;
  }

  .success-text {
    color: ${colors.cyan};
  }
`;

export const errorCouponBox = css`
  margin-top: 20px;
  background: #fff;
  box-shadow: 0px 4px 50px rgba(54, 71, 98, 0.12);
  border-radius: 16px;
  padding: 19px 19px 0px 19px;
  position: relative;
  border: 1px solid ${colors.red};
  height: 130px;

  input {
    border: 1px solid ${colors.red};
    padding: 10px;
    height: 35px;
    color: ${colors.red};

    &:focus,
    &:active {
      color: ${colors.black};
    }
  }

  .btn {
    height: 35px !important;
    line-height: 35px;
  }

  .error-text {
    color: ${colors.red};
  }
`;

export const summaryBox = css`
  background: transparent;
  border: 1px solid #f0eee9;
  border-radius: 16px;
  font-size: 14px;
  position: relative;
  margin: 30px 0;
  h2 {
    border-bottom: 1px solid #f0eee9;
    padding: 20px;
  }
`;

export const summaryDetails = css`
  padding: 10px 20px;
  border-bottom: 1px solid #f0eee9;
  display: flex;
  flex-direction: column;
  p {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }
`;

export const total = css`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`;

export const billingInfo = css`
  border-left: 2px solid #2c2e3f;
  padding-left: 10px;
`;
