/** @jsx jsx */

import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { Drawer } from 'components/shared/Drawer';
import { Input } from 'components/shared/Input';
import { ContactPicker } from 'components/shared/ContactPicker';
import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';
import { GroupDrawerTable } from './../GroupDrawerTable';
import useWindowDimensions from 'hooks/useWindowDimensions';

import * as styles from './GroupDrawer.styles';

const GroupDrawer = ({ group, errors, onGroupChange, isOpen, onClose, onCreate, onUpdate, onDelete }) => {
  const applicableStyles = [styles.drawer.base, isOpen && styles.drawer.state.open];

  const intl = useIntl();

  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (isMobile) return;

    if (isOpen) {
      document.querySelector('body').classList.add('opaque-bg');
    } else {
      document.querySelector('body').classList.remove('opaque-bg');
    }
  }, [isOpen]);

  const { id, name, contacts } = group;

  const drawerHeader = () => {
    if (group.id) {
      return intl.formatMessage({ id: 'GroupDrawer.editGroup' });
    } else {
      return intl.formatMessage({ id: 'GroupDrawer.createGroup' });
    }
  };

  const renderDrawerButtons = () => {
    if (group.id) {
      return (
        <div className='m-t-15 d-flex align-items-center'>
          <a className='btn btn-primary m-r-10' onClick={onUpdate}>
            <FormattedMessage id='GroupDrawer.update' />
          </a>
          <ButtonWithConfirmation
            confirmationText={intl.formatMessage({ id: 'GroupDrawer.deleteConfirm' })}
            onConfirm={onDelete}
            button={
              <a className='btn btn-secondary'>
                <FormattedMessage id='GroupDrawer.delete' />
              </a>
            }
          />
        </div>
      );
    } else {
      return (
        <a className='m-t-15 btn btn-primary' onClick={onCreate}>
          <FormattedMessage id='GroupDrawer.create' />
        </a>
      );
    }
  };

  const contactGid = (contact) => contact.gid || contact.id;
  const handleNameChange = (e) => onGroupChange({ name: e.target.value || '' });
  const isContactSelected = (contact) => contacts.find((item) => contactGid(item) === contactGid(contact));
  const addContact = (contact) => onGroupChange({ contacts: [...contacts, contact] });
  const removeContact = (contact) => {
    return onGroupChange({ contacts: contacts.filter((item) => contactGid(item) !== contactGid(contact)) });
  };
  const errorMessage = (field) => errors.find((item) => item.attribute === field)?.message;

  const handleContactToggle = (contact) => {
    if (isContactSelected(contact)) {
      removeContact(contact);
    } else {
      addContact(contact);
    }
  };

  return (
    <Drawer drawerId='shaparency-groups-drawer' styles={applicableStyles} onClose={onClose}>
      <div className='d-md-none p-15 b-b-grey d-flex align-items-center'>
        <i className='fa fa-chevron-left fw-300 text-black fs-18 cursor-pointer' onClick={onClose} />
        <div className='m-auto font-weight-semibold text-black fs-18'>{drawerHeader()}</div>
      </div>

      <div css={styles.wrapper}>
        <div css={styles.header}>{drawerHeader()}</div>

        <div className='m-t-25 col-12 col-md-6 pl-0 pr-0'>
          <Input
            name='name'
            errorMessage={errorMessage('name')}
            label='Group name'
            value={name}
            onChange={handleNameChange}
            placeholder='Group name'
          />
        </div>

        <div css={styles.line} />

        <div className='col-12 col-md-6 pl-0 pr-0'>
          <ContactPicker
            inputLabel={intl.formatMessage({ id: 'GroupDrawer.addContacts' })}
            dropdownIsOpen={false}
            values={contacts}
            isSelected={isContactSelected}
            onToggleOption={handleContactToggle}
          />
        </div>

        <div className='m-t-30 fs-12 font-weight-semibold text-black text-left text-nowrap'>
          <FormattedMessage id='GroupDrawer.addedContacts' values={{ count: contacts.length }} />
        </div>

        <div css={styles.content}>
          <GroupDrawerTable contacts={contacts} onDelete={removeContact} />
        </div>

        {renderDrawerButtons()}
      </div>
    </Drawer>
  );
};

export { GroupDrawer };
