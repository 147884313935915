/** @jsx jsx */

import React, { useEffect, useMemo, useState } from 'react';
import { jsx } from '@emotion/core';
import { Label } from 'components/shared/Input';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { getAxiosHeaders } from 'utils/getHeaders';

import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { ReactComponent as LockIcon } from 'images/icons/lock-icon.svg';

import * as styles from './AddForm.styles';

import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';

const AddForm = ({ iconsMap, company, setFlash, handleError, onAdd, onDelete }) => {
  const [brand, setBrand] = useState(null);
  const [isPrimary, setIsPrimary] = useState(true);

  const { isMobile } = useWindowDimensions();

  const intl = useIntl();

  useEffect(() => {
    document.getElementById('add-new-payment-method').scrollIntoView();
  }, []);

  const useOptions = () => {
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize: '14px',
            fontFamily: 'Gilroy-Regular',
            color: '#424770',
            letterSpacing: '0.025em',

            '::placeholder': {
              color: '#aab7c4',
            },
          },

          invalid: {
            color: '#9e2146',
          },
        },
      }),
      []
    );

    return options;
  };

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handlePrimaryChange = (event) => {
    setIsPrimary(event.target.checked);
  };

  const handleCardNumberChange = (payload) => {
    setBrand(payload.brand);
  };

  const savePaymentMethod = (paymentMethod) => {
    const payload = {
      payment_method: paymentMethod.id,
      is_primary: isPrimary,
    };

    trackPromise(
      axios.post(`/${company.slug}/payment_methods/`, payload, getAxiosHeaders()).then(onAdd).catch(handleError)
    );
  };

  const handleAdd = () => {
    const cardElement = elements.getElement(CardNumberElement);

    const handleResult = (result) => {
      if (result.error) {
        setFlash(result.error.message);
      } else {
        savePaymentMethod(result.paymentMethod);
      }
    };

    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      .then(handleResult);
  };

  return (
    <Row noGutters className='flex-auto p-10' id='add-new-payment-method'>
      <Col md={6}>
        <div className='d-flex justify-content-between m-b-25 flex-wrap'>
          <label className='fs-18 font-weight-bold text-black m-b-0'>
            <FormattedMessage id='AddForm.ccOrDebit' />
          </label>

          <div className={`d-flex align-items-center ${isMobile && 'w-100'}`}>
            <LockIcon className='m-r-5' />

            <label className='text-grey fs-12 font-weight-regular m-b-0'>
              <FormattedMessage id='AddForm.dataSecured' />
            </label>
          </div>
        </div>

        <div>
          <div>
            <Label text={intl.formatMessage({ id: 'AddForm.cardNumber' })} />

            <div className='d-flex align-items-center'>
              <div css={styles.number}>
                <CardNumberElement options={options} onChange={handleCardNumberChange} />
              </div>

              <div css={styles.icon}>{iconsMap[brand] && <img css={styles.image} src={iconsMap[brand]} />}</div>
            </div>
          </div>

          <div className='d-flex m-t-15'>
            <div className='col-md-6 p-l-0'>
              <Label text={intl.formatMessage({ id: 'AddForm.expDate' })} />

              <div css={styles.wrapper}>
                <CardExpiryElement options={options} />
              </div>
            </div>

            <div className='col-md-6 p-r-0'>
              <Label text={intl.formatMessage({ id: 'AddForm.cvc' })} />

              <div css={styles.wrapper}>
                <CardCvcElement options={options} />
              </div>
            </div>
          </div>

          <div className='checkbox checkbox-primary m-l-5 m-t-20'>
            <input type='checkbox' id='is-primary' onChange={handlePrimaryChange} checked={isPrimary} />

            <label className='text-black fs-14' htmlFor='is-primary'>
              <FormattedMessage id='AddForm.primaryCard' />
            </label>
          </div>

          <div className='d-flex m-t-20 m-b-10'>
            <button onClick={handleAdd} className='btn btn-primary btn-sm-fw' disabled={!stripe}>
              {isMobile
                ? intl.formatMessage({ id: 'AddForm.save' })
                : intl.formatMessage({ id: 'AddForm.savePayment' })}
            </button>

            <button onClick={onDelete} className='btn btn-light-red btn-light-red-inverted btn-sm-fw m-l-5 d-md-none'>
              <FormattedMessage id='AddForm.cancel' />
            </button>

            <button onClick={onDelete} className='btn btn-red border-0 d-none d-md-inline-block'>
              <div className='d-flex align-items-center'>
                <i className='far fa-trash-alt m-r-5 fs-14' />
                <span>
                  <FormattedMessage id='AddForm.cancel' />
                </span>
              </div>
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AddForm;
