import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const Variants = {
  Gray: 'gray',
  Green: 'green',
  Teal: 'teal',
  Black: 'black',
  Faint: 'faint',
  Orange: 'orange',
  Purple: 'purple',
  Red: 'red',
  Chair: 'chair',
  Admin: 'admin',
};

const StateBadge = ({ variant = Variants.Gray, alignEnd = false, children, className }) => (
  <div
    className={clsx(
      'tw-inline-flex tw-items-center tw-justify-center',
      'tw-flex tw-flex-col tw-justify-center tw-box-border tw-min-w-[75px]',
      'tw-text-xs tw-font-medium',
      'tw-rounded-[4px] tw-py-2 tw-px-2 tw-leading-none',
      className,
      {
        'tw-bg-[#E7EAEF] tw-text-black': variant === Variants.Gray,
        'tw-bg-green-300 tw-text-teal-700': variant === Variants.Green,
        'tw-bg-teal-200 tw-text-teal-500': variant === Variants.Teal,
        'tw-bg-gray-200 tw-text-black': variant === Variants.Black,
        'tw-bg-gray-200 tw-text-gray-700': variant === Variants.Faint,
        'tw-bg-orange-200 tw-text-orange-600': variant === Variants.Orange,
        'tw-bg-purple-200 tw-text-purple-500': variant === Variants.Purple,
        'tw-bg-red-100 tw-text-red-400': variant === Variants.Red,
        'tw-bg-orange-200 tw-text-black': variant === Variants.Chair,
        'tw-bg-purple-200 tw-text-black': variant === Variants.Admin,
      },
      {
        'tw-mx-0': alignEnd === true,
        'tw-mx-auto': alignEnd === false,
      },
    )}
  >
    {children}
  </div>
);

StateBadge.propTypes = {
  variant: PropTypes.oneOf(Object.values(Variants)),
  children: PropTypes.node,
};

export default StateBadge;
