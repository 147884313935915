import { gql } from '@apollo/client';

const DOCUMENT_FRAGMENT = gql`
  fragment DocumentFields on Document {
    id
    name
    extension
    url
    contentType
  }
`;

export { DOCUMENT_FRAGMENT };
