import { gql, useQuery } from '@apollo/client';

const SELECT_DOCUMENT_MODAL_QUERY = gql`
  query SelectDocumentModalQuery(
    $parentId: ID
    $parentType: String
    $sortDirection: SortDirectionEnum
    $first: Int
    $after: String
    $searchTerm: String
  ) {
    currentCompany {
      id
      rootDirectories {
        id
        name
        capabilities {
          canCreate
        }
      }
      searchableEntries(
        first: $first
        after: $after
        parentId: $parentId
        parentType: $parentType
        sortDirection: $sortDirection
        searchTerm: $searchTerm
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ... on Directory {
              id
              name
              capabilities {
                canCreate
              }
            }
            ... on Document {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const useSelectDocumentModalQuery = (options) => useQuery(SELECT_DOCUMENT_MODAL_QUERY, options);

export { SELECT_DOCUMENT_MODAL_QUERY, useSelectDocumentModalQuery };
