import { gql, useQuery } from '@apollo/client';
import { COMPANY_FRAGMENT } from './../fragments/CompanyFragment';

const COMPANY_QUERY = gql`
  query company {
    currentCompany {
      ...CompanyFields
    }
  }
  ${COMPANY_FRAGMENT}
`;

const useCompanyQuery = (options) => useQuery(COMPANY_QUERY, options);

export { useCompanyQuery };
