import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const container = {
  base: css`
    z-index: 3;
    position: absolute;
    width: 400px;
    height: 245px;
    bottom: 24px;
    right: 24px;
    background: ${colors.white};
    box-shadow: 0px 3px 20px rgba(16, 8, 63, 0.11);
    border-radius: 7px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    ${media.mobile} {
      position: fixed;
      width: 100%;
      bottom: 0;
      right: 0;
    }
  `,

  minimized: css`
    height: auto;
  `,
};
