/** @jsx jsx */
import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { Placeholder } from 'components/shared/Placeholder';

import { ReactComponent as PlaceholderIcon } from 'images/icons/documents/search-placeholder.svg';

import * as styles from './SearchPlaceholder.styles';

const SearchPlaceholder = memo(({ show, heading, subHeading }) => {
  const message = (
    <>
      <div css={styles.heading}>{heading}</div>

      <div css={styles.subHeading}>{subHeading}</div>
    </>
  );

  return (
    show && (
      <div css={styles.container}>
        <Placeholder image={<PlaceholderIcon />} message={message} />
      </div>
    )
  );
});

export { SearchPlaceholder };
