import { gql, useMutation } from '@apollo/client';
import { CONTACT_FRAGMENT } from 'data/v2/fragments/ContactFragment';

const UPDATE_CONTACT_ROLE_MUTATION = gql`
  mutation UpdateContactRole($input: UpdateContactRoleInput!) {
    updateContactRole(input: $input) {
      contact {
        ...ContactFields
      }
      errors {
        attribute
        message
      }
    }
  }
  ${CONTACT_FRAGMENT}
`;

const useUpdateContactRoleMutation = (options) => useMutation(UPDATE_CONTACT_ROLE_MUTATION, options);

export { UPDATE_CONTACT_ROLE_MUTATION, useUpdateContactRoleMutation };
