/** @jsx jsx */
import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';

import { ReactComponent as DocumentIcon } from 'images/icons/agenda-document.svg';

import * as styles from './ResolutionDocumentListItem.styles';
import { icons } from 'styles';

const ResolutionDocumentListItem = memo(({ resolutionDocument, onDocumentClick, onDelete }) => {
  const document = resolutionDocument.document;
  const documentFullName = `${document.name}.${document.extension}`;

  const openPreview = () => onDocumentClick(document);
  const handleDeleteDocument = () => onDelete(resolutionDocument.id);

  return (
    <div css={styles.listItem} className='d-flex align-items-end justify-content-between m-b-5'>
      <div className='d-flex align-items-center w-percent-90' onClick={openPreview}>
        <DocumentIcon />

        <span className='m-l-10 fs-14 text-black text-truncate w-100'>{documentFullName}</span>
      </div>

      <i onClick={handleDeleteDocument} css={icons.base} className='far fa-trash-alt' />
    </div>
  );
});

export { ResolutionDocumentListItem };
