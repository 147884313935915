/** @jsx jsx */

import React, { memo, useMemo, useState, useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';
import { Container, Row, Col } from 'react-bootstrap';
import * as ActionCable from '@rails/actioncable';
import { FormattedMessage, useIntl } from 'react-intl';

import { StatusBadge } from 'components/shared/StatusBadge';
import { MeetingDetails } from './MeetingDetails';
import { AgendaTasksTabs } from './AgendaTasksTabs';
import { Spinner } from 'components/shared/Spinner';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import { useMeetingActions, useMeetingStore } from 'store/meetingStoreContext';
import { useFlashActions, useFlashStore } from 'store/flashStoreContext';
import { getMeetingTypeOptions } from 'helpers/meetingTypes';
import * as styles from './MeetingSpace.styles';

export default memo(
  ({
    meetingId,
    meetingTypes,
    timezoneOptions,
    adminView,
    root,
    currentContactId,
    currentContactName,
    contactTimezone,
    contactTimezoneOffset,
  }) => {
    const intl = useIntl();

    const meetingTypeOptions = useMemo(() => {
      return getMeetingTypeOptions(meetingTypes);
    }, []);

    const { flash } = useFlashStore();
    const { setFlash } = useFlashActions();
    const { meeting, loaded, edits } = useMeetingStore();
    const { arrangeContext, loadMeeting, updateMeeting, setErrors, discardEdits } = useMeetingActions();
    const { isPseudo } = useMeetingStatus(meeting.status);

    const { current: channel } = useRef({
      channel: 'MeetingsChannel',
      meeting_id: meetingId,
    });

    useEffect(() => {
      loadMeeting(meetingId).catch(setFlash);
    }, []);

    const handleCableUpdate = (response) => {
      arrangeContext(meetingId, response);
    };

    useEffect(() => {
      let cable = ActionCable.createConsumer();
      let subscription = cable.subscriptions.create(channel, {
        received: handleCableUpdate,
      });

      return () => {
        cable.subscriptions.remove(subscription);
      };
    }, []);

    if (!loaded) {
      return <Spinner show />;
    }

    const showNotPublishedMessage = !adminView && meeting.status == 'scheduled';

    return (
      <Container fluid='lg'>
        <Spinner />
        <StatusBadge status={meeting.status} text={intl.formatMessage({ id: 'MeetingSpace.meeting' })} />

        <Row noGutters>
          <Col>
            <MeetingDetails
              adminView={adminView}
              currentContactId={currentContactId}
              currentContactName={currentContactName}
              contactTimezone={contactTimezone}
              contactTimezoneOffset={contactTimezoneOffset}
            />

            {!showNotPublishedMessage && (
              <div css={styles.panel} className='m-t-20 p-t-0'>
                <AgendaTasksTabs
                  meetingId={meetingId}
                  contacts={meeting.contacts}
                  adminView={adminView}
                  meetingStatus={meeting.status}
                  root={root}
                  currentContactId={currentContactId}
                />
              </div>
            )}

            {showNotPublishedMessage && (
              <div css={styles.panel} className='m-t-20 p-t-0 h-100'>
                <p className='text-black fs-16 font-weight-bold p-35'>
                  <FormattedMessage id='MeetingSpace.materialNotPublished' />
                </p>
              </div>
            )}
          </Col>
        </Row>

        <div id='document-preview-modal' className='modal preview-mode' />
      </Container>
    );
  }
);
