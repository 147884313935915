import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Switch as HeadlessSwitch } from '@headlessui/react';

export const Variants = {
  Purple: 'purple',
  PurpleBG: 'purple-bg',
  Teal: 'teal',
  TealBG: 'teal-bg',
  Gray: 'gray',
  GrayBG: 'gray-bg',
};
const Switch = ({
  name,
  labelBefore,
  labelAfter,
  value,
  isChecked,
  isDisabled,
  onChange,
  status,
  isGray,
  variant = Variants.Purple,
}) => (
  <HeadlessSwitch.Group>
    <div
      className={clsx('tw-flex tw-items-center', {
        'tw-cursor-pointer': !isDisabled,
        'tw-cursor-default tw-opacity-70': isDisabled,
      })}
    >
      <HeadlessSwitch.Label
        className={clsx(
          'tw-cursor-inherit',
          'tw-text-sm tw-font-medium tw-leading-none tw-text-black',
          'tw-pr-4 empty:tw-p-0',
          {
            'tw-opacity-30': labelAfter && isChecked && !isDisabled,
            'tw-mt-0.5': variant === Variants.Purple,
            'tw-m-0 tw-font-sans': variant !== Variants.Purple,
          }
        )}
      >
        {labelBefore}
      </HeadlessSwitch.Label>
      <HeadlessSwitch.Label
        className={clsx(
          'tw-cursor-inherit',
          'tw-text-sm tw-font-medium tw-leading-none tw-text-black',
          'tw-pr-4 empty:tw-p-0',
          {
            'tw-opacity-30': labelBefore && !isChecked && !isDisabled,
            'tw-mt-0.5': variant === Variants.Purple,
            'tw-m-0 tw-font-sans': variant !== Variants.Purple,
          }
        )}
      >
        {labelAfter}
      </HeadlessSwitch.Label>
      <HeadlessSwitch
        name={name}
        value={value}
        checked={isChecked}
        disabled={isDisabled}
        onClick={(e) => e.stopPropagation()}
        onChange={onChange}
        className={clsx(
          'tw-inline-flex tw-h-6 tw-w-11 tw-items-center',
          'tw-cursor-inherit tw-rounded-full focus:tw-outline-none',
          {
            'tw-border-none tw-bg-purple-100': variant === Variants.Purple,
            'tw-border-solid tw-border-teal-600 tw-bg-teal-200': variant === Variants.Teal,
            'tw-border-solid tw-border-gray-700 tw-bg-gray-100': variant === Variants.Gray,
            'tw-border-none tw-bg-purple-500/75': variant === Variants.PurpleBG,
            'tw-border tw-border-solid tw-border-teal-400 tw-bg-teal-200': variant === Variants.Teal,
            'tw-border-none tw-bg-teal-500': variant === Variants.TealBG,
            'tw-border tw-border-solid tw-border-gray-700 tw-bg-gray-100': variant === Variants.Gray,
            'tw-border-none tw-bg-gray-500': variant === Variants.GrayBG,
          }
        )}
      >
        <span
          className={clsx(
            'tw-inline-block tw-h-4 tw-w-4 tw-rounded-full',
            'tw-transition-transform tw-duration-200 tw-ease-in-out',
            {
              'tw-translate-x-4': isChecked,
              'tw-translate-x-0': !isChecked,
              'tw-bg-gray-700': variant === Variants.Gray || variant === Variants.GrayBG,
              'tw-bg-purple-600': variant === Variants.Purple,
              'tw-bg-teal-600': variant === Variants.Teal,
              'tw-bg-purple-600': variant === Variants.Purple || variant === Variants.PurpleBG,
              'tw-bg-teal-500': variant === Variants.Teal,
              'tw-bg-teal-700': variant === Variants.TealBG,
            }
          )}
        />
      </HeadlessSwitch>
    </div>
  </HeadlessSwitch.Group>
);
Switch.propTypes = {
  name: PropTypes.string,
  labelBefore: PropTypes.string,
  labelAfter: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(Object.values(Variants)),
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isGray: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Switch;
