import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ActivityFeedItem from 'components/v2/shared/ActivityFeedItem';

const ActivityFeedCircle = () => (
  <div
    className={clsx(
      'tw-flex tw-shrink-0 tw-items-center tw-justify-center',
      'tw-mt-2 tw-rounded-full tw-bg-purple-100',
      'tw-h-4 tw-w-4'
    )}
  >
    <div className='tw-flex tw-h-3 tw-w-3 tw-items-center tw-justify-center tw-rounded-full tw-bg-purple-500'>
      <div className='tw-h-1 tw-w-1 tw-rounded-full tw-bg-white' />
    </div>
  </div>
);

ActivityFeedCircle.propTypes = {};

const ActivityFeed = ({ items }) => (
  <div className='tw-relative'>
    <div
      className={clsx(
        'tw-absolute tw-bottom-0 tw-left-2 -tw-z-10',
        'tw-h-full tw-w-px',
        'tw-border-0 tw-border-l tw-border-dashed tw-border-purple-100'
      )}
    />

    <div className='tw-flex tw-flex-col tw-gap-2'>
      {items.map((item) => (
        <div key={item.id} className='tw-flex tw-items-start tw-gap-4'>
          <ActivityFeedCircle />
          <ActivityFeedItem {...item} />
        </div>
      ))}
    </div>
  </div>
);

ActivityFeed.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      ...ActivityFeedItem.propTypes,
    })
  ),
};

export default ActivityFeed;
