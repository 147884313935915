/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { ReactComponent as DirectoryIcon } from 'images/icons/documents/directory-icon-large.svg';
import { ReactComponent as ArrowIcon } from 'images/icons/documents/move-arrow-right.svg';

import * as styles from './MoveModalDirectory.styles';

const MoveModalDirectory = memo(({ id, name, hasChildren, isSelected, onArrowClick, onClick }) => {
  const handleClick = onClick.bind(this, id);

  const handleArrowClick = (event) => {
    event.stopPropagation();
    onArrowClick(id);
  };

  const containerStyles = [styles.container.base, isSelected && styles.container.selected];

  const arrowIcon = hasChildren && (
    <div css={styles.arrowContainer} onClick={handleArrowClick} className='arrow-icon'>
      <ArrowIcon />
    </div>
  );

  return (
    <div css={containerStyles} onClick={handleClick}>
      <div className='d-flex align-items-end'>
        <DirectoryIcon className='directory__icon' />

        <div className='directory__name text-truncate'>{name}</div>
      </div>

      {arrowIcon}
    </div>
  );
});

export { MoveModalDirectory };
