/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import * as styles from './Badge.styles';

export const Colors = {
  Green: 'green',
  Pink: 'pink',
  Grey: 'grey',
};

const Badge = ({ color = Colors.Grey, children, className }) => (
  <div css={styles.container} className={className}>
    {children}
    <div css={[styles.badge.base, styles.badge[color]]} />
  </div>
);

Badge.propTypes = {
  color: PropTypes.oneOf(Object.values(Colors)),
  className: PropTypes.string,
};

export default Badge;
