import React, { useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import { useDebounce } from 'use-debounce';
import { FormattedMessage } from 'react-intl';

import Spinner from 'components/v2/shared/Spinner/Spinner';
import useCurrentUserCompanies from '../../../hooks/useCurrentUserCompanies';
import { withApollo } from 'utils/v2/apollo';
import { withDateFormatContext } from 'contexts/v2/DateFormatContext';
import { withIntl } from 'utils/v2/intl';
import { Search } from 'components/shared/Search';
import CompanyItem from '../CompanyItem/CompanyItem';

const CompanyList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedTerm] = useDebounce(searchTerm, 500);

  const { data, loading, archiveCompanyContact, closeCompany, setCompanyAsPrimary, fetchMoreCompanies } =
    useCurrentUserCompanies({ skipFetch: false, name: debouncedTerm, context: 'CompanyList' });

  const handleSearch = (e) => setSearchTerm(e.target.value);

  if (!data && loading) {
    return (
      <div className='tw-relative tw-p-5'>
        <Spinner />
      </div>
    );
  }

  const {
    currentUser: {
      companies: { pageInfo, edges: companies },
    },
  } = data;

  const handleArchiveCompany = async (companyId) => {
    await archiveCompanyContact(companyId);
  };

  const handleCloseCompany = async (companyId) => {
    await closeCompany(companyId);
    window.location.assign(`/companies/new`);
  };

  const handleSetAsPrimary = async (companyId) => {
    await setCompanyAsPrimary(companyId);
    window.location.reload();
  };

  const handleInViewChange = (inView) => {
    if (inView && pageInfo.hasNextPage && !loading) {
      fetchMoreCompanies(pageInfo.endCursor);
    }
  };

  const renderCompanies = () => {
    if (!companies.length) {
      return (
        <div className='tw-p-5 tw-text-center tw-text-lg'>
          <FormattedMessage id='CompanyList.noFound' />
        </div>
      );
    }

    return companies.map((company) => (
      <CompanyItem
        key={company.node.id}
        company={company.node}
        onRemoveClick={handleArchiveCompany}
        onCloseClick={handleCloseCompany}
        onSetAsPrimaryClick={handleSetAsPrimary}
      />
    ));
  };

  return (
    <div>
      <div className='tw-mb-5 tw-w-full lg:tw-w-1/4'>
        <Search onChange={handleSearch} value={searchTerm} />
      </div>

      {renderCompanies()}

      {pageInfo.hasNextPage && (
        <InView as='div' onChange={handleInViewChange} className='tw-relative tw-p-5'>
          <Spinner />
        </InView>
      )}
    </div>
  );
};

export default withIntl(withApollo(withDateFormatContext(CompanyList)));
