import styled from 'styled-components';
import { colors, fonts } from 'styles';

export const TimeWrapper = styled.div`
  padding: 10px 20px;
  background-color: ${colors.white};
  ${({ isdesktop }) => isdesktop && `width: 210px`};
  ${({ isdesktop }) => !isdesktop && `flex: 1`};
  border: 1px solid ${colors.cyan};

  .far {
    color: ${colors.cyan};
    position: relative;
    top: -1px;
    font-size: 16px;
    margin-right: 3px;
  }

  &.start-wrapper {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &.end-wrapper {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;
  }
`;
