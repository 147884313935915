import { css } from '@emotion/core';
import { fonts, colors } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const wrapper = css`
  background: ${colors.lightCyan};
  border-radius: 2px;
  display: flex;
  margin: 15px 0;
  align-items: flex-end;
  padding: 12px 20px;
`;

export const message = {
  primary: css`
    ${fonts.bold}
    font-size: 12px;
    color: ${colors.cyan};
  `,

  secondary: css`
    ${fonts.regular}
    margin-left: 1ch;
    font-size: 12px;
    color: ${colors.mediumGrey};
  `,
};
