/** @jsx jsx */
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import { jsx } from '@emotion/core';
import SignaturePad from 'signature_pad';
import Dropzone from 'react-dropzone';
import canvasTxt from 'canvas-txt';
import 'blueimp-canvas-to-blob';
import { FormattedMessage, useIntl } from 'react-intl';

import { SignatureScript } from './SignatureScript';
import { ReactComponent as Upload } from 'images/icons/doc-upload.svg';
import { TabWrapper, StyledTab, StyledClear } from './styles';
import * as styles from './index.styles';
import { signatureTypes, signatureFonts } from './types';

const SignatureInputInMobile = ({ name, existingSignatureImageUrl, onSave }, ref) => {
  const intl = useIntl();

  useImperativeHandle(ref, () => ({
    processInput: (handler) => {
      if (isCanvasEmpty()) {
        return setError(intl.formatMessage({ id: 'SignatureInputInMobile.error' }));
      }

      canvas.current.toBlob(handler);
    },
    resetControl: () => {
      resetControl();
    },
    setError: (error) => {
      setError(error);
    },
  }));

  const canvas = useRef();
  const defaultType = existingSignatureImageUrl ? signatureTypes.saved : signatureTypes.drawing;

  const [activeFont, setActiveFont] = useState(signatureFonts.bethEllen);
  const [signatureType, setSignatureType] = useState(defaultType);
  const [ratioModifier, setRatioModifier] = useState(0);
  const [pad, setPad] = useState();
  const [image, setImage] = useState();
  const [error, setError] = useState();

  const resetControl = () => {
    setActiveFont(signatureFonts.bethEllen);
    setSignatureType(signatureTypes.drawing);
    setImage(null);
    setError(null);
    setRatioModifier(0);
  };

  const isActiveSignatureType = (type) => {
    return type === signatureType;
  };

  const toggleSignatureType = (value) => {
    setSignatureType(value);
    setRatioModifier(0);
  };

  useEffect(() => {
    if (signatureType && canvas.current) {
      return changeSignatureType(signatureType);
    }
  }, [ratioModifier, activeFont, signatureType, image]);

  const onSavedClick = useCallback(() => {
    toggleSignatureType(signatureTypes.saved);
  }, []);
  const onDrawerClick = useCallback(() => {
    toggleSignatureType(signatureTypes.drawing);
  }, []);
  const onScriptClick = useCallback(() => {
    toggleSignatureType(signatureTypes.script);
  }, []);
  const onUploadClick = useCallback(() => {
    toggleSignatureType(signatureTypes.upload);
  }, []);

  const changeSignatureType = (type) => {
    const context = canvas.current.getContext('2d');
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    context.clearRect(0, 0, canvas.current.width, canvas.current.height);

    canvas.current.width = canvas.current.offsetWidth * ratio;
    canvas.current.height = canvas.current.offsetHeight * ratio;

    context.scale(ratio, ratio);

    if (pad) {
      pad.off();
    }

    if (type === signatureTypes.saved && existingSignatureImageUrl) {
      return insertExistingSignatureImage(context, ratio);
    } else if (type === signatureTypes.drawing) {
      return setPad(new SignaturePad(canvas.current, { throttle: 0 }));
    } else if (type === signatureTypes.script) {
      return insertNameIntoCanvas(context, ratio);
    } else if (type === signatureTypes.upload && image) {
      return insertImageIntoCanvas(context, ratio, image);
    }
  };

  const insertExistingSignatureImage = (context, ratio) => {
    const existingImage = new Image();
    existingImage.src = existingSignatureImageUrl;
    existingImage.crossOrigin = 'Anonymous';
    existingImage.onload = () => insertImageIntoCanvas(context, ratio, existingImage);
  };

  const insertImageIntoCanvas = (context, ratio, image) => {
    const scale = 1 / ratio;

    const imgScale = Math.min(
      (scale * canvas.current.width) / image.width,
      (scale * canvas.current.height) / image.height
    );

    const imgW = image.width * (imgScale + ratioModifier / 100);
    const imgH = image.height * (imgScale + ratioModifier / 100);

    const cenX = (scale * canvas.current.width - imgW) / 2;
    const cenY = (scale * canvas.current.height - imgH) / 2;

    context.drawImage(image, cenX, cenY, imgW, imgH);

    const pixels = context.getImageData(0, 0, canvas.current.width, canvas.current.height);

    convertToGrayscaleAndRemoveBackground(pixels);

    context.putImageData(pixels, 0, 0, 0, 0, pixels.width, pixels.height);
  };

  const convertToGrayscaleAndRemoveBackground = (pixels) => {
    for (let y = 0; y < pixels.height; y++) {
      for (let x = 0; x < pixels.width; x++) {
        let data = pixels.data;

        // Grayscale
        const index = y * 4 * pixels.width + x * 4;
        const average = (data[index] + data[index + 1] + data[index + 2]) / 3;

        data[index] = average;
        data[index + 1] = average;
        data[index + 2] = average;

        // Remove background
        if (data[index] >= 160 && data[index + 1] >= 160 && data[index + 2] >= 160) {
          data[index] = 0;
          data[index + 1] = 0;
          data[index + 2] = 0;
          data[index + 3] = 0;
        }
      }
    }
  };

  const insertNameIntoCanvas = (context, ratio) => {
    if (name) {
      const fontSize = 50 + 2 * ratioModifier;
      const scale = 1 / ratio;
      const x = 0;
      const y = activeFont === signatureFonts.dawning ? -20 : -10;

      canvasTxt.font = activeFont;
      canvasTxt.fontSize = fontSize;
      canvasTxt.lineHeight = fontSize;

      canvasTxt.drawText(context, name, x, y, scale * canvas.current.width, scale * canvas.current.height);
    }
  };

  const clearCanvas = () => {
    const context = canvas.current.getContext('2d');

    if (signatureType === signatureTypes.drawing) {
      context.clearRect(0, 0, canvas.current.width, canvas.current.height);
    } else if (signatureType === signatureTypes.upload) {
      setImage(null);
    }
  };

  const changeFontHandler = (font) => {
    return isActiveSignatureType(signatureTypes.script) ? setActiveFont.bind(this, font) : null;
  };

  const maxRatioModifier = 18;
  const minRatioModifier = -18;

  const isMinRatioModifier = ratioModifier === minRatioModifier;
  const isMaxRatioModifier = ratioModifier === maxRatioModifier;

  const scaleUp = () => {
    if (isMaxRatioModifier) return;

    setRatioModifier(ratioModifier + 1);
  };

  const scaleDown = () => {
    if (isMinRatioModifier) return;

    setRatioModifier(ratioModifier - 1);
  };

  const handleDropzoneDrop = (acceptedFiles) => {
    const upload = acceptedFiles[0];
    const image = new Image();

    image.src = URL.createObjectURL(upload);
    image.onload = () => setImage(image);
  };

  const isCanvasEmpty = () => {
    const context = canvas.current.getContext('2d');

    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.current.width, canvas.current.height).data.buffer
    );

    return !pixelBuffer.some((color) => color !== 0);
  };

  const dropZone = (
    <div css={styles.dropzone} className='position-absolute w-100 d-flex align-items-center justify-content-center'>
      <Dropzone accept={['image/jpg', 'image/jpeg', 'image/png']} onDrop={handleDropzoneDrop}>
        {({ getRootProps, getInputProps }) => (
          <div css={styles.dropzone} {...getRootProps()}>
            <input {...getInputProps()} />

            <div className='d-flex align-items-center justify-content-center fs-12 lh-16'>
              <Upload className='m-0' />
              <span className='p-l-15 text-black'>
                <FormattedMessage id='SignatureInputInMobile.browse' />
              </span>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );

  return (
    <>
      {error && (
        <div className='w-100 p-10' css={styles.error}>
          {error}
        </div>
      )}
      <div className='p-10'>
        <TabWrapper>
          {existingSignatureImageUrl && (
            <StyledTab
              index={0}
              hasborderright
              onClick={onSavedClick}
              active={isActiveSignatureType(signatureTypes.saved)}
            >
              <FormattedMessage id='SignatureInputInMobile.saved' />
            </StyledTab>
          )}
          <StyledTab
            index={existingSignatureImageUrl ? 1 : 0}
            hasborderright
            onClick={onDrawerClick}
            active={isActiveSignatureType(signatureTypes.drawing)}
          >
            <FormattedMessage id='SignatureInputInMobile.draw' />
          </StyledTab>
          <StyledTab
            index={2}
            hasborderright
            onClick={onScriptClick}
            active={isActiveSignatureType(signatureTypes.script)}
          >
            <FormattedMessage id='SignatureInputInMobile.useScript' />
          </StyledTab>
          <StyledTab index={3} onClick={onUploadClick} active={isActiveSignatureType(signatureTypes.upload)}>
            <FormattedMessage id='SignatureInputInMobile.upload' />
          </StyledTab>
        </TabWrapper>
      </div>
      <div className='p-10 m-b-10'>
        <input
          css={styles.disabledInput}
          disabled={true}
          type='text'
          value={name || ''}
          className='form-control w-100 '
        />
      </div>
      <div className='p-10'>
        <div className='react-signature-pad' css={styles.canvasWrapper}>
          <canvas css={styles.canvas} ref={canvas} />

          {!isActiveSignatureType(signatureTypes.drawing) && (
            <div css={styles.scaleIcons}>
              <i css={styles.scaleIcon(isMinRatioModifier)} onClick={scaleDown} className='fa fa-fw fa-search-minus' />
              <i
                css={styles.scaleIcon(isMaxRatioModifier)}
                onClick={scaleUp}
                className='fa fa-fw fa-search-plus m-l-10'
              />
            </div>
          )}

          {isActiveSignatureType(signatureTypes.upload) && !image && dropZone}
        </div>
      </div>
      <div className='d-flex'>
        {isActiveSignatureType(signatureTypes.drawing) && <StyledClear onClick={clearCanvas}>Clear</StyledClear>}
        {!isActiveSignatureType(signatureTypes.saved) && onSave && (
          <button className='m-auto btn btn-primary ' onClick={onSave}>
            <FormattedMessage id='SignatureInputInMobile.save' />
          </button>
        )}
      </div>
      {isActiveSignatureType(signatureTypes.script) && (
        <div className='m-l-15 m-r-15 d-flex align-items-center overflow-auto'>
          <SignatureScript
            changeFontHandler={changeFontHandler}
            fontName='bethEllen'
            name={name}
            isActive={activeFont === signatureFonts.bethEllen}
          />
          <SignatureScript
            changeFontHandler={changeFontHandler}
            fontName='dancingScript'
            name={name}
            isActive={activeFont === signatureFonts.dancingScript}
          />
          <SignatureScript
            changeFontHandler={changeFontHandler}
            fontName='dawning'
            name={name}
            isActive={activeFont === signatureFonts.dawning}
          />
          <SignatureScript
            changeFontHandler={changeFontHandler}
            fontName='glory'
            name={name}
            isActive={activeFont === signatureFonts.glory}
          />
          <SignatureScript
            changeFontHandler={changeFontHandler}
            fontName='sheppards'
            name={name}
            isActive={activeFont === signatureFonts.sheppards}
          />
        </div>
      )}
    </>
  );
};

export default forwardRef(SignatureInputInMobile);
