import { gql, useMutation } from '@apollo/client';
import { RESOLUTION_FRAGMENT } from './../fragments/ResolutionFragment';

const UPDATE_RESOLUTION = gql`
  mutation updateResolution($input: UpdateResolutionInput!) {
    updateResolution(input: $input) {
      resolution {
        ...ResolutionFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${RESOLUTION_FRAGMENT}
`;

const useUpdateResolutionMutation = (options) => useMutation(UPDATE_RESOLUTION, options);

export { useUpdateResolutionMutation };
