import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';

export const wrapper = css`
  .vote-panel {
    min-height: auto !important;
  }

  .eye-icon {
    opacity: 0.35;
    position: absolute;
    top: 61%;
    right: 23%;
    cursor: pointer;
  }

  .btn-copy {
    height: 44px;
    width: 45px;
    justify-content: center;
    i {
      font-size: 16px;
    }
  }

  .vote-error {
    ${fonts.bold};
    padding: 2px 4px;
    background: ${colors.red};
    border-radius: 2px;
    line-height: 1;
    color: ${colors.white};
    text-transform: uppercase;
    font-size: 10px;
    z-index: 2;
    position: absolute;
    top: -40px;
    left: 60px;
    letter-spacing: 0.09em;
  }

  #react-picker-input {
    &::placeholder {
      color: ${colors.mediumGrey};
    }
  }

  textarea {
    height: 150px !important;
  }
`;

export const saveButtonsPanel = css`
  overflow: hidden;
`;

export const saveButton = css`
  ${media.tablet} {
    width: 51%;
    margin-right: 0px !important;
  }
`;
