import { useDeleteSubcommitteeMutation } from './../data/mutations/DeleteSubcommitteeMutation';

const useDeleteSubcommittee = () => {
  const update = (cache, { data: { deleteSubcommittee } }) => {
    const { subcommittee } = deleteSubcommittee;

    cache.evict({
      id: cache.identify({ __typename: 'Subcommittee', id: subcommittee.id }),
      broadcast: false,
    });

    cache.gc();
  };

  const [mutate] = useDeleteSubcommitteeMutation({ update });

  const deleteSubcommittee = ({ subcommitteeId }) => {
    try {
      mutate({ variables: { input: { subcommitteeId } } });
    } catch (error) {
      if (error) {
        return onError([error]);
      }
    }
  };

  return { deleteSubcommittee };
};

export { useDeleteSubcommittee };
