import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { notify } from 'components/v2/notification';
import Heading from 'components/v2/shared/Heading';
import Button from 'components/v2/shared/Button';
import TextInput from 'components/v2/shared/TextInput';
import SelectInput from 'components/v2/shared/SelectInput';
import Label from 'components/v2/shared/Label';
import moment from 'moment';
import { wrapMutation } from 'utils/v2/gql';
import { useUpdateProfileDetailsMutation } from 'data/v2/mutations/UpdateProfileDetailsMutation';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const schema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  phoneNumber: z.string().optional(),
  address1: z.string().optional(),
  address2: z.string().optional(),
  city: z.string().optional(),
  zip: z.string().optional(),
});

const UserDetail = ({ countries, currentUser }) => {
  const [addressData, setAddressData] = useState(currentUser.address);
  const commonErrorMessage = `This field can not be left blank`;
  const emailErrorMessage = `Email is incorrect`;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: currentUser.firstName ?? '',
      lastName: currentUser.lastName ?? '',
      phoneNumber: currentUser.phoneNumber ?? '',
      email: currentUser.email ?? '',
      address1: addressData.address1 ?? '',
      address2: addressData.address2 ?? '',
      city: addressData.city ?? '',
      zip: addressData.zip ?? '',
      countryId: addressData.country?.value ?? '',
    },
  });

  const [updateProfileDetails] = useUpdateProfileDetailsMutation();

  const onChangeAddressInput = (e) => {
    const { name, value } = e.target;
    setAddressData((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = (data) => {
    wrapMutation(
      updateProfileDetails,
      {
        variables: {
          input: {
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            email: data.email,
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            zip: data.zip,
            countryId: addressData.country?.value,
          },
        },
      },
      'updateProfileDetails'
    )
      .then(() => {
        notify('success', 'User profile updated');
      })
      .catch((error) => {
        notify('error', 'Please complete the required fields.');
      });
  };

  return (
    <div>
      <div className='tw-flex tw-items-center tw-justify-between'>
        <div className='tw-hidden sm:tw-block'>
          <Heading text='User details' />
        </div>
        <div className='tw-font-sans tw-text-xs tw-font-medium'>
          Joined on: {moment(currentUser.createdAt).format('DD/MM/YYYY')}
        </div>
      </div>
      <div className='tw-py-6'>
        <div className='tw-h-[72px] tw-w-[72px] tw-rounded-full tw-bg-gray-500'>
          <div className='tw-flex tw-h-full tw-items-center tw-justify-center'>
            <h1 className='tw-m-0 tw-font-sans tw-text-[32px] tw-font-bold'>{currentUser.initials}</h1>
          </div>
        </div>
      </div>
      <div className='tw-my-3.5 sm:tw-w-96'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='tw-py-3.5'>
            <TextInput
              label='First name *'
              errorMessage={errors.firstName && commonErrorMessage}
              {...register('firstName')}
              size='large'
              placeholder='First Name'
            />
          </div>

          <div className='tw-py-3.5'>
            <TextInput
              size='large'
              label='Last name *'
              placeholder='Last Name'
              errorMessage={errors.lastName && commonErrorMessage}
              {...register('lastName')}
            />
          </div>

          <div className='tw-py-3.5'>
            <TextInput
              size='large'
              label='Email *'
              placeholder='Email'
              errorMessage={
                (errors.email && errors.email.type === 'required' && commonErrorMessage) ||
                (errors.email && errors.email.type === 'pattern' && emailErrorMessage)
              }
              {...register('email')}
            />
          </div>

          <div className='tw-py-3.5'>
            <TextInput
              size='large'
              label='Phone'
              {...register('phoneNumber')}
              placeholder='Phone'
              errorMessage={errors.phoneNumber}
            />
          </div>

          <div className='tw-py-3.5'>
            <TextInput
              size='large'
              label='Address Line 1'
              {...register('address1')}
              placeholder='Address Line 1'
              errorMessage={errors.address1}
            />
          </div>

          <div className='tw-py-3.5'>
            <TextInput size='large' label='Address Line 2' {...register('address2')} placeholder='Address Line 2' />
          </div>

          <div className='tw-py-3.5'>
            <TextInput size='large' label='City' {...register('city')} placeholder='City' />
          </div>

          <div className='tw-py-3.5'>
            <TextInput size='large' label='Zip' name='zip' {...register('zip')} placeholder='Zip' />
          </div>

          <div className='tw-py-3.5'>
            <Label text='Country' size='large' isRequired />

            <SelectInput
              isFilterable
              name='country'
              optionVariant='regular'
              onChange={(value) => onChangeAddressInput({ target: { name: 'country', value } })}
              value={addressData.country}
              options={countries}
              placeholder='Select country'
              listHeight='large'
            />
          </div>
          <div className='tw-my-[26px]'>
            <Button font='semibold' isDisabled={!!errors.form} isFullWidth size='large' variant='teal' type='submit'>
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default UserDetail;
