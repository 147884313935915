/** @jsx jsx */

import React, { memo, useEffect, useCallback, useState } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { UploadWindowStatusBar } from './../UploadWindowStatusBar';
import { UploadWindowItemList } from './../UploadWindowItemList';
import { useCreateDocument } from 'components/documents/hooks/useCreateDocument';
import { useCreateResolutionDocument } from 'components/votes/hooks/useCreateResolutionDocument';
import { useUploadQueueActions, useUploadQueueStore } from 'store/uploadQueueStoreContext';

import * as styles from './UploadWindow.styles';

const UploadWindow = memo(({ onResolutionDocumentUpload }) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const intl = useIntl();

  const { uploadQueue, currentUpload, pendingCount, completeCount } = useUploadQueueStore();

  const {
    processQueue,
    discardQueue,
    cancelUpload,
    retryUpload,
    completeCurrentUpload: onUpload,
    rejectCurrentUpload: onError,
    setCurrentUpload,
  } = useUploadQueueActions();

  const { createDocument } = useCreateDocument({ onUpload, onError });
  const { createResolutionDocument } = useCreateResolutionDocument({ onUpload, onResolutionDocumentUpload, onError });

  useEffect(() => toggleBeforeUnloadListener(), [pendingCount]);
  useEffect(() => handleQueueProcessing(), [uploadQueue]);
  useEffect(() => handleCurrentUpload(), [currentUpload]);

  const toggleBeforeUnloadListener = () => {
    if (pendingCount > 0) {
      window.addEventListener('beforeunload', onBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', onBeforeUnload);
    }
  };

  const onBeforeUnload = useCallback((e) => {
    e.returnValue = intl.formatMessage({ id: 'UploadWindow.cancelUploads' });
  }, []);

  const handleQueueProcessing = () => {
    if (uploadQueue.length && !currentUpload) {
      processQueue();
    }
  };

  const handleCurrentResolutionDocumentUpload = () => {
    const params = {
      resolutionId: currentUpload.resolutionId,
      attachment: currentUpload.file,
    };

    createResolutionDocument(params, setCurrentUpload);
  };

  const handleCurrentDocumentUpload = () => {
    const params = {
      attachment: currentUpload.file,
      directoryId: currentUpload.directory.id,
      path: currentUpload.file.path,
    };

    createDocument(params, setCurrentUpload);
  };

  const handleCurrentUpload = () => {
    if (!currentUpload) return;

    if (currentUpload.resolutionId) {
      handleCurrentResolutionDocumentUpload();
    } else {
      handleCurrentDocumentUpload();
    }
  };

  const handleToggleMinimizedWindow = useCallback(() => {
    setIsMinimized(!isMinimized);
  }, [isMinimized]);

  const handleCloseWindow = useCallback(() => {
    cancelUpload({ id: currentUpload?.id });
    discardQueue();
  }, [currentUpload]);

  const containerStyles = [styles.container.base, isMinimized && styles.container.minimized];

  return (
    uploadQueue.length > 0 && (
      <div css={containerStyles}>
        <UploadWindowStatusBar
          pendingUploadsCount={pendingCount}
          completeUploadsCount={completeCount}
          isMinimized={isMinimized}
          onToggleMinimized={handleToggleMinimizedWindow}
          onClose={handleCloseWindow}
        />

        <UploadWindowItemList show={!isMinimized} items={uploadQueue} />
      </div>
    )
  );
});

export { UploadWindow };
