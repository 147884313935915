import { gql } from '@apollo/client';
import { ZOOM_MEETING_TOPIC_FRAGMENT } from './ZoomMeetingTopicFragment';

const CONTACT_FRAGMENT = gql`
  fragment ContactFields on Contact {
    id
    name
    email
    initials
    status
    avatarUrl
    companyPosition
  }
`;

export { CONTACT_FRAGMENT };
