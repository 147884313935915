/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { ReactComponent as DirectoryIcon } from 'images/icons/documents/directory-icon.svg';

import * as styles from './BreadcrumbsDropdownItem.styles';

const BreadcrumbsDropdownItem = memo(({ name, onClick }) => {
  return (
    <div css={styles.container} onClick={onClick}>
      <DirectoryIcon css={styles.icon} />
      <div css={styles.name}>{name}</div>
    </div>
  );
});

export { BreadcrumbsDropdownItem };
