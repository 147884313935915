import React from 'react';

const ActionsCell = ({ alignRight }) => {
  return (
    <div className={`text-${alignRight ? 'right' : 'center'}`}>
      <div className='btn-group dropdown no-arrow'>
        <div className='table-action-btn dropdown-toggle'>
          <svg width='2' height='12' viewBox='0 0 2 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='1' cy='11' r='1' fill='#00031f' />
            <circle cx='1' cy='6' r='1' fill='#00031f' />
            <circle cx='1' cy='1' r='1' fill='#00031f' />
          </svg>
        </div>
      </div>
    </div>
  );
};

export { ActionsCell };
