/** @jsx jsx */
import React, { memo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { TextAreaInput } from 'components/shared/TextAreaInput';

import { ResolutionDocumentList } from '../ResolutionDocumentList';

import * as styles from './ResolutionForm.styles';

import { useUpdateResolution } from './../hooks/useUpdateResolution';

const ResolutionForm = memo(({ resolution, index, onDocumentClick, onDelete, resolutionCount, root }) => {
  const [description, setDescription] = useState(resolution.description || '');
  const [showDocuments, setShowDocuments] = useState(false);

  const toggleShowDocuments = () => setShowDocuments(!showDocuments);

  const handleDelete = onDelete.bind(this, resolution);

  const { isUpdating, updateResolution } = useUpdateResolution({});

  const changeDescription = (event) => setDescription(event.target.value);
  const updateResolutionDescription = () => updateResolution({ resolutionId: resolution.id, description: description });

  useEffect(() => setDescription(resolution.description || ''), [resolution]);

  const intl = useIntl();

  return (
    <div className='d-flex' css={styles.resolutionForm}>
      <div css={styles.index}>{`${index}.`}</div>
      <div css={styles.innerWrapper}>
        <div css={styles.textarea}>
          <TextAreaInput
            value={description}
            placeholder={intl.formatMessage({ id: 'ResolutionForm.enterDesc' })}
            onBlur={updateResolutionDescription}
            onChange={changeDescription}
            customInputStyles={styles.textAreaInput}
            id={`resolution-${index}`}
          />
        </div>
        {resolutionCount > 1 && (
          <div css={styles.actions}>
            <div className='action-item' onClick={() => handleDelete(resolution)}>
              <div className='i far fa-trash-alt fa-fw cursor-pointer' />
            </div>
          </div>
        )}
      </div>
      <ResolutionDocumentList
        resolution={resolution}
        root={root}
        showDocuments={showDocuments}
        onDocumentClick={onDocumentClick}
        toggleShowDocuments={toggleShowDocuments}
        isFullWidth={resolutionCount <= 1}
      />
    </div>
  );
});

export { ResolutionForm };
