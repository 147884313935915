import React from 'react';
import styled from 'styled-components';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';
import BounceLoader from 'react-spinners/BounceLoader';
import { colors } from 'styles';

const Wrapper = styled.div`
  background: ${HexToRgba(colors.white, 70)};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const LoadingCircle = () => {
  return (
    <Wrapper>
      <BounceLoader loading={true} color={colors.cyan} />
    </Wrapper>
  );
};
