import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Item = ({ isSelected, onClick, children }) => (
  <li
    onClick={onClick}
    className={clsx(
      'tw-flex tw-grow tw-items-center tw-rounded-sm',
      'tw-text-sm tw-font-medium tw-text-black',
      'tw-p-3 hover:tw-cursor-pointer',
      {
        'tw-bg-white tw-text-black hover:tw-bg-purple-100 hover:tw-text-purple-500': !isSelected,
        'tw-bg-purple-100 tw-text-purple-500 hover:tw-opacity-80': isSelected,
      }
    )}
  >
    {children}
  </li>
);

Item.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default Item;
