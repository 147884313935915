import { gql, useMutation } from '@apollo/client';
import { MEETING_FRAGMENT } from 'data/v2/fragments/MeetingFragment';
import { CONTACT_MEETING_FRAGMENT } from 'data/v2/fragments/ContactMeetingFragment';

const UPDATE_MEETING_ATTENDEES_MUTATION = gql`
  mutation updateMeetingAttendees($input: UpdateMeetingAttendeesInput!) {
    updateMeetingAttendees(input: $input) {
      meeting {
        ...MeetingFields

        contactMeetings {
          ...ContactMeetingFields
        }
      }

      errors {
        attribute
        message
      }
    }
  }
  ${MEETING_FRAGMENT}
  ${CONTACT_MEETING_FRAGMENT}
`;

const useUpdateMeetingAttendeesMutation = (options) => useMutation(UPDATE_MEETING_ATTENDEES_MUTATION, options);

export { UPDATE_MEETING_ATTENDEES_MUTATION, useUpdateMeetingAttendeesMutation };
