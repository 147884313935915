/** @jsx jsx */

import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import { ReactSVG } from 'react-svg';

import * as styles from './SVG.styles';

const SVG = memo(({ src, ...props }) => {
  return <ReactSVG css={styles.icon} src={src} {...props} />;
});

export { SVG };
