/** @jsx jsx */

import React, { memo, useRef, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { AccessRolesTable } from './../AccessRolesTable';
import Filters from './../Filters';
import { Spinner } from 'components/shared/Spinner';
import { useRoleDrawerActions } from 'store/roleDrawerStoreContext';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { withIntl } from '../../../utils/v2/intl';

const List = memo(({ accessRoles, onFiltersChange }) => {
  const { isMobile } = useWindowDimensions();
  const { openViewOnlyDrawer, openDrawer } = useRoleDrawerActions();

  const intl = useIntl();

  const openAccessRole = (event, accessRoleId) => {
    const accessRole = accessRoles.find((accessRole) => accessRole.id === event.detail);

    openViewOnlyDrawer(accessRole.attributes);
  };

  const ref = useRef();

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('drawer:open', openAccessRole);

      return () => {
        node.removeEventListener('drawer:open', openAccessRole);
      };
    }
  }, [accessRoles]);

  return (
    <div ref={ref} className='d-flex flex-column' id='shaparency-roles'>
      <div className='media-heading-mt-small align-items-center d-flex justify-content-between'>
        <div className='text-nowrap media-title'>
          <FormattedMessage id='List.rolesPermissions' />
        </div>

        <button className='btn btn-primary btn-sm-fw mw-100 m-l-30' onClick={openDrawer}>
          {isMobile ? intl.formatMessage({ id: 'List.add' }) : intl.formatMessage({ id: 'List.addRole' })}
        </button>
      </div>

      <div className='flex-grow-1'>
        <Filters onChange={onFiltersChange} />

        <div className='m-t-15 media-row'>
          <AccessRolesTable accessRoles={accessRoles} onClick={openDrawer} />
        </div>
      </div>

      <Spinner />
    </div>
  );
});

export default withIntl(List);
