import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from 'components/v2/shared/Box';
import StateBadge from 'components/v2/shared/StateBadge';
import Avatar from 'components/v2/shared/Avatar';
import IconButton from 'components/v2/shared/IconButton';
import { signatoryStatusBadgeVariantMap } from 'components/v2/smartSign/constants';

const SignatoryItem = ({ name, email, initials, status, signedSignatureCount, totalSignatureCount }) => (
  <Box>
    <div className='tw-grid tw-grow tw-grid-cols-12 tw-items-center tw-gap-2'>
      <div className='tw-col-span-6'>
        <div className='tw-flex tw-grow tw-items-center tw-gap-4'>
          <Avatar text={initials} />

          <div className='tw-flex tw-min-w-0 tw-flex-col'>
            <div className='tw-truncate'>{name}</div>
            <div className='tw-truncate tw-text-xs tw-text-gray-700'>{email}</div>
          </div>
        </div>
      </div>

      <div className='tw-col-span-6'>
        <div className='tw-flex tw-items-center tw-justify-end tw-gap-2'>
          <div className='tw-flex tw-flex-col tw-items-center tw-gap-1 md:tw-flex-row md:tw-gap-4'>
            <div className='tw-text-xs tw-text-gray-700'>{`${signedSignatureCount} / ${totalSignatureCount}`}</div>

            <StateBadge variant={signatoryStatusBadgeVariantMap[status]}>
              <div className='tw-capitalize'>{status}</div>
            </StateBadge>
          </div>

          <IconButton.X className='tw-text-gray-700 hover:!tw-bg-gray-200' />
        </div>
      </div>
    </div>
  </Box>
);

SignatoryItem.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  signedSignatureCount: PropTypes.number.isRequired,
  totalSignatureCount: PropTypes.number.isRequired,
};

export default SignatoryItem;
