import { css } from '@emotion/core';
import { fonts } from 'styles';

export const badge = css`
  margin-left: 7px;
  min-width: 79px;
  width: auto;
  padding: 0 10px;
  line-height: 27px;
  font-size: 16px;
`;
