import { gql } from '@apollo/client';

const COMPANY_PERMISSIONS_FRAGMENT = gql`
  fragment CompanyPermissionsFields on CompanyPermissions {
    subscriptionCanBeManagedByCurrentUser
    canBeManagedByCurrentUser
    canBeRemovedByCurrentUser
    canBeClosedByCurrentUser
  }
`;

export { COMPANY_PERMISSIONS_FRAGMENT };
