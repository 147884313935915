/** @jsx jsx */

import React, { useMemo, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import * as styles from './PaymentForm.styles';

const useOptions = () =>
  useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          fontSize: '14px',
          color: '#424770',
          fontFamily: 'Gilroy-Regular, sans-serif',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    []
  );

const CardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [errorToDisplay, setErrorToDisplay] = useState('');
  const [isComplete, setComplete] = useState(false);
  const [isEmpty, setEmpty] = useState(false);

  const getPaymentMethod = async () => {
    if (!isComplete) {
      setEmpty(true);
      return;
    }
    setEmpty(false);

    setErrorToDisplay('');

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setErrorToDisplay(error && error.message);
    }

    return paymentMethod;
  };

  const onChangeCardElement = (event) => {
    setComplete(event.complete);
  };

  return (
    <div css={isEmpty ? styles.errorCardBox : styles.cardBox}>
      <h2 className='fs-18 font-weight-bold m-b-15'>
        <FormattedMessage id='CardForm.ccOrDebit' />
      </h2>
      <CardElement options={options} onChange={onChangeCardElement} />
    </div>
  );
};

export default CardForm;
