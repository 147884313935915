export const findOptionByValue = (value, options) => options.find((option) => option.value === value) || null;

export const getFieldData = (data) => {
  if (!data) return null;
  if (data.target) return data.target.value;
  if (data.value) return data.value;

  return data;
};

export const getObjectDataByPath = (data, path, defaultValue) => {
  try {
    return path.split('.').reduce((result, key) => result[key], data);
  } catch (e) {
    return defaultValue;
  }
};

export const uniqueBy = (data, fieldName) =>
  data.reduce((result, current) => {
    if (!result.find((element) => element[fieldName] === current[fieldName])) {
      result.push(current);
    }
    return result;
  }, []);

export const arrayToIdMap = (array) => array.reduce((result, element) => ({ ...result, [element.id]: element }), {});
