import { css } from '@emotion/core';
import { colors, panels, media } from 'styles';

export const container = css`
  ${panels.base}
  position: relative;
  margin: 0 auto;
  height: 666px;
  display: flex;
  ${media.tablet} {
    max-width: 100%;
    flex-direction: column;
    height: auto;
  }
`;

export const content = css`
  flex: 1 1 100%;
  max-width: calc(100% - 245px);
  ${media.tablet} {
    max-width: none;
  }
`;

export const title = css`
  padding: 0 20px;
  max-width: 100%;
  ${media.tablet} {
    padding: 10px 20px;
  }
`;

export const wrapper = css`
  position: relative;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.tablet} {
    height: auto;
    flex-direction: column;
    width: 100%;
  }
`;
