/** @jsx jsx */
import React, { memo, useRef } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { DateInput } from 'components/shared/DateInput';
import { TimeInput } from 'components/shared/TimeInput';

import * as styles from './VoteDurationForm.styles';

const VoteDurationForm = memo(
  ({ voteParams, setStartDate, setEndDate, setStartTime, setEndTime, voteOpened, errorMessage }) => {
    const { current: calendarIcon } = useRef(<i className='far fa-calendar-alt' />);

    const dateFormat = getUserDateFormat() || 'DD/MM/YYYY';

    return (
      <>
        <div className='panel vote-panel m-t-30'>
          <div className='vote-heading'>
            <FormattedMessage id='VoteDurationForm.duration' />
          </div>
          <div css={styles.durationWrapped}>
            <div className='start-wrapper' css={styles.startWrapper}>
              <div className='vote-datetime'>
                <label htmlFor='start' className='form-label dash-title fs-12'>
                  <FormattedMessage id='VoteDurationForm.start' />
                </label>

                <div className='d-flex align-items-center datetime-wrapper'>
                  <div className='flex flex-nowrap  m-r-10' css={styles.dataInputWrapper}>
                    <div id='vote-start-date'>
                      <DateInput
                        name='vote-start-date'
                        placeholder={dateFormat}
                        value={voteParams.startDate}
                        onChange={setStartDate}
                        isRequired
                        icon={calendarIcon}
                        iconPosition='right'
                        isDisabled={voteOpened}
                        errorMessage={errorMessage('started_at')}
                      />
                    </div>
                  </div>

                  <div className='start-time'>
                    <TimeInput
                      onChange={setStartTime}
                      value={voteParams.startTime}
                      name='vote-start-time'
                      isDisabled={voteOpened}
                      errorMessage={errorMessage('started_at')}
                      disablePastDates
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='end-wrapper'>
              <div className='vote-datetime'>
                <label htmlFor='end' className='form-label dash-title fs-12'>
                  <FormattedMessage id='VoteDurationForm.end' />
                </label>
                <div className='d-flex align-items-center datetime-wrapper'>
                  <div className='flex flex-nowrap m-r-10' css={styles.dataInputWrapper}>
                    <div id='vote-end-date' className='error-wrapper'>
                      <DateInput
                        name='vote-end-date'
                        placeholder={dateFormat}
                        value={voteParams.endDate}
                        onChange={setEndDate}
                        isRequired
                        icon={calendarIcon}
                        iconPosition='right'
                        isDisabled={voteOpened}
                        errorMessage={errorMessage('ended_at')}
                      />
                    </div>
                  </div>

                  <div className='end-time'>
                    <TimeInput
                      onChange={setEndTime}
                      value={voteParams.endTime}
                      name='vote-end-time'
                      isDisabled={voteOpened}
                      errorMessage={errorMessage('ended_at')}
                      disablePastDates
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export { VoteDurationForm };
