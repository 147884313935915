import { gql, useMutation } from '@apollo/client';
import { RESOLUTION_FRAGMENT } from './../fragments/ResolutionFragment';

const ATTACH_RESOLUTION_DOCUMENT = gql`
  mutation attachResolutionDocument($input: AttachResolutionDocumentInput!) {
    attachResolutionDocument(input: $input) {
      resolution {
        ...ResolutionFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${RESOLUTION_FRAGMENT}
`;

const useAttachResolutionDocumentMutation = (options) => useMutation(ATTACH_RESOLUTION_DOCUMENT, options);

export { useAttachResolutionDocumentMutation };
