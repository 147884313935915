import { useIncrementDocumentNameMutation } from './../data/mutations/IncrementDocumentNameMutation';
import { useApolloCacheHelpers } from './../hooks/useApolloCacheHelpers';

const useIncrementDocumentName = ({ parent, onCompleted, onError }) => {
  const { addDocumentToCache, removeDocumentFromCache } = useApolloCacheHelpers();

  const update = (cache, { data: { incrementDocumentName } }) => {
    const { document, errors } = incrementDocumentName;

    if (errors.length) {
      return onError(errors[0].fullMessage);
    }

    removeDocumentFromCache(parent, document);
    addDocumentToCache(document.parent, document);
    onCompleted();
  };

  const [mutate, { loading: isIncrementing }] = useIncrementDocumentNameMutation({ update });

  const incrementDocumentName = (documentId, destinationId) => {
    mutate({ variables: { input: { documentId, destinationId } } });
  };

  return { incrementDocumentName, isIncrementing };
};

export { useIncrementDocumentName };
