import { css } from '@emotion/core';
import { fonts, colors } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const modal = css`
  .modal-dialog.shaparency {
    width: 454px;

    .modal-content {
      padding: 23px 25px;
      border-radius: 2px;

      .modal-header {
        .modal-title {
          ${fonts.bold}
        }

        .modal-close {
          transform: scale(1);
        }
      }

      .modal-header,
      .modal-body {
        padding: 0;
      }
    }
  }
`;

export const header = css`
  ${fonts.bold}
  font-size: 16px;
  line-height: 16px;
  margin-right: 5px;
  margin-left: 7px;
`;

export const name = css`
  ${fonts.regular}
  font-size: 16px;
  line-height: 16px;
  max-width: 300px;
  color: ${colors.lightGrey};
`;

export const loading = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
`;

export const content = css`
  margin: 26px 0 0 0;
  min-height: 431px;
`;

export const contacts = css`
  height: 250px;
`;

const urlBorderColor = HexToRgba(colors.headingGrey, 40);

export const url = {
  base: css`
    ${fonts.bold}
    border: 1px solid;
    border-color: ${urlBorderColor};
    padding: 8px 11px;
    color: ${colors.black};
    font-size: 12px;
    width: 100%;
    line-height: 16px;
  `,

  copied: css`
    border-color: ${colors.cyan};
  `,
};

export const baseCopyIcon = css`
  margin-left: 10px;
  transform: scale(0.8);
  flex-shrink: 0;
`;

export const copyIcon = css`
  ${baseCopyIcon}
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const info = css`
  ${name}
  font-size: 12px;
  color: ${colors.headingGrey};
  margin: 5px 0;
`;

export const checkmark = css`
  ${baseCopyIcon}
  background: ${colors.cyan};
  width: 32px;
  height: 32px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;

  i {
    margin-left: 2px;
    font-weight: 400;
    color: ${colors.white};
  }
`;
