import { gql, useMutation } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from './../fragments/DocumentFragment';
import { BREADCRUMB_FRAGMENT } from './../fragments/BreadcrumbFragment';

const CREATE_DOCUMENT_MUTATION = gql`
  mutation createDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      document {
        ...DocumentFields

        parent {
          id
          empty

          ancestors {
            id
            empty
          }
        }

        breadcrumbs {
          ...BreadcrumbFields
        }
      }

      errors {
        attribute
        message
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${BREADCRUMB_FRAGMENT}
`;

const useCreateDocumentMutation = (options) => useMutation(CREATE_DOCUMENT_MUTATION, options);

export { CREATE_DOCUMENT_MUTATION, useCreateDocumentMutation };
