/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/v2/shared/TextInput';
import Checkbox from 'components/v2/shared/Checkbox';
import RadioButton from 'components/v2/shared/RadioButton';
import Box from 'components/v2/shared/Box';
import Button, { Variants } from 'components/v2/shared/Button';
import ZoomMeetingForm from './../ZoomMeetingForm';
import ConferenceForm from './../ConferenceForm';
import InPersonLocationForm from './../InPersonLocationForm';

import { useMeetingConfigContext } from 'contexts/v2/MeetingConfigContext';
import { useMeetingFormStepContext } from 'contexts/v2/MeetingFormStepContext';
import { useZoomMeetingConfigContext } from 'contexts/v2/ZoomMeetingConfigContext';
import { getFieldData } from 'utils/v2/data';
import { ZOOM, CONFERENCE, IN_PERSON } from './constants';

import * as styles from './HowToAttendStep.styles';

const HowToAttendStep = () => {
  const { edits, saveConfigData, updateEdits, discardEdits } = useMeetingConfigContext();
  const { zoomEdits } = useZoomMeetingConfigContext();
  const { nextStep } = useMeetingFormStepContext();

  const [errors, setErrors] = useState({});
  const [hasLocation, setHasLocation] = useState(false);

  const toggleHasLocation = () => setHasLocation((state) => !state);

  const handleConfigTypeChange = (value) => {
    const configType = getFieldData(value);

    discardEdits();
    updateEdits('config', configType);

    if (configType === IN_PERSON) {
      setHasLocation(false);
    }
  };

  const handleMeetingConfigSave = (redirectToIndex) => async () => {
    setErrors({});

    try {
      await saveConfigData(zoomEdits);
      if (redirectToIndex) {
        navigate('meetingsIndex');
      } else {
        nextStep();
      }
    } catch (e) {
      setErrors(e);
    }
  };

  const configTypeFormMap = {
    zoom: <ZoomMeetingForm />,
    conference: <ConferenceForm errors={errors} />,
    in_person: <InPersonLocationForm />,
  };

  return (
    <div css={styles.container}>
      <Row noGutters className='mb-4 mt-4'>
        <Col lg={6}>
          <Row noGutters>
            <Col lg={12}>
              <RadioButton
                isChecked={edits.config === ZOOM}
                name='config'
                label='Zoom meeting'
                value={ZOOM}
                onChange={handleConfigTypeChange}
              />
            </Col>

            <Col lg={12}>
              <RadioButton
                isChecked={edits.config === CONFERENCE}
                name='config'
                label='Other conference link'
                value={CONFERENCE}
                onChange={handleConfigTypeChange}
              />
            </Col>

            <Col lg={12}>
              <RadioButton
                isChecked={edits.config === IN_PERSON}
                name='config'
                label='In person location only'
                value={IN_PERSON}
                onChange={handleConfigTypeChange}
              />
            </Col>
          </Row>
        </Col>

        {edits.config !== IN_PERSON && (
          <Col>
            <Box>
              <Checkbox label='Also in person location' isChecked={hasLocation} onChange={toggleHasLocation} />
            </Box>
          </Col>
        )}
      </Row>

      {configTypeFormMap[edits.config]}
      {hasLocation && <div className='mt-5'>{configTypeFormMap[IN_PERSON]}</div>}

      <Row noGutters className='mt-5'>
        <Col lg={12}>
          <Button className='mb-2' isFullWidth onClick={handleMeetingConfigSave()}>
            Next
          </Button>

          <Button variant={Variants.Teal} isFullWidth onClick={handleMeetingConfigSave(true)}>
            Save draft
          </Button>
        </Col>
      </Row>
    </div>
  );
};

HowToAttendStep.propTypes = {};

export default HowToAttendStep;
