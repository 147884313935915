import { css } from '@emotion/core';
import { tables, fonts, colors } from 'styles';

export const table = tables.table;
export const tableRow = tables.tableRow;

export const tableContainer = css`
  overflow: hidden;
`;

export const tableCell = css`
  ${tables.tableCell}
  padding: 12px 5px;
`;

export const deleteIcon = css`
  color: ${colors.lightGrey};

  &:hover {
    cursor: pointer;
    color: ${colors.cyan};
  }
`;
