import React, { useState, useContext } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import TextInput from 'components/v2/shared/TextInput';
import Button from 'components/v2/shared/Button';
import Label from 'components/v2/shared/Label';
import Toast, { Severity } from '../../shared/Toast/Toast';
import { ToastContext } from '../../../../contexts/v2/ToastContext';
import { wrapMutation } from 'utils/v2/gql';
import { useCreateCompanyContactMutation } from 'data/v2/mutations/CreateCompanyContactMutation';

const ExistingCompany = ({ onClick }) => {
  const { toastInfo, setToastInfo } = useContext(ToastContext);
  const [companyData, setCompanyData] = useState('shaparency.com/');

  const onChangeCompanyInput = (e) => {
    const { value } = e.target;
    setCompanyData(value);
  };

  const [createCompanyContact] = useCreateCompanyContactMutation();

  const onSendRequest = () => {
    wrapMutation(
      createCompanyContact,
      {
        variables: {
          input: {
            slug: companyData.split('/').pop(),
          },
        },
      },
      'createCompanyContact'
    )
      .then(() => {
        setToastInfo({
          severity: Severity.Success,
          status: 'Success',
          message: 'Successfully invited. Please wait for the confirmation.',
        });
      })
      .catch(() => {
        setToastInfo({
          severity: Severity.Error,
          status: 'Error',
          message: 'An error has occured.',
        });
      });
  };

  return (
    <>
      <div className='tw-container tw-mx-auto tw-flex tw-max-w-full tw-justify-center tw-bg-white tw-py-6 tw-px-5 sm:tw-px-10'>
        <Toast severity={toastInfo.severity} status={toastInfo.status} message={toastInfo.message} />
        <div className='tw-relative tw-w-full sm:tw-w-[450px]'>
          <h5
            className='tw-flex tw-cursor-pointer tw-items-center tw-text-base !tw-font-extrabold'
            onClick={() => onClick('list')}
          >
            <FiChevronLeft
              size={20}
              className='tw-text-purple-700 sm:tw-absolute sm:-tw-left-6 sm:tw-top-0.5'
              color='#47126E'
            />
            <span className='tw-pl-1.5 tw-font-sans tw-text-sm !tw-font-semibold tw-text-purple-500 sm:tw-pt-1'>
              Companies
            </span>
          </h5>
          <h1 className='tw-mb-0 tw-font-sans tw-text-lg tw-font-semibold tw-text-black sm:tw-text-xl sm:tw-font-bold lg:tw-text-3xl'>
            Add existing company
          </h1>
          <div className='tw-mt-3 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>
            Ask the administrator to provide you with their unique company link.
          </div>
          <div className='tw-mt-7'>
            <Label
              text='Copy and paste below'
              className='!tw-mb-2.5 !tw-font-sans !tw-font-medium !tw-text-gray-700'
              size='large'
            />
            <TextInput name='existingcompany' value={companyData} onChange={onChangeCompanyInput} size='large' />
          </div>
          <div className='tw-mt-8'>
            <Button font='semibold' isFullWidth size='large' variant='purple' onClick={onSendRequest}>
              Send request
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExistingCompany;
