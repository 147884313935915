import { css } from '@emotion/core';
import { colors, media } from 'styles';

export const wrapper = css`
  position: relative;
  margin-left: auto;
  cursor: pointer;
`;

export const container = {
  base: css`
    --hover-opacity: 0.7;
    display: flex;
    align-items: center;
    height: 22px;

    .uploadButton__text {
      font-size: 14px;
      color: ${colors.black};
      line-height: 1;
      margin-right: 7px;
      transition: all 0.2s ease-in;
    }

    .uploadButton__icon {
      transition: all 0.2s ease-in;
      margin-right: 6px;
      line-height: 1;
      flex-shrink: 0;
    }

    &:hover {
      .uploadButton__icon {
        path {
          opacity: var(--hover-opacity);
        }
      }

      .uploadButton__text {
        opacity: var(--hover-opacity);
      }
    }
  `,

  disabled: css`
    cursor: default;

    .uploadButton__icon {
      path {
        opacity: var(--hover-opacity);
      }
    }

    .uploadButton__indicator,
    .uploadButton__text {
      opacity: var(--hover-opacity);
      color: ${colors.mediumGrey};
    }
  `,
};

export const option = css`
  display: flex;
  align-items: center;
  padding: 8px 15px;

  &:hover {
    background: ${colors.lightestGrey};
  }

  .dropdownOption__icon {
    margin-right: 12px;
    flex-shrink: 0;
  }

  .dropdownOption__text {
    white-space: nowrap;
    font-size: 12px;
    line-height: 1;
    color: ${colors.black};
    ${media.tablet} {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const dropdown = css`
  position: absolute;
  top: 25px;
  left: -35px;
  background: ${colors.white};
  box-shadow: 0px 3px 20px rgba(16, 8, 63, 0.11);
  border-radius: 7px;
  z-index: 2;
  ${media.tablet} {
    width: 100%;
    right: 0;
    left: 0;
    top: unset !important;
    /* bottom: 66px; */
    z-index: 1000;
    bottom: 0;
    position: fixed;
    border-radius: 0;
    padding: 10px 0 20px;
  }
`;

export const arrow = {
  base: css`
    flex-shrink: 0;
    font-size: 10px;
    font-weight: 400;
    transition: transform 0.2s ease-in;
    color: ${colors.black};
  `,

  rotated: css`
    transform: rotate(180deg);
  `,
};
