/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';

import { Modal } from 'components/shared/Modal';
import { AgendaReorderItem } from './../AgendaReorderItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { reorder } from 'helpers/reorder';
import { makeAgendaOrderMap, orderMapToParams } from 'helpers/agendas';

import * as styles from './AgendaReorderModal.styles';

const DROPPABLE_TYPE = 'ROOTS';

const AgendaReorderModal = memo(({ agendas, show, onClose, onSave }) => {
  const orderMap = makeAgendaOrderMap(agendas, DROPPABLE_TYPE);

  const [orderMapEdits, setOrderMapEdits] = useState(orderMap);

  const handleShow = () => setOrderMapEdits(orderMap);
  const handleSave = () => onSave(orderMapToParams(orderMapEdits));

  const handleDragEnd = ({ draggableId, type, source, destination }) => {
    if (!destination) return;
    if (destination.index === source.index) return;

    setOrderMapEdits({
      ...orderMapEdits,
      [type]: reorder(orderMapEdits[type], source.index, destination.index),
    });
  };

  const agendaList = orderMapEdits[DROPPABLE_TYPE].map((agenda, index) => (
    <AgendaReorderItem key={`agenda-reorder-item-${agenda.id}`} index={index} item={agenda} orderMap={orderMapEdits} />
  ));

  const renderDroppable = ({ placeholder, innerRef, droppableProps }, { isDraggingOver }) => (
    <div ref={innerRef} {...droppableProps} css={styles.scrollable}>
      {agendaList}
      {placeholder}
    </div>
  );

  return (
    <Modal
      animation={false}
      title='Reorder'
      modalStyles={styles.modal}
      show={show}
      centered
      onShow={handleShow}
      onClose={onClose}
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable type={DROPPABLE_TYPE} droppableId='root-dropppable-list'>
          {renderDroppable}
        </Droppable>
      </DragDropContext>

      <a className='btn btn-primary m-t-5' onClick={handleSave}>
        Save
      </a>
    </Modal>
  );
});

export { AgendaReorderModal };
