import { css } from '@emotion/core';
import { colors } from 'styles';

export const wrapper = {
  base: css`
    border-radius: 2px;
    display: flex;
    background: ${colors.white};
    align-items: center;
    width: 100%;
    padding: 18px 15px;
    cursor: pointer;
    border-bottom: 1px solid ${colors.notificationGrey};

    &:last-of-type {
      border-bottom: none;
    }
  `,

  unread: css`
    background: ${colors.lightBlue};
  `,
};

export const message = css`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.black};
`;

export const createdAgo = css`
  color: ${colors.muted};
  font-size: 12px;
  line-height: 14px;
  flex: 1 1 100%;
  white-space: nowrap;
`;

export const body = css`
  margin-left: 15px;
`;