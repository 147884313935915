import { gql, useMutation } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';

const UPDATE_DIRECTORY = gql`
  mutation updateDirectory($input: UpdateDirectoryInput!) {
    updateDirectory(input: $input) {
      directory {
        ...DirectoryFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${DIRECTORY_FRAGMENT}
`;

const useUpdateDirectoryMutation = (options) => useMutation(UPDATE_DIRECTORY, options);

export { useUpdateDirectoryMutation };
