import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const dropzone = css`
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  color: ${colors.mediumGrey};
  border: 1px dashed ${colors.lightGrey};
  background: ${colors.white};
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const upload = css`
  ${fonts.semiBold}
  color: ${colors.cyan};

  &:hover {
    color: ${colors.cyan};
    opacity: 0.8;
  }
`;

export const icon = css`
  background: ${colors.placeholderGrey};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  svg {
    margin: 0 0 8px 10px;
  }
`;

export const documents = css`
  position: relative;
`;
