/** @jsxRuntime classic */
/** @jsx jsx */

import React, { memo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { getRandomId } from 'helpers/getRandomId';

import axios from 'axios';

import * as styles from './LinkDecorator.styles';

const Link = memo(({ href, text }) => {
  const [metadata, setMetadata] = useState();

  useEffect(() => getPageMetadata(), []);

  const getPageMetadata = () => {
    axios
      .get(`/api/page_metadata.json`, { params: { href } })
      .then((response) => response.data)
      .then(setMetadata)
      .catch(() => {});
  };

  if (!metadata) {
    return (
      <a href={href} target='_blank'>
        {text}
      </a>
    );
  }

  return (
    <a href={href} css={styles.link} target='_blank'>
      <img src={metadata.favicon} css={styles.favicon} />

      <span css={styles.title}>{metadata.title}</span>
    </a>
  );
});

const LinkDecorator = (href, text, index) => {
  return <Link key={`linkify-${getRandomId()}`} href={href} text={text} />;
};

export { LinkDecorator };
