/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import ClipLoader from 'react-spinners/ClipLoader';

import { colors } from 'styles';
import * as styles from './LoadingIndicator.styles';

const LoadingIndicator = memo(({ show }) => {
  return (
    <div css={styles.indicator}>
      <ClipLoader loading={show} size={16} color={colors.cyan} />
    </div>
  );
});

export { LoadingIndicator };
