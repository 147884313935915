/** @jsx jsx */

import React, { memo, useEffect, useState, useRef } from 'react';
import { jsx, css } from '@emotion/core';
import { ReactComponent as DownloadIcon } from 'images/icons/documents/download-icon.svg';

import * as styles from './FileViewerTopBar.styles';

const FileViewerTopBar = memo(
  ({ title, onDownload, onClose, onScaleIn, onScaleOut, isMaxScale, isMinScale, isScaleable }) => {
    const scaleInStyles = [styles.control.base, isMaxScale && styles.control.disabled];

    const scaleOutStyles = [styles.control.base, isMinScale && styles.control.disabled];

    const scaleControls = isScaleable && (
      <>
        <div css={scaleInStyles} className='far fa-search-plus' onClick={onScaleIn} />
        <div css={scaleOutStyles} className='far fa-search-minus' onClick={onScaleOut} />
      </>
    );

    return (
      <div css={styles.container}>
        <div css={styles.inner}>
          <div css={styles.title} className='text-truncate'>
            {title}
          </div>

          <div className='ml-auto d-flex align-items-center'>
            {scaleControls}

            <div css={styles.control.base} onClick={onDownload}>
              <DownloadIcon css={styles.downloadIcon} />
            </div>

            <div css={styles.control.base} className='fa fa-times' onClick={onClose} />
          </div>
        </div>
      </div>
    );
  }
);

export { FileViewerTopBar };
