import { gql, useQuery } from '@apollo/client';

const CONTACTS_QUERY = gql`
  query ContactsQuery($page: Int, $limit: Int, $order: String, $search: String) {
    companyContactCount(search: $search)
    contacts(page: $page, limit: $limit, order: $order, search: $search) {
      edges {
        node {
          name
          id
          isOwner
          contactRole
          accessRoleId
          status
          avatarUrl
          companyPosition
          initials
          email
          phoneNumber
          linkedinUrl
          address
          createdAt
          daysSinceCreation
          lastActiveDate
          daysSinceActive
          actionItems {
            title
          }
        }
      }
    }
  }
`;

const useContactsQuery = (options) => useQuery(CONTACTS_QUERY, options);

export { CONTACTS_QUERY, useContactsQuery };
