import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { getObjectDataByPath } from '../../utils/v2/data';

const initialState = {
  gqlData: null,
};

const actionTypes = {
  SET_DATA: 'GQLDataContext.setData',
};

const GQLDataContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_DATA:
      return { ...state, gqlData: payload.data };
  }
};

export const GQLDataContextProvider = ({ initialQuery, variables, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { data, loading, error } = useQuery(initialQuery, { variables });

  useEffect(() => {
    if (data) {
      dispatch({ type: actionTypes.SET_DATA, payload: { data } });
    }
  }, [data]);

  const getDataByPath = (path, defaultValue) => getObjectDataByPath(state.gqlData, path, defaultValue);

  const { gqlData } = state;

  return (
    <GQLDataContext.Provider value={{ loading, id: variables?.id, gqlData, error, getDataByPath }}>
      {children}
    </GQLDataContext.Provider>
  );
};

GQLDataContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useGQLDataContext = () => useContext(GQLDataContext);
