/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';

import { withIntl } from '../../../utils/v2/intl';
import { ReactComponent as CloseIcon } from 'images/icons/purple-cross.svg';

import * as styles from './MobileSharedDropdown.styles';

const MobileSharedDropdown = ({ onClick }) => {
  return (
    <div css={styles.container} onClick={onClick}>
      <CloseIcon />
    </div>
  );
};

export default withIntl(MobileSharedDropdown);
