/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './AreaButton.styles';

const AreaButton = memo(({ isDisabled, text, icon, onClick, alignLeft, alignRight }) => {
  const containerStyles = [
    styles.container.base,
    isDisabled && styles.container.disabled,
    alignLeft && styles.container.marginRightAuto,
    alignRight && styles.container.marginLeftAuto,
  ];

  const handleClick = isDisabled ? null : onClick;

  return (
    <div css={containerStyles} onClick={handleClick}>
      <div className='areaButton__icon'>{icon}</div>
      <div className='areaButton__text'>{text}</div>
    </div>
  );
});

export { AreaButton };
