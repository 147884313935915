import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Attachment from '../Attachment';
import Button, { Sizes, Variants } from 'components/v2/shared/Button';

const AttachmentList = ({ attachments, onAttachmentRemove, onAttachmentCancel, onAttachmentRestart }) => {
  if (!attachments.length) {
    return (
      <div className='tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-font-bold tw-text-purple-500'>
        <div className='tw-hidden tw-text-lg lg:tw-mb-2 lg:tw-inline-block'>Drag & Drop</div>

        <Button size={Sizes.Small} variant={Variants.Underline} className='!tw-text-black'>
          Select from device
        </Button>
      </div>
    );
  }

  const handleAttachmentListClick = (e) => e.stopPropagation();
  const handleAttachmentCancel = (attachment) => () => onAttachmentCancel(attachment);
  const handleAttachmentRemove = (attachment) => () => onAttachmentRemove(attachment);
  const handleAttachmentRestart = (attachment) => () => onAttachmentRestart(attachment);

  const renderAttachments = attachments.map((attachment) => (
    <Attachment
      key={attachment.id}
      error={attachment.error}
      name={attachment.name}
      progress={attachment.progress}
      state={attachment.state}
      onRemove={handleAttachmentRemove(attachment)}
      onCancel={handleAttachmentCancel(attachment)}
      onRestart={handleAttachmentRestart(attachment)}
    />
  ));

  return (
    <div className='tw-flex tw-h-full tw-flex-col tw-gap-y-2 tw-pt-1.5'>
      <div className='tw-flex tw-h-full tw-flex-col tw-gap-y-4 tw-overflow-y-auto' onClick={handleAttachmentListClick}>
        {renderAttachments}
      </div>

      <Button size={Sizes.Small} variant={Variants.Underline} className='!tw-text-black'>
        Add another file
      </Button>
    </div>
  );
};

AttachmentList.propTypes = {
  attachments: PropTypes.array,
  onAttachmentRemove: PropTypes.func,
  onAttachmentCancel: PropTypes.func,
  onAttachmentRestart: PropTypes.func,
};

export default AttachmentList;
