import { css } from '@emotion/core';
import { panels, icons, colors, fonts, media } from 'styles';

export const wrapper = css`
  ${panels.base}
  position: absolute;
  top: -25px;
  right: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
  padding: 9px 6px;
  border: 1px solid #dedde0;
  box-shadow: 2px 4px 4px rgba(223, 223, 223, 0.25);
  border-radius: 4px;
  ${media.tablet} {
    display: none;
  }
`;

export const tooltip = css`
  ${fonts.semiBold}
  background: ${colors.black};
  border-radius: 8px;
  color: ${colors.white};
  box-sizing: content-box;
  font-size: 12px;
  position: absolute;
  width: fit-content;
  pointer-events: none;
  white-space: nowrap;
  top: -50px;
  padding: 7px 11px;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
`;

export const triangle = css`
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid ${colors.black};
`;

export const icon = css`
  position: relative;
  cursor: pointer;
  padding: 0 6px;
  color: ${colors.lightGrey};

  div {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    svg,
    i {
      opacity: 0.8;
    }

    div {
      opacity: 1;
    }
  }
`;
