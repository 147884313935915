import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';

export const sort = css`
  padding: 0 10px;
  ${media.tablet} {
    padding: 0 15px;
  }
`;

export const actions = css`
  display: flex;
  justify-content: space-between;
  padding: 0 10px 10px 10px;
  ${media.tablet} {
    padding: 0 15px 10px 15px;
  }

  &:empty {
    padding: 0;
  }
`;

export const content = css`
  height: calc(100% - 50px);
  overflow: auto;
  ${media.tablet} {
    height: auto;
    max-height: calc(100% - 85px);
  }
`;
