import { gql } from '@apollo/client';

const ANNOTATION_CREATED_SUBSCRIPTION = gql`
  subscription AnnotationCreatedSubscription {
    annotationCreated {
      id
      data
      contactId
    }
  }
`;

export default ANNOTATION_CREATED_SUBSCRIPTION;
