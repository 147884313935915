import React, { createContext, useContext, useReducer } from 'react';
import { ThemeProvider } from 'styled-components';
import { MeetingProvider, lightTheme } from 'amazon-chime-sdk-component-library-react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { getAxiosHeaders } from 'utils/getHeaders';

const ChimeMeetingContext = createContext();

const initialState = {
  meetingInfo: {},
  attendeeInfo: {},
  isLoading: false,
};

const actionTypes = {
  SET_INFO: 'chime.setInfo',
  SET_LOADING: 'chome.toggleLoad',
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_INFO:
      return { ...state, meetingInfo: payload.meetingInfo, attendeeInfo: payload.attendeeInfo };
    case actionTypes.SET_LOADING:
      return { ...state, isLoading: payload.isLoading };
  }
};

export const ChimeMeeetingProvider = ({ meetingId, currentContactId, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const companySlug = getCompanySlug();
  const meetingURI = `/api/${companySlug}/v1/chime/meetings`;
  const attendeeURI = `/api/${companySlug}/v1/chime/attendees`;

  const getMeetingJoinInfo = () => {
    if (state.isLoading) {
      return;
    }

    dispatch({ type: actionTypes.SET_LOADING, payload: { isLoading: true } });

    const meetingInfo = axios.post(meetingURI, { id: meetingId }, getAxiosHeaders());
    const attendeeInfo = axios.post(attendeeURI, { id: meetingId, contact_id: currentContactId }, getAxiosHeaders());

    Promise.all([meetingInfo, attendeeInfo])
      .then((results) => {
        dispatch({
          type: actionTypes.SET_INFO,
          payload: { meetingInfo: results[0].data, attendeeInfo: results[1].data },
        });
        dispatch({ type: actionTypes.SET_LOADING, payload: { isLoading: false } });
      })
      .catch(() => {
        dispatch({ type: actionTypes.SET_LOADING, payload: { isLoading: false } });
      });
  };

  const getAttendee = async (chimeId) => {
    const {
      data: { data },
    } = await axios.get(attendeeURI, { params: { id: meetingId } });
    const attendee = data.find((item) => item.attributes.chime_id === chimeId);

    return attendee.attributes;
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <MeetingProvider>
        <ChimeMeetingContext.Provider value={{ ...state, getMeetingJoinInfo, getAttendee }}>
          {children}
        </ChimeMeetingContext.Provider>
      </MeetingProvider>
    </ThemeProvider>
  );
};

ChimeMeeetingProvider.propTypes = {
  children: PropTypes.node,
  meetingId: PropTypes.number,
  currentContactId: PropTypes.number,
};

export const useChimeMeetingContext = () => useContext(ChimeMeetingContext);
