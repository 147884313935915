import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const SidebarItem = ({ name, onClick, isSelected }) => (
  <div
    onClick={onClick}
    className={clsx(
      'tw-cursor-pointer tw-rounded',
      'tw-truncate tw-px-4 tw-py-2',
      'tw-text-sm tw-text-black',
      'hover:tw-opacity-70',
      {
        'tw-font-medium': !isSelected,
        'tw-bg-purple-100 tw-font-semibold': isSelected,
      }
    )}
  >
    {name}
  </div>
);

SidebarItem.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default SidebarItem;
