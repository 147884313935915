/** @jsx jsx */

import React, { memo, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { withIntl } from '../../../utils/v2/intl';
import { TASK_STATUSES } from './constants';

import * as styles from './index.styles';

const Line = ({ header, children, isWrapped }) => {
  return (
    <div css={styles.line({ isWrapped })}>
      <p css={styles.header}>{header}:</p>
      <div css={[styles.statuses, styles.textOverflow]}>{children}</div>
    </div>
  );
};

const CountItem = ({ title, count }) => {
  return (
    <p css={styles.statisticItem}>
      <span>{title}:</span>
      <span css={styles.statisticItemNumber}>{count}</span>
    </p>
  );
};

const DateLabel = ({ date, time }) => (
  <div css={styles.dateLabel}>
    <div css={[styles.dateLabelPart, styles.dateLabelDay]}>{date}</div>
    <div css={[styles.dateLabelPart, styles.dateLabelTime]}>{time}</div>
  </div>
);

const TableMobileCard = memo(
  ({
    name,
    nameType = 'Minutes',
    owner,
    approver,
    assignee,
    link,
    linkTrigger,
    type,
    agenda,
    meeting,
    dasherize,
    status,
    isTask,
    date,
    time,
    confirmedCount,
    waitingCount,
    declinedCount,
    totalCount,
    receivedCount,
    pendingCount,
    isLast,
    dueDate,
    log,
  }) => {
    const redirectToEntity = useCallback(() => {
      return link ? window.location.assign(link) : undefined;
    }, [link]);

    const intl = useIntl();

    const taskStatusClassName = isTask ? TASK_STATUSES.find((option) => option.value === status) : '';

    const counts = [confirmedCount, waitingCount, declinedCount, totalCount, receivedCount, pendingCount];
    const showCountBlock = counts.some((count) => count !== undefined);

    const CountBlock = () => {
      return showCountBlock ? (
        <div css={styles.statisticBlock}>
          {confirmedCount !== undefined ? (
            <CountItem title={intl.formatMessage({ id: 'TableMobileCard.confirmed' })} count={confirmedCount} />
          ) : null}
          {waitingCount !== undefined ? (
            <CountItem title={intl.formatMessage({ id: 'TableMobileCard.waiting' })} count={waitingCount} />
          ) : null}
          {declinedCount !== undefined ? (
            <CountItem title={intl.formatMessage({ id: 'TableMobileCard.declined' })} count={declinedCount} />
          ) : null}
          {totalCount !== undefined ? (
            <CountItem title={intl.formatMessage({ id: 'TableMobileCard.total' })} count={totalCount} />
          ) : null}
          {receivedCount !== undefined ? (
            <CountItem title={intl.formatMessage({ id: 'TableMobileCard.received' })} count={receivedCount} />
          ) : null}
          {pendingCount !== undefined ? (
            <CountItem title={intl.formatMessage({ id: 'TableMobileCard.pending' })} count={pendingCount} />
          ) : null}
        </div>
      ) : null;
    };

    const CardContent = () => {
      return (
        <>
          <div css={styles.container}>
            {date && time ? (
              <Line header={intl.formatMessage({ id: 'TableMobileCard.date' })} isWrapped={true}>
                <DateLabel date={date} time={time} />
              </Line>
            ) : null}
            {nameType && name ? (
              <Line header={intl.formatMessage({ id: 'TableMobileCard.name' }, { type: nameType })}>{name}</Line>
            ) : null}
            {type ? <Line header={intl.formatMessage({ id: 'TableMobileCard.meetingType' })}>{type}</Line> : null}
            {agenda ? <Line header={intl.formatMessage({ id: 'TableMobileCard.agenda' })}>{agenda}</Line> : null}
            {meeting ? <Line header={intl.formatMessage({ id: 'TableMobileCard.meeting' })}>{meeting}</Line> : null}
            {owner ? <Line header={intl.formatMessage({ id: 'TableMobileCard.author' })}>{owner}</Line> : null}
            {assignee ? <Line header={intl.formatMessage({ id: 'TableMobileCard.asignee' })}>{assignee}</Line> : null}
            {dueDate ? <Line header={intl.formatMessage({ id: 'TableMobileCard.date' })}>{dueDate}</Line> : null}
            {approver ? <Line header={intl.formatMessage({ id: 'TableMobileCard.approver' })}>{approver}</Line> : null}
            {status || dasherize ? (
              <Line header={intl.formatMessage({ id: 'TableMobileCard.status' })}>
                {isTask && taskStatusClassName ? (
                  <span className={taskStatusClassName.className}>{status}</span>
                ) : (
                  <span className={`badge badge-large ${dasherize ? `badge-${dasherize}` : ''}`}>{status}</span>
                )}
              </Line>
            ) : null}
          </div>
          <CountBlock />
        </>
      );
    };

    const CardWrapper = ({ children }) => {
      if (linkTrigger) {
        return (
          <a css={styles.card(isLast)} data-remote='true' href={linkTrigger}>
            {children}
          </a>
        );
      }

      return (
        <div css={styles.card(isLast)} onClick={redirectToEntity}>
          {children}
        </div>
      );
    };

    return (
      <CardWrapper>
        <CardContent />
      </CardWrapper>
    );
  }
);

export default withIntl(TableMobileCard);
