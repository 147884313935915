import { gql, useQuery } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';
import { DOCUMENT_FRAGMENT } from './../fragments/DocumentFragment';

const RECENT_DIRECTORIES_QUERY = gql`
  query recentDirectories {
    currentContact {
      id

      directories: recentDirectories {
        ...DirectoryFields
      }

      documents: recentDocuments {
        ...DocumentFields
      }
    }
  }
  ${DIRECTORY_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
`;

const useRecentDirectoriesQuery = (options) => useQuery(RECENT_DIRECTORIES_QUERY, options);

export { RECENT_DIRECTORIES_QUERY, useRecentDirectoriesQuery };
