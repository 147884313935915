import { css } from '@emotion/core';
import { colors, fonts, media, animations } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

const hoverColor = HexToRgba(colors.lightGrey, 80);

export const wrapper = ({ withTitle }) => css`
  position: relative;
  margin-top: 1px;
  display: flex;
  ${!!withTitle && 'justify-content: space-between;'}
  ${!!withTitle && 'align-items: flex-start;'}
  ${!!withTitle && 'padding: 15px 0;'}
  width: ${!!withTitle ? '100%' : 'auto'};

  &:hover {
    .chat__icon {
      path {
        fill: ${hoverColor};
      }
    }
  }
`;

export const icon = {
  base: css`
    flex-shrink: 0;
    cursor: pointer;

    @media (max-width: 1280px) {
      path {
        fill: white;
      }
    }
  `,

  animated: css`
    ${animations.shake}
  `,
};

export const badge = ({ withTitle }) => css`
  position: absolute;
  border-radius: 50%;
  cursor: pointer;
  background: ${colors.cyan};
  width: 12px;
  height: 12px;
  top: ${withTitle ? '9px' : '-6px'};
  right: -3px;
  border: 2px solid ${colors.white};
`;

export const title = css`
  ${fonts.regular};
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  ${media.mobile} {
    font-size: 14px;
    line-height: 16px;
  }
`;