/** @jsx jsx */

import React, { memo, useState, useEffect, forwardRef } from 'react';
import { css, jsx } from '@emotion/core';
import { Input } from 'components/shared/Input';
import { Label } from 'components/shared/Input/Label';

import { ReactComponent as ClearIcon } from 'images/icons/purple-cross.svg';

import Skeleton from 'react-loading-skeleton';
import * as styles from './Dropdown.styles';

const Dropdown = memo(
  forwardRef(
    (
      {
        dropdownId,
        inputId,
        inputPlaceholder,
        inputValue,
        inputIcon,
        inputIconPosition,
        rotatableIcon,
        inputError,
        inputLabel,
        onInputChange,
        isOpen,
        onFocus,
        onBlur,
        isLoading,
        isDisabled,
        dropdownWidth,
        dropdownStyles,
        standalone,
        withClearIcon,
        ...props
      },
      dropdownRef
    ) => {
      const handleBlur = (event) => {
        const currentTarget = event.currentTarget;

        setTimeout(() => {
          const withinElement = currentTarget.contains(document.activeElement);

          if (!withinElement) {
            onBlur && onBlur();
          }
        }, 0);
      };

      const skeleton = (
        <div css={styles.skeleton}>
          <Skeleton count={5} />
        </div>
      );

      const handleClear = () => {
        onInputChange({ target: { value: '' } });
        onBlur();
      };

      const childrenStyles = [styles.dropdown(dropdownWidth || 346), dropdownStyles];

      const children = isOpen && <div css={childrenStyles}>{isLoading ? skeleton : props.children}</div>;

      return (
        <>
          <Label text={inputLabel} />
          <div
            tabIndex='1'
            id={dropdownId}
            ref={dropdownRef}
            css={styles.component}
            onFocus={isDisabled ? null : onFocus}
            onBlur={isDisabled ? null : handleBlur}
          >
            {!standalone && (
              <>
                <Input
                  placeholder={inputPlaceholder}
                  icon={inputIcon}
                  iconPosition={inputIconPosition}
                  rotatableIcon={rotatableIcon}
                  value={inputValue}
                  isDisabled={isDisabled}
                  errorMessage={inputError}
                  onChange={onInputChange}
                  id={inputId}
                  type='text'
                />

                {withClearIcon && inputValue.length > 0 && <ClearIcon css={styles.clear} onClick={handleClear} />}
              </>
            )}
            <div css={styles.wrapper}>{children}</div>
          </div>
        </>
      );
    }
  )
);

export { Dropdown };
