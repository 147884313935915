import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const wrapper = (isHovered) => css`
  position: relative;
  cursor: pointer;
  margin-right: 10px;

  .tooltip-icon {
    color: ${isHovered ? colors.accentColor : 'rgba(0, 0, 0, 0.2)'};
  }
`;

export const container = (isHovered) => css`
  ${fonts.regular};
  position: absolute;
  font-size: 12px;
  color: ${colors.black};
  border-radius: 3px;
  box-shadow: -10px 4px 50px rgba(54, 71, 98, 0.25);
  background: ${colors.white};
  width: 160px;
  pointer-events: none;
  text-align: center;
  bottom: auto;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  opacity: ${isHovered ? 1 : 0};
  transition: opacity 0.2s ease-in-out;
  z-index: 2;
  line-height: 1;
  border: 1px solid ${colors.agendaGrey};
`;

export const message = css`
  padding: 10px;
`;

export const triangle = css`
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${colors.white};
`;
