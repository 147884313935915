/** @jsx jsx */

import React, { useState, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { AgendaList } from './../AgendaList';
import { TasksTable } from './../TasksTable';

import { TaskDrawer } from 'components/shared/TaskDrawer';

import { useTasksStore, useTasksActions } from 'store/tasksStoreContext';
import { useFlashActions } from 'store/flashStoreContext';
import { useAgendasStore } from 'store/agendasStoreContext';
import { useTaskDrawerActions } from 'store/taskDrawerStoreContext';

import { ReactComponent as AgendaIcon } from 'images/icons/agenda-icon.svg';
import { ReactComponent as TasksIcon } from 'images/icons/tasks-icon.svg';

import * as styles from './AgendaTasksTabs.styles';
import { utils } from 'styles';

const AgendaTasksTabs = ({
  meetingId,
  contacts,
  meetingDuration,
  adminView,
  editView,
  assigneeView,
  meetingStatus,
  root,
  currentContactId,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const showAgenda = activeTab === 1;
  const showTasks = activeTab === 2;

  const goToAgendas = () => setActiveTab(1);
  const goToTasks = () => setActiveTab(2);

  const tabStyles = {
    agenda: styles.tab({ isSelected: showAgenda }),
    tasks: styles.tab({ isSelected: showTasks }),
  };

  const { agendas, visibilityMap } = useAgendasStore();
  const { tasks } = useTasksStore();

  const { createTask, updateTask, deleteTask, toggleTask } = useTasksActions();

  const { openDrawer, hideDrawer, setErrors } = useTaskDrawerActions();

  const { setFlash } = useFlashActions();

  // TASKS
  const handleAddTask = useCallback(() => {
    openDrawer();
  }, []);

  const handleEditTask = useCallback((params) => {
    openDrawer({ ...params });
  }, []);

  const handleToggleTask = useCallback((taskId) => {
    toggleTask(meetingId, taskId).catch(setFlash);
  }, []);

  const handleDeleteTask = useCallback((taskId) => {
    deleteTask(meetingId, taskId).then(hideDrawer).catch(setFlash);
  }, []);

  const handleCreateTask = useCallback((params) => {
    createTask(meetingId, params).then(hideDrawer).catch(setErrors);
  }, []);

  const handleUpdateTask = useCallback((params) => {
    updateTask(meetingId, params.id, params).then(hideDrawer).catch(setErrors);
  }, []);

  const tabs = (
    <>
      <div css={styles.padded}>
        <div css={styles.tabRow}>
          <div id='agenda-tab' css={tabStyles.agenda} onClick={goToAgendas}>
            <AgendaIcon css={styles.icon({ isSelected: showAgenda })} />
            <div css={styles.tabName({ isSelected: showAgenda })}>
              <FormattedMessage id='AgendaTasksTabs.agenda' />
            </div>
          </div>

          <div id='tasks-tab' css={tabStyles.tasks} onClick={goToTasks}>
            <TasksIcon css={styles.icon({ isSelected: showTasks })} />
            <div css={styles.tabName({ isSelected: showTasks })}>
              <FormattedMessage id='AgendaTasksTabs.tasks' />
            </div>
          </div>
        </div>
      </div>

      <div css={utils.separator} />
    </>
  );

  return (
    <>
      {tabs}

      <div className={showAgenda ? 'd-block' : 'd-none'}>
        <AgendaList
          meetingId={meetingId}
          tasks={tasks}
          agendas={agendas}
          visibilityMap={visibilityMap}
          meetingDuration={meetingDuration}
          contacts={contacts}
          onEditTask={handleEditTask}
          adminView={adminView}
          editView={editView}
          assigneeView={assigneeView}
          meetingStatus={meetingStatus}
          root={root}
        />
      </div>

      <div className={showTasks ? 'd-block' : 'd-none'}>
        <TasksTable
          tasks={tasks}
          agendas={agendas}
          onAdd={handleAddTask}
          onEdit={handleEditTask}
          onToggle={handleToggleTask}
          onDelete={handleDeleteTask}
          adminView={adminView}
          editView={editView}
          meetingStatus={meetingStatus}
          currentContactId={currentContactId}
        />
      </div>

      <TaskDrawer
        agendas={agendas}
        contacts={contacts}
        onClose={hideDrawer}
        onCreate={handleCreateTask}
        onUpdate={handleUpdateTask}
        onDelete={handleDeleteTask}
        adminView={adminView}
        editView={editView}
        meetingStatus={meetingStatus}
        currentContactId={currentContactId}
      />
    </>
  );
};

export { AgendaTasksTabs };
