/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import * as styles from './CommentTarget.styles';

const CommentTarget = memo(({ target, images }) => {
  if (!target && !images) {
    return null;
  }

  const selectedImages =
    images &&
    images.map((imageURL, index) => (
      <div key={`image-${index}`} className='m-b-10'>
        <img src={imageURL} css={styles.image} />
      </div>
    ));

  return (
    <div css={styles.target}>
      <div css={styles.header}>
        <FormattedMessage id='CommentTarget.selectedText' />
      </div>

      <div css={styles.blockquote}>
        {selectedImages}
        {target}
      </div>
    </div>
  );
});

export { CommentTarget };
