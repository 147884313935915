import { useState, useCallback } from 'react';
import { useDebounce } from 'use-debounce';

const useDirectoriesBrowserSearch = () => {
  const [context, setContext] = useState({
    isSearching: false,
    searchTerm: '',
  });

  const [debouncedSearchTerm] = useDebounce(context.searchTerm, 500, { leading: !context.searchTerm.length });

  const updateContext = (updates) => setContext((state) => ({ ...state, ...updates }));

  const handleTermChange = useCallback((event) => {
    updateContext({ searchTerm: event.target.value || '' });
  }, []);

  const startSearching = useCallback(() => {
    updateContext({ isSearching: true });
  }, []);

  const stopSearching = useCallback(() => {
    updateContext({ isSearching: false, searchTerm: '' });
  }, []);

  return {
    handleTermChange,
    startSearching,
    stopSearching,
    debouncedSearchTerm,
    ...context,
  };
};

export { useDirectoriesBrowserSearch };
