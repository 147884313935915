/** @jsx jsx */

import React, { memo, useEffect, useMemo, useCallback, useRef, useState } from 'react';
import { jsx } from '@emotion/core';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { Input } from 'components/shared/Input';
import { CopyPopover } from 'components/shared/CopyPopover';
import { DateInput } from 'components/shared/DateInput';
import { SelectInput } from 'components/shared/SelectInput';
import { TimeInput } from 'components/shared/TimeInput';
import { RadioButton } from 'components/shared/RadioButton';
import { MeetingDetails } from '../MeetingDetails';
import { TimezoneDropdown } from 'components/shared/TimezoneDropdown';
import { LanguageDropdown } from 'components/shared/LanguageDropdown';
import { ZoomDetailsForm } from './../ZoomDetailsForm';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import { useMeetingStore, useMeetingActions } from 'store/meetingStoreContext';
import { getMeetingType } from 'helpers/meetingTypes';
import { parseDateTime } from 'helpers/parseDateTime';
import { getAttributes } from 'helpers/getAttributes';
import { useDateFormatContext } from '../../../contexts/v2/DateFormatContext';

import * as styles from './MeetingDetailsForm.styles';

const MeetingDetailsForm = memo(
  ({
    meetingTypeOptions,
    timezoneOptions,
    currentContactId,
    contactTimezone,
    contactTimezoneOffset,
    isDisabled,
    zoomEnabled,
    availableLanguages,
    contactLanguage,
  }) => {
    const { userDateFormat } = useDateFormatContext();

    const { current: calendarIcon } = useRef(<i className='far fa-calendar-alt' />);
    const { current: locationIcon } = useRef(<i className='far fa-map-marker-alt' />);

    const { edits: meeting, isSubmit, errors } = useMeetingStore();
    const { setEdits } = useMeetingActions();

    const { isPseudo } = useMeetingStatus(meeting?.status);

    const autocompleteRef = React.useRef();

    const intl = useIntl();

    let autocomplete;

    useEffect(() => {
      if (!autocompleteRef.current) {
        return;
      }

      autocomplete = new google.maps.places.Autocomplete(autocompleteRef.current, {
        types: ['geocode'],
      });

      const onPlaceChanged = (e) => {
        const addressObject = autocomplete.getPlace();
        const query = addressObject.formatted_address;

        setEdits('location', query);
      };

      google.maps.event.addListener(autocomplete, 'place_changed', onPlaceChanged);
    }, [autocompleteRef.current]);

    const meetingType = useMemo(() => {
      return getMeetingType(meeting, meetingTypeOptions);
    }, [meeting.meeting_type_id]);

    const handleChange = useCallback((event) => {
      setEdits(event.target.name, event.target.value);
    }, []);

    const handleConferenceChange = useCallback((event) => {
      if (event.target.value == 'chime') {
        setEdits('config', 'conference');
      } else if (event.target.value == 'zoom') {
        setEdits('config', 'zoom');
        setEdits('meeting_url', zoomMeeting?.join_url);
      } else {
        setEdits('config', 'in_person');

        const url = zoomMeeting?.id ? '' : meeting.meeting_url;
        setEdits('meeting_url', url);
      }
    }, []);

    const setDate = useCallback((date) => {
      setEdits('meeting_date', date);
    }, []);

    const setStartTime = useCallback((time) => {
      setEdits('meeting_start_time', time);
    }, []);

    const setEndTime = useCallback((time) => {
      setEdits('meeting_end_time', time);
    }, []);

    const setMeetingType = useCallback((meetingType) => {
      setEdits('meeting_type_id', meetingType.value);
    }, []);

    useEffect(() => {
      if (!meeting.timezone?.length) {
        setEdits('timezone', contactTimezone);
      }

      if (!meeting.language?.length) {
        setEdits('language', contactLanguage);
      }
    }, [meeting]);

    const handleTimezoneChange = useCallback((event) => {
      setEdits('timezone', event.value);
    }, []);

    const handleLanguageChange = useCallback((event) => {
      setEdits('language', event.value);
    }, []);

    const formattedTimeZoneOptions = Object.keys(timezoneOptions)
      .map((key) => [{ label: key, value: timezoneOptions[key] }])
      .flat();

    const findTimezone = () => {
      const timezoneName = meeting.timezone?.length ? meeting.timezone : contactTimezone;

      return formattedTimeZoneOptions.find(({ value }) => value === timezoneName);
    };

    useEffect(() => {
      setMeetingTimezone(findTimezone());
    }, [meeting.timezone]);

    const [meetingTimezone, setMeetingTimezone] = useState(findTimezone());

    const formattedLanguageOptions = Object.keys(availableLanguages)
      .map((key) => [{ label: key, value: availableLanguages[key] }])
      .flat();

    const findLanguage = () => {
      const languageName = meeting.language?.length ? meeting.language : contactLanguage;

      return formattedLanguageOptions.find(({ value }) => value === languageName);
    };

    useEffect(() => {
      setMeetingLanguage(findLanguage());
    }, [meeting.language]);

    const [meetingLanguage, setMeetingLanguage] = useState(findTimezone());

    const meetingDate = useMemo(() => {
      if (meeting.meeting_date) {
        return parseDateTime(meeting.meeting_date);
      }
    }, [meeting.meeting_date]);

    useEffect(() => {
      const keys = Object.keys(errors);

      if (isSubmit && keys.length) {
        const element = document.querySelector(`[name='${keys[0]}']`);

        if (!element) return;

        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, [errors]);

    const showZoomForm = () => {
      if (isPseudo) {
        return false;
      } else {
        return zoomEnabled;
      }
    };

    const zoomMeeting = meeting.zoom_meeting?.data ? getAttributes(meeting.zoom_meeting) : meeting.zoom_meeting;
    const isZoomMeeting = meeting.config == 'zoom';
    const isConferenceMeeting = meeting.config == 'conference';
    const showMeetingUrl = !isConferenceMeeting && (!isZoomMeeting || zoomMeeting?.join_url);
    const hasZoomMeeting = meeting.id !== undefined && zoomMeeting?.id !== null;
    const meetingUrl = meeting.meeting_url || zoomMeeting?.join_url;

    const copyMeetingUrl = () => navigator.clipboard.writeText(meeting.meeting_url);

    return (
      <div className='position-relative'>
        <div css={styles.padded}>
          {!isDisabled ? (
            <>
              <div className='p-t-20'>
                <div className='fs-16 text-black font-weight-bold p-b-20'>
                  <FormattedMessage id='MeetingDetailsForm.meetingDetails' />
                </div>
                <Row noGutters>
                  <Col md={6} css={styles.colBigLeft}>
                    <Input
                      name='title'
                      value={meeting.title || ''}
                      placeholder={intl.formatMessage({ id: 'MeetingDetailsForm.namePlaceholder' })}
                      onChange={handleChange}
                      errorMessage={errors.title}
                      isRequired
                      label={intl.formatMessage({ id: 'MeetingDetailsForm.nameLabel' })}
                    />

                    <div className='p-t-20 d-flex' css={styles.twoInputsWrapper}>
                      <Col
                        md={7}
                        name='meeting_type_id'
                        className='p-l-0'
                        id='meeting_type_id'
                        css={styles.meetTypeInput}
                      >
                        <SelectInput
                          placeholder={intl.formatMessage({ id: 'MeetingDetailsForm.typePlaceholder' })}
                          isRequired
                          errorMessage={errors.meeting_type_id}
                          onChange={setMeetingType}
                          classNamePrefix={'type'}
                          value={meetingType}
                          label={intl.formatMessage({ id: 'MeetingDetailsForm.typeLabel' })}
                          options={meetingTypeOptions}
                        />
                      </Col>

                      <Col md={5} className='p-l-0 p-r-0' css={styles.meetDateInput}>
                        <DateInput
                          name='meeting_date'
                          placeholder={userDateFormat || 'DD/MM/YYYY'}
                          value={meetingDate}
                          onChange={setDate}
                          isRequired
                          errorMessage={errors.meeting_date}
                          icon={calendarIcon}
                          iconPosition='right'
                          label={intl.formatMessage({ id: 'MeetingDetailsForm.dateLabel' })}
                        />
                      </Col>
                    </div>

                    <div className='d-flex flex-wrap align-items-center p-t-20 justify-content-between'>
                      <TimeInput
                        label={intl.formatMessage({ id: 'MeetingDetailsForm.startLabel' })}
                        onChange={setStartTime}
                        value={meeting.meeting_start_time}
                        errorMessage={errors.meeting_start_time}
                        pickerName='meeting_start_time'
                      />

                      <TimeInput
                        label={intl.formatMessage({ id: 'MeetingDetailsForm.endLabel' })}
                        value={meeting.meeting_end_time}
                        errorMessage={errors.meeting_end_time}
                        pickerName='meeting_end_time'
                        onChange={setEndTime}
                      />
                    </div>

                    {Object.keys(availableLanguages).length > 0 && (
                      <div className='p-t-20'>
                        <LanguageDropdown
                          options={formattedLanguageOptions}
                          selectedLanguage={meetingLanguage}
                          onSelect={handleLanguageChange}
                        />
                      </div>
                    )}

                    <div className='p-t-20'>
                      <TimezoneDropdown
                        options={formattedTimeZoneOptions}
                        selectedTimezone={meetingTimezone}
                        onSelect={handleTimezoneChange}
                      />
                    </div>

                    <div className='p-t-20'>
                      <Input
                        name='location'
                        placeholder={intl.formatMessage({ id: 'MeetingDetailsForm.locPlaceholder' })}
                        ref={autocompleteRef}
                        isRequired
                        value={meeting.location || ''}
                        icon={locationIcon}
                        onChange={handleChange}
                        errorMessage={errors.location}
                        iconPosition='right'
                        label={intl.formatMessage({ id: 'MeetingDetailsForm.locLabel' })}
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <label className='fs-12 text-black font-weight-bold m-b-10 lh-16 d-block'>
                      <FormattedMessage id='MeetingDetailsForm.confLink' />
                    </label>

                    {!isPseudo && (
                      <div css={styles.radioButtons}>
                        <RadioButton
                          name='conference'
                          id='conference-chime'
                          value='chime'
                          defaultChecked={isConferenceMeeting}
                          onChange={handleConferenceChange}
                          label={intl.formatMessage({ id: 'MeetingDetailsForm.integratedConf' })}
                          badgeClass='badge-soft-draft'
                          badgeText='beta'
                        />
                        <div className='text-grey fs-12 lh-1 m-b-10'>
                          <FormattedMessage id='MeetingDetailsForm.integratedConfInfo' />
                        </div>

                        <div>
                          <RadioButton
                            name='conference'
                            id='conference-zoom'
                            value='zoom'
                            defaultChecked={isZoomMeeting}
                            onChange={handleConferenceChange}
                            label={intl.formatMessage({ id: 'MeetingDetailsForm.addZoom' })}
                            isDisabled={!zoomEnabled}
                          />
                          {!zoomEnabled && (
                            <div className='text-grey fs-12 lh-1 m-b-10 d-flex flex-row'>
                              <div>
                                <FormattedMessage id='MeetingDetailsForm.zoomConnect' />
                                <a
                                  href={`/${getCompanySlug()}/profile#zoom_integration`}
                                  className='text-color-secondary font-weight-semibold'
                                >
                                  <FormattedMessage id='MeetingDetailsForm.yourProfile' />
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                        <RadioButton
                          name='conference'
                          id='conference-url'
                          value='url'
                          defaultChecked={!isZoomMeeting && !isConferenceMeeting}
                          onChange={handleConferenceChange}
                          label={intl.formatMessage({ id: 'MeetingDetailsForm.addConfLink' })}
                        />
                      </div>
                    )}

                    {showMeetingUrl && (
                      <Row noGutters>
                        <Col md={10}>
                          <Input
                            name='meeting_url'
                            placeholder='https://'
                            value={meetingUrl}
                            errorMessage={errors.meeting_url}
                            onChange={handleChange}
                            isDisabled={isZoomMeeting}
                          />
                        </Col>
                        <Col md={1}>
                          <CopyPopover>
                            <a
                              className={`btn btn-secondary btn-copy d-flex align-items-center ${
                                meetingUrl ? '' : 'disabled'
                              }`}
                              css={styles.copyIcon}
                              onClick={copyMeetingUrl}
                            >
                              <i className='far fa-copy' />
                            </a>
                          </CopyPopover>
                        </Col>
                      </Row>
                    )}

                    {isZoomMeeting && (
                      <div className='m-t-20'>
                        <ZoomDetailsForm timezoneOptions={timezoneOptions} />
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <MeetingDetails
              currentContactId={currentContactId}
              contactTimezone={contactTimezone}
              contactTimezoneOffset={contactTimezoneOffset}
              isMinuteContext
            />
          )}
        </div>
      </div>
    );
  }
);

export { MeetingDetailsForm };
