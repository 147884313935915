import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Link from 'components/v2/layout/Link';
import Avatar from 'components/v2/shared/Avatar';
import Dropdown from 'components/v2/shared/Dropdown';
import ProfileItem from 'components/v2/layout/ProfileItem';
import LogOutItem from 'components/v2/layout/LogOutItem';

const ProfileDropdown = ({ onToggle, currentContact = {}, currentCompany = {} }) => (
  <Dropdown
    renderToggle={({ isComponentVisible }) => (
      <Avatar size='medium' text={currentContact.initials} className='tw-ml-3' onClick={onToggle} />
    )}
    menuElement={
      <Dropdown.Menu className='tw-mt-2 tw-w-40'>
        {currentContact.id && (
          <Link href={`/${currentCompany.slug}/profile`}>
            <Dropdown.Item>
              <ProfileItem />
            </Dropdown.Item>
          </Link>
        )}

        <Link href='/users/sign_out' data-method='delete'>
          <Dropdown.Item>
            <LogOutItem />
          </Dropdown.Item>
        </Link>
      </Dropdown.Menu>
    }
  />
);

ProfileDropdown.propTypes = {
  onToggle: PropTypes.func,
  currentContact: PropTypes.object,
  currentCompany: PropTypes.object,
};

export default ProfileDropdown;
