/** @jsx jsx */
import React, { useCallback } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import useWindowDimensions from 'hooks/useWindowDimensions';
import AppHeader from 'components/shared/AppHeader';
import { ToolbarButtonWrapper, IconButton, HeaderTitle } from 'components/shared/AppHeader/styles';
import { ReactComponent as Logo } from 'images/logo.svg';
import { ReactComponent as CompanyIcon } from 'images/icons/documents/company.svg';
import { ReactComponent as PersonIcon } from 'images/icons/documents/person.svg';
import { ReactComponent as PeopleIcon } from 'images/icons/documents/people.svg';
import { ReactComponent as SignatureIcon } from 'images/icons/documents/signature.svg';
import { ReactComponent as ArrowRight } from 'images/icons/documents/arrow-right.svg';
import { FolderCard, ArrowWrapper, IconWrapper, CardContent } from '../styles';

export const FoldersInMobile = ({ rootDirectories = [], onClick }) => {
  const { isMobile } = useWindowDimensions();
  const companySlug = getCompanySlug();
  const smartDocumentsURL = `/${companySlug}/smart_sign`;
  const back = useCallback(() => {
    window.location.href = smartDocumentsURL;
  }, []);

  if (!rootDirectories) {
    return null;
  }

  return (
    <>
      <AppHeader>
        <ToolbarButtonWrapper>
          <IconButton onClick={back}>
            <i className='far fa-angle-left fa-2x m-r-20' />
            {!isMobile && (
              <HeaderTitle fontSize={16} lineHeight={34}>
                <FormattedMessage id='FoldersInMobile.back' />
              </HeaderTitle>
            )}
          </IconButton>
        </ToolbarButtonWrapper>
        <div className='m-auto'>
          {isMobile ? (
            <Logo width={29} />
          ) : (
            <HeaderTitle fontSize={20} lineHeight={30}>
              <FormattedMessage id='FoldersInMobile.selectDoc' />
            </HeaderTitle>
          )}
        </div>
        <div className={`position-absolute right-0 ${isMobile ? 'm-r-15' : 'm-r-25'}`}>
          <HeaderTitle
            fontSize={16}
            lineHeight={20}
            onClick={() => {
              window.location.href = smartDocumentsURL;
            }}
          >
            <FormattedMessage id='FoldersInMobile.close' />
          </HeaderTitle>
        </div>
      </AppHeader>
      {isMobile && (
        <div className='p-10 p-t-15 p-b-10 m-t-5'>
          <HeaderTitle fontSize={20} lineHeight={30}>
            <FormattedMessage id='FoldersInMobile.selectDoc' />
          </HeaderTitle>
        </div>
      )}
      <div className='p-l-10 p-r-10'>
        {rootDirectories.map((folder) => {
          const name = folder.name;
          const documentCount = folder.documentsCount;
          const subFolderCount = folder.directoriesCount;
          const isPersonal = name === 'Personal Folders';
          const isSmartSign = name === 'SmartSign';
          const isCompany = name === 'Company Folders';
          const isMeetings = name === 'Meeting Folders';
          const handleClick = onClick.bind(this, folder.id);

          return (
            <FolderCard key={folder.id} onClick={handleClick}>
              <CardContent>
                {isCompany && (
                  <IconWrapper>
                    <CompanyIcon />
                  </IconWrapper>
                )}
                {isPersonal && (
                  <IconWrapper>
                    <PersonIcon />
                  </IconWrapper>
                )}
                {isSmartSign && (
                  <IconWrapper>
                    <SignatureIcon />
                  </IconWrapper>
                )}
                {isMeetings && (
                  <IconWrapper>
                    <PeopleIcon />
                  </IconWrapper>
                )}
                {name}
              </CardContent>
              <div className='count'>
                <FormattedMessage id='FoldersInMobile.fileCount' values={{ count: documentCount }} />
              </div>
              <div className='count'>
                <FormattedMessage id='FoldersInMobile.folderCount' values={{ count: subFolderCount }} />
              </div>
              <ArrowWrapper>
                <ArrowRight />
              </ArrowWrapper>
            </FolderCard>
          );
        })}
      </div>
    </>
  );
};
