import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const Sizes = {
  Small: 'small',
  Medium: 'medium',
  Regular: 'regular',
};

const Heading = ({ text, className, size = Sizes.Regular }) => (
  <>
    {size === Sizes.Small ? (
      <p className={clsx('tw-font-sans tw-text-lg', className)}>{text}</p>
    ) : (
      <h1
        className={clsx(
          'tw-font-sans tw-font-bold tw-text-black',
          {
            'tw-text-xl': size === Sizes.Regular,
            'tw-text-lg': size === Sizes.Medium,
          },
          className
        )}
      >
        {text}
      </h1>
    )}
  </>
);

Heading.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Sizes)),
};

export default Heading;
