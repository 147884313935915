import { css } from '@emotion/core';
import { panels, media } from 'styles';

export const panel = css`
  ${panels.base}
  padding: 43px 0 45px 0;
  margin-top: 20px;

  ${media.tablet} {
    border: none;
    box-shadow: none;
  }
`;
