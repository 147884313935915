import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AccessRoleTableRow } from './../AccessRoleTableRow';
import { FadingOverlay } from 'components/shared/PlaceholderTable';
import { PlaceholderTable } from './../PlaceholderTable';

const AccessRolesTable = memo(({ accessRoles, isDefaultAccessRole, onClick }) => {
  if (!accessRoles) {
    return null;
  }

  const tableDefaultBody = accessRoles
    .filter((ar) => ar.attributes.status === 'default')
    .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
    .map((accessRole) => <AccessRoleTableRow key={accessRole.id} accessRole={accessRole} onClick={onClick} />);

  const tableCustomBody = accessRoles
    .filter((ar) => ar.attributes.status === 'custom')
    .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
    .map((accessRole) => <AccessRoleTableRow key={accessRole.id} accessRole={accessRole} onClick={onClick} />);

  return (
    <div className='bs-small position-relative'>
      <FadingOverlay show={!accessRoles.length} resource='access roles' />

      <table className='table table-layout-fixed access-roles-table' id='table_data'>
        <thead>
          <tr>
            <th className='w-22'>
              <FormattedMessage id='AccessRolesTable.role' />
            </th>

            <th className='w-20'>
              <FormattedMessage id='AccessRolesTable.type' />
            </th>

            <th className='w-24'>
              <FormattedMessage id='AccessRolesTable.contacts' />
            </th>

            <th className='w-auto' />
          </tr>
        </thead>

        <tbody>
          <PlaceholderTable show={!accessRoles.length} />
          {tableDefaultBody}
          {tableCustomBody}
        </tbody>
      </table>
    </div>
  );
});

export { AccessRolesTable };
