import { gql } from '@apollo/client';
import { CONTACT_FRAGMENT } from 'data/fragments/ContactFragment';

const CONTACT_GROUP_FRAGMENT = gql`
  fragment ContactGroupFields on ContactGroup {
    id
    name
    size
    createdAt

    owner {
      ...ContactFields
    }

    contacts {
      ...ContactFields
    }
  }
  ${CONTACT_FRAGMENT}
`;

export { CONTACT_GROUP_FRAGMENT };
