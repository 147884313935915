import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const checkmark = (checked = true) => css`
  background: ${checked ? colors.cyan : colors.white};
  z-index: 2;
  border: 1px solid;
  border-color: ${checked ? 'transparent' : colors.lighterGrey};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 8px;
  }

  &:hover {
    cursor: pointer;
    border-color: ${colors.cyan};
  }
`;

export const icon = css`
  &:hover {
    color: ${colors.cyan};
    cursor: pointer;
  }
`;
