import { gql, useQuery } from '@apollo/client';
import { MEETING_FRAGMENT } from 'data/v2/fragments/MeetingFragment';
import { TIMEZONE_FRAGMENT } from 'data/v2/fragments/TimezoneFragment';
import { CONTACT_FRAGMENT } from 'data/v2/fragments/ContactFragment';
import { ACCESS_ROLE_FRAGMENT } from 'data/v2/fragments/AccessRoleFragment';

const MEETING_QUERY = gql`
  query MeetingQuery($id: ID!) {
    currentCompany {
      id

      meeting(id: $id) {
        ...MeetingFields
      }

      accessRoles {
        ...AccessRoleFields
      }

      contacts {
        ...ContactFields
      }

      timezones {
        ...TimezoneFields
      }
    }
  }
  ${MEETING_FRAGMENT}
  ${ACCESS_ROLE_FRAGMENT}
  ${CONTACT_FRAGMENT}
  ${TIMEZONE_FRAGMENT}
`;

const useMeetingQuery = (options) => useQuery(MEETING_QUERY, options);

export { MEETING_QUERY, useMeetingQuery };
