import { useState, useCallback } from 'react';

const useHistoryDrawer = () => {
  const [drawerContext, setDrawerContext] = useState({
    file: null,
    show: false,
  });

  const openHistoryDrawer = useCallback((document) => {
    setDrawerContext({ file: document, show: true });
  }, []);

  const closeHistoryDrawer = useCallback(() => {
    setDrawerContext((context) => ({ ...context, show: false }));
  }, []);

  const resetDrawerContext = useCallback(() => {
    setDrawerContext({ file: null, show: false });
  }, []);

  return {
    drawerContext,
    openHistoryDrawer,
    closeHistoryDrawer,
    resetDrawerContext,
  };
};

export { useHistoryDrawer };
