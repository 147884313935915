import { createStore } from './createStore';
import { deleteById, updateById } from 'utils';

import { trackPromise } from 'react-promise-tracker';
import { getAttributes } from 'helpers/getAttributes';
import { formatDate } from 'helpers/formatDateTime';

import axios from 'axios';

const initialState = {
  tasks: [],
};

const actionTypes = {
  SET: 'tasks.set',
  ADD: 'tasks.add',
  UPDATE: 'tasks.update',
  DELETE: 'tasks.delete',
};

const companyId = getCompanySlug();

const convertToPayload = (params) => {
  const { id, due_date, contacts, tags, ...rest } = params;

  const dueDate = formatDate(due_date);
  const tagIds = tags.map((tag) => tag.id || `new:${tag.name}`);

  const payload = {
    ...rest,
    due_date: dueDate,
    tag_ids: tagIds,
  };

  return { task: payload };
};

const api = {
  toggleTask: (meetingId, taskId) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/tasks/${taskId}/toggle.json`;

    const promise = axios
      .patch(apiURL)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  createTask: (meetingId, params) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/tasks.json`;
    const payload = convertToPayload(params);

    const promise = axios
      .post(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  updateTask: (meetingId, taskId, params) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/tasks/${taskId}.json`;
    const payload = convertToPayload(params);

    const promise = axios
      .put(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  deleteTask: (meetingId, taskId) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/tasks/${taskId}/archive.json`;

    const promise = axios
      .patch(apiURL)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },
};

const useTasksActions = () => {
  const dispatch = useTasksDispatch();

  const toggleTask = (meetingId, taskId) => {
    return api.toggleTask(meetingId, taskId).then((task) => {
      dispatch({ type: actionTypes.UPDATE, payload: task });
    });
  };

  const createTask = (meetingId, params) => {
    return api.createTask(meetingId, params).then((task) => {
      dispatch({ type: actionTypes.ADD, payload: task });
    });
  };

  const updateTask = (meetingId, taskId, params) => {
    return api.updateTask(meetingId, taskId, params).then((task) => {
      dispatch({ type: actionTypes.UPDATE, payload: task });
    });
  };

  const deleteTask = (meetingId, taskId) => {
    return api.deleteTask(meetingId, taskId).then((task) => {
      dispatch({ type: actionTypes.DELETE, payload: task });
    });
  };

  const setTasks = (tasks) => {
    dispatch({ type: actionTypes.SET, payload: tasks });
  };

  return {
    setTasks,
    toggleTask,
    createTask,
    updateTask,
    deleteTask,
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET:
      return { tasks: payload };
    case actionTypes.ADD:
      const isAdded = state.tasks.find((item) => item.id === payload.id);

      if (isAdded) {
        return state;
      }

      return { tasks: [...state.tasks, payload] };
    case actionTypes.UPDATE:
      return { tasks: updateById(state.tasks, payload) };
    case actionTypes.DELETE:
      return { tasks: deleteById(state.tasks, payload) };
  }
};

const [TasksProvider, useTasksStore, useTasksDispatch] = createStore(reducer, initialState);

export { TasksProvider, useTasksStore, useTasksActions };
