import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const placeholder = css`
  text-align: center;
  padding: 20px;

  &:before {
    content: '';
    display: inline-block;
    width: 68px;
    margin: 0 auto;
    height: 68px;
    background: ${colors.placeholderGrey};
    border-radius: 50%;
  }
`;

export const content = css`
  text-align: left;
`;

export const wrapper = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -70px;
`;

export const image = css`
  /* margin: 0 0 0 -25px; */
  flex: 1 1 100%;
`;

export const message = css`
  ${fonts.regular}
  font-size: 14px;
  margin-top: 10px;
  color: ${colors.mediumGrey};
`;
