import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AttachmentState } from 'contexts/v2/FileUploadContext';
import IconButton from 'components/v2/shared/IconButton';
import { FiX, FiTrash2, FiRotateCcw } from 'react-icons/fi';

const attachmentStateLabelMap = {
  [AttachmentState.Failed]: 'Failed',
  [AttachmentState.Enqueued]: 'Preparing',
  [AttachmentState.Pending]: 'Uploading',
  [AttachmentState.Cancelled]: 'Cancelled',
};

const Attachment = ({ name, progress, error, state, onRemove, onCancel, onRestart }) => (
  <div className='tw-flex tw-w-full tw-flex-col tw-gap-y-1'>
    <div className='tw-flex tw-items-center tw-justify-between'>
      <div className='tw-text-sm tw-font-semibold tw-leading-none tw-text-black'>{attachmentStateLabelMap[state]}</div>

      <div className='tw-ml-2 tw-flex tw-items-center tw-justify-center tw-text-xs empty:tw-hidden'>
        {state === AttachmentState.Failed && <IconButton title='Remove' onClick={onRemove} children={<FiTrash2 />} />}
        {state === AttachmentState.Pending && <IconButton title='Cancel' onClick={onCancel} children={<FiX />} />}
        {state === AttachmentState.Cancelled && (
          <>
            <IconButton title='Restart' onClick={onRestart} children={<FiRotateCcw />} />
            <IconButton title='Remove' className='tw-ml-1' onClick={onRemove} children={<FiTrash2 />} />
          </>
        )}
      </div>
    </div>

    <div className='tw-whitespace-nowrap tw-text-xs tw-text-red-400'>{error}</div>

    <div className='tw-relative tw-h-1.5 tw-overflow-hidden tw-rounded tw-bg-purple-100'>
      <div
        className={clsx('tw-absolute tw-inset-0 tw-z-0 -tw-translate-x-full tw-rounded tw-transition-transform', {
          'tw-bg-red-400': state === AttachmentState.Failed,
          'tw-bg-purple-500': state === AttachmentState.Pending,
          'tw-bg-gray-500': state === AttachmentState.Cancelled,
        })}
        style={{ transform: `translateX(${progress - 100}%)` }}
      ></div>
    </div>

    <div className='tw-truncate tw-text-sm tw-font-semibold tw-text-gray-700'>{name}</div>
  </div>
);

Attachment.propTypes = {
  name: PropTypes.string,
  progress: PropTypes.number,
  error: PropTypes.string,
  state: PropTypes.oneOf(Object.keys(attachmentStateLabelMap)),
  onRemove: PropTypes.func,
  onCancel: PropTypes.func,
  onRestart: PropTypes.func,
};

export default Attachment;
