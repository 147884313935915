/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import Text from './../Text';

import * as styles from './LabeledRow.styles';

const LabeledRow = ({ labelWidth = 3, children, label, isBold = true, className }) => (
  <Row className={className}>
    <Col lg={labelWidth}>
      <Text isBold={isBold} text={label} />
    </Col>

    <Col>{children}</Col>
  </Row>
);

LabeledRow.propTypes = {
  labelWidth: PropTypes.number,
  children: PropTypes.node,
  label: PropTypes.string,
  isBold: PropTypes.bool,
  className: PropTypes.string,
};

export default LabeledRow;
