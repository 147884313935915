import { css } from '@emotion/core';
import { colors } from 'styles';

export const modal = css`
  .modal-dialog.shaparency {
    width: 750px;

    .modal-footer {
      background: ${colors.backgroundGrey};
      padding: 0;
    }
  }
`;
