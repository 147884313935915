import { css } from '@emotion/core';
import { colors, panels } from 'styles';

export const panel = panels.base;

export const sidebar = css`
  padding: 42px;
  border-right: 1px solid ${colors.greyBorder};
`;

export const content = css`
  padding: 42px 55px 42px 40px;
`;
