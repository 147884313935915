import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const container = {
  base: css`
    border-top: 1px solid #d8dae3;
    overflow: auto;
    display: none;
    height: 100%;
    max-height: 200px;
  `,

  visible: css`
    display: block;
  `,
};
