import { useState } from 'react';
import { useValidateResolutionsMutation } from './../data/mutations/ValidateResolutionsMutation';

const useValidateResolutions = ({ onError, onCompleted }) => {
  const update = (cache, { data: { validateResolutions } }) => {
    const { vote, errors } = validateResolutions;

    if (errors.length) {
      return onError();
    }

    onCompleted();
  };

  const [mutate] = useValidateResolutionsMutation({ update });

  const validateResolutions = (voteId) => mutate({ variables: { input: voteId } });

  return {
    validateResolutions,
  };
};

export { useValidateResolutions };
