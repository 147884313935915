import { gql, useMutation } from '@apollo/client';
import { MEETING_FRAGMENT } from 'data/v2/fragments/MeetingFragment';

const CREATE_MEETING_MUTATION = gql`
  mutation createMeeting($input: CreateMeetingInput!) {
    createMeeting(input: $input) {
      meeting {
        ...MeetingFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${MEETING_FRAGMENT}
`;

const useCreateMeetingMutation = (options) => useMutation(CREATE_MEETING_MUTATION, options);

export { CREATE_MEETING_MUTATION, useCreateMeetingMutation };
