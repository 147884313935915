import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PdfViewer from 'components/v2/shared/PdfViewer';
import PdfViewerTopbar from 'components/v2/shared/PdfViewerTopbar';
import Modal from 'components/v2/shared/Modal';
import { previewDisabledElements } from 'components/meetings/PdfViewer/constants';
import Spinner from 'components/v2/shared/Spinner';

const FileViewer = ({ fileName, fileUrl, isOpen, onClose, onDocumentLoaded }) => {
  const [instance, setInstance] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);

  const handleAfterLeave = () => setShowSpinner(true);

  const handleDownload = () => {
    instance.UI.downloadPdf({
      filename: fileName,
      downloadType: 'pdf',
    });
  };

  const handlePdfViewerInit = (instance) => {
    setInstance(instance);

    instance.UI.iframeWindow.document.querySelector('.custom-ribbons-container').remove();
    instance.UI.disableFeatures([instance.Feature.ThumbnailReordering, instance.Feature.ThumbnailMerging]);

    instance.Core.documentViewer.addEventListener('documentLoaded', async () => {
      await onDocumentLoaded?.(instance);
      setShowSpinner(false);
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='fullScreen' isDismissable={false} afterLeave={handleAfterLeave}>
      <div className='tw-relative tw-flex tw-h-screen tw-w-full tw-w-full tw-flex-col'>
        <PdfViewerTopbar title={fileName} onClose={onClose} onDownload={handleDownload} isDownloadable={!showSpinner} />

        {fileUrl && (
          <PdfViewer
            documentUrl={fileUrl}
            onInit={handlePdfViewerInit}
            config={{ disabledElements: previewDisabledElements, enableReadOnlyMode: true }}
          />
        )}

        {showSpinner && <Spinner show variant='regular' className='tw-bg-black/20' />}
      </div>
    </Modal>
  );
};

FileViewer.propTypes = {
  fileUrl: PropTypes.string,
  fileName: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onDocumentLoaded: PropTypes.func,
};

export default FileViewer;
