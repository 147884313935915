/** @jsx jsx */

import React, { memo, useRef } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { CommentThreadWrapper } from './../CommentThreadWrapper';
import { Comment } from './../Comment';
import { CommentForm } from './../CommentForm';
import { Scrollable } from 'components/shared/Scrollable';
import { useVisibility } from 'hooks/useVisibility';

const CommentThread = memo(
  ({ minuteId, minuteOwnerId, currentContactId, root, replies, onCancel, onEdit, isReadOnly }) => {
    const { isComponentVisible, hideComponent } = useVisibility(true);

    const listBody =
      replies &&
      replies.map((reply) => (
        <Comment
          key={`reply-${reply.id}`}
          minuteId={minuteId}
          minuteOwnerId={minuteOwnerId}
          currentContactId={currentContactId}
          comment={reply}
          isReadOnly={isReadOnly}
          onEdit={onEdit}
        />
      ));

    const scrollBottom = () => {
      scrollableRef.current.scrollToBottom();
    };

    const scrollableRef = useRef();
    const textAreaRef = useRef();

    const onShow = () => {
      textAreaRef.current.focus();
    };

    return (
      <CommentThreadWrapper isVisible={isComponentVisible} onHide={onCancel} onShow={onShow}>
        {isReadOnly && (
          <div className='d-flex p-l-10 p-t-10' style={{ background: '#F7FEFF' }}>
            <button onClick={hideComponent} className='btn btn-primary btn-sm'>
              <i className='fa fa-chevron-left m-r-5' />
              <FormattedMessage id='CommentThread.back' />
            </button>
          </div>
        )}

        <Scrollable ref={scrollableRef}>
          <Comment
            minuteId={minuteId}
            minuteOwnerId={minuteOwnerId}
            onEdit={onEdit}
            currentContactId={currentContactId}
            comment={root}
            replies={replies}
            isReadOnly={isReadOnly}
            isHighlighted
          />

          {listBody}
        </Scrollable>

        {!isReadOnly && (
          <CommentForm
            textAreaRef={textAreaRef}
            minuteId={minuteId}
            parentId={root.id}
            onSubmit={scrollBottom}
            onCancel={hideComponent}
          />
        )}
      </CommentThreadWrapper>
    );
  }
);

export { CommentThread };
