import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const VisibilityWrapper = ({
  name,
  isVisible,
  children,
  className,
  invisibleClass = 'tw-hidden',
  visibleClass = 'tw-block',
}) => (
  <div
    name={name}
    className={clsx(className, {
      [visibleClass]: isVisible,
      [invisibleClass]: !isVisible,
    })}
  >
    {children}
  </div>
);

VisibilityWrapper.propTypes = {
  name: PropTypes.string,
  isVisible: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  invisibleClass: PropTypes.string,
  visibleClass: PropTypes.string,
};

export default VisibilityWrapper;
