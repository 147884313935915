import React from 'react';
import { MockedProvider } from '@apollo/client/testing';
import { SELECT_DOCUMENT_MODAL_QUERY } from 'data/v2/queries/SelectDocumentModalQuery';
import { WildcardMockLink } from 'wildcard-mock-link';
import { ASC, DESC } from 'data/globals/SortDirections';
import { PARENT_TYPE, ITEMS_PER_PAGE, SIDEBAR_ALL_ITEM } from 'contexts/v2/SelectDocumentModalContext';

const rootDirectories = [
  {
    id: 'gid://shaparency/Directory/1',
    name: 'Meeting Folders',
    __typename: 'Directory',
    capabilities: {
      canCreate: false,
    },
  },
  {
    id: 'gid://shaparency/Directory/2',
    name: 'Personal Folders',
    __typename: 'Directory',
    capabilities: {
      canCreate: true,
    },
  },
  {
    id: 'gid://shaparency/Directory/3',
    name: 'Company Folders',
    __typename: 'Directory',
    capabilities: {
      canCreate: true,
    },
  },
  {
    id: 'gid://shaparency/Directory/4',
    name: 'SmartSign',
    __typename: 'Directory',
    capabilities: {
      canCreate: true,
    },
  },
];

const edges = [
  {
    node: {
      id: 'gid://shaparency/Directory/5',
      name: 'Sample folder',
      __typename: 'Directory',
      capabilities: {
        canCreate: true,
      },
    },
  },
  {
    node: {
      id: 'gid://shaparency/Document/1',
      name: 'Sample document 1',
      __typename: 'Document',
    },
  },
  {
    node: {
      id: 'gid://shaparency/Document/2',
      name: 'Sample document 2',
      __typename: 'Document',
    },
  },
  {
    node: {
      id: 'gid://shaparency/Document/3',
      name: 'Sample document 3',
      __typename: 'Document',
    },
  },
  {
    node: {
      id: 'gid://shaparency/Document/4',
      name: 'Sample document 4',
      __typename: 'Document',
    },
  },
  {
    node: {
      id: 'gid://shaparency/Document/5',
      name: 'Sample document 5',
      __typename: 'Document',
    },
  },
  {
    node: {
      id: 'gid://shaparency/Document/6',
      name: 'Sample document 6',
      __typename: 'Document',
    },
  },
  {
    node: {
      id: 'gid://shaparency/Document/7',
      name: 'Sample document 7',
      __typename: 'Document',
    },
  },
  {
    node: {
      id: 'gid://shaparency/Document/8',
      name: 'Sample document 8',
      __typename: 'Document',
    },
  },
];

const pageInfo = {
  hasNextPage: false,
  endCursor: 'MTU',
  __typename: 'PageInfo',
};

const makeVars = (args = {}) => ({
  first: ITEMS_PER_PAGE,
  parentId: SIDEBAR_ALL_ITEM.id,
  parentType: PARENT_TYPE,
  searchTerm: '',
  sortDirection: DESC,
  ...args,
});

const makeRequest = (queryVars = {}) => ({
  query: SELECT_DOCUMENT_MODAL_QUERY,
  variables: makeVars(queryVars),
});

const makeResponse = (edges) => ({
  data: {
    currentCompany: {
      id: 'gid://shaparency/Company/1',
      __typename: 'Company',
      rootDirectories,
      searchableEntries: {
        pageInfo,
        edges,
      },
    },
  },
});

export const mocks = [
  {
    nMatches: Number.POSITIVE_INFINITY,
    request: makeRequest(),
    result: makeResponse(edges),
  },

  {
    nMatches: Number.POSITIVE_INFINITY,
    request: makeRequest({ sortDirection: ASC }),
    result: makeResponse([...edges].reverse()),
  },

  {
    nMatches: Number.POSITIVE_INFINITY,
    request: makeRequest({ parentId: 'gid://shaparency/Directory/1' }),
    result: makeResponse([edges[1], edges[2]]),
  },

  {
    nMatches: Number.POSITIVE_INFINITY,
    request: makeRequest({ parentId: 'gid://shaparency/Directory/1', sortDirection: ASC }),
    result: makeResponse([edges[2], edges[1]]),
  },

  {
    nMatches: Number.POSITIVE_INFINITY,
    request: makeRequest({ parentId: 'gid://shaparency/Directory/2' }),
    result: makeResponse([edges[3], edges[4]]),
  },

  {
    nMatches: Number.POSITIVE_INFINITY,
    request: makeRequest({ parentId: 'gid://shaparency/Directory/2', sortDirection: ASC }),
    result: makeResponse([edges[4], edges[3]]),
  },

  {
    nMatches: Number.POSITIVE_INFINITY,
    request: makeRequest({ parentId: 'gid://shaparency/Directory/3' }),
    result: makeResponse([edges[5], edges[6]]),
  },

  {
    nMatches: Number.POSITIVE_INFINITY,
    request: makeRequest({ parentId: 'gid://shaparency/Directory/3', sortDirection: ASC }),
    result: makeResponse([edges[6], edges[5]]),
  },

  {
    nMatches: Number.POSITIVE_INFINITY,
    request: makeRequest({ parentId: 'gid://shaparency/Directory/4' }),
    result: makeResponse([edges[7], edges[8]]),
  },

  {
    nMatches: Number.POSITIVE_INFINITY,
    request: makeRequest({ parentId: 'gid://shaparency/Directory/4', sortDirection: ASC }),
    result: makeResponse([edges[8], edges[7]]),
  },

  {
    nMatches: Number.POSITIVE_INFINITY,
    request: makeRequest({ searchTerm: 'Folder' }),
    result: makeResponse([edges[0]]),
  },

  {
    nMatches: Number.POSITIVE_INFINITY,
    request: makeRequest({ sortDirection: ASC, searchTerm: 'Folder' }),
    result: makeResponse([edges[0]]),
  },
];

const link = new WildcardMockLink(mocks);

export const withMockedProvider = (children) => <MockedProvider link={link}>{children}</MockedProvider>;
