import { gql, useMutation } from '@apollo/client';
import { SUBCOMMITTEE_FRAGMENT } from '../fragments/SubcommitteeFragment';

const DELETE_SUBCOMMITTEE_MUTATION = gql`
  mutation deleteSubcommittee($input: DeleteSubcommitteeInput!) {
    deleteSubcommittee(input: $input) {
      subcommittee {
        ...SubcommitteeFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${SUBCOMMITTEE_FRAGMENT}
`;

const useDeleteSubcommitteeMutation = (options) => useMutation(DELETE_SUBCOMMITTEE_MUTATION, options);

export { DELETE_SUBCOMMITTEE_MUTATION, useDeleteSubcommitteeMutation };
