import { gql, useMutation } from '@apollo/client';

const SHARE_DOCUMENT = gql`
  mutation shareDocument($input: ShareDocumentInput!) {
    shareDocument(input: $input) {
      document {
        id
        shared
      }
    }
  }
`;

const useShareDocumentMutation = (options) => useMutation(SHARE_DOCUMENT, options);

export { useShareDocumentMutation };
