import React, { useRef, useEffect, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SubcommitteesTableRow } from '../SubcommitteeTableRow';
import { Spinner } from 'components/shared/Spinner';
import { PlaceholderTable } from '../PlaceholderTable';
import { FadingOverlay } from 'components/shared/PlaceholderTable';
import { useSubcommitteesQuery } from '../data/queries/SubcommitteesQuery';
import { sort } from 'fast-sort';

const SubcommitteesTable = memo(({ onEdit, data, loading, refetch }) => {
  const ref = useRef();

  const intl = useIntl();

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('subcommittees:reload', refetch);

      return () => {
        node.removeEventListener('subcommittees:reload', refetch);
      };
    }
  }, [data]);

  if (loading) {
    return <Spinner show />;
  }

  const { subcommittees, accessRoles } = data.currentCompany;

  const tableRows = sort(subcommittees)
    .desc((item) => item.createdAt)
    .map((subcommittee) => {
      const handleEdit = onEdit.bind(this, subcommittee, accessRoles);
      return (
        <SubcommitteesTableRow
          key={subcommittee.id}
          name={subcommittee.name}
          slug={subcommittee.slug}
          size={subcommittee.size}
          id={subcommittee.id}
          onEdit={handleEdit}
        />
      );
    });

  return (
    <div className='position-relative'>
      <FadingOverlay
        show={!subcommittees.length}
        resource={intl.formatMessage({ id: 'SubcommitteesTable.subcommittees' })}
      />
      <table className='table table-layout-fixed subcommittees-table' ref={ref}>
        <thead>
          <tr>
            <th className='w-70'>
              <FormattedMessage id='SubcommitteesTable.name' />
            </th>
            <th className='w-auto' />
          </tr>
        </thead>

        <tbody>
          {tableRows}
          <PlaceholderTable show={!subcommittees.length} />
        </tbody>
      </table>
    </div>
  );
});

export { SubcommitteesTable };
