import React, { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { withIntl } from '../../../utils/v2/intl';
import { AlertPopup } from 'components/shared/AlertPopup';
import useWindowDimensions from 'hooks/useWindowDimensions';

const ButtonWithConfirmation = ({
  button,
  headerText,
  confirmButtonText,
  confirmButtonUrl,
  dataMethod,
  singleOption,
  alertChildren,
  confirmationText,
  onTransitionEnd,
  onConfirm,
  popupType,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { isMobile } = useWindowDimensions();

  const intl = useIntl();

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  const handleConfirm = () => {
    onConfirm && onConfirm();
    hide();
  };

  const confirmationButtonText = useMemo(() => {
    return confirmButtonText || intl.formatMessage({ id: 'ButtonWithConfirmation.yesConfirm' });
  }, []);

  return (
    <>
      <div className='d-flex align-items-center' style={{ flex: isMobile ? '1 1 100%' : '0 1 auto' }} onClick={show}>
        {button}
      </div>

      <AlertPopup
        onTransitionEnd={onTransitionEnd}
        show={isVisible}
        popupType={popupType || 'info'}
        headerText={headerText || intl.formatMessage({ id: 'ButtonWithConfirmation.confirmation' })}
        onClose={hide}
      >
        <div className='text-black fs-14 lh-22'>{confirmationText}</div>

        {alertChildren}

        <div className='d-flex align-items-center m-t-20 justify-content-center'>
          {confirmButtonUrl && (
            <a
              href={confirmButtonUrl}
              data-method={`${dataMethod ? dataMethod : 'put'}`}
              className={`btn btn-sm-fw btn-${popupType || 'info'}`}
            >
              {confirmationButtonText}
            </a>
          )}

          {!confirmButtonUrl && (
            <button className={`btn btn-sm-fw btn-${popupType || 'info'}`} onClick={handleConfirm}>
              {confirmationButtonText}
            </button>
          )}

          {!singleOption && (
            <button className={`btn btn-sm-fw btn-${popupType || 'info'}-inverted m-l-10`} onClick={hide}>
              <FormattedMessage id='ButtonWithConfirmation.cancel' />
            </button>
          )}
        </div>
      </AlertPopup>
    </>
  );
};

export default withIntl(ButtonWithConfirmation);
