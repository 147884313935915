/** @jsx jsx */

import React, { memo, useEffect } from 'react';
import { jsx } from '@emotion/core';

import { Scrollable } from 'components/shared/Scrollable';
import { BreadcrumbsDropdownItem } from './../BreadcrumbsDropdownItem';
import { useClickOutside } from 'hooks/useClickOutside';

import { ReactComponent as ArrowIcon } from 'images/icons/documents/breadcrumb-arrow-right.svg';
import { ReactComponent as DropdownIcon } from 'images/icons/documents/breadcrumbs.svg';

import * as styles from './BreadcrumbsDropdown.styles';

const BreadcrumbsDropdown = memo(({ options, onClick }) => {
  if (!options.length) {
    return null;
  }

  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(false);

  const toggleVisibility = () => {
    if (!onClick) return;

    setIsComponentVisible(!isComponentVisible);
  };

  useEffect(() => setIsComponentVisible(false), [options]);

  const dropdownOptions = options.map((breadcrumb) => {
    const key = `breadcrumb-${breadcrumb.id}`;
    const handleClick = onClick && onClick.bind(this, breadcrumb.id);

    return <BreadcrumbsDropdownItem key={key} name={breadcrumb.name} onClick={handleClick} />;
  });

  const dropdown = isComponentVisible && (
    <div css={styles.dropdown}>
      <Scrollable css={styles.scrollable} translateContentSizesToHolder>
        {dropdownOptions}
      </Scrollable>
    </div>
  );

  const iconStyles = [styles.icon.base, onClick && styles.icon.hoverable];

  return (
    <div css={styles.container} ref={ref}>
      <DropdownIcon css={iconStyles} onClick={toggleVisibility} />
      <ArrowIcon css={styles.arrow} />
      {dropdown}
    </div>
  );
});

export { BreadcrumbsDropdown };
