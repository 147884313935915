import { css } from '@emotion/core';
import { animations } from 'styles';

export const drawer = (width) => ({
  base: css`
    z-index: 9991;
    pointer-events: all;
    transform: none;
    transition: none;
    box-shadow: -20px 4px 50px rgba(54, 71, 98, 0.25);
    width: ${width}px;
    max-width: 100%;
  `,

  show: animations.slideIn(width, 0),
  hide: animations.slideOut(0, width),
});
