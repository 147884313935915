import { css } from '@emotion/core';
import { utils, icons, colors, fonts, media } from 'styles';

export const viewModeIcon = css`
  ${icons.base}

  svg {
    path {
      fill: ${colors.black};
    }
  }
`;

export const content = css`
  border-radius: 4px;
  padding: 13px 22px;
  margin-top: 10px;
  ${media.mobile} {
    padding: 10px;
  }
`;

export const heading = css`
  ${fonts.regular}
  font-size: 20px;
  margin-bottom: 0;
  color: ${colors.black};
`;

export const container = css`
  position: relative;
`;

export const circle = css`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const checkmark = css`
  position: absolute;
  left: -20px;
  top: 15px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  z-index: 3;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const padded = {
  root: css`
    padding: 12px 38px;
  `,

  child: css`
    padding: 12px 34px 12px 28px;
    padding: 60px;
  `,
};

export const themes = {
  pending: {
    content: {
      base: css`
        ${content}
        background: ${colors.agendaGrey};
        border: 1px solid;
        border-color: transparent;
        ${media.tablet} {
          border: none;
        }
      `,

      hovered: css`
        border-color: #dedde0; ;
      `,
    },

    container: {
      base: css`
        ${container}
        background: transparent;
        border: 1px solid;
        border-color: transparent;
        ${media.tablet} {
          border: none;
        }
      `,

      hovered: css`
        border-color: #dedde0;
      `,
    },

    circle: css`
      ${circle}
      border: 1px solid ${colors.lighterGrey};
      background: ${colors.white};

      &:hover {
        border-color: ${colors.cyan};
      }
    `,

    checkmark: css`
      ${checkmark}
      background: ${colors.white};
    `,
  },

  completed: {
    content: {
      base: css`
        ${content}
        background: #CFF2F4;
        border: 1px solid;
        border-color: transparent;
      `,

      hovered: css`
        border-color: ${colors.cyan};
      `,
    },

    container: {
      withBackground: css`
        background: ${colors.lightCyan};
      `,

      base: css`
        ${container}
        border: 1px solid;
        border-color: transparent;

        .ms-agenda-document-list-item {
          background: #e5f8f9;
        }

        .ms-agenda-task-list-item:hover {
          background: #e5f8f9;
        }

        .fa-pencil {
          color: ${colors.cyan};

          &:hover {
            opacity: 0.8;
          }
        }
      `,

      hovered: css`
        border-color: ${colors.cyan};
      `,
    },

    circle: css`
      ${circle}
      border: none;
      background: ${colors.cyan};

      &:hover {
        border-color: none;
      }
    `,

    checkmark: css`
      ${checkmark}
      background: #E6F8F9;
    `,
  },
};

export const wrapper = (paddingLeft, compactMode) => {
  return {
    base: css`
      padding-left: ${paddingLeft}px;
      display: ${compactMode ? 'none' : 'block'};
      ${media.tablet} {
        padding-left: 0;
      }
    `,

    root: css`
      padding-right: 32px;
    `,

    child: css`
      padding-right: 0;
    `,
  };
};

export const block = css`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;

    path {
      fill: ${colors.cyan};
    }
  }

  span {
    ${utils.uppercasedText}
  }

  hr {
    margin-left: 5px;
    color: ${colors.darkerGrey};
    width: 100%;
  }
`;

export const description = css`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.black};
`;
