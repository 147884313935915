import { gql, useMutation } from '@apollo/client';
import { COMPANY_FRAGMENT } from 'data/v2/fragments/CompanyFragment';

const CREATE_COMPANY_MUTATION = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      company {
        ...CompanyFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${COMPANY_FRAGMENT}
`;

const useCreateCompanyMutation = (options) => useMutation(CREATE_COMPANY_MUTATION, options);

export { CREATE_COMPANY_MUTATION, useCreateCompanyMutation };
