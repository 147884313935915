import { createStore } from './createStore';

import { trackPromise } from 'react-promise-tracker';
import { getAttributes } from 'helpers/getAttributes';

import { useOoEActions } from 'store/ooeStoreContext';
import { useCommentsActions } from 'store/commentsStoreContext';
import { useReviewsActions } from 'store/reviewsStoreContext';

import normalize from 'jsonapi-normalizer';
import axios from 'axios';

const companyId = getCompanySlug();

const initialState = {
  minutes: {},
};

const actionTypes = {
  SET: 'minute.set',
};

const api = {
  requestMinutesReview: (minuteId) => {
    const apiURL = `/api/${companyId}/v1/minutes/${minuteId}/request_review.json`;

    const promise = axios
      .post(apiURL)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  distributeMinutes: (minuteId, notify = true) => {
    const apiURL = `/api/${companyId}/v1/minutes/${minuteId}/distribute.json?notify=${notify}`;

    const promise = axios
      .post(apiURL)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },
};

const useMinutesActions = () => {
  const dispatch = useMinutesDispatch();

  const { setOoE } = useOoEActions();
  const { setComments } = useCommentsActions();
  const { setReviews } = useReviewsActions();

  const setMinutesContext = (minuteId, response) => {
    const data = normalize(response);

    const minute = data.entities.minute && data.entities.minute[minuteId];
    const ooe = data.entities.minute && data.entities.order_of_event[minute.order_of_event.id];
    const reviews = Object.values(data.entities.review || []);
    const comments = Object.values(data.entities.comment || []);

    setMinutes(minute);
    setOoE(ooe);
    setReviews(reviews);
    setComments(comments);
  };

  const setMinutes = (payload) => {
    dispatch({ type: actionTypes.SET, payload: payload });
  };

  const requestMinutesReview = (minuteId) => {
    return api.requestMinutesReview(minuteId).then((minute) => {
      dispatch({ type: actionTypes.SET, payload: minute });
    });
  };

  const distributeMinutes = (minuteId) => {
    return api.distributeMinutes(minuteId).then((minute) => {
      dispatch({ type: actionTypes.SET, payload: minute });
    });
  };

  const approveMinutes = (minuteId) => {
    return api.distributeMinutes(minuteId, false).then((minute) => {
      dispatch({ type: actionTypes.SET, payload: minute });
    });
  };

  return {
    setMinutesContext,
    setMinutes,
    requestMinutesReview,
    distributeMinutes,
    approveMinutes,
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET:
      return { minutes: payload };
  }
};

const [MinutesProvider, useMinutesStore, useMinutesDispatch] = createStore(reducer, initialState);

export { MinutesProvider, useMinutesStore, useMinutesActions };
