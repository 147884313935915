import { useState } from 'react';
import { useUpsertVoteMutation } from './../data/mutations/UpsertVoteMutation';

import { formatDate, formatTime } from 'helpers/formatDateTime';

const useUpsertVote = ({ onError, onCompleted }) => {
  const update = (cache, { data: { upsertVote } }) => {
    const { vote, errors } = upsertVote;
    const errorsCount = errors.length;

    if (errorsCount) {
      setErrors(errors);

      return onError(errorsCount);
    }

    onCompleted(errorsCount, vote.slug);
  };

  const [errors, setErrors] = useState(null);
  const [mutate, { loading: isUpserting }] = useUpsertVoteMutation({ update });

  const resetErrors = (attribute) => setErrors((errors) => errors.filter((error) => error.attribute !== attribute));

  const upsertVote = (payload) => {
    const params = Object.assign({}, payload);

    params.startDate = formatDate(payload.startDate);
    params.endDate = formatDate(payload.endDate);
    params.startTime = formatTime(payload.startTime);
    params.endTime = formatTime(payload.endTime);

    mutate({ variables: { input: params } });
  };

  return {
    errors,
    resetErrors,
    upsertVote,
    isUpserting,
  };
};

export { useUpsertVote };
