import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const hideTable = css`
  ${media.tablet} {
    display: none;
  }
`;

export const padded = css`
  padding: 35px 35px 0;
  ${media.tablet} {
    padding: 25px 25px 0;
  }
  ${media.mobile} {
    padding: 15px 15px 0;
    padding: 0;
  }
`;

export const wrapper = css`
  border: 1px solid ${colors.lighterGrey};
  padding: 32px;
  display: flex;
  height: 100%;
  flex-direction: column;
  ${media.tablet} {
    padding: 15px;
    background-color: ${colors.white};
    border: none;
    box-shadow: 2px 4px 4px rgba(223, 223, 223, 0.25);
  }
`;
export const sendReviewBlock = css`
  ${media.tablet} {
    margin-bottom: 15px;
  }
`;

export const sendReviewWrapper = css`
  padding-bottom: 100px;
  ${media.tablet} {
    padding-bottom: 40px;
  }
  ${media.mobile} {
    padding-bottom: 15px;
  }
`;
