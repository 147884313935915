import { gql } from '@apollo/client';

const ANNOTATION_REMOVED_SUBSCRIPTION = gql`
  subscription AnnotationDeletedSubscription {
    annotationDeleted {
      externalId
      data
    }
  }
`;

export default ANNOTATION_REMOVED_SUBSCRIPTION;
