import { css } from '@emotion/core';
import { media } from 'styles';

export const startWrapper = css`
  margin-right: 30px;
  ${media.mobile} {
    margin-right: 0;
  }
`;

export const durationWrapped = css`
  display: flex;
  flex-wrap: wrap;
`;

export const dataInputWrapper = css`
  min-width: 135px;
`;
