import { css } from '@emotion/core';
import { colors, fonts } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const line = css`
  border-bottom: 1px solid ${colors.purple};
  width: 100%;
`;

export const closeIcon = css`
  cursor: pointer;
  opacity: 1;
  outline: none;
  width: auto;
  margin: 0;

  &:hover {
    opacity: 0.75;
  }
`;

export const format = css`
  ${fonts.bold}
  font-size: 14px;
  color: ${colors.purple};
  padding: 0 4px;
`;

export const details = css`
  color: ${colors.purple};
  font-size: 14px;
  margin: 0 6px;
  white-space: nowrap;
`;

export const heading = css`
  ${fonts.bold}
  font-size: 14px;
  color: ${colors.black};
  line-height: 20px;
`;

export const header = css`
  padding: 0 8px;
`;

export const signatoryBlock = css`
  display: flex;
  align-items: center;
  max-width: calc(100% - 75px);
`;

export const signatory = (isActive) => css`
  ${isActive ? fonts.bold : fonts.regular}
  color: ${isActive ? colors.cyan : colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid;
  padding: 8px 13px 8px 9px;
  margin-bottom: 8px;
  border-radius: 2px;
  border-color: ${isActive ? colors.cyan : 'transparent'};
  background: ${isActive ? HexToRgba(colors.cyan, 10) : 'transparent'};

  &:hover {
    cursor: pointer;
  }
`;

export const addFields = css`
  ${fonts.regular}
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.15em;
  flex-shrink: 0;
  color: ${colors.black};
`;

export const signatoryName = css`
  line-height: 20px;
  font-size: 14px;
  color: ${colors.black};
  padding-left: 3px;
`;

export const padded = css`
  padding-left: 12px;
  padding-right: 12px;
`;

export const active = (isActive) => css`
  color: ${isActive ? colors.cyan : colors.lightGrey};

  path {
    fill: ${isActive ? colors.cyan : colors.lightGrey};
  }

  span {
    ${isActive ? fonts.bold : fonts.regular}
    font-size: 14px;
    color: ${colors.black};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const helpBlock = css`
  ${fonts.regular};
  display: flex;
  justify-content: flex-end;
  padding: 20px 12px;
`;

export const helpContent = css`
  ${fonts.regular};
  font-size: 12px;
  line-height: 16px;
  width: 142px;
  max-width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 10px;
  color: ${colors.brandTextDark};
  color: #00031f;
  &::before {
    content: '*';
    font-size: 30px;
    line-height: 20px;
    color: ${colors.cyan};
    position: absolute;
    top: 3px;
    left: 0;
  }
`;

export const helpArrow = css`
  position: absolute;
  right: 100%;
  top: 16px;
`;
