import React, { useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';

const Dropzone = forwardRef(
  ({ maxFiles = 0, multiple = true, accept, onDrop, isDisabled, className, children }, ref) => {
    const { getRootProps, open, getInputProps, isDragActive } = useDropzone({
      minSize: 0,
      maxSize: 20971520,
      accept,
      multiple,
      maxFiles,
      onDrop,
      disabled: isDisabled,
    });

    useImperativeHandle(ref, () => ({ open }), [open]);

    return (
      <div
        className={clsx('tw-box-border', className, {
          'tw-cursor-pointer': !isDisabled,
          'tw-cursor-not-allowed tw-opacity-30': isDisabled,
        })}
      >
        <div {...getRootProps()} className={clsx('tw-h-full', { 'tw-pointer-events-none': isDisabled })}>
          <input {...getInputProps()} />

          {children}
        </div>
      </div>
    );
  }
);

Dropzone.propTypes = {
  maxFiles: PropTypes.number,
  accept: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
  onDrop: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Dropzone;
