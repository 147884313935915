import { gql } from '@apollo/client';
import { BREADCRUMB_FRAGMENT } from './BreadcrumbFragment';

const DIRECTORY_FRAGMENT = gql`
  fragment DirectoryFields on Directory {
    id
    name
    slug
    favourite
    shared
    hasChildren
    withSmartDocuments
    inSmartSignDirectory
    empty
    createdAt
    directoriesCount
    documentsCount

    parent {
      id
    }

    breadcrumbs {
      ...BreadcrumbFields
    }
  }
  ${BREADCRUMB_FRAGMENT}
`;

export { DIRECTORY_FRAGMENT };
