import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';

export const resolutionHeading = css`
  ${fonts.bold}
  font-size: 16px;
  color: ${colors.black};
  line-height: 28px;
  padding-bottom: 20px;
  display: none;
  ${media.mobile} {
    display: block;
  }
`;
