/** @jsx jsx */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Document from 'images/icons/document.svg';
import * as styles from './FileBrowser.styles';
import { jsx } from '@emotion/core';

const CurrentDocument = ({
  multiple,
  handleMultiClick,
  handleClick,
  documentClassName,
  extension,
  name,
  path,
  searchMode,
  createdAt,
  context,
}) => {
  const timestampFormat = 'DD.MM.YYYY hh:mm A';

  const acceptedFileExtensions = ['ppt', 'pptx', 'pdf', 'doc', 'docx'];

  const isDisabled = () => {
    if (context !== 'smart_sign') return false;

    return !acceptedFileExtensions.includes(extension);
  };

  const handleDocumentClick = () => {
    if (isDisabled()) return;

    return multiple ? handleMultiClick() : handleClick();
  };

  return (
    <Row>
      <Col>
        <div onClick={handleDocumentClick} className={`${documentClassName} ${isDisabled() ? 'disabled' : ''}`}>
          <div className='directory-container dir-link'>
            <div className='d-flex align-items-center name-block'>
              <div className='flex-shrink-0 position-relative'>
                <img src={Document} className='m-0' />
                <span className='extension' css={styles.documentExtension}>
                  {extension}
                </span>
              </div>

              <div className='lh-1 w-100'>
                <h5 className='card-title m-t-0 m-b-0 m-l-20 text-truncate'>{`${name}.${extension}`}</h5>
                {searchMode && <span className='m-l-20 text-light-grey fs-10 card-title m-b-0'>{path}</span>}
              </div>
            </div>

            <span className='card-title m-t-0 m-b-0'>{moment(createdAt).parseZone().format(timestampFormat)}</span>
          </div>
          <div className='directory-row-overlay'>&nbsp;</div>
        </div>
      </Col>
    </Row>
  );
};

export const CurrentDocuments = ({
  currentFolderDocuments,
  selectedDocuments,
  setSelectedDocuments,
  searchMode,
  multiple,
  currentDocument,
  setCurrentDocument,
  context,
}) => {
  const isCurrentDocument = (document) => {
    return document.id === currentDocument?.id;
  };

  return (
    <>
      {currentFolderDocuments.map((document) => {
        const { name, extension, createdAt } = document;
        const breadcrumbs = document.breadcrumbs.map((node) => node.name);
        const path = [...breadcrumbs, document.parent.name].join(' > ');
        const handleClick = setCurrentDocument.bind(this, document);
        const handleMultiClick = () =>
          selectedDocuments.some((d) => d.id === document.id)
            ? setSelectedDocuments(selectedDocuments.filter((d) => d.id !== document.id))
            : setSelectedDocuments((state) => [...state, document]);

        const isCurrent = isCurrentDocument(document) || selectedDocuments.some((d) => d.id === document.id);
        const documentClassName = `react-document dir-card directory-row ${isCurrent ? 'selected' : ''}`;

        return (
          <React.Fragment key={document.id}>
            <CurrentDocument
              handleClick={handleClick}
              handleMultiClick={handleMultiClick}
              extension={extension}
              path={path}
              name={name}
              searchMode={searchMode}
              createdAt={createdAt}
              multiple={multiple}
              documentClassName={documentClassName}
              context={context}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
