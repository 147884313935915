import { css } from '@emotion/core';
import { colors, media } from 'styles';

export const videoGrid = css`
  min-height: 70vh;
  background-color: inherit !important;
  border-bottom: 1px solid ${colors.cyan} !important;

  ${media.mobile} {
    min-height: 40vh;
  }
`;

export const wrapper = css`
  position: relative;
  margin: 15px auto 50px;
`;

export const container = css`
  position: relative;
  width: 75%;
  border: 1px solid ${colors.cyan} !important;
  margin: auto;

  ${media.tablet} {
    width: 100%;
  }
`;

export const controlBar = css`
  background-color: inherit !important;
  position: relative !important;
  border-top: none !important;
`;

export const controlBarButton = css`
  background-color: ${colors.white} !important;
  padding: 10px !important;
  border-radius: 8px !important;

  .ch-control-bar-item-caret .ch-icon {
    transform: translateY(-4px);
  }

  .ch-control-bar-item-caret,
  .ch-control-bar-item-iconButton {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .ch-control-bar-item-caret:hover,
  .ch-control-bar-item-caret:focus,
  .ch-control-bar-item-iconButton:hover {
    background-color: ${colors.cyan};
  }

  ${media.mobile} {
    padding: 0 !important;
  }
`;

export const betaBadge = css`
  ${media.mobile} {
    display: none;
  }
`;

export const gridContainer = css`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

export const rosterContainer = css`
  height: auto !important;
  box-shadow: none !important;
  border-bottom: 1px solid ${colors.cyan} !important;
  background-color: ${colors.backgroundGrey} !important;

  ${media.mobile} {
    display: none;
  }
`;
