/** @jsx jsx */

import React, { memo, useCallback, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { useIntl, FormattedMessage } from 'react-intl';

import { getAttributes } from 'helpers/getAttributes';
import { Placeholder } from 'components/shared/Placeholder';
import { ReactComponent as ContactsIcon } from 'images/icons/no-contacts.svg';
import Avatar from 'components/shared/Avatar';

import { ReactComponent as ShareIcon } from 'images/icons/shared-grey.svg';

import * as styles from './ContactsTable.styles';

const ContactsTable = memo(
  ({ contacts, sharedContactIds, ancestorSharedContactIds, permittedContactIds, onDelete }) => {
    const placeholderIcon = <ContactsIcon />;

    const intl = useIntl();

    if (!contacts || !contacts.length) {
      return <Placeholder image={placeholderIcon} message={intl.formatMessage({ id: 'ContactsTable.noContacts' })} />;
    }

    const initialCompanyContacts = () => {
      return filterCompanyContacts();
    };

    const filterCompanyContacts = () => {
      return contacts.filter((contact) =>
        sharedContactIds.concat(ancestorSharedContactIds, permittedContactIds).includes(getAttributes(contact).id)
      );
    };

    const [companyContacts, setCompanyContacts] = useState([initialCompanyContacts()]);

    useEffect(() => {
      setCompanyContacts(filterCompanyContacts());
    }, [sharedContactIds]);

    const contactPermitted = useCallback((contactId) => {
      return permittedContactIds.includes(contactId);
    });

    const contactShared = useCallback((contactId) => {
      return sharedContactIds.includes(contactId);
    });

    const contactSharedWithAncestor = useCallback((contactId) => {
      return ancestorSharedContactIds.includes(contactId);
    });

    const contactLabel = (contactId) => {
      if (contactPermitted(contactId)) {
        return (
          <div className='m-l-5'>
            <FormattedMessage id='ContactsTable.default' />
          </div>
        );
      } else {
        return (
          <div className='w-percent-10'>
            <ShareIcon />
          </div>
        );
      }
    };

    const tableBody = companyContacts.map((contact, index) => {
      const contactAttributes = getAttributes(contact);
      const handleDelete = onDelete.bind(this, contactAttributes);

      return (
        <tr
          css={styles.tableRow(!contact[index + 1])}
          key={contactAttributes.id}
          className={!contactShared(contactAttributes.id) ? 'opacity-50' : ''}
        >
          <td css={styles.tableCell} className='w-percent-80'>
            <div className='d-flex align-items-center'>
              <Avatar contact={contactAttributes} />
              <div className='text-truncate m-l-10'>{contactAttributes.name}</div>
            </div>
          </td>

          <td>{contactLabel(contactAttributes.id)}</td>

          {contactShared(contactAttributes.id) && (
            <td css={styles.tableCell} className='w-percent-10'>
              <i onClick={handleDelete} css={styles.deleteIcon} className='far fa-fw fa-trash-alt' />
            </td>
          )}
        </tr>
      );
    });

    return (
      <>
        <div css={styles.tableContainer}>
          <table css={styles.table}>
            <tbody>{tableBody}</tbody>
          </table>
        </div>
      </>
    );
  }
);

export { ContactsTable };
