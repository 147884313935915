/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import Avatar from 'components/v2/shared/Avatar';
import Chip from 'components/v2/shared/Chip';
import Text from 'components/v2/shared/Text';
import Icon from 'components/v2/shared/Icon';
import { VARIANTS } from './constants';

import * as styles from './ContactCard.styles';

const ContactCard = ({
  status,
  initials,
  avatarUrl,
  label,
  companyPosition,
  onClear,
  variantValueBackground = 'lightGrey',
  variant = 'base',
  onClick,
  className,
}) => {
  const chipColor = () => {
    switch (status) {
      case 'non-chair':
        return 'darkGrey';
      case 'requested':
      case 'confirmed':
      case 'registered':
        return 'cyan';
      default:
        return 'yellow';
    }
  };

  const statusText = () => {
    switch (status) {
      case 'non-chair':
      case 'chair':
        return 'Chair';
      case 'confirmed':
      case 'registered':
        return 'Accepted';
      case 'requested':
        return 'Awaiting acceptance';
      default:
        return 'Invited';
    }
  };

  const containerStyles = [
    styles.container.base,
    styles.container[variantValueBackground],
    !!onClick && styles.container.clickable,
  ];

  return (
    <div css={containerStyles} className={className} onClick={onClick}>
      <div css={styles.flex}>
        <Avatar text={initials} className='mr-3' />

        <div css={styles.textWrapper}>
          <Text isSemiBold fontSize={14} text={label} className='d-block' />
          <Text color='mediumGrey' text={companyPosition} />
        </div>
      </div>

      <Chip color={chipColor()} css={styles.chip[variant]}>
        <Text color='white' text={statusText()} />
      </Chip>

      {onClear && <Icon icon='close' onClick={onClear} />}
    </div>
  );
};

ContactCard.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string,
  initials: PropTypes.string,
  avatarUrl: PropTypes.string,
  companyPosition: PropTypes.string,
  variantValueBackground: PropTypes.string,
  variant: PropTypes.oneOf(VARIANTS),
  onClear: PropTypes.func,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default ContactCard;
