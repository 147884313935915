import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const modal = css`
  .modal-dialog.shaparency {
    .modal-content {
      .modal-footer {
        margin-top: 30px;
        padding: 16px 0;
      }
    }
  }
`;
