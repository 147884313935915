/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import Text from 'components/v2/shared/Text';
import CircleIcon from 'components/v2/shared/CircleIcon';

import MeetingDetailsStep from './../MeetingDetailsStep';
import HowToAttendStep from './../HowToAttendStep';
import InviteAndNoticeStep from './../InviteAndNoticeStep';
import AttendeesStep from './../AttendeesStep';
import AgendaStep from './../AgendaStep';
import TasksStep from './../TasksStep';
import VotingStep from './../VotingStep';
import OverviewStep from './../OverviewStep';

import * as styles from './MeetingFormContent.styles';

const MeetingFormContent = ({ title, activeStep, onBackClick }) => {
  const stepComponents = [
    <MeetingDetailsStep />,
    <HowToAttendStep />,
    <InviteAndNoticeStep />,
    <AttendeesStep />,
    <TasksStep />,
    <VotingStep />,
    <AgendaStep />,
    <OverviewStep />,
  ];

  return (
    <div css={styles.container}>
      <Row css={styles.header}>
        <Col lg='auto'>
          <CircleIcon icon='chevronLeft' isDisabled={activeStep <= 0} onClick={onBackClick} />
        </Col>

        <Col>
          <Text fontSize={20} isBold text={title} />
        </Col>
      </Row>

      <Row>
        <Col lg='auto'>
          <CircleIcon icon='chevronLeft' className='invisible' />
        </Col>

        <Col css={styles.activeStep}>{stepComponents[activeStep]}</Col>
      </Row>
    </div>
  );
};

MeetingFormContent.propTypes = {
  title: PropTypes.string,
  activeStep: PropTypes.number,
};

export default MeetingFormContent;
