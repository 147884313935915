import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SignatoryItem from 'components/v2/smartSign/SignatoryItem';

const SignatoryList = ({ items }) => (
  <div className='tw-flex tw-flex-col tw-gap-2'>
    {items.map((item) => (
      <SignatoryItem key={item.id} {...item} />
    ))}
  </div>
);

SignatoryList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      ...SignatoryItem.propTypes,
    })
  ),
};

export default SignatoryList;
