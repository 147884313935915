import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const checkbox = css`
  position: relative;
  margin-bottom: 10px;
`;

export const input = css`
  width: 0;
  height: 0;
`;

export const label = css`
  padding-left: 25px !important;
  &:before {
    left: 0 !important;
    margin: 0 !important;
  }
  &:after {
    left: 4px !important;
    margin: 0 !important;
  }
`;
