/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { FormattedMessage, useIntl } from 'react-intl';

import { Input } from 'components/shared/Input';
import { getAxiosHeaders } from 'utils/getHeaders';
import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { trackPromise } from 'react-promise-tracker';

const EditForm = ({ company, handleError, paymentMethod, onDelete, onUpdate }) => {
  const formattedMonth = paymentMethod.exp_month.toString().padStart(2, 0);
  const formattedYear = paymentMethod.exp_year.toString();

  let [expiryDate, setExpiryDate] = useState(`${formattedMonth} / ${formattedYear}`);
  let [isPrimary, setIsPrimary] = useState(paymentMethod.primary);

  const intl = useIntl();

  const deletePaymentMethod = () => {
    trackPromise(
      axios
        .delete(`/${company.slug}/payment_methods/${paymentMethod.id}`, getAxiosHeaders())
        .then(onDelete)
        .catch(handleError)
    );
  };

  const updatePaymentMethod = () => {
    const [expMonth, expYear] = expiryDate.split(' / ');

    let payload = {
      exp_year: Number(expYear),
      exp_month: Number(expMonth),
      is_primary: isPrimary,
    };

    trackPromise(
      axios
        .put(`/${company.slug}/payment_methods/${paymentMethod.id}`, payload, getAxiosHeaders())
        .then(onUpdate)
        .catch(handleError)
    );
  };
  const handleExpiryDateChange = (event) => {
    setExpiryDate(event.target.value);
  };

  const handlePrimaryChange = (event) => {
    setIsPrimary(event.target.checked);
  };

  const { isMobile } = useWindowDimensions();

  const deleteButton = isMobile ? (
    <button className='btn btn-sm-fw btn-light-red btn-light-red-inverted m-l-5'>
      <FormattedMessage id='EditForm.remove' />
    </button>
  ) : (
    <button className='btn btn-red border-0'>
      <div className='d-flex align-items-center'>
        <i className='far fa-trash-alt m-r-5 fs-14' />
        <span>
          <FormattedMessage id='EditForm.remove' />
        </span>
      </div>
    </button>
  );

  return (
    <div className='d-block w-100 p-t-30'>
      <h2 className='fs-18 font-weight-bold m-b-30'>
        <FormattedMessage id='EditForm.ccOrDebit' />
      </h2>
      <div style={{ width: isMobile ? '100%' : 420 }}>
        <Input
          name='cardNum'
          value={`•••• •••• •••• ${paymentMethod.last_digits || paymentMethod.last4}`}
          label={intl.formatMessage({ id: 'EditForm.cardNumber' })}
          isDisabled
          style={{ marginBottom: 20 }}
        />
        <div className='row' style={{ marginBottom: 20 }}>
          <div className='col-md-6 col-sm-12'>
            <InputMask alwaysShowMask mask='99 / 9999' value={expiryDate} onChange={handleExpiryDateChange}>
              <Input label={intl.formatMessage({ id: 'EditForm.expDate' })} placeholder='MM/YYYY' />
            </InputMask>
          </div>

          <div className={`col-md-6 ${isMobile && 'm-t-15'}`}>
            <Input
              name='sec'
              placeholder='•••'
              value='•••'
              isDisabled
              label={intl.formatMessage({ id: 'EditForm.secCode' })}
            />
          </div>
        </div>

        <div className='checkbox checkbox-primary' style={{ marginBottom: 20, marginLeft: 5 }}>
          <input
            type='checkbox'
            id='primary'
            checked={isPrimary}
            disabled={paymentMethod.primary}
            onChange={handlePrimaryChange}
          />

          <label htmlFor='primary' className='fs-14 text-black'>
            <FormattedMessage id='EditForm.primaryCard' />
          </label>
        </div>

        <div className='d-flex align-items-center m-b-10'>
          <button onClick={updatePaymentMethod} className='btn btn-primary btn-sm-fw'>
            {isMobile
              ? intl.formatMessage({ id: 'EditForm.save' })
              : intl.formatMessage({ id: 'EditForm.savePayment' })}
          </button>

          {!paymentMethod.primary && (
            <ButtonWithConfirmation
              confirmationText={intl.formatMessage({ id: 'EditForm.deleteConfirm' })}
              onConfirm={deletePaymentMethod}
              button={deleteButton}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditForm;
