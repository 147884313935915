/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import * as styles from './MenuItem.styles';

const MenuItem = ({ onClick, children }) => (
  <div css={styles.container} onClick={onClick}>
    {children}
  </div>
);

MenuItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default MenuItem;
