import React, { useContext, useState, useEffect } from 'react';
import Checkbox from 'components/v2/shared/Checkbox';
import Button from 'components/v2/shared/Button';
import Label from '../shared/Label/Label';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { getAxiosHeaders } from '../../../utils/getHeaders';
import Spinner from 'components/v2/shared/Spinner';
import Modal from 'components/v2/shared/Modal';
import Alert from 'components/v2/shared/Alert';
import { Severity } from '../shared/Toast';
import { ToastContext } from 'contexts/v2/ToastContext';
import { useCurrentUserProfileQuery } from 'data/v2/queries/CurrentUserProfileQuery';

const CustomTextField = ({ label, element }) => {
  return (
    <div className='tw-flex tw-flex-col tw-space-y-1'>
      <Label text={label} size='large' />
      <div className='tw-ring-solid tw-rounded-sm tw-p-5 !tw-text-[26px] tw-ring tw-ring-1 tw-ring-gray-500'>
        {element}
      </div>
    </div>
  );
};

const PaymentForm = ({ onClose, company, onAddPayment }) => {
  const stripe = useStripe();
  const elements = useElements();
  const onCancelPayment = () => {
    onClose(false);
  };
  const [isChecked, setChecked] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { setToastInfo } = useContext(ToastContext);
  const [alert, setAlert] = useState({ display: false, isValid: false });
  const [loadingStripe, setLoadingStripe] = useState(true);
  const onChangePrimaryCard = () => {
    setChecked((state) => !state);
  };

  useEffect(() => {
    if (stripe && elements) {
      setLoadingStripe(false);
    }
  }, [stripe, elements]);

  const user = useCurrentUserProfileQuery();

  const checkIfCardExist = async (paymentId) => {
    if (user?.data) {
      const header = { Authorization: `Bearer ${ENV['STRIPE_SECRET']}` };
      try {
        const paymentResult = await axios.get(`${ENV['STRIPE_API']}/payment_methods/${paymentId}`, { headers: header });

        const newCardFingerPrint = paymentResult.data.card.fingerprint;

        const customerResult = await axios.get(`${ENV['STRIPE_API']}/customers`, {
          headers: header,
          params: { email: user.data.currentUser.email },
        });
        const customerId = customerResult.data.data[0].id;

        const paymentResponse = await axios.get(`${ENV['STRIPE_API']}/payment_methods`, {
          headers: header,
          params: {
            customer: customerId,
            type: 'card',
          },
        });

        const userCardList = paymentResponse.data.data;

        const duplicate = userCardList.filter((pm) => pm.card.fingerprint === newCardFingerPrint);

        if (duplicate.length > 0) {
          return true;
        }
      } catch (err) {
        setToastInfo({
          severity: Severity.Error,
          status: 'Error',
          message: err.message,
        });
      }
    }
    return false;
  };

  const savePaymentMethod = async (paymentMethod) => {
    const payload = {
      payment_method: paymentMethod.id,
      is_primary: isChecked,
    };

    try {
      const response = await axios.post(`/${company.slug}/payment_methods/`, payload, getAxiosHeaders());

      onAddPayment(response.data);
      setToastInfo({
        severity: Severity.Success,
        status: 'Success',
        message: `Payment method has been successfully added.`,
      });
    } catch (err) {
      setToastInfo({
        severity: Severity.Error,
        status: 'Error',
        message: err.message,
      });
    }
    onClose();
    setLoading(false);
  };

  const handleAdd = () => {
    setLoading(true);
    const cardElement = elements.getElement(CardNumberElement);
    const handleResult = async (result) => {
      if (result.error) {
        setLoading(false);
        onClose();
        setToastInfo({
          severity: Severity.Error,
          status: 'Error',
          message: result.error.message,
        });
      } else {
        let exist = await checkIfCardExist(result.paymentMethod.id);
        if (!exist) {
          savePaymentMethod(result.paymentMethod);
        } else {
          setLoading(false);
          setAlert({
            display: true,
            isValid: false,
          });
        }
      }
    };

    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      .then(handleResult);
  };

  return (
    <>
      {loadingStripe ? (
        <div className='tw-relative tw-mt-8 tw-mb-5 tw-p-5'>
          <Spinner />
        </div>
      ) : (
        <div className='tw-p-16'>
          <p className='tw-m-0 tw-font-sans tw-text-xl tw-font-bold tw-text-black'>Add payment method</p>
          <div className='tw-mt-6'>
            <CustomTextField label='Card Number' element={<CardNumberElement />} />
          </div>
          <div className='tw-mt-5 tw-grid tw-w-full tw-grid-flow-col tw-gap-7 sm:tw-w-[241px]'>
            <CustomTextField label='Expiry date' element={<CardExpiryElement />} />
            <CustomTextField label='CVC' element={<CardCvcElement />} />
          </div>
          <div className='tw-mt-6'>
            <Checkbox
              isChecked={isChecked}
              onChange={onChangePrimaryCard}
              label='Primary Card'
              className='!tw-h-8 !tw-w-8'
              labelClass='!tw-text-xs !tw-font-semibold'
              size='large'
            />
          </div>
          {alert.display && !alert.isValid && (
            <div className='tw-mt-2'>
              <Alert
                description='The payment method already exists. Please enter another payment method.'
                isDismissable={false}
                severity='error'
                className='!tw-bg-red-200 !tw-font-sans tw-text-sm tw-font-medium !tw-text-black'
              />
            </div>
          )}
          <div className='tw-mt-9'>
            <div className='tw-grid tw-grid-flow-col tw-grid-cols-2 tw-gap-2'>
              <div className='tw-col-span-1'>
                <Button
                  font='semibold'
                  className='tw-text-xs'
                  isFullWidth
                  onClick={() => {
                    handleAdd();
                  }}
                  size='large'
                  variant='purple'
                >
                  Add
                </Button>
              </div>
              <div className='tw-col-span-1'>
                <Button
                  font='semibold'
                  className='tw-text-xs'
                  isFullWidth
                  onClick={onCancelPayment}
                  size='large'
                  variant='lavander'
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={isLoading} isDismissable={false} centered size='sm'>
        <div className=' tw-p-8'>
          <div className='tw-relative tw-mt-8 tw-mb-5 tw-p-5'>
            <Spinner />
          </div>
          <div className='tw-mt-8'>
            <div className='tw-text-center'>
              <span className='tw-text-center tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>
                Processing, please wait.
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentForm;
