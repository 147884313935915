import { gql } from '@apollo/client';

const COMPANY_FRAGMENT = gql`
  fragment CompanyFields on Company {
    id
    slug
    name
    crn
    website
    taxId
    isPrimary
    logoUrl
    memberContactCount

    address {
      address1
      address2
    }
  }
`;

export { COMPANY_FRAGMENT };
