/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './ReviewerListTab.styles';

const ReviewerListTab = memo(({ isActive, onClick, label, value }) => {
  const tabStyles = [styles.tab.base, isActive && styles.tab.active];

  const handleClick = onClick.bind(this, value);

  return (
    <div onClick={handleClick} css={tabStyles} className='m-b-10'>
      {label}
    </div>
  );
});

export { ReviewerListTab };
