/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from 'components/shared/Modal';
import { Row, Col } from 'react-bootstrap';
import { ContactPicker } from 'components/shared/ContactPicker';
import { ReviewList } from './../ReviewList';

import { useReviewable } from './../hooks/useReviewable';

import * as styles from './ReviewerModal.styles';

const ReviewerModal = memo(({ show, onClose }) => {
  const { minutes, reviews, reviewers, isReviewerSelected, toggleReviewer, removeReviewer, handleResendReviewRequest } =
    useReviewable();

  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({ id: 'ReviewerModal.inviteReviewers' })}
      centered
      show={show}
      modalStyles={styles.modal}
      onClose={onClose}
    >
      <Row noGutters>
        <Col md={12}>
          <div className='lh-14 fs-14 font-weight-regular c-mid'>
            <FormattedMessage id='ReviewerModal.modalText' />
          </div>

          <div className='m-t-20'>
            <ContactPicker
              values={reviewers}
              excludeId={minutes.contact_id}
              isSelected={isReviewerSelected}
              onToggleOption={toggleReviewer}
            />
          </div>

          <ReviewList reviews={reviews} onResend={handleResendReviewRequest} onDelete={removeReviewer} />
        </Col>
      </Row>
    </Modal>
  );
});

export { ReviewerModal };
