import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const padded = css`
  padding-left: 40px;
  padding-right: 40px;
  ${media.tablet} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const tabRow = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 36px;
  padding-bottom: 35px;
`;

export const tab = ({ isSelected }) => css`
  width: 315px;
  padding: 18px 89px 14px 97px;
  border: 1px solid;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 14px;
  border-color: ${isSelected ? colors.cyan : colors.headingGrey};
  background-color: ${isSelected ? colors.lightCyan : colors.white};
  ${media.mobile} {
    background-color: ${isSelected ? colors.cyan : colors.white};
    border-radius: 14px 0 0 14px;
    height: 36px;
    padding: 0;
    flex-direction: column;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &:last-of-type {
    margin-left: 30px;
    ${media.mobile} {
      border-radius: 0 14px 14px 0;
      margin-left: 0;
    }
  }

  &:hover {
    cursor: pointer;
    border-color: ${colors.cyan};
  }
`;

export const tabName = ({ isSelected }) => css`
  ${fonts.semiBold}
  flex: 1 1 100%;
  text-align: center;
  font-size: 22px;
  color: ${colors.black};
  line-height: 28px;
  ${media.mobile} {
    color: ${isSelected ? colors.white : colors.black};
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    flex-grow: 0;
    flex-basis: auto;
  }
`;

export const icon = ({ isSelected }) => css`
  width: 27px;
  height: 27px;
  fill: ${colors.cyan};
  color: ${colors.white};
  ${media.mobile} {
    width: 16px;
    height: 16px;
    fill: ${isSelected ? colors.lightCyan : colors.cyan};
    color: ${isSelected ? colors.cyan : colors.white};
  }
`;
