/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { useResizeDetector } from 'react-resize-detector';
import { Step } from './../MultistepForm/Step';
import { ActionBar } from './../ActionBar';
import { HelpText } from 'components/shared/HelpText';
import { AlertPopup } from 'components/shared/AlertPopup';
import { useEditable } from 'hooks/useEditable';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import { useDocumentsStore } from '../../../store/documentsStoreContext';
import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';
import { useMeetingActions, useMeetingStore } from 'store/meetingStoreContext';
import { useFlashActions } from 'store/flashStoreContext';

import { ReactComponent as PDFIcon } from 'images/icons/meeting-pdf.svg';

import * as styles from './AgendaAndTasksStep.styles';

const AgendaAndTasksStep = memo(({ show, steps, onSave, saveDraftLabel, onInviteClick, onInvite, children }) => {
  const { meeting } = useMeetingStore();
  const { documents } = useDocumentsStore();
  const { isDraft, isScheduled, isUpcoming } = useMeetingStatus(meeting.status);
  const { setFlash } = useFlashActions();
  const { deleteMeeting, startMeeting, publishMeeting, generateSmartPack } = useMeetingActions();
  const { isEditing: isPopupVisible, startEditing: showPopup, stopEditing: hidePopup } = useEditable();

  const intl = useIntl();

  const isActionable = isScheduled || isUpcoming;
  const enableGenerateSmartPackButton = isActionable && documents.length > 0;

  const handleStartClick = () => startMeeting(meeting.id).catch(setFlash);

  const startMeetingButton = (
    <a
      className={`btn btn-responsive m-b-10 m-l-15 ${isActionable ? 'btn-secondary btn-inverted' : 'btn-disabled'}`}
      onClick={handleStartClick}
      disabled={!isActionable}
    >
      <div className='d-flex align-items-center justify-content-center'>
        <i className='fa fa-play' />
        <span className='m-l-5'>
          <FormattedMessage id='AgendaAndTasksStep.start' />
        </span>
      </div>
    </a>
  );

  const [enablePublish, setEnablePublish] = useState(true);
  const [showPublishPopup, setShowPublishPopup] = useState(false);
  const [showGenerateSmartPackSuccess, setShowGenerateSmartPackSuccess] = useState(false);

  const handlePublishPopupOpen = () => setShowPublishPopup(true);
  const handlePublishPopupClose = () => setShowPublishPopup(false);

  const handlePublishClick = () => {
    if (!enablePublish) return;

    setEnablePublish(false);
    handlePublishPopupClose();
    publishMeeting(meeting.id).then(showPopup).catch(setFlash);

    setTimeout(() => setEnablePublish(true), 3000);
  };

  const publishMeetingButton = (isScheduled || isUpcoming) && (
    <a
      disabled={!enableGenerateSmartPackButton}
      className={`btn btn-responsive m-b-10 border-0 ${enableGenerateSmartPackButton ? 'btn-orange' : 'btn-disabled'}`}
      onClick={handlePublishPopupOpen}
    >
      <div className='d-flex align-items-center justify-content-center'>
        <i className='i far fa-users' />
        <span className='m-l-5'>
          <FormattedMessage id={isUpcoming ? 'AgendaAndTasksStep.republish' : 'AgendaAndTasksStep.publish'} />
        </span>
      </div>
    </a>
  );

  const handleArchiveClick = () => deleteMeeting(meeting.id).catch(setFlash);
  const archiveMeetingButton = (
    <ButtonWithConfirmation
      confirmationText={intl.formatMessage({ id: 'AgendaAndTasksStep.archiveMeeting' })}
      onConfirm={handleArchiveClick}
      button={
        <a className='btn btn-responsive m-b-10 btn-red border-0 d-flex align-items-center justify-content-center'>
          <div className='d-flex align-items-center'>
            <i className='i far fa-trash-alt' />
            <span className='m-l-5'>
              <FormattedMessage id='AgendaAndTasksStep.archive' />
            </span>
          </div>
        </a>
      }
    />
  );

  const inviteButton = isDraft && (
    <a className='btn btn-responsive m-b-10 btn-secondary btn-inverted' onClick={onInviteClick}>
      <FormattedMessage id='AgendaAndTasksStep.sendNotice' />
    </a>
  );

  const { width, ref } = useResizeDetector();
  const actionBarStyles = styles.actionBar(width);

  const stepsPrepend = (
    <>
      <HelpText
        show={isScheduled}
        title={intl.formatMessage({ id: 'AgendaAndTasksStep.helpScheduledTitle' })}
        instructions={intl.formatMessage({ id: 'AgendaAndTasksStep.helpScheduledInstructions' })}
      />

      <HelpText
        show={isUpcoming}
        title={intl.formatMessage({ id: 'AgendaAndTasksStep.helpIncomingTitle' })}
        instructions={intl.formatMessage({ id: 'AgendaAndTasksStep.helpIncomingInstructions' })}
      />
    </>
  );

  const showGenerateSmartPackSuccessPopup = () => setShowGenerateSmartPackSuccess(true);
  const hideGenerateSmartPackSuccessPopup = () => setShowGenerateSmartPackSuccess(false);

  const handleGenerateSmartPackClick = () => {
    generateSmartPack(meeting.id).then(showGenerateSmartPackSuccessPopup);
  };

  const generateSmartPackButton = isScheduled && (
    <a
      onClick={handleGenerateSmartPackClick}
      disabled={!enableGenerateSmartPackButton}
      className={`btn btn-responsive btn-no-transform m-b-10 m-r-10 border-0 ${
        enableGenerateSmartPackButton ? 'btn-orange' : 'btn-disabled'
      }`}
    >
      <div className='d-flex align-items-center justify-content-center'>
        <PDFIcon />
        <span className='m-l-5'>
          <FormattedMessage
            id={
              meeting.smart_pack_id ? 'AgendaAndTasksStep.regenerateSmartPack' : 'AgendaAndTasksStep.generateSmartPack'
            }
          />
        </span>
      </div>
    </a>
  );

  return (
    show && (
      <>
        <AlertPopup show={showPublishPopup} popupType='info' onClose={handlePublishPopupClose}>
          <div className='text-black fs-14 lh-22 m-b-20'>
            <FormattedMessage
              id={
                isUpcoming ? 'AgendaAndTasksStep.startingInfoRepublishing' : 'AgendaAndTasksStep.startingInfoPublishing'
              }
            />
          </div>
          <button className='btn btn-info' onClick={handlePublishClick}>
            <FormattedMessage id='AgendaAndTasksStep.ok' />
          </button>
          <button className='btn btn-info-inverted m-l-10' onClick={handlePublishPopupClose}>
            <FormattedMessage id='AgendaAndTasksStep.cancel' />
          </button>
        </AlertPopup>
        <div className='m-b-50'>
          <Step steps={steps} prepend={stepsPrepend}>
            <div ref={ref}>{children}</div>
          </Step>

          <ActionBar css={actionBarStyles}>
            <div className='d-flex flex-wrap justify-content-between align-items-center'>
              <a className='btn btn-responsive m-b-10 btn-inverted' onClick={onSave}>
                {saveDraftLabel}
              </a>

              <div className='d-flex d-flex-responsive flex-wrap align-items-center'>
                {inviteButton}
                {generateSmartPackButton}
                {publishMeetingButton}
                {startMeetingButton}
                {archiveMeetingButton}
              </div>

              <AlertPopup
                show={isPopupVisible}
                headerText={intl.formatMessage({ id: 'AgendaAndTasksStep.success' })}
                popupType='success'
                onClose={hidePopup}
              >
                <FormattedMessage id='AgendaAndTasksStep.materialPublished' />
              </AlertPopup>

              <AlertPopup
                show={showGenerateSmartPackSuccess}
                headerText={intl.formatMessage({ id: 'AgendaAndTasksStep.success' })}
                popupType='success'
                onClose={hideGenerateSmartPackSuccessPopup}
              >
                <FormattedMessage id='AgendaAndTasksStep.smartPackGenerating' />
              </AlertPopup>
            </div>
          </ActionBar>
        </div>
      </>
    )
  );
});

export { AgendaAndTasksStep };
