import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';

export const Variants = {
  Gray: 'gray',
  Green: 'green',
  Orange: 'orange',
  Black: 'black',
  Purple: 'purple',
  Faint: 'faint',
  Red: 'red',
};

const CalendarIcon = ({ date, time, variant = Variants.Gray }) => (
  <div
    className={clsx('tw-inline-flex tw-flex-col tw-rounded-md tw-p-1 tw-text-sm tw-font-semibold', {
      'tw-bg-gray-500': variant === Variants.Gray,
      'tw-bg-teal-600': variant === Variants.Green,
      'tw-bg-orange-600': variant === Variants.Orange,
      'tw-bg-black': variant === Variants.Black,
      'tw-bg-purple-500': variant === Variants.Purple,
      'tw-bg-gray-700': variant === Variants.Faint,
      'tw-bg-red-400': variant === Variants.Red,
    })}
  >
    <div
      className={clsx('tw-mt-0.5 tw-mb-1 tw-inline-flex tw-items-center tw-justify-center tw-leading-none', {
        'tw-text-purple-100': variant === Variants.Purple,
        'tw-text-white': variant !== Variants.Purple,
      })}
    >
      {date}
    </div>

    <div
      className={clsx('tw-inline-flex tw-items-center tw-justify-center tw-rounded tw-p-1 tw-leading-none', {
        'tw-bg-gray-700 tw-text-white': variant === Variants.Gray,
        'tw-bg-teal-700 tw-text-white': variant === Variants.Green,
        'tw-bg-orange-200 tw-text-black': variant === Variants.Orange,
        'tw-bg-gray-700 tw-text-black': variant === Variants.Black,
        'tw-bg-purple-100 tw-text-purple-500': variant === Variants.Purple,
        'tw-bg-gray-500 tw-text-purple-100': variant === Variants.Faint,
        'tw-bg-white tw-text-red-400': variant === Variants.Red,
      })}
    >
      {time}
    </div>
  </div>
);

CalendarIcon.propTypes = {
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(Variants)),
};

export default CalendarIcon;
