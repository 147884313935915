import { gql, useMutation } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';

const TOGGLE_FAVOURITE_DIRECTORY = gql`
  mutation toggleFavouriteDirectory($input: ToggleFavouriteDirectoryInput!) {
    toggleFavouriteDirectory(input: $input) {
      directory {
        ...DirectoryFields
      }
    }
  }
  ${DIRECTORY_FRAGMENT}
`;

const useToggleFavouriteDirectoryMutation = (options) => useMutation(TOGGLE_FAVOURITE_DIRECTORY, options);

export { useToggleFavouriteDirectoryMutation };
