import React, { useState } from 'react';
import clsx from 'clsx';
import { FiCopy, FiCheck } from 'react-icons/fi';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import Button, { Variants, Sizes } from 'components/v2/shared/Button';
import CompanyItemContacts from '../CompanyItemContacts/CompanyItemContacts';
import { useDateFormatContext } from 'contexts/v2/DateFormatContext';
import { AlertPopup } from 'components/shared/AlertPopup';

import { CopyPopover } from 'components/shared/CopyPopover';

const CompanyItem = ({ company, onRemoveClick, onCloseClick, onSetAsPrimaryClick }) => {
  const {
    isActive,
    isPending,
    isPrimary,
    isCurrentCompany,
    name,
    slug,
    currentUserRole,
    companyRoutes,
    companyPermissions,
    contacts,
    requestedContacts,
    createdAt,
    subcommittees,
  } = company;

  const [closePopupIsVisible, setClosePopupIsVisible] = useState(false);

  const showClosePopup = () => setClosePopupIsVisible(true);
  const hideClosePopup = () => setClosePopupIsVisible(false);

  const handleClose = () => {
    onCloseClick(company.id);
  };

  const { userDateFormat } = useDateFormatContext();

  const handleManageClick = () => {
    window.location.href = companyRoutes.settingsLink;
  };

  const handleSetAsPrimaryClick = () => onSetAsPrimaryClick(company.id);

  const handleRemoveClick = () => onRemoveClick(company.id);

  const handleCloseClick = () => onCloseClick(company.id);

  const handleCopyClick = () => navigator.clipboard.writeText(slug);

  const renderManageButton = () => {
    if (companyPermissions.canBeManagedByCurrentUser) {
      return (
        <Button
          variant={Variants.Teal}
          size={Sizes.Small}
          className='tw-mr-2.5 tw-flex-1 lg:tw-flex-initial'
          onClick={handleManageClick}
        >
          <FormattedMessage id='CompanyItem.manageButton' />
        </Button>
      );
    }
  };

  const renderSetPrimaryButton = () => {
    if (!isPending && !isPrimary) {
      return (
        <Button
          size={Sizes.Small}
          variant={Variants.Purple}
          className='tw-mr-2.5 tw-flex-1 lg:tw-flex-initial'
          onClick={handleSetAsPrimaryClick}
        >
          <FormattedMessage id='CompanyItem.setPrimary' />
        </Button>
      );
    }
  };

  const renderPayButton = () => {
    if (!isActive && companyPermissions.subscriptionCanBeManagedByCurrentUser) {
      return (
        <Button variant={Variants.Orange} size={Sizes.Small} className='tw-mr-2.5 tw-flex-1 lg:tw-flex-initial'>
          <FormattedMessage id='CompanyItem.makePayment' />
        </Button>
      );
    }
  };

  const renderCloseButton = () => {
    if (companyPermissions.canBeClosedByCurrentUser) {
      return (
        <Button
          variant={Variants.Red}
          size={Sizes.Small}
          className='tw-mr-2.5 tw-flex-1 lg:tw-flex-initial'
          onClick={showClosePopup}
        >
          <FormattedMessage id='CompanyItem.close' />
        </Button>
      );
    }
  };

  const renderRemoveButton = () => {
    // Hide this button as per Hannah's request on 17 Nov, 2022
    if (false && !isCurrentCompany && companyPermissions.canBeRemovedByCurrentUser) {
      return (
        <Button
          variant={Variants.Red}
          size={Sizes.Small}
          className='tw-mr-2.5 tw-flex-1 lg:tw-flex-initial'
          onClick={handleRemoveClick}
        >
          <FormattedMessage id='CompanyItem.remove' />
        </Button>
      );
    }
  };

  const renderContacts = () => {
    if (isActive && companyPermissions.canBeManagedByCurrentUser) {
      return <CompanyItemContacts approvedContacts={contacts} requestedContacts={requestedContacts} />;
    }
  };

  return (
    <>
      <AlertPopup show={closePopupIsVisible} popupType='info' onClose={hideClosePopup}>
        <FormattedMessage id='CompanyItem.confirmClose' />

        <div className='d-flex align-items-center m-t-40 justify-content-center'>
          <button className='btn btn-info' onClick={handleClose}>
            <FormattedMessage id='CompanyItem.yes' />
          </button>

          <button className='btn btn-info-inverted m-l-10' onClick={hideClosePopup}>
            <FormattedMessage id='CompanyItem.cancel' />
          </button>
        </div>
      </AlertPopup>
      <div
        className={clsx(
          'company-item tw-relative tw-mb-5 tw-rounded-sm tw-border-2 tw-border-solid tw-p-5 last:tw-mb-0',
          {
            'tw-border-gray-200': !isPending,
            'tw-border-orange-600': isPending,
            'tw-border-teal-500': isPrimary,
          }
        )}
      >
        <div className='tw-mb-1 tw-flex tw-justify-between'>
          <div className='tw-w-1/2'>
            <h3 className='tw-text-md tw-m-0'>{name}</h3>
          </div>
          <div className='tw-flex tw-hidden tw-w-1/4 tw-items-center tw-justify-center tw-text-xs tw-font-bold tw-text-orange-600 lg:tw-block'>
            {isPending && <FormattedMessage id='CompanyItem.pendingApproval' />}
            {companyPermissions.subscriptionCanBeManagedByCurrentUser && !isActive && (
              <FormattedMessage id='CompanyItem.awaitingPayment' />
            )}
          </div>
          <div className='tw-flex tw-w-1/2 tw-items-center tw-justify-end tw-text-xs lg:tw-w-1/4'>
            <span className='tw-mr-1 tw-font-bold tw-text-black'>
              <FormattedMessage id='CompanyItem.yourRole' />
            </span>
            {currentUserRole}
          </div>
        </div>
        {subcommittees && subcommittees.length > 0 && (
          <div className='tw-mb-3'>Subcommitees: {subcommittees.map((s) => s.name).join(', ')}</div>
        )}
        <div className='tw-mb-3 tw-text-xs tw-text-black'>
          <div>
            <span className='tw-mr-1 tw-font-bold'>
              <FormattedMessage id='CompanyItem.magicLink' />
            </span>
            {slug}
            <CopyPopover>
              <FiCopy className='tw-ml-1 tw-cursor-pointer tw-stroke-teal-600' onClick={handleCopyClick} />
            </CopyPopover>
          </div>
          <div>
            <span className='tw-mr-1 tw-font-bold'>
              <FormattedMessage id='CompanyItem.created' />
            </span>
            {moment(createdAt).parseZone().format(userDateFormat)}
          </div>
        </div>
        <div className='tw-mb-4'>{renderContacts()}</div>
        <div className='tw-flex tw-justify-between'>
          <div className='tw-flex tw-w-full lg:tw-w-auto'>
            {renderManageButton()}
            {renderSetPrimaryButton()}
            {renderPayButton()}
            {renderRemoveButton()}
            {renderCloseButton()}
          </div>
          {isPrimary && (
            <div
              className={clsx(
                'tw-absolute -tw-top-3.5 tw-right-2.5 tw-rounded-full tw-bg-white tw-py-1.5 tw-px-2.5 tw-text-xs tw-drop-shadow',
                'lg:tw-relative lg:tw-top-0 lg:tw-p-0 lg:tw-drop-shadow-none'
              )}
            >
              <FormattedMessage id='CompanyItem.primary' />
              <FiCheck className='tw-ml-1 tw-h-4 tw-w-4 tw-align-bottom tw-text-green-600' />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

CompanyItem.propTypes = {
  company: PropTypes.object,
  onRemoveClick: PropTypes.func,
  onSetAsPrimaryClick: PropTypes.func,
};

export default CompanyItem;
