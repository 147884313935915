import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const results = {
  base: css`
    opacity: 0;
    transition: none;
    height: inherit;
  `,

  visible: css`
    opacity: 1;
    transition: opacity 0.4s ease-in;
  `,
};

export const highlight = {
  document: css`
    ${fonts.semiBold}
    background: ${colors.lightCyan};
  `,

  directory: css`
    ${fonts.semiBold}
    background: ${colors.lightestPurple};
  `,
};

export const loading = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
`;

export const container = css`
  width: 100%;
  position: relative;
  height: calc(100% - 50px);
  min-height: 260px;
  padding: 10px 15px 10px 0;
  flex-direction: column;
  display: flex;
`;

export const children = css`
  padding: 0 20px;
  overflow: auto;
  height: 100%;
`;

export const spinner = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const padded = css`
  padding: 0 30px 20px 30px;
`;

export const heading = css`
  ${fonts.semiBold}
  padding: 0 30px;
  color: ${colors.black};
  font-size: 20px;
  margin-bottom: 15px;
`;

export const multiselect = (baseStyles) => {
  return {
    ...baseStyles,

    container: (styles) => ({
      ...baseStyles.container(styles),
      width: 100,
    }),

    valueContainer: (styles) => ({
      ...baseStyles.valueContainer(styles),
      padding: '4px 9px',
      height: 27,
    }),

    indicatorsContainer: (styles) => ({
      ...baseStyles.indicatorsContainer(styles),

      div: {
        padding: '0 5px 0 0',
      },

      i: {
        color: `${colors.black} !important`,
        fontSize: 12,
      },
    }),

    control: (styles) => ({
      ...baseStyles.control(styles),
      border: 'none',
      minHeight: 27,
      background: '#F0F0F0',
      borderRadius: 4,
    }),
  };
};
