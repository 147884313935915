/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useRef, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { useTimezoneSubscription } from './../hooks/useTimezoneSubscription';
import { useTimezoneQuery } from './../data/queries/TimezoneQuery';
import { formatTimezoneOffset } from 'helpers/formatDateTime';
import { withIntl } from '../../../utils/v2/intl';

const HOUR_IN_SECONDS = 3600;
const SECOND_IN_MILLISECONDS = 1000;
const TIME_FORMAT = 'DD MMM YYYY, hh:mm a';

const TimeClock = ({ tzName, tzOffset, contactId, className }) => {
  const { data } = useTimezoneQuery();

  const intl = useIntl();

  useTimezoneSubscription({ contactId: contactId });

  const name = data?.currentContact?.timezone?.name || tzName;
  const gmtOffset = data?.currentContact?.timezone?.gmtOffset || tzOffset;
  const gmtTimeDiff = gmtOffset / HOUR_IN_SECONDS;

  const getCurrentTime = () => {
    if (gmtOffset != null) {
      return moment().utc().add(gmtTimeDiff, 'hours').format(TIME_FORMAT);
    } else {
      return moment().format(TIME_FORMAT);
    }
  };

  const [time, setTime] = useState(getCurrentTime());
  const updateTime = () => setTime(getCurrentTime());

  const timerRef = useRef();

  useEffect(() => {
    if (!data) return;

    updateTime();

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(updateTime, SECOND_IN_MILLISECONDS);

    return () => clearInterval(timerRef.current);
  }, [data]);

  const timezoneDescription = () => {
    if (gmtOffset == null) {
      return intl.formatMessage({ id: 'TimeClock.local' });
    } else {
      return `(${name} ${formatTimezoneOffset(gmtOffset)})`;
    }
  };

  return (
    <div className='tw-text-xs tw-font-medium tw-m-0 tw-pointer-events-none xl:tw-text-black tw-text-white'>
      <div>{time}</div>
      <div className='tw-text-xxs xl:tw-text-gray-700 tw-text-white'>{timezoneDescription()}</div>
    </div>
  );
};

export default withIntl(TimeClock);
