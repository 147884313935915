import { useShareDocumentMutation } from './../data/mutations/ShareDocumentMutation';

const useShareDocument = ({ onCompleted, onError }) => {
  const update = (cache, { data: { shareDocument } }) => {
    onCompleted();
  };

  const [mutate, { loading: isSharing }] = useShareDocumentMutation({ update });

  const shareDocument = (documentId, contactIds) => {
    mutate({ variables: { input: { documentId, contactIds } } });
  };

  return { shareDocument, isSharing };
};

export { useShareDocument };
