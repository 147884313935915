import React from 'react';
import Box from 'components/v2/shared/Box';
import Button from 'components/v2/shared/Button';

const OrderOverview = ({ product, taxData, coupon }) => {
  
  const getCountryCode = () => {
    const countrys = taxData?.jurisdiction.split(" ");

    const initials = countrys.map(country => country.charAt(0).toUpperCase());
    return initials;
  }

  const getTaxAmount= () => {
    return (product.price - getCouponAmount()) * (taxData.percentage / 100);
  }

  const getCouponAmount = () => {
    if(coupon){
      if(coupon?.amount_off) {
        return coupon.amount_off;
      }else if(coupon?.percent_off) {
        return coupon?.percent_off / 100 * product.price;
      }
    }
    return 0;
  }

  const getCouponPercent = () => {
    if(coupon){
      if(!coupon?.amount_off) {
        return `${coupon?.percent_off}%`;
      }
    }
    return '';
  }

  const getTotalAmount = () => {
    return ((product.price) + getTaxAmount()) - getCouponAmount();
  }

  return (
    <>
      <h6 className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Order overview</h6>
      <div className=' tw-mt-4'>
        <Box onClick={() => {}} onRemove={function noRefCheck() {}} className='!tw-py-2'>
          <div className='tw-flex tw-w-full tw-py-2'>
            <div className=''>
              <div className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Plan</div>
            </div>
            <div className='tw-ml-auto tw-text-right'>
              <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>{product.name}</div>
            </div>
          </div>
        </Box>
      </div>

      <div className='tw-mt-1'>
        <Box onClick={() => {}} onRemove={function noRefCheck() {}} className='!tw-py-2'>
          <div className='tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-4 tw-py-2'>
            <div className='tw-col-span-3'>
              <div className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Due today</div>
              <p className='tw-mt-3.5 tw-font-sans tw-text-xs tw-font-medium tw-text-gray-700'>
                First payment will be taken immediately, then yearly on the 5th of July. Charged in USD.
              </p>
            </div>
            <div className='tw-col-span-1 tw-text-right'>
              <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>${product.price}</div>
            </div>
          </div>
        </Box>
      </div>
      <div className='tw-mt-1'>
        <Box className='!tw-py-2'>
          <div className='tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-4 tw-py-2'>
            <div className='tw-col-span-3 tw-flex tw-items-center'>
              <div className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Taxes ({getCountryCode()} {taxData.percentage}%)</div>
            </div>
            <div className='tw-col-span-1 tw-text-right'>
              <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>${getTaxAmount().toFixed(2)}</div>
            </div>
          </div>
        </Box>
      </div>

      <div className='tw-mt-1'>
        <Box onClick={() => {}} className='!tw-py-2'>
          <div className='tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-4 tw-py-2'>
            <div className='tw-col-span-3 tw-flex tw-items-center'>
              <div className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Coupon discount</div>
              <div className='tw-px-[2rem] tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>{getCouponPercent()}</div>
            </div>
            <div className='tw-col-span-1 tw-text-right'>
              <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>${getCouponAmount().toFixed(2)}</div>
            </div>
          </div>
        </Box>
      </div>
      <div className='tw-mt-1'>
        <Box onClick={() => {}} className='!tw-py-2'>
          <div className='tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-4 tw-py-2'>
            <div className='tw-col-span-3'>
              <div className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Total</div>
            </div>
            <div className='tw-col-span-1 tw-text-right'>
              <div className='tw-text-sm tw-font-semibold'>${getTotalAmount().toFixed(2)}</div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default OrderOverview;
