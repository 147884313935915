/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';

import AgendaItem from './../AgendaItem';
import Button, { Variants } from 'components/v2/shared/Button';
import RadioButton from 'components/v2/shared/RadioButton';
import Label from 'components/v2/shared/Label';

import * as styles from './AgendaStep.styles';

const AgendaStep = () => {
  return (
    <div>
      <Label text='Select' />

      <Row noGutters className='mb-4'>
        <Col>
          <RadioButton name='type' label='New' />
        </Col>
        <Col>
          <RadioButton name='type' label='Previous template' />
        </Col>
      </Row>

      <AgendaItem title='Welcome to country' />
      <AgendaItem title='Second item created' />
      <AgendaItem title='Third item created' />

      <AgendaItem isExpanded title='Item 1 of agenda' />

      <Button className='mb-2' isFullWidth>
        Next
      </Button>

      <Button variant={Variants.Teal} isFullWidth>
        Save draft
      </Button>
    </div>
  );
};

AgendaStep.propTypes = {};

export default AgendaStep;
