/** @jsx jsx */

import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';

import * as styles from './RadioButton.styles';

const RadioButton = memo(
  ({ id, name, value, onChange, isDisabled, isChecked, defaultChecked, label, badgeClass, badgeText }) => {
    return (
      <div className='radio' css={styles.radio}>
        <input
          id={id}
          name={name}
          type='radio'
          value={value}
          disabled={isDisabled}
          onChange={onChange}
          checked={isChecked}
          defaultChecked={defaultChecked}
          className='btn-radio'
          css={styles.input}
        />

        <label htmlFor={id} css={styles.label}>
          {label}
        </label>

        {badgeClass && badgeText && <div className={`m-l-5 badge ${badgeClass}`}>{badgeText}</div>}
      </div>
    );
  }
);

export { RadioButton };
