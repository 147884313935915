import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const chevron = {
  base: css`
    transition: transform 0.1s ease-in-out;
    font-weight: 300;
    margin-left: 7px;
    font-size: 12px;
  `,

  rotated: css`
    transform: rotate(180deg);
  `,
};

export const header = css`
  ${fonts.semiBold}
  border-radius: 10px;
  background: ${colors.white};
  padding: 18px 28px;

  @media (max-width: 767px) {
    padding: 20px;
  }
`;

export const expandable = css`
  box-shadow: 2px 4px 4px rgba(223, 223, 223, 0.25);
  border: 1px solid #e7e6e6;
  border-radius: 10px;
  background: ${colors.white};

  @media (max-width: 767px) {
    border: none;
  }
`;

export const mobileCard = css`
  ${fonts.regular}
  padding: 20px 0;
  border-bottom: 1px solid #e5e8f3;
  color: ${colors.black};
  font-size: 14px;

  &:first-child {
    padding-top: 0;
  }
`;

export const mobileRow = css`
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
`;

export const mobileTitle = css`
  ${fonts.bold}
`;

export const mobileValue = css`
  min-width: 95px;
`;
