import { useState, useMemo } from 'react';

const useDropdownRoles = ({ accessRoles, defaultRoleId, currentRoleId }) => {
  const [dropdownRoleId, setDropdownRoleId] = useState(defaultRoleId);

  const changeDropdownRole = (option) => setDropdownRoleId(option.value);
  const resetCurrentDropdownRole = () => setDropdownRoleId(defaultRoleId);

  const currentDropdownRole = useMemo(() => {
    const defaultRole = accessRoles && accessRoles.find((item) => item.attributes.id === dropdownRoleId);

    if (!defaultRole) return;

    return {
      value: dropdownRoleId,
      label: defaultRole.attributes.name,
    };
  }, [dropdownRoleId, currentRoleId, accessRoles]);

  const dropdownRoleOptions = useMemo(() => {
    if (!accessRoles || !defaultRoleId) return [];

    const filtered = accessRoles.filter((item) => item.attributes.id !== currentRoleId);

    return filtered.map((item) => ({
      label: item.attributes.name,
      value: item.attributes.id,
    }));
  }, [currentRoleId, accessRoles]);

  return {
    dropdownRoleOptions,
    currentDropdownRole,
    changeDropdownRole,
    resetCurrentDropdownRole,
  };
};

export { useDropdownRoles };
