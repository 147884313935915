/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import Label from 'components/v2/shared/Label';
import Text from 'components/v2/shared/Text';
import Chip from 'components/v2/shared/Chip';

import { useTimezonesContext } from 'contexts/v2/TimezonesContext';
import { formatDate, formatTime, formatTimezoneOffset } from 'helpers/formatDateTime';
import { TIME_FORMAT, DATE_FORMAT, DAY_FORMAT } from './../constants';
import Pluralize from 'pluralize';

import moment from 'moment';

import * as styles from './InviteAndNoticeSchedule.styles';

const InviteAndNoticeSchedule = ({ scheduledAt, timezone }) => {
  if (!scheduledAt) {
    return null;
  }

  const { getTimezoneByValue } = useTimezonesContext();
  const { value, gmtOffset } = getTimezoneByValue(timezone);

  const diffInDaysFromNow = scheduledAt.diff(moment(), 'days');

  return (
    <div className='mb-4'>
      <Label text='To be sent' />

      <Chip variant='flatRight' className='mr-1'>
        <Text color='lightGrey' text={formatDate(scheduledAt, DAY_FORMAT)} className='mr-2' />
        <Text text={formatDate(scheduledAt, DATE_FORMAT)} className='mr-2' />
        <Text color='lightGrey' text={Pluralize('day', diffInDaysFromNow, true)} />
      </Chip>

      <Chip variant='flatLeft'>
        <Text text={formatTime(scheduledAt, TIME_FORMAT)} />
        <Text text='&nbsp;' />
        <Text color='lightGrey' text={`(${value} ${formatTimezoneOffset(gmtOffset)})`} />
      </Chip>
    </div>
  );
};

InviteAndNoticeSchedule.propTypes = {
  scheduledAt: PropTypes.instanceOf(moment),
  timezone: PropTypes.string,
};

export default InviteAndNoticeSchedule;
