import React, { useState } from 'react';
import Box from 'components/v2/shared/Box';
import { FiX } from 'react-icons/fi';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import Modal from 'components/v2/shared/Modal';

const RoleList = ({ roles, selectedRoleIndex, onClickRole, onDeleteRole }) => {
  const [isDeleteRoleModalOpen, setIsDeleteRoleModalOpen] = useState(false);
  const [roleToBeDeleted, setRoleToBeDeleted] = useState(null);

  const handleDeleteClick = (roleId) => {
    setRoleToBeDeleted(roleId);
    setIsDeleteRoleModalOpen(true);
  };

  const handleDeleteRole = (replacementRoleId) => {
    onDeleteRole(roleToBeDeleted, replacementRoleId);
    setIsDeleteRoleModalOpen(false);
  };

  return (
    <div>
      <Modal isOpen={isDeleteRoleModalOpen} onClose={() => setIsDeleteRoleModalOpen(false)} centered size='xl'>
        <ConfirmDeleteModal
          onClose={() => setIsDeleteRoleModalOpen(false)}
          onConfirm={handleDeleteRole}
          replacementRoleList={roles.filter((role) => role.id !== roleToBeDeleted)}
        />
      </Modal>
      {roles.map((item, i) => (
        <div className='tw-mt-1 tw-items-center tw-rounded !tw-bg-gray-100' key={item.role}>
          <Box isSelected={selectedRoleIndex === i} className='!tw-block !tw-p-0' variant='purple'>
            <div className=' tw-grid tw-grid-flow-col tw-grid-cols-12'>
              <div
                className='tw-col-span-11 tw-grid tw-cursor-pointer tw-grid-flow-col tw-grid-cols-11 tw-p-5'
                onClick={() => onClickRole(item, i)}
              >
                <div className='tw-col-span-5 sm:tw-col-span-7'>
                  <div className='tw-truncate tw-font-sans tw-text-[13px] tw-font-semibold tw-text-black hover:tw-overflow-visible'>
                    {item.role}
                  </div>
                </div>
                <div className='tw-col-span-3 tw-text-right sm:tw-col-span-2'>
                  <div className='tw-font-sans tw-text-xs tw-font-medium tw-text-gray-700'>
                    {item.isDefault && 'Default'}
                  </div>
                </div>
                <div className='tw-col-span-3 tw-text-right sm:tw-col-span-2'>
                  <div className='text-black tw-font-sans tw-text-xs tw-font-medium tw-underline'>
                    {`${item.users.length} users`}
                  </div>
                </div>
              </div>
              <div className='tw-col-span-1 tw-grid tw-place-content-center'>
                {item.isDeletable && (
                  <FiX className='tw-cursor-pointer tw-text-gray-700' onClick={() => handleDeleteClick(item.id)} />
                )}
              </div>
            </div>
          </Box>
        </div>
      ))}
    </div>
  );
};

export default RoleList;
