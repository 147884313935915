/** @jsxRuntime classic */
/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { withIntl } from '../../../utils/v2/intl';
import { FormattedMessage, useIntl } from 'react-intl';

import PlaceholderIcon from 'images/man-with-laptop.jpg';

import { Notification } from './../Notification';
import { Scrollable } from 'components/shared/Scrollable';
import { MediaHeader } from 'components/shared/MediaHeader';

import { useMarkAllNotificationsAsRead } from './../hooks/useMarkAllNotificationsAsRead';
import { useNotificationsQuery } from './../data/queries/NotificationsQuery';
import { useNotificationsSubscription } from './../hooks/useNotificationsSubscription';

import * as styles from './Dropdown.styles';
import { colors } from 'styles';

const Dropdown = memo(({ show, close, onMarkAsRead, onSubscription }) => {
  const { data, loading } = useNotificationsQuery({
    skip: !show,
  });

  const intl = useIntl();

  useNotificationsSubscription({
    onUpdate: () => onSubscription(true),
  });

  const { markAllNotificationsAsRead } = useMarkAllNotificationsAsRead({
    onCompleted: onMarkAsRead,
  });

  const handleMarkAllAsReadClick = (event) => {
    event.stopPropagation();

    const ids = notifications.map((item) => item.id);

    markAllNotificationsAsRead(ids);
  };

  const renderNotification = (notification) => (
    <Notification key={notification.id} onMarkAsRead={onMarkAsRead} {...notification} />
  );

  const notifications = data?.currentContact?.notifications;
  const isLoaded = !loading && notifications;

  const notificationList = isLoaded && notifications.map(renderNotification);
  const markAllAsReadButton = isLoaded && notifications.length > 0 && (
    <>
      <span className='w-100 d-lg-none btn btn-primary' onClick={handleMarkAllAsReadClick}>
        <FormattedMessage id='Dropdown.markAsRead' />
      </span>
      <span className='d-none d-lg-block' onClick={handleMarkAllAsReadClick}>
        <FormattedMessage id='Dropdown.markAsRead' />
      </span>
    </>
  );

  const placeholderStyles = [styles.placeholder.base, isLoaded && !notifications.length && styles.placeholder.visible];
  const wrapperStyles = [styles.wrapper.base, show && styles.wrapper.visible];

  return (
    <div css={wrapperStyles}>
      {/* <MediaHeader title={intl.formatMessage({ id: 'Dropdown.notifications' })} onClose={close} /> */}

      <Scrollable css={styles.notifications}>
        <div css={styles.container}>
          <ClipLoader color={colors.cyan} loading={loading} />
        </div>

        <div css={placeholderStyles}>
          <img src={PlaceholderIcon} css={styles.placeholderImage} />
          <div css={styles.placeholderText}>
            <FormattedMessage id='Dropdown.allSet' />
          </div>
        </div>

        {notificationList}
      </Scrollable>

      <div css={styles.footer}>{markAllAsReadButton}</div>
    </div>
  );
});

export default withIntl(Dropdown);
