import { css } from '@emotion/core';
import { colors, media } from 'styles';

export const outline = css`
  outline: 1px solid ${colors.lighterGrey};
  background ${colors.white};
`;

export const editor = css`
  /* outline: 1px solid ${colors.lighterGrey}; */
  background: ${colors.white};
  border-right: 1px solid ${colors.zircon};
  width: 75%;
  flex-grow: 1;
  /* overflow: hidden; */
  position: relative;
  ${media.mobile} {
    border-bottom: 1px solid ${colors.zircon};
    border-right: none;
  }
`;

export const commentBlock = css`
  min-width: 280px;
  width: 25%;
  ${media.mobile} {
    min-height: 500px;
    flex-basis: 100%;
  }
`;
