import React, { memo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { client } from 'utils/apollo';
import { Viewer } from 'components/votes/Viewer/Viewer';
import { TranslationContextProvider } from '../../../contexts/v2/TranslationContext';
import FileViewer from 'components/v2/shared/FileViewer';
import { useFileViewer } from '../../documents/hooks/useFileViewer';
import AppSignalWrapper from '../../v2/shared/AppSignalWrapper';

export default memo(({ vote, voter, loggedIn }) => {
  const previewDocument = (document) => openFileViewer(document);

  const { fileViewerContext, openFileViewer, closeFileViewer } = useFileViewer();

  return (
    <AppSignalWrapper tags={{ page: 'votes/view' }}>
      <TranslationContextProvider>
        <ApolloProvider client={client}>
          <Viewer vote={vote} voter={voter} loggedIn={loggedIn} previewDocument={previewDocument} />

          <FileViewer
            fileName={fileViewerContext.file?.name}
            fileUrl={fileViewerContext.file?.url}
            isOpen={fileViewerContext.show}
            onClose={closeFileViewer}
          />
        </ApolloProvider>
      </TranslationContextProvider>
    </AppSignalWrapper>
  );
});
