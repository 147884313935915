import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from 'components/v2/shared/Box';
import Checkbox from 'components/v2/shared/Checkbox';
import VisibilityWrapper from 'components/v2/shared/VisibilityWrapper';
import IconButton from 'components/v2/shared/IconButton';
import TextLineClampWithToggle from 'components/v2/shared/TextLineClampWithToggle';
import AgendaItemDocumentList from '../AgendaItemDocumentList';
import { FiPaperclip, FiChevronUp } from 'react-icons/fi';
import { intl } from 'contexts/v2/TranslationContext';

const AgendaItem = ({
  number,
  title,
  description,
  duration,
  assignee,
  documents = [],
  children = [],
  isRoot,
  isCompleted,
  isToggleable,
  onToggle,
  onDocumentClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  return (
    <>
      <Box>
        <div className='tw-flex tw-min-w-0 tw-grow tw-flex-col'>
          <div className='tw-flex tw-items-start tw-gap-2'>
            <div className={clsx('tw-text-sm tw-font-semibold', { 'tw-line-clamp-2': !isOpen })}>
              {number} {title}
            </div>

            <div className='tw-ml-auto tw-flex tw-items-center tw-gap-0.5'>
              <VisibilityWrapper isVisible={isToggleable} visibleClass='tw-flex tw-mr-1.5'>
                <Checkbox isChecked={isCompleted} onChange={onToggle} />
              </VisibilityWrapper>

              <VisibilityWrapper isVisible={documents.length > 0} visibleClass='tw-flex'>
                <FiPaperclip className='tw-h-3 tw-w-3 tw-shrink-0' />
              </VisibilityWrapper>

              <IconButton onClick={toggleIsOpen} className='hover:!tw-bg-gray-200'>
                <FiChevronUp className={clsx('tw-h-4 tw-w-4 tw-stroke-1', !isOpen && 'tw-rotate-180')} />
              </IconButton>
            </div>
          </div>

          <div className='tw-flex tw-flex-col'>
            <VisibilityWrapper isVisible={isRoot} className='tw-text-xs tw-text-gray-700'>
              {duration} {intl.formatMessage({ id: 'AgendaItem.minutes' })}
            </VisibilityWrapper>

            <TextLineClampWithToggle
              text={description}
              className={clsx('tw-text-xs tw-text-gray-700', { 'tw-hidden': !isOpen })}
            />

            <VisibilityWrapper isVisible={!isOpen}>
              <div className='tw-truncate tw-text-xs tw-text-gray-700 empty:tw-hidden'>{description}</div>
            </VisibilityWrapper>
          </div>

          <VisibilityWrapper isVisible={isOpen} className='tw-flex tw-flex-col'>
            <VisibilityWrapper isVisible={isRoot} className='tw-mt-3 tw-text-sm tw-text-black'>
              <span>{intl.formatMessage({ id: 'AgendaItem.assignee' })}: </span>
              <span className='tw-font-bold'>{assignee || intl.formatMessage({ id: 'OrganizerPicker.none' })}</span>
            </VisibilityWrapper>

            <VisibilityWrapper isVisible={documents.length > 0} className='tw-mt-3 empty:tw-hidden'>
              <AgendaItemDocumentList documents={documents} onClick={onDocumentClick} />
            </VisibilityWrapper>
          </VisibilityWrapper>
        </div>
      </Box>

      <div className='tw-ml-4 tw-flex tw-flex-col tw-gap-2'>{children}</div>
    </>
  );
};

AgendaItem.propTypes = {
  number: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  duration: PropTypes.number,
  assignee: PropTypes.string,
  documents: PropTypes.array,
  children: PropTypes.node,
  isRoot: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isToggleable: PropTypes.bool,
  onToggle: PropTypes.func,
  onDocumentClick: PropTypes.func,
};

export default AgendaItem;
