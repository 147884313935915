import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FiSearch } from 'react-icons/fi';
import { intl } from 'contexts/v2/TranslationContext';

const Input = ({ value, onChange }) => (
  <div
    className={clsx(
      'tw-flex tw-items-center tw-gap-2',
      'tw-border-0 tw-border-y tw-border-solid tw-border-gray-100',
      'tw-px-8 tw-py-4'
    )}
  >
    <FiSearch className='tw-h-4 tw-w-4' />

    <input
      type='text'
      className='tw-border-0 tw-font-sans tw-text-sm tw-text-black tw-outline-none'
      placeholder={intl.formatMessage({ id: 'BoardSpaceSelect.searchCompanies' })}
      value={value}
      onChange={onChange}
    />
  </div>
);

Input.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Input;
