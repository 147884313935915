import React from 'react';
import StateBadge from 'components/v2/shared/StateBadge';
import Avatar from '../shared/Avatar/Avatar';
import { formatDate } from 'helpers/formatDateTime';

const IndividualContacts = ({ selectedContact }) => {
  const {
    name,
    companyPosition,
    initials,
    status,
    createdAt,
    daysSinceCreation,
    lastActiveDate,
    daysSinceActive,
    avatarUrl,
  } = selectedContact;
  return (
    <div
      className='0 tw-grid-flow-col_ tw-mt-10 tw-grid
         tw-grid-cols-11 tw-items-center  tw-rounded
         tw-bg-gray-100 tw-pt-4 tw-pl-3 tw-pb-4 tw-pr-6 sm:tw-grid-cols-10'
    >
      <div className='tw-col-span-2 tw-mr-1 tw-flex tw-justify-center'>
        <Avatar placeholder={initials} imgUrl={avatarUrl} className='!tw-bg-[#AFB0D0]' />
      </div>
      <div className='tw-col-span-5 '>
        <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>{name}</div>
        <div className='tw-font-sans tw-text-xs tw-font-normal tw-text-gray-800'>{companyPosition}</div>
      </div>
      <div className='tw-col-span-2'>
        {status === 'Owner' ? (
          <StateBadge className='tw-col-span-2 tw-justify-self-end sm:tw-col-span-6' variant='gray'>
            Owner
          </StateBadge>
        ) : (
          <StateBadge
            className='tw-col-span-2 tw-justify-self-end sm:tw-col-span-3 sm:tw-justify-self-end'
            variant={
              status === 'archived'
                ? 'faint'
                : status === 'registered'
                ? 'green'
                : status === 'confirmed'
                ? 'orange'
                : status === 'invited' || status === 'reinvited'
                ? 'purple'
                : status === 'pending'
                ? 'purple'
                : 'faint'
            }
          >
            {status === 'archived'
              ? 'Archived'
              : status === 'registered'
              ? 'Active'
              : status === 'confirmed'
              ? 'Confirmed'
              : status === 'pending'
              ? 'Pending'
              : status === 'invited' || status === 'reinvited'
              ? 'Invited'
              : ''}
          </StateBadge>
        )}
      </div>
      <div className='tw-col-span-3 tw-mt-4'>
        <p className='tw-font-sans	tw-text-xs tw-font-medium tw-text-black'>Last active</p>
        <p className='tw-font-sans	tw-text-xs tw-font-medium tw-text-black'>Created</p>
      </div>
      <div className='tw-col-span-5 tw-mt-4'>
        <p className='tw-font-sans	tw-text-xs tw-font-medium tw-text-black'>
          {lastActiveDate ? formatDate(lastActiveDate, 'h:mm A, d.M.YYYY') : 'User has not yet been active.'}
        </p>
        <p className='tw-font-sans	tw-text-xs tw-font-medium tw-text-black'>
          {formatDate(createdAt, 'h:mm A, d.M.YYYY')}
        </p>
      </div>
      <div className='tw-col-span-2'>
        <p className='tw-font-sans	tw-text-xs tw-font-medium tw-text-black'>{daysSinceActive}</p>
        <p className='!tw-mb-0 tw-font-sans	tw-text-xs tw-font-medium tw-text-black'>{daysSinceCreation}</p>
      </div>
    </div>
  );
};

export default IndividualContacts;
