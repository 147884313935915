import { useState } from 'react';
import { useCurrentUserCompaniesQuery } from 'data/v2/queries/CurrentUserCompaniesQuery';
import { useArchiveCompanyContactMutation } from 'data/v2/mutations/ArchiveCompanyContactMutation';
import { useSetCompanyAsPrimaryMutation } from 'data/v2/mutations/SetCompanyAsPrimaryMutation';
import { useCloseCompanyMutation } from 'data/v2/mutations/CloseCompanyMutation';
import { NetworkStatus } from '@apollo/client';

const useCurrentUserCompanies = ({ skipFetch = false, name = '', context }) => {
  const [skip, setSkip] = useState(skipFetch);

  const { data, fetchMore, networkStatus, refetch, loading } = useCurrentUserCompaniesQuery({
    skip,
    notifyOnNetworkStatusChange: true,
    variables: { context, name },
    fetchPolicy: 'network-only',
  });

  const [archive] = useArchiveCompanyContactMutation();
  const [setPrimary] = useSetCompanyAsPrimaryMutation();
  const [close] = useCloseCompanyMutation();

  const archiveCompanyContact = async (companyId) => {
    await archive({
      variables: { input: { companyId, userId: data.currentUser.id } },
      update: (cache) => {
        cache.evict({ id: cache.identify({ __typename: 'Company', id: companyId }) });
      },
    });
  };

  const setCompanyAsPrimary = async (companyId) => {
    await setPrimary({ variables: { input: { companyId, userId: data.currentUser.id } } });
  };

  const closeCompany = async (companyId) => {
    await close({ variables: { input: { companyId } } });
  };

  const fetchMoreCompanies = (after) => {
    if (networkStatus === NetworkStatus.fetchMore) return;

    fetchMore({
      variables: {
        first: COMPANIES_PER_PAGE,
        after,
      },
    });
  };

  const fetchCompanies = () => {
    setSkip(false);
  };

  return {
    data,
    fetchMore,
    refetch,
    loading,
    archiveCompanyContact,
    setCompanyAsPrimary,
    closeCompany,
    fetchMoreCompanies,
    fetchCompanies,
  };
};

export default useCurrentUserCompanies;
