import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { GroupDrawerTableRow } from './../GroupDrawerTableRow';
import { DrawerPlaceholderTable } from './../DrawerPlaceholderTable';
import { FadingOverlay } from 'components/shared/PlaceholderTable';

const GroupDrawerTable = memo(({ contacts, onDelete }) => {
  const tableRows = contacts.map((contact) => (
    <GroupDrawerTableRow key={contact.id} contact={contact} onDelete={onDelete} />
  ));

  const intl = useIntl();

  return (
    <div className='position-relative'>
      <FadingOverlay show={!contacts.length} resource={intl.formatMessage({ id: 'GroupDrawerTable.contacts' })} />

      <table className='table table-layout-fixed'>
        <thead>
          <tr>
            <th className='w-30' />
            <th className='w-50' />
            <th className='w-20' />
          </tr>
        </thead>

        <tbody>
          {tableRows}
          <DrawerPlaceholderTable show={!contacts.length} />
        </tbody>
      </table>
    </div>
  );
});

export { GroupDrawerTable };
