import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';
import { DOCUMENT_FRAGMENT } from './../fragments/DocumentFragment';

const SEARCH_QUERY = gql`
  query searchQuery($name: String!) {
    currentCompany {
      id

      directories(name: $name) {
        ...DirectoryFields
      }

      documents(name: $name) {
        ...DocumentFields
      }
    }
  }
  ${DIRECTORY_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
`;

const useSearchQuery = (options) => useQuery(SEARCH_QUERY, options);
const useSearchLazyQuery = (options) => useLazyQuery(SEARCH_QUERY, options);

export { useSearchLazyQuery, useSearchQuery };
