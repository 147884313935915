/** @jsx jsx */

import React, { useCallback } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import Avatar from 'components/shared/Avatar';
import { ContactPicker } from 'components/shared/ContactPicker';

import { useClickOutside } from 'hooks/useClickOutside';

import * as styles from './OrganizerPicker.styles';

const OrganizerPicker = ({ organizer, contacts, onOrganizerChange }) => {
  const isSelected = (contact) => {
    return contact.id === organizer?.id;
  };

  const intl = useIntl();

  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside();

  const showDropdown = () => setIsComponentVisible(true);
  const hideDropdown = () => setIsComponentVisible(false);

  const onToggleOption = (contact) => {
    const newOrganizer = isSelected(contact) ? null : contact;

    onOrganizerChange(newOrganizer);
    hideDropdown();
  };

  const chevronStyles = [styles.chevron.base, isComponentVisible && styles.chevron.flipped];

  const organizerContacts = useCallback(() => {
    return contacts.filter((contact) => contact.calendar_connected);
  }, [contacts]);

  const renderOrganizer = () => {
    if (organizer) {
      return (
        <>
          <Avatar contact={organizer} />
          <span className='m-l-15'>{organizer.name}</span>
        </>
      );
    } else {
      return (
        <span>
          <FormattedMessage id='OrganizerPicker.none' />
        </span>
      );
    }
  };

  const organizerOption = (contact, highlighter) => (
    <>
      <Avatar contact={contact} />

      <span className='m-l-15 flex-grow-1'>{highlighter(contact)}</span>
    </>
  );

  const renderPicker = () => {
    if (isComponentVisible) {
      return (
        <div css={styles.picker} ref={ref}>
          <ContactPicker
            placeholder={intl.formatMessage({ id: 'OrganizerPicker.selectOrganizer' })}
            isMeetingOrganizerPicker
            dropdownWidth={546}
            values={[organizer]}
            dropdownOptions={organizerContacts}
            contactOption={organizerOption}
            isSelected={isSelected}
            onToggleOption={onToggleOption}
            dropdownStyles={styles.dropdown}
            dropdownIsOpen
          />
        </div>
      );
    }
  };

  return (
    <div css={styles.component}>
      <div onClick={showDropdown} css={styles.organizer}>
        {renderOrganizer()}
        <i css={chevronStyles} className='fa fa-chevron-down' />
      </div>

      {renderPicker()}
    </div>
  );
};

export { OrganizerPicker };
