import { useState } from 'react';
import { useUpdateDocumentMutation } from './../data/mutations/UpdateDocumentMutation';
import { useFlashActions } from 'store/flashStoreContext';

const useUpdateDocument = ({ id, onCompleted }) => {
  const update = (cache, { data: { updateDocument } }) => {
    const { errors } = updateDocument;

    if (errors.length) {
      return setError(errors[0].message);
    }

    onCompleted();
  };

  const { setFlash } = useFlashActions();

  const onError = ({ message }) => setFlash(message);

  const [error, setError] = useState(null);
  const [mutate, { loading: isUpdating }] = useUpdateDocumentMutation({ update, onError });

  const resetError = () => setError(null);

  const updateDocument = (name) => {
    mutate({ variables: { input: { name, documentId: id } } });
  };

  return {
    error,
    resetError,
    updateDocument,
    isUpdating,
  };
};

export { useUpdateDocument };
