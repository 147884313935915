import { css } from '@emotion/core';
import { icons, colors, media } from 'styles';

export const documentWrapper = ({ isFullWidth }) => css`
  /* width: 100%; */
  width: ${isFullWidth ? '100%' : 'calc(100% - 42px)'};
  border: 1px solid transparent;
  border-top: none;
  padding: 20px;
  border-color: ${colors.border};
  background: ${colors.lightestGrey};
  ${media.mobile} {
    width: 100%;
  }
`;

export const showDocumentsIcon = css`
  ${icons.base}

  svg {
    path {
      fill: ${colors.black};
    }
  }
`;
