/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import moment from 'moment';

import TextInput from '../TextInput';
import Label from '../Label';
import InputError from '../InputError';
import * as styles from './TimePicker.styles';

const TimePicker = ({ className, value, isDisabled, onChange, label, errorMessage }) => {
  const [timeValue, setTimeValue] = useState(moment(value).parseZone());

  const getTimeValues = () => {
    return [timeValue.format('hh'), timeValue.format('mm'), timeValue.format('A')];
  };

  useEffect(() => {
    value && setTimeValue(moment(value).parseZone());
  }, [value]);

  const modifyValue = (valueName, value) => () => {
    if (isDisabled) {
      return;
    }

    setTimeValue(timeValue.add(value, valueName));
    onChange(timeValue);
  };

  const setValue = (valueName) => (e) => {
    if (isDisabled) {
      return;
    }

    setTimeValue(timeValue[valueName](parseInt(e.target.value)));
    onChange(timeValue);
  };

  const setAmPm = (value) => () => {
    if (isDisabled) {
      return;
    }

    const [, , amPm] = getTimeValues();
    if (amPm === value) {
      return;
    }

    if (amPm === 'AM') {
      setTimeValue(timeValue.add(12, 'hours'));
    } else {
      setTimeValue(timeValue.subtract(12, 'hours'));
    }
    onChange(timeValue);
  };

  const getAmPmButtonStyles = (value) => {
    const [, , amPm] = getTimeValues();
    return [styles.amPmButton.base, amPm === value && styles.amPmButton.active];
  };

  const [hourValue, minuteValue, amPmValue] = getTimeValues();

  return (
    <div css={styles.mainWrapper} className={className}>
      <Label text={label} />
      <div css={styles.component}>
        <div css={styles.wrapper}>
          <div className='d-block'>
            <i onClick={modifyValue('hours', 1)} className='fa fa-chevron-up' />
          </div>
          <TextInput
            onChange={setValue('hours')}
            value={hourValue}
            isDisabled={isDisabled}
            isInvalid={!!errorMessage}
          />
          <div className='d-block'>
            <i onClick={modifyValue('hours', -1)} className='fa fa-chevron-down' />
          </div>
        </div>
        <span className='font-weight-bold' css={styles.timeDelimeter}>
          :
        </span>
        <div css={styles.wrapper}>
          <div className='d-block'>
            <i onClick={modifyValue('minutes', 15)} className='fa fa-chevron-up' />
          </div>
          <TextInput
            onChange={setValue('minutes')}
            value={minuteValue}
            isDisabled={isDisabled}
            isInvalid={!!errorMessage}
          />
          <div className='d-block'>
            <i onClick={modifyValue('minutes', -15)} className='fa fa-chevron-down' />
          </div>
        </div>
        <div css={styles.amPmcontainer}>
          <div css={getAmPmButtonStyles('AM')} onClick={setAmPm('AM')}>
            AM
          </div>
          <div css={getAmPmButtonStyles('PM')} onClick={setAmPm('PM')}>
            PM
          </div>
        </div>
      </div>
      <div css={styles.errorWrapper}>
        <InputError message={errorMessage} />
      </div>
    </div>
  );
};

TimePicker.propTypes = {
  value: PropTypes.instanceOf(moment),
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default TimePicker;
