/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import Text from 'components/v2/shared/Text';
import ActionsMenu from 'components/v2/shared/ActionsMenu';
import MenuItem from 'components/v2/shared/MenuItem';

import * as styles from './TaskItemActionsMenu.styles';

const TaskItemActionsMenu = ({ className }) => {
  return (
    <ActionsMenu className={className}>
      <MenuItem>
        <Text text='Expand' />
      </MenuItem>
      <MenuItem>
        <Text text='Edit' />
      </MenuItem>
      <MenuItem>
        <Text text='Delete task item' />
      </MenuItem>
      <MenuItem>
        <Text text='Move to agenda item' />
      </MenuItem>
      <MenuItem>
        <Text text='Assign new user' />
      </MenuItem>
      <MenuItem>
        <Text text='Add description' />
      </MenuItem>
    </ActionsMenu>
  );
};

TaskItemActionsMenu.propTypes = {
  className: PropTypes.string,
};

export default TaskItemActionsMenu;
