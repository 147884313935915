import React, { memo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { FlashProvider } from 'store/flashStoreContext';
import { ContactProvider } from 'store/contactStoreContext';
import { client } from 'utils/apollo';
import AppSignalWrapper from '../../v2/shared/AppSignalWrapper';
import { TranslationContextProvider } from '../../../contexts/v2/TranslationContext';

import { Form } from 'components/votes/Form';

export default memo(({ vote, contactId, companyId }) => {
  return (
    <AppSignalWrapper tags={{ page: 'votes/create' }}>
      <TranslationContextProvider>
        <ApolloProvider client={client}>
          <FlashProvider>
            <ContactProvider value={{ contactId, companyId }}>
              <Form companyId={companyId} />
            </ContactProvider>
          </FlashProvider>
        </ApolloProvider>
      </TranslationContextProvider>
    </AppSignalWrapper>
  );
});
