import { gql } from '@apollo/client';

const ACTIVITY_FEED_FRAGMENT = gql`
  fragment ActivityFeedFields on ActivityFeed {
    id
    description
    createdAt
    author
  }
`;

export { ACTIVITY_FEED_FRAGMENT };
