import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { ContactProvider } from 'store/contactStoreContext';
import { UploadQueueProvider } from 'store/uploadQueueStoreContext';
import { FlashProvider } from 'store/flashStoreContext';
import { client } from 'utils/apollo';
import AppSignalWrapper from '../../v2/shared/AppSignalWrapper';
import { TranslationContextProvider } from '../../../contexts/v2/TranslationContext';

import { Container } from 'components/documents/Container';

export default ({ directoryId, documentId, contactId, companyId }) => (
  <AppSignalWrapper tags={{ page: 'doocuments' }}>
    <TranslationContextProvider>
      <ApolloProvider client={client}>
        <FlashProvider>
          <ContactProvider value={{ contactId, companyId }}>
            <UploadQueueProvider>
              <div className='container-lg'>
                <Container directoryId={directoryId} documentId={documentId} />
              </div>
            </UploadQueueProvider>
          </ContactProvider>
        </FlashProvider>
      </ApolloProvider>
    </TranslationContextProvider>
  </AppSignalWrapper>
);
