import React from 'react';

import { DateCell } from './DateCell';
import { MultilineCell } from './MultilineCell';
import { TwoMediumLinesCell } from './TwoMediumLinesCell';
import { TwoLinesCell } from './TwoLinesCell';
import { StatusCell } from './StatusCell';
import { ActionsCell } from './ActionsCell';

const PlaceholderTable = ({ show }) => {
  return (
    show &&
    [...Array(8)].map((_, index) => (
      <tr className='placeholder-table-row' key={`placeholder-row-${index}`}>
        <td>
          <DateCell />
        </td>
        <td>
          <MultilineCell />
        </td>
        <td>
          <TwoMediumLinesCell />
        </td>
        <td>
          <TwoLinesCell />
        </td>
        <td>
          <TwoLinesCell />
        </td>
        <td>
          <StatusCell />
        </td>
        <td>
          <ActionsCell />
        </td>
      </tr>
    ))
  );
};

export { PlaceholderTable };
