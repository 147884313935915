import React from 'react';
import { withIntl } from '../../../utils/v2/intl';
import { TitleDesktop, TitleWrapperDesktop } from './styles';

const Header = ({ title, statusDasherize, statusHumanize, withBackButton = false }) => {
  return (
    <>
      {withBackButton ? null : (
        <TitleWrapperDesktop className='d-flex align-items-center'>
          <TitleDesktop className='font-weight-semibold text-black m-r-10'>{title}</TitleDesktop>
          {statusDasherize && statusHumanize ? (
            <div className={` badge badge-large badge-${statusDasherize}`}>{statusHumanize}</div>
          ) : null}
        </TitleWrapperDesktop>
      )}
    </>
  );
};

export default withIntl(Header);
