import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const ToggleButton = ({ Element = 'button', children, isActive, className, onClick }) => (
  <Element
    onClick={onClick}
    className={clsx(
      'tw-appearance-none tw-border-none tw-outline-none',
      'tw-flex tw-items-center tw-justify-center',
      'tw-h-8 tw-w-8',
      'tw-cursor-pointer tw-rounded hover:tw-opacity-80',
      className,
      {
        'tw-bg-transparent tw-text-white/90 xl:tw-text-gray-900': !isActive,
        'tw-bg-purple-600 tw-text-white xl:tw-bg-transparent xl:tw-text-purple-500': isActive,
      }
    )}
  >
    {children}
  </Element>
);

ToggleButton.propTypes = {
  Element: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default ToggleButton;
