import { gql, useMutation } from '@apollo/client';

const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation markAllNotificationsAsRead($input: MarkAllNotificationsAsReadInput!) {
    markAllNotificationsAsRead(input: $input) {
      notifications {
        id
        read
      }

      showBadge
    }
  }
`;

const useMarkAllNotificationsAsReadMutation = (options) => useMutation(MARK_ALL_NOTIFICATIONS_AS_READ, options);

export { useMarkAllNotificationsAsReadMutation };
