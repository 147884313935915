import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from 'components/v2/shared/Button';
import { intl } from 'contexts/v2/TranslationContext';
import useWindowDimensions from 'hooks/useWindowDimensions';

const maxLinesClassMap = {
  1: 'tw-line-clamp-1',
  2: 'tw-line-clamp-2',
  3: 'tw-line-clamp-3',
  4: 'tw-line-clamp-4',
};

const TextLineClampWithToggle = ({ text, maxLines = 1, className }) => {
  const [isClamped, setIsClamped] = useState(true);
  const [isToggleVisible, setIsToggleVisible] = useState(true);
  const toggleIsClamped = () => setIsClamped((prev) => !prev);

  const textRef = useRef();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  useLayoutEffect(() => {
    if (!textRef) return;
    if (!textRef.current) return;
    if (!isClamped) return setIsToggleVisible(true);

    const { clientHeight, scrollHeight } = textRef.current;
    setIsToggleVisible(clientHeight < scrollHeight);
  }, [windowWidth, windowHeight, text, className, maxLines]);

  const renderButtonText = () => {
    const textElements = isClamped
      ? ['+', intl.formatMessage({ id: 'TextLineClampWithToggle.show' })]
      : ['-', intl.formatMessage({ id: 'TextLineClampWithToggle.hide' })];

    return textElements.join(' ');
  };

  if (!text) {
    return null;
  }

  return (
    <div className={clsx('tw-flex tw-flex-col', className)}>
      <div ref={textRef} className={clsx('tw-break-words', isClamped && maxLinesClassMap[maxLines])}>
        {text}
      </div>

      <Button
        variant='transparent'
        className={clsx('!tw-justify-start !tw-p-0 !tw-text-xs', {
          '!tw-hidden': !isToggleVisible,
        })}
        onClick={toggleIsClamped}
      >
        {renderButtonText()}
      </Button>
    </div>
  );
};

TextLineClampWithToggle.propTypes = {
  text: PropTypes.string,
  maxLines: PropTypes.number,
  className: PropTypes.string,
};

export default TextLineClampWithToggle;
