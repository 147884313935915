/** @jsx jsx */

import React, { memo, useRef } from 'react';
import { Modal } from 'components/shared/Modal';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';
import 'blueimp-canvas-to-blob';

import api from './../API';
import * as styles from './Modal.styles';
import SignatureInput from '../SignatureInput';

export default memo(
  ({ show, name, onClose, token, onSignatureInsert, documentId, fieldId, existingSignatureImageUrl }) => {
    const signatureInput = useRef();
    const intl = useIntl();

    const uploadSignature = (forAll) => {
      signatureInput.current.processInput((blob) => {
        api.uploadSignature(
          documentId,
          fieldId,
          token,
          blob,
          forAll,
          (response) => {
            onSignatureInsert(response);
            signatureInput.current.resetControl();
          },
          (error) => {
            signatureInput.current.setError(error.response.data.errors);
          }
        );
      });
    };

    const footer = (
      <div className='text-center w-100'>
        <div className='p-t-25 p-b-25'>
          <button className='btn btn-inverted' onClick={uploadSignature.bind(this, false)}>
            <FormattedMessage id='Modal.insert' />
          </button>
          <button className='m-l-25 btn btn-primary' onClick={uploadSignature.bind(this, true)}>
            <FormattedMessage id='Modal.insertEvery' />
          </button>
        </div>
      </div>
    );

    return (
      <Modal
        modalStyles={styles.modal}
        footer={footer}
        centered
        show={show}
        onClose={onClose}
        title={intl.formatMessage({ id: 'Modal.signature' })}
      >
        <SignatureInput name={name} existingSignatureImageUrl={existingSignatureImageUrl} ref={signatureInput} />
      </Modal>
    );
  }
);
