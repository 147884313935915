import { css } from '@emotion/core';

export const wrapper = css`
  padding-right: 20px;
  overflow: auto;
  height: 214px;
`;

export const placeholder = css`
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  height: 214px;
  justify-content: center;
`;
