import { gql, useMutation } from '@apollo/client';

const UPDATE_PROFILE_DATE_AND_TIMEZONE_SETTINGS_MUTATION = gql`
  mutation UpdateProfileDateAndTimezoneSettings($input: UpdateProfileDateAndTimezoneSettingsInput!) {
    updateProfileDateAndTimezoneSettings(input: $input) {
      user {
        id

        dateFormat {
          label
          value
        }

        address {
          id

          timezone {
            value: name
            label
          }
        }
      }

      errors {
        attribute
        message
      }
    }
  }
`;

const useUpdateProfileDateAndTimezoneSettingsMutation = (options) =>
  useMutation(UPDATE_PROFILE_DATE_AND_TIMEZONE_SETTINGS_MUTATION, options);

export { UPDATE_PROFILE_DATE_AND_TIMEZONE_SETTINGS_MUTATION, useUpdateProfileDateAndTimezoneSettingsMutation };
