import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ListItem, { ListItemPlaceholder } from 'components/v2/smartSign/ListItem';
import ListPlaceholder from 'components/v2/shared/ListPlaceholder';
import { injectIntl, FormattedMessage } from 'react-intl';
import { formatDate, formatTime } from 'helpers/formatDateTime';

const List = ({ items = [], intl }) => (
  <div className='tw-flex tw-flex-col tw-gap-4'>
    <div className='tw-hidden tw-grid-cols-12 tw-gap-2 tw-px-6 tw-text-xs tw-font-medium tw-text-black lg:tw-grid'>
      <div className='tw-col-span-6'>
        <FormattedMessage id='SmartSign.List.document' />
      </div>

      <div className='tw-col-span-2 tw-text-center'>
        <FormattedMessage id='SmartSign.List.signatures' />
      </div>

      <div className='tw-col-span-1 tw-text-center'>
        <FormattedMessage id='SmartSign.List.status' />
      </div>

      <div className='tw-col-span-2'>
        <FormattedMessage id='SmartSign.List.created' />
      </div>

      <div className='tw-col-span-1 tw-text-center'>
        <FormattedMessage id='SmartSign.List.owner' />
      </div>
    </div>

    <div className='tw-flex tw-flex-col tw-gap-2'>
      {!items.length && (
        <ListPlaceholder
          listItemNode={<ListItemPlaceholder />}
          placeholderText={intl.formatMessage({ id: 'SmartSign.List.placeholderText' })}
          buttonText={intl.formatMessage({ id: 'SmartSign.List.placeholderButtonText' })}
        />
      )}

      {items.map((item) => (
        <ListItem
          key={item.id}
          name={item.name}
          signedSignatureCount={item.signedSignatureCount}
          totalSignatureCount={item.totalSignatureCount}
          status={item.status}
          createdAtDate={formatDate(item.createdAt, 'DD MMM YY')}
          createdAtTime={formatTime(item.createdAt)}
          ownerInitials={item.owner.initials}
        />
      ))}
    </div>
  </div>
);

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      signedSignatureCount: PropTypes.number.isRequired,
      totalSignatureCount: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      owner: PropTypes.shape({
        initials: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
};

export default injectIntl(List);
