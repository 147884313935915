import { css } from '@emotion/core';
import { colors } from 'styles';

export const wrapper = {
  base: css`
    transition: none;
    flex: left;
    position: relative;
  `,

  focused: css`
    border-left: none;
    transition: width 0.2s ease-in-out;
    will-change: width;
    width: 100%;
  `,
};

export const input = css`
  padding: 8px 12px 8px 40px;
  font-size: 12px;
  line-height: 1;
  border-radius: 2px;
`;

export const clear = css`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;
