import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import IconButton from 'components/v2/shared/IconButton';
import { FiX, FiTrash2, FiRotateCcw } from 'react-icons/fi';
import Button, { Sizes, Variants } from 'components/v2/shared/Button';
import { AttachmentState } from 'contexts/v2/FileUploadContext';

const attachmentStateLabelMap = {
  [AttachmentState.Enqueued]: 'Preparing',
  [AttachmentState.Pending]: 'Uploading',
  [AttachmentState.Cancelled]: 'Cancelled',
};

const Attachment = ({ name, state, progress = 0, error, onCancel, onRestart, onRemove }) => (
  <div
    className={clsx(
      'tw-flex tw-shrink-0 tw-items-center tw-justify-between',
      'tw-relative tw-overflow-hidden',
      'tw-rounded tw-bg-gray-100',
      'tw-px-4 tw-py-4 md:tw-px-6'
    )}
  >
    <div
      className={clsx('tw-absolute tw-inset-0 tw-z-0 -tw-translate-x-full tw-transition-transform', {
        '!tw-translate-x-0 tw-bg-red-100': state === AttachmentState.Failed,
        'tw-bg-purple-200': state === AttachmentState.Pending,
        'tw-bg-teal-200': state === AttachmentState.Uploaded,
        'tw-bg-gray-200': state === AttachmentState.Cancelled,
      })}
      style={{ transform: `translateX(${progress - 100}%)` }}
    ></div>

    <div className='tw-z-10 tw-min-w-0 tw-truncate tw-text-sm tw-font-semibold tw-text-black'>{name}</div>

    <div className='tw-z-10 tw-ml-2 tw-flex tw-items-center tw-text-xs tw-font-medium'>
      <div className='tw-whitespace-nowrap tw-text-red-400 empty:tw-hidden'>{error}</div>
      <div className='tw-whitespace-nowrap tw-text-gray-700 empty:tw-hidden'>{attachmentStateLabelMap[state]}</div>

      {state === AttachmentState.Failed && (
        <IconButton
          title='Remove'
          className='tw-ml-2 hover:!tw-bg-red-500/20'
          onClick={onRemove}
          children={<FiTrash2 />}
        />
      )}

      {state === AttachmentState.Pending && (
        <IconButton title='Cancel' className='tw-ml-2 hover:!tw-bg-gray-500/20' onClick={onCancel} children={<FiX />} />
      )}

      {state === AttachmentState.Cancelled && (
        <IconButton
          title='Restart'
          className='tw-ml-2 hover:!tw-bg-gray-500/20'
          onClick={onRestart}
          children={<FiRotateCcw />}
        />
      )}
    </div>
  </div>
);

Attachment.propTypes = {
  name: PropTypes.string,
  progress: PropTypes.number,
  error: PropTypes.string,
  state: PropTypes.oneOf(Object.values(AttachmentState)),
  onRemove: PropTypes.func,
  onCancel: PropTypes.func,
  onRestart: PropTypes.func,
};

export default Attachment;
