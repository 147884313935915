import React, { useState, useEffect } from 'react';
import Heading from 'components/v2/shared/Heading';
import Switch from 'components/v2/shared/Switch';
import Button from 'components/v2/shared/Button';
import Box from 'components/v2/shared/Box';
import { FiChevronRight } from 'react-icons/fi';
import clsx from 'clsx';
import logoFooter from 'images/icons/logo-footer.svg';
import Chooseyourplan from './Chooseyourplan';

const Index = () => {
  const [isIndexPlan, setIndexPlan] = useState(0);
  const [isActive, setActive] = useState(false);
  const [isActivePaymentSuccess, setActivePaymentSuccess] = useState(false);
  const [isActivePlan, setActivePlan] = useState(false);
  const [planData] = useState([
    {
      title: 'Standard Business',
      amount: 30,
    },
    {
      title: 'Education and Associations',
      amount: 120,
    },
    {
      title: 'Start Up and Charity',
      amount: 160,
    },
  ]);
  const [selectedPlanData, setSelectedPlanData] = useState(planData && planData.length > 0 ? planData[0] : '');

  const handleOnSwitchChange = () => {
    setActive((current) => !current);
  };
  const onClickPlan = (data, index) => {
    setActivePlan(true);
    setSelectedPlanData(data);
    setIndexPlan(index);
  };
  const onClickPaymentSuccess = () => {
    setActivePaymentSuccess(true);
  };
  useEffect(() => {
    setActivePlan(true);
  }, []);

  return (
    <div className='tw-bg-white'>
      <div className='tw-flex'>
        <img src={logoFooter} alt='Shaparency' className='!tw-h-12 !tw-w-24' />
      </div>
      <div
        className={clsx('tw-justify-center_ tw-mx-auto tw-w-full xl:tw-flex', {
          'lg:tw-flex-row': !isActivePaymentSuccess,
          'tw-flex-col sm:tw-max-w-md sm:tw-px-0': isActivePaymentSuccess,
        })}
      >
        {!isActivePaymentSuccess && (
          <>
            <div className='tw-flex tw-justify-center xl:tw-w-1/2'>
              <div className='tw-max-w-md xl:tw-w-[350px]'>
                <div className='tw-hidden tw-items-center tw-justify-between sm:tw-block sm:tw-flex'>
                  <Heading text='Choose your plan' className='!tw-mb-0 tw-font-sans tw-text-3xl tw-font-bold' />
                </div>
                <div>
                  <div className='tw-py-3'>
                    <div className='tw-col-span-2'>
                      <span className='tw-font-sans tw-text-sm tw-font-medium !tw-text-black tw-text-gray-700 sm:tw-text-sm'>
                        We've made pricing simple, for established companies of any size and to help charities and
                        companies just starting out we're offering discounts.
                      </span>
                    </div>
                  </div>
                </div>
                <div className='tw-mt-2.5'>
                  <h6 className='tw-mb-0 tw-pt-3 tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>
                    Select plan below
                  </h6>
                  <div className='tw-mt-8 tw-flex tw-items-center xl:tw-mt-6'>
                    <div className='tw-font-sans tw-text-xs tw-font-medium tw-text-black'>Pricing by</div>
                    <div className='tw-px-8'>
                      <Switch
                        labelAfter='Year'
                        labelBefore='Month'
                        onChange={handleOnSwitchChange}
                        variant='purple-bg'
                        isChecked={isActive}
                      />
                    </div>
                  </div>
                  <div className='tw-mt-7 xl:tw-mt-5'>
                    <p className='!tw-m-0 tw-font-sans tw-text-xs tw-font-medium tw-text-gray-700'>
                      Note - Prices are per month / per boardspace, paid monthly. Exclude VAT.
                    </p>
                  </div>
                </div>
                <div className='tw-my-5'>
                  {planData.map((row, i) => {
                    return (
                      <>
                        <div className='tw-mb-2 tw-rounded !tw-bg-teal-200' key={i}>
                          <Box
                            onClick={() => {
                              onClickPlan(row, i);
                            }}
                            isSelected={isIndexPlan === i}
                            className='tw-cursor-pointer !tw-rounded !tw-py-6'
                            variant='teal'
                          >
                            <div className='tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-8'>
                              <div className='tw-col-span-7 xl:tw-col-span-8'>
                                <div className='tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>
                                  {row.title}
                                </div>
                                <div className='tw-justify-between sm:tw-flex'>
                                  <div className='tw-mt-2 tw-font-sans tw-text-sm tw-font-medium tw-text-black sm:tw-mt-5'>
                                    ${row.amount} AUD / month
                                  </div>
                                  <div className='tw-grid tw-grid-flow-col tw-gap-6'>
                                    <div className='tw-mt-3.5 sm:tw-mt-5'>
                                      <Button
                                        font='medium'
                                        onClick={() => {}}
                                        size='large'
                                        variant='transparent'
                                        className={clsx('!tw-py-0 !tw-px-0 tw-font-sans !tw-text-xs tw-font-medium', {
                                          '!tw-text-teal-600': isIndexPlan === i,
                                        })}
                                      >
                                        See inclusions
                                      </Button>
                                    </div>
                                    <div className='tw-mt-3.5 sm:tw-mt-5'>
                                      <Button
                                        font='medium'
                                        onClick={() => {}}
                                        size='large'
                                        variant='transparent'
                                        className={clsx('!tw-py-0 !tw-px-0 tw-font-sans !tw-text-xs tw-font-medium', {
                                          '!tw-text-teal-600': isIndexPlan === i,
                                        })}
                                      >
                                        Select plan
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='xl:tw-col-span-0 tw-col-span-1 tw-flex tw-items-center	tw-justify-end xl:tw-hidden'>
                                <FiChevronRight className='tw-h-5 tw-w-5 !tw-text-gray-700 tw-duration-100' />
                              </div>
                            </div>
                          </Box>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className={clsx('xl:tw-w-1/2', {
                'xl:tw-border-0 xl:tw-border-l xl:tw-border-solid xl:tw-border-gray-200': isActivePlan,
              })}
            >
              <div className='tw-mx-auto tw-max-w-md xl:tw-w-[350px]'>
                {isActivePlan && <Chooseyourplan data={selectedPlanData} onClick={onClickPaymentSuccess} />}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
