/** @jsx jsx */

import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/core';

import {
  useMeetingManager,
  VideoTileGrid,
  useMeetingStatus as useChimeMeetingStatus,
  useRosterState,
  Roster,
  RosterGroup,
  RosterAttendee,
  RosterHeader,
} from 'amazon-chime-sdk-component-library-react';
import { FormattedMessage, useIntl } from 'react-intl';

import * as styles from './index.styles';
import { useMeetingStore } from 'store/meetingStoreContext';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import { ChimeControlBar } from './ChimeControlBar';
import { MEETING_STATUS } from './constants';
import { objNotEmpty } from './helpers';
import { useChimeMeetingContext } from 'store/chimeMeetingContext';
import { AlertPopup } from 'components/shared/AlertPopup';

export const MeetingAmazonChime = () => {
  const { meetingInfo, attendeeInfo, getAttendee } = useChimeMeetingContext();
  const [camPopupVisible, setCamPopupVisible] = useState(false);

  const intl = useIntl();

  const { meeting } = useMeetingStore();
  const meetingManager = useMeetingManager();
  const meetingStatus = useChimeMeetingStatus();

  const { isInSession } = useMeetingStatus(meeting.status);

  const { roster } = useRosterState();
  const attendees = Object.values(roster);

  useEffect(() => {
    if (objNotEmpty(meetingInfo) && objNotEmpty(attendeeInfo)) joinMeeting();
  }, [meetingInfo, attendeeInfo]);

  useEffect(() => {
    if (isInSession) {
      window.addEventListener('beforeunload', (e) => {
        e.preventDefault();
        e.returnValue = '';
      });
    } else {
      window.removeEventListener('beforeunload');
    }
  }, [isInSession]);

  useEffect(() => {
    meetingManager.getAttendee = getAttendee;
  }, []);

  const joinMeeting = async () => {
    const joinData = {
      meetingInfo,
      attendeeInfo,
    };
    await meetingManager.join(joinData);
    await meetingManager.start();
    setCamPopupVisible(true);
  };

  const attendeeItems = attendees.map((attendee) => {
    const { chimeAttendeeId } = attendee;
    return <RosterAttendee key={chimeAttendeeId} attendeeId={chimeAttendeeId} />;
  });

  const handlePopupClose = () => {
    setCamPopupVisible(false);
  };

  return (
    <>
      <div css={styles.wrapper}>
        {meetingStatus === MEETING_STATUS.SUCCEEDED ? (
          <div css={styles.container}>
            <div css={styles.gridContainer}>
              <Roster css={styles.rosterContainer}>
                <RosterHeader
                  title={intl.formatMessage({ id: 'MeetingAmazonChime.attendees' })}
                  badgge={`${attendees.length}`}
                />
                <RosterGroup>{attendeeItems}</RosterGroup>
              </Roster>
              <VideoTileGrid css={styles.videoGrid} />
            </div>
            <ChimeControlBar />
          </div>
        ) : null}
      </div>
      <AlertPopup show={camPopupVisible} popupType='info' onClose={handlePopupClose}>
        <div className='text-black fs-14 lh-22 m-b-20'>
          <FormattedMessage id='MeetingAmazonChime.instruction' />
        </div>
        <button className={`btn btn-sm-fw btn-info`} onClick={handlePopupClose}>
          <FormattedMessage id='MeetingAmazonChime.ok' />
        </button>
      </AlertPopup>
    </>
  );
};
