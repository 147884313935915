import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useCombobox } from 'downshift';
import { useVirtual } from 'react-virtual';
import { usePopper } from 'react-popper';
import Combobox from './Combobox';
import Menu from './Menu';
import { OptionVariants } from './constants';

import Label from 'components/v2/shared/Label';
import InputError from 'components/v2/shared/InputError';
import Input from './Input';
import Toggle from './Toggle';

const SelectInput = ({
  options,
  label,
  value,
  onChange = () => {},
  renderModal = () => {},
  renderFooter = () => {},
  placeholder = 'Select',
  errorMessage = '',
  optionVariant = OptionVariants.Regular,
  isDisabled = false,
  isFilterable = false,
  isMulti = false,
  listHeight = 'regular',
  listBorder = false,
  isToggleBorder = true,
  toggleVariant = 'default',
}) => {
  const menuRef = useRef();
  const inputRef = useRef();
  const toggleRef = useRef();

  const {
    styles,
    attributes,
    update: updatePopper,
  } = usePopper(toggleRef.current, menuRef.current, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const itemToString = (item) => (item ? item.label : '');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputItems, setInputItems] = useState(options);
  const [selectedItem, setSelectedItem] = useState(value);
  const [selectedItems, setSelectedItems] = useState(value || []);

  useEffect(() => setInputItems(options), [options]);

  const showModal = (e) => {
    // Headless Dialog tries to restore button focus when closed.
    // Blur button before opening so that we can focus input later on.
    e.currentTarget.blur();
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    inputRef.current.focus();
  };

  const onDataAdded = (data) => {
    onSelectedItemChange({ selectedItem: data });
    closeModal();

    if (!isMulti) {
      closeMenu();
    }
  };

  const onInputValueChange = ({ inputValue }) => {
    if (inputValue) {
      const filteredItems = options.filter((option) =>
        itemToString(option).toLowerCase().match(inputValue.toLowerCase())
      );
      setInputItems(filteredItems);
    } else {
      setInputItems(options);
    }
  };

  const onIsOpenChange = ({ isOpen }) => {
    updatePopper && updatePopper();

    if (!isOpen) {
      inputRef.current.blur();
    }
  };

  const toggleSelectedItems = (selectedItem) => {
    const itemExists = selectedItems.find((item) => item.value === selectedItem.value);

    if (itemExists) {
      return selectedItems.filter((item) => item.value !== selectedItem.value);
    } else {
      return [...selectedItems, selectedItem];
    }
  };

  const onSelectedItemChange = ({ selectedItem }) => {
    if (!isMulti) {
      setSelectedItem(selectedItem);
      onChange(selectedItem);
    } else {
      const values = toggleSelectedItems(selectedItem);
      setSelectedItems(values);
      onChange(values);
    }
  };

  const stateReducer = (state, { type, changes }) => {
    switch (type) {
      case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
      case useCombobox.stateChangeTypes.ToggleButtonClick:
        return { ...changes, inputValue: changes.isOpen ? '' : itemToString(selectedItem) };
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.ItemClick:
        return { ...changes, isOpen: isMulti, inputValue: isMulti ? '' : itemToString(changes.selectedItem) };
      case useCombobox.stateChangeTypes.InputKeyDownEscape:
      case useCombobox.stateChangeTypes.InputBlur:
        return {
          ...changes,
          isOpen: isModalVisible,
          inputValue: isModalVisible ? inputValue : itemToString(selectedItem),
        };
      case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
        return { ...changes, inputValue: state.inputValue || '' };
      default:
        return changes;
    }
  };

  const {
    isOpen,
    inputValue,
    closeMenu,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    getComboboxProps,
    getInputProps,
  } = useCombobox({
    itemToString,
    items: inputItems,
    initialInputValue: itemToString(value),
    selectedItem,
    stateReducer,
    onInputValueChange,
    onSelectedItemChange,
    onIsOpenChange,
  });

  return (
    <div className='tw-flex tw-flex-col'>
      {!!label && <Label isInvalid={!!errorMessage} text={label} {...getLabelProps()} />}

      <div {...getComboboxProps()}>
        <Toggle
          {...getToggleButtonProps({ ref: toggleRef, disabled: isDisabled })}
          isOpen={isOpen}
          isInvalid={!!errorMessage}
          isDisabled={isDisabled}
          size={listHeight}
          isToggleBorder={isToggleBorder}
          variant={toggleVariant}
        >
          <Input
            {...getInputProps({
              disabled: isDisabled,
              ref: inputRef,
              readOnly: !isFilterable,
              placeholder,
            })}
            size={listHeight}
          />
        </Toggle>
      </div>

      <div
        {...attributes.popper}
        {...getMenuProps({ ref: menuRef })}
        style={{ ...styles.popper, minWidth: toggleRef.current?.offsetWidth }}
        className='tw-z-10'
      >
        <Menu
          items={inputItems}
          isOpen={isOpen}
          renderFooter={renderFooter({ showModal, closeMenu })}
          getItemProps={getItemProps}
          selectedItem={isMulti ? selectedItems : selectedItem}
          optionVariant={optionVariant}
          height={listHeight}
          listBorder={listBorder}
        />
      </div>

      <InputError message={errorMessage} className='tw-my-3' />

      {renderModal({ isModalVisible, closeModal, onDataAdded })}
    </div>
  );
};

SelectInput.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isDisabled: PropTypes.bool,
  isFilterable: PropTypes.bool,
  renderModal: PropTypes.func,
  renderFooter: PropTypes.func,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  listHeight: PropTypes.string,
  toggleVariant: PropTypes.string,
  optionVariant: PropTypes.oneOf(Object.values(OptionVariants)),
  listBorder: PropTypes.bool,
  isToggleBorder: PropTypes.bool,
};

export default SelectInput;
