import { css } from '@emotion/core';
import { fonts, panels, colors } from 'styles';

export const wrapper = css`
  text-align: center;
  color: ${colors.black};
  cursor: pointer;
  font-size: 12px;
`;

export const icon = css`
  font-size: 22px;
  text-align: center;
  background: rgba(0, 186, 198, 0.12);
  border-radius: 3px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  color: ${colors.cyan};
`;

export const name = css`
  ${fonts.regular}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const createdAt = css`
  ${fonts.styledLight}
  font-weight: 300;
`;
