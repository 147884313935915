import React, { memo } from 'react';

import { TwoLinesCell } from 'components/shared/PlaceholderTable/TwoLinesCell';
import { StatusCell } from 'components/shared/PlaceholderTable/StatusCell';

const PlaceholderTable = memo(({ show }) => {
  return (
    show &&
    [...Array(8)].map((_, index) => (
      <tr className='placeholder-table-row' key={`placeholder-row-${index}`}>
        <td>
          <TwoLinesCell />
        </td>
        <td>
          <StatusCell alignRight />
        </td>
      </tr>
    ))
  );
});

export { PlaceholderTable };
