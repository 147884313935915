import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const padded = css`
  padding: 35px 40px 0;
  ${media.tablet} {
    padding: 15px 15px 0;
  }
`;

export const title = css`
  font-size: 16px;
  ${fonts.bold};
  font-weight: 600;
  color: ${colors.black};
`;

export const attendeesWrapper = css`
  display: flex;
  flex-wrap: wrap;
`;

export const attendee = css`
  margin-right: 10px;
  margin-bottom: 10px;
`;
