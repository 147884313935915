import React from 'react';
import { FormattedMessage } from 'react-intl';

export const WorkspaceHeader = ({ documentIsConfigured, openModal }) => {
  const companySlug = getCompanySlug();
  const smartDocumentsURL = `/${companySlug}/smart_sign`;

  return (
    <div className='d-flex align-items-center justify-content-end m-b-10'>
      <a href={smartDocumentsURL} data-turbolinks={false} className='btn btn-inverted'>
        <FormattedMessage id='WorkspaceHeader.saveClose' />
      </a>

      {documentIsConfigured && (
        <div className='m-l-10'>
          <button disabled={!documentIsConfigured} onClick={openModal} className='btn btn-primary'>
            <FormattedMessage id='WorkspaceHeader.sendSign' />
          </button>
        </div>
      )}
    </div>
  );
};
