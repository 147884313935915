/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import Avatar, { Colors } from 'components/v2/shared/Avatar';
import Badge, { Colors as BadgeColors } from 'components/v2/shared/Badge';

import * as styles from './AvatarWithBadge.styles';

const AvatarWithBadge = ({ text, alt, avatarColor, badgeColor, className }) => (
  <Badge color={badgeColor} className={className}>
    <Avatar alt={alt} color={avatarColor} text={text} />
  </Badge>
);

AvatarWithBadge.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  alt: PropTypes.string,
  avatarColor: PropTypes.oneOf(Object.values(Colors)),
  badgeColor: PropTypes.oneOf(Object.values(BadgeColors)),
};

export default AvatarWithBadge;
