import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Input = forwardRef((props, ref) => (
  <input
    {...props}
    ref={ref}
    className={clsx(
      'tw-border-none tw-p-0 tw-outline-none',
      'tw-font-sans tw-text-sm tw-text-black placeholder:tw-text-black',
      'tw-w-full tw-cursor-inherit tw-bg-transparent',
      {
        'tw-font-medium placeholder:tw-font-sans placeholder:tw-font-medium ': props.size === 'large',
      }
    )}
  />
));
Input.propTypes = {
  props: PropTypes.object,
};

export default Input;
