import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useCurrentContactContext } from 'contexts/v2/CurrentContactContext';
import { useContactZoomMeetingTopicsQuery } from 'data/v2/queries/ContactZoomMeetingTopicsQuery';
import { findOptionByValue } from 'utils/v2/data';

const initialState = {
  zoomMeetingTopics: [],
  skipFetch: true,
};

const actionTypes = {
  SET_ZOOM_MEETING_TOPICS: 'ZoomMeetingTopicsContext.setZoomMeetingTopics',
  GET_ZOOM_MEETING_TOPICS: 'ZoomMeetingTopicsContext.getZoomMeetingTopics',
};

const ZoomMeetingTopicsContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_ZOOM_MEETING_TOPICS:
      return { ...state, zoomMeetingTopics: payload };
    case actionTypes.GET_ZOOM_MEETING_TOPICS:
      return { ...state, skipFetch: false };
  }
};

export const ZoomMeetingTopicsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { zoomMeetingTopics, skipFetch } = state;

  const { currentContact } = useCurrentContactContext();
  const { data } = useContactZoomMeetingTopicsQuery({
    variables: { id: currentContact.id },
    skip: skipFetch,
  });

  const getZoomMeetingTopics = () => {
    dispatch({ type: actionTypes.GET_ZOOM_MEETING_TOPICS });
  };

  const getZoomTopicByValue = (value) => {
    if (!zoomMeetingTopics?.length) return null;

    findOptionByValue(value, zoomMeetingTopics);
  };

  useEffect(() => {
    if (data) {
      const {
        currentContact: { zoomMeetingTopics: contactTopics },
      } = data;

      dispatch({ type: actionTypes.SET_ZOOM_MEETING_TOPICS, payload: contactTopics });
    }
  }, [data]);

  return (
    <ZoomMeetingTopicsContext.Provider value={{ zoomMeetingTopics, getZoomMeetingTopics, getZoomTopicByValue }}>
      {children}
    </ZoomMeetingTopicsContext.Provider>
  );
};

ZoomMeetingTopicsContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useZoomMeetingTopicsContext = () => useContext(ZoomMeetingTopicsContext);
