import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Severity } from 'components/v2/shared/Toast/Toast';
import { toast } from 'react-hot-toast';

export const ToastContext = createContext(null);

export const ToastContextProvider = ({ children }) => {
  const [info, setInfo] = useState({
    severity: Severity.Info,
    status: null,
    message: null,
  });

  const setToastInfo = (val) => {
    setInfo(val);
  };

  useEffect(() => {
    if (info.status && info.message) {
      toast();
    }
  }, [info]);

  const value = useMemo(() => ({ toastInfo: info, setToastInfo }), [info]);

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
};
