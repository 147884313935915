import React, { memo, useState, useEffect } from 'react';
import Avatar from '../../shared/Avatar';

export default memo(({ contact, toggleContact, isAdded }) => {
  const onClick = (event) => {
    toggleContact(contact);
  };

  return (
    <React.Fragment>
      <div onClick={onClick} className='search-result d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <Avatar contact={contact} />
          <div className='contact-name'>{contact.name}</div>
        </div>

        {isAdded && (
          <div className='checkmark-circle'>
            <div className='checkmark'></div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
});
