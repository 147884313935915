import { createStore } from './createStore';

const initialState = {
  flash: null,
};

const actionTypes = {
  SET: 'flash.set',
  DISCARD: 'flash.discard',
};

const useFlashActions = () => {
  const dispatch = useFlashDispatch();

  const setFlash = (flash) => {
    let message;

    if (typeof flash === 'string') {
      message = flash;
    } else if (flash.response?.status === 404) {
      message = "The resource you've requested hasn't been found";
    } else if (flash.response?.status >= 500) {
      message = 'Whoops, is appears that something went wrong. Please try again in a few minutes';
    } else {
      message = 'Whoops, something unexpected happen. Please try to refresh the page';
    }

    dispatch({ type: actionTypes.SET, payload: message });
  };

  const discardFlash = (flash) => {
    dispatch({ type: actionTypes.DISCARD });
  };

  return { setFlash, discardFlash };
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET:
      return { flash: payload };
    case actionTypes.DISCARD:
      return { flash: null };
  }
};

const [FlashProvider, useFlashStore, useFlashDispatch] = createStore(reducer, initialState);

export { FlashProvider, useFlashStore, useFlashActions };
