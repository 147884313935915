import { css } from '@emotion/core';
import { colors } from 'styles';

export const base = css`
  font-size: 14px;
  color: ${colors.lightGrey};

  &:hover {
    color: ${colors.cyan};
    cursor: pointer;

    path {
      fill: ${colors.cyan};
    }
  }
`;
