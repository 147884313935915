import React, { useState } from 'react';
import AddCompany from './AddCompany';
import Companies from './Companies';
import ExistingCompany from './ExistingCompany';
import Spinner from 'components/v2/shared/Spinner';
import { withApollo } from 'utils/v2/apollo';
import { useCurrentUserCompanyListQuery } from 'data/v2/queries/CurrentUserCompanyListQuery';
import { ToastContextProvider } from '../../../../contexts/v2/ToastContext';

const COMPANIES_PER_PAGE = 10;

const Index = () => {
  const [activePage, setActivePage] = useState('list');
  const handlePageChange = (page) => {
    setActivePage(page);
  };

  const { data, fetchMore, loading, refetch } = useCurrentUserCompanyListQuery({
    variables: {
      first: COMPANIES_PER_PAGE,
      context: 'v2.companies.Index',
    },
  });

  if (loading) {
    return <Spinner variant='global' />;
  }

  const companies = data.currentUser.companies.edges.map((edge) => edge.node);

  return (
    <ToastContextProvider>
      <div className='tw-mx-auto lg:tw-container sm:tw-p-5'>
        <div className='tw-h-full tw-bg-white tw-text-black'>
          {activePage === 'list' && (
            <Companies
              onFetchMore={fetchMore}
              companies={companies}
              pageInfo={{ ...data.currentUser.companies.pageInfo, perPage: COMPANIES_PER_PAGE }}
              onClick={handlePageChange}
              refetch={refetch}
            />
          )}
          {activePage === 'existing' && <ExistingCompany onClick={handlePageChange} />}
          {activePage === 'add' && <AddCompany onClick={handlePageChange} refetch={refetch} />}
        </div>
      </div>
    </ToastContextProvider>
  );
};
export default withApollo(Index);
