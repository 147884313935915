/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { ButtonWithSuccess } from 'components/shared/ButtonWithSuccess';
import { Placeholder } from 'components/shared/Placeholder';
import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';
import { ReactComponent as ContactsIcon } from 'images/icons/no-contacts.svg';
import { ReactComponent as CalendarIcon } from 'images/icons/calendar.svg';
import Avatar from 'components/shared/Avatar';
import { useMeetingStatus } from 'hooks/useMeetingStatus';

import * as styles from './index.styles';

const ContactsTableDesktop = memo(({ contactTableAttrs }) => {
  const {
    contacts,
    contactMeetings,
    meetingStatus,
    setChairman,
    isCreator,
    chairmanId,
    meetingOrganizerId,
    onDelete,
    onResend,
    readOnly,
  } = contactTableAttrs;

  const { isDraft, isUpcoming, isScheduled, isPseudo } = useMeetingStatus(meetingStatus);

  const intl = useIntl();

  const getInvitationText = (contact, invitationStatus) => {
    const acceptedStatuses = ['yes', 'confirmed'];

    if (isCreator(contact)) {
      return intl.formatMessage({ id: 'ContactsTable.creator' });
    }

    if (!meetingStatus || isDraft) {
      return intl.formatMessage({ id: 'ContactsTable.invitePending' });
    }

    if (acceptedStatuses.includes(invitationStatus)) {
      return intl.formatMessage({ id: 'ContactsTable.accepted' });
    } else if (invitationStatus === 'no') {
      return intl.formatMessage({ id: 'ContactsTable.declined' });
    } else if (invitationStatus === 'waiting') {
      return intl.formatMessage({ id: 'ContactsTable.maybe' });
    } else {
      return intl.formatMessage({ id: 'ContactsTable.inviteSent' });
    }
  };

  const placeholderIcon = <ContactsIcon />;

  if (!contacts || !contacts.length) {
    return <Placeholder image={placeholderIcon} message={intl.formatMessage({ id: 'ContactsTable.noAttendees' })} />;
  }

  const handleChairman = (event) => {
    if (event.target.checked) {
      const contactMeetingId = Number(event.target.value);
      setChairman(contactMeetingId);
    } else {
      setChairman(null);
    }
  };

  const isContactEventOrganizer = (contact) => contact.id == meetingOrganizerId;

  const tableBody = contacts.map((contact, index) => {
    const handleDelete = onDelete.bind(this, contact);
    const handleResend = onResend && onResend.bind(this, contact);
    const invitation = contactMeetings.find((item) => item.contact.id === contact.id);
    const isChairman = chairmanId === invitation.id;
    const invitationText = getInvitationText(contact, invitation?.status);

    return (
      <tr css={styles.tableRow(!contact[index + 1])} key={contact.id}>
        <td css={styles.tableCell}>
          <div className='d-flex align-items-center'>
            <Avatar contact={contact} />
            <div className='text-truncate m-l-10'>{contact.name}</div>
            {isContactEventOrganizer(contact) && <CalendarIcon className='m-r-5 m-l-5 w-20px' />}
          </div>
        </td>

        {!isPseudo && (
          <td className='text-truncate' css={[styles.tableCell, styles.uppercase]}>
            <div className='d-flex align-items-center'>
              <span css={[styles.status, styles.statuses[invitation?.status || 'pending']]}>{invitationText}</span>

              {!isCreator(contact) && onResend && (isUpcoming || isScheduled) && (
                <ButtonWithSuccess
                  className={`m-l-30 btn font-weight-regular ${
                    invitation.id ? 'btn-light-orange' : 'btn-disabled'
                  } btn-no-transform btn-xsm`}
                  style={{ width: 108 }}
                  onClick={handleResend}
                >
                  <FormattedMessage id='ContactsTable.resend' />
                </ButtonWithSuccess>
              )}
            </div>
          </td>
        )}

        <td css={styles.tableCell}>
          <div className='d-flex align-items-center'>
            <div className='custom-control custom-switch shaparency-switch'>
              <input
                type='checkbox'
                id={`chairman-${contact.id}`}
                value={invitation.id}
                onChange={handleChairman}
                disabled={readOnly}
                checked={isChairman}
                className='custom-control-input'
              />

              <label className='custom-control-label' htmlFor={`chairman-${contact.id}`} />
            </div>

            {!readOnly && !isCreator(contact) && (
              <ButtonWithConfirmation
                confirmationText={intl.formatMessage({ id: 'ContactsTable.confirm' }, { name: contact.name })}
                onConfirm={handleDelete}
                button={<i css={styles.deleteIcon} className='m-l-20 far fa-fw fa-trash-alt' />}
              />
            )}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div css={styles.tableContainer}>
        <table css={styles.table}>
          <thead>
            <tr>
              <th css={styles.tableHeader(40)}>
                <FormattedMessage id='ContactsTable.attendees' />
              </th>
              {!isPseudo && (
                <th css={styles.tableHeader(40)}>
                  <FormattedMessage id='ContactsTable.status' />
                </th>
              )}
              <th css={styles.tableHeader(20)}>
                <FormattedMessage id='ContactsTable.chair' />
              </th>
            </tr>
          </thead>

          <tbody>{tableBody}</tbody>
        </table>
      </div>
    </>
  );
});

export { ContactsTableDesktop };
