import { css } from '@emotion/core';
import { colors } from 'styles';

export const image = css`
  height: 50px;
  width: auto;
`;

export const target = css`
  font-size: 12px;
`;

export const header = css`
  color: ${colors.black};
  letter-spacing: 0.14em;
  line-height: 1;
  text-transform: uppercase;
`;

export const blockquote = css`
  font-style: italic;
  color: ${colors.black};
  line-height: 16px;
  border-left: 1px solid ${colors.cyan};
  padding-left: 5px;
  margin: 10px 0;
`;
