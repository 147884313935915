/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { ReactComponent as ChevronLeft } from 'images/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'images/icons/chevron-right.svg';
import { ReactComponent as Plus } from 'images/icons/plus.svg';

import { BG_COLORS, SIZES } from './constants';

import * as styles from './CircleIcon.styles';

const iconMap = {
  chevronLeft: <ChevronLeft />,
  chevronRight: <ChevronRight />,
  plus: <Plus />,
};

const CircleIcon = ({ icon, size = 'regular', bgColor = 'grey', onClick, isDisabled = false, className }) => (
  <div
    onClick={onClick}
    css={[styles.circle.base, styles.circle[size], styles.circle[bgColor], isDisabled && styles.circle.disabled]}
    className={className}
  >
    {iconMap[icon]}
  </div>
);

CircleIcon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
  isDisabled: PropTypes.bool,
  bgColor: PropTypes.oneOf(BG_COLORS),
  size: PropTypes.oneOf(SIZES),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default CircleIcon;
