/** @jsx jsx */
import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertPopup } from 'components/shared/AlertPopup';

const MeetingAlert = memo(({ show, onClose, onConfirm }) => {
  const intl = useIntl();

  return (
    <AlertPopup
      show={show}
      headerText={intl.formatMessage({ id: 'MeetingAlert.confirmation' })}
      popupType='info'
      onClose={onClose}
    >
      <div className='text-black fs-14 m-b-20'>
        <FormattedMessage id='MeetingAlert.text' />
      </div>

      <button className='btn btn-info' onClick={onConfirm}>
        <FormattedMessage id='MeetingAlert.confirm' />
      </button>
      <button className='btn btn-info-inverted m-l-10' onClick={onClose}>
        <FormattedMessage id='MeetingAlert.cancel' />
      </button>
    </AlertPopup>
  );
});

export { MeetingAlert };
