/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { Row, Col } from 'react-bootstrap';
import { ContactPicker } from 'components/shared/ContactPicker';
import { ReviewList } from './../ReviewList';

import { useReviewable } from './../hooks/useReviewable';

import * as styles from './ReviewStep.styles';

const ReviewStep = memo(({}) => {
  const { minutes, reviews, reviewers, isReviewerSelected, toggleReviewer, removeReviewer, handleResendReviewRequest } =
    useReviewable();

  return (
    <div css={styles.padded}>
      <div className='font-weight-bold fs-16 text-black' css={styles.hideTable}>
        <FormattedMessage id='ReviewStep.review' />
      </div>

      <Row className='m-t-20'>
        <Col md={6} css={styles.sendReviewBlock}>
          <div css={[styles.wrapper, styles.sendReviewWrapper]}>
            <div className='font-weight-bold fs-16 text-black'>
              <FormattedMessage id='ReviewStep.sendForReview' />
            </div>

            <div className='fs-14 font-weight-regular c-mid m-t-20'>
              <FormattedMessage id='ReviewStep.addContacts' />
            </div>

            <div className='m-t-40'>
              <ContactPicker
                values={reviewers}
                excludeId={minutes.contact_id}
                isSelected={isReviewerSelected}
                onToggleOption={toggleReviewer}
              />
            </div>
          </div>
        </Col>

        <Col md={6}>
          <div css={styles.wrapper}>
            <div className='font-weight-bold fs-16 text-black'>
              <FormattedMessage id='ReviewStep.listOfReviewers' />
            </div>

            <ReviewList reviews={reviews} onResend={handleResendReviewRequest} onDelete={removeReviewer} />
          </div>
        </Col>
      </Row>
    </div>
  );
});

export { ReviewStep };
