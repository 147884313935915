/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import Button, { Variants } from 'components/v2/shared/Button';
import Text from 'components/v2/shared/Text';

import OverviewMeetingBreakdown from './../OverviewMeetingBreakdown';
import OverviewMeetingHeader from './../OverviewMeetingHeader';

import * as styles from './OverviewStep.styles';

const OverviewStep = () => {
  return (
    <div css={styles.container}>
      <OverviewMeetingHeader />

      <Text isBold text="Great, now you've scheduled your meeting and we've sent out invites to your team." />
      <Text text='You can always edit a meeting agenda and invites later on by simply coming back.' />

      <Button variant={Variants.Aqua} className='mt-4'>
        Download Agenda
      </Button>

      <OverviewMeetingBreakdown />

      <Button className='mb-2' isFullWidth>
        Next
      </Button>

      <Button variant={Variants.Teal} isFullWidth>
        Save draft
      </Button>
    </div>
  );
};

OverviewStep.propTypes = {};

export default OverviewStep;
