import { gql } from '@apollo/client';
import { CONTACT_FRAGMENT } from './ContactFragment';

const NOTICE_SCHEDULE_FRAGMENT = gql`
  fragment NoticeScheduleFields on NoticeSchedule {
    id
    config
    message
    scheduledAt

    contact {
      ...ContactFields
    }
  }
  ${CONTACT_FRAGMENT}
`;

export { NOTICE_SCHEDULE_FRAGMENT };
