import { gql, useMutation } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';

const DELETE_DIRECTORY = gql`
  mutation deleteDirectory($input: DeleteDirectoryInput!) {
    deleteDirectory(input: $input) {
      directory {
        ...DirectoryFields

        parent {
          id
          empty
          hasChildren
        }
      }

      errors {
        attribute
        message
      }
    }
  }
  ${DIRECTORY_FRAGMENT}
`;

const useDeleteDirectoryMutation = (options) => useMutation(DELETE_DIRECTORY, options);

export { useDeleteDirectoryMutation };
