import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toggle from './Toggle';
import Input from './Input';
import CompanyList from './CompanyList';
import VisibilityWrapper from 'components/v2/shared/VisibilityWrapper';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useClickOutside } from 'hooks/useClickOutside';
import { useDebounce } from 'use-debounce';
import { getObjectDataByPath } from 'utils/v2/data';
import { useCurrentUserCompaniesQuery } from 'data/v2/queries/CurrentUserCompaniesQuery';

const COMPANIES_PER_PAGE = 10;

const CompanyPicker = ({
  currentCompany = {},
  isViewingCompanies = false,
  toggleIsViewingCompanies = () => {},
  setIsViewingCompanies = () => {},
}) => {
  const { isLargeDesktop } = useWindowDimensions();
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside();

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const {
    data,
    loading: isLoading,
    networkStatus,
    fetchMore,
  } = useCurrentUserCompaniesQuery({
    skip: !isViewingCompanies,
    variables: {
      name: debouncedSearchTerm,
      first: COMPANIES_PER_PAGE,
      context: 'Navbar',
    },
  });

  const companiesData = getObjectDataByPath(data, 'currentUser.companies', { pageInfo: {}, edges: [] });
  const subcommittees = getObjectDataByPath(data, 'currentUser.subcommittees');
  const companies = companiesData.edges.map((edge) => edge.node);

  const fetchMoreCompanies = () => {
    if (!companiesData.pageInfo.hasNextPage) return;
    if (networkStatus === NetworkStatus.fetchMore) return;

    fetchMore({
      variables: {
        after: companiesData.pageInfo.endCursor,
      },
    });
  };

  useEffect(() => {
    if (isLargeDesktop && !isComponentVisible) {
      setIsViewingCompanies(false);
    }
  }, [isComponentVisible]);

  useEffect(() => {
    setSearchTerm('');

    if (isViewingCompanies) {
      setIsComponentVisible(true);
    }
  }, [isViewingCompanies]);

  const handleInputChange = (e) => setSearchTerm(e.target.value);

  return (
    <div
      ref={ref}
      className={clsx(
        'tw-z-aboveBackdrop tw-flex tw-flex-row tw-items-center',
        'tw-h-full xl:tw-w-64 xl:tw-flex-col xl:tw-items-stretch'
      )}
    >
      <Toggle
        companyName={currentCompany.name || 'Shaparency'}
        isActive={isViewingCompanies}
        onClick={toggleIsViewingCompanies}
      />

      <VisibilityWrapper
        isVisible={isViewingCompanies}
        visibleClass='tw-flex'
        className={clsx(
          'tw-z-max tw-flex-col tw-bg-white',
          'tw-fixed tw-inset-0 tw-top-[var(--nav-height)]',
          'xl:tw-w-64 xl:tw-grow'
        )}
      >
        <Input value={searchTerm} onChange={handleInputChange} />

        <CompanyList
          companies={companies}
          subcommittees={subcommittees}
          isLoading={isLoading}
          currentCompany={currentCompany}
          onFetchMore={fetchMoreCompanies}
        />
      </VisibilityWrapper>
    </div>
  );
};

CompanyPicker.propTypes = {
  currentCompany: PropTypes.object,
  isViewingCompanies: PropTypes.bool,
  toggleIsViewingCompanies: PropTypes.func,
  setIsViewingCompanies: PropTypes.func,
};

export default CompanyPicker;
