import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';

export const breadcrumb = {
  base: css`
    ${fonts.regular}
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    color: ${colors.mediumGrey};
    max-width: 100%;
    white-space: nowrap;

    .breadcrumb__name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 16px;
      max-width: 150px;

      ${media.tablet} {
        max-width: 100px;
        max-width: 100%;
      }
    }

    .breadcrumb__arrow {
      margin: 0 8px;
      flex-shrink: 0;

      path {
        stroke: ${colors.black};
      }
    }

    &:last-child {
      margin: 0;

      .breadcrumb__arrow {
        display: none;
      }
    }
  `,

  active: css`
    ${fonts.regular}
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    color: ${colors.black};
    cursor: pointer;

    .breadcrumb__arrow {
      margin: 0 8px;
    }

    &:last-child {
      ${fonts.semiBold}
      cursor: default;
      margin: 0;
      color: ${colors.black};

      .breadcrumb__arrow {
        display: none;
      }
    }
  `,
};
