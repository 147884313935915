import { gql } from '@apollo/client';
import { MEETING_FRAGMENT } from './MeetingFragment';
import { CONTACT_FRAGMENT } from './ContactFragment';

const COMPANY_FRAGMENT = gql`
  fragment CompanyFields on Company {
    id

    meetings {
      ...MeetingFields
    }

    contacts {
      ...ContactFields
    }
  }
  ${MEETING_FRAGMENT}
  ${CONTACT_FRAGMENT}
`;

export { COMPANY_FRAGMENT };
