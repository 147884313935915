import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const link = css`
  display: inline-block;
  line-height: 14px;
  color: ${colors.purple};
  position: relative;
  border-radius: 2px;
  padding: 2px 4px;
  box-shadow: rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px;
`;

export const favicon = css`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 3px;
`;

export const title = css`
  ${fonts.regular}
  line-height: 1;
  background: ${colors.white};
  font-size: 12px;
  margin-left: 15px;
`;
