import { gql } from '@apollo/client';

const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFields on Notification {
    id
    message
    createdAt
    createdAgo
    read
  }
`;

export { NOTIFICATION_FRAGMENT };
