import { useState, useEffect } from 'react';

const useSteps = (stepMap, initialStep = 1, enableAll = false) => {
  const stepKeys = Object.keys(stepMap).map(Number);
  const totalSteps = stepKeys.length;

  const [steps, setSteps] = useState({
    current: initialStep,
    disabled: enableAll ? [] : stepKeys.slice(initialStep),
    completed: [],
  });

  const jumpStep = (step) => {
    setSteps((steps) => ({
      current: step,
      completed: stepKeys.slice(0, step - 1),
      disabled: enableAll ? [] : stepKeys.slice(step + 1),
    }));
  };

  const changeStep = (stepKey) => {
    setSteps((steps) => ({ ...steps, current: stepKey }));
  };

  const advanceStep = () => {
    if (steps.current + 1 > totalSteps) return;

    jumpStep(steps.current + 1);
  };

  return {
    steps,
    changeStep,
    advanceStep,
    jumpStep,
  };
};

export { useSteps };
