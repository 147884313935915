/** @jsx jsx */
import React, { memo, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { useDirectoriesBrowser } from './../hooks/useDirectoriesBrowser';
import { useDirectoriesBrowserSearch } from './../hooks/useDirectoriesBrowserSearch';
import { useModal } from './../hooks/useModal';
import { useHistoryDrawer } from './../hooks/useHistoryDrawer';
import { useFileViewer } from './../hooks/useFileViewer';
import { useFlashActions, useFlashStore } from 'store/flashStoreContext';
import { AlertPopup } from 'components/shared/AlertPopup';
import FileViewer from 'components/v2/shared/FileViewer';
import { ShareModal } from './../ShareModal';
import { MoveModal } from './../MoveModal';
import { DownloadModal } from './../DownloadModal';
import { Search } from './../Search';
import { Breadcrumbs } from './../Breadcrumbs';
import { HistoryDrawer } from './../HistoryDrawer';
import { Spinner } from 'components/shared/Spinner';
import { Sidebar } from './../Sidebar';
import { ContentArea } from './../ContentArea';
import { UploadWindow } from 'components/shared/UploadWindow/UploadWindow';

import * as styles from './Container.styles';

const Container = memo(({ directoryId, documentId }) => {
  const [previewDocumentId, setPreviewDocumentId] = useState(documentId);

  const intl = useIntl();

  const { flash } = useFlashStore();
  const { setFlash, discardFlash } = useFlashActions();

  const previewDocument = () => {
    if (!previewDocumentId) return;

    const document = currentDirectory.documents.find((item) => item.id === previewDocumentId);

    if (document) {
      openFileViewer(document);
    } else {
      setFlash(intl.formatMessage({ id: 'Container.noPermission' }));
    }

    setPreviewDocumentId(null);
  };

  const { fileViewerContext, openFileViewer, closeFileViewer } = useFileViewer();

  const { drawerContext, openHistoryDrawer, closeHistoryDrawer, resetDrawerContext } = useHistoryDrawer();

  const { modalContext: moveModalContext, openModal: openMoveModal, closeModal: closeMoveModal } = useModal();

  const {
    modalContext: downloadModalContext,
    openModal: openDownloadModal,
    closeModal: closeDownloadModal,
  } = useModal();

  const { modalContext: shareModalContext, openModal: openShareModal, closeModal: closeShareModal } = useModal();

  const { isSearching, searchTerm, debouncedSearchTerm, handleTermChange, startSearching, stopSearching } =
    useDirectoriesBrowserSearch();

  const { isLoading, sidebarItemId, currentDirectory, rootDirectories, browseSidebarItem, browseDirectory } =
    useDirectoriesBrowser({ onBrowse: stopSearching, currentDirectoryId: directoryId });

  useEffect(() => browseDirectory(directoryId), []);
  useEffect(() => setCurrentDirectoryURL(), [currentDirectory]);

  const setCurrentDirectoryURL = () => {
    if (!currentDirectory) return;

    const companyId = getCompanySlug();
    const path = `/${companyId}/documents/${currentDirectory.slug}`;

    window.history.replaceState({}, null, path);

    previewDocument();
  };

  if (!rootDirectories || !currentDirectory) {
    return <Spinner show />;
  }

  return (
    <div css={styles.container}>
      <Sidebar items={rootDirectories} selectedItemId={sidebarItemId} onClick={browseSidebarItem} />

      <div css={styles.content}>
        <div css={styles.wrapper}>
          <div css={styles.title}>
            <Breadcrumbs isLoading={isLoading} items={currentDirectory.breadcrumbs} onClick={browseDirectory} />
          </div>

          <Search
            value={searchTerm}
            isFocused={isSearching}
            onChange={handleTermChange}
            onFocus={startSearching}
            onBlur={stopSearching}
          />
        </div>

        <ContentArea
          isSearching={isSearching}
          isLoading={isLoading}
          searchTerm={debouncedSearchTerm}
          currentDirectory={currentDirectory}
          onDirectoryDownload={openDownloadModal}
          onMoveClick={openMoveModal}
          onDocumentClick={openFileViewer}
          onShareClick={openShareModal}
          onDirectoryClick={browseDirectory}
          onViewDocumentHistoryClick={openHistoryDrawer}
        />
      </div>

      <HistoryDrawer
        show={drawerContext.show}
        file={drawerContext.file}
        onClose={closeHistoryDrawer}
        onCloseAnimationEnd={resetDrawerContext}
      />

      <DownloadModal
        show={downloadModalContext.show}
        resource={downloadModalContext.resource}
        onClose={closeDownloadModal}
      />

      <MoveModal show={moveModalContext.show} resource={moveModalContext.resource} onClose={closeMoveModal} />

      <FileViewer
        fileName={fileViewerContext.file?.name}
        fileUrl={fileViewerContext.file?.url}
        isOpen={fileViewerContext.show}
        onClose={closeFileViewer}
      />

      <ShareModal show={shareModalContext.show} resource={shareModalContext.resource} onClose={closeShareModal} />

      <UploadWindow />

      <AlertPopup
        show={!!flash}
        popupType='failure'
        headerText={intl.formatMessage({ id: 'Container.failure' })}
        onClose={discardFlash}
      >
        <div className='text-black fs-14'>{flash}</div>
      </AlertPopup>
    </div>
  );
});

export { Container };
