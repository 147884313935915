import { getAttributes } from 'helpers/getAttributes';
import { useReviewsStore, useReviewsActions } from 'store/reviewsStoreContext';
import { useFlashActions } from 'store/flashStoreContext';
import { useMinutesStore } from 'store/minutesStoreContext';

const useReviewable = () => {
  const { minutes } = useMinutesStore();
  const { reviews } = useReviewsStore();

  const { setFlash } = useFlashActions();
  const { createReview, deleteReview, resendReviewRequest } = useReviewsActions();

  const reviewers = reviews.map((review) => getAttributes(review.reviewer));

  const isReviewerSelected = (reviewer) => {
    return reviewers.find((item) => item.id === reviewer.id);
  };

  const getReview = (reviewer) => {
    return reviews.find((item) => item.contact_id == reviewer.id);
  };

  const toggleReviewer = (reviewer) => {
    const isSelected = isReviewerSelected(reviewer);

    if (isSelected) {
      removeReviewer(reviewer);
    } else {
      addReviewer(reviewer);
    }
  };

  const addReviewer = (reviewer) => {
    createReview(minutes.id, { contact_id: reviewer.id }).catch(setFlash);
  };

  const removeReviewer = (reviewerToDelete) => {
    const review = getReview(reviewerToDelete);
    const reviewerAttributes = reviewers.find((reviewer) => reviewer.id == reviewerToDelete.id);

    if (!reviewerAttributes.deletable) return;

    deleteReview(minutes.id, review.id).catch(setFlash);
  };

  const handleResendReviewRequest = (reviewer) => {
    const review = getReview(reviewer);

    return resendReviewRequest(minutes.id, review.id);
  };

  return {
    minutes,
    reviews,
    reviewers,
    isReviewerSelected,
    toggleReviewer,
    removeReviewer,
    handleResendReviewRequest,
  };
};

export { useReviewable };
