import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const Sizes = {
  Large: 'large',
  Small: 'small',
};

export const Fonts = {
  Medium: 'medium',
  Semibold: 'semibold',
};

export const Variants = {
  Aqua: 'aqua',
  Lavander: 'lavander',
  Underline: 'underline',
  Purple: 'purple',
  Teal: 'teal',
  Orange: 'orange',
  Red: 'red',
  Blue: 'blue',
  Green: 'green',
  Gray: 'gray',
  Gainsboro: 'gainsboro',
  Transparent: 'transparent',
  TransparentTeal: 'transparent-teal',
  TransparentRed: 'transparent-red',
};

const Button = ({
  variant = Variants.Purple,
  size = Sizes.Large,
  font = Fonts.Semibold,
  children,
  onClick,
  isDisabled = false,
  isFullWidth = false,
  className,
  ...props
}) => (
  <button
    className={clsx(
      'tw-flex tw-items-center tw-justify-center',
      'tw-cursor-pointer tw-rounded-sm tw-border-none tw-font-sans tw-leading-none',
      'focus:tw-outline-none',
      'disabled:tw-cursor-not-allowed disabled:tw-opacity-30',
      'tw-whitespace-nowrap',
      {
        'tw-font-semibold': font === Fonts.Semibold,
        'tw-font-medium': font === Fonts.Medium,
        'tw-py-3.5 tw-px-4 tw-text-base': size === Sizes.Large,
        'tw-py-1.5 tw-px-2 tw-text-sm': size === Sizes.Small,
        'tw-bg-purple-500 tw-text-white hover:tw-bg-purple-600 disabled:tw-bg-purple-500': variant === Variants.Purple,
        'tw-bg-teal-500 tw-text-white hover:tw-bg-teal-600 disabled:tw-bg-teal-500': variant === Variants.Teal,
        'tw-bg-orange-500 tw-text-black hover:tw-bg-orange-200 disabled:tw-bg-orange-500': variant === Variants.Orange,
        'tw-bg-red-500 tw-text-white hover:tw-bg-red-600 disabled:tw-bg-red-400': variant === Variants.Red,
        'tw-bg-blue-500 tw-text-white hover:tw-bg-blue-600 disabled:tw-bg-blue-500': variant === Variants.Blue,
        'tw-bg-teal-700 tw-text-white hover:tw-bg-teal-600 disabled:tw-bg-teal-700': variant === Variants.Green,
        'tw-bg-gray-800 tw-text-white hover:tw-bg-black disabled:tw-bg-gray-800': variant === Variants.Gray,
        'tw-bg-transparent tw-text-purple-500 tw-underline tw-underline-offset-2 hover:tw-opacity-80':
          variant === Variants.Underline,
        'tw-bg-teal-200 tw-text-teal-500 hover:tw-bg-teal-500 hover:tw-text-white disabled:tw-bg-teal-200 disabled:tw-text-teal-500':
          variant === Variants.Aqua,
        'tw-bg-purple-100 tw-text-purple-500 hover:tw-bg-purple-200 disabled:tw-bg-purple-100':
          variant === Variants.Lavander,
        'tw-bg-transparent tw-text-purple-500 hover:tw-opacity-80': variant === Variants.Transparent,
        'tw-bg-transparent tw-text-teal-500 hover:tw-opacity-80': variant === Variants.TransparentTeal,
        'tw-border-red-500 tw-bg-transparent tw-text-red-500 hover:tw-opacity-80': variant === Variants.TransparentRed,
        'tw-bg-transparent tw-text-purple-500 hover:tw-bg-gray-100 disabled:tw-bg-transparent':
          variant === Variants.Gainsboro,
        'tw-w-full': isFullWidth,
      },
      className
    )}
    onClick={onClick}
    disabled={isDisabled}
    {...props}
  >
    {children}
  </button>
);

Button.propTypes = {
  variant: PropTypes.oneOf(Object.values(Variants)),
  size: PropTypes.oneOf(Object.values(Sizes)),
  font: PropTypes.oneOf(Object.values(Fonts)),
  children: PropTypes.node,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  className: PropTypes.string,
};

export default Button;
