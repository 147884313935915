import React, { useEffect, createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { useGQLDataContext } from './GQLDataContext';
import { useMeetingNoticeScheduleQuery } from 'data/v2/queries/MeetingNoticeScheduleQuery';
import { useUpsertNoticeScheduleMutation } from 'data/v2/mutations/UpsertNoticeScheduleMutation';
import { useMeetingContext } from './MeetingContext';
import { useCurrentContactContext } from './CurrentContactContext';
import { findOptionByValue } from '../../utils/v2/data';
import { wrapMutation } from 'utils/v2/gql';
import { serializeNoticeSchedule } from 'utils/v2/serializers';
import { humanize } from 'utils/v2/strings';

const defaultNoticeScheduleOptions = [
  { label: 'One week prior', value: 'one_week_prior', momentValue: 1, momentPeriod: 'weeks' },
  { label: 'Two weeks prior', value: 'two_weeks_prior', momentValue: 2, momentPeriod: 'weeks' },
  { label: 'Four weeks prior', value: 'four_weeks_prior', momentValue: 4, momentPeriod: 'weeks' },
  { label: 'One month prior', value: 'one_month_prior', momentValue: 1, momentPeriod: 'months' },
  { label: 'Send once scheduled', value: 'send_once_scheduled', momentValue: 0, momentPeriod: 'days' },
];

const initialState = {
  edits: {},
  noticeSchedule: {},
  noticeScheduleOptions: [],
  skipFetch: true,
};

const actionTypes = {
  SET_NOTICE_SCHEDULE_OPTIONS: 'NoticeScheduleContext.setNoticeScheduleOptions',
  UPDATE_NOTICE_SCHEDULE: 'NoticeScheduleContext.updateNoticeSchedule',
  FETCH_NOTICE_SCHEDULE: 'NoticeScheduleContext.fetchNoticeSchedule',
  UPDATE_EDITS: 'NoticeScheduleContext.updateEdits',
  DISCARD_EDITS: 'NoticeScheduleContext.discardEdits',
};

const NoticeScheduleContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_NOTICE_SCHEDULE_OPTIONS:
      return { ...state, noticeScheduleOptions: payload.noticeScheduleOptions };
    case actionTypes.UPDATE_NOTICE_SCHEDULE:
      const serializedData = serializeNoticeSchedule(payload.noticeSchedule);
      return { ...state, edits: serializedData, noticeSchedule: serializedData };
    case actionTypes.FETCH_NOTICE_SCHEDULE:
      return { ...state, skipFetch: false };
    case actionTypes.UPDATE_EDITS:
      return { ...state, edits: { ...state.edits, ...payload.updates } };
    case actionTypes.DISCARD_EDITS:
      return { ...state, edits: { ...state.noticeSchedule } };
  }
};

export const NoticeScheduleContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    meeting: { meetingId, meetingStartTime },
  } = useMeetingContext();

  const { noticeSchedule, edits, noticeScheduleOptions, skipFetch } = state;
  const { currentContact } = useCurrentContactContext();

  const { loading, data } = useMeetingNoticeScheduleQuery({
    variables: { id: meetingId },
    skip: skipFetch,
  });

  const updateEdits = (fieldName, value) => {
    dispatch({ type: actionTypes.UPDATE_EDITS, payload: { updates: { [fieldName]: value } } });
  };

  const discardEdits = () => {
    dispatch({ type: actionTypes.DISCARD_EDITS });
  };

  const fetchNoticeSchedule = () => {
    dispatch({ type: actionTypes.FETCH_NOTICE_SCHEDULE });
  };

  const [upsertNoticeSchedule] = useUpsertNoticeScheduleMutation();

  const saveNoticeSchedule = async () => {
    const { id, ...input } = edits;

    return wrapMutation(
      upsertNoticeSchedule,
      { variables: { input: { meetingId, attributes: { ...input } } } },
      'upsertNoticeSchedule'
    ).then((data) => {
      dispatch({ type: actionTypes.UPDATE_NOTICE_SCHEDULE, payload: { noticeSchedule: data.noticeSchedule } });
    });
  };

  const optionScheduledAt = ({ momentValue, momentPeriod }) =>
    meetingStartTime.clone().subtract(momentValue, momentPeriod);

  const isOptionDisabled = (option) => optionScheduledAt(option).isBefore();

  const formatOption = (option) => ({
    ...option,
    scheduledAt: optionScheduledAt(option),
    isDisabled: isOptionDisabled(option),
  });

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_NOTICE_SCHEDULE_OPTIONS,
      payload: { noticeScheduleOptions: defaultNoticeScheduleOptions.map(formatOption) },
    });
  }, [meetingStartTime]);

  useEffect(() => {
    if (data) {
      const {
        currentCompany: {
          meeting: { noticeSchedule },
        },
      } = data;

      dispatch({ type: actionTypes.UPDATE_NOTICE_SCHEDULE, payload: { noticeSchedule } });
    }
  }, [data]);

  const getNoticeScheduleOptionByValue = (value) => findOptionByValue(value, noticeScheduleOptions);

  return (
    <NoticeScheduleContext.Provider
      value={{
        loading,
        noticeSchedule,
        edits,
        updateEdits,
        discardEdits,
        saveNoticeSchedule,
        fetchNoticeSchedule,
        noticeScheduleOptions,
        getNoticeScheduleOptionByValue,
      }}
    >
      {children}
    </NoticeScheduleContext.Provider>
  );
};

NoticeScheduleContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useNoticeScheduleContext = () => useContext(NoticeScheduleContext);
