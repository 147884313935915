import { css } from '@emotion/core';
import { colors } from 'styles';

export const container = {
  base: css`
    padding: 6px;
    box-shadow: 0px 1.2px 3.6px rgba(0, 20, 90, 0.1), 0px 6.4px 14.4px rgba(0, 50, 97, 0.13);
    border-radius: 2px;
    position: relative;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.1s ease-in;
    pointer-events: none;
    background: ${colors.white};
  `,

  open: css`
    opacity: 1;
    pointer-events: all;
  `,
};
