import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const container = {
  base: css`
    --hover-opacity: 0.7;

    display: flex;
    align-items: flex-end;
    cursor: pointer;

    .areaButton__icon {
      margin-right: 5px;
      transition: all 0.2s ease-in;
      line-height: 1;
    }

    .areaButton__text {
      line-height: 1;
      transition: all 0.2s ease-in;
      color: ${colors.black};
      font-size: 14px;
    }

    &:hover {
      .areaButton__icon {
        svg path {
          opacity: var(--hover-opacity);
        }
      }

      .areaButton__text {
        opacity: var(--hover-opacity);
      }
    }
  `,

  disabled: css`
    cursor: default;

    .areaButton__icon {
      svg path {
        opacity: var(--hover-opacity);
      }
    }

    .areaButton__text {
      opacity: var(--hover-opacity);
      color: ${colors.mediumGrey};
    }
  `,

  marginRightAuto: css`
    margin-right: auto;
  `,

  marginLeftAuto: css`
    margin-left: auto;
  `,
};
