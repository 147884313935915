/** @jsx jsx */

import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import RSelect, { components as Components } from 'react-select';
import { withIntl } from '../../../utils/v2/intl';
import * as selectStyles from './Select.styles';

const selectorStyles = css`
  width: 100%;

  #react-select-2-input {
    position: absolute;
    left: 0;
  }
`;

const Multiselect = memo(
  ({
    styles,
    isClearable,
    isSearchable,
    hideSelectedOptions,
    backspaceRemovesValue,
    closeMenuOnSelect,
    onChange,
    options,
    withAllOption,
    placeholder,
    components,
    value,
    ...props
  }) => {
    const intl = useIntl();

    const MultiValueContainer = ({ selectProps: { value }, data: { label } }) => {
      const index = value.findIndex((selected) => selected.label === label);
      const isLast = index === value.length - 1;
      const suffix = isLast ? '' : ', ';

      if (value.length === selectOptions.length) {
        if (label === selectOptions[selectOptions.length - 1].label) {
          if (placeholder) {
            return intl.formatMessage({ id: 'Multiselect.placeholderAll' }, { placeholder });
          } else {
            return intl.formatMessage({ id: 'Multiselect.all' });
          }
        } else {
          return '';
        }
      } else {
        return `${label}${suffix}`;
      }
    };

    const noop = () => {};

    const DropdownIndicator = (props) => (
      <Components.DropdownIndicator {...props}>
        <i className='fa fa-chevron-down' />
      </Components.DropdownIndicator>
    );

    const Option = (props) => (
      <Components.Option {...props}>
        <div className='checkbox checkbox-success'>
          <input type='checkbox' checked={props.isSelected} onChange={noop} />

          <label>{props.label}</label>
        </div>
      </Components.Option>
    );

    const allOption = { value: null, label: intl.formatMessage({ id: 'Multiselect.all' }) };

    const onChangeHandler = (selected, event) => {
      if (withAllOption) {
        if (selected !== null && selected.length > 0) {
          if (selected[selected.length - 1].value === allOption.value) {
            return onChange([allOption, ...options]);
          }

          let result = [];

          if (selected.length === options.length) {
            let includesAllOption = selected.find((option) => option.value === allOption.value);

            if (includesAllOption) {
              result = selected.filter((option) => option.value !== allOption.value);
            } else if (event.action === 'select-option') {
              result = [allOption, ...options];
            }

            return onChange(result);
          }
        }
      }

      return onChange(selected);
    };

    const selectValue = withAllOption && value.length === options.length ? [allOption, ...value] : value;

    const selectOptions = withAllOption ? [allOption, ...options] : options;

    const applicableStyles = styles ? styles(selectStyles.component) : selectStyles.component;

    return (
      <div css={selectorStyles}>
        <RSelect
          isMulti
          styles={applicableStyles}
          options={selectOptions}
          isClearable={false}
          onChange={onChangeHandler}
          isSearchable={false}
          value={selectValue}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          placeholder={placeholder}
          closeMenuOnSelect={false}
          components={{ DropdownIndicator, Option, MultiValueContainer }}
          {...props}
        />
      </div>
    );
  }
);

export default withIntl(Multiselect);
