import { gql, useMutation } from '@apollo/client';

const ARCHIVE_CONTACT_MUTATION = gql`
  mutation ArchiveContact($input: ArchiveContactInput!) {
    archiveContact(input: $input) {
      contact {
        name
        status
      }
    }
  }
`;

const useArchiveContactMutation = (options) => useMutation(ARCHIVE_CONTACT_MUTATION, options);

export { ARCHIVE_CONTACT_MUTATION, useArchiveContactMutation };
