import React, { useState, useEffect } from 'react';
import { notify } from 'components/v2/notification';
import Heading from 'components/v2/shared/Heading/';
import PasswordInput from 'components/v2/shared/PasswordInput';
import Button from 'components/v2/shared/Button';
import { wrapMutation } from 'utils/v2/gql';
import { useUpdateProfilePasswordMutation } from 'data/v2/mutations/UpdateProfilePasswordMutation';

const Password = () => {
  const [isValidationPassword, setIsValidationPassword] = useState(false);
  const [updateProfilePassword] = useUpdateProfilePasswordMutation();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
  });
  const errorCommanMessage = `This field can not be left blank`;
  const errorRequirementMessage = `Password requirements are not met.`;
  useEffect(() => {
    if (isValidationPassword) {
      matchPassword();
    }
  }, [formData]);

  const matchPassword = () => {
    formData.passwordConfirmation !== '' && formData.password !== formData.passwordConfirmation
      ? setErrors({ passwordConfirmation: 'Passwords do not match' })
      : isValidationPassword
      ? ''
      : setErrors({ password: errorRequirementMessage });
  };

  const handlePassword = (e, validationPropsValue) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
    validationPropsValue !== undefined && setIsValidationPassword(validationPropsValue);
    if (!validationPropsValue && validationPropsValue != undefined) {
      setErrors({ password: errorRequirementMessage });
    }
    if (isValidationPassword) {
      setErrors({ password: '' });
    }
  };
  const handleSubmit = () => {
    if (formData.password && formData.passwordConfirmation && formData.currentPassword && isValidationPassword) {
      wrapMutation(
        updateProfilePassword,
        {
          variables: {
            input: {
              password: formData.password,
              passwordConfirmation: formData.passwordConfirmation,
              currentPassword: formData.currentPassword,
            },
          },
        },
        'updateProfilePassword'
      )
        .then(() => {
          notify('success', 'Password updated');
        })
        .catch((error) => {
          setErrors({
            password: error.password,
            passwordConfirmation: error.passwordConfirmation,
            currentPassword: error.currentPassword,
          });
          notify('error', 'Review errors before proceeding');
        });
    } else {
      if (!isValidationPassword) {
        setErrors({ password: errorRequirementMessage });
      }
      if (!formData.password && !formData.passwordConfirmation && !formData.currentPassword) {
        setErrors({
          password: errorCommanMessage,
          passwordConfirmation: errorCommanMessage,
          currentPassword: errorCommanMessage,
        });
      } else if (!formData.passwordConfirmation && !formData.currentPassword) {
        setErrors({ passwordConfirmation: errorCommanMessage, currentPassword: errorCommanMessage });
      } else if (!formData.password && !formData.currentPassword) {
        setErrors({ password: errorCommanMessage, currentPassword: errorCommanMessage });
      } else if (!formData.password && !formData.passwordConfirmation) {
        setErrors({ password: errorCommanMessage, passwordConfirmation: errorCommanMessage });
      } else if (!formData.password) {
        setErrors({ password: errorCommanMessage });
      } else if (!formData.passwordConfirmation) {
        setErrors({ passwordConfirmation: errorCommanMessage });
      } else if (!formData.currentPassword) {
        setErrors({ currentPassword: errorCommanMessage });
      }
    }
  };

  return (
    <div>
      <div className='tw-hidden sm:tw-block'>
        <Heading text='Password' className='tw-mb-3' />
      </div>
      <div className='tw-py-5'>
        <PasswordInput
          onChange={handlePassword}
          name='currentPassword'
          value={formData.currentPassword}
          isInvalid={!!errors.currentPassword}
          errorMessage={errors.currentPassword}
          label='Current password'
          placeholder='Current password'
          size='large'
        />
      </div>
      <div className='tw-flex tw-flex-col'>
        <div className='tw-py-5'>
          <PasswordInput
            name='password'
            label='New password'
            placeholder='New password'
            size='large'
            value={formData.password}
            isValidation
            textIconClass='tw-mb-0.5 tw-mr-1'
            isInvalid={!!errors.password}
            errorMessage={errors.password}
            onChange={handlePassword}
          />
        </div>
        <div className='tw-py-2'>
          <PasswordInput
            name='passwordConfirmation'
            label='Repeat new password'
            placeholder='Repeat new password'
            size='large'
            isInvalid={!!errors.passwordConfirmation}
            value={formData.passwordConfirmation}
            errorMessage={errors.passwordConfirmation}
            onChange={handlePassword}
          />
        </div>
      </div>
      <div className='tw-mt-5'>
        <Button
          font='semibold'
          isDisabled={!!errors.passwordConfirmation}
          isFullWidth
          size='large'
          variant='teal'
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
export default Password;
