import { css } from '@emotion/core';
import { colors, media } from 'styles';

export const wrapper = {
  base: css`
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid ${colors.borderLightGrey};
    transition: none;
    width: 182px;
    ${media.tablet} {
      position: relative;
      width: 100%;
      border-left: none;
      border-top: 1px solid ${colors.borderLightGrey};
    }
  `,

  focused: css`
    border-left: none;
    transition: width 0.2s ease-in-out;
    will-change: width;
    width: 100%;
  `,
};

export const input = css`
  padding: 9px 12px 9px 40px;
  border: none;
  font-size: 12px;
  line-height: 1;
  border-radius: 2px;
`;

export const clear = css`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;
