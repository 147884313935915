import { gql } from '@apollo/client';
import { CONTACT_FRAGMENT } from 'data/fragments/ContactFragment';

const SUBCOMMITTEE_FRAGMENT = gql`
  fragment SubcommitteeFields on Subcommittee {
    id
    name
    slug
    size
    createdAt
    parentCompany {
      id
      name
      slug
    }

    contacts {
      ...ContactFields
    }
  }
  ${CONTACT_FRAGMENT}
`;

export { SUBCOMMITTEE_FRAGMENT };
