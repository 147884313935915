// @jsx jsx

import React, { memo, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { Placeholder } from 'components/shared/Placeholder';
import { ContactPicker } from 'components/shared/ContactPicker';
import { ReactComponent as ContactsIcon } from 'images/icons/no-contacts.svg';
import { ReactComponent as DeleteIcon } from 'images/icons/delete-minus.svg';

import * as styles from './Table.styles';
import * as contactsTableStyles from './ContactsTable.styles';

const ContactsTable = memo(({ contacts, contactIds, meetingContactIds, onDelete, onToggleOption }) => {
  const placeholderIcon = <ContactsIcon />;

  const intl = useIntl();

  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    setDropdownOptions(filterContactsForPicker(contactIds));
  }, [contacts, contactIds]);

  const filterContactsForPicker = (contactIds) => {
    return contacts?.filter((contact) => !contactIds.includes(contact.id));
  };

  const fetchResults = (query) => {
    const lowerQuery = query.toLowerCase();

    const filteredContacts = filterVoteContacts().filter(
      (contact) => contact.name.toLowerCase().includes(lowerQuery) || contact.email.toLowerCase().includes(lowerQuery)
    );

    setVoteContacts(filteredContacts);
  };

  const onSearchInputChange = (event) => fetchResults(event.target.value);

  const filterVoteContacts = () => {
    return contacts?.filter((contact) => contactIds.includes(contact.id));
  };

  const isMeetingContact = (contact) => {
    return meetingContactIds && meetingContactIds.includes(contact.id);
  };

  const isSelected = (contact) => false;

  const [voteContacts, setVoteContacts] = useState([]);

  useEffect(() => {
    setVoteContacts(filterVoteContacts());
  }, [contactIds, contacts]);

  const tableBody = voteContacts.map((contact, index) => {
    const handleDelete = onDelete.bind(this, contact);

    return (
      <tr
        css={styles.tableRow(!contact[index + 1])}
        className={`m-b-5 m-l-5 ${isMeetingContact(contact) ? 'opacity-50' : ''}`}
        key={contact.id}
      >
        <td css={contactsTableStyles.hideTablet}>{index + 1}</td>
        <td className='text-truncate'>{contact.name}</td>
        <td className='text-truncate'>{contact.email}</td>
        <td className='text-truncate' css={styles.capitalize}>
          {contact.contactRole}
        </td>

        <td className='w-percent-10 text-right'>
          {!isMeetingContact(contact) && <DeleteIcon className='cursor-pointer' onClick={handleDelete} />}
        </td>
      </tr>
    );
  });

  return (
    <>
      <div className='col-md-4 p-l-0 m-b-20 add-voters'>
        <ContactPicker
          dropdownOptions={dropdownOptions}
          values={[]}
          dropdownIsOpen={false}
          onToggleOption={onToggleOption}
          isSelected={isSelected}
          placeholder={intl.formatMessage({ id: 'ContactsTable.enterName' })}
        />
      </div>

      <div css={styles.tableContainer}>
        <table css={styles.table}>
          <thead>
            <tr>
              <th css={[styles.tableHeader(5), styles.contactsHeaderItem, contactsTableStyles.hideTablet]}>#</th>
              <th css={[styles.tableHeader(20), styles.contactsHeaderItem]}>
                <FormattedMessage id='ContactsTable.name' />
              </th>
              <th css={[styles.tableHeader(25), styles.contactsHeaderItem]}>
                <FormattedMessage id='ContactsTable.email' />
              </th>
              <th css={[styles.tableHeader(25), styles.contactsHeaderItem]}>
                <FormattedMessage id='ContactsTable.role' />
              </th>
              <th css={[styles.tableHeader(25), styles.contactsHeaderItem]}>
                <input
                  type='text'
                  placeholder={intl.formatMessage({ id: 'ContactsTable.search' })}
                  className='w-100 form-control font-weight-light voter-search'
                  onChange={onSearchInputChange}
                />
              </th>
            </tr>
          </thead>

          {voteContacts.length > 0 && <tbody className='fs-12 text-black'>{tableBody}</tbody>}
        </table>

        {voteContacts.length === 0 && (
          <Placeholder image={placeholderIcon} message={intl.formatMessage({ id: 'ContactsTable.noContacts' })} />
        )}
      </div>
    </>
  );
});

export { ContactsTable };
