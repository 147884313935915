import { useState, useEffect } from 'react';
import { useShareableDocumentQuery } from './../data/queries/ShareableDocumentQuery';
import { useShareableDirectoryQuery } from './../data/queries/ShareableDirectoryQuery';

const useShareable = ({ resource }) => {
  const [shareableData, setShareableData] = useState({
    isLoading: false,
    item: null,
    shareableWith: [],
  });

  const updateShareableData = (updates) => setShareableData((state) => ({ ...state, ...updates }));

  const isDirectory = resource?.__typename === 'Directory';
  const isDocument = resource?.__typename === 'Document';

  const { loading: isDocumentLoading, data: documentData } = useShareableDocumentQuery({
    variables: { id: resource?.id },
    fetchPolicy: 'network-only',
    skip: !resource?.id || isDirectory,
  });

  const { loading: isDirectoryLoading, data: directoryData } = useShareableDirectoryQuery({
    variables: { id: resource?.id },
    fetchPolicy: 'network-only',
    skip: !resource?.id || isDocument,
  });

  const dataLoading = isDocumentLoading || isDirectoryLoading;

  useEffect(() => setIsLoading(), [dataLoading]);
  useEffect(() => setShareableDirectoryData(), [directoryData]);
  useEffect(() => setShareableDocumentData(), [documentData]);

  const setIsLoading = () => {
    if (!dataLoading) return;

    updateShareableData({ isLoading: true });
  };

  const setShareableDirectoryData = () => {
    if (!directoryData) return;

    const { directory, contacts } = directoryData.currentCompany;

    updateShareableData({ isLoading: false, item: directory, shareableWith: getShareableWith(contacts, directory) });
  };

  const setShareableDocumentData = () => {
    if (!documentData) return;

    const { document, contacts } = documentData.currentCompany;

    updateShareableData({ isLoading: false, item: document, shareableWith: getShareableWith(contacts, document) });
  };

  const getSharedWithByDefault = (item) => {
    return item.sharedWithViaPermissions.concat(item.sharedWithViaAncestors);
  };

  const getShareableWith = (contacts, item) => {
    const sharedWithIds = item.sharedWith.concat(item.contactsWithAccess).map((item) => item.id);

    return contacts.filter(({ id }) => !sharedWithIds.includes(id));
  };

  const isSharedWith = (contact) => {
    return shareableData.item.sharedWith.find((item) => item.id === contact.id);
  };

  const removeContact = (contact) => {
    const { item } = shareableData;
    const sharedWith = item.sharedWith.filter((item) => item.id !== contact.id);

    updateShareableData({ item: { ...item, sharedWith } });
  };

  const addContact = (contact) => {
    const { item } = shareableData;
    const sharedWith = [...item.sharedWith, contact];

    updateShareableData({ item: { ...item, sharedWith } });
  };

  const toggleContact = (contact) => {
    const isAdded = isSharedWith(contact);

    if (isAdded) {
      removeContact(contact);
    } else {
      addContact(contact);
    }
  };

  return {
    ...shareableData,
    isDocument,
    isDirectory,
    isSharedWith,
    toggleContact,
  };
};

export { useShareable };
