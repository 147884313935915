import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const container = css`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  height: 56px;
  pointer-events: none;
  padding: 13px 25px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 50%, transparent 100%);
`;

export const downloadIcon = css`
  transform: scale(1.3);
`;

export const inner = css`
  position: absolute;
  left: 13px;
  right: 13px;
  top: 25px;
  bottom: 25px;
  z-index: 2;
  padding: 13px 25px;
  pointer-events: auto;
  display: flex;
  align-items: center;
`;

export const title = css`
  ${fonts.semiBold}
  font-size: 16px;
  max-width: 80%;
  color: ${colors.white};
`;

export const control = {
  base: css`
    cursor: pointer;
    font-weight: 300;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    color: ${colors.white};
    width: 40px;
    height: 40px;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  `,

  disabled: css`
    cursor: default;
    color: ${colors.mediumGrey};

    &:hover {
      background: none;
    }
  `,
};
