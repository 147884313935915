import React, { memo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { client } from 'utils/apollo';
import { FlashProvider } from 'store/flashStoreContext';
import { TaskDrawerProvider } from 'store/taskDrawerStoreContext';
import { TasksProvider } from 'store/tasksStoreContext';
import { MeetingProvider } from 'store/meetingStoreContext';
import { AgendasProvider } from 'store/agendasStoreContext';
import { DocumentsProvider } from 'store/documentsStoreContext';
import { MinutesProvider } from 'store/minutesStoreContext';
import { OoEProvider } from 'store/ooeStoreContext';
import { CommentsProvider } from 'store/commentsStoreContext';
import { ReviewsProvider } from 'store/reviewsStoreContext';
import AppSignalWrapper from '../../v2/shared/AppSignalWrapper';
import { TranslationContextProvider } from '../../../contexts/v2/TranslationContext';
import { DateFormatContextProvider } from '../../../contexts/v2/DateFormatContext';

import { ApprovedMinutes } from 'components/minutes/ApprovedMinutes';

export default memo(
  ({ meetingId, minuteId, meetingTypes, timezoneOptions, currentContact, contactTimezone, contactTimezoneOffset }) => {
    return (
      <AppSignalWrapper tags={{ page: 'minutes/show' }}>
        <ApolloProvider client={client}>
          <TranslationContextProvider>
            <DateFormatContextProvider>
              <FlashProvider>
                <TaskDrawerProvider>
                  <MeetingProvider>
                    <TasksProvider>
                      <AgendasProvider>
                        <DocumentsProvider>
                          <OoEProvider>
                            <CommentsProvider>
                              <ReviewsProvider>
                                <MinutesProvider>
                                  <ApprovedMinutes
                                    minuteId={minuteId}
                                    meetingId={meetingId}
                                    meetingTypes={meetingTypes}
                                    timezoneOptions={timezoneOptions}
                                    currentContact={currentContact}
                                    contactTimezone={contactTimezone}
                                    contactTimezoneOffset={contactTimezoneOffset}
                                  />
                                </MinutesProvider>
                              </ReviewsProvider>
                            </CommentsProvider>
                          </OoEProvider>
                        </DocumentsProvider>
                      </AgendasProvider>
                    </TasksProvider>
                  </MeetingProvider>
                </TaskDrawerProvider>
              </FlashProvider>
            </DateFormatContextProvider>
          </TranslationContextProvider>
        </ApolloProvider>
      </AppSignalWrapper>
    );
  }
);
