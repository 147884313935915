/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import Box from '../Box';
import Icon from '../Icon';
import Text from '../Text';
import ContactCard from '../ContactCard';
import * as styles from './ContactGroup.styles';

const ContactGroup = ({ name, className, contacts, onClear }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const countLabel = (number) => {
    return `${number} ${number > 1 ? 'members' : 'member'}`;
  };

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const renderContactCards = () =>
    contacts.map(({ id, name, ...rest }) => (
      <ContactCard key={id} label={name} variantValueBackground='white' variant='list' className='mb-2' {...rest} />
    ));

  return (
    <Box variant='manager' className={className}>
      <div css={styles.headerWrapper}>
        <div>
          <Text text={name} className='d-block' isMedium />
          <Text text={pluralize('member', contacts.length, true)} fontSize={12} color='lightGrey' />
        </div>

        <div onClick={toggleExpanded} className='ml-auto mr-5'>
          <Text text={isExpanded ? 'Collapse' : 'Expand'} color='cyan' fontSize={12} css={styles.expand} />
        </div>

        <Icon icon='close' onClick={onClear} />
      </div>

      {isExpanded && <div className='mt-4'>{renderContactCards()}</div>}
    </Box>
  );
};

ContactGroup.propTypes = {
  className: PropTypes.string,
};

export default ContactGroup;
