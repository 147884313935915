/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';

import { ReactComponent as UnionIcon } from 'images/icons/union.svg';
import { ReactComponent as ArrowRight } from 'images/icons/documents/arrow-right.svg';

import * as styles from './index.styles';

export const TaskTableMobile = ({
  adminAccess,
  editAccess,
  tasks,
  agendas,
  onEdit,
  onDelete,
  onToggle,
  currentContactId,
}) => {
  const tableBody = tasks.map((task, index) => {
    const isActionable = adminAccess || editAccess;
    const handleEdit = onEdit.bind(this, task);
    const isTogglable = isActionable || task.contact_ids.includes(currentContactId);
    const agenda = agendas.find((agenda) => agenda.id === task.agenda_id);

    return (
      <div css={styles.taskItem_mobile} onClick={handleEdit} key={task.id}>
        <div css={styles.taskItem_body_mobile}>
          <div css={styles.taskItem_title_mobile}>{task.title}</div>
          <p className='fs-14 c-mid p-t-2 w-100 d-flex align-items-center'>
            <UnionIcon className='flex-shrink-0' />
            <span className='lh-16 m-l-5 text-truncate'>{agenda?.heading || '-'}</span>
          </p>
        </div>
        <ArrowRight />
      </div>
    );
  });

  return <div>{tableBody}</div>;
};
