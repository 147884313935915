import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as CalendarIcon } from 'images/icons/calendar.svg';
import { ReactComponent as CheckmarkIcon } from 'images/icons/circle-checkmark-inverted.svg';
import { ReactComponent as SignatureIcon } from 'images/icons/signature.svg';
import { DropdownClose, DocumentNavigation, NavigationRow } from './styles';

export const SignatureDropdown = ({
  documentFields,
  isCompleted,
  setShowNavigation,
  handleRowClick,
  showNavigation,
}) => {
  const onShwoNavigation = useCallback(() => {
    setShowNavigation(false);
  }, [setShowNavigation]);

  const intl = useIntl();

  return (
    <DocumentNavigation shownavigation={showNavigation}>
      <h3 className='font-weight-regular'>
        <FormattedMessage
          id='SignatureDropdown.actionCount'
          values={{
            count: documentFields.length,
          }}
        />
      </h3>
      <div className='scrollable'>
        {documentFields
          .sort((a, b) => {
            return a.id - b.id;
          })
          .map((df, i) => (
            <NavigationRow key={`signature-row-${i}`} onClick={() => handleRowClick(df)}>
              <div className='align-items-center d-flex'>
                {isCompleted(df) ? (
                  <CheckmarkIcon className='m-r-15' />
                ) : (
                  <i className='fal fa-chevron-left c-secondary fs-16 m-r-15' />
                )}
                <span className={isCompleted(df) ? 'c-secondary doc-description' : 'doc-description'}>
                  {i + 1}.{' '}
                  {df.isSignature
                    ? intl.formatMessage({ id: 'SignatureDropdown.putSig' })
                    : intl.formatMessage({ id: 'SignatureDropdown.date' })}
                  {isCompleted(df) && (
                    <p className='fs-10 m-b-0 c-light-mid row-label'>
                      <FormattedMessage id='SignatureDropdown.done' />
                    </p>
                  )}
                </span>
              </div>
              <div className='padding-indicator'>
                {df.isSignature ? <SignatureIcon className='m-r-10' /> : <CalendarIcon className='m-r-10' />}
                <FormattedMessage
                  id='SignatureDropdown.page'
                  values={{
                    page: df.page,
                  }}
                />
              </div>
            </NavigationRow>
          ))}
      </div>
      <DropdownClose className='btn btn-block' onClick={onShwoNavigation}>
        <FormattedMessage id='SignatureDropdown.close' />
      </DropdownClose>
    </DocumentNavigation>
  );
};
