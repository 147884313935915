import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import { fonts, colors } from 'styles';
import useWindowDimensions from 'hooks/useWindowDimensions';

const Button = styled.button`
  ${fonts.styledBold}
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.cyan};
  background: ${colors.lightCyan};
  border: 1px solid ${colors.cyan};
  box-sizing: border-box;
  border-radius: 0;
  text-transform: capitalize;
  padding: 4px 28px;
  height: 35px;

  @media all and (max-width: 460px) {
    font-size: 12px;
    line-height: 14px;
    padding: 4px 16px;
    margin-left: 10px !important;
  }
`;

export const SubmitButton = ({ readyToSubmit, isSigning, handleClick }) => {
  const { isDesktop } = useWindowDimensions();

  const intl = useIntl();

  if (readyToSubmit) {
    return !isDesktop ? (
      <Button onClick={handleClick}>
        <FormattedMessage id='SubmitButton.submit' />
      </Button>
    ) : (
      <button onClick={handleClick} className='btn btn-primary'>
        <FormattedMessage id='SubmitButton.submit' />
      </button>
    );
  }

  return !isDesktop ? (
    <Button onClick={handleClick}>
      {isSigning
        ? intl.formatMessage({ id: 'SubmitButton.next' })
        : intl.formatMessage({ id: 'SubmitButton.startSign' })}
    </Button>
  ) : (
    <button onClick={handleClick} className='btn btn-primary'>
      {isSigning
        ? intl.formatMessage({ id: 'SubmitButton.next' })
        : intl.formatMessage({ id: 'SubmitButton.startSign' })}
    </button>
  );
};
