import { css } from '@emotion/core';
import { colors } from 'styles';

export const container = {
  base: css`
    position: relative;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 19px 20px 28px;
    border-width: 1px;
    border-style: solid;
  `,

  lightGrey: css`
    background: ${colors.lightestGrey};
    border-color: ${colors.lightestGrey};
  `,

  white: css`
    background: ${colors.white};
    border-color: ${colors.white};
  `,

  grey: css`
    background: ${colors.iron};
    border-color: ${colors.iron};
  `,

  clickable: css`
    cursor: pointer;
  `,

  whiteBordered: css`
    background: ${colors.white};
    border-color: ${colors.lighterGrey};
  `,
};

export const chip = {
  base: css`
    position: absolute;
    right: 38px;
    top: -15px;
    z-index: 1;
  `,
  list: css`
    position: relative;
    margin-left: auto;
    margin-right: 20px;
  `,
  listActive: css`
    position: relative;
    margin-left: auto;
    margin-right: 20px;
  `,
};

export const textWrapper = css`
  line-height: 1;
`;

export const flex = css`
  display: flex;
  align-items: center;
`;
