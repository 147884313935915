import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const quill = {
  base: css`
    .ql-snow {
      line-height: 1;

      .ql-tooltip {
        left: unset !important;
        transform: translateY(-25px) !important;

        ${media.mobile} {
          .ql-preview {
            max-width: 100px;
          }

          &:before {
            display: none;
          }
        }
      }

      .ql-editor {
        ${fonts.regular}
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        padding: 15px 10px;

        a {
          color: ${colors.purple};
        }

        *::selection {
          background-color: ${colors.cyan};
          color: ${colors.white};
        }

        strong {
          ${fonts.semiBold}
        }
      }

      &.ql-toolbar,
      &.ql-container {
        ${fonts.regular}
        border: none;
        background: ${colors.white};
        padding: 0;

        svg {
          width: auto;
        }
      }

      &.ql-toolbar {
        padding: 5px !important;
        outline: 1px solid ${colors.lighterGrey};

        *:focus {
          outline: none;
        }

        button {
          width: 20px !important;
          height: 20px !important;
        }

        span.ql-size,
        span.ql-header {
          font-size: 12px !important;
        }

        span.ql-header {
          width: 127px !important;
        }

        .ql-picker-options {
          ${fonts.regular}
        }
      }

      &.ql-container {
        height: 448px;
        margin: 10px 15px;
        color: ${colors.black};
      }

      .ql-formats {
        ${media.mobile} {
          margin-right: 0px;
        }
      }
    }
  `,

  readOnly: css`
    .ql-snow {
      &.ql-toolbar {
        display: none;
      }

      &.ql-container {
        height: 482px;
        ${media.mobile} {
          max-height: 482px;
          height: auto;
        }
      }
    }
  `,
};
