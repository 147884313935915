import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';

export const item = css`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  line-height: 16px;
  padding: 10px 13px;
  cursor: pointer;
  ${media.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }

  &:hover {
    background: ${colors.grey};
    border-radius: 6px;
  }
`;
