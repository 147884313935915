import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const tag = css`
  display: inline-block;
  background: ${colors.cyan};
  border-radius: 2px;
  padding: 7px 10px;
  margin-right: 12px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const name = css`
  ${fonts.regular}
  font-size: 14px;
  line-height: 16px;
  color: ${colors.white};
`;

export const deleteIcon = css`
  font-size: 16px;
  color: ${colors.white};
  font-weight: 300;
  margin-left: 16px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
    color: ${colors.white};
  }
`;
