/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as AddItemBelowIcon } from 'images/icons/agenda-add-item-below.svg';
import { ReactComponent as AddItemAboveIcon } from 'images/icons/agenda-add-item-above.svg';
import { ReactComponent as AddSubItemIcon } from 'images/icons/agenda-add-subitem.svg';
import { ReactComponent as ReorderIcon } from 'images/icons/agenda-reorder.svg';

import { useFlashActions } from 'store/flashStoreContext';
import { useAgendasActions } from 'store/agendasStoreContext';
import { MAX_SUBITEM_DEPTH } from '../constants';
import * as styles from './AgendaQuickActions.styles';

const AgendaQuickActions = memo(({ show, meetingId, agendaId, position, parentId, depth, onReorder }) => {
  const { createAgenda, deleteAgenda } = useAgendasActions();
  const { setFlash } = useFlashActions();

  const addItemAbove = () => {
    createAgenda(meetingId, {
      position: position,
      parent_id: parentId,
    }).catch(setFlash);
  };

  const addItemBelow = () => {
    createAgenda(meetingId, {
      position: position + 1,
      parent_id: parentId,
    }).catch(setFlash);
  };

  const addSubItem = () => {
    createAgenda(meetingId, {
      parent_id: agendaId,
    }).catch(setFlash);
  };

  const deleteItem = () => {
    deleteAgenda(meetingId, agendaId).catch(setFlash);
  };

  return (
    show && (
      <div css={styles.wrapper}>
        <div css={styles.icon} onClick={onReorder}>
          <ReorderIcon />

          <div css={styles.tooltip}>
            <FormattedMessage id='AgendaQuickActions.reorder' />
            <div css={styles.triangle} />
          </div>
        </div>

        <div css={styles.icon} onClick={addItemBelow}>
          <AddItemBelowIcon />

          <div css={styles.tooltip}>
            <FormattedMessage id='AgendaQuickActions.addBelow' />
            <div css={styles.triangle} />
          </div>
        </div>

        <div css={styles.icon} onClick={addItemAbove}>
          <AddItemAboveIcon />

          <div css={styles.tooltip}>
            <FormattedMessage id='AgendaQuickActions.addAbove' />
            <div css={styles.triangle} />
          </div>
        </div>

        {depth < MAX_SUBITEM_DEPTH && (
          <div css={styles.icon} onClick={addSubItem}>
            <AddSubItemIcon />

            <div css={styles.tooltip}>
              <FormattedMessage id='AgendaQuickActions.addSubitem' />
              <div css={styles.triangle} />
            </div>
          </div>
        )}

        <div css={styles.icon} onClick={deleteItem}>
          <i className='far fa-trash-alt' />

          <div css={styles.tooltip}>
            <FormattedMessage id='AgendaQuickActions.deleteItem' />
            <div css={styles.triangle} />
          </div>
        </div>
      </div>
    )
  );
});

export { AgendaQuickActions };
