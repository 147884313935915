/** @jsx jsx */

import React, { useMemo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import moment from 'moment';
import currency from 'currency.js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { FormattedMessage, useIntl } from 'react-intl';

import { colors } from 'styles';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { LoadingIndicator } from 'components/shared/LoadingIndicator';
import { AlertPopup } from '../shared/AlertPopup';
import * as styles from './PaymentForm.styles';
import BounceLoader from 'react-spinners/BounceLoader';

const useOptions = () =>
  useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          fontSize: '14px',
          color: '#424770',
          fontFamily: 'Gilroy-Regular, sans-serif',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    []
  );

const PaymentForm = ({
  productSelected,
  setSubscription,
  setProduct,
  setAlert,
  setSubscriptionCancelled,
  company,
  context,
  period,
  trial_ended,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const intl = useIntl();

  const [subscribing, setSubscribing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorToDisplay, setErrorToDisplay] = useState('');
  const [isComplete, setComplete] = useState(false);
  const [isEmpty, setEmpty] = useState(false);

  const handleAlertClose = () => setErrorToDisplay('');

  const handlePaymentThatRequiresCustomerAction = ({ subscription, priceId, paymentMethodId }) => {
    if (subscription.status === 'active' || subscription.status === 'trialing') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    const paymentIntent = subscription.latest_invoice.payment_intent;

    if (paymentIntent.status === 'requires_action') {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  };

  const handleRequiresPaymentMethod = ({ subscription, paymentMethodId, priceId }) => {
    if (subscription.status === 'active' || subscription.status === 'trialing') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
      throw new Error('Your card was declined.');
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  };

  const updatePaymentMethod = ({ subscriptionId, paymentMethodId }) => {
    const payload = {
      payment_method: paymentMethodId,
    };
    return (
      $.ajax({
        url: `/${company.slug}/subscriptions/${subscriptionId}`,
        method: 'put',
        data: payload,
        headers: getConfig(),
      })
        .then((response) => {
          return response;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result.error;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result,
            paymentMethodId: paymentMethodId,
            priceId: productSelected.stripe_id,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setSubscribing(false);
          setErrorToDisplay(error.message);
        })
    );
  };

  const onSubscriptionComplete = (result) => {
    // Payment was successful. Provision access to your service.
    localStorage.clear();
    if (context === 'gateway') {
      window.location.assign(`/${company.slug}/dashboard?subscription_created=true`);
    } else {
      setSubscribing(false);
      setSuccess(true);
      setSubscriptionCancelled(false);
      setAlert(true);
      setSubscription();
      setProduct(productSelected);
    }
  };

  const getConfig = () => {
    return {
      'X-CSRF-Token': $("[name='csrf-token']").attr('content'),
    };
  };

  const createSubscription = ({ paymentMethod, slug }) => {
    const payload = {
      payment_method: paymentMethod.id,
      product_id: productSelected.id,
      context: context,
      company_id: company.id,
      promotion_code: (promotionCodeVerified && promotionCode) || '',
    };

    return (
      $.ajax({
        url: `/${slug}/subscriptions`,
        method: 'post',
        data: payload,
        headers: getConfig(),
      })
        .then((response) => {
          return response;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result.error;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result,
            paymentMethodId: paymentMethod.id,
            priceId: productSelected.stripe_id,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setSubscribing(false);
          setErrorToDisplay(error.message);
        })
    );
  };

  const [promotionCode, setPromotionCode] = useState('');
  const [promotionCodeVerified, setPromotionCodeVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [promotionCodeError, setPromotionCodeError] = useState(false);
  const [promotionCodeSuccess, setPromotionCodeSuccess] = useState(false);

  const handlePromotionCodeChange = (event) => {
    return setPromotionCode(event.target.value);
  };

  const hidePromotionCodeError = () => {
    return setPromotionCodeError(false);
  };

  const handlePromotionCodeSuccess = () => {
    setPromotionCodeSuccess(true);

    setTimeout(() => {
      return setPromotionCodeSuccess(false);
    }, 3000);
  };

  const subscriptionWithTotals = () => {
    return trial_ended || (context === 'settings' && setSubscriptionCancelled);
  };

  const productTotal = () => {
    return subscriptionWithTotals() ? Number(productSelected.price * 1.2) : 0;
  };

  const formatTotal = (total) => {
    return currency(total, { symbol: '', separator: '' }).format();
  };

  const [discountAmount, setDiscountAmount] = useState(null);
  const [discountPercent, setDiscountPercent] = useState(null);
  const [discountTotal, setDiscountTotal] = useState(null);
  const [orderTotal, setOrderTotal] = useState(productTotal());

  const recalculateTotals = (result) => {
    if (!subscriptionWithTotals) {
      setDiscountTotal(0);
      return setOrderTotal(0);
    }

    if (result !== null) {
      setDiscountAmount(result.coupon.amount_off / 100);
      setDiscountPercent(result.coupon.percent_off);
    } else {
      setDiscountTotal(null);
      setOrderTotal(productTotal());
    }
  };

  useEffect(() => {
    if (promotionCodeSuccess) {
      setPromotionCodeError(false);
      setPromotionCodeVerified(true);
    }
  }, [promotionCodeSuccess]);

  useEffect(() => {
    if (promotionCodeError) {
      setPromotionCodeSuccess(false);
      setPromotionCodeVerified(false);
    }
  }, [promotionCodeError]);

  useEffect(() => {
    const discount = discountPercent
      ? Number(productSelected.price * (discountPercent / 100)).toFixed(2)
      : discountAmount;

    setDiscountTotal(discount);

    if (subscriptionWithTotals()) {
      return setOrderTotal(productTotal() - discount);
    } else {
      return setOrderTotal(0);
    }
  }, [discountPercent, discountAmount, productSelected]);

  const verifyPromotionCode = () => {
    setIsVerifying(true);

    return $.ajax({
      url: `/${company.slug}/subscriptions/verify_promotion_code`,
      method: 'get',
      data: { promotion_code: promotionCode },
    })
      .then((response) => {
        return response;
      })
      .then((result) => {
        if (result !== null) {
          handlePromotionCodeSuccess();
        } else {
          setPromotionCodeError(true);
        }

        recalculateTotals(result);
        return setIsVerifying(false);
      });
  };

  const handleSubmit = async (event) => {
    if (!isComplete) {
      setEmpty(true);
      return;
    }
    setEmpty(false);
    // Block native form submission.
    event.preventDefault();

    setSubscribing(true);
    setErrorToDisplay('');

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error && error != ' ') {
      setSubscribing(false);
      setErrorToDisplay(error && error.message);
    }

    // Create the subscription
    createSubscription({
      paymentMethod: paymentMethod,
      slug: company.slug,
    });
  };

  const onChangeCardElement = (event) => {
    setComplete(event.complete);
  };

  const { isMobile } = useWindowDimensions();

  return (
    <div>
      <div className='col-md-10 m-auto' style={{ maxWidth: context === 'gateway' ? 800 : '100%' }}>
        <div id='payment-form' className={`col-md-9 m-auto ${isMobile && 'p-0'}`}>
          <div css={isEmpty ? styles.errorCardBox : styles.cardBox}>
            <h2 className='fs-18 font-weight-bold m-b-15'>
              <FormattedMessage id='PaymentForm.ccOrDebit' />
            </h2>
            <CardElement options={options} onChange={onChangeCardElement} />
          </div>
          <div css={promotionCodeError ? styles.errorCouponBox : styles.couponBox}>
            <h2 className='fs-18 font-weight-bold m-b-15'>
              <FormattedMessage id='PaymentForm.couponCode' />
            </h2>
            <div className='d-flex m-b-5'>
              <input
                className='m-r-15 w-100 coupon-code'
                type='text'
                value={promotionCode}
                onChange={handlePromotionCodeChange}
                onClick={hidePromotionCodeError}
              />
              <div
                className={`btn btn-secondary ${!promotionCode.length && 'btn-disabled'}`}
                onClick={verifyPromotionCode}
              >
                <FormattedMessage id='PaymentForm.apply' />
              </div>
              <LoadingIndicator show={isVerifying} />
            </div>
            {promotionCodeSuccess && (
              <div className='success-text fs-14'>
                <FormattedMessage id='PaymentForm.couponSuccess' />
              </div>
            )}
            {promotionCodeError && (
              <div className='error-text fs-14'>
                <FormattedMessage id='PaymentForm.noCode' />
              </div>
            )}
          </div>
          <div css={styles.summaryBox}>
            <h2 className='fs-18 font-weight-bold'>
              <FormattedMessage id='PaymentForm.orderOverview' />
            </h2>
            <div css={styles.summaryDetails}>
              <p className='c-dark'>
                <span className='c-dark font-weight-bold'>
                  <FormattedMessage id='PaymentForm.subPlan' />
                </span>
                <span className='text-capitalize text-right'>{productSelected.name}</span>
              </p>
              <p className='c-dark'>
                <span className='c-dark font-weight-bold'>
                  <FormattedMessage id='PaymentForm.dueToday' />
                </span>
                {}

                {productSelected.currency === 'usd' ? '$' : '£'}
                {subscriptionWithTotals() ? Number(productSelected.price).toFixed(2) : 0}
              </p>
              {promotionCodeVerified && (
                <p className='c-dark'>
                  <span className='c-dark font-weight-bold'>
                    <FormattedMessage id='PaymentForm.discount' />
                  </span>
                  {!subscriptionWithTotals() && (productSelected.currency === 'usd' ? '-$0' : '-£0')}
                  {subscriptionWithTotals() &&
                    (productSelected.currency === 'usd' ? `-$${discountTotal}` : `-£${discountTotal}`)}
                </p>
              )}
              <p className='c-dark'>
                <span className='c-dark font-weight-bold'>
                  <FormattedMessage id='PaymentForm.taxes' />
                </span>
                {productSelected.currency === 'usd' ? '$' : '£'}
                {subscriptionWithTotals() ? Number(productSelected.price * 0.2).toFixed(2) : 0}
              </p>
              <div css={styles.billingInfo}>
                <FormattedMessage
                  id='PaymentForm.firstPayment'
                  values={{
                    immediately: (
                      <span className='font-weight-semibold text-black'>
                        {intl.formatMessage({
                          id: 'PaymentForm.immediately',
                        })}
                      </span>
                    ),
                    period,
                    day: moment().format('Do'),
                    year: period === 'yearly' ? ` of ${moment().format('MMMM')}` : '',
                  }}
                />
              </div>
            </div>
            <p css={styles.total} className='fs-18 font-weight-bold c-dark'>
              <span>
                <FormattedMessage id='PaymentForm.total' />
              </span>
              {productSelected.currency === 'usd' ? '$' : '£'}
              {formatTotal(orderTotal)}
            </p>
          </div>
          {subscribing && (
            <div className='d-flex justify-content-center m-b-10'>
              <BounceLoader loading={true} color={colors.cyan} />
            </div>
          )}
          <div className='text-center'>
            <button
              type='button'
              className={`btn btn-sm-fw mb-3 btn-primary ${subscribing && 'btn-disabled'}`}
              onClick={handleSubmit}
            >
              {subscribing
                ? intl.formatMessage({ id: 'PaymentForm.processing' })
                : intl.formatMessage({ id: 'PaymentForm.completePayment' })}
            </button>
            <AlertPopup
              show={errorToDisplay !== ''}
              headerText={intl.formatMessage({ id: 'PaymentForm.oops' })}
              popupType='failure'
              onClose={handleAlertClose}
            >
              <FormattedMessage id='PaymentForm.paymentError' values={{ error: errorToDisplay }} />
            </AlertPopup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
