import React, { useRef, useCallback } from 'react';
import styled from 'styled-components';
import 'blueimp-canvas-to-blob';
import { FormattedMessage } from 'react-intl';

import AppHeader from 'components/shared/AppHeader';
import { AppBody } from 'components/shared/AppHeader/styles';
import { HeaderTitle } from 'components/shared/AppHeader/styles';
import SignatureInputInMobile from 'components/smart_documents/SignatureInput/SignatureInputInMobile';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { colors } from 'styles';
import api from '../API';

const CloseIcon = styled.i`
  color: ${colors.black};
`;

const ActionBtnWrapper = styled.div`
  text-align: center;
  position: absolute;
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  button {
    flex: 1;
  }
`;

export const AddSignature = ({
  name,
  token,
  onSignatureInsert,
  documentId,
  fieldId,
  existingSignatureImageUrl,
  setActiveSignature,
}) => {
  const { isMobile } = useWindowDimensions();
  const signatureInput = useRef();
  const onSetActiveSignature = useCallback(() => {
    setActiveSignature(null);
  }, [setActiveSignature]);

  const uploadSignature = (forAll) => {
    signatureInput.current.processInput((blob) => {
      api.uploadSignature(
        documentId,
        fieldId,
        token,
        blob,
        forAll,
        (response) => {
          onSignatureInsert(response);
          signatureInput?.current?.resetControl();
        },
        (error) => {
          signatureInput?.current?.setError(error.response.data.errors);
        }
      );
    });
  };

  return (
    <>
      <AppHeader>
        <div
          className={`w-100 d-flex align-items-center justify-content-between ${
            isMobile ? 'p-l-20 p-r-20' : 'p-l-40 p-r-40'
          }`}
        >
          <HeaderTitle fontSize={isMobile ? 20 : 30} lineHeight='28'>
            <FormattedMessage id='AddSignature.addSignature' />
          </HeaderTitle>
          <CloseIcon className='fal fa-times fa-2x' onClick={onSetActiveSignature} />
        </div>
      </AppHeader>
      <AppBody>
        <SignatureInputInMobile
          name={name}
          existingSignatureImageUrl={existingSignatureImageUrl}
          ref={signatureInput}
        />
        <ActionBtnWrapper>
          <button className='btn btn-inverted' onClick={uploadSignature.bind(this, false)}>
            <FormattedMessage id='AddSignature.insert' />
          </button>
          <button className='m-l-25 btn btn-primary' onClick={uploadSignature.bind(this, true)}>
            <FormattedMessage id='AddSignature.insertEvery' />
          </button>
        </ActionBtnWrapper>
      </AppBody>
    </>
  );
};
