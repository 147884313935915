/** @jsx jsx */

import React, { memo, useMemo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from './../Tooltip';
import { CheckBox } from 'components/shared/CheckBox';

import * as styles from './PermissionsTable.styles';

const PermissionsTable = memo(({ permissions, accessRolePermissions, onChange, isDisabled }) => {
  const groupedAccessRolePermissions = useMemo(() => {
    return accessRolePermissions.reduce((accumulator, value) => {
      accumulator[value.permission_id] = value.permitted;

      return accumulator;
    }, {});
  }, [accessRolePermissions]);

  const groupedPermissions = useMemo(() => {
    return permissions.reduce((accumulator, value) => {
      let key = value.attributes.resource_label;

      if (!accumulator[key]) {
        accumulator[key] = [];
      }

      accumulator[key].push(value);

      return accumulator;
    }, {});
  }, []);

  const renderTable = () => {
    return Object.keys(groupedPermissions).map((key, index) => renderTableRow(key, index));
  };

  const renderTableRow = (label, index) => {
    const items = groupedPermissions[label];
    const bgClass = index % 2 === 0 ? 'even' : 'odd';
    const rows = renderRows(items, bgClass);

    return (
      <React.Fragment key={`permissions-row-group-${index}`}>
        <tr css={styles.resourceRow} className={bgClass}>
          <td>{label}</td>
          <td />
        </tr>

        {rows}
      </React.Fragment>
    );
  };

  const renderRows = (items, bgClass) => {
    return items.map((permission, index) => {
      const { scope_label, description } = permission.attributes;
      const toggle = renderToggle(permission);
      const isLast = !items[index + 1];

      return (
        <tr key={`permissions-row-${permission.id}`} className={`${bgClass} ${isLast ? 'is-last' : ''}`}>
          <td>
            <div className='d-flex align-items-center w-90'>
              <div className='m-r-10 text-truncate'>{scope_label}</div>
              <Tooltip message={description} className='c-accent' />
            </div>
          </td>

          <td className='text-center' key={`toggle-${permission.id}`}>
            {toggle}
          </td>
        </tr>
      );
    });
  };

  const renderToggle = (permission) => {
    const elementId = `permission-checkbox-${permission.id}`;
    const permissionId = Number(permission.id);
    const isChecked = groupedAccessRolePermissions[permissionId];
    const handleChange = onChange.bind(this, {
      permission_id: permission.id,
      permitted: !isChecked,
    });

    return (
      <CheckBox
        id={elementId}
        key={elementId}
        onChange={handleChange}
        value={permission.id}
        isChecked={isChecked}
        isSwitch
        className='d-flex m-b-5 justify-content-center'
        isDisabled={isDisabled}
      />
    );
  };

  return (
    <table css={styles.permissionsTable}>
      <thead>
        <tr>
          <th>
            <FormattedMessage id='PermissionsTable.functions' />
          </th>
          <th>
            <FormattedMessage id='PermissionsTable.noYes' />
          </th>
        </tr>
      </thead>

      <tbody>{renderTable()}</tbody>
    </table>
  );
});

export { PermissionsTable };
