/** @jsx jsx */

import React, { useState, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { SubcommitteeDrawer } from '../SubcommitteeDrawer/SubcommitteeDrawer';
import { SubcommitteesTable } from '../SubcommitteeTable/SubcommitteeTable';
import { useCreateSubcommittee } from '../hooks/useCreateSubcommittee';
import { useDeleteSubcommittee } from '../hooks/useDeleteSubcommittee';
import { useUpdateSubcommittee } from '../hooks/useUpdateSubcommittee';
import { useSubcommitteesQuery } from '../data/queries/SubcommitteesQuery';

const NEW_SUBCOMMITTEE = {
  name: '',
  slug: '',
  contacts: [],
};

const SubcommitteesContainer = ({ companyId }) => {
  const { data, loading, refetch } = useSubcommitteesQuery();
  const [context, setContext] = useState({
    isDrawerOpen: false,
    errors: [],
    subcommittee: NEW_SUBCOMMITTEE,
  });

  const closeDrawer = useCallback(() => {
    setContext((state) => ({ ...state, isDrawerOpen: false, errors: [] }));
  }, []);

  const openDrawer = useCallback((subcommittee) => {
    setContext((state) => ({
      ...state,
      subcommittee: subcommittee,
      isDrawerOpen: true,
      errors: [],
    }));
  }, []);

  const handleSubcommitteeChange = useCallback((values) => {
    console.log('handleSubcommitteeChange: ', values);
    setContext((state) => ({ ...state, subcommittee: { ...state.subcommittee, ...values } }));
  }, []);

  const onError = useCallback((errors) => {
    setContext((state) => ({ ...state, errors: errors }));
  }, []);

  const handleAddSubcommitteeClick = openDrawer.bind(this, NEW_SUBCOMMITTEE);

  const { deleteSubcommittee } = useDeleteSubcommittee({ onCompleted: closeDrawer });
  const { createSubcommittee } = useCreateSubcommittee({ onCompleted: closeDrawer, onError, companyId });
  const { updateSubcommittee } = useUpdateSubcommittee({ onCompleted: closeDrawer, onError });

  const handleDeleteSubcommittee = () => {
    deleteSubcommittee({ subcommitteeId: context.subcommittee.id });
  };

  const handleCreateSubcommittee = () => {
    createSubcommittee({
      name: context.subcommittee.name,
      contacts: context.subcommittee.contacts.map((contact) =>
        contact.id
          ? { id: contact.id, email: contact.email, accessRoleId: contact.accessRoleId }
          : { email: contact.email, accessRoleId: contact.accessRoleId }
      ),
    });
  };

  const handleUpdateSubcommittee = () => {
    console.log(context.subcommittee.contacts);
    updateSubcommittee({
      subcommitteeId: context.subcommittee.id,
      name: context.subcommittee.name,
      contacts: context.subcommittee.contacts.map((contact) =>
        contact.id
          ? { id: contact.id, email: contact.email, accessRoleId: contact.accessRoleId }
          : { email: contact.email, accessRoleId: contact.accessRoleId }
      ),
    });
  };

  return (
    <div className='d-flex flex-column'>
      <div className='media-heading-mt-small align-items-center d-flex justify-content-between'>
        <div className='text-nowrap media-title'>
          <FormattedMessage id='SubcommitteesContainer.subcommittees' />
        </div>
        <div onClick={handleAddSubcommitteeClick} className='btn btn-primary btn-sm-fw mw-200 m-l-30'>
          <FormattedMessage id='SubcommitteesContainer.add' />
        </div>
      </div>

      <div className='media-row'>
        <SubcommitteesTable onEdit={openDrawer} data={data} loading={loading} refetch={refetch} />
      </div>

      <SubcommitteeDrawer
        isOpen={context.isDrawerOpen}
        onClose={closeDrawer}
        subcommittee={context.subcommittee}
        errors={context.errors}
        onSubcommitteeChange={handleSubcommitteeChange}
        onCreate={handleCreateSubcommittee}
        onUpdate={handleUpdateSubcommittee}
        onDelete={handleDeleteSubcommittee}
        accessRoles={loading ? [] : data.currentCompany.accessRoles}
        parentCompanyContacts={loading ? [] : data.currentCompany.contacts}
      />
    </div>
  );
};

export { SubcommitteesContainer };
