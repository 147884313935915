import queryPolicies from 'typePolicies/queryPolicies';
import conversationPolicies from 'typePolicies/conversationPolicies';
import contactGroupPolicies from 'typePolicies/contactGroupPolicies';
import documentPolicies from 'typePolicies/documentPolicies';
import userPolicies from './userPolicies';
import companyPolicies from './companyPolicies';

export default {
  ...queryPolicies,
  ...conversationPolicies,
  ...contactGroupPolicies,
  ...documentPolicies,
  ...userPolicies,
  ...companyPolicies,
};
