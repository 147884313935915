/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import Divider from 'components/v2/shared/Divider';
import Box from 'components/v2/shared/Box';
import Text from 'components/v2/shared/Text';
import VoteItem from './../VoteItem';

import * as styles from './VoteList.styles';

const VoteList = ({ votes }) => {
  if (!votes || !votes.length) {
    return (
      <Box variant='placeholder'>
        <Text color='lightGrey' text='No (0) votes added' />
      </Box>
    );
  }

  return votes.map((vote) => <VoteItem key={vote.id} {...vote} />);
};

VoteList.propTypes = {
  votes: PropTypes.array,
};

export default VoteList;
