import React from 'react';

const Inclusions = ({ planData }) => {
  return (
    <>
      <div className='tw-mt-14 xl:tw-mt-6'>
        <h6 className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black xl:tw-mb-0'>Inclusions</h6>
      </div>
      <div className='tw-my-7'>
        {planData.map((row, i) => {
          return (
            <div key={i} className='tw-mb-1'>
              <div className='tw-grid tw-h-14 tw-grid-flow-col tw-grid-cols-7 tw-rounded tw-bg-gray-100 tw-p-5'>
                <div className='tw-col-span-7 tw-flex tw-justify-between'>
                  <div className='tw-font-sans tw-text-xs tw-font-medium	 tw-text-black'>{row.title}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Inclusions;
