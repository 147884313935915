/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertPopup } from 'components/shared/AlertPopup';

import * as styles from './UploadWindowStatusBar.styles';

const UploadWindowStatusBar = memo(
  ({ pendingUploadsCount, completeUploadsCount, isMinimized, onClose, onToggleMinimized }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const hideConfirmation = () => setShowConfirmation(false);

    const intl = useIntl();

    const boldHeaderText =
      pendingUploadsCount > 0
        ? intl.formatMessage({ id: 'UploadWindowStatusBar.itemCount' }, { count: pendingUploadsCount })
        : intl.formatMessage({ id: 'UploadWindowStatusBar.itemCount' }, { count: completeUploadsCount });

    const regularHeaderText =
      pendingUploadsCount > 0
        ? intl.formatMessage({ id: 'UploadWindowStatusBar.uploading' })
        : intl.formatMessage({ id: 'UploadWindowStatusBar.uploaded' });

    const handleClose = () => {
      if (pendingUploadsCount > 0) {
        setShowConfirmation(true);
      } else {
        onClose();
      }
    };

    return (
      <div css={styles.container}>
        <div>
          <span css={styles.header}>{boldHeaderText} </span>
          <span css={styles.text}>{regularHeaderText}</span>
        </div>

        <div css={styles.controls}>
          <i
            className={`fa fa-fw fa-chevron-${isMinimized ? 'up' : 'down'}`}
            css={styles.toggle}
            onClick={onToggleMinimized}
          />

          <i className='fa fa-fw fa-times' onClick={handleClose} css={styles.close} />
        </div>

        <AlertPopup
          show={showConfirmation}
          popupType='info'
          headerText={intl.formatMessage({ id: 'UploadWindowStatusBar.confirmation' })}
          onClose={hideConfirmation}
        >
          <div className='text-black fs-14'>
            <FormattedMessage id='UploadWindowStatusBar.cancelPending' />
          </div>

          <div className='d-flex align-items-center m-t-40 justify-content-center'>
            <button className='btn btn-info' onClick={onClose}>
              <FormattedMessage id='UploadWindowStatusBar.confirm' />
            </button>
            <button className='btn btn-info-inverted m-l-10' onClick={hideConfirmation}>
              <FormattedMessage id='UploadWindowStatusBar.cancel' />
            </button>
          </div>
        </AlertPopup>
      </div>
    );
  }
);

export { UploadWindowStatusBar };
