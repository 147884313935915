import { gql, useMutation } from '@apollo/client';
import { SUBCOMMITTEE_FRAGMENT } from '../fragments/SubcommitteeFragment';

const UPDATE_SUBCOMMITTEE_MUTATION = gql`
  mutation updateSubcommittee($input: UpdateSubcommitteeInput!) {
    updateSubcommittee(input: $input) {
      subcommittee {
        ...SubcommitteeFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${SUBCOMMITTEE_FRAGMENT}
`;

const useUpdateSubcommitteeMutation = (options) => useMutation(UPDATE_SUBCOMMITTEE_MUTATION, options);

export { UPDATE_SUBCOMMITTEE_MUTATION, useUpdateSubcommitteeMutation };
