import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const BaseOption = ({ isSelected, isDisabled, className, children, itemProps, height, listBorder }) => (
  <li
    {...itemProps}
    className={clsx(
      'tw-absolute tw-top-0 tw-right-0 tw-left-0',
      'tw-box-border tw-flex tw-text-black',
      'tw-cursor-pointer last:tw-border-b-0',
      'tw-px-6 tw-py-3',
      {
        'tw-bg-green-200': isSelected,
        'tw-cursor-pointer hover:tw-bg-green-200': !isDisabled,
        'tw-cursor-not-allowed tw-opacity-30': isDisabled,
        'tw-font-sans tw-text-sm tw-font-normal': height === 'large',
        'tw-border-0 tw-border-b tw-border-solid tw-border-purple-200': height === 'regular' || listBorder,
        'tw-font-medium': height === 'regular',
      },
      className
    )}
  >
    {children}
  </li>
);

BaseOption.propTypes = {
  itemProps: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  height: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  listBorder: PropTypes.bool,
};

export default BaseOption;
