/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { Row, Col } from 'react-bootstrap';
import { Editor } from './../Editor';
import { MeetingDetails } from 'components/meetings/MeetingDetails';
import { ReviewerList } from './../ReviewerList';
import { ActionBar } from './../ActionBar';
import { OoEStep } from './../OoEStep';
import { useActionCable } from 'hooks/useActionCable';
import { useOoEStore } from 'store/ooeStoreContext';
import { useMinutesStore, useMinutesActions } from 'store/minutesStoreContext';
import { useReviewsStore } from 'store/reviewsStoreContext';
import { useCommentsStore } from 'store/commentsStoreContext';

import * as styles from './OwnerSpace.styles';

const OwnerSpace = memo(
  ({
    isPseudoMeeting,
    onSaveDraft,
    onDistribute,
    onApprove,
    currentContact,
    contactTimezone,
    contactTimezoneOffset,
  }) => {
    const { minutes } = useMinutesStore();
    const { setMinutesContext } = useMinutesActions();
    const { ooe } = useOoEStore();
    const { comments } = useCommentsStore();
    const { reviews } = useReviewsStore();

    const [showOoeForm, setShowOoeForm] = useState(false);

    const toggleShowOoeForm = () => setShowOoeForm(!showOoeForm);

    useActionCable('MinutesChannel', { minute_id: minutes.id }, (response) => setMinutesContext(minutes.id, response));

    const editOoeForm = (
      <OoEStep
        minutes={minutes}
        isPseudoMeeting={isPseudoMeeting}
        currentContact={currentContact}
        context='review'
        onBack={toggleShowOoeForm}
      />
    );

    return (
      <>
        <MeetingDetails
          contactTimezone={contactTimezone}
          contactTimezoneOffset={contactTimezoneOffset}
          context='in-review-minutes'
          isMinuteContext
        />

        {showOoeForm && editOoeForm}

        {!showOoeForm && (
          <div className='m-t-20'>
            <Row noGutters css={styles.row}>
              <Col md={9} css={styles.editor}>
                <div className='d-flex align-items-baseline m-t-20 m-l-25 lh-1'>
                  <div className='fs-22 text-black font-weight-bold'>
                    <FormattedMessage id='OwnerSpace.oOE' />
                  </div>

                  <div className='fs-14 c-secondary m-l-10'>
                    <FormattedMessage id='OwnerSpace.commentCount' values={{ count: comments.length }} />
                  </div>
                </div>

                <Editor value={ooe?.description} isReadOnly />
              </Col>

              <Col md={3} className='react-reviewer-list'>
                <ReviewerList minutes={minutes} reviews={reviews} comments={comments} currentContact={currentContact} />
              </Col>
            </Row>
          </div>
        )}

        <div className='m-t-15 p-20 bg-white rounded'>
          <ActionBar
            status={minutes.status}
            onSave={onSaveDraft}
            onDistribute={onDistribute}
            onApprove={onApprove}
            showEditButton={!showOoeForm}
            onEdit={toggleShowOoeForm}
            canDistribute
          />
        </div>
      </>
    );
  }
);

export { OwnerSpace };
