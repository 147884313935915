import React, { StrictMode } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from 'pages/Dashboard';
import Taskboard from 'pages/Taskboard';

const App = ({}) => (
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path='/:slug/dashboard' element={<Dashboard />} />
        <Route exact path='/:slug/taskboard' element={<Taskboard />} />
      </Routes>
    </BrowserRouter>
  </StrictMode>
);

App.propTypes = {};

export default App;
