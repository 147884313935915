import React, { Fragment, useState, useEffect } from 'react';
import { Tab, Disclosure } from '@headlessui/react';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import clsx from 'clsx';
import CompanyDetail from './CompanyDetail';
import Subcommittee from './Subcommittee';
import BillingInvoices from './BillingInvoices';
import RolesPermissions from './RolesPermissions';
import Contacts from './Contacts';
import Heading from 'components/v2/shared/Heading/';
import { withApollo } from 'utils/v2/apollo';
import { useCurrentCompanyQuery } from 'data/v2/queries/CurrentCompanyQuery';
import { ToastContextProvider } from '../../../contexts/v2/ToastContext';

const Index = ({ currentUser, address }) => {
  const [listIndex, setListIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [rolesPermissionData, setRolesPermissionData] = useState({
    isRolesSelected: false,
    isRoleEditable: false,
    isNewRole: false,
    isChangeName: false,
    selectedRoleData: {},
    selectedRoleIndex: '',
    roles: [],
  });

  const { data, refetch: currentCompanyRefetch } = useCurrentCompanyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data) {
      let accessRoles = data.currentCompany.accessRoles;
      if (accessRoles?.length > 0) {
        setRolesPermissionData((prev) => ({
          ...prev,
          roles: accessRoles.map((a) => {
            return {
              id: a.id,
              role: a.name,
              isDefault: a.isDefault,
              users: a.contacts,
              isDeletable: !a.isDefault,
              permissions: a.rolePermissions,
            };
          }),
          selectedRoleData: {
            ...prev.selectedRoleData,
            permissions: accessRoles[prev.selectedRoleIndex]?.rolePermissions,
            role: accessRoles[prev.selectedRoleIndex]?.name,
          },
        }));
      }
    }
  }, [data]);

  const handleSettingsClick = () => {
    setRolesPermissionData((prev) => ({
      ...prev,
      isRolesSelected: false,
      isRoleEditable: false,
      selectedRoleIndex: '',
      isNewRole: false,
    }));
    setIsOpen(false);
  };

  const Menu = ({ label }) => {
    return (
      <Tab as={Fragment}>
        {({ selected }) => (
          <a
            onClick={() => setIsOpen(true)}
            className={clsx(
              'tw-flex tw-h-[50px] tw-cursor-pointer tw-items-center tw-justify-between tw-py-2 tw-px-[33px] tw-font-sans tw-text-sm tw-font-semibold hover:tw-text-teal-500 md:tw-h-full md:tw-justify-start md:tw-px-0',
              {
                'tw-font-normal tw-text-black md:tw-font-semibold md:tw-text-teal-500': selected,
                'tw-text-black sm:tw-font-medium': !selected,
              }
            )}
          >
            {label}
            <span className='tw-pl-3'>
              <FiChevronRight size={17} className={clsx({ 'md:tw-hidden': !selected })} />
            </span>
          </a>
        )}
      </Tab>
    );
  };

  const MenuList = () => (
    <Tab.List className='tw-flex tw-flex-col'>
      <Menu label='Company details' />
      <hr className='tw-my-[9px] tw-w-full tw-border-[1px] tw-border-solid tw-border-gray-200 md:tw-hidden' />
      <Menu label='Roles & Permissions' />
      <hr className='tw-my-[9px] tw-w-full tw-border-[1px] tw-border-solid tw-border-gray-200 md:tw-hidden' />
      <Menu label='Contacts' />
      <hr className='tw-my-[9px] tw-w-full tw-border-[1px] tw-border-solid tw-border-gray-200 md:tw-hidden' />
      <Menu label='Subcommittee' />
      <hr className='tw-my-[9px] tw-w-full tw-border-[1px] tw-border-solid tw-border-gray-200 md:tw-hidden' />
      <Menu label='Billing & Invoices' />
    </Tab.List>
  );
  const Panel = () => {
    return (
      <Tab.Panels>
        <Tab.Panel>
          <CompanyDetail />
        </Tab.Panel>
        <Tab.Panel>
          <RolesPermissions
            rolesPermissionData={rolesPermissionData}
            setRolesPermissionData={setRolesPermissionData}
            currentCompanyRefetch={currentCompanyRefetch}
          />
        </Tab.Panel>
        <Tab.Panel>
          <Contacts roles={data?.currentCompany?.accessRoles} contactRequests={data?.contactRequests} />
        </Tab.Panel>
        <Tab.Panel>
          <Subcommittee currentCompany={data?.currentCompany} />
        </Tab.Panel>
        <Tab.Panel>
          <BillingInvoices currentCompany={data?.currentCompany} />
        </Tab.Panel>
      </Tab.Panels>
    );
  };

  return (
    <ToastContextProvider>
      <div className='tw-mx-auto lg:tw-container sm:tw-p-5 xl:tw-max-w-screen-2xl'>
        <div className='tw-block md:tw-hidden'>
          <Tab.Group selectedIndex={listIndex} onChange={setListIndex}>
            <div className={clsx('tw-w-full tw-pb-1 md:tw-block', { 'tw-hidden': isOpen })}>
              <div className='tw-mx-auto tw-h-screen tw-w-full tw-max-w-full tw-bg-white'>
                <div className='tw-relative tw-py-5 md:tw-px-10'>
                  <Heading text='Settings' className='tw-m-0_ tw-px-[33px] !tw-text-[22px]' />
                </div>
                <div className='tw-pb-10 md:tw-px-10'>
                  <MenuList />
                </div>
              </div>
            </div>
            <div className='tw-w-full tw-pb-1'>
              <div
                className={clsx(
                  'tw-mx-auto tw-h-screen tw-w-full tw-max-w-full tw-bg-white',
                  { 'tw-hidden': !isOpen },
                  'md:tw-block'
                )}
              >
                <Disclosure defaultOpen={true}>
                  {() => (
                    <>
                      <div className='pb-0 tw-py-5 tw-px-5 md:tw-px-10'>
                        <h5
                          className='tw-flex tw-cursor-pointer tw-items-center tw-text-base !tw-font-extrabold hover:tw-underline'
                          onClick={() => handleSettingsClick()}
                        >
                          <FiChevronLeft size={18} className='tw-text-purple-700' color='#47126E' />

                          <span className='tw-font-sans tw-text-sm !tw-font-semibold tw-text-purple-500 sm:tw-pt-1'>
                            Settings
                          </span>
                        </h5>
                      </div>
                      <Disclosure.Panel className={clsx('tw-bg-white tw-px-5 tw-pb-10')}>
                        <Panel currentUser={currentUser} address={address} />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </Tab.Group>
        </div>

        <div className='tw-hidden md:tw-block'>
          <div>
            <Tab.Group selectedIndex={listIndex} onChange={setListIndex}>
              <div className='tw-rounded-xl tw-bg-white tw-py-12'>
                <div className='tw-flex'>
                  <div className='tw-w-1/3 tw-border-0 tw-border-r tw-border-solid tw-border-gray-200 tw-py-9 tw-pl-14 xl:tw-w-1/4'>
                    <Heading text='Companies' className='tw-mb-10 !tw-text-[26px]' />
                    <MenuList />
                  </div>

                  <div className='tw-w-2/3 tw-px-5 md:tw-px-0 xl:tw-w-3/4'>
                    <div className='tw-container tw-flex tw-h-full tw-justify-center'>
                      <div className='tw-w-full !tw-text-sm'>
                        <Panel currentUser={currentUser} address={address} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Group>
          </div>
        </div>
      </div>
    </ToastContextProvider>
  );
};
export default withApollo(Index);
