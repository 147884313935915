/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { COMPLETE, CANCELLED, ERROR } from 'data/globals/UploadQueueItemStates';

import { useUploadQueueActions } from 'store/uploadQueueStoreContext';

import * as styles from './UploadWindowItemActions.styles';

const UploadWindowItemActions = memo(({ id, state, progress, xhr }) => {
  const isComplete = state === COMPLETE;
  const isRetriable = [CANCELLED, ERROR].includes(state);

  const { cancelUpload, retryUpload } = useUploadQueueActions();

  const handleCancel = () => cancelUpload({ id });
  const handleRetry = () => retryUpload({ id });

  const percentage = (
    <div css={styles.percentage} className='percentage'>
      {progress}%
    </div>
  );

  if (isComplete) {
    return percentage;
  }

  if (isRetriable) {
    return <i css={styles.control} className='fa fa-redo' onClick={handleRetry} />;
  }

  return (
    <div css={styles.container}>
      <i css={styles.control} className='fa fa-times' onClick={handleCancel} />
      {percentage}
    </div>
  );
});

export { UploadWindowItemActions };
