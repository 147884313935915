/** @jsx jsx */

import React, { memo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './ImageViewer.styles';

const ImageViewer = memo(({ source, scale, width, onLoad }) => {
  const [size, setSize] = useState({});

  useEffect(() => finalizeLoading(), [size]);

  const finalizeLoading = () => {
    if (!size.width || !size.height) return;

    onLoad();
  };

  const handleLoad = ({ target }) => {
    const imageWidth = Math.min(target.naturalWidth, width);
    const imageHeight = (imageWidth / target.naturalWidth) * target.naturalHeight;

    setSize({ width: imageWidth, height: imageHeight });
  };

  const innerStyles = styles.inner({ ...size, scale });

  return (
    <div css={styles.outer}>
      <div css={innerStyles}>
        <img src={source} onLoad={handleLoad} css={styles.image} />
      </div>
    </div>
  );
});

export { ImageViewer };
