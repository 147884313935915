/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { ShareModalContactListItem } from './../ShareModalContactListItem';
import { Scrollable } from 'components/shared/Scrollable';
import { Placeholder } from 'components/shared/Placeholder';

import { ReactComponent as ContactsIcon } from 'images/icons/no-contacts.svg';

import * as styles from './ShareModalContactList.styles';

const ShareModalContactList = memo(({ withAccess, sharedWith, onDelete }) => {
  const hasWithAccess = withAccess && withAccess.length > 0;
  const hasSharedWith = sharedWith && sharedWith.length > 0;

  const showPlaceholder = !hasWithAccess && !hasSharedWith;

  if (showPlaceholder) {
    return (
      <div css={styles.container}>
        <Placeholder image={<ContactsIcon />} message='No contacts' />
      </div>
    );
  }

  const renderContactWithAccess = (contact, props) => (
    <ShareModalContactListItem key={contact.id} contact={contact} {...props} />
  );

  const renderContact = (contact) => {
    const handleDelete = onDelete.bind(this, contact);

    return renderContactWithAccess(contact, {
      onDelete: handleDelete,
      withIcon: true,
    });
  };

  const renderedSharedWith = sharedWith.map(renderContact);
  const renderedWithAccess = withAccess.map(renderContactWithAccess);

  return (
    <div css={styles.container}>
      <Scrollable>
        {renderedSharedWith}
        {renderedWithAccess}
      </Scrollable>
    </div>
  );
});

export { ShareModalContactList };
