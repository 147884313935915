import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const wrapper = {
  base: css`
    border: 1px solid transparent;
    padding: 11px 9px;
    border-color: ${colors.border};
    background-color: ${colors.white};
  `,

  state: {
    focused: css`
      border-color: ${colors.cyan};
    `,

    invalid: css`
      border-color: ${colors.lightRed};
    `,

    disabled: css`
      background: ${colors.grey};
      border-radius: 2px;
      border: 1px solid rgba(152, 164, 175, 0.4);
    `,
  },
};

export const textarea = {
  base: css`
    ${fonts.regular}
    line-height: 20px;
    border: none;
    font-size: 14px;
    width: 100%;
    height: 150px;
    overflow: auto;
    padding: 0 10px;
    resize: none;

    color: ${colors.black};

    &::placeholder {
      color: ${colors.headingGrey};
    }
  `,

  state: {
    disabled: css`
      ${wrapper.state.readOnly}
      background: ${colors.grey};
      border: none;
    `,

    invalid: css`
      color: ${colors.red};

      &::placeholder {
        color: ${colors.red};
      }
    `,
  },
};
