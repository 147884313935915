import styled from 'styled-components';
import { fonts, colors, media } from 'styles';

export const Row = styled.div`
  color: #00031f;
  ${fonts.styledSemiBold}
  font-size: 12px;
  line-height: 24px;
  .title {
    width: 96px;
    min-width: 96px;
  }
  .row-content {
    margin-left: 21px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.black};
  }
  padding: 2px 0px;
`;

export const ContentCard = styled.div`
  background: ${colors.white};
  border-top: solid 1px ${colors.borderGrey};
  border-bottom: solid 1px ${colors.borderGrey};
  margin-bottom: 8px;
  padding: 12px 22px;
  position: relative;
  margin-bottom: ${({ isLast }) => (isLast ? '0px' : '10px')};
  ${media.mobile} {
    border: solid 1px ${colors.borderGrey};
    margin-left: -15px;
    margin-right: -15px;
    border-left: none;
    border-right: none;
  }
`;

export const Link = styled.a`
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.black};
  ${fonts.styledRegular}
`;

export const DeleteBtnWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border-left: 1px solid ${colors.borderGrey};
  border-bottom: 1px solid ${colors.borderGrey};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  .table-action-btn {
    color: ${colors.lightGrey};
  }
  background: white;
`;

export const TextOverflow = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
