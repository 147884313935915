import { gql, useQuery } from '@apollo/client';
import { RESOLUTION_DOCUMENT_FRAGMENT } from './../fragments/ResolutionDocumentFragment';

const RESOLUTION_QUERY = gql`
  query resolution($id: ID!) {
    currentCompany {
      id

      resolution(id: $id) {
        id

        resolutionDocuments {
          ...ResolutionDocumentFields
        }
      }
    }
  }
  ${RESOLUTION_DOCUMENT_FRAGMENT}
`;

const useResolutionQuery = (options) => useQuery(RESOLUTION_QUERY, options);

export { useResolutionQuery };
