/** @jsxRuntime classic */
/** @jsx jsx */

import React, { memo, useState, useMemo, useRef, useEffect } from 'react';
import { jsx } from '@emotion/core';

import { Message } from './../Message';
import { DateLabel } from './../DateLabel';
import { Scrollable } from 'components/shared/Scrollable';

import { userDateFormat } from '../../../contexts/v2/DateFormatContext';

import moment from 'moment';

import * as styles from './MessageList.styles';

const TIME_THRESHOLD = 5;
const THRESHOLD_PERCENTAGE = 0.2;

const MessageList = memo(({ messages, onScrollTop, onScrollStop }) => {
  const [trackedMessage, setTrackedMessage] = useState(null);
  const [isScrollBottom, setIsScrollBottom] = useState(true);

  const ref = useRef();

  useEffect(() => {
    ref.current.scrollToBottom();
  }, []);

  useEffect(() => {
    if (isScrollBottom) {
      ref.current.scrollToBottom();
    }

    const lastMessage = messages[messages.length - 1];

    if (
      trackedMessage &&
      lastMessage &&
      lastMessage.node.id !== trackedMessage.node.id &&
      lastMessage.node.owner.isCurrentContact
    ) {
      ref.current.scrollToBottom();
    }

    setTrackedMessage(lastMessage);
  }, [messages]);

  const groupedMessages = useMemo(() => {
    return messages.reduce((accumulator, message) => {
      const date = moment(message.node.createdAt).format(userDateFormat);

      if (!accumulator[date]) {
        accumulator[date] = [];
      }

      accumulator[date].push(message);

      return accumulator;
    }, {});
  }, [messages]);

  const withAvatar = (prevMessage, nextMessage) => {
    if (!nextMessage) return true;
    if (prevMessage.owner.id !== nextMessage.owner.id) return true;

    const diff = moment(nextMessage.createdAt).diff(moment(prevMessage.createdAt), 'minutes');

    return diff > TIME_THRESHOLD;
  };

  const renderMessages = (items) => {
    return items.map((item, index) => {
      const showAvatar = withAvatar(items[index].node, items[index + 1]?.node);

      return (
        <Message
          key={item.node.id}
          author={item.node.owner}
          text={item.node.text}
          createdAt={item.node.createdAt}
          withAvatar={showAvatar}
        />
      );
    });
  };

  const messageList = Object.keys(groupedMessages).map((date) => {
    const dateMessages = renderMessages(groupedMessages[date]);

    return (
      <React.Fragment key={`messages-list-fragment-${date}`}>
        <DateLabel key={`label-${date}`} label={date} />

        {dateMessages}
      </React.Fragment>
    );
  });

  const currentDate = moment().format(userDateFormat);
  const messageListPlaceholder = !messages.length && <DateLabel label={currentDate} />;

  const handleScroll = ({ scrollTop, scrollHeight, clientHeight }) => {
    const isBottom = scrollTop === scrollHeight - clientHeight;

    setIsScrollBottom(isBottom);
  };

  const handleScrollTop = ({ scrollTop, scrollHeight, clientHeight }) => {
    const scrollOffset = THRESHOLD_PERCENTAGE * (scrollHeight - clientHeight);

    if (scrollTop <= scrollOffset) {
      onScrollTop();
    }
  };

  const handleScrollStop = () => {
    if (isScrollBottom) {
      onScrollStop();
    }
  };

  return (
    <Scrollable
      ref={ref}
      css={styles.scrollable}
      onUpdate={handleScrollTop}
      onScroll={handleScroll}
      onScrollStop={handleScrollStop}
    >
      <div css={styles.messages}>
        {messageList}
        {messageListPlaceholder}
      </div>
    </Scrollable>
  );
});

export { MessageList };
