import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const votingDrawer = css`
  background-color: #fff;
  display: block;
  position: fixed;
  transition: transform .17s ease-in-out;
  z-index: 9999;
  will-change: transform;
  top: 0;
  bottom: 0;
  right: 0;
  width: 962px;
  transform: translate3d(0, 0, 0);
  box-shadow: -20px 4px 50px rgba(54, 71, 98, 0.25);

  .close-icon {
    padding: 15px;
    color: ${colors.black};
    cursor: pointer;
  }

  .heading {
    width: 100%;
    padding: 23px 36px 32px 36px;

    .name {
      ${fonts.bold}
      font-size: 20px;
      color: ${colors.grey};
      line-height: 28px;
    }
  }

  .wrapper {
    height: 100%;

    .row,
    .side-nav,
    .nav,
    .main-content {
      height: 100%;
    }
  }

  .wrapper {
    .side-nav .nav {
      border: 1px solid ${colors.grey};

      .nav-link {
        background: ${colors.white};
        padding: 12px 36px;
        border-bottom: 1px solid ${colors.grey};
        font-size: 14px;
        color: $headingColor;
        border-radius: 0;
        line-height: 20px;
        display: flex;
        align-items: center;

        img {
          display: none;
          width: 12px;
          height: auto;
        }

        &.active, &.completed {
          color: ${colors.cyan};
        }

        &.active {
          background: rgba(0, 186, 198, 0.1);
        }

        &.completed {
          padding-left: 12px;

          img {
            display: block;
          }
        }
      }
    }

    .tab-pane {
      height: 100vh;
    }

    .main-content {
      border: 1px solid ${colors.grey};
      border-left: none;

      .header {
        ${fonts.bold}
        font-size: 16px;
        line-height: 28px;
        color: ${colors.grey};
      }

      .stats {
        border-radius: 4px;
        height: 20px;
        line-height: 20px;
        background: ${colors.cyan};
        color: ${colors.white}
        padding: 0 12px;
        font-size: 12px;
      }

      .sync-btn:hover {
        i {
          color: ${colors.cyan};
        }
      }

      .reso-container {
        overflow: auto;
        padding-right: 60px;
        max-height: calc(100vh - 215px);

        .resolution {
          padding-right: 0;
        }

        .vote-results {
          .col-md-8 {
            max-width: 60%;
            flex: 0 0 60%;
          }

          .col-md-4 {
            max-width: 40%;
            flex: 0 0 40%;
          }
        }
      }
    }
  }
}
`;
