import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import { serializeAttendees, serializeAttendeesAndGroups } from 'utils/v2/serializers';
import { useUpdateMeetingAttendeesMutation } from 'data/v2/mutations/UpdateMeetingAttendeesMutation';
import { useMeetingContext } from './MeetingContext';
import { wrapMutation } from 'utils/v2/gql';
import { useMeetingAttendeesQuery } from '../../data/v2/queries/MeetingAttendeesQuery';
import { useContactGroupsContext } from './ContactGroupsContext';
import { uniqueBy } from '../../utils/v2/data';
import { arrayToIdMap } from '../../utils/v2/data';

const initialState = {
  edits: {
    individualAttendees: [],
    groups: [],
    chairmanId: null,
    attendeesIdMap: {},
  },
  attendeesIdMap: {},
  individualAttendees: [],
  groups: [],
  chairmanId: null,
  skipFetch: true,
};

const actionTypes = {
  SET_INDIVIDUAL_ATTENDEES: 'AttendeesContext.setIndividualAttendees',
  SET_GROUPS: 'AttendeesContext.setgroups',
  SET_DATA: 'AttendeesContext.setAttendees',
  SET_CHAIR_ID: 'AttendeesContext.setChairmanId',
  SET_ATTENDEES_MAP: 'AttendeesContext.setAttendeeMap',
  FETCH_ATTENDEES: 'AttendeesContext.fetchAttendees',
  DISCARD_EDITS: 'AttendeesContext.discardEdits',
};

const AttendeesContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_INDIVIDUAL_ATTENDEES:
      return { ...state, edits: { ...state.edits, individualAttendees: payload.individualAttendees } };
    case actionTypes.SET_GROUPS:
      return { ...state, edits: { ...state.edits, groups: payload.groups } };
    case actionTypes.SET_DATA:
      const { attendeesIdMap, individualAttendees, groups, chairmanId } = payload;
      return {
        ...state,
        chairmanId,
        attendeesIdMap,
        individualAttendees,
        groups,
        edits: { attendeesIdMap, chairmanId, individualAttendees, groups },
      };
    case actionTypes.FETCH_ATTENDEES:
      return { ...state, skipFetch: false };
    case actionTypes.SET_CHAIR_ID:
      return { ...state, edits: { ...state.edits, chairmanId: payload.chairmanId } };
    case actionTypes.SET_ATTENDEES_MAP:
      return { ...state, edits: { ...state.edits, attendeesIdMap: payload.attendeesIdMap } };
    case actionTypes.DISCARD_EDITS:
      return {
        ...state,
        edits: {
          chairmanId: state.chairmanId,
          individualAttendees: state.individualAttendees,
          groups: state.groups,
          attendeesIdMap: state.attendeesIdMap,
        },
      };
  }
};

export const AttendeesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    meeting: { meetingId },
  } = useMeetingContext();

  const { getGroupsContacts } = useContactGroupsContext();

  const { edits, skipFetch, attendeesIdMap } = state;

  const { data } = useMeetingAttendeesQuery({
    variables: { id: meetingId },
    skip: skipFetch,
  });

  const [updateAttendees] = useUpdateMeetingAttendeesMutation();

  const filterAttendee = (idField, attendeeId) => (attendee) => attendee[idField] !== attendeeId;

  useEffect(() => {
    if (data) {
      const {
        currentCompany: {
          meeting: { contactMeetings, chairman },
        },
      } = data;

      const [attendeesIdMap, individualAttendees, groups] = serializeAttendeesAndGroups(contactMeetings);

      dispatch({
        type: actionTypes.SET_DATA,
        payload: { individualAttendees, groups, attendeesIdMap, chairmanId: !!chairman ? chairman.contact.id : null },
      });
    }
  }, [data]);

  useEffect(() => {
    dispatch({ type: actionTypes.SET_ATTENDEES_MAP, payload: { attendeesIdMap: arrayToIdMap(getAllAttendees(true)) } });
  }, [edits.individualAttendees, edits.groups]);

  const fetchMeetingAttendees = () => {
    dispatch({ type: actionTypes.FETCH_ATTENDEES });
  };

  const setIndividualAttendees = (individualAttendees) => {
    dispatch({ type: actionTypes.SET_INDIVIDUAL_ATTENDEES, payload: { individualAttendees } });
  };

  const setGroups = (groups) => {
    dispatch({
      type: actionTypes.SET_GROUPS,
      payload: { groups },
    });
  };

  const removeIndividualAttendee = (attendeeId) => {
    const {
      edits: { individualAttendees },
    } = state;
    dispatch({
      type: actionTypes.SET_INDIVIDUAL_ATTENDEES,
      payload: { individualAttendees: individualAttendees.filter(filterAttendee('value', attendeeId)) },
    });
  };

  const removeGroup = (groupId) => {
    const {
      edits: { groups },
    } = state;
    const filteredGroups = groups.filter((group) => group.value !== groupId);
    dispatch({
      type: actionTypes.SET_GROUPS,
      payload: { groups: filteredGroups },
    });
  };

  const getGroupIds = () => {
    const {
      edits: { groups },
    } = state;

    return groups.reduce((result, group) => [...result, group.value], []);
  };

  const getAllAttendees = (unique = false) => {
    const {
      edits: { individualAttendees },
    } = state;

    const groupIds = getGroupIds();
    const groupAttendees = getGroupsContacts(groupIds);

    const attedees = [...individualAttendees, ...groupAttendees];

    if (unique) {
      return uniqueBy(attedees, 'id');
    }

    return attedees;
  };

  const hasNoMeetingAttendees = !state.individualAttendees.length && !state.groups.length;

  const hasNoEditsAttendees = !edits.individualAttendees.length && !edits.groups.length;

  const updateMeetingAttendees = () => {
    const attendees = getAllAttendees();

    return wrapMutation(
      updateAttendees,
      {
        variables: {
          input: {
            meetingId,
            attributes: serializeAttendees(attendees),
            chairmanId: !!edits.attendeesIdMap[edits.chairmanId] ? edits.chairmanId : null,
          },
        },
      },
      'updateMeetingAttendees'
    ).then((data) => {
      const {
        meeting: { contactMeetings, chairman },
      } = data;
      const [attendeesIdMap, individualAttendees, groups] = serializeAttendeesAndGroups(contactMeetings);
      dispatch({
        type: actionTypes.SET_DATA,
        payload: {
          attendeesIdMap,
          individualAttendees,
          groups,
          chairmanId: !!chairman ? chairman.contact.id : null,
        },
      });
    });
  };

  const setChairmanId = (chairmanId) => {
    dispatch({ type: actionTypes.SET_CHAIR_ID, payload: { chairmanId } });
  };

  const discardEdits = () => {
    dispatch({ type: actionTypes.DISCARD_EDITS });
  };

  return (
    <AttendeesContext.Provider
      value={{
        edits,
        setIndividualAttendees,
        setGroups,
        attendeesIdMap,
        removeIndividualAttendee,
        updateMeetingAttendees,
        fetchMeetingAttendees,
        removeGroup,
        discardEdits,
        getAllAttendees,
        setChairmanId,
        hasNoMeetingAttendees,
        hasNoEditsAttendees,
      }}
    >
      {children}
    </AttendeesContext.Provider>
  );
};

AttendeesContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useAttendeesContext = () => useContext(AttendeesContext);
