/** @jsx jsx */

import React, { memo, useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { Actions } from './../Actions';

import * as styles from './Step.styles';

const Step = memo(({ steps, prepend, children, onSaveDraft, onSaveAndAdvance, saveDraftLabel }) => {
  const actions = onSaveDraft && onSaveAndAdvance && (
    <Actions saveDraftLabel={saveDraftLabel} onSaveDraft={onSaveDraft} onSaveAndAdvance={onSaveAndAdvance} />
  );

  return (
    <>
      <div css={styles.panel}>
        {prepend}
        {steps}
        {children}
      </div>

      {actions}
    </>
  );
});

export { Step };
