import { gql, useMutation } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from './../fragments/DocumentFragment';

const TOGGLE_FAVOURITE_DOCUMENT = gql`
  mutation toggleFavouriteDocument($input: ToggleFavouriteDocumentInput!) {
    toggleFavouriteDocument(input: $input) {
      document {
        ...DocumentFields
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

const useToggleFavouriteDocumentMutation = (options) => useMutation(TOGGLE_FAVOURITE_DOCUMENT, options);

export { useToggleFavouriteDocumentMutation };
