/** @jsx jsx */
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Row, Col } from 'react-bootstrap';
import { jsx } from '@emotion/core';
import SignaturePad from 'signature_pad';
import Dropzone from 'react-dropzone';
import canvasTxt from 'canvas-txt';
import 'blueimp-canvas-to-blob';
import { ReactComponent as Upload } from 'images/icons/doc-upload.svg';
import { signatureTypes, signatureFonts } from './types';
import { fonts } from 'styles';
import * as styles from './index.styles';

export default forwardRef(({ name, existingSignatureImageUrl, onSave }, ref) => {
  useImperativeHandle(ref, () => ({
    processInput: (handler) => {
      if (isCanvasEmpty()) {
        return setError('Please add a signature in order to proceed');
      }

      canvas.current.toBlob(handler);
    },
    resetControl: () => {
      resetControl();
    },
    setError: (error) => {
      setError(error);
    },
  }));

  const canvas = useRef();

  const defaultType = existingSignatureImageUrl ? signatureTypes.saved : signatureTypes.drawing;

  let [activeFont, setActiveFont] = useState(signatureFonts.bethEllen);
  let [signatureType, setSignatureType] = useState(defaultType);
  let [ratioModifier, setRatioModifier] = useState(0);
  let [pad, setPad] = useState();
  let [image, setImage] = useState();
  let [error, setError] = useState();

  const resetControl = () => {
    setActiveFont(signatureFonts.bethEllen);
    setSignatureType(signatureTypes.drawing);
    setImage(null);
    setError(null);
    setRatioModifier(0);
  };

  const isActiveSignatureType = (type) => {
    return type === signatureType;
  };

  const toggleSignatureType = (e) => {
    setSignatureType(e.target.value);
    setRatioModifier(0);
  };

  useEffect(() => {
    if (signatureType && canvas.current) {
      return changeSignatureType(signatureType);
    }
  }, [ratioModifier, activeFont, signatureType, image]);

  const changeSignatureType = (type) => {
    const context = canvas.current.getContext('2d');
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    context.clearRect(0, 0, canvas.current.width, canvas.current.height);

    canvas.current.width = canvas.current.offsetWidth * ratio;
    canvas.current.height = canvas.current.offsetHeight * ratio;

    context.scale(ratio, ratio);

    if (pad) {
      pad.off();
    }

    if (type === signatureTypes.saved && existingSignatureImageUrl) {
      return insertExistingSignatureImage(context, ratio);
    } else if (type === signatureTypes.drawing) {
      return setPad(new SignaturePad(canvas.current, { throttle: 0 }));
    } else if (type === signatureTypes.script) {
      return insertNameIntoCanvas(context, ratio);
    } else if (type === signatureTypes.upload && image) {
      return insertImageIntoCanvas(context, ratio, image);
    }
  };

  const insertExistingSignatureImage = (context, ratio) => {
    const existingImage = new Image();
    existingImage.src = existingSignatureImageUrl;
    existingImage.crossOrigin = 'Anonymous';
    existingImage.onload = () => insertImageIntoCanvas(context, ratio, existingImage);
  };

  const insertImageIntoCanvas = (context, ratio, image) => {
    const scale = 1 / ratio;

    const imgScale = Math.min(
      (scale * canvas.current.width) / image.width,
      (scale * canvas.current.height) / image.height
    );

    const imgW = image.width * (imgScale + ratioModifier / 100);
    const imgH = image.height * (imgScale + ratioModifier / 100);

    const cenX = (scale * canvas.current.width - imgW) / 2;
    const cenY = (scale * canvas.current.height - imgH) / 2;

    context.drawImage(image, cenX, cenY, imgW, imgH);

    const pixels = context.getImageData(0, 0, canvas.current.width, canvas.current.height);

    convertToGrayscaleAndRemoveBackground(pixels);

    context.putImageData(pixels, 0, 0, 0, 0, pixels.width, pixels.height);
  };

  const convertToGrayscaleAndRemoveBackground = (pixels) => {
    for (let y = 0; y < pixels.height; y++) {
      for (let x = 0; x < pixels.width; x++) {
        let data = pixels.data;

        // Grayscale
        const index = y * 4 * pixels.width + x * 4;
        const average = (data[index] + data[index + 1] + data[index + 2]) / 3;

        data[index] = average;
        data[index + 1] = average;
        data[index + 2] = average;

        // Remove background
        if (data[index] >= 160 && data[index + 1] >= 160 && data[index + 2] >= 160) {
          data[index] = 0;
          data[index + 1] = 0;
          data[index + 2] = 0;
          data[index + 3] = 0;
        }
      }
    }
  };

  const insertNameIntoCanvas = (context, ratio) => {
    if (name) {
      const fontSize = 50 + 2 * ratioModifier;
      const scale = 1 / ratio;
      const x = 0;
      const y = activeFont === signatureFonts.dawning ? -20 : -10;

      canvasTxt.font = activeFont;
      canvasTxt.fontSize = fontSize;
      canvasTxt.lineHeight = fontSize;

      canvasTxt.drawText(context, name, x, y, scale * canvas.current.width, scale * canvas.current.height);
    }
  };

  const isActiveFont = (font) => {
    return isActiveSignatureType(signatureTypes.script) && activeFont === font;
  };

  const clearCanvas = () => {
    const context = canvas.current.getContext('2d');

    if (signatureType === signatureTypes.drawing) {
      context.clearRect(0, 0, canvas.current.width, canvas.current.height);
    } else if (signatureType === signatureTypes.upload) {
      setImage(null);
    }
  };

  const changeFontHandler = (font) => {
    return isActiveSignatureType(signatureTypes.script) ? setActiveFont.bind(this, font) : null;
  };

  const maxRatioModifier = 18;
  const minRatioModifier = -18;

  const isMinRatioModifier = ratioModifier === minRatioModifier;
  const isMaxRatioModifier = ratioModifier === maxRatioModifier;

  const scaleUp = () => {
    if (isMaxRatioModifier) return;

    setRatioModifier(ratioModifier + 1);
  };

  const scaleDown = () => {
    if (isMinRatioModifier) return;

    setRatioModifier(ratioModifier - 1);
  };

  const handleDropzoneDrop = (acceptedFiles) => {
    const upload = acceptedFiles[0];
    const image = new Image();

    image.src = URL.createObjectURL(upload);
    image.onload = () => setImage(image);
  };

  const isCanvasEmpty = () => {
    const context = canvas.current.getContext('2d');

    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.current.width, canvas.current.height).data.buffer
    );

    return !pixelBuffer.some((color) => color !== 0);
  };

  const dropZone = (
    <div css={styles.dropzone} className='position-absolute w-100 d-flex align-items-center justify-content-center'>
      <Dropzone accept={['image/jpg', 'image/jpeg', 'image/png']} onDrop={handleDropzoneDrop}>
        {({ getRootProps, getInputProps }) => (
          <div css={styles.dropzone} {...getRootProps()}>
            <input {...getInputProps()} />

            <div className='d-flex align-items-center justify-content-center fs-12 lh-16'>
              <Upload className='m-0' />
              <span className='p-l-15 text-black d-none d-lg-block'>Drag & Drop files here</span>
              <span className='p-l-15 text-black d-lg-none'>Click to Upload</span>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );

  const renderFont = (fontName) => {
    const isScriptActive = isActiveSignatureType(signatureTypes.script);
    const clickHandler = changeFontHandler(signatureFonts[fontName]);
    const elementStyles = [styles.scriptFont(isScriptActive), fonts[fontName]];

    return (
      <div onClick={clickHandler} css={elementStyles}>
        <div css={styles.signatoryName} className='text-truncate'>
          {name}
        </div>

        {isActiveFont(signatureFonts[fontName]) && (
          <div css={styles.arrow}>
            <div css={styles.triangleLeft}>
              <div css={styles.innerTriangleLeft} />
            </div>

            <div css={styles.rectangle} />
          </div>
        )}
      </div>
    );
  };

  return (
    <Row className='m-t-10 p-b-20'>
      {error && (
        <div className='col-md-12 p-b-10' css={styles.error}>
          {error}
        </div>
      )}
      <Col md={8}>
        <input
          css={styles.disabledInput}
          disabled={true}
          type='text'
          value={name || ''}
          className='form-control w-100 m-b-10'
        />
        <div className='react-signature-pad' css={styles.canvasWrapper}>
          <canvas css={styles.canvas} ref={canvas} />

          {!isActiveSignatureType(signatureTypes.drawing) && (
            <div css={styles.scaleIcons}>
              <i css={styles.scaleIcon(isMinRatioModifier)} onClick={scaleDown} className='fa fa-fw fa-search-minus' />
              <i
                css={styles.scaleIcon(isMaxRatioModifier)}
                onClick={scaleUp}
                className='fa fa-fw fa-search-plus m-l-10'
              />
            </div>
          )}

          {isActiveSignatureType(signatureTypes.upload) && !image && dropZone}
        </div>
        <div className='d-flex flex-column'>
          <span css={styles.clear(isActiveSignatureType(signatureTypes.drawing))} onClick={clearCanvas}>
            Clear
          </span>

          {!isActiveSignatureType(signatureTypes.saved) && onSave && (
            <button className='btn btn-primary w-100px m-t-15' css={styles.saveSignatureButton} onClick={onSave}>
              Save
            </button>
          )}
        </div>
      </Col>

      <Col md={4} css={styles.checkboxes}>
        {existingSignatureImageUrl && (
          <div className='radio'>
            <input
              id={signatureTypes.saved}
              value={signatureTypes.saved}
              onChange={toggleSignatureType}
              type='radio'
              checked={isActiveSignatureType(signatureTypes.saved)}
              className='btn-radio'
            />

            <label css={styles.radioLabel} htmlFor={signatureTypes.saved}>
              Saved
            </label>
          </div>
        )}

        <div className='radio'>
          <input
            id={signatureTypes.drawing}
            value={signatureTypes.drawing}
            onChange={toggleSignatureType}
            type='radio'
            checked={isActiveSignatureType(signatureTypes.drawing)}
            className='btn-radio'
          />

          <label css={styles.radioLabel} htmlFor={signatureTypes.drawing}>
            Sign with mouse or trackpad
          </label>
        </div>

        <div className='radio'>
          <input
            id={signatureTypes.script}
            value={signatureTypes.script}
            type='radio'
            onChange={toggleSignatureType}
            checked={isActiveSignatureType(signatureTypes.script)}
            className='btn-radio'
          />

          <label htmlFor='script' css={styles.radioLabel}>
            Use script
          </label>
        </div>

        <div className='m-t-10' css={styles.scriptFonts(isActiveSignatureType(signatureTypes.script))}>
          {renderFont('bethEllen')}
          {renderFont('dancingScript')}
          {renderFont('dawning')}
          {renderFont('glory')}
          {renderFont('sheppards')}
        </div>

        <div className='radio m-t-10'>
          <input
            id={signatureTypes.upload}
            value={signatureTypes.upload}
            type='radio'
            onChange={toggleSignatureType}
            checked={isActiveSignatureType(signatureTypes.upload)}
            className='btn-radio'
          />

          <label css={styles.radioLabel} htmlFor={signatureTypes.upload}>
            Upload signature
          </label>
        </div>
      </Col>
    </Row>
  );
});
