import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const modal = css`
  @media (max-width: 767px) {
    padding-left: 0 !important;
  }

  .modal-dialog {
    max-width: 93vw;

    @media (max-width: 767px) {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .modal-dialog.alert-popup {
    width: 500px;
    background: transparent;
  }

  .modal-dialog.alert-popup .modal-content {
    box-shadow: 0px 4px 50px rgba(54, 71, 98, 0.12);
    width: 432px;
    margin: 0 auto;
    border-radius: 8px;
    border: none;
    padding: 12px;

    svg {
      width: auto;
      margin: 0 auto;
    }

    @media (max-width: 767px) {
      width: 330px;
    }

    @media (max-width: 350px) {
      width: 280px;
      margin: 10px auto;
      border: 1px solid ${colors.lightestGrey};
      padding: 8px;
    }
  }

  .modal-dialog.alert-popup .modal-header {
    padding: 0;
    margin: 0 auto;
    position: relative;
    width: 100%;
  }

  .modal-dialog.alert-popup .modal-body {
    ${fonts.regular}
    font-size: 14px;
    text-align: center;

    @media (max-width: 350px) {
      line-height: 18px;
    }
  }
`;

export const sign = css`
  position: absolute;
  top: 72%;
  left: 43%;
`;

export const header = {
  base: css`
    ${fonts.bold}
    font-size: 26px;
    margin: 35px 0 25px 0;
  `,
  success: css`
    color: ${colors.cyan};
  `,
  failure: css`
    color: ${colors.red};
  `,
  info: css`
    color: ${colors.orange};
  `,
};

export const close = css`
  position: absolute;
  background: ${colors.white};
  box-shadow: 0px 4px 50px rgba(54, 71, 98, 0.12);
  top: -30px;
  right: -25px;
  width: 36px;
  height: 36px;
  border-radius: 50%;

  @media (max-width: 350px) {
    top: -15px;
    right: -20px;
  }

  &:hover {
    cursor: pointer;

    svg {
      path {
        fill: ${colors.cyan};
      }
    }
  }
`;
