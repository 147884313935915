import { gql } from '@apollo/client';

const COMPANY_ROUTES_FRAGMENT = gql`
  fragment CompanyRoutesFields on CompanyRoutes {
    settingsLink
    subscriptionLink
  }
`;

export { COMPANY_ROUTES_FRAGMENT };
