import { css } from '@emotion/core';
import { panels, colors, fonts } from 'styles';

export const component = css`
  display: flex;
  align-items: baseline;
  position: relative;
`;

export const organizer = css`
  color: ${colors.black};
  display: flex;
  align-items: center;
  line-height: 1;
  cursor: pointer;
`;

export const chevron = {
  base: css`
    margin-left: 5px;
    font-weight: 400;
    color: ${colors.black};
    font-size: 10px;
    transition: transform 0.2s ease-in-out;
  `,

  flipped: css`
    transform: rotate(180deg);
  `,
};

export const picker = css`
  ${panels.base}
  box-shadow: 0px 1.2px 3.6px rgba(0, 20, 90, 0.1), 0px 6.4px 14.4px rgba(0, 50, 97, 0.13);
  width: 346px;
  background: ${colors.white};
  position: absolute;
  top: 20px;
  z-index: 2;
`;

export const dropdown = css`
  position: static;
  box-shadow: none;

  .react-picker-option {
    padding-left: 10px;
    padding-right: 5px;

    &.disabled {
      cursor: default;
      color: ${colors.lightGrey};
    }
  }

  .react-items-wrapper {
    padding-right: 0;
  }
`;
