/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { UploadWindowItem } from './../UploadWindowItem';

import * as styles from './UploadWindowItemList.styles';

const UploadWindowItemList = memo(({ show, items }) => {
  const list = items.map((item) => {
    const { file, xhr, directory, progress, state, error } = item;

    const key = `upload${item.id}`;
    const path = `${directory?.name}/${file.path}`;

    return (
      <UploadWindowItem
        id={item.id}
        key={key}
        path={path}
        state={state}
        name={file.name}
        size={file.size}
        error={error}
        progress={progress}
        xhr={xhr}
      />
    );
  });

  const containerStyles = [styles.container.base, show && styles.container.visible];

  return <div css={containerStyles}>{list}</div>;
});

export { UploadWindowItemList };
