import { screens } from '../theme.config.js';

const screenSizes = {
  xs: '380px',
  ...screens,
};

export const getBreakpointValue = (value) => parseInt(screenSizes[value].slice(0, screenSizes[value].indexOf('px')));

export const getCurrentBreakpoint = (width) => {
  let currentBreakpoint;
  let biggestBreakpointValue = 0;
  for (const breakpoint of Object.keys(screenSizes)) {
    const breakpointValue = getBreakpointValue(breakpoint);
    if (breakpointValue > biggestBreakpointValue && width >= breakpointValue) {
      biggestBreakpointValue = breakpointValue;
      currentBreakpoint = breakpoint;
    }
  }
  return currentBreakpoint;
};
