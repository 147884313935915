/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import Divider from 'components/v2/shared/Divider';
import ContentToggle from 'components/v2/shared/ContentToggle';
import Label from 'components/v2/shared/Label';
import Text from 'components/v2/shared/Text';
import TextInput from 'components/v2/shared/TextInput';
import Checkbox from 'components/v2/shared/Checkbox';
import TextAreaInput from 'components/v2/shared/TextAreaInput';

import { useMeetingConfigContext } from 'contexts/v2/MeetingConfigContext';

import * as styles from './ConferenceForm.styles';

const ConferenceForm = ({ className, errors }) => {
  const { edits, updateEdits } = useMeetingConfigContext();

  const [hasPasscode, setHasPasscode] = useState(false);

  const toggleHasPasscode = () => setHasPasscode((state) => !state);

  const handleConferenceConfigChange = (value) => {
    const targetData = value.target;

    updateEdits(targetData.name, targetData.value);
  };

  return (
    <Row noGutters className={className}>
      <Col lg={12}>
        <Divider position='left' isBold title='Other conference link' />
      </Col>

      <Col lg={12} className='mt-4'>
        <TextInput
          label='Meeting invitation link'
          name='meetingUrl'
          value={edits.meetingUrl}
          onChange={handleConferenceConfigChange}
          errorMessage={errors.meetingUrl}
        />
      </Col>

      <Col lg={12} className='mt-4'>
        <Label text='Passcode' />
      </Col>

      <Col lg={12}>
        <Row className='d-flex align-items-center'>
          <Col lg={2}>
            <Checkbox label='Yes' isChecked={hasPasscode} onChange={toggleHasPasscode} />
          </Col>

          <Col lg={5}>
            <TextInput
              name='passcode'
              value={edits.passcode}
              placeholder='Passcode'
              isDisabled={!hasPasscode}
              onChange={handleConferenceConfigChange}
            />
          </Col>

          <Col lg={5}>
            <Text
              fontSize={12}
              color='lightGrey'
              text='Only users who have the invite link or passcode can join the meeting'
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ConferenceForm.propTypes = {
  className: PropTypes.string,
};

export default ConferenceForm;
