import { css } from '@emotion/core';
import { tables, fonts, colors, media } from 'styles';

export const tableRow = (isLast, isCompleted) => css`
  ${tables.tableRow(isLast)}
  background: ${isCompleted ? colors.lightCyan : colors.transparent};
`;

export const tableHeader = (width) => css`
  ${tables.tableHeader(width)}
  padding: 0;
`;

export const checkmark = (checked, isActive) => css`
  background: ${checked ? colors.cyan : colors.white};
  z-index: 2;
  border: 1px solid;
  border-color: ${checked ? 'transparent' : colors.lighterGrey};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${isActive &&
  `
    &:hover {
      cursor: pointer;
      border-color: ${colors.cyan};
    }
  `}
`;

export const table = css`
  ${tables.table}
  padding: 0 50px;
  width: 100%;
`;

export const completed = css`
  color: ${colors.cyan};
  letter-spacing: 0.1em;
`;

export const tableCell = css`
  ${tables.tableCell}
  padding: 12px 5px;
  border-bottom: 1px solid ${colors.separator};
  font-size: 16px;

  &:last-of-type {
    padding-right: 50px;
  }

  &:first-of-type {
    padding-left: 50px;
  }
`;

export const deleteIcon = css`
  color: ${colors.lightGrey};

  &:hover {
    cursor: pointer;
    color: ${colors.cyan};
  }
`;

export const tableHeading = css`
  ${fonts.bold}
  font-size: 22px;
  padding: 25px 19px 25px 52px;
  margin: 0;
  ${media.tablet} {
    padding-left: 15px;
  }
`;

export const title = (isCompleted) => css`
  position: relative;
  color: ${isCompleted ? colors.cyan : colors.purple};

  &:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 2px;
    height: 1px;
    display: block;
    width: 100%;
    border-bottom: 1px solid ${isCompleted ? colors.cyan : colors.purple};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const taskItem_mobile = css`
  padding: 20px 15px;
  background: ${colors.white};
  box-shadow: 2px 4px 4px rgba(223, 223, 223, 0.25);
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const taskItem_body_mobile = css`
  flex-grow: 1;
  padding-right: 10px;
`;

export const taskItem_title_mobile = css`
  color: ${colors.black};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
`;
