import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useGQLDataContext } from './GQLDataContext';

const DEFAULT_ROLE_LABEL = 'Observer';

const initialState = {
  data: [],
  defaultRole: null,
};

const actionTypes = {
  SET_DATA: 'AccessRolesContext.setData',
};

const AccessRolesContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_DATA:
      return { ...state, data: payload.data, defaultRole: payload.defaultRole };
  }
};

export const AccessRolesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { gqlData, getDataByPath } = useGQLDataContext();

  const format = ({ name, id }) => ({ label: name, value: id });

  useEffect(() => {
    if (gqlData) {
      const data = getDataByPath('currentCompany.accessRoles', []).map(format);
      const defaultRole = data.find(({ label }) => label === DEFAULT_ROLE_LABEL);
      dispatch({ type: actionTypes.SET_DATA, payload: { data, defaultRole } });
    }
  }, [gqlData]);

  const { data, defaultRole } = state;

  return (
    <AccessRolesContext.Provider value={{ accessRoles: data, defaultRole }}>{children}</AccessRolesContext.Provider>
  );
};

AccessRolesContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useAccessRolesContext = () => useContext(AccessRolesContext);
