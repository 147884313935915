export const STEPS = [
  'Meeting details',
  'How to Attend',
  'Notice & Invite',
  'Attendees',
  'Tasks',
  'Voting',
  'Agenda',
  'Overview',
];

export const LONG_DATE_FORMAT = 'dddd, MMMM Do, YYYY';
export const TIME_FORMAT = 'hh:mm A';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DAY_FORMAT = 'dddd';
