import { css } from '@emotion/core';
import { tables, fonts, colors, media } from 'styles';

export const table = tables.table;
export const tableRow = tables.tableRow;

export const tableContainer = css`
  max-height: 375px;
  overflow: auto;

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  table thead th {
    position: sticky;
    top: 0;
    background: ${colors.white};
    padding: 10px;
    border-bottom: 1px solid ${colors.lightGrey} !important;
  }

  td {
    padding: 5px 10px;
    ${media.tablet} {
      padding: 10px 3px !important;
    }
  }
`;

export const tableHeader = (width) => css`
  ${tables.tableHeader(width)}
`;

export const capitalize = css`
  text-transform: capitalize;
`;

export const resolutions = css`
  width: 100%;
  max-width: 850px;
  border-radius: 4px;

  .resolution-wrapper {
    position: relative;
    border-bottom: 1px solid ${colors.grey} !important;
    padding: 30px 20px;
    ${media.mobile} {
      padding-left: 0;
      padding-right: 0;
      border: none;
      background-color: #f8f8f9;
    }
  }

  .answer-option {
    ${fonts.bold};
    border: 1px solid ${colors.grey} !important;
    height: 43px;
    padding: 10px 10px 10px 15px;
    border-radius: 2px;
    font-size: 20px;
    color: ${colors.black};
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.selected {
      border: 1px solid ${colors.cyan} !important;
      background: ${colors.lightCyan};

      .checkmark {
        display: flex !important;
        position: relative;
        margin-right: 10px;
      }
    }
  }

  .vote-poll {
    padding: 45px;
  }
`;

export const buttonContainer = css`
  width: 100%;
  max-width: 850px;
`;

export const countNumber = css`
  position: absolute;
  top: 40px;
  left: 20px;
  ${media.mobile} {
    left: 0;
    color: ${colors.linkWater} !important;
  }
`;

export const resolutionContent = css`
  width: 100%;
`;

export const resolutionDescription = css`
  padding-left: 60px;
  line-height: 20px;
  font-size: 12px;
  ${media.mobile} {
    padding-left: 40px;
    font-size: 14px;
  }
`;

export const resolutionPoll = css`
  padding-left: 60px;
  padding-right: 12px;
  ${media.mobile} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const voteWarning = css`
  display: flex;
  background: ${colors.lightOrange};
  border: 1px solid ${colors.alertOrange};
  border-radius: 2px;
  padding: 5px 30px;
  margin: 45px 45px 20px 45px;
  align-items: center;
  svg {
    margin: 10px 15px;
    min-width: 23px;
  }

  @media (max-width: 767px) {
    margin: 25px 8px 20px 8px;
    padding: 0;
  }
`;

export const contactsHeaderItem = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 10px;
  ${media.tablet} {
    padding: 10px 3px !important;
  }
`;
