import { gql, useQuery } from '@apollo/client';
import { SUBCOMMITTEE_FRAGMENT } from '../fragments/SubcommitteeFragment';
import { ACCESS_ROLE_FRAGMENT } from 'data/v2/fragments/AccessRoleFragment';

const SUBCOMMITTEES_QUERY = gql`
  query subcommittees {
    currentCompany {
      id

      accessRoles {
        ...AccessRoleFields
      }

      contacts {
        id
        name
        email
        initials
        status
        accessRoleId
        companyPosition
        contactRole
      }

      subcommittees {
        ...SubcommitteeFields
      }
    }
  }
  ${SUBCOMMITTEE_FRAGMENT}
  ${ACCESS_ROLE_FRAGMENT}
`;

const useSubcommitteesQuery = (options) => useQuery(SUBCOMMITTEES_QUERY, options);

export { SUBCOMMITTEES_QUERY, useSubcommitteesQuery };
