import { useCallback } from 'react';

const useVoteContacts = ({ vote, voteParams, updateVoteParams, contacts }) => {
  const contactExists = useCallback(
    (contact) => {
      voteParams.contactIds.find((item) => item == contact.id);
    },
    [voteParams.contactIds]
  );

  const addContact = useCallback(
    (contact) => {
      updateVoteParams('contactIds', Array.from(new Set([...voteParams.contactIds, contact.id])));
    },
    [voteParams.contactIds]
  );

  const removeContact = useCallback(
    (contact) => {
      updateVoteParams(
        'contactIds',
        voteParams.contactIds.filter((id) => id !== contact.id)
      );
    },
    [voteParams.contactIds]
  );

  const addCollaborator = useCallback(
    (email) => {
      updateVoteParams('collaboratorEmails', Array.from(new Set([...voteParams.collaboratorEmails, email])));
    },
    [voteParams.collaboratorEmails]
  );

  const removeCollaborator = useCallback(
    (email) => {
      updateVoteParams(
        'collaboratorEmails',
        voteParams.collaboratorEmails.filter((collaboratorEmail) => collaboratorEmail !== email)
      );
    },
    [voteParams.collaboratorEmails]
  );

  const toggleContact = useCallback(
    (contact) => {
      if (contactExists(contact)) {
        removeContact(contact);
      } else {
        addContact(contact);
      }
    },
    [voteParams.contactIds]
  );

  const contactWithEmail = (email) => {
    return contacts?.find((contact) => contact.email === email);
  };

  const importEmails = (inputEmails) => {
    if (!inputEmails) return;

    inputEmails.map((email) => {
      if (contactWithEmail(email)) {
        addContact(contactWithEmail(email));
      } else {
        addCollaborator(email);
      }
    });
  };

  return {
    contactExists,
    addContact,
    removeContact,
    addCollaborator,
    removeCollaborator,
    toggleContact,
    importEmails,
  };
};

export { useVoteContacts };
