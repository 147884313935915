/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';

import { ReactComponent as Icon } from 'images/icons/help-text.svg';

import * as styles from './HelpText.styles';

const HelpText = memo(({ show, title, instructions }) => {
  return (
    show && (
      <div css={styles.wrapper}>
        <Icon css={styles.icon} />

        <div css={styles.flex}>
          <div css={styles.title}>{title}</div>

          <div css={styles.instructions}>{instructions}</div>
        </div>
      </div>
    )
  );
});

export { HelpText };
