/** @jsx jsx */

import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { TextField } from 'components/shared/Input/TextField';

import * as styles from './TimePicker.styles';

const TimePicker = memo(({ onChange, time, inputName, isDisabled, pickerName, isInvalid }) => {
  const formatMoment = (time, format) => moment(time).parseZone().format(format);

  const formattedTimePeriod = formatMoment(time, 'A');
  const formattedHours = parseInt(formatMoment(time, 'hh'));
  const formattedMinutes = parseInt(formatMoment(time, 'mm'));

  let [timePeriod, setTimePeriod] = useState(formattedTimePeriod);
  let [hours, setHours] = useState(formattedHours);
  let [minutes, setMinutes] = useState(formattedMinutes);

  useEffect(() => {
    setTimePeriod(formattedTimePeriod);
    setHours(formattedHours);
    setMinutes(formattedMinutes);
  }, [time]);

  useEffect(() => {
    if (onChange) {
      var dateTime = moment(getTimeString(), 'hh:mm A');

      onChange(dateTime);
    }
  }, [hours, minutes, timePeriod]);

  let [isEditingHours, setIsEditingHours] = React.useState(false);
  let [isEditingMinutes, setIsEditingMinutes] = React.useState(false);

  const timePeriodClass = (selectedTimePeriod) => {
    if (timePeriod === selectedTimePeriod) return 'active';
  };

  const formatTime = (value) => {
    return value > 9 ? value : `0${value}`;
  };

  const getTimeString = () => {
    return `${formatTime(hours)}:${formatTime(minutes)} ${timePeriod}`;
  };

  const addMinutes = (value) => {
    if (isDisabled) {
      return;
    }

    if (isNaN(value)) setMinutes('');
    else setMinutes(value);
  };

  const addHours = (value) => {
    if (isDisabled) {
      return;
    }

    if (isNaN(value)) setHours('');
    else setHours(value);
  };

  const normalizeHours = (hoursValue) => {
    if (hoursValue === 0) {
      return 12;
    } else if (hoursValue === 13) {
      return 1;
    } else {
      return hoursValue;
    }
  };

  const normalizeMinutes = (minutesValue) => {
    if (minutesValue >= 60) {
      return minutesValue - 60;
    } else if (minutesValue < 0) {
      return minutesValue + 60;
    } else {
      return minutesValue;
    }
  };

  const onBlurHours = () => {
    setHours(normalizeHours(hours));

    setIsEditingHours(!isEditingHours);
  };

  const onBlurMinutes = () => {
    if (minutes == '') setMinutes(0);

    setIsEditingMinutes(!isEditingMinutes);
  };

  return (
    <React.Fragment>
      <div className='shaparency-time-picker d-flex align-items-center flex-wrap' name={pickerName}>
        <input type='hidden' name={inputName} value={getTimeString()} />

        <div className='wrapper hours text-center m-r-5'>
          <div className='d-block'>
            <i onClick={() => (isDisabled ? null : addHours(normalizeHours(hours + 1)))} className='fa fa-chevron-up' />
          </div>

          {isEditingHours ? (
            <TextField
              value={formatTime(hours)}
              isDisabled={isDisabled}
              isInvalid={isInvalid}
              onChange={(e) => addHours(parseInt(e.target.value))}
              onBlur={() => onBlurHours()}
            />
          ) : (
            <TextField
              value={formatTime(hours)}
              isDisabled={isDisabled}
              isInvalid={isInvalid}
              onFocus={() => setIsEditingHours(!isEditingHours)}
              isReadOnly
            />
          )}

          <div className='d-block'>
            <i
              onClick={() => (isDisabled ? null : addHours(normalizeHours(hours - 1)))}
              className='fa fa-chevron-down'
            />
          </div>
        </div>

        <span className='font-weight-bold' css={styles.timeDelimeter}>
          :
        </span>

        <div className='wrapper hours text-center m-l-5'>
          <div className='d-block'>
            <i
              onClick={() => (isDisabled ? null : addMinutes(normalizeMinutes(minutes + 15)))}
              className='fa fa-chevron-up'
            />
          </div>

          {isEditingMinutes ? (
            <TextField
              value={formatTime(minutes)}
              isDisabled={isDisabled}
              isInvalid={isInvalid}
              onChange={(e) => addMinutes(parseInt(e.target.value))}
              onBlur={() => onBlurMinutes()}
            />
          ) : (
            <TextField
              type='text'
              isInvalid={isInvalid}
              isDisabled={isDisabled}
              value={formatTime(minutes)}
              onFocus={() => setIsEditingMinutes(!isEditingMinutes)}
              isReadOnly
            />
          )}

          <div className='d-block'>
            <i
              onClick={() => (isDisabled ? null : addMinutes(normalizeMinutes(minutes - 15)))}
              className='fa fa-chevron-down'
            />
          </div>
        </div>

        <div className='controls m-l-10'>
          <div
            onClick={() => (isDisabled ? null : setTimePeriod('AM'))}
            className={`d-block text-center ${timePeriodClass('AM')}`}
          >
            <FormattedMessage id='TimePicker.am' />
          </div>
          <div
            onClick={() => (isDisabled ? null : setTimePeriod('PM'))}
            className={`m-t-5 d-block text-center ${timePeriodClass('PM')}`}
          >
            <FormattedMessage id='TimePicker.pm' />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

TimePicker.propTypes = {
  hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timePeriod: PropTypes.string,
};

export default TimePicker;
