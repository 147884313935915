/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useMeetingManager,
  useToggleLocalMute,
  useSelectAudioOutputDevice,
  useVideoInputs,
  useAudioVideo,
  useSelectVideoInputDevice,
  useSelectAudioInputDevice,
  useAudioOutputs,
  useAudioInputs,
  ControlBar,
  ControlBarButton,
  useLocalVideo,
  useContentShareControls,
  useLocalAudioOutput,
  Microphone,
  Camera,
  Phone,
  Sound,
  Laptop,
} from 'amazon-chime-sdk-component-library-react';

import * as styles from './index.styles';

const BarButton = ({ props }) => <ControlBarButton css={styles.controlBarButton} {...props} />;

export const ChimeControlBar = () => {
  const meetingManager = useMeetingManager();
  const { muted, toggleMute } = useToggleLocalMute();
  const { isVideoEnabled, setIsVideoEnabled, toggleVideo } = useLocalVideo();
  const { isAudioOn, toggleAudio } = useLocalAudioOutput();
  const selectAudioInput = useSelectAudioInputDevice();
  const selectAudioOutput = useSelectAudioOutputDevice();
  const audioVideo = useAudioVideo();
  const { toggleContentShare } = useContentShareControls();
  const selectVideoInput = useSelectVideoInputDevice();
  const { devices: audioDevices, selectedDevice: selectedAudioDevice } = useAudioInputs();
  const { devices: videoDevices, selectedDevice: selectedVideoDevice } = useVideoInputs();
  const { devices: audioDevicesOutput, selectedDevice: selectedAudioOutputDevice } = useAudioOutputs();

  const intl = useIntl();

  const handleLeaveMeeting = () => meetingManager.leave();

  const handleSelectVideoDevice = (deviceId) => {
    if (!audioVideo) {
      return;
    }
    selectVideoInput(deviceId);
    if (deviceId !== 'none' && !isVideoEnabled) {
      audioVideo?.startLocalVideoTile();
      setIsVideoEnabled(true);
    }
  };

  const audioPopOver = () => {
    return audioDevices.reduce((acc, device) => {
      const { deviceId, label } = device;
      const newDevice = {
        onClick: () => selectAudioInput(deviceId),
        children: <span>{label}</span>,
        checked: deviceId === selectedAudioDevice,
      };
      return [newDevice, ...acc];
    }, []);
  };

  const audioOutputPopOver = () => {
    return audioDevicesOutput.reduce((acc, device) => {
      const { deviceId, label } = device;
      const newDevice = {
        onClick: () => selectAudioOutput(deviceId),
        children: <span>{label}</span>,
        checked: deviceId === selectedAudioOutputDevice,
      };
      return [newDevice, ...acc];
    }, []);
  };

  const microphoneButtonProps = {
    icon: muted ? <Microphone muted /> : <Microphone />,
    onClick: () => toggleMute(),
    popOver: audioPopOver(),
    label: intl.formatMessage({ id: 'ChimeControlBar.mute' }),
  };

  const videoPopOver = () => {
    return videoDevices.reduce((acc, device) => {
      const { deviceId, label } = device;
      const newDevice = {
        onClick: () => handleSelectVideoDevice(deviceId),
        children: <span>{label}</span>,
        checked: deviceId === selectedVideoDevice,
      };
      return [newDevice, ...acc];
    }, []);
  };

  const cameraButtonProps = {
    icon: isVideoEnabled ? <Camera /> : <Camera disabled />,
    popOver: videoPopOver(),
    onClick: () => {
      setIsVideoEnabled(isVideoEnabled);
      toggleVideo();
    },
    label: intl.formatMessage({ id: 'ChimeControlBar.camera' }),
  };

  const volumeButtonProps = {
    icon: isAudioOn ? <Sound /> : <Sound disabled />,
    onClick: () => toggleAudio(),
    popOver: audioOutputPopOver(),
    label: intl.formatMessage({ id: 'ChimeControlBar.volume' }),
  };

  const hangUpButtonProps = {
    icon: <Phone />,
    onClick: () => handleLeaveMeeting(),
    label: intl.formatMessage({ id: 'ChimeControlBar.end' }),
  };

  const laptopButtonProps = {
    icon: <Laptop />,
    onClick: () => toggleContentShare(),
    label: intl.formatMessage({ id: 'ChimeControlBar.screen' }),
  };

  return (
    <ControlBar showLabels layout='bottom' responsive={true} css={styles.controlBar}>
      <BarButton props={microphoneButtonProps} />
      <BarButton props={volumeButtonProps} />
      <BarButton props={cameraButtonProps} />
      <BarButton props={laptopButtonProps} />
      <BarButton props={hangUpButtonProps} />
      <div className='m-l-5 badge badge-soft-draft' css={styles.betaBadge}>
        <FormattedMessage id='ChimeControlBar.beta' />
      </div>
    </ControlBar>
  );
};
