/** @jsx jsx */

import React, { memo, useRef, useCallback, useState } from 'react';
import { Quill } from 'react-quill';
import { Row, Col } from 'react-bootstrap';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { OoEActions } from './../OoEActions';
import { Editor } from './../Editor';
import { ReviewSection } from './../ReviewSection';
import { formatDuration } from 'helpers/formatDuration';
import { useOoEStore, useOoEActions } from 'store/ooeStoreContext';
import { useAgendasStore } from 'store/agendasStoreContext';
import { useFlashActions } from 'store/flashStoreContext';

import * as styles from './OoEStep.styles';

const OoEStep = memo(({ minutes, isPseudoMeeting, currentContact, context, onBack }) => {
  const Delta = Quill.import('delta');
  const delta = new Delta();

  const intl = useIntl();

  const [change, setChange] = useState(delta);

  const { agendas } = useAgendasStore();
  const { ooe } = useOoEStore();
  const { editOoE, updateOoE } = useOoEActions();
  const { setFlash } = useFlashActions();

  const handleSaveOoEClick = () => {
    return updateOoE(minutes.id, { description: ooe.description });
  };

  const handleOoEChange = useCallback(
    (content, delta) => {
      const contentChange = change.compose(delta);

      editOoE({ description: content });
      setChange(contentChange);
    },
    [change]
  );

  const getAgendaText = (agenda) => {
    const spaces = ' '.repeat(4 * agenda.depth);
    let text = `${agenda.number} ${agenda.heading}`;

    if (agenda.assignee_id) {
      text += intl.formatMessage({ id: 'OoEStep.assignedTo' }, { name: agenda.assignee_name });
    }

    if (agenda.duration_in_minutes > 0) {
      const duration = formatDuration(agenda.duration_in_minutes);
      text += ` (${duration})`;
    }

    return `${spaces}${text}\n`;
  };

  const sortByPosition = (a, b) => {
    return a.position - b.position;
  };

  const insertAgendaHeading = (agenda, state, editor) => {
    const children = agendas.filter((item) => item.parent_id === agenda.id).sort(sortByPosition);
    const text = getAgendaText(agenda);

    editor.insertText(state.index, text, 'header', 2, true);
    state.index = state.index + text.length;

    children.map((item) => insertAgendaHeading(item, state, editor));
  };

  const copyFromAgenda = () => {
    const editor = quillRef.current.getEditor();
    const roots = agendas.filter((item) => item.root).sort(sortByPosition);
    const state = { index: 0 };

    roots.map((item) => insertAgendaHeading(item, state, editor));
    editor.insertText(state.index, '\n\n', 'header', 0, true);

    editor.blur();
  };

  const quillRef = useRef();

  const renderStepBody = () => {
    const isInReview = minutes.status === 'in_review';

    if (isInReview) {
      return (
        <ReviewSection
          meetingId={minutes.meeting_id}
          minuteId={minutes.id}
          minuteOwnerId={minutes.contact_id}
          quillRef={quillRef}
          currentContact={currentContact}
          value={ooe?.description}
          onTextChange={handleOoEChange}
        />
      );
    } else {
      return (
        <Col md={12} css={styles.outline}>
          <Editor ref={quillRef} value={ooe?.description} onChange={handleOoEChange} />
        </Col>
      );
    }
  };

  const showCopyFromAgenda = agendas.length > 0 && !isPseudoMeeting;

  return (
    <div css={context == 'review' ? '' : styles.padded} className='p-t-35'>
      <Row noGutters>
        <Col md={12}>
          <OoEActions
            onCopyFromAgenda={copyFromAgenda}
            showCopyFromAgenda={showCopyFromAgenda}
            onSave={handleSaveOoEClick}
            onBack={onBack}
          />
        </Col>

        {renderStepBody()}
      </Row>
    </div>
  );
});

export { OoEStep };
