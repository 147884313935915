import { gql, useMutation } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';

const INCREMENT_DIRECTORY_NAME = gql`
  mutation incrementDirectoryName($input: IncrementDirectoryNameInput!) {
    incrementDirectoryName(input: $input) {
      directory {
        ...DirectoryFields

        parent {
          id
          hasChildren
        }
      }

      errors {
        attribute
        fullMessage
      }
    }
  }
  ${DIRECTORY_FRAGMENT}
`;

const useIncrementDirectoryNameMutation = (options) => useMutation(INCREMENT_DIRECTORY_NAME, options);

export { useIncrementDirectoryNameMutation };
