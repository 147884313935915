const getStripeFontOptions = (fontName = 'Gilroy-Regular') => {
  const fontPath = require(`./../../assets/fonts/${fontName}.woff`);

  return {
    fonts: [
      {
        src: `url(${fontPath})`,
        style: 'normal',
        family: fontName,
      },
    ],
  };
};

export { getStripeFontOptions };
