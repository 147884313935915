/** @jsx jsx */

import React, { memo, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { CommentActions } from './../CommentActions';
import { CommentHeader } from './../CommentHeader';
import { CommentTarget } from './../CommentTarget';
import { Expandable } from 'components/shared/Expandable';

import { ReactComponent as ReplyIcon } from 'images/icons/reply-icon.svg';

import * as styles from './Comment.styles';

const RECENT_THRESHOLD = 1;

const Comment = memo(
  ({
    minuteId,
    minuteOwnerId,
    currentContactId,
    comment,
    replies,
    isRoot,
    isHighlighted,
    isReadOnly,
    onReply,
    onEdit,
  }) => {
    if (!comment) {
      return null;
    }

    const { body, author, created_at, resolved } = comment;
    const { attributes: contact } = author.data;

    const handleReply = onReply && onReply.bind(this, comment);

    const replyButton = onReply && (
      <div
        css={isReadOnly ? styles.reply.disabledButton : styles.reply.button}
        onClick={isReadOnly ? undefined : handleReply}
      >
        <div className='d-flex align-items-center'>
          <div css={isReadOnly ? styles.reply.disabledText : styles.reply.text}>Reply</div>
          <ReplyIcon />
        </div>
      </div>
    );

    const threadReplyCount = isHighlighted && replies.length > 0 && (
      <div css={styles.replyCount.thread}>
        <FormattedMessage id='Comment.commentCount' values={{ count: replies.length }} />
      </div>
    );

    const commentOverlay = resolved && <div css={styles.overlay} />;

    const createdAt = moment(comment.created_at).parseZone();
    const recentThreshold = moment().parseZone().subtract(RECENT_THRESHOLD, 'minutes');
    const isCurrentContactComment = currentContactId === comment.author.data.id;
    const isCreatedRecently = createdAt.isAfter(recentThreshold);
    const recentReplyCount =
      replies && replies.filter((item) => moment(item.created_at).parseZone().isAfter(recentThreshold)).length;

    const [isRecent, setIsRecent] = useState(!isCurrentContactComment && isCreatedRecently);

    const listReplyCount = isRoot && replies.length > 0 && (
      <div css={styles.replyCount.list} onClick={handleReply}>
        {recentReplyCount > 0 && <div css={styles.badge} />}
        <FormattedMessage id='Comment.commentCount' values={{ count: replies.length }} />
      </div>
    );

    useEffect(() => {
      if (!isRecent) return;

      const timeLeft = createdAt.add(RECENT_THRESHOLD, 'minutes').diff(moment().parseZone());

      if (timeLeft <= 0) return;

      const timer = setTimeout(() => setIsRecent(false), timeLeft);

      return () => clearTimeout(timer);
    }, []);

    const commentStyles = [
      styles.comment.base,
      isRecent && styles.comment.recent,
      isHighlighted && styles.comment.root,
    ];

    return (
      <div css={commentStyles}>
        {commentOverlay}

        <CommentHeader contact={contact} comment={comment}>
          {!isReadOnly && (
            <CommentActions
              minuteId={minuteId}
              minuteOwnerId={minuteOwnerId}
              currentContactId={currentContactId}
              comment={comment}
              onEdit={onEdit}
            />
          )}
        </CommentHeader>

        <Expandable isHighlighted={isHighlighted}>
          <CommentTarget target={comment.relatable} images={comment.data.images} />
          {body}
        </Expandable>

        {threadReplyCount}

        <div css={styles.footer}>
          {replyButton}
          {listReplyCount}
        </div>
      </div>
    );
  }
);

export { Comment };
