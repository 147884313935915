import {
  CREATE_RESOLUTION_DOCUMENT_MUTATION,
  useCreateResolutionDocumentMutation,
} from './../data/mutations/CreateResolutionDocumentMutation';
import { formatBytes } from 'helpers/formatBytes';
import { DirectUpload } from '@rails/activestorage';

const MAX_SIZE = 104857600;

const useCreateResolutionDocument = ({ onUpload, onResolutionDocumentUpload, onError }) => {
  const update = (cache, { data: { createResolutionDocument } }) => {
    const { vote, errors } = createResolutionDocument;

    if (errors.length) {
      return onError(errors[0].message);
    }

    onUpload();
    onResolutionDocumentUpload(vote);
  };

  const [mutate] = useCreateResolutionDocumentMutation({ update });

  const validate = (attachment) => {
    if (attachment.size > MAX_SIZE) {
      onError(`File size should be less than ${formatBytes(MAX_SIZE, 0)}`);
      return false;
    }

    return true;
  };

  const createResolutionDocument = (params, updateCurrentUpload) => {
    const isValid = validate(params.attachment);

    if (!isValid) return;

    const uploadConfig = {
      directUploadWillStoreFileWithXHR: (xhr) => {
        updateCurrentUpload({ xhr });

        xhr.upload.addEventListener('progress', (event) => {
          const progress = Math.round((event.loaded * 100) / event.total);
          updateCurrentUpload({ progress });
        });
      },
    };

    const upload = new DirectUpload(params.attachment, '/rails/active_storage/direct_uploads', uploadConfig);

    upload.create((error, blob) => {
      if (error) {
        return onError(error);
      }

      const input = {
        resolutionId: params.resolutionId,
        attachment: blob.signed_id,
      };

      mutate({ variables: { input } });
    });
  };

  return { createResolutionDocument };
};

export { useCreateResolutionDocument };
