import { gql, useMutation } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';

const CREATE_DIRECTORY = gql`
  mutation createDirectory($input: CreateDirectoryInput!) {
    createDirectory(input: $input) {
      directory {
        ...DirectoryFields

        parent {
          id
          hasChildren
        }
      }

      errors {
        attribute
        message
      }
    }
  }
  ${DIRECTORY_FRAGMENT}
`;

const useCreateDirectoryMutation = (options) => useMutation(CREATE_DIRECTORY, options);

export { useCreateDirectoryMutation };
