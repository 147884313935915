import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Alert from 'components/v2/shared/Alert';

export const notify = (severity, message, duration = 5000) => {
  toast(
    <Alert width='large' description={message} isDismissable onDismiss={() => toast.dismiss()} severity={severity} />,
    {
      duration: duration,
      position: 'top-center',
      className: '!tw-p-0 !tw-m-0 !tw-max-w-none',
      ariaProps: {
        className: '!tw-p-0 !tw-m-0',
      },
    }
  );
};

const Notification = () => {
  return <Toaster />;
};

export default Notification;
