import { gql, useMutation } from '@apollo/client';
import { ZOOM_MEETING_FRAGMENT } from 'data/v2/fragments/ZoomMeetingFragment';

const UPDATE_MEETING_CONFIG_MUTATION = gql`
  mutation updateMeetingConfig($input: UpdateMeetingConfigInput!) {
    updateMeetingConfig(input: $input) {
      meeting {
        id
        config
        meetingUrl
        passcode
        location
        locationNotes

        zoomMeeting {
          ...ZoomMeetingFields
        }
      }

      errors {
        attribute
        message
      }
    }
  }
  ${ZOOM_MEETING_FRAGMENT}
`;

const useUpdateMeetingConfigMutation = (options) => useMutation(UPDATE_MEETING_CONFIG_MUTATION, options);

export { UPDATE_MEETING_CONFIG_MUTATION, useUpdateMeetingConfigMutation };
