/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { useHover } from 'hooks/useHover';

import * as styles from './Tooltip.styles';

const Tooltip = memo(({ message }) => {
  const [hoverableRef, isHovered] = useHover();

  const containerStyles = styles.container(isHovered);
  const wrapperStyles = styles.wrapper(isHovered);

  return (
    <div ref={hoverableRef} css={wrapperStyles}>
      <i className='fas fa-exclamation-circle tooltip-icon' />

      <div css={containerStyles}>
        <div css={styles.message}>{message}</div>

        <div css={styles.triangle} />
      </div>
    </div>
  );
});

export { Tooltip };
