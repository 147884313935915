/** @jsx jsx */

import React, { forwardRef, memo, useState } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Label } from './../Input/Label';
import { Error } from './../Input/Error';

import { position } from 'styles';

import * as styles from './TextAreaInput.styles';

const TextAreaInput = memo(
  forwardRef(
    (
      {
        id,
        name,
        label,
        placeholder,
        isRequired,
        isDisabled,
        isReadOnly,
        errorMessage,
        value,
        onChange,
        onBlur,
        customWrapperStyles,
        customInputStyles,
        ...props
      },
      ref
    ) => {
      let [isFocused, setIsFocused] = useState(false);

      const handleFocus = () => setIsFocused(true);
      const handleBlur = () => {
        onBlur && onBlur();

        return setIsFocused(false);
      };

      const wrapperStyles = [
        styles.wrapper.base,
        isDisabled && styles.wrapper.state.disabled,
        isFocused && styles.wrapper.state.focused,
        errorMessage && styles.wrapper.state.invalid,
        customWrapperStyles,
      ];

      const applicableStyles = [
        styles.textarea.base,
        isDisabled && styles.textarea.state.disabled,
        errorMessage && styles.textarea.state.invalid,
        customInputStyles,
      ];

      return (
        <div>
          <Label text={label} />

          <div css={position.relative}>
            <div css={wrapperStyles}>
              <textarea
                id={id}
                name={name}
                ref={ref}
                value={value}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={onChange}
                placeholder={placeholder}
                required={isRequired}
                disabled={isDisabled}
                readOnly={isReadOnly}
                css={applicableStyles}
                {...props}
              />
            </div>

            <Error message={errorMessage} />
          </div>
        </div>
      );
    }
  )
);

TextAreaInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export { TextAreaInput };
