import { css } from '@emotion/core';
import { media, fonts, panels, colors } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const tableWrapper = css`
  margin-bottom: 13px;
`;

export const tableHeading = css`
  ${fonts.bold}
  font-size: 14px;
  line-height: 32px;
  margin-left: 5px;
  color: ${colors.black};
`;

export const checkmarkIcon = css`
  position: absolute;
  top: -30px;
  transform: scale(1.5);

  path {
    stroke-width: 2px;
  }
`;

export const documentIcon = (isConfigured) => css`
  position: absolute;
  z-index: -1;
  display: flex;

  path {
    stroke: ${isConfigured ? colors.cyan : colors.black};
    fill: ${isConfigured ? HexToRgba(colors.cyan, 10) : colors.white};
  }
`;

export const panel = css`
  ${panels.base}
  padding: 18px 32px 18px 27px;
  min-height: 70vh;
  position: relative;

  ${media.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const heading = css`
  ${fonts.bold}
  font-size: 16px;
  line-height: 32px;
  color: ${colors.headingGrey};
`;

export const subHeading = css`
  ${fonts.regular}
  line-height: 20px;
  margin: 11px 0 26px 0;
  font-size: 14px;
  line-height: 20px;
  color: #2c2e3f;
`;

export const step = css`
  color: ${colors.black};
`;

export const configure = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -15%);
  text-align: center;

  ${media.tablet} {
    transform: translate(-50%, -30%);
  }
`;

export const document = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
`;

export const documentName = css`
  color: ${colors.black};
  max-width: 170px;
`;

export const drawer = (isOpen, isHide = false) => css`
  box-shadow: ${isOpen ? '-20px 4px 50px rgba(54, 71, 98, 0.25)' : 0};
  transform: translateX(${isOpen && !isHide ? 0 : '1077px'});
  width: 1077px;
  height: 100%;

  @media (max-width: 991px) {
    width: 275px;
  }
}
`;

export const placeholder = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
`;

export const placeholderCircle = css`
  position: relative;
  margin: 0 auto;
  background: ${colors.grey};
  width: 68px;
  height: 68px;
  border-radius: 50%;
`;

export const placeholderIcon = css`
  position: absolute;
  top: 7px;
  left: -8px;
`;

export const placeholderText = css`
  color: ${colors.mediumGrey};
  font-size: 12px;
  margin-top: 20px;
`;

export const viewport = css`
  height: calc(100vh - 40px);
  @media (max-width: 767px) {
    height: auto;
  }
`;
