import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FiChevronDown } from 'react-icons/fi';
import { Heights } from '../Menu/Menu';

export const Variants = {
  Default: 'default',
  Gray: 'gray',
};

const Toggle = forwardRef(
  ({ variant, isToggleBorder, size, children, isOpen, isInvalid, isDisabled, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={clsx('tw-box-border tw-flex tw-items-center', 'tw-rounded-sm', 'tw-px-5 ', {
        'tw-border tw-border-solid ': isToggleBorder,
        'tw-border-none': !isToggleBorder,
        'tw-bg-gray-200': variant === Variants.Gray,
        'tw-border-gray-500': isToggleBorder && size !== Heights.Large && (!isOpen || isDisabled),
        'tw-border-gray-700': isToggleBorder && size === Heights.Large && (!isOpen || isDisabled),
        'tw-cursor-not-allowed tw-opacity-30': isDisabled,
        'tw-cursor-pointer': !isDisabled,
        'tw-border-red-400': isToggleBorder && isInvalid && !isDisabled,
        'tw-border-teal-500': isToggleBorder && isOpen && !isInvalid && !isDisabled,
        'tw-py-4': size === Heights.Large,
        'tw-py-2.25': size !== Heights.Large,
      })}
    >
      {children}

      <FiChevronDown
        className={clsx('tw-ml-auto tw-h-6 tw-w-6 tw-stroke-1', {
          'tw-rotate-0': !isOpen,
          'tw-rotate-180': isOpen,
          'tw-text-black': !isOpen || isDisabled,
          'tw-text-teal-500': isOpen && !isInvalid && !isDisabled,
          'tw-text-red-400': isInvalid && !isDisabled,
        })}
      />
    </div>
  )
);

Toggle.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  isToggleBorder: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(Variants)),
};

export default Toggle;
