import axios from 'axios';

import { intl } from '../../../contexts/v2/TranslationContext';

export default class API {
  static handleError({ response: { data } }) {
    let message = data.message || intl.formatMessage({ id: 'ContactPicker.error' });

    displayFlashMessage(message, 'error');
  }

  static loadContacts(query, withPendingParam, withCalendarParam = false, onLoad) {
    const companyId = getCompanySlug();

    return axios
      .get(
        `/api/${companyId}/contacts.json?with_pending=${withPendingParam}&with_calendar=${withCalendarParam}&query=${query}`
      )
      .then((response) => response.data)
      .then(onLoad)
      .catch(this.handleError);
  }
}
