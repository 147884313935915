/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import Product from './Product';
import Invoices from './Invoices';
import PaymentMethods from './PaymentMethods';
import { AlertPopup } from '../shared/AlertPopup';
import { ReactComponent as CheckMarkIcon } from 'images/icons/checkmark-green.svg';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { userDateFormat } from '../../contexts/v2/DateFormatContext';

import * as styles from './Products.styles';

const ManageForm = ({
  subscription,
  currentProduct,
  company,
  setSubscriptionCancelled,
  setShowCancellationAlert,
  trial_ended,
}) => {
  let [showCancelPopup, setShowCancelPopup] = useState(false);
  const { isMobile } = useWindowDimensions();

  const intl = useIntl();

  const getConfig = () => {
    return {
      'X-CSRF-Token': $("[name='csrf-token']").attr('content'),
    };
  };

  const cancelSubscription = () => {
    $.ajax({
      url: `/${company.slug}/subscriptions/${subscription.id}/cancel`,
      method: 'put',
      headers: getConfig(),
    })
      .then((response) => {
        return response;
      })
      .then((cancelSubscriptionResponse) => {
        setSubscriptionCancelled(true);
        setShowCancellationAlert(true);

        if (!trial_ended) {
          window.location.assign(`/${company.slug}/subscription_gateway`);
        }
      });
  };

  return (
    <div className='p-6'>
      <div className='media-row'>
        <div>
          <div css={styles.activeProductBox} className='active-product-header'>
            <p css={styles.noProductText}>
              <FormattedMessage
                id='ManageForm.companyPlan'
                values={{ plan: <span className='c-dark font-weight-bold'>{currentProduct.name}</span> }}
              />
            </p>
            <div style={{ marginBottom: -60, display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Product
                key={currentProduct.id}
                product={currentProduct}
                period={
                  currentProduct.interval.includes('year')
                    ? intl.formatMessage({ id: 'ManageForm.yearly' })
                    : intl.formatMessage({ id: 'ManageForm.monthly' })
                }
                company={company}
                selected={true}
                handleClick={() => null}
                couponCode={subscription.promotion_code}
                isStandalone
              />
            </div>
          </div>
          <div className='text-center m-t-10 m-b-10 p-l-15 p-r-15 text-black fs-14'>
            {company.verified && (
              <span>
                <CheckMarkIcon />
                <FormattedMessage id='ManageForm.verified' />
              </span>
            )}
            <FormattedMessage
              id='ManageForm.nextBilled'
              values={{
                type: currentProduct.interval.includes('year')
                  ? intl.formatMessage({ id: 'ManageForm.yearly' })
                  : intl.formatMessage({ id: 'ManageForm.monthly' }),
                date: (
                  <span className='font-weight-semibold'>
                    {moment(subscription.deactivated_at).format(userDateFormat)}
                  </span>
                ),
              }}
            />
          </div>
          <div className='d-flex justify-content-center'>
            <button className='btn btn-primary btn-sm-fw w-auto' onClick={() => setShowCancelPopup(true)}>
              <FormattedMessage id='ManageForm.cancelPlan' />
            </button>
          </div>
        </div>
        <div className='p-l-15 p-r-15'>
          <Invoices company={company} key='invoices-table' />
        </div>
        <PaymentMethods company={company} />
      </div>
      <AlertPopup
        key='cancel-popup'
        show={showCancelPopup}
        headerText={intl.formatMessage({ id: 'ManageForm.alertTitle' })}
        popupType='info'
        onClose={() => setShowCancelPopup(false)}
      >
        <p>
          <FormattedMessage id='ManageForm.sure' />
        </p>
        <p>
          <FormattedMessage
            id='ManageForm.cancelText'
            values={{ cycle: moment(subscription.deactivated_at).format('DD-MM-YYYY') }}
          />
        </p>
        {!trial_ended && (
          <p>
            <FormattedMessage id='ManageForm.trialCancel' />
          </p>
        )}
        <div className='d-flex justify-content-center m-t-20'>
          <button className='btn-sm-fw btn btn-teal m-r-15' onClick={() => setShowCancelPopup(false)}>
            {isMobile
              ? intl.formatMessage({ id: 'ManageForm.keep' })
              : intl.formatMessage({ id: 'ManageForm.keepPlan' })}
          </button>
          <button className='btn-sm-fw btn btn-danger' onClick={() => cancelSubscription()}>
            {isMobile
              ? intl.formatMessage({ id: 'ManageForm.cancel' })
              : intl.formatMessage({ id: 'ManageForm.cancelPlan' })}
          </button>
        </div>
      </AlertPopup>
    </div>
  );
};

export default ManageForm;
