import { fonts, colors } from 'styles';
import styled from 'styled-components';

export const HeaderTitleWrapper = styled.div`
  position: absolute;
  left: 86px;
  display: flex;
  align-items: center;
`;

export const HeaderButton = styled.button`
  color: ${colors.cyan};
  position: absolute;
  right: ${({ right }) => right}px;
  background: transparent;
  border: none;
  ${fonts.styledSemiBold}
  text-align: right;
  font-size: ${({ fontSize }) => fontSize}px;
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px`};
  @media (max-width: 375px) {
    right: 0;
    font-size: 14px;
  }
`;

export const BackCont = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleDesktop = styled.h5`
  max-width: 430px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

export const TitleWrapperDesktop = styled.div`
  max-width: 390px;
`;
