import { gql, useMutation } from '@apollo/client';

const DELETE_ACCESS_ROLE_MUTATION = gql`
  mutation DeleteAccessRole($input: DeleteAccessRoleInput!) {
    deleteAccessRole(input: $input) {
      accessRole {
        id
        name
      }
      errors {
        attribute
        message
      }
    }
  }
`;

const useDeleteAccessRoleMutation = (options) => useMutation(DELETE_ACCESS_ROLE_MUTATION, options);

export { DELETE_ACCESS_ROLE_MUTATION, useDeleteAccessRoleMutation };
