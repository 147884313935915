import { gql } from '@apollo/client';
import { RESOLUTION_FRAGMENT } from './ResolutionFragment';
import { MEETING_FRAGMENT } from './MeetingFragment';

const VOTE_FRAGMENT = gql`
  fragment VoteFields on Vote {
    id
    name
    slug
    status
    config
    pollForVote
    voterType
    passcode
    contactIds
    showResults
    anonymous
    startedAt
    endedAt
    collaboratorEmails

    meeting {
      ...MeetingFields
    }

    resolutions {
      ...ResolutionFields
    }
  }
  ${RESOLUTION_FRAGMENT}
  ${MEETING_FRAGMENT}
`;

export { VOTE_FRAGMENT };
