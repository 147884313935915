export const formatDate = (date) => {
  return moment(date).parseZone().format('DD.MM.YYYY');
};

export const formatTime = (date) => {
  return moment(date).parseZone().format('hh:mm A');
};

export const voteStartDate = (vote) => {
  return formatDate(vote?.data.attributes.started_at);
};

export const voteStartTime = (vote) => {
  return formatTime(vote?.data.attributes.started_at);
};

export const voteEndDate = (vote) => {
  return formatDate(vote?.data.attributes.ended_at);
};

export const voteEndTime = (vote) => {
  return formatTime(vote?.data.attributes.ended_at);
};

export const voteName = (vote) => {
  return vote?.data.attributes.name;
};

export const voterAllowPdfExport = (voter, meetingDrawer) => {
  return !meetingDrawer && voter.data.attributes.export_to_pdf;
};

export const accessToken = () => {
  return new URLSearchParams(window.location.search).get('access_token');
};

export const votePasscode = (vote) => {
  return vote?.data.attributes.passcode;
};
