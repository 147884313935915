import { gql, useMutation } from '@apollo/client';

const UPDATE_PROFILE_SMART_SIGN_SETTINGS_MUTATION = gql`
  mutation UpdateProfileSmartSignSettings($input: UpdateProfileSmartSignSettingsInput!) {
    updateProfileSmartSignSettings(input: $input) {
      user {
        id
        signatureUrl
      }

      errors {
        attribute
        message
      }
    }
  }
`;

const useUpdateProfileSmartSignSettingsMutation = (options) =>
  useMutation(UPDATE_PROFILE_SMART_SIGN_SETTINGS_MUTATION, options);

export { UPDATE_PROFILE_SMART_SIGN_SETTINGS_MUTATION, useUpdateProfileSmartSignSettingsMutation };
