import { useCallback } from 'react';

const useContactMeetings = (contactMeetings, setEdits, creatorId = null) => {
  const setChairman = useCallback((contactMeetingId) => {
    setEdits('chairman_id', contactMeetingId);
  }, []);

  const updateContact = useCallback(
    (contactId, params) => {
      const collection = [...contactMeetings];
      const itemIndex = collection.findIndex((item) => item.contact.id === Number(contactId));

      collection[itemIndex] = {
        ...collection[itemIndex],
        ...params,
      };

      setEdits('contact_meetings', collection);
    },
    [contactMeetings]
  );

  const isCreator = (contact) => contact.id === creatorId;

  const removeContact = useCallback(
    (contact) => {
      if (isCreator(contact)) return;

      updateContact(contact.id, { _destroy: true });
    },
    [contactMeetings]
  );

  const contactExists = useCallback(
    (contact) => {
      return contactMeetings.find((item) => item.contact.id === contact.id && !item._destroy);
    },
    [contactMeetings]
  );

  const toggleContact = useCallback(
    (contact) => {
      const isAdded = contactMeetings.find((item) => item.contact.id === contact.id);

      if (isAdded && isCreator(contact)) return;

      if (isAdded) {
        updateContact(contact.id, { _destroy: !isAdded._destroy });
      } else {
        setEdits('contact_meetings', [...contactMeetings, { contact: contact }]);
      }
    },
    [contactMeetings]
  );

  const contacts = contactMeetings.filter((item) => !item._destroy && item.contact).map((item) => item.contact);

  return {
    contacts,
    setChairman,
    updateContact,
    isCreator,
    removeContact,
    contactExists,
    toggleContact,
  };
};

export { useContactMeetings };
