import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ToggleButton from 'components/v2/layout/ToggleButton';
import TimeClock from 'components/toolbar/TimeClock';
import ProfileDropdown from 'components/v2/layout/ProfileDropdown';
import { Icon as Chat } from 'components/conversations/Icon';
import { Bell as Notifications } from 'components/notifications/Bell';
import { FiX, FiMenu } from 'react-icons/fi';

const Toolbar = ({ isViewingMenu, currentContact, currentCompany, toggleIsViewingMenu, toggleIsViewingProfile }) => (
  <div className='tw-flex tw-items-center'>
    {currentContact.id && (
      <>
        <Chat />
        <Notifications />
      </>
    )}

    <ToggleButton className='xl:tw-hidden' isActive={isViewingMenu} onClick={toggleIsViewingMenu}>
      {isViewingMenu ? <FiX className='tw-h-6 tw-w-6' /> : <FiMenu className='tw-h-6 tw-w-6' />}
    </ToggleButton>

    <div className='tw-hidden xl:tw-block'>
      <ProfileDropdown
        onToggle={toggleIsViewingProfile}
        currentContact={currentContact}
        currentCompany={currentCompany}
      />
    </div>
  </div>
);

Toolbar.propTypes = {
  isViewingMenu: PropTypes.bool,
  currentCompany: PropTypes.object,
  toggleIsViewingMenu: PropTypes.func,
  toggleIsViewingProfile: PropTypes.func,
};

export default Toolbar;
