import { useUpdateSubcommitteeMutation } from '../data/mutations/UpdateSubcommitteeMutation';

const useUpdateSubcommittee = ({ onError }) => {
  const update = (cache, { data: { updateSubcommittee } }) => {
    const { errors } = updateSubcommittee;

    if (errors.length) {
      return onError(errors);
    }
  };

  const [mutate] = useUpdateSubcommitteeMutation({ update });

  const updateSubcommittee = ({ subcommitteeId, name, contacts }) => {
    mutate({ variables: { input: { subcommitteeId, name, contacts } } });
  };

  return { updateSubcommittee };
};

export { useUpdateSubcommittee };
