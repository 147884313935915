/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import Avatar from 'components/shared/Avatar';
import { Modal } from 'components/shared/Modal';
import { ContactPicker } from 'components/shared/ContactPicker';
import { ContactsTable } from 'components/meetings/ContactsTable';

import { useContactMeetings } from 'hooks/useContactMeetings';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import { useMeetingStore, useMeetingActions } from 'store/meetingStoreContext';
import { useFlashActions } from 'store/flashStoreContext';

import { ReactComponent as CalendarIcon } from 'images/icons/calendar.svg';

import * as styles from './MeetingAttendeesModal.styles';

const MeetingAttendeesModal = memo(({ show, onClose }) => {
  const { edits: meeting } = useMeetingStore();
  const { isDraft, isUpcoming, isScheduled, isInSession } = useMeetingStatus(meeting.status);
  const { setEdits, discardEdits, inviteAttendees } = useMeetingActions();
  const { setFlash } = useFlashActions();
  const { contact_meetings } = meeting;

  const intl = useIntl();

  const handleClose = () => {
    discardEdits();
    onClose();
  };

  const { contacts, isCreator, setChairman, updateContact, removeContact, contactExists, toggleContact } =
    useContactMeetings(contact_meetings, setEdits, meeting.contact_id);

  const handleUpdate = () => {
    inviteAttendees(meeting.id, meeting).then(handleClose).catch(setFlash);
  };

  const meetingOrganizerId = () => {
    if (meeting.event_organizer) {
      return meeting.event_organizer.data.id;
    }
  };

  const isContactEventOrganizer = (contact) => contact.id == meetingOrganizerId();

  const isContactEnabled = (contact) => !isContactEventOrganizer(contact);

  const calendarIndicator = (contact) => {
    if (isContactEventOrganizer(contact)) {
      return <CalendarIcon className='m-r-15 w-20px' />;
    }
  };

  const eventOrganizerOption = (contact, highlighter) => (
    <>
      <Avatar contact={contact} />

      <span className='m-l-15 flex-grow-1'>{highlighter(contact)}</span>

      {calendarIndicator(contact)}
    </>
  );

  const showInfoMessage = isScheduled || isUpcoming || isInSession;
  const infoMessage = showInfoMessage && (
    <div className='fs-12 c-mid font-weight-regular lh-14'>
      <FormattedMessage id='MeetingAttendeesModal.info' />
    </div>
  );

  return (
    <Modal
      title={intl.formatMessage({ id: 'MeetingAttendeesModal.inviteAttendees' })}
      centered
      show={show}
      modalStyles={styles.modal}
      onClose={handleClose}
    >
      <Row noGutters>
        <Col md={12}>
          {infoMessage}

          <div className='p-t-20'>
            <ContactPicker
              values={contacts}
              onToggleOption={toggleContact}
              dropdownIsOpen={false}
              inputLabel={intl.formatMessage({ id: 'MeetingAttendeesModal.addAttendees' })}
              isSelected={contactExists}
              contactOption={eventOrganizerOption}
              isOptionEnabled={isContactEnabled}
            />
          </div>
        </Col>
      </Row>

      <Row noGutters>
        <Col md={12}>
          <div className='m-t-25'>
            <ContactsTable
              contacts={contacts}
              contactMeetings={contact_meetings}
              meetingStatus={meeting.status}
              chairmanId={meeting.chairman_id}
              isCreator={isCreator}
              updateContact={updateContact}
              setChairman={setChairman}
              onDelete={removeContact}
              meetingOrganizerId={meetingOrganizerId()}
            />
          </div>
        </Col>
      </Row>

      <div className='m-t-20 text-center'>
        <a onClick={handleUpdate} className='btn btn-primary'>
          <FormattedMessage id='MeetingAttendeesModal.save' />
        </a>
        <a onClick={handleClose} className='btn btn-text brand-text m-l-5'>
          <FormattedMessage id='MeetingAttendeesModal.cancel' />
        </a>
      </div>
    </Modal>
  );
});

export { MeetingAttendeesModal };
