const companySlug = getCompanySlug();

const urls = {
  meetingsIndex: `/${companySlug}/meetings`,
  meetingsNew: `/${companySlug}/meetings/new`,
};

const useNavigation = () => {
  const navigate = (urlName) => {
    window.location.assign(urls[urlName]);
  };

  return navigate;
};

export default useNavigation;
