import { css } from '@emotion/core';
import { colors } from 'styles';

export const inviteOption = css`
  display: flex;
  align-items: center;
  background: ${colors.darkGrey};
  padding: 11px 21px;

  &:hover {
    cursor: pointer;
  }

  span {
    margin-left: 13px;
    color: ${colors.black};
    font-size: 12px;
    line-height: 20px;
  }
`;

export const items = css`
  overflow: auto;
  max-height: 241px;
`;

export const organizerNote = css`
  display: flex;
  margin: 7px;
  background: ${colors.lightOrange};
  font-size: 14px;

  svg {
    width: 85px;
    margin-top: 27px;
    margin-left: 7px;
  }
`;
