import React, { useContext, useEffect, useState } from 'react';
import Heading from 'components/v2/shared/Heading';
import Button from 'components/v2/shared/Button';
import Label from 'components/v2/shared/Label';
import TextInput from 'components/v2/shared/TextInput';
import { ToastContext } from 'contexts/v2/ToastContext';
import { Severity } from '../shared/Toast/Toast';
import { ModalContext } from '../shared/Modal/Modal';
import { useUpdateAccessRoleMutation } from 'data/v2/mutations/UpdateAccessRoleMutation';
import { wrapMutation } from 'utils/v2/gql';

const UpdateRoleName = ({ role, refetch }) => {
  const { toastInfo, setToastInfo } = useContext(ToastContext);
  const { onClose } = useContext(ModalContext);
  const [inputValue, setInputValue] = useState(role.role);

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const [updateAccessRole] = useUpdateAccessRoleMutation();

  const handleUpdate = async () => {
    try {
      await wrapMutation(
        updateAccessRole,
        {
          variables: {
            input: {
              accessRoleId: role.id,
              name: inputValue,
            },
          },
        },
        'updateAccessRole'
      );
      refetch();
      setToastInfo({
        severity: Severity.Success,
        status: 'Save Successful',
        message: 'Role name has been successfully updated',
      });
    } catch (error) {
      setToastInfo({
        severity: Severity.Error,
        status: 'Failed Saving',
        message: error.message,
      });
    } finally {
      onClose();
    }
  };

  return (
    <div className='tw-p-12'>
      <Heading text={role.role} className='tw-mb-6 !tw-text-2xl !tw-font-semibold' />
      <Heading text='Change Name' className='!tw-font-semibold' />
      <div className='tw-mt-3'>
        <Label text='Name of role' className='tw-mb-2.5 tw-font-sans tw-text-sm tw-font-semibold' />
        <TextInput name='name' value={inputValue} onChange={(e) => handleInput(e)} />
      </div>
      <div className='tw-mt-6'>
        <Button onClick={handleUpdate} font='semibold' isFullWidth size='large' variant='teal'>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default UpdateRoleName;
