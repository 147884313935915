import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Variants = {
  Rectangle: 'rectangle',
  Circle: 'circle',
};

const Shape = ({ variant, className }) => (
  <div
    className={clsx('tw-box-border tw-bg-purple-150', className, {
      'tw-h-6 tw-w-6 tw-shrink-0 tw-rounded-full': variant === Variants.Circle,
      'tw-h-6 tw-w-full tw-rounded-sm': variant === Variants.Rectangle,
    })}
  />
);

Shape.propTypes = {
  variant: PropTypes.oneOf(Object.values(Variants)),
  className: PropTypes.string,
};

export default Shape;
