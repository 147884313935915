import { css } from '@emotion/core';
import { colors } from 'styles';

export const assignChairButton = css`
  position: absolute;
  background: ${colors.white};
  right: 15px;
  bottom: 14px;
  padding: 0 20px;
`;
