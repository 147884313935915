import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import WebViewer from '@pdftron/webviewer';

const PdfViewer = ({ documentUrl, config = {}, children, className, onInit = () => {} }) => {
  const viewerRef = useRef();

  useEffect(() => {
    WebViewer(
      {
        path: '/pdftron/',
        css: '/pdftron/custom.css',
        preloadWorker: `${WebViewer.WorkerTypes.PDF},${WebViewer.WorkerTypes.OFFICE}`,
        initialDoc: documentUrl,
        useDownloader: false,
        loadAsPDF: true,
        disableLogs: process.env.NODE_ENV === 'production',
        licenseKey: process.env.PDFJS_KEY,
        ...config,
      },
      viewerRef.current
    ).then(onInit);
  }, []);

  return (
    <div ref={viewerRef} className='tw-relative tw-h-full tw-w-full'>
      {children}
    </div>
  );
};

PdfViewer.propTypes = {
  documentUrl: PropTypes.string.isRequired,
  config: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  onInit: PropTypes.func,
};

export default PdfViewer;
