import { css } from '@emotion/core';
import { colors } from 'styles';

export const thread = {
  base: css`
    display: flex;
    flex-direction: column;
    outline: 1px solid ${colors.lighterGrey};
    height: 100%;
    position: absolute;
    width: 100%;
    justify-content: space-between;
    background: ${colors.white};
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 5;
    left: 100%;
    top: 0;
  `,

  open: css`
    animation: slideIn 0.3s ease-in-out forwards;

    @keyframes slideIn {
      0% {
        transform: translate3d(0, 0, 0);
      }

      100% {
        transform: translate3d(-100%, 0, 0);
      }
    }
  `,

  closed: css`
    animation: slideOut 0.3s ease-in-out forwards;

    @keyframes slideOut {
      0% {
        transform: translate3d(-100%, 0, 0);
      }

      100% {
        transform: translate3d(0, 0, 0);
      }
    }
  `,
};
