import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { CONTACT_GROUP_FRAGMENT } from 'data/v2/fragments/ContactGroupFragment';

const CONTACT_GROUPS_QUERY = gql`
  query {
    currentCompany {
      id
      contacts {
        id
        name
        initials
        contactRole
        companyPosition
      }
      contactGroups {
        id
        name
        contacts {
          id
          name
          initials
          contactRole
          companyPosition
        }
      }
    }
  }
`;

const useContactGroupsQuery = (options) => useQuery(CONTACT_GROUPS_QUERY, options);

export { CONTACT_GROUPS_QUERY, useContactGroupsQuery };
