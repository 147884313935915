import { gql, useMutation } from '@apollo/client';

const CREATE_ANNOTATION = gql`
  mutation UpdateAnnotation($input: UpdateAnnotationInput!) {
    UpdateAnnotation(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`;

const useUpdateAnnotationMutation = (options) => useMutation(CREATE_ANNOTATION, options);

export default useUpdateAnnotationMutation;
