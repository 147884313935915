import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ToggleButton from 'components/v2/layout/ToggleButton';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { intl } from 'contexts/v2/TranslationContext';
import { ReactComponent as LogoIcon } from 'images/icons/brand-logo.svg';

const Toggle = ({ companyName, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={clsx(
      'tw-appearance-none tw-outline-none',
      'tw-flex tw-items-center tw-gap-4',
      'tw-h-full tw-w-full tw-justify-between tw-bg-white',
      'tw-border-0',
      'xl:tw-px-4',
      'tw-bg-purple-500 tw-text-white'
    )}
  >
    <div className='tw-flex tw-items-center tw-gap-4'>
      <LogoIcon className='tw-h-8 tw-w-8 tw-shrink-0' />
      <div className='tw-break-words tw-text-left tw-text-base tw-font-bold tw-leading-5 tw-line-clamp-2'>
        {companyName}
      </div>
    </div>

    <FiChevronDown className={clsx('tw-hidden tw-h-4 tw-w-4 tw-shrink-0 xl:tw-block', { 'tw-rotate-180': isActive })} />

    <ToggleButton Element='div' className='xl:tw-hidden' isActive={isActive}>
      <FiChevronDown className={clsx('tw-h-6 tw-w-6 tw-shrink-0', { 'tw-rotate-180': isActive })} />
    </ToggleButton>
  </button>
);

Toggle.propTypes = {
  companyName: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Toggle;
