import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const card = (isLast) => css`
  background-color: ${colors.white};
  border: 1px solid ${colors.borderSecondGrey};
  box-sizing: border-box;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${isLast ? '0px' : '10px'};
  color: ${colors.black};
  position: relative;
`;

export const header = css`
  width: 110px;
  min-width: 110px;
  padding-right: 20px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: ${colors.black};
  margin: 0;
`;
export const statuses = css`
  font-size: 12px;
  line-height: 16px;
`;

export const textOverflow = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const line = css`
  display: flex;
  align-items: center;
`;

export const dotsBlock = css`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  border-left: 1px solid ${colors.borderSecondGrey};
  border-bottom: 1px solid ${colors.borderSecondGrey};
`;

export const dateLabel = css`
  display: flex;
`;

export const dateLabelPart = css`
  ${fonts.styledBold}
  width: 50px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
`;

export const dateLabelDay = css`
  display: flex;
  background-color: #00bac6;
  color: ${colors.white};
  border-radius: 4px 0 0 4px;
`;

export const dateLabelTime = css`
  color: #00bac6;
  background-color: rgba(#00bac6, 0.1);
  background-color: #e5f8f9;
  display: flex;
  border-radius: 0 4px 4px 0;
`;

export const link = css`
  color: inherit;
  text-decoration: none;
  &:focus,
  &:active {
    color: inherit;
  }
`;
