import { gql, useMutation } from '@apollo/client';

const ARCHIVE_COMPANY_CONTACT_MUTATION = gql`
  mutation ArchiveCompanyContact($input: ArchiveCompanyContactInput!) {
    archiveCompanyContact(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`;

const useArchiveCompanyContactMutation = (options) => useMutation(ARCHIVE_COMPANY_CONTACT_MUTATION, options);

export { ARCHIVE_COMPANY_CONTACT_MUTATION, useArchiveCompanyContactMutation };
