import React, { useEffect, createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { useGQLDataContext } from './GQLDataContext';
import { useMeetingContext } from './MeetingContext';
import { useUpdateMeetingConfigMutation } from 'data/v2/mutations/UpdateMeetingConfigMutation';
import { wrapMutation } from 'utils/v2/gql';
import { serializeMeetingConfig } from 'utils/v2/serializers';

import { MEETING_CONFIG_DEFAULTS } from './constants';

const initialState = {
  meeting: { ...MEETING_CONFIG_DEFAULTS },
  edits: { ...MEETING_CONFIG_DEFAULTS },
};

const actionTypes = {
  UPDATE_EDITS: 'MeetingConfigContext.updateEdits',
  UPDATE_DATA: 'MeetingConfigContext.updateData',
  DISCARD_EDITS: 'MeetingConfigContext.discardEdits',
};

const MeetingConfigContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.UPDATE_EDITS:
      return { ...state, edits: { ...state.edits, ...payload.updates } };
    case actionTypes.UPDATE_DATA:
      const serializedData = serializeMeetingConfig(payload.meeting);

      return {
        ...state,
        meeting: serializedData,
        edits: serializedData,
      };
    case actionTypes.DISCARD_EDITS:
      return { ...state, edits: { ...state.meeting } };
  }
};

export const MeetingConfigContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [updateMeetingConfig] = useUpdateMeetingConfigMutation();

  const { gqlData, id, getDataByPath } = useGQLDataContext();
  const {
    meeting: { meetingId },
  } = useMeetingContext();

  const updateEdits = (fieldName, value) => {
    dispatch({ type: actionTypes.UPDATE_EDITS, payload: { updates: { [fieldName]: value } } });
  };

  const updateData = (data) => {
    const { meeting } = data;
    dispatch({ type: actionTypes.UPDATE_DATA, payload: { meeting } });
  };

  useEffect(() => {
    if (gqlData) {
      if (id) {
        updateData(getDataByPath('currentCompany', []));
      }
    }
  }, [gqlData]);

  const saveConfigData = async (zoomMeeting) => {
    const { config, meetingUrl, passcode, location, locationNotes } = edits;

    const attributes = { config, meetingUrl, passcode, location, locationNotes };

    return wrapMutation(
      updateMeetingConfig,
      { variables: { input: { meetingId, attributes, zoomMeeting } } },
      'updateMeetingConfig'
    ).then(updateData);
  };

  const discardEdits = () => {
    dispatch({ type: actionTypes.DISCARD_EDITS });
  };

  const { edits } = state;

  return (
    <MeetingConfigContext.Provider value={{ edits, updateEdits, saveConfigData, discardEdits }}>
      {children}
    </MeetingConfigContext.Provider>
  );
};

MeetingConfigContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useMeetingConfigContext = () => useContext(MeetingConfigContext);
