import { gql, useMutation } from '@apollo/client';
import { RESOLUTION_FRAGMENT } from './../fragments/ResolutionFragment';

const CREATE_RESOLUTION = gql`
  mutation createResolution($input: CreateResolutionInput!) {
    createResolution(input: $input) {
      resolution {
        ...ResolutionFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${RESOLUTION_FRAGMENT}
`;

const useCreateResolutionMutation = (options) => useMutation(CREATE_RESOLUTION, options);

export { useCreateResolutionMutation };
