import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const hoverable = css`
  &:hover {
    opacity: 0.8;
  }
`;

export const container = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
`;

export const controls = css`
  display: flex;
  align-items: center;
`;

export const header = css`
  ${fonts.bold}
  font-size: 14px;
  color: ${colors.black};
`;

export const text = css`
  ${fonts.regular}
  font-size: 14px;
  color: ${colors.black};
`;

export const toggle = css`
  ${hoverable}
  cursor: pointer;
  font-size: 12px;
  flex-shrink: 0;
  font-weight: 300;
  color: ${colors.mediumGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;

  &:hover {
    background: ${colors.placeholderGrey};
  }
`;

export const close = css`
  ${toggle}
  margin-right: 0;
`;
