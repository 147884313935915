/** @jsxRuntime classic */
/** @jsx jsx */
import React, { memo, forwardRef } from 'react';
import { jsx } from '@emotion/core';
import { Scrollbar } from 'react-scrollbars-custom';

import * as styles from './Scrollable.styles';

const Scrollable = memo(
  forwardRef(({ children, ...props }, scrollableRef) => {
    return (
      <Scrollbar ref={scrollableRef} css={styles.container} removeTracksWhenNotUsed {...props}>
        {children}
      </Scrollbar>
    );
  })
);

export { Scrollable };
