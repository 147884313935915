import React from 'react';

import { client } from 'utils/apollo';
import { ApolloProvider } from '@apollo/client';

import { Bell } from './Bell';

export default ({ showBadge, title = '' }) => (
  <ApolloProvider client={client}>
    <Bell showBadge={showBadge} title={title} />
  </ApolloProvider>
);
