import { gql } from '@apollo/client';
import { COMPANY_ROUTES_FRAGMENT } from './CompanyRoutesFragment';
import { COMPANY_PERMISSIONS_FRAGMENT } from './CompanyPermissionsFragment';

const COMPANY_FRAGMENT = gql`
  fragment CompanyFields on Company {
    id
    name
    slug
    isPrimary
    isPending
    isActive
    trialEnded
    isCurrentCompany
    currentUserRole
    createdAt
    subcommittees {
      id
      name
      slug
      contacts {
        id
        email
      }
    }

    companyRoutes {
      ...CompanyRoutesFields
    }

    companyPermissions {
      ...CompanyPermissionsFields
    }
  }
  ${COMPANY_ROUTES_FRAGMENT}
  ${COMPANY_PERMISSIONS_FRAGMENT}
`;

export { COMPANY_FRAGMENT };
