import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FiUsers } from 'react-icons/fi';

import Avatar from 'components/v2/shared/Avatar';

const CompanyItemContacts = ({ approvedContacts, requestedContacts }) => {
  const renderAvatars = (items) => (
    <div className='tw-py-2'>
      {items.slice(0, 5).map((item) => (
        <Avatar key={item.id} color='yellow' src={item.avatarUrl} text={item.initials} className='tw-mr-1' />
      ))}
      {items.length > 5 && <Avatar color='lightPurple' text={`+${items.length - 5}`} />}
    </div>
  );

  return (
    <div className='tw-flex tw-text-black'>
      {!!approvedContacts && approvedContacts.length > 0 && (
        <div className='tw-mr-10 tw-text-xs'>
          <FiUsers className='tw-mr-1 tw-h-4 tw-w-4 tw-align-text-top tw-text-teal-600' />
          <FormattedMessage id='CompanyItemContacts.contacts' />
          <span className='tw-ml-1 tw-font-bold'>{approvedContacts.length}</span>
          {renderAvatars(approvedContacts)}
        </div>
      )}
      {!!requestedContacts && requestedContacts.length > 0 && (
        <div className='tw-text-xs'>
          <FiUsers className='tw-mr-1 tw-h-4 tw-w-4 tw-align-text-top' />
          <FormattedMessage id='CompanyItemContacts.waiting' />
          <span className='tw-ml-1 tw-font-bold'>{requestedContacts.length}</span>
          {renderAvatars(requestedContacts)}
        </div>
      )}
    </div>
  );
};

CompanyItemContacts.propTypes = {
  approvedContacts: PropTypes.array,
  requestedContacts: PropTypes.array,
};

export default CompanyItemContacts;
