/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import useWindowDimensions from 'hooks/useWindowDimensions';
import NoFolders from 'images/icons/no-folders.svg';
import NoDocuments from 'images/icons/no-documents.svg';
import { CurrentDocuments } from '../CurrentDocuments';
import { BrowserSearchInMobile } from './BrowserSearchInMobile';
import * as styles from '../FileBrowser.styles';
import LoadingCircle from '../../LoadingCircle';
import DirectoryPath from 'components/documents/DirectoryPath';
import { FolderRowInMobile } from 'components/shared/FileBrowser/MobileComponents/FolderRowInMobile';
import AppHeader from 'components/shared/AppHeader';
import { ToolbarButtonWrapper, IconButton, HeaderTitle } from 'components/shared/AppHeader/styles';
import { DirectoryPathWrapper, MobHeading, ActionBtn, NoFoldersSection } from '../styles';

export const FolderExplorerInMobile = ({
  currentFolderChildren,
  searchMode,
  currentPath,
  selectFolder,
  error,
  isSelectable,
  multiple,
  currentFolderDocuments,
  selectDocument,
  currentDocument,
  setCurrentDocument,
  selectedDocuments,
  navigateToRoot,
  setSelectedDocuments,
  searchTerm,
  setSearchTerm,
  setIsSearching,
  setSearchResults,
  setSearchMode,
  currentRoot,
  isSearching,
}) => {
  const name = currentRoot.name;
  const timestampFormat = 'DD.MM.YYYY hh:mm A';
  const { isMobile } = useWindowDimensions();

  return (
    <>
      <AppHeader>
        <ToolbarButtonWrapper>
          <IconButton onClick={navigateToRoot}>
            <i className='far fa-angle-left fa-2x m-r-20' />
            {!isMobile && (
              <HeaderTitle fontSize={16} lineHeight={34}>
                <FormattedMessage id='FolderExplorerInMobile.back' />
              </HeaderTitle>
            )}
          </IconButton>
        </ToolbarButtonWrapper>
        <div className='m-auto'>
          <HeaderTitle fontSize={isMobile ? 18 : 20} lineHeight={isMobile ? 20 : 30}>
            {name}
          </HeaderTitle>
        </div>
        <div className={`position-absolute right-0 ${isMobile ? 'm-r-15' : 'm-r-30'}`}>
          <HeaderTitle fontSize={16} lineHeight={20} onClick={navigateToRoot}>
            <FormattedMessage id='FolderExplorerInMobile.close' />
          </HeaderTitle>
        </div>
      </AppHeader>
      <div className='react-browser'>
        {isSearching && <LoadingCircle />}
        {!searchMode && (
          <DirectoryPathWrapper>
            <DirectoryPath directories={currentPath} onDirectoryClick={selectFolder} />
          </DirectoryPathWrapper>
        )}
        <BrowserSearchInMobile
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setIsSearching={setIsSearching}
          setSearchResults={setSearchResults}
          setSearchMode={setSearchMode}
          currentRoot={currentRoot}
        />

        {error && <div css={styles.error}>{error}</div>}

        <MobHeading>
          <FormattedMessage id='FolderExplorerInMobile.folderCount' values={{ count: currentFolderChildren.length }} />
        </MobHeading>

        {currentFolderChildren.length > 0 ? (
          <FolderRowInMobile
            directories={currentFolderChildren}
            onDirectoryClick={selectFolder}
            isSelectable={isSelectable}
            multiple={multiple}
            showDocumentCount={true}
            showCreatedAt={true}
            showPath={searchMode}
            createdAtFormat={timestampFormat}
          />
        ) : (
          <div className='directories-explorer'>
            <NoFoldersSection>
              <div>
                <img src={NoFolders} className='m-0' />
                <div className='m-t-10'>
                  <FormattedMessage id='FolderExplorerInMobile.noFolders' />
                </div>
              </div>
            </NoFoldersSection>
          </div>
        )}

        <MobHeading>
          <FormattedMessage id='FolderExplorerInMobile.fileCount' values={{ count: currentFolderDocuments.length }} />
        </MobHeading>

        <div className='directories-explorer'>
          {currentFolderDocuments.length > 0 ? (
            <CurrentDocuments
              currentFolderDocuments={currentFolderDocuments}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
              searchMode={searchMode}
              multiple={multiple}
              setCurrentDocument={setCurrentDocument}
              currentDocument={currentDocument}
            />
          ) : (
            <NoFoldersSection>
              <div>
                <img src={NoDocuments} className='m-0' />
                <div className='m-t-10'>
                  <FormattedMessage id='FolderExplorerInMobile.noFiles' />
                </div>
              </div>
            </NoFoldersSection>
          )}
        </div>

        <ActionBtn>
          <a
            onClick={selectDocument}
            disabled={!currentDocument}
            className={`btn ${currentDocument || selectedDocuments.length > 0 ? 'btn-primary' : 'btn-disabled'}`}
          >
            <FormattedMessage id='FolderExplorerInMobile.select' />
            {selectedDocuments.length > 0 && ` (${selectedDocuments.length}) files`}
          </a>
        </ActionBtn>
      </div>
    </>
  );
};
