import React from 'react';
import PropTypes from 'prop-types';
import { Toaster as ReactToaster, resolveValue, toast } from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import IconButton from '../IconButton';
import { IoClose, IoCloseCircle, IoInformationCircle } from 'react-icons/io5';
import { FaCheckCircle } from 'react-icons/fa';
import { RiErrorWarningFill } from 'react-icons/ri';

export const Severity = {
  Success: 'success',
  Error: 'error',
  Info: 'info',
  Warning: 'warning',
};

const styles = {
  [Severity.Success]: {
    background: 'tw-bg-green-100',
    border: 'tw-border tw-border-l-teal-700',
    icon: 'tw-text-2xl tw-text-teal-700',
  },
  [Severity.Error]: {
    background: 'tw-bg-red-100',
    border: 'tw-border tw-border-l-red-600',
    icon: 'tw-text-2xl tw-text-red-600',
  },
  [Severity.Warning]: {
    background: 'tw-bg-orange-100',
    border: 'tw-border tw-border-l-orange-600',
    icon: 'tw-text-2xl tw-text-orange-600',
  },
  [Severity.Info]: {
    background: 'tw-bg-gray-100',
    border: 'tw-border tw-border-l-gray-700',
    icon: 'tw-text-2xl tw-text-gray-700',
  },
};

const ToasterIcon = ({ severity, className }) => {
  switch (severity) {
    case Severity.Success:
      return <FaCheckCircle className={clsx(`${styles[severity].icon}`, className)} />;
    case Severity.Error:
      return <IoCloseCircle className={clsx(`${styles[severity].icon}`, className)} />;
    case Severity.Warning:
      return <RiErrorWarningFill className={clsx(`${styles[severity].icon}`, className)} />;
    case Severity.Info:
      return <IoInformationCircle className={clsx(`${styles[severity].icon}`, className)} />;
  }
};

const Toaster = ({ severity, status, message }) => {
  return (
    <div>
      <ReactToaster
        position='top-right'
        toastOptions={{
          duration: 3000,
        }}
        containerStyle={{
          top: 80,
        }}
      >
        {(t) => (
          <Transition
            appear
            show={t.visible}
            className={clsx(
              'tw-flex tw-transform tw-rounded-[4px] tw-border-0 tw-border-l-[5px] tw-border-solid tw-p-[9px] tw-pb-[15px] tw-shadow-lg',
              `${styles[severity].background} ${styles[severity].border}`
            )}
            enter='transition-all duration-150'
            enterFrom='opacity-0 scale-50'
            enterTo='opacity-100 scale-100'
            leave='transition-all duration-150'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-75'
          >
            <ToasterIcon severity={severity} className='tw-mr-[14px]' />
            <div>
              <p className='!tw-m-0 tw-text-sm tw-font-bold tw-text-black'>{status}</p>
              <p className='!tw-m-0 tw-text-xs tw-font-medium tw-text-black'>{message}</p>
            </div>
            <IconButton
              aria-label='close'
              onClick={() => toast.dismiss(t.id)}
              className='tw-relative -tw-top-2 tw-right-0 tw-p-0 tw-text-sm hover:tw-bg-transparent'
            >
              <IoClose />
            </IconButton>
          </Transition>
        )}
      </ReactToaster>
    </div>
  );
};

Toaster.propTypes = {
  severity: PropTypes.oneOf(Object.values(Severity)),
  status: PropTypes.string,
  message: PropTypes.string,
};

export default React.memo(Toaster);
