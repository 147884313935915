import styled from 'styled-components';
import { colors, fonts } from 'styles';

export const FolderCard = styled.div`
  margin-top: 5px;
  background: ${colors.white};
  box-shadow: 2px 4px 4px rgba(223, 223, 223, 0.25);
  border-radius: 4px;
  padding: 15px 20px;
  display: block;
  .count {
    padding-left: 28px;
  }
  position: relative;
`;

export const IconWrapper = styled.div`
  padding-right: 12px;
  display: flex;
  svg {
    path {
      fill: ${colors.cyan};
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 28px;
  color: ${colors.black};
  ${fonts.styledSemiBold}
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const DirectoryPathWrapper = styled.div`
  .directories-path {
    padding: 8px;
    .directory-path-part {
      ${fonts.styledSemiBold}
      font-size: 14px !important;
      line-height: 28px;
      color: ${colors.black} !important;
      &::after {
        color: ${colors.black} !important;
      }
    }
  }
`;

export const MobHeading = styled.div`
  padding: 5px 15px;
  ${fonts.styledBold}
  font-size: 14px;
  line-height: 20px;
  color: ${colors.black};
  background: ${colors.mobileHeadingBackground};
`;

export const ActionBtn = styled.div`
  position: absolute;
  bottom: 75px;
  padding: 11px 15px;
  width: 100%;
  left: 0;
  a {
    width: 100%;
  }
`;

export const NoFoldersSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SearchInput = styled.input`
  height: 44px;
  border: 1px solid ${colors.inputBorder};
  color: ${colors.lightGrey};

  &::placeholder {
    color: ${colors.lightGrey};
  }
`;

export const SmartUploadContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

export const StyledDocumentExtension = styled.span`
  ${fonts.bold}
  position: absolute;
  top: 50%;
  left: 50%;
  color: ${colors.white};
  font-size: 4px;
  text-transform: uppercase;
  transform: translate(-45%, -40%);
`;
