/** @jsx jsx */
import React, { memo, useRef, useCallback, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { Drawer } from './../Drawer';

import { Input } from './../Input';
import { DateInput } from './../DateInput';
import { TextAreaInput } from './../TextAreaInput';
import { TagInput } from './../TagInput';
import { ContactPicker } from './../ContactPicker';
import { AgendaPicker } from './../AgendaPicker';

import { parseDateTime } from 'helpers/parseDateTime';
import { useMeetingStatus } from 'hooks/useMeetingStatus';

import * as styles from './TaskDrawer.styles';

import { useTaskDrawerActions, useTaskDrawerStore } from 'store/taskDrawerStoreContext';

const TaskDrawer = memo(
  ({
    agendas,
    adminView,
    editView,
    meetingStatus,
    contacts,
    onClose,
    onCreate,
    onUpdate,
    onDelete,
    currentContactId,
  }) => {
    const { task, isSubmit, errors, isOpen } = useTaskDrawerStore();
    const { setTask } = useTaskDrawerActions();

    const intl = useIntl();

    const drawerId = 'shaparency-task-drawer';

    const { isEditable, isInSession } = useMeetingStatus(meetingStatus);
    const adminAccess = adminView && (isEditable || isInSession);
    const editAccess = editView && (isEditable || isInSession);
    const isActionable = adminAccess || editAccess;
    const isTogglable = isActionable || task.contact_ids.includes(currentContactId);

    useEffect(() => {
      if (isOpen) {
        document.getElementById(drawerId).scrollTop = 0;
        document.querySelector('body').classList.add('opaque-bg');
      } else {
        document.querySelector('body').classList.remove('opaque-bg');
      }
    }, [isOpen]);

    const applicableStyles = [styles.drawer.base, isOpen && styles.drawer.state.open];

    const { current: calendarIcon } = useRef(<i className='far fa-calendar-alt' />);
    const { current: tagsIcon } = useRef(<i className='fa fa-tags' />);

    const contactExists = useCallback(
      (contact) => {
        return task.contact_ids.find((id) => id === contact.id);
      },
      [task.contact_ids]
    );

    const toggleContact = useCallback(
      (contact) => {
        const isAdded = contactExists(contact);

        if (isAdded) {
          removeContact(contact);
        } else {
          addContact(contact);
        }
      },
      [task.contact_ids]
    );

    const addContact = useCallback(
      (contact) => {
        const contactIds = [...task.contact_ids, contact.id];

        setTask('contact_ids', contactIds);
      },
      [task.contact_ids]
    );

    const removeContact = useCallback(
      (contact) => {
        const contactIds = task.contact_ids.filter((id) => id !== contact.id);

        setTask('contact_ids', contactIds);
      },
      [task.contact_ids]
    );

    const markCompleted = useCallback((event) => {
      setTask('status', event.target.checked ? 'completed' : 'incomplete');
    }, []);

    const handleTags = useCallback((tags) => {
      setTask('tags', tags);
    }, []);

    const handleInputChange = useCallback((event) => {
      setTask(event.target.name, event.target.value);
    }, []);

    const handleDate = useCallback((date) => {
      setTask('due_date', date);
    }, []);

    const addAgenda = useCallback((agenda) => {
      setTask('agenda_id', agenda.id);
    }, []);

    const removeAgenda = useCallback(() => {
      setTask('agenda_id', null);
    }, []);

    const completed = task.status === 'completed';
    const agenda = agendas.find((agenda) => agenda.id === task.agenda_id);
    const taskContacts = contacts.filter((contact) => task.contact_ids.includes(contact.id));

    const handleCreate = () => onCreate(task);
    const handleUpdate = () => onUpdate(task);
    const handleDelete = () => onDelete(task.id);

    const drawerHeader = () => {
      if (!isActionable) {
        return intl.formatMessage({ id: 'TaskDrawer.viewTask' });
      } else if (task.id) {
        return intl.formatMessage({ id: 'TaskDrawer.editTask' });
      } else {
        return intl.formatMessage({ id: 'TaskDrawer.createTask' });
      }
    };

    useEffect(() => {
      const keys = Object.keys(errors);

      if (isSubmit && keys.length) {
        document.querySelector(`[name='${keys[0]}']`).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, [errors]);

    const renderButtons = () => {
      if (!isActionable && !isTogglable) {
        return (
          <a className='btn btn-responsive btn-primary m-t-10' onClick={onClose}>
            <FormattedMessage id='TaskDrawer.close' />
          </a>
        );
      }

      const handleClick = task.id ? handleUpdate : handleCreate;
      const buttonText = task.id
        ? intl.formatMessage({ id: 'TaskDrawer.save' })
        : intl.formatMessage({ id: 'TaskDrawer.addNew' });

      return (
        <>
          <div css={styles.button} className='d-flex align-items-center'>
            <a
              css={styles.button}
              onClick={handleClick}
              className='m-r-25 btn btn-responsive btn-primary btn-no-transform p-l-30 p-r-30 fs-16 m-t-10'
            >
              {buttonText}
            </a>
          </div>

          {adminAccess && task.id && (
            <a
              css={styles.button}
              onClick={handleDelete}
              className=' btn btn-responsive btn-red border-0 fs-16 p-0 m-t-10'
            >
              <i className='far fa-trash-alt' />
              <span className='m-l-5'>
                <FormattedMessage id='TaskDrawer.delete' />
              </span>
            </a>
          )}
        </>
      );
    };

    return (
      <Drawer drawerId={drawerId} styles={applicableStyles} onClose={onClose}>
        <div css={styles.wrapper}>
          <div css={styles.header}>{drawerHeader()}</div>

          <div css={styles.content}>
            <Input
              name='title'
              value={task.title || ''}
              placeholder={intl.formatMessage({ id: 'TaskDrawer.taskNamePlaceholder' })}
              onChange={handleInputChange}
              errorMessage={errors.title}
              label={intl.formatMessage({ id: 'TaskDrawer.taskNameLabel' })}
              isDisabled={!isActionable}
              isRequired
            />

            <div className='m-t-20'>
              <DateInput
                name='due_date'
                value={parseDateTime(task.due_date)}
                placeholder={getUserDateFormat() || 'DD/MM/YYYY'}
                isRequired
                onChange={handleDate}
                errorMessage={errors.due_date}
                icon={calendarIcon}
                iconPosition='right'
                label={intl.formatMessage({ id: 'TaskDrawer.dueDateLabel' })}
                isDisabled={!isActionable}
              />
            </div>

            <div className='m-t-20'>
              <ContactPicker
                values={taskContacts}
                dropdownOptions={contacts}
                onToggleOption={toggleContact}
                inputLabel={intl.formatMessage({ id: 'TaskDrawer.ownersLabel' })}
                dropdownWidth={265}
                placeholder={intl.formatMessage({ id: 'TaskDrawer.ownersPlaceholder' })}
                isSelected={contactExists}
                isReadOnly={!adminAccess}
                standalone
              />
            </div>

            <div className='m-t-30'>
              <AgendaPicker
                options={agendas}
                dropdownWidth={265}
                onAdd={addAgenda}
                onRemove={removeAgenda}
                value={agenda}
                inputLabel={intl.formatMessage({ id: 'TaskDrawer.relatedLabel' })}
                isReadOnly={!adminAccess}
              />
            </div>

            <div className='m-t-20'>
              <TextAreaInput
                name='detail'
                value={task.detail || ''}
                placeholder={intl.formatMessage({ id: 'TaskDrawer.notesPlaceholder' })}
                isRequired
                onChange={handleInputChange}
                isDisabled={!isActionable}
                errorMessage={errors.detail}
                label={intl.formatMessage({ id: 'TaskDrawer.notesLabel' })}
              />
            </div>

            <div className='m-t-20 d-none'>
              <TagInput
                name='tags'
                value={task.tags}
                placeholder={intl.formatMessage({ id: 'TaskDrawer.notesPlaceholder' })}
                isRequired
                onChange={handleTags}
                errorMessage={errors.tags}
                label={intl.formatMessage({ id: 'TaskDrawer.tagsLabel' })}
                icon={tagsIcon}
                isReadOnly={!isActionable}
                iconPosition='right'
              />
            </div>

            <div className='m-t-20'>
              <div className='d-flex flex-wrap justify-content-between align-items-center'>
                {renderButtons()}

                {isTogglable && (
                  <div className='checkbox checkbox-primary m-t-10' css={styles.button}>
                    <input
                      id='completed'
                      type='checkbox'
                      value={completed}
                      checked={completed}
                      onChange={markCompleted}
                    />

                    <label className='text-black fs-14' htmlFor='completed'>
                      <FormattedMessage id='TaskDrawer.mark' />
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    );
  }
);

export { TaskDrawer };
