/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { BreadcrumbsDropdown } from './../BreadcrumbsDropdown';
import { Breadcrumb } from './../Breadcrumb';

import * as styles from './Breadcrumbs.styles';

const MAX_ITEMS = 3;

const Breadcrumbs = memo(({ isLoading, items, onClick }) => {
  if (!items) return null;

  const renderBreadcrumb = (breadcrumb) => {
    const handleClick = onClick && onClick.bind(this, breadcrumb.id);
    const key = `breadcrumb-${breadcrumb.id}`;

    return <Breadcrumb key={key} name={breadcrumb.name} onClick={handleClick} />;
  };

  const containerStyles = [styles.container.base, !isLoading && styles.container.visible];

  if (items.length <= MAX_ITEMS) {
    const breadcrumbs = items.map(renderBreadcrumb);

    return <div css={containerStyles}>{breadcrumbs}</div>;
  }

  const baseBreadcrumbs = items.slice(0, MAX_ITEMS - 1).map(renderBreadcrumb);
  const lastBreadcrumb = renderBreadcrumb(items[items.length - 1]);
  const dropdownOptions = items.slice(MAX_ITEMS - 1, items.length - 1).reverse();

  return (
    <div css={containerStyles}>
      {baseBreadcrumbs}

      <BreadcrumbsDropdown options={dropdownOptions} onClick={onClick} />

      {lastBreadcrumb}
    </div>
  );
});

export { Breadcrumbs };
