import { gql } from '@apollo/client';
import { CONTACT_FRAGMENT } from './ContactFragment';

const CONTACT_GROUP_FRAGMENT = gql`
  fragment ContactGroupFields on ContactGroup {
    id
    name
    size

    contacts {
      ...ContactFields
    }
  }
  ${CONTACT_FRAGMENT}
`;

export { CONTACT_GROUP_FRAGMENT };
