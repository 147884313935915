const orderByPosition = (a, b) => {
  return a.position - b.position;
};

const orderByNumber = (a, b) => {
  return a.number - b.number;
};

const findAgendaRoots = (collection) => {
  return collection.filter((item) => item.root).sort(orderByPosition);
};

const findAgendaChildren = (collection, parent) => {
  return collection.filter((item) => item.parent_id === parent.id).sort(orderByPosition);
};

const makeAgendaOrderMap = (collection, rootKey) => {
  const orderMap = { [rootKey]: [] };

  findAgendaRoots(collection).map((root) => {
    orderMap[rootKey].push(root);
    makeOrderMapForAgenda(collection, root, orderMap);
  });

  return orderMap;
};

const makeOrderMapForAgenda = (collection, agenda, orderMap) => {
  const children = findAgendaChildren(collection, agenda);

  orderMap[agenda.id] = [];

  if (!children.length) {
    return orderMap;
  }

  children.map((child) => {
    orderMap[agenda.id].push(child);
    makeOrderMapForAgenda(collection, child, orderMap);
  });
};

const orderMapToParams = (orderMap) => {
  const payload = {};

  Object.keys(orderMap).map((key) => {
    orderMap[key].map((agenda, index) => {
      payload[agenda.id] = index + 1;
    });
  });

  return payload;
};

export { findAgendaRoots, findAgendaChildren, makeAgendaOrderMap, orderMapToParams, orderByNumber };
