import { css } from '@emotion/core';

export const container = css`
  position: relative;
  height: 100%;
`;

export const header = css`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 20px;
`;

export const activeStep = css`
  padding-right: 68px;
`;
