import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import Group from './picker/Group';

export default memo(({ contacts }) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <div className='d-flex align-items-center'>
        <Group contacts={contacts} header={intl.formatMessage({ id: 'Picker.contacts' })} />
      </div>
    </React.Fragment>
  );
});
