import { css } from '@emotion/core';
import { fonts, panels, colors } from 'styles';

export const wrapper = css`
  ${panels.base}
  box-shadow: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  padding: 25px 25px 20px 25px;

  &:hover {
    background: ${colors.lightestGrey};
  }
`;

export const image = css`
  margin: 0;
  width: 70px;
  margin-bottom: 15px;
  flex-shrink: 0;
`;

export const message = css`
  ${fonts.bold}
  font-size: 16px;
  color: ${colors.black};
`;
