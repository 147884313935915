import React, { useState } from 'react';
import Button from 'components/v2/shared/Button';
import Box from 'components/v2/shared/Box';
import Shape from 'components/v2/shared/Shape';
import { FiChevronDown, FiChevronUp, FiCheck } from 'react-icons/fi';

const InvoiceDetails = ({ currentUser, invoices }) => {
  const [expandedInvoiceId, setExpandedInvoiceId] = useState(null);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };

  const toggleExpand = (invoiceId) => {
    if (expandedInvoiceId === invoiceId) {
      setExpandedInvoiceId(null);
    } else {
      setExpandedInvoiceId(invoiceId);
    }
  };

  return (
    <>
      <div className='tw-mt-10 '>
        <div className='tw-block tw-w-full sm:tw-flex '>
          <div className='tw-font-sans tw-text-sm tw-font-semibold tw-text-black '>Invoices</div>
          <div className='tw-ml-auto tw-justify-between sm:tw-justify-center'>
            <div className='tw-font-sans tw-text-xs tw-font-medium tw-text-black'>Sent to {currentUser?.email}</div>
          </div>
        </div>
      </div>
      <div className='tw-mt-5'>
        {invoices.slice(0, 5).map((invoice, index) => (
          <Box key={index} isSelected className='tw-mb-2 !tw-bg-gray-100'>
            <div className='tw-w-full'>
              <div className='tw-flex tw-w-full tw-justify-between'>
                <div>
                  <Button
                    font='semibold'
                    onClick={() => {}}
                    size='small'
                    variant='transparent'
                    className='!tw-pl-0 tw-font-sans tw-text-sm !tw-font-semibold !tw-text-black'
                  >
                    {new Date(invoice?.paid_at).toLocaleDateString('en-US', options)}
                  </Button>
                </div>
                <div>
                  <Button
                    font='semibold'
                    size='small'
                    onClick={() => {}}
                    variant='transparent'
                    className='!tw-pl-0 tw-font-sans tw-text-sm !tw-font-semibold !tw-text-black'
                  >
                    ${invoice?.total} {invoice?.currency?.toUpperCase()}
                  </Button>
                </div>
                <div>
                  <Button
                    font='medium'
                    size='small'
                    onClick={() => {
                      window.open(invoice.receipt_link, '_blank');
                    }}
                    variant='transparent'
                    className='tw-font-sans tw-text-xs !tw-font-medium tw-text-teal-500'
                  >
                    Download
                  </Button>
                </div>
                <div>
                  {expandedInvoiceId === invoice.id ? (
                    <FiChevronUp
                      className='tw-h-5 tw-w-5 tw-cursor-pointer !tw-text-gray-700 tw-duration-100'
                      onClick={() => toggleExpand(invoice.id)}
                    />
                  ) : (
                    <FiChevronDown
                      className='tw-h-5 tw-w-5 tw-cursor-pointer !tw-text-gray-700 tw-duration-100'
                      onClick={() => toggleExpand(invoice.id)}
                    />
                  )}
                </div>
              </div>
              {expandedInvoiceId === invoice.id && (
                <div className='tw-mt-4 tw-flex tw-w-full'>
                  <div>
                    <Button
                      font='semibold'
                      onClick={() => {}}
                      size='small'
                      variant='transparent'
                      className='!tw-pl-0 tw-font-sans tw-text-sm !tw-font-medium !tw-text-black'
                    >
                      Ref: {invoice?.number}
                    </Button>
                  </div>
                  <div>
                    <div className='-tw-mt-1 tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-4 tw-py-0 tw-pl-4'>
                      <div className='tw-col-span-3 tw-flex tw-items-center'>
                        <div className='tw-relative tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>
                          <Shape variant='circle' className='tw-relative tw-h-4 tw-w-4 tw-w-8 !tw-bg-green-500' />
                          <FiCheck className='tw-absolute tw-top-1.5 tw-left-1.5 tw-text-xs tw-text-black' />
                        </div>
                        <div className='tw-px-2 tw-text-sm tw-font-semibold'>
                          <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>Paid</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Box>
        ))}
      </div>
    </>
  );
};

export default InvoiceDetails;
