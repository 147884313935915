import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const tab = {
  base: css`
    ${fonts.semiBold}
    font-size: 18px;
    color: #ccd0dc;
    margin-right: 18px;
    border-bottom: 2px solid;
    border-color: transparent;
    cursor: pointer;

    &:hover {
      color: ${colors.cyan};
    }
  `,

  active: css`
    color: ${colors.cyan};
    border-color: ${colors.cyan};
  `,
};
