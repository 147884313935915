/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { withIntl } from '../../../utils/v2/intl';
import { GeneratePath } from 'components/documents/GeneratePath';
import api from './API';
import { useDocumentsActions } from 'store/documentsStoreContext';
import { Modal } from 'components/shared/Modal';
import CirclePlus from 'images/icons/circle-plus.svg';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { FolderExplorer } from './FolderExplorer';
import { Folders } from './Folders';
import SendingSignProvider from '../../../contexts/MobSignContext';
import { getResourceID } from 'helpers/graphql';
import * as styles from './FileBrowser.styles';
import LoadingCircle from '../LoadingCircle';

import { useDirectoriesBrowser } from 'components/documents/hooks/useDirectoriesBrowser';

export const FileBrowser = (props) => (
  <SendingSignProvider>
    <FileBrowserContent {...props} />
  </SendingSignProvider>
);

const FileBrowserContent = ({
  multiple,
  visible,
  context,
  agendaId,
  meetingId,
  meetingDirectoryId,
  resolutionId,
  onResolutionDocumentAttached,
  onClose,
  clickButtonComponent,
}) => {
  const { isDesktop } = useWindowDimensions();
  const [show, setShow] = useState(visible);
  const [currentDocument, setCurrentDocument] = useState();
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [error, setError] = useState();
  const { attachAgendaDocuments } = useDocumentsActions();

  const intl = useIntl();

  const resetSearch = () => {
    if (searchMode) {
      setSearchMode(false);
      setSearchResults(null);
      setSearchTerm('');
    }
  };

  const { isLoading, browseDirectory, resetBrowser, currentDirectory, browseSidebarItem, rootDirectories } =
    useDirectoriesBrowser({ onBrowse: resetSearch });

  useEffect(() => setShow(visible), [visible]);

  const hide = () => {
    setShow(false);
    onClose && onClose();
    setSelectedDocuments([]);
  };

  const isSelectable = (directory) => {
    if (!meetingId) {
      return true;
    }

    return getResourceID(directory.id) != meetingDirectoryId;
  };

  const selectDocument = () => {
    if ((!multiple && !currentDocument) || (multiple && selectedDocuments.length === 0)) return;

    setError(null);

    const companySlug = getCompanySlug();

    // check context
    if (context === 'smart_sign') {
      api.createSmartDocument(
        getResourceID(currentDocument.id),
        (smartDocument) => {
          window.location.assign(`/${companySlug}/smart_sign/${smartDocument.slug}/edit`);
        },
        (error) => {
          setError(error.response.data.message);
        }
      );
    } else if (context === 'meeting') {
      attachAgendaDocuments(
        selectedDocuments.map((document) => ({ id: getResourceID(document.id) })),
        agendaId,
        meetingId,
        () => {
          hide();
        }
      );
    } else if (context === 'vote') {
      const documentIds = selectedDocuments.map((document) => getResourceID(document.id));

      onResolutionDocumentAttached(documentIds);
      hide();
    } else {
      setError(intl.formatMessage({ id: 'FileBrowser.noHandler' }));
    }
  };

  const modalStyles = styles.modal(currentDirectory);

  const displayModal = () => {
    const companySlug = getCompanySlug();
    $('#modal-window').modal('hide');
    if (isDesktop) {
      setShow(true);
    } else {
      window.location.assign(`/${companySlug}/smart_sign/browse_documents`);
    }
  };

  const modalTitle = searchMode ? (
    <div className='d-flex align-items-center'>
      <i css={styles.backIcon} onClick={resetSearch} className='far fa-chevron-left fs-14' />
      <div className='m-auto'>
        <FormattedMessage id='FileBrowser.searchResults' />
      </div>
    </div>
  ) : (
    <FormattedMessage id='FileBrowser.selectDoc' />
  );

  const currentFolder = searchMode ? searchResults : currentDirectory;

  return (
    <>
      {context === 'smart_sign' && (
        <div onClick={displayModal} css={styles.browseSection}>
          {clickButtonComponent ? (
            clickButtonComponent()
          ) : (
            <>
              <img src={CirclePlus} className='m-0' />
              <span className='call-to-action'>
                <FormattedMessage id='FileBrowser.orSelect' />
              </span>
              <span className='browse'>
                <FormattedMessage id='FileBrowser.browse' />
              </span>
            </>
          )}
        </div>
      )}

      <Modal
        onClose={hide}
        modalStyles={modalStyles}
        overlay={(isSearching || isLoading) && <LoadingCircle />}
        show={show}
        centered
        onExited={resetBrowser}
        title={modalTitle}
      >
        <div id='react-smart-upload'>
          {currentDirectory ? (
            <FolderExplorer
              currentFolderChildren={currentFolder.directories}
              searchMode={searchMode}
              currentPath={currentDirectory.breadcrumbs}
              selectFolder={browseDirectory}
              error={error}
              isSelectable={isSelectable}
              multiple={multiple}
              currentFolderDocuments={currentFolder.documents}
              selectDocument={selectDocument}
              currentDocument={currentDocument}
              setCurrentDocument={setCurrentDocument}
              selectedDocuments={selectedDocuments}
              navigateToRoot={resetBrowser}
              setSelectedDocuments={setSelectedDocuments}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setIsSearching={setIsSearching}
              setSearchResults={setSearchResults}
              setSearchMode={setSearchMode}
              currentRoot={currentDirectory.breadcrumbs[0]}
              context={context}
            />
          ) : (
            <Folders rootDirectories={rootDirectories} onClick={browseDirectory} />
          )}
        </div>
      </Modal>
    </>
  );
};

export default withIntl(FileBrowser);
