import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AnalyticsItem from './AnalyticsItem';
import AgendaItem from './AgendaItem';
import Tabs from './Tabs';
import LoadingIndicator from 'components/v2/shared/LoadingIndicator';
import VisibilityWrapper from 'components/v2/shared/VisibilityWrapper';
import Switch from 'components/v2/shared/Switch';
import { makeAgendaOrderMap, findAgendaRoots, orderByNumber } from 'helpers/agendas';
import { intl } from 'contexts/v2/TranslationContext';
import { useMeetingStatus } from 'hooks/useMeetingStatus';

const tabMap = {
  [intl.formatMessage({ id: 'AgendaList.agenda' })]: 'agenda',
  [intl.formatMessage({ id: 'AttendeesPicker.attendees' })]: 'attendees',
};

const PdfViewerSidebar = ({
  isVisible,
  isAnnotatable = false,
  isLoadingAnalytics = false,
  meeting,
  publicAnnotations,
  togglePublicAnnotations,
  agendas = [],
  analytics = [],
  onScrollToPage = () => {},
  onToggleAgenda = () => {},
}) => {
  const [currentTab, setCurrentTab] = useState(tabMap.Agenda);

  const handleTabClick = (key) => () => setCurrentTab(key);
  const handleDocumentClick = (document) => () => onScrollToPage(document.page);
  const handleToggleAgenda = (agenda) => () => onToggleAgenda(agenda);

  const { isInSession } = useMeetingStatus(meeting.status);
  const agendaTree = makeAgendaOrderMap(agendas, 'root');

  const renderAgendaItem = (agenda) => (
    <AgendaItem
      key={agenda.id}
      number={agenda.number}
      title={agenda.heading}
      description={agenda.description}
      duration={agenda.duration_in_minutes}
      assignee={agenda.assignee_name}
      documents={meeting.smart_pack_schema[agenda.id]}
      children={agendaTree[agenda.id].map(renderAgendaItem)}
      isRoot={agenda.root}
      isCompleted={agenda.completed}
      isToggleable={agenda.root && isInSession}
      onToggle={handleToggleAgenda(agenda)}
      onDocumentClick={handleDocumentClick}
    />
  );

  return (
    <VisibilityWrapper
      isVisible={isVisible}
      visibleClass='tw-flex'
      className={clsx(
        'tw-fixed tw-inset-0 tw-top-[var(--pdf-viewer-topbar-height)]',
        'tw-z-max tw-bg-white tw-font-sans',
        'tw-shrink-0 tw-flex-col',
        'lg:tw-static lg:tw-w-96',
        'tw-border-0 tw-border-solid tw-border-gray-200 lg:tw-border-r'
      )}
    >
      <Tabs currentTab={currentTab} tabs={tabMap} onClick={handleTabClick} />

      <div className='tw-h-full tw-overflow-auto tw-px-4 tw-py-6'>
        <VisibilityWrapper
          isVisible={currentTab === tabMap.Agenda}
          visibleClass='tw-flex'
          className='tw-flex-col tw-gap-2'
        >
          {agendaTree['root'].map(renderAgendaItem)}
        </VisibilityWrapper>

        <VisibilityWrapper
          isVisible={currentTab === tabMap.Attendees}
          visibleClass='tw-flex'
          className='tw-flex-col tw-gap-2'
        >
          <LoadingIndicator isVisible={isLoadingAnalytics} size={24} />

          {analytics.map((analytics) => (
            <AnalyticsItem key={analytics.contact.id} {...analytics} />
          ))}
        </VisibilityWrapper>
      </div>

      {isAnnotatable && (
        <div className='tw-flex tw-justify-center tw-py-3'>
          <Switch
            labelBefore={intl.formatMessage({ id: 'PdfViewerSidebar.private' })}
            labelAfter={intl.formatMessage({ id: 'PdfViewerSidebar.public' })}
            isChecked={publicAnnotations}
            onChange={togglePublicAnnotations}
          />
        </div>
      )}
    </VisibilityWrapper>
  );
};

PdfViewerSidebar.propTypes = {
  isVisible: PropTypes.bool,
  isAnnotatable: PropTypes.bool,
  isLoadingAnalytics: PropTypes.bool,
  meeting: PropTypes.object.isRequired,
  agendas: PropTypes.array,
  analytics: PropTypes.array,
  scrollToPage: PropTypes.func,
  onToggleAgenda: PropTypes.func,
};

export default PdfViewerSidebar;
