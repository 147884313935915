import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';

export const wrapper = css`
  .wrapper {
    border: 1px solid transparent;
    border-color: rgba(197, 202, 218, 0.6);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 450px;
    height: 190px;
    padding: 15px 15px 0px 15px;
    max-width: 100%;
  }

  .wrapper.has-error {
    border-color: ${colors.red};
  }

  .email-wrapper {
    overflow-y: auto;
    height: 150px;
    align-content: flex-start;
  }

  .input {
    width: 100%;
    height: 45px;
    border: none;
    margin-top: auto;

    &::placeholder {
      color: ${colors.mediumGrey} !important;
      opacity: 1;
    }
  }

  .email-error {
    top: 196px;
    left: 0px;
  }

  .error {
    margin: 0;
    font-size: 90%;
    color: ${colors.red};
  }

  .tag-item {
    padding: 7px 2px 7px 7px;
    height: 30px;
    background-color: ${colors.grey};
    background-image: none;
    color: ${colors.black};
    border: 0 solid transparent;
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 3px;
  }

  .tag-item-button {
    ${fonts.bold}
    background-color: ${colors.grey};
    width: 22px;
    height: 22px;
    cursor: pointer;
    padding: 0;
    height: 30px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const importButton = css`
  margin-right: 20px;
  max-width: 100%;
  ${media.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 30px;
    width: 310px;
  }
  ${media.mobile} {
    margin-right: 0;
    margin-top: 16px;
  }
`;

export const importBlock = css`
  ${media.mobile} {
    display: flex;
    flex-direction: column-reverse;
  }
`;
