/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './DateLabel.styles';

const DateLabel = ({ label }) => {
  return (
    <div css={styles.wrapper}>
      <div css={styles.label}>{label}</div>
    </div>
  );
};

export { DateLabel };
