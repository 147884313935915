/** @jsx jsx */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { useEditable } from 'hooks/useEditable';
import { ContactPicker } from 'components/shared/ContactPicker';
import { Drawer } from 'components/shared/Drawer';
import { WorkspaceHeader } from './WorkspaceHeader';
import { EditableName } from '../EditableName';
import { Configure } from '../Configure';
import { Send } from '../Send';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ReactComponent as Checkmark } from 'images/icons/smart-sign-checkmark.svg';
import { ReactComponent as Document } from 'images/icons/smart-document.svg';
import { SignatoriesList } from './SignatoriesList';
import { ConfigureForMobile } from './ConfigureForMobile';
import { SendForMob } from '../Send/SendForMob';
import Header from '../Header';
import SendingSignProvider from '../../../contexts/MobSignContext';
import { SendingSignContext } from '../../../contexts/MobSignContext';
import api from '../API';
import { withIntl } from '../../../utils/v2/intl';
import * as styles from './Workspace.styles';
import { SendForSigningMob } from './styles';

export const WorkspaceWithProvider = ({ documentId, documentName, documentURL }) => {
  return (
    <SendingSignProvider>
      <Workspace documentId={documentId} documentName={documentName} documentURL={documentURL} />
    </SendingSignProvider>
  );
};

const Workspace = ({ documentId, documentName, documentURL }) => {
  const { isEditing, startEditing, stopEditing } = useEditable();
  const { isOpendSendingSign, setIsOpendSendingSign, isOpendSignModal, setIsOpendSignModal } =
    useContext(SendingSignContext);

  const [filename, setFilename] = useState(documentName);
  const [updateError, setUpdateError] = useState(null);
  const { isMobile, isDesktop } = useWindowDimensions();

  const handleUpdateError = ({ message }) => setUpdateError(message);
  const handleUpdate = (response) => {
    setFilename(response.name);
    stopEditing();
  };
  const updateFilename = (name) => {
    api.updateSmartDocument(documentId, { name }, handleUpdate, handleUpdateError);
  };

  const [signatories, setSignatories] = useState([]);
  const [documentFields, setDocumentFields] = useState([]);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({ sendDocument: [] });
  const [requestInProgress, setRequestInProgress] = useState(false);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [documentIsConfigured, setDocumentIsConfigured] = useState(false);
  const [documentSent, setDocumentSent] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [moveToMobConfigure, setMoveToMobConfigure] = useState(false);

  useEffect(() => {
    api.loadSignatories(documentId, setSignatories);
  }, []);

  useEffect(() => {
    api.loadFields(documentId, buildDocumentFields);
  }, [signatories]);

  useEffect(() => {
    const signatureFields = documentFields.filter(isSignature);
    const signatureFieldIds = signatureFields.map((documentField) => documentField.signatoryId);
    const everyoneSigned = signatories.every((signatory) => signatureFieldIds.includes(signatory.id));

    setDocumentIsConfigured(signatories.length > 0 && documentFields.length > 0 && everyoneSigned);
  }, [signatories, documentFields]);

  const fieldTypes = {
    signature: 'signature',
    date: 'date_signed',
  };

  const dateTypes = {
    '%d/%m/%Y': 'DD/MM/YYYY',
    '%m/%d/%Y': 'MM/DD/YYYY',
    '%Y/%m/%d': 'YYYY/MM/DD',
    '%d - %m - %Y': 'DD - MM - YYYY',
    '%m - %d - %Y': 'MM - DD - YYYY',
    '%Y - %m - %d': 'YYYY - MM - DD',
  };

  const buildDocumentFields = (response) => {
    setDocumentFields(transformDocumentFields(response));
  };

  const transformDocumentFields = (items) => {
    return items.map(({ attributes }) => {
      return {
        id: attributes.id,
        signatoryId: attributes.signatory_id,
        signatoryName: attributes.signatory_name,
        position: {
          x: attributes.position_x,
          y: attributes.position_y,
        },
        page: attributes.page,
        type: attributes.field_type,
        width: attributes.width,
        height: attributes.height,
        deleted: false,
        value: dateTypes[attributes.format],
        format: attributes.format,
      };
    });
  };

  const isSignature = (documentField) => {
    return !documentField.deleted && documentField.type === fieldTypes.signature;
  };

  const createSignatory = (contact) => {
    if (requestInProgress) return;

    setRequestInProgress(true);
    api.createSignatory(documentId, contact, addSignatory);
  };

  const addSignatory = (signatory) => {
    setRequestInProgress(false);
    setSignatories([...signatories, signatory]);
  };

  const removeSignatory = (signatory) => {
    if (requestInProgress) return;

    setRequestInProgress(true);

    api.deleteSignatory(documentId, signatory.id, (response) => {
      const keptSignatories = signatories.filter((item) => item.id != signatory.id);

      setRequestInProgress(false);
      setSignatories(keptSignatories);
    });
  };

  const findOption = (option) => {
    const key = option.hasOwnProperty('withContact') ? 'id' : 'contact_id';

    return signatories.find((signatory) => signatory[key] === option.id);
  };

  const toggleSignatory = (contact) => {
    const signatory = findOption(contact);

    if (signatory) {
      removeSignatory(signatory);
    } else {
      createSignatory(contact);
    }
  };

  const closeDrawer = () => {
    document.querySelector('body').classList.remove('opaque-bg');
    setDrawerIsOpen(false);
    setDrawerIsOpen(false);
  };

  const openDrawer = () => {
    if (signatories.length) {
      document.querySelector('body').classList.add('opaque-bg');
      setDrawerIsOpen(true);
    }
  };

  const onConfigure = useCallback(() => {
    isDesktop ? openDrawer() : setMoveToMobConfigure(true);
  }, [openDrawer, isDesktop, setMoveToMobConfigure]);

  const openModal = () => {
    if (documentIsConfigured) {
      if (isMobile) {
        setIsOpendSendingSign(true);
      } else {
        setIsOpendSignModal(true);
      }
    }
  };

  const closeModal = () => {
    const companySlug = getCompanySlug();
    const smartDocumentsURL = `/${companySlug}/smart_sign`;
    if (documentSent) {
      window.location.assign(smartDocumentsURL);
    } else {
      setIsOpendSignModal(false);
    }
  };

  const sendDocument = () => {
    setShowLoadingSpinner(true);

    api.updateDocumentFields(documentId, documentFields, () => {
      api.sendDocument(
        documentId,
        message,
        () => {
          setDocumentSent(true);
          setShowLoadingSpinner(false);
        },
        (error) => {
          setShowLoadingSpinner(false);
          setErrors({ sendDocument: error.response.data.errors });
          api.loadSignatories(documentId, setSignatories);
        }
      );
    });
  };

  const updateDocumentFields = () => {
    api.updateDocumentFields(documentId, documentFields, (response) => {
      buildDocumentFields(response);
      closeDrawer();
    });
  };

  const discardChanges = () => {
    closeDrawer();
    api.loadFields(documentId, buildDocumentFields);
  };

  const persistContact = (contact, onSubmit, onError) => {
    api.createSignatory(documentId, contact, onSubmit, onError);
  };

  if (!isDesktop && !isOpendSendingSign && moveToMobConfigure) {
    return (
      <>
        <ConfigureForMobile
          setMoveToMobConfigure={setMoveToMobConfigure}
          fieldTypes={fieldTypes}
          dateTypes={dateTypes}
          changeDocumentFields={setDocumentFields}
          documentFields={documentFields}
          signatories={signatories}
          isSignature={isSignature}
          documentURL={documentURL}
          documentIsConfigured={documentIsConfigured}
          saveConfiguration={updateDocumentFields}
          setDrawerIsOpen={setDrawerIsOpen}
          drawerIsOpen={drawerIsOpen}
        />
        <Send
          show={isOpendSignModal}
          message={message}
          setMessage={setMessage}
          showSpinner={showLoadingSpinner}
          documentSent={documentSent}
          onSend={sendDocument}
          onClose={closeModal}
          filename={filename}
          signatories={signatories}
          errors={errors.sendDocument}
        />
      </>
    );
  }

  if (isOpendSendingSign && isMobile) {
    return (
      <SendForMob
        message={message}
        setMessage={setMessage}
        documentSent={documentSent}
        onSend={sendDocument}
        filename={filename}
        signatories={signatories}
        errors={errors.sendDocument}
        onClose={closeModal}
      />
    );
  }

  return (
    <>
      {!isDesktop && <Header type='edit' />}
      <Container fluid='lg'>
        {isDesktop && <WorkspaceHeader documentIsConfigured={documentIsConfigured} openModal={openModal} />}

        <Row>
          <Col lg={6}>
            <div css={styles.panel}>
              <div css={styles.heading}>
                <span css={styles.step}>
                  <FormattedMessage id='Workspace.step1' />
                </span>
                <span>
                  <FormattedMessage id='Workspace.addSign' />
                </span>
              </div>

              <div css={styles.subHeading}>
                <FormattedMessage id='Workspace.step1Sub' />
              </div>

              <ContactPicker
                values={signatories}
                withInviteOption
                onToggleOption={toggleSignatory}
                isSelected={findOption}
                addContact={addSignatory}
                persistContact={persistContact}
                withPending
              />

              <div css={styles.tableWrapper} className='m-t-25' id='signatories-table'>
                {signatories.length > 0 && (
                  <div css={styles.tableHeading}>
                    <FormattedMessage id='Workspace.signList' values={{ count: signatories.length }} />
                  </div>
                )}

                <SignatoriesList signatories={signatories} onDelete={removeSignatory} />
              </div>
            </div>
          </Col>

          <Col lg={6} className='mt-2 mt-lg-0'>
            <div css={styles.panel}>
              <div css={styles.heading}>
                <span css={styles.step}>
                  <FormattedMessage id='Workspace.step2' />
                </span>
                <span>
                  <FormattedMessage id='Workspace.configureDoc' />
                </span>
              </div>

              <div css={styles.configure}>
                <div css={styles.document}>
                  {documentIsConfigured && <Checkmark css={styles.checkmarkIcon} className='d-flex m-0' />}

                  <Document css={styles.documentIcon(documentIsConfigured)} />

                  <div css={styles.documentName}>
                    <EditableName
                      value={filename}
                      error={updateError}
                      isEditing={isEditing}
                      onClick={startEditing}
                      onCancel={stopEditing}
                      onUpdate={updateFilename}
                    />
                  </div>
                </div>
                <button
                  onClick={onConfigure}
                  disabled={!signatories.length}
                  className={`btn ${signatories.length ? 'btn-primary' : 'btn-disabled'}`}
                >
                  <FormattedMessage id='Workspace.configure' />
                </button>
              </div>
              {!isDesktop && documentIsConfigured && (
                <SendForSigningMob>
                  <button
                    disabled={!documentIsConfigured}
                    onClick={openModal}
                    className={`btn ${documentIsConfigured ? 'btn-primary' : 'btn-disabled'}`}
                  >
                    <FormattedMessage id='Workspace.sendSign' />
                  </button>
                </SendForSigningMob>
              )}
            </div>
          </Col>

          <Send
            show={isOpendSignModal}
            message={message}
            setMessage={setMessage}
            showSpinner={showLoadingSpinner}
            documentSent={documentSent}
            onSend={sendDocument}
            onClose={closeModal}
            filename={filename}
            signatories={signatories}
            errors={errors.sendDocument}
          />

          <Drawer drawerId='react-configure-drawer' styles={styles.drawer(drawerIsOpen)}>
            <Configure
              fieldTypes={fieldTypes}
              dateTypes={dateTypes}
              changeDocumentFields={setDocumentFields}
              documentFields={documentFields}
              signatories={signatories}
              isSignature={isSignature}
              documentURL={documentURL}
              documentIsConfigured={documentIsConfigured}
              saveConfiguration={updateDocumentFields}
              onContinueToSigning={openModal}
              discardChanges={discardChanges}
            />
          </Drawer>
        </Row>
      </Container>
    </>
  );
};

export default withIntl(WorkspaceWithProvider);
