/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { COLORS } from './constants';

import * as styles from './TextBadge.styles';

const TextBadge = ({ color = 'orange', text, className }) => (
  <div className={className} css={[styles.badge.base, styles.badge[color]]}>
    {text}
  </div>
);

TextBadge.propTypes = {
  color: PropTypes.oneOf(COLORS),
  text: PropTypes.string,
  className: PropTypes.string,
};

export default TextBadge;
