import { intl } from '../../../../contexts/v2/TranslationContext';

const SHARED = { id: 'shared', name: intl.formatMessage({ id: 'VirtualSidebarItems.sharedWithMe' }), slug: 'shared' };
const RECENTS = {
  id: 'recents',
  name: intl.formatMessage({ id: 'VirtualSidebarItems.recents' }),
  slug: 'recents',
};
const FAVOURITES = {
  id: 'favourites',
  name: intl.formatMessage({ id: 'VirtualSidebarItems.favourites' }),
  slug: 'favourites',
};

const VIRTUAL_SIDEBAR_ITEMS = [RECENTS, FAVOURITES, SHARED];

export { SHARED, RECENTS, FAVOURITES, VIRTUAL_SIDEBAR_ITEMS };
