import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

import { NAV_ITEM_WIDTH } from './constants';

export const mobileNavWrapper = css`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 66px;
  padding: 11px 0 7px;
  background-color: ${colors.white};
  box-shadow: 0px 3px 25px rgba(25, 35, 89, 0.12);
  z-index: 1000;
  align-items: center;
  ${media.tablet} {
    display: flex;
  }
`;

export const navContainer = (padding) => css`
  display: flex;
  padding: 0 ${`${padding}px`};
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${media.mobile} {
    padding: 0;
    justify-content: center;
  }
`;

export const navItem = (navItemWidth = NAV_ITEM_WIDTH, insideMore = false) => css`
  width: ${insideMore ? '100%' : `${navItemWidth}px`};
  min-width: ${insideMore ? '100%' : `${navItemWidth}px`};
  height: 48px;
  color: ${colors.lightGrey};
  display: flex;
  flex-direction: ${insideMore ? 'row' : 'column'};
  justify-content: ${insideMore ? 'flex-start' : 'flex-end'};
  align-items: center;
  font-size: 20px;
  &:focus,
  &:active {
    color: ${colors.lightGrey};
  }
`;

export const navTitle = ({ insideMore = false, isActive }) => css`
  color: ${isActive ? colors.black : colors.lightGrey};
  ${fonts.styledMedium}
  font-weight: 500;
  font-size: ${insideMore ? '14px' : '12px'};
  line-height: ${insideMore ? '16px' : '14px'};
  margin-left: ${insideMore ? '12px' : '0'};
  white-space: nowrap;
  display: flex;
  height: 24px;
  align-items: center;
`;

export const navIconWrapper = ({ insideMore = false, isActive }) => css`
  line-height: 1;
  ${insideMore && 'min-width: 25px;'}
  color: ${isActive ? colors.cyan : 'inherit'}
`;

export const moreItems = (visible) => css`
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: ${colors.white};
  transition: all 0.2s ease-out;
  transform: ${visible ? 'translate(0, 0%)' : 'translate(0, 100%)'};
`;

export const moreItems_header = css`
  ${fonts.styledBold}
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.black};
  margin-bottom: 20px;
`;

export const moreItems_close = css`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
