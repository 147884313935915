import { useSubscription } from '@apollo/client';

import ANNOTATION_CREATED_SUBSCRIPTION from '../subscriptions/AnnotationCreatedSubscription';
import ANNOTATION_UPDATED_SUBSCRIPTION from '../subscriptions/AnnotationUpdatedSubscription';
import ANNOTATION_REMOVED_SUBSCRIPTION from '../subscriptions/AnnotationRemovedSubscription';

const useAnnotationSubscription = ({ onCreated, onUpdated, onRemoved }) => {
  useSubscription(ANNOTATION_CREATED_SUBSCRIPTION, {
    shouldResubscribe: true,
    onSubscriptionData: ({ subscriptionData }) => {
      const {
        data: { annotationCreated },
      } = subscriptionData;

      onCreated(annotationCreated);
    },
  });

  useSubscription(ANNOTATION_UPDATED_SUBSCRIPTION, {
    shouldResubscribe: true,
    onSubscriptionData: ({ subscriptionData }) => {
      const {
        data: { annotationUpdated },
      } = subscriptionData;

      onUpdated(annotationUpdated);
    },
  });

  useSubscription(ANNOTATION_REMOVED_SUBSCRIPTION, {
    shouldResubscribe: true,
    onSubscriptionData: ({ subscriptionData }) => {
      const {
        data: { annotationDeleted },
      } = subscriptionData;

      onRemoved(annotationDeleted);
    },
  });
};

export default useAnnotationSubscription;
