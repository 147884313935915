import { gql, useMutation } from '@apollo/client';
import { SUBCOMMITTEE_FRAGMENT } from '../fragments/SubcommitteeFragment';

const CREATE_SUBCOMMITTEE_MUTATION = gql`
  mutation createSubcommittee($input: CreateSubcommitteeInput!) {
    createSubcommittee(input: $input) {
      subcommittee {
        ...SubcommitteeFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${SUBCOMMITTEE_FRAGMENT}
`;

const useCreateSubcommitteeMutation = (options) => useMutation(CREATE_SUBCOMMITTEE_MUTATION, options);

export { CREATE_SUBCOMMITTEE_MUTATION, useCreateSubcommitteeMutation };
