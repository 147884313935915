export const documentStatusBadgeVariantMap = {
  draft: 'gray',
  completed: 'green',
  signed: 'teal',
  pending: 'orange',
  recalled: 'red',
  corrupted: 'black',
};

export const signatoryStatusBadgeVariantMap = {
  pending: 'orange',
  signed: 'teal',
  viewed: 'purple',
  bounced: 'red',
};
