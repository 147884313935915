import React from 'react';

export const withContext = (Provider, Component) => (props) => {
  return (
    <Provider>
      <Component {...props} />
    </Provider>
  );
};

export const sliceById = (array, payload) => {
  return array.slice(0, array.indexOf(payload) + 1);
};

export const toggleById = (array, payload) => {
  const isExisting = array.find((item) => item.id === payload.id);

  if (isExisting) {
    return deleteById(array, payload);
  } else {
    return [...array, payload];
  }
};

export const deleteById = (array, payload) => {
  return array.filter((item) => item.id !== payload.id);
};

export const updateById = (array, payload) => {
  return array.map((item) => {
    if (item.id !== payload.id) {
      return item;
    }

    return { ...item, ...payload };
  });
};
