/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import * as styles from './DropdownOption.styles';

const DropdownOption = memo(({ noResults, onClick, disabled, ...props }) => {
  if (noResults) {
    return (
      <div css={styles.noResults}>
        <FormattedMessage id='DropdownOption.noResults' />
      </div>
    );
  }

  return (
    <div onClick={onClick} css={styles.option} className={`react-picker-option ${disabled ? 'disabled' : ''}`}>
      {props.children}
    </div>
  );
});

export { DropdownOption };
