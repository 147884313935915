import { useState, useEffect } from 'react';
import { getCurrentBreakpoint } from 'helpers/tailwindStyling';
import useWindowDimensions from 'hooks/useWindowDimensions';

export const useTailwindBreakpoints = () => {
  const dimensions = useWindowDimensions();
  const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint(dimensions.width));

  useEffect(() => {
    setBreakpoint(getCurrentBreakpoint(dimensions.width));
  }, [dimensions.width]);

  return [breakpoint];
};
