import { useDeleteDirectoryMutation } from './../data/mutations/DeleteDirectoryMutation';
import { useApolloCacheHelpers } from './../hooks/useApolloCacheHelpers';
import { useFlashActions } from 'store/flashStoreContext';

const useDeleteDirectory = ({ id }) => {
  const {
    removeDirectoryFromCache,
    evictRecentDirectoriesCache,
    evictFavouriteDirectoriesCache,
    evictSearchDirectoriesCache,
  } = useApolloCacheHelpers();

  const { setFlash } = useFlashActions();

  const onError = ({ message }) => setFlash(message);

  const update = (cache, { data: { deleteDirectory } }) => {
    const { directory } = deleteDirectory;
    const { breadcrumbs } = directory;

    const parent = breadcrumbs[breadcrumbs.length - 2];

    removeDirectoryFromCache(parent, directory);

    evictRecentDirectoriesCache();
    evictFavouriteDirectoriesCache();
    evictSearchDirectoriesCache();
  };

  const [mutate, { loading: isDeleting }] = useDeleteDirectoryMutation({ update, onError });

  const deleteDirectory = () => {
    mutate({ variables: { input: { directoryId: id } } });
  };

  return { isDeleting, deleteDirectory };
};

export { useDeleteDirectory };
