/** @jsx jsx */

import React, { memo, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { useFlashActions } from 'store/flashStoreContext';
import { FormattedMessage } from 'react-intl';

const ButtonWithSuccess = memo(({ className, disableAfter, ...props }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [klassName, setKlassName] = useState(className);
  const { setFlash } = useFlashActions();

  useEffect(() => {
    setKlassName(className);
  }, [className]);

  const showSuccess = () => {
    setIsSuccess(true);
    setKlassName(`${klassName} btn-secondary btn-inverted c-secondary`);

    setTimeout(() => {
      setIsSuccess(false);
      setKlassName(`${klassName} ${disableAfter ? 'btn-disabled' : ''}`);
    }, 3000);
  };

  const handleClick = () => {
    props.onClick && props.onClick().then(showSuccess).catch(setFlash);
  };

  const successBody = (
    <>
      <i className='fa fa-check m-r-5' />
      <span>
        <FormattedMessage id='ButtonWithSuccess.success' />
      </span>
    </>
  );

  const body = isSuccess ? successBody : props.children;
  const isDisabled = klassName.includes('btn-disabled');

  return (
    <button {...props} disabled={isDisabled} className={klassName} onClick={handleClick}>
      {body}
    </button>
  );
});

export { ButtonWithSuccess };
