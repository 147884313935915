/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import Box from './../Box';
import Button from './../Button';
import TextInput from './../TextInput';
import TextButton from './../TextButton';
import TextAreaInput from './../TextAreaInput';

import * as styles from './ResourceManager.styles';

const ResourceManager = ({ className, inputMap, renderHeader, renderButton }) => {
  const [visibleInputKeys, setVisibleInputKeys] = useState([]);

  const isVisible = (key) => visibleInputKeys.indexOf(key) > -1;
  const toggleVisibility = (key) => () => setVisibleInputKeys((state) => [...state, key]);
  const resetResourceManager = () => setVisibleInputKeys([]);

  const renderInputToggles = Object.keys(inputMap).map((key) => (
    <TextButton
      key={`resource-manager-${key}-toggle`}
      isDisabled={isVisible(key)}
      onClick={toggleVisibility(key)}
      text={`+ ${inputMap[key].label}`}
    />
  ));

  const renderInputs = visibleInputKeys.map((key) => (
    <div key={`resource-manager-${key}-input`} className='mb-2'>
      {inputMap[key].component}
    </div>
  ));

  return (
    <Box variant='manager' className={className}>
      <Row noGutters className='justify-content-center'>
        {renderHeader}

        <Col lg={11} className='mb-4 d-flex justify-content-between'>
          {renderInputToggles}
        </Col>

        <Col lg={11} className='mb-4'>
          {renderInputs}
        </Col>

        <Col lg={11}>{renderButton({ resetResourceManager })}</Col>
      </Row>
    </Box>
  );
};

ResourceManager.propTypes = {
  renderHeader: PropTypes.node,
  renderButton: PropTypes.func,
  className: PropTypes.string,
  inputMap: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string,
      component: PropTypes.node,
    })
  ),
};

export default ResourceManager;
