import React, { memo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { withIntl } from '../../utils/v2/intl';
import { AlertPopup } from '../shared/AlertPopup';
import { useClickOutside } from 'hooks/useClickOutside';

const LockedContentPopup = memo(({ contentTitle, show, onClose }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(show || false);

  const intl = useIntl();

  useEffect(() => setIsComponentVisible(show), [show]);

  const open = () => setIsComponentVisible(true);

  const hide = (e) => {
    e.stopPropagation();

    if (onClose) {
      onClose();
    } else {
      setIsComponentVisible(false);
    }
  };

  return (
    <>
      {!onClose && (
        <div onClick={open} className='cursor-pointer'>
          {contentTitle}
        </div>
      )}

      <AlertPopup
        show={isComponentVisible}
        headerText={intl.formatMessage({ id: 'LockedContentPopup.lockedContent' })}
        onClose={hide}
        popupType='info'
      >
        <div className='text-black fs-14'>
          <FormattedMessage id='LockedContentPopup.noPermission' />
        </div>

        <a className='btn btn-info w-180 m-t-40' onClick={hide}>
          <FormattedMessage id='LockedContentPopup.ok' />
        </a>
      </AlertPopup>
    </>
  );
});

export default withIntl(LockedContentPopup);
