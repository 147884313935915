import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const table = css`
  left: 0;
  width: 100%;
  table-layout: fixed;
`;

export const tableHeader = (width) => css`
  ${fonts.bold}
  color: ${colors.black};
  font-size: 12px;
  line-height: 20px;
  width: ${width}%;
`;

export const tableCell = css`
  font-size: 14px;
  color: ${colors.black};
`;

export const tableRow = (isLast) => css`
  border-bottom: 1px solid;
  border-color: ${isLast ? 'transparent' : colors.grey};
`;
