import React, { useState } from 'react';
import { ReactComponent as LogoIcon } from 'images/icons/logo-footer.svg';
import Switch from 'components/v2/shared/Switch';
import ImageUpload from 'components/v2/shared/ImageUpload';
import Alert from 'components/v2/shared/Alert';
import TextInput from 'components/v2/shared/TextInput';
import Button from 'components/v2/shared/Button';
import Label from 'components/v2/shared/Label';
import clsx from 'clsx';
import Heading from 'components/v2/shared/Heading';
import Text from 'components/v2/shared/Text';
import InviteUsers from './InviteUsers';

const AddCompany = ({ onClick }) => {
  const [errors, setErrors] = useState({});
  const [isActive, setIsActive] = useState({
    use_company_name: false,
  });
  const [isUser, setIsUser] = useState(false);
  const [companyData, setCompanyData] = useState({
    name: '',
    slug: '',
    crn: '',
    taxId: '',
    address1: '',
    address2: '',
    website: '',
    isPrimary: true,
  });

  const roles = [
    {
      label: 'By upcoming',
      value: 'upcoming',
    },
    {
      label: 'By upcoming',
      value: 'upcoming',
    },
    {
      label: 'By upcoming',
      value: 'upcoming',
    },
  ];

  const onSwitchChangeHandle = (name) => {
    setIsActive((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const onTextChangeHandle = (event) => {
    const { name, value } = event.target;
    setCompanyData((prev) => ({ ...prev, [name]: value }));
  };
  const onContinue = (event) => {
    if (event == 'invite-users') {
      setIsUser(true);
    }
  };
  const onClickPreviousPage = (event) => {
    setIsUser(event);
  };
  const onClickCompany = (event) => {
    onClick(event);
  };
  return (
    <>
      {!isUser && (
        <div className='tw-container tw-mx-auto tw-flex tw-max-w-full tw-justify-center tw-bg-white tw-py-6 tw-px-5 sm:tw-px-10'>
          <div className='tw-relative tw-w-full tw-pb-10 sm:tw-w-[450px]'>
            <div className='tw-mb-10 tw-flex tw-place-content-center tw-items-center tw-gap-4'>
              <LogoIcon className='!tw-h-12 !tw-w-24' />
            </div>
            <Heading text='Create a new company' className='tw-text-center !tw-text-3xl' />
            <Text
              className='tw-text-center !tw-text-base	tw-font-medium'
              text='You must be the legal owner or representative or the
company to register it on Shaparency'
            />
            <div className='tw-mt-10 tw-flex'>
              <div className='tw-mt-8 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Logo</div>
              <div className='tw-ml-8 sm:tw-ml-14'>
                <ImageUpload text='Logo' variant='company' />
              </div>
            </div>
            <div className='tw-mt-11 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Registered details</div>
            <div className='tw-mt-10'>
              <Alert
                description='Company name, registered number and boardspace name cannot be changed after company is saved.'
                severity='warning'
                isDismissable={false}
                className='tw-font-sans'
              />
            </div>
            <div className='tw-mt-8'>
              <TextInput
                onChange={onTextChangeHandle}
                label='Name of company'
                name='name'
                value={companyData.name}
                size='large'
              />
            </div>
            <div className='tw-mt-7 tw-text-black'>
              <TextInput
                onChange={onTextChangeHandle}
                label='Registered company number'
                name='crn'
                value={companyData.crn}
                size='large'
              />
            </div>
            <div className='tw-mt-7'>
              <TextInput
                onChange={onTextChangeHandle}
                value={companyData.taxId}
                label='Tax identification number'
                name='taxId'
                size='large'
              />
            </div>
            <div className='tw-mt-11 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>
              Registered Company Address
            </div>
            <div className='tw-mt-8'>
              <TextInput
                onChange={onTextChangeHandle}
                value={companyData.address1}
                label='Address Line 1'
                name='address1'
                size='large'
              />
            </div>
            <div className='tw-mt-7'>
              <TextInput
                onChange={onTextChangeHandle}
                value={companyData.address2}
                label='Address Line 2'
                name='address2'
                size='large'
              />
            </div>
            <div className='tw-mt-11 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Website</div>
            <div className='tw-mt-5'>
              <TextInput
                onChange={onTextChangeHandle}
                value={companyData.website}
                label='URL'
                name='website'
                size='large'
              />
            </div>
            <div className='tw-mt-12 tw-justify-between sm:tw-flex'>
              <Label text='Name of board' className='!tw-mb-0 tw-font-sans tw-text-sm tw-font-semibold' />
              <div className='tw-flex'>
                <div className='tw-pr-5 tw-font-sans tw-text-sm tw-font-medium tw-text-black/[0.3]'>
                  Use company name
                </div>
                <Switch
                  variant={isActive.use_company_name ? 'gray' : 'gray-bg'}
                  onChange={() => onSwitchChangeHandle('use_company_name')}
                  isChecked={isActive.use_company_name}
                  isGray={isActive.use_company_name}
                />
              </div>
            </div>
            <div className='tw-mt-5'>
              <TextInput
                onChange={onTextChangeHandle}
                name='slug'
                value={isActive.use_company_name ? companyData.name : companyData.slug}
                size='large'
              />
            </div>
            <div className='tw-mt-11'>
              <Button
                font='semibold'
                isFullWidth
                size='large'
                variant='purple'
                // onClick={() => onContinue('invite-users')}
              >
                Create company
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* ------------------------Invite Contact-------------------------- */}
      <InviteUsers roles={roles} onClickPage={onClickPreviousPage} onClick={onClickCompany} />
    </>
  );
};
export default AddCompany;
