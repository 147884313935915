/** @jsx jsx */
import React, { memo, useState, useRef, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import * as styles from './EmailChips.styles';

import { useKeyPress } from 'hooks/useKeyPress';

const EmailChips = memo(({ onImport, importedEmails }) => {
  const addInputRef = useRef(null);

  const intl = useIntl();

  const emailRegExp = new RegExp(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [validationError, setValidationError] = useState(null);

  const onWrapperClick = () => {
    return addInputRef.current.focus();
  };

  const Enter = useKeyPress('Enter');
  const Tab = useKeyPress('Tab');
  const Comma = useKeyPress(',');

  const handleKeyPress = () => {
    if (!inputValue) return;

    if (Enter || Tab || Comma) {
      event.preventDefault();

      var value = inputValue.trim();

      if (value && isValid(value)) {
        setEmails((currentEmails) => Array.from(new Set([...currentEmails, value])));
        setInputValue('');
      }
    }
  };

  useEffect(() => handleKeyPress(), [Enter, Tab, Comma]);

  const handleChange = () => {
    setInputValue(event.target.value);
    setValidationError(null);
  };

  const handleDelete = (emailToDelete) => {
    return setEmails(emails.filter((email) => email !== emailToDelete));
  };

  const handleImport = () => {
    onImport(emails);

    setEmails([]);
  };

  const handlePaste = () => {
    event.preventDefault();

    var paste = event.clipboardData.getData('text');
    var emails = paste.match(emailRegExp, 'g');

    if (emails) {
      var toBeAdded = emails.filter((email) => !isInList(email));

      setEmails((currentEmails) => Array.from(new Set([...currentEmails, ...toBeAdded])));
    }
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = intl.formatMessage({ id: 'EmailChips.emailAdded' }, { email });
    }

    if (!isEmail(email)) {
      error = intl.formatMessage({ id: 'EmailChips.emailInvalid' }, { email });
    }

    if (error) {
      setValidationError(error);

      return false;
    }

    return true;
  };

  const isInList = (email) => {
    return emails.includes(email) || importedEmails.includes(email);
  };

  const isEmail = (email) => {
    return emailRegExp.test(email);
  };

  const addedEmails = emails.map((email, index) => {
    const onDelete = handleDelete.bind(this, email);

    return (
      <div className='tag-item' key={index}>
        {email}
        <div type='button' className='tag-item-button' onClick={onDelete}>
          x
        </div>
      </div>
    );
  });

  return (
    <>
      <div css={styles.wrapper}>
        <div
          className={`d-flex flex-column m-b-30 wrapper ${validationError ? 'has-error' : ''}`}
          onClick={onWrapperClick}
        >
          <div className='d-flex flex-wrap email-wrapper'>{addedEmails}</div>

          <input
            className='input fs-14'
            value={inputValue}
            placeholder={intl.formatMessage({ id: 'EmailChips.addEmails' })}
            onChange={handleChange}
            onPaste={handlePaste}
            ref={addInputRef}
          />
        </div>

        <div className='d-flex m-t-15' css={styles.importBlock}>
          <a
            className={`btn btn-primary m-b-20 ${emails.length ? '' : 'disabled'}`}
            onClick={handleImport}
            css={styles.importButton}
          >
            <span>
              <FormattedMessage id='EmailChips.import' />
            </span>
          </a>
          <div className='d-flex flex-column'>
            <div className='text-black fs-12'>
              <FormattedMessage id='EmailChips.typeOrPaste' />
            </div>
            <div className='text-black fs-12'>
              <FormattedMessage id='EmailChips.duplicates' />
            </div>
          </div>
        </div>

        {validationError && <div className='vote-error email-error'>{validationError}</div>}
      </div>
    </>
  );
});

export { EmailChips };
