/** @jsx jsx */
import React, { memo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'react-bootstrap/Modal';

import { Input } from 'components/shared/Input';
import { AlertPopup } from 'components/shared/AlertPopup';

import { useKeyPress } from 'hooks/useKeyPress';

import { ReactComponent as LockIcon } from 'images/icons/lock-secondary.svg';

import * as styles from './PasscodeModal.styles';

const PasscodeModal = memo(({ votePasscode, show, onVerify }) => {
  const [passcode, setPasscode] = useState('');
  const [passcodeVisible, setPasscodeVisible] = useState(false);
  const [showIncorrectPasscodeAlert, setShowIncorrectPasscodeAlert] = useState(false);

  const intl = useIntl();

  const onCloseClick = () => {
    return;
  };

  const [showPasscodeModal, setShowPasscodeModal] = useState(show);

  const hideIncorrectPasscodeAlert = () => {
    setPasscode('');

    return setShowIncorrectPasscodeAlert(false);
  };

  const Enter = useKeyPress('Enter');

  const handleKeyPress = (event) => {
    if (Enter) {
      if (showIncorrectPasscodeAlert) {
        return hideIncorrectPasscodeAlert();
      } else {
        return verifyPasscode();
      }
    }
  };

  useEffect(() => handleKeyPress(), [Enter]);

  const changePasscode = (event) => {
    return setPasscode(event.target.value);
  };

  const togglePasscodeVisible = () => setPasscodeVisible(!passcodeVisible);

  const passcodeMatched = () => {
    return passcode === votePasscode;
  };

  const verifyPasscode = () => {
    if (passcode.length == 0) return;

    if (passcodeMatched()) {
      const accessToken = new URLSearchParams(window.location.search).get('access_token');

      setShowPasscodeModal(false);
      Cookies.set('passcodeVerified', accessToken);
      onVerify();
    } else {
      return setShowIncorrectPasscodeAlert(true);
    }
  };

  return (
    <>
      <AlertPopup
        show={showIncorrectPasscodeAlert}
        headerText={intl.formatMessage({ id: 'PasscodeModal.incorrectPass' })}
        popupType='failure'
        onClose={hideIncorrectPasscodeAlert}
      >
        <div className='text-black fs-14 m-b-20'>
          <FormattedMessage id='PasscodeModal.incorrectPassText' />
        </div>
      </AlertPopup>

      <Modal centered show={showPasscodeModal} onHide={onCloseClick} onClose={onCloseClick} css={styles.passcodeModal}>
        <Modal.Header>
          <Modal.Title className='d-flex flex-row justify-content-start'>
            <LockIcon className='lock-icon' />
            <div className='text-black'>
              <FormattedMessage id='PasscodeModal.enterPass' />
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='fs-14 text-grey m-t-5 m-b-20'>
            <FormattedMessage id='PasscodeModal.passProtected' />
          </div>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='col-md-10 p-0'>
              <Input
                type={passcodeVisible ? 'text' : 'password'}
                className='m-t-5 passcode-input'
                name='passcode'
                value={passcode}
                placeholder={intl.formatMessage({ id: 'PasscodeModal.enterPass' })}
                onChange={changePasscode}
              />
            </div>
            <i
              className={`cursor-pointer eye-icon far ${passcodeVisible ? 'fa-eye' : 'fa-eye-slash'}`}
              onClick={togglePasscodeVisible}
            />
            <a
              className={`btn btn-secondary btn-show m-l-10 d-flex align-items-center ${
                passcode && passcode.length ? '' : 'disabled'
              }`}
              onClick={verifyPasscode}
            >
              <FormattedMessage id='PasscodeModal.ok' />
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

export { PasscodeModal };
