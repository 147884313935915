/** @jsx jsx */

import React, { memo, useEffect, createRef } from 'react';
import { jsx } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { FormattedMessage } from 'react-intl';

import { colors } from 'styles';
import * as styles from './Viewer.styles';

const Viewer = memo(
  ({
    documentURL,
    onDocumentLoad,
    onViewportMouseMove,
    onViewportMouseEnter,
    onViewportMouseLeave,
    onViewportMouseClick,
    pointerMode,
    viewportStyles,
    progressiveLoading,
  }) => {
    const documentLoadedEventName = 'pdfViewer:document_loaded';
    const viewer = createRef();

    useEffect(() => loadDocument(), []);

    const isProgressive = () => {
      if (progressiveLoading === null) {
        return true;
      } else {
        return progressiveLoading;
      }
    };

    const loadDocument = () => {
      viewer.current.addEventListener(documentLoadedEventName, onDocumentLoad);

      pdfViewer.loadFile(documentURL, isProgressive());
    };

    return (
      <div ref={viewer} id='pdf-viewer-container' className='position-relative'>
        <div id='pdf_app_viewer' className='pdfViewer'>
          <div id='loading' css={styles.loading}>
            <ClipLoader color={colors.cyan} />
          </div>
          <div id='toolbar' className='d-none'>
            <div id='pager'>
              <button className='prev'>
                <FormattedMessage id='Viewer.prev' />
              </button>
              <button className='next'>
                <FormattedMessage id='Viewer.next' />
              </button>
            </div>

            <div id='page-mode'>
              <label>
                <FormattedMessage id='Viewer.pageMode' />
              </label>
            </div>
          </div>

          <div
            css={[styles.viewport(pointerMode), viewportStyles]}
            onClick={onViewportMouseClick}
            onMouseMove={onViewportMouseMove}
            onMouseEnter={onViewportMouseEnter}
            onMouseLeave={onViewportMouseLeave}
            className='viewport'
            role='main'
          />
        </div>
      </div>
    );
  }
);

export { Viewer };
