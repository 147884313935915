import { css } from '@emotion/core';
import { panels, colors, fonts } from 'styles';

export const modal = css`
  .modal {
    overflow-y: hidden !important;
  }

  .modal-dialog {
    width: 450px;
    height: 590px;
    overflow-y: hidden;

    .modal-body,
    .modal-header {
      padding: 0 24px;

      #react-picker-input {
        height: 35px;
      }
    }

    .modal-content {
      svg {
        width: auto;
      }

      .contact-list {
        overflow-y: auto;
        height: 300px;
      }

      .react-picker-option {
        &.disabled {
          cursor: default;
        }
      }

      .footer {
        min-height: 90px;
      }
    }

    .close {
      position: absolute;
      top: 30px !important;
      right: 30px !important;
    }
  }
`;

export const breadcrumb = css`
  ${fonts.regular};
  cursor: pointer;
  font-size: 14px;
  color: ${colors.black};
  max-width: 250px !important;

  &.selected {
    ${fonts.bold}
    color: ${colors.black};
    cursor: default;
  }

  .text-truncate {
    max-width: 60px;
  }
`;
