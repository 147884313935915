import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const permissionsTable = css`
  position: relative;
  color: #00031f;
  table-layout: fixed;
  width: 100%;

  thead {
    th {
      padding: 10px;
      font-size: 14px;
      text-transform: uppercase;
      ${fonts.regular}
      text-align: center;
      border: none;

      &:first-of-type {
        width: 70%;
        border-right: 1px solid ${colors.headingGrey};
      }
    }
  }

  tr {
    &:first-of-type {
      border-top: none;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &.is-last {
      td {
        padding-bottom: 10px;
      }
    }
  }

  td {
    ${fonts.regular}
    font-size: 14px;
    text-align: center;
    color: ${colors.mediumGrey};
    padding: 0 0 0 60px;

    &:last-of-type {
      padding-left: 0;
    }

    &:first-of-type {
      text-align: left;
      border-right: 1px solid ${colors.headingGrey};
    }

    @media (max-width: 767px) {
      padding-left: 25px;
    }
  }
`;

export const resourceRow = css`
  td {
    ${fonts.semiBold}
    color: ${colors.black};
    padding: 10px 0 0 50px;
    border-top: 1px solid ${colors.headingGrey};

    @media (max-width: 767px) {
      padding-left: 15px;
    }
  }

  &.odd {
    background: ${colors.lightestGrey};

    ~ .odd {
      background: ${colors.lightestGrey};
    }
  }
`;
