/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import * as styles from './Menu.styles';

const Menu = ({ setRef, className, isOpen, children, ...props }) => (
  <div className={className} ref={setRef} {...props} css={[styles.container.base, isOpen && styles.container.open]}>
    {children}
  </div>
);

Menu.propTypes = {
  isOpen: PropTypes.bool,
  props: PropTypes.object,
  className: PropTypes.string,
  setRef: PropTypes.func,
  children: PropTypes.node,
};

export default Menu;
