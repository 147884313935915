import axios from 'axios';

import { intl } from '../../../contexts/v2/TranslationContext';

export default class API {
  static handleError({ response: { data } }) {
    let message = data.message || intl.formatMessage({ id: 'FileBrowser.error' });

    displayFlashMessage(message, 'error');
  }

  // Generic
  static searchFoldersAndDocuments(rootId, searchTerm, onLoad) {
    return axios
      .get(`/api/${getCompanySlug()}/v1/directories.json?directory_id=${rootId}&search_type=dir&query=${searchTerm}`)
      .then((response) => response.data)
      .then(onLoad)
      .catch(this.handleError);
  }

  // SmartSign specific
  static createSmartDocument(documentId, onSubmit, onError = this.handleError) {
    return axios
      .post(`/api/${getCompanySlug()}/smart_documents.json`, { smart_document: { document_id: documentId } })
      .then((response) => response.data)
      .then(onSubmit)
      .catch(onError);
  }
}
