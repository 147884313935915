import React, { memo, useRef, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Row, Col } from 'react-bootstrap';
import Multiselect from '../../shared/Select/Multiselect';
import { Search } from '../../shared/Search';
import { withIntl } from '../../../utils/v2/intl';

const Filters = memo(({ companySlug }) => {
  const intl = useIntl();

  const statusOptions = [
    { value: 'invited', label: intl.formatMessage({ id: 'Filters.pending' }) },
    { value: 'confirmed', label: intl.formatMessage({ id: 'Filters.confirmed' }) },
    { value: 'registered', label: intl.formatMessage({ id: 'Filters.active' }) },
    { value: 'archived', label: intl.formatMessage({ id: 'Filters.archived' }) },
    { value: 'rejected', label: intl.formatMessage({ id: 'Filters.rejected' }) },
  ];

  const initialStatusOptions = statusOptions.filter(({ value }) => value !== 'archived');
  const [selectedStatuses, setSelectedStatuses] = useState(initialStatusOptions);
  let [searchTerm, setSearchTerm] = useState('');
  let [isSearching, setIsSearching] = useState(false);

  const handleTermChange = (event) => {
    setSearchTerm(event.target.value || '');
  };

  const startSearching = () => {
    setIsSearching(true);
  };

  const stopSearching = () => {
    setIsSearching(false);
    setSearchTerm('');
  };

  const loadContacts = () => {
    const query = $.param({
      status: selectedStatuses.filter((option) => option.value).map((option) => option.value),
      search_term: searchTerm,
    });

    $.get(`/${companySlug}/settings/contacts.js?${query}`);
  };

  const ref = useRef();

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('contacts:reload', loadContacts);

      return () => {
        node.removeEventListener('contacts:reload', loadContacts);
      };
    }
  }, [selectedStatuses]);

  useEffect(() => loadContacts(), [selectedStatuses, searchTerm]);

  return (
    <>
      <Row noGutters>
        <Col xs={12} md={6} className='pr-0 pr-md-1 mb-2 mb-md-0'>
          <div className='d-flex shaparency-contacts-filter' ref={ref}>
            <Multiselect
              options={statusOptions}
              onChange={setSelectedStatuses}
              value={selectedStatuses}
              withAllOption
              classNamePrefix='status'
            />
          </div>
        </Col>

        <Col xs={12} md={6} className='pl-0 pl-md-1 mb-2 mb-md-0'>
          <div className='m-l-auto contacts-search'>
            <Search
              value={searchTerm}
              isFocused={isSearching}
              onChange={handleTermChange}
              onFocus={startSearching}
              onBlur={stopSearching}
            />
          </div>
        </Col>
      </Row>
    </>
  );
});

export default withIntl(Filters);
