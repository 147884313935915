import React from 'react';
import { useMeetingStatus as useChimeMeetingStatus } from 'amazon-chime-sdk-component-library-react';
import { useIntl } from 'react-intl';

import { useChimeMeetingContext } from 'store/chimeMeetingContext';
import { useMeetingStore } from 'store/meetingStoreContext';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import { MEETING_STATUS } from '../MeetingAmazonChime/constants';

const JoinChimeButton = () => {
  const { isLoading, getMeetingJoinInfo } = useChimeMeetingContext();

  const intl = useIntl();

  const { meeting } = useMeetingStore();
  const meetingStatus = useChimeMeetingStatus();

  const { isInSession } = useMeetingStatus(meeting.status);

  const handleButtonClick = () => {
    getMeetingJoinInfo();
  };

  if (meetingStatus === MEETING_STATUS.SUCCEEDED || !isInSession) {
    return null;
  } else {
    return (
      <button onClick={handleButtonClick} className='btn btn-responsive btn-secondary m-b-10'>
        <div className='d-flex align-items-center'>
          {isLoading ? (
            <span>{intl.formatMessage({ id: 'JoinChimeButton.loading' })}</span>
          ) : (
            <span>{intl.formatMessage({ id: 'JoinChimeButton.join' })}</span>
          )}
        </div>
      </button>
    );
  }
};

export default JoinChimeButton;
