/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { Modal } from 'components/shared/Modal';
import { ReactComponent as DownloadIcon } from 'images/icons/documents/archive-download-icon-lg.svg';

import * as styles from './DownloadModal.styles';

const DownloadModal = memo(({ show, resource, onClose }) => {
  return (
    <Modal onClose={onClose} modalStyles={styles.modal} show={show} centered>
      <div className='d-flex align-items-center'>
        <DownloadIcon className='flex-shrink-0 m-r-10' />
        <div className='fs-14 text-black lh-18'>
          <FormattedMessage
            id='DownloadModal.info'
            values={{ name: <span className='font-weight-bold'>{resource?.name}</span> }}
          />
        </div>
      </div>

      <div className='d-flex justify-content-center m-t-15'>
        <button className='btn btn-primary btn-sm' onClick={onClose}>
          <FormattedMessage id='DownloadModal.ok' />
        </button>
      </div>
    </Modal>
  );
});

export { DownloadModal };
