import { gql, useQuery } from '@apollo/client';
import { CONTACT_FRAGMENT } from 'data/fragments/ContactFragment';

const SHAREABLE_DOCUMENT_QUERY = gql`
  query shareableDocument($id: ID!) {
    currentCompany {
      id

      contacts {
        ...ContactFields
      }

      document(id: $id) {
        id
        shared
        shareableUrl

        sharedWith {
          ...ContactFields
        }

        contactsWithAccess {
          ...ContactFields
        }
      }
    }
  }
  ${CONTACT_FRAGMENT}
`;

const useShareableDocumentQuery = (options) => useQuery(SHAREABLE_DOCUMENT_QUERY, options);

export { useShareableDocumentQuery };
