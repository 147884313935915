/** @jsx jsx */

import React, { memo, useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { ApolloProvider } from '@apollo/client';

import Group from 'components/contacts/picker/Group';
import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';
import { MeetingAttendeesModal } from 'components/meetings/MeetingAttendeesModal';

import { useMeetingActions, useMeetingStore } from 'store/meetingStoreContext';
import { useMinutesStore } from 'store/minutesStoreContext';
import { useAgendasStore, useAgendasActions } from 'store/agendasStoreContext';
import { useFlashActions } from 'store/flashStoreContext';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import { useActionCable } from 'hooks/useActionCable';

import { formatTimeWithOffset, formatTimezoneOffset } from 'helpers/formatDateTime';

import * as styles from './MeetingDetails.styles';

import { ReactComponent as VideoIcon } from 'images/icons/video-icon.svg';
import { ReactComponent as TimeIcon } from 'images/icons/meeting-time.svg';
import { ReactComponent as LocationIcon } from 'images/icons/meeting-map.svg';
import { ReactComponent as PDFIcon } from 'images/icons/meeting-pdf.svg';
import { ReactComponent as VotingIcon } from 'images/icons/chart-purple.svg';
import { ReactComponent as BoxesIcon } from 'images/icons/boxes-icon.svg';
import { ReactComponent as EndMeetingIcon } from 'images/icons/end-meeting-icon.svg';

import CreatePopup from 'components/minutes/CreatePopup';
import { VotingDrawer } from './../VotingDrawer';

import { MeetingAmazonChime } from '../MeetingAmazonChime/MeetingAmazonChime';

import FileViewer from 'components/v2/shared/FileViewer';
import { useFileViewer } from '../../documents/hooks/useFileViewer';

import PdfViewer from '../PdfViewer';

import { AnnotationProvider } from 'store/annotationContext';
import JoinChimeButton from '../JoinChimeButton';
import { ChimeMeeetingProvider } from 'store/chimeMeetingContext';

import { client } from 'utils/apollo';

import { userDateFormat } from '../../../contexts/v2/DateFormatContext';

const MeetingDetails = memo(
  ({
    adminView,
    isMinuteContext,
    currentContactId,
    currentContactName,
    contactTimezone,
    contactTimezoneOffset,
    context,
  }) => {
    const previewDocument = (document) => openFileViewer(document);
    const intl = useIntl();
    const [pdfViewerOpen, setPdfViewerOpen] = useState(false);

    const { fileViewerContext, openFileViewer, closeFileViewer } = useFileViewer();

    const { meeting } = useMeetingStore();
    const { toggleAgenda } = useAgendasActions();
    const { isDraft, isScheduled, isInSession, isUpcoming, isPseudo, isCompleted, isArchived, isEditable } =
      useMeetingStatus(meeting.status);
    const { minutes } = useMinutesStore();
    const { agendas } = useAgendasStore();

    const handleToggleAgenda = (agenda) => toggleAgenda(meeting.id, agenda.id).catch(setFlash);

    const minutesDocument = minutes?.document && minutes.document.data.attributes;
    const previewMinutesDocument = (document) => {
      openFileViewer(minutesDocument);
    };

    const agendaDocument = meeting.agenda_document && meeting.agenda_document.data.attributes;
    const previewAgendaDocument = (document) => {
      openFileViewer(agendaDocument);
    };

    const { endMeeting, startMeeting, arrangeContext } = useMeetingActions();
    const { setFlash } = useFlashActions();

    useActionCable('MeetingsChannel', { meeting_id: meeting.id }, (response) => arrangeContext(meeting.id, response));

    const [minutesPopupIsVisible, setMinutesPopupIsVisible] = useState(false);
    const showMinutesPopup = () => setMinutesPopupIsVisible(true);
    const hideMinutesPopup = () => setMinutesPopupIsVisible(false);

    const [votingDrawerIsVisible, setVotingDrawerIsVisible] = useState(false);
    const showVotingDrawer = () => setVotingDrawerIsVisible(true);
    const hideVotingDrawer = () => setVotingDrawerIsVisible(false);

    const status = meeting.status;
    const dasherizedStatus = status?.replace('_', '-');
    const humanizedStatus = status?.replace('_', ' ');

    const meetingContacts = meeting.contacts || [];

    const formattedStartTime = (offset) => formatTimeWithOffset(meeting.meeting_start_time, offset);
    const formattedEndTime = (offset) => formatTimeWithOffset(meeting.meeting_end_time, offset);

    const formattedTime = `${formattedStartTime(meeting.timezone_offset)} - ${formattedEndTime(
      meeting.timezone_offset
    )}`;
    const formattedContactTime = `${formattedStartTime(contactTimezoneOffset)} - ${formattedEndTime(
      contactTimezoneOffset
    )}`;

    const showCreatorTimezone = meeting.timezone_offset !== contactTimezoneOffset;

    const chairman = meeting.contact_meetings.find((item) => item.id === meeting.chairman_id)?.contact;

    const companySlug = getCompanySlug();
    const meetingURL = `/${companySlug}/meetings/${meeting.slug}`;
    const currentContactIsOwner = currentContactId == meeting.contact_id;

    const handleViewDetailsClick = () => {
      window.open(meetingURL, '_blank');
    };

    const meetingWithChime = meeting.chime_id && isInSession && !isMinuteContext;

    const handleEndMeeting = useCallback(() => {
      endMeeting(meeting.id).catch(setFlash);
    }, []);

    const handleStartMeeting = useCallback(() => {
      startMeeting(meeting.id).catch(setFlash);
    }, []);

    const ahoyProperties = { meeting_id: meeting.id, contact_id: currentContactId };

    const handlePdfViewerHide = () => setPdfViewerOpen(false);
    const handlePdfViewerShow = () => setPdfViewerOpen(true);

    const handleStartMinutes = () => {
      if (!minutes?.slug) {
        showMinutesPopup();
      } else {
        const URL = ['approved', 'archived'].includes(minutes?.status)
          ? `/${companySlug}/minutes/${minutes.slug}`
          : `/${companySlug}/minutes/${minutes.slug}/edit`;

        window.open(URL, '_blank');
      }
    };

    const handleZoomRedirect = useCallback(() => {
      if (currentContactIsOwner) {
        window.open(meeting.zoom_meeting.data.attributes.start_url, '_blank');
      } else {
        window.open(meeting.zoom_meeting.data.attributes.join_url, '_blank');
      }
    }, []);

    const minutesButtonLabel = () => {
      if (!minutes?.slug) {
        return intl.formatMessage({ id: 'MeetingDetails.startMinutes' });
      } else if (minutes?.status === 'approved') {
        return intl.formatMessage({ id: 'MeetingDetails.viewMinutes' });
      } else if (minutes?.status === 'archived') {
        return intl.formatMessage({ id: 'MeetingDetails.showMinutes' });
      } else {
        return intl.formatMessage({ id: 'MeetingDetails.editMinutes' });
      }
    };

    const endMeetingButton = (
      <a className='d-flex btn btn-responsive m-b-10 btn-light-red btn-light-red-inverted'>
        <div className='d-flex align-items-center'>
          <EndMeetingIcon className='m-0 m-r-10' />
          <span>
            <FormattedMessage id='MeetingDetails.endMeeting' />
          </span>
        </div>
      </a>
    );

    const startMeetingButton = (
      <a onClick={handleStartMeeting} className='btn btn-responsive btn-secondary btn-inverted m-b-10'>
        <div className='d-flex align-items-center'>
          <i className='fa fa-play' />
          <span className='m-l-5'>
            <FormattedMessage id='MeetingDetails.startMeeting' />
          </span>
        </div>
      </a>
    );

    const viewableMinutes = minutes?.status == 'approved' || minutes?.document;
    const [meetingAttendeesModalIsOpen, setMeetingAttendeesModalIsOpen] = useState(false);

    const showMeetingAttendeesModal = () => setMeetingAttendeesModalIsOpen(true);
    const hideMeetingAttendeesModal = () => setMeetingAttendeesModalIsOpen(false);

    const chairmanName = chairman?.name || intl.formatMessage({ id: 'MeetingDetails.noOne' });

    const disableAnnotations = !meeting.smart_pack_editable;

    const showSaveAsPdfButton = context == 'in-review-minutes' || (isMinuteContext && minutes?.status == 'archived');

    const previewAgendaButtonText = agendaDocument
      ? isScheduled
        ? intl.formatMessage({ id: 'MeetingDetails.previewAgenda' })
        : intl.formatMessage({ id: 'MeetingDetails.viewAgenda' })
      : intl.formatMessage({ id: 'MeetingDetails.agendaGenerating' });

    const previewSmartPackButtonText = meeting.smart_pack_url
      ? isScheduled
        ? intl.formatMessage({ id: 'MeetingDetails.previewSmartPack' })
        : intl.formatMessage({ id: 'MeetingDetails.viewSmartPack' })
      : intl.formatMessage({ id: 'MeetingDetails.smartPackGenerating' });

    const showAgendaButton =
      (!isDraft && !isScheduled && !isMinuteContext) || meeting.documents_generating || agendaDocument;
    const showSmartPackButton = meeting.agenda_documents.length > 0 || meeting.documents_generating;

    const renderContent = () => (
      <div css={isMinuteContext ? styles.minutePanel : styles.panel}>
        <Row noGutters>
          <div className='flex flex-wrap align-items-center' css={styles.truncateParent}>
            <div css={styles.heading} className='text-truncate m-b-10 p-b-5'>
              {meeting.title}
            </div>

            {(isUpcoming || isScheduled || isInSession) && !isMinuteContext && meeting.meeting_url && (
              <a
                target='_blank'
                rel='noopener'
                href={meeting.meeting_url}
                className='m-l-10 m-b-10 btn-responsive btn btn-sm btn-secondary'
              >
                <div className='d-flex align-items-center'>
                  <span>
                    <FormattedMessage id='MeetingDetails.join' />
                  </span>
                  <VideoIcon className='m-l-5 m-r-5' />
                  <span>
                    {meeting.config == 'zoom' ? (
                      <FormattedMessage id='MeetingDetails.zoom' />
                    ) : (
                      <FormattedMessage id='MeetingDetails.conference' />
                    )}
                  </span>
                </div>
              </a>
            )}
          </div>
        </Row>

        <Row noGutters className='lh-1'>
          <div className='font-weight-semibold'>
            <FormattedMessage id='MeetingDetails.by' /> <span className='text-black'>{meeting.owner_name}</span> /{' '}
            <FormattedMessage id='MeetingDetails.chair' />
            <span className='text-black m-r-10'>{chairmanName}</span>
          </div>
          <span className='font-weight-light fs-14'>{meeting.meeting_type_name}</span>
        </Row>

        <Row noGutters>
          <Col md={8}>
            <div className='m-t-25 d-flex flex-wrap align-items-start justify-content-between'>
              <div css={styles.block}>
                <TimeIcon className='m-0' />
                <div css={styles.header}>
                  <FormattedMessage id='MeetingDetails.time' />
                </div>
                <div css={styles.bold}>
                  {`${formattedContactTime} ${formatTimeWithOffset(
                    meeting.meeting_start_time,
                    contactTimezoneOffset,
                    userDateFormat
                  )} (${contactTimezone} ${formatTimezoneOffset(contactTimezoneOffset)})`}
                </div>

                {showCreatorTimezone && (
                  <div css={styles.header} className='m-l-25 m-t-5'>
                    {`${formattedTime} ${formatTimeWithOffset(
                      meeting.meeting_start_time,
                      meeting.timezone_offset,
                      userDateFormat
                    )} (${meeting.timezone} ${formatTimezoneOffset(meeting.timezone_offset)})`}
                  </div>
                )}
              </div>

              <div css={styles.block}>
                <LocationIcon className='m-0' />
                <div css={styles.header}>
                  <FormattedMessage id='MeetingDetails.location' />
                </div>
                <div css={styles.bold}>{meeting.location}</div>
              </div>
            </div>
          </Col>

          <Col md={4} className='d-flex justify-content-end m-t-10'>
            <div className='d-flex align-items-end flex-grow-1'>
              <Group
                header={intl.formatMessage({ id: 'MeetingDetails.attendees' })}
                contacts={meetingContacts}
                maxContacts={6}
                allowAdd={false}
              >
                {isInSession && adminView ? (
                  <a onClick={showMeetingAttendeesModal} className='btn btn-responsive btn-secondary btn-sm m-l-10'>
                    <FormattedMessage id='MeetingDetails.plusAdd' />
                  </a>
                ) : null}
              </Group>
            </div>
          </Col>
        </Row>

        <Row noGutters className='m-t-25'>
          <div className='d-flex flex-wrap justify-content-between align-items-center flex-grow-1'>
            <div className='d-flex flex-wrap d-flex-responsive'>
              {showSaveAsPdfButton && (
                <a
                  className='btn btn-responsive btn-no-transform m-r-10  m-b-10 btn-secondary btn-inverted'
                  href={`/${companySlug}/minutes/${minutes.slug}.pdf`}
                  target='_blank'
                  rel='noopener'
                >
                  <div className='d-flex align-items-center'>
                    <PDFIcon className='m-0 m-r-10' />
                    <span>
                      <FormattedMessage id='MeetingDetails.saveAsPdf' />
                    </span>
                  </div>
                </a>
              )}

              {viewableMinutes && (
                <div
                  onClick={previewMinutesDocument}
                  className={`btn btn-responsive m-r-10  m-b-10 ${
                    minutesDocument ? 'btn-secondary btn-inverted' : 'btn-disabled'
                  }`}
                  disabled={!minutesDocument}
                >
                  <div className='d-flex align-items-center'>
                    <PDFIcon className='m-0 m-r-10' />
                    <span>
                      {minutesDocument ? (
                        <FormattedMessage id='MeetingDetails.downloadMinutes' />
                      ) : (
                        <FormattedMessage id='MeetingDetails.minutesGenerating' />
                      )}
                    </span>
                  </div>
                </div>
              )}

              {!isPseudo && (
                <>
                  {showAgendaButton && (
                    <div className='d-flex d-flex-responsive flex-wrap'>
                      <div
                        onClick={previewAgendaDocument}
                        className={`btn btn-responsive m-r-15 m-b-10 btn-no-transform ${
                          agendaDocument ? 'btn-secondary btn-inverted' : 'btn-disabled'
                        }`}
                        disabled={!agendaDocument}
                      >
                        <div className='d-flex align-items-center'>
                          <PDFIcon className='m-0 m-r-10' />
                          <span>{previewAgendaButtonText}</span>
                        </div>
                      </div>

                      {showSmartPackButton && (
                        <button
                          className={`btn btn-responsive m-r-15 m-b-10 btn-no-transform ${
                            meeting.smart_pack_url ? 'btn-secondary btn-inverted' : 'btn-disabled'
                          }`}
                          onClick={handlePdfViewerShow}
                          disabled={!meeting.smart_pack_url}
                          target='_blank'
                          rel='noopener'
                        >
                          <div className='d-flex align-items-center'>
                            <PDFIcon className='m-0 m-r-10' />
                            <span>{previewSmartPackButtonText}</span>
                          </div>
                        </button>
                      )}
                    </div>
                  )}

                  {isMinuteContext && (
                    <button className='btn btn-responsive btn-inverted m-r-15 m-b-10' onClick={handleViewDetailsClick}>
                      <div className='d-flex align-items-center'>
                        <i className='fa fa-sticky-note m-r-10' />
                        <span>
                          <FormattedMessage id='MeetingDetails.viewDetails' />
                        </span>
                      </div>
                    </button>
                  )}

                  {meeting.votes.length > 0 && (
                    <a
                      className='btn btn-responsive btn-inverted voting-toggle m-b-10  m-r-15 d-flex'
                      onClick={showVotingDrawer}
                    >
                      <div className='d-flex align-items-center'>
                        <VotingIcon className='m-0 m-r-10' />
                        <div className='d-flex flex-column align-items-baseline lh-14'>
                          <span>
                            <FormattedMessage id='MeetingDetails.voteCount' values={{ count: meeting.votes.length }} />{' '}
                          </span>
                        </div>
                      </div>
                    </a>
                  )}

                  {adminView || currentContactId == meeting.contact_id ? (
                    <>
                      <button onClick={handleStartMinutes} className='btn btn-responsive m-b-10 m-r-15 btn-inverted'>
                        <div className='d-flex align-items-center'>
                          <BoxesIcon className='m-0 m-r-10' />
                          <span>{minutesButtonLabel()}</span>
                        </div>
                      </button>

                      <CreatePopup
                        show={minutesPopupIsVisible}
                        onClose={hideMinutesPopup}
                        meeting={meeting}
                        meetingOptions={[meeting]}
                        disableMeetingPicker
                        openInNewTab
                      />
                    </>
                  ) : null}
                </>
              )}

              {meetingWithChime && isInSession && !isMinuteContext && <JoinChimeButton />}
            </div>

            <div className='d-flex d-flex-responsive flex-wrap'>
              {isUpcoming && adminView ? startMeetingButton : null}

              {isInSession && adminView ? (
                <ButtonWithConfirmation
                  confirmationText={
                    !!meetingWithChime
                      ? intl.formatMessage({ id: 'MeetingDetails.confirmationChime' })
                      : intl.formatMessage({ id: 'MeetingDetails.confirmationRegular' })
                  }
                  onConfirm={handleEndMeeting}
                  button={endMeetingButton}
                />
              ) : null}
            </div>

            <MeetingAttendeesModal show={meetingAttendeesModalIsOpen} onClose={hideMeetingAttendeesModal} />
          </div>
        </Row>
        {meetingWithChime && <MeetingAmazonChime />}

        {meeting.votes.length ? (
          <VotingDrawer
            votes={meeting.votes}
            show={votingDrawerIsVisible}
            hide={hideVotingDrawer}
            previewDocument={previewDocument}
          />
        ) : null}

        <FileViewer
          fileName={fileViewerContext.file?.name}
          fileUrl={fileViewerContext.file?.url}
          isOpen={fileViewerContext.show}
          onClose={closeFileViewer}
        />

        {meeting.smart_pack_url && (
          <ApolloProvider client={client}>
            <AnnotationProvider
              meetingId={meeting.id}
              smartPackId={meeting.smart_pack_id}
              currentContactId={currentContactId}
            >
              <PdfViewer
                meeting={meeting}
                agendas={agendas}
                author={currentContactName}
                onToggleAgenda={handleToggleAgenda}
                onHide={handlePdfViewerHide}
                show={pdfViewerOpen}
                disableAnnotations={disableAnnotations}
                showCompletedInfo={isCompleted}
                currentContactId={currentContactId}
                ahoyProperties={ahoyProperties}
              />
            </AnnotationProvider>
          </ApolloProvider>
        )}
      </div>
    );

    if (meetingWithChime) {
      return (
        <ChimeMeeetingProvider currentContactId={currentContactId} meetingId={meeting.id}>
          {renderContent()}
        </ChimeMeeetingProvider>
      );
    } else {
      return renderContent();
    }
  }
);

export { MeetingDetails };
