import { gql, useMutation } from '@apollo/client';

const UPDATE_PROFILE_DETAILS_MUTATION = gql`
  mutation UpdateProfileDetails($input: UpdateProfileDetailsInput!) {
    updateProfileDetails(input: $input) {
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        initials

        address {
          id
          address1
          address2
          zip
          city

          country {
            value: id
            label: name
          }
        }
      }

      errors {
        attribute
        message
      }
    }
  }
`;

const useUpdateProfileDetailsMutation = (options) => useMutation(UPDATE_PROFILE_DETAILS_MUTATION, options);

export { UPDATE_PROFILE_DETAILS_MUTATION, useUpdateProfileDetailsMutation };
