import { useState } from 'react';
import { useUpdateResolutionMutation } from './../data/mutations/UpdateResolutionMutation';

const useUpdateResolution = ({}) => {
  const update = (cache, { data: { updateResolution } }) => {
    const { resolution, errors } = updateResolution;

    if (errors.length) {
      return setErrors(errors);
    }
  };

  const [errors, setErrors] = useState(null);
  const [mutate, { loading: isUpdating }] = useUpdateResolutionMutation({ update });

  const updateResolution = (payload) => mutate({ variables: { input: payload } });

  return {
    isUpdating,
    updateResolution,
    errors,
  };
};

export { useUpdateResolution };
