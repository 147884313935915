/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { useClickOutside } from 'hooks/useClickOutside';
import { useAgendasActions } from 'store/agendasStoreContext';
import { useFlashActions } from 'store/flashStoreContext';
import { formatDuration } from 'helpers/formatDuration';

import Slider from 'react-rangeslider';
import moment from 'moment';

import { ReactComponent as ClockIcon } from 'images/icons/clock-icon.svg';

import * as styles from './DurationPicker.styles';

const DurationPicker = ({ meetingId, agendaId, isEditable, maxDuration=120, value }) => {
  const duration = moment.duration(value || 0, 'minutes');

  const intl = useIntl();

  const [hours, setHours] = useState(duration.hours());
  const [minutes, setMinutes] = useState(duration.minutes());

  const { updateAgenda } = useAgendasActions();
  const { setFlash } = useFlashActions();

  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(false);

  useEffect(() => {
    resetInitialValues();
  }, [value]);

  useEffect(() => {
    if (!isComponentVisible) {
      resetInitialValues();
    }
  }, [isComponentVisible]);

  const show = () => setIsComponentVisible(true);

  const hide = () => {
    resetInitialValues();
    setIsComponentVisible(false);
  };

  const resetInitialValues = () => {
    setHours(duration.hours());
    setMinutes(duration.minutes());
  };

  const handleChangeComplete = () => {
    ref.current.ignoreClickOutside = true;
  };

  const totalMinutes = Number(hours) * 60 + Number(minutes);

  const setDuration = () => {
    updateAgenda(meetingId, agendaId, {
      duration_in_minutes: totalMinutes,
    })
      .then(hide)
      .catch(setFlash);
  };

  const handleChange = (value) => {
    const duration = moment.duration(value, 'minutes');

    setHours(duration.hours());
    setMinutes(duration.minutes());
  };

  const renderValue = () => {
    if (value) {
      return formatDuration(value);
    } else {
      return intl.formatMessage({ id: 'DurationPicker.setDuration' });
    }
  };

  if (!isEditable) {
    return (
      <div className='d-flex align-items-center'>
        <ClockIcon className='m-0 m-r-5' />

        <span className='text-light-grey fs-12'>{renderValue()}</span>
      </div>
    );
  }

  return (
    <div className='position-relative'>
      <div className='d-flex align-items-center cursor-pointer' onClick={show}>
        <ClockIcon className='m-0 m-r-5' />

        <span className='text-light-grey fs-12'>{renderValue()}</span>

        <i className='fa fa-pencil' css={styles.pencil} />
      </div>

      {isComponentVisible && (
        <div css={styles.panel} ref={ref}>
          <div className='text-black fs-14 font-weight-semibold m-b-20'>
            <FormattedMessage id='DurationPicker.duration' />
          </div>

          <div className='d-flex align-items-center'>
            <div className='fs-18 text-light-grey font-weight-semibold'>
              {totalMinutes} <FormattedMessage id='DurationPicker.min' />
            </div>
          </div>

          <div css={styles.slider} className='m-t-20'>
            <Slider
              min={5}
              max={maxDuration}
              tooltip={false}
              onChange={handleChange}
              onChangeComplete={handleChangeComplete}
              step={5}
              value={totalMinutes}
            />
          </div>

          <div className='d-flex align-items-center m-t-30'>
            <a className='btn btn-primary' onClick={setDuration}>
              <FormattedMessage id='DurationPicker.set' />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export { DurationPicker };
