import React, { memo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ContactProvider } from 'store/contactStoreContext';
import { UploadQueueProvider } from 'store/uploadQueueStoreContext';
import { FlashProvider } from 'store/flashStoreContext';
import { client } from 'utils/apollo';
import AppSignalWrapper from '../../v2/shared/AppSignalWrapper';
import { TranslationContextProvider } from '../../../contexts/v2/TranslationContext';
import { DateFormatContextProvider } from '../../../contexts/v2/DateFormatContext';

import { Form } from 'components/votes/Form';

export default memo(({ voteId, showPolling, contactId, companyId, root }) => {
  return (
    <AppSignalWrapper tags={{ page: 'votes/edit' }}>
      <DateFormatContextProvider>
        <TranslationContextProvider>
          <ApolloProvider client={client}>
            <FlashProvider>
              <ContactProvider value={{ contactId, companyId }}>
                <UploadQueueProvider>
                  <Form voteId={voteId} companyId={companyId} showPolling={showPolling} root={root} />
                </UploadQueueProvider>
              </ContactProvider>
            </FlashProvider>
          </ApolloProvider>
        </TranslationContextProvider>
      </DateFormatContextProvider>
    </AppSignalWrapper>
  );
});
