import React, { memo, useState } from 'react';

export default memo(({ contact: { avatarUrl, avatarURL, initials, archived } }) => {
  const archivedClass = archived ? 'avatar-archived' : '';
  const image = (
    <img src={avatarUrl || avatarURL} className={`flex-shrink-0 rounded-circle avatar-xs-plus m-0 ${archivedClass}`} />
  );
  const circle = (
    <div className={`rounded-circle flex-shrink-0 avatar-xs-plus bg-soft-yellow ${archivedClass}`}>
      <div className='avatar-title'>{initials}</div>
    </div>
  );

  const avatar = avatarUrl || avatarURL ? image : circle;

  return <>{avatar}</>;
});
