import React, { memo, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { withIntl } from '../../../utils/v2/intl';

import { Select } from '../../shared/Select';
import Multiselect from '../../shared/Select/Multiselect';
import { Search } from '../../shared/Search';

const Filters = memo(({ companySlug }) => {
  const intl = useIntl();

  const statusOptions = [
    { value: 'draft', label: intl.formatMessage({ id: 'Filters.draft' }) },
    { value: 'approved', label: intl.formatMessage({ id: 'Filters.approved' }) },
    { value: 'in_review', label: intl.formatMessage({ id: 'Filters.inReview' }) },
    { value: 'archived', label: intl.formatMessage({ id: 'Filters.archived' }) },
  ];

  const timeframeOptions = [
    { value: 'all', label: intl.formatMessage({ id: 'Filters.all' }) },
    { value: 'next_30_days', label: intl.formatMessage({ id: 'Filters.next30' }) },
    { value: 'next_6_months', label: intl.formatMessage({ id: 'Filters.next6' }) },
    { value: 'past_year', label: intl.formatMessage({ id: 'Filters.pastYear' }) },
  ];

  let [selectedStatuses, setSelectedStatuses] = useState(statusOptions.slice(0, 3));

  let [selectedTimeframe, setSelectedTimeframe] = useState(timeframeOptions[0]);
  let [searchTerm, setSearchTerm] = useState('');
  let [isSearching, setIsSearching] = useState(false);

  const handleTermChange = (event) => {
    setSearchTerm(event.target.value || '');
  };

  const startSearching = () => {
    setIsSearching(true);
  };

  const stopSearching = () => {
    setIsSearching(false);
    setSearchTerm('');
  };

  useEffect(() => {
    const query = $.param({
      status: selectedStatuses.filter((option) => option.value).map((option) => option.value),
      timeframe: selectedTimeframe && selectedTimeframe.value,
      search_term: searchTerm,
    });

    $.get(`/${companySlug}/minutes.js?${query}`);
  }, [selectedStatuses, selectedTimeframe, searchTerm]);

  return (
    <>
      <Row noGutters>
        <Col xs={6} className='p-r-5 m-b-10'>
          <Multiselect
            options={statusOptions}
            classNamePrefix={'status'}
            onChange={setSelectedStatuses}
            value={selectedStatuses}
            withAllOption={true}
            placeholder={intl.formatMessage({ id: 'Filters.status' })}
          />
        </Col>

        <Col xs={6} className='p-l-5 m-b-10'>
          <Select
            options={timeframeOptions}
            onChange={setSelectedTimeframe}
            value={selectedTimeframe}
            placeholder={intl.formatMessage({ id: 'Filters.time' })}
          />
        </Col>
      </Row>
      <div className='m-l-auto'>
        <Search
          value={searchTerm}
          isFocused={isSearching}
          onChange={handleTermChange}
          onFocus={startSearching}
          onBlur={stopSearching}
        />
      </div>
    </>
  );
});

export default withIntl(Filters);
