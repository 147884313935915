/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { useSort } from './../hooks/useSort';
import { AreaPlaceholder } from './../AreaPlaceholder';
import { Document } from './../Document';
import { SortButton } from './../SortButton';
import { UploadButton } from './../UploadButton';

import { ReactComponent as PlaceholderIcon } from 'images/icons/no-documents.svg';

import * as areaStyles from './../shared/Area.styles';

const DocumentsArea = memo(
  ({ isActionless, currentDirectory, onViewDocumentHistoryClick, onClick, onShareClick, onMoveClick }) => {
    const { capabilities, documents } = currentDirectory;
    const { sortedItems: sortedDocuments, isDescending, toggleSort } = useSort(documents);

    const intl = useIntl();

    const renderDocument = (document) => {
      const handleViewHistoryClick = onViewDocumentHistoryClick.bind(this, document);
      const handleShareClick = onShareClick.bind(this, document);
      const handleMoveClick = onMoveClick.bind(this, document);
      const handleClick = onClick.bind(this, document);

      return (
        <Document
          {...document}
          key={document.id}
          capabilities={capabilities}
          onViewHistoryClick={handleViewHistoryClick}
          onClick={handleClick}
          onShareClick={handleShareClick}
          onMoveClick={handleMoveClick}
        />
      );
    };

    const hasDocuments = documents.length > 0;
    const children = sortedDocuments.map(renderDocument);

    return (
      <>
        <div css={areaStyles.actions}>
          <UploadButton directory={currentDirectory} isDisabled={isActionless} />
        </div>

        <div css={areaStyles.sort}>
          <SortButton
            show={hasDocuments}
            onClick={toggleSort}
            isRotated={isDescending}
            text={intl.formatMessage({ id: 'DocumentsArea.name' })}
          />
        </div>

        <div css={areaStyles.content} className='document-area'>
          {children}

          <AreaPlaceholder
            show={!hasDocuments}
            message={intl.formatMessage({ id: 'DocumentsArea.noDocuments' })}
            image={<PlaceholderIcon />}
          />
        </div>
      </>
    );
  }
);

export { DocumentsArea };
