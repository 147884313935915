import { gql } from '@apollo/client';
import { ACTIVITY_FEED_FRAGMENT } from './ActivityFeedFragment';
import { BREADCRUMB_FRAGMENT } from './BreadcrumbFragment';

const DOCUMENT_FRAGMENT = gql`
  fragment DocumentFields on Document {
    id
    name
    favourite
    shared
    contentType
    createdAt
    extension
    smartDocument
    withSmartDocuments
    inSmartSignDirectory
    url

    parent {
      id
    }

    activityFeeds {
      ...ActivityFeedFields
    }

    breadcrumbs {
      ...BreadcrumbFields
    }
  }
  ${ACTIVITY_FEED_FRAGMENT}
  ${BREADCRUMB_FRAGMENT}
`;

export { DOCUMENT_FRAGMENT };
