export const ACTION_ADD = 'add';
export const ACTION_MODIFY = 'modify';
export const ACTION_DELETE = 'delete';

const sharedDisabledElements = [
  'freeTextToolGroupButton',
  'underlineToolGroupButton',
  'strikeoutToolGroupButton',
  'squigglyToolGroupButton',
  'shapeToolGroupButton',
  'freeHandHighlightToolGroupButton',
  'toolsOverlay',
  'toolbarGroup-Measure',
  'toolbarGroup-Edit',
  'toolbarGroup-Forms',
  'toolbarGroup-Shapes',
  'toolbarGroup-Insert',
  'toolbarGroup-FillAndSign',
  'themeChangeButton',
  'outlinesPanelButton',
  'newBookmarkButton',
  'bookmarkControls',
  'ribbons',
  'toolsHeader',
  'notesPanelResizeBar',
  'searchPanelResizeBar',
  'noteState',
  'unpostedCommentIndicator',
  'applyWatermark',
  'thumbnailControl',
  'documentControl',
  'pageManipulationOverlay',
  'signatureToolGroupButton',
  'languageButton',
  'printButton',
  'menuButton',
  'moreButton',
];

export const disabledElements = [...sharedDisabledElements, 'leftPanel', 'leftPanelButton'];

export const previewDisabledElements = [
  ...sharedDisabledElements,
  'searchButton',
  'toggleNotesButton',
  'selectToolButton',
  'panToolButton',
  'leftPanelTabs',
];
