import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button, { Variants } from 'components/v2/shared/Button';
import VisibilityWrapper from 'components/v2/shared/VisibilityWrapper';
import Pluralize from 'pluralize';

const Footer = ({
  selectedCount,
  isViewingSelected,
  onShowSelected,
  onClearSelected,
  onHideSelected,
  onAddSelected,
}) => (
  <div className='tw-flex tw-flex-col tw-items-center tw-gap-2 lg:tw-flex-row'>
    <VisibilityWrapper
      isVisible={isViewingSelected}
      visibleClass='tw-flex'
      className='tw-w-full tw-items-center tw-gap-2'
    >
      <Button isFullWidth variant={Variants.Lavander} onClick={onHideSelected}>
        Hide selected
      </Button>

      <Button isFullWidth variant={Variants.Red} onClick={onClearSelected} isDisabled={selectedCount < 1}>
        Clear all
      </Button>
    </VisibilityWrapper>

    <VisibilityWrapper isVisible={!isViewingSelected} className='tw-w-full'>
      <Button isFullWidth variant={Variants.Purple} onClick={onShowSelected}>
        Show selected
      </Button>
    </VisibilityWrapper>

    <Button isFullWidth variant={Variants.Teal} isDisabled={selectedCount < 1} onClick={onAddSelected}>
      {`Add ${Pluralize('items', selectedCount, true)}`}
    </Button>
  </div>
);

Footer.propTypes = {
  selectedCount: PropTypes.number,
  isViewingSelected: PropTypes.bool,
  onShowSelected: PropTypes.func,
  onClearSelected: PropTypes.func,
  onHideSelected: PropTypes.func,
  onAddSelected: PropTypes.func,
};

export default Footer;
