import React, { useCallback, useMemo, useState } from 'react';

const defaultValues = {
  isOpendSendingSign: false,
  setIsOpendSendingSign: () => {},
  isOpendSignModal: false,
  setIsOpendSignModal: () => {},
};

export const SendingSignContext = React.createContext(defaultValues);

const SendingSignProvider = ({ children }) => {
  const [isOpendSendingSign, setIsOpendSendingSignVal] = useState(false);
  const [isOpendSignModal, setIsOpendSignModalVal] = useState(false);

  const setIsOpendSendingSign = useCallback(
    (value) => {
      setIsOpendSendingSignVal(value);
    },
    [setIsOpendSendingSignVal]
  );

  const setIsOpendSignModal = useCallback(
    (value) => {
      setIsOpendSignModalVal(value);
    },
    [setIsOpendSignModalVal]
  );

  const value = useMemo(
    () => ({
      isOpendSendingSign,
      setIsOpendSendingSign,
      isOpendSignModal,
      setIsOpendSignModal,
    }),
    [isOpendSendingSign, setIsOpendSendingSign, isOpendSignModal, setIsOpendSignModal]
  );

  return <SendingSignContext.Provider value={value}>{children}</SendingSignContext.Provider>;
};

export default SendingSignProvider;
