import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';

export const index = css`
  ${fonts.bold}
  font-size: 40px;
  color: ${colors.placeholderGrey};
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  width: 30px;

  ${media.mobile} {
    top: -11px;
    left: 6px;
    width: auto;
    color: ${colors.linkWater};
  }
`;

export const actions = css`
  display: flex;
  flex-direction: column;

  ${media.mobile} {
    border: 1px solid ${colors.darkerGrey};
    border-top: none;
    display: flex;
    flex-direction: row;
    border-right: none;
  }

  .action-item {
    border: 1px solid ${colors.darkerGrey};
    border-left: none;
    padding: 11px;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.mobile} {
      border: none;
      border-right: 1px solid ${colors.darkerGrey};
      flex-grow: 1;
    }

    .far {
      color: ${colors.lightGrey};

      &:hover {
        color: ${colors.cyan};
      }
    }
  }
`;

export const resolutionForm = css`
  position: relative;
  width: 100%;
  display: flex;
  padding-left: 40px;
  margin-bottom: 20px;
  flex-direction: column;
  ${media.mobile} {
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 40px;
    background-color: ${colors.white};
  }
`;

export const textAreaInput = css`
  height: 125px;
`;

export const textarea = css`
  width: 100%;
`;

export const innerWrapper = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  ${media.mobile} {
    flex-direction: column;
  }
`;
