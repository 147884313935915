/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { useEditable } from 'hooks/useEditable';
import { useCreateDirectory } from './../hooks/useCreateDirectory';
import { useSort } from './../hooks/useSort';
import { useDownloadDirectory } from './../hooks/useDownloadDirectory';
import { Directory } from './../Directory';
import { AreaPlaceholder } from './../AreaPlaceholder';
import { CreateDirectoryInput } from './../CreateDirectoryInput';
import { AreaButton } from './../AreaButton';
import { SortButton } from './../SortButton';

import { ReactComponent as PlaceholderIcon } from 'images/icons/no-folders.svg';
import { ReactComponent as DownloadIcon } from 'images/icons/documents/archive-download-icon.svg';
import { ReactComponent as CreateIcon } from 'images/icons/documents/create-directory-icon.svg';
import { ReactComponent as BackIcon } from 'images/icons/documents/back-icon.svg';

import * as areaStyles from './../shared/Area.styles';

const DirectoriesArea = memo(({ currentDirectory, isActionless, onClick, onShareClick, onMoveClick, onDownload }) => {
  const { directories, capabilities, breadcrumbs } = currentDirectory;
  const { sortedItems: sortedDirectories, isDescending, toggleSort } = useSort(directories);
  const { isEditing, startEditing, stopEditing } = useEditable();
  const { isCreating, error, resetError, createDirectory } = useCreateDirectory({ onCompleted: stopEditing });
  const { isDownloading, downloadDirectory } = useDownloadDirectory({
    onDownload,
  });

  const intl = useIntl();

  const handleCreate = (name) => {
    createDirectory(name, currentDirectory.id);
  };

  const handleCancel = () => {
    resetError();
    stopEditing();
  };

  const handleDownload = (event) => {
    event.stopPropagation();
    downloadDirectory(currentDirectory.id);
  };

  const renderDirectory = (directory) => {
    const handleClick = onClick.bind(this, directory.id);
    const handleMoveClick = onMoveClick.bind(this, directory);
    const handleShareClick = onShareClick.bind(this, directory);

    return (
      <Directory
        {...directory}
        key={directory.id}
        capabilities={capabilities}
        onClick={handleClick}
        onShareClick={handleShareClick}
        onMoveClick={handleMoveClick}
        onDownload={onDownload}
        empty={directory.empty}
        withSmartDocuments={directory.withSmartDocuments}
      />
    );
  };

  const parent = breadcrumbs[breadcrumbs.length - 2];
  const handleBackClick = parent && onClick && onClick.bind(this, parent.id);

  const hasDirectories = directories.length > 0;
  const children = sortedDirectories.map(renderDirectory);

  return (
    <>
      <div css={areaStyles.actions}>
        <AreaButton isDisabled={!parent} onClick={handleBackClick} text='..' icon={<BackIcon />} />

        {!isEditing && !isActionless && (
          <AreaButton
            onClick={startEditing}
            text={intl.formatMessage({ id: 'DirectoriesArea.newFolder' })}
            icon={<CreateIcon />}
            alignRight
          />
        )}

        <div className='m-l-15 p-t-5'>
          <AreaButton
            isDisabled={isDownloading || currentDirectory.virtual || currentDirectory.empty}
            onClick={handleDownload}
            icon={<DownloadIcon />}
          />
        </div>
      </div>

      <div css={areaStyles.sort} className='sort-directories'>
        <SortButton
          show={hasDirectories}
          onClick={toggleSort}
          isRotated={isDescending}
          text={intl.formatMessage({ id: 'DirectoriesArea.name' })}
        />
      </div>

      <div css={areaStyles.content}>
        {children}

        <CreateDirectoryInput
          show={isEditing}
          isLoading={isCreating}
          error={error}
          onChange={resetError}
          onCancel={handleCancel}
          onUpdate={handleCreate}
        />

        <AreaPlaceholder
          show={!isEditing && !hasDirectories}
          message={intl.formatMessage({ id: 'DirectoriesArea.noFolders' })}
          image={<PlaceholderIcon />}
        />
      </div>
    </>
  );
});

export { DirectoriesArea };
