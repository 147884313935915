import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const container = {
  base: css`
    position: relative;
    display: none;
    border-top: 1px solid ${colors.borderLightGrey};
    height: calc(100% - 50px);
    outline: none;
    width: 100%;
    ${media.tablet} {
      height: auto;
      flex-direction: column-reverse;
    }
  `,

  visible: css`
    display: flex;
  `,
};

export const droppable = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  right: 0;
  background: #f1f4ff;
  display: flex;
  justify-content: center;
  opacity: 0.9;
  border: 2px dashed #7391fc;
  box-sizing: border-box;

  .droppable__wrapper {
    display: flex;
    align-items: center;
  }

  .droppable__icon {
    margin-right: 16px;
    flex-shrink: 0;
  }

  .droppable__text {
    width: 100px;
    font-size: 14px;
    line-height: 1;
    color: #787d96;
  }
`;

export const placeholder = css`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const header = css`
  ${fonts.semiBold}
  color: ${colors.black};
  font-size: 20px;
  margin-bottom: 9px;
`;

export const subheader = css`
  ${fonts.regular}
  color: ${colors.mediumGrey};
  font-size: 14px;
`;

export const section = {
  base: css`
    border-right: 1px solid ${colors.grey};
    background: ${colors.white};
    position: relative;
    opacity: 0;
    padding: 14px 10px;
    display: block;
    transition: none;
    width: 100%;
    height: 631px;
    ${media.tablet} {
      padding: 0;
      height: auto;
    }

    &:last-of-type {
      border-right: none;
    }
  `,

  hidden: css`
    display: none;
  `,

  loaded: css`
    transition: opacity 0.4s ease-in;
    opacity: 1;
  `,
};

export const loading = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
`;

export const sectionTitle = css`
  ${fonts.bold};
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: ${colors.mobileHeadingBackground};
  display: none;
  margin: 0;
  color: ${colors.black};
  text-transform: capitalize;
  margin-bottom: 10px;
  ${media.tablet} {
    display: block;
  }
`;
