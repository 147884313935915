import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useGridLayout } from 'react-table';
import clsx from 'clsx';

const Table = ({ data, columns }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      defaultColumn: {
        width: 'auto',
      },
    },
    useGridLayout
  );

  return (
    <div {...getTableProps()}>
      {headerGroups.map((headerGroup) =>
        headerGroup.headers.map((column) => (
          <div {...column.getHeaderProps()} className='tw-text-xs tw-font-medium'>
            {column.render('Header')}
          </div>
        ))
      )}

      {rows.map((row, i) => {
        prepareRow(row);

        return row.cells.map((cell) => (
          <div {...cell.getCellProps()} className='tw-py-8'>
            {cell.render('Cell')}
          </div>
        ));
      })}
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
};

export default Table;
