import { createStore } from './createStore';
import { deleteById, updateById } from 'utils';

import { trackPromise } from 'react-promise-tracker';
import { getAttributes } from 'helpers/getAttributes';

import axios from 'axios';

const initialState = {
  comments: [],
};

const actionTypes = {
  SET: 'comments.set',
  ADD: 'comments.add',
  UPDATE: 'comments.update',
  DELETE: 'comments.delete',
};

const companyId = getCompanySlug();

const api = {
  createComment: (minuteId, params) => {
    const apiURL = `/api/${companyId}/v1/minutes/${minuteId}/ooe/comments.json`;
    const payload = { comment: params };

    const promise = axios
      .post(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  updateComment: (minuteId, commentId, params) => {
    const apiURL = `/api/${companyId}/v1/minutes/${minuteId}/ooe/comments/${commentId}.json`;
    const payload = { comment: params };

    const promise = axios
      .put(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  deleteComment: (minuteId, commentId) => {
    const apiURL = `/api/${companyId}/v1/minutes/${minuteId}/ooe/comments/${commentId}.json`;

    const promise = axios
      .delete(apiURL)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },
};

const useCommentsActions = () => {
  const dispatch = useCommentsDispatch();

  const createComment = (minuteId, params) => {
    return api.createComment(minuteId, params).then((comment) => {
      dispatch({ type: actionTypes.ADD, payload: comment });
    });
  };

  const updateComment = (minuteId, commentId, params) => {
    return api.updateComment(minuteId, commentId, params).then((comment) => {
      dispatch({ type: actionTypes.UPDATE, payload: comment });
    });
  };

  const deleteComment = (minuteId, commentId) => {
    return api.deleteComment(minuteId, commentId).then((comment) => {
      dispatch({ type: actionTypes.DELETE, payload: comment });
    });
  };

  const setComments = (comments) => {
    dispatch({ type: actionTypes.SET, payload: comments });
  };

  return {
    setComments,
    createComment,
    updateComment,
    deleteComment,
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET:
      return { comments: payload };
    case actionTypes.ADD:
      const commentExists = state.comments.some((item) => item.id === payload.id);

      if (commentExists) {
        return state;
      } else {
        return { comments: [...state.comments, payload] };
      }
    case actionTypes.UPDATE:
      return { comments: updateById(state.comments, payload) };
    case actionTypes.DELETE:
      return { comments: deleteById(state.comments, payload) };
  }
};

const [CommentsProvider, useCommentsStore, useCommentsDispatch] = createStore(reducer, initialState);

export { CommentsProvider, useCommentsStore, useCommentsActions };
