import { css } from '@emotion/core';
import { colors } from 'styles';

export const container = css`
  display: block;
  align-items: center;
  background: ${colors.white};
  padding: 10px;

  &:hover {
    cursor: pointer;
    border-radius: 2px;
    background: ${colors.lightCyan};
  }
`;
