import { css } from '@emotion/core';
import { colors } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

const placeholderBackground = HexToRgba(colors.white, 20);

export const page = ({ width, height, translateY, margin }) => css`
  position: absolute;
  background: ${placeholderBackground};
  width: ${width}px;
  height: ${height}px;
  left: 50%;
  margin-bottom: ${margin}px;
  transform: translate(-50%, ${translateY}%);
  bottom: 0;
  top: 0;

  .react-pdf__Page__canvas {
    height: 100% !important;
    width: 100% !important;
  }
`;
