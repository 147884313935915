import { gql, useQuery } from '@apollo/client';

const CURRENT_USER_COMPANY_LIST_QUERY = gql`
  query CurrentUserCompanyListQuery($first: Int, $after: String, $last: Int, $before: String, $name: String) {
    currentUser {
      id

      companies(first: $first, after: $after, last: $last, before: $before, name: $name) @connection(key: $context) {
        pageInfo {
          endCursor
          startCursor
          hasPreviousPage
          hasNextPage
        }

        edges {
          cursor

          node {
            id
            name
            slug
            logoUrl
            createdAt
            memberContactCount
            pendingContactCount
            primaryForCurrentUser
            ownedByCurrentUser
            currentUserRole
          }
        }
      }
    }
  }
`;

const useCurrentUserCompanyListQuery = (options) => useQuery(CURRENT_USER_COMPANY_LIST_QUERY, options);

export { CURRENT_USER_COMPANY_LIST_QUERY, useCurrentUserCompanyListQuery };
