import { css } from '@emotion/core';

export const wrapper = css`
  align-self: flex-start;
`;

export const errorMessage = css`
  position: static;
  white-space: pre-wrap;
  max-width: 100px;
`;
