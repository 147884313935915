import { useEffect } from 'react';
import * as ActionCable from '@rails/actioncable';

const useActionCable = (channelName, params, onUpdate) => {
  const channel = { ...params, channel: channelName };

  useEffect(() => {
    const cable = ActionCable.createConsumer();
    const subscription = cable.subscriptions.create(channel, { received: onUpdate });

    return () => {
      cable.subscriptions.remove(subscription);
    };
  }, []);
};

export { useActionCable };
