/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { Placeholder } from 'components/shared/Placeholder';

import * as styles from './AreaPlaceholder.styles';

const AreaPlaceholder = memo(({ show, image, message }) => {
  return (
    show && (
      <div css={styles.placeholder}>
        <Placeholder message={message} image={image} />
      </div>
    )
  );
});

export { AreaPlaceholder };
