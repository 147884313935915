/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { ContactsTableDesktop } from './ContactsTableDesktop';
import { ContactsTableMobile } from './ContactsTableMobile';
import { AttendanceTable } from './../AttendanceTable';
import useWindowDimensions from 'hooks/useWindowDimensions';

const ContactsTable = memo(
  ({
    contacts,
    contactMeetings,
    meetingStatus,
    setChairman,
    updateContact,
    isCreator,
    chairmanId,
    meetingOrganizerId,
    onDelete,
    onResend,
    readOnly,
  }) => {
    const { isMobile } = useWindowDimensions();
    const contactTableAttrs = {
      contacts,
      contactMeetings,
      meetingStatus,
      setChairman,
      updateContact,
      isCreator,
      chairmanId,
      meetingOrganizerId,
      onDelete,
      onResend,
      readOnly,
    };
    const contactsTable = isMobile ? (
      <ContactsTableMobile contactTableAttrs={contactTableAttrs} />
    ) : (
      <ContactsTableDesktop contactTableAttrs={contactTableAttrs} />
    );

    return (
      <>
        <div className='m-b-20'>
          <AttendanceTable contactMeetings={contactMeetings} />
        </div>
        {contactsTable}
      </>
    );
  }
);

export { ContactsTable };
