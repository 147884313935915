import axios from 'axios';

export default class API {
  // SmartSign specific
  static resendInvitation(resendPath, onSubmit, onError = this.handleError) {
    return axios
      .post(resendPath)
      .then((response) => response.data)
      .then(onSubmit)
      .catch(onError);
  }

  static updateEmail(resendPath, email, onSubmit, onError = this.handleError) {
    const formData = new FormData();
    formData.append('signatory[email]', email);
    return axios
      .put(resendPath, formData)
      .then((response) => response.data)
      .then(onSubmit)
      .catch(onError);
  }
}
