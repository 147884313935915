import { css } from '@emotion/core';
import { colors } from 'styles';

export const wrapper = css`
  padding: 12px 20px;
  width: 100%;
  border: 1px solid ${colors.headingGrey};

  @media (max-width: 767px) {
    padding: 12px 15px;
  }
`;

export const number = css`
  ${wrapper}
  border-right: none;
`;

export const icon = css`
  padding-right: 12px;
  display: flex;
  height: 43px;
  border: 1px solid ${colors.headingGrey};
  border-left: none;
`;

export const image = css`
  width: 31px;
  height: auto;
`;
