/** @jsx jsx */

import React, { memo, useState, useEffect, createRef } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { SVG } from 'components/shared/SVG';
import api from './API';
import { Dropdown } from 'components/shared/Dropdown';
import { DropdownOption } from 'components/shared/DropdownOption';
import Avatar from 'components/shared/Avatar';
import InvitationForm from './InvitationForm';
import Highlighter from 'react-highlight-words';
import { HighlightTag } from 'components/shared/HighlightTag';
import { Label } from 'components/shared/Input/Label';

import { useRegExpFormatter } from 'hooks/useRegExpFormatter';

import { ReactComponent as SearchIcon } from 'images/icons/documents/search-icon.svg';

import Invite from 'images/icons/mail-invite.svg';
import { ReactComponent as Exclamation } from 'images/icons/circle-exclamation.svg';

import * as styles from './ContactPicker.styles';
import { icons } from 'styles';

const ContactPicker = memo(
  ({
    inputLabel,
    values,
    placeholder,
    persistContact,
    onToggleOption,
    dropdownOptions,
    contactOption,
    isSelected,
    excludeId,
    isOptionEnabled,
    addContact,
    withInviteOption,
    dropdownWidth,
    dropdownIsOpen,
    dropdownStyles,
    standalone,
    isReadOnly,
    isDisabled,
    isMeetingOrganizerPicker,
    withPending,
    withConnectedCalendar,
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(dropdownOptions || []);
    const [isOpen, setIsOpen] = useState(!!dropdownIsOpen);

    const [searchTerm, setSearchTerm] = useState('');
    const [isFormOpen, setIsFormOpen] = useState(false);

    const { formattedRegExp } = useRegExpFormatter(searchTerm);

    const intl = useIntl();

    const picker = createRef(null);

    useEffect(() => {
      setOptions(dropdownOptions || []);
    }, [dropdownOptions]);

    const refetchContacts = () => {
      let withPendingParam = withPending || false;
      let withConnectedCalendarParam = withConnectedCalendar || false;

      api.loadContacts(searchTerm, withPendingParam, withConnectedCalendarParam, (contacts) => {
        setOptions(contacts);
      });
    };

    useEffect(() => {
      const node = picker.current;

      if (node) {
        node.addEventListener('contactpicker:reload', refetchContacts);

        return () => {
          node.removeEventListener('contactpicker:reload', refetchContacts);
        };
      }
    }, []);

    useEffect(() => {
      if (dropdownOptions) return;

      let isMounted = true;

      setIsLoading(true);

      let withPendingParam = withPending || false;
      let withConnectedCalendarParam = withConnectedCalendar || false;

      api.loadContacts(searchTerm, withPendingParam, withConnectedCalendarParam, (contacts) => {
        if (isMounted) {
          setOptions(contacts);
          setIsLoading(false);
        }
      });

      return () => {
        isMounted = false;
      };
    }, []);

    useEffect(() => {
      setIsOpen(!!dropdownIsOpen);
    }, [dropdownIsOpen]);

    useEffect(() => {
      if (isOpen) handleFocus();
    }, [isOpen, handleFocus]);

    useEffect(() => autoCloseForm(), [searchTerm]);

    const autoCloseForm = () => {
      if (isFormOpen && !isSearching) {
        closeForm();
      }
    };

    const handleSubmit = (contact) => {
      addContact(contact);
      closeForm();
    };

    const handleInputChange = (e) => {
      setSearchTerm(e.target.value || '');
    };

    const handleFocus = () => setIsOpen(true);

    const handleBlur = () => {
      if (dropdownIsOpen) return;

      setIsOpen(false);
    };

    const onInvite = () => setIsFormOpen(true);

    const closeForm = () => {
      setIsFormOpen(false);
      picker.current && picker.current.focus();
    };

    const opts = options.filter((item) => item.id !== excludeId);

    const pickerOptions = standalone
      ? opts.filter((opt) => !values.find((value) => value.id === opt.id))
      : withInviteOption
      ? [...opts, ...values.filter((value) => !value.withContact)]
      : opts;

    const uniqueEmail = !pickerOptions.map((item) => item.email).includes(searchTerm);

    const regexp = new RegExp(formattedRegExp, 'gi');
    const filteredOptions = pickerOptions.filter((item) => item.email.match(regexp) || item.name.match(regexp));

    const isSearching = searchTerm.length > 0;
    const hasOptions = filteredOptions.length > 0;

    const checkmark = (
      <div className='checkmark-circle'>
        <div className='checkmark' />
      </div>
    );

    const highlighter = (contact) => (
      <Highlighter textToHighlight={contact.name} highlightTag={HighlightTag} searchWords={[formattedRegExp]} />
    );

    const defaultContactOption = (contact, highlighter) => (
      <>
        <Avatar contact={contact} />

        <span className='m-l-15 flex-grow-1'>{highlighter(contact)}</span>
      </>
    );

    const renderOption = (contact, asSelected) => {
      const option = contactOption || defaultContactOption;

      return (
        <div className='d-flex align-items-center'>
          {option(contact, highlighter)}

          {!standalone && asSelected && checkmark}
        </div>
      );
    };

    const isContactEnabled = (contact) => {
      if (isOptionEnabled) {
        return isOptionEnabled(contact);
      } else {
        return true;
      }
    };

    const handleToggle = (contact) => {
      const remainingOptions = filteredOptions.filter((item) => item.id !== contact.id);

      onToggleOption(contact);

      if (!remainingOptions.length) {
        setIsOpen(false);
      }
    };

    const optionsToDisplay = filteredOptions.map((contact, index) => {
      const optionKey = `picker-${contact.id}-${index}`;
      const handleClick = () => {
        if (isContactEnabled(contact)) {
          handleToggle(contact);
        }
      };
      const asSelected = isSelected(contact);

      return (
        <DropdownOption onClick={handleClick} key={optionKey} disabled={!isContactEnabled(contact)}>
          {renderOption(contact, asSelected)}
        </DropdownOption>
      );
    });

    const noResults = <DropdownOption noResults />;

    const dropdownContent = () => {
      if (hasOptions) {
        return optionsToDisplay;
      } else {
        return noResults;
      }
    };

    const inviteOption = withInviteOption && (
      <div id='react-invite-signatory-option' onClick={onInvite} css={styles.inviteOption}>
        <SVG src={Invite} />
        <span>
          <FormattedMessage id='ContactPicker.addTerm' values={{ searchTerm }} />
        </span>
      </div>
    );

    const dropDown = isFormOpen ? (
      <InvitationForm
        searchTerm={searchTerm}
        persistContact={persistContact}
        onSubmit={handleSubmit}
        onClose={closeForm}
      />
    ) : (
      <div>
        {isMeetingOrganizerPicker && (
          <div css={styles.organizerNote}>
            <Exclamation />
            <div className='p-t-10 p-b-10 p-r-5'>
              <FormattedMessage id='ContactPicker.selectOrg' />
            </div>
          </div>
        )}
        <div className='react-items-wrapper'>
          <div css={styles.items}>{dropdownContent()}</div>
        </div>

        {isSearching && uniqueEmail && withInviteOption && inviteOption}
      </div>
    );

    const inputPlaceholder = placeholder || intl.formatMessage({ id: 'ContactPicker.enterName' });

    if (isReadOnly) {
      return standalone && values.length > 0 ? (
        <>
          <Label text={inputLabel} />

          <div className='m-l-5 m-r-15'>
            {values.map((contact) => (
              <div key={contact.id} className='m-t-15 d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <Avatar contact={contact} />
                  <div className='m-l-15 fs-14 text-black'>{contact.name}</div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null;
    }

    return (
      <>
        <Dropdown
          dropdownId='react-signatories-picker'
          ref={picker}
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputPlaceholder={inputPlaceholder}
          inputValue={searchTerm}
          isOpen={isOpen}
          dropdownWidth={dropdownWidth}
          isLoading={isLoading}
          isDisabled={isDisabled}
          inputIconPosition='left'
          inputLabel={inputLabel}
          inputIcon={<SearchIcon />}
          onInputChange={handleInputChange}
          inputId='react-picker-input'
          dropdownStyles={dropdownStyles}
          withClearIcon
        >
          {dropDown}
        </Dropdown>

        {standalone && (
          <div className='m-l-5 m-r-15'>
            {values.map((contact) => (
              <div key={contact.id} className='m-t-15 d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <Avatar contact={contact} />
                  <div className='m-l-15 fs-14 text-black'>{contact.name}</div>
                </div>

                <i css={icons.base} className='fs-14 far fa-trash-alt' onClick={handleToggle.bind(this, contact)} />
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
);

export { ContactPicker };
