import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Menu = ({ children, className }) => (
  <ul
    className={clsx(
      'tw-flex tw-flex-col tw-gap-1',
      'tw-rounded-sm tw-bg-white tw-p-3',
      'tw-border tw-border-solid tw-border-purple-100',
      'tw-shadow-dropdown',
      className
    )}
  >
    {children}
  </ul>
);

Menu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Menu;
