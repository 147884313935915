/** @jsx jsx */

import React, { memo, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import Avatar from 'components/shared/Avatar';
import { Row, Col } from 'react-bootstrap';
import { ContactPicker } from 'components/shared/ContactPicker';
import { ContactsTable } from './../ContactsTable';
import { useMeetingStore, useMeetingActions } from 'store/meetingStoreContext';
import { useContactMeetings } from 'hooks/useContactMeetings';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { OrganizerPicker } from './../OrganizerPicker';
import { ReactComponent as CalendarIcon } from 'images/icons/calendar.svg';

import * as styles from './AttendeesPicker.styles';

const AttendeesPicker = memo(({ isMinutesContext, isDisabled }) => {
  const { edits: meeting, isSubmit, errors } = useMeetingStore();
  const { setEdits, sendMeetingNotice } = useMeetingActions();
  const { isDraft, isPseudo, isUpcoming, isInSession, isScheduled } = useMeetingStatus(meeting.status);
  const { contact_meetings } = meeting;
  const { isMobile } = useWindowDimensions();

  const { contacts, isCreator, setChairman, updateContact, removeContact, contactExists, toggleContact } =
    useContactMeetings(contact_meetings, setEdits, meeting.contact_id);

  const intl = useIntl();

  const meetingOrganizerId = useCallback(() => {
    if (meeting.event_organizer) {
      return meeting.event_organizer.data.id;
    } else {
      return meeting.calendar_contact_id;
    }
  }, [meeting]);

  const isContactEventOrganizer = (contact) => contact.id == meetingOrganizerId();
  const isContactEnabled = (contact) => !isContactEventOrganizer(contact);
  const organizerContact = () => contacts.find(isContactEventOrganizer);

  const setOrganizerContact = (contact) => {
    setEdits('calendar_contact_id', contact?.id);
  };

  const togglePickerContact = (contact) => {
    toggleContact(contact);

    if (isContactEventOrganizer(contact)) {
      setOrganizerContact(contact);
    }
  };

  const removeTableContact = (contact) => {
    if (isContactEventOrganizer(contact)) {
      setOrganizerContact(contact);
    }

    removeContact(contact);
  };

  const calendarIndicator = (contact) => {
    if (isContactEventOrganizer(contact)) {
      return <CalendarIcon className='m-r-15' />;
    }
  };

  const eventOrganizerOption = (contact, highlighter) => (
    <>
      <Avatar contact={contact} />

      <span className='m-l-15 flex-grow-1'>{highlighter(contact)}</span>

      {calendarIndicator(contact)}
    </>
  );

  const sendNotice = (contact) => sendMeetingNotice(meeting.id, contact.id);
  const showInfoMessage = isScheduled || isUpcoming || isInSession;
  const infoMessage = showInfoMessage && !isMinutesContext && (
    <div className='fs-12 c-mid font-weight-regular lh-14'>
      <FormattedMessage id='AttendeesPicker.infoMessage' />
    </div>
  );

  return (
    <div className={`position-relative ${isDisabled ? 'avoid-clicks' : ''}`}>
      <div css={styles.padded}>
        {!isDisabled ? (
          <>
            <div className='fs-16 text-black font-weight-bold'>
              <FormattedMessage id='AttendeesPicker.inviteAttendees' />
            </div>
            {infoMessage}
            <Row noGutters>
              <Col md={6}>
                <div className='p-t-20'>
                  <ContactPicker
                    values={contacts}
                    onToggleOption={togglePickerContact}
                    dropdownIsOpen={false}
                    inputLabel={intl.formatMessage({ id: 'AttendeesPicker.addLabel' })}
                    isSelected={contactExists}
                    contactOption={eventOrganizerOption}
                    isOptionEnabled={isContactEnabled}
                    isDisabled={isDisabled}
                    withConnectedCalendar
                  />
                </div>
              </Col>

              {!isPseudo && (
                <Col md={6}>
                  <div className={`${!isMobile && 'p-l-40'}`}>
                    <label className='fs-12 text-black font-weight-semibold m-r-5 m-t-15 m-b-15'>
                      <FormattedMessage id='AttendeesPicker.selectOrganiser' />
                    </label>
                    <OrganizerPicker
                      contacts={contacts}
                      organizer={organizerContact()}
                      onOrganizerChange={setOrganizerContact}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <Row noGutters>
              <Col md={12}>
                <div className='m-t-25'>
                  <ContactsTable
                    contacts={contacts}
                    contactMeetings={contact_meetings}
                    updateContact={updateContact}
                    meetingStatus={meeting.status}
                    isCreator={isCreator}
                    chairmanId={meeting.chairman_id}
                    readOnly={isDisabled}
                    setChairman={setChairman}
                    onDelete={removeTableContact}
                    onResend={sendNotice}
                    meetingOrganizerId={meetingOrganizerId()}
                  />
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <div>
            <p css={styles.title}>
              <FormattedMessage id='AttendeesPicker.attendees' /> {!!contacts.length ? `(${contacts.length})` : ''}
            </p>
            <div css={styles.attendeesWrapper}>
              {contacts.map((contact) => {
                return (
                  <div key={contact.id} css={styles.attendee}>
                    <Avatar contact={contact} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export { AttendeesPicker };
