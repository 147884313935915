import { gql, useMutation } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';

const MOVE_DIRECTORY = gql`
  mutation moveDirectory($input: MoveDirectoryInput!) {
    moveDirectory(input: $input) {
      directory {
        ...DirectoryFields

        parent {
          id
          hasChildren
        }
      }

      errors {
        attribute
        fullMessage
      }
    }
  }
  ${DIRECTORY_FRAGMENT}
`;

const useMoveDirectoryMutation = (options) => useMutation(MOVE_DIRECTORY, options);

export { useMoveDirectoryMutation };
