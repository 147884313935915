import { gql } from '@apollo/client';

const TIMEZONE_FRAGMENT = gql`
  fragment TimezoneFields on Timezone {
    name
    gmtOffset
  }
`;

export { TIMEZONE_FRAGMENT };
