/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { POSITIONS } from './constants';

import * as styles from './Divider.styles';

const Divider = ({ title, position = 'center', isBold = false, className }) => (
  <div css={[styles.divider.base, styles.divider[position], isBold && styles.divider.bold]} className={className}>
    {title}
  </div>
);

Divider.propTypes = {
  title: PropTypes.string,
  isBold: PropTypes.bool,
  position: PropTypes.oneOf(POSITIONS),
  className: PropTypes.string,
};

export default Divider;
