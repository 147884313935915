import { gql } from '@apollo/client';

const DIRECTORY_CAPABILITIES_FRAGMENT = gql`
  fragment DirectoryCapabilitiesFields on DirectoryCapabilities {
    canCreate
    canUpdate
    canDelete
    canShare
    canMove
  }
`;

export { DIRECTORY_CAPABILITIES_FRAGMENT };
