import { createStore } from './createStore';
import { getRandomId } from 'helpers/getRandomId';
import { updateById } from 'utils/updateById';
import { PENDING, UPLOADING, COMPLETE, CANCELLED, ERROR } from 'data/globals/UploadQueueItemStates';

const initialState = {
  uploadQueue: [],
  currentUpload: null,
  pendingCount: 0,
  completeCount: 0,
};

const actionTypes = {
  ENQUEUE: 'uploadQueue.enqueue',
  PROCESS: 'uploadQueue.process',
  UPDATE: 'uploadQueue.update',
  COMPLETE: 'uploadQueue.complete',
  REJECT: 'uploadQueue.reject',
  CANCEL: 'uploadQueue.cancel',
  RETRY: 'uploadQueue.retry',
  DISCARD: 'uploadQeuue.discard',
};

const useUploadQueueActions = () => {
  const dispatch = useUploadQueueDispatch();

  const addFilesToQueue = (payload) => {
    dispatch({ type: actionTypes.ENQUEUE, payload });
  };

  const processQueue = () => {
    dispatch({ type: actionTypes.PROCESS });
  };

  const setCurrentUpload = (payload) => {
    dispatch({ type: actionTypes.UPDATE, payload });
  };

  const completeCurrentUpload = () => {
    dispatch({ type: actionTypes.COMPLETE });
  };

  const rejectCurrentUpload = (payload) => {
    dispatch({ type: actionTypes.REJECT, payload });
  };

  const retryUpload = (payload) => {
    dispatch({ type: actionTypes.RETRY, payload });
  };

  const cancelUpload = (payload) => {
    dispatch({ type: actionTypes.CANCEL, payload });
  };

  const discardQueue = () => {
    dispatch({ type: actionTypes.DISCARD });
  };

  return {
    addFilesToQueue,
    processQueue,
    discardQueue,
    cancelUpload,
    retryUpload,
    completeCurrentUpload,
    rejectCurrentUpload,
    setCurrentUpload,
  };
};

const convertToUpload = (file, directory, resolutionId) => ({
  file,
  directory,
  resolutionId,
  id: getRandomId(),
  error: null,
  progress: 0,
  state: PENDING,
});

const handleEnqueue = (state, payload) => {
  const uploadables = payload.files.map((item) => convertToUpload(item, payload.directory, payload.resolutionId));
  const uploadQueue = [...state.uploadQueue, ...uploadables];
  const pendingCount = state.pendingCount + uploadables.length;

  return { ...state, uploadQueue, pendingCount };
};

const handleProcess = (state) => {
  const currentUpload = state.uploadQueue.find((item) => item.state === PENDING);

  if (!currentUpload) {
    return { ...state, currentUpload };
  }

  const uploadQueue = updateById(state.uploadQueue, {
    id: currentUpload.id,
    state: UPLOADING,
  });

  return { ...state, currentUpload, uploadQueue };
};

const handleUpdate = (state, payload) => {
  if (!state.currentUpload) return state;

  const uploadQueue = updateById(state.uploadQueue, {
    id: state.currentUpload.id,
    ...payload,
  });

  return { ...state, uploadQueue };
};

const handleComplete = (state) => {
  if (!state.currentUpload) return state;

  const uploadQueue = updateById(state.uploadQueue, {
    id: state.currentUpload.id,
    state: COMPLETE,
    progress: 100,
  });

  const completeCount = state.completeCount + 1;
  const pendingCount = state.pendingCount - 1;

  return handleProcess({ ...state, uploadQueue, completeCount, pendingCount });
};

const handleReject = (state, payload) => {
  const uploadQueue = updateById(state.uploadQueue, {
    id: state.currentUpload.id,
    error: payload,
    state: ERROR,
    progress: 0,
  });

  const pendingCount = state.pendingCount - 1;

  return handleProcess({ ...state, uploadQueue, pendingCount });
};

const handleDiscard = (state) => {
  return { ...initialState };
};

const handleCancel = (state, payload) => {
  const item = state.uploadQueue.find((item) => item.id === payload.id);

  if (!item) {
    return state;
  }

  if (item.xhr) {
    item.xhr.abort();
  }

  const uploadQueue = updateById(state.uploadQueue, {
    id: item.id,
    state: CANCELLED,
    progress: 0,
  });

  const pendingCount = state.pendingCount - 1;

  if (state.currentUpload.id === item.id) {
    return handleProcess({ ...state, uploadQueue, pendingCount });
  } else {
    return { ...state, uploadQueue, pendingCount };
  }
};

const handleRetry = (state, payload) => {
  const uploadQueue = updateById(state.uploadQueue, {
    ...payload,
    state: PENDING,
    progress: 0,
    error: null,
  });

  const pendingCount = state.pendingCount + 1;

  if (!state.currentUpload) {
    return handleProcess({ ...state, uploadQueue, pendingCount });
  } else {
    return { ...state, uploadQueue, pendingCount };
  }
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.ENQUEUE:
      return handleEnqueue(state, payload);
    case actionTypes.PROCESS:
      return handleProcess(state);
    case actionTypes.UPDATE:
      return handleUpdate(state, payload);
    case actionTypes.REJECT:
      return handleReject(state, payload);
    case actionTypes.COMPLETE:
      return handleComplete(state);
    case actionTypes.CANCEL:
      return handleCancel(state, payload);
    case actionTypes.DISCARD:
      return handleDiscard(state);
    case actionTypes.RETRY:
      return handleRetry(state, payload);
  }
};

const [UploadQueueProvider, useUploadQueueStore, useUploadQueueDispatch] = createStore(reducer, initialState);

export { UploadQueueProvider, useUploadQueueStore, useUploadQueueActions };
