import { sort } from 'fast-sort';
import { useSubscription } from '@apollo/client';
import { NOTIFICATIONS_QUERY } from './../data/queries/NotificationsQuery';
import { NOTIFICATIONS_SUBSCRIPTION } from './../data/subscriptions/NotificationsSubscription';

const MAX_NOTIFICATIONS = 20;

const useNotificationsSubscription = ({ onUpdate }) => {
  useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
    shouldResubscribe: true,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const cache = client.readQuery({ query: NOTIFICATIONS_QUERY });

      if (!subscriptionData.data) return;

      const { notificationAdded } = subscriptionData.data;

      if (!notificationAdded) return;

      onUpdate();

      if (!cache) return;

      const { notifications } = cache.currentContact;
      const isAdded = notifications.find((notification) => notification.id === notificationAdded.id);

      if (isAdded) return;

      const collection = [...notifications, notificationAdded];
      const sorted = sort(collection)
        .desc((item) => item.createdAt)
        .slice(0, MAX_NOTIFICATIONS);

      client.writeQuery({
        query: NOTIFICATIONS_QUERY,
        data: { ...cache, currentContact: { ...cache.currentContact, notifications: sorted } },
      });
    },
  });
};

export { useNotificationsSubscription };
