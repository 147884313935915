import { gql } from '@apollo/client';

const ZOOM_MEETING_FRAGMENT = gql`
  fragment ZoomMeetingFields on ZoomMeeting {
    id
    password
    agenda
    waitingRoom
    hostVideo
    participantVideo
    joinBeforeHost
    muteUponEntry
    autoRecording
    existing
  }
`;

export { ZOOM_MEETING_FRAGMENT };
