import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Link from 'components/v2/layout/Link';
import { FiChevronRight } from 'react-icons/fi';

const CompanyItem = ({ href, children, isSelected = false }) => (
  <Link
    href={href}
    aria-selected={isSelected}
    className={clsx(
      'tw-justify-between',
      'tw-py-4 tw-pl-8 tw-pr-6',
      'tw-border-0 tw-border-b tw-border-solid tw-border-gray-100 xl:tw-border-0',
      {
        'tw-text-black hover:tw-text-purple-500': !isSelected,
        'tw-bg-purple-100 tw-text-purple-500 hover:tw-opacity-80': isSelected,
      }
    )}
  >
    {children}
    <FiChevronRight className='tw-h-4 tw-w-4 xl:tw-hidden' />
  </Link>
);

CompanyItem.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  isSelected: PropTypes.bool,
};

export default CompanyItem;
