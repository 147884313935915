/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { AgendaTaskListItem } from './../AgendaTaskListItem';

const AgendaTaskList = memo(({ agenda, meetingId, tasks, editTask, deleteTask, isReadOnly }) => {
  const taskList = tasks.map((task) => {
    const handleDelete = deleteTask.bind(this, task);
    const handleEdit = editTask.bind(this, task);

    return (
      <AgendaTaskListItem
        key={`agenda-task-${task.id}`}
        agendaId={agenda.id}
        meetingId={meetingId}
        task={task}
        editTask={handleEdit}
        deleteTask={handleDelete}
        isReadOnly={isReadOnly}
      />
    );
  });

  return taskList;
});

export { AgendaTaskList };
