import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withContext } from 'utils/v2/shared';
import { FileUploadContextProvider, useFileUploadContext } from 'contexts/v2/FileUploadContext';
import Button, { Sizes, Variants } from 'components/v2/shared/Button';
import AttachmentList from './AttachmentList';
import Dropzone from 'components/v2/shared/Dropzone';

export const DocumentUpload = ({ isDismissable = false, isDisabled, onUpload, onDismiss }) => {
  const {
    attachments,
    enqueuedAttachmentId,
    addAttachments,
    cancelAttachment,
    restartAttachment,
    removeAttachment,
    uploadAttachment,
    hasPendingAttachments,
    hasFailedAttachments,
  } = useFileUploadContext();

  const acceptedFileTypes = [
    '.png',
    '.jpeg',
    '.jpg',
    '.ppt',
    '.pdf',
    '.pptx',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.xlsm',
  ];

  useEffect(() => {
    uploadAttachment(enqueuedAttachmentId, onUpload);
  }, [enqueuedAttachmentId]);

  const StatusText = () => {
    if (!attachments.length) {
      return 'Upload files';
    } else if (hasPendingAttachments) {
      return 'Uploading';
    } else {
      return 'Upload complete';
    }
  };

  return (
    <div className='tw-inline-flex tw-w-full tw-max-w-md tw-flex-col'>
      <div className='tw-mb-2.5 tw-flex tw-items-center tw-justify-between'>
        <div className='tw-text-lg tw-font-bold tw-text-black'>
          <StatusText />
        </div>

        {isDismissable && (
          <Button onClick={onDismiss} size={Sizes.Small} variant={Variants.Transparent}>
            Close
          </Button>
        )}
      </div>

      <Dropzone
        accept={acceptedFileTypes}
        isDisabled={isDisabled}
        onDrop={addAttachments}
        className={clsx('tw-h-44 tw-rounded-xl tw-border-2 tw-border-dashed tw-bg-white tw-p-6 md:tw-h-52', {
          'tw-border-purple-500': !hasFailedAttachments,
          'tw-border-red-400': hasFailedAttachments,
        })}
      >
        <AttachmentList
          attachments={attachments}
          onAttachmentRemove={removeAttachment}
          onAttachmentCancel={cancelAttachment}
          onAttachmentRestart={restartAttachment}
        />
      </Dropzone>
    </div>
  );
};

DocumentUpload.propTypes = {
  isDismissable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onUpload: PropTypes.func,
  onDismiss: PropTypes.func,
};

export default withContext(FileUploadContextProvider, DocumentUpload);
