/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';

import useWindowDimensions from 'hooks/useWindowDimensions';
import * as styles from './Invoices.styles';

const Invoices = ({ company }) => {
  const [invoices, setInvoices] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const { isMobile } = useWindowDimensions();

  const intl = useIntl();

  useEffect(() => {
    $.ajax({
      url: `/${company.slug}/invoices`,
      method: 'get',
      headers: {
        'X-CSRF-Token': $("[name='csrf-token']").attr('content'),
      },
      data: {
        id: company.id,
      },
    }).then(setInvoices);
  }, []);

  const handleClick = (invoice) => window.open(invoice.receipt_link);

  const chevronStyles = [styles.chevron.base, isExpanded && styles.chevron.rotated];

  if (!invoices || !invoices.length) {
    return null;
  }

  const dateFormat = getUserDateFormat() || 'DD/MM/YYYY';

  const mobileList = invoices.map((invoice) => (
    <div css={styles.mobileCard} key={`mobile-${invoice.id}`}>
      <div css={styles.mobileRow}>
        <div css={styles.mobileTitle}>
          <FormattedMessage id='Invoices.transDate' />
        </div>
        <div css={styles.mobileValue}>{moment(invoice.paid_at).format(dateFormat)}</div>
      </div>

      <div css={styles.mobileRow}>
        <div css={styles.mobileTitle}>
          <FormattedMessage id='Invoices.invNum' />
        </div>
        <div css={styles.mobileValue}>{invoice.number}</div>
      </div>

      <div css={styles.mobileRow}>
        <div css={styles.mobileTitle}>
          <FormattedMessage id='Invoices.invTotal' />
        </div>
        <div css={styles.mobileValue}>
          {invoice.total == 0
            ? intl.formatMessage({ id: 'Invoices.freeTrial' })
            : `${invoice.currency === 'usd' ? '$' : '£'}${Number(invoice.total).toFixed(2)}`}
        </div>
      </div>

      <div css={styles.mobileRow}>
        <div css={styles.mobileTitle}>
          <FormattedMessage id='Invoices.status' />
        </div>
        <div css={styles.mobileValue}>
          <FormattedMessage id='Invoices.paid' />
        </div>
      </div>

      <div css={styles.mobileRow}>
        <div css={styles.mobileTitle}>
          <FormattedMessage id='Invoices.pdf' />
        </div>
        <div css={styles.mobileValue} className='font-weight-extrabold' onClick={() => handleClick(invoice)}>
          <FormattedMessage id='Invoices.download' />
        </div>
      </div>
    </div>
  ));

  return (
    <div css={styles.expandable} className='m-t-30 m-b-30'>
      <div className='d-flex align-items-center justify-content-between' css={styles.header}>
        <h2 className='fs-18 font-weight-bold c-dark m-b-0'>
          <FormattedMessage id='Invoices.history' />
        </h2>
        <div onClick={toggleExpanded} className='cursor-pointer fs-14 c-secondary'>
          <span>
            {isExpanded ? intl.formatMessage({ id: 'Invoices.hide' }) : intl.formatMessage({ id: 'Invoices.show' })}
          </span>
          <i className='fa fa-chevron-down' css={chevronStyles} />
        </div>
      </div>

      {isExpanded && isMobile && <div>{mobileList}</div>}

      {isExpanded && !isMobile && (
        <table className='table shaparency-table' key='invoices-table-ele'>
          <thead>
            <tr>
              <th>
                <FormattedMessage id='Invoices.transDate' />
              </th>
              <th>
                <FormattedMessage id='Invoices.invNum' />
              </th>
              <th>
                <FormattedMessage id='Invoices.invTotal' />
              </th>
              <th>
                <FormattedMessage id='Invoices.status' />
              </th>
              <th className='text-center'>
                <FormattedMessage id='Invoices.pdf' />
              </th>
            </tr>
          </thead>

          <tbody>
            {invoices.map((invoice) => (
              <tr key={`invoice-row-${invoice.id}`}>
                <td>{moment(invoice.paid_at).format(dateFormat)}</td>
                <td>{invoice.number}</td>
                <td>
                  {invoice.total == 0
                    ? intl.formatMessage({ id: 'Invoices.freeTrial' })
                    : `${invoice.currency === 'usd' ? '$' : '£'}${Number(invoice.total).toFixed(2)}`}
                </td>
                <td>
                  <FormattedMessage id='Invoices.paid' />
                </td>
                <td className='text-center'>
                  <button className='btn btn-sm' onClick={() => handleClick(invoice)}>
                    <FormattedMessage id='Invoices.download' />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Invoices;
