/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { media } from 'styles';
import { jsx } from '@emotion/core';
import { withIntl } from '../../../utils/v2/intl';
import { useIntl, FormattedMessage } from 'react-intl';

import NavItem from './NavItem';
import { ReactComponent as CloseIcon } from 'images/icons/purple-cross.svg';
import { NAV_ITEM_WIDTH, MOBILE_NAV_PADDING } from './constants';
import { calcVisibleNavItems, setLink, setLinksList } from './helpers';

import * as styles from './index.styles';

const MobileNavigation = ({
  linkDashboard,
  linkDirectories,
  linkMeetings,
  linkMinutes,
  linkTasks,
  linkSmart,
  linkVotes,
  isDashboard,
  isDirectories,
  isMeetings,
  isMinutes,
  isTasks,
  isSmart,
  isVotes,
}) => {
  const links = setLinksList({
    dashboard: setLink(linkDashboard, isDashboard),
    directories: setLink(linkDirectories, isDirectories),
    meetings: setLink(linkMeetings, isMeetings),
    minutes: setLink(linkMinutes, isMinutes),
    tasks: setLink(linkTasks, isTasks),
    smart: setLink(linkSmart, isSmart),
    votes: setLink(linkVotes, isVotes),
  });

  const intl = useIntl();

  const [visibleLinks, setVisibleLinks] = useState([]);
  const [hiddenLinks, setHiddenLinks] = useState([]);
  const [moreItemsVisible, setMoreItemsVisible] = useState(false);

  useEffect(() => {
    calcVisibleNavItems({ links, setVisibleLinks, setHiddenLinks });

    const handleResize = () => {
      if (window.innerWidth > media.TABLET_SCREEN_WIDTH) return;
      calcVisibleNavItems({ links, setVisibleLinks, setHiddenLinks });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div css={styles.mobileNavWrapper}>
        <div css={styles.navContainer(MOBILE_NAV_PADDING)}>
          {visibleLinks.map((theLink) => {
            const { title, link, iconClassName, isActive } = theLink;
            return (
              <NavItem
                navItemWidth={NAV_ITEM_WIDTH}
                title={title}
                link={link}
                isActive={isActive}
                key={title}
                iconClassName={iconClassName}
              />
            );
          })}
          {!!hiddenLinks.length ? (
            <NavItem
              navItemWidth={NAV_ITEM_WIDTH}
              title={intl.formatMessage({ id: 'Navigation.more' })}
              link={null}
              action={() => setMoreItemsVisible(true)}
              iconClassName='fas fa-ellipsis-h'
            />
          ) : null}
        </div>
      </div>

      <div css={styles.moreItems(moreItemsVisible)}>
        <div css={styles.moreItems_header}>
          <span>
            <FormattedMessage id='Navigation.more' />
          </span>
          <div css={styles.moreItems_close} onClick={() => setMoreItemsVisible(false)}>
            <CloseIcon />
          </div>
        </div>
        {hiddenLinks.map((theLink) => {
          const { title, link, iconClassName, isActive } = theLink;
          return (
            <NavItem
              navItemWidth={NAV_ITEM_WIDTH}
              title={title}
              link={link}
              isActive={isActive}
              key={title}
              insideMore={true}
              iconClassName={iconClassName}
            />
          );
        })}
      </div>
    </>
  );
};

export default withIntl(MobileNavigation);
