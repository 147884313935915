/** @jsx jsx */

import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import * as _styles from './Error.styles';

const Error = memo(({ message, styles }) => {
  if (!message) {
    return <></>;
  }

  return <div css={[_styles.component, styles]}>{message}</div>;
});

Error.propTypes = {
  message: PropTypes.string,
};

export { Error };
