import React, { memo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { client } from 'utils/apollo';
import { MeetingProvider } from 'store/meetingStoreContext';
import { FlashProvider } from 'store/flashStoreContext';
import { TaskDrawerProvider } from 'store/taskDrawerStoreContext';
import { TasksProvider } from 'store/tasksStoreContext';
import { AgendasProvider } from 'store/agendasStoreContext';
import { DocumentsProvider } from 'store/documentsStoreContext';
import { MinutesProvider } from 'store/minutesStoreContext';
import { OoEProvider } from 'store/ooeStoreContext';
import { CommentsProvider } from 'store/commentsStoreContext';
import { ReviewsProvider } from 'store/reviewsStoreContext';
import AppSignalWrapper from '../../v2/shared/AppSignalWrapper';
import { TranslationContextProvider } from '../../../contexts/v2/TranslationContext';

import { ReviewSpace } from 'components/minutes/ReviewSpace';

export default memo(({ minuteId, review, meetingId, currentContact, contactTimezone, contactTimezoneOffset }) => {
  return (
    <AppSignalWrapper tags={{ page: 'minutes/review' }}>
      <ApolloProvider client={client}>
        <TranslationContextProvider>
          <FlashProvider>
            <TaskDrawerProvider>
              <MeetingProvider>
                <TasksProvider>
                  <AgendasProvider>
                    <DocumentsProvider>
                      <OoEProvider>
                        <CommentsProvider>
                          <ReviewsProvider>
                            <MinutesProvider>
                              <ReviewSpace
                                minuteId={minuteId}
                                review={review}
                                meetingId={meetingId}
                                currentContact={currentContact}
                                contactTimezone={contactTimezone}
                                contactTimezoneOffset={contactTimezoneOffset}
                              />
                            </MinutesProvider>
                          </ReviewsProvider>
                        </CommentsProvider>
                      </OoEProvider>
                    </DocumentsProvider>
                  </AgendasProvider>
                </TasksProvider>
              </MeetingProvider>
            </TaskDrawerProvider>
          </FlashProvider>
        </TranslationContextProvider>
      </ApolloProvider>
    </AppSignalWrapper>
  );
});
