import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as Logo } from 'images/logo-white.svg';
import useCurrentUserCompanies from 'hooks/useCurrentUserCompanies';
import { FiChevronDown } from 'react-icons/fi';
import { BsFillBuildingFill } from 'react-icons/bs';
import { HiMenu } from 'react-icons/hi';
import NotificationsToggle from 'components/notifications/Toggle';
import ChatsToggle from 'components/conversations/Toggle';
import Avatar from 'components/v2/shared/Avatar/Avatar';
import TimeClock from 'components/toolbar/TimeClock';
import { Menu } from '@headlessui/react';
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import { getCurrentBreakpoint } from 'helpers/tailwindStyling';
import useWindowDimensions from 'hooks/useWindowDimensions';
import StateBadge from '../../shared/StateBadge/StateBadge';
import Button from 'components/v2/shared/Button';

const Link = ({ path, isActive, text }) => {
  return (
    <li
      className={clsx('tw-flex tw-h-[46px] tw-items-center tw-justify-center tw-truncate tw-px-3', {
        'tw-border-0 tw-border-b-2 tw-border-solid tw-border-b-purple-500': isActive,
      })}
    >
      <a
        href={path}
        className={clsx('tw-text-black hover:tw-text-purple-500', {
          'tw-font-semibold tw-text-purple-500': isActive,
        })}
      >
        {text}
      </a>
    </li>
  );
};

const Drawer = ({ currentCompany, breakpoint, links, isConsoleEnabled }) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [subcomList, setSubcomList] = useState([]);

  const { data, loading } = useCurrentUserCompanies({});

  const handleCompanyClick = (company) => {
    if (!company.isPending) {
      window.location.assign(`/${company.slug}/dashboard`);
    }
  };

  const handleMobileNavClick = () => {
    setIsListOpen(false);
  };

  const handleHover = (e) => {
    e.stopPropagation();
    const {
      currentUser: {
        companies: { edges: companies },
      },
    } = data;
    const { currentUser } = data;

    const subcommitteesList = companies.flatMap((company) =>
      company.node.subcommittees.filter((subcom) =>
        subcom.contacts.some((contact) => contact.email === currentUser.email)
      )
    );
    setSubcomList(subcommitteesList);

    if (companies.length > 0 || subcomList.length > 0) {
      setIsListOpen(!isListOpen);
    }
  };

  const renderCompanyList = () => {
    if (!data && loading) {
      return;
    }

    const {
      currentUser: {
        companies: { edges: companies },
      },
    } = data;

    if (!companies.length) {
      return <div className='tw-p-5 tw-text-center tw-text-sm'>No companies found.</div>;
    }

    return (
      <div
        className={clsx('tw-px-5 tw-pt-2 tw-pb-4.5', {
          '!tw-p-0': !['xl', '2xl'].includes(breakpoint) && !isListOpen,
          '!tw-px-5 !tw-pb-4.5': isListOpen,
          '!tw-px-0 !tw-pb-0 !tw-pt-0': isListOpen && companies.length <= 1,
          '!tw-py-0': isListOpen && companies.length <= 1,
        })}
      >
        <>
          {companies.length > 0 ? (
            <div className='tw-mt-2 tw-flex tw-h-[21px] tw-items-center tw-text-xs tw-font-bold'>Select a company:</div>
          ) : null}
          {companies.map((company) => {
            const {
              node: { id, name, subcommittees, isPrimary, trialEnded },
            } = company;
            const { currentUser } = data;
            const subcommitteesList = subcommittees.filter((subcom) =>
              subcom.contacts.some((contact) => contact.email === currentUser.email)
            );

            return (
              <Fragment key={id}>
                <div
                  className='tw-mt-3 tw-flex tw-h-8 tw-w-full tw-items-center tw-justify-between tw-text-xs tw-font-semibold tw-text-purple-500'
                  onClick={() => handleCompanyClick(company.node)}
                >
                  <div className='tw-flex tw-items-center tw-gap-2 tw-truncate'>
                    <div className='tw-flex tw-h-6 tw-w-6 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-purple-200'>
                      <BsFillBuildingFill className='tw-w-full' />
                    </div>
                    <span className='tw-truncate'>{name}</span>
                  </div>
                  {isPrimary && !trialEnded ? (
                    <div className='tw-flex tw-justify-end'>
                      <StateBadge variant='purple' className='!tw-rounded !tw-h-8'>
                        Primary
                      </StateBadge>
                    </div>
                  ) : null}
                  {trialEnded ? (
                    <Button font='semibold' variant='purple' className='tw-h-8 !tw-rounded tw-text-sm'>
                      Renew
                    </Button>
                  ) : null}
                </div>
                <div className='tw-mt-1'>
                  {subcommitteesList.map((sc, index) => (
                    <div
                      key={sc.id}
                      className='tw-relative tw-ml-3 tw-text-xs tw-text-purple-500'
                      onClick={() => handleCompanyClick(sc)}
                    >
                      <div
                        className={clsx('tw-h-8 tw-w-[30px] tw-border tw-border-t-0 tw-border-r-0 tw-border-solid', {
                          'tw-rounded-bl-md': index === subcommitteesList?.length - 1,
                          '!tw-h-4': index === 0,
                        })}
                      ></div>
                      <div
                        key={sc.id}
                        className={clsx('tw-absolute tw-top-[24px] tw-left-10', { '!tw-top-2': index === 0 })}
                      >
                        <span className='tw-truncate tw-text-xs'>{sc.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </Fragment>
            );
          })}
        </>
      </div>
    );
  };

  const Dropdown = () => {
    if (!data && loading) {
      return null;
    }

    return (
      <>
        {isListOpen ? (
          <div className='tw-top-18 tw-absolute tw-flex tw-justify-center  sm:tw-left-10 sm:tw-w-[270px]'>
            <div
              className='tw-max-h-[268px] tw-w-11/12 tw-overflow-y-auto tw-rounded-lg tw-bg-white tw-text-black tw-shadow-xl tw-scrollbar-thin tw-scrollbar-track-gray-200 tw-scrollbar-thumb-purple-500 tw-scrollbar-track-rounded-lg tw-scrollbar-thumb-rounded-lg'
              onMouseLeave={handleHover}
            >
              {renderCompanyList()}
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const NameDisplay = () => {
    if (!data && loading) {
      return <span className='tw-pl-4.5'>{currentCompany.name}</span>;
    }

    const {
      currentUser: {
        companies: { edges: companies },
      },
    } = data;

    const { currentUser } = data;

    const subcommitteesList = companies.flatMap((company) =>
      company.node.subcommittees.filter((subcom) =>
        subcom.contacts.some((contact) => contact.email === currentUser.email)
      )
    );

    const otherCompanies = companies.filter((company) => !company.node.isCurrentCompany);

    return (
      <div className='tw-flex tw-w-11/12 tw-items-center tw-justify-between'>
        <span className='tw-truncate'>{currentCompany.name}</span>
        {otherCompanies.length > 0 || subcommitteesList.length > 0 ? (
          <FiChevronDown className='tw-h-3.5 tw-w-3.5' strokeWidth={4} />
        ) : null}
      </div>
    );
  };

  return (
    <div className='tw-w-[100px] tw-cursor-pointer tw-flex-col tw-justify-center tw-bg-purple-500 tw-text-sm tw-font-bold tw-text-white xs:tw-w-[238px]'>
      <div className='tw-flex tw-h-[67px] tw-items-center'>
        {!['xl', '2xl'].includes(breakpoint) ? <MobileNavMenu onClick={handleMobileNavClick} links={links} isConsoleEnabled={isConsoleEnabled} /> : null}
        <div onMouseEnter={handleHover} className='tw-flex tw-h-full tw-w-full tw-items-center tw-justify-between'>
          <Logo className='tw-ml-3.5 tw-h-[29px]' />
          <div className='tw-w-full tw-pl-4.5'>
            <NameDisplay />
          </div>
        </div>
      </div>
      <Dropdown />
    </div>
  );
};

const ProfileMenu = ({ userData, links }) => {
  return (
    <Menu as='div' className='tw-relative'>
      <Menu.Button className='tw-border-none tw-bg-transparent'>
        <Avatar
          placeholder={userData?.initials}
          imgUrl={userData?.avatar}
          className='tw-h-8 tw-w-8 tw-bg-teal-500 xl:tw-bg-purple-500'
        />
      </Menu.Button>
      <Menu.Items className='tw-absolute tw-right-0 tw-mt-2 tw-w-40 tw-bg-white'>
        <Menu.Item className='tw-w-full tw-border-0 tw-border-b tw-border-solid tw-border-black tw-border-opacity-30 tw-p-4'>
          <a href={links.linkProfile} className='tw-cursor-pointer'>
            <div className='tw-flex tw-items-center tw-text-black hover:tw-text-purple-500'>
              <FaUser className='tw-mr-4' />
              <span className='tw-text-sm'>Profile</span>
            </div>
          </a>
        </Menu.Item>
        <Menu.Item className='tw-w-full tw-p-4'>
          <a href={links.linkSignOut} data-method='delete' className='tw-cursor-pointer'>
            <div className='tw-flex tw-items-center tw-text-black hover:tw-text-purple-500'>
              <FaSignOutAlt className='tw-mr-4' />
              <span className='tw-text-sm'>Sign out</span>
            </div>
          </a>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

const MobileNavMenu = ({ onClick, links, isConsoleEnabled }) => {
  return (
    <Menu as='div' className='tw-relative' onMouseEnter={onClick}>
      <Menu.Button className='tw-border-none tw-bg-transparent'>
        <HiMenu className='tw-ml-3.5 tw-text-3xl tw-text-white' />
      </Menu.Button>
      <Menu.Items className='tw-absolute tw-left-0 tw-mt-4 tw-h-screen tw-w-screen tw-bg-white tw-shadow-xl'>
        <Menu.Item className='tw-w-full tw-border-0 tw-border-b tw-border-solid tw-border-black tw-border-opacity-30 tw-p-4 hover:tw-bg-gray-200'>
          <a href={links.linkDashboard} className='tw-cursor-pointer'>
            <span className='tw-text-sm tw-text-black'>Dashboard</span>
          </a>
        </Menu.Item>
        <Menu.Item className='tw-w-full tw-border-0 tw-border-b tw-border-solid tw-border-black tw-border-opacity-30 tw-p-4 hover:tw-bg-gray-200'>
          <a href={links.linkMeetings} className='tw-cursor-pointer'>
            <span className='tw-text-sm tw-text-black'>Meetings</span>
          </a>
        </Menu.Item>
        <Menu.Item className='tw-w-full tw-border-0 tw-border-b tw-border-solid tw-border-black tw-border-opacity-30 tw-p-4 hover:tw-bg-gray-200'>
          <a href={links.linkMinutes} className='tw-cursor-pointer'>
            <span className='tw-text-sm tw-text-black'>Minutes</span>
          </a>
        </Menu.Item>
        <Menu.Item className='tw-w-full tw-border-0 tw-border-b tw-border-solid tw-border-black tw-border-opacity-30 tw-p-4 hover:tw-bg-gray-200'>
          <a href={links.linkDirectories} className='tw-cursor-pointer'>
            <span className='tw-text-sm tw-text-black'>Documents</span>
          </a>
        </Menu.Item>
        <Menu.Item className='tw-w-full tw-border-0 tw-border-b tw-border-solid tw-border-black tw-border-opacity-30 tw-p-4 hover:tw-bg-gray-200'>
          <a href={links.linkTasks} className='tw-cursor-pointer'>
            <span className='tw-text-sm tw-text-black'>Action Items</span>
          </a>
        </Menu.Item>
        <Menu.Item className='tw-w-full tw-border-0 tw-border-b tw-border-solid tw-border-black tw-border-opacity-30 tw-p-4 hover:tw-bg-gray-200'>
          <a href={links.linkSmart} className='tw-cursor-pointer'>
            <span className='tw-text-sm tw-text-black'>Smartsign</span>
          </a>
        </Menu.Item>
        <Menu.Item className='tw-w-full tw-border-0 tw-border-b tw-border-solid tw-border-black tw-border-opacity-30 tw-p-4 hover:tw-bg-gray-200'>
          <a href={links.linkVotes} className='tw-cursor-pointer'>
            <span className='tw-text-sm tw-text-black'>Voting</span>
          </a>
        </Menu.Item>
        <Menu.Item className='tw-w-full tw-border-0 tw-border-b tw-border-solid tw-border-black tw-border-opacity-30 tw-p-4 hover:tw-bg-gray-200'>
          <a href={links.linkCompanies} className='tw-cursor-pointer'>
            <span className='tw-text-sm tw-text-black'>Companies</span>
          </a>
        </Menu.Item>
        {isConsoleEnabled && <Menu.Item className='tw-w-full tw-p-4 hover:tw-bg-gray-200'>
          <a href={links.linkConsole} className='tw-cursor-pointer'>
            <span className='tw-text-sm tw-text-black'>Admin Console</span>
          </a>
        </Menu.Item>}
      </Menu.Items>
    </Menu>
  );
};

const Navbar = ({
  currentUser,
  currentCompany,
  showNotificationBadge,
  showChatBadge,
  showChat,
  contactId,
  tzName,
  tzOffset,
  isConsoleEnabled,
  ...linksState
}) => {
  const dimensions = useWindowDimensions();
  const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint(dimensions.width));

  useEffect(() => {
    setBreakpoint(getCurrentBreakpoint(dimensions.width));
  }, [dimensions.width]);

  return (
    <div className='tw-fixed tw-z-[10] tw-mt-0 tw-flex tw-h-[67px] tw-w-full tw-bg-purple-500 tw-text-black tw-drop-shadow-xl xl:tw-bg-white'>
      <Drawer currentCompany={currentCompany} breakpoint={breakpoint} links={linksState} isConsoleEnabled={isConsoleEnabled} />
      <div className='tw-mt-[10px] xl:tw-w-9/12'>
        <ul className='tw tw-mb-0 tw-hidden tw-list-none tw-justify-around tw-p-0 tw-text-sm tw-font-medium xl:tw-flex 2xl:tw-ml-8 2xl:tw-justify-start 2xl:tw-gap-9'>
          <Link path={linksState.linkDashboard} isActive={linksState.isDashboard} text='Dashboard' />
          <Link path={linksState.linkMeetings} isActive={linksState.isMeetings} text='Meetings' />
          <Link path={linksState.linkMinutes} isActive={linksState.isMinutes} text='Minutes' />
          <Link path={linksState.linkDirectories} isActive={linksState.isDirectories} text='Documents' />
          <Link path={linksState.linkTasks} isActive={linksState.isTasks} text='Action Items' />
          <Link path={linksState.linkSmart} isActive={linksState.isSmart} text='Smartsign' />
          <Link path={linksState.linkVotes} isActive={linksState.isVotes} text='Voting' />
          {!currentCompany?.ancestry ? (
            <Link path={linksState.linkCompanies} isActive={linksState.isCompanies} text='Companies' />
          ) : null}
          {isConsoleEnabled && <Link path={linksState.linkConsole} text='Admin Console' />}
        </ul>
      </div>
      <div className='tw-mr-4.5 tw-mt-[10px] tw-flex tw-h-[46px] tw-w-full tw-items-center tw-justify-end tw-gap-5 tw-pl-[26px] !tw-text-white xl:tw-w-3/12'>
        <div className='tw-hidden tw-h-full tw-w-1 tw-border-0 tw-border-l tw-border-solid tw-border-gray-200 xl:tw-block'>
          &nbsp;
        </div>
        <div className='tw-hidden sm:tw-block'>
          <TimeClock contactId={contactId} tzName={tzName} tzOffset={tzOffset} />
        </div>
        <ChatsToggle showBadge={showChatBadge} showChat={showChat} />
        <NotificationsToggle showBadge={showNotificationBadge} />
        <ProfileMenu userData={currentUser} links={linksState} />
      </div>
    </div>
  );
};

export default Navbar;
