import { css } from '@emotion/core';
import { colors } from 'styles';

export const placeholder = css`
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  /* outline: 1px solid ${colors.lighterGrey}; */
  background: ${colors.white};
`;

export const panel = css`
  background: ${colors.white};
  position: relative;
  height: 485px;
  /* outline: 1px solid ${colors.lighterGrey}; */
  overflow-x: hidden;
  height: 100%;
`;

export const list = css`
  height: 100%;
`;

export const replyWrapper = css`
  padding: 20px 7px 20px 20px;
  /* outline: 1px solid ${colors.lighterGrey}; */
`;

export const textAreaWrapper = css`
  border: none;
  padding: 0;
`;

export const textAreaInput = css`
  font-size: 12px;
  padding-left: 0;
  line-height: 16px;
  height: 75px;
`;
