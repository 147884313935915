/** @jsx jsx */

import React, { memo, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { AgendaItem } from './../AgendaItem';
import { Placeholder } from 'components/shared/Placeholder';
import { AgendaReorderModal } from './../AgendaReorderModal';
import FileViewer from 'components/v2/shared/FileViewer';
import { useFileViewer } from '../../documents/hooks/useFileViewer';
import { getResourceGID } from 'helpers/graphql';

import { useFlashActions } from 'store/flashStoreContext';
import { useAgendasActions } from 'store/agendasStoreContext';
import { useDocumentsStore } from 'store/documentsStoreContext';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import { useEditable } from 'hooks/useEditable';
import { findAgendaRoots } from 'helpers/agendas';

import { ReactComponent as AgendasIcon } from 'images/icons/no-contacts.svg';
import * as styles from './AgendaList.styles';
import { utils } from 'styles';

const AgendaList = memo(
  ({
    meetingId,
    contacts,
    meetingDuration,
    agendas,
    visibilityMap,
    tasks,
    adminView,
    editView,
    meetingStatus,
    root,
  }) => {
    const { createAgenda, reorderAgendas, toggleAgendasVisibility } = useAgendasActions();
    const { setFlash } = useFlashActions();
    const { isEditable } = useMeetingStatus(meetingStatus);
    const { fileViewerContext, openFileViewer, closeFileViewer } = useFileViewer();

    const intl = useIntl();

    const adminAccess = adminView && isEditable;

    const { documents } = useDocumentsStore();

    const allowExpand = documents.length > 0 || adminAccess;

    const handleAdd = useCallback(() => {
      createAgenda(meetingId, { parent_id: null }).catch(setFlash);
    }, [meetingId]);

    const roots = findAgendaRoots(agendas);

    const {
      isEditing: isReorderModalOpen,
      startEditing: openReorderModal,
      stopEditing: closeReorderModal,
    } = useEditable();

    const handleReorderAgendas = (payload) => {
      reorderAgendas(meetingId, payload).then(closeReorderModal).catch(setFlash);
    };

    const renderList = () => {
      if (roots.length) {
        return roots.map((agenda) => (
          <AgendaItem
            key={`agenda-item-${agenda.id}`}
            contacts={contacts}
            meetingId={meetingId}
            agenda={agenda}
            meetingDuration={meetingDuration}
            onPreview={openFileViewer}
            agendas={agendas}
            documents={documents}
            tasks={tasks}
            visibilityMap={visibilityMap}
            adminView={adminView}
            editView={editView}
            meetingStatus={meetingStatus}
            root={root}
            onReorder={openReorderModal}
          />
        ));
      } else {
        return (
          <div className='p-t-45'>
            <Placeholder message={intl.formatMessage({ id: 'AgendaList.noAgendas' })} image={<AgendasIcon />} />
          </div>
        );
      }
    };

    const allHidden = Object.values(visibilityMap).every((item) => !item);
    const handleToggle = toggleAgendasVisibility.bind(this, allHidden);

    return (
      <div>
        <div css={styles.heading}>
          <div css={styles.header}>
            <FormattedMessage id='AgendaList.agenda' />
          </div>
          {allowExpand && (
            <a onClick={handleToggle} css={styles.toggleVisibilityLink}>
              {allHidden
                ? intl.formatMessage({ id: 'AgendaList.expandAll' })
                : intl.formatMessage({ id: 'AgendaList.collapseAll' })}
            </a>
          )}
        </div>

        <div css={utils.separator} />

        {renderList()}

        <AgendaReorderModal
          onSave={handleReorderAgendas}
          agendas={agendas}
          show={isReorderModalOpen}
          onClose={closeReorderModal}
        />

        <FileViewer
          fileName={fileViewerContext.file?.name}
          fileUrl={fileViewerContext.file?.url}
          isOpen={fileViewerContext.show}
          onClose={closeFileViewer}
        />

        {adminAccess && (
          <div className='text-center p-l-15 p-r-15'>
            <a onClick={handleAdd} className='btn btn-responsive btn-primary m-t-20'>
              <div className='d-flex align-items-center'>
                <i className='far fa-plus-circle fs-20' />
                <div className='m-l-5'>
                  <FormattedMessage id='AgendaList.addAgenda' />
                </div>
              </div>
            </a>
          </div>
        )}
      </div>
    );
  }
);

export { AgendaList };
