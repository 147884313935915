import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const modal = (withFooter) => css`
  .modal-dialog.shaparency {
    .modal-content {
      box-shadow: 0px 5px 10px rgba(153, 183, 200, 0.1), 0px 6.4px 14.4px rgba(183, 183, 183, 0.13);
      border-radius: 2px;
      padding: ${withFooter ? '22px 0 0 0' : '22px 0'};
      background: ${colors.white};
      width: calc(100% - 20px);
      margin: 0 auto;
      ${media.tablet} {
        width: 100%;
        min-height: 100vh;
        height: 100%;
        background: ${colors.lightestGrey};
        padding-top: 0;
      }
    }

    svg.modal-close {
      transform: scale(1.5);
      width: auto;
      margin: 0;
    }

    .modal-header {
      display: flex;
      align-items: center;
      padding: 0 42px;
      justify-content: space-between;
      ${media.tablet} {
        background: ${colors.white};
        height: 52px;
        padding: 0 15px;
        align-items: center;
      }
    }

    .modal-title {
      ${fonts.bold}
      color: ${colors.black};
      font-size: 20px;
      line-height: 28px;
      padding: 0;
    }

    .modal-footer {
      justify-content: center;
      padding: 24px 0;
    }

    .modal-close {
      cursor: pointer;
      opacity: 1;
      outline: none;
      width: auto;
      margin: 0;
      ${media.tablet} {
        position: static !important;
      }

      &:hover {
        opacity: 0.75;
      }
    }

    .modal-body {
      padding: 8px 42px 0 42px;
    }
  }
`;
