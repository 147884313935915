/** @jsx jsx */

import React, { memo, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from 'components/shared/Modal';
import { withIntl } from '../../utils/v2/intl';
import { FormFor, Label, Submit, CheckBox } from 'react-rails-form-helpers';

const styles = {
  modal: css`
    .modal-dialog.shaparency {
      width: 331px;

      .modal-body,
      .modal-header {
        padding: 0 24px;
      }

      .modal-close {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
  `,
};

const ClonePopup = memo(({ meetingId, showVotingCheckbox }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [parts, setParts] = useState({
    attendees: true,
    agenda: true,
    attachments: true,
    tasks: true,
    votes: true,
  });

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  const intl = useIntl();

  const handleAttendees = (event) => {
    setParts({ ...parts, attendees: event.target.checked });
  };

  const handleTasks = (event) => {
    setParts({ ...parts, tasks: event.target.checked });
  };

  const handleVotes = (event) => {
    setParts({ ...parts, votes: event.target.checked });
  };

  const handleAttachments = (event) => {
    setParts({ ...parts, attachments: event.target.checked });
  };

  const handleAgenda = (event) => {
    if (event.target.checked) {
      setParts({ ...parts, agenda: true });
    } else {
      setParts({ ...parts, agenda: false, attachments: false });
    }
  };

  const companyId = getCompanySlug();
  const formURL = `/${companyId}/meetings/${meetingId}/clone`;

  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      setIsVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest('.modal')) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <>
      <a className='cursor-pointer dropdown-item d-flex align-items-baseline' onClick={show}>
        <span>
          <FormattedMessage id='ClonePopup.copy' />
        </span>
      </a>

      <Modal title='Meeting copying' modalStyles={styles.modal} show={isVisible} onClose={hide} centered>
        <div className='fs-14 text-black font-weight-regular m-t-20'>
          <FormattedMessage id='ClonePopup.whatParts' />
        </div>

        <FormFor url={formURL} method='put' name='parts'>
          <div className='d-flex align-items-start justify-content-between m-t-15'>
            <div className='checkbox checkbox-success m-l-5'>
              <CheckBox onChange={handleAttendees} checked={parts.attendees} name='attendees' />

              <Label htmlFor='attendees' className='fs-12 text-black'>
                <FormattedMessage id='ClonePopup.attendees' />
              </Label>
            </div>

            <div>
              <div className='checkbox checkbox-success'>
                <CheckBox onChange={handleAgenda} checked={parts.agenda} name='agenda' />

                <Label htmlFor='agenda' className='fs-12 text-black'>
                  <FormattedMessage id='ClonePopup.agenda' />
                </Label>
              </div>

              <div className='checkbox checkbox-success'>
                <CheckBox
                  onChange={handleAttachments}
                  checked={parts.attachments}
                  disabled={!parts.agenda}
                  name='attachments'
                />

                <Label htmlFor='attachments' className='fs-12 text-black'>
                  <FormattedMessage id='ClonePopup.attachments' />
                </Label>
              </div>
            </div>

            <div>
              <div className='checkbox checkbox-success'>
                <CheckBox onChange={handleTasks} checked={parts.tasks} name='tasks' />

                <Label htmlFor='tasks' className='fs-12 text-black'>
                  <FormattedMessage id='ClonePopup.tasks' />
                </Label>
              </div>

              {showVotingCheckbox && (
                <div className='checkbox checkbox-success'>
                  <CheckBox onChange={handleVotes} checked={parts.votes} name='votes' />

                  <Label htmlFor='tasks' className='fs-12 text-black'>
                    <FormattedMessage id='ClonePopup.votes' />
                  </Label>
                </div>
              )}
            </div>
          </div>

          <div className='m-t-35'>
            <Submit name='commit' className='btn btn-primary' value={intl.formatMessage({ id: 'ClonePopup.copy' })} />
          </div>
        </FormFor>
      </Modal>
    </>
  );
});

export default withIntl(ClonePopup);
