/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { createPortal } from 'react-dom';

import { usePromiseTracker } from 'react-promise-tracker';
import BounceLoader from 'react-spinners/BounceLoader';

import { colors } from 'styles';
import * as styles from './Spinner.styles';

const Spinner = memo(({ show }) => {
  const { promiseInProgress } = usePromiseTracker({ delay: 500 });

  if (!promiseInProgress && !show) {
    return null;
  }

  const spinner = (
    <div css={styles.opaque} className='react__spinner'>
      <BounceLoader loading={true} color={colors.cyan} />
    </div>
  );

  return createPortal(spinner, document.querySelector('body'));
});

export { Spinner };
