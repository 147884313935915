/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { Page } from 'react-pdf/dist/esm/entry.webpack';
import { useInView } from 'react-intersection-observer';

import * as styles from './PageRenderer.styles';

const PageRenderer = memo(
  ({ index, viewer, pageWidth, viewerWidth, margin, offsetTop, initialPageHeight, onRender }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      root: viewer,
      rootMargin: '300% 0px 300% 0px',
    });

    const pdfPage = inView && (
      <Page
        width={pageWidth}
        onRenderSuccess={onRender}
        pageNumber={index + 1}
        renderTextLayer={false}
        renderInteractiveForms={false}
        renderAnnotationLayer={false}
      />
    );

    const translateY = (offsetTop / initialPageHeight) * 100;

    const pageStyles = styles.page({
      width: viewerWidth,
      height: initialPageHeight,
      translateY,
      margin,
    });

    return (
      <div css={pageStyles} ref={ref}>
        {pdfPage}
      </div>
    );
  }
);

export { PageRenderer };
