/** @jsx jsx */

import React, { memo, useCallback, useState } from 'react';
import { jsx } from '@emotion/core';
import { formatTime, formatDate, formatTimezoneOffset } from 'helpers/formatDateTime';
import { TextAreaInput } from 'components/shared/TextAreaInput';
import { Modal } from 'components/shared/Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDateFormatContext } from '../../../contexts/v2/DateFormatContext';

import { OrganizerPicker } from './../OrganizerPicker';
import Group from 'components/contacts/picker/Group';

import { useMeetingActions } from 'store/meetingStoreContext';

import * as styles from './InvitationModal.styles';

const InvitationModal = memo(({ show, meeting, onClose, onInvite }) => {
  const { userDateFormat } = useDateFormatContext();
  const { contact_meetings } = meeting;
  const { setEdits } = useMeetingActions();
  const contacts = contact_meetings.filter((item) => !item._destroy).map((item) => item.contact);

  const intl = useIntl();

  const isOrganizerContact = useCallback(
    (contact) => {
      return contact.id == meeting.calendar_contact_id && contact.calendar_connected;
    },
    [meeting]
  );

  const organizerContact = () => contacts.find(isOrganizerContact);

  const setOrganizerContact = (contact) => {
    setEdits('calendar_contact_id', contact?.id);
  };

  const [message, setMessage] = useState('');

  const handleMessageChange = (e) => setMessage(e.target.value || '');
  const handleInvite = () => onInvite(message, organizerContact?.id);

  const startTime = formatTime(meeting.meeting_start_time);
  const startDate = formatDate(meeting.meeting_date, userDateFormat);

  return (
    <Modal title='Schedule and invite' modalStyles={styles.modal} show={show} centered onClose={onClose}>
      <div className='text-black m-t-15'>
        <div className='fs-14'>
          <span>
            <FormattedMessage id='InvitationModal.aboutToSchedule' />
            <span className='font-weight-bold'>{meeting.title}</span>
          </span>
          <span>
            <FormattedMessage id='InvitationModal.for' />
            <span className='font-weight-bold'>{`${startTime} (${meeting.timezone} ${formatTimezoneOffset(
              meeting.timezone_offset
            )})`}</span>
          </span>
          <span>
            <FormattedMessage id='InvitationModal.on' />
            <span className='font-weight-bold'>{`${startDate}. `}</span>
          </span>
          <span>
            <FormattedMessage id='InvitationModal.willInvite' />
          </span>
        </div>

        <div className='font-weight-bold fs-16 m-t-15'>
          <FormattedMessage id='InvitationModal.attendees' />
        </div>

        <div className='m-t-15'>
          <Group contacts={contacts} maxContacts={6} allowAdd={false} withoutDetails />
        </div>

        <div className='font-weight-bold fs-16 m-t-15'>
          <FormattedMessage id='InvitationModal.inviteFrom' />
        </div>

        <div className='m-t-15'>
          <OrganizerPicker contacts={contacts} organizer={organizerContact()} onOrganizerChange={setOrganizerContact} />
        </div>

        <div className='m-t-35'>
          <TextAreaInput
            label={intl.formatMessage({ id: 'InvitationModal.emailLabel' })}
            name='message'
            onChange={handleMessageChange}
            value={message}
            placeholder={intl.formatMessage({ id: 'InvitationModal.emailPlaceholder' })}
          />
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-center m-t-15'>
        <a onClick={handleInvite} className='btn btn-primary'>
          <FormattedMessage id='InvitationModal.schedule' />
        </a>
        <a onClick={onClose} className='btn btn-text brand-text m-l-5'>
          <FormattedMessage id='InvitationModal.cancel' />
        </a>
      </div>
    </Modal>
  );
});

export { InvitationModal };
