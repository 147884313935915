import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const textField = {
  base: css`
    ${fonts.regular}
    border: 1px solid transparent;
    line-height: 20px;
    font-size: 14px;
    width: 100%;
    padding: 11px 19px;

    border-color: ${colors.border};
    color: ${colors.black};

    &:focus {
      border-color: ${colors.cyan};
    }

    &::placeholder {
      color: ${colors.headingGrey};
    }
  `,

  state: {
    disabled: css`
      background: ${colors.grey};
      color: ${colors.lightGrey};
      border-radius: 2px;
      border: 1px solid rgba(152, 164, 175, 0.4);
    `,

    invalid: css`
      border-color: ${colors.lightRed};
      color: ${colors.red};

      &:focus {
        border-color: ${colors.lightRed} !important;
      }

      &::placeholder {
        color: ${colors.red};
      }
    `,
  },

  accommodation: {
    icon: {
      right: css`
        padding: 11px 45px 11px 19px;
      `,

      left: css`
        padding: 11px 19px 11px 45px;
      `,
    },
  },
};
