import { gql, useMutation } from '@apollo/client';
import { CONTACT_GROUP_FRAGMENT } from './../fragments/ContactGroupFragment';

const UPDATE_CONTACT_GROUP_MUTATION = gql`
  mutation updateContactGroup($input: UpdateContactGroupInput!) {
    updateContactGroup(input: $input) {
      contactGroup {
        ...ContactGroupFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${CONTACT_GROUP_FRAGMENT}
`;

const useUpdateContactGroupMutation = (options) => useMutation(UPDATE_CONTACT_GROUP_MUTATION, options);

export { UPDATE_CONTACT_GROUP_MUTATION, useUpdateContactGroupMutation };
