/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import DatePicker from './../DatePicker';
import Text from './../Text';
import TextButton from './../TextButton';
import TimePicker from './../TimePicker';
import Button, { Variants } from './../Button';
import SelectInput from 'components/v2/shared/SelectInput';

import moment from 'moment';

import * as styles from './NoticeTimeSelectInput.styles';

const NoticeTimeSelectInput = ({
  showCustomPicker = false,
  noticeDateTime = moment().parseZone(),
  onNoticeDateTimeChange,
  ...props
}) => {
  const [isCustomTime, setIsCustomTime] = useState(showCustomPicker);

  useEffect(() => setIsCustomTime(showCustomPicker), [showCustomPicker]);

  const showCustomControls = () => {
    setIsCustomTime(true);
    onNoticeDateTimeChange(noticeDateTime);
  };

  const hideCustomControls = () => {
    setIsCustomTime(false);
    onNoticeDateTimeChange(null);
  };

  const renderFooter = () => (
    <Button isFullWidth variant={Variants.Teal} onClick={showCustomControls}>
      Add custom date and time
    </Button>
  );

  if (!isCustomTime) {
    return <SelectInput {...props} renderFooter={renderFooter} />;
  }

  return (
    <>
      <div css={styles.wrapper}>
        <Text isMedium text='Custom select when invite is to go out' />
        <TextButton text='Cancel' onClick={hideCustomControls} color='black' />
      </div>

      <DatePicker value={noticeDateTime} onChange={onNoticeDateTimeChange} label='Send date' className='mb-4' />
      <TimePicker value={noticeDateTime} onChange={onNoticeDateTimeChange} label='Send time' />
    </>
  );
};

NoticeTimeSelectInput.propTypes = {
  showCustomPicker: PropTypes.bool,
  noticeDateTime: PropTypes.instanceOf(moment),
  onNoticeDateTimeChange: PropTypes.func,
};

export default NoticeTimeSelectInput;
