/** @jsx jsx */

import React, { memo, useMemo, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { useIntl, FormattedMessage } from 'react-intl';

import { useApolloCacheHelpers } from './../hooks/useApolloCacheHelpers';
import { useSearchQuery } from './../data/queries/SearchQuery';

import ClipLoader from 'react-spinners/ClipLoader';
import Highlighter from 'react-highlight-words';

import Multiselect from '../../shared/Select/Multiselect';
import { SearchPlaceholder } from './../SearchPlaceholder';
import { Directory } from './../Directory';
import { Document } from './../Document';

import { colors } from 'styles';
import * as styles from './SearchResults.styles';

const SearchResults = memo(
  ({ show, query, onViewDocumentHistory, onDocumentClick, onDirectoryClick, onDirectoryDownload }) => {
    const { evictSearchDirectoriesCache, evictSearchDocumentsCache } = useApolloCacheHelpers();

    const intl = useIntl();

    const [context, setContext] = useState({
      documents: [],
      directories: [],
      term: query,
      loading: false,
    });

    const updateContext = (updates) => setContext((state) => ({ ...state, ...updates }));

    const filterOptions = [
      { value: 'pdf', label: intl.formatMessage({ id: 'SearchResults.pdf' }) },
      { value: 'image', label: intl.formatMessage({ id: 'SearchResults.image' }) },
      { value: 'document', label: intl.formatMessage({ id: 'SearchResults.document' }) },
    ];

    const [selectedFilters, setSelectedFilters] = useState(filterOptions);
    const selectedFilterValues = selectedFilters.map((item) => item.value);

    const { loading: dataLoading, data } = useSearchQuery({
      variables: { name: query },
      skip: !show || !query.length,
    });

    useEffect(() => setLoading(), [dataLoading]);
    useEffect(() => arrangeContext(), [data]);
    useEffect(() => resetContext(), [show]);

    const resetContext = () => {
      if (show) return;

      evictSearchDirectoriesCache();
      evictSearchDocumentsCache();

      updateContext({ documents: [], directories: [] });
    };

    const setLoading = () => {
      if (!dataLoading) return;

      updateContext({ loading: true });
    };

    const arrangeContext = () => {
      if (!data) return;

      updateContext({
        loading: false,
        term: query,
        ...data.currentCompany,
      });
    };

    const getHighlight = (name, css) => {
      const highlightTag = ({ children }) => <span css={css}>{children}</span>;

      return <Highlighter textToHighlight={name} highlightTag={highlightTag} searchWords={[term]} />;
    };

    const renderDirectory = (directory) => {
      const handleClick = onDirectoryClick.bind(this, directory.id);
      const breadcrumbs = directory.breadcrumbs.map((item) => item.name).join(' > ');
      const highlight = getHighlight(breadcrumbs, styles.highlight.directory);

      return (
        <Directory
          highlight={highlight}
          key={directory.id}
          onClick={handleClick}
          onDownload={onDirectoryDownload}
          capabilities={{}}
          {...directory}
        />
      );
    };

    const renderDocument = (document) => {
      const handleClick = onDocumentClick.bind(this, document);
      const handleViewHistoryClick = onViewDocumentHistory.bind(this, document);
      const breadcrumbs = document.breadcrumbs.map((item) => item.name).join(' > ');
      const highlight = getHighlight(
        `${breadcrumbs} > ${document.name}.${document.extension}`,
        styles.highlight.document
      );

      return (
        <Document
          key={document.id}
          highlight={highlight}
          capabilities={{}}
          onClick={handleClick}
          onViewHistoryClick={handleViewHistoryClick}
          {...document}
        />
      );
    };

    const { directories, documents, term, loading } = context;

    const filteredDocuments = useMemo(() => {
      return documents.filter(({ contentType }) => selectedFilterValues.includes(contentType));
    }, [documents, selectedFilters]);

    const renderedDirectories = directories.map(renderDirectory);
    const renderedDocuments = filteredDocuments.map(renderDocument);

    const hasResults = !loading && query.length > 0 && (directories.length > 0 || documents.length > 0);

    const resultsStyles = [styles.results.base, hasResults && styles.results.visible];

    return (
      show && (
        <div css={styles.container}>
          <SearchPlaceholder
            show={!loading && !query.length}
            heading={intl.formatMessage({ id: 'SearchResults.beginTyping' })}
            subHeading={intl.formatMessage({ id: 'SearchResults.filesFoldersContent' })}
          />

          <SearchPlaceholder
            show={!loading && query.length > 0 && !directories.length && !filteredDocuments.length}
            heading={intl.formatMessage({ id: 'SearchResults.noResults' })}
            subHeading={intl.formatMessage({ id: 'SearchResults.diffKeyword' })}
          />

          <div css={styles.loading}>
            <ClipLoader color={colors.cyan} loading={loading} />
          </div>

          <div css={resultsStyles}>
            <div css={styles.heading}>
              <FormattedMessage
                id='SearchResults.results'
                values={{ count: directories?.length + filteredDocuments?.length }}
              />
            </div>

            <div css={styles.padded}>
              <Multiselect
                options={filterOptions}
                styles={styles.multiselect}
                value={selectedFilters}
                onChange={setSelectedFilters}
                placeholder={intl.formatMessage({ id: 'SearchResults.byType' })}
              />
            </div>

            <div css={styles.children}>
              {renderedDirectories}
              {renderedDocuments}
            </div>
          </div>
        </div>
      )
    );
  }
);

export { SearchResults };
