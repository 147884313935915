import { css } from '@emotion/core';
import { fonts, colors, utils, media } from 'styles';

export const separator = css`
  ${utils.separator}
  margin: 10px 0;
  border-color: ${colors.borderCyan};
`;

export const sidebar = css`
  min-width: 245px;
  background: ${colors.lightestCyan};
  padding: 20px 0;
  ${media.mobile} {
    margin-bottom: 24px;
  }
`;
