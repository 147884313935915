const If = ({ query, children }) => {
  const result = typeof query === 'function' ? query() : query;

  if (!result) {
    return null;
  }

  return children;
};

export { If };
