import { gql, useQuery } from '@apollo/client';
import { CONTACT_GROUP_FRAGMENT } from 'data/v2/fragments/ContactGroupFragment';
import { CONTACT_MEETING_FRAGMENT } from 'data/v2/fragments/ContactMeetingFragment';

const MEETING_ATTENDEES_QUERY = gql`
  query ($id: ID!) {
    currentCompany {
      id

      meeting(id: $id) {
        id

        chairman {
          ...ContactMeetingFields
        }

        contactMeetings {
          ...ContactMeetingFields
        }
      }
    }
  }
  ${CONTACT_GROUP_FRAGMENT}
  ${CONTACT_MEETING_FRAGMENT}
`;

const useMeetingAttendeesQuery = (options) => useQuery(MEETING_ATTENDEES_QUERY, options);

export { MEETING_ATTENDEES_QUERY, useMeetingAttendeesQuery };
