import React from 'react';

import InviteContactModalWindow from '../InviteContactModalWindow';
import SelectInput from '../SelectInput';
import Button, { Variants } from '../Button';

const ContactMultiSelectInput = ({ ...props }) => (
  <SelectInput
    {...props}
    optionVariant='contact'
    renderFooter={({ showModal }) => (
      <Button onClick={showModal} isFullWidth variant={Variants.Teal}>
        Add new contact
      </Button>
    )}
    renderModal={({ isModalVisible, closeModal, onDataAdded }) => (
      <InviteContactModalWindow show={isModalVisible} onHide={closeModal} onDataAdded={onDataAdded} />
    )}
    isMulti
  />
);

ContactMultiSelectInput.propTypes = {};

export default ContactMultiSelectInput;
