import { css } from '@emotion/core';
import { colors, media } from 'styles';

export const wrapper = css`
  background: ${colors.white};
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: flex;
  margin-top: 2px;
  align-items: center;
  height: 55px;
  padding: 11px 28px 11px 32px;
  ${media.tablet} {
    padding: 11px 20px;
    margin-top: 0;
    height: 85px;
    box-shadow: none;
    border-radius: 0;
    border-top: 1px solid ${colors.borderGrey};
  }
`;

export const textArea = css`
  resize: none;
  color: ${colors.black};
  font-size: 12px;
  line-height: 14px;
  border: none;
  padding: 0 10px 0 0;
  flex: 1;

  &:placeholder {
    color: ${colors.mediumGrey};
  }
`;

export const icon = css`
  cursor: pointer;
  margin-left: 10px;
  fill: ${colors.purple};

  &:hover {
    opacity: 0.8;
  }
`;
