import { gql, useQuery } from '@apollo/client';
import { CONTACT_FRAGMENT } from 'data/v2/fragments/ContactFragment';
import { COMPANY_FRAGMENT } from '../../v1/fragments/CompanyFragment';

const CURRENT_USER_COMPANIES_QUERY = gql`
  query currentUserCompanies($first: Int, $after: String, $name: String) {
    currentUser {
      id
      email
      companies(first: $first, after: $after, name: $name) @connection(key: $context) {
        pageInfo {
          endCursor
          startCursor
          hasPreviousPage
          hasNextPage
        }

        edges {
          cursor

          node {
            ...CompanyFields

            contacts {
              ...ContactFields
            }

            requestedContacts {
              ...ContactFields
            }
          }
        }
      }
    }
  }
  ${CONTACT_FRAGMENT}
  ${COMPANY_FRAGMENT}
`;

const useCurrentUserCompaniesQuery = (options) => useQuery(CURRENT_USER_COMPANIES_QUERY, options);

export { CURRENT_USER_COMPANIES_QUERY, useCurrentUserCompaniesQuery };
