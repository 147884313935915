import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { NetworkStatus } from '@apollo/client';
import { ASC, DESC } from 'data/globals/SortDirections';
import { sliceById, toggleById } from 'utils/v2/shared';
import { getObjectDataByPath } from 'utils/v2/data';
import { useSelectDocumentModalQuery } from 'data/v2/queries/SelectDocumentModalQuery';
import { useCreateDocumentMutation } from 'data/v2/mutations/CreateDocumentMutation';

export const ITEMS_PER_PAGE = 20;
export const PARENT_TYPE = 'Directory';

const BREADCRUMB_FOLDERS_ITEM = {
  id: 'breadcrumb:folders',
  name: 'Folders',
};

export const SIDEBAR_ALL_ITEM = {
  id: 'sidebar:all',
  name: 'All',
};

const Modes = {
  BROWSE: 'browse',
  UPLOAD: 'upload',
  SEARCH: 'search',
  VIEW_SELECTED: 'viewSelected',
};

const initialState = {
  mode: Modes.BROWSE,
  searchTerm: '',
  breadcrumbItems: [BREADCRUMB_FOLDERS_ITEM, SIDEBAR_ALL_ITEM],
  sidebarItems: [],
  selectedItems: [],
  sortDirection: DESC,
};

const actionTypes = {
  SET_SIDEBAR_ITEMS: 'SelectDocumentModalContext.setSidebarItems',
  SET_MODE: 'SelectDocumentModalContext.setMode',
  TOGGLE_SORT_DIRECTION: 'SelectDocumentModalContext.toggleSortDirection',
  ENTER_SEARCH_MODE: 'SelectDocumentModalContext.enterSearchMode',
  SELECT_SIDEBAR_ITEM: 'SelectDocumentModalContext.selectSidebarItem',
  SELECT_CONTENT_AREA_DOCUMENT: 'SelectDocumentModalContext.selectContentAreaDocument',
  SELECT_CONTENT_AREA_DIRECTORY: 'SelectDocumentModalContext.selectContentAreaDirectory',
  SELECT_BREADCRUMB_ITEM: 'SelectDocumentModalContext.selectBreadcrumbItem',
  CLEAR_SELECTED_ITEMS: 'SelectDocumentModalContext.clearSelectedItems',
};

const SelectDocumentModalContext = createContext();

const browseModeSlice = {
  mode: Modes.BROWSE,
  searchTerm: '',
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_SIDEBAR_ITEMS:
      return { ...state, sidebarItems: payload.sidebarItems };
    case actionTypes.SET_MODE:
      return { ...state, mode: payload.mode, searchTerm: '' };
    case actionTypes.TOGGLE_SORT_DIRECTION:
      return { ...state, sortDirection: state.sortDirection === ASC ? DESC : ASC };
    case actionTypes.ENTER_SEARCH_MODE:
      return {
        ...state,
        mode: Modes.SEARCH,
        searchTerm: payload.searchTerm,
        breadcrumbItems: [BREADCRUMB_FOLDERS_ITEM, SIDEBAR_ALL_ITEM],
      };
    case actionTypes.SELECT_SIDEBAR_ITEM:
      return { ...state, ...browseModeSlice, breadcrumbItems: [BREADCRUMB_FOLDERS_ITEM, payload.sidebarItem] };
    case actionTypes.SELECT_CONTENT_AREA_DOCUMENT:
      const nextSelectedItems = toggleById(state.selectedItems, payload.contentAreaItem);
      return {
        ...state,
        selectedItems: nextSelectedItems,
        mode: nextSelectedItems.length > 0 ? state.mode : Modes.BROWSE,
      };
    case actionTypes.SELECT_CONTENT_AREA_DIRECTORY:
      return { ...state, ...browseModeSlice, breadcrumbItems: [...state.breadcrumbItems, payload.contentAreaItem] };
    case actionTypes.SELECT_BREADCRUMB_ITEM:
      return {
        ...state,
        ...browseModeSlice,
        breadcrumbItems: sliceById(state.breadcrumbItems, payload.breadcrumbItem),
      };
    case actionTypes.CLEAR_SELECTED_ITEMS:
      return { ...state, selectedItems: [], mode: Modes.BROWSE };
  }
};

export const SelectDocumentModalContextProvider = ({ children, ...args }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...args });
  const [mutate] = useCreateDocumentMutation();

  const currentDirectory = state.breadcrumbItems[state.breadcrumbItems.length - 1];

  const { data, loading, networkStatus, refetch, fetchMore } = useSelectDocumentModalQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      first: ITEMS_PER_PAGE,
      parentId: currentDirectory?.id,
      parentType: PARENT_TYPE,
      searchTerm: state.searchTerm,
      sortDirection: state.sortDirection,
    },
    onCompleted: (data) => {
      dispatch({
        type: actionTypes.SET_SIDEBAR_ITEMS,
        payload: {
          sidebarItems: [SIDEBAR_ALL_ITEM, ...data.currentCompany.rootDirectories],
        },
      });
    },
  });

  const searchableEntries = getObjectDataByPath(data, 'currentCompany.searchableEntries', { pageInfo: {}, edges: [] });
  const contentAreaItems = searchableEntries.edges.map((edge) => edge.node);

  const toggleSortDirection = () => dispatch({ type: actionTypes.TOGGLE_SORT_DIRECTION });
  const isSortDirectionAscending = state.sortDirection === ASC;

  const selectSidebarItem = (sidebarItem) =>
    dispatch({ type: actionTypes.SELECT_SIDEBAR_ITEM, payload: { sidebarItem } });
  const isSelectedSidebarItem = ({ id }) => state.breadcrumbItems[1]?.id === id;

  const selectContentAreaItem = (contentAreaItem) => {
    switch (contentAreaItem.__typename) {
      case 'Directory':
        return dispatch({ type: actionTypes.SELECT_CONTENT_AREA_DIRECTORY, payload: { contentAreaItem } });
      case 'Document':
        return dispatch({ type: actionTypes.SELECT_CONTENT_AREA_DOCUMENT, payload: { contentAreaItem } });
    }
  };
  const isSelectedContentAreaItem = ({ id }) => !!state.selectedItems.find((item) => item.id === id);

  const selectBreadcrumbItem = (breadcrumbItem) =>
    dispatch({ type: actionTypes.SELECT_BREADCRUMB_ITEM, payload: { breadcrumbItem } });

  const enterBrowseMode = () => dispatch({ type: actionTypes.SET_MODE, payload: { mode: Modes.BROWSE } });
  const isBrowsing = state.mode === Modes.BROWSE;

  const enterUploadMode = () => dispatch({ type: actionTypes.SET_MODE, payload: { mode: Modes.UPLOAD } });
  const isUploading = state.mode === Modes.UPLOAD;

  const enterSearchMode = (searchTerm) => dispatch({ type: actionTypes.ENTER_SEARCH_MODE, payload: { searchTerm } });
  const isSearching = state.mode === Modes.SEARCH;

  const enterViewSelectedMode = () => dispatch({ type: actionTypes.SET_MODE, payload: { mode: Modes.VIEW_SELECTED } });
  const isViewingSelected = state.mode === Modes.VIEW_SELECTED;

  const clearSelectedItems = () => dispatch({ type: actionTypes.CLEAR_SELECTED_ITEMS });

  const isFetchingMore = networkStatus == NetworkStatus.fetchMore;
  const fetchMoreContentAreaItems = () => {
    if (!searchableEntries.pageInfo.hasNextPage) return;
    if (isFetchingMore) return;

    fetchMore({
      variables: {
        first: ITEMS_PER_PAGE,
        after: searchableEntries.pageInfo.endCursor,
      },
    });
  };

  const uploadDocument = async (blob) => {
    const { data } = await mutate({
      variables: {
        input: {
          signedId: blob.signed_id,
          path: blob.filename,
          directoryId: currentDirectory?.id,
        },
      },
    });

    selectContentAreaItem(data.createDocument.document);
  };

  return (
    <SelectDocumentModalContext.Provider
      value={{
        isLoading: loading,
        isFetchingMore,
        isSidebarItemSelected: state.breadcrumbItems.length > 1,
        sortDirection: state.sortDirection,
        toggleSortDirection,
        isSortDirectionAscending,
        sidebarItems: state.sidebarItems,
        selectSidebarItem,
        isSelectedSidebarItem,
        breadcrumbItems: state.breadcrumbItems,
        selectBreadcrumbItem,
        contentAreaItems,
        selectContentAreaItem,
        isSelectedContentAreaItem,
        selectedItems: state.selectedItems,
        enterBrowseMode,
        isBrowsing,
        enterUploadMode,
        isUploading,
        enterSearchMode,
        isSearching,
        enterViewSelectedMode,
        isViewingSelected,
        clearSelectedItems,
        canUpload: !isUploading && currentDirectory.capabilities?.canCreate,
        fetchMoreContentAreaItems,
        refetchCurrentDirectory: refetch,
        uploadDocument,
      }}
    >
      {children}
    </SelectDocumentModalContext.Provider>
  );
};

SelectDocumentModalContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useSelectDocumentModalContext = () => useContext(SelectDocumentModalContext);
