import React, { useEffect, createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { useMeetingVotesQuery } from 'data/v2/queries/MeetingVotesQuery';
import { useCreateVoteMutation } from 'data/v2/mutations/CreateVoteMutation';
import { useMeetingContext } from './MeetingContext';
import { wrapMutation } from 'utils/v2/gql';

const initialState = {
  votes: [],
  skipFetch: true,
};

const actionTypes = {
  SET_VOTES: 'VotesContext.setVotes',
  ADD_VOTE: 'VotesContext.addVote',
  FETCH_VOTES: 'VotesContext.fetchVotes',
};

const VotesContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_VOTES:
      return { ...state, votes: payload.votes };
    case actionTypes.ADD_VOTE:
      return { ...state, votes: [...state.votes, payload.vote] };
    case actionTypes.FETCH_VOTES:
      return { ...state, skipFetch: false };
  }
};

export const VotesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    meeting: { meetingId },
  } = useMeetingContext();

  const { votes, skipFetch } = state;

  const { loading, data } = useMeetingVotesQuery({
    variables: { id: meetingId },
    skip: skipFetch,
  });

  useEffect(() => {
    if (data) {
      const {
        currentCompany: {
          meeting: { votes },
        },
      } = data;
      dispatch({ type: actionTypes.SET_VOTES, payload: { votes } });
    }
  }, [data]);

  const fetchMeetingVotes = () => {
    dispatch({ type: actionTypes.FETCH_VOTES });
  };

  const [createVote] = useCreateVoteMutation();

  const addVote = async (input) => {
    return wrapMutation(
      createVote,
      { variables: { input: { attributes: { ...input, meetingId } } } },
      'createVote'
    ).then((data) => {
      dispatch({ type: actionTypes.ADD_VOTE, payload: { vote: data.vote } });
    });
  };

  return (
    <VotesContext.Provider value={{ loading, votes, addVote, fetchMeetingVotes }}>{children}</VotesContext.Provider>
  );
};

VotesContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useVotesContext = () => useContext(VotesContext);
