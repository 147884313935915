/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import * as styles from './AttendanceTable.styles';

const AttendanceTable = memo(({ contactMeetings }) => {
  const existingContactMeetings = contactMeetings.filter((contact) => !contact._destroy);

  const contactMeetingsWithStatus = (status) => existingContactMeetings.filter((contact) => contact.status == status);

  const confirmedContactsCount = contactMeetingsWithStatus('yes').length;
  const declinedContactsCount = contactMeetingsWithStatus('no').length;
  const waitingContactsCount = existingContactMeetings.filter((contact) => !contact.status).length;

  return (
    <table css={styles.table}>
      <thead css={styles.tableHeader}>
        <tr>
          <th className='p-r-20'>
            <FormattedMessage id='AttendanceTable.confirmed' />
          </th>
          <th className='p-r-20'>
            <FormattedMessage id='AttendanceTable.waiting' />
          </th>
          <th>
            <FormattedMessage id='AttendanceTable.declined' />
          </th>
        </tr>
      </thead>

      <tbody>
        <tr className='fs-14 color-black'>
          <td>{confirmedContactsCount}</td>
          <td>{waitingContactsCount}</td>
          <td>{declinedContactsCount}</td>
        </tr>
      </tbody>
    </table>
  );
});

export { AttendanceTable };
