import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const InputError = ({ message, className }) => (
  <div
    className={clsx('tw-items-center tw-gap-x-3 tw-rounded tw-bg-red-100 tw-px-3 tw-py-2.5', className, {
      'tw-hidden': !message,
      'tw-flex': message,
    })}
  >
    <svg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        className='tw-fill-red-600'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17 14.6818L8.5 0L0 14.6818H17ZM9.2642 12.3658H7.71875V10.8203H9.2642V12.3658ZM7.71875 9.2706H9.2642V6.17969H7.71875V9.2706Z'
      />
    </svg>
    <div className='tw-text-sm tw-font-medium tw-text-black'>{message}</div>
  </div>
);

InputError.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

export default InputError;
