import styled from 'styled-components';
import { colors, fonts } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 1000;
`;

export const CountTitle = styled.span`
  color: ${colors.cyan};
`;

export const SignatoriesTitle = styled.div`
  ${fonts.styledBold}
  font-size: 20px;
  line-height: 24px;
  color: #2c2e3f;
`;

export const ConfirmMsg = styled.div`
  margin-top: 17px;
  ${fonts.styledRegular}
  font-size: 14px;
  line-height: 20px;
  color: #2c2e3f;
`;

export const LastConfirmMsg = styled.div`
  ${fonts.styledRegular}
  font-size: 12px;
  line-height: 16px;
  color: #787d96;
`;

export const Warning = styled.span`
  color: ${colors.warning};
`;

export const BodyWrapper = styled.div`
  @media (max-width: 767px) {
    height: calc(100% - 52px);
    padding: 15px;
  }
`;

export const Document = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.black};
  font-size: 16px;
  line-height: 16px;
  padding: 26px 10px 28px 10px;

  span {
    padding-left: 7px;
  }

  .smart-sign-icon {
    position: relative;
    top: 17px;
    right: 12px;
  }
`;

export const EmailMsgWrapper = styled.div`
  padding: 23px 0 17px 0;
  @media (max-width: 767px) {
    padding: 29px 0 15px 0;
  }
  .email-msg-label {
    ${fonts.styledBold}
    font-size: 12px;
    color: #2c2e3f;
    line-height: 30px;
    padding-bottom: 8px;
  }
`;

export const MsgTextarea = styled.textarea`
  padding: 8px 12px;
  font-size: 12px;
  width: 100%;
  resize: none;
  border: 1px solid;
  border-color: ${HexToRgba(colors.lightGrey, 40)};
  min-height: 132px;
  height: 100%;
  border-radius: 2px;

  &:placeholder {
    color: ${colors.mediumGrey};
  }
`;

export const Success = styled.div`
  ${fonts.styledBold}
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 20px;
  line-height: 28px;
  max-width: 170px;
  @media (max-width: 767px) {
    text-align: center;
    max-width: 266px;
  }
`;

export const SentSuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  @media (max-width: 767px) {
    flex-direction: column;
    height: calc(100% - 52px);
    padding: 38px 35px;
  }
`;

export const FinishBtnWrapper = styled.div`
  text-align: center;
  position: absolute;
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  button {
    width: 100%;
  }
`;

export const Overlay = styled.div`
  background: ${HexToRgba(colors.white, 70)};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;
