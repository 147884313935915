/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import Avatar from 'components/shared/Avatar';

import { ReactComponent as ViewableIcon } from 'images/icons/documents/viewable.svg';
import { ReactComponent as ShareIcon } from 'images/icons/documents/shareable-icon.svg';

import * as styles from './ShareModalContactListItem.styles';

const ShareModalContactListItem = memo(({ contact, onDelete, withIcon }) => {
  const deleteButton = onDelete && <i onClick={onDelete} css={styles.icon} className='far fa-fw fa-trash-alt' />;

  const canView = !onDelete && (
    <div className='d-flex align-items-center'>
      <ViewableIcon className='flex-shrink-0 m-r-5' />
      <div className='fs-14 c-secondary'>
        <FormattedMessage id='ShareModalContactListItem.canView' />
      </div>
    </div>
  );

  const shareIcon = withIcon && (
    <div css={styles.shareable}>
      <ShareIcon className='shareable__icon' />
    </div>
  );

  const overlay = !onDelete && <div css={styles.overlay} />;

  return (
    <div css={styles.container}>
      {overlay}

      <div className='d-flex align-items-center'>
        <Avatar contact={contact} />

        <div css={styles.name}>{contact.name}</div>

        {shareIcon}
      </div>

      <div className='ml-auto m-r-5'>
        {canView}
        {deleteButton}
      </div>
    </div>
  );
});

export { ShareModalContactListItem };
