import { useMarkNotificationAsReadMutation } from './../data/mutations/MarkNotificationAsReadMutation';

const useMarkNotificationAsRead = ({ onCompleted }) => {
  const update = (
    cache,
    {
      data: {
        markNotificationAsRead: { showBadge },
      },
    }
  ) => onCompleted(showBadge);

  const [mutate] = useMarkNotificationAsReadMutation({ update });

  const markNotificationAsRead = (notificationId) => {
    mutate({ variables: { input: { notificationId } } });
  };

  return { markNotificationAsRead };
};

export { useMarkNotificationAsRead };
