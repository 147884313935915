import { css } from '@emotion/core';
import { colors, fonts } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const wrapper = (isDisabled, isSelected, { width, height }, withoutStyles) => css`
  position: absolute;
  cursor: ${withoutStyles ? 'auto' : 'pointer'};
  width: ${width}%;
  height: ${height}%;
  border-width: ${isSelected ? '2px' : '1px'};
  border-style: solid;
  border-color: ${withoutStyles ? 'transparent' : isSelected ? colors.purple : colors.cyan};
  z-index: 2;
  top: 0;
  left: 0;
  pointer-events: ${isDisabled ? 'none' : 'auto'};
  background: ${withoutStyles ? 'transparent' : HexToRgba(colors.cyan, 10)};
  opacity: ${isDisabled ? 0.5 : 1};
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .c-secondary {
    @media (max-width: 767px) {
      display: none;
    }
  }

  @media all and (max-width: 767px) {
    width: 110px;
    height: 45px;
  }
`;

export const resignLabel = css`
  position: absolute;
  bottom: 5px;
`;

export const dateField = css`
  ${fonts.bold}
  line-height: 1;
  padding: 15px;
  border: 1px solid ${colors.cyan};
  background: ${HexToRgba(colors.cyan, 10)};
  border-radius: 2px;

  @media all and (max-width: 767px) {
    font-size: 14px;
    padding: 5px;
  }
`;

export const signature = css`
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 100%;
`;

export const dot = (isShown) => css`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  border: 2px solid;
  background: ${colors.white};
  border-color: ${colors.purple};
  display: ${isShown ? 'block' : 'none'};
`;

export const topRightDot = (isShown) => css`
  ${dot(isShown)}
  top: -5px;
  right: -5px;
`;

export const topLeftDot = (isShown) => css`
  ${dot(isShown)}
  top: -5px;
  left: -5px;
`;

export const bottomRightDot = (isShown) => css`
  ${dot(isShown)}
  bottom: -5px;
  right: -5px;
`;

export const bottomLeftDot = (isShown) => css`
  ${dot(isShown)}
  bottom: -5px;
  left: -5px;
`;

export const field = css`
  text-align: center;
  color: ${colors.cyan};
`;

export const placeholder = (forSignature) => css`
  ${fonts.bold}
  line-height: 1;
  padding-bottom: ${forSignature ? 5 : 10}px;
`;

export const content = (forSignature) => css`
  position: relative;
  padding: ${forSignature ? '11px 20px 14px 20px' : '11px 14px'};
  width: 100%;
  font-size: 12px;

  @media all and (max-width: 767px) {
    padding: 5px 10px;
  }
`;

export const remove = (isShown) => css`
  position: absolute;
  width: 20px;
  display: ${isShown ? 'flex' : 'none'};
  height: 20px;
  background: ${HexToRgba(colors.purple, 20)};
  right: 0;
  top: -25px;
  right: -25px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const signatory = (forSignature) => css`
  ${forSignature ? fonts.bold : fonts.regular}
  color: ${forSignature ? colors.cyan : colors.black};
  font-size: ${forSignature ? 16 : 12}px;
  line-height: 14px;

  @media all and (max-width: 767px) {
    font-size: 12px;
  }
`;

export const arrow = (y) => css`
  top: ${y}%;
  left: -85px;
  @media all and (max-width: 1023px) {
    left: -8px;
  }
  position: absolute;
  display: flex;
`;

export const rectangle = (isSignature) => css`
  ${fonts.semiBold}
  border-radius: 1px;
  line-height: 40px;
  letter-spacing: 0.08em;
  padding-left: 10px;
  text-align: center;
  color: ${colors.white};
  text-transform: uppercase;
  width: 85px;
  height: 40px;
  ${isSignature && 'z-index: 1;'}
  background: ${isSignature ? colors.cyan : HexToRgba(colors.cyan, 50)};
  @media all and (max-width: 1023px) {
    width: 45px;
    line-height: 30px;
    height: 30px;
    font-size: 12px;
  }
`;

export const triangleRight = (isCurrent) => css`
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  ${isCurrent && 'z-index: 1;'}
  border-left: 20px solid
    ${isCurrent ? colors.cyan : HexToRgba(colors.cyan, 50)};
  @media all and (max-width: 1023px) {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }
`;
