/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { ReactComponent as MoveIcon } from 'images/icons/move-icon.svg';
import { ReactComponent as DotsIcon } from 'images/icons/dots-icon.svg';
import { ReactComponent as CloseIcon } from 'images/icons/close-icon-black.svg';
import { ReactComponent as CircleCheckmarkIcon } from 'images/icons/circle-checkmark-inverted.svg';

import * as styles from './Icon.styles';

const iconMap = {
  move: <MoveIcon />,
  dots: <DotsIcon />,
  close: <CloseIcon />,
  circleCheckmark: <CircleCheckmarkIcon />,
};

const Icon = ({ icon, onClick, className, setRef }) => (
  <div ref={setRef} className={className} onClick={onClick} css={[styles.icon.base, styles.icon[icon]]}>
    {iconMap[icon]}
  </div>
);

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(iconMap)),
  setRef: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Icon;
