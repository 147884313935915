/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import TextButton from './../TextButton';
import Label from 'components/v2/shared/Label';

import * as styles from './ContentToggle.styles';

const ContentToggle = ({ className, children, labelText, toggleText, onHide, onShow, isVisible = false }) => {
  const [isContentVisible, setIsContentVisible] = useState(isVisible);

  useEffect(() => setIsContentVisible(isVisible), [isVisible]);

  const showContent = () => {
    setIsContentVisible(true);
    onShow && onShow();
  };

  const hideContent = () => {
    setIsContentVisible(false);
    onHide && onHide();
  };

  const renderHeader = (
    <div className='d-flex align-items-center justify-content-between'>
      <Label text={labelText} className='mb-0' />
      <TextButton text='Cancel' color='black' onClick={hideContent} />
    </div>
  );

  const renderChildren = () => {
    if (!isContentVisible) {
      return <TextButton color='cyan' text={toggleText} onClick={showContent} />;
    }

    return (
      <>
        {renderHeader}
        {children}
      </>
    );
  };

  return (
    <div className={className} css={styles.container}>
      {renderChildren()}
    </div>
  );
};

ContentToggle.propTypes = {
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  labelText: PropTypes.string,
  toggleText: PropTypes.string,
};

export default ContentToggle;
