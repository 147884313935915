/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { AnimatedDrawer } from 'components/shared/AnimatedDrawer';
import { HistoryDrawerItem } from './../HistoryDrawerItem';

import * as styles from './HistoryDrawer.styles';

const HistoryDrawer = memo(({ file, show, onClose, onCloseAnimationEnd }) => {
  const timeline = file && file.activityFeeds.map((feed) => <HistoryDrawerItem key={feed.id} {...feed} />);

  return (
    <AnimatedDrawer width={396} show={show} drawerId='shaparency-history-drawer' onClose={onClose} closeOnClickOutside>
      <div css={styles.container}>
        <div css={styles.header}>
          <FormattedMessage id='HistoryDrawer.fileHistory' />
        </div>

        <div css={styles.filename}>
          <FormattedMessage id='HistoryDrawer.fileName' values={{ name: file?.name }} />
        </div>

        <div css={styles.timeline}>{timeline}</div>
      </div>
    </AnimatedDrawer>
  );
});

export { HistoryDrawer };
