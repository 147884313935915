import { css } from '@emotion/core';

export const tableBody = css`
  display: block;
  height: 100%;
  width: 100%;
  padding-right: 10px;
  max-height: 180px;
  overflow: auto;
`;
export const tableCell = css`
  padding: 3px 10px 2px 0;
  font-size: 12px;

  &:nth-of-type(1) {
    width: 35%;
  }

  &:nth-of-type(2) {
    width: 40%;
  }

  &:nth-of-type(3) {
    width: 25%;
    @media (max-width: 767px) {
      text-align: right;
    }
  }
`;

export const tableRow = css`
  display: flex;
`;

export const tableHeader = css`
  padding: 0 0 2px 0;

  &:nth-of-type(1) {
    width: calc(35% - 3.5px);
  }

  &:nth-of-type(2) {
    width: calc(40% - 4px);
  }

  &:nth-of-type(3) {
    width: 25%;
    @media (max-width: 767px) {
      text-align: right;
    }
  }
`;

export const modalStyles = css`
  .modal-dialog.shaparency {
    .modal-content {
      padding-bottom: 32px;
    }
  }
`;
