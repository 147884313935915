const HexToRgba = (hex, opacity) => {
  const tempHex = hex.replace('#', '');

  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);
  const a = opacity / 100;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export { HexToRgba };
