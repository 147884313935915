import { useState } from 'react';
import { useUpdateVotersMutation } from './../data/mutations/UpdateVotersMutation';

const useUpdateVoters = ({ onCompleted }) => {
  const update = (cache, { data: { updateVoters } }) => onCompleted();

  const [mutate, { loading: isUpdatingVoters }] = useUpdateVotersMutation({ update });

  const updateVoters = (payload) => mutate({ variables: { input: payload } });

  return {
    updateVoters,
    isUpdatingVoters,
  };
};

export { useUpdateVoters };
