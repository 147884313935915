import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FiChevronDown } from 'react-icons/fi';

const OrderToggle = ({ isRotated, onClick }) => (
  <div className='tw-flex tw-cursor-pointer tw-items-center tw-gap-x-3' onClick={onClick}>
    <FiChevronDown
      className={clsx('tw-h-4 tw-w-4', {
        'tw-rotate-0': !isRotated,
        'tw-rotate-180': isRotated,
      })}
    />
    <div className='tw-text-xs tw-font-medium tw-text-black'>By Name</div>
  </div>
);

OrderToggle.propTypes = {
  isRotated: PropTypes.bool,
  onClick: PropTypes.func,
};

export default OrderToggle;
