import React from 'react';
import { FiSearch } from 'react-icons/fi';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import IconButton from '../IconButton';

const SearchBar = ({ className, onChange }) => {
  return (
    <div className='tw-relative tw-h-[38px]'>
      <input
        onChange={onChange}
        placeholder='Search'
        className={clsx(
          'tw-box-border tw-h-full tw-w-full tw-rounded-sm tw-border tw-border-solid tw-border-gray-700 tw-px-5 tw-font-sans tw-text-sm tw-outline-none placeholder:tw-text-gray-500 focus:tw-border-teal-500',
          className
        )}
      />
      <div className='tw-absolute tw-right-0 tw-top-0 tw-text-sm'>
        <IconButton className='tw-mt-1 tw-mr-1 tw-rounded-sm !tw-bg-teal-500 tw-p-2 tw-text-white hover:tw-bg-teal-600'>
          <FiSearch />
        </IconButton>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default SearchBar;
