/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';

import { ReactComponent as Icon } from 'images/icons/alarm-bell.svg';
import Dropdown from './../Dropdown';
import { useClickOutside } from 'hooks/useClickOutside';

import * as styles from './Bell.styles';

const Bell = ({ showBadge, title }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(false);
  const handleClick = () => setIsComponentVisible(!isComponentVisible);

  const [isBadgeVisible, setIsBadgeVisible] = useState(showBadge);
  const [iconStyles, setIconStyles] = useState(styles.icon.base);

  const handleAnimationEnd = () => setIconStyles(styles.icon.base);
  const toggleBadge = (show) => setIsBadgeVisible(show);
  const handleCloseBadge = () => setIsBadgeVisible(false);

  const handleSubscription = (show) => {
    toggleBadge(show);

    if (!show) return;

    setIconStyles([styles.icon.base, styles.icon.animated]);
  };

  const badge = isBadgeVisible && <div css={styles.badge({ withTitle: title })} />;

  return (
    <div ref={ref} css={styles.wrapper({ withTitle: title })} onClick={handleClick}>
      {title ? <span css={styles.title}>{title}</span> : null}
      <Icon css={iconStyles} className='bell__icon' onAnimationEnd={handleAnimationEnd} />

      {badge}

      <Dropdown
        show={isComponentVisible}
        close={handleCloseBadge}
        onMarkAsRead={toggleBadge}
        onSubscription={handleSubscription}
      />
    </div>
  );
};

export { Bell };