import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CloseIcon from '../../../../assets/images/icons/close-icon-black.svg';
import FileIcon from '../../../../assets/images/icons/files.svg';
import UploadIcon from '../../../../assets/images/icons/upload.svg';
import FileBrowser from '../../shared/FileBrowser/withApollo';
import { CloseWrapper, CloseIconWrapper, UploadWrapper } from './styles';
import api from './API';
import { withIntl } from '../../../utils/v2/intl';

const SignModal = ({ deviceType, context }) => {
  const intl = useIntl();

  const selectDocument = (file) => {
    const companySlug = getCompanySlug();
    if (context === 'smart_sign') {
      api.createSmartDocument(
        file,
        (smartDocument) => {
          window.location.assign(`/${companySlug}/smart_sign/${smartDocument.slug}/edit`);
        },
        (error) => {
          console.error(error);
        },
        (success) => {
          if (success) {
            document.getElementById('close-modal').click();
          }
        }
      );
    }
  };

  const returnFile = () => {
    return (
      <div className='d-flex flex-direction-row align-items-center'>
        <img src={FileIcon} alt='files-icon' />{' '}
        <span>
          <FormattedMessage id='SignModal.choose' />
        </span>
      </div>
    );
  };

  return (
    <div className='modal-content p-0'>
      <div className='modal-header'>
        <CloseWrapper data-dismiss='modal' id='close-modal'>
          <FormattedMessage id='SignModal.close' />
          <CloseIconWrapper>
            <img src={CloseIcon} alt='close-icon' />
          </CloseIconWrapper>
        </CloseWrapper>
      </div>
      <div className='modal-body'>
        <UploadWrapper deviceType={deviceType} fontSize={deviceType === 'mobile' ? 14 : 20}>
          <FileBrowser context={context} clickButtonComponent={returnFile} />
        </UploadWrapper>
        <UploadWrapper fontSize={deviceType === 'mobile' ? 14 : 20} deviceType={deviceType}>
          <label htmlFor='smart_document_document_attributes_original_file'>
            <img src={UploadIcon} alt='gallery-icon' />{' '}
            <span>
              <FormattedMessage id='SignModal.uploadFile' />
            </span>
            <input
              placeholder={intl.formatMessage({ id: 'SignModal.fileSpec' })}
              accept='.pdf,.doc,.docx,.ppt,.pptx'
              className='uploader form-control'
              type='file'
              name='smart_document[document_attributes][original_file]'
              id='smart_document_document_attributes_original_file'
              style={{ display: 'none' }}
              onChange={(e) => {
                selectDocument(e.target.files[0]);
              }}
            />
          </label>
        </UploadWrapper>
      </div>
    </div>
  );
};

export default withIntl(SignModal);
