/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import TimePicker from 'components/shared/TimePicker';

import { Label, Error } from 'components/shared/Input';

import * as styles from './TimeInput.styles';

const TimeInput = memo(({ name, label, errorMessage, hideErrors, onChange, isDisabled, value, pickerName }) => {
  return (
    <div css={styles.wrapper}>
      <Label text={label} />

      <TimePicker
        onChange={onChange}
        time={value}
        pickerName={pickerName}
        inputName={name}
        isDisabled={isDisabled}
        isInvalid={!!errorMessage}
      />

      {!hideErrors && <Error message={errorMessage} styles={styles.errorMessage} />}
    </div>
  );
});

export { TimeInput };
