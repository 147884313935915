import { gql, useMutation } from '@apollo/client';
import { COMPANY_FRAGMENT } from 'data/v2/fragments/CompanyFragment';

const UPDATE_COMPANY_MUTATION = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        ...CompanyFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${COMPANY_FRAGMENT}
`;

const useUpdateCompanyMutation = (options) => useMutation(UPDATE_COMPANY_MUTATION, options);

export { UPDATE_COMPANY_MUTATION, useUpdateCompanyMutation };
