import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from 'components/v2/shared/Box';
import { formatDateTime } from 'helpers/formatDateTime';
import { intl } from 'contexts/v2/TranslationContext';

const DATE_TIME_FORMAT = 'DD MMM YY, hh:mm A';

const AnalyticsItem = ({ contact, rsvpStatus, annotationCount, firstOpenedAt, lastOpenedAt, totalReadTime }) => (
  <Box>
    <div className='tw-flex tw-min-w-0 tw-grow tw-flex-col tw-gap-1 tw-font-medium'>
      <div className='tw-flex tw-items-center tw-justify-between tw-gap-2'>
        <div className='tw-truncate tw-break-all tw-text-sm tw-text-black'>{contact?.name}</div>
        <div className='tw-text-xs tw-text-gray-700'>{rsvpStatus}</div>
      </div>

      <div className='tw-flex tw-flex-col'>
        <div className='tw-text-xs tw-text-gray-700'>
          {intl.formatMessage({ id: 'AnalyticsItem.annotationCount' })}: {annotationCount}
        </div>

        <div className='tw-text-xs tw-text-gray-700'>
          {intl.formatMessage({ id: 'AnalyticsItem.firstOpenedAt' })}: {formatDateTime(firstOpenedAt, DATE_TIME_FORMAT)}
        </div>

        <div className='tw-text-xs tw-text-gray-700'>
          {intl.formatMessage({ id: 'AnalyticsItem.lastOpenedAt' })}: {formatDateTime(lastOpenedAt, DATE_TIME_FORMAT)}
        </div>

        <div className='tw-text-xs tw-text-gray-700'>
          {intl.formatMessage({ id: 'AnalyticsItem.totalReadTime' })}: {totalReadTime}
        </div>
      </div>
    </div>
  </Box>
);

AnalyticsItem.propTypes = {
  contact: PropTypes.object,
  rsvpStatus: PropTypes.string,
  annotationCount: PropTypes.number,
  firstOpenedAt: PropTypes.string,
  lastOpenedAt: PropTypes.string,
  totalReadTime: PropTypes.string,
};

export default AnalyticsItem;
