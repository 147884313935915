/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { ActionsDropdown } from 'components/shared/ActionsDropdown';
import { useReviewable } from './../hooks/useReviewable';

import * as styles from './ReviewerListItem.styles';

const SENT_STATUSES = ['draft', 'pending'];

const ReviewStatus = memo(({ show, status }) => {
  const intl = useIntl();

  const isSent = SENT_STATUSES.includes(status);
  const klassName = isSent ? 'text-orange' : 'c-secondary';
  const label = isSent
    ? intl.formatMessage({ id: 'ReviewerListItem.sent' })
    : intl.formatMessage({ id: 'ReviewerListItem.received' });

  return show && <div className={`${klassName} fs-14 text-nowrap`}>{label}</div>;
});

const CommentsCount = memo(({ show, count }) => {
  const intl = useIntl();

  const label = intl.formatMessage({ id: 'ReviewerListItem.commentCount' }, { count });

  return show && <div className='c-secondary fs-14 text-nowrap'>{label}</div>;
});

const ReviewerListItem = memo(({ review, comments, showComments, onClick }) => {
  const reviewer = review.reviewer.data.attributes;
  const handleClick = onClick.bind(this, reviewer.id);

  const intl = useIntl();

  const { removeReviewer, handleResendReviewRequest } = useReviewable();

  const dropdownOptions = [
    review.status !== 'completed' && intl.formatMessage({ id: 'ReviewerListItem.resendReview' }),
    reviewer.deletable && intl.formatMessage({ id: 'ReviewerListItem.delete' }),
  ].filter(Boolean);

  const handleActionsDropdownClick = (option) => {
    switch (option) {
      case intl.formatMessage({ id: 'ReviewerListItem.resendReview' }):
        return handleResendReviewRequest(reviewer);
      case intl.formatMessage({ id: 'ReviewerListItem.delete' }):
        return removeReviewer(reviewer);
    }
  };

  return (
    <div className='d-flex align-items-center justify-content-between'>
      <div css={styles.item} onClick={handleClick}>
        <div className='text-black fs-14 text-truncate m-w-0 m-r-10'>{reviewer.name}</div>

        <div className='d-flex align-items-center fs-14'>
          <ReviewStatus show={!showComments} status={review.status} />

          <CommentsCount show={showComments} count={comments.length} />
        </div>
      </div>

      <div className='w-20px'>
        {review.status !== 'completed' && (
          <ActionsDropdown options={dropdownOptions} onClick={handleActionsDropdownClick} />
        )}
      </div>
    </div>
  );
});

export { ReviewerListItem };
