import { css } from '@emotion/core';
import { panels, colors, fonts } from 'styles';

export const wrapper = css`
  ${panels.base}
  min-width: 0;
  padding: 15px 15px 15px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
`;

export const title = css`
  ${fonts.extraBold}
  color: ${colors.black};
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const flex = css`
  display: flex;
  align-items: center;
`;

export const close = css`
  cursor: pointer;
  font-size: 16px;
  flex-shrink: 0;
  font-weight: 300;
  color: ${colors.black};
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;

  &:hover {
    opacity: 0.8;
    background: ${colors.placeholderGrey};
  }
`;
