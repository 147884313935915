import { useState } from 'react';

import { parseDateTime } from 'helpers/parseDateTime';
import { roundUpHours } from 'helpers/roundUpHours';

const useVoteParams = ({ vote }) => {
  const initialStartTime = () => vote?.startedAt || roundUpHours(moment().parseZone());
  const initialEndTime = () => vote?.endedAt || roundUpHours(moment().parseZone()).add(15, 'minute');

  const initialVoteParams = {
    voteId: vote?.id || '',
    name: vote?.name || '',
    passcode: vote?.passcode || '',
    voterType: vote?.voterType || 'contacts',
    showResults: vote ? vote.showResults : true,
    anonymous: vote ? vote.anonymous : false,
    startTime: initialStartTime(),
    endTime: initialEndTime(),
    startDate: parseDateTime(initialStartTime()),
    endDate: parseDateTime(initialEndTime()),
    meetingId: vote?.meetingId,
    contactIds: vote?.contactIds || [],
    collaboratorEmails: vote?.collaboratorEmails || [],
  };

  const [voteParams, setVoteParams] = useState(initialVoteParams);

  const updateVoteParams = (name, value) => setVoteParams((voteParams) => ({ ...voteParams, [name]: value }));

  const setStartDate = (value) => updateVoteParams('startDate', value);
  const setEndDate = (value) => updateVoteParams('endDate', value);
  const setStartTime = (value) => updateVoteParams('startTime', value);
  const setEndTime = (value) => updateVoteParams('endTime', value);

  const setVoteDateTimes = (startTime, endTime, startDate, endDate) => {
    setVoteParams((voteParams) => ({
      ...voteParams,
      startTime: startTime,
      endTime: endTime,
      startDate: startDate,
      endDate: endDate,
    }));
  };

  const setVoteMeetingDateTimes = (meeting) => {
    if (!meeting) {
      const startTime = initialStartTime();
      const endTime = initialEndTime();

      setVoteDateTimes(startTime, endTime, parseDateTime(startTime), parseDateTime(endTime));
    } else {
      const meetingDate = moment(meeting.meetingStartTime).parseZone();

      setVoteDateTimes(meeting.meetingStartTime, meeting.meetingEndTime, meetingDate, meetingDate);
    }
  };

  const setVoteMeetingName = (meeting) => {
    if (!meeting) {
      updateVoteParams('name', '');
    } else {
      updateVoteParams('name', `Meeting Vote: ${meeting.title}`);
    }
  };

  const handleInputChange = (event) => {
    let inputName = event.target.name;
    let inputValue = event.target.value;

    if (inputName == 'anonymous') {
      inputValue = event.target.checked;
    }

    updateVoteParams(inputName, inputValue);
  };

  return {
    voteParams,
    setVoteParams,
    updateVoteParams,
    setStartDate,
    setEndDate,
    setStartTime,
    setEndTime,
    setVoteMeetingDateTimes,
    setVoteMeetingName,
    handleInputChange,
  };
};

export { useVoteParams };
