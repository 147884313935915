/** @jsx jsx */

import React, { memo, useMemo, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Spinner } from 'components/shared/Spinner';
import { Steps } from 'components/shared/Steps';
import { AlertPopup } from 'components/shared/AlertPopup';
import { Step as MeetingStep } from 'components/meetings/MultistepForm/Step';
import { Step } from './Step';
import { StatusBadge } from 'components/shared/StatusBadge';
import { MeetingDetailsForm } from 'components/meetings/MeetingDetailsForm';
import { AgendaTasksTabs } from 'components/meetings/AgendaTasksTabs';
import { OoEStep } from './../OoEStep';
import { ReviewStep } from './../ReviewStep';
import { ActionBar } from './../ActionBar';
import { OwnerSpace } from './../OwnerSpace';
import { AttendeesStep } from 'components/meetings/AttendeesStep';

import { useActionCable } from 'hooks/useActionCable';
import { useSteps } from 'hooks/useSteps';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import { useMeetingActions, useMeetingStore } from 'store/meetingStoreContext';
import { useMinutesActions, useMinutesStore } from 'store/minutesStoreContext';
import { useReviewsStore } from 'store/reviewsStoreContext';
import { useFlashActions, useFlashStore } from 'store/flashStoreContext';
import { useOoEStore, useOoEActions } from 'store/ooeStoreContext';

import { getMeetingTypeOptions } from 'helpers/meetingTypes';

const MultistepForm = memo(
  ({
    root,
    meetingId,
    meetingTypes,
    currentContact,
    isStandalone,
    timezoneOptions,
    contactTimezone,
    contactTimezoneOffset,
    adminView,
    availableLanguages,
    contactLanguage,
  }) => {
    const intl = useIntl();

    const meetingTypeOptions = useMemo(() => {
      return getMeetingTypeOptions(meetingTypes);
    }, []);

    const { flash } = useFlashStore();
    const { setFlash, discardFlash } = useFlashActions();
    const { meeting, loaded, edits } = useMeetingStore();
    const { minutes } = useMinutesStore();
    const { discardEdits } = useMeetingActions();
    const { isDraft, isUpcoming, isArchived, isPseudo } = useMeetingStatus(meeting.status);
    const { ooe } = useOoEStore();
    const { updateOoE } = useOoEActions();

    const stepMap = useMemo(() => {
      if (isStandalone) {
        return {
          1: intl.formatMessage({ id: 'MultistepForm.meetingDetails' }),
          2: intl.formatMessage({ id: 'MultistepForm.attendees' }),
          3: intl.formatMessage({ id: 'MultistepForm.orderOfEvents' }),
          4: intl.formatMessage({ id: 'MultistepForm.review' }),
        };
      } else {
        return {
          1: intl.formatMessage({ id: 'MultistepForm.meetingDetails' }),
          2: intl.formatMessage({ id: 'MultistepForm.attendees' }),
          3: intl.formatMessage({ id: 'MultistepForm.agendaTasks' }),
          4: intl.formatMessage({ id: 'MultistepForm.orderOfEvents' }),
          5: intl.formatMessage({ id: 'MultistepForm.review' }),
        };
      }
    }, []);

    const totalSteps = Object.keys(stepMap).length;

    const { steps, changeStep, advanceStep, jumpStep } = useSteps(stepMap, 1, !isPseudo || meeting.valid);
    const { setErrors, setMeeting, loadMeeting, createMeeting, arrangeContext, inviteAttendees, updateMeeting } =
      useMeetingActions();

    useEffect(() => {
      loadMeeting(meetingId).then(selectStep).catch(setFlash);
    }, []);

    useEffect(() => discardEdits(), [steps.current]);

    useActionCable('MeetingsChannel', { meeting_id: meetingId }, (response) => arrangeContext(meetingId, response));

    const companyId = getCompanySlug();

    const selectStep = (meeting) => {
      if (meeting.valid) {
        jumpStep(totalSteps - 1);
      }
    };

    const saveDraftLabel = isDraft
      ? intl.formatMessage({ id: 'MultistepForm.saveDraft' })
      : intl.formatMessage({ id: 'MultistepForm.save' });

    const handleSaveMeetingDraft = () => {
      updateMeeting(meeting.id, edits).then(handleSaveDraftClick).catch(setErrors);
    };

    const handleSaveAndAdvance = () => {
      updateMeeting(meeting.id, edits).then(advanceStep).catch(setErrors);
    };

    const stepsIndicator = (
      <Steps
        stepMap={stepMap}
        currentStep={steps.current}
        completedSteps={steps.completed}
        changeStep={changeStep}
        disabledSteps={steps.disabled}
      />
    );

    const { reviews } = useReviewsStore();
    const { requestMinutesReview, distributeMinutes, approveMinutes } = useMinutesActions();

    const hasReviewers = reviews && reviews.length > 0;

    const handleSaveDraftClick = () => {
      updateOoE(minutes.id, { description: ooe.description }).then(navigateToMinutes);
    };

    const handleDistributeClick = () => {
      updateOoE(minutes.id, { description: ooe.description }).then(() => {
        distributeMinutes(minutes.id).then(navigateToMinute).catch(setFlash);
      });
    };

    const handleApprovalClick = () => {
      updateOoE(minutes.id, { description: ooe.description }).then(() => {
        approveMinutes(minutes.id).then(navigateToMinute).catch(setFlash);
      });
    };

    const handleReviewClick = () => {
      updateOoE(minutes.id, { description: ooe.description }).then(() => {
        requestMinutesReview(minutes.id).catch(setFlash);
      });
    };

    const navigateToMinute = () => {
      window.location.assign(`/${companyId}/minutes/${minutes.slug}`);
    };

    const navigateToMinutes = () => {
      window.location.assign(`/${companyId}/minutes`);
    };

    const isInReview = minutes.status === 'in_review';

    const inviteMeetingAttendees = (params = {}) => inviteAttendees(edits.id, { ...edits, ...params });

    const bottomActionBar = (
      <ActionBar
        status={minutes.status}
        canDistribute={adminView}
        hasReviewers={hasReviewers}
        onSave={handleSaveDraftClick}
        onReview={handleReviewClick}
        onDistribute={handleDistributeClick}
        onApprove={handleApprovalClick}
      />
    );

    if (!loaded) {
      return <Spinner show />;
    }

    const ownerSpace = (
      <OwnerSpace
        currentContact={currentContact}
        isPseudoMeeting={isPseudo}
        onSaveDraft={handleSaveDraftClick}
        onDistribute={handleDistributeClick}
        onApprove={handleApprovalClick}
        contactTimezone={contactTimezone}
        contactTimezoneOffset={contactTimezoneOffset}
      />
    );

    const editForm = (
      <>
        {steps.current === 1 && (
          <MeetingStep
            saveDraftLabel={intl.formatMessage({ id: 'MultistepForm.save' })}
            onSaveDraft={handleSaveMeetingDraft}
            onSaveAndAdvance={isPseudo ? handleSaveAndAdvance : advanceStep}
            steps={stepsIndicator}
          >
            <MeetingDetailsForm
              isDisabled={!isPseudo}
              meetingTypeOptions={meetingTypeOptions}
              timezoneOptions={timezoneOptions}
              contactTimezone={contactTimezone}
              contactTimezoneOffset={contactTimezoneOffset}
              availableLanguages={availableLanguages}
              contactLanguage={contactLanguage}
            />
          </MeetingStep>
        )}

        <AttendeesStep
          show={steps.current === 2}
          steps={stepsIndicator}
          onSave={navigateToMinutes}
          onAdvance={advanceStep}
          onInvite={inviteMeetingAttendees}
          isDisabled={!isPseudo}
          saveDraftLabel={saveDraftLabel}
          isMinutesContext
        />

        {!isPseudo && steps.current === 3 && (
          <MeetingStep
            saveDraftLabel={intl.formatMessage({ id: 'MultistepForm.save' })}
            onSaveDraft={handleSaveDraftClick}
            onSaveAndAdvance={advanceStep}
            steps={stepsIndicator}
          >
            <AgendaTasksTabs
              meetingId={meeting.id}
              meetingDuration={meeting.duration}
              contacts={meeting.contacts}
              meetingStatus={meeting.status}
              root={root}
              adminView
            />
          </MeetingStep>
        )}

        {steps.current === totalSteps - 1 && (
          <Step actions={bottomActionBar} steps={stepsIndicator}>
            <OoEStep minutes={minutes} isPseudoMeeting={isPseudo} currentContact={currentContact} />
          </Step>
        )}

        {steps.current === totalSteps && (
          <Step actions={bottomActionBar} steps={stepsIndicator}>
            <ReviewStep
              contactTimezone={contactTimezone}
              contactTimezoneOffset={contactTimezoneOffset}
              minutes={minutes}
            />
          </Step>
        )}
      </>
    );

    const renderSpace = () => {
      if (isInReview && adminView) {
        return ownerSpace;
      } else {
        return editForm;
      }
    };

    return (
      <Container fluid='lg'>
        <Row>
          <Col>
            <Spinner />
            <StatusBadge status={minutes.status} text={intl.formatMessage({ id: 'MultistepForm.editMinutes' })} />

            <AlertPopup
              show={!!flash}
              popupType='failure'
              headerText={intl.formatMessage({ id: 'MultistepForm.failure' })}
              onClose={discardFlash}
            >
              <div className='text-black fs-14'>{flash}</div>
            </AlertPopup>

            <div>{renderSpace()}</div>
          </Col>
        </Row>

        <div id='document-preview-modal' className='modal preview-mode' />
      </Container>
    );
  }
);

export { MultistepForm };
