import { createStore } from './createStore';
import { deleteById, updateById } from 'utils';
import { trackPromise } from 'react-promise-tracker';
import { getAttributes } from 'helpers/getAttributes';

import axios from 'axios';

const initialState = {
  reviews: [],
};

const actionTypes = {
  ADD: 'reviews.add',
  DELETE: 'reviews.delete',
};

const companyId = getCompanySlug();

const api = {
  createReview: (minuteId, params) => {
    const apiURL = `/api/${companyId}/v1/minutes/${minuteId}/ooe/reviews.json`;
    const payload = { review: params };

    const promise = axios
      .post(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  deleteReview: (minuteId, reviewId) => {
    const apiURL = `/api/${companyId}/v1/minutes/${minuteId}/ooe/reviews/${reviewId}.json`;

    const promise = axios
      .delete(apiURL)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  resendReviewRequest: (minuteId, reviewId) => {
    const apiURL = `/api/${companyId}/v1/minutes/${minuteId}/review_requests/${reviewId}/resend.json`;

    const promise = axios.post(apiURL);

    return trackPromise(promise);
  },

  finalizeReview: (minuteId, reviewId) => {
    const apiURL = `/api/${companyId}/v1/minutes/${minuteId}/ooe/reviews/${reviewId}.json`;
    const payload = { review: { status: 'completed' } };

    const promise = axios
      .put(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },
};

const useReviewsActions = () => {
  const dispatch = useReviewsDispatch();

  const setReviews = (reviews) => {
    dispatch({ type: actionTypes.SET, payload: reviews });
  };

  const createReview = (minuteId, params) => {
    return api.createReview(minuteId, params).then((comment) => {
      dispatch({ type: actionTypes.ADD, payload: comment });
    });
  };

  const resendReviewRequest = (minuteId, reviewId) => {
    return api.resendReviewRequest(minuteId, reviewId);
  };

  const finalizeReview = (minuteId, reviewId) => {
    return api.finalizeReview(minuteId, reviewId);
  };

  const deleteReview = (minuteId, reviewId) => {
    return api.deleteReview(minuteId, reviewId).then((comment) => {
      dispatch({ type: actionTypes.DELETE, payload: comment });
    });
  };

  return {
    setReviews,
    createReview,
    deleteReview,
    finalizeReview,
    resendReviewRequest,
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET:
      return { reviews: payload };
    case actionTypes.ADD:
      return { reviews: [...state.reviews, payload] };
    case actionTypes.DELETE:
      return { reviews: deleteById(state.reviews, payload) };
  }
};

const [ReviewsProvider, useReviewsStore, useReviewsDispatch] = createStore(reducer, initialState);

export { ReviewsProvider, useReviewsStore, useReviewsActions };
