/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import BSModal from 'react-bootstrap/Modal';

import { ReactComponent as Close } from 'images/icons/close-icon-black.svg';

import * as styles from './Modal.styles';

const Modal = memo(
  ({ show, onClose, title, overlay, header, withFooter, modalStyles, footer, hasCloseIcon = true, ...props }) => {
    return (
      <BSModal
        onHide={onClose}
        dialogClassName='shaparency'
        css={[styles.modal(!!footer), modalStyles]}
        show={show}
        backdrop
        {...props}
      >
        {overlay}
        <BSModal.Header>
          {header && header}
          {title && <BSModal.Title>{title}</BSModal.Title>}
          {hasCloseIcon && <Close className='m-0 cursor-pointer w-auto modal-close' onClick={onClose} />}
        </BSModal.Header>

        <BSModal.Body>{props.children}</BSModal.Body>
        {footer && <BSModal.Footer>{footer}</BSModal.Footer>}
      </BSModal>
    );
  }
);

export { Modal };
