/** @jsx jsx */

import React, { memo, useCallback, useRef, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { Dropdown } from 'components/shared/Dropdown';
import { DropdownOption } from 'components/shared/DropdownOption';
import { ReactComponent as AddTaskIcon } from 'images/icons/add-task-icon.svg';
import { useClickOutside } from 'hooks/useClickOutside';

import { useRegExpFormatter } from 'hooks/useRegExpFormatter';

import { HighlightTag } from 'components/shared/HighlightTag';
import Highlighter from 'react-highlight-words';

import * as styles from './EditableTaskTitle.styles';

import { useTaskDrawerActions } from 'store/taskDrawerStoreContext';
import { useFlashActions } from 'store/flashStoreContext';
import { useTasksActions } from 'store/tasksStoreContext';

const EditableTaskTitle = memo(({ meetingId, agendaId, task, tasks, isEditable, isOpen, onHide }) => {
  const { openDrawer } = useTaskDrawerActions();
  const { setFlash } = useFlashActions();
  const { updateTask } = useTasksActions();

  const [query, setQuery] = useState(task.title || '');
  const [isActive, setIsActive] = useState(!!isOpen);

  const { formattedRegExp } = useRegExpFormatter(query);

  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(isActive);

  useEffect(() => {
    setIsActive(!!isOpen);
    setIsComponentVisible(!!isOpen);
  }, [isOpen]);

  useEffect(() => {
    setEditableCursor();
  }, [isActive]);

  useEffect(() => {
    setQuery(task.title || '');
  }, [task]);

  useEffect(() => {
    if (!isComponentVisible) {
      hide();
    }
  }, [isComponentVisible]);

  const hide = () => {
    setIsActive(false);
    setQuery(task.title || '');
    onHide && onHide();
  };

  const toggleActive = () => {
    setIsActive(!isActive);
    setIsComponentVisible(!isActive);
  };

  const editableRef = useRef();

  const queryHasChanged = task.title !== query;

  const setEditableCursor = () => {
    if (!isActive) return;

    editableRef.current.focus();

    editableRef.current.selectionStart = query.length;
    editableRef.current.selectionEnd = query.length;
  };

  const changeQuery = (event) => {
    setQuery(event.target.value || '');
  };

  const handleAddTask = useCallback(() => {
    openDrawer({ title: query, agenda_id: agendaId });
    toggleActive();
  }, [query]);

  const handleSelectTask = useCallback(
    (task) => {
      updateTask(meetingId, task.id, { ...task, agenda_id: agendaId })
        .then(hide)
        .catch(setFlash);
    },
    [task.id]
  );

  const handleDeleteTask = useCallback(() => {
    updateTask(meetingId, task.id, { ...task, agenda_id: null }).catch(setFlash);
  }, [task.id]);

  const regexp = new RegExp(formattedRegExp, 'gi');
  const pickerOptions = tasks
    .filter((option) => !option.agenda_id)
    .filter((option) => option.id !== task?.id)
    .filter((option) => option.title.match(regexp));

  const renderOption = (option) => {
    const key = `task-picker-${option.id}`;
    const handleClick = handleSelectTask.bind(this, option);

    return (
      <DropdownOption key={key} onClick={handleClick}>
        <div className='d-flex align-items-center flex-wrap'>
          <div className='w-100'>
            <Highlighter textToHighlight={option.title} highlightTag={HighlightTag} searchWords={[formattedRegExp]} />
          </div>
        </div>
      </DropdownOption>
    );
  };

  const addTaskOption = !task.id && (
    <div css={styles.addTaskOption} onClick={handleAddTask}>
      <AddTaskIcon />
      <span>
        <FormattedMessage id='EditableTaskTitle.addNewTask' />
      </span>
    </div>
  );

  const taskActions = (
    <>
      {isActive && <i css={styles.icon.pencil} className='fa fa-pencil' onClick={toggleActive} />}
      {task.id && <i css={styles.icon.trash} className='far fa-trash-alt' onClick={handleDeleteTask} />}
    </>
  );

  if (!isEditable) return task.title;

  if (!isActive) {
    return (
      task.title && (
        <div className='d-flex align-items-baseline' ref={ref}>
          <span>{task.title}</span>

          {taskActions}
        </div>
      )
    );
  }

  const hasOptions = pickerOptions.length > 0;

  const dropdownContent = task.id && !hasOptions ? <DropdownOption noResults /> : pickerOptions.map(renderOption);

  const wrapperStyles = styles.wrapper(hasOptions);

  return (
    <div className='dib outline-none' ref={ref} tabIndex='1'>
      <input ref={editableRef} css={styles.input} value={query} onChange={changeQuery} />

      {taskActions}

      <Dropdown isOpen={queryHasChanged} standalone>
        <div css={wrapperStyles}>
          <div css={styles.items}>{dropdownContent}</div>
        </div>

        {addTaskOption}
      </Dropdown>
    </div>
  );
});

export { EditableTaskTitle };
