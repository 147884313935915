/** @jsx jsx */

import React, { memo} from 'react';
import { jsx } from '@emotion/core';
import { useClickOutside } from 'hooks/useClickOutside';
import { ActionsDropdownItem } from './../ActionsDropdownItem';
import { ReactComponent as DotsIcon } from 'images/icons/documents/horizontal-dots-icon.svg';
import * as styles from './ActionsDropdown.styles';
import MobileSharedDropdown from '../shared/MobileSharedDropdown';

const ActionsDropdown = memo(({ options, onClick }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(false);

  const show = (e) => {
    e.stopPropagation();
    setIsComponentVisible(true);
  };

  const handleClick = (option) => {
    onClick(option);
    setIsComponentVisible(false);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setIsComponentVisible(false);
  };

  const dropdownContent = options.map((option, index) => (
    <ActionsDropdownItem
      key={`actions-dropdown-option-${index}`}
      option={option[1]}
      onClick={handleClick}
      disabled={!option[0]}
    />
  ));

  const dropdown = isComponentVisible && (
    <div css={styles.dropdown} ref={ref}>
      <MobileSharedDropdown onClick={handleClose} />
      {dropdownContent}
    </div>
  );

  return (
    options.length > 0 && (
      <div css={styles.container} onClick={show} className='actions-container'>
        <div css={styles.trigger}>
          <DotsIcon css={styles.icon} />
        </div>

        <div className='actions-dropdown'>{dropdown}</div>
      </div>
    )
  );
});

export { ActionsDropdown };
