import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';

import { client } from 'utils/apollo';
import { GQLDataContextProvider } from './GQLDataContext';

import { MeetingFormStepContextProvider } from './MeetingFormStepContext';
import { CurrentContactContextProvider } from './CurrentContactContext';
import { TimezonesContextProvider } from './TimezonesContext';
import { ContactsContextProvider } from './ContactsContext';
import { AccessRolesContextProvider } from './AccessRolesContext';
import { MeetingContextProvider } from './MeetingContext';
import { TasksContextProvider } from './TasksContext';
import { NoticeScheduleContextProvider } from './NoticeScheduleContext';
import { ContactGroupsContextProvider } from './ContactGroupsContext';
import { AttendeesContextProvider } from './AttendeesContext';
import { VotesContextProvider } from './VotesContext';
import { MeetingConfigContextProvider } from './MeetingConfigContext';
import { ZoomMeetingConfigContextProvider } from './ZoomMeetingConfigContext';
import { ZoomMeetingTopicsContextProvider } from './ZoomMeetingTopicsContext';

import ComposeProviders from './ComposeProviders';

import { MEETING_QUERY } from 'data/v2/queries/MeetingQuery';
import { MEETING_STEPS } from './constants';

const Providers = ComposeProviders([
  TimezonesContextProvider,
  ContactsContextProvider,
  ContactGroupsContextProvider,
  AccessRolesContextProvider,
  MeetingContextProvider,
  TasksContextProvider,
  NoticeScheduleContextProvider,
  AttendeesContextProvider,
  VotesContextProvider,
  MeetingConfigContextProvider,
  ZoomMeetingConfigContextProvider,
  ZoomMeetingTopicsContextProvider,
]);

export const EditMeetingContextProvider = ({ children, id }) => {
  return (
    <ApolloProvider client={client}>
      <CurrentContactContextProvider>
        <GQLDataContextProvider initialQuery={MEETING_QUERY} variables={{ id }}>
          <Providers>
            <MeetingFormStepContextProvider steps={MEETING_STEPS}>{children}</MeetingFormStepContextProvider>
          </Providers>
        </GQLDataContextProvider>
      </CurrentContactContextProvider>
    </ApolloProvider>
  );
};

EditMeetingContextProvider.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};

export const useEditMeetingContext = () => useContext(EditMeetingContext);
