import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import VisibilityWrapper from 'components/v2/shared/VisibilityWrapper';
import Button, { Variants } from 'components/v2/shared/Button';
import TextInput from 'components/v2/shared/TextInput';

const Topbar = ({ isSearching, canUpload, onSearch, onCancel, onUpload }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const performSearch = () => onSearch(searchTerm);
  const handleInputChange = (e) => setSearchTerm(e.target.value);

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') {
      performSearch();
    }
  };

  useEffect(() => {
    if (!isSearching) {
      setSearchTerm('');
    }
  }, [isSearching]);

  return (
    <div className='tw-flex tw-flex-col tw-gap-y-4'>
      <div className='tw-text-xl tw-font-bold tw-text-black lg:tw-hidden'>Documents</div>

      <div className='tw-flex tw-flex-col tw-gap-4 md:tw-flex-row md:tw-items-center'>
        <div className='md:tw-w-full'>
          <TextInput placeholder='Search' value={searchTerm} onChange={handleInputChange} onKeyDown={handleKeyDown} />
        </div>

        <div className='tw-flex tw-items-center tw-gap-2'>
          <Button
            className='tw-w-1/2 lg:tw-w-full'
            isDisabled={!searchTerm.length}
            variant={Variants.Teal}
            onClick={performSearch}
          >
            Search
          </Button>

          <VisibilityWrapper isVisible={isSearching} className='tw-w-1/2 lg:tw-w-full'>
            <Button isFullWidth variant={Variants.Lavander} onClick={onCancel}>
              Cancel
            </Button>
          </VisibilityWrapper>

          <VisibilityWrapper isVisible={!isSearching} className='tw-w-1/2 lg:tw-w-full'>
            <Button isFullWidth variant={Variants.Purple} onClick={onUpload} isDisabled={!canUpload}>
              Upload
            </Button>
          </VisibilityWrapper>
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  isSearching: PropTypes.bool,
  canUpload: PropTypes.bool,
  onSearch: PropTypes.func,
  onUpload: PropTypes.func,
  onCancel: PropTypes.func,
};

export default Topbar;
