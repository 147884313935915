import React, { useContext, useEffect, useState } from 'react';
import Button from 'components/v2/shared/Button';
import Label from 'components/v2/shared/Label';
import TextInput from 'components/v2/shared/TextInput';
import { wrapMutation } from 'utils/v2/gql';
import { useCloseCompanyMutation } from 'data/v2/mutations/CloseCompanyMutation';
import { ModalContext } from '../shared/Modal/Modal';

const DeleteCompany = ({ company }) => {
  const { onClose } = useContext(ModalContext);
  const [inputValue, setInputValue] = useState('');
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const [closeCompany] = useCloseCompanyMutation();

  useEffect(() => {
    setIsDeleteConfirmed(inputValue === 'DELETE');
  }, [inputValue]);

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleDelete = () => {
    wrapMutation(
      closeCompany,
      {
        variables: {
          input: {
            companyId: company.id,
          },
        },
      },
      'closeCompany'
    ).then(() => {
      window.location.href = `/${company.slug}/companies`;
      onClose();
    });
  };

  return (
    <>
      <div className='tw-py-16 tw-pl-16 tw-pr-14'>
        <div className='tw-font-sans tw-text-xl tw-font-bold tw-text-black'>
          Are you sure you want to delete this company?
        </div>
        <div className='tw-mt-4 tw-font-sans tw-text-sm tw-font-medium tw-text-black'>
          Once deleted all information of company will be removed permanently.
        </div>

        <div className='tw-mt-8'>
          <div className='tw-mb-[19px]'>
            <Label text='Write DELETE to confirm' />
            <TextInput name='slug' className='!tw-text-xs' size='large' value={inputValue} onChange={handleInput} />
          </div>
          <div className='tw-flex tw-flex-col sm:tw-grid sm:tw-grid-flow-col sm:tw-grid-cols-2 sm:tw-gap-5'>
            <div className='sm:tw-col-span-1'>
              <Button
                font='semibold'
                className='tw-bg-red-600 disabled:!tw-bg-gray-200 disabled:tw-text-gray-700'
                isFullWidth
                size='large'
                variant='red'
                onClick={handleDelete}
                isDisabled={!isDeleteConfirmed}
              >
                Yes, delete company
              </Button>
            </div>
            <div className='tw-mt-5 sm:tw-col-span-1 sm:tw-mt-0'>
              <Button
                font='semibold'
                isFullWidth
                onClick={onClose}
                size='large'
                variant='red'
                className='tw-bg-transparent !tw-text-red-600 tw-outline tw-outline-2 tw-outline-red-600 hover:tw-bg-transparent'
              >
                No, keep company
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteCompany;
