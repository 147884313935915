import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const modal = css`
  .modal-dialog.shaparency {
    .modal-content {
      border-radius: 2px;

      .modal-header {
        .modal-title {
          ${fonts.bold}
        }

        .modal-close {
          transform: scale(1);
        }
      }
    }
  }
`;

export const content = {
  base: css`
    border: 1px solid;
    margin-top: 10px;
    border-radius: 2px;
    border-color: ${colors.headingGrey};
    height: 367px;
  `,

  error: css`
    border-color: ${colors.red};
  `,
};

export const loading = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
`;

export const error = css`
  color: ${colors.red};
  height: 25px;
  font-size: 12px;
`;
