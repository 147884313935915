/** @jsx jsx */
import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import FileBrowser from '../../shared/FileBrowser';
import { ReactComponent as DocumentsIcon } from 'images/icons/agenda-documents-upload.svg';
import { useUploadQueueActions } from 'store/uploadQueueStoreContext';

import * as styles from './ResolutionDocumentsUpload.styles';

const ResolutionDocumentsUpload = memo(({ resolution, onBrowseUpload, root }) => {
  const minSize = 0;
  const maxSize = 20971520; // 20MB;

  const [showBrowse, setShowBrowse] = useState(false);

  const hideBrowse = () => setShowBrowse(false);

  const acceptedFileTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    '.ppt',
    '.pdf',
    '.pptx',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.xlsm',
  ];

  const { addFilesToQueue } = useUploadQueueActions();

  const handleBrowse = () => {
    $('#modal-window').modal('hide');
    setShowBrowse(true);
  };

  const handleDrop = (files) => {
    addFilesToQueue({ files: files, resolutionId: resolution.id });
  };

  const dropzoneComponent = ({ getRootProps, getInputProps, isDragActive }) => (
    <>
      <div css={styles.dropzone(isDragActive)} {...getRootProps()}>
        <input {...getInputProps()} />

        <div css={styles.icon}>
          <DocumentsIcon />
        </div>

        <div>
          <span className='d-none d-lg-block'>
            <FormattedMessage id='ResolutionDocumentsUpload.dragNDrop' />
          </span>
          <a css={styles.upload}>
            <FormattedMessage id='ResolutionDocumentsUpload.clickToUpload' />
          </a>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div>
        <FileBrowser
          visible={showBrowse}
          multiple
          context='vote'
          resolutionId={resolution.id}
          onResolutionDocumentAttached={onBrowseUpload}
          onClose={hideBrowse}
        />

        <Dropzone minSize={minSize} maxSize={maxSize} accept={acceptedFileTypes} onDrop={handleDrop}>
          {dropzoneComponent}
        </Dropzone>

        <div className='text-center m-t-10'>
          <FormattedMessage id='ResolutionDocumentsUpload.or' />
          <a onClick={handleBrowse} css={styles.upload} className='browse'>
            <FormattedMessage id='ResolutionDocumentsUpload.browseFiles' />
          </a>
        </div>
      </div>
    </>
  );
});

export { ResolutionDocumentsUpload };
