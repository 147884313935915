import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const container = css`
  position: absolute;
  top: 50%;
  transition: none;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const heading = css`
  ${fonts.semiBold}
  color: ${colors.black};
  font-size: 20px;
  margin-bottom: 9px;
`;

export const subHeading = css`
  ${fonts.regular}
  color: ${colors.mediumGrey};
  text-align: center;
  margin: 0 auto;
  max-width: 200px;
  font-size: 14px;
`;
