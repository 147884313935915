/** @jsx jsx */

import React from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';

import Button from 'components/v2/shared/Button';
import closeIcon from 'images/icons/close-icon-black.svg';

import * as styles from './SmartPackPdfModal.styles';

const SmartPackPdfModal = ({ show, onHide, onAnnotatedDlClick, handleDlClick, onDlClick, hideAnnotationsDownload }) => {
  const closeBtn = (
    <button className='close' aria-label='Close' onClick={onHide} css={styles.modal}>
      <img src={closeIcon} />
    </button>
  );

  return (
    <Modal show={show} onHide={onHide} onClose={onHide} centered css={styles.modal}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id='SmartPackPdfModal.download' />
        </Modal.Title>
        {closeBtn}
      </Modal.Header>
      <Modal.Body>
        <Button onClick={onDlClick} fullWidth className='mb-3'>
          <FormattedMessage id='SmartPackPdfModal.smartPack' />
        </Button>
        {hideAnnotationsDownload && (
          <Button onClick={onAnnotatedDlClick} fullWidth>
            <FormattedMessage id='SmartPackPdfModal.smartPackWithAnnots' />
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};

SmartPackPdfModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onAnnotatedDlClick: PropTypes.func,
  onDlClick: PropTypes.func,
  smartPackUrl: PropTypes.string,
};

export default SmartPackPdfModal;
