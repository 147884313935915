import React, { useState } from 'react';
import clsx from 'clsx';
import CheckCircleIcon from 'images/icons/circled-green-check.svg';
import Heading from 'components/v2/shared/Heading/Heading';

const IntegrationsBox = ({ connectUrl, disconnectUrl, name, active, logoUrl, emailIdentifier }) => {
  return (
    <>
      <div className='tw-mt-0.5 tw-flex tw-max-w-full tw-flex-wrap'>
        <div className='tw-w-full'>
          <div
            onClick={() => window.location.assign(connectUrl)}
            className={clsx(
              'tw-text-cyan-500 tw-relative tw-flex tw-h-[125px] tw-w-full tw-items-center tw-justify-center tw-rounded-xl tw-border tw-border-solid hover:tw-cursor-pointer hover:tw-border-teal-500',
              {
                'tw-bg-teal-200 md:tw-border-teal-500': active,
                'tw-border-transparent tw-bg-gray-200 sm:tw-border-gray-500 sm:tw-bg-transparent': !active,
              }
            )}
          >
            {active && <img src={CheckCircleIcon} className='top-10 right-10 tw-absolute tw-h-[18px]' />}
            <div className='tw-mt-4 tw-flex tw-items-center tw-justify-center tw-text-center'>
              <div>
                <img src={logoUrl} alt='Loading' className={clsx({ 'tw-h-9': !name, 'tw-h-11': !!name })} />
                {name && name !== 'Zoom Integration' && (
                  <p className={clsx('!tw-m-0 tw-pt-1 tw-font-sans', {})}>{name}</p>
                )}
                <p
                  className={clsx(
                    '!tw-mb-0 tw-h-5 tw-font-sans tw-font-sans tw-text-xs tw-font-medium tw-text-teal-500',
                    { 'tw-mt-1': !name }
                  )}
                >
                  {active && emailIdentifier}
                </p>
              </div>
            </div>
          </div>
          <div className='tw-mt-1.5 tw-flex tw-h-5 tw-justify-center'>
            <a href={disconnectUrl} className='tw-font-sans tw-text-teal-500' data-method='delete'>
              {active && 'Disconnect'}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const Integrations = ({ currentUser, zoomIntegration, calendarIntegrations }) => {
  return (
    <>
      <div className='tw-hidden sm:tw-block'>
        <Heading text='Integrations' className='!tw-mb-8' />
      </div>
      <h1 className='tw-m-0 tw-pb-5 tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>Zoom integration</h1>
      <p className='tw-font-sans tw-font-medium tw-text-black'>
        Connect your Zoom account to create video meetings from within Shaparency
      </p>
      <div className='tw-grid tw-w-full tw-gap-5 sm:tw-grid-cols-2'>
        <IntegrationsBox {...zoomIntegration} />
      </div>
      <div className='tw-py-8'>
        <h1 className='tw-py-5 tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>Calendar integration</h1>
        <p className='tw-mb-5 tw-font-sans tw-font-medium tw-text-black'>
          Connect your calendar to send Shaparency meeting invites directly from your calendar
        </p>
        <div className='tw-grid tw-w-full tw-gap-5 sm:tw-grid-cols-2'>
          {calendarIntegrations.map((calendarIntegration) => (
            <IntegrationsBox key={calendarIntegration.name} {...calendarIntegration} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Integrations;
