import { useIncrementDirectoryNameMutation } from './../data/mutations/IncrementDirectoryNameMutation';
import { useApolloCacheHelpers } from './../hooks/useApolloCacheHelpers';

const useIncrementDirectoryName = ({ parent, onCompleted, onError }) => {
  const { addDirectoryToCache, removeDirectoryFromCache } = useApolloCacheHelpers();

  const update = (cache, { data: { incrementDirectoryName } }) => {
    const { directory, errors } = incrementDirectoryName;

    if (errors.length) {
      return onError(errors[0].fullMessage);
    }

    removeDirectoryFromCache(parent, directory);
    addDirectoryToCache(directory.parent, directory);
    onCompleted();
  };

  const [mutate, { loading: isIncrementing }] = useIncrementDirectoryNameMutation({ update });

  const incrementDirectoryName = (directoryId, destinationId) => {
    mutate({ variables: { input: { directoryId, destinationId } } });
  };

  return { incrementDirectoryName, isIncrementing };
};

export { useIncrementDirectoryName };
