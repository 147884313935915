/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchLazyQuery } from 'components/documents/data/queries/SearchQuery.js';

import * as styles from './FileBrowser.styles';
import api from './API';

export const FileBrowserSearch = ({
  searchTerm,
  setSearchTerm,
  setIsSearching,
  setSearchResults,
  setSearchMode,
  currentRoot,
}) => {
  const [searchDocuments, { data }] = useSearchLazyQuery();

  useEffect(() => {
    if (data) {
      setIsSearching(false);
      setSearchResults(data.currentCompany);
      setSearchMode(true);
    }
  }, [data]);

  const handleSearch = (e) => {
    e.preventDefault();
    setIsSearching(true);
    searchDocuments({ variables: { name: searchTerm } });
  };

  const intl = useIntl();

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value || '');
  };

  return (
    <div css={styles.padded} className='d-flex align-items-center m-b-20'>
      <div className='input-group'>
        <div className='input-group-prepend'>
          <div className='input-group-text rounded-0'>
            <i className='far fa-search text-color-heading fs-12' />
          </div>
        </div>

        <form className='d-flex flex-grow-1' onSubmit={handleSearch}>
          <input
            type='text'
            className='form-control rounded-0'
            value={searchTerm}
            css={styles.searchInput}
            placeholder={intl.formatMessage({ id: 'FileBrowserSearch.fileName' })}
            onChange={handleSearchTermChange}
          />
        </form>
      </div>

      <button css={styles.searchButton} onClick={handleSearch} className='btn btn-secondary m-l-15'>
        <FormattedMessage id='FileBrowserSearch.search' />
      </button>
    </div>
  );
};
