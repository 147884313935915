import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const modal = (withinFolder) => css`
  .modal-dialog.shaparency {
    width: ${withinFolder ? 614 : 800}px;

    .modal-content {
      padding: 12px 0;
    }

    .modal-header {
      ${fonts.bold}
      padding: 0 32px;
      ${media.tablet} {
        padding: 0 15px;
      }

      .modal-title {
        width: 100%;
      }
    }

    .modal-body {
      padding: 25px 0 0 0;
    }
  }
`;

export const placeholderImage = css`
  position: absolute;
  top: 15px;
  left: 215px;
  left: 50%;
`;

export const placeholderImageFiles = css`
  ${placeholderImage}
  left: 235px;
  left: 50%;
  transform: translateX(-10%);
`;

export const placeholderCircle = css`
  width: 55px;
  margin: 0 auto;
  height: 55px;
  background: ${colors.placeholderGrey};
  border-radius: 50%;
`;

export const searchButton = css`
  height: 44px;
  line-height: 44px;
  padding-left: 25px;
  padding-right: 25px;
`;

export const backIcon = css`
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const searchInput = css`
  height: 44px;
  border: 1px solid ${colors.inputBorder};
  color: ${colors.lightGrey};

  &::placeholder {
    color: ${colors.lightGrey};
  }
`;

export const overlay = css`
  background: ${HexToRgba(colors.white, 70)};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const browseSection = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  span.browse,
  img:hover {
    opacity: 0.8;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const documentExtension = css`
  ${fonts.bold}
  position: absolute;
  top: 50%;
  left: 50%;
  color: ${colors.white};
  font-size: 4px;
  text-transform: uppercase;
  transform: translate(-45%, -40%);
`;

export const heading = css`
  ${fonts.semiBold}
  font-size: 14px;
  line-height: 24px;
  color: ${colors.black};
`;

export const padded = css`
  padding: 0 32px;
  ${media.tablet} {
    padding: 0 15px;
  }
`;

export const error = css`
  ${padded}
  font-size: 14px;
  margin-bottom: 20px;
  color: ${colors.warning};
`;

export const separator = css`
  border-top: 1px solid #f0f1f1;
  width: 100%;
  height: 1px;
`;

export const header = css`
  ${fonts.semiBold}
  color: ${colors.lightGrey};
  font-size: 16px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
`;

export const folders = css`
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.tablet} {
    flex-direction: column;
  }
`;

export const folderIcon = (isPersonal) => css`
  path {
    stroke: ${isPersonal ? colors.purple : colors.cyan};
  }
`;

export const folder = css`
  border: 1px solid ${colors.lighterGrey};
  border-radius: 15px;
  width: 170px;
  height: 170px;
  padding: 29px 17px 25px 19px;

  &:hover {
    padding: 27px 15px 23px 17px;
    border-color: ${colors.lightPurple};
    border-width: 3px;
    cursor: pointer;
  }

  ${media.tablet} {
    margin-bottom: 10px;
    width: 100%;
    padding: 15px;
    height: auto;
}
  }
`;

export const name = css`
  ${fonts.bold}
  font-size: 16px;
  color: ${colors.black};
  line-height: 28px;
  margin-top: 15px;
`;

export const info = css`
  ${fonts.regular}
  font-size: 12px;
  line-height: 18px;
  color: ${colors.lightGrey};
`;
