import { css } from '@emotion/core';

export const headerWrapper = css`
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const expand = css`
  margin-bottom: -3px;
  cursor: pointer;
`;
