/** @jsx jsx */

import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { Row, Col } from 'react-bootstrap';

import Spinner from 'components/v2/shared/Spinner';
import Button, { Variants } from 'components/v2/shared/Button';
import Box from 'components/v2/shared/Box';
import RadioButton from 'components/v2/shared/RadioButton';
import Text from 'components/v2/shared/Text';
import { useContactGroupsContext } from 'contexts/v2/ContactGroupsContext';
import { useContactsContext } from 'contexts/v2/ContactsContext';
import { useAttendeesContext } from 'contexts/v2/AttendeesContext';
import ContactMultiSelectInput from 'components/v2/shared/ContactMultiSelectInput';
import ContactGroupSelectInput from '../../shared/ContactGroupSelectInput/ContactGroupSelectInput';
import Divider from 'components/v2/shared/Divider';
import ContactCard from 'components/v2/shared/ContactCard';
import useNavigation from 'hooks/v2/useNavigation';
import ContactGroup from '../../shared/ContactGroup/ContactGroup';
import TextButton from '../../shared/TextButton/TextButton';
import AssignChairModalWindow from '../../shared/AssignChairModalWindow/AssignChairModalWindow';
import { useMeetingFormStepContext } from 'contexts/v2/MeetingFormStepContext';
import { INDIVIDUALS, GROUP } from './constants';

import * as styles from './AttendeesStep.styles';

const AttendeesStep = () => {
  const [selectType, setSelectType] = useState(INDIVIDUALS);
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const { fetchContactGroups, contactGroups, getGroupsContacts, loading } = useContactGroupsContext();
  const {
    edits: { individualAttendees, groups },
    setIndividualAttendees,
    setGroups,
    removeIndividualAttendee,
    updateMeetingAttendees,
    fetchMeetingAttendees,
    removeGroup,
    discardEdits,
    hasNoMeetingAttendees,
    hasNoEditsAttendees,
  } = useAttendeesContext();
  const { contacts } = useContactsContext();

  const navigate = useNavigation();
  const { nextStep } = useMeetingFormStepContext();

  useEffect(() => {
    discardEdits();
    fetchContactGroups();
    fetchMeetingAttendees();
  }, []);

  const handleIndividualChange = (data) => setIndividualAttendees(data || []);

  const handleGroupChange = (data) => setGroups(data || []);

  const handleRadioChange = (e) => setSelectType(e.target.value);

  const handleRemoveAttendeeClick = (id) => () => removeIndividualAttendee(id);

  const handleSkipStep = () => nextStep();

  const handleMeetingSave = (redirectToIndex) => async () => {
    try {
      await updateMeetingAttendees();
      if (redirectToIndex) {
        navigate('meetingsIndex');
      } else {
        nextStep();
      }
    } catch {}
  };

  const handleRemoveGroup = (groupId) => () => removeGroup(groupId);

  const handleAssignClick = () => setIsAssignModalVisible(true);

  const onModalHide = () => setIsAssignModalVisible(false);

  const noAttendees = !groups.length && !individualAttendees.length;

  const renderSelectInput = () =>
    selectType === INDIVIDUALS ? (
      <ContactMultiSelectInput
        onChange={handleIndividualChange}
        options={contacts}
        placeholder='Enter contact name'
        value={individualAttendees}
      />
    ) : (
      <ContactGroupSelectInput
        value={groups}
        onChange={handleGroupChange}
        options={contactGroups}
        placeholder='Select group'
      />
    );

  const renderIndividuals = () => {
    if (individualAttendees.length) {
      return (
        <>
          <Text text='Individuals' isMedium className='ml-4 mb-3 mt-2' />
          {individualAttendees.map(({ id, ...props }) => (
            <ContactCard
              variant='list'
              key={`individual-${id}`}
              onClear={handleRemoveAttendeeClick(id)}
              className='mb-2'
              {...props}
            />
          ))}
        </>
      );
    }
  };

  const renderGroups = () => {
    if (groups.length) {
      return (
        <>
          <Text text='Groups' isMedium className='ml-4 mb-3 mt-2' />
          {groups.map(({ label, value }) => (
            <ContactGroup
              key={`group-${label}`}
              name={label}
              contacts={getGroupsContacts([value])}
              className='mb-2'
              onClear={handleRemoveGroup(value)}
            />
          ))}
        </>
      );
    }
  };

  const renderButtons = () => {
    if (hasNoMeetingAttendees && hasNoEditsAttendees) {
      return (
        <Button variant={Variants.Transparent} isFullWidth onClick={handleSkipStep}>
          Skip this step
        </Button>
      );
    }

    return (
      <>
        <Button className='mb-2' isFullWidth onClick={handleMeetingSave()}>
          Next
        </Button>

        <Button color={Variants.Transparent} isFullWidth onClick={handleMeetingSave(true)}>
          Save draft
        </Button>
      </>
    );
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Box variant='manager'>
        <Row noGutters className='mb-2'>
          <Col lg={12}>
            <Text color='lightGrey' text='Add as many individuals or groups as you like' className='mb-4' />
          </Col>
          <Col lg={12}>
            <Row>
              <Col>
                <RadioButton
                  onChange={handleRadioChange}
                  name='type'
                  label='Individuals'
                  value={INDIVIDUALS}
                  isChecked={selectType === INDIVIDUALS}
                />
              </Col>
              <Col>
                <RadioButton
                  onChange={handleRadioChange}
                  name='type'
                  label='Group'
                  value={GROUP}
                  isChecked={selectType === GROUP}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>{renderSelectInput()}</Col>
        </Row>
      </Box>

      <Row>
        <Col className='position-relative'>
          <Divider isBold position='left' title='Added to meeting' className='mb-2 mt-5' />
          {!hasNoEditsAttendees && (
            <TextButton onClick={handleAssignClick} text='Assign Chair' css={styles.assignChairButton} />
          )}
        </Col>
      </Row>
      {hasNoEditsAttendees ? (
        <Box variant='manager' className='mb-4'>
          <Text color='lightGrey' text='No (0) attendees added' />
        </Box>
      ) : (
        <Row className='mb-2'>
          <Col>
            {renderGroups()}
            {renderIndividuals()}
          </Col>
        </Row>
      )}

      {renderButtons()}
      <AssignChairModalWindow onHide={onModalHide} show={isAssignModalVisible} />
    </div>
  );
};

AttendeesStep.propTypes = {};

export default AttendeesStep;
