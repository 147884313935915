import React from 'react';
import Button from 'components/v2/shared/Button';
import Permissions from './Permissions';
import StateBadge from 'components/v2/shared/StateBadge';
import Modal from 'components/v2/shared/Modal';
import { FiX } from 'react-icons/fi';
import Heading from 'components/v2/shared/Heading';

const Users = ({ name, role, isActive }) => {
  return (
    <>
      <div className='tw-mt-1 tw-grid tw-h-20 tw-grid-flow-col tw-grid-cols-12 tw-items-center tw-rounded tw-bg-gray-100'>
        <div className='tw-col-span-2 tw-flex tw-justify-center'>
          <div className='tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-[#AFB0D0]  tw-object-contain tw-font-sans tw-text-sm tw-font-semibold tw-text-white'>
            SS
          </div>
        </div>
        <div className='tw-col-span-4 sm:tw-col-span-7'>
          <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>{name}</div>
          <div className='tw-font-sans tw-text-xs tw-font-normal tw-text-gray-800'>{role}</div>
        </div>
        <div className='tw-col-span-4 tw-flex tw-justify-end sm:tw-col-span-2'>
          <StateBadge variant={isActive ? 'green' : 'purple'} className='!tw-text-xs'>
            {isActive ? 'Active' : 'Pending'}
          </StateBadge>
        </div>
      </div>
    </>
  );
};

const Edit = ({ userModalOpen, onModalChange, selectedRoleData, defaultPermissions, setPermissionsState }) => {
  return (
    <div>
      <Heading text='Users Assigned' className='!tw-mb-2 !tw-text-xl !tw-font-bold tw-text-black' />
      <div className='tw-grid tw-h-12 tw-grid-flow-col tw-grid-cols-8 tw-items-center tw-rounded tw-bg-gray-100 tw-px-5'>
        <div className='tw-col-span-6 tw-font-sans tw-text-sm tw-font-semibold tw-text-black sm:tw-col-span-6'>
          {selectedRoleData.users.length} total users
        </div>
        <div className='tw-col-span-6 tw-flex tw-justify-end'>
          <Button
            className='!tw-font-sans !tw-text-sm !tw-font-semibold'
            font='medium'
            isFullWidth
            size='small'
            variant='transparent-teal'
            onClick={onModalChange}
          >
            View all users
          </Button>
        </div>
      </div>
      <Permissions
        data={selectedRoleData}
        isDefault={selectedRoleData.isDefault}
        defaultPermissions={defaultPermissions}
        setPermissionsState={setPermissionsState}
      />

      <Modal isOpen={userModalOpen} onClose={onModalChange}>
        <div className='tw-mt-7 tw-mb-7 tw-p-8'>
          <div className='tw-mb-4 tw-h-12 tw-grid-flow-col tw-grid-cols-10 tw-items-center'>
            <Heading text={selectedRoleData.role} className='!tw-mb-6 !tw-text-2xl tw-font-bold' />
            <Heading
              text='Users assigned'
              className='!tw-mb-4 tw-font-sans !tw-text-xl tw-font-semibold tw-text-black'
            />
          </div>
          <div className='tw-pt-8'>
            {selectedRoleData.users.map((user) => (
              <div key={user.id} className='tw-mt-1'>
                <Users name={user.name} role={user.companyPosition} isActive />
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Edit;
