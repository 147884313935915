/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { ReactComponent as ArrowIcon } from 'images/icons/documents/breadcrumb-arrow-right.svg';

import * as styles from './Breadcrumb.styles';

const Breadcrumb = memo(({ name, onClick }) => {
  const breadcrumbStyles = [styles.breadcrumb.base, onClick && styles.breadcrumb.active];

  return (
    <div css={breadcrumbStyles}>
      <div onClick={onClick} className='breadcrumb__name'>
        {name}
      </div>

      <ArrowIcon className='breadcrumb__arrow' />
    </div>
  );
});

export { Breadcrumb };
