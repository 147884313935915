import React, { useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import RegularOption from '../RegularOption';
import ContactOption from '../ContactOption';
import ContactGroupOption from '../ContactGroupOption';
import { OptionVariants } from '../constants';
import { useVirtual } from 'react-virtual';

const optionComponentVariants = {
  [OptionVariants.Regular]: RegularOption,
  [OptionVariants.Contact]: ContactOption,
  [OptionVariants.ContactGroup]: ContactGroupOption,
};

export const Heights = {
  Regular: 'regular',
  Large: 'large',
};

const Menu = ({
  items,
  isOpen,
  getItemProps = (props) => props,
  renderFooter,
  selectedItem,
  optionVariant,
  height = Heights.Regular,
  listBorder,
}) => {
  const OptionComponent = optionComponentVariants[optionVariant];
  const listRef = useRef();

  const rowVirtualizer = useVirtual({
    size: items.length,
    parentRef: listRef,
    overscan: 3,
  });

  const isItemSelected = (itemValue) => {
    if (!selectedItem) {
      return false;
    }

    if (Array.isArray(selectedItem)) {
      return !!selectedItem.find((item) => item.value === itemValue);
    }

    return selectedItem.value === itemValue;
  };

  const renderVirtualItems = rowVirtualizer.virtualItems.map((virtualItem) => {
    const item = items[virtualItem.index];

    return (
      <OptionComponent
        key={`${item.label}-${virtualItem.index}`}
        item={item}
        isSelected={isItemSelected(item.value)}
        isDisabled={item.isDisabled}
        height={height}
        listBorder={listBorder}
        itemProps={getItemProps({
          disabled: item.isDisabled,
          index: virtualItem.index,
          ref: virtualItem.measureRef,
          style: {
            transform: `translateY(${virtualItem.start}px)`,
          },
        })}
      />
    );
  });

  return (
    <div className={clsx('tw-w-full tw-rounded-sm tw-bg-white tw-shadow-dropdown', { 'tw-hidden': !isOpen })}>
      <div
        className={clsx('tw-relative tw-box-border tw-w-full tw-overflow-y-auto tw-overflow-x-hidden', {
          'tw-max-h-52': height === Heights.Regular,
          'tw-max-h-72': height === Heights.Large,
        })}
      >
        <ul className='tw-m-0 tw-box-border tw-block tw-w-full tw-list-none tw-p-0' ref={listRef}>
          <li key='total-size' style={{ height: rowVirtualizer.totalSize }} />
          {renderVirtualItems}
        </ul>
      </div>

      <div
        className={clsx(
          'tw-px-5 tw-py-3',
          'tw-box-border tw-bg-gray-100',
          'tw-border-0 tw-border-t tw-border-solid tw-border-purple-200',
          'empty:tw-border-0 empty:tw-p-0'
        )}
      >
        {renderFooter}
      </div>
    </div>
  );
};

Menu.propTypes = {
  items: PropTypes.array,
  isOpen: PropTypes.bool,
  listBorder: PropTypes.bool,
  getItemProps: PropTypes.func,
  renderFooter: PropTypes.node,
  selectedItem: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  optionVariant: PropTypes.oneOf(Object.values(OptionVariants)),
  height: PropTypes.oneOf(Object.values(Heights)),
};

export default Menu;
