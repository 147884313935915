export const signatureTypes = {
  saved: 'saved',
  script: 'script',
  upload: 'upload',
  drawing: 'drawing',
};

export const signatureFonts = {
  bethEllen: 'BethEllen-Regular',
  dancingScript: 'DancingScript-Regular',
  dawning: 'Dawning-Regular',
  glory: 'GiveYouGlory-Regular',
  sheppards: 'MrsSheppards-Regular',
};
