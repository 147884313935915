import { css } from '@emotion/core';
import { colors } from 'styles';

export const notOpenedModal = css`
  .modal-dialog {
    width: 340px;
    height: 260px;
  }

  .modal-title {
    padding-left: 16px !important;
  }

  .column {
    padding: 0;
    max-width: 135px;

    .text-left {
      text-align: left;
    }

    .far {
      position: relative;
      top: -1px;
    }

    .date {
      background-color: ${colors.cyan};
      color: ${colors.white};
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      height: 40px;
    }

    .time {
      background-color: ${colors.lightCyan};
      color: ${colors.cyan};
      height: 40px;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }
}
`;
