import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ContentAreaItem from '../ContentAreaItem';
import LoadingIndicator from 'components/v2/shared/LoadingIndicator';
import Placeholder from '../Placeholder';

const ContentArea = ({ isLoading, isFetchingMore, items = [], onClick, isSelected = () => false }) => {
  const handleItemClick = (item) => () => onClick(item);
  const renderItems = items.map((item) => (
    <ContentAreaItem
      key={item.id}
      name={item.name}
      onClick={handleItemClick(item)}
      variant={item.__typename}
      isSelected={isSelected(item)}
    />
  ));

  if (isLoading && !isFetchingMore) {
    return <LoadingIndicator isVisible />;
  }

  if (!items.length) {
    return <Placeholder message='We could not find anything' />;
  }

  return (
    <div className='tw-flex tw-flex-col tw-gap-y-1'>
      {renderItems}
      <LoadingIndicator isVisible={isLoading && isFetchingMore} />
    </div>
  );
};

ContentArea.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
  isSelected: PropTypes.func,
  isLoading: PropTypes.bool,
  isFetchingMore: PropTypes.bool,
};

export default ContentArea;
