import { useSubscription } from '@apollo/client';
import { TIMEZONE_QUERY } from './../data/queries/TimezoneQuery';
import { TIMEZONE_SUBSCRIPTION } from './../data/subscriptions/TimezoneSubscription';

const useTimezoneSubscription = ({ contactId }) => {
  useSubscription(TIMEZONE_SUBSCRIPTION, {
    shouldResubscribe: true,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const fallback = { currentContact: { id: contactId } };
      const cache = client.readQuery({ query: TIMEZONE_QUERY }) || fallback;

      if (!subscriptionData.data) return;

      const { timezoneChanged } = subscriptionData.data;

      if (!timezoneChanged) return;
      if (!cache) return;

      client.writeQuery({
        query: TIMEZONE_QUERY,
        data: { ...cache, currentContact: { ...cache.currentContact, timezone: timezoneChanged } },
      });
    },
  });
};

export { useTimezoneSubscription };
