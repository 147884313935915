import { gql, useQuery } from '@apollo/client';
import { VOTE_FRAGMENT } from 'data/v2/fragments/VoteFragment';

const MEETING_VOTES_QUERY = gql`
  query MeetingVotesQuery($id: ID!) {
    currentCompany {
      id

      meeting(id: $id) {
        id

        votes {
          ...VoteFields
        }
      }
    }
  }
  ${VOTE_FRAGMENT}
`;

const useMeetingVotesQuery = (options) => useQuery(MEETING_VOTES_QUERY, options);

export { MEETING_VOTES_QUERY, useMeetingVotesQuery };
