import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import SignaturePad from 'signature_pad';
import Dropzone from 'react-dropzone';
import canvasTxt from 'canvas-txt';
import 'blueimp-canvas-to-blob';
import { signatureTypes, signatureFonts } from './types';
import Heading from 'components/v2/shared/Heading/';
import TextInput from 'components/v2/shared/TextInput';
import Label from 'components/v2/shared/Label';
import Button from 'components/v2/shared/Button/Button';
import { FiCircle } from 'react-icons/fi';
import clsx from 'clsx';

const Index = forwardRef(({ onSave, onClearSignature, name, existingSignatureImageUrl }, ref) => {
  useImperativeHandle(ref, () => ({
    processInput: (handler) => {
      if (isCanvasEmpty()) {
        return setError('Please add a signature in order to proceed');
      }

      canvas.current.toBlob(handler);
    },
    resetControl: () => {
      resetControl();
    },
    setError: (error) => {
      setError(error);
    },
  }));

  const savedCanvas = useRef();
  const canvas = useRef();

  let [activeFont, setActiveFont] = useState(signatureFonts.bethEllen);
  let [signatureType, setSignatureType] = useState(signatureTypes.saved);
  let [activeScript, setActiveScript] = useState(false);
  let [ratioModifier, setRatioModifier] = useState(0);
  let [pad, setPad] = useState();
  let [image, setImage] = useState();
  let [error, setError] = useState();

  const resetControl = () => {
    setActiveFont(signatureFonts.bethEllen);
    setSignatureType(signatureTypes.drawing);
    setImage(null);
    setError(null);
    setRatioModifier(0);
  };

  const isActiveSignatureType = (type) => {
    return type === signatureType;
  };

  const toggleSignatureType = (value) => {
    setSignatureType(value);
    setActiveScript(false);
    setRatioModifier(0);
  };

  useEffect(() => {
    if (signatureType && canvas.current) {
      return changeSignatureType(signatureType);
    }
    if (signatureType && savedCanvas.current) {
      return setSavedSignature();
    }
  }, [ratioModifier, activeFont, signatureType, image]);

  useEffect(() => {
    if (signatureType && savedCanvas.current) {
      return setSavedSignature();
    }
  }, [signatureType, existingSignatureImageUrl]);

  const setSavedSignature = () => {
    const context = savedCanvas.current.getContext('2d');
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    context.clearRect(0, 0, savedCanvas.current.width, savedCanvas.current.height);

    savedCanvas.current.width = savedCanvas.current.offsetWidth * ratio;
    savedCanvas.current.height = savedCanvas.current.offsetHeight * ratio;

    context.scale(ratio, ratio);

    if (pad) {
      pad.off();
    }

    if (existingSignatureImageUrl) {
      const existingImage = new Image();
      existingImage.src = existingSignatureImageUrl;
      existingImage.crossOrigin = 'Anonymous';
      existingImage.onload = () => insertImageIntoCanvas(context, ratio, existingImage, savedCanvas);
    }
  };

  const changeSignatureType = (type) => {
    const context = canvas.current.getContext('2d');
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    context.clearRect(0, 0, canvas.current.width, canvas.current.height);

    canvas.current.width = canvas.current.offsetWidth * ratio;
    canvas.current.height = canvas.current.offsetHeight * ratio;

    context.scale(ratio, ratio);

    if (pad) {
      pad.off();
    }

    if (type === signatureTypes.saved && existingSignatureImageUrl) {
      return insertExistingSignatureImage(context, ratio);
    } else if (type === signatureTypes.drawing) {
      return setPad(new SignaturePad(canvas.current, { throttle: 0 }));
    } else if (type === signatureTypes.script) {
      return insertNameIntoCanvas(context, ratio);
    } else if (type === signatureTypes.upload && image) {
      return insertImageIntoCanvas(context, ratio, image, canvas);
    }
  };

  const insertExistingSignatureImage = (context, ratio) => {
    const existingImage = new Image();
    existingImage.src = existingSignatureImageUrl;
    existingImage.crossOrigin = 'Anonymous';
    existingImage.onload = () => insertImageIntoCanvas(context, ratio, existingImage, canvas);
  };

  const insertImageIntoCanvas = (context, ratio, image, canvas) => {
    const scale = 1 / ratio;

    const imgScale = Math.min(
      (scale * canvas.current.width) / image.width,
      (scale * canvas.current.height) / image.height
    );

    const imgW = image.width * (imgScale + ratioModifier / 100);
    const imgH = image.height * (imgScale + ratioModifier / 100);

    const cenX = (scale * canvas.current.width - imgW) / 2;
    const cenY = (scale * canvas.current.height - imgH) / 2;

    context.drawImage(image, cenX, cenY, imgW, imgH);

    const pixels = context.getImageData(0, 0, canvas.current.width, canvas.current.height);

    convertToGrayscaleAndRemoveBackground(pixels);

    context.putImageData(pixels, 0, 0, 0, 0, pixels.width, pixels.height);
  };

  const convertToGrayscaleAndRemoveBackground = (pixels) => {
    for (let y = 0; y < pixels.height; y++) {
      for (let x = 0; x < pixels.width; x++) {
        let data = pixels.data;

        // Grayscale
        const index = y * 4 * pixels.width + x * 4;
        const average = (data[index] + data[index + 1] + data[index + 2]) / 3;

        data[index] = average;
        data[index + 1] = average;
        data[index + 2] = average;

        // Remove background
        if (data[index] >= 160 && data[index + 1] >= 160 && data[index + 2] >= 160) {
          data[index] = 0;
          data[index + 1] = 0;
          data[index + 2] = 0;
          data[index + 3] = 0;
        }
      }
    }
  };

  const insertNameIntoCanvas = (context, ratio) => {
    if (name) {
      const fontSize = 50 + 2 * ratioModifier;
      const scale = 1 / ratio;
      const x = 0;
      const y = activeFont === signatureFonts.dawning ? -20 : -10;

      canvasTxt.font = activeFont;
      canvasTxt.fontSize = fontSize;
      canvasTxt.lineHeight = fontSize;

      canvasTxt.drawText(context, name, x, y, scale * canvas.current.width, scale * canvas.current.height);
    }
  };

  const isActiveFont = (font) => {
    return isActiveSignatureType(signatureTypes.script) && activeFont === font;
  };

  const clearCanvas = () => {
    const context = canvas.current.getContext('2d');
    if (signatureType === signatureTypes.drawing) {
      context.clearRect(0, 0, canvas.current.width, canvas.current.height);
    } else if (signatureType === signatureTypes.upload) {
      setImage(null);
    }
  };

  const changeFontHandler = (font) => {
    return isActiveSignatureType(signatureTypes.script) ? setActiveFont.bind(this, font) : null;
  };

  const maxRatioModifier = 18;
  const minRatioModifier = -18;

  const isMinRatioModifier = ratioModifier === minRatioModifier;
  const isMaxRatioModifier = ratioModifier === maxRatioModifier;

  const scaleUp = () => {
    if (isMaxRatioModifier) return;

    setRatioModifier(ratioModifier + 1);
  };

  const scaleDown = () => {
    if (isMinRatioModifier) return;

    setRatioModifier(ratioModifier - 1);
  };

  const handleDropzoneDrop = (acceptedFiles) => {
    const upload = acceptedFiles[0];
    const image = new Image();

    image.src = URL.createObjectURL(upload);
    image.onload = () => setImage(image);
  };

  const isCanvasEmpty = () => {
    const context = canvas.current.getContext('2d');

    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.current.width, canvas.current.height).data.buffer
    );

    return !pixelBuffer.some((color) => color !== 0);
  };

  const dropZone = (
    <div className='tw-w-100 tw-absolute tw-flex tw-h-full tw-w-full	tw-cursor-pointer	tw-items-center tw-justify-center'>
      <Dropzone accept={['image/jpg', 'image/jpeg', 'image/png']} onDrop={handleDropzoneDrop}>
        {({ getRootProps, getInputProps }) => (
          <div
            className='tw-w-100 tw-absolute tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-justify-center'
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            <div className='justify-center tw-flex tw-items-center'>
              <span className='tw-p-l-15 d-none d-lg-block tw-text-black'>Drag and drop</span>
              <span className='tw-p-l-15 d-lg-none tw-font-sans tw-text-sm	tw-font-medium	tw-text-black'>
                Select from folder instead
              </span>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );

  const renderFont = (fontName) => {
    const clickHandler = changeFontHandler(signatureFonts[fontName]);
    return (
      <div
        className={clsx('tw-relative tw-mt-1 tw-h-16 tw-rounded tw-bg-gray-100 tw-leading-9 hover:tw-cursor-pointer')}
        style={{ fontFamily: `${signatureFonts[fontName]}, sans-serif` }}
        onClick={clickHandler}
      >
        <div className='tw-text-truncate tw-absolute tw-top-5 tw-left-20 tw-z-10 tw-w-full tw-max-w-xs tw-pl-0.5 tw-text-xl'>
          {name}
        </div>
        <div className='tw-relative'>
          <div className='tw-absolute	tw-left-7 tw-top-3'>
            <FiCircle color='#00BAC6' size={16} />
            {isActiveFont(signatureFonts[fontName]) && (
              <div style={{ left: '5px' }} className='tw-absolute	tw-top-0'>
                <FiCircle color='#00BAC6' size={6} fill='#00BAC6' />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  const DrawHere = () => {
    const context = canvas.current.getContext('2d');
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    context.clearRect(0, 0, canvas.current.width, canvas.current.height);

    canvas.current.width = canvas.current.offsetWidth * ratio;
    canvas.current.height = canvas.current.offsetHeight * ratio;

    context.scale(ratio, ratio);
    setPad(new SignaturePad(canvas.current, { throttle: 0 }));
  };
  return (
    <>
      <div className='tw-hidden sm:tw-block'>
        <Heading text='SmartSign settings' className='!tw-mb-0' />
      </div>
      <div className='tw-pt-8 tw-text-xs'>
        {error && <div className='tw-pb-10 tw-text-sm tw-text-red-600 md:tw-w-full'>{error}</div>}
        <div>
          <div className='tw-pb-5'>
            <TextInput
              size='large'
              label='Name used for signature'
              disabled={true}
              type='text'
              value={name || ''}
              isReadOnly
              className='tw-form-control tw-w-100 tw-m-b-10 tw-border tw-border-solid	tw-border-black tw-bg-white'
            />
          </div>

          {(isActiveSignatureType(signatureTypes.upload) ||
            isActiveSignatureType(signatureTypes.drawing) ||
            isActiveSignatureType(signatureTypes.saved)) &&
            existingSignatureImageUrl && (
              <div className='tw-py-5'>
                <Label text='Current signature style' className='tw-font-sans	tw-text-xs	tw-font-semibold' />
                <div className='tw-relative tw-mt-1.5 tw-flex tw-w-full	tw-items-center tw-justify-center	tw-rounded-none tw-border tw-border-solid tw-border-black tw-bg-gray-100 sm:tw-rounded-xl'>
                  <canvas className='tw-h-40 tw-w-full' ref={savedCanvas} />
                </div>
              </div>
            )}
          {!isActiveSignatureType(signatureTypes.saved) && (
            <div className='tw-py-5'>
              <div
                className={clsx('tw-flex tw-justify-between', {
                  'tw-mt-2':
                    isActiveSignatureType(signatureTypes.upload) || isActiveSignatureType(signatureTypes.drawing),
                })}
              >
                <Label
                  text={
                    isActiveSignatureType(signatureTypes.upload)
                      ? 'Upload images as a signature'
                      : isActiveSignatureType(signatureTypes.drawing)
                      ? 'Draw new signature below in box'
                      : 'Current signature style'
                  }
                  className='tw-font-sans !tw-text-xs !tw-font-semibold	!tw-text-black !tw-text-black'
                />

                {(isActiveSignatureType(signatureTypes.upload) || isActiveSignatureType(signatureTypes.drawing)) && (
                  <a
                    className='tw-cursor-pointer tw-font-sans tw-text-xs tw-font-semibold	tw-text-teal-500	hover:tw-text-teal-500'
                    onClick={() => toggleSignatureType(signatureTypes.saved)}
                  >
                    Cancel
                  </a>
                )}
              </div>

              <div
                className={clsx(
                  'tw-relative tw-mt-1.5 tw-flex tw-w-full	tw-items-center tw-justify-center	tw-rounded-none tw-border tw-border-solid tw-border-teal-500 tw-bg-gray-100 sm:tw-rounded-xl',
                  {
                    '!tw-border-2 tw-border-dashed !tw-border-purple-500': isActiveSignatureType(signatureTypes.upload),
                  }
                )}
              >
                <canvas
                  className='tw-h-40 tw-w-full'
                  ref={isActiveSignatureType(signatureTypes.script) && !activeScript ? savedCanvas : canvas}
                />

                {!isActiveSignatureType(signatureTypes.drawing) && (
                  <div className='tw-absolute tw-top-2 tw-left-2.5'>
                    <i
                      className={clsx('tw-text-normal', {
                        'tw-text-gray-200': isMinRatioModifier,
                        'tw-text-gray-500 hover:tw-cursor-pointer hover:tw-text-black': !isMinRatioModifier,
                      })}
                      onClick={scaleDown}
                    />
                    <i
                      className={clsx('tw-text-normal', {
                        'tw-text-gray-200': isMinRatioModifier,
                        'tw-text-gray-500 hover:tw-cursor-pointer hover:tw-text-black': !isMinRatioModifier,
                      })}
                      onClick={scaleUp}
                    />
                  </div>
                )}
                {isActiveSignatureType(signatureTypes.upload) && !image && dropZone}
              </div>
              {isActiveSignatureType(signatureTypes.drawing) && (
                <span
                  className='tw-mt-2 tw-cursor-pointer tw-font-sans tw-text-xs tw-font-semibold	tw-text-teal-500 hover:tw-text-teal-500'
                  onClick={clearCanvas}
                >
                  Clear
                </span>
              )}
              {(isActiveSignatureType(signatureTypes.upload) || isActiveSignatureType(signatureTypes.drawing)) && (
                <div className='tw-mt-7'>
                  <Button font='semibold' isFullWidth size='large' variant='teal' onClick={onSave}>
                    Save
                  </Button>
                </div>
              )}
            </div>
          )}

          {isActiveSignatureType(signatureTypes.script) && (
            <>
              {!activeScript ? (
                <div className='tw-flex tw-justify-between tw-pr-4'>
                  <a
                    className='tw-cursor-pointer tw-font-sans tw-text-xs tw-font-semibold	tw-text-teal-500 hover:tw-text-teal-500'
                    onClick={() => setActiveScript(true)}
                  >
                    Choose different font style
                  </a>
                  <a
                    className='tw-cursor-pointer tw-font-sans tw-text-xs tw-font-semibold	tw-text-teal-500 hover:tw-text-teal-500 '
                    onClick={onClearSignature}
                  >
                    Clear Saved Signature
                  </a>
                </div>
              ) : (
                <>
                  <div className='tw-mt-4 tw-flex tw-justify-between'>
                    <span>
                      <Label
                        text='Choose new font signature'
                        className='tw-font-sans	tw-text-xs tw-font-semibold tw-text-black'
                      />
                    </span>
                    <span>
                      <a
                        className='tw-cursor-pointer tw-font-sans tw-text-xs tw-font-semibold	tw-text-teal-500 hover:tw-text-teal-500'
                        onClick={() => setActiveScript(false)}
                      >
                        Cancel
                      </a>
                    </span>
                  </div>

                  <div className='tw-text-black'>
                    {renderFont('bethEllen')}
                    {renderFont('dancingScript')}
                    {renderFont('dawning')}
                    {renderFont('glory')}
                    {renderFont('sheppards')}
                  </div>

                  <div className='tw-mt-7'>
                    <Button font='semibold' isFullWidth size='large' variant='teal' onClick={onSave}>
                      Save
                    </Button>
                  </div>
                </>
              )}
            </>
          )}

          <div className='tw-mt-12 tw-font-sans tw-text-xs tw-font-semibold tw-text-black'>Create new signature</div>

          {(isActiveSignatureType(signatureTypes.saved) ||
            isActiveSignatureType(signatureTypes.drawing) ||
            isActiveSignatureType(signatureTypes.upload)) && (
            <div className='tw-pt-5'>
              <a
                className='tw-cursor-pointer tw-font-sans tw-text-xs tw-font-semibold tw-text-teal-500 hover:tw-text-teal-500'
                onClick={() => toggleSignatureType(signatureTypes.script)}
              >
                Choose font style
              </a>
            </div>
          )}
          {(isActiveSignatureType(signatureTypes.saved) ||
            isActiveSignatureType(signatureTypes.script) ||
            isActiveSignatureType(signatureTypes.upload)) && (
            <div className='tw-pt-5'>
              <a
                className='tw-cursor-pointer tw-font-sans tw-text-xs tw-font-semibold tw-text-teal-500 hover:tw-text-teal-500'
                onClick={() => toggleSignatureType(signatureTypes.drawing)}
              >
                Create signature with mouse or trackpad
              </a>
            </div>
          )}
          {(isActiveSignatureType(signatureTypes.saved) ||
            isActiveSignatureType(signatureTypes.script) ||
            isActiveSignatureType(signatureTypes.drawing)) && (
            <div className='tw-pt-5'>
              <a
                className='tw-cursor-pointer tw-font-sans tw-text-xs tw-font-semibold tw-text-teal-500 hover:tw-text-teal-500'
                onClick={() => toggleSignatureType(signatureTypes.upload)}
              >
                Upload images as a signature
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
});
export default Index;
