/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import { useIntl, FormattedMessage } from 'react-intl';

import { useShareable } from './../hooks/useShareable';
import { useShareDirectory } from './../hooks/useShareDirectory';
import { useShareDocument } from './../hooks/useShareDocument';

import ClipLoader from 'react-spinners/ClipLoader';

import { LoadingIndicator } from 'components/shared/LoadingIndicator';
import { ShareModalContactList } from './../ShareModalContactList';
import { ContactPicker } from 'components/shared/ContactPicker';
import { Modal } from 'components/shared/Modal';

import { ReactComponent as DirectoryIcon } from 'images/icons/documents/directory-icon-large.svg';
import { ReactComponent as DocumentIcon } from 'images/icons/documents/document-icon-large.svg';
import { ReactComponent as CopyIcon } from 'images/share-button.svg';
import { ReactComponent as ShareIcon } from 'images/icons/documents/share-icon.svg';

import { colors } from 'styles';
import * as styles from './ShareModal.styles';

const ShareModal = memo(({ show, resource, onClose }) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const intl = useIntl();

  const highlightLinkCopied = () => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
  };

  const { isLoading, isDirectory, isDocument, item, shareableWith, sharedWithByDefault, isSharedWith, toggleContact } =
    useShareable({ resource });

  const resourceIcon = () => {
    if (isDirectory) {
      return <DirectoryIcon className='flex-shrink-0' />;
    } else if (isDocument) {
      return <DocumentIcon className='flex-shrink-0' />;
    }
  };

  const { shareDocument, isSharing: isSharingDocument } = useShareDocument({
    onCompleted: onClose,
  });
  const { shareDirectory, isSharing: isSharingDirectory } = useShareDirectory({
    onCompleted: onClose,
  });

  const modalTitle = (
    <div className='d-flex align-items-end'>
      {resourceIcon()}

      <div css={styles.header}>
        <FormattedMessage id='ShareModal.share' />
      </div>

      <div className='text-truncate' css={styles.name}>
        {resource?.name}
      </div>
    </div>
  );

  const copyShareableUrl = () => {
    navigator.clipboard.writeText(item.shareableUrl);
    highlightLinkCopied();
  };

  const urlStyles = [styles.url.base, linkCopied && styles.url.copied];

  const checkmark = (
    <div css={styles.checkmark}>
      <i className='fa fa-check' />
    </div>
  );

  const copyLink = () => {
    if (linkCopied) {
      return checkmark;
    } else {
      return <CopyIcon css={styles.copyIcon} onClick={copyShareableUrl} />;
    }
  };

  const handleShare = () => {
    const contactIds = item.sharedWith.map((item) => item.id);

    if (isDirectory) {
      shareDirectory(item.id, contactIds);
    } else if (isDocument) {
      shareDocument(item.id, contactIds);
    }
  };

  const isSharing = isSharingDocument || isSharingDirectory;

  const modalBody = item && !isLoading && (
    <>
      <ContactPicker
        dropdownOptions={shareableWith}
        values={[]}
        placeholder={intl.formatMessage({ id: 'ShareModal.addContacts' })}
        isSelected={isSharedWith}
        onToggleOption={toggleContact}
      />

      <ShareModalContactList
        withAccess={item.contactsWithAccess}
        sharedWith={item.sharedWith}
        onDelete={toggleContact}
      />

      <div className='d-flex align-items-center m-t-10'>
        <div className='text-truncate' css={urlStyles}>
          {item.shareableUrl}
        </div>

        {copyLink()}
      </div>

      <div css={styles.info}>
        <FormattedMessage id='ShareModal.onlyContacts' />
      </div>

      <div className='d-flex justify-content-center m-t-20'>
        <button
          className={`btn btn-inverted btn-sm ${item.sharedWith.length ? '' : 'btn-disabled'}`}
          onClick={handleShare}
        >
          <div className='d-flex align-items-center'>
            <ShareIcon />
            <span>
              <FormattedMessage id='ShareModal.share' />
            </span>
          </div>
        </button>
        <LoadingIndicator show={isSharing} />
      </div>
    </>
  );

  return (
    <Modal title={modalTitle} onClose={onClose} modalStyles={styles.modal} show={show} centered>
      <div css={styles.content}>
        <div css={styles.loading}>
          <ClipLoader color={colors.cyan} loading={isLoading} />
        </div>

        {modalBody}
      </div>
    </Modal>
  );
});

export { ShareModal };
