import { useState } from 'react';
import { usePublishVoteMutation } from './../data/mutations/PublishVoteMutation';

const usePublishVote = ({ onCompleted }) => {
  const update = (cache, { data: { publishVote } }) => onCompleted();

  const [mutate, { loading: isPublishing }] = usePublishVoteMutation({ update });

  const publishVote = (payload) => mutate({ variables: { input: payload } });

  return {
    publishVote,
    isPublishing,
  };
};

export { usePublishVote };
