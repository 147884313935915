import { gql, useQuery } from '@apollo/client';

const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      id
      dateFormat {
        label
        value
      }
    }
  }
`;

const useCurrentUserQuery = (options) => useQuery(CURRENT_USER_QUERY, options);

export { CURRENT_USER_QUERY, useCurrentUserQuery };
