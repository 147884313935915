import { gql, useMutation } from '@apollo/client';

const CREATE_ROLE_PERMISSIONS_MUTATION = gql`
  mutation CreateRolePermissions($input: CreateRolePermissionsInput!) {
    createRolePermissions(input: $input) {
      rolePermissions {
        id
        permitted
      }
      errors {
        attribute
        message
      }
    }
  }
`;

const useCreateRolePermissionsMutation = (options) => useMutation(CREATE_ROLE_PERMISSIONS_MUTATION, options);

export { CREATE_ROLE_PERMISSIONS_MUTATION, useCreateRolePermissionsMutation };
