import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FiLogOut } from 'react-icons/fi';
import { intl } from 'contexts/v2/TranslationContext';

const LogOutItem = ({}) => (
  <div className='tw-flex tw-items-center tw-gap-2'>
    <FiLogOut className='tw-h-4 tw-w-4' />
    {intl.formatMessage({ id: 'MobileMenu.signOut' })}
  </div>
);

LogOutItem.propTypes = {};

export default LogOutItem;
