/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FormattedMessage } from 'react-intl';

import { UploadWindowItemActions } from './../UploadWindowItemActions';
import { formatBytes } from 'helpers/formatBytes';

import { ReactComponent as DocumentIcon } from 'images/icons/documents/document-icon.svg';
import { ReactComponent as TooltipIcon } from 'images/icons/documents/upload-tooltip-icon.svg';

import * as styles from './UploadWindowItem.styles';

const UploadWindowItem = memo(({ id, name, path, state, size, error, progress, xhr }) => {
  const progressStyles = error ? styles.failed : styles.progress(progress);
  const pathDefined = path.split('/')[0] !== 'undefined';

  const itemSize = formatBytes(size, 2);
  const itemPath = path
    .split('/')
    .filter((item) => item.length)
    .join(' > ');

  const tooltip = (
    <Tooltip>
      <div css={styles.tooltip}>{error}</div>
    </Tooltip>
  );

  const itemError = error && (
    <OverlayTrigger placement='top' overlay={tooltip}>
      <TooltipIcon className='cursor-pointer' />
    </OverlayTrigger>
  );

  return (
    <div css={styles.container}>
      <div css={styles.flex}>
        <DocumentIcon css={styles.icon} />

        <div className='d-flex align-items-center'>
          <div>
            <div css={styles.name}>{name}</div>

            {pathDefined && (
              <div css={styles.path}>
                {error ? (
                  <div css={styles.error}>
                    <FormattedMessage id='UploadWindowItem.uploadFailed' />
                  </div>
                ) : (
                  itemPath
                )}
              </div>
            )}

            {!pathDefined && error && (
              <div css={styles.path}>
                <div css={styles.error}>
                  <FormattedMessage id='UploadWindowItem.uploadFailed' />
                </div>
              </div>
            )}
          </div>

          {itemError}
        </div>
      </div>

      <div css={styles.size}>{itemSize}</div>

      <div css={styles.actions}>
        <UploadWindowItemActions id={id} state={state} progress={progress} xhr={xhr} />
      </div>

      <div css={styles.bar}>
        <div css={progressStyles} />
      </div>
    </div>
  );
});

export { UploadWindowItem };
