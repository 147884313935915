import React, { memo } from 'react';
import { components as Components } from 'react-select';
import RCreatableSelect from 'react-select/creatable';

import { If } from '../helpers/If';

import * as selectStyles from './Select.styles';

const CreatableSelect = memo(
  ({
    styles,
    isClearable,
    isSearchable = false,
    hideSelectedOptions,
    backspaceRemovesValue,
    onChange,
    components,
    options,
    isMulti = true,
    ...props
  }) => {
    const MultiValueContainer = ({ selectProps: { value } }) => value.label;
    const DropdownIndicator = (props) => (
      <Components.DropdownIndicator {...props}>
        <i className='fa fa-chevron-down' />
      </Components.DropdownIndicator>
    );

    const Option = (props) => (
      <Components.Option {...props}>
        <div className='single-option-wrapper'>
          <label>{props.label}</label>

          <If query={props.isSelected}>
            <div className='checkmark-circle'>
              <div className='checkmark' />
            </div>
          </If>
        </div>
      </Components.Option>
    );

    const isDeselect = (event) => event.action === 'deselect-option';
    const onChangeHandler = (selected, event) => onChange(isDeselect(event) ? null : selected);
    const componentStyles = isSearchable ? { ...selectStyles.component, input: () => {} } : selectStyles.component;
    const applicableStyles = styles ? styles(componentStyles) : componentStyles;

    return (
      <>
        <RCreatableSelect
          isMulti={isMulti}
          styles={applicableStyles}
          options={options}
          isClearable={false}
          onChange={onChangeHandler}
          isSearchable={isSearchable}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, Option, MultiValueContainer }}
          {...props}
        />
      </>
    );
  }
);

export { CreatableSelect };
