import { css } from '@emotion/core';
import { media } from 'styles';

export const inner = ({ height, width, scale }) => css`
  position: relative;
  margin: 0 auto;
  line-height: 100vh;
  width: ${width * scale}px;
  height: ${height * scale}px;
  ${media.tablet} {
    max-width: 100%;
    height: auto;
  }
`;

export const image = css`
  width: 100%;
  height: 100%;
`;

export const outer = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: auto;
  width: 100%;
  bottom: 0;
`;
