export const MEETING_STATUS = {
  LOADING: 0,
  SUCCEEDED: 1,
  FAILED: 2,
  ENDED: 3,
  JOINED_FROM_ANOTHER_DEVICE: 4,
  LEFT: 5,
  TERMINAL_FAILURE: 6,
};

export const MEETING_IN_SESSION = 'in_session';
