/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { ReviewerListTab } from './../ReviewerListTab';
import { ReviewerListItem } from './../ReviewerListItem';

const ReviewerListTabs = memo(({ reviews, comments, minutes, currentContact, onReviewerClick }) => {
  const tabMap = {
    invited: 1,
    comments: 2,
  };

  const intl = useIntl();

  const [activeTab, setActiveTab] = useState(tabMap.invited);

  const isPendingTabActive = activeTab === tabMap.invited;
  const isCommentsTabActive = activeTab === tabMap.comments;

  const getCommentsForReview = (review) => {
    return comments.filter((comment) => comment.author.data.attributes.id === review.reviewer.data.attributes.id);
  };

  const reviewers = reviews.map((review) => {
    const reviewComments = getCommentsForReview(review);

    return (
      <ReviewerListItem
        key={review.id}
        review={review}
        comments={reviewComments}
        showComments={isCommentsTabActive}
        onClick={onReviewerClick}
      />
    );
  });

  return (
    <>
      <div className='d-flex flex-wrap align-items-center m-l-10 m-r-10'>
        <ReviewerListTab
          value={tabMap.invited}
          isActive={isPendingTabActive}
          label={intl.formatMessage({ id: 'ReviewerListTabs.invited' })}
          onClick={setActiveTab}
        />

        <ReviewerListTab
          value={tabMap.comments}
          isActive={isCommentsTabActive}
          label={intl.formatMessage({ id: 'ReviewerListTabs.comments' })}
          onClick={setActiveTab}
        />
      </div>

      <div className='m-t-10'>{reviewers}</div>
    </>
  );
});

export { ReviewerListTabs };
