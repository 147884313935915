import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const drawer = {
  base: css`
    z-index: 9991;
    pointer-events: all;
    box-shadow: none;
    transform: translate3d(761px, 0, 0);
    transform: translate3d(100%, 0, 0);
    width: 761px;
    max-width: 100%;

    ${media.mobile} {
      animation-duration: 0;

      .drawer__close {
        display: none;
      }
    }
  `,

  state: {
    open: css`
      box-shadow: -20px 4px 50px rgba(54, 71, 98, 0.25);
      transform: translate3d(0, 0, 0);
    `,
  },
};

export const wrapper = css`
  padding: 21px 35px;

  ${media.mobile} {
    padding: 0 15px;
  }
`;

export const content = css`
  overflow: auto;
  height: calc(100vh - 400px);
`;

export const header = css`
  ${fonts.bold}
  color: ${colors.black};
  font-size: 18px;
  text-align: left;

  ${media.mobile} {
    display: none;
  }
`;

export const line = css`
  height: 1px;
  background: #f2f2f2;
  width: calc(100% + 70px);
  margin: 30px -35px;

  ${media.mobile} {
    width: calc(100% + 50px);
  }
`;
