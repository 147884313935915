import React from 'react';
import { components as Components } from 'react-select';

import { matchedParts } from '../helpers/MatchedText';

export const HighlightableOption = (props) => {
  const {
    label,
    selectProps: { inputValue },
  } = props;
  const parts = matchedParts(label, inputValue);

  return (
    <Components.Option {...props}>
      <label>
        {parts.map(({ text, matched }, index) => {
          if (matched) {
            return <strong key={index}>{text}</strong>;
          } else {
            return text;
          }
        })}
      </label>
    </Components.Option>
  );
};

export default HighlightableOption;
