import { gql } from '@apollo/client';

const CONTACT_FRAGMENT = gql`
  fragment ContactFields on Contact {
    id
    name
    email
    contactRole
  }
`;

export { CONTACT_FRAGMENT };
