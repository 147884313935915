import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FiCheck } from 'react-icons/fi';

export const Sizes = {
  Regular: 'regular',
  Large: 'large',
};

const Checkbox = ({ size = Sizes.Regular, name, label, labelClass, value, isChecked, isDisabled, onChange }) => (
  <label
    className={clsx('tw-relative tw-m-0 tw-inline-flex tw-items-center tw-gap-2', {
      'tw-cursor-pointer': !isDisabled,
      'tw-cursor-not-allowed tw-opacity-30': isDisabled,
    })}
  >
    <input
      className='tw-peer tw-invisible tw-absolute tw-inset-0 tw-m-0 tw-h-full tw-w-full'
      type='checkbox'
      name={name}
      disabled={isDisabled}
      onChange={onChange}
      checked={isChecked}
      value={value}
    />

    <span
      className={clsx(
        'tw-inline-flex tw-items-center tw-justify-center',
        'tw-box-border tw-overflow-hidden tw-rounded-sm tw-text-white',
        'tw-border tw-border-solid tw-border-gray-500 tw-bg-white',
        'peer-checked:tw-border-teal-500 peer-checked:tw-bg-teal-500',
        'hover:tw-border-teal-500',
        {
          'tw-h-4 tw-w-4': size === Sizes.Regular,
          'tw-h-8 tw-w-8': size === Sizes.Large,
        }
      )}
    >
      <FiCheck
        className={clsx('tw-stroke-[3px]', {
          'tw-scale-75': size === Sizes.Regular,
          'tw-scale-125': size === Sizes.Large,
          'tw-hidden': !isChecked,
          'tw-block': isChecked,
        })}
      />
    </span>

    <span
      className={clsx('tw-mt-0.5 tw-text-sm tw-font-medium tw-leading-none tw-text-black empty:tw-hidden', labelClass)}
    >
      {label}
    </span>
  </label>
);

Checkbox.propTypes = {
  size: PropTypes.oneOf(Object.values(Sizes)),
  name: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
