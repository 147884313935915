import { gql, useQuery } from '@apollo/client';

const CURRENT_COMPANY_QUERY = gql`
  query CurrentCompanyQuery {
    currentUser {
      id
      dateFormat {
        label
        value
      }
    }
    contactRequests {
      id
      email
      createdAt
    }
    currentCompany {
      id
      slug
      name
      crn
      website
      taxId
      primaryForCurrentUser
      logoUrl
      createdAt
      address {
        address1
        address2
      }
      accessRoles {
        id
        name
        isDefault
        contacts {
          id
          name
          contactRole
          accessRoleId
          linkedinUrl
          phoneNumber
          avatarUrl
          status
          initials
          email
          companyPosition
          address
          createdAt
          daysSinceCreation
          lastActiveDate
          daysSinceActive
        }
        rolePermissions {
          id
          permitted
          permission {
            resource
            scope
            description
          }
        }
      }
    }
  }
`;

const useCurrentCompanyQuery = (options) => useQuery(CURRENT_COMPANY_QUERY, options);

export { CURRENT_COMPANY_QUERY, useCurrentCompanyQuery };
