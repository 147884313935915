import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const author = css`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 20px 20px 10px 20px;
`;

export const heading = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 12px;
  line-height: 1;
`;

export const name = css`
  ${fonts.semiBold}
  color: ${colors.black};
`;

export const timestamps = css`
  ${fonts.regular}
  color: ${colors.mediumGrey};
  width: 100%;
  padding-top: 5px;
`;
