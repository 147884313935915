/** @jsx jsx */

import React, { memo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Viewer } from './../Viewer';
import { Toolbar } from './../Toolbar';
import { Field } from './../Field';

import * as styles from './Configure.styles';

const Configure = memo(
  ({
    documentFields,
    changeDocumentFields,
    signatories,
    documentIsConfigured,
    documentURL,
    saveConfiguration,
    onContinueToSigning,
    fieldTypes,
    dateTypes,
    isSignature,
    discardChanges,
  }) => {
    const activeDocumentFields = documentFields.filter((documentField) => !documentField.deleted);

    const defaultDateFormat = '%d/%m/%Y';

    const documentFieldSize = {
      width: 21,
      height: 6.835,
    };

    const dateTypeOptions = Object.keys(dateTypes).map((key) => {
      return {
        value: key,
        label: dateTypes[key],
      };
    });

    let [activeSignatory, setActiveSignatory] = useState();
    let [documentLoaded, setDocumentLoaded] = useState(false);
    let [activeFieldType, setActiveFieldType] = useState(fieldTypes.signature);
    let [activeField, setActiveField] = useState();
    let [tempField, setTempField] = useState({
      page: 1,
      active: false,
      position: {
        x: 1000,
        y: 0,
      },
      disabled: true,
      format: defaultDateFormat,
      value: dateTypes[defaultDateFormat],
    });

    useEffect(() => populateTempField(), [activeFieldType, activeSignatory]);

    const populateTempField = () => {
      if (!activeFieldType && !activeSignatory) return;

      setTempField({
        ...tempField,
        type: activeFieldType,
        signatoryId: activeSignatory?.id,
        signatoryName: activeSignatory?.name,
      });
    };

    const markDocumentAsLoaded = () => {
      setDocumentLoaded(true);
    };

    const isActiveSignatory = (signatory) => {
      return activeSignatory && activeSignatory.id === signatory.id;
    };

    const isActiveFieldType = (name) => {
      return activeFieldType && activeFieldType === name;
    };

    const toggleActiveField = (field) => {
      setActiveFieldType(isActiveFieldType(field) ? null : field);

      setActiveField(null);
    };

    const toggleActiveSignatory = (signatory) => {
      if (isActiveSignatory(signatory)) {
        setActiveSignatory(null);
      } else {
        setActiveSignatory(signatory);
        setActiveFieldType(fieldTypes.signature);
      }

      setActiveField(null);
    };

    const updateDocumentField = (documentField, params) => {
      if (documentField.disabled) {
        return setTempField({ ...tempField, ...params });
      }

      const index = documentFields.findIndex((field) => field.id === documentField.id);
      const currentFields = [...documentFields];

      currentFields[index] = { ...documentField, ...params };

      changeDocumentFields(currentFields);

      if (!params.deleted) {
        setActiveField(currentFields[index]);
      }
    };

    const unsetControls = () => {
      setActiveField(null);
      setActiveSignatory(null);
      setActiveFieldType(null);
    };

    const removeDocumentField = (documentField) => {
      updateDocumentField(documentField, { deleted: true });
      unsetControls();
    };

    const updateFieldCoordinates = (documentField, coordinates) => {
      updateDocumentField(documentField, { position: coordinates });
    };

    const getRelativeCoordinates = (x, y, parent) => {
      return {
        y: ((y - parent.offset().top) * 100) / parent.height(),
        x: ((x - parent.offset().left) * 100) / parent.width(),
      };
    };

    const documentPage = ({ page }) => {
      return document.querySelector(`.canvas-page-wrapper[data-page='${page}']`);
    };

    const addDocumentField = (f, e) => {
      const canvas = documentPage(f);
      const documentFieldId = Math.random().toString(36).substr(2, 9);
      const width = $(canvas).width();
      const height = $(canvas).height();
      // const position = tempField.position
      const position = getRelativeCoordinates(e.clientX - 50, e.clientY - 20, $(canvas));

      if (position.x >= 100 - documentFieldSize.width) {
        position.x = 100 - documentFieldSize.width;
      }

      if (position.x <= 3000 / width) {
        position.x = 3000 / width;
      }

      if (position.y <= 2500 / height) {
        position.y = 2500 / height;
      }

      if (position.y > 100 - documentFieldSize.height) {
        position.y = 100 - documentFieldSize.height;
      }

      const documentField = {
        id: documentFieldId,
        signatoryId: activeSignatory.id,
        signatoryName: activeSignatory.name,
        type: activeFieldType,
        position: position,
        page: canvas.dataset.page,
        format: tempField.format,
        width: documentFieldSize.width,
        height: documentFieldSize.height,
        value: tempField.value,
      };

      changeDocumentFields([...documentFields, documentField]);
      setActiveField(documentField);
    };

    const handleViewportClick = (e) => {
      if (displayTempField) {
        addDocumentField(tempField, e);
      } else {
        setActiveField(null);
      }
    };

    const displayTempField = activeSignatory && activeFieldType && tempField.active && !activeField;

    const showTempField = () => {
      setTempField({ ...tempField, active: true });
    };

    const hideTempField = () => {
      setTempField({ ...tempField, active: false });
    };

    const moveTempField = (e) => {
      const canvas = e.target.closest('.canvas-page-wrapper');
      const width = $(canvas).width();
      const height = $(canvas).height();

      if (!displayTempField || !canvas) return;

      const position = getRelativeCoordinates(e.clientX - 50, e.clientY - 20, $(canvas));

      if (position.x >= 100 - documentFieldSize.width) {
        position.x = 100 - documentFieldSize.width;
      }

      if (position.x <= 3000 / width) {
        position.x = 3000 / width;
      }

      if (position.y <= 2500 / height) {
        position.y = 2500 / height;
      }

      if (position.y > 100 - documentFieldSize.height) {
        position.y = 100 - documentFieldSize.height;
      }

      setTempField({
        ...tempField,
        page: canvas.dataset.page,
        position: position,
      });
    };

    const selectDocumentField = (documentField) => {
      const signatory = signatories.find((signatory) => signatory.id === documentField.signatoryId);

      setActiveField(documentField);
      setActiveSignatory(signatory);
      setActiveFieldType(documentField.type);
    };

    const unsetControlsAndSaveConfiguration = () => {
      unsetControls();
      saveConfiguration();
    };

    const unsetControlsAndContinueToSigning = () => {
      unsetControls();
      onContinueToSigning();
    };

    const unsetControlsAndDiscardChanges = () => {
      unsetControls();
      discardChanges();
    };

    const documentFieldComponents =
      documentLoaded &&
      activeDocumentFields.map((documentField) => {
        const removeField = removeDocumentField.bind(this, documentField);
        const isSelected = activeField?.id === documentField.id;
        const signatureField = isSignature(documentField);
        const page = documentPage(documentField);

        return (
          <Field
            key={documentField.id}
            field={documentField}
            removeField={removeField}
            size={documentFieldSize}
            updateField={updateFieldCoordinates}
            selectField={selectDocumentField}
            position={documentField.position}
            page={page}
            isSelected={isSelected}
            isSignature={signatureField}
          />
        );
      });

    return (
      <div css={styles.wrapper}>
        <div css={styles.viewer}>
          <Viewer
            documentURL={documentURL}
            onDocumentLoad={markDocumentAsLoaded}
            onViewportMouseMove={moveTempField}
            onViewportMouseClick={handleViewportClick}
            onViewportMouseEnter={showTempField}
            onViewportMouseLeave={hideTempField}
            pointerMode={displayTempField}
            viewportStyles={styles.viewport}
          />
        </div>

        {displayTempField && (
          <Field
            field={tempField}
            page={documentPage(tempField)}
            size={documentFieldSize}
            position={tempField.position}
            isSignature={isSignature({ type: activeFieldType })}
          />
        )}

        {documentFieldComponents}

        <div css={styles.toolbar}>
          <Toolbar
            signatories={signatories}
            toggleSignatory={toggleActiveSignatory}
            currentSignatory={activeSignatory}
            isSelectedSignatory={isActiveSignatory}
            saveConfiguration={unsetControlsAndSaveConfiguration}
            documentIsConfigured={documentIsConfigured}
            onContinueToSigning={unsetControlsAndContinueToSigning}
            activeFieldType={activeFieldType}
            isActiveFieldType={isActiveFieldType}
            activeField={activeField || tempField}
            toggleActiveField={toggleActiveField}
            fieldTypes={fieldTypes}
            dateTypeOptions={dateTypeOptions}
            onDateTypeChange={updateDocumentField}
            defaultDateFormat={defaultDateFormat}
            discardChanges={unsetControlsAndDiscardChanges}
          />
        </div>
      </div>
    );
  }
);

export { Configure };
