import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import uniqueId from 'lodash/uniqueId';
import Button from './../Button';
import { withIntl } from 'utils/v2/intl';
import { useIntl, FormattedMessage } from 'react-intl';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

const Pagination = ({
  hasNextPage = false,
  hasPreviousPage = false,
  endCursor,
  startCursor,
  perPage = 10,
  onChange = () => {},
}) => {
  const intl = useIntl();
  const handleClick = (args) => () => onChange({ variables: args });

  return (
    <div className='tw-flex tw-items-center tw-justify-center tw-gap-1'>
      <Button
        font='medium'
        variant='transparent'
        label={intl.formatMessage({ id: 'Pagination.first' })}
        isDisabled={!hasPreviousPage}
        onClick={handleClick({
          first: perPage,
          last: null,
          before: null,
          after: null,
        })}
      >
        <FiChevronsLeft className='tw-h-4 tw-w-4 tw-shrink-0' />
      </Button>

      <Button
        font='medium'
        variant='lavander'
        className='tw-w-22'
        isDisabled={!hasPreviousPage}
        onClick={handleClick({
          first: perPage,
          last: null,
          before: startCursor,
          after: null,
        })}
      >
        <FormattedMessage id='Pagination.previous' />
      </Button>

      <Button
        font='medium'
        variant='lavander'
        className='tw-w-22'
        isDisabled={!hasNextPage}
        onClick={handleClick({
          first: perPage,
          last: null,
          before: null,
          after: endCursor,
        })}
      >
        <FormattedMessage id='Pagination.next' />
      </Button>

      <Button
        font='medium'
        variant='transparent'
        isDisabled={!hasNextPage}
        label={intl.formatMessage({ id: 'Pagination.last' })}
        onClick={handleClick({
          first: null,
          last: perPage,
          before: null,
          after: null,
        })}
      >
        <FiChevronsRight className='tw-h-4 tw-w-4 tw-shrink-0' />
      </Button>
    </div>
  );
};

Pagination.propTypes = {
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  endCursor: PropTypes.string,
  startCursor: PropTypes.string,
  perPage: PropTypes.number,
  onChange: PropTypes.func,
};

export default withIntl(Pagination);
