import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import BaseOption from '../BaseOption';
import Avatar from 'components/v2/shared/Avatar';

const ContactOption = ({ item, ...props }) => (
  <BaseOption {...props} className='tw-items-center'>
    <Avatar placeholder={item.initials} className='tw-mr-5' />

    <div className='tw-flex tw-flex-col'>
      <div className='tw-text-sm'>{item.label}</div>
      <div className='tw-text-xs tw-leading-none tw-text-gray-700'>{item.companyPosition}</div>
    </div>
  </BaseOption>
);

ContactOption.propTypes = {
  ...BaseOption.propTypes,
  item: PropTypes.object,
};

export default ContactOption;
