import React, { memo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { client } from 'utils/apollo';
import { ResolutionDocumentViewerList } from 'components/votes/ResolutionDocumentViewerList';
import { TranslationContextProvider } from '../../../contexts/v2/TranslationContext';
import FileViewer from 'components/v2/shared/FileViewer';
import { useFileViewer } from '../../documents/hooks/useFileViewer';
import AppSignalWrapper from '../../v2/shared/AppSignalWrapper';

export default memo(({ resolutionId }) => {
  const previewDocument = (document) => openFileViewer(document);

  const { fileViewerContext, openFileViewer, closeFileViewer } = useFileViewer();

  return (
    <AppSignalWrapper tags={{ page: 'votes/showDocuments' }}>
      <TranslationContextProvider>
        <ApolloProvider client={client}>
          <ResolutionDocumentViewerList resolutionId={resolutionId} previewDocument={previewDocument} />

          <FileViewer
            fileName={fileViewerContext.file?.name}
            fileUrl={fileViewerContext.file?.url}
            isOpen={fileViewerContext.show}
            onClose={closeFileViewer}
          />
        </ApolloProvider>
      </TranslationContextProvider>
    </AppSignalWrapper>
  );
});
