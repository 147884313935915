import React from 'react';
import styled from 'styled-components';
import { fonts, colors } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  height: 21px;
  font-size: 10px;
  line-height: 35px;
  ${fonts.styledSemiBold}
`;

export const DayMonth = styled.div`
  background: ${colors.cyan};
  color: ${colors.white};
  padding: 2px 12px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const Time = styled.div`
  background: #00bac610;
  color: ${colors.cyan};
  padding: 2px 12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const CalendarIcon = ({ time, day, month }) => {
  return (
    <Wrapper>
      <DayMonth className='d-flex align-items-center'>
        {day} {month}{' '}
      </DayMonth>
      <Time className='d-flex align-items-center'>{time}</Time>
    </Wrapper>
  );
};
