import { createStore } from './createStore';

import { deleteById, updateById } from 'utils';
import { getAttributes } from 'helpers/getAttributes';
import { trackPromise } from 'react-promise-tracker';

import axios from 'axios';

const initialState = {
  documents: [],
};

const actionTypes = {
  SET: 'documents.set',
  ADD: 'documents.add',
  UPDATE: 'documents.update',
  DELETE: 'documents.remove',
};

const companyId = getCompanySlug();

const api = {
  createDocument: (meetingId, agendaId, document, onUploadProgress) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/agendas/${agendaId}/documents.json`;
    const payload = new FormData();
    const config = { onUploadProgress };

    payload.append('document', document);

    return axios
      .post(apiURL, payload, config)
      .then((response) => response.data)
      .then(getAttributes);
  },

  updateDocument: (meetingId, agendaId, documentId, params) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/agendas/${agendaId}/documents/${documentId}.json`;
    const payload = { document: params };

    const promise = axios
      .put(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  deleteDocument: (meetingId, agendaId, documentId) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/agendas/${agendaId}/documents/${documentId}.json`;
    const promise = axios
      .delete(apiURL)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  attachAgendaDocuments: (documents, agendaId, meetingId, onSubmit) => {
    const promise = axios
      .post(`/api/${getCompanySlug()}/v1/meetings/${meetingId}/agendas/${agendaId}/documents/attach.json`, {
        agenda: { documents: documents },
      })
      .then((response) => {
        onSubmit();
        return response.data;
      })
      .then(getAttributes);

    return trackPromise(promise);
  },
};

const useDocumentsActions = () => {
  const dispatch = useDocumentsDispatch();

  const createDocument = (meetingId, agendaId, document, onProgress) => {
    return api.createDocument(meetingId, agendaId, document, onProgress).then((documents) => {
      return dispatch({ type: actionTypes.SET, payload: documents });
    });
  };

  const updateDocument = (meetingId, agendaId, documentId, params) => {
    return api.updateDocument(meetingId, agendaId, documentId, params).then((documents) => {
      dispatch({ type: actionTypes.SET, payload: documents });
    });
  };

  const deleteDocument = (meetingId, agendaId, documentId) => {
    return api.deleteDocument(meetingId, agendaId, documentId).then((documents) => {
      dispatch({ type: actionTypes.SET, payload: documents });
    });
  };

  const attachAgendaDocuments = (documents, agendaId, meetingId, onSubmit) => {
    return api.attachAgendaDocuments(documents, agendaId, meetingId, onSubmit).then((documents) => {
      dispatch({ type: actionTypes.SET, payload: documents });
    });
  };

  const setDocuments = (documents) => {
    dispatch({ type: actionTypes.SET, payload: documents });
  };

  return {
    setDocuments,
    createDocument,
    updateDocument,
    deleteDocument,
    attachAgendaDocuments,
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET:
      return { documents: payload };
    case actionTypes.ADD:
      return { documents: [...state.documents, payload] };
    case actionTypes.UPDATE:
      return { documents: updateById(state.documents, payload) };
    case actionTypes.DELETE:
      return { documents: deleteById(state.documents, payload) };
  }
};

const [DocumentsProvider, useDocumentsStore, useDocumentsDispatch] = createStore(reducer, initialState);

export { DocumentsProvider, useDocumentsStore, useDocumentsActions };
