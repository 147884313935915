import { CREATE_DOCUMENT_MUTATION, useCreateDocumentMutation } from './../data/mutations/CreateDocumentMutation';
import { useApolloCacheHelpers } from './../hooks/useApolloCacheHelpers';
import { formatBytes } from 'helpers/formatBytes';
import { DirectUpload } from '@rails/activestorage';

const MAX_SIZE = 104857600;

const useCreateDocument = ({ onUpload, onError }) => {
  const {
    addDirectoryToCache,
    addDocumentToCache,
    evictRecentDocumentsCache,
    evictRecentDirectoriesCache,
    evictSearchDocumentsCache,
    evictSearchDirectoriesCache,
  } = useApolloCacheHelpers();

  const update = (cache, { data: { createDocument } }) => {
    const { document, errors } = createDocument;

    if (errors.length) {
      return onError(errors[0].message);
    }

    const { breadcrumbs } = document;

    breadcrumbs.map((directory, index) => {
      if (breadcrumbs[index + 1]) {
        addDirectoryToCache(directory, { ...breadcrumbs[index + 1], __typename: 'Directory' });
      } else {
        addDocumentToCache(directory, { ...document, __typename: 'Document' });
      }
    });

    evictRecentDocumentsCache();
    evictRecentDirectoriesCache();
    evictSearchDirectoriesCache();
    evictSearchDocumentsCache();

    onUpload();
  };

  const [mutate] = useCreateDocumentMutation({ update });

  const validate = (attachment) => {
    if (attachment.size > MAX_SIZE) {
      onError(`File size should be less than ${formatBytes(MAX_SIZE, 0)}`);
      return false;
    }

    return true;
  };

  const createDocument = (params, updateCurrentUpload) => {
    const isValid = validate(params.attachment);

    if (!isValid) return;

    const uploadConfig = {
      directUploadWillStoreFileWithXHR: (xhr) => {
        updateCurrentUpload({ xhr });

        xhr.upload.addEventListener('progress', (event) => {
          const progress = Math.round((event.loaded * 100) / event.total);
          updateCurrentUpload({ progress });
        });
      },
    };

    const upload = new DirectUpload(params.attachment, '/rails/active_storage/direct_uploads', uploadConfig);

    upload.create((error, blob) => {
      if (error) {
        return onError(error);
      }

      const input = {
        directoryId: params.directoryId,
        path: params.path,
        signedId: blob.signed_id,
      };

      mutate({ variables: { input } });
    });
  };

  return { createDocument };
};

export { useCreateDocument };
