import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const drawer = (isOpen) => css`
  z-index: var(--max-z-index);
  transform: none;
  overflow-x: hidden;
  transition: none;
  width: 450px;
  box-shadow: -20px 4px 50px rgba(54, 71, 98, 0.25);
  animation: ${isOpen ? 'slideIn' : 'slideOut'} 0.2s ease-out forwards;

  @keyframes slideOut {
    0% {
      transform: translate3d(0, 0, 0);
    }

    100% {
      transform: translate3d(460px, 0, 0);
    }
  }

  @keyframes slideIn {
    0% {
      transform: translate3d(460px, 0, 0);
    }

    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @media (max-width: 767px) {
    animation-duration: 0s;

    .drawer__close {
      display: none;
    }
  }

  .custom-switch {
    padding-left: 0;
  }

  .custom-control.shaparency-switch .custom-control-label::after {
    left: 2px !important;
  }
`;

export const wrapper = css`
  padding: 21px 35px;
  height: calc(100vh - 140px);

  @media (max-width: 767px) {
    padding: 0;
    height: calc(100% - 140px);
  }
`;

export const content = (isDisabled) => css`
  width: calc(100% + 80px);
  margin: 30px 0 15px -45px;
  overflow: auto;
  border-bottom: 1px solid ${colors.headingGrey};
  border-top: 1px solid ${colors.headingGrey};
  height: ${isDisabled ? 'calc(100vh - 140px)' : 'calc(100vh - 200px)'};

  @media (max-width: 767px) {
    width: 100%;
    margin: 20px 0 0 0;
    height: ${isDisabled ? '100%' : 'calc(100% - 64px)'};
  }
`;

export const input = css`
  @media (max-width: 767px) {
    padding: 0 15px;
  }
`;

export const header = css`
  ${fonts.bold}
  color: ${colors.black};
  text-align: left;
  font-size: 18px;

  @media (max-width: 767px) {
    display: none;
  }
`;
