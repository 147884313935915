import { useCreateContactGroupMutation } from './../data/mutations/CreateContactGroupMutation';
import { CONTACT_GROUP_FRAGMENT } from './../data/fragments/ContactGroupFragment';

const useCreateContactGroup = ({ onCompleted, onError, companyId }) => {
  const update = (cache, { data: { createContactGroup } }) => {
    const { contactGroup, errors } = createContactGroup;

    if (errors && errors.length) {
      onError(errors);
    } else {
      cache.modify({
        id: cache.identify({ __typename: 'Company', id: companyId }),
        fields: {
          contactGroups(existing = [], { readField }) {
            const isPresent = existing.find((item) => readField('id', item) === contactGroup.id);

            if (isPresent) {
              return existing;
            }

            return [
              ...existing,
              cache.writeFragment({
                data: contactGroup,
                fragment: CONTACT_GROUP_FRAGMENT,
                fragmentName: 'ContactGroupFields',
              }),
            ];
          },
        },
      });

      onCompleted(contactGroup);
    }
  };

  const [mutate] = useCreateContactGroupMutation({ update });

  const createContactGroup = ({ name, contactIds }) => {
    mutate({ variables: { input: { name, contactIds } } })
      .catch((error) => {
        onError([{ message: error.message }]);
      });
  };

  return { createContactGroup };
};

export { useCreateContactGroup };
