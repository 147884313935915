import React, { useState, useEffect } from 'react';
import Button from 'components/v2/shared/Button';
import Heading from 'components/v2/shared/Heading';
import Inclusions from './Inclusions';
import PurchasePlan from './PurchasePlan';

const planDetails = ({ data }) => {
  const [isActiveInclusions, setActiveInclusions] = useState(false);
  const [isActivePurchasePlan, setActivePurchasePlan] = useState(false);
  const [isInviteUsers, setIsInviteUsers] = useState(false);

  const handleOnClickInclusions = () => {
    setActiveInclusions(true);
    setActivePurchasePlan(false);
  };
  const handleOnClickPurchasePlan = () => {
    setActivePurchasePlan(true);
    setActiveInclusions(false);
  };
  const cancelPurchasePlan = () => {
    setActivePurchasePlan(false);
  };
  const completePayment = () => {
    setIsInviteUsers(true);
  };

  const [planData] = useState([
    {
      title: 'Board portal',
    },
    {
      title: 'Meeting space',
    },
    {
      title: 'Calendar integration',
    },
    {
      title: 'Document storage',
    },
    {
      title: 'Minute taking',
    },
    {
      title: 'Voting',
    },
    {
      title: 'Taskboard',
    },
    {
      title: 'Electronic signature tool',
    },
    {
      title: 'Chat',
    },
    {
      title: 'Contacts & groups',
    },
    {
      title: 'Multi-company access',
    },
  ]);
  useEffect(() => {
    setActiveInclusions(true);
  }, [planData]);

  return (
    <>
      <div className='tw-mt-6 tw-w-full xl:tw-mt-0'>
        <div className='tw-hidden tw-items-center tw-justify-between sm:tw-block sm:tw-flex'>
          <Heading text={data.title} className='!tw-mb-1' />
        </div>
        <div className='tw-mt-5 tw-hidden tw-items-center tw-justify-between tw-font-sans tw-text-sm tw-font-medium	tw-text-black	sm:tw-block sm:tw-flex'>
          ${data.amount} AUD / month
        </div>
      </div>
      <div className='tw-my-4 tw-mt-7 tw-grid tw-grid-flow-col tw-grid-cols-2 tw-gap-2 tw-py-3'>
        <div className='tw-col-span-1'>
          <Button
            font='semibold'
            isFullWidth
            onClick={handleOnClickInclusions}
            size='small'
            variant={isActiveInclusions ? 'aqua' : 'lavander'}
            className='!tw-font-sans !tw-text-xs'
          >
            Inclusions
          </Button>
        </div>

        <div className='tw-col-span-1'>
          <Button
            font='semibold'
            className='tw-cursor-pointer !tw-font-sans !tw-text-xs !tw-font-semibold'
            isFullWidth
            size='small'
            onClick={handleOnClickPurchasePlan}
            variant={isActiveInclusions ? 'lavander' : 'aqua'}
          >
            Purchase plan
          </Button>
        </div>
      </div>
      <div className='tw-w-full'>{isActiveInclusions && <Inclusions planData={planData} />}</div>
      {isActivePurchasePlan && <PurchasePlan />}
      {isActivePurchasePlan && (
        <>
          <div className='tw-mt-5'>
            <Button
              font='semibold'
              className='tw-h-11 !tw-text-base'
              isFullWidth
              onClick={completePayment}
              variant='teal'
            >
              Complete payment
            </Button>
          </div>
          <div className='tw-my-4'>
            <Button
              font='medium'
              className='!tw-text-sm tw-font-medium'
              onClick={cancelPurchasePlan}
              isFullWidth
              variant='transparent'
            >
              Cancel purchase
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default planDetails;
