/** @jsx jsx */

import React, { memo, useRef } from 'react';
import { jsx } from '@emotion/core';

import { SidebarItem } from './../SidebarItem';

import { ReactComponent as RecentsIcon } from 'images/icons/documents/recents.svg';
import { ReactComponent as FavouritesIcon } from 'images/icons/documents/favourites.svg';
import { ReactComponent as SmartSignIcon } from 'images/icons/documents/smart-sign.svg';
import { ReactComponent as CompanyIcon } from 'images/icons/documents/company.svg';
import { ReactComponent as PersonalIcon } from 'images/icons/documents/personal.svg';
import { ReactComponent as MeetingIcon } from 'images/icons/documents/meeting.svg';
import { ReactComponent as SharedWithMeIcon } from 'images/icons/documents/shared-with-me.svg';

import { SHARED, RECENTS, FAVOURITES, VIRTUAL_SIDEBAR_ITEMS } from './../data/globals/VirtualSidebarItems';

import * as styles from './Sidebar.styles';

const Sidebar = memo(({ items, selectedItemId, onClick }) => {
  const iconMap = {
    'Personal Folders': <PersonalIcon />,
    'Company Folders': <CompanyIcon />,
    SmartSign: <SmartSignIcon />,
    'Meeting Folders': <MeetingIcon />,
    [RECENTS.name]: <RecentsIcon />,
    [FAVOURITES.name]: <FavouritesIcon />,
    [SHARED.name]: <SharedWithMeIcon />,
  };

  const getItemProps = (item) => {
    const { id, name } = item;

    const key = `sidebar-item-${id}`;
    const icon = iconMap[name];
    const isSelected = item.id === selectedItemId;
    const handleClick = onClick.bind(this, item.id);

    return {
      id,
      name,
      key,
      icon,
      isSelected,
      onClick: handleClick,
    };
  };

  const Items = memo(({ options }) => {
    if (!options) {
      return null;
    }

    return options.map((item) => {
      return <SidebarItem {...getItemProps(item)} />;
    });
  });

  const { current: virtualItems } = useRef(VIRTUAL_SIDEBAR_ITEMS);

  return (
    <div css={styles.sidebar} className='document-sidebar'>
      <Items options={virtualItems} />
      <div css={styles.separator} />
      <Items options={items} />
    </div>
  );
});

export { Sidebar };
