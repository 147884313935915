import { css } from '@emotion/core';
import { colors, fonts } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const viewer = {
  base: css`
    opacity: 1;
    width: 100%;
    height: 100%;

    *::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  `,

  hidden: css`
    opacity: 0;
  `,
};

export const container = {
  base: css`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    right: 0;
    display: flex;
    z-index: 9999;
    flex-direction: column;

    &:after {
      background: rgba(0, 0, 0, 0.8);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      height: 100%;
    }
  `,

  visible: css`
    pointer-events: all;
    opacity: 1;
  `,
};

export const bar = css`
  padding: 13px 25px;
  display: flex;
  align-items: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 50%, transparent 100%);
`;

export const spinner = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
`;
