import { css } from '@emotion/core';
import { colors, fonts } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const overlay = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  right: 0;
  background: ${HexToRgba(colors.white, 70)};
`;

export const comment = {
  base: css`
    position: relative;
    padding-bottom: 16px;
    background: ${colors.white};
    font-size: 12px;
    z-index: 1;
    outline: 1px solid ${colors.lighterGrey};
  `,

  recent: css`
    background: ${colors.lightYellow};
  `,

  root: css`
    background: ${colors.lighterCyan};
    z-index: 2;
    padding-bottom: 20px;
  `,
};

export const replyCount = {
  thread: css`
    position: absolute;
    bottom: 0;
    color: ${colors.mediumGrey};
    background: white;
    border-radius: 70px;
    border: 1px solid ${colors.lighterGrey};
    transform: translateY(50%);
    left: 16px;
    z-index: 3;
    font-size: 10px;
    padding: 5px 10px;
    line-height: 1;
    background: ${colors.white};
  `,

  list: css`
    font-size: 10px;
    color: ${colors.mediumGrey};
    border-bottom: 1px dashed ${colors.mediumGrey};
    position: absolute;
    right: 20px;
    z-index: 3;
    line-height: 16px;

    &:hover {
      cursor: pointer;
    }
  `,
};

export const footer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 0 20px;
  position: relative;

  &:empty {
    padding: 0;
  }
`;

export const reply = {
  button: css`
    line-height: 1;
    display: flex;
    align-items: center;

    div:hover {
      cursor: pointer;
      svg {
        path {
          fill: ${colors.cyan};
        }
      }
    }
  `,

  disabledButton: css`
    line-height: 1;
    display: flex;
    align-items: center;

    div:hover {
      cursor: default;
    }
  `,

  text: css`
    ${fonts.bold}
    color: ${colors.cyan};
    padding-right: 5px;
  `,

  disabledText: css`
    ${fonts.bold}
    color: ${colors.lighterGrey};
    padding-right: 5px;
  `,
};

export const badge = css`
  position: absolute;
  border-radius: 50%;
  cursor: pointer;
  background: ${colors.cyan};
  width: 10px;
  height: 10px;
  top: 3px;
  right: -12px;
  border: 2px solid ${colors.white};
`;
