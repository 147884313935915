/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import ClipLoader from 'react-spinners/ClipLoader';

import * as styles from './Panel.styles';
import { colors } from 'styles';

const Panel = memo(({ heading, children, viewMoreLink, loading }) => {
  const viewMore = viewMoreLink && (
    <a href={viewMoreLink} css={styles.viewMore}>
      <FormattedMessage id='Panel.viewMore' />
    </a>
  );

  return (
    <div css={styles.wrapper}>
      <div css={styles.heading}>
        {heading}
        {viewMore}
      </div>

      {children}

      <div css={styles.spinner}>
        <ClipLoader color={colors.cyan} loading={!!loading} />
      </div>
    </div>
  );
});

export { Panel };
