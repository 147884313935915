import { gql, useMutation } from '@apollo/client';

const APPROVE_CONTACT_MUTATION = gql`
  mutation ApproveContact($input: ApproveContactInput!) {
    approveContact(input: $input) {
      contact {
        name
        status
      }
    }
  }
`;

const useApproveContactMutation = (options) => useMutation(APPROVE_CONTACT_MUTATION, options);

export { APPROVE_CONTACT_MUTATION, useApproveContactMutation };
