import React, { useState } from 'react';

const ProductChangeForm = ({
  subscriptionId,
  selectedProduct,
  newProduct,
  setShowChangeProductForm,
  setSelectedProduct,
  company,
}) => {
  const getConfig = () => {
    return {
      'X-CSRF-Token': $("[name='csrf-token']").attr('content'),
    };
  };

  const confirmProductChange = () => {
    let payload = { product_id: newProduct.id };

    return $.ajax({
      url: `/${company.slug}/subscriptions/${subscriptionId}`,
      method: 'put',
      data: payload,
      headers: getConfig(),
    })
      .then((response) => {
        return response;
      })
      .then((result) => {
        setSelectedProduct(newProduct);
        setShowChangeProductForm(false);
      });
  };

  const cancelProductChange = () => {
    setShowChangeProductForm(false);
  };

  return (
    <div className='flex justify-center'>
      {newProduct !== selectedProduct ? (
        <div className='w-full rounded overflow-hidden border rounded-md p-4 mb-4'>
          <div className='flex justify-between text-gray-600 text-m'>
            <div>Current price</div>
            <div className='font-bold text-m'>{selectedProduct.name}</div>
          </div>

          <div className='flex justify-between text-gray-600 text-m'>
            <div>New price</div>
            <div className='font-bold text-m'>{newProduct.name}</div>
          </div>

          <div>
            <button onClick={() => confirmProductChange()} type='submit'>
              <div className='w-auto -mx-2 md:mx-0'>
                <span>Confirm change</span>
              </div>
            </button>
            <button onClick={() => cancelProductChange()} type='submit'>
              <div className='w-auto -mx-2 md:mx-0'>
                <span>Cancel</span>
              </div>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductChangeForm;
