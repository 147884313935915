import { gql, useMutation } from '@apollo/client';

const REINVITE_CONTACT_MUTATION = gql`
  mutation ReinviteContact($input: ReinviteContactInput!) {
    reinviteContact(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`;

const useReinviteContactMutation = (options) => useMutation(REINVITE_CONTACT_MUTATION, options);

export { REINVITE_CONTACT_MUTATION, useReinviteContactMutation };
