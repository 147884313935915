import React, { useState } from 'react';
import Heading from 'components/v2/shared/Heading/';
import Checkbox from 'components/v2/shared/Checkbox';
import Button from 'components/v2/shared/Button';
import { intl } from 'contexts/v2/TranslationContext';
import { FormattedMessage } from 'react-intl';
import toast, { Toaster } from 'react-hot-toast';
import Alert from 'components/v2/shared/Alert';
import { wrapMutation } from 'utils/v2/gql';
import { useUpdateProfileEmailPreferencesMutation } from 'data/v2/mutations/UpdateProfileEmailPreferencesMutation';

const EmailPreferences = ({ mailConfig }) => {
  const [updateProfileEmailPreferences] = useUpdateProfileEmailPreferencesMutation();
  const [formData, setFormData] = useState(mailConfig);

  const handleChange = ({ target: { name } }) => {
    setFormData((prev) => ({ ...prev, [name]: !formData[name] }));
  };

  const handleSubmit = () => {
    const { __typename, ...input } = formData;

    wrapMutation(
      updateProfileEmailPreferences,
      {
        variables: {
          input,
        },
      },
      'updateProfileEmailPreferences'
    )
      .then(() => {
        successToast();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const successToast = () => {
    toast.custom(
      <Alert
        description='Email preferences updated'
        isDismissable
        onDismiss={() => toast.remove()}
        severity='success'
      />,
      {
        duration: 5000,
        position: 'top-right',
        className: '!tw-p-0 !tw-m-0',
        ariaProps: {
          className: '!tw-p-0 !tw-m-0',
        },
      }
    );
  };

  return (
    <div>
      <div className='tw-hidden sm:tw-block'>
        <Heading text='Email preferences' className='tw-mb-3.5' />
      </div>

      <div className='tw-mt-8'>
        <div className='tw-pb-5'>
          <p className='tw-font-sans tw-font-semibold tw-text-black'>
            <FormattedMessage id='Profile.EmailPreferences.votes' />
          </p>

          <div className='tw-grid tw-grid-flow-row tw-gap-4'>
            <Checkbox
              variant='noHover'
              name='voteCancelled'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.voteCancelled' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.voteCancelled}
            />

            <Checkbox
              variant='noHover'
              name='voteOpened'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.voteOpened' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.voteOpened}
            />

            <Checkbox
              variant='noHover'
              name='votePublished'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.votePublished' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.votePublished}
            />

            <Checkbox
              variant='noHover'
              name='voteUpdated'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.voteUpdated' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.voteUpdated}
            />
          </div>
        </div>

        <div className='tw-py-5'>
          <p className='tw-font-sans tw-font-semibold tw-text-black'>
            <FormattedMessage id='Profile.EmailPreferences.tasks' />
          </p>

          <div className='tw-grid tw-grid-flow-row tw-gap-4'>
            <Checkbox
              variant='noHover'
              name='taskAssigned'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.taskAssigned' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.taskAssigned}
            />

            <Checkbox
              variant='noHover'
              name='taskReminder'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.taskReminder' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.taskReminder}
            />

            <Checkbox
              variant='noHover'
              name='taskUpdated'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.taskUpdated' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.taskUpdated}
            />
          </div>
        </div>

        <div className='tw-py-5'>
          <p className='tw-font-sans tw-font-semibold tw-text-black'>
            <FormattedMessage id='Profile.EmailPreferences.docsAndDirs' />
          </p>

          <div className='tw-grid tw-grid-flow-row tw-gap-4'>
            <Checkbox
              variant='noHover'
              name='itemShared'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.itemShared' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.itemShared}
            />
          </div>
        </div>

        <div className='tw-py-5'>
          <p className='tw-font-sans tw-font-semibold tw-text-black'>
            <FormattedMessage id='Profile.EmailPreferences.minutes' />
          </p>

          <div className='tw-grid tw-grid-flow-row tw-gap-4'>
            <Checkbox
              variant='noHover'
              name='minuteReviewRequested'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.minuteReviewRequested' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.minuteReviewRequested}
            />

            <Checkbox
              variant='noHover'
              name='minuteApproved'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.minuteApproved' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.minuteApproved}
            />

            <Checkbox
              variant='noHover'
              name='minuteCommentReceived'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.minuteCommentReceived' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.minuteCommentReceived}
            />
          </div>
        </div>

        <div className='tw-py-5'>
          <p className='tw-font-sans tw-font-semibold tw-text-black'>
            <FormattedMessage id='Profile.EmailPreferences.meetings' />
          </p>

          <div className='tw-grid tw-grid-flow-row tw-gap-4'>
            <Checkbox
              variant='noHover'
              name='meetingRsvp'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.meetingRsvp' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.meetingRsvp}
            />

            <Checkbox
              variant='noHover'
              name='meetingNotice'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.meetingNotice' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.meetingNotice}
            />

            <Checkbox
              variant='noHover'
              name='meetingDocumentsPublished'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.meetingDocumentsPublished' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.meetingDocumentsPublished}
            />

            <Checkbox
              variant='noHover'
              name='meetingAttendeeRemoved'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.meetingAttendeeRemoved' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.meetingAttendeeRemoved}
            />

            <Checkbox
              variant='noHover'
              name='meetingInvite'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.meetingInvite' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.meetingInvite}
            />

            <Checkbox
              variant='noHover'
              name='meetingUpdated'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.meetingUpdated' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.meetingUpdated}
            />

            <Checkbox
              variant='noHover'
              name='meetingCancelled'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.meetingCancelled' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.meetingCancelled}
            />

            <Checkbox
              variant='noHover'
              name='meetingStarted'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.meetingStarted' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.meetingStarted}
            />

            <Checkbox
              variant='noHover'
              name='meetingAssignedAgenda'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.meetingAssignedAgenda' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.meetingAssignedAgenda}
            />
          </div>
        </div>

        <div className='tw-py-5'>
          <p className='tw-font-sans tw-font-semibold tw-text-black'>
            <FormattedMessage id='Profile.EmailPreferences.chat' />
          </p>

          <div className='tw-grid tw-grid-flow-row tw-gap-4'>
            <Checkbox
              variant='noHover'
              name='newChatMessage'
              label={intl.formatMessage({ id: 'Profile.EmailPreferences.newChatMessage' })}
              size='regular'
              onChange={handleChange}
              isChecked={formData.newChatMessage}
            />
          </div>
        </div>

        <Toaster />

        <div className='tw-mt-5'>
          <div className='tw-text-center'>
            <Button font='semibold' isFullWidth size='large' variant='teal' onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailPreferences;
