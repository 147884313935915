import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import MeetingForm from 'components/v2/meetings/MeetingForm';
import { CreateMeetingContextProvider } from 'contexts/v2/CreateMeetingContext';

export default () => (
  <CreateMeetingContextProvider>
    <Container>
      <Row className='justify-content-center'>
        <Col lg={10}>
          <MeetingForm />
        </Col>
      </Row>
    </Container>
  </CreateMeetingContextProvider>
);
