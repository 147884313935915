import { useState } from 'react';
import { useCreateDirectoryMutation } from './../data/mutations/CreateDirectoryMutation';
import { useApolloCacheHelpers } from './../hooks/useApolloCacheHelpers';

const useCreateDirectory = ({ onCompleted }) => {
  const { evictRecentDocumentsCache, evictSearchDocumentsCache, addDirectoryToCache } = useApolloCacheHelpers();

  const update = (cache, { data: { createDirectory } }) => {
    const { directory, errors } = createDirectory;

    if (errors.length) {
      return setError(errors[0].message);
    }

    const { breadcrumbs } = directory;

    const parent = breadcrumbs[breadcrumbs.length - 2];

    addDirectoryToCache(parent, directory);
    evictRecentDocumentsCache();
    evictSearchDocumentsCache();
    onCompleted();
  };

  const [error, setError] = useState(null);
  const [mutate, { loading: isCreating }] = useCreateDirectoryMutation({ update });

  const resetError = () => setError(null);

  const createDirectory = (name, parentId) => {
    mutate({ variables: { input: { name, parentId } } });
  };

  return {
    error,
    resetError,
    createDirectory,
    isCreating,
  };
};

export { useCreateDirectory };
