/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import ClipLoader from 'react-spinners/ClipLoader';
import Dropzone from 'react-dropzone';

import { SearchResults } from './../SearchResults';
import { DirectoriesArea } from './../DirectoriesArea';
import { DocumentsArea } from './../DocumentsArea';

import { useUploadQueueActions } from 'store/uploadQueueStoreContext';
import { ReactComponent as UploadIcon } from 'images/icons/documents/dropzone-icon.svg';

import { DateFormatContextProvider } from '../../../contexts/v2/DateFormatContext';

import { colors } from 'styles';
import * as styles from './ContentArea.styles';

const ContentArea = memo(
  ({
    isLoading,
    isSearching,
    searchTerm,
    currentDirectory,
    onShareClick,
    onMoveClick,
    onViewDocumentHistoryClick,
    onDocumentClick,
    onDirectoryClick,
    onDirectoryDownload,
  }) => {
    const { addFilesToQueue } = useUploadQueueActions();

    const isActionless = currentDirectory.virtual || !currentDirectory.capabilities.canCreate || isSearching;

    const handleDrop = (files) => {
      if (!isActionless) {
        addFilesToQueue({ files, directory: currentDirectory });
      }
    };

    const dropWrapper = ({ getRootProps, getInputProps, isDragActive }) => {
      const rootProps = getRootProps();
      const showDropppable = !isActionless && isDragActive;

      const droppable = showDropppable && (
        <div css={styles.droppable}>
          <div className='droppable__wrapper'>
            <UploadIcon className='droppable__icon' />

            <div className='d-none d-lg-block droppable__text'>
              <FormattedMessage id='ContentArea.dragNDrop' />
            </div>
            <div className='d-lg-none droppable__text'>
              <FormattedMessage id='ContentArea.clickToUpload' />
            </div>
          </div>
        </div>
      );

      const containerStyles = [styles.container.base, styles.container.visible];

      const sectionStyles = [
        styles.section.base,
        isSearching && styles.section.hidden,
        !isLoading && styles.section.loaded,
      ];

      return (
        <DateFormatContextProvider>
          <div {...rootProps} css={containerStyles} className='row m-0'>
            {droppable}

            <div css={styles.loading}>
              <ClipLoader color={colors.cyan} loading={isLoading} />
            </div>

            <SearchResults
              show={isSearching}
              query={searchTerm}
              onDocumentClick={onDocumentClick}
              onDirectoryClick={onDirectoryClick}
              onDirectoryDownload={onDirectoryDownload}
              onViewDocumentHistory={onViewDocumentHistoryClick}
            />

            <div css={sectionStyles} className='col-lg-6'>
              <p css={styles.sectionTitle}>
                <FormattedMessage id='ContentArea.files' />
              </p>
              <DocumentsArea
                currentDirectory={currentDirectory}
                isActionless={isActionless}
                onClick={onDocumentClick}
                onShareClick={onShareClick}
                onMoveClick={onMoveClick}
                onViewDocumentHistoryClick={onViewDocumentHistoryClick}
              />
            </div>

            <div css={sectionStyles} className='col-lg-6'>
              <p css={styles.sectionTitle}>
                <FormattedMessage id='ContentArea.folders' />
              </p>
              <DirectoriesArea
                currentDirectory={currentDirectory}
                isActionless={isActionless}
                onShareClick={onShareClick}
                onMoveClick={onMoveClick}
                onClick={onDirectoryClick}
                onDownload={onDirectoryDownload}
              />
            </div>
          </div>
        </DateFormatContextProvider>
      );
    };

    return <Dropzone onDrop={handleDrop}>{dropWrapper}</Dropzone>;
  }
);

export { ContentArea };
