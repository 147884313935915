import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const BreadcrumbItem = ({ name, isSelected, onClick, className }) => (
  <div className={clsx('tw-group tw-inline-flex tw-min-w-0 tw-items-center tw-gap-x-2', className)}>
    <div
      onClick={onClick}
      className={clsx('tw-cursor-pointer tw-text-sm tw-text-purple-500', {
        'tw-font-medium': !isSelected,
        'tw-font-semibold': isSelected,
      })}
    >
      {name}
    </div>
    <span className='tw-text-purple-500 group-last:tw-hidden'>/</span>
  </div>
);

BreadcrumbItem.propTypes = {
  name: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default BreadcrumbItem;
