import React, { useContext, createContext, useReducer } from 'react';

const createStore = (reducer, initialState) => {
  const storeContext = createContext();
  const dispatchContext = createContext();

  const StoreProvider = ({ children, value = {} }) => {
    const [store, dispatch] = useReducer(reducer, { ...initialState, ...value });

    return (
      <dispatchContext.Provider value={dispatch}>
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
      </dispatchContext.Provider>
    );
  };

  const useStore = () => useContext(storeContext);
  const useDispatch = () => useContext(dispatchContext);

  return [StoreProvider, useStore, useDispatch];
};

export { createStore };
