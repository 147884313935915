import React from 'react';
import { useIntl } from 'react-intl';

import { Row, Col } from 'react-bootstrap';
import { Document } from './../Document';
import { Panel } from './../Panel';
import { FallbackCard } from './../FallbackCard';
import FileViewer from 'components/v2/shared/FileViewer';
import { withIntl } from '../../../utils/v2/intl';
import { useFileViewer } from 'components/documents/hooks/useFileViewer';
import { useRecentDocumentsQuery } from './../data/queries/RecentDocumentsQuery';

import { DateFormatContextProvider } from '../../../contexts/v2/DateFormatContext';

const RecentDocuments = () => {
  const { data, loading } = useRecentDocumentsQuery();
  const { fileViewerContext, openFileViewer, closeFileViewer } = useFileViewer();

  const intl = useIntl();

  if (loading || !data) {
    return <Panel heading={intl.formatMessage({ id: 'RecentDocuments.recentDocs' })} loading />;
  }

  const { recentDashboardDocuments } = data.currentContact;
  const documentsPath = `/${getCompanySlug()}/documents`;

  if (!recentDashboardDocuments.length) {
    return (
      <Panel heading={intl.formatMessage({ id: 'RecentDocuments.recentDocs' })}>
        <FallbackCard message={intl.formatMessage({ id: 'RecentDocuments.viewDocs' })} link={documentsPath} />
      </Panel>
    );
  }

  const renderDocument = (document) => {
    const handleClick = openFileViewer.bind(this, document);

    return (
      <Col md={3} key={`document-wrapper-${document.id}`}>
        <Document key={document.id} onClick={handleClick} {...document} />
      </Col>
    );
  };

  const renderedDocuments = recentDashboardDocuments.map(renderDocument);

  return (
    <DateFormatContextProvider>
      <Panel heading={intl.formatMessage({ id: 'RecentDocuments.recentDocs' })} viewMoreLink={documentsPath}>
        <Row>{renderedDocuments}</Row>

        <FileViewer
          fileName={fileViewerContext.file?.name}
          fileUrl={fileViewerContext.file?.url}
          isOpen={fileViewerContext.show}
          onClose={closeFileViewer}
        />
      </Panel>
    </DateFormatContextProvider>
  );
};

export default withIntl(RecentDocuments);
