/** @jsx jsx */

import React, { memo, useState, useRef } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { DatePicker } from 'components/shared/DatePicker';

import { Icon, Label, Error, TextField } from 'components/shared/Input';

import { position } from 'styles';

const DateInput = memo(
  ({
    name,
    icon,
    iconPosition,
    label,
    placeholder,
    isRequired,
    isDisabled,
    isReadOnly,
    errorMessage,
    value,
    onChange,
    disablePastDates,
  }) => {
    let [isFocused, setIsFocused] = useState(false);

    let textFieldRef = useRef();

    const handleFocus = (e) => {
      if (isReadOnly || isDisabled) return;

      setIsFocused(true);
      textFieldRef.current.focus();
    };

    const handleBlur = (e) => {
      if (isReadOnly || isDisabled) return;

      const isWithin = e.relatedTarget && e.currentTarget.contains(e.relatedTarget);

      if (isWithin) {
        textFieldRef.current.focus();
      } else {
        setIsFocused(false);
      }
    };

    const formattedValue = value?.format(getUserDateFormat() || 'DD/MM/YYYY') || '';

    const handleChange = (value) => {
      onChange(value);
      setIsFocused(false);
      textFieldRef.current.blur();
    };

    return (
      <div>
        <Label text={label} />

        <div onBlur={handleBlur} onFocus={handleFocus} tabIndex='1' className='outline-none'>
          <div css={position.relative}>
            <TextField
              ref={textFieldRef}
              name={name}
              value={formattedValue}
              placeholder={placeholder}
              required={isRequired}
              isDisabled={isDisabled}
              isReadOnly
              isInvalid={!!errorMessage}
            />

            <Icon icon={icon} position={iconPosition} asFocused={isFocused} asInvalid={!!errorMessage} />

            <Error message={errorMessage} />
          </div>

          {!isReadOnly && !isDisabled && (
            <DatePicker onDateChange={handleChange} date={value} show={isFocused} disablePastDates={disablePastDates} />
          )}
        </div>
      </div>
    );
  }
);

DateInput.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.object,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export { DateInput };
