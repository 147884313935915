import { gql, useQuery } from '@apollo/client';
import { TIMEZONE_FRAGMENT } from 'data/v2/fragments/TimezoneFragment';

const CURRENT_CONTACT_QUERY = gql`
  query CurrentContactQuery {
    currentContact {
      id

      timezone {
        ...TimezoneFields
      }
    }
  }
  ${TIMEZONE_FRAGMENT}
`;

const useCurrentContactQuery = (options) => useQuery(CURRENT_CONTACT_QUERY, options);

export { CURRENT_CONTACT_QUERY, useCurrentContactQuery };
