import React, { useState, useContext } from 'react';
import Heading from 'components/v2/shared/Heading';
import Filter from 'components/v2/shared/Filter';
import Switch from 'components/v2/shared/Switch';
import IndividualContacts from 'components/v2/contacts/IndividualContacts';
import Spinner from '../shared/Spinner/Spinner';
import { FormattedMessage } from 'react-intl';
import { withIntl } from 'utils/v2/intl';
import { wrapMutation } from 'utils/v2/gql';
import { useUpdateContactRoleMutation } from '../../../data/v2/mutations/UpdateContactRoleMutation';
import { Severity } from '../shared/Toast/Toast';
import { ToastContext } from 'contexts/v2/ToastContext';
import { SelectedContactContext } from '../settings/Contacts';
import { HiChevronUpDown } from 'react-icons/hi2';
import { CONTACTS_QUERY } from 'data/v2/queries/ContactsQuery';

const IndividualPermission = ({ title, permitted, description }) => {
  return (
    <div className='tw-grid tw-h-16 tw-grid-flow-col tw-grid-cols-8 tw-items-center tw-rounded tw-bg-gray-100 tw-px-5'>
      <div className='tw-col-span-6 tw-flex tw-flex-col tw-justify-center tw-font-sans tw-text-[13px] tw-font-semibold tw-text-black sm:tw-col-span-6'>
        <FormattedMessage id={`PermissionScope.${title}`} />
        <p className='tw-m-0 tw-text-xs tw-font-medium tw-text-gray-700'>{description}</p>
      </div>
      <div className='tw-col-span-2 tw-flex tw-justify-end'>
        <Switch isDisabled={true} isChecked={permitted} variant={permitted ? 'teal-bg' : 'gray-bg'} />
      </div>
    </div>
  );
};

const PermissionIndividual = ({ openModal }) => {
  const [selectedContact, setSelectedContact] = useContext(SelectedContactContext);
  const { id: selectedContactId, contactRole, accessRoleId, roles } = selectedContact;
  const { toastInfo, setToastInfo } = useContext(ToastContext);
  const getPermissionsByRoleId = (roleId) => {
    let selectedRole = roles.filter((role) => role.id === roleId)[0];

    return {
      'Company settings': selectedRole.rolePermissions.filter((p) =>
        ['company', 'subscription', 'chat'].includes(p.permission.resource)
      ),
      'Contact & Roles': selectedRole?.rolePermissions.filter((p) =>
        ['access_role', 'contact_group', 'contact'].includes(p.permission.resource)
      ),
      Meetings: selectedRole?.rolePermissions.filter((p) => ['meeting'].includes(p.permission.resource)),
      Documents: selectedRole?.rolePermissions.filter((p) => ['document'].includes(p.permission.resource)),
      Tasks: selectedRole.rolePermissions.filter((p) => ['task'].includes(p.permission.resource)),
      Minutes: selectedRole?.rolePermissions.filter((p) => ['minute'].includes(p.permission.resource)),
      Voting: selectedRole?.rolePermissions.filter((p) => ['vote'].includes(p.permission.resource)),
      SmartSign: selectedRole?.rolePermissions.filter((p) => ['smart_sign'].includes(p.permission.resource)),
    };
  };
  let initPermissions = getPermissionsByRoleId(accessRoleId);
  const [modalPermissions, setModalPermissions] = useState(initPermissions);

  const CreatePermissions = ({ permission }) => {
    return permission.map((p) => {
      return (
        <div className='tw-mt-1' key={p.permission.scope}>
          <IndividualPermission
            title={p.permission.scope}
            permitted={p.permitted}
            description={p.permission.description}
          />
        </div>
      );
    });
  };

  const filterItems = roles.reduce((arr, role) => {
    return arr.concat({
      id: role.id,
      name: role.name,
    });
  }, []);

  const [updateContactRole] = useUpdateContactRoleMutation({
    refetchQueries: [{ query: CONTACTS_QUERY }],
  });
  const onSelect = (role) => {
    let newPermissions = getPermissionsByRoleId(role.id);
    wrapMutation(
      updateContactRole,
      {
        variables: {
          input: {
            contactId: selectedContactId,
            id: role.id,
          },
        },
      },
      'updateContactRole'
    )
      .then(() => {
        if (initPermissions !== modalPermissions) {
          setToastInfo({
            severity: Severity.Success,
            status: 'Success',
            message: `Contact role update successful.`,
          });
        }
        setSelectedContact({ ...selectedContact, contactRole: role.name });
        setModalPermissions(newPermissions);
      })
      .catch((error) => {
        setToastInfo({
          severity: Severity.Error,
          status: 'Error',
          message: error.message,
        });
      });
  };

  return (
    <div className=' tw-mt-12 tw-flex  tw-justify-center '>
      <div className='tw-relative tw-w-full sm:tw-w-[350px]'>
        <Heading text='Permissions' className='!tw-mb-0' />
        <IndividualContacts selectedContact={selectedContact} fromPermission />
        <div className='tw-mb-2 tw-mt-6 tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>Role</div>
        <div className='tw-h-12'>
          <Filter
            items={filterItems}
            placeholder={contactRole}
            iconRight={<HiChevronUpDown className='tw-text-lg' />}
            variant='Role'
            onSelect={onSelect}
          />
        </div>

        <div className='tw-mt-10'>
          <div className='tw-mb-2.5 tw-font-sans tw-text-xs tw-font-semibold tw-text-black'>Company Settings</div>
          <CreatePermissions permission={modalPermissions['Company settings']} />
          <div className='tw-mt-7 tw-mb-2.5 tw-font-sans tw-text-xs tw-font-semibold tw-text-black'>
            Contacts & Roles
          </div>
          <CreatePermissions permission={modalPermissions['Contact & Roles']} />
          <div className='tw-mt-8 tw-mb-2.5 tw-font-sans tw-text-xs tw-font-semibold tw-text-black'>Meetings</div>
          <CreatePermissions permission={modalPermissions['Meetings']} />
          <div className='tw-mt-14 tw-mb-2.5 tw-font-sans tw-text-xs tw-font-semibold tw-text-black'>Documents</div>
          <CreatePermissions permission={modalPermissions['Documents']} />
          <div className='tw-mt-12 tw-mb-2.5 tw-font-sans tw-text-xs tw-font-semibold tw-text-black'>Tasks</div>
          <CreatePermissions permission={modalPermissions['Tasks']} />
          <div className='tw-mt-7 tw-mb-2.5 tw-font-sans tw-text-xs tw-font-semibold tw-text-black'>Minute</div>
          <CreatePermissions permission={modalPermissions['Minutes']} />
          <div className='tw-mt-7 tw-mb-2.5 tw-font-sans tw-text-xs tw-font-semibold tw-text-black'>Voting</div>
          <CreatePermissions permission={modalPermissions['Voting']} />
          <div className='tw-mt-11 tw-mb-2.5 tw-font-sans tw-text-xs tw-font-semibold tw-text-black'>Smartsign</div>
          <CreatePermissions permission={modalPermissions['SmartSign']} />
          <div className='tw-mb-5'></div>
        </div>
      </div>
    </div>
  );
};

export default withIntl(PermissionIndividual);
