import { gql, useQuery } from '@apollo/client';

const MEETING_SMART_PACK_ANALYTICS_QUERY = gql`
  query MeetingSmartPackAnalyticsQuery($id: ID!) {
    currentCompany {
      id

      meeting(id: $id) {
        id

        smartPack {
          id

          analytics {
            contact {
              id
              name
            }

            rsvpStatus
            annotationCount
            firstOpenedAt
            lastOpenedAt
            totalReadTime
          }
        }
      }
    }
  }
`;

const useMeetingSmartPackAnalyticsQuery = (options) => useQuery(MEETING_SMART_PACK_ANALYTICS_QUERY, options);

export { MEETING_SMART_PACK_ANALYTICS_QUERY, useMeetingSmartPackAnalyticsQuery };
