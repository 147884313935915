/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { ReviewerListTabs } from './../ReviewerListTabs';
import { CommentList } from './../CommentList';
import { ReviewerModal } from './../ReviewerModal';

import * as styles from './ReviewerList.styles';

const ReviewerList = memo(({ minutes, comments, reviews, currentContact }) => {
  const [activeReviewerId, setActiveReviewerId] = useState(null);
  const [isReviewerModalVisible, setIsReviewerModalVisible] = useState(false);

  const handleBackClick = () => setActiveReviewerId(null);
  const handleAddReviewerClick = () => setIsReviewerModalVisible(true);
  const handleCloseModalClick = () => setIsReviewerModalVisible(false);

  return (
    <div css={styles.container}>
      <div className='m-l-10 d-flex flex-wrap align-items-center justify-content-between'>
        <div className='font-weight-extrabold fs-22 text-black m-b-10'>
          <FormattedMessage id='ReviewerList.reviewers' />
        </div>

        <a className='btn btn-secondary m-b-10' onClick={handleAddReviewerClick}>
          <FormattedMessage id='ReviewerList.plusAdd' />
        </a>
      </div>

      <div className='m-t-20'>
        {!activeReviewerId && (
          <ReviewerListTabs
            reviews={reviews}
            minutes={minutes}
            comments={comments}
            currentContact={currentContact}
            onReviewerClick={setActiveReviewerId}
          />
        )}
      </div>

      <ReviewerModal show={isReviewerModalVisible} onClose={handleCloseModalClick} />

      {activeReviewerId && (
        <div css={styles.commentList}>
          <CommentList
            minuteId={minutes.id}
            reviewerId={activeReviewerId}
            className='outline-none'
            currentContact={currentContact}
          />

          <i onClick={handleBackClick} css={styles.back} className='fal fa-chevron-left' />
        </div>
      )}
    </div>
  );
});

export { ReviewerList };
