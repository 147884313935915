/** @jsx jsx */

import React, { memo, useState, useEffect, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { createPortal } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDropdownRoles } from './../hooks/useDropdownRoles';
import { SelectInput } from 'components/shared/SelectInput';
import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';
import { Input } from 'components/shared/Input';
import { Drawer } from '../../shared/Drawer';
import { PermissionsTable } from './../PermissionsTable';
import useWindowDimensions from 'hooks/useWindowDimensions';

import * as styles from './RoleDrawer.styles';

import { useRoleDrawerStore, useRoleDrawerActions } from 'store/roleDrawerStoreContext';

const RoleDrawer = memo(({ defaultRoleId, accessRoles, permissions, onCreate, onUpdate, onDelete, appendTo }) => {
  const { isOpen, role, errors, viewOnly } = useRoleDrawerStore();
  const { hideDrawer, setRole, setErrors } = useRoleDrawerActions();
  const { isMobile } = useWindowDimensions();

  const intl = useIntl();

  const [show, setShow] = useState(isOpen);

  const { dropdownRoleOptions, currentDropdownRole, changeDropdownRole, resetCurrentDropdownRole } = useDropdownRoles({
    accessRoles,
    defaultRoleId,
    currentRoleId: role?.id,
  });

  useEffect(() => {
    if (isOpen) {
      setShow(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isMobile) return;

    if (show) {
      document.querySelector('body').classList.add('opaque-bg');
    } else {
      document.querySelector('body').classList.remove('opaque-bg');
    }
  }, [show]);

  useEffect(() => {
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      document.getElementById(drawerId).scrollTop = 0;
    }
  }, [errors]);

  const getPermissionPayload = (permission) => {
    return {
      permitted: permission.permitted,
      id: permission.permission_id,
    };
  };

  const getPayload = () => {
    return {
      permissions: role.permissions.map(getPermissionPayload),
      name: role.name,
    };
  };

  const handlePermissionsChange = useCallback(
    (payload) => {
      let collection = [...role.permissions];

      const index = collection.findIndex((item) => Number(item.permission_id) === Number(payload.permission_id));

      if (index !== -1) {
        collection[index] = payload;
      } else {
        collection = [...collection, payload];
      }

      setRole({ permissions: collection });
    },
    [role.permissions]
  );

  const handleNameChange = useCallback((event) => {
    setRole({ name: event.target.value || '' });
  }, []);

  const handleSaveClick = () => {
    const payload = getPayload();

    if (role.id) {
      onUpdate(role.id, payload, hideDrawer, setErrors);
    } else {
      onCreate(payload, hideDrawer, setErrors);
    }
  };

  const handleDeleteClick = () => {
    onDelete(role.id, currentDropdownRole.value, hideDrawer);
  };

  const drawerId = 'shaparency-role-drawer';

  const isDisabled = viewOnly || (role.id && !role.company_id);

  const getDrawerHeader = () => {
    if (isDisabled) {
      return intl.formatMessage({ id: 'RoleDrawer.viewRole' });
    } else if (role.id) {
      return intl.formatMessage({ id: 'RoleDrawer.editRole' });
    } else {
      return intl.formatMessage({ id: 'RoleDrawer.newRole' });
    }
  };

  const rolesDropdown = (
    <div className='m-t-15 text-left'>
      <SelectInput
        placeholder={intl.formatMessage({ id: 'RoleDrawer.selectRole' })}
        isRequired
        onChange={changeDropdownRole}
        options={dropdownRoleOptions}
        value={currentDropdownRole}
      />
    </div>
  );

  const renderButtons = () => {
    if (isDisabled) {
      return null;
    }

    const deleteButton = isMobile ? (
      <button className='btn btn-sm-fw btn-light-red btn-light-red-inverted m-l-5'>
        <FormattedMessage id='RoleDrawer.remove' />
      </button>
    ) : (
      <button className='btn m-l-10 btn-red border-0 btn-sm-fw'>
        <div className='d-flex align-items-center'>
          <i className='far fa-trash-alt m-r-5 fs-14' />
          <span>
            <FormattedMessage id='RoleDrawer.remove' />
          </span>
        </div>
      </button>
    );

    return (
      <div
        className={`d-flex align-items-center ${isMobile ? 'p-l-15 p-r-15 m-t-15' : 'p-l-5 justify-content-between'}`}
      >
        <button onClick={handleSaveClick} className={`btn btn-sm-fw btn-primary fs-16`}>
          <FormattedMessage id='RoleDrawer.save' />
        </button>

        {role?.id && (
          <ButtonWithConfirmation
            confirmationText={intl.formatMessage({ id: 'RoleDrawer.confirmationText' })}
            onConfirm={handleDeleteClick}
            onTransitionEnd={resetCurrentDropdownRole}
            alertChildren={rolesDropdown}
            button={deleteButton}
            popupType='failure'
          />
        )}
      </div>
    );
  };

  const onAnimationEnd = () => {
    if (!isOpen) {
      setShow(false);
    }
  };

  const drawer = (
    <Drawer drawerId={drawerId} onAnimationEnd={onAnimationEnd} styles={styles.drawer(isOpen)} onClose={hideDrawer}>
      <div className='d-md-none p-15 b-b-grey d-flex align-items-center'>
        <i className='fa fa-chevron-left fw-300 text-black fs-18 cursor-pointer' onClick={hideDrawer} />
        <div className='m-auto font-weight-semibold text-black fs-18'>{getDrawerHeader()}</div>
      </div>

      <div css={styles.wrapper}>
        <div css={styles.header}>{getDrawerHeader()}</div>

        <div className='m-t-20' css={styles.input}>
          <Input
            placeholder={intl.formatMessage({ id: 'RoleDrawer.roleName' })}
            value={role.name}
            isRequired
            errorMessage={errors.name}
            onChange={handleNameChange}
            isDisabled={isDisabled}
          />
        </div>

        <div css={styles.content(isDisabled)}>
          <PermissionsTable
            permissions={permissions}
            accessRolePermissions={role.permissions}
            onChange={handlePermissionsChange}
            isDisabled={isDisabled}
          />
        </div>
        {renderButtons()}
      </div>
    </Drawer>
  );

  return show && createPortal(drawer, appendTo);
});

export { RoleDrawer };
