import React from 'react';
import PropTypes from 'prop-types';

import SelectInput from 'components/v2/shared/SelectInput';

const ContactGroupSelectInput = ({ ...props }) => {
  return <SelectInput {...props} optionVariant='multiGroup' isMulti />;
};

ContactGroupSelectInput.propTypes = {
  className: PropTypes.string,
};

export default ContactGroupSelectInput;
