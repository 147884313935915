/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';

import * as styles from './ActionBar.styles';

const ActionBar = memo(
  ({ status, canDistribute, hasReviewers, onSave, onReview, onDistribute, onApprove, showEditButton, onEdit }) => {
    const isDraft = status === 'draft';
    const isInReview = status === 'in_review';

    const intl = useIntl();

    const isDistributable = isDraft || isInReview;

    const approvalButton = (
      <button className='btn btn-inverted btn-responsive m-l-10'>
        <FormattedMessage id='ActionBar.saveAndApprove' />
      </button>
    );

    const distributionButton = (
      <button
        className={`btn btn-responsive m-l-10 ${isDistributable ? 'btn-primary' : 'btn-disabled'}`}
        disabled={!isDistributable}
      >
        <FormattedMessage id='ActionBar.sendForDist' />
      </button>
    );

    const renderReviewButton = () => {
      if (!isDraft) {
        return;
      }

      const button = (
        <button onClick={hasReviewers ? onReview : null} className='btn m-l-10 btn-primary btn-responsive'>
          <FormattedMessage id='ActionBar.sendForReview' />
        </button>
      );

      if (hasReviewers) {
        return button;
      } else {
        return (
          <ButtonWithConfirmation
            headerText={intl.formatMessage({ id: 'ActionBar.warning' })}
            confirmButtonText={intl.formatMessage({ id: 'ActionBar.ok' })}
            button={button}
            singleOption
            confirmationText={intl.formatMessage({ id: 'ActionBar.reviewersConfirmation' })}
          />
        );
      }
    };

    return (
      <div className='d-flex align-items-center justify-content-center flex-wrap' css={styles.bar}>
        <button onClick={onSave} className='btn btn-inverted btn-responsive'>
          <FormattedMessage id='ActionBar.saveAndExit' />
        </button>

        {showEditButton && (
          <button onClick={onEdit} className='btn btn-inverted m-l-10 btn-responsive'>
            <FormattedMessage id='ActionBar.editOrder' />
          </button>
        )}

        {canDistribute && (
          <ButtonWithConfirmation
            headerText={intl.formatMessage({ id: 'ActionBar.warning' })}
            confirmButtonText={intl.formatMessage({ id: 'ActionBar.ok' })}
            button={approvalButton}
            confirmationText={intl.formatMessage({ id: 'ActionBar.approvedConfirmation' })}
            onConfirm={onApprove}
          />
        )}

        {renderReviewButton()}

        {canDistribute && (
          <ButtonWithConfirmation
            headerText={intl.formatMessage({ id: 'ActionBar.warning' })}
            confirmButtonText={intl.formatMessage({ id: 'ActionBar.ok' })}
            button={distributionButton}
            confirmationText={intl.formatMessage({ id: 'ActionBar.distributedConfirmation' })}
            onConfirm={onDistribute}
          />
        )}
      </div>
    );
  }
);

export { ActionBar };
