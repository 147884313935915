const GeneratePath = (currentPath, rootDirectory, currentDirectory) => {
  if (currentDirectory.data.id === rootDirectory.data.id) return [rootDirectory];

  const currentDirectoryIndex = currentPath.findIndex(({ data: { id } }) => currentDirectory.data.id === id);

  if (currentDirectoryIndex > 0) {
    return currentPath.slice(0, currentDirectoryIndex + 1);
  } else {
    return [...currentPath, currentDirectory];
  }
};

export { GeneratePath };
