/** @jsx jsx */

import React, { useRef, useEffect, useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { jsx } from '@emotion/core';
import WebViewer from '@pdftron/webviewer';
import PropTypes from 'prop-types';
import * as styles from './PdfViewer.styles';
import { useAnnotationContext } from '../../../store/annotationContext';
import { ACTION_ADD, ACTION_MODIFY, ACTION_DELETE, disabledElements } from './constants';
import PdfViewerSidebar from '../PdfViewerSidebar';
import SmartPackPdfModal from '../SmartPackPdfModal/SmartPackPdfModal';
import { Spinner } from 'components/shared/Spinner';
import { FormattedMessage, useIntl } from 'react-intl';
import PdfViewerTopbar from 'components/v2/shared/PdfViewerTopbar';
import BasePdfViewer from 'components/v2/shared/PdfViewer';
import { getResourceGID } from 'helpers/graphql';
import { useMeetingSmartPackAnalyticsQuery } from 'data/v2/queries/MeetingSmartPackAnalyticsQuery';
import useWindowDimensions from 'hooks/useWindowDimensions';
import moment from 'moment';

const PdfViewer = ({
  meeting,
  agendas,
  author,
  show,
  onHide,
  onToggleAgenda,
  disableAnnotations,
  showCompletedInfo,
  ahoyProperties,
  currentContactId,
}) => {
  const intl = useIntl();
  const { isDesktop } = useWindowDimensions();

  const publicAnnotationsRef = useRef(false);
  const [publicAnnotations, setPublicAnnotations] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewerInstance, setViewerInstance] = useState(null);
  const [viewerUI, setViewerUI] = useState(null);
  const [annotationManager, setAnnotationManager] = useState(null);
  const [agendaDlModalOpen, setAgendaDlModalOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  const [timeOpened, setTimeOpened] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(isDesktop);
  const toggleSidebar = () => setIsSidebarVisible((prev) => !prev);

  const togglePublicAnnotations = () => {
    publicAnnotationsRef.current = !publicAnnotations;
    setPublicAnnotations(!publicAnnotations);
  };

  const trackTotalReadTime = () => {
    const timeViewedInSeconds = moment().diff(timeOpened, 'seconds');
    ahoy.track('smart_pack_viewed', { ...ahoyProperties, timeViewedInSeconds });
  };

  useEffect(() => {
    const timeTracker = (e) => {
      e.preventDefault();
      trackTotalReadTime();
    };

    window.addEventListener('beforeunload', timeTracker);

    return () => {
      window.removeEventListener('beforeunload', timeTracker);
    };
  }, [timeOpened]);

  useEffect(() => {
    if (show) {
      setTimeOpened(moment());
    } else if (timeOpened) {
      trackTotalReadTime();
    }
  }, [show]);

  const {
    data: analyticsData,
    loading: isLoadingAnalytics,
    refetch: refetchAnalytics,
  } = useMeetingSmartPackAnalyticsQuery({
    skip: !show,
    fetchPolicy: 'network-only',
    variables: { id: getResourceGID('Meeting', meeting.id) },
  });

  const {
    initialAnnotations,
    addedAnnotation,
    updatedAnnotation,
    removedAnnotation,
    addAnnotation,
    modifyAnnotation,
    removeAnnotation,
    refetchAnnotations,
  } = useAnnotationContext();

  const drawRemoteAnnotation = async (remoteAnnotation) => {
    const [annotation] = await annotationManager.importAnnotationCommand(remoteAnnotation.data);
    annotation.authorId = remoteAnnotation.contactId;
    annotationManager.redrawAnnotation(annotation);
    hideAnnotation(annotation);
  };

  const hideAnnotation = (annotation) => {
    if (publicAnnotations) return;

    annotationManager.hideAnnotation(annotation);
  };

  // Handle annotation add through the subscription
  useEffect(() => {
    if (addedAnnotation && viewerInstance) {
      drawRemoteAnnotation(addedAnnotation);
    }
  }, [addedAnnotation]);

  // Handle annotation update through the subscription
  useEffect(() => {
    if (updatedAnnotation && viewerInstance) {
      drawRemoteAnnotation(updatedAnnotation);
    }
  }, [updatedAnnotation]);

  // Handle annotation remove through the subscription
  useEffect(() => {
    if (removedAnnotation && viewerInstance) {
      const command = `<delete><id>${removedAnnotation.externalId}</id></delete>`;
      annotationManager.importAnnotationCommand(command);
    }
  }, [removedAnnotation]);

  const annotationIsPrivate = (annotation) => {
    return annotation.getCustomData('enabledContactId').length > 0;
  };

  const isCurrentContactAnnotation = (annotation) => {
    if (!annotationIsPrivate) return false;

    return annotation.getCustomData('enabledContactId') == currentContactId.toString();
  };

  const showPublicAnnotations = (annotations) => {
    annotations.forEach((annotation) => {
      if (annotationIsPrivate(annotation)) {
        annotationManager.hideAnnotation(annotation);
      } else {
        annotationManager.showAnnotation(annotation);
      }
    });
  };

  const showPrivateAnnotations = (annotations) => {
    annotations.forEach((annotation) => {
      if (isCurrentContactAnnotation(annotation)) {
        annotationManager.showAnnotation(annotation);
      } else {
        annotationManager.hideAnnotation(annotation);
      }
    });
  };

  useEffect(() => {
    if (!viewerInstance) return;

    const annotations = annotationManager.getAnnotationsList();

    if (publicAnnotations) {
      return showPublicAnnotations(annotations);
    } else {
      return showPrivateAnnotations(annotations);
    }
  }, [publicAnnotations]);

  const handlePdfViewerInit = (instance) => {
    const { annotationManager, documentViewer, Annotations } = instance.Core;

    setViewerUI(instance.UI);
    setAnnotationManager(annotationManager);
    setViewerInstance(instance.Core);
    setShowSpinner(false);

    annotationManager.setPermissionCheckCallback((_, annotation) => annotation.authorId === currentContactId);

    const historyManager = instance.docViewer.getAnnotationHistoryManager();

    instance.UI.setHeaderItems((header) => {
      const items = header.getItems();
      const headerItems = disableAnnotations
        ? items.filter((item) => item.type !== 'divider' && item.toolName !== 'AnnotationEdit')
        : items;

      const annotationItems = [
        {
          type: 'toolButton',
          toolName: 'AnnotationCreateTextHighlight',
        },
        {
          type: 'toolButton',
          toolName: 'AnnotationCreateSticky',
        },
        {
          type: 'toolButton',
          toolName: 'AnnotationCreateFreeHand',
        },
        {
          type: 'divider',
        },
        {
          type: 'actionButton',
          img: 'icon-operation-undo',
          toolName: 'undoButton',
          dataElement: 'undoButton',
          title: 'action.undo',
          onClick: () => historyManager.undo(),
        },
        {
          type: 'actionButton',
          img: 'icon-operation-redo',
          toolName: 'redoButton',
          dataElement: 'redoButton',
          title: 'action.redo',
          onClick: () => historyManager.redo(),
        },
        {
          type: 'toolButton',
          toolName: 'AnnotationEraserTool',
        },
      ];

      const toolbarItems = disableAnnotations ? [...headerItems] : [...annotationItems, ...headerItems];

      header.update(toolbarItems);
    });

    documentViewer.getTool('AnnotationCreateSticky').setStyles({
      StrokeColor: new Annotations.Color(0, 186, 198),
    });

    documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => setCurrentPage(pageNumber));

    documentViewer.addEventListener('documentLoaded', () => {
      initialAnnotations.forEach(async (annotationItem) => {
        const [annotation] = await annotationManager.importAnnotationCommand(annotationItem.data);
        if (annotation) {
          annotation.authorId = annotationItem.contactId;
          annotation.setCustomData('gid', annotationItem.gid);
          annotationManager.redrawAnnotation(annotation);

          if (!isCurrentContactAnnotation(annotation)) {
            annotationManager.hideAnnotation(annotation);
          }
        }
      });
    });

    annotationManager.addEventListener('annotationChanged', (annotations, action, { imported }) => {
      if (imported) {
        // Run this so the annotations from the document don't get re-exported
        annotationManager.exportAnnotationCommand();
        return;
      }
      annotations.forEach((annotation) => {
        if (action === ACTION_ADD && !publicAnnotationsRef.current) {
          annotation.setCustomData('enabledContactId', currentContactId);
        }

        annotationManager.exportAnnotationCommand().then(async (xfdf) => {
          if (action === ACTION_ADD) {
            const gid = await addAnnotation(annotation, xfdf, publicAnnotationsRef.current);
            annotation.authorId = currentContactId;
            annotation.setCustomData('gid', gid);
          } else if (action === ACTION_MODIFY) {
            await modifyAnnotation(annotation, xfdf);
          } else if (action === ACTION_DELETE) {
            await removeAnnotation(annotation);
          }
          refetchAnalytics();
        });
      });
    });
  };

  const handleAgendaDlPress = () => setAgendaDlModalOpen(true);
  const handleAgendaDlModalHide = () => setAgendaDlModalOpen(false);

  const handleDownloadClick = async (includeAnnotations) => {
    setShowSpinner(true);
    ahoy.track('smart_pack_downloaded', ahoyProperties);
    refetchAnalytics();

    const filename = includeAnnotations ? 'SmartPackWithAnnotations.pdf' : 'SmartPack.pdf';

    const options = {
      filename: filename,
      downloadType: 'pdf',
      includeAnnotations: includeAnnotations,
    };

    setShowSpinner(false);
    viewerUI.downloadPdf(options);
  };

  const handleDlAnnotatedClick = () => handleDownloadClick(true);
  const handleDlClick = () => handleDownloadClick(false);

  const handleExit = () => {
    ahoy.track('smart_pack_closed', { ...ahoyProperties, page_number: currentPage });
  };

  const handleExited = () => {
    refetchAnnotations();
    setShowSpinner(true);
  };

  const handleEnter = () => {
    ahoy.track('smart_pack_opened', ahoyProperties);
  };

  const scrollToPage = (page) => {
    if (!isDesktop) {
      toggleSidebar();
    }

    viewerInstance && viewerInstance.documentViewer.setCurrentPage(page);
  };

  return (
    <>
      <Modal
        show={show}
        css={styles.modal}
        onHide={onHide}
        onExit={handleExit}
        onExited={handleExited}
        onEnter={handleEnter}
      >
        <Modal.Body>
          <div css={styles.modalButtons}></div>

          <PdfViewerTopbar
            title={meeting.title}
            onClose={onHide}
            onDownload={handleAgendaDlPress}
            onShowMenu={toggleSidebar}
            isViewingMenu={isSidebarVisible}
          />

          <div css={styles.viewerContainer}>
            <PdfViewerSidebar
              isVisible={isSidebarVisible}
              meeting={meeting}
              agendas={agendas}
              analytics={analyticsData?.currentCompany?.meeting?.smartPack?.analytics}
              isLoadingAnalytics={isLoadingAnalytics}
              onScrollToPage={scrollToPage}
              onToggleAgenda={onToggleAgenda}
              publicAnnotations={publicAnnotations}
              togglePublicAnnotations={togglePublicAnnotations}
              isAnnotatable={!disableAnnotations}
            />

            <BasePdfViewer
              documentUrl={meeting.smart_pack_url}
              className='webviewer'
              config={{ annotationUser: author, disabledElements }}
              onInit={handlePdfViewerInit}
            >
              {showCompletedInfo && (
                <div css={styles.draftBanner}>
                  <FormattedMessage id='PdfViewerSidebar.completedInfo' />
                </div>
              )}
              <Spinner show={showSpinner} />
            </BasePdfViewer>
          </div>
        </Modal.Body>
      </Modal>

      <SmartPackPdfModal
        smartPackUrl={meeting.smart_pack_url}
        onDlClick={handleDlClick}
        onAnnotatedDlClick={handleDlAnnotatedClick}
        hideAnnotationsDownload={!disableAnnotations}
        show={agendaDlModalOpen}
        onHide={handleAgendaDlModalHide}
      />
    </>
  );
};

PdfViewer.propTypes = {
  meeting: PropTypes.object,
  agendas: PropTypes.array,
  author: PropTypes.string,
  show: PropTypes.bool,
  disableAnnotations: PropTypes.bool,
  onToggleAgenda: PropTypes.func,
  onHide: PropTypes.func,
};

export default PdfViewer;
