import { gql, useMutation } from '@apollo/client';

const REJECT_CONTACT_MUTATION = gql`
  mutation RejectContact($input: RejectContactInput!) {
    rejectContact(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`;

const useRejectContactMutation = (options) => useMutation(REJECT_CONTACT_MUTATION, options);

export { REJECT_CONTACT_MUTATION, useRejectContactMutation };
