import { createStore } from './createStore';

const initialState = {
  contactId: null,
  companyId: null,
};

const actionTypes = {
  SET: 'contact.set',
};

const useContactActions = () => {
  const dispatch = useContactDispatch();

  const setContact = (payload) => {
    dispatch({ type: actionTypes.SET, payload });
  };

  return { setContact };
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET:
      return { ...state, ...payload };
  }
};

const [ContactProvider, useContactStore, useContactDispatch] = createStore(reducer, initialState);

export { ContactProvider, useContactStore, useContactActions };
