import { gql } from '@apollo/client';
import { CONTACT_FRAGMENT } from './ContactFragment';

const MEETING_FRAGMENT = gql`
  fragment MeetingFields on Meeting {
    id
    title
    meetingDate
    meetingStartTime
    meetingEndTime

    contacts {
      ...ContactFields
    }
  }
  ${CONTACT_FRAGMENT}
`;

export { MEETING_FRAGMENT };
