import { gql, useMutation } from '@apollo/client';

const MARK_MESSAGES_AS_READ_MUTATION = gql`
  mutation markMessagesAsRead($input: MarkMessagesAsReadInput!) {
    markMessagesAsRead(input: $input) {
      showBadge
    }
  }
`;

const useMarkMessagesAsReadMutation = (options) => useMutation(MARK_MESSAGES_AS_READ_MUTATION, options);

export { useMarkMessagesAsReadMutation };
