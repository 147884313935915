import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const modal = css`
  .modal-dialog.shaparency {
    .modal-content {
      .modal-header {
        padding: 0 26px;
      }

      .modal-body {
        padding: 8px 26px 0 26px;
      }
    }
  }
`;

export const scrollable = css`
  overflow: auto;
  max-height: 400px;
  padding: 0 5px;
`;
