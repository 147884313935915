import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const wrapper = css`
  margin: 17px auto;
  display: flex;
  justify-content: center;
`;

export const label = css`
  background: ${colors.placeholderGrey};
  border-radius: 50px;
  font-size: 12px;
  color: ${colors.mediumGrey};
  padding: 5px 24px;
`;
