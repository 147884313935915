/** @jsx jsx */
import React, { memo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import * as styles from './PollingForm.styles';

import { LoadingIndicator } from 'components/shared/LoadingIndicator';
import { Spinner } from 'components/shared/Spinner';
import { AlertPopup } from 'components/shared/AlertPopup';

import { ResolutionForm } from '../ResolutionForm';
import { ResolutionSettings } from '../ResolutionSettings';
import { PublishConfirmation } from '../PublishConfirmation';

import { useCreateResolution } from './../hooks/useCreateResolution';
import { useDeleteResolution } from './../hooks/useDeleteResolution';
import { useUpdateVoteSettings } from './../hooks/useUpdateVoteSettings';
import { useValidateResolutions } from './../hooks/useValidateResolutions';

import FileViewer from 'components/v2/shared/FileViewer';
import { useFileViewer } from '../../documents/hooks/useFileViewer';

import { UploadWindow } from 'components/shared/UploadWindow/UploadWindow';

const PollingForm = memo(({ vote, onStepChange, root }) => {
  const previewDocument = (document) => openFileViewer(document);

  const { fileViewerContext, openFileViewer, closeFileViewer } = useFileViewer();

  const onCreatedResolution = (resolution) => setResolutions(resolutions.concat(resolution));
  const onDeletedResolution = (vote) => setResolutions(vote.resolutions);
  const onUpdatedSettings = () => changeUpdateConfig('success', true);

  const changeUpdateConfig = (name, value) => setUpdateConfig((updateConfig) => ({ ...updateConfig, [name]: value }));

  const { isCreating, createResolution } = useCreateResolution({ voteId: vote.id, onCompleted: onCreatedResolution });
  const { isDeleting, deleteResolution } = useDeleteResolution({ onCompleted: onDeletedResolution });
  const { isUpdating, updateVoteSettings } = useUpdateVoteSettings({ onCompleted: onUpdatedSettings });

  const [resolutions, setResolutions] = useState(vote.resolutions);
  const [pollForVote, setPollForVote] = useState(vote.pollForVote);
  const [config, setConfig] = useState(vote.config);
  const [updateConfig, setUpdateConfig] = useState({ success: false, publish: false });

  const votePublished = vote?.status === 'published';

  const intl = useIntl();

  const assignWindowLocation = () => {
    if (votePublished) {
      window.location.assign(`/${getCompanySlug()}/voting/${vote.slug}`);
    } else {
      window.location.assign(`/${getCompanySlug()}/voting`);
    }
  };

  useEffect(() => {
    if (!updateConfig.success) return;

    if (updateConfig.publish) {
      setUpdateConfig({ success: false, publish: false });
      setShowPublishConfirmation(true);
    } else {
      assignWindowLocation();
    }
  }, [updateConfig.success]);

  const addResolution = () => createResolution();
  const removeResolution = (resolution) => deleteResolution(resolution.id);

  const saveVote = () => {
    $('.main').scrollTop(0, 0);
    saveVoteSettings();
  };

  const handleSave = () => {
    changeUpdateConfig('publish', false);

    if (votePublished) return validateResolutions({ voteId: vote?.id });

    saveVote();
  };

  const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
  const [showResolutionAlert, setShowResolutionAlert] = useState(false);

  const displayResolutionAlert = () => setShowResolutionAlert(true);
  const hideResolutionAlert = () => setShowResolutionAlert(false);

  const { validateResolutions } = useValidateResolutions({ onError: displayResolutionAlert, onCompleted: saveVote });

  const hidePublishConfirmation = () => setShowPublishConfirmation(false);

  const handleSaveAndPublish = () => {
    changeUpdateConfig('publish', true);
    validateResolutions({ voteId: vote?.id });
  };

  const saveVoteSettings = () => {
    updateVoteSettings({
      voteId: vote.id,
      pollForVote: pollForVote,
      config: config,
    });
  };

  const refreshResolutions = (vote) => setResolutions(vote.resolutions);

  const renderResolutions = resolutions.map((resolution, index) => {
    return (
      <ResolutionForm
        resolution={resolution}
        key={resolution.id}
        index={index + 1}
        onDocumentClick={previewDocument}
        onDelete={removeResolution}
        resolutionCount={resolutions.length}
        root={root}
      />
    );
  });

  return (
    <>
      <AlertPopup
        show={showResolutionAlert}
        onClose={hideResolutionAlert}
        headerText={intl.formatMessage({ id: 'PollingForm.failure' })}
        popupType='failure'
      >
        <div className='text-black fs-14'>
          <FormattedMessage id='PollingForm.oneResolution' />
        </div>
      </AlertPopup>

      <Spinner show={isDeleting} />

      <PublishConfirmation vote={vote} show={showPublishConfirmation} onClose={hidePublishConfirmation} />

      <FileViewer
        fileName={fileViewerContext.file?.name}
        fileUrl={fileViewerContext.file?.url}
        isOpen={fileViewerContext.show}
        onClose={closeFileViewer}
      />

      <UploadWindow onResolutionDocumentUpload={refreshResolutions} />

      <div className='panel p-40 p-t-20'>
        <div className='row'>
          <div className='col-md-8'>
            <div>
              <div css={styles.resolutionHeading}>
                <span>
                  <FormattedMessage id='PollingForm.resolutions' />
                </span>
                <div css={styles.showTablet}>
                  <div className='btn btn-primary' onClick={addResolution}>
                    <FormattedMessage id='PollingForm.addNew' />
                  </div>
                </div>
              </div>
              <div css={styles.resolutionPanel}>
                {renderResolutions}
                <div className='d-flex' css={styles.hideMobile}>
                  <div className='btn btn-primary m-l-40 m-t-20' onClick={addResolution}>
                    <FormattedMessage id='PollingForm.addNew' />
                  </div>
                  <div className='m-t-25'>
                    <LoadingIndicator show={isCreating} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div css={[styles.resolutionHeading, styles.hideMobile]}>
              <FormattedMessage id='PollingForm.settings' />
            </div>
            <div css={styles.settingsPanel}>
              <ResolutionSettings
                pollForVote={pollForVote}
                setPollForVote={setPollForVote}
                config={config}
                setConfig={setConfig}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className='page-actions panel bs-small d-flex flex-wrap justify-content-center flex-row m-t-30 p-20'
        css={styles.saveButtonsPanel}
      >
        {!votePublished && (
          <>
            <a
              className='btn btn-responsive btn-secondary faded  mr-3 m-b-10'
              onClick={handleSave}
              css={styles.saveButton}
            >
              <FormattedMessage id='PollingForm.saveClose' />
            </a>
            <a
              className='btn btn-responsive btn-primary m-b-10'
              id='btn-save-vote'
              onClick={handleSaveAndPublish}
              css={styles.saveButton}
            >
              <FormattedMessage id='PollingForm.saveContinue' />
            </a>
          </>
        )}
        {votePublished && (
          <a className='btn btn-responsive btn-primary' onClick={handleSave}>
            <FormattedMessage id='PollingForm.save' />
          </a>
        )}
      </div>
    </>
  );
});

export { PollingForm };
