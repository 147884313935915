import { gql, useMutation } from '@apollo/client';
import { COMPANY_FRAGMENT } from 'data/v2/fragments/CompanyFragment';

const DELETE_COMPANY_LOGO_MUTATION = gql`
  mutation DeleteCompanyLogo($input: DeleteCompanyLogoInput!) {
    deleteCompanyLogo(input: $input) {
      company {
        ...CompanyFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${COMPANY_FRAGMENT}
`;

const useDeleteCompanyLogoMutation = (options) => useMutation(DELETE_COMPANY_LOGO_MUTATION, options);

export { DELETE_COMPANY_LOGO_MUTATION, useDeleteCompanyLogoMutation };
