import React, { useState } from 'react';
import Button from 'components/v2/shared/Button';
import { HiChevronDown } from 'react-icons/hi2';
import Filter from 'components/v2/shared/Filter';
import Label from 'components/v2/shared/Label';

const ConfirmDeleteModal = ({ onConfirm, onClose, replacementRoleList }) => {
  const roleFilterItems = replacementRoleList.map((item) => ({ id: item.id, name: item.role }));
  const [selected, setSelected] = useState(null);

  return (
    <div className='tw-mx-5 tw-my-[45px] tw-min-h-[220px] sm:tw-m-14'>
      <div className='tw-font-sans tw-text-xl tw-font-bold tw-text-black'>
        Are you sure you want to delete this role?
      </div>
      <div className='tw-mt-4 tw-font-sans tw-text-sm tw-font-medium tw-text-black'>
        All contacts with this role will be reassigned to the following:
      </div>
      <Label text='Role' className='!tw-mb-3 tw-mt-4 tw-font-sans tw-text-xs tw-font-semibold tw-text-[#95969F]' />
      <div className='tw-h-11'>
        <Filter
          items={roleFilterItems}
          placeholder='Role'
          iconRight={<HiChevronDown className='tw-h-6 tw-w-6' />}
          onSelect={(data) => {
            setSelected(data.id);
          }}
        />
      </div>
      <div className='tw-mt-7'>
        <div className='tw-flex tw-flex-col tw-gap-2 sm:tw-grid sm:tw-grid-flow-col sm:tw-grid-cols-2'>
          <div className='sm:tw-col-span-1'>
            <Button
              font='semibold'
              className='tw-h-11 tw-text-sm'
              isFullWidth
              size='large'
              variant='red'
              onClick={() => onConfirm(selected)}
            >
              Yes, delete role
            </Button>
          </div>
          <div className='sm:tw-col-span-1'>
            <Button
              font='semibold'
              className='tw-h-11 tw-border-[1px] !tw-border-solid tw-border-purple-500 tw-text-sm'
              isFullWidth
              onClick={onClose}
              size='large'
              variant='transparent-red'
            >
              No, keep role
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
