import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Placeholder = ({ message }) => (
  <div className='tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-text-center'>
    <div className='tw-break-normal tw-text-xl tw-font-bold tw-text-black'>{message}</div>
  </div>
);

Placeholder.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Placeholder;
