import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const icon = {
  base: css`
    cursor: pointer;
    margin-right: 12px;
  `,

  hoverable: css`
    &:hover {
      rect {
        fill: ${colors.cyan};
      }

      circle {
        fill: ${colors.white};
      }
    }
  `,
};

export const arrow = css`
  margin: 0 8px 0 0;
`;

export const container = css`
  position: relative;
  line-height: 1;
  white-space: nowrap;
`;

export const dropdown = css`
  z-index: 2;
  position: absolute;
  top: 20px;
  right: 25px;
  border-radius: 4px;
  padding: 8px;
  background: ${colors.white};
  box-shadow: 0px 3px 20px rgba(16, 8, 63, 0.11);
`;

export const scrollable = css`
  max-height: 400px;

  &.trackYVisible {
    width: 215px !important;
  }
`;
