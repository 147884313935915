import React, { useEffect, useState, useContext, Fragment } from 'react';
import Heading from 'components/v2/shared/Heading';
import Button from 'components/v2/shared/Button';
import Shape from 'components/v2/shared/Shape';
import Label from '../shared/Label/Label';
import Box from 'components/v2/shared/Box';
import Modal from 'components/v2/shared/Modal';
import Checkbox from 'components/v2/shared/Checkbox';
import Spinner from 'components/v2/shared/Spinner';
import SelectInput from 'components/v2/shared/SelectInput';
import { FiCheck, FiX, FiInfo } from 'react-icons/fi';
import PaymentForm from './PaymentForm';
import InvoiceDetails from './InvoiceDetails';
import InvoiceHistory from './InvoiceHistory';
import clsx from 'clsx';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import TextInput from 'components/v2/shared/TextInput';
import Alert from 'components/v2/shared/Alert';
import Inclusions from './Inclusions';
import axios from 'axios';
import { getAxiosHeaders } from 'utils/getHeaders';
import Toast, { Severity } from '../shared/Toast';
import { ToastContext } from 'contexts/v2/ToastContext';
import { useCurrentUserProfileQuery } from '../../../data/v2/queries/CurrentUserProfileQuery';
import { getCurrentBreakpoint } from 'helpers/tailwindStyling';
import useWindowDimensions from 'hooks/useWindowDimensions';

import Mastercard from 'images/icons/payment-icons/mastercard.svg';
import Discover from 'images/icons/payment-icons/discover.svg';
import Unionpay from 'images/icons/payment-icons/unionpay.svg';
import Diners from 'images/icons/payment-icons/diners.svg';
import Visa from 'images/icons/payment-icons/visa.svg';
import Amex from 'images/icons/payment-icons/amex.svg';
import JCB from 'images/icons/payment-icons/jcb.svg';

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 20 }, (_, index) => ({
  id: index,
  value: String(currentYear + index),
  label: String(currentYear + index),
}));
const months = Array.from({ length: 12 }, (_, index) => ({
  id: index,
  value: String(index + 1).padStart(2, '0'),
  label: String(index + 1).padStart(2, '0'),
}));

const PaymentContainer = ({ data, company, onDelete, onEdit, breakpoint }) => {
  const [isDeletePayment, setDeletePayment] = useState(false);
  const [isEditPayment, setEditPayment] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { setToastInfo } = useContext(ToastContext);
  const [alert, setAlert] = useState({ display: false, isValid: false });
  const [editAlert, setEditAlert] = useState({ display: false, isValid: false });
  const [isChecked, setChecked] = useState(data.primary);
  const [expiryMonth, setExpiryMonth] = useState(data.exp_month.toString().padStart(2, '0'));
  const [expiryYear, setExpiryYear] = useState(data.exp_year);

  const iconsMap = {
    mastercard: Mastercard,
    discover: Discover,
    unionpay: Unionpay,
    diners: Diners,
    visa: Visa,
    amex: Amex,
    jcb: JCB,
  };

  const handleDeletePayment = async () => {
    setLoading(true);

    try {
      const response = await axios.delete(`/${company.slug}/payment_methods/${data.id}`, getAxiosHeaders());

      onDelete(response.data);
      setToastInfo({
        severity: Severity.Success,
        status: 'Success',
        message: `Payment method has been deleted.`,
      });
    } catch (err) {
      setToastInfo({
        severity: Severity.Error,
        status: 'Error',
        message: err.message,
      });
    }

    setLoading(false);
    setDeletePayment(false);
  };

  const editPaymentMethod = async () => {
    setLoading(true);
    const payload = {
      exp_month: expiryMonth,
      exp_year: expiryYear,
      is_primary: isChecked,
      payment_method: data.stripe_id,
    };
    try {
      const response = await axios.put(`/${company.slug}/payment_methods/${data.id}`, payload, getAxiosHeaders());
      onEdit(response.data);
      setToastInfo({
        severity: Severity.Success,
        status: 'Success',
        message: `Payment method has been edited.`,
      });
    } catch (err) {
      setToastInfo({
        severity: Severity.Error,
        status: 'Error',
        message: err.message,
      });
    }
    setEditPayment(false);
    setLoading(false);
  };

  const onClickEdit = () => {
    if (data.primary) {
      setEditAlert({
        display: true,
        isValid: false,
      });
    } else {
      setChecked((state) => !state);
    }
  };

  const onClickToDelete = () => {
    if (data.primary) {
      setAlert({
        display: true,
        isValid: false,
      });
    } else {
      setDeletePayment((prev) => (prev = !prev));
    }
  };

  return (
    <>
      <Modal isOpen={isLoading} isDismissable={false} centered size='sm'>
        <div className=' tw-p-8'>
          <div className='tw-relative tw-mt-8 tw-mb-5 tw-p-5'>
            <Spinner />
          </div>
          <div className='tw-mt-8'>
            <div className='tw-text-center'>
              <span className='tw-text-center tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>
                Processing, please wait.
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <div className='tw-relative tw-mt-3.5'>
        <Box isSelected onClick={() => {}} className='!tw-bg-gray-100'>
          <div className='tw-w-full'>
            <div
              className={clsx('tw-flex', {
                'tw-justify-between': data.primary,
                'tw-justify-end': !data.primary,
              })}
            >
              {data.primary && (
                <div>
                  <Button
                    font='medium'
                    onClick={() => {}}
                    size='small'
                    variant='transparent'
                    className='!tw-pl-0 tw-font-sans tw-text-xs !tw-font-medium !tw-text-black'
                  >
                    Primary
                  </Button>
                </div>
              )}
              <div className='tw-flex tw-gap-2'>
                <Button
                  font='medium'
                  onClick={() => setEditPayment(true)}
                  size='small'
                  variant='transparent'
                  className='tw-font-sans tw-text-xs !tw-font-medium !tw-text-teal-500'
                >
                  Edit
                </Button>
                {['xs', 'sm', 'md'].includes(breakpoint) && (
                  <div onClick={() => onClickToDelete()} className='tw-text-gray-700'>
                    <FiX className='tw-cursor-pointer' />
                  </div>
                )}
              </div>
            </div>
            {alert.display && !alert.isValid && (
              <div className='tw-mt-2'>
                <Alert
                  description='You must add or select another card as your primary payment method before you can remove this card.'
                  isDismissable={false}
                  severity='warning'
                  className='tw-font-small !tw-bg-orange-200 !tw-font-sans tw-text-sm'
                />
              </div>
            )}
            <div className='tw-relative tw-w-full'>
              <div className='tw-mt-2.5 tw-grid tw-grid-flow-col sm:tw-grid-cols-12'>
                <div className='tw-grid tw-grid-flow-row tw-gap-2 sm:tw-col-span-11 sm:tw-grid-flow-col sm:tw-grid-cols-6'>
                  <div className='tw-col-span-6 tw-grid tw-grid-flow-col tw-grid-cols-5 tw-gap-2'>
                    <div className='tw-col-span-1 tw-grid tw-h-12 tw-w-full tw-place-content-center tw-bg-white tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>
                      <img className='tw-p-2' src={iconsMap[data.brand]} />
                    </div>
                    <div className='tw-col-span-3 tw-grid tw-h-12 tw-w-full tw-place-content-center tw-bg-white tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>
                      {['xs', 'sm', 'md'].includes(breakpoint)
                        ? `xxxx ${data.last_digits}`
                        : `xxxx xxxx xxxx ${data.last_digits}`}
                    </div>
                    <div className='tw-col-span-1 tw-grid tw-h-12 tw-w-full tw-place-content-center tw-bg-white tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>
                      {data.exp_month} / {data.exp_year.toString().slice(-2)}
                    </div>
                  </div>
                </div>
                {['lg', 'xl', '2xl'].includes(breakpoint) && (
                  <div
                    onClick={() => onClickToDelete()}
                    className='tw-absolute tw-right-0 tw-top-2.5 tw-text-gray-700 sm:tw-col-span-1'
                  >
                    <FiX className='tw-cursor-pointer' />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>
      </div>
      <Modal isOpen={isDeletePayment} onClose={() => setDeletePayment(false)} size='md' centered>
        <div className='tw-px-12 tw-py-16'>
          <div className='tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>Delete payment method</div>
          <div className='tw-text-14 tw-mt-4 tw-font-sans tw-text-black'>
            Are you sure you want to delete this payment method?
          </div>

          <div className='tw-mt-6 lg:tw-flex lg:tw-justify-between'>
            <Button
              font='semibold'
              className='tw-text-xs lg:tw-mr-2'
              isFullWidth
              size='large'
              variant='red'
              onClick={handleDeletePayment}
            >
              Yes, delete
            </Button>
            <Button
              font='semibold'
              className={clsx('tw-ring-solid tw-text-xs tw-text-red-500 tw-ring tw-ring-1 tw-ring-red-500', {
                'tw-mt-2': !['xl', '2xl'].includes(breakpoint),
              })}
              isFullWidth
              size='large'
              variant='transparent'
              onClick={() => {
                setDeletePayment(false);
              }}
            >
              No, keep
            </Button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={isEditPayment} onClose={() => setEditPayment(false)} size='md' centered>
        <div className='tw-px-12 tw-py-14'>
          <div className='tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>Edit payment method</div>
          <div className='tw-col-span-6 tw-grid tw-grid-flow-col tw-grid-cols-5 tw-gap-2 tw-py-2'>
            <div className='tw-col-span-1 tw-grid tw-h-12 tw-w-full tw-place-content-center tw-font-sans'>
              <img src={iconsMap[data.brand]} />
            </div>
            <div className='tw-text-md tw-col-span-3 tw-flex tw-h-12 tw-w-full tw-items-center tw-font-sans tw-font-semibold tw-text-black'>
              {['xs', 'sm', 'md'].includes(breakpoint)
                ? `xxxx ${data.last_digits}`
                : `xxxx xxxx xxxx ${data.last_digits}`}
            </div>
          </div>
          <div className='tw-mt-2 tw-grid tw-grid-flow-col tw-grid-cols-2 tw-gap-2'>
            <div className='tw-col-span-1 tw-overflow-y-auto'>
              <Label text='Expiry Month' size='large' />
              <SelectInput
                onChange={(data) => setExpiryMonth(data.value)}
                options={months}
                value={expiryMonth}
                placeholder={data.exp_month}
                listBorder
              />
            </div>
            <div className='tw-col-span-1 tw-overflow-y-auto'>
              <Label text='Expiry Year' size='large' />
              <SelectInput
                onChange={(data) => setExpiryYear(data.value)}
                options={years}
                value={expiryYear}
                placeholder={data.exp_year}
                listBorder
              />
            </div>
          </div>
          <div className='tw-mt-8'>
            <Checkbox
              isChecked={isChecked}
              onChange={() => onClickEdit()}
              label='Primary Card'
              className='!tw-h-8 !tw-w-8'
              labelClass='!tw-text-xs !tw-font-semibold'
              size='large'
            />
            {editAlert.display && !editAlert.isValid && (
              <div className='tw-mt-2'>
                <Alert
                  description='You must add or select another card as your primary payment method before you can edit this card.'
                  isDismissable={false}
                  severity='warning'
                  className='tw-font-small !tw-bg-orange-200 !tw-font-sans tw-text-sm'
                />
              </div>
            )}
          </div>
          <div className='tw-mt-10 tw-mb-6 tw-grid tw-grid-flow-col tw-grid-cols-2 tw-gap-2'>
            <div className='tw-col-span-1'>
              <Button
                font='semibold'
                className='tw-text-xs'
                isFullWidth
                onClick={() => editPaymentMethod()}
                size='large'
                variant='purple'
              >
                Save
              </Button>
            </div>
            <div className='tw-col-span-1'>
              <Button
                font='semibold'
                className='tw-text-xs'
                isFullWidth
                onClick={() => setEditPayment(false)}
                size='large'
                variant='lavander'
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const PaymentSuccess = ({ company, subscription, product, inclusions, onSelectPlan, invoices }) => {
  const [isActive, setActive] = useState(false);
  const [isActivePayment, setActivePayment] = useState(false);
  const [toCancelPlan, setToCancelPlan] = useState(false);
  const [toSeeInclusion, setToSeeInclusion] = useState(false);
  const [confirmCancelPlan, setconfirmCancelPlan] = useState('');
  const [currentSubcription] = useState(subscription);
  const [isPlanCancelled, setIsPlanCancelled] = useState(subscription.status !== 'active');
  const stripePromise = loadStripe(ENV['STRIPE_KEY']);
  const [payments, setPayments] = useState([]);
  const { toastInfo, setToastInfo } = useContext(ToastContext);
  const { data, loading } = useCurrentUserProfileQuery();
  const dimensions = useWindowDimensions();
  const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint(dimensions.width));

  useEffect(() => {
    setBreakpoint(getCurrentBreakpoint(dimensions.width));
  }, [dimensions.width]);

  const getToExpDate = () => {
    const currentDate = new Date();
    const expDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);

    const month = (expDate.getMonth() + 1).toString().padStart(2, '0');
    const day = expDate.getDate().toString().padStart(2, '0');
    const year = expDate.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const loadPaymentMethods = async () => {
    try {
      const response = await axios.get(`/${company.slug}/payment_methods`, getAxiosHeaders());
      setPayments(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    loadPaymentMethods();
  }, [data]);

  const onDelete = (payment) => {
    let index = payments.findIndex((obj) => obj.id === payment.id);
    if (index >= -1) {
      let paymentList = [...payments];
      paymentList.splice(index, 1);
      setPayments(paymentList);
    }
  };

  const onAddPayment = (payment) => {
    if (payment.primary) {
      let paymentList = [...payments];

      let index = paymentList.findIndex((obj) => obj.primary === true);

      paymentList = paymentList.map((obj, i) => (i === index ? { ...obj, primary: false } : obj));

      paymentList.unshift(payment);

      setPayments(paymentList);
    } else {
      setPayments((prev) => [...prev, payment]);
    }
  };

  const onEditPayment = (payment) => {
    let paymentList = [...payments];

    if (payment.primary) {
      let index = paymentList.findIndex((obj) => obj.primary === true);
      paymentList = paymentList.map((obj, i) => (i === index ? { ...obj, primary: false } : obj));
    }

    paymentList = paymentList.map((pm) =>
      pm.stripe_id === payment.stripe_id
        ? { ...pm, exp_month: payment.exp_month, exp_year: payment.exp_year, primary: payment.primary }
        : pm
    );
    paymentList.sort((a, b) => (a.primary === b.primary ? 0 : a.primary ? -1 : 1));

    setPayments(paymentList);
  };

  const handleCancelPlan = async () => {
    if (currentSubcription?.id) {
      try {
        await axios.put(`/${company.slug}/subscriptions/${currentSubcription.id}/cancel`, {}, getAxiosHeaders());
        setToCancelPlan(false);
        setIsPlanCancelled(true);
      } catch (error) {
        setToastInfo({
          severity: Severity.Error,
          status: 'Error',
          message: error.message,
        });
      }
    }
  };

  const onClickInvoiceHistory = () => {
    setActive(true);
  };
  const onClickAddPayment = () => {
    setActivePayment(true);
  };
  const onClose = () => {
    setActivePayment(false);
  };
  const currentPage = () => {
    setActive(false);
  };
  const onDeletePayment = (val) => {
    setDeletePayment(val);
  };
  return (
    <>
      {!isActive && (
        <>
          <div className='tw-items-center sm:tw-flex'>
            <div className='tw-hidden tw-py-9 sm:tw-block'>
              <Heading text='Billing & Invoices' className='tw-mb-0' />
            </div>

            <div className='tw-ml-auto tw-flex'>
              <div className='tw-hidden sm:tw-block'>
                <Button
                  font='medium'
                  onClick={onClickInvoiceHistory}
                  size='small'
                  variant='transparent'
                  className='tw-font-sans tw-text-xs !tw-font-medium !tw-text-teal-500'
                >
                  Full invoice history
                </Button>
              </div>
            </div>
          </div>
          <div className='tw-mt-2'>
            <p className='tw-mb-0 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Your plan</p>
          </div>
          <div className='tw-mt-5'>
            <p className='tw-mb-2.5 tw-font-sans tw-text-xs tw-font-medium tw-text-black'>
              *We will need to verify your status after sign up.
            </p>
          </div>
          <div className='tw-mt-3.5'>
            <div
              className={clsx('tw-rounded tw-p-6', {
                'tw-bg-green-100': !isPlanCancelled,
                'tw-bg-orange-100': isPlanCancelled,
              })}
            >
              <div className='sm:tw-h-9'>
                <div className='tw-relative tw-mb-6 sm:tw-float-right sm:tw-mb-0'>
                  {!isPlanCancelled && (
                    <FiCheck className='tw-absolute tw-top-1.5 tw-left-2 tw-font-sans tw-text-lg tw-font-medium tw-text-black' />
                  )}
                  {isPlanCancelled && (
                    <FiInfo className='tw-absolute tw-top-1.5 tw-left-2 tw-font-sans tw-text-lg tw-font-medium tw-text-white' />
                  )}
                  <Shape
                    variant='circle'
                    className={clsx('!tw-h-8 !tw-w-8', {
                      '!tw-bg-green-500': !isPlanCancelled,
                      '!tw-bg-orange-600': isPlanCancelled,
                    })}
                  />
                </div>
                <h5 className='tw-font-sans tw-text-lg tw-font-semibold sm:tw-float-left sm:tw-mt-0'>
                  {product?.name}
                </h5>
              </div>
              <div className='tw-mt-8 sm:tw-mt-5'>
                <p className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>
                  ${product?.price} {product?.currency?.toUpperCase()} / {product?.interval}
                </p>
              </div>
              {!isPlanCancelled && (
                <div className='tw-mt-5 sm:tw-mt-3'>
                  <p className='tw-font-sans tw-text-xs tw-font-medium tw-text-black'>
                    {product.interval}ly subscription, next billed {subscription.deactivated_at}
                  </p>
                </div>
              )}
              {isPlanCancelled && (
                <div className='tw-item-center tw-mt-5 tw-flex tw-w-full tw-justify-center tw-rounded-sm tw-bg-orange-500 !tw-pt-4 sm:tw-mt-3'>
                  <p className='tw-text-center tw-font-sans tw-text-xs tw-font-medium tw-text-black'>
                    This plan will expire in 30 days, on {subscription.deactivated_at}
                  </p>
                </div>
              )}
              <div className='tw-mt-5 sm:tw-mt-3'>
                <p className='tw-font-sans tw-text-xs tw-font-medium tw-text-black'>
                  Prices are per {product.interval} / per boardspace, paid {product.interval}.Exclude VAT.
                </p>
              </div>

              <div className='tw-mt-8'>
                <div className='tw-flex tw-justify-between'>
                  <div>
                    <Button
                      font='medium'
                      onClick={() => setToSeeInclusion(true)}
                      size='small'
                      variant='transparent'
                      className={clsx('!tw-pl-0 tw-font-sans tw-text-xs !tw-font-medium', {
                        '!tw-text-teal-500': !isPlanCancelled,
                        '!tw-text-orange-600': isPlanCancelled,
                      })}
                    >
                      See inclusions
                    </Button>
                  </div>
                  <div>
                    <Button
                      font='medium'
                      onClick={
                        isPlanCancelled
                          ? () => {}
                          : () => {
                              setToCancelPlan((prev) => (prev = !prev));
                            }
                      }
                      size='small'
                      variant='transparent'
                      className='tw-font-sans tw-text-xs !tw-font-medium !tw-text-black'
                    >
                      {isPlanCancelled && (
                        <span onClick={onSelectPlan} className='tw-text-orange-600'>
                          Select Plan
                        </span>
                      )}
                      {!isPlanCancelled && <span className='tw-text-black'>Cancel Plan</span>}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='tw-mt-9'>
            <div className='tw-flex tw-justify-between'>
              <div>
                <Button
                  font='semibold'
                  onClick={() => {}}
                  size='small'
                  variant='transparent'
                  className='!tw-pl-0 tw-font-sans tw-text-sm !tw-font-semibold !tw-text-black'
                >
                  Payment method (s)
                </Button>
              </div>
              <div>
                <Button
                  font='medium'
                  onClick={onClickAddPayment}
                  size='small'
                  variant='transparent'
                  className='tw-pt-3 tw-font-sans tw-text-xs !tw-font-medium !tw-text-teal-500'
                >
                  Add payment method
                </Button>
              </div>
            </div>
          </div>
          {payments.map((payment, index) => {
            return (
              <PaymentContainer
                key={index}
                data={payment}
                company={company}
                onDelete={onDelete}
                onEdit={onEditPayment}
                breakpoint={breakpoint}
              />
            );
          })}
          <Toast severity={toastInfo.severity} status={toastInfo.status} message={toastInfo.message} />
          {!loading && data?.currentUser ? (
            <div>
              <InvoiceDetails currentUser={data.currentUser} invoices={invoices} />
            </div>
          ) : null}
          <div className='tw-mt-4'>
            <div className='tw-float-right'>
              <Button
                font='medium'
                onClick={onClickInvoiceHistory}
                size='small'
                variant='transparent'
                className='tw-mb-9 tw-font-sans tw-text-xs !tw-font-medium !tw-text-teal-500'
              >
                See full invoice history
              </Button>
            </div>
          </div>
        </>
      )}

      {isActive && !loading && data?.currentUser && (
        <InvoiceHistory onClick={currentPage} company={company} currentUser={data.currentUser} invoiceList={invoices} />
      )}
      <Modal isOpen={isActivePayment} onClose={onClose} size='md' centered>
        <Elements stripe={stripePromise}>
          <PaymentForm onClose={onClose} company={company} onAddPayment={onAddPayment} />
        </Elements>
      </Modal>
      <Modal isOpen={toSeeInclusion} onClose={() => setToSeeInclusion(false)} size='md' centered>
        <div className='tw-px-12 tw-py-16'>
          <div className='tw-flex tw-justify-between'>
            <div className='tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>{product?.name}</div>
            <div className='tw-text-md tw-mt-1 tw-font-sans'>
              ${product?.price} {product?.currency?.toUpperCase()} / {product?.interval}
            </div>
          </div>
          <div className='tw-text-md tw-mt-6 tw-font-sans tw-font-medium tw-font-semibold tw-text-black'>
            Inclusions
          </div>
          <div className='tw-my-6'>
            {inclusions.map((row, i) => {
              return (
                <div key={i} className='tw-h-8'>
                  <div className='tw-font-sm tw-font-sans tw-text-sm tw-text-black'>{row.title}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
      <Modal isOpen={toCancelPlan} onClose={() => setToCancelPlan(false)} size='xl' centered>
        <div className='tw-px-12 tw-py-16'>
          <div className='tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>
            Are you sure you want to cancel this plan?
          </div>
          <div className='tw-text-14 tw-mt-4 tw-font-sans tw-text-black'>
            You will continue to have full access to Shaparency until the end of the current billing perios
          </div>
          <div className='tw-mt-9 tw-rounded-md !tw-bg-orange-500 tw-py-4 tw-px-8'>
            If you cancel, this plan will expire in 30 days on {getToExpDate()}
          </div>
          <div className='tw-mt-6'>
            <TextInput
              label='Write CANCEL to confirm'
              onChange={(e) => setconfirmCancelPlan(e.target.value)}
              placeholder='CANCEL'
              size='regular'
              value={confirmCancelPlan}
            />
          </div>
          <div className='tw-mt-6 lg:tw-flex lg:tw-justify-between'>
            <Button
              font='semibold'
              className='tw-text-xs lg:tw-mr-2'
              isFullWidth
              size='large'
              variant='red'
              onClick={() => {
                handleCancelPlan();
              }}
              isDisabled={confirmCancelPlan !== 'CANCEL'}
            >
              Yes, cancel plan
            </Button>
            <Button
              font='semibold'
              className={clsx('tw-ring-solid tw-text-xs tw-text-red-500 tw-ring tw-ring-1 tw-ring-red-500', {
                'tw-mt-2': !['xl', '2xl'].includes(breakpoint),
              })}
              isFullWidth
              size='large'
              variant='transparent'
              onClick={() => {
                setToCancelPlan(false);
              }}
            >
              No, keep plan
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentSuccess;
