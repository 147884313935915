import React from 'react';

const ComposeProviders = (providers) =>
  providers.reduce((Composed, Current) => ({ children }) => (
    <Composed>
      <Current>{children}</Current>
    </Composed>
  ));

export default ComposeProviders;
