import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import VisibilityWrapper from 'components/v2/shared/VisibilityWrapper';
import MenuItem from 'components/v2/layout/MenuItem';
import ProfileItem from 'components/v2/layout/ProfileItem';
import LogOutItem from 'components/v2/layout/LogOutItem';
import { FiChevronRight } from 'react-icons/fi';
import { intl } from 'contexts/v2/TranslationContext';

const Menu = ({ isViewingMenu, currentCompany, currentContact }) => {
  const menuItemProps = (path) => ({
    isSelected: window.location.href.indexOf(path) > -1,
    href: `/${currentCompany.slug}/${path}`,
  });

  return (
    <VisibilityWrapper
      isVisible={isViewingMenu}
      visibleClass='tw-flex'
      className={clsx(
        'tw-fixed tw-inset-0 tw-top-[var(--nav-height)] tw-grow tw-flex-col',
        'tw-z-max tw-overflow-auto tw-bg-white',
        'xl:tw-static xl:tw-flex xl:tw-flex-row xl:tw-items-center xl:tw-justify-center xl:tw-gap-8'
      )}
    >
      {currentContact.id && (
        <>
          <MenuItem {...menuItemProps('dashboard')}>
            {intl.formatMessage({ id: 'Navigation.dashboard' })}
            <FiChevronRight className='tw-h-4 tw-w-4 xl:tw-hidden' />
          </MenuItem>

          <MenuItem {...menuItemProps('documents')}>
            {intl.formatMessage({ id: 'Navigation.documents' })}
            <FiChevronRight className='tw-h-4 tw-w-4 xl:tw-hidden' />
          </MenuItem>

          <MenuItem {...menuItemProps('meetings')}>
            {intl.formatMessage({ id: 'Navigation.meetings' })}
            <FiChevronRight className='tw-h-4 tw-w-4 xl:tw-hidden' />
          </MenuItem>

          <MenuItem {...menuItemProps('minutes')}>
            {intl.formatMessage({ id: 'Navigation.minutes' })}
            <FiChevronRight className='tw-h-4 tw-w-4 xl:tw-hidden' />
          </MenuItem>

          <MenuItem {...menuItemProps('companies')}>
            {intl.formatMessage({ id: 'MobileMenu.companies' })}
            <FiChevronRight className='tw-h-4 tw-w-4 xl:tw-hidden' />
          </MenuItem>

          <MenuItem {...menuItemProps('smart_sign')}>
            {intl.formatMessage({ id: 'Navigation.smartSign' })}
            <FiChevronRight className='tw-h-4 tw-w-4 xl:tw-hidden' />
          </MenuItem>

          <MenuItem {...menuItemProps('action_items')}>
            {intl.formatMessage({ id: 'Navigation.taskboard' })}
            <FiChevronRight className='tw-h-4 tw-w-4 xl:tw-hidden' />
          </MenuItem>

          <MenuItem {...menuItemProps('voting')}>
            {intl.formatMessage({ id: 'Navigation.voting' })}
            <FiChevronRight className='tw-h-4 tw-w-4 xl:tw-hidden' />
          </MenuItem>

          <MenuItem {...menuItemProps('profile')} className='xl:tw-hidden'>
            <ProfileItem />
            <FiChevronRight className='tw-h-4 tw-w-4 xl:tw-hidden' />
          </MenuItem>
        </>
      )}

      <MenuItem href='/users/sign_out' data-method='delete' className='xl:tw-hidden'>
        <LogOutItem />
        <FiChevronRight className='tw-h-4 tw-w-4 xl:tw-hidden' />
      </MenuItem>
    </VisibilityWrapper>
  );
};

Menu.propTypes = {
  isViewingMenu: PropTypes.bool,
  currentCompany: PropTypes.object,
  currentContact: PropTypes.object,
};

export default Menu;
