import { gql, useQuery } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';
import { DOCUMENT_FRAGMENT } from './../fragments/DocumentFragment';

const FAVOURITE_DIRECTORIES_QUERY = gql`
  query favouriteDirectories {
    currentContact {
      id

      directories: favouriteDirectories {
        ...DirectoryFields
      }

      documents: favouriteDocuments {
        ...DocumentFields
      }
    }
  }
  ${DIRECTORY_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
`;

const useFavouriteDirectoriesQuery = (options) => useQuery(FAVOURITE_DIRECTORIES_QUERY, options);

export { FAVOURITE_DIRECTORIES_QUERY, useFavouriteDirectoriesQuery };
