import React from 'react';
import { DirectUpload } from '@rails/activestorage';

export const uploadFile = (file, { onInit = () => {}, onProgress = () => {} }) => {
  return new Promise((resolve, reject) => {
    new DirectUpload(file, '/rails/active_storage/direct_uploads', {
      directUploadWillStoreFileWithXHR: (xhr) => {
        onInit(xhr);

        xhr.upload.addEventListener('progress', (event) => onProgress(Math.round((event.loaded * 100) / event.total)));
      },
    }).create((error, blob) => {
      if (error) {
        reject(error);
      } else {
        resolve(blob);
      }
    });
  });
};
