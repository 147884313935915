/** @jsx jsx */
import React, { memo, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';

import { ResolutionDocumentListItem } from '../ResolutionDocumentListItem';
import { ResolutionDocumentsUpload } from '../ResolutionDocumentsUpload';

import { useDeleteResolutionDocument } from './../hooks/useDeleteResolutionDocument';
import { useAttachResolutionDocument } from './../hooks/useAttachResolutionDocument';

import { Spinner } from 'components/shared/Spinner';

import { ReactComponent as PlusIcon } from 'images/icons/agenda-plus.svg';
import { ReactComponent as MinusIcon } from 'images/icons/agenda-minus.svg';
import { ReactComponent as DocumentsIcon } from 'images/icons/agenda-documents.svg';

import * as styles from './ResolutionDocumentList.styles';

const ResolutionDocumentList = memo(
  ({ resolution, root, showDocuments, onDocumentClick, toggleShowDocuments, isFullWidth }) => {
    const onDeletedResolutionDocument = (resolutionDocumentId) => {
      setResolutionDocuments(resolutionDocuments.filter((document) => document.id !== resolutionDocumentId));
    };

    const { isDeleting, deleteResolutionDocument } = useDeleteResolutionDocument({
      onCompleted: onDeletedResolutionDocument,
    });

    const onDocumentAdded = (resolution) => {
      setResolutionDocuments(resolution.resolutionDocuments);
    };

    const { isAttaching, attachResolutionDocument } = useAttachResolutionDocument({
      resolutionId: resolution.id,
      onCompleted: onDocumentAdded,
    });

    const onBrowseUpload = (documentIds) => attachResolutionDocument(documentIds);

    const [resolutionDocuments, setResolutionDocuments] = useState(resolution.resolutionDocuments);

    useEffect(() => setResolutionDocuments(resolution.resolutionDocuments), [resolution.resolutionDocuments]);

    const renderDocuments = resolutionDocuments.map((resolutionDocument) => {
      return (
        <ResolutionDocumentListItem
          key={resolutionDocument.id}
          resolutionDocument={resolutionDocument}
          onDocumentClick={onDocumentClick}
          onDelete={deleteResolutionDocument}
        />
      );
    });

    return (
      <>
        <Spinner show={isDeleting || isAttaching} />

        <div css={styles.documentWrapper({ isFullWidth })}>
          <div className='d-flex justify-content-between' onClick={toggleShowDocuments}>
            <div className='d-flex align-items-start'>
              <DocumentsIcon className='m-t-5' />
              <div className='m-l-5 text-black fs-14'>{`Files attached (${resolutionDocuments.length})`}</div>
            </div>
            <div css={styles.showDocumentsIcon} id='toggle-docs'>
              {showDocuments ? <MinusIcon /> : <PlusIcon />}
            </div>
          </div>

          {showDocuments && (
            <div className='m-t-20'>
              <div className='m-b-5'>{renderDocuments}</div>

              <ResolutionDocumentsUpload resolution={resolution} root={root} onBrowseUpload={onBrowseUpload} />
            </div>
          )}
        </div>
      </>
    );
  }
);

export { ResolutionDocumentList };
