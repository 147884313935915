import { colors } from 'styles';

export const component = {
  container: (styles) => ({
    ...styles,
  }),

  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    minHeight: 34,
    border: '1px solid rgba(152, 164, 175, 0.4)',
    borderRadius: 2,
    boxShadow: 'none',
    outline: 'none',

    '&:hover': {
      borderColor: 'rgba(152, 164, 175, 0.4)',
      cursor: 'pointer',
    },
  }),

  input: (styles) => ({
    height: 0,
    margin: 0,
    padding: 0,
    outline: 'none',
  }),

  indicatorSeparator: (styles) => ({
    display: 'none',
  }),

  dropdownIndicator: (styles, state) => ({
    ...styles,
    fontSize: 14,
    marginRight: 5,

    i: {
      transition: 'all .2s ease',
      color: colors.lightGrey,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      fontWeight: 300,
    },
  }),

  indicatorsContainer: (styles) => ({
    div: {
      padding: '5px 7px',
      color: 'hsl(0, 0%, 80%)',

      '&:hover': {
        color: 'hsl(0, 0%, 80%)',
      },
    },
  }),

  valueContainer: (styles) => ({
    ...styles,
    flexWrap: 'nowrap',
    fontSize: 12,
    color: 'black',
    overflow: 'hidden',
    padding: '7px 9px',
    lineHeight: 1.5,
    minWidth: 0,
    display: 'initial',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),

  menu: (styles) => ({
    ...styles,
    boxShadow: '0px 1.2px 3.6px rgba(0, 72, 90, 0.1), 0px 6.4px 14.4px rgba(0, 77, 97, 0.13)',
    marginTop: 0,
    marginBottom: 0,
    zIndex: 9,
  }),

  menuList: (styles) => ({
    ...styles,
    maxHeight: 350,
    padding: 0,

    '@media (max-width: 991px)': {
      maxHeight: 150,
    },
  }),

  placeholder: (styles) => ({
    ...styles,
    fontSize: 12,
    color: '#787D96',
  }),

  option: (styles) => ({
    ...styles,
    padding: '8px 0px',
    display: 'flex',
    marginTop: 0.5,
    alignItems: 'center',
    lineHeight: 1.5,
    outline: 'none',
    backgroundColor: 'white',

    label: {
      margin: 0,
      padding: '2px 0 0 8px',
      marginLeft: 10,
      color: 'black',
      fontSize: 12,

      '&:hover': {
        cursor: 'pointer',
      },

      '&:before': {
        marginLeft: '-14px !important',
      },

      '&:after': {
        left: '6px !important',
      },
    },

    input: {
      paddingRight: 6,
      width: 16,
      height: 16,
    },

    '.single-option-wrapper': {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      justifyContent: 'space-between',
      padding: '0 9px',

      label: {
        marginLeft: 0,
        padding: 2,
      },
    },

    '&:hover': {
      background: '#E6F8F9',
      cursor: 'pointer',
    },
  }),

  multiValue: (styles) => ({
    ...styles,
    background: 'none',
  }),

  multiValueRemove: (styles) => ({
    display: 'none',
  }),
};
