/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as DocumentIcon } from 'images/icons/agenda-document.svg';

import * as styles from './Uploadable.styles';

const Uploadable = memo(({ name, percentage, error, uploaded, onCancel, context }) => {
  if (uploaded) return;

  const progressBarStyles = styles.uploadable.progress(percentage);

  const uploadableStyles = [styles.uploadable.base, error && styles.uploadable.invalid];

  return (
    <div css={uploadableStyles}>
      <div css={progressBarStyles} />

      <div className='d-flex align-items-center z-i-3'>
        {context !== 'vote' && <div className='font-weight-bold fs-14 color-black m-r-10'>-</div>}

        <DocumentIcon />

        <div className='m-l-10'>{name}</div>
      </div>

      <div css={styles.warn} className='z-i-3 fs-14 m-r-35'>
        {error}
      </div>

      <div className='z-i-3 font-weight-bold fs-14 m-r-15'>{percentage}%</div>

      <div className='z-i-3'>
        {error && (
          <a onClick={onCancel} className='cursor-pointer' css={styles.warn}>
            <FormattedMessage id='Uploadable.cancel' />
          </a>
        )}
      </div>
    </div>
  );
});

export { Uploadable };
