/** @jsx jsx */

import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { createPortal } from 'react-dom';
import Draggable from 'react-draggable';
import { useIntl, FormattedMessage } from 'react-intl';

import { ReactComponent as Cross } from 'images/icons/cross.svg';
import { ReactComponent as Signature } from 'images/icons/signature.svg';
import useWindowDimensions from 'hooks/useWindowDimensions';

import * as styles from './Field.styles';

export const Field = ({
  selectField,
  updateField,
  removeField,
  field,
  page,
  position,
  isSignature,
  forSignature,
  signatureURL,
  isSelected,
  size,
  current,
  isDisabled,
}) => {
  const pageWidth = $(page).width();
  const pageHeight = $(page).height();
  const [fieldPosition, setFieldPosition] = useState({
    x: (pageWidth / 100) * position.x,
    y: (pageHeight / 100) * position.y,
  });
  const { isMobile } = useWindowDimensions();

  const intl = useIntl();

  useEffect(() => {
    setFieldPosition({
      x: (pageWidth / 100) * position.x,
      y: (pageHeight / 100) * position.y,
    });
  }, [pageWidth, pageHeight, position, setFieldPosition]);

  const handleDragEnd = (e, data) => {
    updateField(field, {
      x: (data.x * 100) / pageWidth,
      y: (data.y * 100) / pageHeight,
    });
  };

  const handleDragStart = (e) => {
    e.stopPropagation();

    selectField(field);
  };

  const handleRemove = (e) => {
    e.stopPropagation();

    removeField(field);
  };

  const bounds = {
    left: 30,
    top: 25,
    right: pageWidth * (1 - size.width / 100),
    bottom: pageHeight * (1 - size.height / 100),
  };

  const arrowTooltip = (
    <div css={styles.arrow(position.y)} id={`arrow-${field.id}`}>
      <div css={styles.rectangle(current === field.id)}>
        {isSignature ? intl.formatMessage({ id: 'Field.sign' }) : intl.formatMessage({ id: 'Field.date' })}
      </div>
      <div css={styles.triangleRight(current === field.id)} />
    </div>
  );

  const renderFieldContent = () => {
    if (signatureURL) {
      return (
        <>
          <img css={styles.signature} src={signatureURL} />
          <span css={styles.resignLabel} className='c-secondary fs-12 font-weight-bold'>
            <FormattedMessage id='Field.reSign' />
          </span>
        </>
      );
    }

    if (forSignature && !isSignature) {
      return <div css={styles.dateField}>{field.value}</div>;
    }

    return (
      <div css={styles.content(forSignature)}>
        <div css={styles.topRightDot(isSelected)} />
        <div css={styles.topLeftDot(isSelected)} />
        <div css={styles.bottomLeftDot(isSelected)} />
        <div css={styles.bottomRightDot(isSelected)} />

        <span className='react-remove-field' onClick={handleRemove} css={styles.remove(isSelected)}>
          <Cross />
        </span>

        <div css={styles.field}>
          <div css={styles.placeholder(forSignature)} className='w-100'>
            {isSignature ? (
              <Signature />
            ) : (
              <div>
                {isMobile ? null : <i className='fs-14 far fa-calendar-alt p-r-5' />}
                <span>{field.value}</span>
              </div>
            )}
          </div>

          <div className='text-truncate' css={styles.signatory(forSignature)}>
            {forSignature ? intl.formatMessage({ id: 'Field.clickToSign' }) : field.signatoryName}
          </div>
        </div>
      </div>
    );
  };

  const component = (
    <>
      {forSignature && arrowTooltip}

      <Draggable
        disabled={forSignature}
        position={forSignature ? null : fieldPosition}
        cancel='.react-remove-field'
        onStop={field.disabled ? undefined : handleDragEnd}
        onStart={field.disabled ? undefined : handleDragStart}
        axis='both'
        bounds={bounds}
      >
        <div
          onClick={forSignature ? selectField : undefined}
          className={`react-smart-sign-block ${isSelected ? 'active' : null}`}
          css={styles.wrapper(field.disabled || isDisabled, isSelected, size, forSignature && !isSignature)}
          style={forSignature ? { top: `${position.y}%`, left: `${position.x}%` } : null}
        >
          {renderFieldContent()}
        </div>
      </Draggable>
    </>
  );

  if (!page) {
    return null;
  }
  return createPortal(component, page);
};
