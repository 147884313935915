/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { COLORS, VARIANTS } from './constants';

import * as styles from './Chip.styles';

const Chip = ({ color = 'grey', variant = 'rounded', children, className, onClick }) => (
  <div
    onClick={onClick}
    className={className}
    css={[styles.chip.base, styles.chip[color], styles.chip[variant], onClick && styles.chip.clickable]}
  >
    {children}
  </div>
);

Chip.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  variant: PropTypes.oneOf(VARIANTS),
  color: PropTypes.oneOf(COLORS),
  children: PropTypes.node,
};

export default Chip;
