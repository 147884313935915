import { useMoveDocumentMutation } from './../data/mutations/MoveDocumentMutation';
import { useApolloCacheHelpers } from './../hooks/useApolloCacheHelpers';

const useMoveDocument = ({ parent, onCompleted, onError }) => {
  const { addDocumentToCache, removeDocumentFromCache } = useApolloCacheHelpers();

  const update = (cache, { data: { moveDocument } }) => {
    const { document, errors } = moveDocument;

    if (errors.length) {
      return onError(errors[0].fullMessage);
    }

    removeDocumentFromCache(parent, document);
    addDocumentToCache(document.parent, document);
    onCompleted();
  };

  const [mutate, { loading: isMoving }] = useMoveDocumentMutation({ update });

  const moveDocument = (documentId, destinationId) => {
    mutate({ variables: { input: { documentId, destinationId } } });
  };

  return { moveDocument, isMoving };
};

export { useMoveDocument };
