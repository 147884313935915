import { useCreateSubcommitteeMutation } from '../data/mutations/CreateSubcommitteeMutation';
import { SUBCOMMITTEE_FRAGMENT } from '../data/fragments/SubcommitteeFragment';
import { SUBCOMMITTEES_QUERY } from '../data/queries/SubcommitteesQuery';

const useCreateSubcommittee = ({ onError, companyId }) => {
  const refetchQueries = [{ query: SUBCOMMITTEES_QUERY }];
  const update = (cache, { data: { createSubcommittee } }) => {
    const { subcommittee, errors } = createSubcommittee;

    if (errors.length) {
      return onError(errors);
    }

    cache.modify({
      id: cache.identify({ __typename: 'Company', id: companyId }),
      fields: {
        subcommittees(existing = [], { readField }) {
          const isPresent = existing.find((item) => readField('id', item) === subcommittee.id);

          if (isPresent) {
            return existing;
          }

          return [
            ...existing,
            cache.writeFragment({
              data: subcommittee,
              fragment: SUBCOMMITTEE_FRAGMENT,
              fragmentName: 'SubcommitteeFields',
            }),
          ];
        },
      },
    });
  };

  const [mutate] = useCreateSubcommitteeMutation({ update, refetchQueries });

  const createSubcommittee = async ({ name, contacts }) => {
    const res = await mutate({ variables: { input: { name, contacts } } });
    return res;
  };

  return { createSubcommittee };
};

export { useCreateSubcommittee };
