import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const label = css`
  ${fonts.semiBold}
  font-size: 12px;
  line-height: 16px;
  color: ${colors.black};
  display: block;
  margin-bottom: 12px;
`;
