import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const modal = css`
  .modal-dialog.shaparency {
    height: 598px;

    .modal-content .modal-header {
      padding-right: 30px;
    }
  }
`;
