import React, { useState, useEffect, useContext, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Drawer } from 'components/shared/Drawer';
import useWindowDimensions from 'hooks/useWindowDimensions';
import AppHeader from '../../shared/AppHeader';
import { ToolbarButtonWrapper, IconButton, HeaderTitle } from '../../shared/AppHeader/styles';
import { Viewer } from '../Viewer';
import { Field } from '../Field';
import { Toolbar } from '../Toolbar';
import { SendingSignContext } from '../../../contexts/MobSignContext';
import { AddFieldBtn, SendForSigningMob } from './styles';
import { ShowNavigation } from '../Signatures/styles';
import * as styles from './Workspace.styles';

export const ConfigureForMobile = ({
  setMoveToMobConfigure,
  documentFields,
  changeDocumentFields,
  signatories,
  documentIsConfigured,
  documentURL,
  saveConfiguration,
  fieldTypes,
  dateTypes,
  isSignature,
  drawerIsOpen,
  setDrawerIsOpen,
}) => {
  const { setIsOpendSendingSign, setIsOpendSignModal } = useContext(SendingSignContext);
  const { isMobile, isTablet } = useWindowDimensions();
  const activeDocumentFields = documentFields.filter((documentField) => !documentField.deleted);
  const defaultDateFormat = '%d/%m/%Y';
  const documentFieldSize = {
    width: 21,
    height: 6.835,
  };
  const dateTypeOptions = Object.keys(dateTypes).map((key) => {
    return {
      value: key,
      label: dateTypes[key],
    };
  });
  const [activeSignatory, setActiveSignatory] = useState();
  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [activeFieldType, setActiveFieldType] = useState(fieldTypes.signature);
  const [activeField, setActiveField] = useState();
  const [hideDrawer, setHideDrawer] = useState(false);
  const [tempField, setTempField] = useState({
    page: 1,
    active: false,
    position: {
      x: 1000,
      y: 0,
    },
    disabled: true,
    format: defaultDateFormat,
    value: dateTypes[defaultDateFormat],
  });
  const populateTempField = () => {
    if (!activeFieldType && !activeSignatory) return;

    setTempField({
      ...tempField,
      type: activeFieldType,
      signatoryId: activeSignatory?.id,
      signatoryName: activeSignatory?.name,
    });
  };

  useEffect(() => populateTempField(), [activeFieldType, activeSignatory]);

  const markDocumentAsLoaded = () => {
    setDocumentLoaded(true);
  };

  const isActiveSignatory = (signatory) => {
    return activeSignatory && activeSignatory.id === signatory.id;
  };

  const isActiveFieldType = (name) => {
    return activeFieldType && activeFieldType === name;
  };

  const toggleActiveField = (field) => {
    setActiveFieldType(isActiveFieldType(field) ? null : field);

    setActiveField(null);
  };

  const toggleActiveSignatory = (signatory) => {
    if (isActiveSignatory(signatory)) {
      setActiveSignatory(null);
    } else {
      setActiveSignatory(signatory);
      setActiveFieldType(fieldTypes.signature);
    }

    setActiveField(null);
  };

  const updateDocumentField = (documentField, params) => {
    if (documentField.disabled) {
      return setTempField({ ...tempField, ...params });
    }

    const index = documentFields.findIndex((field) => field.id === documentField.id);
    const currentFields = [...documentFields];

    currentFields[index] = { ...documentField, ...params };

    changeDocumentFields(currentFields);

    if (!params.deleted) {
      setActiveField(currentFields[index]);
    }
  };

  const unsetControls = () => {
    setActiveField(null);
    setActiveSignatory(null);
    setActiveFieldType(null);
  };

  const removeDocumentField = (documentField) => {
    updateDocumentField(documentField, { deleted: true });
    unsetControls();
  };

  const updateFieldCoordinates = (documentField, coordinates) => {
    updateDocumentField(documentField, { position: coordinates });
  };

  const getRelativeCoordinates = (x, y, parent) => {
    return {
      y: ((y - parent.offset().top) * 100) / parent.height(),
      x: ((x - parent.offset().left) * 100) / parent.width(),
    };
  };

  const documentPage = ({ page }) => {
    return document.querySelector(`.canvas-page-wrapper[data-page='${page}']`);
  };

  const addDocumentField = (f, e) => {
    const canvas = documentPage(f);
    const documentFieldId = Math.random().toString(36).substr(2, 9);
    const position = getRelativeCoordinates(e.clientX - 50, e.clientY - 20, $(canvas));

    const documentField = {
      id: documentFieldId,
      signatoryId: activeSignatory.id,
      signatoryName: activeSignatory.name,
      type: activeFieldType,
      position: position,
      page: canvas.dataset.page,
      format: tempField.format,
      width: documentFieldSize.width,
      height: documentFieldSize.height,
      value: tempField.value,
    };

    changeDocumentFields([...documentFields, documentField]);
    setActiveField(documentField);
  };

  const handleViewportClick = (e) => {
    if (displayTempField) {
      addDocumentField(tempField, e);
    } else {
      setActiveField(null);
    }
  };

  const displayTempField = activeSignatory && activeFieldType && tempField.active && !activeField;

  const showTempField = () => {
    setTempField({ ...tempField, active: true });
  };

  const hideTempField = () => {
    setTempField({ ...tempField, active: false });
  };

  const moveTempField = (e) => {
    const canvas = e.target.closest('.canvas-page-wrapper');
    const width = $(canvas).width();
    const height = $(canvas).height();

    if (!displayTempField || !canvas) return;

    const position = getRelativeCoordinates(e.clientX - 50, e.clientY - 20, $(canvas));

    if (position.x >= 100 - documentFieldSize.width) {
      position.x = 100 - documentFieldSize.width;
    }

    if (position.x <= 3000 / width) {
      position.x = 3000 / width;
    }

    if (position.y <= 2500 / height) {
      position.y = 2500 / height;
    }

    if (position.y > 100 - documentFieldSize.height) {
      position.y = 100 - documentFieldSize.height;
    }

    setTempField({
      ...tempField,
      page: canvas.dataset.page,
      position: position,
    });
  };

  const selectDocumentField = (documentField) => {
    const signatory = signatories.find((signatory) => signatory.id === documentField.signatoryId);

    setActiveField(documentField);
    setActiveSignatory(signatory);
    setActiveFieldType(documentField.type);
  };

  const unsetControlsAndSaveConfiguration = () => {
    unsetControls();
    saveConfiguration();
  };

  const unsetControlsAndContinueToSigning = () => {
    unsetControls();
    openModal();
  };

  const openModal = () => {
    if (documentIsConfigured) {
      if (isMobile) {
        setIsOpendSendingSign(true);
      } else {
        setIsOpendSignModal(true);
      }
    }
  };

  const documentFieldComponents =
    documentLoaded &&
    activeDocumentFields.map((documentField) => {
      const removeField = removeDocumentField.bind(this, documentField);
      const isSelected = activeField?.id === documentField.id;
      const signatureField = isSignature(documentField);
      const page = documentPage(documentField);

      return (
        <Field
          key={documentField.id}
          field={documentField}
          removeField={removeField}
          size={documentFieldSize}
          updateField={updateFieldCoordinates}
          selectField={selectDocumentField}
          position={documentField.position}
          page={page}
          isSelected={isSelected}
          isSignature={signatureField}
        />
      );
    });

  const closeDrawer = () => {
    unsetControlsAndSaveConfiguration();
    setHideDrawer(false);
    setDrawerIsOpen(false);
  };

  const openDrawer = () => {
    if (signatories.length) {
      setDrawerIsOpen(true);
    }
  };

  const onHideDrawer = useCallback(() => {
    setHideDrawer(false);
  }, [setHideDrawer]);
  const onShowDrawer = useCallback(() => {
    setHideDrawer(true);
  }, [setHideDrawer]);
  const back = useCallback(() => {
    closeDrawer();
    setMoveToMobConfigure(false);
  }, [closeDrawer, setMoveToMobConfigure]);

  return (
    <>
      <AppHeader>
        <ToolbarButtonWrapper>
          <IconButton onClick={back}>
            <i className='far fa-angle-left fa-2x m-r-20' />
            {isTablet && (
              <HeaderTitle fontSize={16} lineHeight={34}>
                <FormattedMessage id='ConfigureForMobile.back' />
              </HeaderTitle>
            )}
          </IconButton>
        </ToolbarButtonWrapper>
        {!drawerIsOpen && (
          <AddFieldBtn onClick={!drawerIsOpen ? openDrawer : closeDrawer}>
            <FormattedMessage id='ConfigureForMobile.addFields' />
          </AddFieldBtn>
        )}
        {drawerIsOpen && (
          <ShowNavigation onClick={onHideDrawer} shownavigation={!hideDrawer}>
            <i className='fal fa-chevron-left c-secondary fs-16' />
          </ShowNavigation>
        )}
      </AppHeader>
      <div onClick={onShowDrawer}>
        <Viewer
          documentURL={documentURL}
          onDocumentLoad={markDocumentAsLoaded}
          onViewportMouseMove={moveTempField}
          onViewportMouseClick={handleViewportClick}
          onViewportMouseEnter={showTempField}
          onViewportMouseLeave={hideTempField}
          pointerMode={displayTempField}
          viewportStyles={styles.viewport}
        />
      </div>

      {displayTempField && (
        <Field
          field={tempField}
          page={documentPage(tempField)}
          size={documentFieldSize}
          position={tempField.position}
          isSignature={isSignature({ type: activeFieldType })}
        />
      )}
      {documentFieldComponents}

      <Drawer drawerId='react-configure-drawer' styles={styles.drawer(drawerIsOpen, hideDrawer)}>
        <Toolbar
          signatories={signatories}
          toggleSignatory={toggleActiveSignatory}
          currentSignatory={activeSignatory}
          isSelectedSignatory={isActiveSignatory}
          documentIsConfigured={documentIsConfigured}
          activeFieldType={activeFieldType}
          isActiveFieldType={isActiveFieldType}
          activeField={activeField || tempField}
          toggleActiveField={toggleActiveField}
          fieldTypes={fieldTypes}
          dateTypeOptions={dateTypeOptions}
          onDateTypeChange={updateDocumentField}
          defaultDateFormat={defaultDateFormat}
          showActions={false}
        />
      </Drawer>

      <SendForSigningMob>
        <button
          disabled={!documentIsConfigured}
          onClick={unsetControlsAndContinueToSigning}
          className={`btn ${documentIsConfigured ? 'btn-primary' : 'btn-disabled'}`}
        >
          <FormattedMessage id='ConfigureForMobile.sendSign' />
        </button>
      </SendForSigningMob>
    </>
  );
};
