import { gql } from '@apollo/client';
import { CONTACT_FRAGMENT } from './ContactFragment';

const TASK_FRAGMENT = gql`
  fragment TaskFields on Task {
    id
    title
    status
    dueDate

    owner {
      ...ContactFields
    }
  }
  ${CONTACT_FRAGMENT}
`;

export { TASK_FRAGMENT };
