import { css } from '@emotion/core';
import { colors } from 'styles';

export const option = (isLast) => css`
  padding-top: 10px;
  padding-right: 12px;
  padding-left: 21px;
  padding-bottom: 10px;

  &:hover {
    cursor: pointer;
    background-color: ${colors.lightCyan};
  }
`;

export const noResults = css`
  padding: 21px 12px 21px 21px;
`;
