import { gql, useQuery } from '@apollo/client';
import { CONTACT_FRAGMENT } from 'data/fragments/ContactFragment';

const SHAREABLE_DIRECTORY_QUERY = gql`
  query shareableDirectory($id: ID!) {
    currentCompany {
      id

      contacts {
        ...ContactFields
      }

      directory(id: $id) {
        id
        shareableUrl
        shared

        sharedWith {
          ...ContactFields
        }

        contactsWithAccess {
          ...ContactFields
        }
      }
    }
  }
  ${CONTACT_FRAGMENT}
`;

const useShareableDirectoryQuery = (options) => useQuery(SHAREABLE_DIRECTORY_QUERY, options);

export { useShareableDirectoryQuery };
