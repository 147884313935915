/** @jsx jsx */
import React, { memo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import * as styles from './VoteDetailsForm.styles';

import { VoteSettingsForm } from '../VoteSettingsForm';
import { VoteDurationForm } from '../VoteDurationForm';
import { ContactsTable } from '../Voters/ContactsTable';
import { CollaboratorsTable } from '../Voters/CollaboratorsTable';

import { Spinner } from 'components/shared/Spinner';

import { ReactComponent as UsersIcon } from 'images/icons/users.svg';

import { useVoteParams } from './../hooks/useVoteParams';
import { useVoteContacts } from './../hooks/useVoteContacts';
import { useUpsertVote } from './../hooks/useUpsertVote';
import { useUpdateVoters } from './../hooks/useUpdateVoters';
import { useFetchCompany } from './../hooks/useFetchCompany';

const VoteDetailsForm = memo(({ vote, companyId }) => {
  const { contacts, meetings } = useFetchCompany({ id: companyId });

  const intl = useIntl();

  const [showSpinner, setShowSpinner] = useState(false);

  const voteOpened = vote?.status === 'open';
  const votePublished = vote?.status === 'published';

  const {
    voteParams,
    setVoteParams,
    updateVoteParams,
    setStartDate,
    setEndDate,
    setStartTime,
    setEndTime,
    setVoteMeetingDateTimes,
    setVoteMeetingName,
    handleInputChange,
  } = useVoteParams({ vote: vote });

  const { contactExists, addContact, removeContact, addCollaborator, removeCollaborator, toggleContact, importEmails } =
    useVoteContacts({ vote: vote, voteParams: voteParams, updateVoteParams: updateVoteParams, contacts: contacts });

  const [meeting, setMeeting] = useState(vote?.meeting);
  const [meetingContactIds, setMeetingContactIds] = useState(
    meeting ? meeting.contacts.map((contact) => contact.id) : []
  );

  const [isMounted, setIsMounted] = useState(false);

  const handleMeetingSelectChange = (event) => {
    if (event === null) {
      setVoteParams((voteParams) => ({ ...voteParams, meetingId: null }));
      setMeeting(null);
    } else {
      setVoteParams((voteParams) => ({ ...voteParams, meetingId: event.id }));
    }
  };

  const findMeeting = (id) => meetings?.find((meeting) => meeting.id == id);
  const setMeetingData = () => setMeeting(findMeeting(voteParams.meetingId));

  const removeMeetingContactsFromVote = () => {
    if (meetingContactIds) {
      const newContactIds = voteParams.contactIds.filter((id) => !meetingContactIds.includes(id));

      updateVoteParams('contactIds', newContactIds);
    }

    setMeetingContactIds([]);
  };

  const addMeetingContactsToVote = () => {
    const meetingContactIds = meeting.contacts.map((contact) => contact.id);

    setMeetingContactIds(meetingContactIds);
    updateVoteParams('contactIds', Array.from(new Set(voteParams.contactIds.concat(meetingContactIds))));
  };

  const setVoteMeetingContactIds = () => {
    if (!meeting) {
      removeMeetingContactsFromVote();
    } else {
      addMeetingContactsToVote();
    }
  };

  useEffect(() => {
    if (!isMounted) return setIsMounted(true);
    if (!voteParams.meetingId && meeting) return;

    setVoteMeetingDateTimes(meeting);
    setVoteMeetingName(meeting);
    setVoteMeetingContactIds();
  }, [voteParams.meetingId, meeting]);

  useEffect(() => {
    if (vote?.id) {
      updateVoteParams('voteId', vote?.id);
    }
  }, [vote]);

  const handleEmailImport = (inputEmails) => {
    setShowSpinner(true);
    importEmails(inputEmails);
    setShowSpinner(false);
  };

  const onError = (errorsCount) => {
    clearFlashMessages();

    $('.main').scrollTop(0, 0);
    displayFlashMessage(intl.formatMessage({ id: 'VoteDetailsForm.errrors' }, { errorsCount }), 'error');
  };

  const [continueToPolling, setContinueToPolling] = useState(false);
  const [successFlash, setSuccessFlash] = useState('');

  const changeSuccessFlash = () => {
    if (voteParams.voteId) {
      setSuccessFlash(intl.formatMessage({ id: 'VoteDetailsForm.voteUpdated' }));
    } else {
      setSuccessFlash(intl.formatMessage({ id: 'VoteDetailsForm.voteCreated' }));
    }
  };

  const [upsertSuccess, setUpsertSuccess] = useState(false);
  const [voteSlug, setVoteSlug] = useState(null);

  const onCompleted = (errorsCount, voteSlug) => {
    setVoteSlug(voteSlug);
    setUpsertSuccess(true);
  };

  useEffect(() => {
    if (!upsertSuccess) return;
    if (continueToPolling) return window.location.assign(`/${getCompanySlug()}/voting/${voteSlug}/edit`);

    $('.main').scrollTop(0, 0);
    displayFlashMessage(successFlash, 'success');

    if (voteOpened) {
      window.location.assign(`/${getCompanySlug()}/voting/${vote.slug}`);
    } else {
      window.location.assign(`/${getCompanySlug()}/voting`);
    }
  }, [upsertSuccess, voteParams.voteId]);

  const { isUpserting, errors, resetErrors, upsertVote } = useUpsertVote({
    onError: onError,
    onCompleted: onCompleted,
  });
  const { isUpdatingVoters, updateVoters } = useUpdateVoters({ onCompleted });

  const handleSave = () => {
    changeSuccessFlash();
    setContinueToPolling(false);

    if (voteOpened) {
      updateVoters({
        voteId: voteParams.voteId,
        contactIds: voteParams.contactIds,
        collaboratorEmails: voteParams.collaboratorEmails,
      });
    } else {
      upsertVote(voteParams);
    }
  };

  const handleSaveAndContinue = () => {
    setContinueToPolling(true);
    upsertVote(voteParams);
  };

  const errorMessage = (attribute) => {
    if (!errors) return;
    const error = errors.find((error) => error.attribute == attribute);
    return error && error.message;
  };

  const resetAttributeError = (attribute) => {
    if (errors && errors.length > 0) {
      resetErrors(attribute);
    }
  };

  useEffect(() => {
    resetAttributeError('started_at');

    if (voteParams.endDate > voteParams.startDate) return;

    setVoteParams((params) => ({ ...params, ['endDate']: voteParams.startDate }));
  }, [voteParams.startDate, voteParams.startTime]);

  useEffect(() => resetAttributeError('name'), [voteParams.name]);
  useEffect(() => resetAttributeError('ended_at'), [voteParams.endDate, voteParams.endTime]);
  useEffect(() => resetAttributeError('voters'), [voteParams.contactIds, voteParams.collaboratorEmails]);

  const saveButtonText = () => {
    if (voteParams.voteId) {
      return intl.formatMessage({ id: 'VoteDetailsForm.saveClose' });
    } else {
      return intl.formatMessage({ id: 'VoteDetailsForm.saveDraft' });
    }
  };

  return (
    <>
      <Spinner show={showSpinner || isUpserting || isUpdatingVoters} />

      <div>
        <div css={styles.wrapper} className='row vote-wrapper'>
          <div className='col-md-12'>
            <VoteSettingsForm
              voteParams={voteParams}
              onMeetingDataSet={setMeetingData}
              handleInputChange={handleInputChange}
              updateVoteParams={updateVoteParams}
              errorMessage={errorMessage}
              voteOpened={voteOpened}
              selectedMeeting={meeting}
              onMeetingSelect={handleMeetingSelectChange}
              meetings={meetings}
            />

            <VoteDurationForm
              voteParams={voteParams}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              voteOpened={voteOpened}
              errorMessage={errorMessage}
            />

            <div className='panel vote-panel m-t-30'>
              <div className='vote-heading'>
                <FormattedMessage id='VoteDetailsForm.voters' />
              </div>
              <div className={`vote-error ${errorMessage('voters') ? 'd-flex' : 'd-none'}`}>
                <FormattedMessage id='VoteDetailsForm.oneContact' />
              </div>
              <div className='d-flex align-items-center fs-12 dash-title form-label p-b-10'>
                <UsersIcon className='m-0' />
                <div className='m-l-5'>
                  <FormattedMessage id='VoteDetailsForm.addFromContacts' />
                </div>
                <div className='m-l-5'>{`(${voteParams.contactIds && voteParams.contactIds.length})`}</div>
              </div>

              <ContactsTable
                contacts={contacts}
                contactIds={voteParams.contactIds}
                meetingContactIds={meetingContactIds}
                onDelete={removeContact}
                onToggleOption={toggleContact}
              />
            </div>

            {voteParams.voterType !== 'contacts' && (
              <div className='panel vote-panel p-t-15'>
                <div className='d-flex align-items-center fs-12 dash-title form-label p-b-10'>
                  <UsersIcon className='m-0' />
                  <div className='m-l-5'>
                    <FormattedMessage id='VoteDetailsForm.addCollabs' />
                  </div>
                  <div className='m-l-5'>{`(${
                    voteParams.collaboratorEmails && voteParams.collaboratorEmails.length
                  })`}</div>
                </div>

                <CollaboratorsTable
                  voteId={voteParams.voteId}
                  collaboratorEmails={voteParams.collaboratorEmails}
                  persistedEmails={vote?.collaboratorEmails}
                  voteStatus={vote?.status}
                  onDelete={removeCollaborator}
                  handleEmailImport={handleEmailImport}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className='page-actions panel bs-small d-flex flex-wrap justify-content-center flex-row m-t-30 p-20 p-b-10'
          css={styles.saveButtonsPanel}
        >
          {!voteOpened && (
            <>
              <a className='btn  btn-responsive m-b-10 btn-secondary mr-3' onClick={handleSave} css={styles.saveButton}>
                {saveButtonText()}
              </a>
              <a
                className='btn  btn-responsive m-b-10 btn-primary'
                id='btn-continue-to-polls'
                onClick={handleSaveAndContinue}
                css={styles.saveButton}
              >
                <FormattedMessage id='VoteDetailsForm.saveContinue' />
              </a>
            </>
          )}
          {voteOpened && (
            <a className='btn  btn-responsive m-b-10 btn-primary' onClick={handleSave}>
              <FormattedMessage id='VoteDetailsForm.save' />
            </a>
          )}
        </div>
      </div>
    </>
  );
});

export { VoteDetailsForm };
