/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { ReactComponent as CommentIcon } from 'images/icons/comment-quick-add.svg';

import * as styles from './CommentToggle.styles';

const CommentToggle = memo(({ show, posY, onClick }) => {
  const toggleStyles = [styles.toggle.base(posY), show && styles.toggle.visible];

  return (
    <div css={toggleStyles} onClick={onClick}>
      <CommentIcon />
    </div>
  );
});

export { CommentToggle };
