/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import Placeholder from 'images/group.png';
import * as styles from './FallbackCard.styles';

const FallbackCard = memo(({ message, link }) => {
  return (
    <a css={styles.wrapper} href={link}>
      <img css={styles.image} src={Placeholder} />
      <div css={styles.message}>{message}</div>
    </a>
  );
});

export { FallbackCard };
