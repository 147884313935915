import { css } from '@emotion/core';
import { colors } from 'styles';

export const bar = css`
  background: ${colors.white};
  border-bottom: 1px solid ${colors.brandBorder};
  position: fixed;
  left: 0;
  z-index: 2;
  right: 0;
`;

export const signatureIcon = css`
  height: 111px;
  width: auto;
`;

export const modal = css`
  .modal-dialog.shaparency {
    .modal-body {
      position: static;
    }

    .modal-footer {
      margin-top: 0;

      & > * {
        margin: 0;
      }
    }
`;

export const wrapper = css`
  width: 100%;
  max-width: 777px;
  margin: 0 auto;
  padding: 8px 0;
  text-align: right;
`;

export const modalBody = css`
  padding: 67px 0 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
