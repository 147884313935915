import { gql } from '@apollo/client';

const BREADCRUMB_FRAGMENT = gql`
  fragment BreadcrumbFields on Breadcrumb {
    id
    name
  }
`;

export { BREADCRUMB_FRAGMENT };
