import { css } from '@emotion/core';
import { media, fonts, colors } from 'styles';

export const trigger = css`
  cursor: pointer;
`;

export const dropdown = css`
  background: ${colors.white};
  box-shadow: 0px 3px 20px rgba(16, 8, 63, 0.11);
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 2;
`;

export const container = css`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const option = css`
  ${fonts.regular}
  color: ${colors.black};
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  line-height: 1;
  padding: 12px 15px;
  border-radius: 0;

  ${media.tablet} {
    padding: 15px;
  }

  &:hover {
    background: ${colors.lightestGrey};
  }

  &.disabled {
    cursor: default;
    color: ${colors.headingGrey};
  }
`;

export const icon = css`
  flex-shrink: 0;
`;
