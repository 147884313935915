import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from 'components/v2/shared/Box';
import VisibilityWrapper from 'components/v2/shared/VisibilityWrapper';

const ListItemTemplate = ({
  isSplitView = false,
  isHoverable = false,
  nameNode,
  signatureCountNode,
  statusNode,
  dateNode,
  timeNode,
  ownerNode,
}) => (
  <Box isHoverable={isHoverable}>
    <div className='tw-grid tw-grow tw-grid-cols-12 tw-items-center tw-gap-2 lg:tw-hidden'>
      <div className='tw-col-span-12'>{nameNode}</div>
      <div className='tw-col-span-6 md:tw-hidden'>{signatureCountNode}</div>

      <div className='tw-col-span-6 tw-hidden tw-items-center tw-gap-2 md:tw-flex'>
        {statusNode}
        <div className='tw-flex tw-w-full tw-items-center tw-gap-1'>
          {dateNode}
          {timeNode}
        </div>
      </div>

      <div className='tw-col-span-2' />

      <div className='tw-col-span-4'>
        <div className='tw-flex tw-items-center tw-justify-end tw-gap-4'>
          <div className='tw-hidden tw-justify-end md:tw-flex md:tw-w-full'>{signatureCountNode}</div>
          {ownerNode}
        </div>
      </div>
    </div>

    <VisibilityWrapper
      isVisible={isSplitView}
      visibleClass='lg:tw-grid'
      className='tw-hidden tw-grow tw-grid-cols-12 tw-items-center tw-gap-2'
    >
      <div className='tw-col-span-6'>{nameNode}</div>
      <div className='tw-col-span-2' />
      <div className='tw-col-span-4'>
        <div className='tw-flex tw-items-center tw-justify-end tw-gap-4'>
          {signatureCountNode}
          {statusNode}
        </div>
      </div>
    </VisibilityWrapper>

    <VisibilityWrapper
      isVisible={!isSplitView}
      visibleClass='lg:tw-grid'
      className='tw-hidden tw-grow tw-grid-cols-12 tw-items-center tw-gap-2'
    >
      <div className='tw-col-span-6'>{nameNode}</div>
      <div className='tw-col-span-2 tw-flex tw-justify-center'>{signatureCountNode}</div>
      <div className='tw-col-span-1 tw-flex tw-justify-center'>{statusNode}</div>
      <div className='tw-col-span-2'>
        <div className='tw-flex tw-items-center tw-gap-1'>
          {dateNode}
          {timeNode}
        </div>
      </div>
      <div className='tw-col-span-1 tw-flex tw-justify-center'>{ownerNode}</div>
    </VisibilityWrapper>
  </Box>
);

ListItemTemplate.propTypes = {
  isHoverable: PropTypes.bool,
  isSplitView: PropTypes.bool,
  nameNode: PropTypes.node.isRequired,
  signatureCountNode: PropTypes.node.isRequired,
  statusNode: PropTypes.node.isRequired,
  dateNode: PropTypes.node.isRequired,
  timeNode: PropTypes.node.isRequired,
  ownerNode: PropTypes.node.isRequired,
};

export default ListItemTemplate;
