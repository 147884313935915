import { gql, useMutation } from '@apollo/client';
import { VOTE_FRAGMENT } from './../fragments/VoteFragment';

const UPDATE_VOTE_SETTINGS = gql`
  mutation updateVoteSettings($input: UpdateVoteSettingsInput!) {
    updateVoteSettings(input: $input) {
      vote {
        ...VoteFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${VOTE_FRAGMENT}
`;

const useUpdateVoteSettingsMutation = (options) => useMutation(UPDATE_VOTE_SETTINGS, options);

export { useUpdateVoteSettingsMutation };
