import { css } from '@emotion/core';
import { icons, fonts, colors } from 'styles';

export const icon = {
  pencil: css`
    ${icons.base}
    font-size: 12px;
    margin: 0 10px;
  `,

  trash: css`
    ${icons.base}
    color: ${colors.red};

    &:hover {
      color: ${colors.red};
      opacity: 0.8;
    }
  `,
};

export const input = css`
  border: 1px solid #dfdfdf;
  background: ${colors.white};
  border-radius: 3px;
  height: 28px;
  font-size: 14px;
  padding: 6px 5px;
  color: ${colors.mediumGrey};
  width: 255px;
  max-width: 100%;
`;

export const wrapper = (hasOptions) => css`
  padding: ${hasOptions ? '14px 8px 14px 0' : 0};
`;

export const items = css`
  overflow: auto;
  max-height: 241px;
`;

export const addTaskOption = css`
  display: flex;
  align-items: center;
  background: ${colors.darkGrey};
  padding: 11px 21px;

  &:hover {
    cursor: pointer;
  }

  span {
    margin-left: 13px;
    color: ${colors.black};
    font-size: 12px;
    line-height: 20px;
  }
`;
