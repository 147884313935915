import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from 'components/v2/shared/Box';

const ActivityFeedItem = ({ date, time, owner, description }) => (
  <Box className='tw-relative tw-grow'>
    <div
      className={clsx(
        'tw-absolute tw-top-2.5 -tw-left-2',
        'tw-border-l-0 tw-border-solid tw-border-y-transparent tw-border-r-gray-100',
        'tw-border-y-6 tw-border-r-8'
      )}
    />

    <div className='tw-flex tw-flex-col tw-gap-1'>
      <div className='tw-flex tw-flex-col'>
        <div className='tw-text-sm tw-font-semibold tw-text-black'>{date}</div>
        <div className='tw-text-xs tw-font-medium tw-text-gray-700'>{`${time} \u2022 ${owner}`}</div>
      </div>

      <div className='tw-text-xs tw-font-medium tw-text-gray-700'>{description}</div>
    </div>
  </Box>
);

ActivityFeedItem.propTypes = {
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ActivityFeedItem;
