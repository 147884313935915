import styled from 'styled-components';
import { fonts, colors } from 'styles';

export const SendForSigningMob = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  background: white;
  z-index: 1003;
  bottom: 0px;
  display: flex;
  justify-content: center;
  padding: 17px;

  button {
    width: 235px;
  }
`;

export const Row = styled.div`
  ${fonts.styledSemiBold}
  color: #00031f;
  font-size: 12px;
  line-height: 24px;
  .title {
    width: 96px;
  }
  .row-content {
    margin-left: 21px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #2c2e3f;
  }
  padding: 2px 0px;
`;

export const ContentCard = styled.div`
  background: #ffffff;
  ${({ hasTopBorder }) => hasTopBorder && 'border-top: solid 1px #dde0ed'};
  border-bottom: solid 1px #dde0ed;
  padding: 12px 22px;
  position: relative;
`;

export const Item = styled.div`
  ${fonts.styledRegular}
  font-size: 12px;
  color: #2c2e3f;
`;

export const ItemTitle = styled.div`
  font-weight: 600;
  line-height: 24px;
  width: 50%;
`;
export const ItemContent = styled.div`
  font-weight: normal;
  line-height: 16px;
  padding-left: 8px;
  width: 50%;
`;

export const Delete = styled.a`
  position: absolute;
  top: 11px;
  right: 13px;
  svg {
    &:hover {
      path {
        fill: ${colors.cyan};
      }
    }
  }
`;

export const EmptyWrapper = styled.div`
  margin-top: 55px;
  @media (min-width: 768px) {
    margin-top: 78px;
  }
`;

export const ContacsWrapper = styled.div`
  background: #f2f2f2;
  width: 77px;
  margin: auto;
  border-radius: 50%;
  height: 68px;
`;

export const HeaderTitle = styled.span`
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px`};
  ${fonts.styledSemiBold}
  color: #2c2e3f;
`;

export const AddFieldBtn = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  border: none;
  background: #00bac6;
  ${fonts.styledSemiBold}
  font-size: 16px;
  text-align: right;
  color: #ffffff;
  padding: 24px;
  display: flex !important;
  align-items: center !important;
  box-sizing: border-box;
`;
