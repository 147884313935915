/** @jsx jsx */

import React, { memo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';

import { useClickOutside } from 'hooks/useClickOutside';
import { Drawer } from 'components/shared/Drawer';

import * as styles from './AnimatedDrawer.styles';

const AnimatedDrawer = memo(
  ({ show, onClose, onCloseAnimationEnd, closeOnClickOutside, width, children, ...props }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(show);

    const [isOpen, setIsOpen] = useState(show);

    useEffect(() => syncVisibility(), [show]);
    useEffect(() => toggleBackground(), [isOpen]);
    useEffect(() => syncOutsideClick(), [isComponentVisible]);

    const syncVisibility = () => {
      if (show) {
        setIsOpen(true);
        setIsComponentVisible(true);
      }
    };

    const toggleBackground = () => {
      const classList = document.querySelector('body').classList;

      if (isOpen) {
        classList.add('opaque-bg');
      } else {
        classList.remove('opaque-bg');
      }
    };

    const syncOutsideClick = () => {
      if (!isComponentVisible && closeOnClickOutside) {
        onClose();
      }
    };

    const onAnimationEnd = () => {
      if (!show) {
        setIsOpen(false);
        setIsComponentVisible(false);
        onCloseAnimationEnd && onCloseAnimationEnd();
      }
    };

    const baseStyles = styles.drawer(width);

    const drawerStyles = [baseStyles.base, show ? baseStyles.show : baseStyles.hide, props.styles];

    return (
      isOpen && (
        <Drawer {...props} ref={ref} styles={drawerStyles} onAnimationEnd={onAnimationEnd} onClose={onClose}>
          {children}
        </Drawer>
      )
    );
  }
);

export { AnimatedDrawer };
