/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './Steps.styles';

const Steps = memo(({ stepMap, currentStep, disabledSteps, completedSteps, changeStep }) => {
  const stepKeys = Object.keys(stepMap).map(Number);
  const totalSteps = stepKeys.length;

  // TODO - Refactor
  const renderedSteps = stepKeys.map((step, index) => {
    const isClickable = !disabledSteps.includes(step);
    const isCompleted = completedSteps && completedSteps.includes(step);
    const withLine = index < totalSteps - 1;
    const isActive = step == currentStep;
    const handleClick = isClickable && !isActive ? changeStep.bind(this, step) : null;

    return (
      <div key={`step-${step}`} css={styles.step(withLine)}>
        <div id={`step-${step}`} onClick={handleClick} css={styles.wrapper(isClickable && !isActive)}>
          <div css={styles.outerCircle(isActive)}>
            <div css={styles.innerCircle(isActive)}>{isCompleted ? <i className='fa fa-check fs-10' /> : step}</div>
          </div>

          <div css={styles.name(isActive)}>{stepMap[step]}</div>
        </div>

        {withLine && <div css={styles.line} />}
      </div>
    );
  });

  return <div css={styles.steps}>{renderedSteps}</div>;
});

export { Steps };
