/** @jsx jsx */
import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import BoardSpaceIcon from 'images/icons/board-space.svg';
import * as styles from './index.styles';

export default ({ title, slug, currentSlug, pending_contact, companyId, primaryCompanyId, manageable }) => {
  const onManageDashboardRedirect = () => {
    if (!pending_contact) {
      window.location.assign(`/${slug}/dashboard`);
    }
  };
  const manageLink = `/${slug}/settings`;
  const isPrimary = +companyId === primaryCompanyId;

  return (
    <div css={styles.companyBlock}>
      <div css={styles.companyIconWrapper({ isActive: currentSlug === slug })} onClick={onManageDashboardRedirect}>
        <div css={styles.companyIconCont}>
          <img src={BoardSpaceIcon} alt='board-space-icon' className='m-0' />
        </div>
      </div>
      <div css={[styles.companyTextWrapper, styles.companyTextCont]}>
        <div onClick={onManageDashboardRedirect} css={[styles.companyTextWrapper, styles.companyTextCont]}>
          <p css={[styles.companyTitle, styles.textEllipsis]}>{title}</p>
          {pending_contact ? (
            <p css={[styles.companyPendingLabel, styles.textEllipsis]}>
              <FormattedMessage id='CompanyItem.pending' />
            </p>
          ) : null}
          {isPrimary ? (
            <p css={[styles.companyPrimaryLabel, styles.textEllipsis]}>
              <FormattedMessage id='CompanyItem.primary' />
            </p>
          ) : null}
        </div>
        {manageable ? (
          <a href={manageLink} css={styles.companyManageLabel}>
            <FormattedMessage id='CompanyItem.manage' />
          </a>
        ) : null}
      </div>
    </div>
  );
};
