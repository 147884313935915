import { css } from '@emotion/core';
import { tables, fonts, colors } from 'styles';

export const tableHeader = tables.tableHeader;

export const table = css`
  left: 0;
  width: auto;
  table-layout: fixed;
`;
