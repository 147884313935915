import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { client } from 'utils/apollo';

import RecentDocuments from './RecentDocuments';

export default () => (
  <ApolloProvider client={client}>
    <RecentDocuments />
  </ApolloProvider>
);
