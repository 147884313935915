import { css } from '@emotion/core';

export const listItem = css`
  background: rgb(225, 228, 232);
  padding: 10px 17px;
  border-radius: 4px;
  height: 45px;
  cursor: pointer;

  .far {
    line-height: 23px;
  }
`;
