import React, { memo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { client } from 'utils/apollo';
import { MeetingProvider } from 'store/meetingStoreContext';
import { FlashProvider } from 'store/flashStoreContext';
import { TaskDrawerProvider } from 'store/taskDrawerStoreContext';
import { TasksProvider } from 'store/tasksStoreContext';
import { AgendasProvider } from 'store/agendasStoreContext';
import { DocumentsProvider } from 'store/documentsStoreContext';
import { MinutesProvider } from 'store/minutesStoreContext';
import { OoEProvider } from 'store/ooeStoreContext';
import { CommentsProvider } from 'store/commentsStoreContext';
import { ReviewsProvider } from 'store/reviewsStoreContext';
import AppSignalWrapper from '../../v2/shared/AppSignalWrapper';
import { TranslationContextProvider } from '../../../contexts/v2/TranslationContext';
import { DateFormatContextProvider } from '../../../contexts/v2/DateFormatContext';

import { MultistepForm } from 'components/minutes/MultistepForm';

export default memo(
  ({
    root,
    meetingId,
    meetingTypes,
    timezoneOptions,
    contactTimezone,
    contactTimezoneOffset,
    currentContact,
    isStandalone,
    adminView,
    availableLanguages,
    contactLanguage,
  }) => {
    return (
      <AppSignalWrapper tags={{ page: 'minutes/edit' }}>
        <ApolloProvider client={client}>
          <TranslationContextProvider>
            <DateFormatContextProvider>
              <FlashProvider>
                <TaskDrawerProvider>
                  <MeetingProvider>
                    <TasksProvider>
                      <AgendasProvider>
                        <DocumentsProvider>
                          <OoEProvider>
                            <CommentsProvider>
                              <ReviewsProvider>
                                <MinutesProvider>
                                  <MultistepForm
                                    meetingId={meetingId}
                                    meetingTypes={meetingTypes}
                                    timezoneOptions={timezoneOptions}
                                    isStandalone={isStandalone}
                                    currentContact={currentContact}
                                    contactTimezone={contactTimezone}
                                    contactTimezoneOffset={contactTimezoneOffset}
                                    root={root}
                                    adminView={adminView}
                                    availableLanguages={availableLanguages}
                                    contactLanguage={contactLanguage}
                                  />
                                </MinutesProvider>
                              </ReviewsProvider>
                            </CommentsProvider>
                          </OoEProvider>
                        </DocumentsProvider>
                      </AgendasProvider>
                    </TasksProvider>
                  </MeetingProvider>
                </TaskDrawerProvider>
              </FlashProvider>
            </DateFormatContextProvider>
          </TranslationContextProvider>
        </ApolloProvider>
      </AppSignalWrapper>
    );
  }
);
