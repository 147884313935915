/** @jsx jsx */

import React, { memo, useRef, useState } from 'react';
import { jsx } from '@emotion/core';

import { useClickOutside } from 'hooks/useClickOutside';

import { ReactComponent as DotsIcon } from 'images/icons/documents/horizontal-dots-icon.svg';

import * as styles from './ActionsDropdown.styles';

const ActionsDropdown = memo(({ options, onClick }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside();

  const show = () => setIsComponentVisible(true);

  const handleClick = (option) => {
    onClick(option);
    setIsComponentVisible(false);
  };

  const dropdownContent = options.map((option, index) => {
    const handleOptionClick = (e) => {
      e.stopPropagation();
      handleClick(option);
    };

    return (
      <div css={styles.option} onClick={handleOptionClick} key={`actions-dropdown-option-${index}`}>
        {option}
      </div>
    );
  });

  const dropdown = isComponentVisible && <div css={styles.dropdown}>{dropdownContent}</div>;

  return (
    options.length > 0 && (
      <div css={styles.container} onClick={show} ref={ref}>
        <div css={styles.trigger}>
          <DotsIcon css={styles.icon} />
        </div>

        <div className='actions-dropdown'>{dropdown}</div>
      </div>
    )
  );
});

export { ActionsDropdown };
