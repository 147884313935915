/** @jsx jsx */

import React, { memo, useState, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { Input } from 'components/shared/Input';
import { TextAreaInput } from 'components/shared/TextAreaInput';
import { RadioButton } from 'components/shared/RadioButton';
import { CheckBox } from 'components/shared/CheckBox';
import { ZoomMeetingsDropdown } from '../ZoomMeetingsDropdown';

import { useMeetingStore, useMeetingActions } from 'store/meetingStoreContext';

import { getAttributes } from 'helpers/getAttributes';

import * as styles from './ZoomDetailsForm.styles';

const ZoomDetailsForm = memo(({}) => {
  const { edits: meeting } = useMeetingStore();
  const { setEdits, setZoomEdits } = useMeetingActions();

  const intl = useIntl();

  const zoomMeeting = meeting.zoom_meeting;

  const [showPasscodeInput, setShowPasscodeInput] = useState(zoomMeeting.password?.length > 0);
  const [showMeetingsDropdown, setShowMeetingsDropdown] = useState(false);

  const toggleShowPasscodeInput = () => {
    if (showPasscodeInput) {
      setShowPasscodeInput(false);

      setZoomEdits('password', null);
    } else {
      setShowPasscodeInput(true);
    }
  };

  const toggleShowMeetingsDropdown = () => {
    if (showMeetingsDropdown) {
      setEdits('zoom_meeting_id', null);
    }

    setShowMeetingsDropdown(!showMeetingsDropdown);
  };

  const handleChange = useCallback((event) => {
    let inputValue = event.target.value;

    if (inputValue === 'true') {
      inputValue = true;
    } else if (inputValue === 'false') {
      inputValue = false;
    }

    setZoomEdits(event.target.name, inputValue);
  }, []);

  const handleDescriptionChange = useCallback((event) => {
    if (event.target.value.length > 2000) return;

    setZoomEdits('agenda', event.target.value);
  }, []);

  const handlePasswordChange = useCallback((event) => {
    if (event.target.value.length > 10) return;

    setZoomEdits('password', event.target.value);
  }, []);

  const handleMeetingIdChange = useCallback(
    (event, onMeetingIdChange) => {
      if (event.value == meeting.zoom_meeting_id) {
        setEdits('zoom_meeting_id', '');
      } else {
        setEdits('zoom_meeting_id', event.value);
      }

      onMeetingIdChange();
    },
    [meeting.zoom_meeting_id]
  );

  const handleCheckBoxChange = useCallback((event) => {
    setZoomEdits(event.target.name, event.target.checked);
  }, []);

  const handleAutoRecordingChange = useCallback((event) => {
    let inputValue = event.target.checked;

    if (inputValue) {
      inputValue = 'local';
    } else {
      inputValue = 'none';
    }

    setZoomEdits('auto_recording', inputValue);
  }, []);

  return (
    <div css={styles.container}>
      <label className='fs-12 text-black font-weight-bold'>
        <FormattedMessage id='ZoomDetailsForm.meetingId' />
      </label>

      <Row noGutters className='p-5 m-b-20' css={styles.radioButton}>
        <Col md={6}>
          <RadioButton
            name='zoom-meeting-dropdown'
            defaultChecked={!showMeetingsDropdown}
            onChange={toggleShowMeetingsDropdown}
            id='new-meeting'
            label={intl.formatMessage({ id: 'ZoomDetailsForm.newMeetingLabel' })}
          />
        </Col>
        <Col md={6}>
          <RadioButton
            name='zoom-meeting-dropdown'
            defaultChecked={showMeetingsDropdown}
            onChange={toggleShowMeetingsDropdown}
            id='existing-meeting'
            label={intl.formatMessage({ id: 'ZoomDetailsForm.existingMeetingLabel' })}
          />
        </Col>
      </Row>

      {showMeetingsDropdown && (
        <ZoomMeetingsDropdown onSelect={handleMeetingIdChange} selectedValue={meeting.zoom_meeting_id} />
      )}

      {!showMeetingsDropdown && (
        <div>
          <label className='fs-12 text-black font-weight-bold m-b-20'>
            <FormattedMessage id='ZoomDetailsForm.description' />
          </label>

          <TextAreaInput
            value={zoomMeeting.agenda || ''}
            placeholder={intl.formatMessage({ id: 'ZoomDetailsForm.descriptionPlaceholder' })}
            onChange={handleDescriptionChange}
            name='agenda'
          />

          <div className='m-t-10 fs-12 text-grey lh-1'>
            <FormattedMessage id='ZoomDetailsForm.descriptionLimit' />
          </div>

          <div className='zoom-wrapper'>
            <h4 className='m-t-40'>
              <span>
                <FormattedMessage id='ZoomDetailsForm.security' />
              </span>
            </h4>

            <div className='d-flex'>
              <label className='fs-12 text-black font-weight-bold m-r-5'>
                <FormattedMessage id='ZoomDetailsForm.passcode' />
              </label>
              <i className='fa fa-lock-alt' />
            </div>

            <div className='zoom-check-box m-t-10'>
              <Row>
                <Col md={4}>
                  <CheckBox
                    name='passcode'
                    isChecked={showPasscodeInput}
                    onChange={toggleShowPasscodeInput}
                    id='passcode'
                    label={intl.formatMessage({ id: 'ZoomDetailsForm.yes' })}
                  />
                </Col>
                <Col md={8} className='fs-12 text-grey lh-1'>
                  <FormattedMessage id='ZoomDetailsForm.passInfo' />
                </Col>
              </Row>
            </div>

            {showPasscodeInput && (
              <Input
                value={zoomMeeting.password || ''}
                placeholder={intl.formatMessage({ id: 'ZoomDetailsForm.passcode' })}
                onChange={handlePasswordChange}
                name='password'
              />
            )}

            <label className='fs-12 text-black font-weight-bold m-r-5 m-t-20'>
              <FormattedMessage id='ZoomDetailsForm.waitingRoom' />
            </label>

            <div className='zoom-check-box m-t-10'>
              <Row className='d-flex m-t-15'>
                <Col md={4}>
                  <CheckBox
                    name='waiting_room'
                    isChecked={zoomMeeting.waiting_room}
                    onChange={handleCheckBoxChange}
                    id='waiting_room'
                    label={intl.formatMessage({ id: 'ZoomDetailsForm.yes' })}
                  />
                </Col>
                <Col md={8} className='fs-12 text-grey lh-1'>
                  <FormattedMessage id='ZoomDetailsForm.onlyAdmitted' />
                </Col>
              </Row>
            </div>

            <div className='zoom-wrapper m-t-40'>
              <h4>
                <span>
                  <FormattedMessage id='ZoomDetailsForm.video' />
                </span>
              </h4>
            </div>

            <label className='fs-12 text-black font-weight-bold'>
              <FormattedMessage id='ZoomDetailsForm.host' />
            </label>

            <Row noGutters className='p-5' css={styles.radioButton}>
              <Col md={2}>
                <RadioButton
                  name='host_video'
                  value={true}
                  defaultChecked={zoomMeeting.host_video}
                  onChange={handleChange}
                  id='host-on'
                  label={intl.formatMessage({ id: 'ZoomDetailsForm.on' })}
                />
              </Col>
              <Col md={2}>
                <RadioButton
                  name='host_video'
                  value={false}
                  defaultChecked={!zoomMeeting.host_video}
                  onChange={handleChange}
                  id='host-off'
                  label={intl.formatMessage({ id: 'ZoomDetailsForm.off' })}
                />
              </Col>
            </Row>

            <label className='fs-12 text-black font-weight-bold m-t-10'>
              <FormattedMessage id='ZoomDetailsForm.participant' />
            </label>

            <Row noGutters className='p-5' css={styles.radioButton}>
              <Col md={2}>
                <RadioButton
                  name='participant_video'
                  value={true}
                  defaultChecked={zoomMeeting.participant_video}
                  onChange={handleChange}
                  id='participant-on'
                  label={intl.formatMessage({ id: 'ZoomDetailsForm.on' })}
                />
              </Col>
              <Col md={2}>
                <RadioButton
                  name='participant_video'
                  value={false}
                  defaultChecked={!zoomMeeting.participant_video}
                  onChange={handleChange}
                  id='participant-off'
                  label={intl.formatMessage({ id: 'ZoomDetailsForm.off' })}
                />
              </Col>
            </Row>

            <div className='zoom-wrapper'>
              <h4>
                <span>
                  <FormattedMessage id='ZoomDetailsForm.meetingOptions' />
                </span>
              </h4>
            </div>

            <div className='zoom-check-box m-t-20'>
              <CheckBox
                name='join_before_host'
                isChecked={zoomMeeting.join_before_host}
                onChange={handleCheckBoxChange}
                id='join_before_host'
                label={intl.formatMessage({ id: 'ZoomDetailsForm.beforeHostLabel' })}
              />
            </div>

            <div className='zoom-check-box'>
              <CheckBox
                name='mute_upon_entry'
                isChecked={zoomMeeting.mute_upon_entry}
                onChange={handleCheckBoxChange}
                id='mute_upon_entry'
                label={intl.formatMessage({ id: 'ZoomDetailsForm.muteOnEntryLabel' })}
              />
            </div>

            <div className='zoom-check-box'>
              <CheckBox
                name='auto_recording'
                isChecked={zoomMeeting.auto_recording == 'local'}
                onChange={handleAutoRecordingChange}
                id='auto_recording'
                label={intl.formatMessage({ id: 'ZoomDetailsForm.autoRecLabel' })}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export { ZoomDetailsForm };
