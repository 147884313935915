/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { ReactComponent as TimelineIcon } from 'images/icons/timeline-icon.svg';

import * as styles from './HistoryDrawerItem.styles';

const HistoryDrawerItem = memo(({ description, author, createdAt }) => {
  const currentDate = moment().parseZone();
  const itemDate = moment(createdAt).parseZone();

  const intl = useIntl();

  const isToday = itemDate.isSame(currentDate, 'day');

  const formattedDate = itemDate.format('DD.MM.YY');
  const formattedTime = itemDate.format('hh:mm A');

  const prefix = isToday ? intl.formatMessage({ id: 'HistoryDrawerItem.today' }) : formattedDate;
  const date = `${prefix}, ${formattedTime}`;

  return (
    <div css={styles.container}>
      <TimelineIcon css={styles.icon} />

      <div css={styles.wrapper}>
        <div css={styles.arrow} />

        <div css={styles.block}>
          <div css={styles.date}>{date}</div>

          <div css={styles.description}>{description}</div>

          <div css={styles.author}>{author}</div>
        </div>
      </div>
    </div>
  );
});

export { HistoryDrawerItem };
