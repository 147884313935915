import React from 'react';

const StatusCell = ({ alignLeft, alignRight }) => {
  return (
    <div className={`placeholder-table-cell ${alignLeft ? 'left-aligned' : alignRight ? 'right-aligned' : 'centered'}`}>
      <div className='placeholder-status-stub w-percent-50'>
        <div className='placeholder-text-stub regular grey w-percent-60' />
      </div>
    </div>
  );
};

export { StatusCell };
