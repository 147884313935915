import { gql, useMutation } from '@apollo/client';
import { NOTICE_SCHEDULE_FRAGMENT } from 'data/v2/fragments/NoticeScheduleFragment';

const UPSERT_NOTICE_SCHEDULE_MUTATION = gql`
  mutation upsertNoticeSchedule($input: UpsertNoticeScheduleInput!) {
    upsertNoticeSchedule(input: $input) {
      noticeSchedule {
        ...NoticeScheduleFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${NOTICE_SCHEDULE_FRAGMENT}
`;

const useUpsertNoticeScheduleMutation = (options) => useMutation(UPSERT_NOTICE_SCHEDULE_MUTATION, options);

export { UPSERT_NOTICE_SCHEDULE_MUTATION, useUpsertNoticeScheduleMutation };
