import { css } from '@emotion/core';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';
import { colors } from 'styles';

export const opaque = css`
  background: ${HexToRgba(colors.white, 70)};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
`;
