/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';

import { ReactComponent as ChatIcon } from 'images/icons/chat.svg';
import Chat from './../Chat';

import * as styles from './Icon.styles';
import { createPortal } from 'react-dom';

const HIGHLIGHT_DURATION = 1000;

const Icon = ({ showBadge, showChat, title }) => {
  const [isComponentVisible, setIsComponentVisible] = useState(showChat);
  const handleClick = () => setIsComponentVisible(!isComponentVisible);

  const [isBadgeVisible, setIsBadgeVisible] = useState(showBadge);
  const [iconStyles, setIconStyles] = useState(styles.icon.base);
  const [hasNewMessages, setHasNewMessages] = useState(false);

  const handleAnimationEnd = () => setIconStyles(styles.icon.base);
  const toggleBadge = (show) => setIsBadgeVisible(show);

  const handleSubscription = (show) => {
    toggleBadge(show);

    if (!show) return;

    setHasNewMessages(true);
    setIconStyles([styles.icon.base, styles.icon.animated]);

    setTimeout(() => setHasNewMessages(false), HIGHLIGHT_DURATION);
  };

  const badge = isBadgeVisible && <div css={styles.badge({ withTitle: title })} />;

  return (
    <>
      <div css={styles.wrapper({ withTitle: title })} onClick={handleClick} className='react-chat-wrapper'>
        {title ? <span css={styles.title}>{title}</span> : null}
        <ChatIcon css={iconStyles} className='chat__icon' onAnimationEnd={handleAnimationEnd} />
        {badge}
      </div>

      {createPortal(
        <Chat
        show={isComponentVisible}
        isHighlighted={hasNewMessages}
        isUnread={isBadgeVisible}
        onMarkAsRead={toggleBadge}
        onClose={handleClick}
        onSubscription={handleSubscription}
      />, document.body
      )}
    </>
  );
};

export { Icon };