import { gql } from '@apollo/client';

const MESSAGE_FRAGMENT = gql`
  fragment MessageFields on Message {
    id
    text
    createdAt
  }
`;

export { MESSAGE_FRAGMENT };
