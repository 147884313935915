/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './SidebarItem.styles';

const SidebarItem = memo(({ name, id, icon, onClick, isSelected }) => {
  const itemStyles = [styles.item.base, isSelected && styles.item.selected];

  return (
    <div css={itemStyles} onClick={onClick}>
      <div css={styles.icon}>{icon}</div>

      {name}
    </div>
  );
});

export { SidebarItem };
