/** @jsx jsx */
import React from 'react';
import { ReactComponent as LogoIcon } from 'images/logo.svg';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { Badge } from 'components/shared/Badge';
import useWindowDimensions from 'hooks/useWindowDimensions';
import * as styles from './Viewer.styles';
import { voteName, voterAllowPdfExport, voteStartDate, voteStartTime, voteEndDate, voteEndTime } from './helper';
import { ResolutionsTable } from '../Voters/ResolutionsTable';
import { TimeWrapper } from './styles';

export const ViewerDesktop = ({
  meetingDrawer,
  loggedIn,
  vote,
  voteStatus,
  voter,
  changeVoteStatus,
  previewDocument,
  openPdf,
}) => {
  const intl = useIntl();
  const { isDesktop } = useWindowDimensions();
  return (
    <div css={meetingDrawer ? styles.meetingDrawerContainer : ''}>
      {!meetingDrawer && (
        <div css={styles.bar}>
          <div css={styles.wrapper}>
            <div css={styles.brand} className='p-l-20'>
              <LogoIcon />
              <h3 className='font-weight-bold fs-16 m-0 text-color-primary'>
                <FormattedMessage id='ViewerDesktop.shaparency' />
              </h3>
              {loggedIn && (
                <a href='/users/sign_in' className='btn btn-inverted m-l-20'>
                  <FormattedMessage id='ViewerDesktop.toApp' />
                </a>
              )}
            </div>
          </div>
        </div>
      )}
      <div css={meetingDrawer ? styles.meetingDrawer : styles.voteContainer}>
        <div className='d-flex justify-content-between align-items-center flex-grow-1 m-b-15'>
          <div className='d-flex flex-column'>
            <div className='d-flex'>
              <div className='vote-name fs-28 font-weight-semibold text-black m-r-10 text-truncate lh-30'>
                {voteName(vote)}
              </div>
              <div>
                <Badge status={voteStatus} text={intl.formatMessage({ id: 'ViewerDesktop.votes' })} />
              </div>
            </div>
            <div className='d-flex align-items-center fs-16 font-weight-semibold text-black m-r-10 m-t-15 flex-row'>
              <div>
                <FormattedMessage id='ViewerDesktop.voteCreator' values={{ name: vote.data.attributes.owner_name }} />
              </div>
            </div>
            {voteStatus == 'published' && meetingDrawer && (
              <div className='fs-14 m-t-20'>
                <FormattedMessage id='ViewerDesktop.votingAvailable' />
              </div>
            )}
          </div>
          {voterAllowPdfExport(voter, meetingDrawer) && (
            <div className='d-flex m-t-20 m-b-10'>
              <div className='btn btn-primary btn-pdf' onClick={openPdf}>
                <FormattedMessage id='ViewerDesktop.exportPdf' />
              </div>
            </div>
          )}
        </div>

        <div>
          <div className='d-flex flex-row text-black fs-12'>
            <TimeWrapper className='start-wrapper' isdesktop={isDesktop}>
              <div className='font-weight-semibold'>
                <FormattedMessage id='ViewerDesktop.start' />
              </div>
              <div className='date d-flex flex-row center-center justify-content-between'>
                <div className='far fa-calendar-alt' />
                <div>{voteStartDate(vote)}</div>
                <div className='far fa-clock m-l-10' />
                <div>{voteStartTime(vote)}</div>
              </div>
            </TimeWrapper>
            <TimeWrapper className='end-wrapper' isdesktop={isDesktop}>
              <div className='font-weight-semibold'>
                <FormattedMessage id='ViewerDesktop.end' />
              </div>
              <div className='date d-flex flex-row center-center justify-content-between'>
                <div className='far fa-calendar-alt' />
                <div>{voteEndDate(vote)}</div>
                <div className='far fa-clock m-l-10' />
                <div>{voteEndTime(vote)}</div>
              </div>
            </TimeWrapper>
          </div>
        </div>
        {!(voteStatus == 'published' && meetingDrawer) && (
          <ResolutionsTable
            vote={vote}
            voter={voter}
            onVoteStatusChange={changeVoteStatus}
            meetingDrawer={meetingDrawer}
            previewDocument={previewDocument}
          />
        )}
      </div>
    </div>
  );
};
