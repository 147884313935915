import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { colors, fonts, media } from 'styles';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  background: ${colors.white};
  border-bottom: solid 1px ${colors.borderSecondGrey};
  width: 100%;
  height: var(--auxiliary-nav-height);
  top: var(--primary-nav-height);
  z-index: 100;
  @media (max-width: 767px) {
    height: 52px;
  }
`;

export const ToolbarButtonWrapper = styled.div`
  position: absolute;
  left: 24px;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    left: 15px;
  }
`;

export const IconButton = styled(Button)`
  &&& {
    display: contents;
    color: ${colors.black};
    &:active {
      color: ${colors.black};
    }
  }
`;

export const HeaderTitle = styled.p`
  font-size: ${({ fontSize }) => fontSize}px;
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px`};
  color: ${colors.black};
  ${fonts.styledSemiBold};
  margin-bottom: 0;
  ${media.mobile} {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const AppBody = styled.div`
  margin-top: 68px;
  height: calc(100% - 68px);
  @media (max-width: 767px) {
    margin-top: 52px;
    height: calc(100% - 52px);
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 910px;
  margin: 0 auto;
  height: 62px;
`;

export const ToAppBarWrapper = styled(Container)`
  padding: ${({ isdesktop }) => (!isdesktop ? '18px' : '8px 0')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ToAppButton = styled.a`
  ${fonts.styledBold}
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.cyan};
  background: ${colors.lightCyan};
  border: 1px solid ${colors.cyan};
  box-sizing: border-box;
  border-radius: 0;
  text-transform: capitalize;
  padding: 4px 28px;
  height: 35px;
  &:hover {
    color: ${colors.cyan};
  }

  @media all and (max-width: 460px) {
    font-size: 12px;
    line-height: 14px;
    padding: 4px 16px;
    margin-left: 10px !important;
  }
`;

export const Brand = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin: 0 10px 0 0;
    height: 28px;
  }
`;
