/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';
import { withIntl } from '../../../utils/v2/intl';

import { ChatHeader } from './../ChatHeader';
import { MessageForm } from './../MessageForm';
import { MessageList } from './../MessageList';

import { useConversationQuery } from './../data/queries/ConversationQuery';
import { useConversationSubscription } from './../hooks/useConversationSubscription';
import { useMarkMessagesAsRead } from './../hooks/useMarkMessagesAsRead';
import { useCreateMessage } from './../hooks/useCreateMessage';

import { NetworkStatus } from '@apollo/client';

import ClipLoader from 'react-spinners/ClipLoader';

import * as styles from './Chat.styles';
import { colors } from 'styles';

const MESSAGES_PER_PAGE = 50;

const Chat = ({ show, isUnread, isHighlighted, onMarkAsRead, onClose, onSubscription }) => {
  const [messageText, setMessageText] = useState('');

  const intl = useIntl();

  const resetMessageText = () => setMessageText('');
  const handleMessageTextChange = (e) => setMessageText(e.target.value || '');

  const handleMessageTextSubmit = () => {
    const message = messageText.trim();

    if (message.length) {
      createMessage(message);
    } else {
      resetMessageText();
    }
  };

  const { createMessage } = useCreateMessage({ onCompleted: resetMessageText });
  const { markMessagesAsRead } = useMarkMessagesAsRead({ onCompleted: onMarkAsRead });
  const { data, fetchMore, loading, networkStatus } = useConversationQuery({
    skip: !show,
    notifyOnNetworkStatusChange: true,
    variables: { last: MESSAGES_PER_PAGE, fetchParticipants: true },
  });

  useConversationSubscription({ onUpdate: onSubscription });

  const wrapperStyles = [
    styles.wrapper.base,
    show && styles.wrapper.visible,
    isHighlighted && styles.wrapper.highlighted,
  ];

  if (!data || networkStatus === NetworkStatus.loading) {
    return (
      <div css={wrapperStyles}>
        <ChatHeader onClose={onClose} />

        <div css={styles.spinner}>
          <ClipLoader color={colors.cyan} loading={loading} />
        </div>
      </div>
    );
  }

  const { pageInfo, edges: messages } = data.currentCompany.conversation.messages;

  const handleScrollTop = () => {
    if (!pageInfo.hasPreviousPage) return;
    if (networkStatus == NetworkStatus.fetchMore) return;

    fetchMore({
      variables: {
        last: MESSAGES_PER_PAGE,
        before: pageInfo.startCursor,
      },
    });
  };

  const handleScrollStop = () => {
    if (isUnread) {
      markMessagesAsRead();
    }
  };

  return (
    <div css={wrapperStyles} className='react-chat'>
      <ChatHeader
        title={intl.formatMessage({ id: 'Chat.title' }, { name: data.currentCompany.name })}
        participants={data.currentCompany.conversation.participants}
        onClose={onClose}
      />

      <MessageList messages={messages} onScrollStop={handleScrollStop} onScrollTop={handleScrollTop} />

      <MessageForm value={messageText} onChange={handleMessageTextChange} onSubmit={handleMessageTextSubmit} />
    </div>
  );
};

export default withIntl(Chat);
