import React, { useState, useContext } from 'react';
import StateBadge from 'components/v2/shared/StateBadge';
import Avatar from '../shared/Avatar/Avatar';
import Switch from 'components/v2/shared/Switch';
import Button, { Sizes, Variants } from 'components/v2/shared/Button';
import Modal from 'components/v2/shared/Modal';
import clsx from 'clsx';
import Toast, { Severity } from '../shared/Toast/Toast';
import { ToastContext } from 'contexts/v2/ToastContext';
import { wrapMutation } from 'utils/v2/gql';
import { useArchiveContactMutation } from '../../../data/v2/mutations/ArchiveContactMutation';
import { useReinviteContactMutation } from '../../../data/v2/mutations/ReinviteContactMutation';
import { capitalize } from '../../../utils/capitalize';

const ContactListItem = ({ data, openIndividual }) => {
  const [contact, setContact] = useState({
    id: data.id,
    isOwner: data.isOwner,
    name: data.name,
    companyPosition: capitalize(data.contactRole),
    initials: data.initials,
    avatarUrl: data.avatarUrl,
    status: data.status,
    tasks: data.actionItems,
    taskCount: data.actionItems.length,
  });
  const [active, setActive] = useState(
    contact.status !== 'archived' && contact.status !== 'invited' && contact.status !== 'reinvited'
  );
  const [modalState, setModalState] = useState(false);
  const [archiveContact] = useArchiveContactMutation();
  const [reinviteContact] = useReinviteContactMutation();
  const { toastInfo, setToastInfo } = useContext(ToastContext);

  const handleSwitchClick = () => {
    if (active) setModalState(true);
  };

  const handleArchive = () => {
    wrapMutation(
      archiveContact,
      {
        variables: {
          input: { contactId: contact.id },
        },
      },
      'archiveContact'
    )
      .then(() => {
        setActive(false);
        setModalState(false);
        setContact({ ...contact, status: 'archived' });
        setToastInfo({
          severity: Severity.Success,
          status: 'Success',
          message: `Contact archived.`,
        });
      })
      .catch((error) => {
        setToastInfo({
          severity: Severity.Success,
          status: 'Error',
          message: `Error archiving contact.`,
        });
      });
  };

  const onReinvite = () => {
    wrapMutation(
      reinviteContact,
      {
        variables: {
          input: { contactId: contact.id },
        },
      },
      'reinviteContact'
    )
      .then(() => {
        setContact({ ...contact, status: contact.status === 'invited' ? 'invited' : 'reinvited' });
        setToastInfo({
          severity: Severity.Success,
          status: 'Success',
          message: `Contact has been reinvited.`,
        });
      })
      .catch((error) => {
        setToastInfo({
          severity: Severity.Error,
          status: 'Error',
          message: `Error reinviting contact.`,
        });
      });
  };

  const ConfirmArchiveModal = () => {
    return (
      <div className='tw-mx-5 tw-my-[45px] sm:tw-m-14'>
        {contact.taskCount == 0 ? (
          <>
            <div className='tw-font-sans tw-text-xl tw-font-bold tw-text-black'>
              Are you sure you want to archive this contact?
            </div>
            <div className='tw-mt-4 tw-font-sans tw-text-sm tw-font-medium tw-text-black'>
              The contact will be archived and can not longer access their
              <br />
              account. You can reactivate the account by sending a verification
              <br />
              email again.
            </div>

            <div className='tw-mt-7'>
              <div className='tw-flex tw-flex-col tw-gap-2 sm:tw-grid sm:tw-grid-flow-col sm:tw-grid-cols-2'>
                <div className='sm:tw-col-span-1'>
                  <Button
                    font='semibold'
                    className='tw-h-11 tw-text-sm'
                    isFullWidth
                    onClick={handleArchive}
                    size='large'
                    variant='purple'
                  >
                    Yes, archive contact
                  </Button>
                </div>
                <div className='sm:tw-col-span-1'>
                  <Button
                    font='semibold'
                    className='tw-h-11 tw-border-[1px] !tw-border-solid tw-border-purple-500 tw-text-sm'
                    isFullWidth
                    onClick={() => setModalState(false)}
                    size='large'
                    variant='transparent'
                  >
                    No, keep contact
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='tw-font-sans tw-text-xl tw-font-bold tw-text-black'>Archive Contact</div>
            <div className='tw-mt-4 tw-font-sans tw-text-sm tw-font-medium tw-text-black'>
              The contact you are about to archive has{' '}
              <strong>{`${contact.taskCount} active action item${contact.taskCount > 1 ? 's' : ''}`}:</strong>
              {contact.taskCount >= 1 && (
                <ol className='tw-ml-3 tw-mt-6 tw-pl-1'>
                  {contact.tasks.map((task) => {
                    return (
                      <li>
                        <strong>{task.title}</strong>
                      </li>
                    );
                  })}
                </ol>
              )}
              <br />
              Would you like to reassign the action {`item${contact.taskCount > 1 ? 's' : ''}`} to the owner?
            </div>

            <div className='tw-mt-7'>
              <div className='tw-flex tw-flex-col tw-gap-2 sm:tw-grid sm:tw-grid-flow-col sm:tw-grid-cols-2'>
                <div className='sm:tw-col-span-1'>
                  <Button
                    font='semibold'
                    className='tw-h-11 tw-text-sm'
                    isFullWidth
                    onClick={handleArchive}
                    size='large'
                    variant='purple'
                  >
                    Yes, reassign &amp; archive contact
                  </Button>
                </div>
                <div className='sm:tw-col-span-1'>
                  <Button
                    font='semibold'
                    className='tw-h-11 tw-border-[1px] !tw-border-solid tw-border-purple-500 tw-text-sm'
                    isFullWidth
                    onClick={() => setModalState(false)}
                    size='large'
                    variant='transparent'
                  >
                    No, keep contact
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div
      onClick={() => {
        openIndividual(true, data);
      }}
      className={clsx(
        'tw-mb-[6px] tw-grid tw-grid-cols-12 tw-grid-rows-1 tw-items-start tw-rounded-[4px] tw-bg-gray-100 tw-px-[22px] tw-py-4 tw-text-black sm:tw-grid-cols-12 sm:tw-grid-rows-1 sm:tw-items-center',
        {
          'tw-border tw-border-solid tw-border-[#E1DFEC] tw-bg-white': !active,
        }
      )}
    >
      <Modal isOpen={modalState} onClose={() => setModalState(false)} centered size='xl'>
        <ConfirmArchiveModal />
      </Modal>
      <div className='tw-col-span-6 tw-flex'>
        <Avatar placeholder={contact.initials} imgUrl={contact.avatarUrl} className='!tw-bg-[#AFB0D0]' />
        <div className='tw-ml-3 tw-truncate'>
          <div className='tw-truncate tw-text-[13px]'>{contact.name}</div>
          <div className='tw-text-xs tw-text-gray-700'>{capitalize(data.contactRole)}</div>
        </div>
      </div>
      <div className='tw-col-span-6 tw-flex tw-flex-col tw-justify-end sm:tw-flex-row'>
        {(contact.status === 'archived' || contact.status === 'invited' || contact.status === 'reinvited') && (
          <Button
            size={Sizes.Small}
            className='tw-font-sans tw-text-sm tw-font-semibold tw-text-teal-500'
            variant={Variants.Transparent}
            onClick={onReinvite}
          >
            Re-send invite
          </Button>
        )}
        {contact.isOwner ? (
          <StateBadge className='tw-col-span-2 tw-justify-self-end sm:tw-col-span-6' variant='gray' alignEnd={true}>
            Owner
          </StateBadge>
        ) : (
          <>
            {contact.status !== 'registered' ? (
              <div className='tw-flex tw-justify-end'>
                <StateBadge
                  className='tw-col-span-2 !tw-mx-0 tw-mb-5 tw-w-fit !tw-justify-end tw-justify-self-end sm:tw-col-span-3 sm:tw-mb-0 sm:tw-justify-self-end'
                  variant={
                    contact.status === 'archived'
                      ? 'faint'
                      : contact.status === 'registered'
                      ? 'green'
                      : contact.status === 'confirmed'
                      ? 'orange'
                      : contact.status === 'invited' || contact.status === 'reinvited'
                      ? 'purple'
                      : ''
                  }
                >
                  {contact.status === 'archived'
                    ? 'Archived'
                    : contact.status === 'registered'
                    ? 'Active'
                    : contact.status === 'confirmed'
                    ? 'Confirmed'
                    : contact.status === 'invited'
                    ? 'Invited'
                    : contact.status === 'reinvited'
                    ? 'Reinvited'
                    : ''}
                </StateBadge>
              </div>
            ) : (
              <></>
            )}
            <div className='tw-col-span-6 tw-flex tw-items-center tw-justify-end tw-justify-self-end sm:tw-col-span-3'>
              <span
                className={clsx('tw-pr-3 tw-text-xs tw-font-semibold', {
                  'tw-ml-3': contact.status !== 'archived',
                })}
              >
                {contact.status === 'archived' ? 'Archived' : 'Active'}
              </span>
              <Switch variant={active ? 'teal-bg' : 'gray-bg'} isChecked={active} onChange={handleSwitchClick} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactListItem;
