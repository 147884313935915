import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import { timezoneNameWithOffset } from 'utils/v2/timezones';
import { useGQLDataContext } from './GQLDataContext';
import { findOptionByValue } from 'utils/v2/data';

const initialState = {
  data: [],
};

const actionTypes = {
  SET_DATA: 'TimezonesContext.setData',
};

const TimezonesContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_DATA:
      return { ...state, data: payload.data };
  }
};

export const TimezonesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { gqlData, getDataByPath } = useGQLDataContext();

  const format = ({ gmtOffset, name }) => ({
    label: timezoneNameWithOffset({ name, gmtOffset }),
    value: name,
    gmtOffset,
  });

  useEffect(() => {
    if (gqlData) {
      const data = getDataByPath('currentCompany.timezones', []).map(format);
      dispatch({ type: actionTypes.SET_DATA, payload: { data } });
    }
  }, [gqlData]);

  const getTimezoneByValue = (value) => findOptionByValue(value, data);

  const { data } = state;

  return (
    <TimezonesContext.Provider value={{ timezones: data, getTimezoneByValue }}>{children}</TimezonesContext.Provider>
  );
};

TimezonesContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useTimezonesContext = () => useContext(TimezonesContext);
