/** @jsx jsx */

import React, { memo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { HighlightTag } from 'components/shared/HighlightTag';
import { Dropdown } from 'components/shared/Dropdown';
import { DropdownOption } from 'components/shared/DropdownOption';

import Highlighter from 'react-highlight-words';

import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';

import * as styles from './ZoomMeetingsDropdown.styles';

const ZoomMeetingsDropdown = memo(({ selectedValue, onSelect }) => {
  const [zoomMeetings, setZoomMeetings] = useState(null);

  const intl = useIntl();

  const fetchZoomMeetings = () => {
    const apiURL = `/api/${getCompanySlug()}/v1/zoom_meetings.json`;

    const promise = axios
      .get(apiURL)
      .then((response) => response.data)
      .then(setZoomMeetings);

    return trackPromise(promise);
  };

  useEffect(() => {
    fetchZoomMeetings();
  }, []);

  const getZoomMeetingLabel = () => zoomMeetings?.find((meeting) => meeting?.value === selectedValue)?.label;

  let [isOpen, setIsOpen] = useState(false);
  let [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => setSearchTerm(event.target.value || '');
  const handleFocus = () => setIsOpen(true);
  const handleBlur = () => setIsOpen(false);

  const regex = new RegExp(searchTerm, 'gi');
  const pickerOptions = zoomMeetings?.filter((option) => option.label.match(regex));
  const hasOptions = pickerOptions && pickerOptions.length > 0;

  const checkmark = (
    <div className='checkmark-circle'>
      <div className='checkmark' />
    </div>
  );

  const renderOption = (meeting) => {
    const key = `meeting-picker-${meeting.value}`;
    const onClick = onSelect.bind(this, meeting, handleBlur);
    const isSelected = meeting.value === selectedValue;

    return (
      <DropdownOption key={key} onClick={onClick}>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <div className='w-100 text-truncate'>
            <Highlighter textToHighlight={meeting.label} highlightTag={HighlightTag} searchWords={[searchTerm]} />
          </div>

          {isSelected && checkmark}
        </div>
      </DropdownOption>
    );
  };

  const noResults = <DropdownOption noResults />;
  const dropdownContent = pickerOptions?.map(renderOption);
  const dropdownBody = hasOptions ? dropdownContent : noResults;
  const wrapperStyles = styles.wrapper(hasOptions);

  return (
    <>
      <div css={styles.dropdownWrapper}>
        <Dropdown
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputPlaceholder={intl.formatMessage({ id: 'ZoomMeetingsDropdown.selectPlaceholder' })}
          inputIcon={<i className='fa fa-chevron-down fs-14 fw-300' />}
          inputIconPosition='right'
          inputValue={isOpen ? searchTerm : getZoomMeetingLabel() || ''}
          isOpen={isOpen}
          onInputChange={handleInputChange}
          rotatableIcon
        >
          <div css={wrapperStyles}>
            <div css={styles.items} className='cursor-pointer'>
              {dropdownBody}
            </div>
          </div>
        </Dropdown>
      </div>
    </>
  );
});

export { ZoomMeetingsDropdown };
