/** @jsx jsx */

import React, { memo, useCallback, useState } from 'react';
import { jsx } from '@emotion/core';

import { Col } from 'react-bootstrap';
import { Spinner } from 'components/shared/Spinner';
import { CommentToggle } from './../CommentToggle';
import { CommentList } from './../CommentList';
import { Editor } from './../Editor';

import { useActionCable } from 'hooks/useActionCable';
import { useFlashActions } from 'store/flashStoreContext';
import { useMeetingActions, useMeetingStore } from 'store/meetingStoreContext';
import { useMinutesActions } from 'store/minutesStoreContext';
import { useOoEActions, useOoEStore } from 'store/ooeStoreContext';
import { useReviewsStore } from 'store/reviewsStoreContext';

import * as styles from './ReviewSection.styles';

const ReviewSection = memo(
  ({ minuteId, minuteOwnerId, currentContact, onTextChange, quillRef, isEditorReadOnly, isCommentListReadOnly }) => {
    const { meeting, loaded } = useMeetingStore();
    const { setMinutesContext } = useMinutesActions();
    const { ooe } = useOoEStore();
    const { reviews } = useReviewsStore();

    const { setFlash } = useFlashActions();
    const { setOoE } = useOoEActions();
    const { loadMeeting } = useMeetingActions();

    useActionCable('MinutesChannel', { minute_id: minuteId }, (response) => setMinutesContext(minuteId, response));

    const [selection, setSelection] = useState({
      top: 0,
      show: false,
      active: false,
      text: null,
      images: [],
    });

    const handleSelectionChange = useCallback((range, source, editor) => {
      if (isCommentListReadOnly) return;

      if (!range || !range.length) {
        return setSelection((state) => ({ ...state, show: false }));
      }

      const text = editor.getText(range.index, range.length);
      const images = editor
        .getContents(range.index, range.length)
        .ops.filter((item) => item.insert.image)
        .map((item) => item.insert.image);

      setSelection({
        show: true,
        text: text,
        top: editor.getBounds(range).top,
        images: images,
      });
    }, []);

    const handleToggleClick = useCallback(() => {
      setSelection((state) => ({ ...state, active: true }));
    }, []);

    const handleCancelClick = useCallback(() => {
      setSelection((state) => ({ ...state, active: false }));
    }, []);

    if (!loaded) {
      return <Spinner show />;
    }

    return (
      <>
        <Col md={9} css={styles.outline}>
          <Editor
            ref={quillRef}
            value={ooe?.description}
            onChange={onTextChange}
            onSelectionChange={handleSelectionChange}
            isReadOnly={isEditorReadOnly}
          />

          <CommentToggle show={selection.show} posY={selection.top} onClick={handleToggleClick} />
        </Col>

        <Col md={3} css={styles.outline}>
          <CommentList
            minuteId={minuteId}
            minuteOwnerId={minuteOwnerId}
            currentContact={currentContact}
            selectedText={selection.text}
            selectedImages={selection.images}
            showAddCommentForm={selection.active}
            onAddCommentFormCancel={handleCancelClick}
            isReadOnly={isCommentListReadOnly}
          />
        </Col>
      </>
    );
  }
);

export { ReviewSection };
