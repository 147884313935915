import { useSubscription } from '@apollo/client';
import { CONVERSATION_QUERY } from './../data/queries/ConversationQuery';
import { CONVERSATION_SUBSCRIPTION } from './../data/subscriptions/ConversationSubscription';

const useConversationSubscription = ({ onUpdate }) => {
  useSubscription(CONVERSATION_SUBSCRIPTION, {
    shouldResubscribe: true,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const cache = client.readQuery({ query: CONVERSATION_QUERY, variables: {} });

      if (!subscriptionData.data) return;

      const { messageAdded } = subscriptionData.data;

      if (!messageAdded) return;

      onUpdate(!messageAdded.owner.isCurrentContact);

      if (!cache) return;

      const { messages } = cache.currentCompany.conversation;
      const isAdded = messages.edges.find((message) => message.node.id === messageAdded.id);

      if (isAdded) return;

      client.writeQuery({
        query: CONVERSATION_QUERY,
        data: {
          ...cache,
          currentCompany: {
            ...cache.currentCompany,
            conversation: {
              ...cache.currentCompany.conversation,
              messages: {
                ...cache.currentCompany.conversation.messages,
                edges: [
                  ...cache.currentCompany.conversation.messages.edges,
                  { node: messageAdded, __typename: 'MessageEdge', cursor: null },
                ],
              },
            },
          },
        },
      });
    },
  });
};

export { useConversationSubscription };
