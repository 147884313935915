import { css } from '@emotion/core';
import { colors } from 'styles';

export const chip = {
  base: css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
  `,

  rounded: css`
    border-radius: 23px;
  `,

  flatRight: css`
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
  `,

  flatLeft: css`
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
  `,

  clickable: css`
    cursor: pointer;

    & > * {
      cursor: pointer;
    }

    &:hover {
      opacity: 0.8;
    }
  `,

  grey: css`
    background: ${colors.chipGrey};
  `,

  darkGrey: css`
    background: ${colors.headingGrey};
  `,

  lightCyan: css`
    background: ${colors.lightCyan};
  `,

  cyan: css`
    background: ${colors.cyan};
  `,

  yellow: css`
    background: ${colors.yellow};
  `,
};
