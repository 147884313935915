import React from 'react';

const TwoMediumLinesCell = () => {
  return (
    <div className='placeholder-table-cell'>
      <div className='placeholder-text-stub regular grey w-percent-40' />
      <div className='placeholder-text-stub regular grey w-percent-20' />
    </div>
  );
};

export { TwoMediumLinesCell };
