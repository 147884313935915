import React, { memo } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';

import MoveForm from './MoveForm';

export default memo(({ documentId, formUrl, currentDirectoryPath, rootDirectory }) => {
  const closeModal = () => {
    $('#modal-window').modal('hide');
  };

  const intl = useIntl();

  const handleSuccess = () => {
    clearFlashMessages();
    $.get(currentDirectoryPath);
    closeModal();
  };

  const handleFail = ({ response: { data } }) => {
    closeModal();
    const error = data.message || intl.formatMessage({ id: 'DocumentMoveForm.error' });
    displayFlashMessage(error, 'error');
  };

  const onFormSubmit = (formData, destinationDirectoryId) => {
    const payload = { destination_directory_id: destinationDirectoryId };
    axios
      .post(formUrl, payload, { headers: { Accept: 'application/json' } })
      .then(handleSuccess)
      .catch(handleFail);
  };

  const directorySuitable = (directory) => {
    return directory.data.attributes.moveTargetable;
  };

  return <MoveForm rootDirectory={rootDirectory} onFormSubmit={onFormSubmit} directorySuitable={directorySuitable} />;
});
