import { gql, useMutation } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from './../fragments/DocumentFragment';

const INCREMENT_DOCUMENT_NAME = gql`
  mutation incrementDocumentName($input: IncrementDocumentNameInput!) {
    incrementDocumentName(input: $input) {
      document {
        ...DocumentFields
      }

      errors {
        attribute
        fullMessage
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

const useIncrementDocumentNameMutation = (options) => useMutation(INCREMENT_DOCUMENT_NAME, options);

export { useIncrementDocumentNameMutation };
