import { gql, useMutation } from '@apollo/client';

const CREATE_DOCUMENT_MUTATION = gql`
  mutation createDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      document {
        id
        name
      }
    }
  }
`;

const useCreateDocumentMutation = (options) => useMutation(CREATE_DOCUMENT_MUTATION, options);

export { CREATE_DOCUMENT_MUTATION, useCreateDocumentMutation };
