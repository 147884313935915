import { createStore } from './createStore';
import { deleteById, updateById } from 'utils';

import { trackPromise } from 'react-promise-tracker';
import { getAttributes } from 'helpers/getAttributes';

import axios from 'axios';

const initialState = {
  agendas: [],
  visibilityMap: {},
};

const actionTypes = {
  SET: 'agendas.set',
  ADD: 'agendas.add',
  UPDATE: 'agendas.update',
  DELETE: 'agendas.remove',
  TOGGLE_VISIBILITY: 'agenda.toggleVisibility',
  TOGGLE_ALL_VISIBILITY: 'agenda.toggleAllVisibility',
};

const companyId = getCompanySlug();

const api = {
  toggleAgenda: (meetingId, agendaId) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/agendas/${agendaId}/toggle.json`;

    const promise = axios
      .patch(apiURL)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  createAgenda: (meetingId, params) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/agendas.json`;
    const payload = { agenda: params };

    const promise = axios
      .post(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  updateAgenda: (meetingId, agendaId, params) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/agendas/${agendaId}.json`;
    const payload = { agenda: params };

    const promise = axios
      .put(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  deleteAgenda: (meetingId, agendaId) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/agendas/${agendaId}.json`;

    const promise = axios
      .delete(apiURL)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  reorderAgendas: (meetingId, params) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/agendas/reorder.json`;
    const payload = { agenda: params };

    const promise = axios
      .put(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },
};

const useAgendasActions = () => {
  const dispatch = useAgendasDispatch();

  const toggleAgenda = (meetingId, agendaId) => {
    return api.toggleAgenda(meetingId, agendaId).then((agenda) => {
      dispatch({ type: actionTypes.UPDATE, payload: agenda });
    });
  };

  const toggleAgendaVisibility = (agendaId) => {
    return dispatch({ type: actionTypes.TOGGLE_VISIBILITY, payload: agendaId });
  };

  const toggleAgendasVisibility = (isVisible) => {
    return dispatch({ type: actionTypes.TOGGLE_ALL_VISIBILITY, payload: isVisible });
  };

  const createAgenda = (meetingId, params) => {
    return api.createAgenda(meetingId, params).then(setAgendas);
  };

  const updateAgenda = (meetingId, agendaId, params) => {
    return api.updateAgenda(meetingId, agendaId, params).then(setAgendas);
  };

  const deleteAgenda = (meetingId, agendaId) => {
    return api.deleteAgenda(meetingId, agendaId).then(setAgendas);
  };

  const reorderAgendas = (meetingId, params) => {
    return api.reorderAgendas(meetingId, params).then(setAgendas);
  };

  const setAgendas = (agendas) => {
    dispatch({ type: actionTypes.SET, payload: agendas });
  };

  return {
    setAgendas,
    toggleAgenda,
    createAgenda,
    updateAgenda,
    deleteAgenda,
    reorderAgendas,
    toggleAgendaVisibility,
    toggleAgendasVisibility,
  };
};

const getVisibilityMap = (state, payload) => {
  return payload.reduce((accumulator, agenda) => {
    return { ...accumulator, [agenda.id]: state.visibilityMap[agenda.id] || false };
  }, {});
};

const setVisibilityMap = (state, value) => {
  return Object.keys(state.visibilityMap).reduce((accumulator, key) => {
    return { ...accumulator, [key]: value };
  }, {});
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET:
      return { agendas: payload, visibilityMap: getVisibilityMap(state, payload) };
    case actionTypes.UPDATE:
      return { agendas: updateById(state.agendas, payload), visibilityMap: getVisibilityMap(state, [payload]) };
    case actionTypes.TOGGLE_VISIBILITY:
      return { ...state, visibilityMap: { ...state.visibilityMap, [payload]: !state.visibilityMap[payload] } };
    case actionTypes.TOGGLE_ALL_VISIBILITY:
      return { ...state, visibilityMap: setVisibilityMap(state, payload) };
  }
};

const [AgendasProvider, useAgendasStore, useAgendasDispatch] = createStore(reducer, initialState);

export { AgendasProvider, useAgendasStore, useAgendasActions };
