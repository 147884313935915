import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const trigger = css`
  cursor: pointer;
`;

export const dropdown = css`
  background: ${colors.white};
  box-shadow: 0px 3px 20px rgba(16, 8, 63, 0.11);
  position: absolute;
  right: 25px;
  z-index: 2;
  ${media.tablet} {
    width: 100%;
    right: 0;
    top: unset !important; /* bottom: 66px; */
    z-index: 1000;
    bottom: 0;
    position: fixed;
    border-radius: 0;
  }
`;

export const container = css`
  display: inline-block;
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  ${media.tablet} {
    margin-left: 7px;
  }
`;

export const option = css`
  ${fonts.regular}
  color: ${colors.black};
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  line-height: 1;
  padding: 12px 15px;

  ${media.tablet} {
    padding: 15px;
  }

  &:hover {
    background: ${colors.lightestGrey};
  }
`;

export const icon = css`
  flex-shrink: 0;
`;
