/** @jsx jsx */

import React, { memo, useRef, useState, useCallback, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';
import Alert from 'react-bootstrap/Alert';

import { AgendaQuickActions } from './../AgendaQuickActions';
import { EditableInput } from 'components/shared/EditableInput';
import { AgendaAssigneeSelect } from './../AgendaAssigneeSelect';
import { DurationPicker } from './../DurationPicker';

import { MAX_SUBITEM_DEPTH } from '../constants';
import { ReactComponent as PlusIcon } from 'images/icons/agenda-plus.svg';
import { ReactComponent as MinusIcon } from 'images/icons/agenda-minus.svg';
import { ReactComponent as CheckmarkIcon } from 'images/icons/checkmark.svg';
import useWindowDimensions from 'hooks/useWindowDimensions';

import * as styles from './AgendaItemWrapper.styles';

import { useHover } from 'hooks/useHover';
import { useFlashActions } from 'store/flashStoreContext';
import { useAgendasActions } from 'store/agendasStoreContext';

const AgendaItemWrapper = memo(
  ({
    meetingId,
    isVisible,
    agenda,
    contacts,
    subItems,
    meetingDuration,
    theme,
    children,
    isBlank,
    showToggle,
    onReorder,
    adminAccess,
    editAccess,
    childrenDuration,
  }) => {
    const { setFlash } = useFlashActions();
    const { toggleAgenda, updateAgenda, createAgenda, toggleAgendaVisibility } = useAgendasActions();

    const { isDesktop } = useWindowDimensions();
    const [hoverableRef, isHovered] = useHover();
    const [isEditingTitle, setIsEditingTitle] = useState(false);

    const startEditingTitle = () => setIsEditingTitle(true);
    const stopEditingTitle = () => setIsEditingTitle(false);

    const [numberWidth, setNumberWidth] = useState(0);
    const numberRef = useRef();

    React.useLayoutEffect(() => setNumberWidth(numberRef.current?.clientWidth), [numberRef.current]);

    const [heading, setHeading] = useState(agenda.heading);

    useEffect(() => setHeading(agenda.heading), [agenda.heading]);

    const updateHeading = (text) => {
      updateAgenda(meetingId, agenda.id, { heading: text }).then(stopEditingTitle).catch(setFlash);
    };

    const toggleCollapsed = () => {
      toggleAgendaVisibility(agenda.id);
    };

    const handleToggle = useCallback(() => {
      toggleAgenda(meetingId, agenda.id).catch(setFlash);
    }, []);

    // Styling
    const wrapper = styles.wrapper(numberWidth, false);

    const styling = {
      container: [
        theme.container.base,
        agenda.root && theme.container.withBackground,
        isHovered && theme.container.hovered,
      ],

      content: [theme.content.base, isHovered && theme.content.hovered],

      wrapper: [wrapper.base, agenda.root ? wrapper.root : wrapper.child],

      hoverable: agenda.root ? styles.padded.root : styles.padded.child,

      checkmark: theme.checkmark,
      circle: theme.circle,
    };

    const addSubItem = () => {
      createAgenda(meetingId, {
        parent_id: agenda.id,
      }).catch(setFlash);
    };

    const [warning, setWarning] = useState()
    useEffect(() => {
      if (agenda.duration_in_minutes > meetingDuration) {
        setWarning("Agenda item's duration is longer than the meeting's duration")
      } else {
        setWarning()
      }
    }, [agenda.duration_in_minutes])
    useEffect(() => {
      if (childrenDuration > agenda.duration_in_minutes) {
        setWarning("Sub-items' duration is longer than the agenda item's duration")
      } else {
        setWarning()
      }
    }, [childrenDuration])

    return (
      <div css={styling.container} className='agenda-item'>
        {agenda.root && showToggle && (
          <div css={styling.checkmark} onClick={handleToggle} className='agenda-circle'>
            <div css={styling.circle}>{agenda.completed && <CheckmarkIcon />}</div>
          </div>
        )}

        <div ref={hoverableRef} className='position-relative' css={styling.hoverable}>
          <AgendaQuickActions
            show={adminAccess && isHovered}
            meetingId={meetingId}
            agendaId={agenda.id}
            position={agenda.position}
            parentId={agenda.parent_id}
            depth={agenda.depth}
            onReorder={onReorder}
          />

          <div className='position-relative d-flex align-items-center justify-content-between'>
            <div className='d-flex flex-grow-1 align-items-baseline m-r-10'>
              <div css={styles.heading} ref={numberRef}>
                {agenda.number}
              </div>

              <div className='m-l-5'>
                <div css={styles.heading} className='d-inline-flex agenda-heading'>
                  <EditableInput
                    value={heading}
                    isEditable={isEditingTitle}
                    onClick={startEditingTitle}
                    onCancel={stopEditingTitle}
                    onUpdate={updateHeading}
                    isReadOnly={!adminAccess && !editAccess}
                    maxWidth={500}
                  />
                </div>

                {warning && (
                  <Alert dismissible css={styles.warning} onClose={() => setWarning()}>
                    <p>{ warning }</p>
                  </Alert>
                )}

                <div className='d-flex flex-wrap lh-1 m-t-5'>
                  <div className='m-r-15 m-b-10'>
                    <DurationPicker
                      meetingId={meetingId}
                      agendaId={agenda.id}
                      isEditable={adminAccess || editAccess}
                      maxDuration={agenda.parent_duration_in_minutes}
                      value={agenda.duration_in_minutes}
                    />
                  </div>

                  <div className='m-b-10'>
                    <AgendaAssigneeSelect
                      options={contacts}
                      meetingId={meetingId}
                      agendaId={agenda.id}
                      assigneeId={agenda.assignee_id}
                      isEditable={adminAccess}
                    />
                  </div>
                </div>
              </div>
            </div>

            {!isBlank && (
              <div css={styles.viewModeIcon} onClick={toggleCollapsed}>
                {isVisible ? <MinusIcon /> : <PlusIcon />}
              </div>
            )}
          </div>

          {agenda.depth < MAX_SUBITEM_DEPTH && !isDesktop ? (
            <div onClick={addSubItem} className='btn btn-responsive btn-primary m-t-20'>
              <div className='d-flex align-items-center'>
                <i className='far fa-plus-circle fs-20' />
                <div className='m-l-5'>
                  <FormattedMessage id='AgendaItemWrapper.AddSubitem' />
                </div>
              </div>
            </div>
          ) : null}

          {!isBlank && (
            <div className={isVisible ? 'd-block' : 'd-none'}>
              <div css={styling.wrapper}>
                <div css={styling.content}>{children}</div>
              </div>
            </div>
          )}
        </div>

        {subItems.length > 0 && (
          <div className='d-block'>
            <div css={styling.hoverable}>{subItems}</div>
          </div>
        )}
      </div>
    );
  }
);

export { AgendaItemWrapper };
