import React, { useRef } from 'react';
import SignatureInput from 'components/v2/pages/signatureInput';
import { useUpdateProfileSmartSignSettingsMutation } from 'data/v2/mutations/UpdateProfileSmartSignSettingsMutation';
import { useClearProfileSmartSignSignatureMutation } from 'data/v2/mutations/ClearProfileSmartSignSignatureMutation';
import { uploadFile } from 'utils/v2/directUploads';
import { wrapMutation } from 'utils/v2/gql';
import { notify } from 'components/v2/notification';

const SmartSign = ({ currentUser }) => {
  const [updateProfileSmartSignSettings] = useUpdateProfileSmartSignSettingsMutation();
  const [clearProfileSmartSignSignature] = useClearProfileSmartSignSignatureMutation();

  const signatureInputRef = useRef();

  const handleClearSignature = () => {
    wrapMutation(clearProfileSmartSignSignature, { variables: { input: {} } }, 'clearProfileSmartSignSignature')
      .then(() => {
        notify('success', 'Your signature was successfully cleared!');
      })
      .catch((err) => console.log(err));
  };

  const handleSave = (blob) => {
    signatureInputRef.current.processInput((signatureBlob) => {
      signatureBlob.name = 'signature';
      uploadFile(signatureBlob, {}).then((blob) => {
        wrapMutation(
          updateProfileSmartSignSettings,
          { variables: { input: { signatureBlobSignedId: blob.signed_id } } },
          'updateProfileSmartSignSettings'
        )
          .then(() => {
            notify('success', 'Your signature was successfully saved!');
          })
          .catch((err) => console.log(err));
      });
    });
  };

  return (
    <SignatureInput
      ref={signatureInputRef}
      onSave={handleSave}
      onClearSignature={handleClearSignature}
      existingSignatureImageUrl={currentUser.signatureUrl}
      name={`${currentUser.firstName} ${currentUser.lastName}`}
    />
  );
};

export default SmartSign;
