export default {
  Company: {
    fields: {
      searchableEntries: {
        keyArgs: ['searchTerm', 'parentId', 'parentType', 'sortDirection'],
        merge(existing = {}, incoming, { args }) {
          if (!args.after) return incoming;
          if (!existing.edges) return incoming;

          const edges = [...existing.edges, ...incoming.edges];
          return { ...incoming, edges };
        },
      },
    },
  },
};
