import React, { memo, useState } from 'react';

import FileViewer from 'components/v2/shared/FileViewer';
import { useFileViewer } from 'components/documents/hooks/useFileViewer';

export default memo(({ buttonText, documentName, documentUrl, className }) => {
  const previewDocument = () => openFileViewer();

  const { fileViewerContext, openFileViewer, closeFileViewer } = useFileViewer();

  return (
    <>
      <div className={className || 'btn btn-primary'} onClick={previewDocument}>
        {buttonText}
      </div>

      <FileViewer
        fileName={documentName}
        fileUrl={documentUrl}
        isOpen={fileViewerContext.show}
        onClose={closeFileViewer}
      />
    </>
  );
});
