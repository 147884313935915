/** @jsx jsx */

import React, { memo, useCallback, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { TimeInput } from 'components/shared/TimeInput';
import { formatTime } from 'helpers/formatDateTime';
import { getErrors } from 'helpers/getErrors';

import { useClickOutside } from 'hooks/useClickOutside';

import { useAgendasActions } from 'store/agendasStoreContext';

import * as styles from './AgendaTimePicker.styles';
import { utils } from 'styles';

const AgendaTimePicker = memo(({ meetingId, agendaId, startTime, endTime, isEditable }) => {
  const { updateAgenda } = useAgendasActions();

  const timeline = [formatTime(startTime), formatTime(endTime)].join(' - ');

  if (!isEditable) {
    return (
      <div css={styles.component.base}>
        <div>{timeline}</div>
      </div>
    );
  }

  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside();

  const [_startTime, setStartTime] = useState(startTime);
  const [_endTime, setEndTime] = useState(endTime);
  const [errors, setErrors] = useState({});

  const showDropdown = () => setIsComponentVisible(true);
  const hideDropdown = () => setIsComponentVisible(false);

  useEffect(() => setStartTime(startTime), [startTime]);
  useEffect(() => setEndTime(endTime), [endTime]);
  useEffect(() => hideDropdown(), [startTime, endTime]);

  useEffect(() => {
    if (!isComponentVisible) setErrors({});
  }, [isComponentVisible]);

  const handleError = useCallback((response) => {
    const messages = getErrors(response);
    setErrors(messages);
  }, []);

  const setTime = () => {
    updateAgenda(meetingId, agendaId, {
      start_time: formatTime(_startTime),
      end_time: formatTime(_endTime),
    }).catch(handleError);
  };

  const handleStartTimeChange = (value) => {
    setStartTime(value);
    setErrors({});
  };

  const handleEndTimeChange = (value) => {
    setEndTime(value);
    setErrors({});
  };

  const message = errors.start_time || errors.end_time;

  const dropdown = isComponentVisible && (
    <div css={styles.dropdown} ref={ref}>
      <div css={styles.padded} className='m-b-15'>
        <TimeInput
          label='Start time'
          onChange={handleStartTimeChange}
          value={startTime}
          errorMessage={errors.start_time}
          name='start_time'
          hideErrors
        />
      </div>

      <div css={utils.separator} />

      <div css={styles.padded} className='m-t-15'>
        <TimeInput
          label='End time'
          onChange={handleEndTimeChange}
          value={endTime}
          name='end_time'
          errorMessage={errors.end_time}
          hideErrors
        />
      </div>

      <div css={styles.padded} className='d-flex justify-content-center m-t-15'>
        {message ? (
          <a css={styles.errorButton} className='btn w-100'>
            {message}
          </a>
        ) : (
          <a onClick={setTime} className='btn btn-primary w-100'>
            <FormattedMessage id='AgendaTimePicker.setTime' />
          </a>
        )}
      </div>
    </div>
  );

  const componentStyles = [styles.component.base, styles.component.hoverable];

  return (
    <div css={componentStyles} onClick={showDropdown}>
      <div>{timeline}</div>

      {dropdown}
    </div>
  );
});

export { AgendaTimePicker };
