import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '@appsignal/react';

import appSignal from 'utils/v2/appSignal';

const AppSignalWrapper = ({ tags, children }) => (
  <ErrorBoundary tags={{ ...tags }} instance={appSignal}>
    {children}
  </ErrorBoundary>
);

AppSignalWrapper.propTypes = {
  tags: PropTypes.object,
  children: PropTypes.node,
};

export default AppSignalWrapper;
