/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { ReactComponent as Icon } from 'images/icons/documents/arrow-down.svg';

import * as styles from './SortButton.styles';

const SortButton = memo(({ show, text, isRotated, onClick }) => {
  const iconStyles = [styles.icon.base, isRotated && styles.icon.rotated];

  return (
    show && (
      <div css={styles.container} onClick={onClick}>
        <div css={styles.text}>{text}</div>

        <Icon css={iconStyles} />
      </div>
    )
  );
});

export { SortButton };
