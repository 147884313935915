/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';

import { HighlightTag } from 'components/shared/HighlightTag';
import { Dropdown } from 'components/shared/Dropdown';
import { DropdownOption } from 'components/shared/DropdownOption';
import { formatDuration } from 'helpers/formatDuration';

import { useRegExpFormatter } from 'hooks/useRegExpFormatter';

import * as styles from './AgendaPicker.styles';
import { icons } from 'styles';

const AgendaPicker = memo(({ inputLabel, inputValue, dropdownWidth, options, value, onAdd, onRemove, isReadOnly }) => {
  let [isOpen, setIsOpen] = useState(false);
  let [searchTerm, setSearchTerm] = useState('');

  const intl = useIntl();

  const { formattedRegExp } = useRegExpFormatter(searchTerm);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value || '');
  };

  const regex = new RegExp(formattedRegExp, 'gi');

  const pickerOptions = options
    .filter((option) => option.id !== value?.id)
    .filter((option) => option.heading.match(regex));

  const isSearching = searchTerm.length > 0;
  const hasOptions = pickerOptions.length > 0;

  const handleFocus = () => setIsOpen(true);
  const handleBlur = () => setIsOpen(false);

  const renderOption = (agenda) => {
    const key = `agenda-picker-${agenda.id}`;
    const handleClick = onAdd.bind(this, agenda);
    const heading = `${agenda.number} ${agenda.heading}`;

    return (
      <DropdownOption key={key} onClick={handleClick}>
        <div className='d-flex align-items-center flex-wrap w-100'>
          <div className='w-100 text-truncate'>
            <Highlighter textToHighlight={heading} highlightTag={HighlightTag} searchWords={[formattedRegExp]} />
          </div>

          <div css={styles.duration}>{formatDuration(agenda.duration_in_minutes)}</div>
        </div>
      </DropdownOption>
    );
  };

  const noResults = <DropdownOption noResults />;
  const dropdownContent = pickerOptions.map(renderOption);
  const dropdownBody = hasOptions ? dropdownContent : noResults;

  return (
    <>
      <Dropdown
        onFocus={handleFocus}
        onBlur={handleBlur}
        inputPlaceholder={intl.formatMessage({ id: 'AgendaPicker.addItems' })}
        inputValue={searchTerm}
        isOpen={isOpen}
        isDisabled={isReadOnly}
        inputLabel={inputLabel}
        dropdownWidth={dropdownWidth}
        onInputChange={handleInputChange}
      >
        <div>
          <div css={styles.items}>{dropdownBody}</div>
        </div>
      </Dropdown>

      {value && (
        <div css={styles.value}>
          <div className='d-flex align-items-center flex-wrap w-percent-90'>
            <div className='font-weight-semibold fs-14 text-black w-100 text-truncate'>
              {value.number} {value.heading}
            </div>

            <div css={styles.duration}>{formatDuration(value.duration_in_minutes)}</div>
          </div>

          {!isReadOnly && <i className='far fa-trash-alt' css={icons.base} onClick={onRemove} />}
        </div>
      )}
    </>
  );
});

export { AgendaPicker };
