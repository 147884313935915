import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Tabs = ({ currentTab, tabs = {}, onClick = () => {} }) => (
  <div
    className={clsx(
      'tw-flex tw-shrink-0 tw-items-center',
      'tw-box-border tw-h-11 tw-w-full',
      'tw-border-0 tw-border-b tw-border-solid tw-border-gray-200'
    )}
  >
    {Object.keys(tabs).map((key) => (
      <button
        key={key}
        onClick={onClick(tabs[key])}
        className={clsx(
          'tw-border-0 tw-bg-transparent tw-outline-none',
          'tw-h-full tw-w-1/2',
          'tw-font-sans tw-text-sm tw-font-medium tw-text-black',
          'hover:tw-opacity-80',
          {
            '-tw-mb-0.5 tw-border-b-2 tw-border-solid tw-border-purple-500': currentTab === tabs[key],
          }
        )}
      >
        {key}
      </button>
    ))}
  </div>
);

Tabs.propTypes = {
  currentTab: PropTypes.string,
  tabs: PropTypes.object,
  onClick: PropTypes.func,
};

export default Tabs;
