import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const noProductBox = css`
  background-color: #fff9f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  padding: 15px;
  margin-bottom: 30px;
`;

export const noProductText = css`
  max-width: 400px;
  line-height: 20px;
  color: ${colors.black};
  margin: 10px 10%;
`;

export const activeProductBox = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  padding: 15px;
  margin-bottom: 60px;

  productBox {
    margin-bottom: -80px;
  }
`;

export const productBox = css`
  background: #fff;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0px 4px 50px rgba(54, 71, 98, 0.12);
  border-radius: 4px;
  padding: 15px 15px 25px 15px;
  border-top: 7px solid white;
  position: relative;
`;

export const cardsTable = css`
  border: 1px solid #f2f2f2;
  border-radius: 16px;
  margin-top: 20px;
`;

export const cardsTableRow = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #f2f2f2;
`;
