import React, { useEffect, createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { generateMeetingDates } from 'utils/v2/dates';
import { useGQLDataContext } from './GQLDataContext';
import { useCurrentContactContext } from 'contexts/v2/CurrentContactContext';
import { useCreateMeetingMutation } from 'data/v2/mutations/CreateMeetingMutation';
import { useUpdateMeetingMutation } from 'data/v2/mutations/UpdateMeetingMutation';
import { wrapMutation } from 'utils/v2/gql';
import { serializeMeeting } from 'utils/v2/serializers';

const [meetingDate, meetingStartTime, meetingEndTime] = generateMeetingDates();

const MEETING_DEFAULTS = {
  meetingId: null,
  status: 'draft',
  slug: null,
  contactId: '',
  title: '',
  description: '',
  type: '',
  meetingDate,
  meetingStartTime,
  meetingEndTime,
  timezone: null,
};

const initialState = {
  meeting: { ...MEETING_DEFAULTS },
  edits: { ...MEETING_DEFAULTS },
};

const actionTypes = {
  UPDATE_EDITS: 'MeetingContext.updateEdits',
  UPDATE_DATA: 'MeetingContext.updateData',
  DISCARD_EDITS: 'MeetingContext.discardEdits',
};

const MeetingContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.UPDATE_EDITS:
      return { ...state, edits: { ...state.edits, ...payload.updates } };
    case actionTypes.UPDATE_DATA:
      return { ...state, meeting: serializeMeeting(payload.meeting), edits: serializeMeeting(payload.meeting) };
    case actionTypes.DISCARD_EDITS:
      return { ...state, edits: { ...state.meeting } };
  }
};

export const MeetingContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [createMeeting] = useCreateMeetingMutation();
  const [updateMeeting] = useUpdateMeetingMutation();

  const { gqlData, id, getDataByPath } = useGQLDataContext();
  const { currentContact } = useCurrentContactContext();

  const updateEdits = (fieldName, value) => {
    dispatch({ type: actionTypes.UPDATE_EDITS, payload: { updates: { [fieldName]: value } } });
  };

  const updateMultipleEdits = (values) => {
    dispatch({ type: actionTypes.UPDATE_EDITS, payload: { updates: { ...values } } });
  };

  const updateData = (data) => {
    const { meeting } = data;
    dispatch({ type: actionTypes.UPDATE_DATA, payload: { meeting } });
  };

  useEffect(() => {
    if (gqlData) {
      if (id) {
        updateData(getDataByPath('currentCompany', []));
      } else {
        updateMultipleEdits({ contactId: currentContact.id, timezone: currentContact.timezone.name });
      }
    }
  }, [gqlData]);

  const saveMeetingData = async () => {
    const { meetingId, chairman, status, slug, ...input } = edits;

    if (!meetingId) {
      return wrapMutation(createMeeting, { variables: { input: { attributes: { ...input } } } }, 'createMeeting').then(
        updateData
      );
    } else {
      return wrapMutation(
        updateMeeting,
        { variables: { input: { meetingId, attributes: { ...input } } } },
        'updateMeeting'
      ).then(updateData);
    }
  };

  const discardEdits = () => {
    dispatch({ type: actionTypes.DISCARD_EDITS });
  };

  const { meeting, edits } = state;

  return (
    <MeetingContext.Provider value={{ meeting, edits, updateEdits, saveMeetingData, discardEdits }}>
      {children}
    </MeetingContext.Provider>
  );
};

MeetingContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useMeetingContext = () => useContext(MeetingContext);
