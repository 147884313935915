import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const card = css`
  margin-right: 30px;
  width: 56px;
  height: auto;
`;

export const cardsTable = css`
  border: 1px solid #f2f2f2;
  border-radius: 16px;
  margin-top: 20px;
  position: relative;

  @media (max-width: 767px) {
    border: none;
  }
`;

export const cardsTableRow = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 35px;
  border-bottom: 1px solid #f2f2f2;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    background: ${colors.white};
    border: 1px solid #ced2e0;
    border-radius: 16px;
    padding: 16px 21px 10px 21px;
    margin-bottom: 10px;
  }
`;

export const modalStyles = css`
  .modal-dialog.shaparency {
    .modal-content {
      padding-bottom: 32px;
    }
  }
`;
