import { gql, useQuery } from '@apollo/client';

const SMART_PACK_PREVIEW_QUERY = gql`
  query SmartPackPreviewQuery($meetingId: ID!) {
    currentCompany {
      id

      meeting(id: $meetingId) {
        id

        agendas {
          id

          documents {
            id
            url
            extension
          }
        }
      }
    }
  }
`;

const useSmartPackPreviewQuery = (options) => useQuery(SMART_PACK_PREVIEW_QUERY, options);

export default useSmartPackPreviewQuery;
