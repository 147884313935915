import { gql, useQuery } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';
import { DOCUMENT_FRAGMENT } from './../fragments/DocumentFragment';
import { DIRECTORY_CAPABILITIES_FRAGMENT } from './../fragments/DirectoryCapabilitiesFragment';

const DIRECTORY_QUERY = gql`
  query directory($id: ID!) {
    currentCompany {
      id

      directory(id: $id) {
        ...DirectoryFields

        capabilities {
          ...DirectoryCapabilitiesFields
        }

        directories {
          ...DirectoryFields
        }

        documents {
          ...DocumentFields
        }
      }
    }
  }
  ${DIRECTORY_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${DIRECTORY_CAPABILITIES_FRAGMENT}
`;

const useDirectoryQuery = (options) => useQuery(DIRECTORY_QUERY, options);

export { useDirectoryQuery };
