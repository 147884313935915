/** @jsxRuntime classic */
/** @jsx jsx */

import React, { memo, useEffect, useRef, useState } from 'react';
import { jsx } from '@emotion/core';

import Modal from 'react-bootstrap/Modal';

import { ReactComponent as CloseIcon } from 'images/icons/alert-close.svg';

import { ReactComponent as GreenBG } from 'images/icons/BG-green.svg';
import { ReactComponent as YellowBG } from 'images/icons/BG-yellow.svg';
import { ReactComponent as RedBG } from 'images/icons/BG-red.svg';

import { ReactComponent as SuccessSign } from 'images/icons/success-sign.svg';
import { ReactComponent as FailureSign } from 'images/icons/failure-sign.svg';
import { ReactComponent as InfoSign } from 'images/icons/info-sign.svg';

import * as styles from './AlertPopup.styles';

const AlertPopup = memo(({ show, children, headerText, popupType, onClose, onTransitionEnd }) => {
  const { current: backgrounds } = useRef({
    success: <GreenBG />,
    failure: <RedBG />,
    info: <YellowBG />,
  });

  const { current: signs } = useRef({
    success: <SuccessSign />,
    failure: <FailureSign />,
    info: <InfoSign />,
  });

  const headerStyles = [styles.header.base, styles.header[popupType]];

  return (
    <Modal
      centered
      backdrop='static'
      onHide={onClose}
      show={show}
      onExited={onTransitionEnd}
      css={styles.modal}
      dialogClassName='alert-popup'
      backdropClassName='modal-backdrop-white'
    >
      <Modal.Header>
        <div css={styles.close} className='close-alert-popup'>
          <CloseIcon onClick={onClose} />
        </div>

        {backgrounds[popupType]}

        <div css={styles.sign}>{signs[popupType]}</div>
      </Modal.Header>

      <Modal.Body>
        <div css={headerStyles}>{headerText}</div>

        <div className='lh-18'>{children}</div>
      </Modal.Body>
    </Modal>
  );
});

export { AlertPopup };
