import { useMarkAllNotificationsAsReadMutation } from './../data/mutations/MarkAllNotificationsAsReadMutation';

const useMarkAllNotificationsAsRead = ({ onCompleted }) => {
  const update = (
    cache,
    {
      data: {
        markAllNotificationsAsRead: { showBadge },
      },
    }
  ) => onCompleted(showBadge);

  const [mutate] = useMarkAllNotificationsAsReadMutation({ update });

  const markAllNotificationsAsRead = (notificationIds) => {
    mutate({ variables: { input: { notificationIds } } });
  };

  return { markAllNotificationsAsRead };
};

export { useMarkAllNotificationsAsRead };
