import { gql, useMutation } from '@apollo/client';
import { MESSAGE_FRAGMENT } from './../fragments/MessageFragment';

const CREATE_MESSAGE_MUTATION = gql`
  mutation createMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      message {
        ...MessageFields
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

const useCreateMessageMutation = (options) => useMutation(CREATE_MESSAGE_MUTATION, options);

export { useCreateMessageMutation };
