import { fonts, colors } from 'styles';

export const select = (isInvalid, isDisabled) => {
  return (baseStyles) => {
    return {
      ...baseStyles,

      container: (styles) => ({
        ...baseStyles.container(styles),

        width: '100%',
      }),

      placeholder: (styles) => ({
        ...baseStyles.placeholder(styles),

        fontSize: 14,
        color: isInvalid ? colors.red : colors.headingGrey,
      }),

      control: (styles, state) => ({
        ...baseStyles.control(styles),

        backgroundColor: isDisabled ? colors.grey : colors.white,
        minHeight: 44,
        borderRadius: isDisabled ? 2 : 0,
        border: `1px solid ${
          isDisabled
            ? 'rgba(152, 164, 175, 0.4)'
            : isInvalid
            ? colors.lightRed
            : state.isFocused
            ? colors.cyan
            : colors.border
        }`,

        '&:hover': {
          cursor: 'pointer',
          border: `1px solid ${isInvalid ? colors.lightRed : state.isFocused ? colors.cyan : colors.border}`,
        },
      }),

      valueContainer: (styles) => ({
        ...baseStyles.valueContainer(styles),

        padding: '10px 20px',
        fontSize: 14,
        color: isInvalid ? colors.red : colors.black,
      }),

      dropdownIndicator: (styles, state) => ({
        ...baseStyles.dropdownIndicator(styles, state),

        i: {
          fontWeight: 400,
          color: isInvalid ? colors.red : state.isFocused ? colors.cyan : colors.lightGrey,

          '&:hover': {
            color: isInvalid ? colors.red : state.isFocused ? colors.cyan : colors.lightGrey,
          },
        },
      }),

      option: (styles) => ({
        ...baseStyles.option(styles),

        padding: 0,

        '.single-option-wrapper': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexGrow: 1,
          padding: '9px 10px 10px 20px',
          fontSize: 14,
          marginLeft: 2,

          label: {
            margin: 0,
            padding: 0,
          },
        },
      }),
    };
  };
};
