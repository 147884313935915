import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  SelectDocumentModalContextProvider,
  useSelectDocumentModalContext,
} from 'contexts/v2/SelectDocumentModalContext';
import { withContext } from 'utils/v2/shared';
import { useInView } from 'react-intersection-observer';
import Modal, { Sizes } from 'components/v2/shared/Modal';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import OrderToggle from './OrderToggle';
import Breadcrumbs from './Breadcrumbs';
import ContentArea from './ContentArea';
import SelectedItemList from './SelectedItemList';
import Footer from './Footer';
import DocumentUpload from 'components/v2/shared/DocumentUpload';
import Button, { Variants } from 'components/v2/shared/Button';
import VisibilityWrapper from 'components/v2/shared/VisibilityWrapper';

const SelectDocumentModal = ({ isOpen, onAdd, onClose }) => {
  const { ref: loadMoreRef, inView: loadMoreInView } = useInView();

  const {
    isLoading,
    isFetchingMore,
    isSidebarItemSelected,
    sortDirection,
    toggleSortDirection,
    isSortDirectionAscending,
    sidebarItems,
    selectSidebarItem,
    isSelectedSidebarItem,
    breadcrumbItems,
    selectBreadcrumbItem,
    contentAreaItems,
    selectContentAreaItem,
    isSelectedContentAreaItem,
    selectedItems,
    enterBrowseMode,
    isBrowsing,
    enterUploadMode,
    isUploading,
    enterSearchMode,
    isSearching,
    enterViewSelectedMode,
    isViewingSelected,
    clearSelectedItems,
    canUpload,
    fetchMoreContentAreaItems,
    refetchCurrentDirectory,
    uploadDocument,
  } = useSelectDocumentModalContext();

  useEffect(() => {
    if (loadMoreInView && !isUploading) {
      fetchMoreContentAreaItems();
    }
  }, [loadMoreInView]);

  const handleAddSelected = () => onAdd && onAdd(selectedItems);
  const handleDismissDocumentUpload = () => {
    refetchCurrentDirectory();
    enterBrowseMode();
  };

  return (
    <Modal size={Sizes['5xl']} isOpen={isOpen} onClose={onClose}>
      <div className='tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-stretch'>
        <div className='tw-hidden tw-w-60 lg:tw-block'>
          <Sidebar items={sidebarItems} onClick={selectSidebarItem} isSelected={isSelectedSidebarItem} />
        </div>

        <div className='tw-flex tw-grow tw-flex-col'>
          <div className='tw-px-6'>
            <div className='tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-pt-6 tw-pb-3 lg:tw-py-3'>
              <div className='lg:tw-mr-10'>
                <Topbar
                  isSearching={isSearching}
                  canUpload={canUpload}
                  onSearch={enterSearchMode}
                  onUpload={enterUploadMode}
                  onCancel={enterBrowseMode}
                />
              </div>
            </div>

            <div className='tw-h-64 md:tw-h-72 lg:tw-h-96'>
              <VisibilityWrapper isVisible={!isSidebarItemSelected} className='tw-py-3 lg:tw-hidden'>
                <Sidebar items={sidebarItems} onClick={selectSidebarItem} isSelected={isSelectedSidebarItem} />
              </VisibilityWrapper>

              <VisibilityWrapper
                isVisible={isSidebarItemSelected}
                className='tw-flex tw-h-full tw-flex-col tw-gap-y-3 tw-py-3'
              >
                <VisibilityWrapper name='breadcrumbs-wrapper' isVisible={isSearching || isBrowsing}>
                  <Breadcrumbs items={breadcrumbItems} onClick={selectBreadcrumbItem} />
                </VisibilityWrapper>

                <VisibilityWrapper
                  name='order-toggle-wrapper'
                  isVisible={isSearching || isBrowsing}
                  className='tw-border-0 tw-border-y tw-border-solid tw-border-gray-200 tw-py-3'
                >
                  <OrderToggle isRotated={isSortDirectionAscending} onClick={toggleSortDirection} />
                </VisibilityWrapper>

                <div className='tw-h-full tw-overflow-auto' key={`scrollArea-${sortDirection}`}>
                  {isUploading && (
                    <div className='tw-flex tw-h-full tw-items-center tw-justify-center'>
                      <DocumentUpload isDismissable onDismiss={handleDismissDocumentUpload} onUpload={uploadDocument} />
                    </div>
                  )}

                  <VisibilityWrapper name='selected-items-wrapper' isVisible={isViewingSelected} className='tw-h-full'>
                    <SelectedItemList items={selectedItems} onClick={selectContentAreaItem} />
                  </VisibilityWrapper>

                  <VisibilityWrapper isVisible={isBrowsing || isSearching} className='tw-h-full'>
                    <ContentArea
                      isLoading={isLoading}
                      isFetchingMore={isFetchingMore}
                      items={contentAreaItems}
                      onClick={selectContentAreaItem}
                      isSelected={isSelectedContentAreaItem}
                    />
                    <div ref={loadMoreRef} />
                  </VisibilityWrapper>
                </div>
              </VisibilityWrapper>
            </div>
          </div>

          <div className='tw-border-0 tw-border-t tw-border-solid tw-border-gray-200 tw-bg-white tw-p-4'>
            <Footer
              selectedCount={selectedItems.length}
              isViewingSelected={isViewingSelected}
              onShowSelected={enterViewSelectedMode}
              onHideSelected={enterBrowseMode}
              onClearSelected={clearSelectedItems}
              onAddSelected={handleAddSelected}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

SelectDocumentModal.propTypes = {
  isOpen: PropTypes.bool,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
};

export default withContext(SelectDocumentModalContextProvider, SelectDocumentModal);
