import { css } from '@emotion/core';
import { utils, icons, colors, fonts, media } from 'styles';

export const actionBar = (width) => css`
  position: fixed;
  bottom: 0;
  width: ${width}px;
  ${media.tablet} {
    position: relative;
  }
`;
