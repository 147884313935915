import { gql, useQuery } from '@apollo/client';
import { TIMEZONE_FRAGMENT } from './../fragments/TimezoneFragment';

const TIMEZONE_QUERY = gql`
  query timezone {
    currentContact {
      id

      timezone {
        ...TimezoneFields
      }
    }
  }
  ${TIMEZONE_FRAGMENT}
`;

const useTimezoneQuery = (options) => useQuery(TIMEZONE_QUERY, options);

export { TIMEZONE_QUERY, useTimezoneQuery };
