import { css } from '@emotion/core';
import { colors } from 'styles';

export const container = css`
  box-shadow: 0px 15px 30px rgba(18, 5, 34, 0.1), 0px 6.4px 14.4px rgba(32, 0, 56, 0.13);
  border-radius: 3px;
  border: 1px solid ${colors.cyan};
  padding: 20px 25px;
  position: relative;
`;
