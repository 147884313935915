/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContactPicker } from 'components/shared/ContactPicker';

import { useClickOutside } from 'hooks/useClickOutside';
import { useAgendasActions } from 'store/agendasStoreContext';
import { useFlashActions } from 'store/flashStoreContext';

import { ReactComponent as AssigneeIcon } from 'images/icons/user-icon-sm.svg';

import * as styles from './AgendaAssigneeSelect.styles';

const AgendaAssigneeSelect = memo(({ meetingId, agendaId, options, assigneeId, isEditable }) => {
  const { setFlash } = useFlashActions();
  const { updateAgenda } = useAgendasActions();

  const intl = useIntl();

  const assignee = options.find((option) => option.id === assigneeId);

  const renderAssignee = () => {
    if (assignee) {
      return <span css={styles.assignee}>{assignee.name}</span>;
    } else {
      return (
        <span css={styles.assigneeless}>
          <FormattedMessage id='AgendaAssigneeSelect.noOne' />
        </span>
      );
    }
  };

  if (!isEditable) {
    return (
      <div css={styles.component}>
        <AssigneeIcon css={styles.assigneeIcon} />

        <div>
          <FormattedMessage id='AgendaAssigneeSelect.assignedTo' />
          <div css={styles.assignee.base}>{renderAssignee()}</div>
        </div>
      </div>
    );
  }

  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside();

  const showDropdown = () => setIsComponentVisible(true);
  const hideDropdown = () => setIsComponentVisible(false);

  const isSelected = (contact) => {
    return contact.id === assignee?.id;
  };

  const changeAssignee = (contact) => {
    const contactId = isSelected(contact) ? null : contact.id;

    updateAgenda(meetingId, agendaId, {
      assignee_id: contactId,
    })
      .then(hideDropdown)
      .catch(setFlash);
  };

  const pickerStyles = [styles.picker.base, isComponentVisible && styles.picker.visible];

  const assigneePicker = (
    <div css={pickerStyles} ref={ref}>
      <ContactPicker
        placeholder={intl.formatMessage({ id: 'AgendaAssigneeSelect.selectAsignee' })}
        dropdownWidth={346}
        values={[assignee]}
        dropdownOptions={options}
        isSelected={isSelected}
        onToggleOption={changeAssignee}
        dropdownStyles={styles.dropdown}
        dropdownIsOpen
      />
    </div>
  );

  const chevronStyles = [styles.chevron.base, isComponentVisible && styles.chevron.flipped];

  const assigneeStyles = [styles.assignee.base, styles.assignee.hoverable];

  return (
    <div css={styles.component}>
      <AssigneeIcon css={styles.assigneeIcon} />

      <div>
        <FormattedMessage id='AgendaAssigneeSelect.assignedTo' />
        <div css={assigneeStyles} onClick={showDropdown}>
          {renderAssignee()}
          <i css={chevronStyles} className='fa fa-chevron-down' />
        </div>
      </div>

      {assigneePicker}
    </div>
  );
});

export { AgendaAssigneeSelect };
