/** @jsx jsx */

import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { Drawer } from 'components/shared/Drawer';
import { Input } from 'components/shared/Input';
import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';
import useWindowDimensions from 'hooks/useWindowDimensions';

import * as styles from './SubcommitteeDrawer.styles';
import { Label } from 'components/shared/Input/Label';

import { Select } from '../../shared/Select/Select';
import { CreatableSelect } from '../../shared/Select/CreatableSelect';
import { SubcommitteeDrawerTable } from '../SubcommitteeDrawerTable/SubcommitteeDrawerTable';

const INITIAL_NEW_CONTACT = {
  email: '',
  accessRoleId: '',
};

function RolesPicker({ accessRoles, onChange, value }) {
  const accessRolesOptions = accessRoles.map((ar) => ({ label: ar.name, value: ar.id }));
  const currentValue = accessRolesOptions.find((option) => option.value === value);

  return (
    <Select
      options={accessRolesOptions}
      onChange={onChange}
      className='tw-w-full'
      isSearchable
      isMulti={false}
      value={currentValue}
    />
  );
}

function ContactPicker({ parentCompanyContacts, onChange, value }) {
  const parentCompanyContactsOptions = parentCompanyContacts.map((contact) => {
    return { value: contact.email, label: contact.email };
  });
  const currentValue = parentCompanyContactsOptions.find((option) => option.value === value);

  return (
    <CreatableSelect
      options={parentCompanyContactsOptions}
      onChange={onChange}
      className='tw-w-full'
      isSearchable
      isMulti={false}
      value={currentValue}
    />
  );
}

function ContactsInvitationForm({ contacts, accessRoles, parentCompanyContacts, onSubcommitteeChange }) {
  const [contact, setContact] = useState(INITIAL_NEW_CONTACT);

  const handleAddContact = () => {
    onSubcommitteeChange({ contacts: [...contacts, contact] });
  };

  const handleContactChange = (option) => {
    const parentCompanyContact = parentCompanyContacts.find((c) => c.email === option.value);
    setContact({
      ...contact,
      email: option.value,
      name: parentCompanyContact?.name || option.value,
      initials: parentCompanyContact?.initials || option.value.substr(0, 2),
    });
  };

  const handleRoleChange = (option) => {
    setContact({ ...contact, accessRoleId: option.value });
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <Label text='Add Contacts' />
      <div className='tw-mb-2 tw-flex tw-justify-between tw-space-x-2'>
        <ContactPicker
          parentCompanyContacts={parentCompanyContacts}
          onChange={(e) => handleContactChange(e)}
          value={contact.email}
        />
        <RolesPicker accessRoles={accessRoles} onChange={(e) => handleRoleChange(e)} value={contact.accessRoleId} />
      </div>
      <a className='btn btn-primary m-r-10' onClick={handleAddContact}>
        <FormattedMessage id='SubcommitteeDrawer.addContact' />
      </a>
    </div>
  );
}

const SubcommitteeDrawer = ({
  subcommittee,
  accessRoles,
  parentCompanyContacts,
  errors,
  onSubcommitteeChange,
  isOpen,
  onClose,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const applicableStyles = [styles.drawer.base, isOpen && styles.drawer.state.open];

  const intl = useIntl();

  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (isMobile) return;

    if (isOpen) {
      document.querySelector('body').classList.add('opaque-bg');
    } else {
      document.querySelector('body').classList.remove('opaque-bg');
    }
  }, [isOpen]);

  const { name, contacts } = subcommittee;

  const drawerHeader = () => {
    if (subcommittee.id) {
      return intl.formatMessage({ id: 'SubcommitteeDrawer.editSubcommittee' });
    } else {
      return intl.formatMessage({ id: 'SubcommitteeDrawer.createSubcommittee' });
    }
  };

  const renderDrawerButtons = () => {
    if (subcommittee.id) {
      return (
        <div className='m-t-15 d-flex align-items-center'>
          <a className='btn btn-primary m-r-10' onClick={onUpdate}>
            <FormattedMessage id='SubcommitteeDrawer.update' />
          </a>
          <ButtonWithConfirmation
            confirmationText={intl.formatMessage({ id: 'SubcommitteeDrawer.deleteConfirm' })}
            onConfirm={onDelete}
            button={
              <a className='btn btn-secondary'>
                <FormattedMessage id='SubcommitteeDrawer.delete' />
              </a>
            }
          />
        </div>
      );
    } else {
      return (
        <a className='m-t-15 btn btn-primary' onClick={onCreate}>
          <FormattedMessage id='SubcommitteeDrawer.create' />
        </a>
      );
    }
  };

  const handleNameChange = (e) => onSubcommitteeChange({ name: e.target.value || '' });
  const errorMessage = (field) => errors.find((item) => item.attribute === field)?.message;
  const contactGid = (contact) => contact.gid || contact.id;
  const removeContact = (contact) => {
    return onSubcommitteeChange({ contacts: contacts.filter((item) => contactGid(item) !== contactGid(contact)) });
  };

  return (
    <Drawer drawerId='shaparency-subcommittees-drawer' styles={applicableStyles} onClose={onClose}>
      <div className='d-md-none p-15 b-b-grey d-flex align-items-center'>
        <i className='fa fa-chevron-left fw-300 text-black fs-18 cursor-pointer' onClick={onClose} />
        <div className='m-auto font-weight-semibold text-black fs-18'>{drawerHeader()}</div>
      </div>

      <div css={styles.wrapper}>
        <div css={styles.header}>{drawerHeader()}</div>

        <div className='m-t-25 col-12 col-md-6 pl-0 pr-0'>
          <Input
            name='name'
            errorMessage={errorMessage('name')}
            label={intl.formatMessage({ id: 'SubcommitteeDrawer.subcommitteeName' })}
            value={name}
            onChange={handleNameChange}
            placeholder={intl.formatMessage({ id: 'SubcommitteeDrawer.subcommitteeName' })}
          />
        </div>

        <div css={styles.line} />

        <div className='col-12 pl-0 pr-0'>
          <ContactsInvitationForm
            accessRoles={accessRoles}
            contacts={contacts}
            onSubcommitteeChange={onSubcommitteeChange}
            parentCompanyContacts={parentCompanyContacts}
          />
        </div>

        <div className='m-t-30 fs-12 font-weight-semibold text-black text-left text-nowrap'>
          <FormattedMessage id='GroupDrawer.addedContacts' values={{ count: contacts.length }} />
        </div>

        <div css={styles.content}>
          <SubcommitteeDrawerTable contacts={contacts} onDelete={removeContact} accessRoles={accessRoles} />
        </div>

        {renderDrawerButtons()}
      </div>
    </Drawer>
  );
};

export { SubcommitteeDrawer };
