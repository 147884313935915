/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import * as styles from './Products.styles';
import { ReactComponent as CheckMarkCircleIcon } from 'images/icons/circle-checkmark-large.svg';

const Product = ({ product, company, handleClick, selected, couponCode, isStandalone }) => {
  return (
    <div className={`col-sm-6 ${isStandalone ? 'col-md-6 col-lg-6' : 'col-md-6 col-lg-6'} m-t-30`}>
      <div
        css={styles.productBox}
        style={{
          borderColor: selected ? '#00BAC6' : 'white',
          position: 'relative',
          cursor: selected ? 'cursor' : 'pointer',
        }}
        className='product-box'
      >
        <a
          onClick={() => handleClick(product)}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
        {selected && (
          <CheckMarkCircleIcon
            style={{
              position: 'absolute',
              left: '50%',
              marginLeft: -22,
              top: -22,
            }}
          />
        )}
        <div className='c-dark text-center font-weight-extrabold fs-22 m-t-10'>{product.name}</div>
        <p className='fs-38 font-weight-bold c-secondary text-center m-t-30 product-price'>
          <span style={{ fontWeight: 'bolder' }}>{product.currency === 'usd' ? '$' : '£'}</span>
          {Number(product.price).toFixed(2)}
          {couponCode && '*'}
        </p>
        <p className='text-center fs-12 c-dark w-160 m-auto lh-16'>
          {product.interval.includes('year') ? (
            <FormattedMessage id='Product.perYearly' />
          ) : (
            <FormattedMessage id='Product.perMonthly' />
          )}
        </p>
        {couponCode && (
          <div className='fs-12 c-dark text-center m-t-10'>
            <FormattedMessage id='Product.codeApplied' values={{ code: couponCode }} />
          </div>
        )}
      </div>
      {product.name.includes('Charity') && !company.verified ? (
        <span className='fs-12 w-87 m-t-5 lh-1 m-auto d-block text-center'>
          <FormattedMessage id='Product.verifyStatus' />
        </span>
      ) : (
        <span className='d-block' />
      )}
    </div>
  );
};

export default Product;
