import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { deleteById, updateById } from 'utils/v2/shared';
import { uploadFile } from 'utils/v2/directUploads';
import { ErrorCode } from 'react-dropzone';

export const AttachmentState = {
  Enqueued: 'enqueued',
  Pending: 'pending',
  Uploaded: 'uploaded',
  Failed: 'failed',
  Cancelled: 'cancelled',
};

const errorCodeMessageMap = {
  [ErrorCode.FileTooLarge]: 'File is too large',
  [ErrorCode.FileTooSmall]: 'File is too small',
  [ErrorCode.FileInvalidType]: 'File type not supported',
  [ErrorCode.TooManyFiles]: 'Too many files',
  blobError: 'Error while uploading',
};

const initialState = {
  attachments: [],
  enqueuedAttachmentId: null,
};

const actionTypes = {
  SET_ENQUEUED_ATTACHMENT_ID: 'FileUploadContext.setEnqueuedAttachmentId',
  SET_ATTACHMENTS: 'FileUploadContext.setAttachments',
  ADD_ATTACHMENTS: 'FileUploadContext.addAttachments',
  UPDATE_ATTACHMENT: 'FileUploadContext.updateAttachment',
  REMOVE_ATTACHMENT: 'FileUploadContext.removeAttachment',
};

const FileUploadContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_ENQUEUED_ATTACHMENT_ID:
      return { ...state, enqueuedAttachmentId: payload.enqueuedAttachmentId };
    case actionTypes.SET_ATTACHMENTS:
      return { ...state, attachments: payload.attachments };
    case actionTypes.ADD_ATTACHMENTS:
      return { ...state, attachments: [...payload.attachments, ...state.attachments] };
    case actionTypes.UPDATE_ATTACHMENT:
      return { ...state, attachments: updateById(state.attachments, payload.attachment) };
    case actionTypes.REMOVE_ATTACHMENT:
      return { ...state, attachments: deleteById(state.attachments, payload.attachment) };
  }
};

export const FileUploadContextProvider = ({ children, ...args }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...args });

  const { attachments } = state;

  const findAttachmentById = (id) => attachments.find((attachment) => attachment.id === id);
  const hasAttachmentsWithState = (state) => attachments.some((attachment) => attachment.state === state);
  const filterAttachmentsByState = (state) => attachments.filter((attachment) => attachment.state === state);

  const convertFileToRejectedAttachment = (attachment) => ({
    id: uniqueId(),
    name: attachment.file.name,
    file: attachment.file,
    state: AttachmentState.Failed,
    error: errorCodeMessageMap[attachment.errors[0].code],
  });

  const convertFileToEnqueuedAttachment = (attachment) => ({
    id: uniqueId(),
    name: attachment.name,
    file: attachment,
    state: AttachmentState.Enqueued,
    preview: URL.createObjectURL(attachment),
  });

  const convertFilesToAttachments = (acceptedFiles, rejectedFiles) => [
    ...rejectedFiles.map(convertFileToRejectedAttachment),
    ...acceptedFiles.map(convertFileToEnqueuedAttachment),
  ];

  const setAttachments = (acceptedFiles, rejectedFiles) => {
    dispatch({
      type: actionTypes.SET_ATTACHMENTS,
      payload: {
        attachments: convertFilesToAttachments(acceptedFiles, rejectedFiles),
      },
    });
  };

  const addAttachments = (acceptedFiles, rejectedFiles) => {
    dispatch({
      type: actionTypes.ADD_ATTACHMENTS,
      payload: {
        attachments: convertFilesToAttachments(acceptedFiles, rejectedFiles),
      },
    });
  };

  const updateAttachment = ({ id }, params) => {
    dispatch({ type: actionTypes.UPDATE_ATTACHMENT, payload: { attachment: { id, ...params } } });
  };

  const removeAttachment = ({ id }) => {
    dispatch({ type: actionTypes.REMOVE_ATTACHMENT, payload: { attachment: { id } } });
  };

  const cancelAttachment = ({ id }) => {
    const attachment = findAttachmentById(id);

    attachment.xhr?.abort();

    updateAttachment(attachment, { state: AttachmentState.Cancelled });
  };

  const restartAttachment = ({ id }) => {
    updateAttachment({ id }, { state: AttachmentState.Enqueued, progress: 0 });
  };

  const uploadAttachment = async (attachmentId, onUpload = (blob) => blob) => {
    const attachment = findAttachmentById(attachmentId);

    if (!attachment) return;

    try {
      const blob = await uploadFile(attachment.file, {
        onInit: (xhr) => updateAttachment(attachment, { xhr, state: AttachmentState.Pending }),
        onProgress: (progress) => updateAttachment(attachment, { progress }),
      });

      const upload = await onUpload(blob);

      updateAttachment(attachment, { upload, state: AttachmentState.Uploaded });
    } catch (error) {
      updateAttachment(attachment, { error: errorCodeMessageMap.blobError, state: AttachmentState.Failed });
    }
  };

  const enqueuedAttachments = filterAttachmentsByState(AttachmentState.Enqueued);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_ENQUEUED_ATTACHMENT_ID,
      payload: {
        enqueuedAttachmentId: enqueuedAttachments[0]?.id,
      },
    });
  }, [enqueuedAttachments.length]);

  return (
    <FileUploadContext.Provider
      value={{
        attachments,
        enqueuedAttachmentId: state.enqueuedAttachmentId,
        uploadedAttachments: filterAttachmentsByState(AttachmentState.Uploaded),
        setAttachments,
        addAttachments,
        cancelAttachment,
        removeAttachment,
        restartAttachment,
        uploadAttachment,
        hasPendingAttachments: hasAttachmentsWithState(AttachmentState.Pending),
        hasFailedAttachments: hasAttachmentsWithState(AttachmentState.Failed),
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};

FileUploadContextProvider.propTypes = {
  children: PropTypes.node,
  args: PropTypes.object,
};

export const useFileUploadContext = () => useContext(FileUploadContext);
