import React, { useContext } from 'react';
import Button from 'components/v2/shared/Button';
import { wrapMutation } from 'utils/v2/gql';
import { useApproveContactMutation } from '../../../data/v2/mutations/ApproveContactMutation';
import { useRejectContactMutation } from '../../../data/v2/mutations/RejectContactMutation';
import Toast, { Severity } from '../shared/Toast/Toast';
import { ToastContext } from 'contexts/v2/ToastContext';
import { useCurrentUserQuery } from '../../../data/v2/queries/CurrentUserQuery';
import { formatDate } from 'helpers/formatDateTime';
import { CURRENT_COMPANY_QUERY } from '../../../data/v2/queries/CurrentCompanyQuery';

const Approval = ({ id, email, createdAt, onDecide }) => {
  const [approveContact] = useApproveContactMutation({
    refetchQueries: [
      {
        query: CURRENT_COMPANY_QUERY,
      },
    ],
  });
  const [rejectContact] = useRejectContactMutation({
    refetchQueries: [
      {
        query: CURRENT_COMPANY_QUERY,
      },
    ],
  });
  const { toastInfo, setToastInfo } = useContext(ToastContext);
  const { data, loading } = useCurrentUserQuery();

  const handleApproveContact = () => {
    wrapMutation(
      approveContact,
      {
        variables: {
          input: { contactId: id },
        },
      },
      'approveContact'
    )
      .then(() => {
        setToastInfo({
          severity: Severity.Success,
          status: 'Success',
          message: 'Contact approved',
        });
        onDecide(id);
      })
      .catch((err) => {
        setToastInfo({
          severity: Severity.Success,
          status: 'Error',
          message: 'Error approving contact.',
        });
      });
  };

  const handleRejectContact = () => {
    wrapMutation(
      rejectContact,
      {
        variables: {
          input: { contactId: id },
        },
      },
      'rejectContact'
    )
      .then(() => {
        setToastInfo({
          severity: Severity.Success,
          status: 'Success',
          message: 'Contact rejected.',
        });
        onDecide(id);
      })
      .catch((err) => {
        setToastInfo({
          severity: Severity.Success,
          status: 'Error',
          message: 'Error rejecting contact.',
        });
      });
  };

  return (
    <>
      <Toast severity={toastInfo.severity} status={toastInfo.status} message={toastInfo.message} />
      <div className='tw-mt-1 tw-w-full tw-items-center tw-justify-between tw-rounded tw-bg-gray-100 tw-py-5 tw-pl-6 tw-pr-5 md:tw-flex'>
        <div>
          <div className='tw-w-full tw-font-sans tw-text-[13px] tw-font-semibold tw-text-black'>{email}</div>
          {!loading ? (
            <div className='tw-w-full tw-font-sans tw-text-xs tw-font-medium tw-text-gray-700'>
              {formatDate(createdAt, data.currentUser.dateFormat.label)}
            </div>
          ) : null}
        </div>
        <div className='tw-flex tw-pt-2'>
          <Button
            font='medium'
            onClick={handleApproveContact}
            size='small'
            variant='teal'
            tw-text='sm'
            className='tw-mr-1.5 tw-h-6 tw-w-[70px] tw-flex-grow !tw-rounded-[4px] !tw-p-6 tw-text-xs tw-font-semibold md:!tw-p-4'
          >
            Approve
          </Button>
          <Button
            font='medium'
            onClick={handleRejectContact}
            size='small'
            variant='red'
            className='tw-h-6 tw-w-[70px] tw-flex-grow !tw-rounded-[4px] !tw-p-6 tw-text-xs tw-font-semibold md:!tw-p-4'
          >
            Reject
          </Button>
        </div>
      </div>
    </>
  );
};

export default Approval;
