import { css } from '@emotion/core';
import { colors, panels, fonts } from 'styles';

export const wrapper = {
  base: css`
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
  `,

  withAvatar: css`
    margin-bottom: 20px;
  `,
};

export const image = css`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
`;

export const block = {
  base: css`
    ${panels.base}
    position: relative;
    width: 340px;
    padding: 12px 12px 10px 12px;
    background: ${colors.white};
  `,

  withMarginRight: css`
    margin-right: 15px;
  `,

  withMarginLeft: css`
    margin-left: 15px;
  `,
};

export const author = css`
  ${fonts.extraBold}
  font-size: 12px;
  padding: 0 10px;
  color: ${colors.black};
`;

export const text = css`
  margin: 8px 0 5px 0;
  padding: 0 10px;
  font-size: 12px;
  color: ${colors.black};
  white-space: pre-line;
  word-break: break-word;
  line-height: 16px;
`;

export const date = css`
  color: ${colors.lightGrey};
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
`;

export const triangleBase = css`
  width: 0;
  height: 0;
  position: absolute;
  z-index: 1;
  box-shadow: 0 4px 4px rgb(223 223 223 / 25%);
  bottom: 0;
  border-top: 10px solid transparent;
`;

export const triangle = {
  left: css`
    ${triangleBase}
    left: -5px;
    border-right: 10px solid ${colors.white};
  `,

  right: css`
    ${triangleBase}
    right: -5px;
    border-left: 10px solid ${colors.white};
  `,
};
