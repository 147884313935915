import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const datePicker = {
  base: css`
    .DayPicker {
      visibility: hidden;
      box-shadow: 0px 1.2px 3.6px rgba(0, 72, 90, 0.1), 0px 6.4px 14.4px rgba(0, 77, 97, 0.13);
      border-radius: 2px;
      padding: 17px 19px 13px 15px;
      width: 230px;
      position: absolute !important;
      z-index: 3;

      .DayPicker_weekHeader__vertical {
        padding: 0 !important;
        white-space: nowrap;
        top: 27px;
        left: -2px;
        margin-left: 0 !important;

        li {
          display: none;
          width: 28px !important;
        }
      }

      .CalendarMonthGrid {
        width: 194px !important;
      }

      .DayPickerNavigation_button {
        .prev,
        .next {
          font-weight: 300;
          font-size: 12px;
          position: absolute;
          top: 5px;
        }

        .prev {
          right: 31px;
        }

        .next {
          right: 6px;
        }
      }

      .CalendarMonth {
        padding: 0 !important;
      }

      .CalendarMonth_table {
        margin: 0 auto;
      }

      .CalendarMonth_caption {
        ${fonts.bold}
        font-size: 14px;
        text-align: left;
        padding-left: 7px;
        padding-top: 0;
        padding-bottom: 25px;
        color: ${colors.black};
      }

      .CalendarDay__default {
        ${fonts.regular}
        border: none;
        font-size: 12px;
        padding: 0;
        color: ${colors.black};

        &:focus {
          outline: none;
        }

        &:hover {
          background: ${colors.cyan};
          border-radius: 14px;
          border: none;
          color: white;
        }

        &.CalendarDay__blocked_out_of_range {
          color: ${colors.mediumGrey};

          &:hover {
            background: none;
            border-radius: 0;
          }
        }

        &.CalendarDay__selected {
          background: ${colors.cyan};
          border-radius: 50px;
          color: white;

          &.CalendarDay__blocked_out_of_range:hover {
            background: ${colors.cyan};
            border-radius: 50px;
            color: white;
          }
        }
      }

      .DayPicker_weekHeader_li {
        visibility: hidden;
        font-size: 0;
      }

      .DayPicker_weekHeader_li::first-letter {
        ${fonts.bold}
        visibility: visible;
        color: ${colors.black};
        font-size: 12px;
      }
    }
  `,

  visible: css`
    .DayPicker {
      visibility: visible;

      .DayPicker_weekHeader__vertical {
        li {
          display: inline-block;
        }
      }
    }
  `,
};
