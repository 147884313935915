import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const container = css`
  ${fonts.regular}
  color: ${colors.black};
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  line-height: 1;
  padding: 12px 15px;
  border-radius: 0;

  ${media.tablet} {
    font-weight: 500;
    padding: 15px;
  }

  &:hover {
    background: ${colors.lightestGrey};
  }

  &.disabled {
    cursor: default;
    color: ${colors.headingGrey};
  }
`;
