/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { Select } from 'components/shared/Select';

import { position } from 'styles';
import * as styles from './SelectInput.styles';

import { Label, Error } from 'components/shared/Input';

const SelectInput = memo(({ label, errorMessage, placeholder, value, onChange, isDisabled, options }) => {
  const applicableStyles = styles.select(!!errorMessage, isDisabled);

  return (
    <div>
      <Label text={label} />

      <div css={position.relative}>
        <Select
          onChange={onChange}
          isDisabled={isDisabled}
          placeholder={placeholder}
          value={value}
          options={options}
          styles={applicableStyles}
          closeMenuOnSelect
          blurInputOnSelect
        />

        <Error message={errorMessage} />
      </div>
    </div>
  );
});

export { SelectInput };
