import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { client } from 'utils/apollo';
import AppSignalWrapper from '../../v2/shared/AppSignalWrapper';
import { TranslationContextProvider } from '../../../contexts/v2/TranslationContext';

import { Container } from 'components/groups/Container';

export default ({ companyId }) => (
  <AppSignalWrapper tags={{ page: 'groups' }}>
    <TranslationContextProvider>
      <ApolloProvider client={client}>
        <Container companyId={companyId} />
      </ApolloProvider>
    </TranslationContextProvider>
  </AppSignalWrapper>
);
