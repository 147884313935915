/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import Text from 'components/v2/shared/Text';
import ActionsMenu from 'components/v2/shared/ActionsMenu';
import MenuItem from 'components/v2/shared/MenuItem';

import * as styles from './AgendaItemActionsMenu.styles';

const AgendaItemActionsMenu = ({ className }) => {
  return (
    <ActionsMenu className={className}>
      <MenuItem>
        <Text text='Minimize' />
      </MenuItem>
      <MenuItem>
        <Text text='Duplicate agenda item' />
      </MenuItem>
      <MenuItem>
        <Text text='Delete agenda item' />
      </MenuItem>
      <MenuItem>
        <Text text='Move to sub agenda item' />
      </MenuItem>
      <MenuItem>
        <Text text='Add description' />
      </MenuItem>
    </ActionsMenu>
  );
};

AgendaItemActionsMenu.propTypes = {
  className: PropTypes.string,
};

export default AgendaItemActionsMenu;
