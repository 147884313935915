import { css } from '@emotion/core';
import { colors } from 'styles';

export const container = css`
  height: 100%;
`;

export const header = css`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
