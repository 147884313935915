import React, { useState } from 'react';
import Button from 'components/v2/shared/Button';
import TextInput from 'components/v2/shared/TextInput';
import Alert from 'components/v2/shared/Alert';
import Label from 'components/v2/shared/Label';
import OrderOverview from './OrderOverview';

const PurchasePlan = ({}) => {
  const [isActiveCouponCode, setActiveCouponCode] = useState(false);
  const handleApplyCouponcode = () => {
    setActiveCouponCode(true);
  };

  return (
    <>
      <div className='tw-mt-14 xl:tw-mt-5'>
        <p className='tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>Purchase plan with Credit Card</p>
      </div>
      <div className='tw-mt-9'>
        <TextInput name='card_number' size='large' label='Card number' placeholder='Card number' />
      </div>
      <div className='tw-mt-3 tw-grid tw-w-full tw-w-[250px] tw-grid-flow-col tw-grid-cols-2 tw-gap-8 sm:tw-w-[350px] xl:tw-w-[300px]'>
        <TextInput name='expiry' size='large' label='Expiry' placeholder='MM / YY' className='!tw-w-28' />
        <TextInput name='csv' size='large' label='CSV' placeholder='_ _ _' className='!tw-w-28' />
      </div>
      {isActiveCouponCode && (
        <>
          <div className='tw-mt-5'>
            <Label text='Apply coupon code' className='!tw-text-xs !tw-font-semibold' />
            <div className='tw-relative'>
              <TextInput name='coupon_code' size='large' placeholder='Coupon code' />
              <Button font='semibold' size='large' variant='teal' className='tw-absolute tw-right-1 tw-top-1'>
                Apply
              </Button>
            </div>
          </div>

          <div className='tw-mt-2'>
            <Alert
              description='We have added a discount to your order'
              isDismissable={false}
              severity='success'
              className='!tw-font-sans tw-text-sm tw-font-medium !tw-text-black'
            />
          </div>
          <div className='tw-mt-5'>
            <div className='tw-relative'>
              <TextInput name='coupon_code' size='large' isInvalid placeholder='Coupon code' />
              <Button
                font='semibold'
                onClick={() => {}}
                size='large'
                variant='red'
                className='tw-absolute tw-right-1 tw-top-1'
              >
                Retry
              </Button>
            </div>
          </div>

          <div className='tw-mt-2'>
            <Alert
              description='We do not recognise that code sorry.'
              isDismissable={false}
              severity='error'
              className='!tw-bg-red-200 !tw-font-sans tw-text-sm tw-font-medium !tw-text-black'
            />
          </div>
        </>
      )}
      {!isActiveCouponCode && (
        <div className='tw-mt-7'>
          <Button
            font='semibold'
            onClick={handleApplyCouponcode}
            size='small'
            variant='transparent'
            className='!tw-px-0 tw-text-xs !tw-text-teal-500'
          >
            Apply coupon code
          </Button>
        </div>
      )}

      <div className='tw-mt-24'>
        <OrderOverview />
      </div>
    </>
  );
};

export default PurchasePlan;
