import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { client } from 'utils/apollo';
import AppSignalWrapper from '../../v2/shared/AppSignalWrapper';
import { TranslationContextProvider } from '../../../contexts/v2/TranslationContext';

import { SubcommitteesContainer } from 'components/subcommittees/Container';

export default ({ companyId }) => (
  <AppSignalWrapper tags={{ page: 'subcommittees' }}>
    <TranslationContextProvider>
      <ApolloProvider client={client}>
        <SubcommitteesContainer companyId={companyId} />
      </ApolloProvider>
    </TranslationContextProvider>
  </AppSignalWrapper>
);
