import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonWithSuccess } from 'components/shared/ButtonWithSuccess';
import { ReactComponent as HistoryIcon } from 'images/icons/history-icon.svg';

const OoEActions = memo(({ onSave, showCopyFromAgenda, onCopyFromAgenda, onToggleHistory, onBack }) => {
  return (
    <div className='d-flex flex-wrap align-items-center justify-content-between'>
      <div className='d-flex align-items-center m-b-10 m-r-10'>
        <div className='text-black fs-16 font-weight-bold'>
          <FormattedMessage id='OoEActions.oOE' />
        </div>

        <HistoryIcon className='m-l-10 d-none' onClick={onToggleHistory} />
      </div>

      <div className='d-flex align-items-end m-b-10'>
        <div className='d-flex align-items-center'>
          {showCopyFromAgenda && (
            <button className='btn btn-sm btn-primary' onClick={onCopyFromAgenda}>
              <FormattedMessage id='OoEActions.agendaCopy' />
            </button>
          )}

          <ButtonWithSuccess className='btn btn-sm btn-primary m-l-10' onClick={onSave}>
            <FormattedMessage id='OoEActions.save' />
          </ButtonWithSuccess>
        </div>

        {onBack && (
          <button onClick={onBack} className='btn btn-primary m-l-10'>
            <FormattedMessage id='OoEActions.back' />
          </button>
        )}
      </div>
    </div>
  );
});

export { OoEActions };
