import { gql, useQuery } from '@apollo/client';
import { CONTACT_GROUP_FRAGMENT } from './../fragments/ContactGroupFragment';

const CONTACT_GROUPS_QUERY = gql`
  query contactGroups {
    currentCompany {
      contacts {
        name
        initials
        contactRole
      }
      accessRoles {
        id
        name
      }
      contactGroups {
        id
        name
      }
    }
  }
`;

const useContactGroupsQuery = (options) => useQuery(CONTACT_GROUPS_QUERY, options);

export { useContactGroupsQuery };
