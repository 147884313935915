import React, { forwardRef, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import CircleCheck from 'images/icons/circle-checkmark.svg';
import CircleTimes from 'images/icons/circle-times.svg';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Label from '../Label';
import InputError from '../InputError';

export const Sizes = {
  Large: 'large',
  Regular: 'regular',
};

const REGEX = {
  MIN8_REGEX: new RegExp('^(?=.{8,})'),
  LOWERCASE_REGEX: new RegExp('^(?=.*[a-z])'),
  UPPERCASE_REGEX: new RegExp('^(?=.*[A-Z])'),
  NUMBER_REGEX: new RegExp('^(?=.*[0-9])'),
  SPECIAL_CHARACTER_REGEX: new RegExp('^(?=.*[!@#$%^&*()])'), //,
};

const min8Validation = (value) => REGEX.MIN8_REGEX.test(value);
const lowercaseValidation = (value) => REGEX.LOWERCASE_REGEX.test(value);
const uppercaseValidation = (value) => REGEX.UPPERCASE_REGEX.test(value);
const numberValidation = (value) => REGEX.NUMBER_REGEX.test(value);
const specialCharacterValidation = (value) => REGEX.SPECIAL_CHARACTER_REGEX.test(value);
const PasswordInput = forwardRef(
  (
    {
      id,
      name,
      value,
      placeholder,
      label,
      isRequired,
      isDisabled,
      isReadOnly,
      errorMessage,
      isInvalid,
      onChange,
      onBlur,
      className,
      isValidation,
      textIconClass,
      size = Sizes.Regular,
      ...props
    },
    ref
  ) => {
    const [isInputTypeText, setIsInputTypeText] = useState(false);
    const [isShowValidationIcon, setIsShowValidationIcon] = useState(false);
    const [validationProps, setValidationProps] = useState({
      min8: false,
      lowercase: false,
      uppercase: false,
      number: false,
      special_character: false,
    });

    const updateValidationPropsValue = (name, value) => {
      setValidationProps((prev) => ({ ...prev, [name]: value }));
    };

    const handleOnChangePassword = (e) => {
      const { value } = e.target;
      setIsShowValidationIcon(value !== '');
      updateValidationPropsValue('password', value);
      updateValidationPropsValue('min8', min8Validation(value));
      updateValidationPropsValue('lowercase', lowercaseValidation(value));
      updateValidationPropsValue('uppercase', uppercaseValidation(value));
      updateValidationPropsValue('number', numberValidation(value));
      updateValidationPropsValue('special_character', specialCharacterValidation(value));
      if (
        min8Validation(value) &&
        lowercaseValidation(value) &&
        uppercaseValidation(value) &&
        numberValidation(value) &&
        specialCharacterValidation(value)
      ) {
        onChange(e, true);
      } else {
        onChange(e, false);
      }
    };

    const handleShowHide = () => {
      setIsInputTypeText(!isInputTypeText);
    };

    const ValidationIcon = ({ isValid }) => {
      return (
        <>
          {isShowValidationIcon && (
            <img src={isValid ? CircleCheck : CircleTimes} className={clsx('tw-h-2.5', textIconClass)} />
          )}
        </>
      );
    };

    return (
      <div className='tw-w-auto'>
        {!!label && <Label id={id} text={label} size={size} />}
        {isValidation && (
          <div className='tw-my-1'>
            <p className='tw-mb-7 tw-text-xs tw-font-medium tw-text-black'>
              <ValidationIcon isValid={validationProps.min8} />
              Min 8 characters. <br />
              <ValidationIcon isValid={validationProps.lowercase} />
              A lowercase a - z. <br />
              <ValidationIcon isValid={validationProps.uppercase} />
              An uppercase A - Z. <br />
              <ValidationIcon isValid={validationProps.number} />
              A number. <br />
              <ValidationIcon isValid={validationProps.special_character} />
              A special character (!@#$%^&*(). <br />
            </p>
          </div>
        )}
        <div className='tw-relative'>
          <input
            ref={ref}
            id={id}
            name={name}
            type={isInputTypeText ? 'text' : 'password'}
            autoComplete='none'
            value={value}
            placeholder={placeholder}
            required={isRequired}
            disabled={isDisabled}
            readOnly={isReadOnly}
            onChange={isValidation ? handleOnChangePassword : onChange}
            onBlur={onBlur}
            {...props}
            className={clsx(
              'tw-box-border tw-w-full tw-rounded-sm tw-border tw-border-solid tw-px-5 tw-font-sans tw-text-sm tw-outline-none',
              {
                'tw-py-4': size === Sizes.Large,
                'tw-h-11': size === Sizes.Regular,
              },
              'placeholder:tw-text-gray-500',
              {
                'tw-border-gray-500 focus:tw-border-teal-500': size === Sizes.Regular && !errorMessage && !isInvalid,
                'tw-border-gray-700 tw-font-medium focus:tw-border-teal-500':
                  size === Sizes.Large && !errorMessage && !isInvalid,
                'tw-cursor-not-allowed tw-opacity-30': isDisabled,
                'tw-border-red-400 placeholder:tw-text-red-400': errorMessage || isInvalid,
              },
              className
            )}
          />
          <button
            onClick={handleShowHide}
            className={clsx(
              'tw-absolute tw-right-2.5 tw-cursor-pointer	tw-border-transparent tw-bg-transparent tw-text-gray-600 tw-opacity-80',
              {
                'tw-top-3': size === 'large',
                'tw-top-2': size === 'regular',
              }
            )}
            type='button'
          >
            {isInputTypeText ? <FiEyeOff /> : <FiEye />}
          </button>
          <InputError message={errorMessage} className='!tw-my-4 !tw-py-4' />
        </div>
      </div>
    );
  }
);

PasswordInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isValidation: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  errorMessage: PropTypes.string,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Sizes)),
};

export default PasswordInput;
