import React, { useState } from 'react';
import Heading from 'components/v2/shared/Heading';
import IndividualContacts from 'components/v2/contacts/IndividualContacts';
import PermissionIndividual from 'components/v2/contacts/PermissionIndividual';
import Modal from 'components/v2/shared/Modal';

const ContactIndividual = ({ openIndividual, selectedContact }) => {
  const [openModal, setOpenModal] = useState(false);
  const { name, email, contactRole, phoneNumber, linkedinUrl, address } = selectedContact;
  const handleModal = () => {
    setOpenModal(prevValue => prevValue = !prevValue);
  };
  const notEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) === false;
  };

  return (
    <>
      <div>
        <div className='tw-w-full'>
          <div className='tw-justify-between sm:tw-flex'>
            <Heading text='Individual' className='!tw-mb-0' />
            <div
              className='tw-cursor-pointer tw-pt-2.5 tw-font-sans tw-text-xs tw-font-medium tw-text-teal-500'
              onClick={() => openIndividual(false, selectedContact)}
            >
              Close window
            </div>
          </div>
          <IndividualContacts
            selectedContact={selectedContact}
            isPending
          />
          <div className='tw-mt-7 tw-w-full'>
            <Modal
              isOpen={openModal}
              onClose={() => handleModal()}
              centered
              size='sm'
            >
              <PermissionIndividual selectedContact={selectedContact} openModal={openModal} />
            </Modal>
          </div>
          <div>
            <Heading text='Details' className='tw-my-8' />
            <label htmlFor='Name' className='!tw-mb-0 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>
              Name
            </label>
            <Heading
              className='tw-mt-5 tw-mb-2.5 !tw-font-sans !tw-text-xs !tw-font-bold tw-text-black'
              text='First Name'
            />
            <p className='!tw-mb-0 tw-font-sans tw-text-sm tw-font-medium'>{(name && notEmail(name)) ? name.split(" ")[0] : ""}</p>
            <Heading
              className='tw-mt-5 tw-mb-2.5 !tw-font-sans !tw-text-xs !tw-font-bold tw-text-black'
              text='Last Name'
            />
            <p className='!tw-mb-0 tw-font-sans tw-text-sm tw-font-medium'>{name ? name.split(" ")[1] : ""}</p>
            <label htmlFor='Contact' className='tw-mt-12 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>
              Contact
            </label>
            <Heading
              className='tw-mt-5 tw-mb-2.5 !tw-font-sans !tw-text-xs !tw-font-bold tw-text-black'
              text='Email'
            />
            <p className='!tw-mb-0 tw-font-sans tw-text-sm tw-font-medium'>{email}</p>
            <Heading
              className='tw-mt-5 tw-mb-2.5 !tw-font-sans !tw-text-xs !tw-font-bold tw-text-black'
              text='Phone'
            />
            <p className='!tw-mb-0 tw-font-sans tw-text-sm tw-font-medium'>{phoneNumber}</p>
            <Heading
              className='tw-mt-5 tw-mb-2.5 !tw-font-sans !tw-text-xs !tw-font-bold tw-text-black'
              text='Linked Profile URL'
            />
            <p className='!tw-mb-0 tw-font-sans tw-text-sm tw-font-medium'>{linkedinUrl}</p>
            <Heading
              className='tw-mt-5 tw-mb-2.5 !tw-font-sans !tw-text-xs !tw-font-bold tw-text-black'
              text='Address'
            />
            <p className='!tw-mb-0 tw-font-sans tw-text-xs tw-font-medium'>
              {address}
            </p>
            <label
              htmlFor='Permission'
              className='tw-mb-2 tw-mt-12 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'
            >
              Permission
            </label>
            <div className='tw-flex tw-justify-between'>
              <p className='!tw-mb-0 tw-font-sans tw-text-xs tw-font-medium'>
                {contactRole ? contactRole.replace(/^\w/, (c) => c.toUpperCase()) : 'role'}
              </p>
              <p onClick={() => { handleModal() }} className='!tw-mb-0 tw-font-sans tw-text-xs tw-font-medium tw-text-teal-500 tw-cursor-pointer'>
                View permissions
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactIndividual;
