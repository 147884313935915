import { useDeleteDocumentMutation } from './../data/mutations/DeleteDocumentMutation';
import { useApolloCacheHelpers } from './../hooks/useApolloCacheHelpers';
import { useFlashActions } from 'store/flashStoreContext';

const useDeleteDocument = ({ id }) => {
  const {
    removeDocumentFromCache,
    evictRecentDocumentsCache,
    evictFavouriteDocumentsCache,
    evictSearchDocumentsCache,
  } = useApolloCacheHelpers();

  const { setFlash } = useFlashActions();

  const onError = ({ message }) => setFlash(message);

  const update = (cache, { data: { deleteDocument } }) => {
    const { document } = deleteDocument;
    const { breadcrumbs } = document;

    removeDocumentFromCache(document.parent, document);
    evictRecentDocumentsCache();
    evictFavouriteDocumentsCache();
    evictSearchDocumentsCache();
  };

  const [mutate, { loading: isDeleting }] = useDeleteDocumentMutation({ update, onError });

  const deleteDocument = () => {
    mutate({ variables: { input: { documentId: id } } });
  };

  return { isDeleting, deleteDocument };
};

export { useDeleteDocument };
