/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { Select } from 'components/shared/Select';
import closeIcon from 'images/icons/close-icon-black.svg';
import { ReactComponent as Signature } from 'images/icons/signature.svg';
import { ReactComponent as CurveArrow } from 'images/icons/curve-arrow.svg';

import * as styles from './Toolbar.styles';

const Toolbar = memo(
  ({
    signatories,
    currentSignatory,
    toggleSignatory,
    isSelectedSignatory,
    saveConfiguration,
    documentIsConfigured,
    onContinueToSigning,
    toggleActiveField,
    isActiveFieldType,
    fieldTypes,
    activeField,
    dateTypeOptions,
    onDateTypeChange,
    discardChanges,
    showActions = true,
  }) => {
    const signatoryList = signatories.map((signatory, index) => {
      const signatoryKey = `configure-${signatory.id}`;
      const isSelected = isSelectedSignatory(signatory);
      const onClick = toggleSignatory.bind(this, signatory);
      const activeClass = isSelected ? 'active' : null;

      return (
        <div
          className={`signatory-item ${activeClass}`}
          key={signatoryKey}
          onClick={onClick}
          css={styles.signatory(isSelected)}
        >
          <div css={styles.signatoryBlock}>
            <div>{index + 1}</div>
            <div className='text-truncate m-l-5'>{signatory.name}</div>
          </div>

          {isSelected && (
            <div css={styles.addFields}>
              <FormattedMessage id='Toolbar.addFields' />
            </div>
          )}
        </div>
      );
    });

    const dateTypeChangeHandler = (option) => {
      onDateTypeChange(activeField, {
        format: option.value,
        value: option.label,
      });
    };

    return (
      <>
        <div id='react-configure-toolbar'>
          {showActions && (
            <div css={styles.padded}>
              <div css={styles.header} className='d-flex align-items-center justify-content-between'>
                <span css={styles.heading}>{`Signatories (${signatories.length})`}</span>
                <img src={closeIcon} css={styles.closeIcon} onClick={discardChanges} />
              </div>
            </div>
          )}

          {documentIsConfigured ? null : (
            <div css={styles.helpBlock}>
              <div css={styles.helpContent}>
                <CurveArrow css={styles.helpArrow} />
                <span>
                  <FormattedMessage id='Toolbar.clickName' />
                </span>
              </div>
            </div>
          )}
          <div css={styles.padded} id='react-signatories-list' className='m-t-15'>
            {signatoryList}
          </div>

          {currentSignatory && (
            <div className='m-t-35'>
              <div css={styles.padded}>
                <div css={styles.header} className='d-flex align-items-baseline'>
                  <div css={styles.heading}>
                    <FormattedMessage id='Toolbar.fieldsFor' />
                  </div>
                  <div css={styles.signatoryName}>{currentSignatory.name}</div>
                </div>
              </div>

              <div css={styles.padded}>
                <div className='m-t-25' css={styles.header}>
                  <div
                    css={styles.active(isActiveFieldType(fieldTypes.signature))}
                    onClick={toggleActiveField.bind(this, fieldTypes.signature)}
                    className='d-flex align-items-center'
                  >
                    <Signature className='d-flex m-0 m-r-10' />
                    <span>
                      <FormattedMessage id='Toolbar.signature' />
                    </span>
                  </div>

                  <div
                    css={styles.active(isActiveFieldType(fieldTypes.date))}
                    onClick={toggleActiveField.bind(this, fieldTypes.date)}
                    className='d-flex align-items-baseline m-t-10'
                  >
                    <i className='far fa-fw fa-calendar-alt m-r-10' />
                    <span>
                      <FormattedMessage id='Toolbar.dateSigned' />
                    </span>
                  </div>
                </div>
              </div>

              {activeField.type === fieldTypes.date && (
                <div id='react-selected-field-details' className='m-t-30'>
                  <div className='d-flex justify-content-center align-items-center'>
                    <span css={styles.line} />
                    <div css={styles.details}>
                      <FormattedMessage id='Toolbar.fieldDetails' />
                    </div>
                    <span css={styles.line} />
                  </div>

                  <div css={styles.padded} className='m-t-20 d-flex align-items-center justify-content-between'>
                    <span css={styles.format}>
                      <FormattedMessage id='Toolbar.format' />
                    </span>
                    <div className='tw-w-1/2'>
                      <Select
                      options={dateTypeOptions}
                      value={{
                        value: activeField.format,
                        label: activeField.value,
                      }}
                      onChange={dateTypeChangeHandler}
                      classNamePrefix='format'
                    />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {showActions && (
          <div css={styles.padded}>
            <div css={styles.header} className='d-flex align-self-start m-t-25'>
              <button onClick={saveConfiguration} className='m-r-5 btn btn-inverted'>
                <FormattedMessage id='Toolbar.saveClose' />
              </button>

              <button
                disabled={!documentIsConfigured}
                onClick={onContinueToSigning}
                className={`btn ${documentIsConfigured ? 'btn-primary' : 'btn-disabled'}`}
              >
                <FormattedMessage id='Toolbar.sendSign' />
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
);

export { Toolbar };
