import { gql, useMutation } from '@apollo/client';

const DELETE_ANNOTATION = gql`
  mutation deleteAnnotation($input: DeleteAnnotationInput!) {
    deleteAnnotation(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`;

const useDeleteAnnotationMutation = (options) => useMutation(DELETE_ANNOTATION, options);

export default useDeleteAnnotationMutation;
