import { gql, useMutation } from '@apollo/client';
import { DIRECTORY_FRAGMENT } from './../fragments/DirectoryFragment';

const SHARE_DIRECTORY = gql`
  mutation shareDirectory($input: ShareDirectoryInput!) {
    shareDirectory(input: $input) {
      directory {
        id
        shared

        descendants {
          id
          shared

          documents {
            id
            shared
          }
        }

        documents {
          id
          shared
        }
      }
    }
  }
`;

const useShareDirectoryMutation = (options) => useMutation(SHARE_DIRECTORY, options);

export { useShareDirectoryMutation };
