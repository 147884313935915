import { css } from '@emotion/core';
import { colors, icons } from 'styles';

export const icon = css`
  ${icons.base}
  color: ${colors.lighterGrey};
  font-size: 12px;
  margin-left: 10px;
`;

export const editableIcon = css`
  ${icon}
  margin-top: 5px;
`;

export const staticText = {
  base: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    white-space: pre-line;
  `,

  placeholder: css`
    color: ${colors.red};
  `,
};

export const textArea = css`
  background: ${colors.white};
  resize: none;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  min-width: 1px;
  width: 100%;
  padding: 8px 12px;
`;
