import React, { useState, useEffect } from 'react';
import { Viewer } from './../Viewer';
import { Field } from './../Field';
import { Spinner } from 'components/shared/Spinner';
import Modal from './Modal';
import { ActionBar } from './ActionBar';
import { AddSignature } from './AddSignature';
import { Outer, ViewerWrapper, OptionsWrapper } from './styles';
import api from './../API';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { withIntl } from '../../../utils/v2/intl';

const Signatures = ({ documentId, documentURL, accessToken, existingSignatureImageUrl, loggedIn }) => {
  const { isDesktop } = useWindowDimensions();
  const [isLoaded, setIsLoaded] = useState(false);

  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [documentFields, setDocumentFields] = useState();
  const [activeSignature, setActiveSignature] = useState(null);
  const [isSigning, setIsSigning] = useState(false);
  const [currentFieldInView, setCurrentFieldInView] = useState(null);
  const [showNavigation, setShowNavigation] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [completedFields, setCompletedFields] = useState([]);

  useEffect(() => {
    if (documentLoaded) {
      api.loadSignatureFields(documentId, accessToken, (response) => {
        setDocumentFields(transformDocumentFields(response));
      });
    }
    !isSigning && $('body').css('overflow', 'hidden');
  }, [documentLoaded, isSigning, !isDesktop]);

  useEffect(() => {
    if (documentFields) {
      const signatureFields = documentFields.filter((field) => field.isSignature);
      const readySignatures = signatureFields.filter((field) => field.signatureURL);

      setReadyToSubmit(signatureFields.length === readySignatures.length);
      setIsLoaded(true);
    }
  }, [documentFields]);

  const transformDocumentFields = (fields) => {
    return fields.map((field) => {
      const isSignature = field.field_type === 'signature';

      return {
        id: field.id,
        signatoryName: field.signatory_name,
        isSignature: isSignature,
        forSignature: true,
        page: field.page,
        value: field.value,
        signatureURL: field.attachment_url,
        position: {
          x: field.position_x,
          y: field.position_y,
        },
        size: {
          width: field.width,
          height: field.height,
        },
      };
    });
  };

  const markDocumentAsLoaded = () => {
    setDocumentLoaded(true);
  };

  const documentPage = ({ page }) => {
    return document.querySelector(`.canvas-page-wrapper[data-page='${page}']`);
  };

  const updateFieldsWithSignature = (response) => {
    const currentFields = [...documentFields];
    let completed = completedFields;

    response.map((field) => {
      const index = documentFields.findIndex((documentField) => field.id === documentField.id);

      currentFields[index] = {
        ...documentFields[index],
        signatureURL: field.attachment_url,
      };
      setCompletedFields((state) => [...state, field.id]);
    });

    setDocumentFields(currentFields);
    setActiveSignature(null);
  };

  const modalCloseHandler = () => {
    setActiveSignature(null);
  };

  const submitSignatures = () => {
    api.submitSignature(documentId, accessToken, (response) => {
      window.location.assign(`/${getCompanySlug()}/review_documents/thank_you`);
    });
  };

  const scrollToField = (id) => {
    setCurrentFieldInView(id);
    $('.viewport').scrollTo(`#arrow-${id}`, 1000);
  };

  const handleNextClick = () => {
    if (isSigning) {
      setIsSigning(true);
      setShowNavigation(true);
      moveNext();
    } else {
      const firstItem = documentFields
        .filter((field) => field.isSignature)
        .sort((a, b) => {
          return a.id - b.id;
        })[0].id;
      setIsSigning(true);
      setShowNavigation(true);
      scrollToField(firstItem);
    }
  };

  const handleRowClick = (field) => {
    setShowNavigation(true);
    scrollToField(field.id);
  };

  const moveNext = (index) => {
    let nextId;
    if (index) {
      nextId = index;
    } else {
      nextId = documentFields.findIndex((field) => field.id === currentFieldInView) + 1;
    }

    if (nextId >= documentFields.length) return null;

    if (!documentFields[nextId].isSignature) {
      moveNext(nextId + 1);
    } else {
      scrollToField(documentFields[nextId].id);
    }
  };

  const isCompleted = (field) => {
    return completedFields.includes(field.id) || field.signatureURL || !field.isSignature;
  };

  return (
    <Outer>
      <ActionBar
        isLoaded={isLoaded}
        readyToSubmit={readyToSubmit}
        submitSignatures={submitSignatures}
        isSigning={isSigning}
        handleNextClick={handleNextClick}
        loggedIn={loggedIn}
        showNavigation={showNavigation}
        documentFields={documentFields}
        isCompleted={isCompleted}
        handleRowClick={handleRowClick}
        setShowNavigation={setShowNavigation}
      />

      <Spinner show={!isLoaded} />

      <div className={`p-b-20 p-t-90 justify-content-center d-${isLoaded ? 'flex' : 'none'}`}>
        <ViewerWrapper isdesktop={isDesktop}>
          <Viewer documentURL={documentURL} onDocumentLoad={markDocumentAsLoaded} progressiveLoading={false} />
        </ViewerWrapper>
        {!isDesktop && !!activeSignature && (
          <OptionsWrapper>
            <AddSignature
              setActiveSignature={setActiveSignature}
              name={activeSignature?.signatoryName}
              token={accessToken}
              onSignatureInsert={updateFieldsWithSignature}
              documentId={documentId}
              fieldId={activeSignature?.id}
              existingSignatureImageUrl={existingSignatureImageUrl}
            />
          </OptionsWrapper>
        )}
        <Modal
          show={!!activeSignature && isDesktop}
          name={activeSignature?.signatoryName}
          fieldId={activeSignature?.id}
          onClose={modalCloseHandler}
          onSignatureInsert={updateFieldsWithSignature}
          documentId={documentId}
          token={accessToken}
          existingSignatureImageUrl={existingSignatureImageUrl}
        />

        {documentFields &&
          documentFields.map((documentField) => {
            const selectFieldHandler = documentField.isSignature
              ? setActiveSignature.bind(this, documentField)
              : () => {};

            return (
              <Field
                field={documentField}
                forSignature={true}
                updateField={() => {}}
                selectField={selectFieldHandler}
                key={documentField.id}
                signatureURL={documentField.signatureURL}
                position={documentField.position}
                isSignature={documentField.isSignature}
                size={documentField.size}
                current={currentFieldInView}
                page={documentPage(documentField)}
                isDisabled={!isSigning && !readyToSubmit}
              />
            );
          })}
      </div>
    </Outer>
  );
};

export default withIntl(Signatures);
