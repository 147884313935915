import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MenuItem from 'components/v2/layout/MenuItem';
import Dropdown from 'components/v2/shared/Dropdown';
import { Disclosure } from '@headlessui/react';
import { intl } from 'contexts/v2/TranslationContext';
import { FiTool, FiChevronDown } from 'react-icons/fi';

const Variants = {
  Mobile: 'mobile',
  Desktop: 'desktop',
};

const LanguagePicker = ({ value, options, onChange, variant }) => {
  const keys = Object.keys(options);
  const label = keys.find((key) => options[key] === value);

  if (!keys.length) {
    return null;
  }

  const handleDropdownItemClick = (language) => () => onChange(language);

  if (variant === Variants.Desktop) {
    return (
      <Dropdown
        className='tw-hidden xl:tw-block'
        renderToggle={({ isComponentVisible }) => (
          <div
            className={clsx('tw-flex tw-items-center tw-gap-2 tw-text-xxs tw-font-normal', {
              'tw-text-black': !isComponentVisible,
              'tw-text-purple-500': isComponentVisible,
            })}
          >
            <FiChevronDown className={clsx('tw-h-3 tw-w-3', { 'tw-rotate-180': isComponentVisible })} />
            {intl.formatMessage({ id: 'LanguageDropdown.language' })} ({value.toUpperCase()})
          </div>
        )}
        menuElement={
          <Dropdown.Menu className='tw-w-40'>
            {keys.map((key, index) => (
              <Dropdown.Item
                onClick={handleDropdownItemClick(options[key])}
                isSelected={value === options[key]}
                key={`language-picker-${options[key]}`}
              >
                {key}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        }
      />
    );
  }

  return (
    <Disclosure>
      {({ open }) => (
        <div className='xl:tw-hidden'>
          <Disclosure.Button as='div'>
            <MenuItem>
              <div className='tw-flex tw-items-center tw-gap-2'>
                <FiTool className='tw-h-4 tw-w-4' />
                {intl.formatMessage({ id: 'LanguageDropdown.language' })} ({value.toUpperCase()})
              </div>
              <FiChevronDown className={clsx('tw-h-4 tw-w-4', { 'tw-rotate-180': open })} />
            </MenuItem>
          </Disclosure.Button>

          <Disclosure.Panel>
            {keys.map((key, index) => (
              <MenuItem
                onClick={handleDropdownItemClick(options[key])}
                className='!tw-pl-10'
                isSelected={value === options[key]}
                key={`responsive-language-picker-${options[key]}`}
              >
                {key}
              </MenuItem>
            ))}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};

LanguagePicker.propTypes = {
  value: PropTypes.string,
  options: PropTypes.object,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(Variants)).isRequired,
};

export default LanguagePicker;
