import styled from 'styled-components';
import { fonts, colors } from 'styles';

export const TabWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${colors.purple};
`;

export const StyledTab = styled.div`
  ${fonts.styledSemiBold}
  height: 43px;
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 28px;
  border-radius: 8px;
  box-sizing: border-box;
  border-radius: ${({ index }) => (index === 0 ? '7px 0px 0px 7px' : index === 3 ? '0px 7px 7px 0px' : '0px')};
  ${({ hasborderright }) => hasborderright && `border-right: 1px solid ${colors.purple};`}
  background: ${({ active }) => (active ? colors.purple : colors.white)};
  color: ${({ active }) => (active ? colors.white : colors.purple)};
  position: relative;
  ${({ active }) =>
    active &&
    ` &:after {
    content: '';
    height: 0;
    width: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${colors.purple};
    left: 0;
    right: 0;
    top: 100%;
    margin: auto;
    position: absolute;
    display: block;
  }`}
`;

export const StyledClear = styled.span`
  margin: auto;
  color: ${colors.purple};
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
`;

export const SignatoryName = styled.div`
  z-index: 2;
  width: 100%;
  max-width: 200px;
  @media all and (max-width: 375px) {
    font-size: 14px;
  }
  @media all and (max-width: 320px) {
    font-size: 10px;
  }
`;

export const SignatoryWrapper = styled.div`
  flex: 1;
  padding-top: 16px;
  padding-bottom: 16px;
  opacity: 0.8;
  margin-right: 10px;
  ${({ isActive }) => isActive && `border-bottom: solid 2px ${colors.cyan}`};
  ${({ isActive }) => isActive && `opacity: 1`};
  text-align: center;

  &:last-child {
    margin-right: 0;
  }
`;
