import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const textButton = {
  base: css`
    ${fonts.regular}
    outline: none;
    line-height: 1.2;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 12px;
  `,

  cyan: css`
    color: ${colors.cyan};
  `,

  black: css`
    color: ${colors.black};
  `,

  disabled: css`
    color: ${colors.headingGrey};
  `,
};
