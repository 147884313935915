import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertPopup } from 'components/shared/AlertPopup';
import Logo from '../../../../assets/images/logo.svg';
import useWindowDimensions from 'hooks/useWindowDimensions';
import AppHeader from '../../shared/AppHeader';
import MobileMenu from '../../shared/MobileMenu';
import { ToolbarButtonWrapper, IconButton, HeaderTitle } from '../../shared/AppHeader/styles';
import { HeaderTitleWrapper, HeaderButton } from './styles';
import { useVisibility } from 'hooks/useVisibility';
import { TYPES } from './constants';

const Header = ({
  type = TYPES.NORMAL,
  title,
  signatorisSigned,
  signatoris,
  redirectUrl,
  confirmText,
  userName,
  currentUser,
  currentContact,
  profileLink,
  signOutLink,
  unreadMessages,
  unreadNotifications,
  currentCompany,
  companies,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { isMobile, isDesktop, isTablet } = useWindowDimensions();
  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  const intl = useIntl();

  const companySlug = getCompanySlug();
  const smartDocumentsURL = `/${companySlug}/smart_sign`;

  const { isComponentVisible, showComponent, hideComponent } = useVisibility(false);

  const MobileMenuComponent = () => {
    return (
      <MobileMenu
        unreadMessages={unreadMessages}
        unreadNotifications={unreadNotifications}
        isVisible={isComponentVisible}
        close={hideComponent}
        userName={userName}
        profileLink={profileLink}
        signOutLink={signOutLink}
        currentUser={currentUser}
        currentContact={currentContact}
        currentCompany={currentCompany}
        companies={companies}
      />
    );
  };

  if (isDesktop) {
    return <div>{title}</div>;
  } else {
    if (type === TYPES.NORMAL) {
      return (
        <>
          <AppHeader>
            <ToolbarButtonWrapper>
              <IconButton onClick={showComponent}>
                <i className='fal fa-bars fa-2x' />
              </IconButton>
            </ToolbarButtonWrapper>
            {isTablet && (
              <HeaderTitleWrapper>
                <HeaderTitle fontSize={20} lineHeight={30}>
                  {title}
                </HeaderTitle>
              </HeaderTitleWrapper>
            )}
            <img src={Logo} alt='logo-image' width={29} />
          </AppHeader>
          <MobileMenuComponent />
        </>
      );
    }

    if (type === TYPES.SHOW || type === TYPES.EDIT) {
      return (
        <>
          <AppHeader>
            <ToolbarButtonWrapper>
              <IconButton
                onClick={() => {
                  window.location.href = smartDocumentsURL;
                }}
              >
                <i className='far fa-angle-left fa-2x m-r-20' />
              </IconButton>
            </ToolbarButtonWrapper>
            <div className={isMobile ? 'm-l-40' : 'm-l-100'}>
              <HeaderTitle fontSize={isMobile ? 18 : 20} lineHeight={isMobile ? 20 : 30}>
                {type === TYPES.SHOW
                  ? intl.formatMessage({ id: 'Header.signedCount' }, { signed: signatorisSigned, total: signatoris })
                  : intl.formatMessage({ id: 'Header.smartSign' })}
              </HeaderTitle>
            </div>
            {type === TYPES.SHOW && (
              <HeaderButton onClick={show} fontSize={isMobile ? 16 : 18} lineHeight={20} right={isMobile ? 15 : 24}>
                <FormattedMessage id='Header.recall' />
              </HeaderButton>
            )}
            {type === TYPES.EDIT && (
              <HeaderButton
                onClick={() => {
                  window.location.href = smartDocumentsURL;
                }}
                fontSize={isMobile ? 16 : 18}
                lineHeight={20}
                right={isMobile ? 15 : 24}
              >
                <FormattedMessage id='Header.saveClose' />
              </HeaderButton>
            )}
          </AppHeader>
          <AlertPopup
            show={isVisible}
            popupType='info'
            headerText={intl.formatMessage({ id: 'Header.confirmation' })}
            onClose={hide}
          >
            <div className='text-black fs-14'>{confirmText}</div>
            <div className='d-flex align-items-center m-t-40 justify-content-center'>
              {redirectUrl && (
                <a href={redirectUrl} data-method='post' className='btn btn-info'>
                  <FormattedMessage id='Header.confirm' />
                </a>
              )}
              <button className={`btn btn-info-inverted m-l-10`} onClick={hide}>
                <FormattedMessage id='Header.cancel' />
              </button>
            </div>
          </AlertPopup>
          <MobileMenuComponent />
        </>
      );
    }
  }
};

export default Header;
