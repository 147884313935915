import { css } from '@emotion/core';
import { utils, colors, fonts } from 'styles';

export const addTask = css`
  display: flex;
  margin-top: 11px;
  align-items: center;
  color: ${colors.cyan};
  font-size: 16px;

  svg {
    flex-shrink: 0;
    margin-right: 7px;
  }

  &:hover {
    cursor: pointer;

    svg {
      opacity: 0.8;
    }
  }
`;
