import { gql } from '@apollo/client';

const ANNOTATION_UPDATED_SUBSCRIPTION = gql`
  subscription AnnotationUpdatedSubscription {
    annotationUpdated {
      id
      data
      contactId
    }
  }
`;

export default ANNOTATION_UPDATED_SUBSCRIPTION;
