import { useToggleFavouriteDirectoryMutation } from './../data/mutations/ToggleFavouriteDirectoryMutation';
import { useApolloCacheHelpers } from './../hooks/useApolloCacheHelpers';

const useToggleFavouriteDirectory = ({ id }) => {
  const { addDirectoryToFavouritesCache } = useApolloCacheHelpers();

  const update = (
    cache,
    {
      data: {
        toggleFavouriteDirectory: { directory },
      },
    }
  ) => {
    addDirectoryToFavouritesCache(directory);
  };

  const [mutate, { loading: isToggling }] = useToggleFavouriteDirectoryMutation({ update });

  const toggleFavouriteDirectory = () => {
    mutate({ variables: { input: { directoryId: id } } });
  };

  return { toggleFavouriteDirectory, isToggling };
};

export { useToggleFavouriteDirectory };
