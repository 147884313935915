import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const component = css`
  ${fonts.bold}
  padding: 3px 5px;
  white-space: nowrap;
  display: inline-block;
  background: ${colors.red};
  border-radius: 2px;
  line-height: 1;
  color: ${colors.white};
  text-transform: uppercase;
  font-size: 10px;
  z-index: 2;
  position: absolute;
  top: -8px;
  right: 12px;
  letter-spacing: 0.09em;
`;
