import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Backdrop from 'components/v2/shared/Backdrop';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const Header = ({ children, isBodyLockEnabled }) => {
  const headerRef = useRef();

  useEffect(() => {
    if (isBodyLockEnabled) {
      disableBodyScroll(headerRef.current);
    } else {
      enableBodyScroll(headerRef.current);
    }
  }, [isBodyLockEnabled]);

  return (
    <div
      ref={headerRef}
      className={clsx(
        'tw-fixed tw-top-0 tw-left-0 tw-right-0',
        'tw-h-[var(--primary-nav-height)]',
        'tw-box-border tw-font-sans tw-shadow',
        'tw-z-max tw-px-4 xl:tw-pl-0',
        'tw-bg-purple-500 xl:tw-bg-white'
      )}
    >
      <div className='tw-flex tw-h-full tw-items-center'>{children}</div>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  isBodyLockEnabled: PropTypes.bool,
};

export default Header;
