import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FiUser } from 'react-icons/fi';
import { intl } from 'contexts/v2/TranslationContext';

const ProfileItem = ({}) => (
  <div className='tw-flex tw-items-center tw-gap-2'>
    <FiUser className='tw-h-4 tw-w-4' />
    {intl.formatMessage({ id: 'MobileMenu.yourProfile' })}
  </div>
);

ProfileItem.propTypes = {};

export default ProfileItem;
