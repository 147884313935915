/** @jsxRuntime classic */
/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { Linkify } from 'components/shared/Linkify';

import Avatar from 'components/shared/Avatar';
import moment from 'moment';

import * as styles from './Message.styles';

const TIME_FORMAT = 'hh:mm A';

const MessageAvatar = ({ author, show, withAvatar }) => {
  const avatar = withAvatar && <Avatar contact={author} />;

  return show && <div css={styles.image}>{avatar}</div>;
};

const Message = memo(({ author, text, createdAt, withAvatar }) => {
  const { isCurrentContact } = author;

  const wrapperStyles = [styles.wrapper.base, withAvatar && styles.wrapper.withAvatar];

  const blockStyles = [
    styles.block.base,
    isCurrentContact && styles.block.withMarginRight,
    !isCurrentContact && styles.block.withMarginLeft,
  ];

  const triangleStyles = withAvatar && [
    isCurrentContact && styles.triangle.right,
    !isCurrentContact && styles.triangle.left,
  ];

  const timeCreated = moment(createdAt).format(TIME_FORMAT);

  return (
    <div css={wrapperStyles}>
      <MessageAvatar show={!isCurrentContact} author={author} withAvatar={withAvatar} />

      <div css={blockStyles}>
        <div css={styles.author}>{author.name}</div>

        <div css={styles.text}>
          <Linkify text={text} />
        </div>

        <div css={styles.date}>{timeCreated}</div>

        <div css={triangleStyles} />
      </div>

      <MessageAvatar show={isCurrentContact} author={author} withAvatar={withAvatar} />
    </div>
  );
});

export { Message };
