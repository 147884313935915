import { gql, useMutation } from '@apollo/client';

const CREATE_ACCESS_ROLE_MUTATION = gql`
  mutation CreateAccessRole($input: CreateAccessRoleInput!) {
    createAccessRole(input: $input) {
      accessRole {
        id
        name
      }
      errors {
        attribute
        message
      }
    }
  }
`;

const useCreateAccessRoleMutation = (options) => useMutation(CREATE_ACCESS_ROLE_MUTATION, options);

export { CREATE_ACCESS_ROLE_MUTATION, useCreateAccessRoleMutation };
