/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import Box from 'components/v2/shared/Box';
import Text from 'components/v2/shared/Text';
import Avatar from 'components/v2/shared/Avatar';
import CircleIcon from 'components/v2/shared/CircleIcon';
import VoteItemActionsMenu from './../VoteItemActionsMenu';

import * as styles from './VoteItem.styles';

const VoteItem = ({ name, owner, className }) => {
  const renderAvatar = () => {
    if (owner) {
      return <Avatar size='small' text={owner.initials} />;
    } else {
      return <CircleIcon size='small' onClick={(e) => alert('click')} icon='plus' bgColor='white' />;
    }
  };

  return (
    <Box variant='task' className='mb-2'>
      <Text fontSize={12} text={name} className='mr-3' isTruncated />
      <div className='d-flex align-items-center'>
        {renderAvatar()}
        <VoteItemActionsMenu className='ml-3' />
      </div>
    </Box>
  );
};

VoteItem.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  owner: PropTypes.shape({
    avatarUrl: PropTypes.string,
    initials: PropTypes.string,
  }),
};

export default VoteItem;
