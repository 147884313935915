/** @jsx jsx */

import React, { memo, useCallback } from 'react';
import { css, jsx } from '@emotion/core';

import { useTaskDrawerActions } from 'store/taskDrawerStoreContext';
import { useTasksActions } from 'store/tasksStoreContext';
import { useFlashActions } from 'store/flashStoreContext';
import { useHover } from 'hooks/useHover';

import * as styles from './AgendaTaskListItem.styles';

const AgendaTaskListItem = memo(({ meetingId, agendaId, task, tasks, isEditable }) => {
  const [hoverableRef, isHovered] = useHover();

  const { openDrawer } = useTaskDrawerActions();
  const { updateTask } = useTasksActions();
  const { setFlash } = useFlashActions();

  const handleEdit = useCallback(() => {
    openDrawer({ ...task });
  }, [task]);

  const handleDeleteTask = useCallback(
    (e) => {
      e.stopPropagation();
      updateTask(meetingId, task.id, { ...task, agenda_id: null }).catch(setFlash);
    },
    [task.id]
  );

  const applicableStyles = [
    styles.task.base,
    task.completed && styles.task.completed,
    isHovered && styles.task.hovered,
  ];

  return (
    <div ref={hoverableRef} className='d-flex align-items-center' onClick={handleEdit}>
      {task.completed && <i className='fa fa-check m-r-5' css={styles.checkmark} />}

      <div css={applicableStyles} className='ms-agenda-task-list-item'>
        <div className='d-flex align-items-center cursor-pointer'>
          <div>{task.title}</div>
          {isEditable && <i css={styles.deleteIcon} className='m-l-10 far fa-trash-alt' onClick={handleDeleteTask} />}
        </div>
      </div>
    </div>
  );
});

export { AgendaTaskListItem };
