import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import BreadcrumbItem from '../BreadcrumbItem';

const Breadcrumbs = ({ items = [], onClick }) => {
  const handleBreadcrumbItemClick = (item) => () => onClick(item);
  const renderBreadcrumbItems = items.map((item, index) => (
    <BreadcrumbItem
      key={item.id}
      name={item.name}
      isSelected={index === items.length - 1}
      onClick={handleBreadcrumbItemClick(item)}
      className={clsx({
        'lg:tw-hidden': index === 0,
      })}
    />
  ));

  return <div className='tw-inline-flex tw-flex-wrap tw-items-center tw-gap-2'>{renderBreadcrumbItems}</div>;
};

Breadcrumbs.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
};

export default Breadcrumbs;
