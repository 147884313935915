import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const modal = css`
  .modal-dialog.shaparency {
    height: 598px;

    .modal-content {
      ${media.tablet} {
        height: auto;
        min-height: auto;
        background: ${colors.white};
      }

      .modal-header {
        padding-right: 30px;

        ${media.tablet} {
          padding: 0 30px 0 42px;
        }
      }
    }
  }
`;
