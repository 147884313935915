/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './ActionsDropdownItem.styles';

const ActionsDropdownItem = memo(({ option, disabled, onClick }) => {
  const handleClick = (event) => {
    event.stopPropagation();

    return disabled ? null : onClick(option);
  };

  return (
    <div css={styles.container} onClick={handleClick} className={`${disabled ? 'disabled' : ''}`}>
      {option}
    </div>
  );
});

export { ActionsDropdownItem };
