/** @jsx jsx */

import React, { memo, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { withIntl } from '../../utils/v2/intl';

import SignatureInput from '../smart_documents/SignatureInput';
import { AlertPopup } from '../shared/AlertPopup';
import api from './API';

const SmartSignSettings = memo(({ userName, existingSignatureImageUrl }) => {
  const [existingSignature, setExistingSignature] = useState(existingSignatureImageUrl);
  const signatureInput = useRef();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const intl = useIntl();

  const hideSuccessAlert = () => {
    return setShowSuccessAlert(false);
  };

  const onUpload = (response) => {
    setExistingSignature(response.data.signature_url);
    setShowSuccessAlert(true);
  };

  const saveSignature = () => {
    signatureInput.current.processInput((signatureBlob) => {
      api.uploadSignature(signatureBlob, onUpload);
    });
  };

  return (
    <Row className='m-t-10 p-b-20'>
      <AlertPopup
        show={showSuccessAlert}
        headerText={intl.formatMessage({ id: 'SmartSignSettings.success' })}
        popupType='success'
        key='success-popup'
        onClose={hideSuccessAlert}
      >
        <FormattedMessage id='SmartSignSettings.signatureSaved' />
      </AlertPopup>
      <Col md={10}>
        <span>
          <FormattedMessage id='SmartSignSettings.signature' />
        </span>

        <SignatureInput
          name={userName}
          existingSignatureImageUrl={existingSignature}
          ref={signatureInput}
          onSave={saveSignature}
        />
      </Col>
    </Row>
  );
});

export default withIntl(SmartSignSettings);
