/** @jsx jsx */

import React, { memo, useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { Comment } from './../Comment';
import { CommentThread } from './../CommentThread';
import { EditCommentThread } from './../EditCommentThread';
import { Scrollable } from 'components/shared/Scrollable';
import { Placeholder } from 'components/shared/Placeholder';
import { CommentForm } from './../CommentForm';

import { useCommentsStore } from 'store/commentsStoreContext';
import { useMinutesActions } from 'store/minutesStoreContext';
import { useActionCable } from 'hooks/useActionCable';

import { ReactComponent as CommentIcon } from 'images/icons/comment-icon.svg';

import * as styles from './CommentList.styles';

const CommentList = memo(
  ({
    minuteId,
    minuteOwnerId,
    currentContact,
    reviewerId,
    showAddCommentForm,
    selectedText,
    selectedImages,
    onAddCommentFormCancel,
    isReadOnly,
    ...props
  }) => {
    const { comments } = useCommentsStore();
    const { setMinutesContext } = useMinutesActions();

    const intl = useIntl();

    useActionCable('MinutesChannel', { minute_id: minuteId }, (response) => setMinutesContext(minuteId, response));

    const [currentComment, setCurrentComment] = useState(null);
    const [editableComment, setEditableComment] = useState(null);

    useEffect(() => {
      if (currentComment) {
        const updatedComment = comments.find((item) => item.id === currentComment.id);
        setCurrentComment(updatedComment);
      }
    }, [comments]);

    useEffect(() => {
      if (showAddCommentForm) {
        closeCommentThread();
      }
    }, [showAddCommentForm]);

    const openCommentThread = useCallback((comment) => {
      setCurrentComment(comment);
    }, []);

    const closeCommentThread = useCallback(() => {
      setCurrentComment(null);
    }, []);

    const openEditThread = useCallback((comment) => {
      setEditableComment(comment);
    }, []);

    const closeEditThread = useCallback(() => {
      setEditableComment(null);
    }, []);

    const compareByDateDesc = (a, b) => {
      const dateA = a.replied_at || a.created_at;
      const dateB = b.replied_at || b.created_at;

      return new Date(dateB) - new Date(dateA);
    };

    const compareByDateAsc = (a, b) => {
      const dateA = a.replied_at || a.created_at;
      const dateB = b.replied_at || b.created_at;

      return new Date(dateA) - new Date(dateB);
    };

    const getReplies = (parentId) => {
      return comments.filter((item) => item.ancestry === parentId.toString()).sort(compareByDateAsc);
    };

    const roots = useMemo(() => {
      let scope = comments.filter((comment) => !comment.ancestry);

      if (reviewerId) {
        scope = scope.filter(({ author }) => author.data.attributes.id === reviewerId);
      }

      return scope.sort(compareByDateDesc);
    }, [comments, reviewerId]);

    const listBody = roots.map((comment) => (
      <Comment
        key={comment.id}
        minuteId={minuteId}
        minuteOwnerId={minuteOwnerId}
        comment={comment}
        replies={getReplies(comment.id)}
        currentContactId={currentContact.data.id}
        onReply={openCommentThread}
        onEdit={openEditThread}
        isReadOnly={isReadOnly}
        isRoot
      />
    ));

    const commentThread = currentComment && (
      <CommentThread
        minuteId={minuteId}
        minuteOwnerId={minuteOwnerId}
        root={currentComment}
        currentContactId={currentContact.data.id}
        replies={getReplies(currentComment.id)}
        onEdit={openEditThread}
        onCancel={closeCommentThread}
        isReadOnly={isReadOnly}
      />
    );

    const editableThread = editableComment && (
      <EditCommentThread minuteId={minuteId} comment={editableComment} onCancel={closeEditThread} />
    );

    const scrollableRef = useRef();

    const scrollTop = () => {
      scrollableRef.current.scrollToTop();
    };

    const handleAddCommentFormSubmit = () => {
      onAddCommentFormCancel();
      scrollTop();
    };

    const addCommentForm = showAddCommentForm && (
      <CommentForm
        minuteId={minuteId}
        author={currentContact}
        selectedText={selectedText}
        selectedImages={selectedImages}
        onShow={scrollTop}
        onCancel={onAddCommentFormCancel}
        onSubmit={handleAddCommentFormSubmit}
      />
    );

    if (reviewerId) {
      if (!roots || !roots.length) {
        return (
          <div css={styles.placeholder} className='outline-none'>
            <Placeholder image={<CommentIcon />} message={intl.formatMessage({ id: 'CommentList.noComments' })} />
          </div>
        );
      }
    }

    if (!showAddCommentForm) {
      if (!comments || !comments.length) {
        return (
          <div css={styles.placeholder}>
            <Placeholder image={<CommentIcon />} message={intl.formatMessage({ id: 'CommentList.noComments' })} />
          </div>
        );
      }
    }

    return (
      <div css={styles.panel} {...props}>
        <div css={styles.list}>
          <Scrollable ref={scrollableRef}>
            {addCommentForm}
            {listBody}
          </Scrollable>
        </div>

        {commentThread}
        {editableThread}
      </div>
    );
  }
);

export { CommentList };
