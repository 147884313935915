/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row } from 'react-bootstrap';
import Chip from 'components/v2/shared/Chip';
import Text from 'components/v2/shared/Text';

import * as styles from './OverviewMeetingHeader.styles';

const OverviewMeetingHeader = () => (
  <Row noGutters className='mb-4'>
    <Chip className='mr-4'>
      <Text fontSize={12} text='12.02.2022&nbsp;' />
      <Text fontSize={12} color='lightGrey' text='13 days' />
    </Chip>

    <Chip className='mr-4'>
      <Text fontSize={12} color='black' text='12:30 PM&nbsp;' />
      <Text fontSize={12} color='lightGrey' text='(London GMT+01:00)' />
    </Chip>

    <Chip color='lightCyan' onClick={(e) => window.open('https://google.com', '_blank')}>
      <Text fontSize={12} color='cyan' text='URL link to conference' />
    </Chip>
  </Row>
);

OverviewMeetingHeader.propTypes = {};

export default OverviewMeetingHeader;
