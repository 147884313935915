/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './ActionBar.styles';

const ActionBar = memo(({ children, ...props }) => {
  return (
    <div css={styles.bar} {...props}>
      {children}
    </div>
  );
});

export { ActionBar };
