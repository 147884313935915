import React, { memo, useState, useRef } from 'react';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';

import Modal from 'react-bootstrap/Modal';
import SearchResult from './SearchResult';

import closeIcon from '../../../../assets/images/icons/close-icon-black.svg';

export default memo(({ setPickedContacts, pickedContacts, searchKey, onHide, show, header }) => {
  const companySlug = getCompanySlug();

  const contactsURL = `/api/${companySlug}/contacts.json`;

  let fetched = false;

  const searchInputRef = useRef(null);
  let [results, setResults] = useState([]);

  const fetchResults = (query = null) => {
    axios.get(contactsURL, { params: { scope: searchKey, query: query } }).then(({ data }) => {
      setResults(data);
    });
    fetched = true;
  };

  const onSearchInputChange = (event) => {
    fetchResults(event.target.value);
  };

  const onModalEnter = () => {
    if (!fetched) fetchResults();

    searchInputRef.current.focus();
  };

  const closeBtn = (
    <button className='close' aria-label='Close' onClick={onHide}>
      <span aria-hidden='true'>
        <img src={closeIcon} />
      </span>
    </button>
  );

  const pickedContactIds = pickedContacts.map((contact) => contact.id);

  const isAdded = (contact) => {
    return pickedContactIds.includes(contact.id);
  };

  const toggleContact = (contact) => {
    let added = isAdded(contact);

    if (added) {
      removeContact(contact);
    } else {
      addContact(contact);
    }
  };

  const addContact = (contact) => {
    setPickedContacts(pickedContacts.concat(contact));
  };

  const removeContact = (contact) => {
    setPickedContacts(pickedContacts.filter((pickedContact) => pickedContact.id != contact.id));
  };

  const searchResults = results.map((contact) => {
    return <SearchResult contact={contact} key={contact.id} toggleContact={toggleContact} isAdded={isAdded(contact)} />;
  });

  return (
    <React.Fragment>
      <Modal onEnter={onModalEnter} className='shaparency-contact-picker' show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>{header || 'Invite contacts'}</Modal.Title>
          {closeBtn}
        </Modal.Header>
        <Modal.Body>
          <div className='search-input-wrapper'>
            <input
              type='text'
              placeholder='Search contacts'
              className='w-100 form-control'
              onChange={onSearchInputChange}
              ref={searchInputRef}
            />
          </div>
          <div className='search-results'>{searchResults}</div>
          <div className='no-results'>
            <FormattedMessage id='SearchModal.noResults' />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
});
