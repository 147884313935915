import { useMarkMessagesAsReadMutation } from './../data/mutations/MarkMessagesAsReadMutation';

const useMarkMessagesAsRead = ({ onCompleted }) => {
  const update = (
    cache,
    {
      data: {
        markMessagesAsRead: { showBadge },
      },
    }
  ) => onCompleted(showBadge);

  const [mutate] = useMarkMessagesAsReadMutation({ update });

  const markMessagesAsRead = () => mutate({ variables: { input: {} } });

  return { markMessagesAsRead };
};

export { useMarkMessagesAsRead };
