import { gql } from '@apollo/client';
import { CONTACT_FRAGMENT } from './ContactFragment';

const VOTE_FRAGMENT = gql`
  fragment VoteFields on Vote {
    id
    name
    description
    config

    owner {
      ...ContactFields
    }
  }
  ${CONTACT_FRAGMENT}
`;

export { VOTE_FRAGMENT };
