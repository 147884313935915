export default {
  Conversation: {
    fields: {
      messages: {
        merge(existing = {}, incoming, { args }) {
          if (!existing.edges) {
            return incoming;
          }
          // Workaround for updates via subscription
          // https://github.com/apollographql/apollo-client/issues/7817
          const viaSubscription = incoming.edges.find((item) => !item.cursor);

          if (viaSubscription) {
            return incoming;
          }

          return {
            ...incoming,
            edges: [...incoming.edges, ...existing.edges],
          };
        },
      },
    },
  },
};
