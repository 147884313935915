import { gql, useMutation } from '@apollo/client';
import { RESOLUTION_FRAGMENT } from './../fragments/ResolutionFragment';

const CREATE_RESOLUTION_DOCUMENT = gql`
  mutation createResolutionDocument($input: CreateResolutionDocumentInput!) {
    createResolutionDocument(input: $input) {
      vote {
        resolutions {
          ...ResolutionFields
        }
      }

      errors {
        attribute
        message
      }
    }
  }
  ${RESOLUTION_FRAGMENT}
`;

const useCreateResolutionDocumentMutation = (options) => useMutation(CREATE_RESOLUTION_DOCUMENT, options);

export { useCreateResolutionDocumentMutation };
