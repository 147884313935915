/** @jsx jsx */

import React, { memo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import * as styles from './VoterResultsTable.styles';

const VoterResultsTable = memo(({ show, pollResults, answerOptions }) => {
  const [selectedOption, setSelectedOption] = useState(answerOptions[0]);
  const [voters, setVoters] = useState(pollResults.answers[selectedOption]);

  const optionIsSelected = (option) => {
    return selectedOption == option;
  };

  const setOption = (option) => {
    return setSelectedOption(option);
  };

  const contacts = () => {
    if (!voters) {
      return [];
    }

    return voters.filter((voter) => voter.data.attributes.contact_id != null);
  };

  const collaborators = () => {
    if (!voters) {
      return [];
    }

    return voters.filter((voter) => !voter.data.attributes.contact_id);
  };

  useEffect(() => {
    return setVoters(pollResults.answers[selectedOption]);
  }, [selectedOption, pollResults]);

  const renderContacts = contacts().map((voter, index) => {
    return (
      <tr css={styles.tableRow(!voter[index + 1]) && styles.voterRow} key={voter.data.id}>
        <td>{index + 1}</td>
        <td className='text-truncate'>{voter.data.attributes.name}</td>
        <td className='text-truncate'>{voter.data.attributes.email}</td>
      </tr>
    );
  });

  const renderCollaborators = collaborators().map((voter, index) => {
    return (
      <tr css={styles.tableRow(!voter[index + 1])} key={voter.data.id}>
        <td>{index + 1}</td>
        <td className='text-truncate'>{voter.data.attributes.email}</td>
      </tr>
    );
  });

  const options = answerOptions.map((option, index) => {
    return (
      <div
        key={`option-${index}`}
        className={`text-black fs-14 w-60 cursor-pointer ${optionIsSelected(option) ? 'selected' : ''}`}
        css={styles.voteOption}
        onClick={() => setOption(option)}
      >
        {option}
      </div>
    );
  });

  return (
    <>
      {show && (
        <div className='m-t-20' css={styles.voterTable}>
          <div className='d-flex text-center justify-content-center'>{options}</div>

          <div className='fs-16 font-weight-semibold text-black m-t-15 m-b-15' css={styles.votersTitle}>
            <FormattedMessage id='VoterResultsTable.companyContacts' values={{ count: contacts().length }} />
          </div>

          <table css={styles.table}>
            <thead css={styles.tableHead}>
              <tr>
                <th css={styles.tableHeader(10)}>#</th>
                <th css={styles.tableHeader(45)}>
                  <FormattedMessage id='VoterResultsTable.name' />
                </th>
                <th css={styles.tableHeader(45)}>
                  <FormattedMessage id='VoterResultsTable.email' />
                </th>
              </tr>
            </thead>

            {contacts().length > 0 && <tbody className='fs-12 text-black'>{renderContacts}</tbody>}
          </table>

          {contacts().length === 0 && (
            <div className='fs-14 text-grey text-center p-10' css={styles.votersTitle}>
              <FormattedMessage id='VoterResultsTable.noContacts' />
            </div>
          )}

          <div className='fs-16 font-weight-semibold text-black m-b-15 m-t-30' css={styles.votersTitle}>
            <FormattedMessage id='VoterResultsTable.collabCount' values={{ count: collaborators().length }} />
          </div>

          <table css={styles.table}>
            <thead css={styles.tableHead}>
              <tr>
                <th css={styles.tableHeader(10)}>#</th>
                <th css={styles.tableHeader(90)}>
                  <FormattedMessage id='VoterResultsTable.email' />
                </th>
              </tr>
            </thead>

            {collaborators().length > 0 && <tbody className='fs-12 text-black'>{renderCollaborators}</tbody>}
          </table>

          {collaborators().length === 0 && (
            <div className='fs-14 text-grey text-center p-10' css={styles.votersTitle}>
              <FormattedMessage id='VoterResultsTable.noCollab' />
            </div>
          )}
        </div>
      )}
    </>
  );
});

export { VoterResultsTable };
