import { gql, useMutation } from '@apollo/client';

const CLOSE_COMPANY_MUTATION = gql`
  mutation CloseCompany($input: CloseCompanyInput!) {
    closeCompany(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`;

const useCloseCompanyMutation = (options) => useMutation(CLOSE_COMPANY_MUTATION, options);

export { CLOSE_COMPANY_MUTATION, useCloseCompanyMutation };
