import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { AlertPopup } from '../shared/AlertPopup';
import { withIntl } from '../../utils/v2/intl';

const Success = memo(({ show, text }) => {
  const [isVisible, setIsVisible] = useState(!!show);
  useEffect(() => history.replaceState(null, null, location.pathname), []);

  const intl = useIntl();

  const handleOnClose = () => setIsVisible(false);

  return (
    <>
      <AlertPopup
        show={isVisible}
        headerText={intl.formatMessage({ id: 'Success.success' })}
        popupType='success'
        key='success-popup'
        onClose={handleOnClose}
      >
        {text}
      </AlertPopup>
    </>
  );
});

export default withIntl(Success);
