import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Backdrop = ({ isVisible }) => (
  <div
    className={clsx('tw-fixed tw-inset-0 tw-z-backdrop tw-bg-black/30', {
      'tw-hidden': !isVisible,
    })}
  />
);

Backdrop.propTypes = {
  isVisible: PropTypes.bool,
};

export default Backdrop;
