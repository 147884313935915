/** @jsx jsx */

import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import * as styles from './Label.styles';

const Label = memo(({ text, htmlFor, style }) => {
  if (!text) {
    return <></>;
  }

  return (
    <label htmlFor={htmlFor} css={styles.label} style={style}>
      {text}
    </label>
  );
});

Label.propTypes = {
  text: PropTypes.string,
  htmlFor: PropTypes.string,
};

export { Label };
