import { gql, useMutation } from '@apollo/client';

const CREATE_CONTACTS_MUTATION = gql`
  mutation CreateContacts($input: CreateContactsInput!) {
    createContacts(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`;

const useCreateContactsMutation = (options) => useMutation(CREATE_CONTACTS_MUTATION, options);

export { CREATE_CONTACTS_MUTATION, useCreateContactsMutation };
