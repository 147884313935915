/** @jsx jsx */

import React, { memo, useCallback, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { AgendaItem as AgendaSubItem } from './';
import { AgendaItemWrapper } from './../AgendaItemWrapper';
import { AgendaTaskListItem } from './../AgendaTaskListItem';
import { AgendaDocumentList } from './../AgendaDocumentList';
import { AgendaDocumentsUpload } from './../AgendaDocumentsUpload';
import { AgendaQuickTaskAdd } from './../AgendaQuickTaskAdd';
import { EditableTextArea } from 'components/shared/EditableTextArea';
import { findAgendaChildren } from 'helpers/agendas';

import { useFlashActions } from 'store/flashStoreContext';
import { useAgendasActions } from 'store/agendasStoreContext';
import { useMeetingStatus } from 'hooks/useMeetingStatus';

import { ReactComponent as TasksIcon } from 'images/icons/agenda-tasks.svg';
import { ReactComponent as DocumentsIcon } from 'images/icons/agenda-documents.svg';

import * as styles from './AgendaItem.styles';
import { utils } from 'styles';

const AgendaItem = memo(
  ({
    meetingId,
    agenda,
    tasks,
    contacts,
    meetingDuration,
    documents,
    agendas,
    theme,
    adminView,
    editView,
    meetingStatus,
    root,
    visibilityMap,
    onReorder,
    onPreview,
  }) => {
    const { setFlash } = useFlashActions();
    const { updateAgenda } = useAgendasActions();

    const intl = useIntl();

    const { isEditable, isInSession } = useMeetingStatus(meetingStatus);

    const [description, setDescription] = useState(agenda.description || '');

    useEffect(() => setDescription(agenda.description || ''), [agenda.description]);

    const updateDescription = useCallback((text) => {
      updateAgenda(meetingId, agenda.id, { description: text }).catch(setFlash);
    }, []);

    const children = findAgendaChildren(agendas, agenda);

    const relatedDocuments = documents
      .filter((document) => document.agenda_id === agenda.id)
      .sort((a, b) => a.position - b.position);

    const relatedTasks = tasks.filter((task) => task.agenda_id === agenda.id);

    const adminAccess = adminView && isEditable;
    const editAccess = editView && isEditable;
    const allowEdits = adminAccess || editAccess;

    const showTasks = allowEdits || relatedTasks.length > 0;
    const showDocuments = allowEdits || relatedDocuments.length > 0;
    const showToggle = adminView && isInSession;

    const isBlank = !adminAccess && !description.length && !relatedTasks.length && !relatedDocuments.length;

    const agendaTheme = theme || styles.themes[agenda.status];

    const childrenDuration = children.reduce((s, item) => s += item.duration_in_minutes, 0)

    const subItems = children.map((child, index) => {
      return (
        <AgendaSubItem
          key={`agenda-${agenda.id}sub-item-${index}`}
          meetingId={meetingId}
          agenda={child}
          tasks={tasks}
          theme={agendaTheme}
          documents={documents}
          agendas={agendas}
          adminView={adminView}
          editView={editView}
          visibilityMap={visibilityMap}
          meetingStatus={meetingStatus}
          root={root}
          onPreview={onPreview}
          onReorder={onReorder}
          contacts={contacts}
        />
      );
    });

    return (
      <>
        <AgendaItemWrapper
          meetingId={meetingId}
          agenda={agenda}
          subItems={subItems}
          isBlank={isBlank}
          meetingDuration={meetingDuration}
          contacts={contacts}
          isVisible={visibilityMap[agenda.id]}
          showToggle={showToggle}
          theme={agendaTheme}
          onReorder={onReorder}
          adminAccess={adminAccess}
          editAccess={editAccess}
          childrenDuration={childrenDuration}
        >
          <div css={styles.description}>
            <EditableTextArea
              value={description}
              placeholder={intl.formatMessage({ id: 'AgendaItem.descPlaceholder' })}
              onBlur={updateDescription}
              isDisabled={!allowEdits}
            />
          </div>

          {showTasks && (
            <>
              <div css={styles.block}>
                <TasksIcon />
                <span>
                  <FormattedMessage id='AgendaItem.relatedTasks' values={{ count: relatedTasks.length }} />
                </span>
                <hr />
              </div>

              {relatedTasks.map((task) => (
                <AgendaTaskListItem
                  key={`agenda-task-${task.id}`}
                  task={task}
                  meetingId={meetingId}
                  agendaId={agenda.id}
                  tasks={tasks}
                  isEditable={adminAccess}
                  root={root}
                />
              ))}

              <AgendaQuickTaskAdd
                meetingId={meetingId}
                agendaId={agenda.id}
                tasks={tasks}
                show={adminAccess}
                isEmpty={!relatedTasks.length}
              />
            </>
          )}

          {showDocuments && (
            <>
              <div css={styles.block}>
                <DocumentsIcon />
                <span>
                  <FormattedMessage id='AgendaItem.relatedDocs' values={{ count: relatedDocuments.length }} />
                </span>
                <hr />
              </div>

              <AgendaDocumentList
                meetingId={meetingId}
                agendaId={agenda.id}
                documents={relatedDocuments}
                isEditable={allowEdits}
                onPreview={onPreview}
              />

              <AgendaDocumentsUpload meetingId={meetingId} agendaId={agenda.id} show={allowEdits} root={root} />
            </>
          )}
        </AgendaItemWrapper>

        {agenda.root && <div css={utils.separator} />}
      </>
    );
  }
);

export { AgendaItem };
