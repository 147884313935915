/** @jsx jsx */
import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';

import * as styles from './VoteNotOpenedModal.styles';

const VoteNotOpenedModal = memo(({ voteName, voteStartDate, voteStartTime, voteEndDate, voteEndTime, show }) => {
  const hideNotOpenedModal = () => {
    return;
  };

  return (
    <Modal centered show={show} css={styles.notOpenedModal} onHide={hideNotOpenedModal}>
      <Modal.Header>
        <Modal.Title className='d-flex flex-row'>
          <div className='text-black'>
            <FormattedMessage id='VoteNotOpenedModal.notOpened' />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='fs-14'>
          <FormattedMessage id='VoteNotOpenedModal.willBeAvailable' values={{ name: voteName }} />
        </div>

        <div className='d-flex flex-row justify-content-between m-t-25 font-weight-semibold'>
          <div className='column'>
            <div className='fs-14 text-black m-b-10 text-left'>
              <FormattedMessage id='VoteNotOpenedModal.start' />
            </div>
            <div className='date d-flex flex-row center-center'>
              <div className='far fa-calendar-alt m-r-10' />
              <div>{voteStartDate}</div>
            </div>
            <div className='time d-flex flex-row center-center'>
              <div className='far fa-clock m-r-10' />
              <div>{voteStartTime}</div>
            </div>
          </div>

          <div className='column'>
            <div className='fs-14 text-black m-b-10 text-left'>
              <FormattedMessage id='VoteNotOpenedModal.end' />
            </div>
            <div className='date d-flex flex-row center-center'>
              <div className='far fa-calendar-alt m-r-10' />
              <div>{voteEndDate}</div>
            </div>
            <div className='time d-flex flex-row center-center'>
              <div className='far fa-clock m-r-10' />
              <div>{voteEndTime}</div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export { VoteNotOpenedModal };
