/** @jsx jsx */

import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';

import * as styles from './Placeholder.styles';

const Placeholder = memo(({ image, message }) => {
  return (
    <div css={styles.placeholder}>
      <div css={styles.wrapper}>
        <div css={styles.image}>{image}</div>
        <div css={styles.message}>{message}</div>
      </div>
    </div>
  );
});

export { Placeholder };
