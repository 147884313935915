import { useState, useCallback } from 'react';

const useFileViewer = () => {
  const [fileViewerContext, setDrawerContext] = useState({
    file: null,
    show: false,
  });

  const openFileViewer = useCallback((document) => {
    setDrawerContext({ file: document, show: true });
  }, []);

  const closeFileViewer = useCallback(() => {
    setDrawerContext({ file: null, show: false });
  }, []);

  return {
    fileViewerContext,
    openFileViewer,
    closeFileViewer,
  };
};

export { useFileViewer };
