import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import api from '../API';
import { SearchInput } from '../styles';
import { useSearchLazyQuery } from 'components/documents/data/queries/SearchQuery.js';

export const BrowserSearchInMobile = ({
  searchTerm,
  setSearchTerm,
  setIsSearching,
  setSearchResults,
  setSearchMode,
  currentRoot,
}) => {
  const [searchDocuments, { data }] = useSearchLazyQuery();

  useEffect(() => {
    if (data) {
      setIsSearching(false);
      setSearchResults(data.currentCompany);
      setSearchMode(true);
    }
  }, [data]);

  const handleSearch = (e) => {
    e.preventDefault();
    setIsSearching(true);
    searchDocuments({ variables: { name: searchTerm } });
  };

  const intl = useIntl();

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value || '');
  };

  return (
    <div className='input-group mb-2'>
      <div className='input-group-prepend'>
        <div className='input-group-text rounded-0'>
          <i className='far fa-search text-color-heading fs-12' />
        </div>
      </div>

      <form className='d-flex flex-grow-1' onSubmit={handleSearch}>
        <SearchInput
          type='text'
          className='form-control rounded-0'
          value={searchTerm}
          placeholder={intl.formatMessage({ id: 'BrowserSearchInMobile.fileName' })}
          onChange={handleSearchTermChange}
        />
      </form>
    </div>
  );
};
