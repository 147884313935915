/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import Switch from 'react-input-switch';
import { FormattedMessage } from 'react-intl';

import * as styles from './Products.styles';

const Packages = ({ products, brochure }) => {
  const [period, setPeriod] = useState('monthly');
  const handleSwitch = (period) => setPeriod(period);
  const reversedProducts = [].concat(products).reverse();

  return (
    <div className='col-md-10 m-auto'>
      <div className='d-flex justify-content-center align-items-center m-b-30'>
        <span
          style={{
            marginRight: 10,
            marginTop: -8,
            color: period === 'monthly' ? '#00BAC6' : 'grey',
            fontFamily: period === 'monthly' ? 'Gilroy-Bold' : 'Gilroy-Regular',
          }}
        >
          <FormattedMessage id='Packages.monthly' />
        </span>
        <Switch
          on='yearly'
          off='monthly'
          value={period}
          styles={{
            track: {
              backgroundColor: '#00BAC6',
            },
            trackChecked: {
              backgroundColor: '#00BAC6',
            },
            button: {
              backgroundColor: '#f6f6f6',
            },
          }}
          onChange={handleSwitch}
        />{' '}
        <span
          style={{
            marginLeft: 10,
            marginTop: -8,
            color: period === 'yearly' ? '#00BAC6' : 'grey',
            fontFamily: period === 'yearly' ? 'Gilroy-Bold' : 'Gilroy-Regular',
          }}
        >
          <FormattedMessage id='Packages.yearly' />
        </span>
      </div>
      <div className='row'>
        {reversedProducts
          .filter((f) => period.includes(f.interval))
          .map((product, index) => {
            return (
              <div className={`col-md-${brochure ? 4 : 6}`} key={`packages-${index}`}>
                <div
                  css={styles.productBox}
                  style={{
                    borderColor: 'white',
                    position: 'relative',
                    cursor: 'cursor',
                  }}
                >
                  <div className='c-dark text-center font-weight-bold fs-22 m-t-30'>
                    {product.name
                      .replace('yearly', '')
                      .replace('Yearly', '')
                      .replace('monthly', '')
                      .replace('Monthly', '')}
                  </div>
                  <p className='fs-38 font-weight-bold c-secondary text-center m-t-30 product-price'>
                    <span style={{ fontWeight: 'bolder' }}>{product.currency === 'usd' ? '$' : '£'}</span>
                    {Number(product.price).toFixed(2)}
                  </p>
                  <span className='fs-12 d-block text-center m-b-15' style={{ marginTop: -25, opacity: 0.65 }}>
                    <FormattedMessage id='Packages.exclVat' />
                  </span>
                  <p className='text-center fs-12 c-dark w-180 m-auto lh-16 m-b-15'>
                    {product.name.toLowerCase().includes('yearly') ? (
                      <FormattedMessage id='Packages.perYearly' />
                    ) : (
                      <FormattedMessage id='Packages.perMonthly' />
                    )}
                  </p>
                  <p className='p-l-20 p-r-20 text-center fs-13 m-b-20'>
                    {product.name.toLowerCase().includes('charity') && <FormattedMessage id='Packages.charityDesc' />}
                    {product.name.toLowerCase().includes('standard') && <FormattedMessage id='Packages.standardDesc' />}
                    {product.name.toLowerCase().includes('education') && <FormattedMessage id='Packages.eduDesc' />}
                  </p>
                  <div className='text-center m-b-10'>
                    <a className='btn btn-primary' href='/users/sign_up'>
                      <FormattedMessage id='Packages.signUp' />
                    </a>
                  </div>
                </div>
                {product.name.toLowerCase().includes('charity') ? (
                  <span className='fs-12 w-87 m-t-15 m-auto d-block text-center'>
                    <FormattedMessage id='Packages.verify' />
                  </span>
                ) : (
                  <span className='m-t-15 d-block' />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Packages;
