/** @jsxRuntime classic */
/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { LinkDecorator } from './LinkDecorator';
import ReactLinkify from 'react-linkify';

const Linkify = memo(({ text }) => {
  return <ReactLinkify componentDecorator={LinkDecorator}>{text}</ReactLinkify>;
});

export { Linkify };
