import React from 'react';
import { withIntl } from '../../../utils/v2/intl';
import { FormattedMessage } from 'react-intl';

import CalendarIcon from '../../shared/CalendarIcon';
import LockedContentPopup from '../../shared/LockedContentPopup';
import ButtonWithRedirect from '../../shared/ButtonWithRedirect';
import MobileSharedDropdown from '../../documents/shared/MobileSharedDropdown';
import { useEditable } from 'hooks/useEditable';
import { Row, ContentCard, DeleteBtnWrapper, TextOverflow } from './styles';

const Contents = ({
  editable,
  showable,
  editableLink,
  showableLink,
  time,
  day,
  month,
  fileName,
  numberOfSignatories,
  signatories,
  ownerName,
  status,
  statusText,
  bounced,
  bouncedText,
  destroy,
  delBtnText,
  confirmationText,
  redirectUrl,
  isLast,
}) => {
  const { isEditing: isPopupVisible, startEditing: showPopup, stopEditing: hidePopup } = useEditable();

  const handleClick = () => {
    if (editable || showable) {
      window.location.assign(editable ? editableLink : showableLink);
    } else {
      showPopup();
    }
  };

  return (
    <ContentCard isLast={isLast}>
      <div onClick={handleClick}>
        <LockedContentPopup contentTitle={fileName} show={isPopupVisible} onClose={hidePopup} />

        <Row className='d-flex align-items-center'>
          <div className='title'>
            <FormattedMessage id='Contents.date' />
          </div>
          <div className='row-content'>
            <CalendarIcon time={time} day={day} month={month} />
          </div>
        </Row>
        <Row className='d-flex align-items-center'>
          <div className='title'>
            <FormattedMessage id='Contents.docName' />
          </div>
          <TextOverflow className='row-content d-flex align-items-center'>
            <i className='file fad fa-file-pdf m-r-5 flex-shrink-0' />
            <span className='font-weight-regular text-black cursor-pointer text-truncate'>{fileName}</span>
          </TextOverflow>
        </Row>
        <Row className='d-flex align-items-center'>
          <div className='title'>
            <FormattedMessage id='Contents.signatories' />
          </div>
          <div className='row-content d-flex align-items-center'>
            {numberOfSignatories} / {signatories} <FormattedMessage id='Contents.signed' />
          </div>
        </Row>
        <Row className='d-flex align-items-center'>
          <div className='title'>
            <FormattedMessage id='Contents.owner' />
          </div>
          <div className='row-content d-flex align-items-center'>{ownerName}</div>
        </Row>
        <Row className='d-flex align-items-center'>
          <div className='title'>
            <FormattedMessage id='Contents.status' />
          </div>
          <div className='row-content d-flex align-items-center'>
            <span className={`badge badge-large badge-${status}`}>
              {statusText}
              {bounced && <span className='exclamation-mark-circle m-l-10' title={bouncedText} />}
            </span>
          </div>
        </Row>
      </div>
      <DeleteBtnWrapper>
        {destroy && (
          <>
            <div className='btn-group dropdown no-arrow d-flex align-items-center'>
              <a
                className='table-action-btn dropdown-toggle'
                data-toggle='dropdown'
                data-display='static'
                aria-expanded='false'
              >
                <svg width='2' height='12' viewBox='0 0 2 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <circle cx='1' cy='11' r='1' fill='#00031f' />
                  <circle cx='1' cy='6' r='1' fill='#00031f' />
                  <circle cx='1' cy='1' r='1' fill='#00031f' />
                </svg>
              </a>

              <div className='dropdown-menu dropdown-menu-right actions-dropdown'>
                <MobileSharedDropdown />

                <ButtonWithRedirect
                  buttonText={<span>{delBtnText}</span>}
                  confirmationText={confirmationText}
                  redirectUrl={redirectUrl}
                  dataMethod='delete'
                  buttonClassNames='dropdown-item'
                />
              </div>
            </div>
          </>
        )}
      </DeleteBtnWrapper>
    </ContentCard>
  );
};

export default withIntl(Contents);
