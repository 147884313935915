/** @jsx jsx */

import React, { memo, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';

import { CommentHeader } from './../CommentHeader';
import { CommentTarget } from './../CommentTarget';
import { TextAreaInput } from 'components/shared/TextAreaInput';
import { Expandable } from 'components/shared/Expandable';

import { useCommentsActions } from 'store/commentsStoreContext';
import { useFlashActions } from 'store/flashStoreContext';

import * as styles from './CommentForm.styles';

const CommentForm = memo(
  ({
    minuteId,
    commentId,
    parentId,
    author,
    value,
    selectedText,
    selectedImages,
    onShow,
    onSubmit,
    onCancel,
    textAreaRef,
    isFullHeight,
  }) => {
    const [text, setText] = useState(value || '');

    useEffect(() => setText(value || ''), [value]);

    useEffect(() => {
      onShow && onShow();
    }, []);

    const handleChange = (event) => {
      setText(event.target.value || '');
    };

    const resetText = () => {
      setText('');
    };

    const { attributes: contact } = author?.data || {};
    const { createComment, updateComment } = useCommentsActions();
    const { setFlash } = useFlashActions();

    const handleSubmit = () => {
      if (commentId) {
        const payload = { body: text };
        updateComment(minuteId, commentId, payload).then(onSubmit).catch(setFlash);
      } else {
        const payload = {
          parent_id: parentId,
          body: text,
          relatable: selectedText,
          data: { images: selectedImages },
        };
        createComment(minuteId, payload).then(resetText).then(onSubmit).catch(setFlash);
      }
    };

    const isSubmittable = text.length > 0;

    const textAreaInputStyles = [styles.textAreaInput.base, isFullHeight && styles.textAreaInput.fullHeight];

    const getButtonText = () => {
      if (value) {
        return 'Update';
      } else if (parentId) {
        return 'Reply';
      } else {
        return 'Add';
      }
    };

    const header = !parentId && (
      <>
        <CommentHeader contact={contact} />
        <Expandable>
          <CommentTarget target={selectedText} images={selectedImages} />
        </Expandable>
      </>
    );

    return (
      <div css={styles.form}>
        <div css={styles.header}>{header}</div>

        <div css={styles.replyWrapper}>
          <TextAreaInput
            ref={textAreaRef}
            value={text}
            onChange={handleChange}
            customWrapperStyles={styles.textAreaWrapper}
            customInputStyles={textAreaInputStyles}
            placeholder={`Add comment and press ${getButtonText()}`}
          />

          <div className='d-flex align-items-center m-t-10'>
            <button
              disabled={!isSubmittable}
              className={`btn btn-sm ${isSubmittable ? 'btn-primary' : 'btn-disabled'}`}
              onClick={handleSubmit}
            >
              {getButtonText()}
            </button>

            <button className='btn btn-inverted btn-sm m-l-15' onClick={onCancel}>
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export { CommentForm };
