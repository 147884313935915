import { gql, useMutation } from '@apollo/client';
import { VOTE_FRAGMENT } from './../fragments/VoteFragment';

const UPDATE_VOTERS = gql`
  mutation updateVoters($input: UpdateVotersInput!) {
    updateVoters(input: $input) {
      vote {
        ...VoteFields
      }
    }
  }
  ${VOTE_FRAGMENT}
`;

const useUpdateVotersMutation = (options) => useMutation(UPDATE_VOTERS, options);

export { useUpdateVotersMutation };
