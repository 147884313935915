/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { usePlacesWidget } from 'react-google-autocomplete';

import TextInput from '../TextInput';

const PlacesInput = ({ onChange, ...props }) => {
  const { ref } = usePlacesWidget({
    options: {
      types: ['geocode'],
    },
    onPlaceSelected: (place) => onChange(place.formatted_address),
  });

  return <TextInput onChange={onChange} ref={ref} {...props} />;
};

PlacesInput.propTypes = {
  onChange: PropTypes.func,
};

export default PlacesInput;
