import { css } from '@emotion/core';
import { colors } from 'styles';

export const bar = css`
  background: ${colors.white};
  border-bottom: 1px solid ${colors.brandBorder};
  position: fixed;
  left: 0;
  z-index: 5;
  right: 0;
`;

export const container = css`
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
`;

export const wrapper = css`
  ${container}
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const brand = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin: 0 10px 0 0;
    height: 28px;
  }
`;

export const voteContainer = css`
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  padding: 80px 20px 20px 20px;
  min-height: 100vh;

  .time-wrapper {
    padding: 10px 20px;
    height: 70px;
    background-color: ${colors.white};
    width: 210px;
    border 1px solid ${colors.cyan};

    .far {
      color: ${colors.cyan};
      position: relative;
      top: -1px;
      font-size: 16px;
      margin-right: 3px;
    }

    &.start-wrapper {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &.end-wrapper {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-left: none;
    }
  }

  .btn-pdf {
    text-transform: none !important;
  }
`;

export const meetingDrawerContainer = css`
  max-height: 93vh;
  overflow-y: auto;
  overflow-x: hidden;

  .vote-name {
    width: 90% !important;
  }
`;

export const meetingDrawer = css`
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  padding: 20px 40px;

  .panel {
    box-shadow: none;
  }
`;
