import React, { useEffect, createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { useGQLDataContext } from './GQLDataContext';
import { useMeetingContext } from './MeetingContext';
import { serializeZoomMeetingConfig } from 'utils/v2/serializers';

import { ZOOM_MEETING_CONFIG_DEFAULTS } from './constants';

const initialState = {
  zoomMeeting: { ...ZOOM_MEETING_CONFIG_DEFAULTS },
  zoomEdits: { ...ZOOM_MEETING_CONFIG_DEFAULTS },
};

const actionTypes = {
  UPDATE_ZOOM_EDITS: 'ZoomMeetingConfigContext.updateZoomEdits',
  UPDATE_ZOOM_DATA: 'ZoomMeetingConfigContext.updateZoomData',
  DISCARD_ZOOM_EDITS: 'ZoomMeetingConfigContext.discardZoomEdits',
};

const ZoomMeetingConfigContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.UPDATE_ZOOM_EDITS:
      return { ...state, zoomEdits: { ...state.zoomEdits, ...payload.updates } };
    case actionTypes.UPDATE_ZOOM_DATA:
      const serializedData = serializeZoomMeetingConfig(payload.zoomMeeting);

      return {
        ...state,
        zoomMeeting: serializedData,
        zoomEdits: serializedData,
      };
    case actionTypes.DISCARD_ZOOM_EDITS:
      return { ...state, zoomEdits: { ...state.zoomMeeting } };
  }
};

export const ZoomMeetingConfigContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { gqlData, id, getDataByPath } = useGQLDataContext();
  const {
    meeting: { meetingId },
  } = useMeetingContext();

  const updateZoomEdits = (fieldName, value) => {
    dispatch({ type: actionTypes.UPDATE_ZOOM_EDITS, payload: { updates: { [fieldName]: value } } });
  };

  const updateZoomData = (data) => {
    const { zoomMeeting } = data;
    dispatch({ type: actionTypes.UPDATE_ZOOM_DATA, payload: { zoomMeeting } });
  };

  useEffect(() => {
    if (gqlData) {
      if (id) {
        updateZoomData(getDataByPath('currentCompany', []));
      }
    }
  }, [gqlData]);

  const discardZoomEdits = () => {
    dispatch({ type: actionTypes.DISCARD_ZOOM_EDITS });
  };

  const { zoomEdits } = state;

  return (
    <ZoomMeetingConfigContext.Provider value={{ zoomEdits, updateZoomEdits, discardZoomEdits }}>
      {children}
    </ZoomMeetingConfigContext.Provider>
  );
};

ZoomMeetingConfigContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useZoomMeetingConfigContext = () => useContext(ZoomMeetingConfigContext);
