/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';

import { Input } from './../Input';

import * as styles from './TagInput.styles';

const TagInput = memo(
  ({
    name,
    icon,
    iconPosition,
    label,
    placeholder,
    isRequired,
    isDisabled,
    errorMessage,
    format,
    value,
    onChange,
    isReadOnly,
  }) => {
    let [isFocused, setIsFocused] = useState(false);
    let [tag, setTag] = useState('');

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    const deleteTag = (tag) => {
      const keptTags = value.filter((item) => item.name != tag.name);

      onChange(keptTags);
    };

    const addTag = (tag) => {
      const tagName = tag.trim();
      const isAdded = value.find((item) => item.name === tagName);

      setTag('');

      if (!isAdded) {
        onChange([...value, { name: tagName }]);
      }
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      addTag(tag);
    };

    const handleChange = (e) => {
      setTag(e.target.value || '');
    };

    const tags = value.map((tag, index) => {
      const handleDelete = deleteTag.bind(this, tag);

      return (
        <div key={index} css={styles.tag}>
          <div css={styles.name}>{tag.name}</div>

          {!isReadOnly && <i className='fa fa-times' css={styles.deleteIcon} onClick={handleDelete} />}
        </div>
      );
    });

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Input
            name={name}
            value={tag}
            label={label}
            icon={icon}
            iconPosition={iconPosition}
            onFocus={handleFocus}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            required={isRequired}
            disabled={isReadOnly}
            errorMessage={errorMessage}
          />
        </form>

        <div className='d-flex align-items-center flex-wrap'>{tags}</div>
      </div>
    );
  }
);

export { TagInput };
