import React from 'react';

import { TranslationContextProvider } from '../../contexts/v2/TranslationContext';

export const withIntl = (Component) => (props) =>
  (
    <TranslationContextProvider>
      <Component {...props} />
    </TranslationContextProvider>
  );
