import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const container = css`
  padding: 8px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  max-width: 200px;

  &:hover {
    background: ${colors.lightestPurple};
  }
`;

export const icon = css`
  flex-shrink: 0;
  margin-right: 7px;
`;

export const name = css`
  ${fonts.regular}
  font-size: 14px;
  line-height: 16px;
  color: ${colors.black};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
