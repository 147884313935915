/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { InView } from 'react-intersection-observer';

import NotificationsToggle from '../../notifications/Toggle';
import Spinner from 'components/v2/shared/Spinner/Spinner';
import ChatToggle from '../../conversations/Toggle';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Avatar from 'components/shared/Avatar';
import CompanyItem from './CompanyItem';
import TimeClock from 'components/toolbar/TimeClock/withApollo';
import useCurrentUserCompanies from '../../../hooks/useCurrentUserCompanies';
import { ReactComponent as ArrowRight } from 'images/icons/documents/arrow-right.svg';
import * as styles from './index.styles';
import { withApollo } from '../../../utils/v2/apollo';

const MobileMenu = ({
  unreadNotifications,
  unreadMessages,
  isVisible,
  close,
  userName,
  profileLink,
  currentUser,
  currentContact,
  signOutLink,
  currentCompany,
  primaryCompanyId,
}) => {
  const intl = useIntl();

  const { fetchCompanies, fetchMoreCompanies, data, loading } = useCurrentUserCompanies({
    skipFetch: true,
    name: '',
    context: 'MobileMenu',
  });

  useEffect(() => {
    if (isVisible) {
      fetchCompanies();
    }
  }, [isVisible]);

  const { isDesktop } = useWindowDimensions();
  if (isDesktop || !isVisible) return null;

  const contact = { initials: currentUser.initials };

  const onViewAllRedirect = () => {
    window.location.assign(`/${currentCompany.slug}/companies`);
  };

  const handleRedirectProfilePage = () => {
    window.location.assign(`/${currentCompany.slug}/profile`);
  };

  const handleInViewChange = (inView) => {
    const {
      currentUser: {
        companies: { pageInfo },
      },
    } = data;

    if (inView && pageInfo.hasNextPage && !loading) {
      fetchMoreCompanies(pageInfo.endCursor);
    }
  };

  const renderCompanies = () => {
    if (!data) {
      return (
        <div className='tw-relative tw-px-5 tw-py-8'>
          <Spinner />
        </div>
      );
    }

    const {
      currentUser: {
        companies: { edges },
      },
    } = data;

    return edges.map((company) => {
      const { id, name, slug, isPending, canBeManagedByCurrentUser } = company.node;
      return (
        <CompanyItem
          key={id}
          companyId={id}
          primaryCompanyId={primaryCompanyId}
          title={name}
          slug={slug}
          currentSlug={currentCompany.slug}
          pending_contact={isPending}
          manageable={canBeManagedByCurrentUser}
        />
      );
    });
  };

  const renderLoadMore = () => {
    if (!data || loading) {
      return null;
    }

    const {
      currentUser: {
        companies: { pageInfo },
      },
    } = data;

    return (
      pageInfo.hasNextPage && (
        <InView as='div' onChange={handleInViewChange} className='tw-relative tw-py-8'>
          <Spinner />
        </InView>
      )
    );
  };

  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        <div css={styles.board}>
          <p css={styles.boardTitle}>
            <FormattedMessage id='MobileMenu.companies' />
          </p>
          <div css={styles.companies}>
            {renderCompanies()}
            {renderLoadMore()}
          </div>
          <div css={styles.viewAll} onClick={onViewAllRedirect}>
            <FormattedMessage id='MobileMenu.viewAll' />
          </div>
        </div>
        <div css={styles.profileActionsBlock}>
          <div css={styles.nameAndAvatar} onClick={handleRedirectProfilePage}>
            <div>
              <div css={styles.userNameBlock}>
                <FormattedMessage
                  id='MobileMenu.viewAll'
                  values={{ name: <span css={styles.userName}>{userName}</span> }}
                />
              </div>
              <TimeClock />
            </div>
            <Avatar contact={contact} />
          </div>
          <div onClick={handleRedirectProfilePage} css={[styles.profileBlock, styles.link]}>
            <div css={styles.link}>
              <i className='fal fa-user m-r-5'></i>
              <span>
                <FormattedMessage id='MobileMenu.yourProfile' />
              </span>
            </div>
            <ArrowRight />
          </div>
          <div css={styles.actionsBlock}>
            <div>
              <div css={styles.toggleButton}>
                <NotificationsToggle
                  showBadge={unreadNotifications}
                  title={intl.formatMessage({ id: 'MobileMenu.notifications' })}
                />
              </div>
              <div css={styles.toggleButton}>
                <ChatToggle showBadge={unreadMessages} title={intl.formatMessage({ id: 'MobileMenu.chat' })} />
              </div>
            </div>
            <a rel='nofollow' data-method='delete' href={signOutLink} css={[styles.signOut, styles.link]}>
              <i className='fal fa-sign-out-alt m-r-5'></i>
              <span>
                <FormattedMessage id='MobileMenu.signOut' />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div css={styles.closeCont} onClick={close}>
        <div css={styles.close}></div>
      </div>
    </div>
  );
};

export default withApollo(MobileMenu);
