import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const wrapper = {
  base: css`
    box-shadow: 0px 5px 10px rgba(153, 183, 200, 0.1), 0px 6.4px 14.4px rgba(183, 183, 183, 0.13);
    border-radius: 2px;
    background: ${colors.white};
    display: none;
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-95%);
    width: 270px;
    z-index: var(--max-z-index);

    ${media.tablet} {
      z-index: 99999;
      transform: none;
      position: fixed;
      width: 100%;
      height: calc(100% - var(--nav-height));
      bottom: 0;
      top: var(--nav-height);
      left: 0;
      flex-direction: column;
      border: none;
      box-shadow: none;
    }
  `,

  visible: css`
    display: block;
    ${media.tablet} {
      display: flex;
    }
  `,
};

export const container = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:empty {
    height: 0;
  }
`;

export const backIcon = css`
  transform: rotate(180deg);
  margin-right: 5px;
`;

export const notifications = css`
  min-height: 200px;
  max-height: 200px;
  ${media.tablet} {
    max-height: none;
  }

  .ScrollbarsCustom-Content {
    height: 100%;
  }
`;

export const footer = css`
  ${fonts.bold}
  font-size: 12px;
  color: ${colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-top: 1px solid ${colors.notificationGrey};

  &:empty {
    padding: 0;
  }

  span {
    cursor: pointer;
  }
`;

export const placeholder = {
  base: css`
    ${container}
    display: none;
    flex-direction: column;
  `,

  visible: css`
    display: flex;
  `,
};

export const placeholderImage = css`
  width: 165px;
`;

export const placeholderText = css`
  color: ${colors.cyan};
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;
