import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal, { Sizes } from './../Modal';
import { useAttendeesContext } from 'contexts/v2/AttendeesContext';
import TextInput from '../TextInput';
import Button from '../Button';
import ContactCard from '../ContactCard';

const AssignChairModalWindow = ({ show, onHide }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [chairId, setChairId] = useState(null);

  const {
    getAllAttendees,
    edits: { chairmanId, attendeesIdMap },
    setChairmanId,
  } = useAttendeesContext();

  useEffect(() => {
    if (chairmanId) {
      setChairId(chairmanId);
    }
  }, [chairmanId]);

  const handleCardClick = (id) => () => setChairId(id);

  const handleSearchTermChange = (e) => setSearchTerm(e.target.value);

  const isChair = (id) => !!attendeesIdMap[id] && chairId === id;

  const resetState = () => {
    setChairId(chairmanId);
  };

  const handleSave = () => {
    setChairmanId(chairId);
    onHide();
  };

  const renderContacts = () => {
    const attendees = !!searchTerm
      ? getAllAttendees(true).filter(({ name }) => name.toLowerCase().match(searchTerm))
      : getAllAttendees(true);

    return attendees.map(({ name, id, ...rest }) => (
      <ContactCard
        key={`${id}`}
        label={name}
        {...rest}
        className='mb-2'
        variant='list'
        variantValueBackground={isChair(id) ? 'whiteBordered' : 'lightGrey'}
        status={isChair(id) ? 'chair' : 'non-chair'}
        onClick={handleCardClick(id)}
      />
    ));
  };

  return (
    <Modal isOpen={show} onClose={onHide} size={Sizes.lg} afterLeave={resetState}>
      <div className='tw-flex tw-flex-col tw-py-8 tw-px-12'>
        <div className='tw-mb-8 tw-text-xl tw-font-bold tw-text-black'>Assign person to be chair</div>

        <div className='tw-mb-2'>
          <TextInput placeholder='Type name' onChange={handleSearchTermChange} value={searchTerm} />
        </div>

        <div className='tw-h-[400px] tw-overflow-y-auto tw-overflow-x-hidden'>{renderContacts()}</div>

        <div className='tw-mt-5'>
          <Button isFullWidth onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AssignChairModalWindow.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default AssignChairModalWindow;
