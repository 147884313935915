import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Avatar from 'components/shared/Avatar';

const GroupDrawerTableRow = memo(({ contact, onDelete }) => {
  const handleDelete = onDelete.bind(this, contact);

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <Avatar contact={contact} />
          <div className='text-truncate m-l-10 text-black fs-14 font-weight-semibold'>
            <div className='d-none d-md-block'>{contact.name}</div>
            <div className='d-md-none'>{contact.initials}</div>
          </div>
        </div>
      </td>

      <td>
        <div className='text-truncate text-black fs-12'>{contact.email}</div>
      </td>

      <td>
        <div
          className='d-flex align-items-center flex-nowrap cursor-pointer justify-content-end'
          onClick={handleDelete}
        >
          <span className='m-r-5 fs-12 text-black'>
            <FormattedMessage id='GroupDrawerTableRow.delete' />
          </span>
          <i className='far fa-fw fa-trash-alt' />
        </div>
      </td>
    </tr>
  );
});

export { GroupDrawerTableRow };
