import React, { useState } from 'react';
import { FiChevronLeft, FiChevronDown, FiChevronUp, FiCheck } from 'react-icons/fi';
import Shape from 'components/v2/shared/Shape';
import Box from 'components/v2/shared/Box';
import Button from 'components/v2/shared/Button';
import List from 'components/v2/shared/List';

const InvoiceItem = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };

  const onOpen = () => {
    setIsOpen((state) => !state);
  };

  return (
    <Box isSelected onClick={() => {}} className='tw-mb-2 !tw-bg-gray-100'>
      <div className='tw-w-full'>
        <div className='tw-flex tw-w-full tw-justify-between'>
          <div>
            <Button
              font='semibold'
              onClick={() => {}}
              size='small'
              variant='transparent'
              className='!tw-pl-0 tw-font-sans tw-text-sm !tw-font-semibold !tw-text-black'
            >
              {new Date(data?.paid_at).toLocaleDateString('en-US', options)}
            </Button>
          </div>
          <div>
            <Button
              font='semibold'
              onClick={() => {}}
              size='small'
              variant='transparent'
              className='!tw-pl-0 tw-font-sans tw-text-sm !tw-font-semibold !tw-text-black'
            >
              ${data?.total} {data?.currency?.toUpperCase()}
            </Button>
          </div>
          <div>
            <Button
              font='medium'
              size='small'
              onClick={() => {
                window.open(data.receipt_link, '_blank');
              }}
              variant='transparent'
              className='tw-font-sans tw-text-xs !tw-font-medium tw-text-teal-500'
            >
              Download
            </Button>
          </div>
          <div>
            {isOpen ? (
              <FiChevronUp
                className='tw-h-5 tw-w-5 tw-cursor-pointer !tw-text-gray-700 tw-duration-100'
                onClick={() => onOpen()}
              />
            ) : (
              <FiChevronDown
                onClick={() => onOpen()}
                className={`tw-h-5 tw-w-5 tw-cursor-pointer !tw-text-gray-700 tw-duration-100`}
              />
            )}
          </div>
        </div>
        {isOpen && (
          <div className='tw-mt-4 tw-flex tw-w-full'>
            <div>
              <Button
                font='semibold'
                onClick={() => {}}
                size='small'
                variant='transparent'
                className='!tw-pl-0 tw-font-sans tw-text-sm !tw-font-medium !tw-text-black'
              >
                Ref: {data?.number}
              </Button>
            </div>
            <div>
              <div className='-tw-mt-1 tw-grid tw-w-full tw-grid-flow-col tw-grid-cols-4 tw-py-0 tw-pl-4'>
                <div className='tw-col-span-3 tw-flex tw-items-center'>
                  <div className='tw-relative tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>
                    <Shape variant='circle' className='tw-relative tw-h-4 tw-w-4 !tw-bg-green-500' />
                    <FiCheck className='tw-absolute tw-top-1.5 tw-left-1.5 tw-text-xs tw-text-black' />
                  </div>
                  <div className='tw-px-2 tw-text-sm tw-font-semibold'>
                    <div className='tw-font-sans tw-text-sm tw-font-medium tw-text-black'>Paid</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Box>
  );
};

const InvoiceHistory = ({ onClick, currentUser, invoiceList }) => {
  const onPreviousPage = () => {
    onClick(false);
  };
  const [invoices, setInvoices] = useState({
    pageTotal: 18,
    perPage: 10,
    page: 1,
    data: invoiceList.slice(0, 10),
  });

  const handlePaginationClick = (page) => {
    setInvoices((prev) => {
      const start = prev.perPage * page - prev.perPage;
      const end = prev.perPage * page;

      return {
        ...prev,
        page,
        data: invoiceList.slice(start, end),
      };
    });
  };

  return (
    <>
      <div className='tw-relative tw-ml-6 sm:tw-ml-0'>
        <div className='tw-w-full'>
          <FiChevronLeft
            className='tw-purple-500 tw-absolute tw-top-4 -tw-left-2 tw-cursor-pointer tw-text-sm'
            onClick={onPreviousPage}
          />
          <Button
            font='medium'
            onClick={onPreviousPage}
            size='large'
            variant='transparent'
            className='tw-pt-4 tw-font-sans tw-text-xs !tw-font-medium !tw-text-purple-500'
          >
            Billing & Invoices
          </Button>
          <div className='tw-font-sans tw-text-xl tw-font-bold tw-text-black'>Invoice history</div>
        </div>
      </div>
      <div className='tw-mt-5'>
        <div className='tw-block tw-w-full sm:tw-flex'>
          <div className='tw-mt-2 tw-font-sans tw-text-sm tw-font-semibold tw-text-black'>Invoices</div>
          <div className='tw-ml-auto tw-flex tw-justify-between sm:tw-justify-center'>
            <div className='tw-pt-2 tw-font-sans tw-text-xs tw-font-medium tw-text-black sm:tw-px-5'>
              Sent to {currentUser.email}
            </div>
          </div>
        </div>
      </div>
      <div className='tw-mt-5'>
        <List
          items={invoices.data}
          paginated
          page={invoices.page}
          perPage={invoices.perPage}
          pageTotal={invoices.pageTotal}
          render={(props) => <InvoiceItem {...props} />}
          onPaginationClick={handlePaginationClick}
        />
      </div>
    </>
  );
};
export default InvoiceHistory;
