import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const Variants = {
  Purple: 'purple',
  Teal: 'teal',
};
const Box = ({ isSelected = false, isHoverable = false, variant = Variants.Purple, children, className, onClick }) => (
  <div
    onClick={onClick}
    className={clsx(
      'tw-flex tw-items-center tw-justify-between tw-gap-2',
      'tw-box-border tw-rounded',
      'tw-text-sm tw-font-medium tw-text-black',
      'tw-px-6 tw-py-4',
      {
        'tw-cursor-pointer': isHoverable,
        'hover:tw-bg-purple-100': isHoverable && !isSelected,
        'hover:tw-opacity-80': isHoverable && isSelected,
        'tw-bg-gray-100': !isSelected,
        'tw-bg-purple-200': variant === Variants.Purple && isSelected,
        'tw-bg-teal-200': variant === Variants.Teal && isSelected,
      },
      className
    )}
  >
    {children}
  </div>
);

Box.propTypes = {
  isSelected: PropTypes.bool,
  isHoverable: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(Variants)),
};

export default Box;
