import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Label from '../Label';
import InputError from '../InputError';

const TextAreaInput = forwardRef(
  (
    {
      id,
      name,
      label,
      placeholder,
      isRequired,
      isDisabled,
      isReadOnly,
      isInvalid,
      errorMessage,
      value,
      onChange,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <Label text={label} isInvalid={isInvalid || !!errorMessage} />
        <div>
          <textarea
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            required={isRequired}
            disabled={isDisabled}
            readOnly={isReadOnly}
            ref={ref}
            className={clsx(
              'tw-box-border tw-w-full tw-resize-none tw-rounded-sm tw-border tw-border-solid tw-py-3.5 tw-px-5 tw-font-sans tw-text-sm tw-outline-none placeholder:tw-text-gray-500',
              {
                'tw-border-gray-500 focus:tw-border-teal-500': !isInvalid && !errorMessage,
                'tw-cursor-not-allowed tw-opacity-30': isDisabled,
                'tw-border-red-400 placeholder:tw-text-red-400': errorMessage || isInvalid,
              },
              className
            )}
            {...props}
          ></textarea>
          <InputError message={errorMessage} className='tw-my-3' />
        </div>
      </>
    );
  }
);

TextAreaInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default TextAreaInput;
