import { gql, useQuery } from '@apollo/client';
import { ZOOM_MEETING_TOPIC_FRAGMENT } from 'data/v2/fragments/ZoomMeetingTopicFragment';

const CONTACT_ZOOM_MEETING_TOPICS_QUERY = gql`
  query {
    currentContact {
      id

      zoomMeetingTopics {
        ...ZoomMeetingTopicFields
      }
    }
  }
  ${ZOOM_MEETING_TOPIC_FRAGMENT}
`;

const useContactZoomMeetingTopicsQuery = (options) => useQuery(CONTACT_ZOOM_MEETING_TOPICS_QUERY, options);

export { CONTACT_ZOOM_MEETING_TOPICS_QUERY, useContactZoomMeetingTopicsQuery };
