import React from 'react';
import PropTypes from 'prop-types';

import InviteContactModalWindow from './../InviteContactModalWindow';
import Button, { Variants } from './../Button';
import ContactCard from '../ContactCard/ContactCard';

import SelectInput from 'components/v2/shared/SelectInput';

const ContactSelectInput = ({ hasModal, ...props }) => {
  const { value, onClear } = props;

  if (value) {
    return <ContactCard {...value} onClear={onClear} />;
  }

  const renderFooter = ({ showModal }) => {
    if (!hasModal) {
      return null;
    }

    return (
      <Button onClick={showModal} isFullWidth variant={Variants.Teal}>
        Add new contact
      </Button>
    );
  };

  const renderModal = ({ isModalVisible, closeModal, onDataAdded }) => {
    if (!hasModal) {
      return null;
    }

    return <InviteContactModalWindow show={isModalVisible} onHide={closeModal} onDataAdded={onDataAdded} />;
  };

  return (
    <SelectInput
      isFilterable
      {...props}
      optionVariant='contact'
      renderFooter={renderFooter}
      renderModal={renderModal}
    />
  );
};

ContactSelectInput.propTypes = {};

export default ContactSelectInput;
