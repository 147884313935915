import { gql, useQuery } from '@apollo/client';
import { NOTIFICATION_FRAGMENT } from './../fragments/NotificationFragment';
import { CONTACT_FRAGMENT } from 'data/fragments/ContactFragment';

const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription NotificationsSubscription {
    notificationAdded {
      ...NotificationFields

      contact {
        ...ContactFields
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
  ${CONTACT_FRAGMENT}
`;

export { NOTIFICATIONS_SUBSCRIPTION };
