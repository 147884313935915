import { gql, useQuery } from '@apollo/client';
import { TIMEZONE_FRAGMENT } from 'data/v2/fragments/TimezoneFragment';
import { CONTACT_FRAGMENT } from 'data/v2/fragments/ContactFragment';
import { ACCESS_ROLE_FRAGMENT } from 'data/v2/fragments/AccessRoleFragment';

const MEETING_SETUP_QUERY = gql`
  query MeetingSetupQuery {
    currentCompany {
      id

      accessRoles {
        ...AccessRoleFields
      }

      contacts {
        ...ContactFields
      }

      timezones {
        ...TimezoneFields
      }
    }
  }
  ${ACCESS_ROLE_FRAGMENT}
  ${CONTACT_FRAGMENT}
  ${TIMEZONE_FRAGMENT}
`;

const useMeetingSetupQuery = (options) => useQuery(MEETING_SETUP_QUERY, options);

export { MEETING_SETUP_QUERY, useMeetingSetupQuery };
