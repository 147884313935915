/** @jsx jsx */

import React, { memo, forwardRef } from 'react';
import { css, jsx } from '@emotion/core';

import * as styles from './Drawer.styles';

const Drawer = memo(
  forwardRef(({ drawerId, onClose, ...props }, ref) => {
    return (
      <div ref={ref} id={drawerId} css={[styles.drawer, props.styles]} onAnimationEnd={props.onAnimationEnd}>
        {onClose && <i css={styles.close} onClick={onClose} className='far fa-times drawer__close' />}
        {props.children}
      </div>
    );
  })
);

export { Drawer };
