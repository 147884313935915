import { css } from '@emotion/core';
import { colors } from 'styles';

export const loading = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;

export const viewport = (pointerMode) => css`
  overflow: auto;
  padding: 25px 30px;
  cursor: ${pointerMode ? 'pointer' : 'default'};
`;
