import { useState, useEffect } from 'react';
import { useResolutionQuery } from './../data/queries/ResolutionQuery';

const useFetchResolutionDocuments = ({ resolutionId }) => {
  const [updatableData, setUpdatableData] = useState({
    isLoading: false,
    resolutionDocuments: [],
  });

  const updateResolutionDocumentsData = (updates) => setUpdatableData((state) => ({ ...state, ...updates }));

  const { loading: isResolutionLoading, data: resolutionData } = useResolutionQuery({
    variables: { id: resolutionId },
  });

  useEffect(() => setIsLoading(), [isResolutionLoading]);
  useEffect(() => setResolutionData(), [resolutionData]);

  const setIsLoading = () => {
    if (!isResolutionLoading) return;

    updateResolutionDocumentsData({ isLoading: true });
  };

  const setResolutionData = () => {
    if (!resolutionData) return;

    const { resolution } = resolutionData.currentCompany;

    updateResolutionDocumentsData({
      isLoading: false,
      resolutionDocuments: resolution.resolutionDocuments,
    });
  };

  return {
    ...updatableData,
  };
};

export { useFetchResolutionDocuments };
