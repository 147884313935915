const getAttributes = (object) => {
  return isIterable(object)
    ? object.map((item) => item.data.attributes)
    : object.data
    ? object.data.attributes
    : object.data;
};

const isIterable = (item) => {
  return Symbol.iterator in Object(item);
};

export { getAttributes };
