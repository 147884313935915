import { gql, useMutation } from '@apollo/client';

const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationAsRead($input: MarkNotificationAsReadInput!) {
    markNotificationAsRead(input: $input) {
      notification {
        id
        read
      }

      showBadge
    }
  }
`;

const useMarkNotificationAsReadMutation = (options) => useMutation(MARK_NOTIFICATION_AS_READ, options);

export { useMarkNotificationAsReadMutation };
