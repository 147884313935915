import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px auto;
  max-width: 800px;
  padding: 0 15px;
  ${media.mobile} {
    flex-direction: column;
  }
`;

export const flex = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
`;

export const icon = css`
  flex-shrink: 0;
  margin: 10px 15px 10px 0;
`;

export const title = css`
  ${fonts.semiBold}
  color: ${colors.cyan};
  font-size: 16px;
`;

export const instructions = css`
  color: ${colors.black};
  font-size: 14px;
`;
