import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ClipLoader from 'react-spinners/ClipLoader';
import theme from 'theme.config.js';

const LoadingIndicator = ({ isVisible = false, size = 16, className }) => (
  <div className={clsx('tw-flex tw-items-center tw-justify-center tw-p-2 empty:tw-hidden', className)}>
    <ClipLoader loading={isVisible} size={size} color={theme.colors.teal['500']} />
  </div>
);

LoadingIndicator.propTypes = {
  isVisible: PropTypes.bool,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default LoadingIndicator;
