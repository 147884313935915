import { createStore } from './createStore';

import { getAttributes } from 'helpers/getAttributes';
import { trackPromise } from 'react-promise-tracker';

import { formatDate, formatTime } from 'helpers/formatDateTime';
import { roundUpHours } from 'helpers/roundUpHours';
import { getErrors } from 'helpers/getErrors';

import { useTasksActions } from 'store/tasksStoreContext';
import { useAgendasActions } from 'store/agendasStoreContext';
import { useDocumentsActions } from 'store/documentsStoreContext';
import { useMinutesActions } from 'store/minutesStoreContext';

import normalize from 'jsonapi-normalizer';
import axios from 'axios';
import moment from 'moment';

const companyId = getCompanySlug();

const meetingDate = moment().parseZone();
const meetingStart = roundUpHours(meetingDate);
const meetingEnd = meetingStart.clone().add(15, 'minute');

const DEFAULTS = {
  title: '',
  status: 'draft',
  location: '',
  meeting_url: '',
  chairman_id: '',
  calendar_contact_id: '',
  meeting_date: meetingDate,
  meeting_start_time: meetingStart,
  meeting_end_time: meetingEnd,
  meeting_type_id: null,
  meeting_type_name: '',
  contacts: [],
  contact_meetings: [],
  meeting: [],
  valid: false,
  owner_name: '',
  timezone_offset: 0,
  votes: [],
  voter: null,
  directory_id: null,
  timezone: '',
  language: null,
  config: 'conference',
  zoom_meeting_id: null,
  zoom_meeting: {
    agenda: null,
    password: null,
    host_video: true,
    participant_video: true,
    waiting_room: false,
    join_before_host: true,
    mute_upon_entry: true,
    auto_recording: 'local',
  },
};

const initialState = {
  meeting: { ...DEFAULTS },
  edits: { ...DEFAULTS },
  errors: {},
  loaded: false,
  isSubmit: false,
};

const actionTypes = {
  SET: 'meeting.set',
  DELETE: 'meeting.delete',
  SET_EDITS: 'meeting.edits.set',
  SET_ZOOM_EDITS: 'meeting.zoom_edits.set',
  SET_ERRORS: 'meeting.errors.set',
  DISCARD_EDITS: 'meeting.edits.discard',
};

const inviteParams = (params) => {
  const contactMeetingAttributes = params.contact_meetings.map((item) => {
    const { contact, status, ...rest } = item;
    return { ...rest, id: item.id, contact_id: contact.id };
  });

  const payload = {
    status: params.status,
    email_message: params.email_message,
    chairman_id: params.chairman_id,
    calendar_contact_id: params.calendar_contact_id,
    contact_meetings_attributes: contactMeetingAttributes,
  };

  return { meeting: payload };
};

const convertToPayload = (params) => {
  const {
    meeting_date,
    meeting_start_time,
    meeting_end_time,
    contact_meetings,
    email_message,
    chairman_id,
    zoom_meeting,
    meeting_url,
    ...rest
  } = params;

  const meetingDate = formatDate(meeting_date);
  const meetingStartTime = formatTime(meeting_start_time);
  const meetingEndTime = formatTime(meeting_end_time);
  const meetingUrl = params.config == 'chime' || params.config == 'zoom' ? '' : params.meeting_url;

  let zoomParams = {};

  if (params.config == 'zoom' && !params.zoom_meeting_id) {
    zoomParams = { zoom_meeting: params.zoom_meeting.data?.attributes || params.zoom_meeting };
  } else if (params.zoom_meeting_id) {
    zoomParams = { zoom_meeting_id: params.zoom_meeting_id };
  } else {
    zoomParams = { meeting_url: params.meeting_url };
  }

  const payload = {
    ...rest,
    meeting_date: meetingDate,
    meeting_start_time: meetingStartTime,
    meeting_end_time: meetingEndTime,
    meeting_url: meetingUrl,
    ...zoomParams,
    ...rest,
  };

  return { meeting: payload };
};

const api = {
  loadMeeting: (meetingId) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}.json`;
    const promise = axios.get(apiURL).then((response) => response.data);

    return trackPromise(promise);
  },

  createMeeting: (params) => {
    const apiURL = `/api/${companyId}/v1/meetings.json`;
    const payload = convertToPayload(params);

    const promise = axios.post(apiURL, payload).then((response) => response.data);

    return trackPromise(promise);
  },

  updateMeeting: (meetingId, params) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}.json`;
    const payload = convertToPayload(params);

    const promise = axios.patch(apiURL, payload).then((response) => response.data);

    return trackPromise(promise);
  },

  inviteAttendees: (meetingId, params) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/attendees.json`;
    const payload = inviteParams(params);

    const promise = axios.patch(apiURL, payload).then((response) => response.data);

    return trackPromise(promise);
  },

  deleteMeeting: (meetingId) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/update_status.json`;
    const payload = { meeting: { status: 'archived' } };

    const promise = axios
      .patch(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  startMeeting: (meetingId) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/update_status.json`;
    const payload = { meeting: { status: 'in_session' } };

    const promise = axios
      .patch(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },

  sendMeetingNotice: (meetingId, contactId) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/send_notice.json`;
    const payload = { contact_id: contactId };

    return axios.post(apiURL, payload).then((response) => response.data);
  },

  publishMeeting: (meetingId) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/publish.json`;

    const promise = axios.post(apiURL).then((response) => response.data);

    return trackPromise(promise);
  },

  generateSmartPack: (meetingId) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/generate_smart_pack.json`;

    const promise = axios.post(apiURL).then((response) => response.data);

    return trackPromise(promise);
  },

  endMeeting: (meetingId) => {
    const apiURL = `/api/${companyId}/v1/meetings/${meetingId}/update_status.json`;
    const payload = { meeting: { status: 'completed' } };

    const promise = axios
      .patch(apiURL, payload)
      .then((response) => response.data)
      .then(getAttributes);

    return trackPromise(promise);
  },
};

const useMeetingActions = () => {
  const dispatch = useMeetingDispatch();

  const { setTasks } = useTasksActions();
  const { setAgendas } = useAgendasActions();
  const { setDocuments } = useDocumentsActions();
  const { setMinutesContext } = useMinutesActions();

  const arrangeContext = (meetingId, response) => {
    const data = normalize(response);

    const meeting = data.entities.meeting[meetingId];
    const tasks = Object.values(data.entities.task || []);
    const agendas = Object.values(data.entities.agenda || []);
    const documents = Object.values(data.entities.agenda_document || []);

    setMeeting(meeting);
    setTasks(tasks);
    setAgendas(agendas);
    setDocuments(documents);
    setMinutesContext(meeting.minute_id, response);

    return meeting;
  };

  const loadMeeting = (meetingId) => {
    return api.loadMeeting(meetingId).then((response) => {
      return arrangeContext(meetingId, response);
    });
  };

  const sendMeetingNotice = (meetingId, contactId) => {
    return api.sendMeetingNotice(meetingId, contactId);
  };

  const startMeeting = (meetingId) => {
    return api.startMeeting(meetingId).then(setMeeting);
  };

  const endMeeting = (meetingId) => {
    return api.endMeeting(meetingId).then(setMeeting);
  };

  const deleteMeeting = (meetingId) => {
    return api.deleteMeeting(meetingId).then(setMeeting);
  };

  const createMeeting = (params) => {
    return api.createMeeting(params).then((response) => {
      return arrangeContext(response.data.attributes.id, response);
    });
  };

  const publishMeeting = (meetingId) => {
    return api.publishMeeting(meetingId).then((response) => {
      return arrangeContext(meetingId, response);
    });
  };

  const generateSmartPack = (meetingId) => {
    return api.generateSmartPack(meetingId).then((response) => {
      return arrangeContext(meetingId, response);
    });
  };

  const updateMeeting = (meetingId, params) => {
    return api.updateMeeting(meetingId, params).then((response) => {
      return arrangeContext(meetingId, response);
    });
  };

  const inviteAttendees = (meetingId, params) => {
    return api.inviteAttendees(meetingId, params).then((response) => {
      return arrangeContext(meetingId, response);
    });
  };

  const setMeeting = (params) => {
    dispatch({ type: actionTypes.SET, payload: params });
  };

  const setErrors = (payload) => {
    const messages = getErrors(payload);

    dispatch({ type: actionTypes.SET_ERRORS, payload: messages });
  };

  const setEdits = (key, value) => {
    dispatch({ type: actionTypes.SET_EDITS, payload: { key, value } });
  };

  const setZoomEdits = (key, value) => {
    dispatch({ type: actionTypes.SET_ZOOM_EDITS, payload: { key, value } });
  };

  const discardEdits = (payload) => {
    dispatch({ type: actionTypes.DISCARD_EDITS });
  };

  return {
    arrangeContext,
    setMeeting,
    loadMeeting,
    startMeeting,
    endMeeting,
    createMeeting,
    updateMeeting,
    inviteAttendees,
    deleteMeeting,
    setErrors,
    setEdits,
    setZoomEdits,
    discardEdits,
    sendMeetingNotice,
    publishMeeting,
    generateSmartPack,
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;

  let contact_meetings = [];

  switch (type) {
    case actionTypes.SET:
      const contacts = getAttributes(payload.contacts);

      if (payload.contact_meetings) {
        contact_meetings = getAttributes(payload.contact_meetings).map((item) => ({
          ...item,
          id: item.id,
          contact: getAttributes(item.contact),
        }));
      } else {
        contact_meetings = contacts.map((item) => ({ contact: item }));
      }

      if (payload.id) {
        payload.meeting_start_time = payload.start_time_with_offset;
        payload.meeting_end_time = payload.end_time_with_offset;
      }

      const zoom_meeting = payload.zoom_meeting ? getAttributes(payload.zoom_meeting) : DEFAULTS['zoom_meeting'];

      return {
        ...state,

        meeting: {
          ...state.meeting,
          ...payload,
          contacts: contacts,
          contact_meetings: contact_meetings,
          zoom_meeting: zoom_meeting,
        },

        edits: {
          ...state.edits,
          ...payload,
          contacts: contacts,
          contact_meetings: contact_meetings,
        },

        loaded: true,
        isSubmit: false,
      };

    case actionTypes.SET_EDITS:
      return {
        ...state,

        edits: {
          ...state.edits,
          [payload.key]: payload.value,
        },

        errors: {
          ...state.errors,
          [payload.key]: null,
        },

        isSubmit: false,
      };

    case actionTypes.SET_ZOOM_EDITS:
      return {
        ...state,

        edits: {
          ...state.edits,
          zoom_meeting: {
            ...state.edits.zoom_meeting,
            [payload.key]: payload.value,
          },
        },

        isSubmit: false,
      };

    case actionTypes.SET_ERRORS:
      return {
        ...state,
        errors: payload,
        isSubmit: true,
      };

    case actionTypes.DISCARD_EDITS:
      return {
        ...state,

        isSubmit: false,
        errors: {},
        edits: { ...state.meeting },
      };
  }
};

const [MeetingProvider, useMeetingStore, useMeetingDispatch] = createStore(reducer, initialState);

export { MeetingProvider, useMeetingStore, useMeetingActions };
