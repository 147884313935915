import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const padded = css`
  padding: 35px 40px 0;
  ${media.mobile} {
    padding: 15px 15px 0;
  }
`;

export const overlay = css`
  background: rgba(255, 255, 255, 0.72);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const container = css`
  background: ${colors.white};
  border-radius: 8px;
  display: flex;
  box-shadow: 0px 4px 50px rgba(54, 71, 98, 0.12);
  align-items: center;
  padding: 16px 17px;
`;

export const conferenceWrapper = css`
  padding-left: 25px !important;
  ${media.mobile} {
    padding-left: 0 !important;
  }
  label {
    font-size: 14px;
    color: ${colors.black};
  }
`;

export const radioButtons = css`
  font-size: 14px;
  color: ${colors.black};

  .radio {
    label {
      &::after {
        background-color: ${colors.cyan};
      }
    }
  }

  input[type='radio']:checked + label {
    &::after {
      background-color: ${colors.cyan};
    }
  }

  input[type='radio']:checked + label {
    &::before {
      border-color: ${colors.cyan};
    }
  }
`;

export const copyIcon = css`
  margin-left: 10px;
  height: 44px;
  width: 44px;

  .fa-copy {
    font-size: 17px;
  }

  ${media.mobile} {
    margin-left: 0px;
    margin-top: 10px;
  }
`;

export const colBigLeft = css`
  padding-right: 25px !important;
  padding-bottom: 0 !important;
  ${media.tablet} {
    padding-right: 10px !important;
    padding-bottom: 20px !important;
  }

  ${media.mobile} {
    padding-right: 0 !important;
  }
`;

export const meetTypeInput = css`
  ${media.tablet} {
    flex-grow: 1;
    padding-right: 10px !important;
    padding-bottom: 20px !important;
  }

  ${media.mobile} {
    padding-right: 0 !important;
  }
`;

export const meetDateInput = css`
  ${media.tablet} {
    flex-grow: 1;
  }
`;

export const twoInputsWrapper = css`
  ${media.tablet} {
    flex-wrap: wrap;
  }
`;
