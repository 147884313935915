/** @jsx jsx */

import React, { memo, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Spinner } from 'components/shared/Spinner';
import { MeetingDetails } from './../MeetingDetails';
import { StatusBadge } from 'components/shared/StatusBadge';
import { AlertPopup } from 'components/shared/AlertPopup';
import { AgendaTasksTabs } from './../AgendaTasksTabs';

import { useMeetingActions, useMeetingStore } from 'store/meetingStoreContext';
import { useFlashActions, useFlashStore } from 'store/flashStoreContext';

import * as styles from './EditAgendaView.styles';

const EditAgendaView = memo(({ meetingId, contactId, contactTimezone, contactTimezoneOffset, root }) => {
  const { setFlash, discardFlash } = useFlashActions();
  const { loadMeeting } = useMeetingActions();
  const { flash } = useFlashStore();
  const { meeting, loaded } = useMeetingStore();

  const intl = useIntl();

  useEffect(() => {
    loadMeeting(meetingId).catch(setFlash);
  }, []);

  if (!loaded) {
    return <Spinner show />;
  }

  return (
    <Container fluid='lg'>
      <Row>
        <Col>
          <StatusBadge
            status={meeting.status}
            text={
              meeting.id
                ? intl.formatMessage({ id: 'EditAgendaView.meeting' })
                : intl.formatMessage({ id: 'EditAgendaView.createMeeting' })
            }
          />
          <Spinner />

          <AlertPopup
            show={!!flash}
            popupType='failure'
            headerText={intl.formatMessage({ id: 'EditAgendaView.failure' })}
            onClose={discardFlash}
          >
            <div className='text-black fs-14'>{flash}</div>
          </AlertPopup>

          <MeetingDetails
            currentContactId={contactId}
            contactTimezone={contactTimezone}
            contactTimezoneOffset={contactTimezoneOffset}
          />

          <div css={styles.panel}>
            <AgendaTasksTabs
              meetingId={meeting.id}
              currentContactId={contactId}
              meetingDuration={meeting.duration}
              contacts={meeting.contacts}
              meetingStatus={meeting.status}
              editView
              root={root}
            />
          </div>

          <div id='document-preview-modal' className='modal preview-mode' />
        </Col>
      </Row>
    </Container>
  );
});

export { EditAgendaView };
