import { useState } from 'react';
import { useResendVoteInviteMutation } from './../data/mutations/ResendVoteInviteMutation';

const useResendVoteInvite = () => {
  const update = (cache, { data: { resendVoteInvite } }) => {
    const { vote } = resendVoteInvite;
  };

  const [mutate] = useResendVoteInviteMutation({ update });

  const resendVoteInvite = (payload) => mutate({ variables: { input: payload } });

  return {
    resendVoteInvite,
  };
};

export { useResendVoteInvite };
