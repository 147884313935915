/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import Avatar from 'components/shared/Avatar';
import moment from 'moment';

import * as styles from './CommentHeader.styles';

const CommentHeader = memo(({ contact, comment, children }) => {
  if (!contact) {
    return null;
  }

  const momentObj = moment(comment?.created_at).parseZone();
  const formatted = momentObj.clone().format('hh:mm A');
  const createdAgo = momentObj.clone().fromNow();
  const createdAt = `${formatted}, ${createdAgo}`;

  return (
    <div css={styles.author}>
      <Avatar contact={contact} />

      <div css={styles.heading}>
        <div css={styles.name}>{contact.name}</div>

        <div css={styles.timestamps}>{createdAt}</div>
      </div>

      {children}
    </div>
  );
});

export { CommentHeader };
