import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CompanyItem from '../CompanyItem';
import LoadingIndicator from 'components/v2/shared/LoadingIndicator';
import StateBadge from 'components/v2/shared/StateBadge';
import { FormattedMessage } from 'react-intl';
import { intl } from 'contexts/v2/TranslationContext';
import { useInView } from 'react-intersection-observer';

const CompanyList = ({
  companies = [],
  currentCompany = {},
  subcommittees = [],
  isLoading,
  onFetchMore = () => {},
}) => {
  const { ref: fetchMoreRef, inView: fetchMoreInView } = useInView();

  useEffect(() => {
    if (fetchMoreInView) {
      onFetchMore && onFetchMore();
    }
  }, [fetchMoreInView]);

  if (!isLoading && !companies.length) {
    return (
      <div className='tw-px-8 tw-py-4 tw-text-sm tw-font-medium tw-text-black'>
        {intl.formatMessage({ id: 'CompanyList.noFound' })}
      </div>
    );
  }

  const renderSubcommittees = (subcommittees = []) => {
    return (
      <div className=''>
        {subcommittees.map((subcommittee) => (
          <div className='tw-relative tw-ml-1 tw-px-2' key={subcommittee.id}>
            <a href={`/${subcommittee.slug}`} className='fs-12 c-secondary'>
              {subcommittee.name}
            </a>
          </div>
        ))}
      </div>
    );
  };

  const renderCompanies = companies.map((company) => (
    <>
      <CompanyItem key={company.id} href={`/${company.slug}`} isSelected={company.id === currentCompany.id}>
        <div className='tw-flex tw-flex-col tw-gap-1'>
          <div className='tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between'>
            {company.name}
            {company.isPrimary && (
              <StateBadge variant='teal' className='tw-capitalize'>
                <FormattedMessage id='BoardSpaceSelect.primary' />
              </StateBadge>
            )}
            {company.isPending && (
              <StateBadge variant='orange' className='tw-capitalize'>
                <FormattedMessage id='BoardSpaceSelect.pending' />
              </StateBadge>
            )}
          </div>
          {renderSubcommittees(subcommittees.filter((subcommittee) => subcommittee.parentCompany.id === company.id))}
        </div>
      </CompanyItem>
    </>
  ));

  return (
    <div className='tw-overflow-auto'>
      {renderCompanies}
      <div ref={fetchMoreRef} />
      <LoadingIndicator isVisible={isLoading} className='tw-py-4' />
    </div>
  );
};

CompanyList.propTypes = {
  companies: PropTypes.array,
  currentCompany: PropTypes.object,
  isLoading: PropTypes.bool,
  onFetchMore: PropTypes.func,
};

export default CompanyList;
