import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as DocumentIcon } from 'images/icons/document.svg';
import { ReactComponent as SmartDocumentIcon } from 'images/icons/documents/smart-sign-icon.svg';
import { Table } from '../Signatories/Table';
import {
  CountTitle,
  SignatoriesTitle,
  ConfirmMsg,
  Warning,
  BodyWrapper,
  Document,
  EmailMsgWrapper,
  MsgTextarea,
} from './styles';
import * as styles from './Send.styles';

export const SendToSignatories = ({ signatories, errors, documentName, message, setMessage, hasHeader = true }) => {
  const onMessageChange = (e) => {
    setMessage(e.target.value || '');
  };

  const intl = useIntl();

  return (
    <BodyWrapper>
      {hasHeader && (
        <SignatoriesTitle>
          <span>
            <FormattedMessage id='SendToSignatories.sendTo' />
          </span>
          <br />
          <CountTitle>
            <FormattedMessage id='SendToSignatories.signatoryCount' values={{ count: signatories.length }} />
          </CountTitle>
        </SignatoriesTitle>
      )}
      <ConfirmMsg>
        <FormattedMessage id='SendToSignatories.confirmation' />
      </ConfirmMsg>

      {errors.length > 0 && (
        <div className='m-t-10'>
          <SignatoriesTitle>
            <Warning>
              <FormattedMessage id='SendToSignatories.errorCount' values={{ count: errors.length }} />
            </Warning>
            <span>
              {' '}
              <FormattedMessage id='SendToSignatories.occured' />
            </span>
          </SignatoriesTitle>

          <ConfirmMsg>
            {errors.map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </ConfirmMsg>
        </div>
      )}

      <Document>
        <div className='position-relative'>
          <DocumentIcon />
          <SmartDocumentIcon className='smart-sign-icon' />
        </div>
        <span className='text-truncate'>{documentName}</span>
      </Document>
      <Table signatories={signatories} tableStyles={{ ...styles }} />

      <EmailMsgWrapper>
        <div className='email-msg-label'>
          <FormattedMessage id='SendToSignatories.emailMessage' />
        </div>
        <MsgTextarea
          value={message}
          onChange={onMessageChange}
          placeholder={intl.formatMessage({ id: 'SendToSignatories.enterMessage' })}
        />
      </EmailMsgWrapper>
    </BodyWrapper>
  );
};
