import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

const spacing = 5;

export const item = css`
  background: ${colors.white};
  margin-bottom: ${spacing}px;

  &:hover {
    cursor: pointer;
  }
`;

export const icon = css`
  flex-shrink: 0;
  margin-right: 5px;
`;

export const heading = css`
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid ${colors.mobileGrey};
  border-radius: 4px;
  padding: 8px;
  line-height: 1;
  color: ${colors.black};
`;

export const children = css`
  padding-left: 20px;
  margin-top: ${spacing}px;

  &:empty {
    margin: 0;
  }
`;
