import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';

export const container = css`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 11px;
  line-height: 1;
  ${media.tablet} {
    padding: 8px 15px;
    height: 48px;
    border-top: 1px solid ${colors.borderSecondGrey};
  }

  .document__actions {
    margin-right: 0;
    display: flex;
    align-items: center;
  }

  .document__icon {
    flex-shrink: 0;
  }

  .document__createdAt {
    ${fonts.regular}
    color: ${colors.mediumGrey};
    font-size: 12px;
    margin-right: 0;
    margin-left: auto;
    white-space: nowrap;
    line-height: 16px;
    ${media.tablet} {
      display: flex;
      flex-direction: column;
    }
  }

  .document__name {
    ${fonts.regular}
    font-size: 14px;
    line-height: 15px;
    margin-right: 7px;
    margin-left: 7px;
    color: ${colors.black};
  }

  &:hover {
    cursor: pointer;
    background: ${colors.lightCyan};
    border-radius: 5px;

    .document__name {
      color: ${colors.cyan};
    }
  }
`;

export const wrapper = css`
  display: flex;
  align-items: center;
  min-width: 0;
`;

export const star = {
  base: css`
    flex-shrink: 0;
    ${media.tablet} {
      margin-left: 8px;
    }
  `,

  favourite: css`
    path {
      fill: ${colors.yellow};
    }
  `,
};

export const smartDocumentBadge = css`
  position: absolute;
  bottom: -5px;
  left: 3px;
`;

export const sharedBadge = css`
  position: absolute;
  top: -2px;
  right: -4px;
`;
