import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const document = {
  base: css`
    background: #e1e4e8;
    opacity: 1;
    padding: 10px 17px;
    display: flex;
    margin-bottom: 5px;
    font-size: 14px;
    color: ${colors.black};
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    flex-direction: column;

    &:hover {
      cursor: pointer;
      background: ${colors.white};
    }
  `,

  state: {
    dragged: css`
      background: ${colors.white};
      box-shadow: 0px 5px 10px 3px rgba(57, 53, 70, 0.1);
      opacity: 0.7;
    `,

    uploaded: css`
      background: ${colors.white};
    `,
  },
};

export const placeholder = (top, show) => css`
  display: ${show ? 'block' : 'none'};
  position: absolute;
  top: ${top}px;
  width: 100%;
  z-index: 0;
  pointer-events: none;
  background: transparent;
  line-height: 1;
  height: 42px;
  border: 1px dashed #dedde0;
`;

export const number = css`
  ${fonts.bold}
  font-size: 14px;
  margin-right: 10px;
`;

export const name = css`
  margin: 0 10px;
  cursor: pointer;
  line-break: anywhere;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const docIcon = css`
  min-width: 15px;
  display: flex;
`;

export const wrapper = css`
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  justify-content: space-between;
  position: relative;
  padding-right: 14px;
`;

export const innerWrapper = css`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

export const deleteIcon = css`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
`;
