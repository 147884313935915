import moment from 'moment';

export const roundUpHours = (date) =>
  date.minute() || date.second() || date.millisecond() ? date.add(1, 'hour').startOf('hour') : date.startOf('hour');

export const generateMeetingDates = () => {
  const meetingDate = moment().parseZone();
  const meetingStart = roundUpHours(meetingDate);
  const meetingEnd = meetingStart.clone().add(15, 'minute');

  return [meetingDate, meetingStart, meetingEnd];
};
