import { camelCase } from './strings';

export const formatGqlErrors = (errors) =>
  errors.reduce(
    (accumulated, current) => ({ ...accumulated, ...{ [camelCase(current.attribute)]: current.message } }),
    {}
  );

export const wrapMutation = (mutationFunc, vars, mutationName) => {
  return new Promise((resolve, reject) => {
    mutationFunc(vars).then(({ data }) => {
      if (data[mutationName].errors?.length) {
        reject(formatGqlErrors(data[mutationName].errors));
      } else resolve(data[mutationName]);
    });
  });
};
