import { css } from '@emotion/core';
import { panels, colors, fonts, media } from 'styles';

export const hideMobile = css`
  ${media.mobile} {
    display: none !important;
  }
`;

export const showTablet = css`
  display: none;
  ${media.tablet} {
    display: block !important;
  }
`;

export const resolutionHeading = css`
  ${fonts.bold}
  font-size: 16px;
  color: ${colors.black};
  line-height: 28px;
  padding-bottom: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const resolutionPanel = css`
  border-radius: 4px;
  min-height: 225px;
  ${media.mobile} {
    padding: 20px 0 0;
    background-color: transparent;
    box-shadow: none;
  }
`;

export const settingsPanel = css`
  border-radius: 4px;
  min-height: 225px;
`;

export const saveButtonsPanel = css`
  overflow: hidden;
`;

export const saveButton = css`
  ${media.tablet} {
    width: 50%;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
`;
