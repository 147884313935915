/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';

import Button, { Variants } from './../Button';
import TextInput from './../TextInput';
import SelectInput from 'components/v2/shared/SelectInput';
import TextAreaInput from './../TextAreaInput';
import ResourceManager from './../ResourceManager';

import { TASK_DEFAULTS } from './constants';
import { getFieldData } from 'utils/v2/data';
import { useContactsContext } from 'contexts/v2/ContactsContext';
import { useTasksContext } from 'contexts/v2/TasksContext';

import * as styles from './TaskManager.styles';

const TaskManager = ({ className }) => {
  const [attributes, setAttributes] = useState({ ...TASK_DEFAULTS });
  const [errors, setErrors] = useState({});

  const handleFieldChange = (fieldName) => (e) => {
    const value = getFieldData(e);
    setAttributes((state) => ({ ...state, [fieldName]: value }));
  };

  const { contacts } = useContactsContext();
  const { addTask } = useTasksContext();

  const handleAddTask = (resetResourceManager) => async () => {
    setErrors({});

    try {
      await addTask(attributes);
      resetResourceManager();
      setAttributes({ ...TASK_DEFAULTS });
    } catch (e) {
      setErrors(e);
    }
  };

  const inputMap = {
    description: {
      label: 'Description',
      component: (
        <TextAreaInput
          errorMessage={errors.detail}
          value={attributes.detail}
          onChange={handleFieldChange('detail')}
          placeholder='Enter your description...'
          label='Description'
        />
      ),
    },

    attachment: {
      label: 'Attachment',
      component: <p>TODO: Attachment component</p>,
    },

    assignee: {
      label: 'Assignee',
      component: (
        <SelectInput
          options={contacts}
          onChange={handleFieldChange('contactId')}
          label='Assigned'
          optionVariant='contact'
          variantValueBackground='white'
        />
      ),
    },

    dueDate: {
      label: 'Due date',
      component: <p>TODO: Notice times based on meeting date</p>,
    },
  };

  return (
    <ResourceManager
      className={className}
      inputMap={inputMap}
      renderHeader={
        <Col lg={12} className='mb-4'>
          <TextInput
            name='title'
            label='What is the task'
            errorMessage={errors.title}
            placeholder='Enter your task...'
            value={attributes.title}
            onChange={handleFieldChange('title')}
          />
        </Col>
      }
      renderButton={({ resetResourceManager }) => (
        <Button variant={Variants.Teal} isFullWidth onClick={handleAddTask(resetResourceManager)}>
          Add task to meeting
        </Button>
      )}
    />
  );
};

TaskManager.propTypes = {
  className: PropTypes.string,
};

export default TaskManager;
