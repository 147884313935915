import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const wrapper = css`
  display: none;
  align-items: center;
  background: ${colors.white};
  border-bottom: 1px solid ${colors.mobileGrey};

  ${media.tablet} {
    height: 69px;
    display: flex;
    background ${colors.white};
    padding: 0 25px;
  }

  ${media.mobile} {
    height: 53px;
    padding: 0 15px;
  }
`;

export const title = css`
  ${fonts.semiBold}
  color: ${colors.black};
  margin: auto;

  ${media.tablet} {
    font-size: 20px;
  }

  ${media.mobile} {
    font-size: 18px;
  }
`;

export const close = css`
  color: ${colors.black};
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
`;
