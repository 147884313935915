import { css } from '@emotion/core';
import { media, colors } from 'styles';

export const container = css`
  display: none;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  background: ${colors.white};
  top: -15px;
  line-height: 1;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  padding: 10px 15px;
  border-top: 1px solid ${colors.lightPurple};

  ${media.tablet} {
    display: block;
  }
`;
