import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const divider = {
  base: css`
    --margin: 10px;

    display: flex;
    align-items: center;
    margin: var(--margin) 0;
    font-size: 14px;
    color: ${colors.black};
    letter-spacing: 0.1em;
    text-transform: uppercase;
  `,

  right: css`
    &:before {
      content: '';
      flex: 1;
      border-bottom: 1px solid ${colors.headingGrey};
    }

    &:not(:empty):before {
      margin-right: var(--margin);
    }
  `,

  left: css`
    &:after {
      content: '';
      flex: 1;
      border-bottom: 1px solid ${colors.headingGrey};
    }

    &:not(:empty):after {
      margin-left: var(--margin);
    }
  `,

  center: css`
    &:after,
    &:before {
      content: '';
      flex: 1;
      border-bottom: 1px solid ${colors.headingGrey};
    }

    &:not(:empty):before {
      margin-right: var(--margin);
    }

    &:not(:empty):after {
      margin-left: var(--margin);
    }
  `,

  bold: css`
    ${fonts.bold}
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
  `,
};
