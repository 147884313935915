import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const form = css`
  padding: 22px 18px;
`;

export const heading = css`
  ${fonts.bold}
  font-size: 12px;
  line-height: 16px;
  color: ${colors.black};
  margin-bottom: 17px;
`;

export const icon = css`
  margin-bottom: 1px;
`;
