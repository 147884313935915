import { css } from '@emotion/core';
import { icons, colors, fonts } from 'styles';

export const task = {
  base: css`
    color: ${colors.mediumGrey};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-left: 17px;
    padding: 5px;
    flex-grow: 1;
    line-height: 1;
  `,

  hovered: css`
    cursor: pointer;
    background: #e1e4e8;
    border-radius: 4px;
  `,

  completed: css`
    margin-left: 0;
    color: ${colors.cyan};
  `,
};

export const more = css`
  ${fonts.regular}
  color: ${colors.cyan};
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: lowercase;
`;

export const checkmark = css`
  color: ${colors.cyan};
  font-weight: 400;
  font-size: 12px;
`;

export const deleteIcon = css`
  ${icons.base}
  color: ${colors.red};

  &:hover {
    color: ${colors.red};
    opacity: 0.8;
  }
`;
