import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const container = css`
  background: ${colors.white};
  position: relative;
  padding: 22px 20px 22px 21px;
  border-left: 1px solid ${colors.lightestGrey};
  height: 100%;

  @media (max-width: 480px) {
    min-height: 135px;
  }
`;

export const commentList = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const back = css`
  background: ${colors.white};
  box-shadow: 0px 4px 50px rgba(54, 71, 98, 0.12);
  font-size: 16px;
  color: ${colors.cyan};
  position: absolute;
  left: -48px;
  top: 0;
  height: 54px;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: 0.4s;
  transform: translate(0%);
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    left: 0px;
  }
`;
