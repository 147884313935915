import { gql, useMutation } from '@apollo/client';

const SET_COMPANY_AS_PRIMARY_MUTATION = gql`
  mutation SetCompanyAsPrimary($input: SetCompanyAsPrimaryInput!) {
    setCompanyAsPrimary(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`;

const useSetCompanyAsPrimaryMutation = (options) => useMutation(SET_COMPANY_AS_PRIMARY_MUTATION, options);

export { SET_COMPANY_AS_PRIMARY_MUTATION, useSetCompanyAsPrimaryMutation };
