import { gql, useMutation } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from './../fragments/DocumentFragment';

const MOVE_DOCUMENT = gql`
  mutation moveDocument($input: MoveDocumentInput!) {
    moveDocument(input: $input) {
      document {
        ...DocumentFields
      }

      errors {
        attribute
        fullMessage
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

const useMoveDocumentMutation = (options) => useMutation(MOVE_DOCUMENT, options);

export { useMoveDocumentMutation };
