import moment from 'moment';

const HOUR_IN_MINUTES = 60;
const HOUR_IN_SECONDS = 3600;

const formatDateTime = (value, format) => {
  return moment(value).parseZone().format(format);
};

const formatDate = (value, format = 'DD/MM/YYYY') => {
  return formatDateTime(value, format);
};

const formatTime = (value, format = 'hh:mm A') => {
  return formatDateTime(value, format);
};

const formatTimeWithOffset = (value, offset, format = 'hh:mm A') => {
  return moment.utc(value).add(offset, 'seconds').format(format);
};

const formatTimezoneOffset = (gmtOffset) => {
  let gmtTimeDiff = gmtOffset / HOUR_IN_SECONDS;
  let gmtSign = `${gmtTimeDiff >= 0 ? '+' : '-'}`;

  let gmtTimezoneHours = Math.abs(Math.trunc(gmtOffset / HOUR_IN_SECONDS));
  let gmtTimezoneMinutes = (Math.abs(gmtOffset) % HOUR_IN_SECONDS) / HOUR_IN_MINUTES;

  return `GMT${gmtSign}${('0' + gmtTimezoneHours).slice(-2)}:${gmtTimezoneMinutes > 0 ? gmtTimezoneMinutes : '00'}`;
};

export { formatDateTime, formatDate, formatTime, formatTimeWithOffset, formatTimezoneOffset };
