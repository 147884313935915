import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const SubcommitteesTableRow = memo(({ name, slug, size, onEdit }) => {
  const intl = useIntl();

  const sizeLabel = () => {
    if (size === 0) {
      return intl.formatMessage({ id: 'SubcommitteesTableRow.noUsers' });
    } else {
      return intl.formatMessage({ id: 'SubcommitteesTableRow.users' }, { count: size });
    }
  };

  return (
    <tr>
      <td>
        <div className='d-flex flex-wrap flex-grow-1 text-black'>
          <div className='font-weight-semibold fs-14 w-100 text-truncate'>
            <a href={`/${slug}`} className='c-secondary'>
              {name}
            </a>
          </div>
          <div>{sizeLabel()}</div>
        </div>
      </td>

      <td className='text-right'>
        <a onClick={onEdit} className='btn btn-inverted btn-sm w-100px'>
          <FormattedMessage id='SubcommitteesTableRow.manage' />
        </a>
      </td>
    </tr>
  );
});

export { SubcommitteesTableRow };
