import React, { useEffect, createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { useMeetingTasksQuery } from 'data/v2/queries/MeetingTasksQuery';
import { useCreateTaskMutation } from 'data/v2/mutations/CreateTaskMutation';
import { useMeetingContext } from './MeetingContext';
import { wrapMutation } from 'utils/v2/gql';

const initialState = {
  tasks: [],
  skipFetch: true,
};

const actionTypes = {
  SET_TASKS: 'TasksContext.setTasks',
  ADD_TASK: 'TasksContext.addTask',
  FETCH_TASKS: 'TasksContext.fetchTasks',
};

const TasksContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_TASKS:
      return { ...state, tasks: payload.tasks };
    case actionTypes.ADD_TASK:
      return { ...state, tasks: [...state.tasks, payload.task] };
    case actionTypes.FETCH_TASKS:
      return { ...state, skipFetch: false };
  }
};

export const TasksContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    meeting: { meetingId },
  } = useMeetingContext();

  const { tasks, skipFetch } = state;

  const { loading, data } = useMeetingTasksQuery({
    variables: { id: meetingId },
    skip: skipFetch,
  });

  useEffect(() => {
    if (data) {
      const {
        currentCompany: {
          meeting: { tasks },
        },
      } = data;
      dispatch({ type: actionTypes.SET_TASKS, payload: { tasks } });
    }
  }, [data]);

  const fetchMeetingTasks = () => {
    dispatch({ type: actionTypes.FETCH_TASKS });
  };

  const [createTask] = useCreateTaskMutation();

  const addTask = async (input) => {
    return wrapMutation(
      createTask,
      { variables: { input: { attributes: { ...input, meetingId } } } },
      'createTask'
    ).then((data) => {
      dispatch({ type: actionTypes.ADD_TASK, payload: { task: data.task } });
    });
  };

  return (
    <TasksContext.Provider value={{ loading, tasks, addTask, fetchMeetingTasks }}>{children}</TasksContext.Provider>
  );
};

TasksContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useTasksContext = () => useContext(TasksContext);
