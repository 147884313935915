/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './Step.styles';

const Step = memo(({ steps, children, actions }) => {
  return (
    <>
      <div css={styles.panel}>
        {steps}
        {children}
      </div>

      <div css={styles.actions}>{actions}</div>
    </>
  );
});

export { Step };
