import { gql, useQuery } from '@apollo/client';

const RECENT_DOCUMENTS_QUERY = gql`
  query recentDocuments {
    currentContact {
      id

      recentDashboardDocuments {
        id
        name
        extension
        withSmartDocuments
        dashboardIcon
        url
        contentType
        createdAt
      }
    }
  }
`;

const useRecentDocumentsQuery = (options) => useQuery(RECENT_DOCUMENTS_QUERY, options);

export { useRecentDocumentsQuery };
