import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Item from './Item';
import Menu from './Menu';
import { usePopper } from 'react-popper';
import { useClickOutside } from 'hooks/useClickOutside';

const Dropdown = ({ renderToggle = () => {}, menuElement, placement = 'bottom-end', className }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside();

  const toggleDropdown = () => {
    setIsComponentVisible((prev) => !prev);
    updatePopper && updatePopper();
  };

  const [toggleRef, setToggleRef] = useState();
  const [menuRef, setMenuRef] = useState();

  const { styles, attributes, update: updatePopper } = usePopper(toggleRef, menuRef, { placement });

  return (
    <span ref={ref} className={className}>
      <button
        ref={setToggleRef}
        onClick={toggleDropdown}
        className='tw-border-none tw-bg-transparent tw-p-0 tw-outline-none'
      >
        {renderToggle({ isComponentVisible })}
      </button>

      <div {...attributes.popper} ref={setMenuRef} style={styles.popper}>
        {isComponentVisible && menuElement}
      </div>
    </span>
  );
};

Dropdown.Item = Item;
Dropdown.Menu = Menu;

Dropdown.propTypes = {
  renderToggle: PropTypes.func,
  menuElement: PropTypes.node,
  placement: PropTypes.string,
  className: PropTypes.string,
};

export default Dropdown;
