import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FormattedMessage, useIntl } from 'react-intl';
import { withIntl } from '../../../../utils/v2/intl';

import { ContentCard, ItemContent, ItemTitle, Item, ResendButtonWrapper } from './styles';
import api from './API';

const SignatoryCard = ({ id, name, status, email, signed, mayResentInvitation, isUpdating, resendPath }) => {
  const [emailValue, setEmail] = useState(email);
  const [isResponseSeuccess, setIsResponseSuccess] = useState(false);

  const intl = useIntl();

  return (
    <ContentCard>
      <div className='d-flex p-l-15'>
        <Item className='d-flex align-items-center' flex={2}>
          <ItemTitle>
            <FormattedMessage id='SignatoryCard.name' />
          </ItemTitle>
          <ItemContent>{name}</ItemContent>
        </Item>
        <Item className='d-flex align-items-center' flex={1}>
          <ItemTitle>
            <FormattedMessage id='SignatoryCard.status' />
          </ItemTitle>
          <ItemContent>{status}</ItemContent>
        </Item>
      </div>
      <div className='d-flex p-l-15'>
        <Item className='d-flex align-items-center' flex={2}>
          <ItemTitle>
            <FormattedMessage id='SignatoryCard.email' />
          </ItemTitle>
          {
            <ItemContent className='smart-document-email'>
              {isUpdating ? (
                <Form.Control
                  type='text'
                  placeholder={intl.formatMessage({ id: 'SignatoryCard.enterEmail' })}
                  value={emailValue}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onBlur={() => {
                    api.updateEmail(
                      `/${getCompanySlug()}/signatories/${id}.json`,
                      emailValue,
                      (response) => {
                        setIsResponseSuccess(true);
                        setTimeout(() => {
                          setIsResponseSuccess(false);
                        }, 3000);
                      },
                      (error) => {
                        console.error(error);
                      }
                    );
                  }}
                />
              ) : (
                email
              )}
            </ItemContent>
          }
        </Item>
        <Item className='d-flex align-items-center' flex={1}>
          <ItemTitle>
            <FormattedMessage id='SignatoryCard.signed' />
          </ItemTitle>
          <ItemContent>{signed}</ItemContent>
        </Item>
      </div>
      <ResendButtonWrapper isSuccess={isResponseSeuccess}>
        {mayResentInvitation && (
          <a
            id={`resend-${id}`}
            onClick={() => {
              api.resendInvitation(
                `/${getCompanySlug()}/signatories/${id}/resend.json`,
                (response) => {
                  setIsResponseSuccess(true);
                  setTimeout(() => {
                    setIsResponseSuccess(false);
                  }, 3000);
                },
                (error) => {
                  console.error(error);
                }
              );
            }}
          >
            {isResponseSeuccess ? (
              <>
                <i className='fas fa-check m-r-5' /> <FormattedMessage id='SignatoryCard.success' />
              </>
            ) : (
              intl.formatMessage({ id: 'SignatoryCard.resend' })
            )}
          </a>
        )}
      </ResendButtonWrapper>
    </ContentCard>
  );
};

export default withIntl(SignatoryCard);
