import React from 'react';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as LogoIcon } from 'images/logo.svg';
import { Badge } from 'components/shared/Badge';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { voteName, voterAllowPdfExport, voteStartDate, voteStartTime, voteEndDate, voteEndTime } from './helper';
import { ResolutionsTable } from '../Voters/ResolutionsTable';
import AppHeader from 'components/shared/AppHeader';
import { ToAppBarWrapper, Brand, AppBody } from 'components/shared/AppHeader/styles';
import { TimeWrapper } from './styles';

export const ViewerMobile = ({
  meetingDrawer,
  loggedIn,
  vote,
  voteStatus,
  voter,
  changeVoteStatus,
  previewDocument,
  openPdf,
}) => {
  const intl = useIntl();
  const { isDesktop, isTablet } = useWindowDimensions();
  return (
    <>
      <AppHeader>
        <ToAppBarWrapper isdesktop={isDesktop}>
          <Brand>
            <LogoIcon />
            <h3 className='font-weight-bold fs-16 m-0 text-color-primary'>
              <FormattedMessage id='ViewerMobile.shaparency' />
            </h3>
            {loggedIn && (
              <a href='/users/sign_in' className='btn btn-inverted m-l-20'>
                <FormattedMessage id='ViewerMobile.toApp' />
              </a>
            )}
          </Brand>
        </ToAppBarWrapper>
      </AppHeader>
      <AppBody className='p-15'>
        <div className='d-flex justify-content-between'>
          <div>
            <div className='vote-name fs-16 font-weight-bold text-black text-truncate lh-20'>{voteName(vote)}</div>
            <div>
              <Badge status={voteStatus} text={intl.formatMessage({ id: 'ViewerMobile.votes' })} />
            </div>
          </div>
          <div>
            <div className='vote-name fs-16 font-weight-semibold text-black text-truncate lh-20'>
              {vote.data.attributes.owner_name}
            </div>
            <div className='text-medium-grey fs-12 font-weight-regular'>
              <FormattedMessage id='ViewerMobile.voteCreator' />
            </div>
          </div>
        </div>
        <div className='d-flex flex-row text-black fs-12 m-t-12'>
          <TimeWrapper
            isdesktop={isDesktop}
            className={clsx('start-wrapper', {
              ['d-flex justify-content-between']: isTablet,
            })}
          >
            <div className='font-weight-semibold'>
              <FormattedMessage id='ViewerMobile.start' />
            </div>
            <div
              className={clsx({
                ['d-flex']: isTablet,
              })}
            >
              <div className='d-flex align-items-center'>
                <div className='far fa-calendar-alt' />
                <div>{voteStartDate(vote)}</div>
              </div>
              <div
                className={clsx('d-flex align-items-center', {
                  ['m-l-15']: isTablet,
                })}
              >
                <div className='far fa-clock' />
                <div>{voteStartTime(vote)}</div>
              </div>
            </div>
          </TimeWrapper>
          <TimeWrapper
            isdesktop={isDesktop}
            className={clsx('end-wrapper', {
              ['d-flex justify-content-between']: isTablet,
            })}
          >
            <div className='font-weight-semibold'>
              <FormattedMessage id='ViewerMobile.end' />
            </div>
            <div className={isTablet ? 'd-flex' : ''}>
              <div className='d-flex align-items-center'>
                <div className='far fa-calendar-alt' />
                <div>{voteEndDate(vote)}</div>
              </div>
              <div
                className={clsx('d-flex align-items-center', {
                  ['m-l-15']: isTablet,
                })}
              >
                <div className='far fa-clock' />
                <div>{voteEndTime(vote)}</div>
              </div>
            </div>
          </TimeWrapper>
        </div>
        {voteStatus == 'published' && meetingDrawer && voterAllowPdfExport(voter, meetingDrawer) && (
          <div className='d-flex justify-content-between align-items-center flex-grow-1 m-t-15 m-b-15'>
            <div className='d-flex flex-column'>
              {voteStatus == 'published' && meetingDrawer && (
                <div className='fs-14 m-t-20'>
                  <FormattedMessage id='ViewerMobile.votingAvailable' />
                </div>
              )}
            </div>
            {voterAllowPdfExport(voter, meetingDrawer) && (
              <div className='d-flex m-t-20 m-b-10'>
                <div
                  className='btn btn-primary btn-pdf'
                  onClick={() => {
                    openPdf(vote);
                  }}
                >
                  <FormattedMessage id='ViewerMobile.exportPdf' />
                </div>
              </div>
            )}
          </div>
        )}
        {!(voteStatus == 'published' && meetingDrawer) && (
          <ResolutionsTable
            vote={vote}
            voter={voter}
            onVoteStatusChange={changeVoteStatus}
            meetingDrawer={meetingDrawer}
            previewDocument={previewDocument}
          />
        )}
      </AppBody>
    </>
  );
};
