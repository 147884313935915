import { gql, useMutation } from '@apollo/client';

const CREATE_COMPANY_CONTACT_MUTATION = gql`
  mutation CreateCompanyContact($input: CreateCompanyContactInput!) {
    createCompanyContact(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`;

const useCreateCompanyContactMutation = (options) => useMutation(CREATE_COMPANY_CONTACT_MUTATION, options);

export { CREATE_COMPANY_CONTACT_MUTATION, useCreateCompanyContactMutation };
