// @jsx jsx

import React, { memo, useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { Placeholder } from 'components/shared/Placeholder';
import { ButtonWithSuccess } from 'components/shared/ButtonWithSuccess';
import { EmailChips } from 'components/shared/EmailChips';

import { ReactComponent as ContactsIcon } from 'images/icons/no-contacts.svg';
import { ReactComponent as DeleteIcon } from 'images/icons/delete-minus.svg';

import { useResendVoteInvite } from '../hooks/useResendVoteInvite';

import * as styles from './Table.styles';

const CollaboratorsTable = memo(
  ({ voteId, collaboratorEmails, persistedEmails, voteStatus, onDelete, handleEmailImport }) => {
    const placeholderIcon = <ContactsIcon />;

    const [emails, setEmails] = useState(collaboratorEmails);

    const intl = useIntl();

    useEffect(() => setEmails(collaboratorEmails), [collaboratorEmails]);

    const fetchResults = (query) => {
      const lowerQuery = query.toLowerCase();

      const filteredEmails = collaboratorEmails.filter((email) => email.toLowerCase().includes(lowerQuery));

      setEmails(filteredEmails);
    };

    const onSearchInputChange = (event) => fetchResults(event.target.value);
    const onResend = (email) => resendVoteInvite({ voteId: voteId, email: email });
    const emailPersisted = (email) => persistedEmails.includes(email);

    const { resendVoteInvite } = useResendVoteInvite();

    const tableBody = emails.map((email, index) => {
      const handleDelete = onDelete.bind(this, email);
      const handleResend = onResend.bind(this, email);

      return (
        <tr css={styles.tableRow(!email[index + 1])} className='m-b-5 m-l-5' key={`collaborator-${index}`}>
          <td>{index + 1}</td>
          <td className='text-truncate'>{email}</td>
          <td className='w-130'>
            {voteStatus === 'published' && emailPersisted(email) && (
              <ButtonWithSuccess
                type='button'
                onClick={handleResend}
                className='btn btn-sm btn-inverted m-l-5 w-130 fs-12'
              >
                <FormattedMessage id='CollaboratorsTable.resendRequest' />
              </ButtonWithSuccess>
            )}
          </td>

          <td className='w-percent-10 text-right'>
            <DeleteIcon className='cursor-pointer' onClick={handleDelete} />
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className='col-md-5 p-0'>
          <EmailChips onImport={handleEmailImport} importedEmails={collaboratorEmails} />
        </div>

        <div css={styles.tableContainer}>
          <table css={styles.table}>
            <thead>
              <tr>
                <th css={styles.tableHeader(5)}>#</th>
                <th css={styles.tableHeader(50)}>
                  <FormattedMessage id='CollaboratorsTable.email' />
                </th>
                <th css={styles.tableHeader(45)} colSpan='2'>
                  <input
                    type='text'
                    placeholder={intl.formatMessage({ id: 'CollaboratorsTable.search' })}
                    className='w-100 form-control font-weight-light collaborator-search'
                    onChange={onSearchInputChange}
                  />
                </th>
              </tr>
            </thead>

            {emails.length > 0 && <tbody className='fs-12 text-black'>{tableBody}</tbody>}
          </table>

          {!emails ||
            (!emails.length && (
              <Placeholder
                image={placeholderIcon}
                message={intl.formatMessage({ id: 'CollaboratorsTable.noCollab' })}
              />
            ))}
        </div>
      </>
    );
  }
);

export { CollaboratorsTable };
