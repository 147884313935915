import React, { useEffect, useMemo, useState } from 'react';
import Switch from 'components/v2/shared/Switch';
import Alert from 'components/v2/shared/Alert';
import Heading from 'components/v2/shared/Heading';
import { FormattedMessage } from 'react-intl';
import { withIntl } from 'utils/v2/intl';

const SinglePermission = ({ details, isDefault, onClick }) => {
  const [isActive, setIsActive] = useState(details.permitted);
  const handleOnSwitchChange = () => {
    setIsActive((current) => !current);
    onClick(details?.permission?.id ?? details.id, !isActive);
  };

  return (
    <div className='tw-grid tw-h-16 tw-grid-flow-col tw-grid-cols-8 tw-items-center tw-rounded tw-bg-gray-100 tw-px-5'>
      <div className='tw-col-span-6 tw-flex tw-flex-col tw-justify-center tw-font-sans tw-text-[13px] tw-font-semibold tw-text-black sm:tw-col-span-6'>
        <FormattedMessage id={`PermissionScope.${details.permission.scope}`} />
        <p className='tw-m-0 tw-text-xs tw-font-medium tw-text-gray-700'>{details.permission.description}</p>
      </div>
      <div className='tw-col-span-2 tw-flex tw-justify-end'>
        <Switch
          variant={isActive ? 'teal-bg' : 'gray'}
          isDisabled={isDefault}
          isChecked={isActive}
          onChange={handleOnSwitchChange}
        />
      </div>
    </div>
  );
};

const Permissions = ({ data, isDefault, defaultPermissions, setPermissionsState }) => {
  useEffect(() => {
    setPermissionsState(data?.permissions ?? defaultPermissions.permissions);
  }, [data]);

  const handlePermissionClick = (permissionId, isActive) => {
    setPermissionsState((prev) => {
      const newState = [...prev];
      const index = newState.findIndex((s) => s.id === permissionId);
      newState[index] = { ...newState[index], permitted: isActive };

      return newState;
    });
  };

  const sortPermissionGroup = (groups) => {
    const sortedGroups = { ...groups };
    Object.keys(sortedGroups).forEach((key) => {
      sortedGroups[key].sort((a, b) => {
        return a.permission.order < b.permission.order ? -1 : a.permission.order > b.permission.order ? 1 : 0;
      });
    });

    return sortedGroups;
  };

  const permissionGroup = useMemo(
    () => ({
      'Company Settings': data?.permissions.filter((p) =>
        ['company', 'subscription', 'chat'].includes(p.permission.resource)
      ),
      'Contact & Roles': data?.permissions.filter((p) =>
        ['access_role', 'contact_group', 'contact'].includes(p.permission.resource)
      ),
      Meetings: data?.permissions.filter((p) => ['meeting'].includes(p.permission.resource)),
      Documents: data?.permissions.filter((p) => ['document'].includes(p.permission.resource)),
      Tasks: data?.permissions.filter((p) => ['task'].includes(p.permission.resource)),
      Minutes: data?.permissions.filter((p) => ['minute'].includes(p.permission.resource)),
      Voting: data?.permissions.filter((p) => ['vote'].includes(p.permission.resource)),
      SmartSign: data?.permissions.filter((p) => ['smart_sign'].includes(p.permission.resource)),
    }),
    [data]
  );

  const defaultPermissionGroup = useMemo(
    () => ({
      'Company Settings': defaultPermissions?.permissions
        .filter((p) => ['company', 'subscription', 'chat'].includes(p.resource))
        .map((permission) => ({
          permission,
          permitted: false,
        })),
      'Contact & Roles': defaultPermissions?.permissions
        .filter((p) => ['access_role', 'contact_group', 'contact'].includes(p.resource))
        .map((permission) => ({
          permission,
          permitted: false,
        })),
      Meetings: defaultPermissions?.permissions
        .filter((p) => ['meeting'].includes(p.resource))
        .map((permission) => ({
          permission,
          permitted: false,
        })),
      Documents: defaultPermissions?.permissions
        .filter((p) => ['document'].includes(p.resource))
        .map((permission) => ({
          permission,
          permitted: false,
        })),
      Tasks: defaultPermissions?.permissions
        .filter((p) => ['task'].includes(p.resource))
        .map((permission) => ({
          permission,
          permitted: false,
        })),
      Minutes: defaultPermissions?.permissions
        .filter((p) => ['minute'].includes(p.resource))
        .map((permission) => ({
          permission,
          permitted: false,
        })),
      Voting: defaultPermissions?.permissions
        .filter((p) => ['vote'].includes(p.resource))
        .map((permission) => ({
          permission,
          permitted: false,
        })),
      SmartSign: defaultPermissions?.permissions
        .filter((p) => ['smart_sign'].includes(p.resource))
        .map((permission) => ({
          permission,
          permitted: false,
        })),
    }),
    [defaultPermissions]
  );

  return (
    <div className={isDefault ? 'tw-my-11' : 'tw-my-14'}>
      <Heading text='Permissions' className='!tw-mb-0 !tw-text-xl !tw-font-bold tw-text-black' />
      {data?.isDefault && (
        <div className='tw-mt-2'>
          <Alert
            className='tw-w-full !tw-min-w-0 !tw-px-2'
            description='Default permissions cannot be edited. Create custom role to manage permissions.'
            severity='warning'
            isDismissable={false}
          />
        </div>
      )}
      <div className='tw-mt-8'>
        {Object.keys(
          data?.permissions ? sortPermissionGroup(permissionGroup) : sortPermissionGroup(defaultPermissionGroup)
        ).map((group) => (
          <React.Fragment key={group}>
            <div className='tw-mb-2.5 tw-mt-[27px] tw-font-sans tw-text-base tw-font-bold tw-text-black'>{group}</div>
            {data?.permissions
              ? permissionGroup[group].map((details) => (
                  <div key={details.permission.scope} className='tw-mt-1'>
                    <SinglePermission isDefault={isDefault} details={details} onClick={handlePermissionClick} />
                  </div>
                ))
              : defaultPermissionGroup[group]?.map((details) => (
                  <div key={details.permission.scope} className='tw-mt-1'>
                    <SinglePermission isDefault={isDefault} details={details} onClick={handlePermissionClick} />
                  </div>
                ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default withIntl(Permissions);
