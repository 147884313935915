/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';

import * as styles from './Badge.styles';

const Badge = ({ status, badgeStyles }) => {
  if (!status) {
    return <></>;
  }

  const dasherizedStatus = status.replace('_', '-');
  const humanizedStatus = status.replace('_', ' ');

  const klassName = `badge badge-large badge-${dasherizedStatus}`;
  const applicableStyles = [styles.base, badgeStyles];

  return (
    <div css={applicableStyles} className={klassName}>
      {humanizedStatus}
    </div>
  );
};

export { Badge };
