import { css } from '@emotion/core';
import { icons, panels, fonts, colors, media } from 'styles';

export const panel = css`
  box-shadow: 0px 1.2px 3.6px rgba(0, 20, 90, 0.1), 0px 6.4px 14.4px rgba(0, 50, 97, 0.13);
  background: ${colors.white};
  padding: 22px 27px;
  position: absolute;
  top: 15px;
  z-index: 2;
  left: -5px;
  ${media.mobile} {
    width: 100%;
    position: fixed;
    top: unset;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 15px;
    bottom: 0;
    max-width: 100%;
  }
`;

export const slider = css`
  .rangeslider {
    width: 322px;
    border-radius: 25px;
    height: 5px;
    background: #f0f0f0;
    box-shadow: none;
    margin: 0;
    max-width: 100%;
    ${media.mobile} {
      width: 100%;
    }
  }

  .rangeslider__fill {
    background: ${colors.cyan};
    box-shadow: none;
  }

  .rangeslider__labels {
    display: none;
  }

  .rangeslider__handle {
    background: ${colors.cyan};
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    box-shadow: none;
    outline: none;

    &::after {
      display: none;
    }
  }
`;

export const pencil = css`
  ${icons.base}
  color: ${colors.lighterGrey};
  font-size: 12px;
  margin-left: 7px;
`;
