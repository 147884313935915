/** @jsx jsx */

import React, { memo, useRef } from 'react';
import { jsx } from '@emotion/core';

import { CommentThreadWrapper } from './../CommentThreadWrapper';
import { CommentForm } from './../CommentForm';
import { useVisibility } from 'hooks/useVisibility';

const EditCommentThread = memo(({ minuteId, comment, onCancel }) => {
  const { isComponentVisible, hideComponent } = useVisibility(true);

  const textAreaRef = useRef();

  const onShow = () => {
    textAreaRef.current.focus();

    textAreaRef.current.selectionStart = comment.body.length;
    textAreaRef.current.selectionEnd = comment.body.length;
    textAreaRef.current.scrollTo({
      top: textAreaRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <CommentThreadWrapper isVisible={isComponentVisible} onHide={onCancel} onShow={onShow}>
      <CommentForm
        textAreaRef={textAreaRef}
        minuteId={minuteId}
        commentId={comment.id}
        author={comment.author}
        value={comment.body}
        onSubmit={hideComponent}
        onCancel={hideComponent}
        isFullHeight
      />
    </CommentThreadWrapper>
  );
});

export { EditCommentThread };
