/** @jsx jsx */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import axios from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';

import { getAttributes } from 'helpers/getAttributes';
import { trackPromise } from 'react-promise-tracker';
import Pluralize from 'pluralize';
import Modal from 'react-bootstrap/Modal';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import * as styles from './ShareableModal.styles';

import { ContactPicker } from 'components/shared/ContactPicker';
import { ContactsTable } from './ContactsTable';
import { ReactComponent as FileIcon } from 'images/icons/file.svg';
import { ReactComponent as FolderIcon } from 'images/icons/folder.svg';
import { ReactComponent as CopyIcon } from 'images/share-button.svg';
import { ReactComponent as CloseIcon } from 'images/icons/close-icon-black.svg';

export default memo(({ shareable, contacts, context }) => {
  const companySlug = getCompanySlug();
  const [basicShareable, setBasicShareable] = useState(shareable);

  const intl = useIntl();

  const [currentShareable, setCurrentShareable] = useState(basicShareable);
  const [sharedContactIds, setSharedContactIds] = useState(getAttributes(currentShareable).shared_contact_ids);
  const [ancestorSharedContactIds, setAncestorSharedContactIds] = useState(
    getAttributes(currentShareable).ancestor_shared_contact_ids
  );
  const [ancestors, setAncestors] = useState(getAttributes(basicShareable).ancestors);

  useEffect(() => {
    setAncestors(getAttributes(basicShareable).ancestors);
  }, [basicShareable]);

  useEffect(() => {
    setAncestorSharedContactIds(getAttributes(currentShareable).ancestor_shared_contact_ids);
  }, [sharedContactIds, currentShareable]);

  useEffect(() => {
    setContactsForPicker(
      filterContactsForPicker(
        sharedContactIds,
        ancestorSharedContactIds,
        getAttributes(currentShareable).permitted_contact_ids
      )
    );
  }, [sharedContactIds]);

  useEffect(() => {
    setSharedContactIds(getAttributes(currentShareable).shared_contact_ids);
  }, [currentShareable]);

  const [isVisible, setIsVisible] = useState(true);

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  const initialContactsForPicker = () => {
    const attributes = getAttributes(currentShareable);

    return filterContactsForPicker(
      attributes.shared_contact_ids,
      attributes.ancestor_shared_contact_ids,
      attributes.permitted_contact_ids
    );
  };

  const filterContactsForPicker = (sharedContactIds, ancestorSharedContactIds, permittedIds) => {
    return contacts
      .filter(
        (contact) =>
          !sharedContactIds.concat(ancestorSharedContactIds, permittedIds).includes(contact.data.attributes.id)
      )
      .map((contact) => contact.data.attributes);
  };

  const [contactsForPicker, setContactsForPicker] = useState(initialContactsForPicker());

  const isSelected = (contact) => {
    return false;
  };

  const shareableIsSelected = (shareable) => {
    return shareable.data.type == currentShareable.data.type && shareable.data.id == currentShareable.data.id;
  };

  const contactExists = useCallback((contact) => {
    return sharedContactIds
      .concat(ancestorSharedContactIds, getAttributes(currentShareable).permitted_contact_ids)
      .find((item) => item == contact.id);
  });

  const toggleContact = useCallback((contact) => {
    const isAdded = contactExists(contact);

    if (isAdded !== undefined) {
      return removeContact(contact);
    } else {
      return addContact(contact);
    }
  });

  const addContact = useCallback((contact) => {
    const contactIds = [...sharedContactIds, contact.id];

    setSharedContactIds(contactIds);
    shareCurrentShareable(contactIds);
  });

  const removeContact = useCallback((contact) => {
    const contactIds = sharedContactIds.filter((id) => id !== contact.id);

    setSharedContactIds(contactIds);
    shareCurrentShareable(contactIds);
  });

  const shareCurrentShareable = (contactIds) => {
    const path = `/api/${companySlug}/v1/${Pluralize(currentShareable.data.type)}/${
      currentShareable.data.id
    }/share.json`;
    const payload = { contact_ids: contactIds };
    const promise = axios
      .put(path, payload)
      .then((response) => response.status)
      .then(handleResponse);

    return trackPromise(promise);
  };

  const reloadBasicShareable = () => {
    const path = `/api/${companySlug}/v1/${Pluralize(basicShareable.data.type)}/${basicShareable.data.id}`;
    const promise = axios
      .get(path)
      .then((response) => response.data)
      .then((response) => setBasicShareable(response));

    return trackPromise(promise);
  };

  const handleResponse = (responseStatus) => {
    if (responseStatus == 200) {
      reloadBasicShareable();

      const directory = context == 'action' ? ancestors[ancestors.length - 1] : currentShareable;
      const directoryId = directory.data.id;
      const path = `/${companySlug}/documents/${directoryId}/load_details`;
      const payload = { type: currentShareable.data.type, id: directoryId };

      $.ajax({
        url: path,
        method: 'get',
        data: payload,
      });
    }
  };

  const copyShareableUrl = () => {
    return navigator.clipboard.writeText(getAttributes(currentShareable).url);
  };

  const popover = (
    <Popover id='popover-basic' style={{ zIndex: 1000000, fontFamily: 'Gilroy-Regular' }} className='fs-12'>
      <Popover.Content>
        <FormattedMessage id='ShareableModal.urlCopied' />
      </Popover.Content>
    </Popover>
  );

  const breadcrumbs = ancestors.map((ancestor) => {
    const ancestorAttributes = getAttributes(ancestor);

    return (
      <div
        key={ancestorAttributes.id}
        css={styles.breadcrumb}
        className={`d-flex ${shareableIsSelected(ancestor) ? 'selected' : ''}`}
        onClick={() => {
          setCurrentShareable(ancestor);
        }}
      >
        <div className='w-100 text-truncate'>{ancestorAttributes.name}</div>
        <div className='fal fa-chevron-right m-l-5 m-r-5 center-center' />
      </div>
    );
  });

  return (
    <>
      <Modal css={styles.modal} show={isVisible} onHide={hide} onClose={hide}>
        <Modal.Header className='d-flex flex-column'>
          <div className='d-flex flex-row align-items-center flex-grow-1 w-100 p-0 m-b-5 m-t-10'>
            <Modal.Title className='d-flex m-b-5 align-items-center p-0'>
              <div className='m-r-10'>
                {currentShareable.data.type === 'directory' && <FolderIcon />}
                {currentShareable.data.type === 'document' && <FileIcon />}
              </div>
              <div className='font-weight-bold fs-16 text-black m-r-10'>
                <FormattedMessage id='ShareableModal.share' values={{ name: getAttributes(basicShareable).name }} />
              </div>
            </Modal.Title>

            <button className='close' aria-label='Close' onClick={hide}>
              <span aria-hidden='true'>
                <CloseIcon />
              </span>
            </button>
          </div>
          <div className='d-flex flex-row flex-grow-1 w-100 p-0 m-b-15'>
            {breadcrumbs}
            <div
              css={styles.breadcrumb}
              className={`d-flex text-truncate ${shareableIsSelected(basicShareable) ? 'selected' : ''}`}
              onClick={() => {
                setCurrentShareable(basicShareable);
              }}
            >
              {ancestors.length > 0 && <div>{getAttributes(basicShareable).name}</div>}
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          {getAttributes(currentShareable).share && (
            <ContactPicker
              dropdownOptions={contactsForPicker}
              values={[]}
              dropdownIsOpen={false}
              onToggleOption={toggleContact}
              isSelected={isSelected}
              placeholder={intl.formatMessage({ id: 'ShareableModal.contactsAccess' })}
            />
          )}

          {getAttributes(currentShareable).share && (
            <div className='contact-list m-t-10 m-b-10'>
              <ContactsTable
                contacts={contacts}
                sharedContactIds={sharedContactIds}
                ancestorSharedContactIds={ancestorSharedContactIds}
                permittedContactIds={getAttributes(currentShareable).permitted_contact_ids}
                onDelete={removeContact}
              />
            </div>
          )}

          <div className='footer'>
            <div className='d-flex align-items-center justify-content-between m-t-15'>
              <div className='b-1-solid m-r-10 w-334'>
                <div className='fs-10 text-dark-navy text-truncate font-weight-semibold p-5 p-l-10'>
                  {getAttributes(currentShareable).url}
                </div>
              </div>
              <div>
                <OverlayTrigger trigger='click' placement='top' overlay={popover} rootClose>
                  <CopyIcon className='cursor-pointer' onClick={copyShareableUrl} />
                </OverlayTrigger>
              </div>
            </div>

            <span className='fs-12 text-light-grey m-b-25'>
              <FormattedMessage id='ShareableModal.onlyContacts' />
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});
