/** @jsxRuntime classic */
/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { useMarkNotificationAsRead } from './../hooks/useMarkNotificationAsRead';

import * as styles from './Notification.styles';
import Avatar from '../../v2/shared/Avatar/Avatar';

const Notification = memo(({ id, message, contact, read, createdAgo, onMarkAsRead }) => {
  const wrapperStyles = [styles.wrapper.base, !read && styles.wrapper.unread];

  const { markNotificationAsRead } = useMarkNotificationAsRead({
    onCompleted: onMarkAsRead,
  });

  const handleClick = (event) => {
    event.stopPropagation();

    if (read) return;

    markNotificationAsRead(id);
  };

  return (
    <div css={wrapperStyles} onClick={handleClick}>
      <div className='tw-h-8 tw-w-8'><Avatar imgUrl={contact?.avatarUrl} placeholder={contact?.initials} /></div>

      <div css={styles.body}>
        <div dangerouslySetInnerHTML={{ __html: message }} css={styles.message} />
        <div dangerouslySetInnerHTML={{ __html: createdAgo }} css={styles.createdAgo} />
      </div>
    </div>
  );
});

export { Notification };
