import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import { useMeetingContext } from './MeetingContext';

const initialState = {
  steps: 0,
  hashes: [],
  currentStep: 0,
};

const actionTypes = {
  SET_STEPS: 'MeetingFormStepContext.setSteps',
  SET_CURRENT_STEP: 'MeetingFormStepContext.setCurrentStep',
  PREV_STEP: 'MeetingFormStepContext.prevStep',
  NEXT_STEP: 'MeetingFormStepContext.nextStep',
};

const MeetingFormStepContext = createContext();

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_STEPS:
      return { ...state, steps: payload.steps };
    case actionTypes.SET_CURRENT_STEP:
      return { ...state, currentStep: payload.currentStep };
    case actionTypes.PREV_STEP:
      return { ...state, currentStep: Math.max(0, state.currentStep - 1) };
    case actionTypes.NEXT_STEP:
      return { ...state, currentStep: Math.min(state.currentStep + 1, state.steps - 1) };
  }
};

export const MeetingFormStepContextProvider = ({ steps, children }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, steps: steps.length, hashes: steps });

  const {
    meeting: { slug },
  } = useMeetingContext();

  const { currentStep, hashes } = state;

  const setCurrentStep = (currentStep) => dispatch({ type: actionTypes.SET_CURRENT_STEP, payload: { currentStep } });
  const prevStep = () => dispatch({ type: actionTypes.PREV_STEP });

  const nextStep = () => dispatch({ type: actionTypes.NEXT_STEP });

  // Debounce the hash update to avoid url flickering
  const debouncedUpdateHash = useDebouncedCallback(() => location.replace(`#${hashes[currentStep]}`), 200);

  const updateStepByHash = () => {
    const step = hashes.findIndex((hash) => hash === location.hash.slice(1));
    setCurrentStep(step);
  };

  useEffect(() => {
    if (!location.hash) {
      location.replace(`#${hashes[0]}`);
    } else {
      updateStepByHash();
    }
  }, []);

  useEffect(() => {
    debouncedUpdateHash();
  }, [currentStep]);

  useEffect(() => {
    if (slug) {
      history.replaceState(null, null, `/${getCompanySlug()}/meetings/${slug}/edit#${hashes[currentStep]}`);
    }
  }, [slug]);

  return (
    <MeetingFormStepContext.Provider value={{ currentStep, setCurrentStep, prevStep, nextStep }}>
      {children}
    </MeetingFormStepContext.Provider>
  );
};

MeetingFormStepContextProvider.propTypes = {
  steps: PropTypes.array.isRequired,
  children: PropTypes.node,
};

export const useMeetingFormStepContext = () => useContext(MeetingFormStepContext);
