import moment from 'moment';
import { arrayToIdMap } from './data';

export const serializeNoticeSchedule = (noticeSchedule) => {
  const { contact, __typename, message, scheduledAt, ...fields } = noticeSchedule || {};

  return {
    ...fields,
    message: message || '',
    contactId: contact.id,
    scheduledAt: scheduledAt ? moment.parseZone(scheduledAt) : null,
  };
};

export const serializeMeeting = (meeting) => {
  const { owner, id, __typename, type, chairman, description, meetingType, title, slug } = meeting;

  return {
    title,
    slug,
    meetingId: id,
    contactId: owner.id,
    description: description || '',
    type: type || '',
    meetingDate: moment.parseZone(meeting.meetingDate),
    meetingStartTime: moment.parseZone(meeting.meetingStartTime),
    meetingEndTime: moment.parseZone(meeting.meetingEndTime),
    timezone: meeting.timezone.name,
  };
};

export const serializeMeetingConfig = (meeting) => {
  const { id, config, meetingUrl, passcode, location, locationNotes } = meeting;

  return {
    config,
    meetingUrl: meetingUrl || '',
    passcode: passcode || '',
    location: location || '',
    locationNotes: locationNotes || '',
  };
};

export const serializeZoomMeetingConfig = (zoomMeeting) => {
  return {
    zoomId: zoomMeeting?.zoomId,
    existing: zoomMeeting?.existing || false,
    agenda: zoomMeeting?.agenda || '',
    password: zoomMeeting?.password || '',
    hostVideo: zoomMeeting?.hostVideo || true,
    participantVideo: zoomMeeting?.participantVideo || true,
    waitingRoom: zoomMeeting?.waitingRoom || false,
    joinBeforeHost: zoomMeeting?.joinBeforeHost || true,
    muteUponEntry: zoomMeeting?.muteUponEntry || true,
    autoRecording: zoomMeeting?.autoRecording || 'local',
    existing: zoomMeeting?.existing,
  };
};

export const serializeAttendees = (attendees) =>
  attendees.map(({ id, contactGroupId }) => ({
    contactId: id,
    ...(contactGroupId && { contactGroupId }),
  }));

export const serializeAttendeesAndGroups = (data) => {
  const attendeesIdMap = arrayToIdMap(data.map(({ contact }) => contact));
  const individualAttendees = data
    .filter((attendee) => !attendee.contactGroup)
    .map(({ contact: { id, name, ...rest } }) => ({ label: name, value: id, id, name, ...rest }));
  const groupObject = data
    .filter((attendee) => !!attendee.contactGroup)
    .reduce((groups, attendee) => {
      const {
        contactGroup: { name, id },
      } = attendee;
      if (!groups[name]) groups[name] = { label: name, value: id };
      return groups;
    }, {});
  const groups = Object.keys(groupObject).map((key) => groupObject[key]);
  return [attendeesIdMap, individualAttendees, groups];
};
