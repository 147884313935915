import React, { useState, useEffect, useContext } from 'react';
import Button from 'components/v2/shared/Button';
import Heading from 'components/v2/shared/Heading';
import Modal from 'components/v2/shared/Modal';
import Spinner from 'components/v2/shared/Spinner';
import Inclusions from './Inclusions';
import PurchasePlan from './PurchasePlan';
import { useStripe } from '@stripe/react-stripe-js';
import { Severity } from '../shared/Toast';
import { ToastContext } from 'contexts/v2/ToastContext';
import { getAxiosHeaders } from '../../../utils/getHeaders';
import axios from 'axios';

const PlanDetails = ({ data, onClick, company, taxData, planData, cancelPurchase }) => {
  const [isActiveInclusions, setActiveInclusions] = useState(false);
  const [isActivePurchasePlan, setActivePurchasePlan] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const stripe = useStripe();
  const [cardElement, setCardElemet] = useState(null);
  const [couponCode, setCouponCode] = useState(null);
  const { setToastInfo } = useContext(ToastContext);
  const [toCancelPurchase, setToCancelPurchase] = useState(false);

  const handleOnClickInclusions = () => {
    setActiveInclusions(true);
    setActivePurchasePlan(false);
  };
  const handleOnClickPurchasePlan = () => {
    setActivePurchasePlan(true);
    setActiveInclusions(false);
  };

  const handlePaymentThatRequiresCustomerAction = async ({ subscription, priceId, paymentMethodId }) => {
    if (subscription.status === 'active' || subscription.status === 'trialing') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    const paymentIntent = subscription.latest_invoice.payment_intent;

    if (paymentIntent.status === 'requires_action') {
      return await stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              return {
                priceId: priceId,
                subscription: subscription,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  };

  const handleRequiresPaymentMethod = ({ subscription, paymentMethodId, priceId }) => {
    if (subscription.status === 'active' || subscription.status === 'trialing') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
      throw new Error('Your card was declined.');
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  };


  const createSubscription = async (paymentMethod) => {
    const payload = {
      payment_method: paymentMethod.id,
      company_id: company.id.split('/').pop(),
      product_id: data.id.split('/').pop(),
      promotion_code: couponCode ? couponCode : '',
    };
    try {
      const response = await axios.post(`/${company.slug}/subscriptions/`, payload, getAxiosHeaders());

      if(response.data.error){
        setToastInfo({
          severity: Severity.Error,
          status: 'Error',
          message: response.data.error.message,
        });
      }else {
        const latest_invoice = {
          id : response.data.latest_invoice.id,
          total : (response.data.latest_invoice.total / 100).toFixed(2),
          paid_at : new Date(),
          number : response.data.latest_invoice.number,
          receipt_link: response.data.latest_invoice.invoice_pdf,
          company_id: company.id.split('/').pop(),
          currency : response.data.latest_invoice.currency
        };

        await handlePaymentThatRequiresCustomerAction({
          subscription: response.data,
          paymentMethodId: paymentMethod.id,
          priceId: data.stripeId,
        });
        handleRequiresPaymentMethod({
          subscription: response.data,
          paymentMethodId: paymentMethod.id,
          priceId: data.stripeId,
        });
        onClick(true, latest_invoice);
      }
    } catch (err) {
      setToastInfo({
        severity: Severity.Error,
        status: 'Error',
        message: err.message,
      });
    }
  }

  const completePayment = async () => {
    setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error && error != ' ') {
      setLoading(false);
      setToastInfo({
        severity: Severity.Error,
        status: 'Error',
        message: error.message,
      });
    } else {
      await createSubscription(paymentMethod);
    }
    setLoading(false);
  };

  const onClose = () => {
    setLoading(false);
    onClick(false, null);
  };

  useEffect(() => {
    setActiveInclusions(true);
  }, [planData]);

  const onCardDetailsDone = (cardNumberElemet) => {
    setCardElemet(cardNumberElemet);
  }

  const onAddCoupon = (couponCode) => {
    setCouponCode(couponCode);
  }

  return (
    <>
    <Modal isOpen={toCancelPurchase} onClose={() => { setToCancelPurchase(false) }} size='md' centered>
        <div className='tw-px-12 tw-py-16'>
          <div className='tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>
            Cancel Purchase
          </div>
          <div className='tw-mt-4 tw-font-sans tw-text-14 tw-text-black'>
            Are you sure you want to cancel?
          </div>

          <div className='tw-grid tw-grid-flow-col tw-grid-cols-2 tw-gap-2 tw-mt-4'>
            <div className='tw-col-span-1'>
              <Button
                font='semibold'
                className='tw-text-xs'
                isFullWidth
                size='large'
                variant='red'
                onClick={cancelPurchase}
              >
                Yes, cancel
              </Button>
            </div>
            <div className='tw-col-span-1'>
              <Button
                font='semibold'
                className='tw-text-xs tw-text-red-500 tw-ring tw-ring-solid tw-ring-red-500 tw-ring-2'
                isFullWidth
                size='large'
                variant='transparent'
                onClick={()=>setToCancelPurchase(false)}
              >
                No, continue
              </Button>
            </div>
          </div>

        </div>
      </Modal>
      <div className='tw-mt-6 tw-w-full xl:tw-mt-0'>
        <div className='tw-hidden tw-mt-9 tw-items-center tw-justify-between sm:tw-block sm:tw-flex'>
          <Heading text={data?.name} className='!tw-mb-1' />
        </div>
        <div className='tw-mt-5 tw-hidden tw-items-center tw-justify-between tw-font-sans tw-text-sm tw-font-medium	tw-text-black	sm:tw-block sm:tw-flex'>
          ${data?.price} {data.currency?.toUpperCase()} / {data?.interval}
        </div>
      </div>
      <div className='tw-my-4 tw-mt-7 tw-grid tw-grid-flow-col tw-grid-cols-2 tw-gap-2 tw-py-3'>
        <div className='tw-col-span-1'>
          <Button
            font='semibold'
            isFullWidth
            onClick={handleOnClickInclusions}
            size='small'
            variant={isActiveInclusions ? 'aqua' : 'lavander'}
            className='!tw-font-sans !tw-text-xs'
          >
            Inclusions
          </Button>
        </div>

        <div className='tw-col-span-1'>
          <Button
            font='semibold'
            className='tw-cursor-pointer !tw-font-sans !tw-text-xs !tw-font-semibold'
            isFullWidth
            size='small'
            onClick={handleOnClickPurchasePlan}
            variant={isActiveInclusions ? 'lavander' : 'aqua'}
          >
            Purchase plan
          </Button>
        </div>
      </div>
      <div className='tw-w-full'>{isActiveInclusions && <Inclusions planData={planData} />}</div>
      {isActivePurchasePlan && <PurchasePlan company={company} product={data} taxData={taxData} onCardDetailsDone={onCardDetailsDone} onAddCoupon={onAddCoupon} />}
      {isActivePurchasePlan && (
        <>
          <div className='tw-mt-5'>
            <Button
              font='semibold'
              className='tw-h-11 !tw-text-base'
              isFullWidth
              onClick={completePayment}
              variant='teal'
              isDisabled={cardElement === null}
            >
              Complete payment
            </Button>
          </div>
          <div className='tw-my-4'>
            <Button
              font='medium'
              className='!tw-text-sm tw-font-medium'
              onClick={() => setToCancelPurchase(true)}
              isFullWidth
              variant='transparent'
            >
              Cancel purchase
            </Button>
          </div>
        </>
      )}
      <Modal isOpen={isLoading} isDismissable={false} centered>
        <div className=' tw-p-8'>
          <div className='tw-relative tw-mt-8 tw-mb-5 tw-p-5'>
            <Spinner />
          </div>
          <div className='tw-mt-8'>
            <div className='tw-text-center'>
              <span className='tw-text-center tw-font-sans tw-text-lg tw-font-semibold tw-text-black'>
                Processing, please wait.
              </span>
            </div>
          </div>
          <div className='tw-mt-5'>
            <Button
              font='medium'
              className='!tw-text-sm tw-font-medium tw-text-red-400'
              isFullWidth
              onClick={onClose}
              variant='transparent'
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PlanDetails;
