import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as LogoIcon } from 'images/logo.svg';
import { SignatureDropdown } from './SignatureDropdown';
import { SubmitButton } from './SubmitButton';
import { Overlay, ShowNavigation, Bar } from './styles';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ToAppButton, ToAppBarWrapper, Brand } from 'components/shared/AppHeader/styles';

export const ActionBar = ({
  isLoaded,
  readyToSubmit,
  submitSignatures,
  isSigning,
  handleNextClick,
  loggedIn,
  showNavigation,
  documentFields,
  isCompleted,
  handleRowClick,
  setShowNavigation,
}) => {
  const { isDesktop } = useWindowDimensions();
  const onShwoNavigation = useCallback(() => {
    setShowNavigation(true);
  }, [setShowNavigation]);
  return (
    <Bar>
      <ToAppBarWrapper isdesktop={isDesktop}>
        <Brand>
          <LogoIcon />
          <h3 className='font-weight-bold fs-16 m-0 text-color-primary'>
            <FormattedMessage id='ActionBar.shaparency' />
          </h3>
          {loggedIn && isDesktop && (
            <a href='/users/sign_in' className='btn btn-inverted m-l-20'>
              <FormattedMessage id='ActionBar.toApp' />
            </a>
          )}
          {loggedIn && !isDesktop && (
            <ToAppButton href='/users/sign_in' className='m-l-20'>
              <FormattedMessage id='ActionBar.toApp' />
            </ToAppButton>
          )}
        </Brand>

        {isLoaded && !isSigning && !readyToSubmit && <Overlay />}

        <div>
          {isLoaded && (
            <SubmitButton
              readyToSubmit={readyToSubmit}
              isSigning={isSigning}
              handleClick={readyToSubmit ? submitSignatures : handleNextClick}
            />
          )}
          {isSigning && (
            <ShowNavigation onClick={onShwoNavigation} shownavigation={showNavigation}>
              <i className='fal fa-chevron-left c-secondary fs-16' />
            </ShowNavigation>
          )}
          {isLoaded && (
            <SignatureDropdown
              documentFields={documentFields}
              isCompleted={isCompleted}
              handleRowClick={handleRowClick}
              setShowNavigation={setShowNavigation}
              showNavigation={showNavigation}
            />
          )}
        </div>
      </ToAppBarWrapper>
    </Bar>
  );
};
