import { css } from '@emotion/core';
import { colors, fonts, media } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const scriptFonts = (isActive) => css`
  color: ${colors.black};
  opacity: ${isActive ? 1 : 0.3};
`;

export const radioLabel = css`
  &:hover {
    cursor: pointer;
  }

  padding-left: 10px !important;
  max-width: 125px !important;

  &:before {
    left: 6px !important;
  }

  &:after {
    left: 12px !important;
  }
`;

export const error = css`
  font-size: 14px;
  color: ${colors.warning};
`;

export const signatoryName = css`
  padding-left: 2px;
  position: absolute;
  z-index: 2;
  width: 100%;
  max-width: 200px;
`;

export const scaleIcons = css`
  position: absolute;
  top: 9px;
  left: 11px;
`;

export const scaleIcon = (isDisabled) => css`
  color: ${isDisabled ? colors.lighterGrey : colors.lightGrey};
  font-weight: 400;

  &:hover {
    cursor: ${isDisabled ? 'auto' : 'pointer'};

    color: ${isDisabled ? colors.lighterGrey : colors.black};
  }
`;

export const scriptFont = (isScript) => css`
  position: relative;
  height: 35px;
  line-height: 35px;

  span {
    position: absolute;
    top: 0;
    z-index: 2;
  }

  &:hover {
    cursor: ${isScript ? 'pointer' : 'default'};
  }
`;

export const clear = (isVisible) => css`
  color: ${colors.purple};
  font-size: 14px;
  visibility: ${isVisible ? 'visible' : 'hidden'};
  &:hover {
    cursor: pointer;
  }
`;

export const canvas = css`
  width: 100%;
  height: 236px;
`;

export const canvasWrapper = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${colors.backgroundGrey};
  border: 1px solid ${colors.borderGrey};
`;

export const dropzone = css`
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div:focus {
    outline: none;
  }
`;

export const checkboxes = css`
  label {
    ${fonts.bold}
    font-size: 12px;
    color: ${colors.headingGrey};
  }

  input:checked ~ label {
    color: ${colors.purple};
  }
`;

export const separator = css`
  position: absolute;
  left: 0;
  right: 0;
  border-bottom: 1px solid ${colors.modalBorder};
`;

export const footer = css`
  background: #f8f9fa;
`;

export const disabledInput = css`
  background: ${colors.grey} !important;
  color: ${colors.mediumGrey};
  font-size: 12px;
  border-color: ${HexToRgba(colors.lightGrey, 40)};
`;

export const arrow = css`
  top: 0;
  right: 0px;
  z-index: 1;
  height: 35px;
  width: 45%;
  position: absolute;
  display: flex;
`;

export const rectangle = css`
  ${fonts.semiBold}
  background: #E6F8F9;
  border: 1px solid ${colors.cyan};
  line-height: 40px;
  letter-spacing: 0.08em;
  padding-left: 10px;
  border-left: none;
  text-align: center;
  color: ${colors.white};
  width: 100%;
  text-transform: uppercase;
`;

export const triangleLeft = css`
  width: 0;
  height: 0;
  border-top: 17.5px solid transparent;
  border-bottom: 17.5px solid transparent;
  border-right: 17.5px solid ${colors.cyan};
`;

export const innerTriangleLeft = css`
  width: 0;
  height: 0;
  border-top: 16.5px solid transparent;
  position: relative;
  top: -16.5px;
  left: 1px;
  border-bottom: 16.5px solid transparent;
  border-right: 16.5px solid #e6f8f9;
`;

export const saveSignatureButton = css`
  ${media.mobile} {
    position: absolute;
    top: 600px;
  }
`;
