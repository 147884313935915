/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import ReactDOM, { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import BounceLoader from 'react-spinners/BounceLoader';

import { VARIANTS } from './constants';

import { colors } from 'styles';
import * as styles from './Spinner.styles';

const Spinner = ({ show = true, variant = 'regular', className }) => {
  const portalTarget = document.querySelector('body');

  const renderSpinner = (
    <div css={[styles.container.base, styles.container[variant]]} className={className}>
      <BounceLoader loading={show} color={colors.cyan} />
    </div>
  );

  const variantMap = {
    regular: renderSpinner,
    global: createPortal(renderSpinner, portalTarget),
  };

  return variantMap[variant];
};

Spinner.propTypes = {
  variant: PropTypes.oneOf(VARIANTS),
  show: PropTypes.bool,
  className: PropTypes.string,
};

export default Spinner;
