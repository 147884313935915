import { useState } from 'react';
import { useDeleteResolutionMutation } from './../data/mutations/DeleteResolutionMutation';

const useDeleteResolution = ({ onCompleted }) => {
  const update = (cache, { data: { deleteResolution } }) => {
    const { vote } = deleteResolution;

    onCompleted(vote);
  };

  const [mutate, { loading: isDeleting }] = useDeleteResolutionMutation({ update });

  const deleteResolution = (resolutionId) => mutate({ variables: { input: { resolutionId: resolutionId } } });

  return {
    isDeleting,
    deleteResolution,
  };
};

export { useDeleteResolution };
