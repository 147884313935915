import { gql, useQuery } from '@apollo/client';

const CURRENT_USER_PROFILE_QUERY = gql`
  query CurrentUserProfileQuery {
    countries {
      value: id
      label: name
    }

    timezones {
      label
      value: name
    }

    dateFormats {
      label
      value
    }

    currentUser {
      id

      firstName
      lastName
      email
      phoneNumber
      initials
      createdAt
      signatureUrl

      mailConfig {
        voteCancelled
        voteOpened
        votePublished
        voteUpdated
        taskAssigned
        taskReminder
        taskUpdated
        itemShared
        minuteReviewRequested
        minuteApproved
        minuteCommentReceived
        meetingRsvp
        meetingNotice
        meetingDocumentsPublished
        meetingAttendeeRemoved
        meetingInvite
        meetingUpdated
        meetingCancelled
        meetingStarted
        meetingAssignedAgenda
        newChatMessage
      }

      zoomIntegration {
        name
        active
        connectUrl
        disconnectUrl
        logoUrl
        emailIdentifier
      }

      calendarIntegrations {
        name
        active
        connectUrl
        disconnectUrl
        logoUrl
        emailIdentifier
      }

      dateFormat {
        label
        value
      }

      address {
        id
        address1
        address2
        zip
        city

        country {
          value: id
          label: name
        }

        timezone {
          value: name
          label
        }
      }
    }
  }
`;

const useCurrentUserProfileQuery = (options) => useQuery(CURRENT_USER_PROFILE_QUERY, options);

export { CURRENT_USER_PROFILE_QUERY, useCurrentUserProfileQuery };
