import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const black = '#2C2E3F';
export const cyan = '#00BAC6';
export const lighterCyan = '#F7FEFF';
export const lightBlue = '#F7FAFD';
export const lightCyan = '#E6F8F9';
export const lightestCyan = '#EDFAFB';
export const borderCyan = '#B7E6E9';
export const white = '#FFFFFF';
export const yellow = '#FFCE7B';
export const lightYellow = '#FFFDF5';
export const alertOrange = '#FFA918';
export const orange = '#FF7A00';
export const muted = '#6c757d';
export const lightOrange = '#FFF9F2';
export const warning = '#FF3D81';
export const purple = '#662D91';
export const lightPurple = '#AC8FC1';
export const lightestPurple = '#F0EAF4';
export const grey = '#F2F2F2';
export const red = '#FF7A7A';
export const lightRed = HexToRgba(red, 60);
export const folderGrey = '#FCFCFC';
export const lightestGrey = '#FAFAFA';
export const lighterGrey = '#D1D3DA';
export const lightGrey = '#98A4AF';
export const mobileGrey = '#DDE0ED';
export const darkerGrey = '#E3E5E9';
export const darkGrey = '#F8F8FA';
export const backgroundGrey = '#F7F8F9';
export const placeholderGrey = '#F2F2F2';
export const agendaGrey = '#F5F6F7';
export const mediumGrey = '#787D96';
export const headingGrey = '#C5CADA';
export const borderColor = '#e7eaf3';
export const brandBorder = '#E6E8F1';
export const modalBorder = '#E5EAEE';
export const borderGrey = '#E6E6E6';
export const borderSecondGrey = '#dde0ed';
export const borderLightGrey = '#EDEEF1';
export const inputBorder = '#F0F1F1';
export const border = HexToRgba(headingGrey, 60);
export const separator = '#ECEDF0';
export const accentColor = '#FF3D81';
export const drawerTableGrey = '#EBE9F3';
export const notificationGrey = '#EBF0F4';
export const lilac = '#EFEDFB';
export const lilacBorder = '#E7E4F9';
export const mobileHeadingBackground = '#ededed';
export const linkWater = '#d8d9e2';
export const zircon = '#D6DBDF';
export const greyBorder = '#E2E2E2';
export const iconGrey = '#F3F3F3';
export const silver = '#CCCCCC';
export const sherpaBlue = '#00485a';
export const astronautBlue = '#004D61';
export const badgeGrey = '#E0DEF0';
export const badgePink = '#FF7A7A';
export const badgeGreen = '#44AA5A';
export const chipGrey = '#F6F7FC';
export const lightestOrange = '#FFDCBC';
export const iron = '#D1D1D5';
