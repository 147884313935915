import { useState } from 'react';
import { useUpdateDirectoryMutation } from './../data/mutations/UpdateDirectoryMutation';
import { useFlashActions } from 'store/flashStoreContext';

const useUpdateDirectory = ({ id, onCompleted }) => {
  const update = (cache, { data: { updateDirectory } }) => {
    const { errors } = updateDirectory;

    if (errors.length) {
      return setError(errors[0].message);
    }

    onCompleted();
  };

  const { setFlash } = useFlashActions();

  const onError = ({ message }) => setFlash(message);

  const [error, setError] = useState(null);
  const [mutate, { loading: isUpdating }] = useUpdateDirectoryMutation({ update, onError });

  const resetError = () => setError(null);

  const updateDirectory = (name) => {
    mutate({ variables: { input: { name, directoryId: id } } });
  };

  return {
    error,
    resetError,
    updateDirectory,
    isUpdating,
  };
};

export { useUpdateDirectory };
