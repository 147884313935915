/** @jsx jsx */

import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';

import * as styles from './CheckBox.styles';

const CheckBox = memo(
  ({ id, name, label, badgeClass, badgeText, className, onChange, isChecked, isDisabled, isSwitch }) => {
    return (
      <div
        css={styles.checkbox}
        className={`${
          isSwitch ? 'custom-control custom-switch shaparency-switch cyan' : 'checkbox checkbox-success'
        } ${className} ${isChecked && 'checked'}`}
      >
        <input
          type='checkbox'
          id={id}
          name={name}
          checked={isChecked}
          disabled={isDisabled}
          onChange={onChange}
          className={`${isSwitch && 'custom-control-input'}`}
          css={styles.input}
        />

        <label
          css={styles.label}
          htmlFor={id}
          className={`${isSwitch && 'custom-control-label'} fs-14 text-black ${!label && 'p-l-0'}`}
        >
          {label}
        </label>

        {badgeClass && badgeText && <div className={`m-l-5 badge ${badgeClass}`}>{badgeText}</div>}
      </div>
    );
  }
);

export { CheckBox };
