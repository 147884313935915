import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const container = {
  base: css`
    --hover-color: #f4f3f8;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 16px;
    border-bottom: 1px solid ${colors.modalBorder};

    .directory__name {
      ${fonts.regular}
      margin-left: 7px;
      max-width: 300px;
      font-size: 14px;
      line-height: 15px;
      color: ${colors.black};
    }

    &:hover {
      background: var(--hover-color);
    }
  `,

  selected: css`
    background: var(--hover-color);

    .directory__name {
      ${fonts.semiBold}
      color: ${colors.purple};
    }

    .directory__icon {
      path {
        fill: ${colors.purple};
      }
    }
  `,
};

export const arrowContainer = css`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    svg {
      opacity: 0.7;
    }
  }
`;
