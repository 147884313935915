/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import * as styles from './CommentThreadWrapper.styles';

const CommentThreadWrapper = memo(({ isVisible, onShow, onHide, children }) => {
  const onAnimationEnd = () => {
    if (!isVisible) {
      onHide();
    } else {
      onShow();
    }
  };

  const threadStyles = [styles.thread.base, isVisible ? styles.thread.open : styles.thread.closed];

  return (
    <div css={threadStyles} onAnimationEnd={onAnimationEnd}>
      {children}
    </div>
  );
});

export { CommentThreadWrapper };
