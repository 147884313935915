/** @jsx jsx */
import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { VoterResultsTable } from './VoterResultsTable';

import * as styles from './Results.styles';

const Results = memo(({ pollResults, answerOptions, showDetails }) => {
  const [showVoterDetails, setShowVoterDetails] = useState(false);

  const intl = useIntl();

  const toggleDetails = () => {
    if (showVoterDetails) {
      return setShowVoterDetails(false);
    } else {
      return setShowVoterDetails(true);
    }
  };

  const chevronStyles = [styles.chevron.base, showVoterDetails && styles.chevron.rotated];

  const results = answerOptions.map((option, index) => {
    const answers = pollResults.answers[option];
    const answerCount = (answers && answers.length) || 0;
    const percentage = pollResults.answer_count ? (answerCount / pollResults.answer_count) * 100 : 0;

    return (
      <div key={`option-${index}`} css={styles.resultRow}>
        <div className='progress' css={styles.progressBar(percentage)} />
        <div className='text text-black d-flex'>
          <div className='font-weight-bold fs-14'>{option}</div>
          <div className='m-l-5 fs-12'>
            <FormattedMessage id='Results.voteCount' values={{ count: answerCount }} />
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div css={styles.detailsContainer}>
        <div className='m-t-15' css={styles.resolutionPollVotes}>
          {results}
        </div>
        {showDetails && (
          <div className='d-flex cursor-pointer justify-content-center m-t-10' onClick={toggleDetails}>
            <div className='text-primary fs-12 font-weight-semibold text-center' css={styles.showDetails}>
              {`${
                showVoterDetails
                  ? intl.formatMessage({ id: 'Results.hideDetails' })
                  : intl.formatMessage({ id: 'Results.showDetails' })
              }`}
            </div>
            <i className='fa fa-chevron-down' css={chevronStyles} />
          </div>
        )}
      </div>
      <VoterResultsTable show={showVoterDetails} pollResults={pollResults} answerOptions={answerOptions} />
    </>
  );
});

export { Results };
