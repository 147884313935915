import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const badge = {
  base: css`
    ${fonts.regular}
    border-radius: 4px;
    display: inline-flex;
    padding: 3px 5px;
    min-width: 65px;
    align-items: center;
    font-size: 12px;
    justify-content: center;
    line-height: 1.2;
  `,

  white: css`
    background: ${colors.white};
    color: ${colors.lightGrey};
    border: 0.8px solid ${colors.lightGrey};
  `,

  orange: css`
    background: ${colors.lightestOrange};
    color: ${colors.orange};
  `,

  red: css`
    color: ${colors.white};
    background: ${colors.red};
  `,

  lightPurple: css`
    color: ${colors.purple};
    background: ${colors.badgeGrey};
  `,
};
