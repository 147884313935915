import { gql, useQuery } from '@apollo/client';
import { NOTIFICATION_FRAGMENT } from './../fragments/NotificationFragment';
import { CONTACT_FRAGMENT } from 'data/fragments/ContactFragment';

const NOTIFICATIONS_QUERY = gql`
  query notifications {
    currentContact {
      id

      notifications {
        ...NotificationFields

        contact {
          ...ContactFields
        }
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
  ${CONTACT_FRAGMENT}
`;

const useNotificationsQuery = (options) => useQuery(NOTIFICATIONS_QUERY, options);

export { NOTIFICATIONS_QUERY, useNotificationsQuery };
