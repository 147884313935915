import { css } from '@emotion/core';
import { colors } from 'styles';

export const container = css`
  .ScrollbarsCustom-Wrapper {
    inset: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
  }

  .ScrollbarsCustom-Track {
    right: 6px !important;
    z-index: 2;
    width: 4px !important;
    background: rgba(209, 211, 218, 0.2) !important;

    .ScrollbarsCustom-Thumb {
      background: #b9c0c7 !important;
    }
  }
`;
