import { css } from '@emotion/core';
import { colors, fonts } from 'styles';
import { HexToRgba } from 'components/shared/helpers/HexToRgba';

export const container = css`
  width: 320px;
  background-color: ${colors.white};

  @media (max-width: 810px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: ${colors.silver} 1px solid;
  }
`;

export const draftBanner = css`
  color: ${colors.orange};
  background-color: ${colors.lightOrange};
  font-size: 12px;
  padding: 15px;
  border: solid 1px ${colors.orange};
  border-radius: 5px;
  margin: 10px;

  @media (max-width: 810px) {
    display: none;
  }
`;

export const topList = css`
  ${fonts.semiBold}
  color: ${colors.black};
  font-size: 12px;
  padding: 15px 35px;
  list-style: none;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 810px) {
    display: none;
  }
`;

export const mobileList = {
  base: css`
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 53px;
    padding-top: 25px;
    padding-right: 25px;
    background-color: ${colors.white};
    z-index: 10;
    transform: translateY(100%);
    transition: transform 250ms ease;
    list-style: none;
  `,

  open: css`
    transform: translateY(0);
  `,
};

export const backButton = css`
  ${fonts.bold}
  font-size: 14px;
  background-color: ${colors.darkGrey};
  width: 100%;
  height: 60px;
  color: ${colors.purple};
  border: none;
  text-align: left;
  padding: 0 20px;

  @media (max-width: 1224px) {
    height: 52px;
  }
`;

export const docList = css`
  padding: 15px 0px 0px 20px;
  list-style: none;
  border-bottom: ${HexToRgba(colors.lightGrey, 40)} 1px solid;
  margin-bottom: 15px;
  margin-left: 15px;
`;

export const docItem = css`
  display: flex;
  height: 40px;
  color: ${colors.purple};
  align-items: center;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 0 20px;
  transform: translateX(-20px);

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: ${colors.purple};
    color: ${colors.white};
  }
`;

export const closeButton = css`
  border: none;
  background: transparent;
  position: absolute;
  top: 18px;
  right: 15px;
  width: 40px;
  height: 40px;
  padding: 0;
`;

export const downloadButton = css`
  border: none;
  width: 50px;
  cursor: pointer;
  height: 52px;
  display: none;
  background-color: ${colors.white};

  @media (max-width: 810px) {
    display: block;
  }

  i {
    color: ${colors.cyan};
  }
`;

export const tocButton = css`
  border: none;
  width: 50px;
  cursor: pointer;
  height: 52px;
  display: none;
  background-color: ${colors.purple};

  @media (max-width: 810px) {
    display: block;
  }

  i {
    color: ${colors.white};
  }
`;
