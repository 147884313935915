import { gql, useMutation } from '@apollo/client';
import { TASK_FRAGMENT } from 'data/v2/fragments/TaskFragment';

const CREATE_TASK_MUTATION = gql`
  mutation createTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      task {
        ...TaskFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${TASK_FRAGMENT}
`;

const useCreateTaskMutation = (options) => useMutation(CREATE_TASK_MUTATION, options);

export { CREATE_TASK_MUTATION, useCreateTaskMutation };
