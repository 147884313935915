/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import { PropTypes } from 'prop-types';

import * as styles from './FormStepItem.styles';

const FormStepItem = ({ title, isActive, onClick }) => {
  return (
    <div css={styles.container} onClick={onClick}>
      <div css={[styles.icon.base, isActive && styles.icon.active]} />
      <a css={[styles.step.base, isActive && styles.step.active]}>{title}</a>
    </div>
  );
};

FormStepItem.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  isActive: PropTypes.bool,
};

export default FormStepItem;
