const updateById = (array, payload) => {
  return array.map((item) => {
    if (item.id !== payload.id) {
      return item;
    }

    return { ...item, ...payload };
  });
};

export { updateById };
