import React from 'react';
import PropTypes from 'prop-types';
import useSmartPackPreviewQuery from 'components/meetings/data/queries/SmartPackPreviewQuery';
import { getResourceGID } from 'helpers/graphql';
import FileViewer from 'components/v2/shared/FileViewer';

const SmartPackPreviewModal = ({ meetingId, title, onClose }) => {
  const { data, loading } = useSmartPackPreviewQuery({
    fetchPolicy: 'network-only',
    variables: {
      meetingId: getResourceGID('Meeting', meetingId),
    },
  });

  const agendas = data?.currentCompany?.meeting?.agendas || [];
  const arrangedDocuments = agendas.flatMap((agenda) => agenda.documents);
  const initialDocumentUrl = arrangedDocuments[0]?.url;

  const handleDocumentLoaded = async (instance) => {
    const remainingDocs = arrangedDocuments.slice(1);
    const mainDoc = await instance.Core.documentViewer.getDocument();

    for (const document of remainingDocs) {
      await mainDoc.mergeDocument(document.url);
    }
  };

  if (!initialDocumentUrl) {
    return null;
  }

  return (
    <FileViewer
      isOpen
      fileName={title}
      fileUrl={initialDocumentUrl}
      onDocumentLoaded={handleDocumentLoaded}
      onClose={onClose}
    />
  );
};

SmartPackPreviewModal.propTypes = {
  title: PropTypes.string,
  meetingId: PropTypes.number,
  onClose: PropTypes.func,
};

export default SmartPackPreviewModal;
