/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import { AgendaReorderItem as AgendaReorderSubItem } from './';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { ReactComponent as MoveIcon } from 'images/icons/agenda-move.svg';

import * as styles from './AgendaReorderItem.styles';

const AgendaReorderItem = memo(({ item, index, orderMap }) => {
  const childList = orderMap[item.id].map((child, index) => (
    <AgendaReorderSubItem key={`agenda-reorder-item-${child.id}`} item={child} index={index} orderMap={orderMap} />
  ));

  const renderDroppable = ({ innerRef, droppableProps, placeholder }, { isDragging }) => (
    <div ref={innerRef} {...droppableProps} css={styles.children}>
      {childList}
      {placeholder}
    </div>
  );

  const renderDraggable = ({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
    <div ref={innerRef} {...draggableProps} {...dragHandleProps} css={styles.item}>
      <div css={styles.heading}>
        <MoveIcon css={styles.icon} />
        {`${item.number} ${item.heading}`}
      </div>

      <Droppable droppableId={`dropppable-${item.id}`} type={`${item.id}`}>
        {renderDroppable}
      </Droppable>
    </div>
  );

  return (
    <Draggable draggableId={`${item.id}`} index={index}>
      {renderDraggable}
    </Draggable>
  );
});

export { AgendaReorderItem };
