import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const control = css`
  cursor: pointer;
  font-weight: 300;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  width: 24px;
  height: 24px;

  &:hover {
    background: ${colors.placeholderGrey};
  }
`;

export const percentage = css`
  ${fonts.regular}
  color: ${colors.cyan};
  font-size: 12px;
`;

export const container = css`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  cursor: pointer;

  .fa {
    display: none;
    cursor: pointer;
  }

  .percentage {
    cursor: pointer;
    display: block;
  }

  &:hover {
    .fa {
      display: flex;
    }

    .percentage {
      display: none;
    }
  }
`;
