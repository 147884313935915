/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as Contacts } from 'images/icons/no-contacts.svg';

import * as styles from './Table.styles';

const Table = memo(({ tableStyles, signatories, onDelete }) => {
  const intl = useIntl();

  if (!signatories.length) {
    return (
      <div css={styles.placeholder}>
        <div css={styles.placeholderCircle}>
          <Contacts css={styles.placeholderIcon} className='d-flex m-0' />
        </div>

        <div css={styles.placeholderText}>
          <FormattedMessage id='Table.noSignatories' />
        </div>
      </div>
    );
  }

  const tableBody = signatories.map((signatory, index) => (
    <tr css={[styles.tableRow(!signatories[index + 1]), tableStyles?.tableRow]} key={signatory.id || index}>
      <td className='text-truncate' css={[styles.tableCell, tableStyles?.tableCell]}>
        {signatory.name}
      </td>
      <td className='text-truncate' css={[styles.tableCell, tableStyles?.tableCell]}>
        {signatory.email}
      </td>
      <td css={[styles.tableCell, tableStyles?.tableCell]}>
        {signatory.withContact ? intl.formatMessage({ id: 'Table.yes' }) : intl.formatMessage({ id: 'Table.no' })}
      </td>
      {onDelete && (
        <td css={[styles.tableCell, tableStyles?.tableCell]}>
          <i css={styles.deleteIcon} className='far fa-fw fa-trash-alt' onClick={onDelete.bind(this, signatory)} />
        </td>
      )}
    </tr>
  ));

  return (
    <table css={styles.table} id='signatories-table'>
      <thead>
        <tr css={tableStyles?.tableRow}>
          <th css={[styles.tableHeader(35), tableStyles?.tableHeader]}>
            <FormattedMessage id='Table.name' />
          </th>
          <th css={[styles.tableHeader(45), tableStyles?.tableHeader]}>
            <FormattedMessage id='Table.email' />
          </th>
          <th css={[styles.tableHeader(15), tableStyles?.tableHeader]}>
            <FormattedMessage id='Table.contact' />
          </th>
          {onDelete && <th css={[styles.tableHeader(5), tableStyles?.tableHeader]}></th>}
        </tr>
      </thead>
      <tbody css={tableStyles?.tableBody}>{tableBody}</tbody>
    </table>
  );
});

export { Table };
