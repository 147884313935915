import { css } from '@emotion/core';
import { colors } from 'styles';

export const name = css`
  margin-left: 10px;
  max-width: 150px;
  font-size: 14px;
  color: ${colors.black};
`;

export const item = css`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const state = {
  base: css`
    font-size: 10px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  `,

  waiting: css`
    color: ${colors.orange};
  `,

  received: css`
    color: ${colors.cyan};
  `,
};

export const avatarSide = css`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const statusSide = css`
  white-space: nowrap;
`;
