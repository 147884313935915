import { useState } from 'react';
import { useUpdateVoteSettingsMutation } from './../data/mutations/UpdateVoteSettingsMutation';

const useUpdateVoteSettings = ({ onCompleted }) => {
  const update = (cache, { data: { updateVoteSettings } }) => {
    const { vote, errors } = updateVoteSettings;

    if (errors.length) {
      return setErrors(errors);
    }

    onCompleted();
  };

  const [errors, setErrors] = useState(null);
  const [mutate, { loading: isUpdating }] = useUpdateVoteSettingsMutation({ update });

  const updateVoteSettings = (payload) => mutate({ variables: { input: payload } });

  return {
    errors,
    updateVoteSettings,
    isUpdating,
  };
};

export { useUpdateVoteSettings };
