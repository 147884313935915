import { gql, useMutation } from '@apollo/client';

const UPDATE_PROFILE_PASSWORD_MUTATION = gql`
  mutation UpdateProfilePassword($input: UpdateProfilePasswordInput!) {
    updateProfilePassword(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`;

const useUpdateProfilePasswordMutation = (options) => useMutation(UPDATE_PROFILE_PASSWORD_MUTATION, options);

export { UPDATE_PROFILE_PASSWORD_MUTATION, useUpdateProfilePasswordMutation };
