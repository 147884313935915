import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const drawer = {
  base: css`
    z-index: 9991;
    pointer-events: all;
    box-shadow: none;
    transform: translate3d(490px, 0, 0);
    transform: translate3d(100%, 0, 0);
    width: 480px;
    max-width: 100%;
  `,

  state: {
    open: css`
      box-shadow: -20px 4px 50px rgba(54, 71, 98, 0.25);
      transform: translate3d(0, 0, 0);
    `,
  },
};

export const wrapper = css`
  padding: 21px 40px;
`;

export const content = css`
  padding-top: 31px;
`;

export const header = css`
  ${fonts.bold}
  color: ${colors.black};
  font-size: 18px;
`;

export const button = css`
  ${media.tablet} {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;
