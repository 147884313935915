import React, { memo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { client } from 'utils/apollo';
import { FlashProvider } from 'store/flashStoreContext';
import { TaskDrawerProvider } from 'store/taskDrawerStoreContext';
import { MeetingProvider } from 'store/meetingStoreContext';
import { TasksProvider } from 'store/tasksStoreContext';
import { AgendasProvider } from 'store/agendasStoreContext';
import { DocumentsProvider } from 'store/documentsStoreContext';
import { OoEProvider } from 'store/ooeStoreContext';
import { CommentsProvider } from 'store/commentsStoreContext';
import { ReviewsProvider } from 'store/reviewsStoreContext';
import { MinutesProvider } from 'store/minutesStoreContext';
import AppSignalWrapper from '../../v2/shared/AppSignalWrapper';
import { TranslationContextProvider } from '../../../contexts/v2/TranslationContext';

import { EditAgendaView } from 'components/meetings/EditAgendaView';

export default memo(({ meetingId, contactId, contactTimezone, contactTimezoneOffset, root }) => {
  return (
    <AppSignalWrapper tags={{ page: 'meetings/editAgenda' }}>
      <ApolloProvider client={client}>
        <TranslationContextProvider>
          <FlashProvider>
            <TaskDrawerProvider>
              <MeetingProvider>
                <TasksProvider>
                  <AgendasProvider>
                    <DocumentsProvider>
                      <OoEProvider>
                        <CommentsProvider>
                          <ReviewsProvider>
                            <MinutesProvider>
                              <EditAgendaView
                                meetingId={meetingId}
                                contactId={contactId}
                                contactTimezone={contactTimezone}
                                contactTimezoneOffset={contactTimezoneOffset}
                                root={root}
                              />
                            </MinutesProvider>
                          </ReviewsProvider>
                        </CommentsProvider>
                      </OoEProvider>
                    </DocumentsProvider>
                  </AgendasProvider>
                </TasksProvider>
              </MeetingProvider>
            </TaskDrawerProvider>
          </FlashProvider>
        </TranslationContextProvider>
      </ApolloProvider>
    </AppSignalWrapper>
  );
});
