import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const popover = css`
  ${fonts.regular};
  inset: auto auto 26px -5px !important;
  position: relative !important;
  font-size: 12px;
  color: ${colors.black};
  border-radius: 3px;
  box-shadow: -10px 4px 50px rgba(54, 71, 98, 0.25);
  background: ${colors.white};
  width: 160px;
  pointer-events: none;
  text-align: center;
  bottom: auto;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  z-index: 2;
  line-height: 1;
  border: 1px solid ${colors.agendaGrey};

  .arrow {
    display: none;
  }

  .triangle {
    top: 27px;
    width: 0;
    height: 0;
    position: absolute;
    left: 53%;
    right: 0;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${colors.white};
  }
`;
