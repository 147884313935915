import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const resultRow = css`
  width: 100%;
  border: 1px solid ${colors.lilacBorder} !important;
  border-radius: 2px;
  height: 43px;
  margin-bottom: 15px;

  .text {
    position: relative;
    top: -31px;
    left: 15px;
  }
`;

export const progressBar = (width) => css`
  width: ${width}%;
  height: 100%;
  border-radius: 0px;
  background-color: ${colors.lilac};
`;

export const showDetails = css`
  border-bottom: 1px dashed ${colors.purple};
  width: 80px;
`;

export const resolutionPollVotes = css`
  padding-left: 60px;
  padding-right: 12px;
  ${media.mobile} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const chevron = {
  base: css`
    transition: transform 0.1s ease-in-out;
    font-weight: 300;
    margin-left: 7px;
    font-size: 12px;
    color: ${colors.purple};
  `,

  rotated: css`
    transform: rotate(180deg);
  `,
};

export const detailsContainer = css`
  .fa {
    line-height: unset;
  }

  .voter-table {
    padding: 20px;
    max-height: 522px;
    background-color: ${colors.backgroundGrey};
    border-radius: 20px;
  }

  .option {
    width: 80px;
    margin: 5px;

    &.selected {
      ${fonts.semiBold};
      border: 1px solid ${colors.purple};
      border-radius: 44px;
      color: ${colors.purple};
      cursor: default;
    }
  }
`;
