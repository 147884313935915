/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as DocumentIcon } from 'images/icons/agenda-document.svg';
import { useDocumentsActions } from 'store/documentsStoreContext';
import { useFlashActions } from 'store/flashStoreContext';

import * as styles from './AgendaDocumentListItem.styles';
import { icons } from 'styles';

const AgendaDocumentListItem = ({ document, meetingId, agendaId, index, showPlaceholder, isEditable, onPreview }) => {
  const draggableId = String(document.id);

  const { setFlash } = useFlashActions();
  const { deleteDocument } = useDocumentsActions();

  const name = `${document.name}.${document.extension}`;
  const number = `${index + 1}.`;

  const placeholderOffset = index > 0 ? 5 * index : 0;
  const placeholderTop = index * 44 + placeholderOffset;

  const placeholderStyles = styles.placeholder(placeholderTop, showPlaceholder);

  const openPreview = onPreview.bind(this, document);

  const handleDeleteDocument = () => {
    deleteDocument(meetingId, agendaId, document.id).catch(setFlash);
  };

  const renderDraggable = ({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => {
    const draggableStyles = [styles.document.base, isDragging && styles.document.state.dragged];

    return (
      <div
        className='ms-agenda-document-list-item'
        css={draggableStyles}
        ref={innerRef}
        {...draggableProps}
        {...dragHandleProps}
      >
        <div css={styles.wrapper}>
          <div css={styles.innerWrapper}>
            <div css={styles.number}>{number}</div>

            <div css={styles.docIcon}>
              <DocumentIcon />
            </div>

            <div css={styles.name}>
              <span onClick={openPreview}>{name}</span>
            </div>
          </div>
          {isEditable && !isDragging && (
            <i onClick={handleDeleteDocument} css={[icons.base, styles.deleteIcon]} className='far fa-trash-alt' />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div css={placeholderStyles} />

      <Draggable isDragDisabled={!isEditable} draggableId={draggableId} index={index}>
        {renderDraggable}
      </Draggable>
    </>
  );
};

export { AgendaDocumentListItem };
