import React, { memo, useRef, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { withIntl } from '../../../utils/v2/intl';
import Multiselect from '../../shared/Select/Multiselect';

const Filters = memo(({ onChange }) => {
  const intl = useIntl();

  const statusOptions = [
    { value: 'default', label: intl.formatMessage({ id: 'Filters.deafult' }) },
    { value: 'custom', label: intl.formatMessage({ id: 'Filters.custom' }) },
  ];

  const [selectedStatuses, setSelectedStatuses] = useState(statusOptions);

  const loadRoles = () => {
    onChange(selectedStatuses);
  };

  useEffect(() => loadRoles(), [selectedStatuses]);

  const ref = useRef();

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('roles:reload', loadRoles);

      return () => {
        node.removeEventListener('roles:reload', loadRoles);
      };
    }
  }, [selectedStatuses]);

  return (
    <div className='filters col-12 col-md-3 p-0 m-b-20' id='shaparency-role-filters' ref={ref}>
      <Multiselect
        value={selectedStatuses}
        options={statusOptions}
        onChange={setSelectedStatuses}
        classNamePrefix='role-status'
        placeholder={intl.formatMessage({ id: 'Filters.status' })}
        withAllOption
      />
    </div>
  );
});

export default withIntl(Filters);
