import { css } from '@emotion/core';
import { panels, colors, fonts, media } from 'styles';

export const panel = css`
  ${panels.base}
  padding: 24px 0 29px 0;
  /* ${media.tablet} {
    background-color: transparent;
  } */
`;

export const heading = css`
  ${fonts.bold}
  color: ${colors.black};
  font-size: 22px;
  margin: 0;
`;

export const editDetailsHeading = css`
  padding-top: 26px;
  padding-left: 40px;
  padding-right: 40px;
  align-items: center;
`;
