import { gql, useMutation } from '@apollo/client';

const RESEND_VOTE_INVITE = gql`
  mutation resendVoteInvite($input: ResendVoteInviteInput!) {
    resendVoteInvite(input: $input) {
      vote {
        id
      }
    }
  }
`;

const useResendVoteInviteMutation = (options) => useMutation(RESEND_VOTE_INVITE, options);

export { useResendVoteInviteMutation };
