import { gql, useMutation } from '@apollo/client';

const CLEAR_PROFILE_SMART_SIGN_SIGNATURE_MUTATION = gql`
  mutation ClearProfileSmartSignSignature($input: ClearProfileSmartSignSignatureInput!) {
    clearProfileSmartSignSignature(input: $input) {
      user {
        id
        signatureUrl
      }

      errors {
        attribute
        message
      }
    }
  }
`;

const useClearProfileSmartSignSignatureMutation = (options) =>
  useMutation(CLEAR_PROFILE_SMART_SIGN_SIGNATURE_MUTATION, options);

export { CLEAR_PROFILE_SMART_SIGN_SIGNATURE_MUTATION, useClearProfileSmartSignSignatureMutation };
