import { gql, useMutation } from '@apollo/client';

const UPDATE_PROFILE_EMAIL_PREFERENCES_MUTATION = gql`
  mutation UpdateProfileEmailPreferences($input: UpdateProfileEmailPreferencesInput!) {
    updateProfileEmailPreferences(input: $input) {
      user {
        id

        mailConfig {
          voteCancelled
          voteOpened
          votePublished
          voteUpdated
          taskAssigned
          taskReminder
          taskUpdated
          itemShared
          minuteReviewRequested
          minuteApproved
          minuteCommentReceived
          meetingRsvp
          meetingNotice
          meetingDocumentsPublished
          meetingAttendeeRemoved
          meetingInvite
          meetingUpdated
          meetingCancelled
          meetingStarted
          meetingAssignedAgenda
          newChatMessage
        }
      }

      errors {
        attribute
        message
      }
    }
  }
`;

const useUpdateProfileEmailPreferencesMutation = (options) =>
  useMutation(UPDATE_PROFILE_EMAIL_PREFERENCES_MUTATION, options);

export { UPDATE_PROFILE_EMAIL_PREFERENCES_MUTATION, useUpdateProfileEmailPreferencesMutation };
