import { gql, useMutation } from '@apollo/client';
import { VOTE_FRAGMENT } from './../fragments/VoteFragment';

const DELETE_RESOLUTION = gql`
  mutation deleteResolution($input: DeleteResolutionInput!) {
    deleteResolution(input: $input) {
      vote {
        ...VoteFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${VOTE_FRAGMENT}
`;

const useDeleteResolutionMutation = (options) => useMutation(DELETE_RESOLUTION, options);

export { useDeleteResolutionMutation };
