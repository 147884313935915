/** @jsx jsx */

import React, { useState, useRef } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';

import Label from '../Label';
import InputError from '../InputError';
import TextInput from '../TextInput';
import * as styles from './DatePicker.styles';
import { position } from 'styles';

const DatePicker = ({
  name,
  label,
  placeholder,
  isRequired,
  isDisabled,
  className,
  isReadOnly,
  errorMessage,
  value,
  onChange,
  disablePastDates,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const textInputRef = useRef(null);

  const getDatePickerStyles = () => [styles.datePicker.base, isFocused && styles.datePicker.visible];

  const renderNextIcon = () => <i className='fa next fa-chevron-down' />;
  const renderPrevIcon = () => <i className='fa prev fa-chevron-up' />;

  const isOutsideRange = (day) => disablePastDates && day.isBefore(moment().parseZone(), 'day');

  const formatValue = () => value?.format(getUserDateFormat() || 'DD/MM/YYYY') || '';

  const handleDateChange = (value) => {
    onChange(value);
    setIsFocused(false);
    textInputRef.current.blur();
  };

  const handleFocus = () => {
    if (isReadOnly || isDisabled) {
      return;
    }
    setIsFocused(true);
    textInputRef.current.focus();
  };

  const handleBlur = (e) => {
    if (isReadOnly || isDisabled) {
      return;
    }
    const isWithin = e.relatedTarget && e.currentTarget.contains(e.relatedTarget);
    if (isWithin) {
      textInputRef.current.focus();
    } else {
      setIsFocused(false);
    }
  };

  const renderDatePicker = () => {
    if (isDisabled || isReadOnly) {
      return null;
    }

    return (
      <div css={getDatePickerStyles()}>
        <DayPickerSingleDateController
          date={value}
          numberOfMonths={1}
          onDateChange={handleDateChange}
          focused={isFocused}
          orientation='vertical'
          navPrev={renderPrevIcon()}
          navNext={renderNextIcon()}
          hideKeyboardShortcutsPanel={true}
          daySize={28}
          firstDayOfWeek={1}
          verticalHeight={215}
          isOutsideRange={isOutsideRange}
          transitionDuration={300}
        />
      </div>
    );
  };

  const renderDateInput = () => (
    <>
      <TextInput
        ref={textInputRef}
        name={name}
        value={formatValue()}
        placeholder={placeholder}
        required={isRequired}
        isDisabled={isDisabled}
        isReadOnly
        errorMessage={errorMessage}
      />
      <InputError message={errorMessage} />
    </>
  );

  return (
    <div className={className}>
      <Label text={label} />
      <div css={position.relative} onBlur={handleBlur} onFocus={handleFocus}>
        {renderDateInput()}
        {renderDatePicker()}
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  isReadOnly: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.instanceOf(moment),
  onChange: PropTypes.func,
  disablePastDates: PropTypes.bool,
};

export default DatePicker;
