import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import useAnnotationsQuery from '../components/meetings/data/queries/AnnotationsQuery';
import useCreateAnnotationMutation from '../components/meetings/data/mutations/CreateAnnotationMutation';
import useUpdateAnnotationMutation from '../components/meetings/data/mutations/UpdateAnnotationMutation';
import useDeleteAnnotationMutation from '../components/meetings/data/mutations/DeleteAnnotationMutation';
import useAnnotationSubscription from '../components/meetings/data/hooks/useAnnotationSubscription';
import { getResourceGID } from '../helpers/graphql';

const AnnotationContext = createContext();

const initialState = {
  initialAnnotations: [],
  addedAnnotaion: null,
  updatedAnnotation: null,
  removedAnnotation: null,
};

const actionTypes = {
  SET_INITIAL_DATA: 'annotations.setInitialData',
  SET_ADDED_ANNOTATION: 'annotations.setAdded',
  SET_UPDATED_ANNOTATION: 'annotation.setUpdated',
  SET_REMOVED_ANNOTATION: 'annotation.setRemoved',
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_INITIAL_DATA:
      return { ...state, initialAnnotations: payload.initialAnnotations };
    case actionTypes.SET_ADDED_ANNOTATION:
      return { ...state, addedAnnotation: payload.data };
    case actionTypes.SET_UPDATED_ANNOTATION:
      return { ...state, updatedAnnotation: payload.data };
    case actionTypes.SET_REMOVED_ANNOTATION:
      return { ...state, removedAnnotation: payload.data };
  }
};

export const AnnotationProvider = ({ meetingId, smartPackId, children, currentContactId }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [createAnnotation] = useCreateAnnotationMutation();
  const [updateAnnotation] = useUpdateAnnotationMutation();
  const [deleteAnnotation] = useDeleteAnnotationMutation();

  const { data, refetch } = useAnnotationsQuery({
    variables: { id: getResourceGID('Meeting', meetingId) },
    fetchPolicy: 'network-only',
  });

  const handleRemoteAnnotation = (actionType) => (data) => {
    if (data.contactId === currentContactId) {
      return;
    }

    dispatch({ type: actionType, payload: { data } });
  };

  useAnnotationSubscription({
    onCreated: handleRemoteAnnotation(actionTypes.SET_ADDED_ANNOTATION),
    onUpdated: handleRemoteAnnotation(actionTypes.SET_UPDATED_ANNOTATION),
    onRemoved: handleRemoteAnnotation(actionTypes.SET_REMOVED_ANNOTATION),
  });

  // On initial db annotations load
  useEffect(() => {
    if (data) {
      const {
        currentCompany: {
          meeting: {
            smartPack: { annotations },
          },
        },
      } = data;
      const initialAnnotations = annotations.map((annotation) => ({
        data: annotation.data,
        contactId: annotation.contactId,
        gid: annotation.id,
      }));
      dispatch({ type: actionTypes.SET_INITIAL_DATA, payload: { initialAnnotations } });
    }
  }, [data, dispatch]);

  const addAnnotation = async (annotation, xfdf, shared) => {
    const { data } = await createAnnotation({
      variables: {
        input: {
          smartPackId: getResourceGID('SmartPack', smartPackId),
          externalId: annotation.Id,
          data: xfdf,
          shared: shared,
        },
      },
    });

    const annotationId = data.createAnnotation.annotation.id;

    ahoy.track('annotation_added', { annotation_id: annotationId });

    return annotationId;
  };

  const modifyAnnotation = (annotation, data) => {
    ahoy.track('annotation_modified', { annotation_id: annotation.getCustomData('gid') });

    updateAnnotation({
      variables: {
        input: {
          annotationId: annotation.getCustomData('gid'),
          data,
        },
      },
    });
  };

  const removeAnnotation = (annotation) => {
    ahoy.track('annotation_deleted', { annotation_id: annotation.getCustomData('gid') });

    deleteAnnotation({
      variables: {
        input: {
          annotationId: annotation.getCustomData('gid'),
        },
      },
    });
  };

  const refetchAnnotations = () => refetch();

  const { initialAnnotations, addedAnnotation, updatedAnnotation, removedAnnotation } = state;

  return (
    <AnnotationContext.Provider
      value={{
        initialAnnotations,
        addedAnnotation,
        updatedAnnotation,
        removedAnnotation,
        addAnnotation,
        modifyAnnotation,
        removeAnnotation,
        refetchAnnotations,
      }}
    >
      {children}
    </AnnotationContext.Provider>
  );
};

AnnotationProvider.propTypes = {
  meetingId: PropTypes.number,
  smartPackId: PropTypes.number,
  currentContactId: PropTypes.number,
  children: PropTypes.node,
};

export const useAnnotationContext = () => useContext(AnnotationContext);
