import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as PlaceholderIcon } from 'images/icons/no-meetings.svg';
import { ReactComponent as LogoIcon } from 'images/logo.svg';

const FadingOverlay = memo(({ show, resource }) => {
  if (!show) {
    return null;
  }

  const heading = `No ${resource}`;

  return (
    <div className='placeholder-table-fade-overlay'>
      <div className='shaparency-placeholder d-none d-lg-flex'>
        <div className='circle'>
          <PlaceholderIcon />
        </div>

        <div className='rectangle'>
          <div className='fs-19'>
            <FormattedMessage id='FadingOverlay.noResource' values={{ resource }} />
          </div>
          <div className='fs-14 text-nowrap'>
            <FormattedMessage id='FadingOverlay.haveBeenFound' />
          </div>
        </div>
      </div>

      <div className='shaparency-placeholder d-lg-none'>
        <div className='shaparency-placeholder_logo-container'>
          <LogoIcon className='shaparency-placeholder_logo' />
        </div>

        <div className='shaparency-placeholder_text-container'>
          <div className='fs-19'>
            <FormattedMessage id='FadingOverlay.noResource' values={{ resource }} />
          </div>
          <div className='fs-14 text-nowrap'>
            <FormattedMessage id='FadingOverlay.haveBeenFound' />
          </div>
        </div>
      </div>
    </div>
  );
});

export { FadingOverlay };
