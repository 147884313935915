/** @jsx jsx */

import React, { memo, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { Placeholder } from 'components/shared/Placeholder';
import { ReactComponent as TasksIcon } from 'images/icons/no-contacts.svg';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { useMeetingStatus } from 'hooks/useMeetingStatus';

import { TaskTableDesktop } from './TaskTableDesktop';
import { TaskTableMobile } from './TaskTableMobile';

import * as styles from './index.styles';
import { utils } from 'styles';

const TasksTable = memo(
  ({ tasks, agendas, onAdd, onEdit, onDelete, onToggle, adminView, editView, meetingStatus, currentContactId }) => {
    const { isEditable, isInSession } = useMeetingStatus(meetingStatus);
    const adminAccess = adminView && (isEditable || isInSession);
    const editAccess = editView && (isEditable || isInSession);
    const { isDesktop } = useWindowDimensions();

    const showTasks = isDesktop ? (
      <TaskTableDesktop
        adminAccess={adminAccess}
        editAccess={editAccess}
        tasks={tasks}
        agendas={agendas}
        onEdit={onEdit}
        onDelete={onDelete}
        onToggle={onToggle}
        currentContactId={currentContactId}
      />
    ) : (
      <TaskTableMobile
        adminAccess={adminAccess}
        editAccess={editAccess}
        tasks={tasks}
        agendas={agendas}
        onEdit={onEdit}
        onDelete={onDelete}
        onToggle={onToggle}
        currentContactId={currentContactId}
      />
    );

    return (
      <>
        <div className='d-flex align-items-baseline'>
          <h2 css={styles.tableHeading}>
            <FormattedMessage id='TasksTable.tasks' />
          </h2>

          {isDesktop && adminAccess ? (
            <a className='btn btn-primary btn-no-transform' onClick={onAdd}>
              <FormattedMessage id='TasksTable.addNew' />
            </a>
          ) : null}
        </div>

        <div css={utils.separator} />

        {tasks.length > 0 ? (
          showTasks
        ) : (
          <div className='p-t-45'>
            <Placeholder message='No tasks added' image={<TasksIcon />} />
          </div>
        )}
        {!isDesktop && adminAccess ? (
          <div className='m-t-20 p-l-15 p-r-15'>
            <a className='btn btn-responsive btn-primary btn-no-transform' onClick={onAdd}>
              <FormattedMessage id='TasksTable.addNew' />
            </a>
          </div>
        ) : null}
      </>
    );
  }
);

export { TasksTable };
