import axios from 'axios';

export default class API {
  static handleError({ response: { data } }) {
    let message = data.message || 'Something went wrong. Please try again in a few seconds.';

    displayFlashMessage(message, 'error');
  }

  static uploadSignature(documentId, fieldId, accessToken, blob, forAll, onUpload, onError = this.handleError) {
    const formData = new FormData();

    formData.append('access_token', accessToken);
    formData.append('document_field[attachment_attributes][file]', blob);
    formData.append('for_all_empty', forAll);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    return axios
      .put(`/api/${getCompanySlug()}/smart_documents/${documentId}/document_fields/${fieldId}.json`, formData, config)
      .then((response) => response.data)
      .then(onUpload)
      .catch(onError);
  }

  static updateSmartDocument(documentId, params, onUpdate, onError = this.handleError) {
    return axios
      .put(`/api/${getCompanySlug()}/smart_documents/${documentId}.json`, { smart_document: { ...params } })
      .then((response) => response.data)
      .then(onUpdate)
      .catch(onError);
  }

  static searchFoldersAndDocuments(rootId, searchTerm, onLoad) {
    return axios
      .get(`/api/${getCompanySlug()}/v1/directories.json?directory_id=${rootId}&search_type=dir&query=${searchTerm}`)
      .then((response) => response.data)
      .then(onLoad)
      .catch(this.handleError);
  }

  static createSmartDocument(documentId, onSubmit, onError = this.handleError) {
    return axios
      .post(`/api/${getCompanySlug()}/smart_documents.json`, { smart_document: { document_id: documentId } })
      .then((response) => response.data)
      .then(onSubmit)
      .catch(onError);
  }

  static submitSignature(documentId, accessToken, onSubmit) {
    return axios
      .post(`/${getCompanySlug()}/review_documents/${documentId}/signature_approve.json?access_token=${accessToken}`)
      .then((response) => response.data)
      .then(onSubmit)
      .catch(this.handleError);
  }

  static loadSignatureFields(documentId, accessToken, onLoad) {
    return axios
      .get(`/api/${getCompanySlug()}/smart_documents/${documentId}/document_fields.json?access_token=${accessToken}`)
      .then((response) => response.data)
      .then(onLoad)
      .catch(this.handleError);
  }

  static loadFields(documentId, onLoad) {
    const companySlug = getCompanySlug();
    return axios
      .get(`/${companySlug}/smart_sign/${documentId}/document_fields.json`)
      .then((response) => response.data.data)
      .then(onLoad)
      .catch(this.handleError);
  }

  static loadContacts(query, onLoad) {
    const companySlug = getCompanySlug();
    return axios
      .get(`/api/${companySlug}/contacts.json?query=${query}`)
      .then((response) => response.data)
      .then(onLoad)
      .catch(this.handleError);
  }

  static loadSignatories(documentId, onLoad) {
    return axios
      .get(`/api/${getCompanySlug()}/smart_documents/${documentId}/signatories.json`)
      .then((response) => response.data)
      .then(onLoad)
      .catch(this.handleError);
  }

  static deleteSignatory(documentId, signatoryId, onDelete) {
    return axios
      .delete(`/api/${getCompanySlug()}/smart_documents/${documentId}/signatories/${signatoryId}.json`)
      .then((response) => response.data)
      .then(onDelete)
      .catch(this.handleError);
  }

  static updateDocumentFields(documentId, documentFields, onUpdate, onError = this.handleError) {
    const companySlug = getCompanySlug();
    return axios
      .put(`/${companySlug}/smart_sign/${documentId}/configure.json`, this.getDocumentFieldsParams(documentFields))
      .then((response) => response.data.data)
      .then(onUpdate)
      .catch(onError);
  }

  static sendDocument(documentId, message, onSend, onError = this.handleError) {
    const companySlug = getCompanySlug();
    return axios
      .put(`/${companySlug}/smart_sign/${documentId}/send.json?next=true`, {
        smart_document: { email_message: message },
      })
      .then((response) => response.data)
      .then(onSend)
      .catch(onError);
  }

  static createSignatory(documentId, contact, onCreate, onError = this.handleError) {
    return axios
      .post(`/api/${getCompanySlug()}/smart_documents/${documentId}/signatories.json`, this.getSignatoryParams(contact))
      .then((response) => response.data)
      .then(onCreate)
      .catch(onError);
  }

  static getDocumentFieldsParams(documentFields) {
    return {
      configure: {
        fields: documentFields.map((field) => {
          return {
            id: isNaN(field.id) ? null : field.id,
            field_type: field.type,
            signatory_id: field.signatoryId,
            page: field.page,
            position_x: field.position.x,
            position_y: field.position.y,
            format: field.format,
            width: field.width,
            height: field.height,
            _destroy: field.deleted,
          };
        }),
      },
    };
  }

  static getSignatoryParams(contact) {
    return {
      signatory: {
        name: contact.name,
        email: contact.email,
        contact_id: contact.id,
      },
    };
  }
}
