import { css } from '@emotion/core';

export const iframe = css`
  width: 100%;
  border: none;
  height: 90vh;
`;

export const wrapper = css`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;
