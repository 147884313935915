import React from 'react';

const DateCell = () => {
  return (
    <div className='placeholder-date-stub'>
      <div className='placeholder-date-header'>
        <div className='placeholder-text-stub regular grey-darkest w-percent-60' />
      </div>

      <div className='placeholder-date-body'>
        <div className='placeholder-text-stub tiny grey w-percent-60' />
        <div className='placeholder-text-stub tiny grey w-percent-60 m-t-5' />
      </div>
    </div>
  );
};

export { DateCell };
