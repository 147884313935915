/** @jsx jsx */

import React, { memo, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { AgendaDocumentListItem } from './../AgendaDocumentListItem';
import { reorder } from 'helpers/reorder';
import { useDocumentsActions } from 'store/documentsStoreContext';

import * as styles from './AgendaDocumentList.styles';

const AgendaDocumentList = ({ meetingId, agendaId, documents, isEditable, onPreview }) => {
  const [agendaDocuments, setAgendaDocuments] = useState(documents);
  const { updateDocument } = useDocumentsActions();

  useEffect(() => {
    setAgendaDocuments(documents);
  }, [documents]);

  const renderDocuments = (isDragging) => {
    return agendaDocuments.map((document, index) => {
      return (
        <AgendaDocumentListItem
          key={`agenda-document-${document.id}`}
          document={document}
          index={index}
          isEditable={isEditable}
          meetingId={meetingId}
          onPreview={onPreview}
          agendaId={agendaId}
          showPlaceholder={isDragging}
        />
      );
    });
  };

  const onDragEnd = (payload) => {
    const { draggableId, source, destination } = payload;

    if (!destination) return;
    if (destination.index === source.index) return;

    const reordered = reorder(documents, source.index, destination.index);

    setAgendaDocuments(reordered);

    updateDocument(meetingId, agendaId, draggableId, {
      position: destination.index + 1,
    });
  };

  const droppable = ({ placeholder, innerRef, droppableProps }, { isDraggingOver }) => {
    const draggables = renderDocuments(isDraggingOver);

    return (
      <div ref={innerRef} {...droppableProps}>
        {draggables}
        {placeholder}
      </div>
    );
  };

  return (
    <div css={styles.documents}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`${agendaId}-droppable-list`}>{droppable}</Droppable>
      </DragDropContext>
    </div>
  );
};

export { AgendaDocumentList };
