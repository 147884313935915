import { gql, useQuery } from '@apollo/client';

const PRODUCTS_QUERY = gql`
  query {
    products {
      id
      stripeId
      name
      price
      interval
      currency
      deprecated
    }
  }
`;

const useProductsQuery = (options) => useQuery(PRODUCTS_QUERY, options);

export { PRODUCTS_QUERY, useProductsQuery };

