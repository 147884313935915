import { css } from '@emotion/core';
import { media } from 'styles';

export const hideMobile = css`
  ${media.mobile} {
    display: none;
  }
`;

export const hideTablet = css`
  ${media.tablet} {
    display: none;
  }
`;
