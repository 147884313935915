/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { Placeholder } from 'components/shared/Placeholder';
import { ReactComponent as ContactsIcon } from 'images/icons/no-contacts.svg';
import { ReviewListItem } from './../ReviewListItem';

import * as styles from './ReviewList.styles';

const ReviewList = memo(({ reviews, onResend, onDelete }) => {
  const intl = useIntl();

  if (!reviews || !reviews.length) {
    return (
      <div css={styles.placeholder}>
        <Placeholder image={<ContactsIcon />} message={intl.formatMessage({ id: 'ReviewList.noContacts' })} />
      </div>
    );
  }

  const list = reviews.map((review) => (
    <ReviewListItem
      key={`review-${review.id}`}
      reviewer={review.reviewer}
      state={review.status}
      onResend={onResend}
      onDelete={onDelete}
    />
  ));

  return (
    <div className='m-t-30'>
      <div css={styles.wrapper}>{list}</div>
    </div>
  );
});

export { ReviewList };
