/** @jsx jsx */

import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';

import Spinner from 'components/v2/shared/Spinner';
import Divider from 'components/v2/shared/Divider';
import ContentToggle from 'components/v2/shared/ContentToggle';
import Button, { Variants } from 'components/v2/shared/Button';
import Checkbox from 'components/v2/shared/Checkbox';
import NoticeTimeSelectInput from 'components/v2/shared/NoticeTimeSelectInput';
import ContactSelectInput from 'components/v2/shared/ContactSelectInput';
import Text from 'components/v2/shared/Text';
import TextAreaInput from 'components/v2/shared/TextAreaInput';
import InviteAndNoticeSchedule from './../InviteAndNoticeSchedule';

import useNavigation from 'hooks/v2/useNavigation';

import { getFieldData } from 'utils/v2/data';
import { useMeetingFormStepContext } from 'contexts/v2/MeetingFormStepContext';
import { useTimezonesContext } from 'contexts/v2/TimezonesContext';
import { formatDate, formatTime } from 'helpers/formatDateTime';
import { useMeetingContext } from 'contexts/v2/MeetingContext';
import { useCurrentContactContext } from 'contexts/v2/CurrentContactContext';
import { useContactsContext } from 'contexts/v2/ContactsContext';
import { useNoticeScheduleContext } from 'contexts/v2/NoticeScheduleContext';
import { LONG_DATE_FORMAT } from './../constants';

import moment from 'moment';

import * as styles from './InviteAndNoticeStep.styles';

const InviteAndNoticeStep = () => {
  const [errors, setErrors] = useState({});

  const {
    edits,
    updateEdits,
    discardEdits,
    loading,
    getNoticeScheduleOptionByValue,
    noticeScheduleOptions,
    noticeSchedule,
    fetchNoticeSchedule,
    saveNoticeSchedule,
  } = useNoticeScheduleContext();

  const { getTimezoneByValue } = useTimezonesContext();
  const { currentContact } = useCurrentContactContext();
  const { contacts, getContactByValue } = useContactsContext();
  const { meeting } = useMeetingContext();
  const { nextStep } = useMeetingFormStepContext();

  useEffect(() => fetchNoticeSchedule(), []);
  useEffect(() => discardEdits(), []);

  const navigate = useNavigation();
  const isFromCurrentContact = edits.contactId === currentContact.id;

  const handleFieldChange = (fieldName) => (value) => {
    if (errors[fieldName]) {
      setErrors((state) => ({ ...state, [fieldName]: null }));
    }

    updateEdits(fieldName, getFieldData(value));
  };

  const handleOptionChange = ({ value, scheduledAt, momentValue }) => {
    updateEdits('config', value);

    // Do not set `scheduledAt` if `config` is set to `send_once_scheduled`
    // Taking advantage of the fact that `momentValue` will be 0
    if (momentValue) {
      updateEdits('scheduledAt', scheduledAt);
    } else {
      updateEdits('scheduledAt', null);
    }
  };

  const handleNoticeDateTimeChange = (value) => {
    updateEdits('config', null);
    updateEdits('scheduledAt', getFieldData(value));
  };

  const handleCheckboxChange = (value) => {
    if (isFromCurrentContact) {
      updateEdits('contactId', null);
    } else {
      updateEdits('contactId', getFieldData(value));
    }
  };

  const onMessageHide = () => updateEdits('message', '');

  const handleSave = (redirectToIndex) => async () => {
    setErrors({});

    try {
      await saveNoticeSchedule();

      if (redirectToIndex) {
        navigate('meetingsIndex');
      } else {
        nextStep();
      }
    } catch (e) {
      setErrors(e);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Row noGutters>
      <Col lg={12} className='mb-4'>
        <Text isSemiBold text='Meeting&nbsp;' />
        <Text text='scheduled for&nbsp;' />
        <Text isSemiBold text={formatTime(meeting.meetingStartTime)} />
        <Text text='&nbsp;on&nbsp;' />
        <Text isSemiBold text={formatDate(meeting.meetingDate, LONG_DATE_FORMAT)} />
      </Col>

      <Col lg={12} className='mb-2'>
        <Divider isBold position='left' title='Sending from' />

        <ContactSelectInput
          isDisabled={isFromCurrentContact}
          errorMessage={errors.contactId}
          onChange={handleFieldChange('contactId')}
          value={getContactByValue(edits.contactId)}
          options={contacts}
          label='Invite and notice will go from'
        />
      </Col>

      <Col lg={12} className='mb-5'>
        <Checkbox
          label='From myself'
          isChecked={isFromCurrentContact}
          value={currentContact.id}
          onChange={handleCheckboxChange}
        />
      </Col>

      <Col lg={12} className='mb-4'>
        <Divider isBold position='left' title='Notice schedule' />

        <NoticeTimeSelectInput
          showCustomPicker={edits.scheduledAt && !edits.config}
          noticeDateTime={edits.scheduledAt}
          onNoticeDateTimeChange={handleNoticeDateTimeChange}
          errorMessage={errors.config}
          value={getNoticeScheduleOptionByValue(edits.config)}
          onChange={handleOptionChange}
          placeholder='Select notice time'
          label='Select when notice is to go out prior to meeting'
          options={noticeScheduleOptions}
          isSearchable={false}
        />
      </Col>

      <Col lg={12}>
        <InviteAndNoticeSchedule scheduledAt={edits.scheduledAt} timezone={meeting.timezone} />
      </Col>

      <Col lg={12} className='mb-4'>
        <ContentToggle
          isVisible={!!noticeSchedule.message && noticeSchedule.message.length}
          onHide={onMessageHide}
          toggleText='Add optional message to notice'
          labelText='Add message to notice'
        >
          <TextAreaInput placeholder='Add message' value={edits.message} onChange={handleFieldChange('message')} />
        </ContentToggle>
      </Col>

      <Col lg={12}>
        <Button isFullWidth onClick={handleSave()}>
          Next
        </Button>

        <Button isFullWidth variant={Variants.Teal} onClick={handleSave(true)}>
          Save draft
        </Button>
      </Col>
    </Row>
  );
};

InviteAndNoticeStep.propTypes = {};

export default InviteAndNoticeStep;
