/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import Box from 'components/v2/shared/Box';
import Text from 'components/v2/shared/Text';
import Avatar from 'components/v2/shared/Avatar';
import CircleIcon from 'components/v2/shared/CircleIcon';
import TextBadge from 'components/v2/shared/TextBadge';
import TaskItemActionsMenu from './../TaskItemActionsMenu';

import { humanize } from 'utils/v2/strings';

import * as styles from './TaskItem.styles';

const TaskItem = ({ title, status, owner, className }) => {
  const renderAvatar = () => {
    if (owner) {
      return <Avatar size='small' text={owner.initials} />;
    } else {
      return <CircleIcon size='small' onClick={(e) => alert('click')} icon='plus' bgColor='white' />;
    }
  };

  const badgeColorMap = { in_process: 'orange', overdue: 'red' };
  const badgeColor = badgeColorMap[status] || 'lightPurple';

  return (
    <Box variant='task' className='mb-2'>
      <Text fontSize={12} text={title} className='mr-3' isTruncated />

      <div className='d-flex align-items-center'>
        <TextBadge color={badgeColor} text={humanize(status)} className='mr-3' />
        {renderAvatar()}
        <TaskItemActionsMenu className='ml-3' />
      </div>
    </Box>
  );
};

TaskItem.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  className: PropTypes.string,
  owner: PropTypes.shape({
    avatarUrl: PropTypes.string,
    initials: PropTypes.string,
  }),
};

export default TaskItem;
