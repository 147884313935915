/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import * as styles from './Actions.styles';

const Actions = memo(({ onSaveDraft, onSaveAndAdvance, saveDraftLabel }) => {
  return (
    <div css={styles.actions}>
      <div className='d-flex flex-wrap justify-content-center'>
        <a onClick={onSaveDraft} className='btn btn-responsive btn-inverted m-b-10'>
          {saveDraftLabel}
        </a>

        <a onClick={onSaveAndAdvance} className='btn btn-responsive m-l-10 btn-primary m-b-10'>
          <FormattedMessage id='Actions.next' />
        </a>
      </div>
    </div>
  );
});

export { Actions };
