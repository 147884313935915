import { gql } from '@apollo/client';

const ACCESS_ROLE_FRAGMENT = gql`
  fragment AccessRoleFields on AccessRole {
    id
    name
  }
`;

export { ACCESS_ROLE_FRAGMENT };
