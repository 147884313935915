import { gql, useMutation } from '@apollo/client';

const UPDATE_ROLE_PERMISSIONS_MUTATION = gql`
  mutation UpdateRolePermissions($input: UpdateRolePermissionsInput!) {
    updateRolePermissions(input: $input) {
      rolePermissions {
        id
        permitted
      }
    }
  }
`;

const useUpdateRolePermissionsMutation = (options) => useMutation(UPDATE_ROLE_PERMISSIONS_MUTATION, options);

export { UPDATE_ROLE_PERMISSIONS_MUTATION, useUpdateRolePermissionsMutation };
