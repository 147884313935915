import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FiMenu, FiDownloadCloud, FiX } from 'react-icons/fi';
import IconButton from 'components/v2/shared/IconButton';

const PdfViewerTopbar = ({ title, onClose, onDownload, onShowMenu, isViewingMenu }) => (
  <div
    className={clsx(
      'tw-h-[var(--pdf-viewer-topbar-height)]',
      'tw-flex tw-items-center tw-justify-between tw-gap-4',
      'tw-box-border tw-bg-white tw-p-2',
      'tw-border-0 tw-border-b tw-border-solid tw-border-gray-200'
    )}
  >
    <div className='tw-flex tw-items-center tw-gap-1'>
      <IconButton title='Close' onClick={onClose} className='!tw-p-2 hover:!tw-bg-gray-200'>
        <FiX className='tw-h-5 tw-w-5 tw-text-purple-500' />
      </IconButton>

      {onShowMenu && (
        <IconButton
          title='Show Menu'
          onClick={onShowMenu}
          className={clsx('!tw-p-2', {
            'hover:!tw-bg-gray-200': !isViewingMenu,
            '!tw-bg-gray-200 hover:!tw-bg-opacity-80': isViewingMenu,
          })}
        >
          <FiMenu className='tw-h-5 tw-w-5 tw-text-purple-500' />
        </IconButton>
      )}
    </div>

    <div className='tw-truncate tw-text-base tw-font-semibold tw-text-purple-500'>{title}</div>

    <div className='tw-flex tw-items-center tw-gap-1'>
      {onShowMenu && (
        <IconButton onClick={onShowMenu} className='tw-invisible !tw-p-2'>
          <FiMenu className='tw-h-5 tw-w-5' />
        </IconButton>
      )}

      <IconButton title='Download' onClick={onDownload} className='!tw-p-2 hover:!tw-bg-gray-200'>
        <FiDownloadCloud className='tw-h-5 tw-w-5 tw-text-purple-500' />
      </IconButton>
    </div>
  </div>
);

PdfViewerTopbar.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  onDownload: PropTypes.func,
  onShowMenu: PropTypes.func,
  isViewingMenu: PropTypes.bool,
};

export default PdfViewerTopbar;
