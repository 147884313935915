const getMeetingTypeOptions = (meetingTypes) => {
  return meetingTypes.map((meetingType) => {
    return {
      value: meetingType[1],
      label: meetingType[0],
    };
  });
};

const getMeetingType = (meeting, meetingTypeOptions) => {
  return meetingTypeOptions.find(({ value }) => value === meeting.meeting_type_id);
};

export { getMeetingType, getMeetingTypeOptions };
