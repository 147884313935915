import { useMoveDirectoryMutation } from './../data/mutations/MoveDirectoryMutation';
import { useApolloCacheHelpers } from './../hooks/useApolloCacheHelpers';

const useMoveDirectory = ({ parent, onCompleted, onError }) => {
  const { addDirectoryToCache, removeDirectoryFromCache } = useApolloCacheHelpers();

  const update = (cache, { data: { moveDirectory } }) => {
    const { directory, errors } = moveDirectory;

    if (errors.length) {
      return onError(errors[0].fullMessage);
    }

    removeDirectoryFromCache(parent, directory);
    addDirectoryToCache(directory.parent, directory);
    onCompleted();
  };

  const [mutate, { loading: isMoving }] = useMoveDirectoryMutation({ update });

  const moveDirectory = (directoryId, destinationId) => {
    mutate({ variables: { input: { directoryId, destinationId } } });
  };

  return { moveDirectory, isMoving };
};

export { useMoveDirectory };
