/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { HighlightTag } from 'components/shared/HighlightTag';
import { Dropdown } from 'components/shared/Dropdown';
import { DropdownOption } from 'components/shared/DropdownOption';

import { useRegExpFormatter } from 'hooks/useRegExpFormatter';

import Highlighter from 'react-highlight-words';

import * as styles from './TimezoneDropdown.styles';

const TimezoneDropdown = memo(({ options, selectedTimezone, onSelect }) => {
  let [isOpen, setIsOpen] = useState(false);
  let [searchTerm, setSearchTerm] = useState('');

  const intl = useIntl();

  const { formattedRegExp } = useRegExpFormatter(searchTerm);

  const handleInputChange = (event) => setSearchTerm(event.target.value || '');
  const handleFocus = () => setIsOpen(true);
  const handleBlur = () => setIsOpen(false);

  const regex = new RegExp(formattedRegExp, 'gi');
  const pickerOptions = options.filter((option) => option.label.match(regex));
  const hasOptions = pickerOptions.length > 0;

  const checkmark = (
    <div className='checkmark-circle'>
      <div className='checkmark' />
    </div>
  );

  const renderOption = (timezone) => {
    const key = `timezone-picker-${timezone.value}`;
    const onClick = onSelect.bind(this, timezone, handleBlur);
    const isSelected = timezone.value == selectedTimezone.value;

    return (
      <DropdownOption key={key} onClick={onClick}>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <div className='w-100 text-truncate'>
            <Highlighter textToHighlight={timezone.label} highlightTag={HighlightTag} searchWords={[formattedRegExp]} />
          </div>

          {isSelected && checkmark}
        </div>
      </DropdownOption>
    );
  };

  const noResults = <DropdownOption noResults />;
  const dropdownContent = pickerOptions.map(renderOption);
  const dropdownBody = hasOptions ? dropdownContent : noResults;
  const wrapperStyles = styles.wrapper(hasOptions);

  return (
    <>
      <div css={styles.dropdownWrapper}>
        <Dropdown
          dropdownId='timezone'
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputPlaceholder={intl.formatMessage({ id: 'TimezoneDropdown.selectTime' })}
          inputIcon={<i className='fa fa-chevron-down fs-14 fw-300' />}
          inputIconPosition='right'
          inputValue={isOpen ? searchTerm : selectedTimezone.label || ''}
          isOpen={isOpen}
          onInputChange={handleInputChange}
          rotatableIcon
          inputLabel={intl.formatMessage({ id: 'TimezoneDropdown.timezone' })}
        >
          <div css={wrapperStyles}>
            <div css={styles.items} className='cursor-pointer'>
              {dropdownBody}
            </div>
          </div>
        </Dropdown>
      </div>
    </>
  );
});

export { TimezoneDropdown };
