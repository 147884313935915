import { css } from '@emotion/core';
import { fonts, colors, icons } from 'styles';

export const icon = css`
  ${icons.base}
  color: ${colors.lighterGrey};
  font-size: 12px;
  margin-left: 7px;
`;

export const input = (width, hasError) => css`
  ${fonts.regular}
  background: ${colors.white};
  border: 1px solid ${hasError ? colors.red : '#DFDFDF'};
  color: ${hasError ? colors.red : colors.black};
  border-radius: 3px;
  min-width: 1px;
  width: ${width}px;
  box-sizing: content-box;
  cursor: text;
  padding: 3px 5px;
  max-width: 100%;

  &:focus {
    border: 1px solid ${hasError ? colors.red : colors.cyan} !important;
  }
`;

export const measure = css`
  white-space: pre;
  visibility: hidden;
  position: absolute;
  padding: 0;
  opacity: 0;
  left: 0;
  top: 0;
`;

export const staticText = {
  base: css`
    overflow: hidden;
    cursor: text;
    text-overflow: ellipsis;
    white-space: pre;
  `,

  placeholder: css`
    color: ${colors.lightGrey};
  `,
};
