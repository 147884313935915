import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const heading = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 25px 38px;

  ${media.tablet} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const header = css`
  ${fonts.bold}
  color: ${colors.black};
  font-size: 22px;
`;

export const toggleVisibilityLink = css`
  ${fonts.semiBold}
  font-size: 14px;
  color: ${colors.cyan};

  &:hover {
    color: ${colors.cyan};
    opacity: 0.8;
    cursor: pointer;
  }
`;
