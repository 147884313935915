/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import Divider from 'components/v2/shared/Divider';
import RadioButton from 'components/v2/shared/RadioButton';
import Label from 'components/v2/shared/Label';
import Checkbox from 'components/v2/shared/Checkbox';
import SelectInput from 'components/v2/shared/SelectInput';
import Text from 'components/v2/shared/Text';
import TextInput from 'components/v2/shared/TextInput';

import { useZoomMeetingConfigContext } from 'contexts/v2/ZoomMeetingConfigContext';
import { useZoomMeetingTopicsContext } from 'contexts/v2/ZoomMeetingTopicsContext';

import { ENABLED_AUTO_RECORDING, DISABLED_AUTO_RECORDING } from './constants';

import * as styles from './ZoomMeetingForm.styles';

const ZoomMeetingForm = ({ className }) => {
  const { zoomEdits, updateZoomEdits, discardZoomEdits } = useZoomMeetingConfigContext();
  const { zoomMeetingTopics, getZoomMeetingTopics, getZoomTopicByValue } = useZoomMeetingTopicsContext();

  const [hasPasscode, setHasPasscode] = useState(false);
  const [showMeetingForm, setShowMeetingForm] = useState(!zoomEdits.existing);

  const toggleHasPasscode = () => setHasPasscode((state) => !state);

  const toggleShowMeetingForm = () => {
    setShowMeetingForm(!showMeetingForm);
  };

  const handleZoomConfigChange = (value) => {
    const targetData = value.target;

    updateZoomEdits(targetData.name, targetData.value);
  };

  const handleMeetingIdChange = (meeting) => {
    updateZoomEdits('zoomId', meeting.value);
  };

  const handleCheckboxChange = (value) => {
    const targetData = value.target;

    updateZoomEdits(targetData.name, value.target.checked);
  };

  const handleHostVideoChange = () => {
    updateZoomEdits('hostVideo', !zoomEdits.hostVideo);
  };

  const handleParticipantVideoChange = () => {
    updateZoomEdits('participantVideo', !zoomEdits.participantVideo);
  };

  const handleAutoRecordingChange = (value) => {
    if (zoomEdits.autoRecording == ENABLED_AUTO_RECORDING) {
      updateZoomEdits('autoRecording', DISABLED_AUTO_RECORDING);
    } else {
      updateZoomEdits('autoRecording', ENABLED_AUTO_RECORDING);
    }
  };

  useEffect(() => {
    if (!showMeetingForm) {
      getZoomMeetingTopics();
    }
  }, [showMeetingForm]);

  return (
    <>
      <Row noGutters>
        <Col lg={12}>
          <Divider isBold title='Zoom details' position='left' />
        </Col>

        <Col lg={12} className='mt-4'>
          <Label text='Zoom Meeting ID' />

          <Row noGutters>
            <Col>
              <RadioButton
                name='zoom_meeting_id'
                label='Generate Automatically'
                isChecked={showMeetingForm}
                onChange={toggleShowMeetingForm}
              />
            </Col>

            <Col>
              <RadioButton
                name='zoom_meeting_id'
                label='Personal Meeting ID'
                isChecked={!showMeetingForm}
                onChange={toggleShowMeetingForm}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {!showMeetingForm && (
        <SelectInput
          isFilterable
          onChange={handleMeetingIdChange}
          value={getZoomTopicByValue(zoomEdits.zoomId)}
          options={zoomMeetingTopics}
        />
      )}

      {showMeetingForm && (
        <Row noGutters>
          <Col lg={12} className='mt-4'>
            <Label text='Passcode' />

            <Row className='d-flex align-items-center'>
              <Col lg={2}>
                <Checkbox label='Yes' isChecked={hasPasscode} onChange={toggleHasPasscode} />
              </Col>

              <Col lg={5}>
                <TextInput
                  placeholder='Passcode'
                  isDisabled={!hasPasscode}
                  name='password'
                  value={zoomEdits.password}
                  onChange={handleZoomConfigChange}
                />
              </Col>

              <Col lg={5}>
                <Text
                  fontSize={12}
                  color='lightGrey'
                  text='Only users who have the invite link or passcode can join the meeting'
                />
              </Col>
            </Row>
          </Col>

          <Col lg={12} className='mt-4'>
            <Label text='Waiting room' />

            <Row noGutters>
              <Col lg={2}>
                <Checkbox
                  label='Yes'
                  name='waitingRoom'
                  isChecked={zoomEdits.waitingRoom}
                  onChange={handleCheckboxChange}
                />
              </Col>

              <Col>
                <Text fontSize={12} color='lightGrey' text='Only users admitted by the host can join the meeting' />
              </Col>
            </Row>
          </Col>

          <Col lg={12} className='mt-4'>
            <Label text='Video for Host' />

            <Row noGutters>
              <Col lg={2}>
                <RadioButton
                  name='hostVideo'
                  label='On'
                  isChecked={zoomEdits.hostVideo}
                  onChange={handleHostVideoChange}
                />
              </Col>

              <Col>
                <RadioButton
                  name='hostVideo'
                  label='Off'
                  isChecked={!zoomEdits.hostVideo}
                  onChange={handleHostVideoChange}
                />
              </Col>
            </Row>
          </Col>

          <Col lg={12} className='mt-4'>
            <Label text='Video for Participant' />

            <Row noGutters>
              <Col lg={2}>
                <RadioButton
                  name='participantVideo'
                  label='On'
                  isChecked={zoomEdits.participantVideo}
                  onChange={handleParticipantVideoChange}
                />
              </Col>

              <Col>
                <RadioButton
                  name='participantVideo'
                  label='Off'
                  isChecked={!zoomEdits.participantVideo}
                  onChange={handleParticipantVideoChange}
                />
              </Col>
            </Row>
          </Col>

          <Col lg={12} className='mt-4'>
            <Label text='Meeting options' />

            <Row noGutters>
              <Col lg={12} className='mb-1'>
                <Checkbox
                  label='Allow participants to join anytime'
                  name='joinBeforeHost'
                  isChecked={zoomEdits.joinBeforeHost}
                  onChange={handleCheckboxChange}
                />
              </Col>

              <Col lg={12} className='mb-1'>
                <Checkbox
                  label='Mute participants upon entry'
                  name='muteUponEntry'
                  isChecked={zoomEdits.muteUponEntry}
                  onChange={handleCheckboxChange}
                />
              </Col>

              <Col lg={12} className='mb-1'>
                <Checkbox
                  label='Automatically record meeting on the local computer'
                  name='autoRecording'
                  isChecked={zoomEdits.autoRecording == ENABLED_AUTO_RECORDING}
                  onChange={handleAutoRecordingChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

ZoomMeetingForm.propTypes = {
  className: PropTypes.string,
};

export default ZoomMeetingForm;
