import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const Sizes = {
  Small: 'small',
  Medium: 'medium',
  Regular: 'regular',
};

export const Colors = {
  Purple: 'purple',
  Cyan: 'cyan',
  Yellow: 'yellow',
  LightPurple: 'lightPurple',
};

const Avatar = ({ className, color = Colors.Purple, placeholder, imgUrl, size = Sizes.Regular, onClick }) => (
  <div
    onClick={onClick}
    className={clsx(
      'tw-inline-flex tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-full tw-font-bold tw-text-white',
      {
        'tw-h-4 tw-w-4 tw-text-[10px]': size === Sizes.Small,
        'tw-h-6 tw-w-6 tw-text-xs': size === Sizes.Medium,
        'tw-h-8 tw-w-8 tw-text-sm': size === Sizes.Regular,
        'tw-bg-purple-500': color === Colors.Purple,
        'tw-bg-teal-500': color === Colors.Cyan,
        'tw-bg-orange-500': color === Colors.Yellow,
        'tw-bg-purple-200 tw-text-purple-500': color === Colors.LightPurple,
      },
      className
    )}
  >
    {imgUrl ? <img src={imgUrl} /> : placeholder?.toUpperCase()}
  </div>
);

Avatar.propTypes = {
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  imgUrl: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(Colors)),
  size: PropTypes.oneOf(Object.values(Sizes)),
};

export default Avatar;
