/** @jsx jsx */

import React, { memo, useRef, useCallback } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import moment from 'moment';

import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';

import * as styles from './DatePicker.styles';

const DatePicker = memo(({ date, show, onDateChange, disablePastDates }) => {
  const { current: navPrevIcon } = useRef(<i className='fa prev fa-chevron-up' />);
  const { current: navNextIcon } = useRef(<i className='fa next fa-chevron-down' />);

  const noop = () => {};

  const applicableStyles = [styles.datePicker.base, show && styles.datePicker.visible];

  const isOutsideRange = useCallback((day) => {
    if (!disablePastDates) return;

    const today = moment().parseZone();

    return day.isBefore(today, 'day');
  }, []);

  return (
    <div css={applicableStyles}>
      <DayPickerSingleDateController
        date={date}
        numberOfMonths={1}
        onDateChange={onDateChange}
        focused={false}
        orientation='vertical'
        navPrev={navPrevIcon}
        navNext={navNextIcon}
        hideKeyboardShortcutsPanel={true}
        daySize={28}
        firstDayOfWeek={1}
        verticalHeight={215}
        isOutsideRange={isOutsideRange}
        onFocusChange={noop}
        transitionDuration={300}
      />
    </div>
  );
});

DatePicker.propTypes = {
  date: PropTypes.object,
  show: PropTypes.bool,
  onDateChange: PropTypes.func,
};

export { DatePicker };
