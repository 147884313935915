import styled from 'styled-components';
import { fonts } from 'styles';

export const Item = styled.div`
  ${fonts.styledRegular}
  font-size: 12px;
  color: #2c2e3f;
  flex: ${({ flex }) => flex};
`;

export const ItemTitle = styled.div`
  ${fonts.styledSemiBold}
  line-height: 24px;
`;
export const ItemContent = styled.div`
  ${fonts.styledRegular}
  line-height: 16px;
  padding-left: 8px;
`;

export const ContentCard = styled.div`
  padding-top: 12px;
  background: #ffffff;
  border-top: solid 1px #dde0ed;
  border-bottom: solid 1px #dde0ed;
  margin-bottom: 10px;
  position: relative;
  .smart-document-email {
    max-width: 200px;
    input {
      width: 100%;
    }
  }
`;

export const ResendButtonWrapper = styled.div`
  border-top: 1px solid #dde0ed;
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: center;
  padding: 8px;
  margin-top: 12px;
  a {
    ${fonts.styledRegular}
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: lowercase;
    color: ${(props) => (props.isSuccess ? '#00BAC6' : '#662d91')} !important;
    display: flex;
    align-items: center;
  }
  ${(props) => props.isSuccess && 'background-color: #E6F9FA;'}
`;
