/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { withIntl } from '../../utils/v2/intl';

import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';
import CircleCheckmark from 'images/icons/circle-checkmark.svg';
import GoogleLogo from 'images/icons/google.svg';
import MicrosoftLogo from 'images/icons/windows.svg';
import { intl } from '../../contexts/v2/TranslationContext';

const CalendarIntegrations = memo(
  ({ active, calendarLogo, appName, emailIdentifier, connectPath, disconnectPath, isGoogle }) => {
    let [showWarning, setShowWarning] = useState(false);
    const buttonStyle = {
      all: 'unset',
      maxWidth: '100%',
    };

    const int = useIntl();

    const connectCalendar = () => {
      if (isGoogle) {
        setShowWarning(true);
      } else {
        redirectToConnect();
      }
    };

    const redirectToConnect = () => {
      window.location.assign(connectPath);
    };

    const connectButton = (
      <button style={buttonStyle}>
        <div className='calendar-app-item'>
          <div className='calendar-app-connect'>
            <div className='calendar-app-content'>
              <div className='calendar-app-logo'>
                <img src={isGoogle ? GoogleLogo : MicrosoftLogo} />
              </div>
              <div className='calendar-app-title'>{appName}</div>
            </div>
          </div>
        </div>
      </button>
    );

    return (
      <>
        {active ? (
          <div className='calendar-app-item checked'>
            <div className='calendar-app-connect'>
              <div className='calendar-app-checkmark'>
                <img src={CircleCheckmark} />
              </div>
              <div className='calendar-app-content'>
                <div className='calendar-app-logo'>
                  <img src={isGoogle ? GoogleLogo : MicrosoftLogo} />
                </div>
                <div className='calendar-app-title'>{appName}</div>
                <div className='calendar-app-email'>{emailIdentifier}</div>
              </div>
            </div>
            <div className='calendar-app-disconnect'>
              <a href={disconnectPath} data-method='delete'>
                <FormattedMessage id='CalendarIntegrations.disconnect' />
              </a>
            </div>
          </div>
        ) : isGoogle ? (
          <ButtonWithConfirmation
            headerText={intl.formatMessage({ id: 'CalendarIntegrations.warning' })}
            confirmButtonText={intl.formatMessage({ id: 'CalendarIntegrations.ok' })}
            button={connectButton}
            confirmationText={intl.formatMessage({ id: 'CalendarIntegrations.grantPermission' })}
            onConfirm={redirectToConnect}
          />
        ) : (
          <a className='calendar-app-link' onClick={connectCalendar}>
            {connectButton}
          </a>
        )}
      </>
    );
  }
);

export default withIntl(CalendarIntegrations);
