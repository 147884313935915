import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const card = (isLast) => css`
  background-color: ${colors.white};
  border-top: 1px solid ${colors.borderSecondGrey};
  border-bottom: 1px solid ${colors.borderSecondGrey};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-bottom: ${isLast ? '0' : '10px'};
  color: ${colors.black};
  position: relative;
`;

export const container = css`
  padding: 15px 20px;
`;

export const header = css`
  ${fonts.semiBold}
  width: 110px;
  min-width: 110px;
  padding-right: 20px;
  white-space: nowrap;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 24px;
  color: ${colors.black};
  margin: 0;
`;
export const statuses = css`
  font-size: 12px;
  line-height: 16px;
`;

export const textOverflow = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
`;

export const line = ({ isWrapped = false }) => css`
  display: flex;
  align-items: center;
  flex-wrap: ${isWrapped ? 'wrap' : 'nowrap'};
  &:first-of-type {
    padding-right: 20px;
  }
`;

export const dotsBlock = css`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  border-left: 1px solid ${colors.borderSecondGrey};
  border-bottom: 1px solid ${colors.borderSecondGrey};
`;

export const dateLabel = css`
  display: flex;
`;

export const dateLabelPart = css`
  ${fonts.styledBold}
  width: 50px;
  min-width: 50px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
`;

export const dateLabelDay = css`
  display: flex;
  background-color: ${colors.cyan};
  color: ${colors.white};
  border-radius: 4px 0 0 4px;
`;

export const dateLabelTime = css`
  color: ${colors.cyan};
  background-color: rgba(${colors.cyan}, 0.1);
  background-color: #e5f8f9;
  display: flex;
  border-radius: 0 4px 4px 0;
`;

export const link = css`
  color: inherit;
  text-decoration: none;
  &:focus,
  &:active {
    color: inherit;
  }
`;

export const statisticBlock = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${colors.borderSecondGrey};
  flex-wrap: wrap;
  padding: 3px 20px;
`;

export const statisticItem = css`
  ${fonts.bold};
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: ${colors.black};
  &:not(last-child) {
    margin-right: 10px;
  }
`;

export const statisticItemNumber = css`
  color: ${colors.mediumGrey};
  margin-left: 2px;
`;
