import { css } from '@emotion/core';
import { colors, media } from 'styles';

export const toggle = {
  base: (top) => css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate3d(50%, ${top}px, 0);
    z-index: 6;
    background: ${colors.white};
    box-shadow: 0px 6.4px 14.4px rgb(183 183 183 / 13%), 0px 5px 10px rgb(153 183 200 / 10%);
    border-radius: 25px;
    margin-top: 10px;
    opacity: 0;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
    cursor: pointer;
    width: 49px;
    height: 49px;
    ${media.mobile} {
      transform: translate3d(0, ${top}px, 0);
    }

    &:hover {
      svg {
        path {
          fill: ${colors.cyan};
        }
      }
    }
  `,

  visible: css`
    opacity: 1;
  `,
};
