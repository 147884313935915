/** @jsx jsx */

import React, { useState, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { GroupsTable } from './../GroupsTable';
import { GroupDrawer } from './../GroupDrawer';

import { useCreateContactGroup } from './../hooks/useCreateContactGroup';
import { useDeleteContactGroup } from './../hooks/useDeleteContactGroup';
import { useUpdateContactGroup } from './../hooks/useUpdateContactGroup';

const NEW_GROUP = {
  name: '',
  contacts: [],
};

const Container = ({ companyId }) => {
  const [context, setContext] = useState({
    isDrawerOpen: false,
    errors: [],
    group: NEW_GROUP,
  });

  const closeDrawer = useCallback(() => {
    setContext((state) => ({ ...state, isDrawerOpen: false, errors: [] }));
  }, []);

  const openDrawer = useCallback((group) => {
    setContext((state) => ({ ...state, group: group, isDrawerOpen: true, errors: [] }));
  }, []);

  const handleGroupChange = useCallback((values) => {
    setContext((state) => ({ ...state, group: { ...state.group, ...values } }));
  }, []);

  const onError = useCallback((errors) => {
    setContext((state) => ({ ...state, errors: errors }));
  }, []);

  const handleAddGroupClick = openDrawer.bind(this, NEW_GROUP);

  const { deleteContactGroup } = useDeleteContactGroup({ onCompleted: closeDrawer });
  const { createContactGroup } = useCreateContactGroup({ onCompleted: closeDrawer, onError, companyId });
  const { updateContactGroup } = useUpdateContactGroup({ onCompleted: closeDrawer, onError });

  const handleDeleteGroup = () => {
    deleteContactGroup({ contactGroupId: context.group.id });
  };

  const handleCreateGroup = () => {
    createContactGroup({
      name: context.group.name,
      contactIds: context.group.contacts.map((item) => item.gid),
    });
  };

  const handleUpdateGroup = () => {
    updateContactGroup({
      contactGroupId: context.group.id,
      name: context.group.name,
      contactIds: context.group.contacts.map((item) => item.gid || item.id),
    });
  };

  return (
    <div className='d-flex flex-column'>
      <div className='media-heading-mt-small align-items-center d-flex justify-content-between'>
        <div className='text-nowrap media-title'>
          <FormattedMessage id='Container.groups' />
        </div>
        <div onClick={handleAddGroupClick} className='btn btn-primary btn-sm-fw mw-100 m-l-30'>
          <FormattedMessage id='Container.addGroup' />
        </div>
      </div>

      <div className='media-row'>
        <GroupsTable onEdit={openDrawer} />
      </div>

      <GroupDrawer
        isOpen={context.isDrawerOpen}
        onClose={closeDrawer}
        group={context.group}
        errors={context.errors}
        onGroupChange={handleGroupChange}
        onCreate={handleCreateGroup}
        onUpdate={handleUpdateGroup}
        onDelete={handleDeleteGroup}
      />
    </div>
  );
};

export { Container };
