import { gql } from '@apollo/client';
import { TIMEZONE_FRAGMENT } from './TimezoneFragment';
import { CONTACT_FRAGMENT } from './ContactFragment';
import { CONTACT_MEETING_FRAGMENT } from './ContactMeetingFragment';
import { ZOOM_MEETING_FRAGMENT } from './ZoomMeetingFragment';

const MEETING_FRAGMENT = gql`
  fragment MeetingFields on Meeting {
    id
    title
    slug
    type
    description
    meetingDate
    meetingStartTime
    meetingEndTime
    config
    location
    locationNotes
    meetingUrl
    passcode

    chairman {
      ...ContactMeetingFields
    }

    timezone {
      ...TimezoneFields
    }

    owner {
      ...ContactFields
    }

    zoomMeeting {
      ...ZoomMeetingFields
    }
  }
  ${TIMEZONE_FRAGMENT}
  ${CONTACT_FRAGMENT}
  ${CONTACT_MEETING_FRAGMENT}
  ${ZOOM_MEETING_FRAGMENT}
`;

export { MEETING_FRAGMENT };
