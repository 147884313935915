import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const icon = css`
  padding-right: 10px;
`;

export const item = {
  base: css`
    ${fonts.regular}
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 14px;
    padding: 12px 32px;
    color: ${colors.black};
    cursor: pointer;

    svg {
      width: 18px;
    }
  `,

  selected: css`
    ${fonts.semiBold}
    position: relative;
    color: ${colors.white};
    background: ${colors.cyan};

    svg {
      path {
        fill: ${colors.white};
      }

      path[stroke] {
        stroke: ${colors.white};
      }
    }
  `,
};
