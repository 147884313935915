/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';

import moment from 'moment';

import * as styles from './Document.styles';

import { useDateFormatContext } from '../../../contexts/v2/DateFormatContext';

const Document = memo(({ name, createdAt, dashboardIcon, withSmartDocuments, onClick }) => {
  const { userDateFormat } = useDateFormatContext();

  const dateCreated = moment(createdAt).parseZone().format(userDateFormat);
  const smartDocumentBadge = withSmartDocuments && <div className='badge smart_sign'>&nbsp;</div>;

  return (
    <div css={styles.wrapper} onClick={onClick}>
      <i className={dashboardIcon} css={styles.icon}>
        {smartDocumentBadge}
      </i>

      <div css={styles.name}>{name}</div>
      <div css={styles.createdAt}>{dateCreated}</div>
    </div>
  );
});

export { Document };
