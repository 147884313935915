/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import TaskItem from './../TaskItem';
import Divider from 'components/v2/shared/Divider';
import Box from 'components/v2/shared/Box';
import Text from 'components/v2/shared/Text';

import * as styles from './TaskList.styles';

const TaskList = ({ tasks }) => {
  if (!tasks || !tasks.length) {
    return (
      <Box variant='placeholder'>
        <Text color='lightGrey' text='No (0) tasks added' />
      </Box>
    );
  }

  return tasks.map((task) => <TaskItem key={task.id} {...task} />);
};

TaskList.propTypes = {
  tasks: PropTypes.array,
};

export default TaskList;
