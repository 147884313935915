import { useCreateMessageMutation } from './../data/mutations/CreateMessageMutation';

const useCreateMessage = ({ onCompleted }) => {
  const update = (cache, data) => onCompleted();

  const [mutate] = useCreateMessageMutation({ update });

  const createMessage = (messageText) => {
    mutate({ variables: { input: { messageText } } });
  };

  return { createMessage };
};

export { useCreateMessage };
