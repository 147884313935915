import { css } from '@emotion/core';
import { colors, media } from 'styles';

export const spinner = css`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

export const scrollable = css`
  min-height: 420px;
  max-height: 420px;
  background: ${colors.lightestGrey};
  ${media.tablet} {
    max-height: none;
    min-height: auto;
    flex-grow: 1;
  }
`;

export const messages = css`
  padding: 0 32px;
`;
