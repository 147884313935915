import React, { useState, useMemo } from 'react';
import { notify } from 'components/v2/notification';
import SelectInput from 'components/v2/shared/SelectInput/SelectInput';
import Button from 'components/v2/shared/Button/Button';
import Heading from 'components/v2/shared/Heading';
import Label from 'components/v2/shared/Label';
import { wrapMutation } from 'utils/v2/gql';
import { useUpdateProfileDateAndTimezoneSettingsMutation } from 'data/v2/mutations/UpdateProfileDateAndTimezoneSettingsMutation';

const DateFormat = ({ timezones, dateFormats, currentUser }) => {
  const [errors, setErrors] = useState({});
  const rowData = useMemo(() => {
    return {
      dateFormat: currentUser.dateFormat,
      timezone: currentUser.address.timezone,
    };
  }, [currentUser]);
  const [data, setData] = useState({
    dateFormat: currentUser.dateFormat,
    timezone: currentUser.address.timezone,
  });
  const [updateProfileDateAndTimezoneSettings] = useUpdateProfileDateAndTimezoneSettingsMutation();
  const handleSubmit = () => {
    wrapMutation(
      updateProfileDateAndTimezoneSettings,
      {
        variables: {
          input: {
            timezone: data.timezone?.value,
            dateFormat: data.dateFormat?.value,
          },
        },
      },
      'updateProfileDateAndTimezoneSettings'
    )
      .then(() => {
        if (rowData.dateFormat?.value !== data.dateFormat?.value && rowData.timezone.value !== data.timezone.value) {
          notify('success', 'Date format and Timezone are updated');
        } else if (rowData.dateFormat?.value !== data.dateFormat?.value) {
          notify('success', 'Date format is updated');
        } else if (rowData.timezone.value !== data.timezone.value) {
          notify('success', 'Timezone is updated');
        }
      })
      .catch(setErrors);
  };

  return (
    <div className='tw-pb-40'>
      <div className='tw-hidden sm:tw-block'>
        <Heading text='Date and timezone settings' className='tw-mb-3 tw-font-sans tw-text-xl tw-font-bold' />
      </div>

      <div>
        <div className='tw-py-5'>
          <Label text='Date format' className='tw-font-sans tw-text-sm !tw-font-medium' />

          <SelectInput
            name='date_format'
            value={data.dateFormat}
            onChange={(value) => setData((prev) => ({ ...prev, dateFormat: value }))}
            optionVariant='regular'
            options={dateFormats}
            errorMessage={errors.dateFormat}
            listHeight='large'
          />
        </div>

        <div className='tw-py-5'>
          <Label text='Timezone' className='tw-font-sans tw-text-sm !tw-font-medium' />

          <SelectInput
            isFilterable
            onChange={(value) => setData((prev) => ({ ...prev, timezone: value }))}
            name='timezone'
            value={data.timezone}
            optionVariant='regular'
            options={timezones}
            errorMessage={errors.timezone}
            placeholder='Type and select name'
            listHeight='large'
          />
        </div>

        <div className='tw-my-9'>
          <div className='tw-text-center'>
            <Button font='semibold' isFullWidth size='large' variant='teal' onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateFormat;
