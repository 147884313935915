/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { useClickOutside } from 'hooks/useClickOutside';

import { ReactComponent as UploadIcon } from 'images/icons/documents/upload-icon.svg';
import { ReactComponent as DocumentUploadIcon } from 'images/icons/documents/document-upload-icon.svg';
import { ReactComponent as DirectoryUploadIcon } from 'images/icons/documents/directory-upload-icon.svg';
import { useUploadQueueActions } from 'store/uploadQueueStoreContext';
import { fromEvent } from 'file-selector';
import MobileSharedDropdown from '../shared/MobileSharedDropdown';

import * as styles from './UploadButton.styles';

const UploadButton = memo(({ directory, isDisabled }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside();
  const { addFilesToQueue } = useUploadQueueActions();

  const documentsInputRef = React.useRef();
  const directoriesInputRef = React.useRef();

  const handleClick = () => !isDisabled && setIsComponentVisible(true);
  const handleDocumentsClick = () => documentsInputRef.current.click();
  const handleDirectoriesClick = () => directoriesInputRef.current.click();
  const handleClose = (event) => {
    event.stopPropagation();
    setIsComponentVisible(false);
  };

  const dropdown = isComponentVisible && (
    <div css={styles.dropdown} ref={ref}>
      <MobileSharedDropdown onClick={handleClose} />
      <div css={styles.option} onClick={handleDocumentsClick}>
        <DocumentUploadIcon className='dropdownOption__icon' />
        <div className='dropdownOption__text'>
          <FormattedMessage id='UploadButton.documents' />
        </div>
      </div>

      <div css={styles.option} onClick={handleDirectoriesClick}>
        <DirectoryUploadIcon className='dropdownOption__icon' />
        <div className='dropdownOption__text'>
          <FormattedMessage id='UploadButton.folders' />
        </div>
      </div>
    </div>
  );

  const containerStyles = [styles.container.base, isDisabled && styles.container.disabled];

  const arrowStyles = [styles.arrow.base, isComponentVisible && styles.arrow.rotated];

  const handleFilesChange = async (event) => {
    const files = await fromEvent(event);
    addFilesToQueue({ files, directory });
  };

  return (
    <div css={styles.wrapper}>
      <div css={containerStyles} onClick={handleClick}>
        <UploadIcon className='uploadButton__icon' />
        <div className='uploadButton__text'>
          <FormattedMessage id='UploadButton.upload' />
        </div>
        <i className='fa fa-chevron-down uploadButton__indicator' css={arrowStyles} />
      </div>

      <input type='file' value='' onChange={handleFilesChange} multiple className='d-none' ref={documentsInputRef} />
      <input
        type='file'
        value=''
        onChange={handleFilesChange}
        multiple
        webkitdirectory='true'
        ref={directoriesInputRef}
        className='d-none'
      />

      {dropdown}
    </div>
  );
});

export { UploadButton };
