import { gql, useMutation } from '@apollo/client';

const PUBLISH_VOTE = gql`
  mutation publishVote($input: PublishVoteInput!) {
    publishVote(input: $input) {
      vote {
        id
        emailMessage
      }
    }
  }
`;

const usePublishVoteMutation = (options) => useMutation(PUBLISH_VOTE, options);

export { usePublishVoteMutation };
