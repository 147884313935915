import { css } from '@emotion/core';
import { fonts, colors, media } from 'styles';

export const modal = css`
  .modal-dialog.shaparency {
    width: 454px;

    .modal-header {
      ${media.tablet} {
        display: none;
      }
    }

    .modal-content {
      padding: 20px 25px;
      border-radius: 2px;

      ${media.tablet} {
        min-height: auto;
        width: 90%;
      }

      .modal-close {
        display: none;
      }

      .modal-header,
      .modal-body {
        padding: 0;
      }
    }
  }
`;
