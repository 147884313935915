import React, { memo, useState, useEffect } from 'react';

import SearchModal from './SearchModal';
import Avatar from '../../shared/Avatar';
import ContactsListModal from '../ContactsListModal';

import { ReactComponent as UsersIcon } from 'images/icons/users.svg';
import { ReactComponent as AddIcon } from 'images/icons/circle-plus.svg';

export default memo(({ contacts, header, allowAdd, maxContacts, withoutDetails, children }) => {
  let [searchOpen, setSearchOpen] = useState(false);
  let [pickedContacts, setPickedContacts] = useState(contacts);
  let [showContactsModal, setShowContactsModal] = useState(false);

  useEffect(() => {
    setPickedContacts(contacts);
  }, [contacts]);

  let searchKey = header && header.toLowerCase();

  const onModalHide = (event) => {
    setSearchOpen(false);
  };
  const onAddIconClick = (event) => {
    setSearchOpen(true);
  };
  const onShowMoreClick = () => {
    return withoutDetails ? null : setShowContactsModal(true);
  };
  const onContactsModalHide = () => {
    setShowContactsModal(false);
  };

  const pickedContactIds = pickedContacts.map((contact) => {
    return <input type='hidden' key={contact.id} name={`${searchKey}_ids[]`} value={contact.id} />;
  });

  const addedContacts = pickedContacts.slice(0, maxContacts).map((contact) => {
    const klassName = `m-r-5 ${!withoutDetails && 'cursor-pointer'} flex-shrink-0`;

    return (
      <div onClick={onShowMoreClick} key={contact.id} className={klassName}>
        <Avatar contact={contact} />
      </div>
    );
  });

  const diff = pickedContacts.length - maxContacts;

  const contactsLeft = (
    <div className='m-r-5 more-contacts' onClick={onShowMoreClick}>
      <Avatar contact={{ initials: `+${diff}` }} />
    </div>
  );

  return (
    <React.Fragment>
      <div className='shaparency-picker-contacts contacts-block'>
        {header && (
          <div className='d-flex align-items-center'>
            <UsersIcon className='m-0' />
            <span className='m-l-5 heading'>{header}</span>
            <span className='m-l-5 count'>{pickedContacts.length}</span>
          </div>
        )}

        <div className='d-flex align-items-center contacts-section'>
          {addedContacts}
          {diff > 0 && contactsLeft}
          {allowAdd && <AddIcon className='add-button m-0' onClick={onAddIconClick} />}
          {children}
        </div>
      </div>

      {pickedContactIds}

      <SearchModal
        pickedContacts={pickedContacts}
        setPickedContacts={setPickedContacts}
        searchKey={searchKey}
        show={searchOpen}
        onHide={onModalHide}
        header={header}
      />

      <ContactsListModal
        contacts={pickedContacts}
        header={header}
        show={showContactsModal}
        onHide={onContactsModalHide}
      />
    </React.Fragment>
  );
});
