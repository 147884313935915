import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import FolderRow from 'components/shared/FileBrowser/FolderRow';
import DirectoryPath from './DirectoryPath';
import { GeneratePath } from './GeneratePath';

const directoryAttributesShape = {
  name: PropTypes.string,
};

const directoryShape = {
  data: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape(directoryAttributesShape),
  }),
};

directoryAttributesShape.childDirectories = PropTypes.arrayOf(PropTypes.shape(directoryShape));

const directoryPropType = PropTypes.shape(directoryShape);

const MoveForm = ({ rootDirectory, onFormSubmit, directorySuitable }) => {
  const [currentDirectory, setCurrentDirectory] = useState(rootDirectory);
  const [currentPath, setCurrentPath] = useState([rootDirectory]);

  const intl = useIntl();

  onDirectorySelect = (directory) => {
    if (!directorySuitable(directory)) return;
    if (directory.data.id === currentDirectory.data.id) return;

    setCurrentDirectory(directory);
    setCurrentPath(GeneratePath(currentPath, rootDirectory, directory));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    onFormSubmit(formData, currentDirectory.data.id);
  };

  const { data: directoryData } = currentDirectory;
  const { childDirectories: subdirectories } = directoryData.attributes;
  const submitEnabled = directoryData.id && directoryData.attributes.moveTargetable;

  return (
    <div className='document-move-form'>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <DirectoryPath directories={currentPath} onDirectoryClick={onDirectorySelect} />
          </Col>
        </Row>
        <Row className='m-t-10'>
          <Col>
            <FolderRow
              directories={subdirectories}
              onDirectoryClick={onDirectorySelect}
              isSelectable={directorySuitable}
            />
          </Col>
        </Row>
        <Row>
          <Col className='form-actions'>
            <input
              type='submit'
              value={intl.formatMessage({ id: 'MoveForm.move' })}
              disabled={!submitEnabled}
              className='btn btn-primary m-r-10'
            />
            <button className='btn btn-text' data-dismiss='modal' type='button'>
              <FormattedMessage id='MoveForm.cancel' />
            </button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

MoveForm.propTypes = {
  rootDirectory: directoryPropType,
  onFormSubmit: PropTypes.func.isRequired,
  directorySuitable: PropTypes.func.isRequired,
};

export default MoveForm;
