/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { useEditable } from 'hooks/useEditable';
import { useUpdateDirectory } from './../hooks/useUpdateDirectory';
import { useDeleteDirectory } from './../hooks/useDeleteDirectory';
import { useDownloadDirectory } from './../hooks/useDownloadDirectory';
import { useToggleFavouriteDirectory } from './../hooks/useToggleFavouriteDirectory';
import { AlertPopup } from 'components/shared/AlertPopup';

import { LoadingIndicator } from 'components/shared/LoadingIndicator';
import { ActionsDropdown } from './../ActionsDropdown';
import { EditableName } from './../EditableName';
import { AreaButton } from './../AreaButton';

import { ReactComponent as SharedIcon } from 'images/icons/documents/shared-badge.svg';
import { ReactComponent as DownloadIcon } from 'images/icons/documents/archive-download-icon.svg';
import { ReactComponent as StarIcon } from 'images/icons/documents/star-icon.svg';
import { ReactComponent as DirectoryIcon } from 'images/icons/documents/directory-icon.svg';

import * as styles from './Directory.styles';
import moment from 'moment';

import { useDateFormatContext } from '../../../contexts/v2/DateFormatContext';

const Directory = memo(
  ({
    id,
    name,
    createdAt,
    highlight,
    capabilities,
    shared,
    withSmartDocuments,
    favourite,
    empty,
    onClick,
    onShareClick,
    onMoveClick,
    onDownload,
  }) => {
    const { userDateFormat } = useDateFormatContext();
    const { isEditing, startEditing, stopEditing } = useEditable();
    const { isDeleting, deleteDirectory } = useDeleteDirectory({ id });
    const { isDownloading, downloadDirectory } = useDownloadDirectory({
      onDownload,
    });
    const { isToggling, toggleFavouriteDirectory } = useToggleFavouriteDirectory({ id });
    const { error, resetError, isUpdating, updateDirectory } = useUpdateDirectory({ id, onCompleted: stopEditing });
    const { isEditing: isAlertVisible, startEditing: showAlert, stopEditing: hideAlert } = useEditable();

    const intl = useIntl();

    const handleToggleFavouriteClick = (event) => {
      event.stopPropagation();
      toggleFavouriteDirectory();
    };

    const handleAlertCancel = (e) => {
      e.stopPropagation();
      hideAlert();
    };

    const handleAlertConfirm = (e) => {
      handleAlertCancel(e);
      deleteDirectory();
    };

    const handleCancel = () => {
      resetError();
      stopEditing();
    };

    const handleDownload = (event) => {
      event.stopPropagation();
      downloadDirectory(id);
    };

    const quickActions = [
      [capabilities?.canUpdate, intl.formatMessage({ id: 'Directory.rename' })],
      [capabilities?.canMove, intl.formatMessage({ id: 'Directory.move' })],
      [!withSmartDocuments && capabilities?.canDelete, intl.formatMessage({ id: 'Directory.delete' })],
      [capabilities?.canShare, intl.formatMessage({ id: 'Directory.share' })],
    ];

    const actionable = quickActions.filter((option) => option[0]).length > 0;

    const handleQuickActionClick = (option) => {
      switch (option) {
        case intl.formatMessage({ id: 'Directory.rename' }):
          return startEditing();
        case intl.formatMessage({ id: 'Directory.delete' }):
          return showAlert();
        case intl.formatMessage({ id: 'Directory.move' }):
          return onMoveClick();
        case intl.formatMessage({ id: 'Directory.share' }):
          return onShareClick();
      }
    };

    const starStyles = [styles.star.base, favourite && styles.star.favourite];

    const dateCreatedFull = moment(createdAt)
      .parseZone()
      .format(userDateFormat + ' hh:mm A');
    const [dateCreated, timeCreated] = dateCreatedFull.split(',');
    const sharedDirectoryIcon = shared && <SharedIcon css={styles.sharedBadge} />;

    return (
      <div css={styles.container} onClick={onClick} className='directory-container'>
        <div css={styles.wrapper}>
          <div className='position-relative'>
            <DirectoryIcon className='directory__icon' />
            {sharedDirectoryIcon}
          </div>

          <div className={`directory__name ${!isEditing && 'text-truncate'}`}>
            <EditableName
              value={name}
              error={error}
              highlight={highlight}
              isEditing={isEditing}
              onChange={resetError}
              onUpdate={updateDirectory}
              onCancel={handleCancel}
            />
          </div>
        </div>

        <div className='directory__createdAt'>
          {!isEditing ? (
            <>
              <span>{dateCreated}</span>
              <span>{timeCreated}</span>
            </>
          ) : null}
        </div>

        <div className='directory__actions'>
          <LoadingIndicator show={isToggling || isUpdating || isDeleting || isDownloading} />

          <AreaButton isDisabled={empty} onClick={handleDownload} icon={<DownloadIcon />} />

          <StarIcon css={starStyles} onClick={handleToggleFavouriteClick} />

          {actionable && <ActionsDropdown options={quickActions} onClick={handleQuickActionClick} />}

          <AlertPopup show={isAlertVisible} popupType='info' headerText='Confirmation' onClose={handleAlertCancel}>
            <div className='text-black fs-14'>
              <FormattedMessage id='Directory.deleteConfirm' />
            </div>

            <div className='d-flex align-items-center m-t-40 justify-content-center'>
              <button className='btn btn-info' onClick={handleAlertConfirm}>
                <FormattedMessage id='Directory.yes' />
              </button>

              <button className='btn btn-info-inverted m-l-10' onClick={handleAlertCancel}>
                <FormattedMessage id='Directory.cancel' />
              </button>
            </div>
          </AlertPopup>
        </div>
      </div>
    );
  }
);

export { Directory };
