import { gql, useMutation } from '@apollo/client';
import { RESOLUTION_DOCUMENT_FRAGMENT } from './../fragments/ResolutionDocumentFragment';

const DELETE_RESOLUTION_DOCUMENT = gql`
  mutation deleteResolutionDocument($input: DeleteResolutionDocumentInput!) {
    deleteResolutionDocument(input: $input) {
      resolutionDocument {
        ...ResolutionDocumentFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${RESOLUTION_DOCUMENT_FRAGMENT}
`;

const useDeleteResolutionDocumentMutation = (options) => useMutation(DELETE_RESOLUTION_DOCUMENT, options);

export { useDeleteResolutionDocumentMutation };
