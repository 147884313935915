/** @jsx jsx */

import React, { memo, useState } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as PlusIcon } from 'images/icons/circle-plus-sm.svg';
import { EditableTaskTitle } from './../EditableTaskTitle';

import * as styles from './AgendaQuickTaskAdd.styles';

const AgendaQuickTaskAdd = memo(({ meetingId, agendaId, tasks, isEmpty, show }) => {
  const [isActive, setIsActive] = useState(false);

  const activate = () => setIsActive(true);
  const deactivate = () => setIsActive(false);

  return (
    show && (
      <>
        <div css={styles.wrapper}>
          <EditableTaskTitle
            meetingId={meetingId}
            agendaId={agendaId}
            task={{}}
            tasks={tasks}
            isEditable
            onHide={deactivate}
            isOpen={isActive}
          />
        </div>

        {isEmpty && (
          <div css={styles.empty}>
            <div css={styles.heading}>
              <FormattedMessage id='AgendaQuickTaskAdd.noTasks' />
            </div>
          </div>
        )}

        <div css={styles.component}>
          <div onClick={activate} css={styles.addTask}>
            <PlusIcon />
            <FormattedMessage id='AgendaQuickTaskAdd.addTask' />
          </div>
        </div>
      </>
    )
  );
});

export { AgendaQuickTaskAdd };
