import { gql, useMutation } from '@apollo/client';
import { VOTE_FRAGMENT } from 'data/v2/fragments/VoteFragment';

const CREATE_VOTE_MUTATION = gql`
  mutation createVote($input: CreateVoteInput!) {
    createVote(input: $input) {
      vote {
        ...VoteFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${VOTE_FRAGMENT}
`;

const useCreateVoteMutation = (options) => useMutation(CREATE_VOTE_MUTATION, options);

export { CREATE_VOTE_MUTATION, useCreateVoteMutation };
