import React, { memo } from 'react';

import ButtonWithConfirmation from 'components/shared/ButtonWithConfirmation';

export default memo(({ buttonClassNames, iconClass, buttonText, redirectUrl, dataMethod, confirmationText }) => {
  const button = <div className={`${buttonClassNames}`}>{buttonText}</div>;

  const buttonWithIcon = (
    <div className='cursor-pointer dropdown-item d-flex align-items-baseline'>
      <i className={`mdi mr-2 text-muted vertical-middle ${iconClass}`} />
      {buttonText}
    </div>
  );

  return (
    <ButtonWithConfirmation
      button={iconClass ? buttonWithIcon : button}
      confirmButtonUrl={redirectUrl}
      dataMethod={dataMethod}
      confirmationText={confirmationText}
    />
  );
});
