import React, { useState, useEffect, useContext } from 'react';
import Permissions from 'components/v2/roles_permissions/Permissions';
import RoleList from 'components/v2/roles_permissions/RoleList';
import Edit from 'components/v2/roles_permissions/Edit';
import Heading from 'components/v2/shared/Heading';
import Button from 'components/v2/shared/Button';
import TextInput from 'components/v2/shared/TextInput';
import Modal from 'components/v2/shared/Modal';
import clsx from 'clsx';
import Toast, { Severity } from '../shared/Toast/Toast';
import { ToastContext } from 'contexts/v2/ToastContext';
import UpdateRoleName from './UpdateRoleName';
import { usePermissionsQuery } from 'data/v2/queries/PermissionsQuery';
import { useUpdateRolePermissionsMutation } from 'data/v2/mutations/UpdateRolePermissionsMutation';
import { useCreateAccessRoleMutation } from 'data/v2/mutations/CreateAccessRoleMutation';
import { useDeleteAccessRoleMutation } from 'data/v2/mutations/DeleteAccessRoleMutation';
import { useCreateRolePermissionsMutation } from 'data/v2/mutations/CreateRolePermissionsMutation';
import { wrapMutation } from 'utils/v2/gql';

const RolesPermissions = ({ rolesPermissionData, setRolesPermissionData, currentCompanyRefetch }) => {
  const { isRolesSelected, isRoleEditable, isNewRole, selectedRoleData, selectedRoleIndex, roles } =
    rolesPermissionData;
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [activeRoles, setActiveRoles] = useState(false);
  const [userModalOpen, setUserModal] = useState(false);
  const [value, setValue] = useState('');
  const { toastInfo, setToastInfo } = useContext(ToastContext);
  const [permissionsState, setPermissionsState] = useState([]);
  const { data: defaultPermissions } = usePermissionsQuery();
  const [createAccessRole] = useCreateAccessRoleMutation();
  const [deleteAccessRole] = useDeleteAccessRoleMutation();
  const [createRolePermissions] = useCreateRolePermissionsMutation();
  const [updateRolePermissions] = useUpdateRolePermissionsMutation();

  const onClickRole = (item, index) => {
    onRolehandleChange(true);
    setRolesPermissionData((prev) => ({
      ...prev,
      selectedRoleIndex: index,
      selectedRoleData: item,
      isRolesSelected: true,
      isNewRole: false,
      isRoleEditable: !item.isDefault,
    }));
    setActiveRoles(true);
  };

  const onRoleClose = () => {
    setRolesPermissionData((prev) => ({
      ...prev,
      isRolesSelected: false,
      isRoleEditable: false,
    }));
  };

  const onRolehandleChange = (state) => {
    setActiveRoles(state);
    if (!state) {
      setRolesPermissionData((prev) => ({
        ...prev,
        selectedRoleIndex: '',
        isNewRole: false,
        isRolesSelected: false,
        isRoleEditable: false,
      }));
    }
  };

  const onModalChange = () => {
    setUserModal(!userModalOpen);
  };

  const onRoleNameChange = (state) => {
    setUpdateModalOpen(state);
  };

  const onAddNewRole = (state) => {
    onRoleNameChange(false);
    setRolesPermissionData((prev) => ({
      ...prev,
      isNewRole: state,
      selectedRoleIndex: '',
      isRolesSelected: state,
      isRoleEditable: state,
    }));
    setValue('');
  };

  const handleCreateRole = async () => {
    const permissions = permissionsState.map((p) => ({
      permissionId: p.id,
      permitted: p?.permitted ?? false,
    }));

    try {
      const { accessRole } = await wrapMutation(
        createAccessRole,
        {
          variables: {
            input: {
              name: value,
            },
          },
        },
        'createAccessRole'
      );

      await wrapMutation(
        createRolePermissions,
        {
          variables: {
            input: {
              accessRoleId: accessRole.id,
              permissions,
            },
          },
        },
        'createRolePermissions'
      );

      onRolehandleChange(false);
      currentCompanyRefetch();
      setToastInfo({
        severity: Severity.Success,
        status: 'Save Successful',
        message: 'Access role has been successfully created',
      });
    } catch (error) {
      setToastInfo({
        severity: Severity.Error,
        status: 'Failed Saving',
        message: error.message,
      });
    }
  };

  const handleUpdateRole = async () => {
    const permissions = permissionsState.map((p) => ({
      rolePermissionId: p.id,
      permitted: p.permitted,
    }));

    try {
      await wrapMutation(
        updateRolePermissions,
        {
          variables: {
            input: {
              permissions,
            },
          },
        },
        'updateRolePermissions'
      );
      currentCompanyRefetch();
      setToastInfo({
        severity: Severity.Success,
        status: 'Save Successful',
        message: 'Permissions has been successfully updated',
      });
    } catch (error) {
      setToastInfo({
        severity: Severity.Error,
        status: 'Failed Saving',
        message: error.message,
      });
    }
  };

  const handleDeleteRole = async (accessRoleId, replacementRoleId) => {
    setRolesPermissionData((prev) => ({
      ...prev,
      isRolesSelected: false,
    }));

    try {
      await wrapMutation(
        deleteAccessRole,
        {
          variables: {
            input: {
              accessRoleId,
              replacementRoleId,
            },
          },
        },
        'deleteAccessRole'
      );
      currentCompanyRefetch();
      setToastInfo({
        severity: Severity.Success,
        status: 'Success',
        message: 'Successfully deleted!',
      });
    } catch (error) {
      Object.values(error).forEach((err) => {
        setToastInfo({
          severity: Severity.Error,
          status: 'Error',
          message: err,
        });
      });
    }
  };

  useEffect(() => {
    onRolehandleChange(true);
  }, []);

  return (
    <>
      <div className='tw-flex tw-justify-center md:tw-container'>
        <Toast severity={toastInfo.severity} status={toastInfo.status} message={toastInfo.message} />
        <Modal isOpen={updateModalOpen} onClose={() => onRoleNameChange(false)} size='xl'>
          <UpdateRoleName role={selectedRoleData} refetch={currentCompanyRefetch} />
        </Modal>
        <div
          className={clsx('tw-w-full', {
            'tw-py-6 sm:tw-w-[450px] sm:tw-py-9': !isRolesSelected,
          })}
        >
          <>
            <div className='tw-block lg:tw-flex'>
              <div
                className={clsx({
                  'tw-py-6 sm:tw-justify-center sm:tw-py-9 lg:tw-flex lg:tw-w-1/2 lg:tw-border-0 lg:tw-border-r lg:tw-border-solid lg:tw-border-gray-200 lg:tw-px-8 ':
                    isRolesSelected,
                  'tw-hidden': isRolesSelected,
                })}
              >
                <div
                  className={clsx({
                    'sm:tw-w-[450px]': !isRolesSelected,
                    'sm:tw-w-[450px] lg:tw-w-[350px]': isRolesSelected,
                  })}
                >
                  <div className='tw-mb-8 tw-justify-between sm:tw-flex'>
                    <Heading
                      text='Roles & Permissions'
                      className='!tw-mb-0 !tw-text-[22px] sm:tw-block md:!tw-text-[26px]'
                    />
                    <Button
                      font='medium'
                      size='small'
                      variant='transparent'
                      className='!tw-p-0 !tw-font-sans !tw-text-sm !tw-font-medium !tw-text-teal-500'
                      onClick={() => onAddNewRole(true)}
                    >
                      Add role
                    </Button>
                  </div>
                  <RoleList
                    roles={roles}
                    selectedRoleIndex={selectedRoleIndex}
                    onClickRole={onClickRole}
                    onDeleteRole={handleDeleteRole}
                  />
                </div>
              </div>
              {isRolesSelected && !isNewRole && (
                <div className='tw-justify-center sm:tw-flex sm:tw-py-9 lg:tw-m-0 lg:tw-w-1/2 lg:tw-py-6'>
                  <div className='tw-w-full md:tw-px-8'>
                    {activeRoles && (
                      <>
                        <div className='tw-mb-9 tw-grid tw-h-12 tw-grid-flow-col tw-grid-cols-8 sm:tw-mt-0'>
                          <div
                            className={clsx('tw-flex tw-items-center tw-justify-between', {
                              'tw-col-span-4': !selectedRoleData.isDefault,
                              'tw-col-span-6': selectedRoleData.isDefault,
                            })}
                          >
                            <Heading text={selectedRoleData.role} className='tw-mb-0 !tw-text-[26px]' />
                          </div>
                          <div className='tw-col-span-4 tw-flex tw-items-center tw-justify-end'>
                            {!selectedRoleData.isDefault && (
                              <Button
                                className='tw-cursor-pointer !tw-font-sans !tw-text-sm !tw-font-semibold !tw-text-teal-500 hover:tw-text-teal-500'
                                variant='transparent'
                                onClick={() => onRoleNameChange(true)}
                              >
                                Change name
                              </Button>
                            )}
                            <div
                              className='tw-cursor-pointer tw-font-sans tw-text-sm tw-font-semibold tw-text-teal-500'
                              onClick={onRoleClose}
                            >
                              Close window
                            </div>
                          </div>
                        </div>
                        <Edit
                          userModalOpen={userModalOpen}
                          onModalChange={onModalChange}
                          selectedRoleData={selectedRoleData}
                          defaultPermissions={defaultPermissions}
                          setPermissionsState={setPermissionsState}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
              {isNewRole && (
                <>
                  <div className='tw-mt-14 tw-justify-center tw-p-6 sm:tw-flex sm:tw-p-9 lg:tw-m-0 lg:tw-w-1/2'>
                    <div className='tw-w-full'>
                      <div className='tw-mb-5'>
                        <Heading text='Create new role' className='!tw-text-[26px]' />
                      </div>
                      <TextInput
                        label='Name of role'
                        name='new_role'
                        placeholder='Enter name'
                        size='large'
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                      <Permissions
                        isDefault={false}
                        defaultPermissions={defaultPermissions}
                        setPermissionsState={setPermissionsState}
                      />
                      <div className='tw-pb-[58px]'></div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        </div>
      </div>
      {(isNewRole || isRoleEditable) && (
        <div className='bottom-0 left-0 right-0 tw-bg-gray-100_ tw-fixed tw-mt-11 tw-flex tw-justify-center'>
          <div className='tw-relative tw-w-full tw-px-5 lg:tw-container xl:tw-max-w-screen-2xl xl:tw-p-0'>
            <div className='tw-absolute -tw-top-0 -tw-z-10 tw-w-[95%] lg:tw-mx-5 lg:tw-w-11/12 xl:tw-w-[97%]'></div>
            <div className='tw-flex lg:tw-px-5'>
              <div className='tw-hidden tw-w-1/3 sm:tw-block lg:tw-hidden xl:tw-block xl:tw-w-1/4'></div>
              <div className='tw-flex tw-w-full tw-px-5 sm:tw-w-2/3 md:tw-px-0 lg:tw-w-full xl:tw-w-3/4'>
                <div className='tw-hidden tw-w-1/2 lg:tw-block '></div>
                <div className='tw-w-full tw-bg-white lg:tw-w-1/2'>
                  <div className='tw-mx-auto tw-w-full tw-pt-0 sm:tw-max-w-md sm:tw-pt-3 lg:tw-w-[350px]'>
                    <Button
                      font='semibold'
                      isFullWidth
                      size='large'
                      variant='teal'
                      className='tw-cursor-pointer !tw-font-sans !tw-text-base !tw-font-semibold'
                      onClick={isNewRole ? handleCreateRole : handleUpdateRole}
                    >
                      {isNewRole ? 'Save & create role' : 'Save role'}
                    </Button>
                    <div className='tw-mt-2 tw-flex tw-justify-center'>
                      <Button
                        className='tw-cursor-pointer !tw-font-sans !tw-text-sm !tw-font-medium !tw-text-purple-500'
                        onClick={() => onAddNewRole(false)}
                        variant='transparent'
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RolesPermissions;
