import { gql } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from './DocumentFragment';

const RESOLUTION_DOCUMENT_FRAGMENT = gql`
  fragment ResolutionDocumentFields on ResolutionDocument {
    id

    document {
      ...DocumentFields
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export { RESOLUTION_DOCUMENT_FRAGMENT };
