/** @jsx jsx */

import React, { memo, useRef, useMemo, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { AgendaAndTasksStep } from './../AgendaAndTasksStep';
import { Spinner } from 'components/shared/Spinner';
import { Steps } from 'components/shared/Steps';
import { AlertPopup } from 'components/shared/AlertPopup';
import { Step } from './Step';
import { StatusBadge } from 'components/shared/StatusBadge';
import { MeetingDetails } from './../MeetingDetails';
import { MeetingDetailsForm } from './../MeetingDetailsForm';
import { AgendaTasksTabs } from './../AgendaTasksTabs';
import { AttendeesStep } from './../AttendeesStep';
import { useVisibility } from 'hooks/useVisibility';
import { InvitationModal } from './../InvitationModal';
import MeetingSpace from './../MeetingSpace';
import { useSteps } from 'hooks/useSteps';
import { useMeetingStatus } from 'hooks/useMeetingStatus';
import { useActionCable } from 'hooks/useActionCable';
import { useMeetingActions, useMeetingStore } from 'store/meetingStoreContext';
import { useFlashActions, useFlashStore } from 'store/flashStoreContext';

import { getMeetingTypeOptions } from 'helpers/meetingTypes';

const MultistepForm = memo(
  ({
    meetingId,
    meetingTypes,
    timezoneOptions,
    currentContactId,
    currentContactName,
    contactTimezone,
    contactTimezoneOffset,
    zoomEnabled,
    root,
    availableLanguages,
    contactLanguage,
  }) => {
    const intl = useIntl();

    const meetingTypeOptions = useMemo(() => {
      return getMeetingTypeOptions(meetingTypes);
    }, []);

    const { current: stepMap } = useRef({
      1: intl.formatMessage({ id: 'MultistepForm.meetingDetails' }),
      2: intl.formatMessage({ id: 'MultistepForm.attendees' }),
      3: intl.formatMessage({ id: 'MultistepForm.agendaTasks' }),
    });

    const { steps, changeStep, advanceStep, jumpStep } = useSteps(stepMap);
    const { flash } = useFlashStore();
    const { setFlash, discardFlash } = useFlashActions();
    const { meeting, loaded, edits } = useMeetingStore();
    const { discardEdits, inviteAttendees, arrangeContext } = useMeetingActions();
    const { isDraft, isScheduled, isUpcoming, isArchived } = useMeetingStatus(meeting.status);
    const {
      showComponent: showModal,
      hideComponent: hideModal,
      isComponentVisible: isModalVisible,
    } = useVisibility(false);

    const { setErrors, setMeeting, loadMeeting, createMeeting, updateMeeting } = useMeetingActions();

    useActionCable('MeetingsChannel', { meeting_id: meetingId }, (response) => arrangeContext(meetingId, response));

    useEffect(() => {
      if (meetingId) {
        loadMeeting(meetingId).then(selectStep).catch(setFlash);
      } else {
        setMeeting({ contacts: [] });
      }
    }, []);

    useEffect(() => {
      if (!meeting.id) return;

      const URL = getAppropriateURL();

      window.history.replaceState({}, null, URL);
    }, [meeting]);

    useEffect(() => discardEdits(), [steps.current]);

    const companyId = getCompanySlug();

    const getAppropriateURL = () => {
      if (isDraft || isScheduled || isUpcoming) {
        return `/${companyId}/meetings/${meeting.slug}/edit`;
      } else {
        return `/${companyId}/meetings/${meeting.slug}`;
      }
    };

    const upsertMeeting = (handleUpsert) => {
      if (meeting.id) {
        updateMeeting(meeting.id, edits).then(handleUpsert).catch(setErrors);
      } else {
        createMeeting(edits).then(handleUpsert).catch(setErrors);
      }
    };

    const selectStep = (meeting) => {
      if (!meeting.valid) return;

      const nextStep = meeting.status == 'scheduled' ? 3 : 2;

      jumpStep(nextStep);
    };

    const [zoomPopupVisible, setZoomPopupVisible] = useState(false);
    const [saveAndAdvance, setSaveAndAdvance] = useState(false);

    const showZoomPopup = () => setZoomPopupVisible(true);
    const hideZoomPopup = () => setZoomPopupVisible(false);

    const showZoomConfirmation = meeting.id && meeting.config == 'zoom' && edits.config != 'zoom';

    const handleMeetingDetailsSave = () => {
      if (showZoomConfirmation) {
        setSaveAndAdvance(false);
        showZoomPopup();
      } else {
        handleSaveDraft();
      }
    };

    const handleMeetingDetailsSaveAndAdvance = () => {
      if (showZoomConfirmation) {
        setSaveAndAdvance(true);
        showZoomPopup();
      } else {
        handleSaveAndAdvance();
      }
    };

    const hideZoomPopupAndSave = () => {
      hideZoomPopup();

      saveAndAdvance ? handleSaveAndAdvance() : handleSaveDraft();
    };

    const handleSaveDraft = () => upsertMeeting(navigateToMeetings);
    const handleSaveAndAdvance = () => upsertMeeting(advanceStep);

    const handleInviteClick = () => {
      const hasContacts = edits.contact_meetings.filter((item) => !item._destroy).length > 0;

      if (hasContacts) {
        if (steps.current === 2) {
          inviteMeetingAttendees();
        }
        showModal();
      } else {
        setFlash(intl.formatMessage({ id: 'MultistepForm.addAttendeesFlash' }));
      }
    };

    const inviteMeetingAttendees = (params = {}) => inviteAttendees(edits.id, { ...edits, ...params });

    const handleInvite = (message, organizerId) => {
      inviteMeetingAttendees({
        ...edits,
        status: 'scheduled',
        email_message: message,
      })
        .then(hideModal)
        .then(advanceStep)
        .catch(setFlash);
    };

    // MISC
    const navigateToMeetings = () => {
      window.location.assign(`/${companyId}/meetings`);
    };

    const stepsIndicator = (
      <Steps
        stepMap={stepMap}
        currentStep={steps.current}
        completedSteps={steps.completed}
        changeStep={changeStep}
        disabledSteps={steps.disabled}
      />
    );

    if (meeting.id && !isDraft && !isScheduled && !isUpcoming) {
      return (
        <MeetingSpace
          meetingId={meeting.id}
          meetingTypes={meetingTypes}
          currentContactId={currentContactId}
          currentContactName={currentContactName}
          contactTimezone={contactTimezone}
          contactTimezoneOffset={contactTimezoneOffset}
          adminView
        />
      );
    }

    if (!loaded) {
      return <Spinner show />;
    }

    const saveDraftLabel = isDraft
      ? intl.formatMessage({ id: 'MultistepForm.saveDraft' })
      : intl.formatMessage({ id: 'MultistepForm.save' });

    return (
      <>
        <AlertPopup show={zoomPopupVisible} popupType='info' onClose={hideZoomPopup}>
          <FormattedMessage id='MultistepForm.confirmConferenceChange' />

          <div className='d-flex align-items-center m-t-40 justify-content-center'>
            <button className='btn btn-info' onClick={hideZoomPopupAndSave}>
              <FormattedMessage id='MultistepForm.yes' />
            </button>

            <button className='btn btn-info-inverted m-l-10' onClick={hideZoomPopup}>
              <FormattedMessage id='MultistepForm.cancel' />
            </button>
          </div>
        </AlertPopup>
        <Container fluid='lg'>
          <Row>
            <Col>
              <StatusBadge
                status={meeting.status}
                text={
                  meeting.id
                    ? intl.formatMessage({ id: 'MultistepForm.meeting' })
                    : intl.formatMessage({ id: 'MultistepForm.createMeeting' })
                }
              />
              <Spinner />

              <AlertPopup
                show={!!flash}
                popupType='failure'
                headerText={intl.formatMessage({ id: 'MultistepForm.failure' })}
                onClose={discardFlash}
              >
                <div className='text-black fs-14'>{flash}</div>
              </AlertPopup>

              {meeting.id && (
                <div className='m-b-20'>
                  <MeetingDetails
                    currentContactId={currentContactId}
                    currentContactName={currentContactName}
                    contactTimezone={contactTimezone}
                    contactTimezoneOffset={contactTimezoneOffset}
                    adminView
                  />
                </div>
              )}

              {steps.current === 1 && (
                <Step
                  saveDraftLabel={saveDraftLabel}
                  onSaveDraft={handleMeetingDetailsSave}
                  onSaveAndAdvance={handleMeetingDetailsSaveAndAdvance}
                  steps={stepsIndicator}
                >
                  <MeetingDetailsForm
                    meetingTypeOptions={meetingTypeOptions}
                    timezoneOptions={timezoneOptions}
                    contactTimezone={contactTimezone}
                    contactTimezoneOffset={contactTimezoneOffset}
                    zoomEnabled={zoomEnabled}
                    availableLanguages={availableLanguages}
                    contactLanguage={contactLanguage}
                  />
                </Step>
              )}

              <AttendeesStep
                show={steps.current === 2}
                steps={stepsIndicator}
                saveDraftLabel={saveDraftLabel}
                onSave={navigateToMeetings}
                onAdvance={advanceStep}
                onInvite={inviteMeetingAttendees}
                onInviteClick={handleInviteClick}
              />

              <AgendaAndTasksStep
                show={steps.current === 3}
                saveDraftLabel={saveDraftLabel}
                onSave={handleSaveDraft}
                onInvite={inviteMeetingAttendees}
                onInviteClick={handleInviteClick}
                steps={stepsIndicator}
              >
                <AgendaTasksTabs
                  meetingId={meeting.id}
                  meetingDuration={meeting.duration}
                  contacts={meeting.contacts}
                  meetingStatus={meeting.status}
                  root={root}
                  adminView
                />
              </AgendaAndTasksStep>
            </Col>
          </Row>

          <InvitationModal show={isModalVisible} meeting={edits} onInvite={handleInvite} onClose={hideModal} />

          <div id='document-preview-modal' className='modal preview-mode' />
        </Container>
      </>
    );
  }
);

export { MultistepForm };
