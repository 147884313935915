import React, { Fragment, useState } from 'react';
import { Tab, Disclosure } from '@headlessui/react';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import { withApollo } from 'utils/v2/apollo';
import { withIntl } from 'utils/v2/intl';
import { useCurrentUserProfileQuery } from 'data/v2/queries/CurrentUserProfileQuery';
import clsx from 'clsx';
import UserDetail from './UserDetail';
import Spinner from 'components/v2/shared/Spinner';
import DateFormat from './DateFormat';
import Password from './Password';
import Heading from 'components/v2/shared/Heading';
import Integrations from './Integrations';
import EmailPreferences from './EmailPreferences';
import SmartSign from './SmartSign';
import Notification from 'components/v2/notification';

const Panel = ({ timezones, countries, dateFormats, currentUser }) => (
  <Tab.Panels>
    <Tab.Panel>
      <UserDetail countries={countries} currentUser={currentUser} />
    </Tab.Panel>

    <Tab.Panel>
      <Password />
    </Tab.Panel>

    <Tab.Panel>
      <Integrations
        zoomIntegration={currentUser.zoomIntegration}
        calendarIntegrations={currentUser.calendarIntegrations}
      />
    </Tab.Panel>

    <Tab.Panel>
      <SmartSign currentUser={currentUser} />
    </Tab.Panel>

    <Tab.Panel>
      <DateFormat currentUser={currentUser} timezones={timezones} dateFormats={dateFormats} />
    </Tab.Panel>

    <Tab.Panel>
      <EmailPreferences mailConfig={currentUser.mailConfig} />
    </Tab.Panel>
  </Tab.Panels>
);

const Index = () => {
  const Menu = ({ label }) => (
    <Tab as={Fragment}>
      {({ selected }) => (
        <a
          onClick={() => setDisclosureHeading(label)}
          className={clsx(
            'tw-full tw-cursor-pointer tw-px-1	tw-py-2 tw-font-sans !tw-text-sm hover:tw-text-teal-500 sm:tw-px-0',
            {
              'tw-font-semibold tw-text-teal-500': selected,
              'tw-font-medium tw-text-black': !selected,
            }
          )}
        >
          {label}
          <span className='tw-full tw-pl-3'>
            <FiChevronRight size={17} className={clsx({ 'tw-hidden': !selected })} />
          </span>
        </a>
      )}
    </Tab>
  );

  const MenuList = () => (
    <Tab.List className='tw-flex tw-flex-col'>
      <Menu label='User details' />
      <Menu label='Password' />
      <Menu label='Integrations' />
      <Menu label='SmartSign settings' />
      <Menu label='Date and timezone settings' />
      <Menu label='Email preferences' />
    </Tab.List>
  );

  const [disclosureHeading, setDisclosureHeading] = useState('User details');
  const { data, loading } = useCurrentUserProfileQuery();

  if (loading) {
    return <Spinner variant='global' />;
  }

  return (
    <div className='tw-mx-auto lg:tw-container sm:tw-p-5 xl:tw-w-3/4'>
      <Notification />
      <div className='sm:tw-hidden'>
        <Tab.Group>
          <div className='tw-w-full tw-pb-1'>
            <div className='tw-mx-auto tw-w-full tw-max-w-full tw-bg-white '>
              <div className='tw-py-5 tw-px-5 sm:tw-px-10'>
                <Heading text='Settings' className='tw-m-0_' />
              </div>
              <div className='tw-px-10 tw-pb-10'>
                <MenuList />
              </div>
            </div>
          </div>
          <div className='tw-w-full tw-pb-1'>
            <div className='tw-mx-auto tw-w-full tw-max-w-full tw-bg-white'>
              <Disclosure defaultOpen={true}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className='tw-flex tw-w-full tw-items-center tw-justify-between tw-border-transparent tw-bg-transparent tw-py-6 tw-px-5 tw-text-left tw-text-sm tw-font-medium'>
                      <Heading text={disclosureHeading} className='tw-m-0' />
                      <div className='tw-grid tw-h-10 tw-w-10 tw-place-content-center tw-rounded-full tw-bg-purple-100'>
                        <FiChevronDown
                          className={`${open ? 'tw-rotate-180 tw-transform' : ''} tw-h-5 tw-w-5 tw-duration-100`}
                        />
                      </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className='tw-px-5 tw-pb-10'>
                      <Panel {...data} />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </Tab.Group>
      </div>

      <div className='tw-hidden sm:tw-block'>
        <Tab.Group>
          <div className='tw-my-2 tw-flex tw-bg-white'>
            <div className='tw-w-80 tw-border-0 tw-border-r tw-border-solid tw-border-gray-200 tw-py-9 tw-pl-14 lg:tw-w-1/3 '>
              <Heading text='Settings' className='tw-mb-10' />
              <MenuList />
            </div>
            <div className='tw-w-2/3 tw-py-9'>
              <div className='tw-container tw-flex tw-h-full tw-justify-center'>
                <div className='tw-w-full !tw-text-sm sm:tw-w-[400px]'>
                  <Panel {...data} />
                </div>
              </div>
            </div>
          </div>
        </Tab.Group>
      </div>
    </div>
  );
};

export default withApollo(withIntl(Index));
