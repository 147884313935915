import { css } from '@emotion/core';

export const passcodeModal = css`
  .modal-dialog {
    width: 340px;
    height: 160px;
  }

  .modal-dialog-centered {
    margin: auto;
  }

  .modal-body {
    padding-top: 0;
  }

  .lock-icon {
    width: auto;
    margin-right: 10px;
  }

  .eye-icon {
    opacity: 0.35;
    position: absolute;
    top: 59%;
    right: 25%;
  }

  .btn-show {
    text-transform: capitalize !important;
    height: 40px;
    width: 50px;
    margin-top: 5px;
  }
`;
