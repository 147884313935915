import { gql, useQuery } from '@apollo/client';
import { MESSAGE_FRAGMENT } from './../fragments/MessageFragment';
import { CONTACT_FRAGMENT } from 'data/fragments/ContactFragment';

const CONVERSATION_SUBSCRIPTION = gql`
  subscription ConversationSubscription {
    messageAdded {
      ...MessageFields

      owner {
        ...ContactFields
      }
    }
  }
  ${MESSAGE_FRAGMENT}
  ${CONTACT_FRAGMENT}
`;

export { CONVERSATION_SUBSCRIPTION };
