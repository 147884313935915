import styled from 'styled-components';
import { fonts, colors } from 'styles';

export const HeaderTitleWrapper = styled.div`
  position: absolute;
  left: 86px;
`;

export const HeaderButton = styled.button`
  color: ${colors.cyan};
  position: absolute;
  right: ${({ right }) => right}px;
  background: transparent;
  border: none;
  ${fonts.styledSemiBold}
  text-align: right;
  font-size: ${({ fontSize }) => fontSize}px;
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px`};
  @media (max-width: 375px) {
    right: 0;
    font-size: 14px;
  }
`;
