/** @jsx jsx */

import React, { memo, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import axios from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';

import { trackPromise } from 'react-promise-tracker';
import { getAttributes } from 'helpers/getAttributes';

import { AlertPopup } from 'components/shared/AlertPopup';
import { Spinner } from 'components/shared/Spinner';

import { ResolutionDocumentViewerList } from '../ResolutionDocumentViewerList';

import { Results } from './Results';

import { ReactComponent as CheckmarkIcon } from 'images/icons/check-green.svg';
import { ReactComponent as Exclamation } from 'images/icons/circle-exclamation.svg';

import * as styles from './Table.styles';

const ResolutionsTable = memo(({ vote, voter, onVoteStatusChange, previewDocument }) => {
  const companySlug = getCompanySlug();

  const accessToken = new URLSearchParams(window.location.search).get('access_token');

  const intl = useIntl();

  const [showSpinner, setShowSpinner] = useState(false);
  const [voteAttributes, setVoteAttributes] = useState(vote.data.attributes);
  const [showVotingSuccessAlert, setShowVotingSuccessAlert] = useState(false);
  const [showVotingFailureAlert, setShowVotingFailureAlert] = useState(false);

  const hideVotingSuccessAlert = () => {
    return setShowVotingSuccessAlert(false);
  };

  const hideVotingFailureAlert = () => {
    return setShowVotingFailureAlert(false);
  };

  const voteOpen = () => {
    return voteAttributes.status == 'open';
  };

  const votePublished = () => {
    return voteAttributes.status == 'published';
  };

  const resolutionPoll = (resolution) => {
    return resolution.data.attributes.poll.data;
  };

  const votePoll = () => {
    return voteAttributes.poll.data;
  };

  const resolutionPollResults = (resolution) => {
    return resolutionPoll(resolution).attributes;
  };

  const votePollResults = () => {
    return votePoll().attributes;
  };

  const savedVoterAnswers = () => {
    if (voter.data) {
      return Object.assign({}, ...voter.data.attributes.answers);
    }
  };

  const initialVoterActive = () => {
    return voter.data && voter.data.attributes.active;
  };

  const voterShowResults = () => {
    return voter.data && voter.data.attributes.show_results;
  };

  const voteAnswerOptions = () => {
    return voteAttributes.answer_options;
  };

  const voteResolutions = () => {
    return voteAttributes.resolutions;
  };

  const showDetails = () => {
    return !voteAttributes.anonymous || voter.data.attributes.show_all_results;
  };

  const isSelected = (pollId, answer) => {
    return voterAnswers[pollId.toString()] == answer;
  };

  const showOptions = () => {
    return voterActive || !voterShowResults();
  };

  const showResults = () => {
    return !voterActive && voterShowResults() && !votePublished();
  };

  const [voterAnswers, setVoterAnswers] = useState(savedVoterAnswers() || {});
  const [voterActive, setVoterActive] = useState(initialVoterActive());

  const toggleVoterAnswer = (pollId, answer) => {
    if (!voterActive || savedVoterAnswers().length > 0) return;

    const newAnswer = {};

    if (isSelected(pollId, answer)) {
      newAnswer[pollId] = null;

      return setVoterAnswers((currentVoteAnswers) => ({
        ...currentVoteAnswers,
        ...newAnswer,
      }));
    } else {
      newAnswer[pollId] = answer;

      return setVoterAnswers((currentVoteAnswers) => ({
        ...currentVoteAnswers,
        ...newAnswer,
      }));
    }
  };

  const allPollsAnswered = () => {
    const voterAnswersLength = Object.keys(voterAnswers).length;

    if (votePoll()) {
      return voterAnswersLength === 1;
    } else {
      return voterAnswersLength === voteResolutions().length;
    }
  };

  const handleVoteRefresh = () => {
    fetchVote();
    window.scrollTo(0, 0);
    setVoterActive(false);
  };

  const handleResponse = (responseStatus) => {
    if (responseStatus == 200) {
      handleVoteRefresh();
      setShowVotingSuccessAlert(true);
    }
  };

  const handleError = () => {
    handleVoteRefresh();
    setShowVotingFailureAlert(true);
  };

  const castVote = () => {
    const path = `/api/${companySlug}/v1/voters/${voter.data.id}/cast_vote.json`;
    const payload = { answer_params: voterAnswers };

    payload['access_token'] = accessToken;

    const promise = axios
      .put(path, payload)
      .then((response) => response.status)
      .then(handleResponse)
      .catch(handleError);

    return trackPromise(promise);
  };

  const fetchVote = () => {
    const path = `/api/${companySlug}/v1/votes/${vote.data.id}.json`;
    const params = {};

    if (accessToken) {
      params['access_token'] = accessToken;
    } else {
      params['voter_id'] = voter.data.id;
    }

    const promise = axios
      .get(path, { params: params })
      .then((response) => response.data)
      .then((response) => setVote(response));

    return trackPromise(promise);
  };

  const setVote = (response) => {
    return setVoteAttributes(response.data.attributes);
  };

  const refreshVote = () => {
    setShowSpinner(true);
    fetchVote();

    setTimeout(() => {
      setShowSpinner(false);
    }, 200);
  };

  useEffect(() => {
    return setVoteAttributes(vote.data.attributes);
  }, [vote]);

  useEffect(() => {
    return onVoteStatusChange(voteAttributes.status);
  }, [voteAttributes]);

  const answerOptions = (pollId) => {
    return voteAnswerOptions().map((option, index) => {
      return (
        <div
          className={`answer-option  ${isSelected(pollId, option) ? 'selected' : ''} ${
            voterActive ? 'cursor-pointer' : ''
          }`}
          key={`option-${index}`}
          onClick={() => toggleVoterAnswer(pollId, option)}
        >
          <CheckmarkIcon className='d-none checkmark' />
          {option}
        </div>
      );
    });
  };

  const resolutions = voteResolutions().map((resolution, index) => {
    const resolutionAttributes = getAttributes(resolution);

    return (
      <div className='d-flex flex-row resolution-wrapper' key={index}>
        <div className='fs-40 text-border-grey text-center font-weight-semibold' css={styles.countNumber}>
          {index + 1}.
        </div>
        <div className='d-flex flex-column' css={styles.resolutionContent}>
          {resolutionAttributes.description && (
            <div className='text-black m-b-15' css={styles.resolutionDescription}>
              {resolutionAttributes.description}
            </div>
          )}

          <ResolutionDocumentViewerList
            resolutionId={resolution.data.attributes.global_id}
            previewDocument={previewDocument}
          />

          {resolutionPoll(resolution) && resolutionPoll(resolution).id && showOptions() && (
            <div className='m-t-15' css={styles.resolutionPoll}>
              {answerOptions(resolutionPoll(resolution).id)}
            </div>
          )}
          {resolutionPoll(resolution) && resolutionPoll(resolution).id && showResults() && (
            <Results
              pollResults={resolutionPollResults(resolution)}
              answerOptions={voteAnswerOptions()}
              showDetails={showDetails()}
            />
          )}
        </div>
      </div>
    );
  });

  return (
    <>
      <Spinner show={showSpinner} />
      <AlertPopup
        show={showVotingSuccessAlert}
        headerText={intl.formatMessage({ id: 'ResolutionsTable.thanks' })}
        popupType='success'
        onClose={hideVotingSuccessAlert}
      >
        <div className='text-black fs-14 m-b-20'>
          <FormattedMessage id='ResolutionsTable.voteCast' />
        </div>
      </AlertPopup>
      <AlertPopup
        show={showVotingFailureAlert}
        headerText={intl.formatMessage({ id: 'ResolutionsTable.alreadyVoted' })}
        popupType='info'
        onClose={hideVotingFailureAlert}
      >
        <div className='text-black fs-14 m-b-20'>
          <FormattedMessage id='ResolutionsTable.cantCastAgain' />
        </div>
      </AlertPopup>

      <div className='m-b-30'>
        <div className='d-flex m-t-40'>
          <div className='fs-16 font-weight-semibold text-black'>
            <FormattedMessage id='ResolutionsTable.resolutions' />
          </div>
          {!voterActive && voteOpen() && showResults() && (
            <div className='far fa-sync-alt c-secondary cursor-pointer fs-18 m-l-10' onClick={() => refreshVote()} />
          )}
        </div>
        <div className='panel m-t-20 resolution-panel' css={styles.resolutions}>
          {voterActive && (
            <div css={styles.voteWarning}>
              <Exclamation />
              <div className='p-t-10 p-r-5 fs-12 text-black'>
                <FormattedMessage id='ResolutionsTable.cannotChange' />
              </div>
            </div>
          )}
          {resolutions}
          {votePoll() && showOptions() && <div className='vote-poll'>{answerOptions(votePoll().id)}</div>}
          {votePoll() && showResults() && (
            <div className='vote-poll'>
              <Results
                pollResults={votePollResults()}
                answerOptions={voteAnswerOptions()}
                showDetails={showDetails()}
              />
            </div>
          )}
        </div>
        {voterActive && (
          <div className='panel d-flex m-t-20 align-items-center p-20' css={styles.buttonContainer}>
            <a
              onClick={castVote}
              className={`btn btn-primary w-220 ${voterActive && allPollsAnswered() ? '' : 'disabled'}`}
            >
              <FormattedMessage id='ResolutionsTable.castVote' />
            </a>
          </div>
        )}
      </div>
    </>
  );
});

export { ResolutionsTable };
