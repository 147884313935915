/** @jsx jsx */

import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';

import Spinner from 'components/v2/shared/Spinner';
import Button, { Variants } from 'components/v2/shared/Button';
import TaskManager from 'components/v2/shared/TaskManager';
import TaskList from './../TaskList';
import Divider from 'components/v2/shared/Divider';

import { useTasksContext } from 'contexts/v2/TasksContext';

import * as styles from './TasksStep.styles';

const TasksStep = () => {
  const { tasks, loading, fetchMeetingTasks } = useTasksContext();

  useEffect(() => fetchMeetingTasks(), []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Row noGutters>
      <Col lg={12} className='mb-5'>
        <TaskManager />
      </Col>

      <Col lg={12} className='mb-5'>
        <Divider title='Tasks of meeting' position='left' isBold />
        <TaskList tasks={tasks} />
      </Col>

      <Col lg={12} className='mb-5'>
        <Button isFullWidth>Next</Button>

        <Button variant={Variants.Teal} isFullWidth className='mt-2'>
          Save draft
        </Button>
      </Col>
    </Row>
  );
};

TasksStep.propTypes = {};

export default TasksStep;
