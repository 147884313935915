/** @jsx jsx */

import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';

import Avatar from 'components/shared/Avatar';
import { ButtonWithSuccess } from 'components/shared/ButtonWithSuccess';

import * as styles from './ReviewListItem.styles';

const ReviewListItem = memo(({ reviewer, state, onResend, onDelete }) => {
  const { attributes: contact } = reviewer.data;

  const intl = useIntl();

  const isDraft = state === 'draft';
  const isPending = state === 'pending';

  const stateText =
    isPending || isDraft
      ? intl.formatMessage({ id: 'ReviewListItem.reviewWaiting' })
      : intl.formatMessage({ id: 'ReviewListItem.reviewReceived' });

  const stateStyles = [styles.state.base, isPending || isDraft ? styles.state.waiting : styles.state.received];

  const handleResend = onResend && onResend.bind(this, contact);
  const handleDelete = onDelete && onDelete.bind(this, { ...contact, deletable: reviewer.deletable });

  const resendButton = isPending && (
    <ButtonWithSuccess onClick={handleResend} className='btn btn-sm btn-inverted m-l-5 w-130'>
      <FormattedMessage id='ReviewListItem.resendRequest' />
    </ButtonWithSuccess>
  );

  const deleteButton = isPending && (
    <i
      onClick={handleDelete}
      className={`${
        reviewer.deletable ? 'cursor-pointer hover-c-secondary' : null
      } m-l-10 c-light-mid far fa-fw fa-trash-alt`}
    />
  );

  return (
    <div css={styles.item}>
      <div css={styles.avatarSide}>
        <Avatar contact={contact} />
        <div css={styles.name} className='text-truncate'>
          {contact.name}
        </div>
      </div>

      <div css={[stateStyles, styles.statusSide]}>{stateText}</div>

      {resendButton}
      {deleteButton}
    </div>
  );
});

export { ReviewListItem };
