import { css } from '@emotion/core';
import { tables, fonts, colors } from 'styles';

export const table = tables.table;
export const tableRow = tables.tableRow;

export const tableContainer = css`
  overflow: auto;
  max-height: 300px;
`;

export const tableHeader = (width) => css`
  ${tables.tableHeader(width)}
  padding: 3px 5px;
`;

export const tableCell = css`
  ${tables.tableCell}
  padding: 12px 5px;
`;

export const warning = css`
  font-size: 12px;
  color: ${colors.red};
`;

export const uppercase = css`
  text-transform: uppercase;
`;

export const status = css`
  min-width: 103px;
`;

export const statuses = {
  confirmed: css`
    color: ${colors.cyan};
  `,
  yes: css`
    color: ${colors.cyan};
  `,
  no: css`
    color: ${colors.red};
  `,
  pending: css`
    color: ${colors.orange};
  `,
  waiting: css`
    color: ${colors.orange};
  `,
  no_show: css`
    color: ${colors.orange};
  `,
};

export const deleteIcon = css`
  color: ${colors.lightGrey};

  &:hover {
    cursor: pointer;
    color: ${colors.cyan};
  }
`;
export const flexBetween = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const deleteMobile = css`
  flex-basis: 0 !important;
  flex-grow: 0 !important;
`;

export const mobileItem = css`
  border-top: 1px solid ${colors.headingGrey};
  padding: 15px 0;
  margin: 0 -15px;
`;

export const mobileItemWrapper = css`
  padding: 0 15px;
`;

export const mobileContainer = css`
  margin: 0 -15px;
  padding: 0 15px;
  border-bottom: 1px solid ${colors.headingGrey};
`;
