/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { COLORS } from './constants';

import * as styles from './TextButton.styles';

const TextButton = ({ color = 'cyan', text, onClick, isDisabled, className }) => (
  <button
    css={[styles.textButton.base, styles.textButton[color], isDisabled && styles.textButton.disabled]}
    onClick={onClick}
    className={className}
    disabled={isDisabled}
  >
    {text}
  </button>
);

TextButton.propTypes = {
  color: PropTypes.oneOf(COLORS),
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default TextButton;
