/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import * as styles from './Link.styles';

const Link = ({ children, href }) => (
  <a css={styles.link} href={href} rel='noopener' target='_blank'>
    {children}
  </a>
);

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
};

export default Link;
