import { gql } from '@apollo/client';

const ZOOM_MEETING_TOPIC_FRAGMENT = gql`
  fragment ZoomMeetingTopicFields on ZoomMeetingTopic {
    label
    value
  }
`;

export { ZOOM_MEETING_TOPIC_FRAGMENT };
