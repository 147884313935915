import { useDeleteContactGroupMutation } from './../data/mutations/DeleteContactGroupMutation';

const useDeleteContactGroup = ({ onCompleted }) => {
  const update = (cache, { data: { deleteContactGroup } }) => {
    const { contactGroup } = deleteContactGroup;

    cache.evict({
      id: cache.identify({ __typename: 'ContactGroup', id: contactGroup.id }),
      broadcast: false,
    });

    cache.gc();
    onCompleted(deleteContactGroup);
  };

  const [mutate] = useDeleteContactGroupMutation({ update });

  const deleteContactGroup = ({ contactGroupId }) => {
    mutate({ variables: { input: { contactGroupId } } });
  };

  return { deleteContactGroup };
};

export { useDeleteContactGroup };
