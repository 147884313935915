import { css } from '@emotion/core';
import { colors } from 'styles';

export const drawer = css`
  background-color: ${colors.white};
  display: block;
  height: 100vh;
  overflow: auto;
  position: fixed;
  transition: transform 0.2s ease-in-out;
  width: 450px;
  z-index: var(--above-max-z-index);
  transform: translateX(460px);
  transform: translateX(100%);
  right: 0;
  will-change: transform;
  top: 0;
  bottom: 0;
  box-shadow: none;

  @media (max-width: 767px) {
    height: 100%;
    width: 100%;
  }
`;

export const close = css`
  top: 26px;
  font-size: 14px;
  right: 35px;
  z-index: 3;
  position: absolute;
  color: ${colors.black};

  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
`;
