/** @jsx jsx */

import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Switch from 'react-input-switch';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as PlanSelectIcon } from 'images/icons/plan-select.svg';
import { ReactComponent as FreeTrialBadge } from 'images/icons/free-trial-14.svg';
import { withIntl } from '../../utils/v2/intl';
import { getStripeFontOptions } from 'utils/getStripeFontOptions';

import * as styles from './Products.styles';
import PaymentForm from './PaymentForm';
import Product from './Product';
import Invoices from './Invoices';

import { AlertPopup } from '../shared/AlertPopup';
import ManageForm from './ManageForm';

const stripePromise = loadStripe(ENV['STRIPE_KEY']);

const Products = ({ products, currentProduct, subscription, company, context, trial_ended }) => {
  const [period, setPeriod] = useState('monthly');
  let [showSuccessAlert, setShowSuccessAlert] = useState(false);
  let [subscribedProduct, setSubscribedProduct] = useState(currentProduct);
  let [currentSubscription, setCurrentSubscription] = useState(subscription);
  let [subscriptionCancelled, setSubscriptionCancelled] = useState(subscription && subscription.status === 'cancelled');
  let [showCancellationAlert, setShowCancellationAlert] = useState(false);

  const handleSuccessAlertClose = () => setShowSuccessAlert(false);
  const handleCancellationAlertClose = () => setShowCancellationAlert(false);

  const intl = useIntl();

  const isMonthlyProduct = (product) => {
    return product.interval === 'month';
  };

  const isYearlyProduct = (product) => {
    return product.interval === 'year';
  };

  const defaultProduct = products.find((p) => p.name.includes('Charity') && isMonthlyProduct(p));
  const [productSelected, setProduct] = useState(defaultProduct);

  const handleSetSub = () => {
    $.ajax({
      url: `/${company.slug}/profile/reload_subscription`,
      method: 'get',
      headers: {
        'X-CSRF-Token': $("[name='csrf-token']").attr('content'),
      },
    }).then((result) => {
      setCurrentSubscription(result);
    });
  };

  const handleClick = (product) => {
    setProduct(product);
  };

  const handleSwitch = (period, product) => {
    if (period === 'monthly') {
      setProduct(products.find((p) => p.name === product.name && isMonthlyProduct(p)));
    } else {
      setProduct(products.find((p) => p.name === product.name && isYearlyProduct(p)));
    }
    setPeriod(period);
  };

  // Sets the order of the products to match design
  // Using concat so that this is no destructive, else we get weird positions
  let prods = [];
  if (period === 'monthly') {
    prods = products.filter((p) => isMonthlyProduct(p));
  } else {
    prods = products.filter((p) => isYearlyProduct(p));
  }

  const reversedProducts = [].concat(prods).reverse();

  return (
    <div>
      {context === 'settings' && (
        <div className='media-heading'>
          <h2 className='media-title'>
            <FormattedMessage id='Products.billingInvoices' />
          </h2>
          <p className='fs-14 font-weight-semibold c-dark m-t-20 d-none d-md-block'>
            <FormattedMessage id='Products.planInfo' />
          </p>
        </div>
      )}
      {currentSubscription && !subscriptionCancelled && context === 'settings' ? (
        [
          <ManageForm
            key={`${subscribedProduct}-manage-form`}
            currentProduct={subscribedProduct}
            subscription={currentSubscription}
            setSubscriptionCancelled={setSubscriptionCancelled}
            setShowCancellationAlert={setShowCancellationAlert}
            company={company}
            trial_ended={trial_ended}
          />,
          <AlertPopup
            show={showSuccessAlert}
            headerText={intl.formatMessage({ id: 'Products.success' })}
            popupType='success'
            key='success-popup'
            onClose={handleSuccessAlertClose}
          >
            <FormattedMessage id='Products.paymentSuccess' />
            <br />
            <FormattedMessage id='Products.subCreated' />
          </AlertPopup>,
        ]
      ) : (
        <Elements stripe={stripePromise} options={getStripeFontOptions()}>
          <AlertPopup
            show={showCancellationAlert}
            headerText={intl.formatMessage({ id: 'Products.success' })}
            popupType='success'
            onClose={handleCancellationAlertClose}
          >
            <FormattedMessage id='Products.subCancelled' />
          </AlertPopup>
          {subscription && context === 'settings' && (
            <div css={styles.noProductBox}>
              <PlanSelectIcon />
              <p css={styles.noProductText}>
                <FormattedMessage
                  id='Products.noSub'
                  values={{ date: moment(subscription.deactivated_at).format('DD-MM-YYYY') }}
                />
              </p>
            </div>
          )}
          {!(currentSubscription && subscriptionCancelled) && (
            <div className='mw-630 m-auto m-b-30 m-t-10 p-l-15 p-r-15'>
              <div className='d-flex flex-row align-items-center'>
                {!trial_ended && (
                  <div className='flex-grow-1'>
                    <FreeTrialBadge />
                  </div>
                )}
                <div className='p-l-20 p-r-20 text-center'>
                  <p className='fs-14 c-dark m-b-0'>
                    {trial_ended ? (
                      <span>
                        <FormattedMessage id='Products.trialEnded' />
                      </span>
                    ) : (
                      <span>
                        <FormattedMessage id='Products.plans' />
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className='col-md-10 m-auto p-b-10' style={{ maxWidth: 800 }}>
            <div className='d-flex justify-content-center align-items-center'>
              <span
                style={{
                  marginRight: 10,
                  marginTop: -8,
                  color: period === 'monthly' ? '#00BAC6' : 'grey',
                  fontFamily: period === 'monthly' ? 'Gilroy-Bold' : 'Gilroy-Regular',
                }}
              >
                <FormattedMessage id='Products.monthly' />
              </span>
              <Switch
                on='yearly'
                off='monthly'
                value={period}
                styles={{
                  track: {
                    backgroundColor: '#00BAC6',
                  },
                  trackChecked: {
                    backgroundColor: '#00BAC6',
                  },
                  button: {
                    backgroundColor: '#f6f6f6',
                  },
                }}
                onChange={(period) => handleSwitch(period, productSelected)}
              />{' '}
              <span
                style={{
                  marginLeft: 10,
                  marginTop: -8,
                  color: period === 'yearly' ? '#00BAC6' : 'grey',
                  fontFamily: period === 'yearly' ? 'Gilroy-Bold' : 'Gilroy-Regular',
                }}
              >
                <FormattedMessage id='Products.yearly' />
              </span>
            </div>
            <div className='row'>
              {reversedProducts.map((product, index) => {
                return (
                  <Product
                    key={product.id}
                    product={product}
                    period={period}
                    company={company}
                    selected={productSelected === product}
                    handleClick={handleClick}
                  />
                );
              })}
            </div>
          </div>

          {subscription ? (
            <div className='col-md-12'>
              <Invoices company={company} key='invoices-table' />
            </div>
          ) : null}

          {productSelected ? (
            <PaymentForm
              company={company}
              productSelected={productSelected}
              period={period}
              setSubscription={handleSetSub}
              setProduct={setSubscribedProduct}
              setAlert={setShowSuccessAlert}
              setSubscriptionCancelled={setSubscriptionCancelled}
              context={context}
              trial_ended={trial_ended}
            />
          ) : null}
        </Elements>
      )}
    </div>
  );
};

export default withIntl(Products);
