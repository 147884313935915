/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import NoFolders from 'images/icons/no-folders.svg';
import NoDocuments from 'images/icons/no-documents.svg';
import DirectoryPath from 'components/documents/DirectoryPath';
import FolderRow from 'components/shared/FileBrowser/FolderRow';
import { CurrentDocuments } from './CurrentDocuments';
import { FileBrowserSearch } from './FileBrowserSearch';
import * as styles from './FileBrowser.styles';

export const FolderExplorer = ({
  currentFolderChildren,
  searchMode,
  currentPath,
  selectFolder,
  error,
  isSelectable,
  multiple,
  currentFolderDocuments,
  selectDocument,
  currentDocument,
  setCurrentDocument,
  selectedDocuments,
  navigateToRoot,
  setSelectedDocuments,
  searchTerm,
  setSearchTerm,
  setIsSearching,
  setSearchResults,
  setSearchMode,
  currentRoot,
  context,
}) => {
  const timestampFormat = 'DD.MM.YYYY hh:mm A';

  return (
    <div className='react-browser'>
      {!searchMode && (
        <div css={styles.padded} className='p-b-20'>
          <DirectoryPath directories={currentPath} onDirectoryClick={selectFolder} />
        </div>
      )}

      <FileBrowserSearch
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setIsSearching={setIsSearching}
        setSearchResults={setSearchResults}
        setSearchMode={setSearchMode}
        currentRoot={currentRoot}
      />

      {error && <div css={styles.error}>{error}</div>}

      <div css={styles.padded} className='m-t-15 p-b-10'>
        <div css={styles.heading} className='p-b-10'>
          <FormattedMessage id='FolderExplorer.folderCount' values={{ count: currentFolderChildren.length }} />
        </div>

        {currentFolderChildren.length > 0 ? (
          <FolderRow
            directories={currentFolderChildren}
            onDirectoryClick={selectFolder}
            isSelectable={isSelectable}
            multiple={multiple}
            showDocumentCount={true}
            showCreatedAt={true}
            showPath={searchMode}
            createdAtFormat={timestampFormat}
          />
        ) : (
          <div className='directories-explorer'>
            <div className='position-relative m-t-40'>
              <div css={styles.placeholderCircle} />

              <div css={styles.placeholderImage}>
                <img src={NoFolders} className='m-0' />
                <div className='m-t-10'>
                  <FormattedMessage id='FolderExplorer.noFolders' />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div css={styles.separator} />

      <div css={styles.padded} className='m-t-15'>
        <div css={styles.heading} className='p-b-10'>
          <FormattedMessage id='FolderExplorer.fileCount' values={{ count: currentFolderDocuments.length }} />
        </div>

        <div className='directories-explorer'>
          {currentFolderDocuments.length > 0 ? (
            <CurrentDocuments
              currentFolderDocuments={currentFolderDocuments}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
              searchMode={searchMode}
              multiple={multiple}
              setCurrentDocument={setCurrentDocument}
              currentDocument={currentDocument}
              context={context}
            />
          ) : (
            <div className='position-relative m-t-40'>
              <div css={styles.placeholderCircle} />

              <div css={styles.placeholderImageFiles}>
                <img src={NoDocuments} className='m-0' />
                <div className='m-t-10'>
                  <FormattedMessage id='FolderExplorer.noFiles' />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div css={styles.padded} className='m-t-30'>
        <div className='text-left'>
          <a
            onClick={selectDocument}
            disabled={!currentDocument}
            className={`btn ${currentDocument || selectedDocuments.length > 0 ? 'btn-primary' : 'btn-disabled'}`}
          >
            <FormattedMessage id='FolderExplorer.select' />
            {selectedDocuments.length > 0 && ` (${selectedDocuments.length}) files`}
          </a>
          <a onClick={navigateToRoot} className='btn btn-text brand-text'>
            <FormattedMessage id='FolderExplorer.cancel' />
          </a>
        </div>
      </div>
    </div>
  );
};
