import { css } from '@emotion/core';
import { panels, media } from 'styles';

export const bar = css`
  ${panels.base}
  padding: 20px 30px 10px;
  border-top: 1px solid rgb(223, 223, 223, 0.25);
  margin-top: 15px;
  z-index: 4;
  ${media.mobile} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
