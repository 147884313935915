import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FiX } from 'react-icons/fi';
import IconButton from 'components/v2/shared/IconButton';

export const Severity = {
  Success: 'success',
  Error: 'error',
  Info: 'info',
  Warning: 'warning',
};
export const Width = {
  Default: 'default',
  Large: 'large',
};

const severityIconMap = {
  [Severity.Success]: (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='10' cy='10' r='10' fill='#B1EDBE' />
      <path
        d='M6.36035 9.89558L9.54217 13.1791L14.5422 7.27002'
        stroke='black'
        strokeWidth='0.85'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),
  [Severity.Error]: (
    <svg width='24' height='21' viewBox='0 0 24 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.1579 20.3438L11.5789 0.34375L0 20.3438H23.1579ZM12.6209 17.1904H10.5157V15.0852H12.6209V17.1904ZM10.5157 12.9733H12.6209V8.76278H10.5157V12.9733Z'
        fill='#F12F2F'
      />
    </svg>
  ),
  [Severity.Info]: (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 0C4.46667 0 0 4.46667 0 10C0 15.5333 4.46667 20 10 20C15.5333 20 20 15.5333 20 10C20 4.46667 15.5333 0 10 0ZM11.1111 14.6444C11.1111 15.2444 10.6 15.7333 10 15.7333C9.4 15.7333 8.88889 15.2444 8.88889 14.6444V8.95556C8.88889 8.35556 9.4 7.86667 10 7.86667C10.6 7.86667 11.1111 8.35556 11.1111 8.95556V14.6444ZM10.0222 6.44444C9.42222 6.44444 8.93333 5.95556 8.93333 5.35556C8.93333 4.75556 9.42222 4.26667 10.0222 4.26667C10.6222 4.26667 11.1111 4.75556 11.1111 5.35556C11.1111 5.95556 10.6222 6.44444 10.0222 6.44444Z'
        fill='#662D91'
      />
    </svg>
  ),
  [Severity.Warning]: (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 0C4.46667 0 0 4.46667 0 10C0 15.5333 4.46667 20 10 20C15.5333 20 20 15.5333 20 10C20 4.46667 15.5333 0 10 0ZM11.1111 14.6444C11.1111 15.2444 10.6 15.7333 10 15.7333C9.4 15.7333 8.88889 15.2444 8.88889 14.6444V8.95556C8.88889 8.35556 9.4 7.86667 10 7.86667C10.6 7.86667 11.1111 8.35556 11.1111 8.95556V14.6444ZM10.0222 6.44444C9.42222 6.44444 8.93333 5.95556 8.93333 5.35556C8.93333 4.75556 9.42222 4.26667 10.0222 4.26667C10.6222 4.26667 11.1111 4.75556 11.1111 5.35556C11.1111 5.95556 10.6222 6.44444 10.0222 6.44444Z'
        fill='#FF7A00'
      />
    </svg>
  ),
};

const Alert = ({
  title = '',
  description,
  children,
  isDismissable = true,
  onDismiss,
  severity = Severity.Success,
  width = Width.Default,
  className,
}) => (
  <div
    className={clsx(
      'tw-break-word tw-rounded tw-p-4',
      {
        'tw-w-[320px] sm:tw-w-[550px] lg:tw-w-[950px]': width === Width.Large,
        'tw-bg-green-100': severity === Severity.Success,
        'tw-bg-red-100': severity === Severity.Error,
        'tw-bg-purple-100': severity === Severity.Info,
        'tw-bg-orange-100': severity === Severity.Warning,
      },
      className
    )}
  >
    <div
      className={clsx('tw-flex', {
        'tw-items-center': !title.length,
        'tw-items-start': title.length > 0,
        'tw-justify-between': width === Width.Large,
      })}
    >
      <div className={clsx('tw-flex', { 'tw-w-full tw-justify-start lg:tw-justify-center': width === Width.Large })}>
        <div className='tw-flex tw-h-5 tw-w-6 tw-shrink-0 tw-items-center tw-justify-center'>
          {severityIconMap[severity]}
        </div>

        <div className='tw-mx-4 tw-flex tw-flex-col tw-gap-y-2 tw-text-black'>
          <div className='tw-text-base tw-font-bold tw-leading-none empty:tw-hidden'>{title}</div>
          <div className='tw-text-sm tw-font-medium empty:tw-hidden'>{description}</div>
        </div>
      </div>
      <div>
        <IconButton
          onClick={onDismiss}
          children={<FiX />}
          className={clsx('tw-ml-auto tw-text-base', {
            'hover:!tw-bg-green-500/40': severity === Severity.Success,
            'hover:!tw-bg-red-400/20': severity === Severity.Error,
            'hover:!tw-bg-purple-200': severity === Severity.Info,
            'hover:!tw-bg-orange-500/40': severity === Severity.Warning,
            '!tw-ml-0': width === Width.Large,
            'tw-hidden': !isDismissable,
          })}
        />
      </div>
    </div>

    <div className='tw-mr-4 tw-ml-10 tw-mt-2 empty:tw-hidden'>{children}</div>
  </div>
);

Alert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  children: PropTypes.node,
  severity: PropTypes.oneOf(Object.values(Severity)),
  width: PropTypes.oneOf(Object.values(Width)),
  isDismissable: PropTypes.bool,
  onDismiss: PropTypes.func,
};

export default Alert;
