/** @jsx jsx */

import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import VoteManager from 'components/v2/shared/VoteManager';
import Button, { Variants } from 'components/v2/shared/Button';
import Divider from 'components/v2/shared/Divider';
import VoteList from './../VoteList';
import Spinner from 'components/v2/shared/Spinner';

import { useVotesContext } from 'contexts/v2/VotesContext';

import * as styles from './VotingStep.styles';

const VotingStep = () => {
  const { votes, loading, fetchMeetingVotes } = useVotesContext();

  useEffect(() => fetchMeetingVotes(), []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Row noGutters>
      <Col lg={12} className='mb-5'>
        <VoteManager />
      </Col>

      <Col lg={12} className='mb-5'>
        <Divider title='Votes of meeting' position='left' isBold />
        <VoteList votes={votes} />
      </Col>

      <Col lg={12} className='mb-5'>
        <Button isFullWidth>Next</Button>

        <Button variant={Variants.Teal} isFullWidth className='mt-2'>
          Save draft
        </Button>
      </Col>
    </Row>
  );
};

VotingStep.propTypes = {};

export default VotingStep;
