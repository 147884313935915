import { gql, useQuery } from '@apollo/client';
import { NOTICE_SCHEDULE_FRAGMENT } from 'data/v2/fragments/NoticeScheduleFragment';

const MEETING_NOTICE_SCHEDULE_QUERY = gql`
  query MeetingNoticeScheduleQuery($id: ID!) {
    currentCompany {
      id

      meeting(id: $id) {
        id

        noticeSchedule {
          ...NoticeScheduleFields
        }
      }
    }
  }
  ${NOTICE_SCHEDULE_FRAGMENT}
`;

const useMeetingNoticeScheduleQuery = (options) => useQuery(MEETING_NOTICE_SCHEDULE_QUERY, options);

export { MEETING_NOTICE_SCHEDULE_QUERY, useMeetingNoticeScheduleQuery };
