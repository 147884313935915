import { ApolloLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import * as ActionCable from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import typePolicies from 'typePolicies';
import { disableFragmentWarnings } from 'graphql-tag';

disableFragmentWarnings();

const cableLink = new ActionCableLink({ cable: ActionCable.createConsumer() });
const httpLink = createUploadLink({
  uri: `/api/${window.getCompanySlug?.()}/graphql`,
  credentials: 'same-origin',
  headers: {
    'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')?.getAttribute('content'),
  },
});

const hasSubscriptionOperation = ({ query: { definitions } }) =>
  definitions.some(({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription');

const link = ApolloLink.split(hasSubscriptionOperation, cableLink, httpLink);
const cache = new InMemoryCache({ typePolicies });
const client = new ApolloClient({ link, cache });

export { client };
