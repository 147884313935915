import { css } from '@emotion/core';
import { colors, fonts } from 'styles';

export const container = css`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
`;

export const text = css`
  ${fonts.bold}
  color: ${colors.black};
  font-size: 12px;
`;

export const icon = {
  base: css`
    margin-left: 4px;
    transition: transform 0.2s ease-in-out;
  `,

  rotated: css`
    transform: rotate(-180deg);
  `,
};
