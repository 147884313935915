import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const placeholder = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
`;

export const placeholderCircle = css`
  position: relative;
  margin: 0 auto;
  background: ${colors.grey};
  width: 68px;
  height: 68px;
  border-radius: 50%;
`;

export const placeholderIcon = css`
  position: absolute;
  top: 7px;
  left: -8px;
`;

export const placeholderText = css`
  color: ${colors.mediumGrey};
  font-size: 12px;
  margin-top: 20px;
`;

export const table = css`
  left: 0;
  width: 100%;
  table-layout: fixed;
`;

export const tableHeader = (width) => css`
  ${fonts.bold}
  color: ${colors.black};
  padding-top: 13px;
  font-size: 12px;
  line-height: 20px;
  width: ${width}%;
  padding: 10px 5px 5px 4px;
`;

export const tableCell = css`
  font-size: 14px;
  color: ${colors.black};
  padding: 14px 5px 14px 4px;
`;

export const tableRow = (isLast) => css`
  border-bottom: 1px solid;
  border-color: ${isLast ? 'transparent' : colors.grey};
`;

export const deleteIcon = css`
  color: ${colors.lightGrey};

  &:hover {
    cursor: pointer;
    color: ${colors.cyan};
  }
`;
