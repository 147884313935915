import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const container = css`
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1;
  padding: 10px 0;
  cursor: pointer;

  &:after {
    content: '';
    width: 1px;
    height: 100%;
    border-right: 1px dashed ${colors.cyan};
    z-index: 1;
    position: absolute;
    left: 7px;
    top: 50%;
  }

  &:last-child:after {
    display: none;
  }
`;

export const icon = {
  base: css`
    --size: 8px;
    --border-size: 2px;

    width: var(--size);
    height: var(--size);
    border: var(--border-size) solid ${colors.cyan};
    z-index: 2;
    border-radius: 50%;
    position: absolute;
    background: ${colors.white};
    left: 3px;
    top: 50%;
    transform: translate(0, -50%);
  `,

  active: css`
    left: 0;
    --size: 15px;
    --border-size: 5px;
  `,
};

export const step = {
  base: css`
    --step-color: ${colors.black};

    display: block;
    font-size: 14px;
    margin-left: 22px;
    color: var(--step-color);

    &:hover {
      color: var(--step-color);
    }
  `,

  active: css`
    ${fonts.bold}
    --step-color: ${colors.cyan};
  `,
};
