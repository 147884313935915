import React, { memo, useEffect, useState } from 'react';
import { getErrors } from 'helpers/getErrors';
import { trackPromise } from 'react-promise-tracker';
import { FormattedMessage, useIntl } from 'react-intl';
import { withIntl } from '../../../utils/v2/intl';
import axios from 'axios';

import { Modal } from 'components/shared/Modal';
import { MeetingPicker } from 'components/shared/MeetingPicker';
import { Input } from 'components/shared/Input';

import * as styles from './CreatePopup.styles';

const CreatePopup = memo(({ show, onClose, meeting, meetingOptions, disableMeetingPicker, openInNewTab }) => {
  const [minuteName, setMinuteName] = useState('');
  const [minuteErrors, setMinuteErrors] = useState({});
  const [minuteMeeting, setMinuteMeeting] = useState(meeting);

  const intl = useIntl();

  useEffect(() => {
    setMinuteMeeting(meeting);
  }, [meeting]);

  useEffect(() => {
    setMinuteErrors({});

    if (minuteMeeting) {
      setMinuteName(`${intl.formatMessage({ id: 'CreatePopup.minutes' })} ${minuteMeeting.title}`);
    }
  }, [minuteMeeting]);

  const handleClose = () => {
    setMinuteName('');
    setMinuteMeeting(meeting);
    setMinuteErrors({});
    onClose();
  };

  const companyId = getCompanySlug();
  const apiURL = `/api/${companyId}/v1/minutes.json`;

  const changeMinuteName = (event) => {
    setMinuteName(event.target.value || '');
    setMinuteErrors((state) => ({ ...state, name: null }));
  };

  const handleErrors = (response) => {
    setMinuteErrors(getErrors(response));
  };

  const navigateToMinute = ({ data: { attributes } }) => {
    if (openInNewTab) {
      handleClose();
      window.open(`/${companyId}/minutes/${attributes.slug}/edit`, '_blank');
    } else {
      window.location.assign(`/${companyId}/minutes/${attributes.slug}/edit`);
    }
  };

  const createMinute = () => {
    const payload = { minute: { name: minuteName, meeting_id: minuteMeeting?.id } };
    const promise = axios
      .post(apiURL, payload)
      .then((response) => response.data)
      .then(navigateToMinute)
      .catch(handleErrors);

    return trackPromise(promise);
  };

  const toggleMeeting = (meeting, onSelect) => {
    if (minuteMeeting?.id === meeting.id) {
      setMinuteMeeting(null);
    } else {
      setMinuteMeeting(meeting);
      onSelect();
    }
  };

  return (
    <Modal title='Create minute' modalStyles={styles.modal} show={show} onClose={handleClose} centered>
      <div className='m-t-35'>
        <div className='p-l-5'>
          <div className='m-t-5'>
            <div className='m-b-10'>
              <MeetingPicker
                onSelect={toggleMeeting}
                inputError={minuteErrors.meeting_id}
                value={minuteMeeting}
                isDisabled={disableMeetingPicker}
                options={meetingOptions}
              />
            </div>

            <Input
              name='name'
              errorMessage={minuteErrors.name}
              value={minuteName}
              onChange={changeMinuteName}
              isRequired
              placeholder={intl.formatMessage({ id: 'CreatePopup.inputPlaceholder' })}
            />
          </div>
        </div>

        <button className='btn btn-primary m-t-35 p-l-30 p-r-30' onClick={createMinute}>
          <FormattedMessage id='CreatePopup.create' />
        </button>
      </div>
    </Modal>
  );
});

export default withIntl(CreatePopup);
