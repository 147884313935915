import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';

const List = ({ items, render, paginated = false, ...paginationProps }) => {
  return (
    <>
      {items?.map((item) => {
        const ListItem = render({ data: item });
        return <Fragment key={item.id}>{ListItem}</Fragment>;
      })}
      {paginated && <Pagination {...paginationProps} />}
    </>
  );
};

List.propTypes = {
  items: PropTypes.array,
  render: PropTypes.func,
  paginated: PropTypes.bool,
  page: PropTypes.number,
  perPage: PropTypes.number,
  pageTotal: PropTypes.number,
  onPaginationClick: PropTypes.func,
};

export default List;
