import { gql, useMutation } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from './../fragments/DocumentFragment';

const UPDATE_DOCUMENT = gql`
  mutation updateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      document {
        ...DocumentFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

const useUpdateDocumentMutation = (options) => useMutation(UPDATE_DOCUMENT, options);

export { useUpdateDocumentMutation };
