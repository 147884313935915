import { createStore } from './createStore';
import { getErrors } from 'helpers/getErrors';

const DEFAULTS = {
  permissions: [],
  name: '',
};

const initialState = {
  isOpen: false,
  role: DEFAULTS,
  errors: {},
};

const actionTypes = {
  OPEN_FOR_VIEW: 'drawer.open.viewonly',
  OPEN: 'drawer.open',
  HIDE: 'drawer.hide',
  SET_ROLE: 'drawer.role.set',
  SET_ERRORS: 'drawer.errors.set',
};

const useRoleDrawerActions = () => {
  const dispatch = useRoleDrawerDispatch();

  const openViewOnlyDrawer = (role) => {
    dispatch({ type: actionTypes.OPEN_FOR_VIEW, payload: { ...DEFAULTS, ...role } });
  };

  const openDrawer = (role) => {
    dispatch({ type: actionTypes.OPEN, payload: { ...DEFAULTS, ...role } });
  };

  const hideDrawer = () => {
    dispatch({ type: actionTypes.HIDE });
  };

  const setRole = (payload) => {
    dispatch({ type: actionTypes.SET_ROLE, payload: payload });
  };

  const setErrors = (payload) => {
    const messages = getErrors(payload);

    dispatch({ type: actionTypes.SET_ERRORS, payload: messages });
  };

  return {
    openDrawer,
    openViewOnlyDrawer,
    hideDrawer,
    setRole,
    setErrors,
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.OPEN_FOR_VIEW:
      return {
        ...state,
        viewOnly: true,
        isOpen: true,
        errors: {},
        role: { ...payload },
      };

    case actionTypes.OPEN:
      return {
        ...state,
        viewOnly: false,
        isOpen: true,
        errors: {},
        role: { ...payload },
      };

    case actionTypes.HIDE:
      return {
        ...state,
        isOpen: false,
      };

    case actionTypes.SET_ROLE:
      return {
        ...state,
        errors: {},
        role: { ...state.role, ...payload },
      };

    case actionTypes.SET_ERRORS:
      return {
        ...state,
        errors: payload,
      };
  }
};

const [RoleDrawerProvider, useRoleDrawerStore, useRoleDrawerDispatch] = createStore(reducer, initialState);

export { RoleDrawerProvider, useRoleDrawerStore, useRoleDrawerActions };
