import { gql, useQuery } from '@apollo/client';

const PERMISSIONS_QUERY = gql`
  query {
    permissions {
      id
      scope
      resource
      description
    }
  }
`;

const usePermissionsQuery = (options) => useQuery(PERMISSIONS_QUERY, options);

export { PERMISSIONS_QUERY, usePermissionsQuery };
