import moment from 'moment';

import { intl } from '../contexts/v2/TranslationContext';

const formatDuration = (value) => {
  const duration = moment.duration(value || 0, 'minutes');

  if (duration.hours() > 0 && duration.minutes() > 0) {
    return `${intl.formatMessage({ id: 'FormatDuration.hours' }, { hours: duration.hours() })} ${intl.formatMessage(
      { id: 'FormatDuration.minutes' },
      { minutes: duration.minutes() }
    )}`;
  } else if (duration.minutes() > 0) {
    return intl.formatMessage({ id: 'FormatDuration.minutes' }, { minutes: duration.asMinutes() });
  } else if (duration.hours() > 0) {
    return intl.formatMessage({ id: 'FormatDuration.hours' }, { hours: duration.asHours() });
  }
};

export { formatDuration };
