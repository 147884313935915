import React, { memo } from 'react';

export default memo(({ directories, onDirectoryClick }) => {
  const currentDirectoryId = directories.length > 1 ? directories[directories.length - 1].id : undefined;

  return (
    <div className='directories-path'>
      {directories.map((directory) => {
        const selectedClass = currentDirectoryId === directory.id ? 'selected-directory' : '';
        const onClickHandler = () => onDirectoryClick(directory.id);

        return (
          <React.Fragment key={directory.id}>
            <span className={`cursor-pointer directory-path-part ${selectedClass}`} onClick={onClickHandler}>
              {directory.name}
            </span>
          </React.Fragment>
        );
      })}
    </div>
  );
});
