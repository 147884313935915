import { css } from '@emotion/core';
import { fonts, colors } from 'styles';

export const mainWrapper = css`
  display: flex;
  flex-direction: column;
`;

export const component = css`
  display: flex;
  flex-direction: row;
`;

export const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;

  i {
    font-weight: 300;
    color: ${colors.mediumGrey};

    &:hover {
      cursor: pointer;
      color: ${colors.cyan};
    }
  }

  input {
    width: 40px;
    border-radius: 2px;
    height: 44px;
    color: ${colors.black};
    padding: 0;
    text-align: center;
    ${fonts.styledSemiBold}

    @media (maxWidth: 991px) {
      font-size: 16px;
      line-height: 24px;
    }
`;

export const timeDelimeter = css`
  display: flex;
  align-items: center;
  color: ${colors.lightGrey};
  margin-right: 5px;
`;

export const amPmcontainer = css`
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3px;
`;

export const amPmButton = {
  base: css`
    width: 40px;
    height: 20px;
    background: ${colors.grey};
    border-radius: 2px;
    font-size: 12px;
    line-height: 20px;
    color: ${colors.mediumGrey};
    cursor: pointer;
    text-align: center;
    margin-bottom: 5px;
  `,
  active: css`
    background: ${colors.cyan};
    color: ${colors.white};
  `,
};

export const errorWrapper = css`
  position: relative;
  margin-top: 20px;
`;
