import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import BaseOption from '../BaseOption';

const RegularOption = ({ item, ...props }) => (
  <BaseOption {...props} className='tw-text-sm'>
    {item.label}
  </BaseOption>
);

RegularOption.propTypes = {
  ...BaseOption.propTypes,
  item: PropTypes.object,
};

export default RegularOption;
