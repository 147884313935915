import { gql, useMutation } from '@apollo/client';
import { CONTACT_GROUP_FRAGMENT } from './../fragments/ContactGroupFragment';

const DELETE_CONTACT_GROUP_MUTATION = gql`
  mutation deleteContactGroup($input: DeleteContactGroupInput!) {
    deleteContactGroup(input: $input) {
      contactGroup {
        ...ContactGroupFields
      }

      errors {
        attribute
        message
      }
    }
  }
  ${CONTACT_GROUP_FRAGMENT}
`;

const useDeleteContactGroupMutation = (options) => useMutation(DELETE_CONTACT_GROUP_MUTATION, options);

export { DELETE_CONTACT_GROUP_MUTATION, useDeleteContactGroupMutation };
